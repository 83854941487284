import React from 'react';
import 'twin.macro';
import Records from 'components/videoCardProgress/Records';

const StudentInfo = ({ secondaryText, video, children, showTitle, videoStats }) => {
    return (
        <div tw='flex justify-between p-2.5 bg-InfoGray rounded-b-md'>
            <div tw='pr-2 pl-2.5 whitespace-normal'>
                {showTitle && <div tw='text-sm font-semibold height[45px]'>{video.title}</div>}
                {secondaryText}
            </div>
            <Records className='record-list' stats={videoStats} />

            {children}
        </div>
    );
};

export default StudentInfo;
