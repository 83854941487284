import React from 'react';
import tw from 'twin.macro';
import ReactPlayer from 'react-player';
import Modal from '../Modal/Modal';

export const VideoPlayerOverlay = ({ isOpen, setIsOpen, videoId }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
            <div tw='h-screen w-screen max-w-4xl max-h-[280px] sm:max-h-[500px]'>
                <ReactPlayer
                    playing={true} url={`https://vimeo.com/${videoId}`} width='100%' height='100%'
                    controls={true} />
            </div>
        </Modal>
    );
};

export default VideoPlayerOverlay;
