import React from 'react';
import { styled } from 'twin.macro';

const S = {
    Container: styled.div`
        //font-size: 1em;
        font-size: 16px;
        font-family: Avenir, serif;
        position: relative;
        min-height: 1em;
        margin: 1em 0;
        background: #f8f8f9;
        padding: 1em 1em;
        line-height: 1.4285em;
        color: rgba(0, 0, 0, 0.87);
        transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease,
        -webkit-box-shadow 0.1s ease;
        border-radius: 0.28571429rem;
        box-shadow: 0 0 0 1px rgb(34 36 38 / 22%) inset, 0 0 0 0 transparent;

        align-self: stretch;

        .header {
            font-size: 1.14285714em;
        }

        &.success {
            color: #2c662d;
            background-color: #fcfff5;
            box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;

            .header {
                color: #1a531b;
            }
        }

        &.error {
            color: #a94442;
            background-color: #f2dede;
            box-shadow: 0 0 0 1px #ebccd1 inset, 0 0 0 0 transparent;

            .header {
                color: #912d2b;
            }
        }

        &.warning {
            color: #573a08;
            background-color: #fffaf3;
            box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;

            .header {
                color: #794b02;
            }
        }

        &.info {
            color: #276f86;
            background-color: #f8ffff;
            box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;

            .header {
                color: #0e566c;
            }
        }

        &.debug {
            color: #222222;
            background-color: #ffffff;
            box-shadow: 0 0 0 1px #dddddd inset, 0 0 0 0 transparent;

            .header {
                color: #000000;
            }
        }
    `
};

const Message = ({ children, html, show, style, text, type }) => {
    const className = `message ${type ?? 'info'}`;
    if( show !== undefined && !show ) {
        return null;
    }

    if (html) {
        return (
            <S.Container className={className} style={style} dangerouslySetInnerHTML={{ __html: html }} />
        );
    }
    return (
        <S.Container className={className} style={style}>
            {text ?? children}
        </S.Container>
    );
};

export default Message;
