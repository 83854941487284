import React from 'react';
import PropTypes from 'prop-types';
import RecordImageSilver from 'static/images/records/silver.png';
import RecordImageGold from 'static/images/records/gold.png';
import RecordImagePlatinum from 'static/images/records/platinum.png';
import tw, { css, styled } from 'twin.macro';

const Container = styled.div(({ $variant, $teacherPlat }) => [
    tw`rounded-full font-sans font-extrabold text-lg my-2 maxWidth[200px]`,
    $variant === 'Platinum' && tw`bg-PlatinumRecord text-PlatinumText pl-4`,
    $variant === 'Gold' && tw`bg-GoldRecord  text-GoldText pl-4 text-center`,
    $variant === 'Silver' && tw`bg-TeacherAssignedGray  text-SilverText pl-4 text-center`,
    $teacherPlat && tw`background-color[#3BB8EE] color[#073A68] pl-4`
    // teacher && tw`minWidth[120px]`
]);

const RecordIndicator = ({ level, goal, teacherPlat, minWidth, ...props }) => {
    let RecordImages = {
        Silver: {
            img: RecordImageSilver
        },
        Gold: {
            img: RecordImageGold
        },
        Platinum: {
            img: RecordImagePlatinum
        }
    };
    return (
        <Container
            $variant={level}
            $teacherPlat={teacherPlat}
            css={[
                css`
                    min-width: 150px;

                    @media only screen and (max-width: 600px) {
                        margin-top: 20px;
                    }
                `
            ]}
            style={{ minWidth: minWidth }}
            {...props}
        >
            <div tw='relative flex items-center justify-center pr-2 '>
                <div tw='text-center text-2xl'>{goal ? goal : level}</div>
                <img tw=' w-5 max-h-5 ml-2 ' src={RecordImages[level]?.img} alt='img' />
            </div>
        </Container>
    );
};

RecordIndicator.propTypes = {
    anyProp: PropTypes.any
};

export default RecordIndicator;
