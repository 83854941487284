// warmup message for each level
import React from 'react';

export const QuizIntroMessage = [
    'See what you already know!</br>Answer these 5 warm-up questions and unlock the music video.',
    'Get <strong>100%</strong> to earn a <strong>Silver</strong> Record',
    'Get <strong>100%</strong> to earn a <strong>Gold</strong> Record',
    'Get <strong>100%</strong> to go <strong>Platinum</strong>'
];

export const WarmupFinishedMessage = [
    // 0 - 5
    // 'You can do it! This video will help :)',
    // 'You’re moving in the right direction. Check out the video to learn more. ',
    // 'Good start! Learn even more by watching the video.',
    // 'Nicely done! Watch the video and keep up the good work. ',
    ['Watch the video to keep learning about this subject.', 'The more you watch, the more you learn and remember!'],
    ['Watch the video to keep learning about this subject.', 'The more you watch, the more you learn and remember!'],
    ['Watch the video to keep learning about this subject.', 'The more you watch, the more you learn and remember!'],
    ['Watch the video to keep learning about this subject.', 'The more you watch, the more you learn and remember!'],
    ['Great job! You already know a lot about this subject!', 'Watch the video to keep crushing it!'],
    ['Great job! You already know a lot about this subject!', 'Watch the video to keep crushing it!']
    // <div>You aced it! You’re great at this topic.<br /><br />Plus, you just earned the Perfect Warmup achievement<br /><br />Watch the video and keep progressing.</div>
];

export const QuizFinalScoreMessage = [
    // 0-3
    'Watch the video again and then retake the Challenge. You can do this!',
    'Watch the video again and then retake the Challenge. You can do this!',
    'Watch the video again and then retake the Challenge. You can do this!',
    // 4-5
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    // 6
    'Nice work! You’re 4 questions away from earning the record. Take a peek at the video one more time. You got this!',
    // 7
    'Great job!! You’re so close. Only 3 more questions to go!',
    // 8
    'Fantastic score!! Only 2 more questions and the record is yours.',
    // 9
    'You’re a math rock star!! Answer 1 more question correctly to earn the record',
    // 10
    'CONGRATULATIONS!!! You did it!! You earned the record.'
];

