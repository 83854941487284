import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Route, useMatch, NavLink, Routes, Navigate } from 'react-router-dom';
import ChildSettings from './Settings/ChildSettings';
import Settings from './Settings/Settings';
import ManageSubscription from './Settings/ManageSubscription';

const settingsItems = {
    settings: 'Your Settings',
    child_settings: 'Child Settings',
    subscription: 'Manage Subscription'
};

const settingsTabs = [
    {
        name: 'Your Settings',
        path: 'settings',
        component: Settings
    },
    {
        name: 'Child Settings',
        path: 'child_settings',
        component: ChildSettings
    },
    {
        name: 'Manage Subscription',
        path: 'subscription',
        component: ManageSubscription
    }
];

const ParentSettings = () => {
    const match = useMatch('/settings/:tab_name');
    const { tab_name } = match?.params ?? {};

    const menu = useMemo(() => {
        return Object.keys(settingsItems).map((path) => (
            <NavLink
                replace={true}
                to={`/settings/${path}`}
                key={path}
                tw='py-4 cursor-pointer text-center float-left w-full rounded-md font-Poppins uppercase tracking-wider leading-relaxed text-[#B5AAD0]'
                css={tab_name === path && [tw`bg-[#6441B9] text-white `]}
            >
                {settingsItems[path]}
            </NavLink>
        ));
    }, [tab_name]);

    const currentTab = window.location.pathname.split('/').pop();

    return (

        <>
            {/*Mobile */}
            <div tw='sm:hidden relative sm:rounded flex flex-col sm:flex-row w-screen sm:w-[unset] min-h-full bg-[#3E257A] max-w-full '>
                <div tw='bg-[#1C0057] text-white px-6 pt-2 min-h-[100vh] pb-5'>
                    {settingsTabs.map((item, index) => (
                        <div key={item.path}>
                            {item.path !== 'settings' && (
                                <div tw='mb-[-3px]'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='98%' height='4' viewBox='0 0 530 4' fill='none'>
                                        <path d='M1 1L530 0.999971' stroke='#3E257A' />
                                    </svg>
                                </div>
                            )}
                            <NavLink
                                to={`/settings/${item.path}`}
                                key={item.path}
                                tw='py-4 cursor-pointer sm:text-center float-left w-full rounded-md font-Poppins uppercase tracking-wider pl-5 leading-relaxed text-[#B5AAD0]'
                                css={currentTab === item.path && [tw`bg-[#6441B9] text-white`]}
                            >
                                <div tw='flex justify-between items-center pr-5'>
                                    {item.name}
                                    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'>
                                        <path d={currentTab === item.path ? 'M10.6666 6.66602L6.37625 1.76273C6.17704 1.53506 5.82288 1.53506 5.62367 1.76273L1.33329 6.66601' : 'M1.33337 1.33398L5.62375 6.23727C5.82296 6.46494 6.17712 6.46494 6.37633 6.23727L10.6667 1.33398'} stroke={currentTab === item.path ? 'white' : '#9D91BB'} strokeWidth='1.5' strokeLinecap='round' />
                                    </svg>
                                </div>
                            </NavLink>
                            {currentTab === item.path && (
                                <div tw='w-full sm:w-3/4 pb-6'>
                                    <Routes>
                                        <Route key={index} path={item.path} element={<item.component />} />
                                    </Routes>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
      
            {/* Desktop view */}
            <div tw='relative sm:rounded flex-col sm:flex-row w-screen sm:w-[unset] min-h-full bg-[#3E257A] hidden sm:flex'>
              
                <div tw='hidden sm:block w-1/4 max-w-[300px] min-w-[250px] bg-[#4D3095] text-white pt-10'>
                    {menu}
                </div>
                <div tw='px-4 lg:px-12 w-full sm:w-3/4 pb-6'>
                    <div tw='hidden sm:block text-2xl mt-8 text-white'>
                        {settingsTabs.find(({ path }) => path === (tab_name ?? 'settings'))?.name}
                    </div>
                    <Routes>
                        {settingsTabs.map((item) => (
                            <Route path={item.path} key={item.path} element={<item.component />} />
                        ))}
                        <Route path='*' element={<Navigate to={settingsTabs[0].path} />} />
                    </Routes>
                </div>
            </div></>
    );
};

export default ParentSettings;
