import React, { useState } from 'react';
import 'twin.macro';
import moment from 'moment/moment';
import { Tooltip } from 'react-tooltip';

import { ChallengeButtonDTC, ListBox, HeartIcon, DTCVideoViewIcon } from 'components';
import { useClassStudents } from 'state/Teacher/ClassState';
import NullAchievementImage from 'static/images/achievements/null_achievements.png';

import { useLesson } from '../../../../state/CatalogState';
import AchievementConstants from '../Progress/AchievementConstants';

import './Session.css';

const VideoInfo = (props) => {
    // get the video
    const { video, quiz_level_goal, parent, toggleFavoriteVideo, selectedStudent } = props;
    const { students } = useClassStudents(null);
    // const listBoxItems = students.map((student) => {
    //     let views = 0;
    //     if (student?.video_stats) {
    //         if (video.id in student.video_stats) {
    //             views = student.video_stats[video.id]?.view_count ?? 0;
    //         }
    //     }
    //     return {
    //         name: `${student?.user?.first_name}'s Views: ${views}`,
    //         id: student?.user?.id,
    //         item: student
    //     };
    // });
    // const [selectedStudent, setSelectedStudent] = useState(listBoxItems[0]);
    // let showListBox = listBoxItems?.length > 1;

    const lesson = useLesson(video?.lesson_id);
    // const stats = useVideoStats(video?.id, selectedStudent?.item?.user?.id);

    if (!video)
        return null;

    // get the stats
    let currentLevel = 0;
    let lastView = null;
    let favorite = false;

    // use the stats for this video and the logged-in user
    const stats = video?.stats ?? null;
    let videoViews = stats?.view_count === 0 ? null : stats?.view_count ?? null;

    // use the stats for this video and the selected student
    if (parent && selectedStudent) {
        videoViews = selectedStudent?.item?.video_stats?.[video?.id]?.view_count ?? 0;
    }

    if (stats) {
        currentLevel = stats.current_level;
        favorite = stats.favorite;
        lastView = moment.utc(stats.last_viewed).local();
    }

    const takeChallenge = () => {
        if (props.onTakeChallenge) props.onTakeChallenge();
    };

    // if this video has a quiz, create a challenge button
    let challengeButton = null;
    if (video.quiz_id && currentLevel > 0 && currentLevel < 4) {
        challengeButton = (
            <>
                <div tw='w-1/2 lg:w-1/3 justify-end hidden md:flex'>
                    <div tw='maxWidth[280px] md:maxWidth[100%]'>
                        <ChallengeButtonDTC
                            level={currentLevel}
                            onClick={takeChallenge}
                        />
                    </div>
                </div>

                <div tw='ml-1 mt-4 justify-end flex md:hidden'>
                    <div tw='maxWidth[280px] md:maxWidth[100%]'>
                        <ChallengeButtonDTC
                            level={currentLevel}
                            onClick={takeChallenge}
                        />
                    </div>
                </div>
            </>
        );
    }

    // get all the achievements
    let achievements = [];
    if (props.achievements) {
        achievements = props.achievements.map((badge) => {
            return {
                id: badge.id,
                type: badge.badge_type,
                title: badge.badge_type,
                description: badge.description,
                date: moment(badge.datetime_awarded)
            };
        });
    }

    // sort achievements by date
    achievements = achievements.sort((a, b) => a.date.diff(b.date));
    // check if there is fewer achievements than 4
    // if there is, add an object to the achievements list to show a nullAchievementsSVG
    let amountOfNullAchievements = 4 - achievements.length;
    if (amountOfNullAchievements <= 4 && amountOfNullAchievements > 0) {
        const nullAchievementObjects = Array.from({ length: amountOfNullAchievements }, () => ({ nullAchievements: true }));
        achievements = achievements.concat(nullAchievementObjects);
    }

    let nodes = achievements?.map((achievement, index) => {
        console.log("🚀 ~ nodes ~ achievement:", achievement)
        const config = AchievementConstants[achievement.type];
        console.log("🚀 ~ nodes ~ config:", config)
        return (
            <React.Fragment key={index}>
                <div tw='relative first:ml-0 ml-5 mt-3 hidden md:block'>
                    <div tw='h-10 w-9'>
                        <img
                            data-tooltip-id='tooltip-achievement'
                            data-tooltip-content={config?.description}
                            data-tooltip-delay-show={500}
                            src={achievement.nullAchievements ? NullAchievementImage : config.non_ribbon_image}
                            alt={achievement.nullAchievements ? 'null_image' : config.header} />
                    </div>
                </div>
                <div tw='relative first:ml-0 mr-4 mt-3 md:hidden'>
                    <div tw='h-14 w-12'>
                        <img
                            tw='h-full w-full'
                            src={achievement.nullAchievements ? NullAchievementImage : config.non_ribbon_image}
                            alt={achievement.nullAchievements ? 'null_image' : config.header} />
                    </div>
                </div>
                <Tooltip
                    id='tooltip-achievement'
                    style={{
                        backgroundColor: '#6441B9',
                        color: '#fff',
                        fontSize: '12px',
                        width: '200px',
                        textAlign: 'center',
                        borderRadius: '8px',
                        zIndex: 50,
                        textWrap: 'balance'
                    }}
                />
            </React.Fragment>
        );
    });

    let goalLoading = !quiz_level_goal ? 0 : quiz_level_goal;

    return (
        <>
            <div tw='hidden md:block'>
                <div className='video-info-container' tw='font-Poppins text-white bg-[#3E257A] rounded-b-lg px-8 py-9'>
                    <div className='header'>
                        <div tw='flex items-center justify-between w-full'>
                            <h1 tw='w-96 sm:w-auto my-1 mt-4 sm:my-0 sm:mt-0 font-semibold text-xl text-white'>
                                {video.title}
                            </h1>
                            <div tw='flex items-center'>
                                <div tw='text-[#9D91BB] text-base '>{videoViews}</div>
                                <div tw='ml-2 relative'>
                                    <DTCVideoViewIcon selectedStudentName={selectedStudent?.item.user.first_name} />
                                </div>
                                {!parent &&
                                    <div tw='ml-2 relative'>
                                        <HeartIcon
                                            favorite={favorite}
                                            toggleFavoriteVideo={toggleFavoriteVideo} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div tw='flex'>
                            {achievements.length > 0 && !parent ? nodes : null}
                        </div>
                        <div tw='flex justify-between items-center w-full'>
                            <div tw='w-full md:w-2/3 text-sm mt-3.5 '>{lesson?.description}</div>
                            {challengeButton}
                        </div>
                    </div>
                </div>
            </div>

            <div tw='block md:hidden'>
                <div className='video-info-container' tw='font-Poppins text-white rounded-b-lg px-3 pb-9 pt-5'>
                    <div className='header'>
                        <div tw='flex items-start mt-2 justify-between w-full'>
                            <h1 tw='w-96 sm:w-auto -mt-1 mb-1 sm:my-0 sm:mt-0 font-semibold text-xl text-white'>
                                {video.title}
                            </h1>
                            <div tw='flex items-center'>
                                <div tw='text-[#9D91BB] text-base '>{videoViews}</div>
                                <div tw='ml-2 relative'>
                                    <DTCVideoViewIcon />
                                </div>
                                {!parent &&
                                    <div tw='ml-2 relative'>
                                        <HeartIcon
                                            favorite={favorite}
                                            toggleFavoriteVideo={toggleFavoriteVideo} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div tw='flex'>
                            {achievements.length > 0 && !parent ? nodes : null}
                        </div>
                        <div tw='flex justify-between items-center w-full flex-col'>
                            <div
                                tw='w-full md:w-2/3 text-sm font-light mt-3.5 text-[#fff] text-base leading-[120%] font-Poppins'>{lesson?.description}</div>
                            {challengeButton}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VideoInfo;
