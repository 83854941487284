import React from 'react';
import BugReport from 'components/BugReport/BugReport';
import { NavLink } from 'react-router-dom';
import tw, { css } from 'twin.macro';

const MobileNav = ({ isMobileMenuOpen, isNotProd, items, user }) => {
    return (
        <div tw='bg-[#3E257A]'>
            {isMobileMenuOpen && (
                <div
                    // tw='md:hidden'
                    id='mobile-menu'
                    css={[
                        css`
                            @media (min-width: 900px) {
                                display: none;
                            }
                            @media (max-width: 550px) {
                                height: 100vh;
                            }
                        `
                    ]}
                >
                    <div tw='pt-9 pb-6 space-y-4 sm:space-y-2 text-center'>
                        <div tw='h-0.5 bg-white mb-10 absolute left-0 right-0 bottom-0' style={{ top: '75px' }} />
                        {items.map(({ path, title, exact }) => (
                            <NavLink
                                key={path}
                                to={path}
                                end={exact}
                                aria-current='page'
                                tw='text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-lg font-extrabold'
                                activeStyle={tw`bg-PinkCrayola`}
                                onClick={() => openMobileMenu(false)}
                            >
                                {title}
                            </NavLink>
                        ))}
                        {isNotProd && (
                            <div tw='flex items-center justify-center'>
                                <BugReport tw='ml-0' user={user}>
                                    <div
                                        tw='py-2 px-6 rounded-full uppercase text-sm font-extrabold leading-normal text-white cursor-pointer'
                                        css={[
                                            css`
                                                background: linear-gradient(90deg, #e637a8 0%, #e68137 100%);
                                            `
                                        ]}
                                    >
                                        FEEDBACK
                                    </div>
                                </BugReport>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileNav;