import React, { useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';
import moment from 'moment/moment';
import { styled } from 'twin.macro';
import { Navigate, useMatch, useNavigate, useRoutes } from 'react-router-dom';
import { AssignmentCard, NullContainer, OverDueDecorator, TabNavigator, ChildLearningCategories, Modal } from 'components';
import { useQuery } from 'hooks/useQuery';
import { useAssignments, useCurrentAssignment } from 'state/Student/AssignmentsState';
import { Helmet } from 'react-helmet';
import { useMedia } from 'react-use';
import BlueCheckMarkGif from 'static/images/gifs/blue-checkmark.gif';
import PartyPopperGif from 'static/images/gifs/party-popper.gif';
import { useUser, useParent, useStudent } from 'state/User/UserState';
import { useAssignmentActions } from 'state/Student/AssignmentsState';
import {
    assignmentIsOverdue,
    filterCompletedAssignments,
    filterIncompleteAssignments,
    filterPastDueAssignments,
    filterUpcommingAssignments
} from '../../../model/muzology';
import CloseButton from '../../../../components/AnswerKey/CloseButton';

// compare due dates of 2 assignments, ascending
const compareDueDatesAsc = (a, b) => {
    let last_a = moment(a.due);
    let last_b = moment(b.due);
    return last_a.diff(last_b);
};

// compare due dates of 2 assignments, descending
const compareDueDatesDes = (a, b) => {
    let last_a = moment(a.due);
    let last_b = moment(b.due);
    return last_a.diff(last_b);
};

const AssignmentsList = ({ assignments: allAssignments, path, mainAssignment, isChild }) => {

    const zeroGlobalAssignments = allAssignments.length === 0;
    const navigate = useNavigate();
    let isMainAssignmentUpcoming = mainAssignment?.progress.completed === false;
    // filter the assignments for this path
    const assignments = useMemo(() => {
        if (!allAssignments) return [];

        if (path === 'what-to-do-next') {
            // upcoming assignments
            let assignments = filterIncompleteAssignments(allAssignments);
            assignments = filterUpcommingAssignments(assignments);
            assignments = assignments.sort(compareDueDatesDes);
            if (assignments.length === 0) return [];
            // this is the assignment that is most current and should only be showed in What To Do Now
            return assignments.slice(1);
        } else if (path === 'overdue') {
            let assignments = filterIncompleteAssignments(allAssignments);
            assignments = filterPastDueAssignments(assignments);
            assignments = assignments.sort(compareDueDatesDes);
            return assignments;
        } else if (path === 'completed') {
            let assignments = filterCompletedAssignments(allAssignments);
            assignments = assignments.sort(compareDueDatesDes);
            return assignments;
        }
        // unknown
        console.error('unknown path', path);
        return [];
    }, [allAssignments, path]);
    if (!assignments) return null;

    // show null message
    if (assignments.length === 0) {
        let message = null;

        if (isChild) {
            switch (path) {
                case 'what-to-do-next':
                    message = (
                        <>
                            <div tw='text-white text-base sm:text-2xl font-medium text-center'>You don't have any {zeroGlobalAssignments ? 'shared' : 'upcoming'} playlists. Ask
                                your parent to create a Muzology playlist and share it with you! Or, check out some{' '}
                            <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}> math videos!</span></div>
                        </>
                    );
                    break;
                case 'overdue':
                    message = (
                        <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                            {zeroGlobalAssignments ?
                                <div>You don't have any shared playlists. Ask your parent to create a Muzology playlist and share it with you! Or, check out some{' '}
                                    <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}> math videos!</span></div> :
                                <div>
                                    <div tw='w-full flex justify-center '>
                                        <div tw='maxWidth[222px]'>
                                            <img src={PartyPopperGif} />
                                        </div>
                                    </div>
                                    <div>You’re right on time! None of your playlists are overdue.</div>
                                </div>
                            }
                        </div>
                    );
                    break;
                case 'completed':
                    message = (
                        <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                            {zeroGlobalAssignments ?
                                <div>You don't have any shared playlists. Ask your parent to create a Muzology playlist and share it with you! Or, check out some{' '}
                                    <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}> math videos!</span></div> :
                                <div>
                                    Keep going! Complete your{' '}
                                    <span tw='underline cursor-pointer' onClick={() => navigate('/assignments/what-to-do-now')}>
                                        playlist
                                    </span>{' '}
                                    and earn awards.
                                </div>
                            }
                        </div>
                    );
                    break;
            }
        } else switch (path) {
            case 'what-to-do-next':
                if (isMainAssignmentUpcoming) {
                    message = (
                        <>
                            <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                                You don't have any upcoming assignments, right now. Keep going on your{' '}
                                <span tw='underline cursor-pointer' onClick={() => navigate('/assignments/what-to-do-now')}>
                                    current assignment
                                </span>
                                !
                            </div>
                        </>
                    );
                } else
                    message = (
                        <>
                            <div tw='text-white text-base sm:text-2xl font-medium text-center'>You don’t have any upcoming assignments right now.</div>
                            <div tw='text-white text-base sm:text-2xl font-medium mt-6 text-center'>
                                Check out the{' '}
                                <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}>
                                    video library
                                </span>{' '}
                                and watch new music videos to get a head start.
                            </div>
                        </>
                    );
                break;
            case 'overdue':
                message = (
                    <div>
                        <div tw='w-full flex justify-center '>
                            <div tw='maxWidth[222px]'>
                                <img src={PartyPopperGif} />
                            </div>
                        </div>
                        <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                            You’re right on time! None of your assignments are overdue.
                        </div>
                    </div>
                );
                break;
            case 'completed':
                message = (
                    <div tw='text-white m-0 text-base sm:text-2xl font-medium text-center'>
                        Keep going! Complete your{' '}
                        <span tw='underline cursor-pointer' onClick={() => navigate('/assignments/what-to-do-now')}>
                            assignments
                        </span>{' '}
                        and earn awards.
                    </div>
                );
                break;
        }
        return <NullContainer>{message}</NullContainer>;
    }

    // create <AssignmentCard/> for each assignment
    return assignments.map((assignment) => {
        return <AssignmentCard
            key={assignment.id} currentTab={path} assignment={assignment} isOpen={true}
            isExpandable={false} />;
    });
};

const AssignmentsContainer = () => {
    const navigate = useNavigate();
    const user = useUser();
    const { is_parent_account: isChild } = user;
    const assignments = useAssignments();
    const { fetchAssignments } = useAssignmentActions();

    const mainAssignment = useCurrentAssignment();
    const [activeTab, setActiveTab] = useState(0);
    const [studentLearningModal, setStudentLearningModal] = useState(false);
    const query = useQuery();
    const path = isChild ? '/playlists/' : '/assignments/';
    const [overdueItems, setOverdueItems] = useState([]);
    let noAssignments = assignments.length === 0;

    // re grab assignments on load
    useEffect(() => {
        fetchAssignments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        // sort & categorize assignments
        let pastDates = [];
        let upcomingDates = [];
        let completed = [];
        if (assignments) {
            assignments.forEach((item) => {
                if (item.progress?.completed) {
                    completed.push(item);
                } else if (moment().isAfter(item.due)) {
                    pastDates.push(item);
                } else upcomingDates.push(item);
            });
        }

        // Sort each date array into new to oldest
        let sorted_pastDates = pastDates.sort((a, b) => {
            let last_a = moment(a.due);
            let last_b = moment(b.due);
            return last_b.diff(last_a);
        });

        let sorted_upcomingDates = upcomingDates.sort((a, b) => {
            let last_a = moment(a.due);
            let last_b = moment(b.due);
            return last_a.diff(last_b);
        });
        setOverdueItems(sorted_pastDates);
    }, [assignments]);

    // if an assignment_id is specified redirect to correct tab
    useEffect(() => {
        const { assignment_id } = query;
        if (assignment_id) {
            // find the assignment
            let assignment = assignments.filter((assignment) => assignment.id === assignment_id);
            if (assignment) {
                // console.log('Show assignment:');
                if (assignment.progress?.completed) {
                    // completed tab
                    navigate(`${path}completed/#${assignment_id}`, { replace: true });
                } else {
                    if (assignmentIsOverdue(assignment)) {
                        // overdue
                        navigate(`${path}overdue/#${assignment_id}`, { replace: true });
                    } else {
                        // not overdue
                        navigate(`${path}what-to-do-next/#${assignment_id}`, { replace: true });
                    }
                }
            }
        }
    }, [assignments, navigate, path, query]);

    // list to be passed down for mobile UX / replacing the buttons with a drop down
    // shorten names if under certain width
    const isTablet = useMedia('(min-width: 700px)');
    const match = useMatch(`${path}:tab_name`);
    const tab_name = match?.params.tab_name;

    // null copy for child 
    let childWhatToDoNowNullCopy = (
        <div>
            <div tw='w-full flex justify-center '>
                <div tw='maxWidth[165px]'>
                    <img src={BlueCheckMarkGif} />
                </div>
            </div>
            <div tw='text-white text-base sm:text-2xl font-medium text-center'> You’re up to date on all of your playlists.</div>
        </div>
    );
    if (noAssignments) childWhatToDoNowNullCopy =
        <div tw='text-white text-base sm:text-2xl font-medium text-center'>You don't have any shared playlists. Ask your parent to create a Muzology playlist and share it with you!
            Or, check out some <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}> math videos! </span></div>;

    const nullWhatToDoNow = (
        <NullContainer>
            <>
                {isChild ?
                    <div>
                        {childWhatToDoNowNullCopy}
                    </div> :
                    <div>
                        <div tw='w-full flex justify-center '>
                            <div tw='maxWidth[165px]'>
                                <img src={BlueCheckMarkGif} alt='Blue checkmark' />
                            </div>
                        </div>
                        <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                            Great job! You’re up to date on all of your assignments.
                        </div>
                        <div tw='text-white text-base sm:text-2xl font-medium mt-6 text-center'>
                            Now’s a good time to rewatch videos and retake Challenges from{' '}
                            <span tw='underline cursor-pointer' onClick={() => navigate('/assignments/completed')}>
                                            completed assignments
                            </span>
                            . Let’s make sure you still remember the math.
                        </div>
                    </div>}
            </>
        </NullContainer>
    );

    const items = [
        {
            name: isTablet ? 'What To Do Now' : 'Do Now',
            path: 'what-to-do-now',
            element: mainAssignment ? (
                <AssignmentCard assignment={mainAssignment} isOpen={true} isExpandable={false} />
            ) : (
                <NullContainer>
                    <>
                        {isChild ?
                            <div>
                                {childWhatToDoNowNullCopy}
                            </div> :
                            <div>
                                <div tw='w-full flex justify-center '>
                                    <div tw='maxWidth[165px]'>
                                        <img src={BlueCheckMarkGif} alt='Blue checkmark' />
                                    </div>
                                </div>
                                <div tw='text-white text-base sm:text-2xl font-medium text-center'>
                                    Great job! You’re up to date on all of your assignments.
                                </div>
                                <div tw='text-white text-base sm:text-2xl font-medium mt-6 text-center'>
                                    Now’s a good time to rewatch videos and retake Challenges from{' '}
                                    <span tw='underline cursor-pointer' onClick={() => navigate('/assignments/completed')}>
                                            completed assignments
                                    </span>
                                    . Let’s make sure you still remember the math.
                                </div>
                            </div>}
                    </>
                </NullContainer>
            )
        },
        {
            name: isTablet ? 'What To Do Next' : 'Do Next',
            path: 'what-to-do-next'
        },
        {
            name: 'Overdue',
            decorator: overdueItems?.length > 0 ? <OverDueDecorator overDueAssignments={overdueItems.length} /> : null,
            path: 'overdue'
        },
        {
            name: 'Completed',
            path: 'completed'
        }
    ];

    // assignment tab routes
    const routes = useRoutes(
        [...items.map((item) => ({
            path: item.path,
            element: item.element ?? (<AssignmentsList assignments={assignments} path={item.path} mainAssignment={mainAssignment} isChild={isChild} />)
        })), { path: '*', element: <Navigate to={`${path}${items[0].path}`} /> }]
    );

    return (
        <Container id='assignments-page'>
            <Helmet>
                <title>Assignments</title>
            </Helmet>
            <div tw='py-6'>
                <div id='assignments-container' tw='[maxWidth:var(--layout-width)] mx-auto '>
                    {/* {isChild && (
                        <div tw='flex justify-end mb-6'>
                            <Button variant='gradient-orange' onClick={() => setStudentLearningModal(true)}>
                                WHAT I WANT TO LEARN
                            </Button>
                        </div>
                    )} */}
                    <>
                        <TabNavigator activeTab={tab_name} activeTabHandler={setActiveTab} variant='enclosed' items={items} />
                        {routes}
                    </>
                </div>
            </div>
            <Modal
                isOpen={studentLearningModal}
                setIsOpen={(value) => setStudentLearningModal(value)}
                modalChildrenStyle={tw`maxWidth[740px] mx-auto`}
            >
                <div tw='relative rounded bg-white'>
                    <ChildLearningCategories handleModal={setStudentLearningModal} user={user} />
                    <CloseButton tw='absolute -top-4 -right-4' onClick={() => setStudentLearningModal(false)} />
                </div>
            </Modal>
        </Container>
    );
};

const Container = styled.div`
    background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);
    height: 100%;
    width: 100%;
`;

export default AssignmentsContainer;
