import * as React from 'react';

function SvgClassIcon14(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FFD233' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.548 27.871l-4.129-4.129c0-.344.344-1.032 1.032-2.064l-4.129-1.033 2.065-3.096 4.13 1.032c4.128-6.194 10.322-8.258 14.45-8.258 0 4.129-2.064 10.322-8.257 14.451l1.032 4.13-3.097 2.064-1.032-4.13c-1.032.69-1.72 1.033-2.065 1.033zm6.71-9.29a1.548 1.548 0 100-3.097 1.548 1.548 0 000 3.097zm-11.871 6.193l1.29 1.29c-.688.69-1.118 1.635-1.29 2.84 1.205-.173 2.15-.602 2.839-1.29l1.29 1.29c-1.376 1.375-3.44 2.064-6.193 2.064 0-2.753.688-4.818 2.064-6.194z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon14;
