import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';

import { useNavigate } from 'react-router-dom';
import Hero from 'static/heroes/Hero.jpg';
import NullHero from 'static/heroes/Hero_Null.jpg';
import NullHeroMobile from 'static/heroes/first_login_mobile.png';

import NullHeroTablet from 'static/heroes/first_login_tablet.png';
import { CurrentAssignmentCard, Modal, Button, NewVideoStudentHero, ChildLearningCategories } from 'components';
import ReactPlayer from 'react-player';
import { useUser } from 'state/User/UserState.js';
import { useFirstMountState } from 'react-use';
import VideoPlayerOverlay from '../../../../../components/VideoPlayerOverlay/VideoPlayerOverlay';
import { CHILD_TUTORIAL_VIDEO_ID, CHILD_TUTORIAL_VIDEO_URL } from '../../../../../config';

const Container = styled.div`
    flex-grow: 1;
    background-image: url(${(props) => props.backgroundImage || Hero});
    background-size: 100%;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    height: 100%;
    margin-top: 5.5px;
    background-size: cover;

    @media only screen and (max-width: 640px) {
        background: linear-gradient(180deg, #533db4 0%, #1e6db7 100%);
        margin-top: 0;
        margin-top: ${(props) => (props.firsLogin ? '65px' : '0')};
        background-image: url(${(props) => (props.firsLogin ? NullHeroTablet : '')});
        background-repeat: no-repeat;
        background-size: 100%;
        height: ${(props) => (props.firsLogin ? '90%' : '100%')};
    }

    @media only screen and (max-width: 440px) {
        margin-top: ${(props) => (props.firsLogin ? '65px' : '0')};
        background-image: url(${(props) => (props.firsLogin ? NullHeroMobile : '')});
        background-repeat: no-repeat;
        background-size: cover;
        height: ${(props) => (props.firsLogin ? '90%' : '100%')};
    }
    @media only screen and (max-width: 380px) {
        background-position-x: ${(props) => (props.firsLogin ? '-20px' : '')};
    }
`;

const CurrentAssignment = ({ assignment, isChild, setStudentLearningModal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    // if its a users first time logging in show them the first time background image and correct buttons
    const user = useUser();
    const firsLogin = user.login_count === 1;
    const recommendedVideo = !firsLogin && !assignment;
    const [playVideo, setPlayVideo] = useState(false);


    const newVideo = (
        <div tw='relative h-full w-full flex flex-col-reverse sm:flex-row justify-center px-4 lg:px-10 z-20 max-w-[1300px] mx-auto'>
            <div tw='w-full sm:w-2/5 lg:w-3/5 xl:w-full max-w-[100%] sm:max-w-[472px] mt-5 sm:mt-[2.2rem] md:mt-[3.8rem] lg:mt-[4.5rem] xl:mt-[7.5rem] py-6 sm:py-0'>

                <NewVideoStudentHero />
            </div>

            <div tw=' h-full sm:h-4/5 lg:h-full w-full maxWidth[1265px] mt-20 sm:mt-0 xl:mt-5 ml-0 sm:ml-7'>
                <div tw='hidden sm:block w-1/3 md:w-1/2' />
                <CurrentAssignmentCard assignment={assignment} recommendedVideo={recommendedVideo} isChild={isChild} />
            </div>
        </div>
    );

    const panel = (
        <div tw='relative h-full w-full block xl:flex items-center justify-center px-0 lg:px-10 z-20'>
            <div tw='flex items-start justify-center h-full sm:h-4/5 lg:h-full w-full maxWidth[1265px] mt-20 sm:mt-0 xl:mt-5'>
                <div tw='hidden sm:block w-1/3 md:w-1/2' />
                <CurrentAssignmentCard assignment={assignment} recommendedVideo={recommendedVideo} isChild={isChild} />
            </div>
        </div>
    );

    return (
        <Container backgroundImage={firsLogin ? NullHero : Hero} firsLogin={firsLogin}>
            {firsLogin ? (
                <div tw='flex flex-col xsm:flex-row items-center xsm:items-end justify-end xsm:justify-center height[90%]'>
                    <div tw='ml-1 sm:marginLeft[180px]'>
                        <VideoPlayerOverlay videoId={CHILD_TUTORIAL_VIDEO_ID} isOpen={playVideo} setIsOpen={setPlayVideo} />
                        <Button size='large' onClick={() => setPlayVideo(true)}>
                            SEE HOW IT WORKS
                        </Button>
                    </div>

                    {assignment && (
                        <div tw='mt-4 sm:mt-0 mr-1 sm:mr-0 ml-1 sm:ml-7'>
                            <Button tw='minWidth[201px]' variant='teal-large' onClick={() => navigate('/assignments/what-to-do-now')}>
                                GO TO PLAYLIST
                            </Button>
                        </div>
                    )}
                </div>
            ) : (
                <>{newVideo}</>
            )}

            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
                <div tw='h-screen w-screen max-w-3xl '>
                    <ReactPlayer
                        playing={true} url={CHILD_TUTORIAL_VIDEO_URL} width='100%' height='100%'
                        controls={true} />
                </div>
            </Modal>
        </Container>
    );
};

export default CurrentAssignment;
