import React from 'react';
import { styled } from 'twin.macro';

const Button = styled.button`
	position: relative;

	border-radius: 24px;
	height: 50px;
	min-width: 150px;

	padding: 0 40px;
	margin: 0;

	font-family: Avenir, serif;
	font-weight: 500;
	font-size: 21px;

	line-height: normal;

	color: white;

	cursor: pointer;
	user-select: none;

	display: flex;
	justify-content: center;
	align-items: center;

	// normal - pink
	background-color: ${(props) => props.backgroundColor || '#e637a8'};
	border: 4px solid ${(props) => props.backgroundColor || '#e637a8'};

	.right-image {
		float: right;
		position: absolute;
		right: 35px;
	}

	&:hover:enabled {
		background-image: none;
		background-color: #e637a8;
		border: 4px solid #91005e;
		color: white;
	}

	&:active:enabled {
		background-image: none;
		background-color: #91005e;
		border: 4px solid #91005e;
		color: white;
	}

	&:focus {
		outline: none;
	}

	// purple
	&.purple {
		background-color: #8f71ea;
		border: 4px solid #8f71ea;

		&:hover {
			background-color: #8f71ea;
			border: 4px solid #341983;
			color: white;
		}

		&:active {
			background-color: #341983;
			border: 4px solid #341983;
			color: white;
		}
	}

	// aqua
	&.aqua {
		background-color: #1abdd7;
		border: 4px solid #1abdd7;

		&:hover {
			background-color: #1abdd7;
			border: 4px solid #005865;
			color: white;
		}

		&:active {
			background-color: #005865;
			border: 4px solid #005865;
			color: white;
		}
	}

	&.disabled {
		background: #dedede;
		border: 4px solid transparent;
		color: #565757;
		cursor: not-allowed;
	}
`;

export const StyledButton = ({ content, onClick, children, styleName, style, disabled, className, backgroundColor, type }) => {
    return (
        <Button
            type={type}
            className={`${className || ''} styled-button ${disabled ? 'disabled' : ''}`}
            disabled={disabled}
            onClick={onClick}
            styleName={`styled-button ${styleName || null}`}
            style={style}
        >
            {children || content}
        </Button>
    );
};

export default StyledButton;