import React from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import RecordIcon from 'components/RecordIcon';
import moment from 'moment';
import { Achievements, Button, VideoViews, CompletedAssignments, TotalAchievements } from 'components/index';
import { useAssignments } from 'state/Student/AssignmentsState';
import { maybePlural } from '../../../../../utils/string';

const S = {
    Container: styled.section`
        width: 60%;
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        border-radius: 18px;
        padding: 24px 0px;

        @media only screen and (max-width: 1100px) {
            width: 100%;
        }
        @media only screen and (max-width: 500px) {
            border-radius: 0;
        }
    `,

    GeneralStatsContainer: styled.div`
        color: #fff;

        .row-1 {
            display: flex;
            width: 98%;

            .records-container {
                height: 151px;
                width: 50%;
                margin: 16px 8px 16px 24px;
                padding: 14px 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                background: #3b2a7e;
                border-radius: 19px;

                .records {
                    display: flex;

                    .record {
                        max-width: 75px;
                        margin: 0px 7px;
                        position: relative;

                        .records-earned-silver {
                            position: absolute;
                            font-size: 22px;
                            color: #fff;
                            right: ${(props) => (props.silver >= 10 ? '33%' : '42%')};
                            top: 33%;
                        }

                        .records-earned-gold {
                            position: absolute;
                            font-size: 22px;
                            color: #fff;
                            right: ${(props) => (props.gold >= 10 ? '33%' : '42%')};
                            top: 33%;
                        }

                        .records-earned-plat {
                            position: absolute;
                            font-size: 22px;
                            color: #fff;
                            right: ${(props) => (props.plat >= 10 ? '33%' : '42%')};
                            top: 33%;
                        }
                    }
                }

                .records-copy {
                    font-size: ${(props) => props.theme.fontSizes.small};
                    line-height: 25px;
                }
            }

            .total-records-container {
                height: 151px;
                width: 30%;
                margin: 16px 8px 16px 8px;
                padding: 14px 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                background: #3b2a7e;
                border-radius: 19px;

                .total-records-number {
                    font-size: 39px;
                }

                .total-records-copy {
                    font-size: ${(props) => props.theme.fontSizes.small};
                    line-height: 25px;
                }
            }

            .total-achievements-container {
                height: 151px;
                width: 40%;
                margin: 16px 8px 16px 8px;
                padding: 14px 0px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                background: #3b2a7e;
                border-radius: 19px;

                .total-achievements {
                    font-size: 39px;
                }

                .total-achievements-copy {
                    font-size: 18px;
                    line-height: 25px;
                }
            }

            @media only screen and (max-width: 768px) {
                flex-direction: column;
                width: 100%;
                padding: 0 10px;
                .records-container {
                    width: 100%;
                    margin: 10px 0;
                }

                .total-records-container {
                    width: 100%;
                    margin: 10px 0;
                }

                .total-achievements-container {
                    width: 100%;
                    margin: 10px 0;
                }
            }
        }

        .total-video-views-container {
            height: 125px;
            width: 94%;
            margin: 16px 24px 16px 24px;
            padding: 5px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background: #3b2a7e;
            border-radius: 19px;

            .total-video-views {
                font-size: 39px;
            }

            .total-video-views-copy {
                font-size: ${(props) => props.theme.fontSizes.small};
                line-height: 25px;
            }
        }
    `,
    AchievementsContainer: styled.div`
        color: #fff;
        padding: 0px 24px;

        .achievements {
            display: flex;
        }
    `
};

const GeneralStats = ({ achievements, videoStats }) => {
    const navigate = useNavigate();
    const assignments = useAssignments();

    // Finding Video Records levels
    // this could probably be refactored
    const stats = Object.values(videoStats);
    let silver = [];
    let gold = [];
    let plat = [];
    stats.forEach((item) => {
        if (item.current_level === 2) {
            silver.push(item);
        }
        if (item.current_level === 3) {
            silver.push(item);
            gold.push(item);
        }
        if (item.current_level === 4) {
            silver.push(item);
            gold.push(item);
            plat.push(item);
        }
    });
    silver = silver.length;
    gold = gold.length;
    plat = plat.length;
    // let totalRecordsEarned = silver + gold + plat;

    // Sort and grab first three achievements
    let sortedAchievements = achievements.sort((a, b) => moment(b.datetime_awarded).diff(moment(a.datetime_awarded)));
    sortedAchievements = sortedAchievements.slice(0, 3);

    return (
        <S.Container aria-label='General Stats'>
            <S.GeneralStatsContainer silver={silver} gold={gold} plat={plat}>
                <h2 tw='font-extrabold text-white text-2xl ml-6'>My General Stats</h2>
                <div className='row-1'>
                    <div aria-labelledby='earned-records-label' className='records-container'>
                        <div className='records'>
                            <div className='record' aria-label={`${silver} Silver ${maybePlural('Record', silver)}`}>
                                <RecordIcon level={1} />
                                <div tw='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fontSize[18px] text-white font-sans font-black'>
                                    {silver}
                                </div>
                            </div>
                            <div className='record' aria-label={`${gold} Gold ${maybePlural('Record', silver)}`}>
                                <RecordIcon level={2} />
                                <div tw='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fontSize[18px] text-white font-sans font-black'>
                                    {gold}
                                </div>
                            </div>
                            <div className='record' aria-label={`${gold} Platinum ${maybePlural('Record', plat)}`}>
                                <RecordIcon level={3} />
                                <div tw='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fontSize[18px] text-white font-sans font-black'>
                                    {plat}
                                </div>
                            </div>
                        </div>
                        <div id='earned-records-label' tw='text-lg text-white'>Earned Records</div>
                    </div>
                    <div aria-label={`${achievements?.length} Total Achievements`} className='total-records-container'>
                        <TotalAchievements achievements={achievements} />
                    </div>
                    <div aria-label='Completed Assignments' className='total-achievements-container'>
                        <CompletedAssignments assignments={assignments} />
                    </div>
                </div>
                <div tw='mx-6 hidden md:block'>
                    <VideoViews stats={Object.values(videoStats)} />
                </div>
            </S.GeneralStatsContainer>
            <S.AchievementsContainer>
                <h2 tw='font-extrabold text-white text-2xl mt-6 mb-4'>Achievements</h2>
                <Achievements achievements={sortedAchievements} />
            </S.AchievementsContainer>
            <div tw='flex items-center justify-center mt-9'>
                <Button variant='large' onClick={() => navigate('/stats')}>
                    SEE ALL STATS
                </Button>
            </div>
        </S.Container>
    );
};

export default GeneralStats;
