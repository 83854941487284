import * as React from 'react';

function SvgClassIcon27(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF57BC' />
            <path
                d='M31.967 24.466l-1.361-9.301a4.817 4.817 0 00-1.674-2.98A5.077 5.077 0 0025.66 11H14.34c-2.486 0-4.597 1.773-4.947 4.165l-1.362 9.301c-.095.643.021 1.298.33 1.873a3.14 3.14 0 001.395 1.33c.598.29 1.274.391 1.933.287a3.212 3.212 0 001.739-.867l2.823-2.732h7.495l2.811 2.732c.6.583 1.412.911 2.249.911 1.949 0 3.435-1.664 3.16-3.534zm-13.216-6.18h-2.499v2.428h-1.25v-2.428h-2.498V17.07h2.499v-2.428h1.25v2.428h2.497v1.215zm4.996-1.215c-.687 0-1.249-.546-1.249-1.214 0-.668.562-1.214 1.25-1.214.686 0 1.249.546 1.249 1.214 0 .668-.563 1.214-1.25 1.214zm2.499 3.643c-.687 0-1.25-.546-1.25-1.214 0-.668.563-1.214 1.25-1.214s1.249.546 1.249 1.214c0 .668-.562 1.214-1.25 1.214z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon27;
