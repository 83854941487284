import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import RecordImageSilver from 'static/images/records/DTC/silver.png';
import RecordImageGold from 'static/images/records/DTC/gold.png';
import RecordImagePlatinum from 'static/images/records/DTC/platinum.png';

const ProgressBar = styled.div(({ score }) => [
    tw` bg-[#02AC6F] rounded-xl text-center h-[15px] text-xs `,
    score < 100 && tw`bg-[#46A6F8] rounded-none rounded-l-lg`
]);

const VideoStatsMobileCard = ({ stats }) => {
    const navigate = useNavigate();
    const levels = stats?.stats?.quiz_levels;

    // check if stats object is empty this is the same as null
    let emptyStats = stats && Object.keys(stats).length === 0 && stats.constructor === Object;
    let silverScore = levels ? levels[1]?.score ?? 0 : 0;
    let SilverRecord = !stats || emptyStats ? RecordImageSilver : stats?.stats?.current_level < 1 ? RecordImageSilver : RecordImageSilver;
    let goldScore = levels ? levels[2]?.score ?? 0 : 0;
    let GoldRecord = !stats || emptyStats ? RecordImageGold : stats?.stats?.current_level < 2 ? RecordImageGold : RecordImageGold;
    let platScore = levels ? levels[3]?.score ?? 0 : 0;
    let PlatRecord = !stats || emptyStats ? RecordImagePlatinum : stats?.stats?.current_level < 3 ? RecordImagePlatinum : RecordImagePlatinum;
    return (
        <div tw='bg-gradient-to-b to-PholxPink from-SkyBlueCrayola px-5 pt-6 pb-4 text-white mt-8'>
            <div tw='flex'>
                <div tw='cursor-pointer flex items-center' onClick={() => navigate(`/video/${stats.id}`)}>
                    <img tw='h-16 w-24 object-cover' src={stats.url_thumbnail} alt='thumbnail' />
                </div>
                <div tw='ml-2'>
                    <span tw='inline'>{stats.title}</span>
                    <span tw='block'>My Views: {stats.stats.view_count}</span>
                </div>
            </div>
            <div tw='bg-[rgba(42, 38, 77, 0.5)] rounded-xl mt-4 py-5'>
                <div tw='mx-auto max-w-[120px]'>
                    <div>Warm Up Score</div>
                    <div tw='block text-center mt-2'>{stats.stats.quiz_levels[0]?.score ?? 0}%</div>
                </div>
                <div tw='mx-auto max-w-[180px] mt-6'>
                    <div tw='text-center'>Records Earned</div>
                    <div tw='flex space-x-2 mt-3'>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14' src={SilverRecord} alt='img' />
                            <span tw='text-xs font-black absolute text-white'>{levels ? levels[1]?.score ?? '' : ''}</span>
                            {emptyStats && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                            {stats?.stats?.current_level < 1 && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14' src={GoldRecord} alt='img' />
                            <span tw='text-xs font-black absolute text-white'>{levels ? levels[2]?.score ?? '' : ''}</span>

                            {emptyStats && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                            {stats?.stats?.current_level < 2 && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14' src={PlatRecord} alt='img' />
                            <span tw='text-xs font-black absolute text-white'>{levels ? levels[3]?.score ?? '' : ''}</span>

                            {emptyStats && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                            {stats?.stats?.current_level < 3 && (
                                <div tw='absolute bg-gray-600 bg-opacity-50 w-14 h-14 rounded-full flex justify-center items-center'>
                                    <svg
                                        width='25' height='30' viewBox='0 0 25 30' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                            fill='#2A295E'
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div tw='mx-auto max-w-[200px] mt-6'>
                    <div tw='text-center'>Attempts</div>
                    <div tw='flex justify-center space-x-2 mt-2 '>
                        <div tw='flex space-x-2 items-center'>
                            <span>{levels ? levels[1]?.attempts ?? 0 : 0}</span>
                            <img tw='w-5 h-5' src={RecordImageSilver} alt='img' />
                        </div>
                        <div tw='flex space-x-2 items-center'>
                            <span>{levels ? levels[2]?.attempts ?? 0 : 0}</span>
                            <img tw='w-5 h-5' src={RecordImageGold} alt='img' />
                        </div>
                        <div tw='flex space-x-2 items-center'>
                            <span>{levels ? levels[3]?.attempts ?? 0 : 0}</span>
                            <img tw='w-5 h-5' src={RecordImagePlatinum} alt='img' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

VideoStatsMobileCard.propTypes = {
    anyProp: PropTypes.any
};

export default VideoStatsMobileCard;
