import React from 'react';
import 'twin.macro';

const CancelButton = ({ onClick }) => {
    return (
        <button tw='absolute top-1.5 sm:top-[12px] right-1.5 sm:right-[15px] z-[10000000] cursor-pointer' onClick={onClick}>
            <svg xmlns='http://www.w3.org/2000/svg' tw=' w-6 h-6 sm:w-8 sm:h-8  lg:w-12 lg:h-12' viewBox='0 0 48 48' fill='none'>
                <g clip-path='url(#clip0_3019_79126)'>
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M23.9999 0C37.2548 0 48 10.7452 48 23.9999C48 37.2548 37.2548 48 23.9999 48C10.7452 48 0 37.2548 0 23.9999C0 10.7452 10.7452 0 23.9999 0ZM23.9999 23.4071C24.3275 23.4071 24.5929 23.6725 24.5929 23.9999C24.5929 24.3275 24.3275 24.5929 23.9999 24.5929C23.6725 24.5929 23.4071 24.3275 23.4071 23.9999C23.4071 23.6725 23.6725 23.4071 23.9999 23.4071Z' fill='#D6D4D4' />
                    <path fill-rule='evenodd' clip-rule='evenodd' d='M23.998 0.837891C36.7901 0.837891 47.1602 11.2079 47.1602 23.9999C47.1602 36.792 36.7901 47.1621 23.998 47.1621C11.2059 47.1621 0.835938 36.792 0.835938 23.9999C0.835938 11.2079 11.2059 0.837891 23.998 0.837891ZM23.998 23.4071C24.3255 23.4071 24.591 23.6726 24.591 23.9999C24.591 24.3275 24.3255 24.5929 23.998 24.5929C23.6706 24.5929 23.4051 24.3275 23.4051 23.9999C23.4051 23.6726 23.6706 23.4071 23.998 23.4071Z' fill='url(#paint0_angular_3019_79126)' />
                    <circle cx='24' cy='24' r='1' fill='#B6A9F0' />
                    <path d='M13 36L35 14' stroke='white' strokeWidth='4' strokeLinecap='round' />
                    <g filter='url(#filter0_d_3019_79126)'>
                        <path d='M13 14L35 36' stroke='white' strokeWidth='4' strokeLinecap='round' />
                    </g>
                </g>
                <defs>
                    <filter
                        id='filter0_d_3019_79126' x='7' y='12' width='34' height='34' filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'>
                        <feFlood flood-opacity='0' result='BackgroundImageFix' />
                        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                        <feOffset dy='4' />
                        <feGaussianBlur stdDeviation='2' />
                        <feComposite in2='hardAlpha' operator='out' />
                        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3019_79126' />
                        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3019_79126' result='shape' />
                    </filter>
                    <radialGradient id='paint0_angular_3019_79126' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(23.9981 24) rotate(114.401) scale(23.683)'>
                        <stop offset='0.255208' stop-color='#C2B5FF' />
                        <stop offset='0.790282' stop-color='#3B20B8' />
                    </radialGradient>
                    <clipPath id='clip0_3019_79126'>
                        <rect width='48' height='48' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </button>
    );
};

export default CancelButton;