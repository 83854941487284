import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { Modal } from 'components';
import {
    ONBOARD_STAGE_AGREE_TO_TERMS,
    ONBOARD_STAGE_INTRO,
    ONBOARD_STAGE_SETUP_CHILD,
    ONBOARD_STAGE_SETUP_TUTORIAL_VIDEO,
    ONBOARD_STAGE_SUBSCRIPTION_CANCELLED,
    ONBOARD_STAGE_TRIAL_EXPIRED,
    ONBOARD_STAGE_TRIAL_EXPIRING_SOON,
    ONBOARD_STAGE_VERIFY,
    ONBOARD_REGISTRATION_REQUIRED
} from 'app/AccountOnboard/OnboardStages';
import { useOnboardStageFinished } from 'state/OnboardState';
import CancelSubscriptionPopUp from '../CancelSubscriptionPopUp/CancelSubscriptionPopUp';
import EndTrialDialog from '../EndTrailDialog/EndTrialDialog';
import AgreeToTerms from './Stages/AgreeToTerms';
import CreatChildAccount from './Stages/CreatChildAccount';
import CreateMathPlaylist from './Stages/CreateMathPlaylist';
import Intro from './Stages/Intro';
import { TutorialVideoPlayer } from './Stages/TutorialVideoPlayer';
import { CreateChildProvider } from './Stages/CreateChildProvider';

const OnBoarding = ({ stage, setOnboardState }) => {
    const [isOpen, setIsOpen] = useState(false);
    const stageFinished = useOnboardStageFinished();
    const [childrenStyle, setChildrenStyle] = useState(tw`w-full max-w-[820px] mx-auto`);

    // console.log('🚀 ~ OnBoarding ~ childrenStyle:', childrenStyle);
    useEffect(() => {
        if (stage === ONBOARD_STAGE_AGREE_TO_TERMS) setChildrenStyle(tw`w-full max-w-[768px] lg:max-w-[1100px] xl:max-w-[1200px] mx-auto`);
        if (stage === ONBOARD_REGISTRATION_REQUIRED) setChildrenStyle(tw`w-full max-w-[380px] sm:max-w-[768px] lg:max-w-[1100px] xl:max-w-[1200px] mx-auto`);
        // else setChildrenStyle(tw`w-full max-w-[820px] mx-auto`);
    }, [stage]);

    const renderCurrentStage = () => {
        /* this ensures the stages appear in the correct order */
        switch (stage) {
            case ONBOARD_STAGE_INTRO:
            case ONBOARD_STAGE_VERIFY:
                return (
                    <CreateChildProvider>
                        <div tw='mx-4 relative'>
                            <div tw='bg-white p-6 rounded-xl'>
                                <Intro />
                            </div>
                        </div>
                    </CreateChildProvider>
                );

            case ONBOARD_STAGE_AGREE_TO_TERMS:
                // agree to terms and set name
                // return (
                //     <div tw='mx-4 relative'>
                //         <div tw='bg-white p-6 rounded-xl'>
                //             <AgreeToTerms />
                //         </div>
                //     </div>
                // );
                return (
                    <CreateMathPlaylist setOnboardState={setOnboardState} isOpen={isOpen} teacher={true} />
                );

            case ONBOARD_STAGE_SETUP_CHILD:
                // add a child account
                return (
                    <CreateChildProvider>
                        <div tw='mx-4 relative'>
                            <div tw='bg-white p-6 rounded-xl'>
                                <CreatChildAccount />
                            </div>
                        </div>
                    </CreateChildProvider>
                );

            case ONBOARD_REGISTRATION_REQUIRED:
            // select grade level and math level
                return (
                    <CreateMathPlaylist setOnboardState={setOnboardState} isOpen={isOpen} />
                );

            case ONBOARD_STAGE_SETUP_TUTORIAL_VIDEO:
                // setup teacher
                return (
                    <TutorialVideoPlayer stageFinished={stageFinished} />
                );

                // case ONBOARD_STAGE_SUBSCRIPTION_CANCELLED:
                //     // the subscription has been cancelled
                //     return (
                //         <CancelSubscriptionPopUp />
                //     );

            case ONBOARD_STAGE_TRIAL_EXPIRING_SOON:
            case ONBOARD_STAGE_TRIAL_EXPIRED:
                // the trial has expired
                return (
                    <EndTrialDialog setOnboardState={setOnboardState} show={true} />
                );

            default:
                console.log('Unknown stage: ', stage);
                return <div>Unknown Stage: {stage}</div>;
        }
    };

    if (!stage) {
        return null;
    }

    return (
        <Modal isOpen={true} setIsOpen={null} fakeSetIsOpen={setIsOpen} modalChildrenStyle={childrenStyle}>

            {renderCurrentStage()}

        </Modal>
    );
};

export default OnBoarding;
