import * as React from 'react';

function SvgClassIcon26(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#33FFCE' />
            <path
                d='M30.822 27.964l-2.937-2.933V13.748l-1.387-2.563a1.209 1.209 0 00-1.068-.648 1.201 1.201 0 00-1.067.648l-1.302 2.57v6.453L12.03 9.179A.602.602 0 0011 9.61v4.897h1.309v.97H11v3.877h1.309v.97H11v4.03h1.309v.969H11v3.072a.607.607 0 00.603.606h18.79a.601.601 0 00.56-.374.608.608 0 00-.131-.662zM15.824 24.2v-5.49l5.47 5.49h-5.47zm10.855 1.278h-2.412v-1.472h2.412v1.472zm0-2.423h-2.412V14.05l1.17-2.284 1.242 2.29v8.999z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon26;
