import React from 'react';
import { css, styled } from 'twin.macro';

const Container = styled.div`
    background-image: url(${(props) => props.$image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;

const AnimationContainer = styled.div`
    background-image: url(${(props) => props.$image});
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
`;
const BackgroundImage = ({ children, image, animate, ...props }) => {
    return (
        <Container
            className='background-image'
            $image={image}
            {...props}

        >
            {animate && <AnimationContainer />}
            {children}
        </Container>
    );
};

export default BackgroundImage;
