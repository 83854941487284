import { fromJS } from 'immutable';
// quiz session state
import {
    INIT_QUIZ_CONTEXT,
    INIT_QUIZ_SESSION,
    QUIZ_SESSION_READY,
    QUIZ_STATE_CANCELLED,
    QUIZ_STATE_CREATE_SESSION,
    QUIZ_STATE_INIT,
    QUIZ_STATE_READY,
    QUIZ_STATE_RUNNING,
    SET_SESSION_STATE,
    SETUP_QUIZ_PROGRESS
} from './constants.js';

import { getQuizID, getQuizSession } from './selectors.js';
import { beginQuizQuestion } from './context';
import { fetchInstance, fetchQuiz, postQuizSession } from './api';

// Identifiers

const QUIZ_SESSION_ERROR = 'muzology/quiz_session/QUIZ_SESSION_ERROR';

const SET_QUIZ_PARAMS = 'muzology/quiz_session/SET_QUIZ_PARAMS';
// const SET_QUIZ_PROGRESS = 'muzology/quiz_session/SET_QUIZ_PROGRESS';

const BEGIN_QUIZ_SESSION = 'muzology/quiz_session/BEGIN_QUIZ_SESSION';
const CANCEL_QUIZ_SESSION = 'muzology/quiz_session/CANCEL_QUIZ_SESSION';
const END_QUIZ_SESSION = 'muzology/quiz_session/END_QUIZ_SESSION';

const SUBMIT_QUIZ_ANSWER = 'muzology/quiz_session/SUBMIT_QUIZ_ANSWER';
const SUBMIT_QUIZ_QUESTION = 'muzology/quiz_session/SUBMIT_QUIZ_QUESTION';

const CREATE_QUIZ_SESSION = 'muzology/quiz_session/CREATE_QUIZ_SESSION';
const CREATE_QUIZ_SESSION_SUCCESS = 'muzology/quiz_session/CREATE_QUIZ_SESSION_SUCCESS';
const CREATE_QUIZ_SESSION_FAILURE = 'muzology/quiz_session/CREATE_QUIZ_SESSION_FAILURE';


// Actions

/**
 initQuizSession(quiz_id, level) setup the quiz session
    - initializes the session state
    - ensures the quiz is loaded
    - Loads a quiz Instance
    - dispatch QUIZ_SESSION_READY upon completion
    - returns the quiz instance
 */

export function initQuizSession(quiz_id, level = null) {
    console.log('initQuizSession(', quiz_id, level, ')');
    // will asynchronously load the quiz and then initialize the session
    return async (dispatch, getState) => {
        console.log('[initQuizSession] dispatch INIT_QUIZ_SESSION', quiz_id, level);

        // initialize the quiz session data
        await dispatch({ type: INIT_QUIZ_SESSION, quiz_id, level });
        // this will set the following session state:
        // session = {
        //     state: QUIZ_STATE_INIT,
        //     quiz_id: action.quiz_id,
        //     level: action.level,
        //     instance: null,
        //     context: null
        // };

        // see if the quiz definition is already loaded, otherwise load it
        const quiz = await fetchQuiz(quiz_id, getState(), dispatch);
        console.log('[initQuizSession] quiz:', quiz, level);
        // let quiz = getQuiz(getState(), quiz_id);
        // if (!quiz) {
        //     // quiz not loaded - fetch the quiz and wait for it to return
        //     // TODO: check for a loading state on this quiz, it may be currently en route
        //     const response = await dispatch(fetchQuizDefinition(quiz_id));
        //     // TODO: check response for failure
        //     // the quiz is loaded
        //     quiz = response.payload;
        // }

        // fetch current instance
        const instance = await fetchInstance(quiz_id, level);
        console.log('[initQuizSession], instance:', instance);

        // the quiz session is initialized
        return await dispatch({ type: QUIZ_SESSION_READY, payload: { quiz_id, quiz, level, instance } });
    };
}

function createQuizSession(quiz_id, level = null) {
    return async (dispatch) => {
        console.log('Create quiz session\n    quiz: ', quiz_id, '\n    level:', level);

        // call the api
        try {
            await dispatch({ type: CREATE_QUIZ_SESSION });
            // await dispatch({ type: SET_SESSION_STATE, state: QUIZ_STATE_CREATE_SESSION });
            const data = await postQuizSession(quiz_id, level);
            await dispatch({ type: CREATE_QUIZ_SESSION_SUCCESS, payload: data });
            return data;
        } catch (err) {
            console.error(err);
            await dispatch({ type: CREATE_QUIZ_SESSION_FAILURE });
            return null;
        }
    };
}

/**
 beginQuizSession() begin taking the quiz
 Loads a Quiz Instance
 */
export function beginQuizSession(quizConfig, level = null) {
    return async (dispatch, getState) => {

        // get the session state
        const session = getQuizSession(getState());
        const session_state = session.get('state');
        console.log('beginQuizSession(', quizConfig, ')');

        // check the current session state
        if (session_state !== QUIZ_STATE_READY) {
            console.error('beginQuizSession, in invalid session state: ', session_state);
            return dispatch({ type: QUIZ_SESSION_ERROR, error: 'Invalid state' });
        }

        // check the quiz is loaded
        const quiz_id = getQuizID(getState());
        if (!quiz_id) {
            // dispatch an error
            console.error('beginQuizSession, no quiz_id');
            return;
        }

        // TODO: check for error

        // create the quiz session
        // this posts to the api and returns the instance
        // console.log('Create session response:', session_response);
        // TODO: check response for an error
        const instance = await dispatch(createQuizSession(quiz_id, level));
        // console.log('QuizResults:', instance);
        // console.log('QuizInstance:', instance?.instance);

        // begin running the session:
        // - loads the quiz instance
        // - sets the first question to #1
        // - initializes the context to the first question
        // - sets the session state to QUIZ_STATE_RUNNING
        await dispatch({ type: BEGIN_QUIZ_SESSION, payload: { instance } });

        // initialize the context:
        // - determines the first question to show based on existing responses
        // - setup the progress display
        // - sets the context state to CONTEXT_STATE_INITIALIZED
        await dispatch({ type: INIT_QUIZ_CONTEXT, payload: { instance, quizConfig } });
        await dispatch({ type: SETUP_QUIZ_PROGRESS });

        // begin the question
        // - sets the question state to CONTEXT_STATE_WAITING_FOR_USER
        await dispatch(beginQuizQuestion());

        // quiz session is running
        await dispatch({ type: SET_SESSION_STATE, state: QUIZ_STATE_RUNNING });
    };
}

export function endQuizSession() {
    return { type: END_QUIZ_SESSION };
}

// export function cancelQuizSession() {
//     return { type: CANCEL_QUIZ_SESSION };
// }
//
// export function setQuizParams(showFeedback, showResults, maxAttempts, showTrivia, showScore = false, disableReset = false) {
//     return function (dispatch) {
//         const params = {
//             showFeedback,
//             showResults,
//             maxAttempts,
//             showTrivia,
//             showScore,
//             disableReset
//         };
//         // console.log("setQuizParams: ", params);
//         dispatch({ type: SET_QUIZ_PARAMS, params });
//     };
// }


// change the current session state
const setSessionState = (state, session_state) => {
    return state.mergeIn(['quiz', 'session'], fromJS({ state: session_state }));
};

// Quiz Session Reducer

export default function quizSessionReducer(state = [], action = {}) {
    let context = {};
    let session = {};
    switch (action.type) {
        case SET_SESSION_STATE:
            console.debug('[quiz] session_state:', action.state);
            return state.setIn(['quiz', 'session', 'state'], action.state);

        case SET_QUIZ_PARAMS:
            return state.setIn(['quiz', 'session', 'params'], fromJS(action.params));

        case CREATE_QUIZ_SESSION:
            return state.setIn(['quiz', 'session', 'state'], QUIZ_STATE_CREATE_SESSION);

        case INIT_QUIZ_SESSION:
            // console.log('INIT_QUIZ_SESSION');
            // construct a session state
            session = {
                state: QUIZ_STATE_INIT,
                quiz_id: action.quiz_id,
                level: action.level,
                instance: null,
                context: null
            };
            return state.setIn(['quiz', 'session'], fromJS(session));

        case QUIZ_SESSION_READY:
            // console.log('QUIZ_SESSION_READY');
            // construct a session state
            session = {
                quiz_id: action.payload.quiz_id,
                quiz: action.payload.quiz,
                level: action.payload.level,
                instance: action.payload.instance,
                state: QUIZ_STATE_READY
            };
            return state.mergeIn(['quiz', 'session'], fromJS(session));

        case BEGIN_QUIZ_SESSION: {
            // get the instance (QuizResult) from the action payload
            const instance = action.payload.instance;
            if (!instance) {
                // this is an error
                console.error('Error: BEGIN_QUIZ_SESSION called with no quiz instance');
                return state;
            }

            // get the questions from the instance (QuizInstance)
            const questions = instance.instance?.questions;
            if (!questions) {
                // this is an error
                console.error('Error: BEGIN_QUIZ_SESSION called with no questions');
                return state;
            }

            // let questions = getQuizInstanceQuestions(state);
            // console.log( questions.toJS() );
            // TODO: build this
            // let progress = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            // set progress
            // build the context - default to question 1
            // context = {
            //     question_index: 0,
            //     questions: questions,
            //     question: questions && questions.length ? questions[0] : null,
            //
            //     // previous responses
            //     responses: [],
            //     response: null,
            //
            //     waiting: true
            // };

            // construct a session context
            session = {
                state: QUIZ_STATE_RUNNING,
                instance: instance,
                context: {
                    question_index: 0,
                    questions: questions,
                    question: questions?.length ? questions[0] : null,
                    // setup responses
                    responses: [],
                    response: null,
                    waiting: true
                }
            };
            // console.log('BEGIN_QUIZ_SESSION:', session);

            // merge the session context into the state
            return state.mergeIn(['quiz', 'session'], fromJS(session));
        }

        case CANCEL_QUIZ_SESSION:
            return setSessionState(state, QUIZ_STATE_CANCELLED);

        case SUBMIT_QUIZ_ANSWER:
            return state;

        case SUBMIT_QUIZ_QUESTION:
            return state;

        case END_QUIZ_SESSION:
            // delete the session at the end
            return state.deleteIn(['quiz', 'session']);

        default:
            return state;
    }
}
