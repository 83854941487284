import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import useAxios from 'axios-hooks';
import { useVideos } from 'state/CatalogState';
import VideoStatsProvider, { useVideosWithStatsMap } from '../Student/VideoStats';
import { useDevTool } from '../../lib/DevTool/DevTool';
import { useActivity } from '../../context/LoadingContext';
import ClassState from './ClassState';

export const ACTION = 'ACTION';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();
const Context = React.createContext();

export const SET_PLAYLISTS = 'SET_PLAYLISTS';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const DELETE_PLAYLIST = 'DELETE_PLAYLIST';
export const UPDATE_PLAYLIST = 'UPDATE_PLAYLIST';
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';

const initialState = {
    playlists: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case SET_PLAYLISTS:
            return { ...state, playlists: action.payload };
        case ADD_PLAYLIST:
            return { ...state, playlists: [...state.playlists, action.payload] };
        case DELETE_PLAYLIST:
            return { ...state, playlists: state.playlists.filter((playlist) => playlist.id !== action.payload) };
        case UPDATE_PLAYLIST:
            return { ...state, playlists: [...state.playlists.filter((playlist) => playlist.id !== action.payload.id), action.payload] };
        case ADD_ASSIGNMENT: {
            const playlist = state.playlists.find((playlist) => playlist.id === action.payload.video_list_id);
            if (!playlist) {
                console.error('Error: ADD_ASSIGNMENT unable to find playlist:', action.payload.video_list_id);
                return state;
            }
            const updated = {
                ...playlist,
                assignments: [...playlist.assignments?.filter((assignment) => assignment.id !== action.payload.id), action.payload]
            };
            return {
                ...state,
                playlists: [...state.playlists.filter((playlist) => playlist.id !== action.payload.video_list_id), updated]
            };
        }
        case DELETE_ASSIGNMENT: {
            return {
                ...state,
                playlists: state.playlists.map((playlist) => ({ ...playlist, assignments: playlist.assignments.filter((a) => a.id !== action.payload) }))
            };
        }
        default:
            return state;
    }
};

const TeacherProvider = (props) => {
    const [store, dispatch] = useReducer(reducer, initialState, undefined);
    const [endActivity, addActivity] = useActivity(0);

    useDevTool('TeacherProvider', store);


    // load initial data
    const [{ loading }, getPlaylists] = useAxios({ url: '/api/assignment_playlists/', method: 'GET' }, { manual: true });
    useEffect(() => {
        // console.debug('[activity] addActivity: assignment_playlists');
        addActivity();
        getPlaylists()
            .then(({ data }) => {
                // console.debug('[activity] endActivity: assignment_playlists');
                endActivity();
                // console.log('fetched playlists', data);
                dispatch({ type: SET_PLAYLISTS, payload: data });
            })
            .catch((err) => {
                console.error(err);
                endActivity();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addAssignment = React.useCallback((assignment) => {
        dispatch({ type: ADD_ASSIGNMENT, payload: assignment });
    }, []);

    const deleteAssignment = React.useCallback((assignment_id) => {
        dispatch({ type: DELETE_ASSIGNMENT, payload: assignment_id });
    }, []);

    const fetchAssignments = React.useCallback(() => {
        return getPlaylists()
            .then(({ data }) => {
                console.log('[TeacherProvider] fetchAssignments: fetched playlists', data);
                dispatch({ type: SET_PLAYLISTS, payload: data });
            })
            .catch((err) => {
                console.error(err);
            });
    }, [getPlaylists]);

    // delete a playlist
    const [{ loading: deleting }, deletePlaylistCall] = useAxios({ url: '/api/assignment_playlists/', method: 'DELETE' }, { manual: true });
    const deletePlaylist = React.useCallback(
        (playlistId) => {
            // delete the playlist from the server
            return deletePlaylistCall({ url: `/api/assignment_playlists/${playlistId}/` })
                .then((response) => {
                    dispatch({ type: DELETE_PLAYLIST, payload: playlistId });
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        [deletePlaylistCall]
    );

    // const { playlists, playlistsError } = useSWR('/api/assignment_playlists/', apiFetcher, {
    //     onSuccess: (data) => {
    //         console.log('fetched playlists', data);
    //         dispatch({ type: SET_PLAYLISTS, payload: data });
    //     }
    // });

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                <Context.Provider value={{ addAssignment, deleteAssignment, deletePlaylist, fetchAssignments }} displayName='TeacherContext'>
                    <ClassState>
                        {props.children}
                    </ClassState>
                </Context.Provider>
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );

    /*
    const performAction = (arg) => {
        dispatch({ type: ACTION, payload: arg });
        // .then(r => {
        //     console.log(r);
        // });
    };

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                {props.children}
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
     */
};

export function usePlaylists() {
    const store = useContext(StoreContext);
    if (store === undefined) {
        // throw new Error('usePlaylists must be used within an TeacherProvider');
    }
    return useMemo(() => store?.playlists ?? null, [store]);
}

export function useTeacherDispatch() {
    const context = useContext(DispatchContext);
    if (context === undefined) {
        // console.error('usePlaylistDispatch must be used within an TeacherProvider');
        // throw new Error('usePlaylistDispatch must be used within an TeacherProvider');
    }
    return context;
}

export function usePlaylistActions() {
    const context = useContext(Context);
    if (context === undefined) {
        console.error('usePlaylistActions must be used within an TeacherProvider');
        // throw new Error('usePlaylistDispatch must be used within an TeacherProvider');
        return {};
    }
    return context;
}

export function usePlaylistAssignWizardDispatch() {
    const context = useContext(DispatchContext);
    if (context === undefined) {
        console.error('usePlaylistAssignWizardDispatch must be used within an TeacherProvider');
        // throw new Error('usePlaylistDispatch must be used within an TeacherProvider');
    }
    return context;
}

export function useTeacherAssignments() {
    const playlists = usePlaylists();
    const videos = useVideos();
    // console.log('vids', videos);
    const assignments = playlists.reduce((all, playlist) => {
        return [
            ...all,
            ...playlist.assignments.map((a) => ({
                ...a,
                name: playlist.name,
                videos: a.items?.reduce((items, video_id) => {
                    const video = videos[video_id];
                    if (video) {
                        return [...items, video];
                    }
                    return items;
                }, [])
            }))
        ];
    }, []);

    //     const reduceVideoStats = (videos, stats) => {
    //         return videos.reduce((all, video) => {
    //             if (!video) return all;
    //             const video_id = video.get('id');
    //             return all.set(video_id, video.set('stats', stats.get(video_id)));
    //         }, Map());
    //     };
    //
    // // get all video as a js object with stats attached
    //     export const getVideosAndStatsSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
    //         return reduceVideoStats(videos, stats).toJS();
    //     });
    //
    //     export const getVideosAndStatsListSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
    //         return reduceVideoStats(videos, stats).toList().toJS();
    //     });
    return assignments;
}

export function useTeacherAssignedVideos() {
    const playlists = usePlaylists();
    const videos = useVideosWithStatsMap();

    return useMemo(() => {
        const assignedVideos = playlists.reduce((all, playlist) => {
            if (playlist.assignments?.length > 0) {
                // this playlist has assignments
                // console.log('playlist', playlist);
                const vids = playlist.videos
                    ?.map((item_id) => videos[item_id])
                    .filter((video) => video !== undefined)
                    .reduce((v, video) => ({ ...v, [video.id]: video }), {});
                return { ...all, ...vids };
            }
            return all;
        }, {});
        return Object.values(assignedVideos);
    }, [playlists, videos]);
}

export default TeacherProvider;
