import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Modal from 'components/Modal/Modal';
import { apiClient, handleAPIError } from 'utils/api-client';
import BalanceText from 'react-balance-text';
import CloseButton from '../AnswerKey/CloseButton';


const NewCategoryPopup = ({ showModal, setShowModal }) => {
    const handleClose = () => {
        return apiClient
            .delete('/api/announcements/new_categories_message/')
            .then(() => {
                setShowModal(false);
            })
            .catch((err) => {
                console.error(err);
                handleAPIError(err);
            });
    };
    return (
        <Modal isOpen={showModal} modalChildrenStyle={tw`maxWidth[729px] w-full mx-[20px] sm:mx-auto`}>
            <div tw='relative bg-white rounded flex-col justify-center font-sans'>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 px-6 text-center text-white text-2xl min-h-[40px] rounded-t'>
                    <BalanceText>
                        Find Videos by Grade Level!
                    </BalanceText>
                </div>
                <div tw='text-center text-lg text-DarkLiver px-4 sm:px-10 py-10 mx-auto max-w-[650px]'>
                    <BalanceText>
                        Now you can access videos on the "Videos" page by grade level and by category.
                    </BalanceText>
                </div>
                <CloseButton tw='absolute -top-5 -right-5' onClick={() => handleClose()} />
            </div>
        </Modal>
    );
};

NewCategoryPopup.propTypes = {
    anyProp: PropTypes.any
};

export default NewCategoryPopup;

