// if true the correct answer is indicated when taking a quiz
export const QUIZ_SHOW_CORRECT_ANSWER = process.env.REACT_APP_QUIZ_SHOW_CORRECT_ANSWER === 'true';

// if true the quiz runs through each instance once and then completes
export const QUIZ_MODE_RESEARCH = false;

// if true the quiz questions contain audio buttons that can be used to play a clip of the question
export const QUIZ_SHOW_AUDIO_CLIPS = true;

// a map of features
export default {
    QUIZ_SHOW_CORRECT_ANSWER,
    QUIZ_MODE_RESEARCH,
    QUIZ_SHOW_AUDIO_CLIPS
};
