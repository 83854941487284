import React from 'react';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';
import Slider from 'react-slick/lib';
import AchievementConstants from './DummyAchievementConstants';

// Custom button styles
const SlickButtonStyle = css`
    &&& {
        position: absolute;
        width: 55px;
        height: 55px;
        @media only screen and (max-width: 1024px) {
            width: 45px;
            height: 45px;
        }
        @media only screen and (max-width: 414px) {
            width: 33px;
            height: 33px;
        }
        // center it within its parent...
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        &.next {
            left: 95%;
            right: 0px;
        }
        &.prev {
            right: 95%;
        }
        &.disabled {
            display: none;
        }
        &:active {
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }
`;

const WrapperStyle = styled.div`
    .slick-slider {
        padding: 0px 25px 0px 70px;
        .slick-slide {
        }
    }
`;

function NextArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'next disabled';
    else className = 'next';
    return (
        <div
            onClick={onClick}
            className={className}
            css={[SlickButtonStyle]}>
            <svg
                width='50'
                height='50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M18.75 37.5L31.25 25l-12.5-12.5'
                    stroke='#fff'
                    strokeWidth='4.167'
                    strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

function PrevArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'prev disabled';
    else className = 'prev';
    return (
        <div
            onClick={onClick}
            className={className}
            css={[SlickButtonStyle]}>
            <svg
                width='50'
                height='50'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M31.25 37.5L18.75 25l12.5-12.5'
                    stroke='#fff'
                    strokeWidth='4.167'
                    strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

const AchievementSlider = ({ achievements }) => {
    let dragging = false;

    // if a type of achievement has been achieved multiple times reduce down
    let reducedAchievements = achievements.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue['badge_type']] = result[currentValue['badge_type']] || []).push(currentValue);
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {});

    achievements = Object.values(reducedAchievements).map((achievement) => {
        // create counter if dupes
        let duplicatedAchievement = achievement.length > 1 ? achievement.length : null;
        const config = AchievementConstants[achievement[0].badge_type];
        return (
            <div tw='relative'>
                <div
                    tw='relative'
                    style={{ maxWidth: '107px' }}>
                    <div>{config.image}</div>
                    {duplicatedAchievement && (
                        <div tw='bg-white rounded-full text-2xl font-extrabold text-PinkCrayola absolute top-0 right-0 w-9 h-9 flex items-center justify-center'>
                            {duplicatedAchievement}
                        </div>
                    )}
                </div>
            </div>
        );
    });

    // default settings
    let settings = {
        dots: false,
        infinite: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        initialSlide: 0,
        slidesToShow: 7,
        slidesToScroll: 2,
        beforeChange: () => (dragging = true),
        afterChange: () => (dragging = false),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <WrapperStyle>
            <div
                tw='p-5 rounded-lg'
                style={{ backgroundColor: 'rgba(124, 110, 244, 0.5' }}>
                <Slider {...settings}>{achievements}</Slider>
            </div>
        </WrapperStyle>
    );
};

AchievementSlider.propTypes = {
    anyProp: PropTypes.any
};

export default AchievementSlider;
