import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import { showError } from '../../../../../AccountOnboard/Components/Components';
import 'twin.macro';
import { changeStudentPassword } from '../../../../../../state/Teacher/StudentSettings';
import ParentSettingsModal from './ParentSettingsModal';

const ResetStudentPassword = ({ selectedChild, setIsOpen, isOpen, addToast }) => {
    // form hook
    const { register, handleSubmit, watch, errors: formErrors, setError } = useForm({});
    // get a reference to the password for validation
    const password = useRef({});
    const currentPassword = useRef({});
    password.current = watch('password', '');
    currentPassword.current = watch('current_password', '');
    let studentId = selectedChild?.user.id;
    const onSubmitPasswordChange = async (data) => {
        if (studentId) {
            changeStudentPassword(studentId, data.confirmPassword, addToast);
        }
        setIsOpen(false);
    };

    return (
        <ParentSettingsModal isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
            <div tw='mx-4 sm:mx-10 relative py-10'>
                <div tw='bg-[#4D3095] rounded-xl mb-12'>
                    <div tw=' mb-2 font-medium text-lg text-white text-center sm:text-left'>Reset {selectedChild?.user.first_name}'s Password</div>
                    <div tw='text-[#B5AAD0] pl-1 mt-3'>This is the password your child will use to log in to Muzology.</div>
                    <form onSubmit={handleSubmit(onSubmitPasswordChange)} autoComplete='off' id='settings'>
                        <React.Fragment>
                            <div tw='mt-5 mb-2 font-medium  text-white'>New Password</div>
                            <input
                                tw='bg-[#4D3095] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                                type='password'
                                name='password'
                                ref={register({
                                    required: true,
                                    minLength: {
                                        value: 6,
                                        message: 'Password must have at least 6 characters'
                                    }
                                })}
                            />
                            {showError(formErrors.password)}
                            <div tw='mt-5 mb-2 font-medium text-white'>Confirm New Password</div>
                            <input
                                tw='bg-[#4D3095] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                                name='confirmPassword'
                                type='password'
                                ref={register({
                                    validate: (value) => value === password.current || 'The passwords do not match'
                                })}
                            />
                            {showError(formErrors.confirmPassword)}
                        </React.Fragment>
                        <div tw=' w-max mt-6'>
                            <Button type='submit' tw='bg-[#FEEA50] z-60 border-[#404040] border-[1px] rounded-md text-[#404040] font-Poppins font-medium'>
                                Save Changes
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ParentSettingsModal>
    );
};

export default ResetStudentPassword;
