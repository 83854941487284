import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';

const VideoViews = ({ stats }) => {
    // calculate view stats
    const { viewCount, averageViews, completionPercentage } = useMemo(() => {
        let averageViews = 0;
        let completionPercentage = 0;
        let viewCount = 0;
        const unlockedStats = stats.filter(stats => stats.current_level >= 1);
        const unlockedCount = unlockedStats.length;
        if (unlockedCount > 0) {
            // count the views
            viewCount = unlockedStats.reduce((count, v) => count + (v.view_count ?? 0), 0);

            // completion percentage
            completionPercentage = (Math.round(viewCount / unlockedCount) / 7) * 100;
            completionPercentage = Math.min(completionPercentage, 100);

            // average views
            averageViews = viewCount / unlockedCount;
            if (Number.isNaN(averageViews))
                averageViews = 0;
            else
                averageViews = Math.round(averageViews * 10) / 10;
        }
        return {
            viewCount,
            averageViews,
            completionPercentage
        };
    }, [stats]);

    return (
        <div tw='bg-PicoteeBlue text-white rounded-lg font-sans px-6 pt-6 pb-4 flex justify-around items-center'>
            <div aria-label={`${averageViews} Average Views Per Video`}>
                <div tw='text-3xl font-extrabold text-center'>{averageViews}</div>
                <div tw='flex items-center'>
                    <div tw='pr-2 font-extrabold'>0</div>
                    <div tw='w-48 h-1 bg-GainsboroGrey '>
                        <div
                            tw='h-1 bg-PinkCrayola'
                            style={{
                                width: `${(completionPercentage / 100) * 100}%`
                            }}
                        />
                    </div>
                    <div tw='pl-2 font-extrabold'>7</div>
                </div>
                <div tw='text-lg mt-1 font-semibold'>Average Views Per Video</div>
            </div>

            <svg
                aria-hidden={true}
                width='2' height='92' viewBox='0 0 2 92' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <line
                    x1='1' x2='1' y2='92' stroke='white'
                    strokeWidth='2' />
            </svg>

            <div aria-label={`${viewCount} Total Video Views`} tw='relative mt-5'>
                <div tw='text-3xl font-extrabold text-center'>{viewCount}</div>
                <div tw='text-lg mt-1 font-semibold'>Total Video Views</div>
            </div>
        </div>
    );
};

VideoViews.propTypes = {
    anyProp: PropTypes.any
};

export default VideoViews;
