import React from 'react';

const SvgSortIndicatorDown = (props) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 10 7'
        {...props}>
        <defs>
            <path
                id='sort_indicator_down_svg__a'
                d='M647.699 764.92l4.194-4.195a.73.73 0 011.049 0 .73.73 0 010 1.049l-4.194 4.194a.73.73 0 01-1.05 0 .73.73 0 010-1.048z'
            />
            <path
                id='sort_indicator_down_svg__b'
                d='M644.53 760.725l4.195 4.195a.73.73 0 010 1.048.73.73 0 01-1.049 0l-4.194-4.194a.73.73 0 010-1.049c.296-.273.775-.273 1.048 0z'
            />
        </defs>
        <use
            fill='currentColor'
            xlinkHref='#sort_indicator_down_svg__a'
            transform='translate(-643 -760)' />
        <use
            fill='currentColor'
            xlinkHref='#sort_indicator_down_svg__b'
            transform='translate(-643 -760)' />
    </svg>
);

export default SvgSortIndicatorDown;
