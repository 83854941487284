import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { Button, Modal, VideoPlayerProgress } from 'components';
import { recordImageURL } from 'static/records/FilledRecordsAssets';
import { useAssignments } from 'state/Student/AssignmentsState';
import moment from 'moment';
import { useMedia } from 'react-use';
import { useVideoWithStats } from '../../state/Student/VideoStats';
import { ordinalNum } from '../../utils/pluralize';
import { challenge_titles } from '../../app/constants/constants';
import { VideoLockedHeader } from '../../app/containers/shared/QuizSessionContainer/components/VideoUnlockedHeader';
import QuizIntroAttempts from './QuizIntroAttempts';
import GradientBorder from './GradientBorder';
import ProgressDots from './ProgressDots';

const BorderGradient = css`
    border: 19px solid;
    border-image-slice: 1;
    border-width: 19px;
    border-image-source: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
    min-height: 550px;
    max-height: 550px;
    background: #fff;

    /* margin: auto;
    width: 835px;
    height: 550px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    background-clip: padding-box;
    border: solid 19px transparent;
    border-radius: 30px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -19px;
        border-radius: inherit;
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
    } */
    /* 
    position: relative;
    border: 4px solid transparent;
    border-radius: 16px;
    min-height: 550px;
    max-height: 550px;
    background: #fff;
    background-clip: padding-box;
    padding: 10px;

    :after {
        position: absolute;
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        background: linear-gradient(red, blue);
        content: '';
        z-index: -1;
        border-radius: 16px;
    } */
`;

const ProgressBarContainerCss = css`
    top: -40px;
    left: -72px;
    @media only screen and (max-width: 1200px) {
    };
`;

const ParentBorderGradient = css`
    width: 40px;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    padding: 0px 4px;
    background-color: #281757;
    max-height: 594px;
`;

const ParentHeightCss = css`
    max-height: 594px;
`;

const QuizIntro = ({ teacher, videoId, level, currentScore, previousScores, onStart, videoTitle, progress, type }) => {
    console.log('[QuizIntro] render', videoId, level, currentScore, previousScores, onStart, videoTitle, progress, type);
    const [isOpen, setIsOpen] = useState(false);

    const recordProgress = recordImageURL(level, currentScore);

    // get assignment list
    const assignments = useAssignments();

    // get video with stats
    const videoWithStats = useVideoWithStats(videoId);
    const isReviewQuiz = videoWithStats.stats?.current_level > level;

    // console.log('videoWithStats', videoWithStats);

    // sort assignment by most recent
    const videoAssignmentByDate = useMemo(() => {
        // filter assignments related to the video
        const videoAssignment = assignments.filter(assignment => assignment.items.some(item => item === videoId));
        return videoAssignment.sort((a, b) => moment(b.created).diff(moment(a.created)));
    }, [assignments, videoId]);

    let excludeZeroFromCurrentScore = currentScore > 0 && currentScore;

    const isSmall = useMedia('(min-width: 640px)');
    // const isTablet = useMedia('(min-width: 768px)');
    // const isLarge = useMedia('(min-width: 1024px)');
    // const isXL = useMedia('(min-width: 1280px)');
    // const is2XL = useMedia('(min-width: 1536px)');
    const miniQuiz = type === 'mini';

    const progressDots = useMemo(() => {
        if (miniQuiz) {
            return (
                <ProgressDots tw='mt-6'  progress={progress.slice(0, level === 0 ? 3 : 5)} />
            );
        } else {
            if (isSmall) {
                return (
                    <ProgressDots tw='mt-6' progress={progress} />
                );
            } else {
                return (
                    <>
                        <ProgressDots tw='mt-6 mb-3.5' progress={progress.slice(0, 5)} />
                        <ProgressDots progress={progress.slice(5)} startIndex={5} />
                    </>
                );
            }
        }
    }, [isSmall, level, miniQuiz, progress]);

    return (
        <div tw='absolute inset-0 m-auto sm:maxWidth[873px] sm:maxHeight[543px]' data-testid='quiz-intro'>
            <GradientBorder border={level === 0 ? 'orange' : null} >
                {/*
                <CancelButton
                    tw='right[0] top[0] lg:top[-49px] lg:right[-46px]'
                    className='quiz-cancel-button'
                    onClick={() => navigate(-1)}
                    // style={{ right: '-46px', top: '-49px' }}
                    // css={[css`
                    // `]}
                >
                    <WhiteXButton style={{ color: '#6A7277' }} />
                </CancelButton>
                */}
                <div tw='h-full relative pt-32' data-testid='quiz-intro-contents'>
                    {level === 0 ?
                        <VideoLockedHeader tw='absolute z-10 flex justify-center right[0] left[0] top[3.1875rem]' />
                        :
                        <div tw='absolute z-10 m-auto right[0] left[0] top[-3.1875rem] sm:top[-5.1rem] maxWidth[10rem] sm:maxWidth[12.1875rem]'>
                            <img src={recordProgress} tw='w-full maxWidth[10rem] sm:maxWidth[12.1875rem]' />
                            <div tw='absolute inset-0 text-white font-black text-4xl flex items-center justify-center'>{excludeZeroFromCurrentScore}</div>
                        </div>
                    }

                    <h1 tw='flex justify-center uppercase font-black text-center  text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-EarieBlack'>
                        {challenge_titles[level]}
                    </h1>
                    <div tw='flex justify-center font-black text-center text-base sm:text-2xl text-EarieBlack pt-2'>{videoTitle}</div>

                    {/*
                    <div tw='flex items-center justify-center mt-6' className='progress-container' css={[RemoveLastLineCss]}>
                        {progressDots}
                    </div>
                    */}

                    {progressDots}

                    {/*
                    // font-size: 18px;
                    // line-height: 25px;
                    text-align: center;
                    font-weight: 800;
                    font-family: 'Avenir';
                    margin-top: 41px;
                    margin-bottom: 30px;
                    */}
                    {/*data-testid='quiz-into-contents'*/}


                    {level === 0 ?
                        <div tw='flex items-center justify-center font-extrabold text-black text-lg text-center mt-10 mb-4'>
                            See what you already know!<br />
                            Answer these {miniQuiz ? '3' : '5'} Warm Up questions to unlock the music video.
                        </div>
                        :
                        <div tw='flex items-center justify-center font-black text-base sm:text-2xl text-EarieBlack mt-9 '>
                            {isReviewQuiz ? 'REVIEW' : `${ordinalNum(previousScores.length + 1)} Attempt`}
                        </div>
                    }
                    <div tw='flex justify-center py-4'>
                        <Button
                            id='start-quiz-button'
                            tabIndex={1}
                            onClick={onStart}
                            tw='focus-visible:outlineColor[#e66a5b]'
                        >
                            <div tw='flex items-center pl-3 text-lg'>
                                <div>START</div>
                                <div>
                                    <svg
                                        width='24' height='24' viewBox='0 0 24 24' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M9 18L15 12L9 6' stroke='white' strokeWidth='2' strokeLinecap='round'
                                            strokeLinejoin='round' />
                                    </svg>
                                </div>
                            </div>
                        </Button>
                    </div>
                    <div tw='flex justify-center pt-5 pb-3.5'>
                        <div tw='text-SonicSilver text-lg font-extrabold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                            {previousScores.length > 0 && 'See Attempts'}
                        </div>
                    </div>
                    <div tw='absolute' css={[ProgressBarContainerCss]}>
                        {(!teacher && videoAssignmentByDate?.length >= 1) && (
                            <VideoPlayerProgress
                                quiz_level_goal={videoAssignmentByDate[0].quiz_level_goal}
                                quiz_score_goal={videoAssignmentByDate[0].quiz_score_goal}
                                stats={videoWithStats.stats}
                                ParentBorderGradient={ParentBorderGradient}
                                ParentHeightCss={ParentHeightCss}
                            />
                        )}
                    </div>
                </div>
                <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
                    <QuizIntroAttempts videoTitle={videoTitle} level={level} previousScores={previousScores} setIsOpen={setIsOpen} />
                </Modal>
            </GradientBorder>
        </div>
    );
};

QuizIntro.propTypes = {
    previousScores: PropTypes.array,
    currentScore: PropTypes.number,
    level: PropTypes.number
};

export default QuizIntro;
