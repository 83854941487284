import moment from 'moment';
import React, { useState } from 'react';
import { styled } from 'twin.macro';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CalendarSVG(props) {
    return (
        <svg
            width={30} height={30} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M12.084 21.915V11.914h-1.186a23.63 23.63 0 00-2.46 1.511v1.303a34.128 34.128 0 012.358-1.457h.023v8.644h1.265zm2.228-2.447c.088 1.2 1.114 2.636 3.193 2.636 2.359 0 3.75-1.998 3.75-5.383 0-3.626-1.464-5.002-3.662-5.002-1.736 0-3.37 1.26-3.37 3.392 0 2.175 1.546 3.318 3.143 3.318 1.399 0 2.306-.705 2.593-1.48h.051c-.008 2.467-.864 4.057-2.447 4.057-1.245 0-1.89-.844-1.969-1.538h-1.282zm5.537-4.344c0 1.305-1.048 2.212-2.22 2.212-1.127 0-2.145-.718-2.145-2.25 0-1.543 1.091-2.268 2.19-2.268 1.187 0 2.175.746 2.175 2.306z'
                fill='#5A5353'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.875 7.5v18.75a1.875 1.875 0 001.875 1.875h22.5a1.875 1.875 0 001.875-1.875V7.5H1.875zM3.75 1.875A3.75 3.75 0 000 5.625V26.25A3.75 3.75 0 003.75 30h22.5A3.75 3.75 0 0030 26.25V5.625a3.75 3.75 0 00-3.75-3.75H3.75z'
                fill='#5A5353'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.563 0A.937.937 0 017.5.938v.937a.937.937 0 11-1.875 0V.937A.937.937 0 016.563 0zm16.875 0a.938.938 0 01.937.938v.937a.938.938 0 01-1.875 0V.937A.938.938 0 0123.438 0z'
                fill='#5A5353'
            />
        </svg>
    );
}

const CalendarContainer = styled.div`
    color: #afafb7;
    font-family: Avenir, serif;
    font-size: 16px;
    font-weight: 500;
    position: relative;
`;

const CalendarIcon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
`;

const StyledDatePicker = styled(DatePicker)`
    height: 34px;
    border: none;
    padding: 0;
`;

const CalendarInputContainer = styled.div`
    display: flex;
    border: ${(props) => `2px solid ${props.borderError ? '#EE7171' : '#c7c7ff'}`};
    border-radius: 5px;
    padding: 4px;
    background-color: #ffffff;
    //color: #afafb7;
    color: #5a5353;
    font-family: Avenir, serif;
    font-size: 16px;
    font-weight: 500;

    input {
        padding-left: 10px;
    }
`;

const CalendarInput = (props) => {
    return (
        <CalendarInputContainer borderError={props.borderError}>
            <CalendarIcon onClick={props.onClick}>
                <CalendarSVG />
            </CalendarIcon>
            <input {...props} />
        </CalendarInputContainer>
    );
};

const Calendar = ({ date, onChange, placeholder, borderError, ...props }) => {
    // convert date param from moment, default to todays date
    const [currentValue, setCurrentValue] = useState(date ? date.toDate() : moment().toDate());
    // console.log('calendar date:', date);
    // console.log('today:', Date());
    // console.log('currentValue:', currentValue);

    const handleChange = (date) => {
        // save local state
        setCurrentValue(date);

        // convert to moment & return to parent
        if (onChange)
            onChange(moment(date));
    };

    return (
        <CalendarContainer>
            <StyledDatePicker
                tabIndex={1}
                selected={currentValue}
                onChange={handleChange}
                fixedHeight={true}
                shouldCloseOnSelect={true}
                placeholderText={placeholder && 'Set Due Date'}
                customInput={<CalendarInput borderError={borderError} />}
                {...props}
            />
        </CalendarContainer>
    );
};

export default Calendar;
