import React, { useRef, useState } from 'react';
import tw, { css } from 'twin.macro';
import { useForm } from 'react-hook-form';
import qs from 'qs';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import CSRFToken from 'lib/components/csrftoken';
import { is_username_valid } from 'lib/util';
import FormError from 'components/FormError/FormError';
import { apiLoginClient } from 'context/Auth/AuthProvider';


const StudentSignUpForm = ({ setIsLoading, joinCode, setIsOpen }) => {
    const [submitErrors, setSubmitErrors] = useState({});

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        setError
    } = useForm();

    let isThereErrors = Object.keys(errors).length !== 0;

    const [{ loading: isPosting }, postInviteCode] = useAxios(
        {
            url: '/api/join/signup/',
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
        },
        { manual: true, axios: apiLoginClient }
    );

    const password = useRef({});
    password.current = watch('password', '');

    const onSubmit = (data) => {
        setIsLoading(true);
        setSubmitErrors({});
        console.log(joinCode);
        data['join_code'] = joinCode;
        console.log('data stringify', qs.stringify(data));
        postInviteCode({ data: qs.stringify(data) })
            .then((response) => {
                // check for errors:
                const data = response.data;
                if (data.errors) {
                    setSubmitErrors(data.errors);
                    Object.keys(errors).forEach((name) => {
                        setError(name, { type: 'manual', message: errors[name] });
                    });
                    setIsLoading(false);
                } else {
                    // on success redirect to this site - user should now be authenticated
                    window.location.href = `${window.location.origin}/`;
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Login error:', error);
                console.error(error);
            });
    };
    const navigate = useNavigate();

    return (
        <>
            <div tw='bg-[#3E257A] font-Poppins flex flex-col items-center rounded-t-2xl relative px-3 '>
                <div tw='text-white text-base font-medium text-center max-w-[380px] mt-10 '>Please create your Student Muzology account.</div>
                <form onSubmit={handleSubmit(onSubmit)} tw='w-full max-w-[450px] mt-3'>
                    <CSRFToken />
                    <div tw='flex justify-between w-full '>
                        <div tw='w-1/2 mr-3 relative'>
                            <input
                                placeholder='First Name'
                                type='text'
                                name='first_name'
                                data-testid='first_name'
                                ref={register({ required: true })}
                                tw='block w-full pl-10 pr-4 py-2.5 mt-2 font-normal text-base text-[#9D91BB] bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'

                            />
                            {errors.first_name && <div tw='pt-2 text-red-500'>* This field is required</div>}
                        </div>
                        <div tw='w-1/2 ml-3'>
                            <input
                                placeholder='Last Name'
                                data-testid='last_name'
                                name='last_name'
                                type='text'
                                ref={register({ required: true })}
                                tw='block w-full pl-10 pr-4 py-2.5 mt-2 font-normal text-base text-[#9D91BB] bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'
                            />
                            {errors.last_name && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                        </div>
                    </div>

                    <div tw='relative  mt-5'>


                        <input
                            name='username'
                            ref={register({
                                required: true,
                                validate: (value) => is_username_valid(value) || 'The username can only contain letters, numbers, _, @, +, . and -'
                            })}
                            data-testid='username'
                            placeholder='Please create a username...'
                            tw='block w-full pl-10 pr-4 py-2.5 mt-2 font-normal text-base text-[#9D91BB] bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'

                        />
                        <div tw='absolute top-[.95rem] left-3.5'>
                            <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M9 7.5C10.6569 7.5 12 6.15685 12 4.5C12 2.84315 10.6569 1.5 9 1.5C7.34315 1.5 6 2.84315 6 4.5C6 6.15685 7.34315 7.5 9 7.5Z' stroke='white' strokeWidth='1.5' />
                                <path d='M9 9.75C10.956 9.75 12.6623 10.3613 13.566 11.268M11.688 15.3278C10.902 15.5955 9.9825 15.75 9 15.75C6.1005 15.75 3.75 14.4075 3.75 12.75C3.75 11.73 4.64025 10.83 6 10.2878' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                                <path d='M13.5 13.875L14.25 14.625L15.75 12.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                            </svg>

                        </div>
                        {errors.last_name && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                        {FormError(submitErrors.username)}

                    </div>

                    <div tw='relative mt-5'>
                        <input
                            placeholder='Please create a password...'
                            type='password'
                            name='password'
                            ref={register({
                                required: true,
                                minLength: { value: 6, message: 'Password must have at least 6 characters' }
                            })}
                            data-testid='password1'
                            tw='block w-full pl-10 pr-4 py-2.5 mt-2 font-normal text-base text-[#9D91BB] bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'

                        />
                        <div tw='absolute top-[.85rem] left-3.5'>
                            <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M9 12.75C8.60218 12.75 8.22064 12.592 7.93934 12.3107C7.65804 12.0294 7.5 11.6478 7.5 11.25C7.5 10.4175 8.1675 9.75 9 9.75C9.39782 9.75 9.77936 9.90804 10.0607 10.1893C10.342 10.4706 10.5 10.8522 10.5 11.25C10.5 11.6478 10.342 12.0294 10.0607 12.3107C9.77936 12.592 9.39782 12.75 9 12.75ZM13.5 15V7.5H4.5V15H13.5ZM13.5 6C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5V15C15 15.3978 14.842 15.7794 14.5607 16.0607C14.2794 16.342 13.8978 16.5 13.5 16.5H4.5C4.10218 16.5 3.72064 16.342 3.43934 16.0607C3.15804 15.7794 3 15.3978 3 15V7.5C3 6.6675 3.6675 6 4.5 6H5.25V4.5C5.25 3.50544 5.64509 2.55161 6.34835 1.84835C7.05161 1.14509 8.00544 0.75 9 0.75C9.49246 0.75 9.98009 0.846997 10.4351 1.03545C10.89 1.22391 11.3034 1.50013 11.6517 1.84835C11.9999 2.19657 12.2761 2.60997 12.4645 3.06494C12.653 3.51991 12.75 4.00754 12.75 4.5V6H13.5ZM9 2.25C8.40326 2.25 7.83097 2.48705 7.40901 2.90901C6.98705 3.33097 6.75 3.90326 6.75 4.5V6H11.25V4.5C11.25 3.90326 11.0129 3.33097 10.591 2.90901C10.169 2.48705 9.59674 2.25 9 2.25Z' fill='white' />
                            </svg>

                        </div>
                        {FormError(errors.password)}
                    </div>
                    <div tw='relative  mt-4'>
                        <input
                            placeholder='Please confirm your password...'
                            type='password'
                            name='confirmPassword'
                            ref={register({
                                validate: (value) => value === password.current || 'The passwords do not match'
                            })}
                            data-testid='password2'
                            tw='block w-full pl-10 pr-4 py-2.5 mt-2 font-normal text-base text-[#9D91BB] bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'

                        />
                        {errors.confirmPassword && <div tw='pt-2 text-red-500'>* The passwords do not match.</div>}
                        <div tw='absolute top-[.85rem] left-3.5'>
                            <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M9 12.75C8.60218 12.75 8.22064 12.592 7.93934 12.3107C7.65804 12.0294 7.5 11.6478 7.5 11.25C7.5 10.4175 8.1675 9.75 9 9.75C9.39782 9.75 9.77936 9.90804 10.0607 10.1893C10.342 10.4706 10.5 10.8522 10.5 11.25C10.5 11.6478 10.342 12.0294 10.0607 12.3107C9.77936 12.592 9.39782 12.75 9 12.75ZM13.5 15V7.5H4.5V15H13.5ZM13.5 6C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5V15C15 15.3978 14.842 15.7794 14.5607 16.0607C14.2794 16.342 13.8978 16.5 13.5 16.5H4.5C4.10218 16.5 3.72064 16.342 3.43934 16.0607C3.15804 15.7794 3 15.3978 3 15V7.5C3 6.6675 3.6675 6 4.5 6H5.25V4.5C5.25 3.50544 5.64509 2.55161 6.34835 1.84835C7.05161 1.14509 8.00544 0.75 9 0.75C9.49246 0.75 9.98009 0.846997 10.4351 1.03545C10.89 1.22391 11.3034 1.50013 11.6517 1.84835C11.9999 2.19657 12.2761 2.60997 12.4645 3.06494C12.653 3.51991 12.75 4.00754 12.75 4.5V6H13.5ZM9 2.25C8.40326 2.25 7.83097 2.48705 7.40901 2.90901C6.98705 3.33097 6.75 3.90326 6.75 4.5V6H11.25V4.5C11.25 3.90326 11.0129 3.33097 10.591 2.90901C10.169 2.48705 9.59674 2.25 9 2.25Z' fill='white' />
                            </svg>

                        </div>
                    </div>
                    <div tw='flex justify-center pb-5'>
                        <button id='submit-setup' tw='bg-[#46A6F8] rounded-lg text-white py-2 px-12 cursor-pointer leading-normal mt-4 hover:bg-[#176BB2] '>
                            Continue
                        </button>
                    </div>
                </form>
            </div>
            <div tw='bg-[#6441B9] text-center text-white text-sm font-normal  py-5 rounded-b-2xl font-Poppins px-3'>
                <div tw='text-white font-normal text-sm  text-center'>
                    {' '}
                    Already have a Muzology account,{' '}
                    <br tw='block sm:hidden'/>
                    <span tw='text-white underline cursor-pointer text-sm ' onClick={() => setIsOpen(false)}>
                        click here to log in.
                    </span>
                </div>
                <div tw='text-sm text-center font-normal mt-2'>Forgot your username or password? Pease see your teacher.</div>
            </div>
        </>
    );
};

export default StudentSignUpForm;