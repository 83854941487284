import React from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import LoadingOverlay from '../LoadingOverlay';

const BorderGradient = css`
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box; /* !importanté */
    outline: solid 5px transparent; /* !importanté */
    border-radius: 0.5rem;
    background-color: white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -5px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    }
`;

const StudentSelectionCard = ({ student, active, setActive }) => {
    let studentName = '';
    if (!student.loading) {
        studentName = `${student.user?.last_name}, ${student.user?.first_name}`;
    }

    return (
        <button
            tabIndex='0'
            tw='relative maxWidth[232px] minHeight[60px] bg-PurpleWhite rounded-lg flex items-center py-4 mt-4 cursor-pointer w-full '
            css={[student.user.id === active && BorderGradient]}
            onClick={() => setActive(student.user.id)}
            // style={{ maxWidth: '232px' }}
        >
            {student.loading && <LoadingOverlay show={student.loading}/>}
            <div tw='ml-4 text-EarieBlack text-lg'>
                {studentName}
            </div>
        </button>
    );
};

StudentSelectionCard.propTypes = {
    student: PropTypes.object,
    active: PropTypes.number,
    setActive: PropTypes.func
};

export default StudentSelectionCard;
