import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import { Button, Modal } from 'components';
import SvgWhiteXButton from 'images/icons/WhiteXButton';

const AssignPlaylistNowOrLaterModal = ({ isOpen, setIsOpen, playlist, onAssignNow, onAssignLater, parent }) => {

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[806px] mx-auto`}>
            <div tw='mx-1 xsm:mx-4'>
                <div tw='w-full px-6 py-3 text-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-tl-xl rounded-tr-xl relative'>
                    <h1 tw='text-white text-2xl font-medium'>Playlist Created!</h1>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-1 xsm:-right-4 -top-3 xsm:-top-6 w-8 xsm:w-12 h-8 xsm:h-12 flex justify-center items-center '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                </div>

                <div tw='bg-white rounded-bl-xl rounded-br-xl px-1 xsm:px-4 py-8 text-center'>
                    <div tw='space-y-6'>
                        <h3 tw='font-black text-2xl text-MediumPurple'>{playlist.name}</h3>
                        <p tw='font-extrabold text-lg text-DarkLiver'>Would you like to assign this playlist later or assign it now?</p>
                    </div>
                    <div tw='xsm:space-x-4 mt-7'>
                        <Button onClick={onAssignLater} variant='secondary'>
                            Assign Later
                        </Button>
                        <Button onClick={onAssignNow}>Assign Now</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

AssignPlaylistNowOrLaterModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    playlist: PropTypes.object
};

export default AssignPlaylistNowOrLaterModal;
