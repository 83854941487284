import React from 'react';
import tw, { css } from 'twin.macro';

const VideoSurveyEndDesktop = ({ onSubmit }) => {
    return (
        <div tw='px-8 lg:px-16  w-full'>
            <div tw='text-white text-sm sm:text-base md:text-lg lg:text-xl font-medium xl:font-semibold'>
            How's the level of math in this video for you?
            </div>
            
            <div tw='flex justify-between mt-3 md:mt-5'>
                <div tw='rounded-md md:rounded-lg border-[1px] border-solid border-white text-sm py-2.5 lg:pl-6 lg:pr-6 text-white cursor-pointer hover:bg-[#46A6F8] flex-1 lg:mr-2' onClick={() => onSubmit(1)}>
                    Easy 
                </div>
                <div tw='rounded-md md:rounded-lg border-[1px] border-solid border-white text-sm py-2.5 lg:pl-6 lg:pr-6 text-white cursor-pointer hover:bg-[#46A6F8] flex-1 mx-1 lg:mx-2' onClick={() => onSubmit(2)}>
                    Just right
                </div>
                <div tw='rounded-md md:rounded-lg border-[1px] border-solid border-white text-sm py-2.5 lg:pl-6 lg:pr-6 text-white cursor-pointer hover:bg-[#46A6F8] flex-1 lg:ml-2' onClick={() => onSubmit(3)}>
                    Advanced 
                </div>
               
            </div>
        </div>
        
    );
};

export default VideoSurveyEndDesktop;