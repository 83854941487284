import React from 'react';

const HeartFavoriteIcon = (props) => {
    let icon = null;

    if (props.favorite) {
        icon = (
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19' fill='none'>
                <path d='M1.69013 7.82795C2.07963 12.6432 7.37041 15.699 9.9671 16.625C12.7345 15.7502 17.8076 12.6885 18.2689 7.82795C19.0967 1.75415 11.6666 0.927793 9.9671 4.4871C8.33286 0.927793 1.20325 1.80892 1.69013 7.82795Z' fill='#E63AA3'/>
            </svg>
        );
    } else {
        icon = (
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19' fill='none'>
                <path d='M1.69013 7.82795C2.07963 12.6432 7.37041 15.699 9.9671 16.625C12.7345 15.7502 17.8076 12.6885 18.2689 7.82795C19.0967 1.75415 11.6666 0.927793 9.9671 4.4871C8.33286 0.927793 1.20325 1.80892 1.69013 7.82795Z' stroke='#979FA6' strokeLinecap='round' strokeLinejoin='round'/>
            </svg>
        );
    }

    return icon;
};


export default HeartFavoriteIcon;
