import * as React from 'react';

function SvgClassIcon08(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#80A3FF' />
            <path
                d='M14.76 16.072c0-.776.143-1.448.428-2.018.301-.586.705-1.07 1.211-1.45a5.326 5.326 0 011.805-.878c.681-.19 1.41-.285 2.186-.285.775 0 1.504.095 2.184.285.681.19 1.275.483 1.782.879.506.38.91.863 1.211 1.448.3.57.451 1.243.451 2.02 0 .918-.253 1.701-.76 2.35-.49.65-1.187 1.109-2.09 1.378v.048c1.014.269 1.79.76 2.328 1.472.538.697.807 1.567.807 2.612 0 .808-.166 1.52-.498 2.138a4.398 4.398 0 01-1.307 1.544 5.902 5.902 0 01-1.876.902 8.005 8.005 0 01-2.232.309c-.776 0-1.52-.103-2.233-.309a6.201 6.201 0 01-1.9-.902 4.754 4.754 0 01-1.306-1.544c-.317-.618-.475-1.33-.475-2.138 0-1.044.269-1.915.807-2.612.539-.713 1.314-1.203 2.328-1.473v-.047c-.903-.27-1.607-.728-2.114-1.377-.49-.65-.736-1.433-.736-2.352zm3.563.499c0 .522.19.966.57 1.33.38.364.879.546 1.497.546.617 0 1.116-.182 1.496-.546.38-.364.57-.808.57-1.33 0-.507-.19-.942-.57-1.306-.38-.364-.879-.546-1.496-.546-.618 0-1.117.182-1.497.546-.38.364-.57.8-.57 1.306zm-.285 6.864a2 2 0 00.642 1.496c.427.412.997.617 1.71.617.712 0 1.282-.205 1.71-.617a2 2 0 00.64-1.496c0-.602-.213-1.109-.64-1.52-.428-.412-.998-.618-1.71-.618-.713 0-1.283.206-1.71.618a2.027 2.027 0 00-.642 1.52z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon08;
