import React from 'react';

const SvgCategoryRatios = (props) => (
    <svg
        viewBox='0 0 28 28'
        width='1em'
        height='1em'
        {...props}>
        <g transform='translate(-38 -846)'>
            <circle
                data-name='category_ratios'
                cx={14}
                cy={14}
                r={14}
                transform='translate(38 846)'
                fill='white' />
            <circle
                data-name='Ellipse 521'
                cx={3}
                cy={3}
                r={3}
                transform='translate(49 852)'
                fill='#1E215' />
            <circle
                data-name='Ellipse 522'
                cx={3}
                cy={3}
                r={3}
                transform='translate(49 863)'
                fill='#1E215' />
        </g>
    </svg>
);

export default SvgCategoryRatios;
