import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';

const TotalAchievements = ({ achievements }) => {
    const totalAchievements = achievements?.length;
    return (
        <div tw='bg-PicoteeBlue text-white rounded-lg font-sans px-6 pt-6 pb-4 text-center'>
            <div tw='text-4xl font-extrabold relative text-center'>
                <div>{totalAchievements}</div>
            </div>
            <div tw='text-lg mt-1'>My Total Achievements</div>
        </div>
    );
};

TotalAchievements.propTypes = {
    anyProp: PropTypes.any
};

export default TotalAchievements;
