import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BalanceText from 'react-balance-text';
import tw, { css, styled } from 'twin.macro';
import { wrapTitleParens } from '../../lib/string';

const ScrollBarStyle = css`
    //height: 100vh;
    ::-webkit-scrollbar {
        background: transparent;
        width: 11px;
        padding: 0 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #3E257A;
        border-radius: 20px;
        max-height: 60px;
    }

    ::-webkit-scrollbar-track {
    }
`;

const Tab = styled.button(({ $active }) => [
    tw`text-base py-2 px-8 text-white text-center cursor-pointer flex items-center `,
    $active && tw`px-0`
]);

const DTCLyricsPopOver = ({ lyrics, title, copyrightYear }) => {
    const [open, setOpen] = useState(false);
    const newLyrics = lyrics ?? '';

    const copyright = `Copyright © ${copyrightYear ?? '2017'} Muzology, LLC. All rights reserved.`;

    return (
        <div>
            <div
                tw='hidden lg:flex absolute py-3 px-4 -right-16 text-center '
                style={{ top: '-3.5rem' }}
                id='video-session-print-show-lyrics'
            >
                <Tab tabIndex='0' onClick={() => setOpen(!open)} $active={open} style={{ width: '160px' }}>
                    <div tw='mr-2'>{open ? 'Hide Lyrics' : ' Lyrics'}</div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='21' fill='none'>
                        <path
                            fill='#fff'
                            d='M.834 20.166v-16.5c0-.504.18-.936.539-1.295.36-.36.79-.539 1.294-.538h10.084c.504 0 .936.18 1.295.539.36.359.539.79.538 1.294v.39a4.821 4.821 0 0 0-1.008.618c-.306.245-.58.52-.825.825V3.666H2.667v11h10.084v-3.667c.244.306.52.58.825.825.305.245.641.451 1.008.62v2.222c0 .504-.18.936-.539 1.295-.36.36-.79.539-1.294.538H4.5L.834 20.166Zm3.667-7.333h3.666v-1.834H4.501v1.834Zm11.916-1.834a2.652 2.652 0 0 1-1.948-.802 2.652 2.652 0 0 1-.802-1.948c0-.764.268-1.413.802-1.948a2.652 2.652 0 0 1 1.948-.802c.168 0 .329.016.482.046.152.03.297.069.435.115V.916h3.667v1.833h-1.834v5.5c0 .764-.267 1.414-.802 1.948a2.652 2.652 0 0 1-1.948.802Zm-11.916-.916h6.416V8.249H4.501v1.834Zm0-2.75h6.416V5.499H4.501v1.834Z' />
                    </svg>
                </Tab>

            </div>
            {open && (
                <div
                    tw='rounded-tl-lg rounded-b-lg text-white font-Poppins text-base font-normal max-w-md pt-5 px-9 overflow-auto absolute top-0 right-0 h-full xl:h-[542px] bg-[rgba(28, 0, 87, 0.5)]  backdrop-blur-3xl'
                    css={[
                        ScrollBarStyle,
                        css`
                            z-index: 100;
                            @media only screen and (max-height: 740px) {
                                right: 141px !important;
                                height: 460px;
                            }
                        `
                    ]}
                >
                    <div tw='mx-auto max-w-[345px] whitespace-pre-wrap'>
                        <div tw='text-xl font-semibold pb-4 pt-0'>
                            {wrapTitleParens(title)}
                        </div>
                        {newLyrics}
                        <BalanceText tw='mt-4 mb-2'>{copyright}</BalanceText>
                    </div>

                    <div tw='absolute right-4 top-6 cursor-pointer' onClick={() => setOpen(false)}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'>
                            <path
                                fill='#fff'
                                d='M16.811 1.138a1.248 1.248 0 0 0-1.766 0l-6.127 6.1L2.79 1.125A1.25 1.25 0 0 0 .754 2.483c.062.151.154.289.27.405L7.151 9l-6.127 6.113a1.246 1.246 0 0 0 1.362 2.032c.151-.062.289-.154.405-.27l6.127-6.113 6.127 6.113a1.248 1.248 0 0 0 1.766 0 1.246 1.246 0 0 0 0-1.762L10.684 9l6.127-6.112a1.25 1.25 0 0 0 0-1.75Z' />
                        </svg>
                    </div>
                </div>
            )}
        </div>
    );
};

DTCLyricsPopOver.propTypes = {
    lyrics: PropTypes.string
};

export default DTCLyricsPopOver;
