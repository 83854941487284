import React from 'react';
import TimesTableCrushedItImg from 'static/images/guidance/times-table-crushed-it.png';
import tw, { styled, css } from 'twin.macro';

const ClipStyle = css`
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(4px 2px #B031FA);
`;

const TimesTableCrushedIt = ({ image }) => {
    return (
        <>
            <div tw='absolute text-white text-4xl sm:text-6xl lg:text-5xl xl:text-6xl bottom-[74px] sm:bottom-28 font-bold left-3 sm:left-5' css={[ClipStyle]}>YOU </div>
            <div tw='absolute text-white text-4xl sm:text-6xl lg:text-5xl xl:text-6xl bottom-10 sm:bottom-14 lg:bottom-16 xl:bottom-14 font-bold left-3 sm:left-5' css={[ClipStyle]}>CRUSHED IT! </div>
            <div tw='absolute text-white text-base tiny:text-xl xl:text-2xl bottom-2 sm:bottom-4 font-medium left-3 sm:left-5'>Rewatch a video of your choice.</div>

            <img src={TimesTableCrushedItImg} alt={image} tw='h-full w-full object-cover' />
        </>
    );
};

export default TimesTableCrushedIt;