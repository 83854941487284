import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { challenge_titles } from 'app/constants/constants';
import { useMedia } from 'react-use';
import QuizHeader from './QuizHeader';
import QuizProgress from './QuizProgress';

export const QuizPanelTextColors = {
    0: '#311b71',
    1: '#4f5a60',
    2: '#775a00',
    3: '#003e48'
};

export const QuizPanelStyle = styled.div`
    //background: #fff linear-gradient(to right, #e7eaed 0%, #a0a5a8 100%);

    /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); */

    //margin-top: 60px;
    //margin-bottom: 10px;
    /* margin: auto; */

    position: relative;

    /* border-radius: 20px; */
    //overflow: scroll;

    display: flex;
    flex-direction: column;
    height: 100vh;

    .quiz-title {
        padding-top: 20px;
        text-align: center;

        //color: #4f5a60;
        font-family: Avenir, serif;
        font-weight: 900;
        font-size: 20px;
        white-space: nowrap;

        text-overflow: ellipsis;

        color: #fff;
        background-image: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    }

    .quiz-header-content {
    }

    .quiz-panel-contents {
        background-color: #f4f0ff;
        box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
        //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        //flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
    }

    /* New Design */

    /* background-image: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%); */
    color: #fff;

    .quiz-header-content {
        //background-color: #75d7e9;
    }

    .quiz-panel-contents {
        background: linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%);
        //background-color: #7569d6;
        flex-grow: 1;
    }

    .progress-bar {
        .indicator-container {
            .indicator {
                /* background-image: linear-gradient(to right, #9ce7f3 0%, #27c1d9 100%); */
            }
        }

        .position-indicator {
            background-color: #58d1e4;
        }
    }

    // the inner shadow is an overlay that goes over the quiz contents
    .inner-shadow-overlay {
        /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16); */

        position: absolute;
        left: 10px;
        top: 10px;
        right: 10px;
        bottom: 10px;

        z-index: 90;

        padding: 0;

        pointer-events: none; // allow clicking through to the
    }

    /****************************
        responsive breakpoints
    */

    // default
    width: 100%;
    //width: calc(100vw - 0);
    //width: 100vw;
    //min-width: 90vw;
    //max-width: 2000px;

    @media only screen and (min-width: 1200px) {
        // 1200 and over
        //width: 990px;
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        // 992 - 1199
        //width: 990px;
    }

    @media only screen and (max-width: 850px) and (min-width: 768px) {
        // 768 - 992
        /* width: 723px; */
        .quiz-title {
            left: 70px;
            right: 70px;
            font-size: 20px;
            //background: blue !important;
        }
    }

    @media only screen and (max-width: 767px) and (min-width: 400px) {
        width: 100%;

        .quiz-title {
            display: none;
            left: 60px;
            right: 60px;
            font-size: 18px;
        }
    }
    @media only screen and (max-width: 399px) {
        // 400 and lower
        width: 100%;

        .quiz-title {
            display: none;
            left: 60px;
            right: 60px;
            font-size: 16px;
        }
    }
`;

export const QuizPanelContents = styled.div`
    position: relative;

    //height: 460px;
    padding: 80px 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    // Style for "Answer the"
    font-family: Avenir, serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;

    // Text style for "5 question"
    .bold {
        font-family: Avenir, serif;
        font-weight: 800;
    }

    @media only screen and (min-width: 1200px) {
        // 1200 and over
        font-size: 30px;
        padding: 80px 20px; // 80 tb, 20 lr
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        // 992 - 1199
        font-size: 30px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
        font-size: 26px;
        padding: 40px 10px; // 80 tb, 20 lr
        height: unset;
    }
    @media only screen and (max-width: 767px) {
        // 767 and lower
        font-size: 20px;
        padding: 10px;
        height: unset;
    }
`;

const QuizPanel = (
    {
        children,
        colors,
        current_question_index,
        hideProgressHeader,
        isLoading,
        level,
        onCancel,
        onProgressSelect,
        progress,
        setOpen,
        title,
        ...props
    }
) => {
    const isSmall = useMedia('(min-width: 640px)');
    // console.log('isSmall', isSmall);
    let level_title = challenge_titles[level];

    // if (level !== null && title !== null) {
    //     if (level_title) {
    //         title = `${level_title.toUpperCase()}: ${title}`;
    //     }
    // }
    // console.log('QuizProps', props);
    // console.log('progress', props.progress);
    // const progressDots = useMemo(() => {
    //     console.log('progressDots', props.progress);
    //     return (
    //         <QuizProgress
    //             color={props.colors ? props.colors.progressColor : null}
    //             progress={props.progress}
    //             current={props.current_question_index}
    //             onSelect={props.onProgressSelect}
    //         />
    //     );
    // }, [props.colors, props.current_question_index, props.onProgressSelect, props.progress]);

    /* NOTE: REMOVE 'overflow-scroll' here to have a fixed quiz header */
    return (
        // <QuizPanelStyle className={`quiz-panel ${  props.className || ''}`}>
        <QuizPanelStyle
            // tw='bg-red-400'
            className='quiz-panel new-design'
            tw='background[#7569d6] h-full overflow-scroll sm:overflow-auto'
            {...props}
        >
            {!hideProgressHeader && (
                <QuizHeader
                    tw='background[linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)] hidden sm:block'
                    className='quiz-header '
                    level={level}
                    title={title}
                    progress={progress}
                    onCancel={onCancel}
                    setOpen={setOpen}
                    isLoading={isLoading}
                >
                    {isSmall && (
                        <QuizProgress
                            color={colors ? colors.progressColor : null}
                            progress={progress}
                            current={current_question_index}
                            onSelect={onProgressSelect}
                        />
                    )}
                </QuizHeader>
            )}

            {/* NOTE: add ' overflow-scroll' here to have a fixed quiz header */}
            <div
                className='quiz-panel-contents'
                tw='background[#7569d6] flex-grow' // 'overflow-scroll'
            >
                {children}
            </div>

            {/* <div className='inner-shadow-overlay' /> */}
        </QuizPanelStyle>
    );
};

export default QuizPanel;
