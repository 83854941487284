import React, { useMemo } from 'react';
import { RudderstackProvider, useRudderStack } from './RudderstackProvider';

const Context = React.createContext();

export const AnalyticsProvider = ({ children }) => {
    const value = {};
    return (
        <Context.Provider value={value}>
            <RudderstackProvider>
                {children}
            </RudderstackProvider>
        </Context.Provider>
    );
};

export const useAnalytics = (pageName = null, pageCategory = null, pageProperties = null) => {
    const context = React.useContext(Context);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }

    const rudderstack = useRudderStack();

    React.useEffect(() => {
        if(!rudderstack) {
            console.log('[rudderstack useAnalytics] rudderstack not ready');
            return;
        }
        if (pageName && rudderstack) {
            console.log('[rudderstack useAnalytics] pageName:', pageName, pageCategory, pageProperties ?? {} );
            rudderstack.page(pageCategory, pageName, pageProperties);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(() => ({
        track(...args) {
            console.log('[Analytics] Track:', ...args);
            if (rudderstack) {
                rudderstack.track(...args);
            }
        },
        page(...args) {
            console.log('[Analytics] Page:', ...args);
            if (rudderstack) {
                rudderstack.track(...args);
            }
        },
        identify(...args) {
            console.log('[Analytics] Identify:', ...args);
            if (rudderstack) {
                rudderstack.identify(...args);
            }
        },
        getAnonymousId() {
            console.log('[Analytics] getAnonymousId()');
            if (rudderstack) {
                return rudderstack.getAnonymousId();
            }
            return null;
        },
        reset() {
            console.log('[Analytics] reset()');
            if (rudderstack) {
                rudderstack.reset();
            }
        }
    }), [rudderstack]);
};

export default AnalyticsProvider;
