import React from 'react';
import { styled } from 'twin.macro';
import TabMenu from 'components/TabMenu';
import { useStudent, useTeacher } from 'state/User/UserState';

const TabContainer = styled.div`
    width: 100%;
    height: 74px;
    padding: 0;
    font-size: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    @media only screen and (max-width: 600px) {
        height: 100%;
    }
`;

const HomeTabs = ({ className, activeTab, onSelectTab, tabs }) => {
    const teacher = useTeacher();

    return (
        <TabContainer className={`tab-menu-container ${className}`}>
            <TabMenu
                tabs={tabs}
                activeTab={activeTab}
                onSelectTab={onSelectTab}
                color='#ffffff'
                selectedColor='#ffffff'
                leftAlign='flex-start'
                teacher={teacher}
            />
            <div className='border' />
        </TabContainer>
    );
};
export default HomeTabs;
