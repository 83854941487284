import { initQuizSession, beginQuizSession, endQuizSession } from 'modules/quiz/session';
import { useDispatch, useSelector } from 'react-redux';
import {
    enableSubmitButton,
    freeEntryTextChanged,
    getNextQuestionIndex,
    nextQuestion,
    quizFinished,
    setAnswerData,
    setQuestionChoice,
    setQuizContextState,
    setQuizSessionState,
    submitQuestionAnswer
} from 'modules/quiz/context';
import {
    answerDataSelector,
    freeEntryTextSelector,
    getButtonStates,
    getFreeEntryText,
    getQuestion,
    getQuestionText,
    getQuiz,
    getQuizContext,
    getQuizInstanceQuestions,
    getQuizProgress,
    getQuizSession, getQuizStore,
    quizInstanceSelector,
    quizSessionSelector
} from 'modules/quiz/selectors';
import { toJS } from 'utils';
import useSWR from 'swr';

import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { heapEvent } from '../context/analytics/HeapProvider';
import { useAssignmentActions } from '../state/Student/AssignmentsState';
import { ENABLE_SUBMIT_BUTTON, QUIZ_STATE_COMPLETED, QUIZ_STATE_RUNNING_BUT_FINISHED, SET_SESSION_STATE } from '../modules/quiz/constants';

// export const getNextQuestionIndexSelector = createSelector([getNextQuestionIndex], (index) => index);


// heap_event('quiz_begin', {
//     quiz_id: quiz_session?.quiz_id,
//     level: level
// });

export function useQuizSession() {
    // const dispatch = useDispatch();
    // const nextQuestionIndex = useSelector(getNextQuestionIndex);
    // const questions = useSelector(getQuizInstanceQuestions);
    // console.log('[*useQuizSession] next_question_index:', nextQuestionIndex);
    // console.log('[*useQuizSession] question_count:', questions?.count());

    const quiz_session = useSelector(quizSessionSelector);

    // const beginQuiz = useCallback((level = null) => {
    //     // begin the quiz session - this will get a quiz instance from the server, set parameters and start the first question
    //     let quizConfig = {};
    //     console.log('[useQuizSession] calling beginQuizSession()', quizConfig, level);
    //
    //     // post analytics events
    //     heap_event('quiz_begin', {
    //         quiz_id: quiz_session?.quiz_id,
    //         level: level
    //     });
    //
    //     dispatch(beginQuizSession(quizConfig, level));
    // }, [dispatch, quiz_session]);

    // const submitAnswerAction = async () => {
    //     // called when the user clicks "Submit" on a question
    //     const quizIsFinished = nextQuestionIndex >= questions?.count();
    //     console.log('[*submitAnswerAction] next_question_index:', nextQuestionIndex);
    //     console.log('[*submitAnswerAction] question_count:', questions?.count());
    //     console.log('[*submitAnswerAction] quizIsFinished:', quizIsFinished);
    //
    //     // submit an answer
    //     await dispatch(submitQuestionAnswer(quizIsFinished));
    //
    //     // TODO: move this to the server
    //     // if this is the final question go ahead and submit the quiz
    //     if (nextQuestionIndex >= questions?.count()) {
    //
    //         // disable the next button
    //         // dispatch({ type: ENABLE_SUBMIT_BUTTON, enable: false });
    //
    //         // submit the question
    //         const response = await dispatch(quizFinished());
    //
    //         // the quiz is finished, update assignments
    //         const { assignments } = response;
    //         if (assignments) {
    //             assignments.forEach(updateAssignment);
    //         }
    //
    //         // re-enable the next button
    //         dispatch(enableSubmitButton(true));
    //     }
    // };

    // const { updateAssignment } = useAssignmentActions();
    // const nextQuestionAction = () => {
    //     console.log('[*nextQuestionAction] next_question_index:', nextQuestionIndex);
    //     console.log('[*nextQuestionAction] question_count:', questions?.count());
    //     // check if the quiz is finished
    //     if (nextQuestionIndex >= questions.count()) {
    //         // mark quiz as now complete - this will
    //         dispatch(setQuizSessionState(QUIZ_STATE_COMPLETED));
    //         return;
    //     }
    //
    //     // advance to the next question, and check if the quiz is finished
    //     return dispatch(nextQuestion())
    //         .then((response) => {
    //             console.log('nextQuestion:', response);
    //             if (response) {
    //                 // the quiz is finished, update assignments
    //                 const { assignments } = response;
    //                 if (assignments) {
    //                     assignments.forEach(assignment => updateAssignment(assignment));
    //                 }
    //             }
    //         });
    // };

    // set the answer data
    // const setAnswerDataCallback = useCallback((data = null) => {
    //     dispatch(setAnswerData(data));
    // }, [dispatch]);

    // const enableSubmitButtonCallback = useCallback((enable = true) => {
    //     dispatch(enableSubmitButton(enable));
    // }, [dispatch]);

    // getQuiz(state, quiz_id)
    return {
        store: useSelector((state) => toJS(getQuizStore(state))),       // ['quiz']
        session: quiz_session, // ['quiz', 'session'],

        // quiz
        quiz: useSelector((state) => toJS(getQuiz(state, quiz_session?.quiz_id))),    // ['quiz', 'quizzes', quiz_id]

        // instance (results)
        instance: useSelector(quizInstanceSelector),        // ['quiz', 'session', 'instance']

        // context
        context: useSelector((state) => toJS(getQuizContext(state))), // ['quiz', 'session', 'context']
        progress: useSelector((state) => toJS(getQuizProgress(state))), // ['quiz', 'session', 'context', 'progress']
        button_states: useSelector(getButtonStates),        // ['quiz', 'session', 'context', 'button_state']

        // current question
        question: useSelector((state) => toJS(getQuestion(state))), // ['quiz', 'session', 'context', 'question']
        question_text: useSelector(getQuestionText),        // ['quiz', 'session', 'context', 'question', 'question_text']

        // current response
        freeEntryText: useSelector(freeEntryTextSelector),  // ['quiz', 'session', 'context', 'response', 'free_entry']
        answerData: useSelector(answerDataSelector)        // ['quiz', 'session', 'context', 'response', 'free_entry']
        ////////
        // actions

        // quiz session actions
        // initQuizSession: useCallback( (quiz_id, checkInstance = true, level = null) => dispatch(initQuizSession(quiz_id, level)), [dispatch] ),
        // beginQuiz,
        // endQuizSession: useCallback(() => dispatch(endQuizSession()), [dispatch]),
        // setChoice: (index) => dispatch(setQuestionChoice(index)),
        // textChanged: (text) => dispatch(freeEntryTextChanged(text)),
        // enableSubmitButton: enableSubmitButtonCallback,
        // setAnswerData: setAnswerDataCallback,
        // submitAnswer: submitAnswerAction,
        // nextQuestion: nextQuestionAction
    };
}

export function useQuizContext() {
    return toJS(useSelector(getQuizContext));
}


export function useQuizDefinition(quizId) {
    const { data } = useSWR(`/api/fullquizzes/${quizId}`);
    return data;
}

