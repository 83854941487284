import React from 'react';
import tw, { styled, css } from 'twin.macro';

import TimesTableMathStar from './ImageCards/TimesTableMathStar';
import TimesTableCrushedIt from './ImageCards/TimesTableCrushedIt';
import TimesTableFirstQuiz from './ImageCards/TimesTableFirstQuiz';
import TimesTableFirstVideo from './ImageCards/TimesTableFirstVideo';

const CardContainer = styled.div(({ level }) => [
    tw`p-0 flex flex-col rounded-lg relative overflow-hidden`
]);

const LargeImageCard = ({ image }) => {

    const ImageMap = {
        'times-table-first-video': <TimesTableFirstVideo image={image} />,
        'times-table-first-quiz': <TimesTableFirstQuiz image={image} />,
        'times-table-crushed-it': <TimesTableCrushedIt image={image} />,
        'times-table-math-star': <TimesTableMathStar image={image} />
    };

    return (
        <>
            <CardContainer
                tw='hidden md:flex pl-0 p-0 justify-end h-[342px]'
                css={[
                    css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`
                ]}
            >
                {ImageMap[image]}
            </CardContainer>

            <CardContainer
                tw='md:hidden justify-end h-[230px]'
                css={[css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`]}
            >
                {ImageMap[image]}
            </CardContainer>
        </>
    );
};

export default LargeImageCard;
