import React from 'react';
import BalanceText from 'react-balance-text';
import tw, { styled, css } from 'twin.macro';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import RecordIndicator from 'components/RecordIndicator';
import { level_titles } from '../../app/constants/constants';
import { wrapTitleParens } from '../../lib/string';

const CardContainer = styled.div(({ $level }) => [
    tw`p-5 cursor-pointer flex flex-col rounded-lg relative overflow-hidden`,
    $level === 1 && tw`hover:[box-shadow:0 0 10px 10px #ffffff22] active:[box-shadow:0 0 10px 10px #ffffff44]`,
    $level === 2 && tw`hover:[box-shadow:0 0 10px 10px #F2B845] active:[box-shadow:0 0 10px 10px #F2B845]`,
    $level === 3 && tw`hover:[box-shadow:0 0 10px 10px #24A6D3] active:[box-shadow:0 0 10px 10px #24A6D3]`
]);

const buttonLevels = [
    'silver-dtc',
    'silver-dtc',
    'gold-dtc',
    'platinum-dtc'
];

const actionLevels = [
    'unlock-video',
    'silver-record',
    'gold-record',
    'platinum-record'
];

const LargeQuizCard = ({ video, action }) => {
    const navigate = useNavigate();

    const handleClick = (event) => {
        event.stopPropagation();
        navigate(`/quiz/${video.quiz_id}`);
    };

    const actionLevel = actionLevels.indexOf(action);
    let level = actionLevel;
    let verb = 'Take the';
    let buttonVerb = 'START';
    if (video?.stats) {
        if (video.stats.current_level <= actionLevel) {
            // if the current level is lower than the level of the action,
            // then use the current level
            level = video.stats.current_level;
            verb = 'Take the ';
            buttonVerb = 'START';
            if (video.stats.quiz_levels[level]?.attempts >= 1) {
                verb = 'Continue the';
                buttonVerb = 'Continue';
            }

        } else if (video.stats.current_level > actionLevel) {
            // if the current level is higher than the level of the action,
            // then use the action's level. This is a review level.
            level = actionLevel;
            verb = 'Review the';
            buttonVerb = 'Review';
        }
    }

    // get the style for the button
    const buttonLevel = buttonLevels[level];
    return (
        <>
            <CardContainer
                tw='hidden md:flex pl-10 p-5 justify-end h-[342px]'
                $level={level}
                css={[
                    css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`
                ]}
                onClick={handleClick}
            >
                <div tw='absolute bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[88px] w-[88px] flex items-center justify-center top-[32px] left-[40px] z-20'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='47' height='53' fill='none'>
                        <path
                            fill='#fff' fillRule='evenodd'
                            d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z'
                            clipRule='evenodd' />
                    </svg>
                </div>
                <div tw='h-[88px] w-[88px] z-10 absolute top-[32px] left-[90px]'>
                    <RecordIndicator level={level} dtc={true} />
                </div>
                <div tw='text-[1.35rem] lg:text-[1.15rem] xl:text-[1.35rem] text-white font-semibold max-w-[100%]'>
                    <BalanceText>{verb} {level_titles[level]} Challenge for “{video?.title}”  </BalanceText>
                </div>
                <div tw='flex justify-between items-end w-full h-[58px] mt-6'>
                    <div>
                        <Button
                            tw='min-w-[150px] lg:min-w-[115px] xl:min-w-[150px]'
                            variant={buttonLevel}
                            onClick={handleClick}
                        >
                            <div tw='text-sm md:text-base font-medium pl-2 lg:pl-1'>
                                {buttonVerb}
                            </div>
                        </Button>
                    </div>

                </div>
            </CardContainer>

            <CardContainer
                tw='md:hidden justify-end h-[240px]'
                css={[css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`]}
                onClick={handleClick}
            >
                <div tw='absolute  bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[54px] w-[54px] flex  items-center justify-center top-[12px] left-[15px] z-20 rounded'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='34' height='40' fill='none'>
                        <path
                            fill='#fff' fillRule='evenodd'
                            d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z'
                            clipRule='evenodd' />
                    </svg>
                </div>
                <div tw='h-[53px] w-[53px] z-10 absolute top-[12px] left-[45px] '>
                    <RecordIndicator level={level} dtc={true} />
                </div>
                <div tw='text-[1.15rem] text-white font-normal max-w-[100%]'>
                    <BalanceText>{verb} {level_titles[level]} Challenge for “{video?.title}”  </BalanceText>
                </div>
                <div tw='flex justify-between items-end w-full mt-4'>
                    <div>
                        <Button
                            tw='min-w-[120px] h-[40px] pt-2.5 font-semibold'
                            variant={buttonLevel}
                            onClick={handleClick}
                        >
                            <div tw='text-sm md:text-base font-medium'>
                                {buttonVerb}
                            </div>
                        </Button>
                    </div>
                </div>
            </CardContainer>
        </>
    );
};

export default LargeQuizCard;