import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { ROLE_STUDENT } from '../../constants';
import { on_event } from '../../context/analytics/Events';
import { useAccount, useEnvironmentVariable, useUser } from '../../state/User/UserState';


// beacon identifiers
export const muzology_student_beacon = process.env.REACT_APP_HELPSCOUT_STUDENT_BEACON ?? '4db65187-7e6e-46cf-b656-eb4863010487';
export const muzology_parent_beacon = process.env.REACT_APP_HELPSCOUT_PARENT_BEACON ?? '4db65187-7e6e-46cf-b656-eb4863010487';
export const muzology_teacher_beacon = process.env.REACT_APP_HELPSCOUT_TEACHER_BEACON ?? '4db65187-7e6e-46cf-b656-eb4863010487';

// const muzology_student_beacon = '4db65187-7e6e-46cf-b656-eb4863010487';
// const muzology_teacher_beacon = '4db65187-7e6e-46cf-b656-eb4863010487';
// const muzology_parent_beacon = '4db65187-7e6e-46cf-b656-eb4863010487';

// install the helpscout beacon
export const installHelpScout = (helpscout_signature, user, isSmall) => {
    if (isSmall) {
        window.Beacon('destroy');
    }

    // help scout set up
    else if (window.Beacon) {

        if (user.role === ROLE_STUDENT) {
            //student beacon
            window.Beacon('init', muzology_student_beacon);

            let email = `student_${user.id}@muzology.com`;
            if (user.email) {
                email = user.email;
            }

            window.Beacon('identify', {
                signature: helpscout_signature ?? '',
                name: `${user.id} student`,
                email: email,
                user_id: user.id,
                username: user.username,
                user_role: 'Student'
            });

            window.Beacon('config', {
                enablePreviousMessages: false,
                labels: {
                    whatMethodWorks: ''
                }
            });

            window.Beacon('on', 'open', () => {
                // TODO: convert to on_event
                on_event('helpscout-opened', {
                    event_category: 'Beacon',
                    user_id: user.id,
                    username: user.username,
                    user_role: 'Student'
                });

                window.Beacon('on', 'article-viewed', (article) => {
                    on_event('helpscout-article-viewed', {
                        event_category: 'Beacon',
                        article_id: article.id,
                        user_id: user.id,
                        username: user.username,
                        user_role: 'Student'
                    });
                });

                window.Beacon('on', 'search', (query) => {
                    // console.log(query.query);
                    on_event('helpscout-search', {
                        event_category: 'Beacon',
                        query: query.query,
                        user_id: user.id,
                        username: user.username,
                        user_role: 'Student'
                    });
                });
            });

        } else {
            // parents & teachers
            const role = user.is_parent ? 'Parent' : 'Teacher';
            const init = user.is_parent ? muzology_parent_beacon : muzology_teacher_beacon;

            window.Beacon('init', init);

            window.Beacon('identify', {
                signature: helpscout_signature ?? '',
                name: user.username,
                email: user.email,
                user_id: user.id,
                username: user.username,
                user_role: role
            });

            // connect beacon to google analytics
            window.Beacon('on', 'open', () => {
                on_event('helpscout-opened', {
                    event_category: 'Beacon',
                    user_id: user.id,
                    username: user.username,
                    user_role: role
                });
            });

            window.Beacon('on', 'helpscout-article-viewed', (article) => {
                on_event('article-viewed', {
                    event_category: 'Beacon',
                    article_id: article.id,
                    username: user.username,
                    user_id: user.id,
                    user_role: role
                });
            });
            window.Beacon('on', 'helpscout-search', (query) => {
                // console.log(query.query);
                on_event('search', {
                    event_category: 'Beacon',
                    query: query.query,
                    username: user.username,
                    user_id: user.id,
                    user_role: role
                });
            });

        }
    }
};


export const HelpScout = ({ children }) => {
    const user = useUser();
    const signature = useEnvironmentVariable('helpscout_signature');
    const { width } = useWindowSize();
    const isSmall = width < 900;

    // install helpscout beacon
    // React.useEffect( () => {
    //     if( window.Beacon ) {
    //         window.Beacon('init', '4d2e046a-e4a1-4157-853a-195987624072');
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // disable helpscout for non-teachers (CoreCivic modification)
    const account = useAccount();
    const { disable_helpscout, disable_helpscout_teachers } = account;

    // install helpscout beacon
    useEffect(() => {
        console.log('[HelpScout] Install HelpScout:', user, signature);
        if (!user?.is_teacher && disable_helpscout) {
            return null;
        }
        if (signature && user) {
            installHelpScout(signature, user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, signature, disable_helpscout, user?.is_teacher]);


    useEffect(() => {
        console.log('[HelpScout] Install HelpScout');
        if (!user) {
            return;
        }

        if (user.is_staff) {
            console.log('[HelpScout] Disable HelpScout staff');
            return;
        }

        if ((!user?.is_teacher) && disable_helpscout) {
            console.log('[HelpScout] Disable HelpScout student');
            return;
        }

        if (user?.is_teacher && disable_helpscout_teachers) {
            console.log('[HelpScout] Disable HelpScout teacher');
            return;
        }

        if (signature && user) {
            console.log('[HelpScout] Installing HelpScout:', user, signature);
            installHelpScout(signature, user, isSmall);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signature, user?.id, user.is_staff, user.is_teacher, account?.name, isSmall]);
    return children;
};

export default HelpScout;