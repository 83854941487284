import * as React from 'react';

function SvgClassIcon17(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF8C4B' />
            <path
                d='M29.415 12.109a2.129 2.129 0 00-1.504-1.508A18.067 18.067 0 0023.685 10L30 16.323a18.113 18.113 0 00-.585-4.214zM10.585 27.89c.193.734.77 1.31 1.504 1.508.963.258 2.467.565 4.225.601L10 23.677c.032 1.755.33 3.25.585 4.214zm-.416-6.786l8.718 8.734c5.343-.782 10.032-4.787 10.948-10.944l-8.722-8.734c-5.343.783-10.032 4.787-10.944 10.944zm12.795-4.984a.324.324 0 01.455 0l.456.456a.324.324 0 010 .455l-1.141 1.141 1.141 1.142a.324.324 0 010 .455l-.456.456a.324.324 0 01-.455 0l-1.141-1.141-.912.915 1.141 1.141a.324.324 0 010 .456l-.455.455a.324.324 0 01-.456 0L20 20.912l-.911.91 1.14 1.142a.324.324 0 010 .455l-.455.456a.324.324 0 01-.456 0l-1.14-1.141-1.142 1.141a.324.324 0 01-.455 0l-.456-.456a.324.324 0 010-.455l1.141-1.141-1.141-1.137a.324.324 0 010-.456l.456-.456a.324.324 0 01.455 0l1.141 1.141.912-.911-1.141-1.141a.324.324 0 010-.456l.455-.455a.324.324 0 01.456 0L20 19.092l.911-.91-1.14-1.142a.324.324 0 010-.455l.455-.456a.324.324 0 01.456 0l1.14 1.141 1.142-1.149z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon17;
