import React, { useCallback, useRef, useState } from 'react';
import tw, { css } from 'twin.macro';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { useNotifications } from '../../context/NotificationProvider';
import NotificationItem from '../NotificationItem/NotificationItem';
import NotificationDialog from './NotificationDialog';


const NotificationDropdown = ({ children }) => {
    const { notifications, removeNotification, selectNotification, closeNotification } = useNotifications();
    const [isOpen, setOpen] = useState(false);
    const ref = useRef();

    const onCloseAllNotifications = useCallback( () => {
        for (const notification of notifications) {
            if (!notification.selected) selectNotification(notification.id);
        }
    },[notifications, selectNotification]);

    const handleClose = useCallback( () => {
        if( isOpen ) {
            onCloseAllNotifications();
            setOpen(false);
        }
    },[isOpen, onCloseAllNotifications]);

    const badge_value = notifications?.filter((notification) => !notification.selected).length;
    return (
        <div ref={ref} tw='relative' >
            <div
                tw='flex mr-3 sm:mr-0'
                onClick={() => {
                    if (isOpen) {
                        console.log('on click is open');
                        handleClose();
                    }
                    else {
                        console.log('on click is closed');
                        setOpen(true);
                    }
                }}
            >
                {children}
                {(badge_value > 0) && <div tw='bg-red-500 text-white absolute rounded-full w-[18px] h-[18px] text-sm right-1 -top-1 flex items-center justify-center'>
                    {badge_value}
                </div>}
            </div>
            {isOpen && (<NotificationDialog notifications={notifications} onClose={handleClose} parentRef={ref}/>)}
        </div>
    );
};

export default NotificationDropdown;
