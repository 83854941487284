import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { Button, TabNavigator } from 'components';
import { Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withScrollLayout } from '../../../layout/Layouts';
import VideoSessionContainer from '../../child/VideoSessionContainer/VideoSessionContainer';
import { MyAchievements, MyRecords, MyVideoStats } from '../../student/StatsContainer/tabs';
import { useStudent, useStudents } from '../../../../state/Teacher/ClassState';
import { useStudentStats } from '../../../../state/Student/VideoStats';
import { useVideos } from '../../../../state/CatalogState';
import { useActivityForUser } from '../../../../state/User/ActivityState';

const items = [
    {
        name: 'Video Stats',
        path: 'video-stats'
    },
    {
        name: 'Records',
        path: 'records'
    },
    {
        name: 'Achievements',
        path: 'achievements'
    }
];

const StudentDashboard = () => {
    const match = useMatch('/student/:student_id/:tab_name');
    const { student_id = null, tab_name = 'stats' } = match?.params ?? {};
    // const { student_id } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);

    const students = useStudents();
    const vids = useVideos();

    const stats = useStudentStats(student_id);
    const student = useStudent(student_id);
    const activity = useActivityForUser(student_id);

    // get this student from students
    const studentData = useMemo(() => {
        if (students && student_id) {
            return students.find((s) => String(s.user.id) === String(student_id));
        }
    }, [student_id, students]);

    // attach this student's 'stats' to each video
    const data = useMemo(() => {
        if (studentData?.video_stats) {
            const videoArray = Object.values(vids);
            return videoArray.map((vid) => {
                let s = {};
                if (studentData?.video_stats) {
                    s = studentData?.video_stats[vid.id];
                }
                return { ...vid, stats: s };
            });
        }
    }, [vids, studentData]);

    // const path = `/student/${student_id}/`;

    // filter stats for only unlocked videos
    const unlockedStats = useMemo(() => Object.values(stats ?? {}).filter((stat) => stat.current_level > 0), [stats]);
    // filter videos that are unlocked
    const unlockedVideos = useMemo(() => {
        return vids.reduce((acc, vid) => {
            const stats = unlockedStats.find(({ video_id }) => video_id === vid.id);
            if (stats) {
                return [...acc, { ...vid, stats }];
            } else {
                return acc;
            }
        }, []);
    }, [vids, unlockedStats]);

    // const handleTabs = (item) => {
    //     switch (true) {
    //         case item.path === items[0].path:
    //             return <MyVideoStats tableData={unlockedVideos} activity={activity} stats={unlockedStats} />;
    //         case item.path === items[1].path:
    //             return <MyRecords activity={activity} stats={stats} videos={data} />;
    //         case item.path === items[2].path:
    //         default:
    //             return <MyAchievements videos={data} teacher={true} />;
    //     }
    // };

    return (
        <>
            <Helmet>
                <title>{`Student (${student?.full_name})`}</title>
            </Helmet>

            <div tw='z-50 py-4 px-8 flex justify-between items-center w-full top-0 right-0 background[linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)]'>
                <h1 tw='text-2xl font-medium text-white'>{student?.user.first_name && `${student.user.first_name} Stats`}</h1>
                <Button variant='Review' onClick={() => navigate(-1)}>
                    Done
                </Button>
            </div>
            <div tw='h-full px-6 py-10 relative background[linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)]'>
                <div tw='[maxWidth:var(--layout-width)] mx-auto'>
                    <TabNavigator
                        activeTabPath={tab_name}
                        // activeTab={activeTab}
                        // activeTabHandler={setActiveTab}
                        variant='enclosed'
                        items={items}
                    />
                    <Routes>
                        <Route path='video-stats' element={<MyVideoStats tableData={unlockedVideos} activity={activity} stats={unlockedStats} />} />
                        <Route path='records' element={<MyRecords activity={activity} stats={stats} videos={data} />} />
                        <Route path='achievements' element={<MyAchievements videos={data} teacher={true} />} />
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default StudentDashboard;
