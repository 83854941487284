import React from 'react';
import SlideWrapper from '../../Components/SlideWrapper';
import { css } from 'twin.macro';
import ActionBox from '../../Components/ActionBoxThird';
import LightBulb from '../imgs/lightblulb.png';
import PenAndPaper from '../imgs/penandpaper.png'
import WaveBackground from '../../imgs/WaveBackground.png';
import SmallDots from '../../imgs/SmallDots.png'
import BackgroundImage from 'lib/components/BackgroundImage';

const ContainerStyle = css`
    background: rgba(100, 65, 185, 0.60);
    backdrop-filter: blur(10px);
    min-height: 180px;
    padding: 10px 0; 
    /* max-width: 425px; */
`;

const WaysToUse = ({ nonNullState, teacherName }) => {
    return (
        <SlideWrapper slide={'blue-pink'}>
            <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light' />
            <div tw='bg-[#76E6FC] h-[123px] md:h-[123px] w-[3px] absolute top-1/2 transform -translate-y-1/2' />
            <div tw='absolute top-5 lg:top-[210px] banner_xl:top-44  right-10 lg:right-0 lg:left-10 '>
                <img src={SmallDots} />
            </div>
            <div tw='font-Poppins font-semibold flex flex-col lg:flex-row gap-4 min-h-[400px] lg:min-h-[280px] banner_xl:min-h-[250px] px-4 pt-6'>
                {!nonNullState ?
                    <div>
                        <div tw='font-Poppins  text-white text-2xl lg:text-3xl  max-w-full md:max-w-[300px] text-left banner_xl:mt-1 '>Let's get started, <br /> {teacherName}!</div>
                        <div tw='font-Poppins  text-white text-lg lg:text-xl  w-[200px] banner_xl:w-[300px] mt-2'>How to Make the Most of Muzology </div>
                    </div>
                    :
                    <div>
                        <div tw='font-Poppins  text-white text-2xl lg:text-3xl  max-w-full md:max-w-[200px] mb-8 md:mb-0 md:mt-3 banner_xl:mt-1 text-left '>Ways to Use Muzology</div>
                        <div tw='font-Poppins text-white text-xl w-[200px] banner_xl:w-[300px] mt-2'>How to Make the Most of Muzology </div>
                    </div>
                }

                <div tw='flex flex-col md:flex-row gap-2 lg:gap-6 w-full'>
                    <div tw='w-full md:w-1/2 '>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-white text-lg w-full font-normal'>
                                    <div tw='font-semibold text-lg text-center mt-3'>How to Make Math Class Rock! </div>
                                    <div tw=''>
                                        <div tw='text-base max-w-[305px] mx-auto mt-2 font-normal text-center'>Play Muzology Videos for your Class</div>
                                        <ul tw='text-left text-base max-w-[365px] mx-auto list-disc mt-2 pl-10'>
                                            <li>
                                                To introduce and review topics.
                                            </li>
                                            <li>
                                                To set a fun vibe when students arrive.
                                            </li>
                                            <li>
                                                To engage in math talk with students.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='hidden lg:block absolute top-[45%] transform -translate-y-1/2 -left-[60px] xl:-left-[52px] '>
                                <div tw='max-w-[140px] max-h-[140px]'>
                                    <img src={LightBulb} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw='mt-5 md:mt-0 w-full md:w-1/2'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-white text-lg w-full font-normal'>
                                    <div tw='font-semibold text-lg text-center mt-3'>And, Gain Back Time!   </div>
                                    <div tw=''>
                                        <div tw='text-sm lg:text-base max-w-[372px] mt-1.5 mx-auto'>Differentiate Instruction with Playlists </div>
                                        <ul tw='text-left text-base list-disc max-w-[440px] ml-auto pl-[4.7rem] mt-1.5'>
                                            <li>
                                                Allocate class time for students to work on assigned playlists. (Simultaneously, provide individualized instruction.)
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='hidden lg:block absolute top-1/3 transform -translate-y-1/2 -left-10 banner_xl:-left-8 '>
                                <div tw='max-w-[105px] max-h-[105px]'>
                                    <img src={PenAndPaper} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </SlideWrapper>
    );
};

export default WaysToUse;