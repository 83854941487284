// warmup message for each level
import React from 'react';

export const QuizFinishedConditionalMessage = {
    0: [
        "You can do it. Watch the video and retake the Challenge.",
        "Math is a journey. Watch the video and keep going!",
        "You're warming up. Watch the video and try again!"
    ],
    10: [
        "You can do it. Watch the video and keep going!",
        "Math is a journey. Watch the video and retake the Challenge.",
        "You're warming up. Watch the video and try again!"
    ],
    20: [
        "You're getting it. Remember, practice makes perfect",
        "You're getting there. Watch the video and keep going!",
        "You're on your way! Keep up this great momentum.",
    ],
    30: [
        "Good start! Every problem solved is a step forward.",
        "Solid start! You're starting to unlock the secrets of math.",
        "Keep it up! Every question you tackle makes you stronger."
    ],
    40: [
        "Great job! Keep going.",
        "Almost halfway there! Your hard work is showing.",
        "You're doing great! Keep it up."
    ],
    50: [
        "Halfway there! You've got this.",
        "Super job! Keep up the great work.",
        "You're halfway through the Challenge! Stay focused and confident."
    ],
    60: [
        "Fantastic! Your math skills are really taking off.",
        "Let's go! Keep up the great work,",
        "You've passed the halfway mark! Let's go."
    ],
    70: [
        "Amazing! Go get that 100%.",
        "You're on a roll! Keep it up.",
        "Impressive! Go get that 100%."
    ],
    80: [
        "Incredible! That 100% is yours.",
        "Flex those skills! Two more questions to go,",
        "Bravo! That's how it's done."
    ],
    90:[
        "Amazing job! How's it feel to be a math rock star?",
        "You're a star! Keep crushing it!",
        "You own this Challenge! Go get your 100%."
    ],
    100:    [
        "Congratulations! You're a TOP Muzology performer.",
        "We're seriously impressed! Perfect score.",
        "That's how it's done! You absolutely crushed this. Bravo!"
    ]
}

export const QuizIntroMessage = [
    'See what you already know!</br>Answer these 5 warm-up questions and unlock the music video.',
    'Get <strong>100%</strong> to earn a <strong>Silver</strong> Record',
    'Get <strong>100%</strong> to earn a <strong>Gold</strong> Record',
    'Get <strong>100%</strong> to go <strong>Platinum</strong>'
];


export const WarmupFinishedMessage = {
    0: [
        "You've got this. Watch the music video to learn math the fun way.",
        "You can do it. Watch the music video to learn math the fun way.",
        "Watch the music video and see what you learn!"
    ],
    20: [
        "Good start. Watch the music video and keep going!",
        "You've got this. Now, watch the music video and see what you learn.",
        "You're getting there. Watch the music video to keep going."
    ],
    40: [
        "Solid start. Watch the music video to keep learning.",
        "You're getting it. Watch the video to sharpen your skills.",
        "Keep going! Watch the video and sharpen your skills."
    ],
    60: [
        "Great job! Watch the video to keep going.",
        "Excellent. Watch the music video to fine-tune your skills.",
        "Nice! Watch the music video and fine-tune your skills."
    ],
    80: [
        "Fantastic! Watch the music video to perfect your skills.",
        "Amazing job! Watch the music video to perfect your skills.",
        "You're a star! Watch the music video to perfect your skills.",
    ],
    100: [
        "Bravo! A perfect score! Watch the music video and prepare for the Challenges.",
        "You rock! Watch the music video and prepare for the Challenges.",
        "Perfection! Watch the music video and prepare for the Challenges."
    ],
    33: [
        "Good start. Watch the music video and keep going!",
        "You've got this. Now, watch the music video and see what you learn.",
        "You're getting there. Watch the music video to keep going."
    ],
    67: [
        "Great job! Watch the video to keep going.",
        "Excellent. Watch the music video to fine-tune your skills.",
        "Nice! Watch the music video and fine-tune your skills."
    ]
};

export const QuizFinalScoreMessage = [
    // 0-3
    'Watch the video again and then retake the Challenge. You can do this!',
    'Watch the video again and then retake the Challenge. You can do this!',
    'Watch the video again and then retake the Challenge. You can do this!',
    // 4-5
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    'You’re on the right track! Check out the video one more time. Then, come back and crush this Challenge.',
    // 6
    'Nice work! You’re 4 questions away from earning the record. Take a peek at the video one more time. You got this!',
    // 7
    'Great job!! You’re so close. Only 3 more questions to go!',
    // 8
    'Fantastic score!! Only 2 more questions and the record is yours.',
    // 9
    'You’re a math rock star!! Answer 1 more question correctly to earn the record',
    // 10
    'CONGRATULATIONS!!! You did it!! You earned the record.'
];

