import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import PlaylistHoverBG from 'static/icons/DTC-NavBar/hoverBackgrounds/Playlist2.png';
import ProfileHoverBg from 'static/icons/DTC-NavBar/hoverBackgrounds/Profile2.png';
import HomeHoverBg from 'static/icons/DTC-NavBar/hoverBackgrounds/Home2.png';
import ProgressHoverBg from 'static/icons/DTC-NavBar/hoverBackgrounds/Progress2.png';
import { useMedia } from 'react-use';

const StyledNavLink = styled(NavLink)(({ $bgColor }) => [
    tw`text-[#C2C3CB] text-xs font-medium min-h-[3.5rem] relative`,
    ({ $bgColor = 'HOME' }) => containerVariants[$bgColor]
]);

const HideSideBar = css`
    @media (max-width: 900px) {
        display: none;
    }
`;

const HideBottomBar = css`
    @media (min-width: 900px) {
        display: none;
    }
`;

const containerVariants = {
    // Named class sets
    HOME: css`
      &:hover, &.active {
        background-image: url(${HomeHoverBg});
        background-size: 100% 100%;
        color: white;
        font-weight: 600;
      }

      &.active {
        background-image: none;
      }
      @media (max-width: 900px) {
        &:hover, &.active {
          background: none;
        }
      }
    `,

    STATS: css` 
    &:hover { 
        background-image: url(${ProfileHoverBg}); 
        background-size: 100% 100%;
    }
    &.active {
        background-image: none;
        //background-image: url(${ProfileHoverBg}); 
        background-size: 100% 100%;
        color: white;
        font-weight: 600;
    }  
    @media (max-width: 900px) {
        &:hover, &.active { 
            background: none; 
        }
    }
    `,
    PROFILE: css` 
    &:hover { 
        background-image: url(${ProfileHoverBg}); 
        background-size: 100% 100%;
    } 
    &.active {
        //background-image: url(${ProfileHoverBg}); 
        background-size: 100% 100%;
        color: white;
        font-weight: 600;
    }  
    @media (max-width: 900px) {
        &:hover, &.active { 
            background: none; 
        }
    }
    `

};

const StyledNavigationLink = ({ path, pathMatch, handleCloseSearch, title, activeIcon, icon, onClick }) => {
    const match = useMatch(pathMatch);
    const isSmall = useMedia('(max-width: 768px)');

    const handleClick = () => {
        // call handleCloseSearch if it exists
        if (handleCloseSearch) {
            handleCloseSearch();
        }

        // call onClick if it exists
        if (onClick) {
            onClick();
        }
    };

    return (
        <StyledNavLink
            key={path}
            to={path}
            aria-current='page'
            tw='text-[#C2C3CB] text-xs font-medium w-full px-4 active:opacity-50 hover:text-white'
            $bgColor={title}
            onClick={handleClick}
            data-testid={isSmall ? `mobile-navigation-button-${title}` : `navigation-button-${title}`}

        >
            <div tw='flex items-center z-10 relative h-full md:flex' css={[HideSideBar]}>
                {match ? activeIcon : icon}
                <div tw='ml-2 hidden iPadPro:block'> {title}</div>
            </div>

            <div tw='flex items-center z-10 relative h-full pt-2 flex-col' css={[HideBottomBar]}>
                {match ? activeIcon : icon}
                <div> {title}</div>
            </div>

        </StyledNavLink>
    );
};

export default StyledNavigationLink;
