import gold_star from 'static/images/particles/gold_star.png';
import purple_ball from 'static/images/particles/purple.png';
import blue_ball from 'static/images/particles/blue.png';
import white_ball from 'static/images/particles/white.png';

export const bouncing_balls = {
    name: 'Bouncing Balls',
    particles: {
        number: {
            value: 80,
            density: {
                enable: false
            }
        },

        shape: {
            type: ['images'],
            images: [
                { src: white_ball, height: 20, width: 20 },
                { src: purple_ball, height: 20, width: 20 },
                { src: blue_ball, height: 20, width: 20 }
            ]
        },

        size: {
            value: 40,
            random: true
        },

        move: {
            out_mode: 'out',
            enable: true,
            random: true,
            speed: 32
        },

        line_linked: {
            enable: false
        }
    }
};

export const silver_confetti = {
    name: 'Silver Confetti',
    background: {},
    particles: {
        number: {
            value: 100,
            density: {
                enable: true
            }
        },
        size: {
            value: 10,
            random: true
        },
        shape: {
            type: 'polygon',
            polygon: {
                nb_sides: 4
            }
        },
        move: {
            enable: true,
            direction: 'bottom',
            out_mode: 'out',
            speed: 16
        },
        line_linked: {
            enable: false
        }
    }
};

export const spinning_stars = {
    name: 'Spinning Stars',
    background: {},
    particles: {
        number: {
            value: 100,
            density: {
                enable: false
            }
        },

        shape: {
            type: 'images',
            images: [{ src: gold_star, height: 20, width: 20 }]
        },

        size: {
            value: 40,
            random: true
        },

        move: {
            out_mode: 'out',
            enable: true,
            random: true
        },
        line_linked: {
            enable: false
        }
    }
};

export const shooting_stars = {
    name: 'Shooting Stars',
    background: {},
    particles: {
        number: {
            value: 100,
            density: {
                enable: false
            }
        },

        shape: {
            type: 'images',
            images: [{ src: gold_star, height: 20, width: 20 }]
        },

        size: {
            value: 40,
            random: true
        },

        move: {
            out_mode: 'out',
            enable: true,
            random: true
        },
        line_linked: {
            enable: false
        }
    }
};

export const falling_snow = {
    name: 'Falling Snow',
    background: {},
    particles: {
        number: {
            value: 50,
            density: {
                enable: false
            }
        },
        size: {
            value: 10,
            random: true
        },
        move: {
            enable: true,
            direction: 'bottom',
            out_mode: 'out'
        },
        line_linked: {
            enable: false
        }
    }
};

export const demo1 = {
    name: 'Demo 1',
    particles: {
        number: { value: 80, density: { enable: true, value_area: 800 } },
        color: { value: '#ffffff' },
        shape: { type: 'circle', stroke: { width: 0, color: '#000000' }, polygon: { nb_sides: 5 }, image: { src: 'img/github.svg', width: 100, height: 100 } },
        opacity: { value: 0.5, random: false, anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false } },
        size: { value: 3, random: true, anim: { enable: false, speed: 40, size_min: 0.1, sync: false } },
        line_linked: { enable: true, distance: 150, color: '#ffffff', opacity: 0.4, width: 1 },
        move: {
            enable: true,
            speed: 6,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
        }
    },
    interactivity: {
        detect_on: 'canvas',
        events: { onhover: { enable: true, mode: 'repulse' }, onclick: { enable: true, mode: 'push' }, resize: true },
        modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
        }
    },
    retina_detect: true
};

export const bouncing_dots = {
    background: {
        color: {
            value: '#0d47a1'
        }
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: 'push'
            },
            onHover: {
                enable: true,
                mode: 'repulse'
            },
            resize: true
        },
        modes: {
            push: {
                quantity: 4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: {
            value: '#ffffff'
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        collisions: {
            enable: true
        },
        move: {
            directions: 'none',
            enable: true,
            outModes: {
                default: 'bounce'
            },
            random: false,
            speed: 6,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 80
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: 'circle'
        },
        size: {
            value: { min: 1, max: 5 }
        }
    },
    detectRetina: true
};
