import React from 'react';

const MyPlaylistsSVG = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.02569 10.0995C3.44636 15.5737 9.16039 19.0477 11.9648 20.1004C14.9537 19.1059 20.4326 15.6253 20.9307 10.0995C21.8248 3.19458 13.8003 2.25514 11.9648 6.30151C10.1998 2.25515 2.49986 3.25685 3.02569 10.0995Z' stroke='#E775EF' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
};

export default MyPlaylistsSVG;