import React, { useState } from 'react';
import tw from 'twin.macro';

import Modal from '../Modal/Modal.js';
import Button from '../Button/Button.js';
import CloseButton from '../AnswerKey/CloseButton';

const RemoveStudentDialog = ({ studentId, changeStudentName, open, onClose }) => {
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');

    const handleSubmitEditStudentName = () => {
        changeStudentName(studentId, newFirstName, newLastName);
        onClose();
        setNewFirstName('');
        setNewLastName('');
    };

    return (
        <Modal
            isOpen={open}
            setIsOpen={(value) => {
                if (!value)
                    onClose();
            }}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            <div tw='bg-white rounded flex-col justify-center relative rounded-t-lg ' style={{ minWidth: '800px' }}>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-2xl rounded-t-lg'>Edit Name</div>
                <div tw='flex flex-col justify-start p-4'>
                    <label tw='mb-2 uppercase font-medium text-sm text-EarieBlack'>Students First Name</label>
                    <input
                        tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12'
                        type='text'
                        value={newFirstName}
                        onChange={(e) => setNewFirstName(e.target.value)}
                    />
                </div>
                <div tw='flex flex-col justify-start p-4'>
                    <label tw='mb-2 uppercase font-medium text-sm text-EarieBlack'>Students Last Name</label>
                    <input
                        tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12'
                        type='text'
                        value={newLastName}
                        onChange={(e) => setNewLastName(e.target.value)}
                    />
                </div>
                <div tw='flex justify-center space-x-2 py-4'>
                    <Button
                        variant='RosterTable'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Keep
                    </Button>
                    <Button onClick={() => handleSubmitEditStudentName()}>Edit Name</Button>
                </div>
                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={onClose}
                />
            </div>

        </Modal>
    );
};

export default RemoveStudentDialog;
