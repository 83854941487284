import React from 'react';
import 'twin.macro';
import { useLocation, useParams } from 'react-router-dom';
import { HomePageVideoRows } from 'components';
import { useCourseAndVideos } from 'state/CatalogState';
import { useVideosWithStats } from 'state/Student/VideoStats';
import VideoSlider from 'components/VideoSlider';
import PlaylistVideoRow from '../PlaylistVideoRow';
import ProgressModal from '../ProgressModal';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const CreateVideoGrid = (group) => {
    return (
        <div tw='pt-14' key={group.id} id={group.id}>
            <PlaylistVideoRow
                group={group}
                title={group.title}
                groupTitle={''}
                favorites={null}
                student_id={null}
                allVideos={null}
            />
        </div>
    )
};

const VideoLoading = () =>  <LoadingScreen />;


const PlaylistContainer = () => {
    const location = useLocation();
    const { course_id } = useParams();
    const timesTableCourse = useCourseAndVideos('b9c9297a-d351-4bfc-85fb-21e41ad344dc');
    const selectedCourseGroup = timesTableCourse?.groups.find(group => group.id === course_id)
 

    if(!timesTableCourse) return VideoLoading

    return (
        <div tw='bg-[#1C0057] h-full min-h-full pb-10 '>
            <div tw='2xl:[max-width:var(--layout-width)] mx-auto max-h-full'>
                {CreateVideoGrid(selectedCourseGroup)}
            </div>
        </div>
    );
};


export default PlaylistContainer;