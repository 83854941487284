import React from 'react';
import ActiveHomeSVG from '../../static/icons/DTC-NavBar/Home/ActiveHomeSVG';
import HomeSVG from '../../static/icons/DTC-NavBar/Home/HomeSVG';
import ActiveProgressSVG from '../../static/icons/DTC-NavBar/Progress/ActiveProgressSVG';
import ProgressSVG from '../../static/icons/DTC-NavBar/Progress/ProgressSVG';


export const NavItemColors = [
    '#46A6F8',
    '#02AC6F',
    '#ECA121'
];

const NavItems = {
    parent: [
        {
            path: '/home',
            pathMatch: '/home',
            title: 'HOME',
            exact: true,
            icon: <HomeSVG />,
            activeIcon: <ActiveHomeSVG />
        },
        {
            path: '/stats',
            pathMatch: '/stats/*',
            title: 'STATS',
            icon: <ProgressSVG style={{ color: NavItemColors[1] }} />,
            activeIcon: <ActiveProgressSVG style={{ color: NavItemColors[1] }} />,
            clickedIcon: <ActiveProgressSVG style={{ color: NavItemColors[1] }} />
        }
    ],

    child: [
        {
            path: '/home',
            pathMatch: '/home',
            title: 'HOME',
            exact: true,
            icon: <HomeSVG />,
            activeIcon: <ActiveHomeSVG />
        },
        {
            path: '/stats',
            pathMatch: '/stats/*',
            title: 'STATS',
            icon: <ProgressSVG style={{ color: NavItemColors[1] }} />,
            activeIcon: <ActiveProgressSVG style={{ color: NavItemColors[1] }} />,
            clickedIcon: <ActiveProgressSVG style={{ color: NavItemColors[1] }} />
        }
    ]
};

export default NavItems;
