import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import ReactHoverObserver from 'react-hover-observer';
import { HoverBoxSmall } from 'components';

const HeartIcon = ({ favorite, toggleFavoriteVideo }) => {
    let icon = null;
    let copy = null;
    const [isHovering, setIsHovering] = useState(false);

    if (favorite) {
        icon = (
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19' fill='none'>
                <path fill='#E63AA3' d='M1.69 7.828c.39 4.815 5.68 7.871 8.277 8.797 2.768-.875 7.84-3.937 8.302-8.797.828-6.074-6.602-6.9-8.302-3.34-1.634-3.56-8.764-2.68-8.277 3.34Z' />
            </svg>
        );
        copy = 'remove from favorites playlist';
    } else {
        icon = (
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='19' fill='none'>
                <path stroke='#979FA6' strokeLinecap='round' strokeLinejoin='round' d='M1.69 7.828c.39 4.815 5.68 7.871 8.277 8.797 2.768-.875 7.84-3.937 8.302-8.797.828-6.074-6.602-6.9-8.302-3.34-1.634-3.56-8.764-2.68-8.277 3.34Z' />
            </svg>
        );
        copy = 'add to favorites playlist';

    }

    return (
        <ReactHoverObserver
            onHoverChanged={() => setIsHovering(true)}
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div tw='cursor-pointer relative' onClick={toggleFavoriteVideo}>
                {icon}
                <HoverBoxSmall isHovering={isHovering}>
                    <div tw='uppercase text-white text-xs text-center'>{copy}</div>
                </HoverBoxSmall>
            </div>
        </ReactHoverObserver>
    );
};

HeartIcon.propTypes = {
    favorite: PropTypes.bool
};

export default HeartIcon;

