import * as React from 'react';

function SvgThreeDotsVert(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 8 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <ellipse
                cx={4}
                cy={23.821}
                rx={3.811}
                ry={4}
                transform='rotate(-90 4 23.821)'
                fill='#5A5353'
            />
            <ellipse
                cx={4}
                cy={13.339}
                rx={3.811}
                ry={4}
                transform='rotate(-90 4 13.339)'
                fill='#5A5353'
            />
            <ellipse
                cx={4}
                cy={3.811}
                rx={3.811}
                ry={4}
                transform='rotate(-90 4 3.811)'
                fill='#5A5353'
            />
        </svg>
    );
}

export default SvgThreeDotsVert;
