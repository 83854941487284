import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css, styled } from 'twin.macro';
import ReactHoverObserver from 'react-hover-observer';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { useMedia } from 'react-use';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'state/User/UserState.js';
import { useSearch } from 'context/SearchProvider';
import VideoThumbnail from '../VideoThumbnail';
import VideoCardInfo from './VideoCardInfo/VideoCardInfo';
import VideoPlayingInfo from './VideoCardInfo/VideoPlayingInfo';
import VideoCardIndicator from './VideoCardInfo/VideoCardIndicator';
import { usePlaylistVideos } from 'state/Student/AssignmentsState';

// import { useWebTourState } from 'state/WebTourState';
const PlayerWrapperCss = css`
    position: relative;
    //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    border-radius: 5px;
    //filter: drop-shadow(4px 4px 4px black);
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 0.375rem/** 6px */;
        border-top-right-radius: 0.375rem/** 6px */;
        overflow: hidden;
    }
`;

const Container = styled.div`
    position: relative;

    .item {
        cursor: pointer;
    }

    &.grow {
        transform: scale(1.2);
        z-index: 1;
        transition: transform 0.1s ease-in-out;
        //transition-delay: 1.5s;
        transform-origin: ${(props) => props.$transformOrigin};
    }
`;

const VideoCard = (props) => {
    const navigate = useNavigate();
    const { search, setDropDown } = useSearch();
    const isMedium = useMedia('(min-width: 768px)');
    const { video, teacher, showTitle, libraryView } = props;
    const videoRef = useRef();
    const { is_parent_account } = useAccount();
    const [isHovering, setIsHovering] = useState(false);
    const videosWithStats = useVideosWithStats();
    const playlistVideos = usePlaylistVideos();

    const transformOrigin = useMemo(() => {
        if (isMedium) {
            // 3 columns
            if (props.index % 3 === 0) {
                return '10% 50%';
            } else if (props.index % 3 === 2) {
                return '90% 50%';
            }
        }
        return '50% 50%';
    }, [props.index, isMedium]);

    const handleNavigate = () => {
        navigate(`/video/${video?.id}`);
        search(null);
        setDropDown(false);
    };

    const formatPlaylistInclusion = (video, playlistVideos, videosWithStats) => {
        let findVideo = playlistVideos.find((v) => v.id === video.id);
        let matchVideo = findVideo && videosWithStats.find((v) => v.id === findVideo.id);
        if (!findVideo || matchVideo.playlist.length === 0) return false;
        return true;
    };

    // teacher has assigned video
    let teacherHasAssignedVideo = null;
    if (video && teacher) {
        teacherHasAssignedVideo = formatPlaylistInclusion(video, playlistVideos, videosWithStats);
    }

    return (
        <Container
            className={`video-card-container ${isHovering ? 'grow' : 'normal'} `}
            $transformOrigin={transformOrigin}
            data-testid='video-card'
            data-video-id={video?.id}
            data-video-title={video?.title}
            aria-label={video?.title}
        >
            <ReactHoverObserver
                onHoverChanged={(value) => {
                    // console.log('[VideoCard] hover changed', value);
                    // if( value.isHovering )
                    if (!props.disableHover) setIsHovering(value.isHovering);
                }}
                onMouseOver={({ e, setIsHovering, unsetIsHovering }) => {
                    if (e.target.closest('#no-hover')) {
                        unsetIsHovering();
                    } else {
                        setIsHovering();
                    }
                }}
                onMouseOut={() => {
                    // setIsHovering(null);
                    //if (!props.disableHover) setIsHovering(false);
                }}
                hoverDelayInMs={250}
                hoverOffDelayInMs={250}
            >
                <div className='item video-card-item'>
                    <VideoThumbnail
                        video={video}
                        className='img video-card-thumbnail'
                        onClick={() => handleNavigate()}
                        videoDuration={video?.duration}
                        isNew={video?.new_video}
                        isMini={video?.is_mini}
                        isAssigned={video?.assignment && !teacher}
                        teacher={teacher}
                        assignment={video?.assignment}
                        teacherHasAssignedVideo={teacherHasAssignedVideo}
                    />
                    <VideoCardInfo
                        showViewCount={true}
                        className='normal'
                        showTitle={showTitle}
                        video={video}
                        teacher={teacher}
                        libraryView={libraryView}
                        onMouseOver={() => setIsHovering(false)}
                        tour={props.index === 1}
                        isHovering={isHovering}
                        dtc={is_parent_account}
                        onClick={handleNavigate}
                    />

                </div>

                {isHovering && (
                    <div
                        css={[PlayerWrapperCss]}
                        tw='absolute top-0 left-0 right-0 backgroundColor[transparent] shadow-none '
                    // id={props.index === 1 && 'video-page-tour-video-thumbnail'}
                    >
                        <div
                            onClick={() => handleNavigate()}
                            style={{ backgroundImage: `url(${video?.url_thumbnail})` }}
                            tw='relative w-full paddingTop[56.25%] bg-contain backgroundColor[transparent] cursor-pointer rounded-t-md'
                        >
                            <ReactPlayer
                                playing={isHovering}
                                url={video?.url_hls}
                                width='100%'
                                height='100%'
                                controls={false}
                                // light={video.url_thumbnail}
                                playIcon={<div />}
                                className='react-player'
                                muted={true}
                                ref={videoRef}
                            />

                        </div>

                    </div>
                )}
            </ReactHoverObserver>
        </Container>
    );
};

VideoCard.propTypes = {
    video: PropTypes.object
};

export default VideoCard;
