import React from 'react';
import AchievementRibbon from 'static/images/achievements/achievement-ribbon.png';
import tw, { css, styled } from 'twin.macro';
import NullAchievement from 'static/icons/NullAchievements.png';

const LineClamp = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const Container = styled.div(({ $variant }) => [
    tw`relative py-8  w-full h-screen maxHeight[18rem] flex flex-col items-center justify-end bg-AchievementTransparency rounded-2xl`,
    // Use props  to conditionally style your components
    $variant === 'plaque' && tw` bg-EarieBlack rounded-none max-h-80 `,
    $variant === 'nullAchievement' && tw`h-full`
]);

const BorderGradient = css`
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box; /* !importanté */
    border: solid 10px transparent; /* !importanté */

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -10px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    }
`;

const AchievementCard = ({ image, title, variant, nullAchievement, videoTitle }) => {
    if (nullAchievement) {
        return (
            <Container variant='nullAchievement'>
                <div tw='px-8 flex flex-col items-center justify-center'>
                    <div>
                        <img src={NullAchievement} />
                    </div>
                    <p tw='text-base font-extrabold text-center mt-4' style={{ color: 'rgba(224, 224, 229, 0.5)' }}>
                        Watch videos and take Challenges to earn Achievements!
                    </p>
                </div>
            </Container>
        );
    }
    if (variant === 'plaque')
        return (
            <Container $variant={variant} style={{ maxHeight: '365px', maxWidth: '310px' }} css={[BorderGradient]}>
                <img tw='w-auto h-auto max-h-48 absolute m-auto left-0 right-0 top-8 z-20' src={image} />
                <img tw='w-7/12 h-auto absolute m-auto left-0 right-0 top-32 z-10' src={AchievementRibbon} />
                <div tw='text-white text-xl font-extrabold font-sans mt-8'>{title}</div>
            </Container>
        );
    else
        return (
            <Container
                $variant={variant}
                aria-label={`Achievement: ${title} on ${videoTitle}`}
                css={[
                    css`
                        max-width: 232px;
                        @media (max-width: 768px) {
                            max-width: 100%;
                            margin-top: 10px;
                        }
                    `
                ]}
            >
                <img aria-hidden={true} tw='w-auto h-auto max-h-40 absolute m-auto left-0 right-0 top-9 z-20' src={image} />
                {/* <img tw='w-auto h-auto absolute m-auto left-0 right-0 top-36 z-10' src={AchievementRibbon} /> */}
                <div aria-hidden={true} tw='text-white text-lg md:text-base lg:text-sm xl:text-base font-extrabold font-sans'>{title}</div>
                <div aria-hidden={true} tw='text-white text-sm font-normal font-sans text-center' css={[LineClamp]}>
                    {videoTitle}
                </div>
            </Container>
        );
};

export default AchievementCard;
