import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import { useClassActions, useClassStudents } from 'state/Teacher/ClassState';
import { useForm } from 'react-hook-form';
import { ONBOARD_STAGE_SETUP_CHILD } from 'app/AccountOnboard/OnboardStages';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useOnboardStageFinished } from 'state/OnboardState';
import StateSelectListBox from '../StateSelectListBox/StateSelectListBox';

const STEP_USERNAME = 1;

const CheckboxContainer = styled.div`
    .checkbox-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 14px 0 0;

        .checkbox-item {
            /* width: 50%; */
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            @media (max-width: 700px) {
                width: 100%;
            }
        }

        .container {
            display: block;
            position: relative;
            margin: 0;
            padding-left: 35px;
            margin-bottom: 15px;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            line-height: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: 30px;

            .title {
                font-weight: 400;
                font-size: 18px;
                margin-right: 32px;
                line-height: normal;
                color: #1A1717;

                a {
                    color: white;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }

        /* Hide the browser's default checkbox */

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 27px;
            width: 27px;
            background-color: #fff;
            border: 1px solid #1A1717;
            border-radius: 99px;
        }

        /* On mouse-over, add a grey background color */

        .container:hover input ~ .checkmark {
            background: #1cc8e1;
            border: 1px solid #1cc8e1;
        }

        /* When the checkbox is checked, add a blue background */

        .container input:checked ~ .checkmark {
            background: #1cc8e1;
        }

        /* Create the checkmark/indicator (hidden when not checked) */

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */

        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */

        .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .mobile-style {
        @media (max-width: 400px) {
            margin-bottom: 30px;
        }
    }
`;

const CheckBox = styled.div`
        .checkbox-item {
            /* width: 50%; */
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            @media (max-width: 700px) {
                width: 100%;
            }
            @media (max-width: 640px) {
                margin-top: 25px;
            }
        }

        .container {
            display: block;
            position: relative;
            margin: 0;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            line-height: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: 30px;
            max-width: 500px;
            margin: 16px auto;
            margin-bottom: 15px;
            @media (max-width: 640px) {
                font-size: 16px;
            }
            .title {
                font-weight: 400;
                font-size: 18px;
                margin-left: 35px;
                line-height: normal;
                color: #1A1717;
                text-align: left;
                @media (max-width: 640px) {
                font-size: 16px;
            }
                a {
                    color: white;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }

        /* Hide the browser's default checkbox */

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 27px;
            width: 27px;
            background-color: #fff;
            border: 1px solid #1A1717;
            border-radius: 99px;
        }

        /* On mouse-over, add a grey background color */

        .container:hover input ~ .checkmark {
            background: #1cc8e1;
            border: 1px solid #1cc8e1;
        }

        /* When the checkbox is checked, add a blue background */

        .container input:checked ~ .checkmark {
            background: #1cc8e1;
        }

        /* Create the checkmark/indicator (hidden when not checked) */

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */

        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */

        .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    

    .mobile-style {
        @media (max-width: 400px) {
            margin-bottom: 30px;
        }
    }
`;

const ChildInfoForm = ({ classes, setChildren, addCount, studentCount, twoChildren, studentId, setShowTopicSelection, studentName, defaultState, saveStep }) => {

    const { addStudent } = useClassStudents(classes[0]?.id);
    const { register, handleSubmit, watch, errors: formErrors, setError, reset, clearErrors } = useForm({});
    const stageFinished = useOnboardStageFinished();
    const [state, setState] = useState(defaultState);
    const { fetchStudents, addStudents } = useClassActions();

    const selectState = (data) => {
        setState(data.abbreviation);
        clearErrors('state');
    };

    const onSubmitFinished = (data) => {
        if (!state) {
            setError('state', { type: 'focus' }, { shouldFocus: true });
            return;
        }
        data['state'] = state;
        console.log('SUBMIT DATA', data);
        const payload = { user_id: studentId, ...data };
        apiClient.post('/api/studentprofile/', payload)
            .then(response => {
                const { data } = response;
                console.log(data);
                if (data?.user?.id) {
                    addStudents([data]);
                    stageFinished(ONBOARD_STAGE_SETUP_CHILD);
                }
                fetchStudents();
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);
            });
    };

    const onSubmitAddAnotherChild = (data) => {
        if (!state) {
            setError('state', { type: 'focus' }, { shouldFocus: true });
            return;
        }
        data['state'] = state;
        const payload = { user_id: studentId, ...data };
        apiClient.post('/api/studentprofile/', payload)
            .then(response => {
                const { data } = response;
                console.log(data);
                if (data?.user?.id) {
                    addCount(studentCount + 1);
                    saveStep(STEP_USERNAME);
                    reset();

                }
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);
            });
    };


    const makeCheckbox = () => {
        let checkbox = Array(14).fill(0);
        return checkbox.map((x, index) => {
            let label = index;
            if (index === 0)
                label = 'K';
            else if (index === 13)
                label = 'Other';
            return (
                <CheckboxContainer key={index}>
                    <div className='checkbox-grid'>
                        <label className='container'>
                            <div className='title'>{label}</div>
                            <input
                                type='radio'
                                name='grade'
                                ref={register({
                                    required: true
                                })}
                                value={index}
                            />
                            <span className='checkmark' />
                        </label>
                    </div>
                </CheckboxContainer>
            );
        });
    };
    // proficiency_level
    return (
        <form id='child-info-form'>
            <div tw='mt-10 mb-2 font-bold  mx-auto max-w-[380px] text-EarieBlack text-base xsm:text-lg'>
                What’s {studentName}’s grade?
            </div>

            <div tw='w-full max-w-[600px] mx-auto'>
                <div tw='grid grid-cols-3 xsm:grid-cols-5 '>
                    {makeCheckbox()}
                </div>
                {formErrors.grades && <div tw='pt-2 text-red-500'>* This field is required.</div>}

            </div>
            <div tw='mt-10 mb-2 font-bold  mx-auto max-w-[380px] text-EarieBlack text-base xsm:text-lg'>
                Confirm {studentName}’s state.
            </div>
            <div tw='mt-2 mx-auto flex justify-center'>
                <StateSelectListBox
                    tw='max-w-[420px] bg-white'
                    selected={state}
                    onSelectItem={selectState}
                    cypressName='state-selection'
                    onBoard={true}
                />
            </div>
            {formErrors.state && <div tw='pt-2 text-red-500'>* This field is required.</div>}
            <div tw='mt-10 mb-2 font-bold  mx-auto max-w-[380px] text-EarieBlack text-base xsm:text-lg'>
                Describe {studentName}’s current math level?
            </div>
            <div tw='flex flex-col'>
                <CheckBox>
                    <label className='container' data-testid='grade-level' >
                        <div className='title'>
                            Is below grade level (behind/needs to catch up)</div>
                        <input
                            ref={register({
                                required: true
                            })}
                            name='proficiency_level'
                            type='radio'
                            value='below'
                        />
                        <span className='checkmark' />
                    </label>
                </CheckBox>
                <CheckBox>
                    <label className='container' >
                        <div className='title'>
                            Is at grade level (doing well/needs a little extra support)</div>
                        <input
                            ref={register({
                                required: true
                            })}
                            name='proficiency_level'
                            type='radio'
                            value='at'
                        />
                        <span className='checkmark' />
                    </label>
                </CheckBox>
                <CheckBox>
                    <label className='container' >
                        <div className='title'>
                            Is above grade level (advanced/excels at math)</div>
                        <input
                            ref={register({
                                required: true
                            })}
                            name='proficiency_level'
                            type='radio'
                            value='above'
                        />
                        <span className='checkmark' />
                    </label>
                </CheckBox>
                {formErrors.proficiency_level && <div tw='pt-2 text-red-500'>* This field is required.</div>}
            </div>

            <div tw='mt-10 flex justify-center gap-10'>
                <div>
                    <button
                        id='submit-button'
                        tw='py-2 px-8 bg-[#1CC8E1] rounded-lg text-sm font-extrabold leading-normal text-white cursor-pointer min-w-[200px] min-h-[40px]'
                        onClick={handleSubmit(twoChildren ? onSubmitAddAnotherChild : onSubmitFinished)}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </form>
    );
};

ChildInfoForm.propTypes = {
    anyProp: PropTypes.any
};

export default ChildInfoForm;