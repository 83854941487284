import React from 'react';
import tw, { css, styled } from 'twin.macro';

const Container = styled.div(({ slide, border }) => [
    tw`relative`,
    css` border-radius: 8px;`,
    slide === 'blue-pink' && css` background:  linear-gradient(266deg, #D53EBD 8.05%, #5A9CFE 86.07%);`,
    slide === 'purple-pink' && css` background: linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%);`,
    slide === 'green' && css` background: linear-gradient(266deg, #27DCD5 8.05%, #01AB6E 86.07%);`,
    slide === 'blue' && css`background: linear-gradient(266deg, #18ABDF 8.06%, #1470EE 86.07%);`

]);

const SlideWrapper = ({ slide, children }) => {
    return (
        <Container slide={slide}>
            {children}
        </Container>
    );
};

export default SlideWrapper;