import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';
import { Button, ClassIcon, Modal } from 'components';
import ReactCardFlip from 'react-card-flip';
import { useMeasure } from 'react-use';
import RecordIcon from 'components/RecordIcon';
import { useClassStudents } from '../../state/Teacher/ClassState';


const StyledAchievementDescription = styled.div`
    margin-bottom: 32px;
    text-transform: lowercase;

    &:first-letter {
        text-transform: capitalize;
    }
`;

const StudentNames = ({ c, students }) => {
    // Function to compare last names alphabetically
    function compareByLastName(a, b) {
        const lastNameComparison = a.user.last_name.localeCompare(b.user.last_name);
        if (lastNameComparison !== 0) {
            // If last names are different, sort by last name
            return lastNameComparison;
        } else {
            // If last names are the same, sort by first name
            return a.user.first_name.localeCompare(b.user.first_name);
        }
    }

    const reOrderStudentsByName = students.sort(compareByLastName)
    return reOrderStudentsByName.map((student, key) => {
        return (
            <div key={key} tw='mt-1 xsm:mt-0'>
                {student.user.last_name}, {student.user.first_name} 
            </div>
        );
    });
};

const studentHasRecord = (student, videoId, level) => {
    if (student?.video_stats && videoId) {
        const stats = student.video_stats[videoId];
        if (stats) {
            if (stats.current_level > level) {
                return true;
            }
        }
    }
    return false;
};

const Plaque = ({
    teacher,
    record,
    achievementImage,
    recordImage,
    recordText,
    multiplier,
    isAchievement,
    copy,
    achievementDescription,
    classes,
    popUpCompletion,
    recordCompletions,
    minHeight,
    videoId,
    level,
    tour
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [ref, { height }] = useMeasure();
    const { students: allStudents } = useClassStudents(null);
    // const { stepIndex } = useWebTourState();

    // get the students for each class
    const awardedClasses = classes?.map((cls, key) => {
        const awardedStudents = [];
        cls.students.forEach((studentId) => {
            const student = allStudents.find((s) => s.user.id === studentId);
            if (studentHasRecord(student, videoId, level)) awardedStudents.push(student);
        });
        return {
            cls,
            awardedStudents
        };
    });

    // useEffect(() => {
    //     if(stepIndex === 19 && tour) setIsOpen(true)
    // }, [stepIndex]);

    // determine if this is flippable
    const flippable = !teacher && !record;
    if (flippable) {
    }

    return (
        <button
            tabIndex={flippable ? 0 : -1}
            ref={ref}
            tw='maxWidth[310px]'
            onMouseEnter={() => setIsFlipped(flippable)}
            onMouseLeave={() => setIsFlipped(false)}
            onClick={() => {
                if (flippable)
                    setIsFlipped(!isFlipped);
            }}
        >
            <ReactCardFlip isFlipped={isFlipped} flipDirection='horizontal'>
                {/* Front of the card */}
                <div id='front'>
                    <div tw='p-3 background[linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)]'>
                        <div tw='py-4 bg-EarieBlack relative' style={{ minHeight: minHeight ? minHeight : '385px' }}>
                            {teacher ? (
                                <button
                                    id='awards-classes-status'
                                    onClick={() => setIsOpen(true)}
                                    type='button'
                                    tw='rounded-full z-10 absolute top-4 right-4 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                    aria-controls='open-modal'
                                    aria-expanded='false'
                                    aria-label='Open Class Progress Modal'
                                >
                                    <svg
                                        width='28' height='28' viewBox='0 0 28 28' fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM15 20.75C15 20.8875 14.8875 21 14.75 21H13.25C13.1125 21 13 20.8875 13 20.75V12.25C13 12.1125 13.1125 12 13.25 12H14.75C14.8875 12 15 12.1125 15 12.25V20.75ZM14 10C13.6075 9.99199 13.2337 9.83043 12.959 9.55C12.6842 9.26957 12.5303 8.8926 12.5303 8.5C12.5303 8.1074 12.6842 7.73043 12.959 7.45C13.2337 7.16957 13.6075 7.00801 14 7C14.3925 7.00801 14.7663 7.16957 15.041 7.45C15.3158 7.73043 15.4697 8.1074 15.4697 8.5C15.4697 8.8926 15.3158 9.26957 15.041 9.55C14.7663 9.83043 14.3925 9.99199 14 10Z'
                                            fill='white'
                                        />
                                    </svg>
                                </button>
                            ) : (
                                multiplier && (
                                    <span
                                        tw='h-11 w-11 fontSize[22px] font-extrabold rounded-full bg-white text-PinkCrayola flex justify-center items-center absolute top-4 right-4'>
                                        {multiplier}
                                    </span>
                                )
                            )}
                            <div tw='px-10 lg:px-14 mb-7 relative flex items-center justify-center'>
                                {teacher || record ? (
                                    <>
                                        <img tw='w-full max-w-xs' src={recordImage} alt='record' />

                                        {/* if variant record do not have text in the middle of record */}
                                        {recordText && <span tw='fontSize[32px] font-extrabold absolute text-white'>{recordText}</span>}
                                    </>
                                ) : (
                                    <div tw='relative pt-2'>
                                        <img tw='w-full max-w-xs relative z-20' src={achievementImage} alt='record' />
                                        {/* The yellow banner beneath the badge only shows if it's an achievement */}
                                        {isAchievement && !isFlipped && (
                                            <svg
                                                tw='w-3/4 maxWidth[160px] absolute left-1/2 bottom[-38%]  transform -translate-y-1/2 -translate-x-1/2'
                                                css={[
                                                    css`
                                                        @media only screen and (max-width: 627px) and (min-width: 460px) {
                                                            bottom: -25%;
                                                        }
                                                    `
                                                ]}
                                                viewBox='0 0 137 114'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path d='M.576.129h135.56V114l-70.49-22.872L.575 114V.129z' fill='#FCC458' />
                                            </svg>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div tw='p-4 text-center font-extrabold text-white'>
                                <h3 tw='text-xl'>
                                    {copy.title} {!isAchievement && 'Record'}
                                </h3>
                                <p tw='text-lg font-medium mx-auto max-w-[220px] '>{copy.videoTitle}</p>
                            </div>
                        </div>

                        <Modal
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            modalChildrenStyle={tw`w-full maxWidth[729px]  lg:maxWidth[929px] mx-0 xsm:mx-auto `}
                        >
                            <div tw='mx-0 xsm:mx-4 relative'>
                                <div
                                    tw='w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-tl-xl rounded-tr-xl relative'>
                                    <h1 tw='text-white text-2xl font-medium'>{copy.title}</h1>
                                    <div tw='flex space-x-1 items-center mr-5'>
                                        <h1 tw='text-white text-2xl font-medium '>{recordText}</h1>
                                        <div tw='h-6 w-6'>
                                            <RecordIcon level={level} />
                                        </div>
                                    </div>
                                </div>
                                <div tw='bg-white flex items-center justify-center px-3 pt-5 '>
                                    <h1 tw='text-EarieBlack text-2xl font-medium'>{popUpCompletion}</h1>
                                </div>
                                <div tw='bg-white maxHeight[582px] overflow-auto pt-6'>
                                    {awardedClasses &&
                                        awardedClasses.map(({ cls, awardedStudents }, key) => {
                                            if (awardedStudents.length > 0) {
                                                // get the students who got a record from this class
                                                return (
                                                    <div key={key} tw='px-6 pb-5'>
                                                        <div tw='flex items-center space-x-2 w-full bg-PicoteeBlue text-white px-4 py-2.5'>
                                                            <ClassIcon
                                                                width='29px'
                                                                height='29px'
                                                                style={{ marginRight: '10px' }}
                                                                name={cls.display_icon ?? 'ClassIcon00'}
                                                            />

                                                            <span tw='font-medium text-lg'>{cls.title}</span>
                                                        </div>
                                                        <div>
                                                            {awardedStudents?.length > 0 && (
                                                                <div
                                                                    tw='flex flex-wrap xsm:grid grid-cols-4 gap-4 w-full bg-GhostWhite px-4 py-2.5 text-EarieBlack text-sm font-medium'>
                                                                    <StudentNames c={cls} students={awardedStudents} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                </div>
                                <div tw='bg-white flex items-center justify-between px-6 py-4 rounded-bl-xl rounded-br-xl'>
                                    <Button onClick={() => setIsOpen(false)}>Done</Button>
                                </div>
                                <button
                                    tabIndex={0}
                                    tw='bg-white h-12 w-12 absolute -top-6 -right-6 rounded-full hidden xsm:flex justify-center items-center shadow-md cursor-pointer '
                                    onClick={() => setIsOpen(false)}
                                >
                                    <svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M16.705 1.257c-.71-.711-1.773-.711-2.482 0l-5.2 5.211-5.201-5.211c-.71-.711-1.773-.711-2.482 0-.71.71-.71 1.776 0 2.487l5.2 5.212-5.2 5.211c-.71.71-.71 1.777 0 2.487.355.356.827.474 1.3.474.473 0 .946-.118 1.3-.474l5.2-5.211 5.2 5.211c.355.356.828.474 1.301.474s.946-.118 1.3-.474c.71-.71.71-1.776 0-2.487l-5.2-5.211 5.2-5.212c.473-.71.473-1.777-.236-2.487z'
                                            fill='#6A7277'
                                        />
                                    </svg>
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
                {/* Back of card */}
                <div tw='p-3 background[linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)]' style={{ height: `${height}px` }}>
                    <div tw='h-full pt-8 pb-10 bg-EarieBlack'>
                        <div tw='mb-10 px-12 text-xl font-extrabold text-center text-white flex flex-col justify-center h-full'>
                            <StyledAchievementDescription tw='mb-8 lowercase'>{achievementDescription}</StyledAchievementDescription>
                            <p>You rock!</p>
                        </div>
                    </div>
                </div>
            </ReactCardFlip>
        </button>
    );
};

Plaque.propTypes = {
    teacher: PropTypes.bool,
    isAchievement: PropTypes.bool,
    achievementImage: PropTypes.string,
    copy: PropTypes.object
};

export default Plaque;
