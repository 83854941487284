import React from 'react';
import { styled } from 'twin.macro';
import RecordIcon from 'components/RecordIcon';
import LikeIconUnselected from 'images/icons/LikeIconUnselected';
import LikeIconSelected from 'images/icons/LikeIconSelected';
import white_arrow_right from 'static/images/white_arrow_right.png';

//<editor-fold desc="Style">
export const PrimaryButton = styled.button`
	border-radius: 20px;
	height: 40px;
	padding: 10px 80px;
	margin-top: 16px;
	margin-bottom: 16px;

	background-image: linear-gradient(100deg, #fb1681, #bb0784);

	font-family: 'Source Sans Pro', serif;
	font-size: 20px;
	font-weight: 600;

	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5) !important;

	color: white;

	cursor: pointer;
	user-select: none;

	:hover {
		background: linear-gradient(100deg, #e11670, #9b076a);
	}

	:active {
		background: linear-gradient(100deg, #ba155d, #6f0749);
		text-shadow: none;
	}
`;

export const FlatButton = styled.div`
    margin: ${(props) => props.m || '0'};
    padding: ${(props) => props.p || '0'};
    font-size: ${(props) => props.fontSize || 'inherit'};
    
    font-family: "Source Sans Pro",serif;

	font-weight: 600;

	background: transparent;

	color: #dddddd;

	cursor: pointer;
	user-select: none;

	:hover {
		color: white;
	}

	:active {
		background: #222222;
		text-shadow: none;
		color: white;
	}

	.text {
		font-family: 'Source Sans Pro';
		font-size: 20px;
		font-weight: 600;
	}
`;

/*
    ChallengeButton - rounded button with a record icon that says "Take Challenge"
*/

const ChallengeButtonContainer = styled.div`
	position: relative;
	user-select: none;

	@media only screen and (max-width: 768px) {
		font-size: 12px;
	}

	.record-div {
		position: absolute;
		left: 14px;
		top: 0;
		height: 100%;
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: 768px) {
			width: 25px;
			margin-top: 3px;
		}
	}

	.arrow-div {
		position: absolute;
		top: 0;
		right: 18px;
		bottom: 0;

		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const ChallengeButton = styled.button`
	border-radius: 24px;
	height: 48px;
	padding: 0 40px 0 56px;

	border: 4px solid #e637a8;
	background: #e637a8;

	font-family: Avenir, serif;
	font-weight: 500;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	display: inline-block;
    overflow: hidden;
    white-space: nowrap;
	cursor: pointer;

	@media only screen and (max-width: 768px) {
		height: 40px;
	}
	&:hover {
		border: 4px solid #91005e;
	}
`;

export const RecordButton = ({ level, onClick, cypress }) => {
    const recordIcon = <RecordIcon level={level || 0} />;
    return (
        <ChallengeButtonContainer>
            <div className='record-div'>{recordIcon}</div>

            <ChallengeButton
                style={{ background: '#e637a8' }}
                onClick={onClick}
                data-testid={cypress || 'RecordButton'}
                aria-label='Take Challenge'
            >
				TAKE CHALLENGE
            </ChallengeButton>

            <div className='arrow-div'>
                <img
                    className='arrow-img'
                    src={white_arrow_right}
                    alt='right arrow' />
            </div>
        </ChallengeButtonContainer>
    );
};

const FavoriteButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const FavoriteButton = ({ toggleFavorite, favorite }) => {
    return (
        <FavoriteButtonContainer>
            <FlatButton
                className='favorite-button'
                onClick={toggleFavorite}
                highlight={favorite}>
                {favorite ? <LikeIconSelected className='like-icon selected' /> : <LikeIconUnselected className='like-icon' />}
            </FlatButton>
        </FavoriteButtonContainer>
    );
};

/*
    used for teachers "Assign Now"
 */

export const NavBarButton = styled.button`
	width: 207px;
	height: 42px;
	border-radius: 21px;
	background-color: #e637a8;
	border: none;
	color: #ffffff;
	font-family: Avenir, serif;
	font-size: 16px;
	font-weight: 800;
	margin: auto 20px;
	cursor: pointer;

	@media only screen and (max-width: 515px) {
		width: 156px;
		height: 32px;
		font-size: 12px;
		margin: auto 12px;
	}

	&:hover {
		background-color: #b62c85;
	}

	&:active {
		background-color: #6e1b50;
	}

`;
