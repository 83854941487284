import { getCurrentTimestamp, toJS } from 'utils';
import {
    QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS,
    QUESTION_TYPE_FREE_ENTRY,
    QUESTION_TYPE_MULTIPLE_CHOICE,
    QUESTION_TYPE_MULTIPLE_SELECT,
    QUESTION_TYPE_TRUE_FALSE
} from '../../app/containers/shared/QuizSessionContainer/QuestionTypes/constants';

import { SET_BUTTON_STATE } from './constants';
import { getButtonStates, getQuestion, getQuizContext } from './selectors.js';

import { ADD_QUIZ_RESPONSE, BUTTON_STATE_CORRECT, BUTTON_STATE_INCORRECT, BUTTON_STATE_SELECTED, BUTTON_STATE_UNSELECTED } from './constants.js';
import { prepareFreeEntryText } from './util';

export function scoreMultipleChoice(context, question, buttons, response) {
    // console.debug('[MULTIPLE CHOICE] Score question: ', question);
    // console.debug('[MULTIPLE CHOICE]        context: ', context);
    // console.debug('[MULTIPLE CHOICE]        buttons: ', buttons);
    // console.debug('[MULTIPLE CHOICE]       response: ', response);

    let answers = null;

    // get the choices
    let choices = question.choices;

    let feedback_state = context.feedback_state;

    if (!feedback_state) {
        // the feedback state is an array of integers, one for each choice
        feedback_state = choices.map(() => 0);
    }

    let correct = false;
    // let submit = false;

    // check the answers
    for (let i = 0; i < choices.length; i++) {
        let choice = choices[i];
        // console.log('checking choice:', choice);

        let state = buttons[i] || false;

        // console.log(' button state:', state);

        if (state) {
            answers = choice.id;

            if (choice.correct && state) {
                // this answer was selected correctly
                // console.log('*** CORRECT!');

                // mark the feedback state
                feedback_state[i] = BUTTON_STATE_CORRECT;

                // this question is correct - score it
                correct = true;
            } else {
                // mark the feedback state
                feedback_state[i] = BUTTON_STATE_INCORRECT;

                correct = false;
            }

            // submit the question
            // submit = true;

            // we can exit this loop because only one choice should be selected
            break;
        }
    }

    // update response
    response.correct = correct;
    response.selected_choices = [answers]; // selected_choices must be set for the question to be considered answered
    response.feedback_state = feedback_state;
    return response;
}

export function scoreMultipleSelect(context, question, buttons, response) {
    console.log('ScoreMultipleSelect:', question);
    const choices = question.choices;

    // collect a list of selected choices
    let selected_choices = [];
    for (let i = 0; i < choices.length; i++) {
        const choice = choices[i];
        const state = buttons[i] === BUTTON_STATE_SELECTED;

        // save all selected choices in a list
        if (state) {
            selected_choices.push(choice.id);
        }
    }

    // determine if this question is answered correctly:
    // 	  * compare each question choice to the button state
    let correct = true;
    for (let i = 0; i < choices.length; i++) {
        const choice = choices[i];
        const state = buttons[i] === BUTTON_STATE_SELECTED;

        // check if this choice state matches the user state
        if (choice.correct !== state) {
            // this question is incorrect
            correct = false;
            // console.log("  ***  INCORRECT ", choice.correct, state);
            break;
        }
    }

    // setup the proper feedback state
    let feedback_state = choices.map((choice, i) => {
        const state = buttons[i] === BUTTON_STATE_SELECTED;
        if (state) {
            // mark this choice correct or incorrect based on the whole question correct
            // feedback_state[i] = correct ? 1 : -1;

            // mark this choice correct if it matches the correct answer
            return choice.correct === state ? BUTTON_STATE_CORRECT : BUTTON_STATE_INCORRECT;
        }

        return BUTTON_STATE_UNSELECTED;
    });

    // update response
    response.correct = correct;
    response.selected_choices = selected_choices;
    response.feedback_state = feedback_state;
    return response;
}

export function scoreFreeEntry(context, question, buttons, response) {
    response.correct = false;


    // strip all whitespace from comparisons
    const inputText = prepareFreeEntryText(context?.response?.free_entry);
    if (!inputText) {
        // input was empty
        console.warn('Free entry input is empty: ', context.free_entry);
        console.dir(context);
        return response;
    }

    // convert keyboard characters to regular characters
    const conversions = [
        [String.fromCharCode(8804), '<='],
        [String.fromCharCode(8805), '>='],
        ['≤', '<='],
        ['≥', '>=']
    ];
    const cleanText = conversions.reduce((text, conversion) => {
        return text.replace(conversion[0], conversion[1]);
    }, inputText);
    console.debug('  Scoring free entry:', cleanText);

    let correct = false;
    let feedback_state = [BUTTON_STATE_INCORRECT];

    // check if the text matches any of the choices
    const choices = question.choices;
    let answer = null;
    for (let i = 0; i < choices.length; i++) {
        // get the choice text
        const choice = choices[i];
        if (!choice.choice_text) continue;

        console.debug('    Checking choice:', choice.choice_text);
        console.debug('             choice:', choice);

        // prepare the choice text
        // let choice_text = stripWhitespace(choice.choice_text).toLowerCase();
        let choice_text = prepareFreeEntryText(choice.choice_text);

        // compare this choice with the entered text
        if (choice_text === cleanText) {
            // console.log('      CORRECT');
            correct = true;
            answer = choice.id;
            break;
        }
    }

    if (correct) {
        feedback_state[0] = BUTTON_STATE_CORRECT;
    } else {
        feedback_state[0] = BUTTON_STATE_INCORRECT;
    }

    // selected_choices must be set for the question to be considered answered
    // correct = boolean
    // selected_choices = [<choice_id>]
    // feedback_state = [<button_state>]
    response.correct = correct;
    response.selected_choices = answer ? [answer] : null;
    response.feedback_state = feedback_state;
    return response;
}

const getCurrentAnswerData = (context) => {
    const inputText = context?.response?.free_entry;
    if (!inputText) {
        console.warn('[quiz] getCurrentAnswerData inputText is empty');
        return null;
    }
    const inputs = JSON.parse(inputText);
    console.log('[quiz] answer data:', inputs);
    return inputs;
};

export function scoreFillInMultipleBlanks(context, question, buttons, response) {
    console.log('[score] fill in multiple blanks:', question, context, buttons, response);

    // get the input data
    const inputs = getCurrentAnswerData(context);
    if (!inputs) {
        console.warn('[score] fill in multiple blanks, input is empty');
        return response;
    }

    // convert inputs to a list
    const inputValues = Object.values(inputs);
    console.log('[score] fill in blanks, input values:', inputValues);

    // gather valid answers from the question choices:
    // choices are stored as a list of lists:
    //  ex:	[[choice1, choice2], [choice1, choice2]]
    // const choices = question.choices.map(choice => {
    //     // check if choice_text contains json
    //     if (choice.choice_text.startsWith('[')) {
    //         return JSON.parse(choice.choice_text);
    //     } else {
    //         return [choice.choice_text];
    //     }
    //     //choice.choice_text
    // });
    // console.log('[score] choices:', choices);

    const findChoiceWithValue = (question, value) => {
        console.log('[score] findChoiceWithValue:question', question, value);
        // find the choice in the question
        return question.choices.find(choice => {
            // gather the values for this choice
            let values = [choice.choice_text];
            if (choice.choice_text.startsWith('[')) {
                values = JSON.parse(choice.choice_text);
            }
            // check the values for a match
            const check = value.toLowerCase();
            return values.find(val => val.toLowerCase() === check);
        });
    };

    // compare choices to input values
    // const matches = [];
    const scores = [];
    const selected_choices = [];
    for (let i = 0; i < inputValues.length; i++) {
        // for each value, check if a question choice matches
        // if the choice is correct, check that it has not already been used

        // find the value in the question choices
        const value = inputValues[i].trim().toLowerCase();
        const choice = findChoiceWithValue(question, value);
        if (!choice) {
            console.error('[score] no choice for match not found:', value);
            scores.push(-1);
            continue;
        }

        // check if this choice has already been used
        if (selected_choices.includes(choice.id)) {
            console.log('[score] match already used:', value);
            scores.push(-1);
            continue;
        }

        // this choice is correct
        console.log('[score] match:', value);
        selected_choices.push(choice.id);
        scores.push(1);

        // get the choice id
        // const choice = question.choices.find(choice => choice.choice_text === match[0]);
        // if (!choice) {
        //     console.error('[score] choice for match not found:', match[0]);
        //     continue;
        // }
    }

    // create the response - for the question to be correct, all choices must be correct
    response.correct = scores.every(score => score === 1);
    response.selected_choices = selected_choices;
    response.feedback_state = scores;

    console.log('[score] response:', response);
    return response;
}

const score_functions = {
    [QUESTION_TYPE_TRUE_FALSE]: scoreMultipleChoice,
    [QUESTION_TYPE_MULTIPLE_CHOICE]: scoreMultipleChoice,
    [QUESTION_TYPE_MULTIPLE_SELECT]: scoreMultipleSelect,
    [QUESTION_TYPE_FREE_ENTRY]: scoreFreeEntry,
    [QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS]: scoreFillInMultipleBlanks
};

export function scoreCurrentQuestion() {
    return function (dispatch, getState) {
        // get the current question
        const question = toJS(getQuestion(getState()));

        // create the submission
        const context = toJS(getQuizContext(getState()));

        const buttons = getButtonStates(getState());

        let response = context.response;

        // increment attempts
        // dispatch(incrementAttempts());

        // this version submits all attempts
        response.submit = true;

        // increment attempts
        response.attempts += 1;

        // score the question - get the score function for this question typ and call it
        const scoreFunction = score_functions[question.type];
        if (!scoreFunction) {
            console.error('Invalid question type: ', question.type);
            return null;
        }
        response = scoreFunction(context, question, buttons, response);

        // prepare the response for submission
        if (response.submit) {
            response.question_stop = getCurrentTimestamp();
            // response.selected_choice = '';
        }

        // save the response to the result
        dispatch({ type: ADD_QUIZ_RESPONSE, response });

        // show feedback if configured
        if (context.config && context.config.showFeedback) {
            dispatch({ type: SET_BUTTON_STATE, button_state: response.feedback_state });
        }
    };
}
