import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';
import BackgroundImage from './404-page-bg.png';

const Error404Page = (props) => {
    return (
        <div
            tw='w-full h-full flex flex-col justify-center items-center text-white'
            css={[
                css`background-image: url(${BackgroundImage});`,
                css`background-size: cover;background-position: right;`
            ]}
        >
            <div tw='fontSize[144px] fontWeight[400] fontFamily[Edo SZ] text-MuzologyLightBlue'>
                404
            </div>
            <div tw='fontSize[32px] fontWeight[800] flex flex-col text-center'>
                <div tw='fontSize[36px]'>
                    Oh no! The music stopped playing.
                </div>
                <div tw='fontSize[32px]'>
                    The page you’re looking for can’t be found.
                </div>
                <div tw='fontSize[24px] paddingTop[40px]'>
                    Head back to <a tw='underline' href='/'>Muzology Home.</a>
                </div>
            </div>
        </div>
    );
};

Error404Page.propTypes = {
    anyProp: PropTypes.any
};

export default Error404Page;

