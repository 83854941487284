import React, { Component } from 'react';
import { styled } from 'twin.macro';
import { CircularProgressbar } from 'react-circular-progressbar';
import RecordIcon from '../RecordIcon';
import RecordProgress from './RecordProgress';
import 'react-circular-progressbar/dist/styles.css';
import { Tooltip } from 'react-tooltip';


const RecordProgressContainer = styled.div`
    font-size: 14px;
    font-family: 'Open Sans', serif;
    display: flex;
    align-items: flex-start;

    .record {
        height: 30px;
        width: 30px;
        margin-right: 7px;
        background: ${(props) => !props.$dtc ? 'rgba(231, 231, 231, 0.75)' : null};
        border-radius: 50%;
        @media only screen and (max-width: 1024px) {
            height: 27px;
            width: 27px;
        }
        .current-level {
            border: ${(props) => !props.$dtc ? '2px solid #f6d253' : null};
            border-radius: 50%;
            height: 100%;
            width: 100%;
            background: ${(props) => !props.$dtc ? 'rgba(231, 231, 231, 0.75)' : null};

            &.level1 {
                border-color:${(props) => !props.$dtc ? '#afafaf' : null};
            }

            &.level2 {
                border-color:${(props) => !props.$dtc ? '#f6d253' : null};
            }

            &.level3 {
                border-color: ${(props) => !props.$dtc ? '#389caa' : null};
            }
              
        }
    }
`;

export default class Records extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.stats || !this.props.stats.quiz_levels) {
            return (
                <RecordProgressContainer className='record-progress-container' $dtc={this.props.dtc}>
                    <div className='record'><div className='current-level level1' /></div>
                    <div className='record'><div className='current-level level2' /></div>
                    <div className='record'><div className='current-level level3' /></div>
                </RecordProgressContainer>
            );
        }

        const stats = this.props.stats;

        const RecordIndicator = ({ level, levelText }) => {
            let record = <div className={`current-level level${level}`} />;
            const gradientId = 'progressGradient';

            // greyed out records for levels not yet reached
            if (stats.current_level === level) {
                // on the current level draw the record if there has been at least 1 attempt
                if (stats.quiz_levels[level] && stats.quiz_levels[level].attempts >= 1) {
                    if (this.props.dtc) {
                        record = <RecordIcon level={level} brightness='50%' />;
                        const progressPercent = stats.current_score ? stats.current_score : 0;

                        return (
                            <>
                                <RecordProgress />
                                <div className='record'>
                                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                        <div style={{ position: 'absolute', transform: 'scale(1.25)' }}>
                                            <CircularProgressbar
                                                value={progressPercent}
                                                strokeWidth={4}
                                                styles={{
                                                    path: {
                                                        stroke: `url(#${gradientId})`, // reference the gradient by its ID
                                                        strokeLinecap: 'round'
                                                    },
                                                    trail: {
                                                        stroke: '#1C0057'
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ position: 'absolute' }}>
                                            {record}
                                        </div>
                                    </div>
                                </div>
                            </>
                        );

                    } else {
                        record = <div className={`current-level level${level}`} />;
                        return (
                            <div className='record'>
                                {record}
                            </div>
                        );
                    }
                }
                return null;
            }

            // draw a record if the level has been reached
            else if (stats.current_level > level) {
                // draw an already earned record
                record = <RecordIcon level={level} />;
                return (
                    <div
                        className='record'
                        data-tooltip-id='tooltip-record-info'
                        data-tooltip-content={`${levelText} Record`}
                        data-tooltip-delay-show={100}
                    >
                        {record}
                        {this.props.isHovering &&
                            <Tooltip
                                id='tooltip-record-info'
                                tw='font-Poppins'
                                style={{
                                    backgroundColor: '#6441B9',
                                    color: '#fff',
                                    fontSize: '12px'
                                }} />
                        }
                    </div>
                );
            }
            else if (this.props.dtc) {
                return null;
            }
            return <div className='record'>{record}</div>;
        };

        return (
            <RecordProgressContainer className='record-progress-container'>
                <RecordIndicator level={1} levelText={'Silver'} />
                <RecordIndicator level={2} levelText={'Gold'} />
                <RecordIndicator level={3} levelText={'Platinum'} />
            </RecordProgressContainer>
        );
    }
}
