import React, { useState } from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';
import RecordImg from 'components/RecordImg';
import PropTypes from 'prop-types';
import { recordImageURL } from 'static/records/FilledRecordsAssets';
import ProgressBar from './ProgressBar';
const RecordContainer = styled.div``;

const RecordProgressAnimationContainer = styled.div`
    width: 80%;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    .record-container {
        position: relative;
        z-index: 100;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 1px solid transparent;
        margin-left: -10px;
        flex-grow: 0;
        flex-shrink: 0;

        .record-img {
            width: 100%;
            height: 100%;
        }

        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.36);

        .down {
            box-shadow: none;
        }

        &:hover {
            border: 1px solid black;
        }

        &:active {
            box-shadow: none;
        }

        .record-halo {
            position: absolute;
            left: -40px;
            top: -40px;
            right: -40px;
            bottom: -40px;
        }
    }

    .progress-bar {
        flex-grow: 1;
        flex-shrink: 1;
        z-index: 10;
    }
`;

const RecordProgress = ({ level, score, className }) => {
    let [recordDown /*, setRecordDown*/] = useState(false);
    let recordProgress = recordImageURL(level, score);

    let percent = (score || 0) / 100.0;
    console.log('Percent:', percent);
    return (
        <RecordProgressAnimationContainer className={className}>
            <ProgressBar percent={percent} level={level} />

            <RecordContainer className={`record-container ${recordDown ? 'down' : 'up'} `}>
                <div tw='absolute inset-0 text-white font-black text-4xl flex items-center justify-center'>{score}</div>
                <img src={recordProgress} tw='w-full' />
            </RecordContainer>
        </RecordProgressAnimationContainer>
    );
};

RecordProgress.propTypes = {
    level: PropTypes.number,
    score: PropTypes.number
};

export default RecordProgress;
