import { stripWhitespace } from '../../utils/string';

export const prepareFreeEntryText = (text) => {
    // return stripWhitespace(text ?? '').toLowerCase();
    if (text) {
        const stripped = stripWhitespace(text);
        if (stripped) {
            return stripped.toLowerCase();
        }
    }
    return null;
};