import React from 'react';
import LazyLoadVideoCard from './LazyLoadVideoCard';
import { StandardVideoGrid } from 'styles';
import tw, { styled } from 'twin.macro';

const Grid = styled.div(({ B2BVideosPageGrid }) => [
    tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 md:[gap:48px 32px]`,
    B2BVideosPageGrid && tw`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pb-8 `,
])

const LazyLoadVideoGrid = ({ numberOfVideoCards, B2BVideosPageGrid }) => {
    const createCards = () => {
        const fakeCardList = Array(numberOfVideoCards).fill(0);
        return fakeCardList.map((card, index) => {
            return <LazyLoadVideoCard key={`lazy-card-${index}`} card={card} />
        })
    }
    return (
        <div>
            <Grid B2BVideosPageGrid={B2BVideosPageGrid}>
                {createCards()}
            </Grid>
        </div>
    );
};

export default LazyLoadVideoGrid;