// import {
//     Player
//     // PlaybackConfig,
//     // PlayerAPI,
//     // SourceConfig,
//     // StyleConfig
// } from 'bitmovin-player';
import { LogLevel, Player, PlayerEvent, ViewMode } from 'bitmovin-player';

import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createVideoPoster } from '../../../../app/util';
// import { PlaybackToggleOverlay, UIContainer, UIFactory, UIManager } from 'bitmovin-player-ui';
// import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';
import { time_ranges_to_array } from '../../util';
import 'bitmovin-player/bitmovinplayer-ui.css';
import { buildMuzologyVideoPlayerUI } from './BitmovinUI';

require('./VideoPlayer.scss');

// const BITMOVIN_KEY = '7ae3ff0f-3493-4bd4-b2e7-d622d225bfbb'; // old bitdash key
// const BITMOVIN_ANALYTICS_KEY = null;
const BITMOVIN_KEY = '99837356-f248-4b19-b299-d032177cb5c1'; // bitmovin key
const BITMOVIN_ANALYTICS_KEY = '83C8D8FD-80B9-4FA4-8039-13B2E86DFB66'; // bitmovin analytics key

const WatchingEvents = [
    PlayerEvent.Error,
    PlayerEvent.Warning,
    PlayerEvent.Destroy,
    PlayerEvent.Metadata,
    PlayerEvent.Muted,
    PlayerEvent.Paused,
    PlayerEvent.Play,
    PlayerEvent.PlaybackFinished,
    PlayerEvent.PlaybackSpeedChanged,
    PlayerEvent.PlayerResized,
    PlayerEvent.Playing,
    PlayerEvent.Ready,
    PlayerEvent.Seek,
    PlayerEvent.Seeked,
    PlayerEvent.Unmuted,
    PlayerEvent.ViewModeChanged,
    PlayerEvent.VolumeChanged
];

// const BitmovinPlayer = React.forwardRef((props, videoNode) => {
const BitmovinPlayer = React.forwardRef((props, elementRef) => {

    // store the player in a reference
    const playerRef = useRef();

    // access the player
    // const getPlayer = () => playerRef.current;
    // get the ranges played - access the HTML5 video element
    // const getPlayed = () => time_ranges_to_array(getPlayer()?.getVideoElement()?.played);
    // // save the player and connect the event handler
    // const attachPlayer = useCallback((bitmovinPlayer) => {
    //
    //     // save the player in the reference
    //     playerRef.current = bitmovinPlayer;
    //
    //     // attach to the caller reference if provided
    //     if (props.playerRef)
    //         props.playerRef.current = bitmovinPlayer;
    // }, [props.playerRef]);
    // detach the current player
    // const detachPlayer = useCallback(() => {
    //     // clear the references
    //     playerRef.current = null;
    //     if (props.playerRef)
    //         props.playerRef.current = null;
    // }, [props.playerRef]);

    const [playButtonUIManager, setPlayButtonUIManager] = useState(null);
    const uiManager = useRef(null);

    // create the player, load the source from props, save the player
    const createPlayer = useCallback((element) => {

        // check for a video
        if (!props.video?.id) {
            // TODO: exception?
            console.error('[BitmovinPlayer] Aborting player setup, video not set');
            return null;
        }

        console.debug('[BitmovinPlayer] createPlayer() for video:', props.video);

        // specific event handlers
        const onPlaybackFinished = (event) => {
            // get the ranges played - access the HTML5 video element
            const played = time_ranges_to_array(playerRef.current?.getVideoElement()?.played);
            console.log('on playback finished', { event, played });

            // return the event with the played ranges attached
            return { ...event, played };
        };

        // handle specific events
        const playerEvents = {
            [PlayerEvent.PlaybackFinished]: onPlaybackFinished
        };

        // handle all events
        const onPlayerEvent = (event) => {
            // console.log('[BitmovinPlayer] Event: ', event.type, event, playerRef.current);
            console.debug('[BitmovinPlayer] on event ', event?.type, { event, player: playerRef.current });

            // switch (type) {
            //     case PlayerEvent.PlaybackFinished:
            //         onPlaybackFinished(event);
            //         break;
            // }

            if (!event) {
                console.error('[BitmovinPlayer] onPlayerEvent: invalid event', event);
                return;
            }

            // our event handlers
            const handler = playerEvents[event.type];
            if (handler) {
                event = handler(event);
            }

            // pass on all player events to the parent, along with the player
            if (props.onPlayerEvent)
                props.onPlayerEvent(event, playerRef.current);
        };

        // create the bitmovin config
        let config = {
            key: BITMOVIN_KEY,
            playback: {
                // muted: true,
                autoplay: false
                // preferredTech : [{
                //     player: 'html5',
                //     streaming: 'hls'
                // }]
            },

            // create a map of event names connected to the event handler
            events: WatchingEvents.reduce((all, name) => ({ ...all, [name]: onPlayerEvent }), {}),

            logs: {
                bitmovin: true,
                level: LogLevel.ERROR
                // level: LogLevel.WARN
                // level: LogLevel.DEBUG
            },

            skin: {
                // disable bitmovin logo
                screenLogoImage: null // /static/images/muzology_logo.png"
            },

            // disable the context menu
            tweaks: {
                context_menu_entries: []
            },

            // location of ui
            location: {
                ui: '//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js',
                ui_css: '//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css'
            },

            // ui externally managed
            ui: false
        };

        // add analytics
        if (BITMOVIN_ANALYTICS_KEY) {
            const analytics = {
                key: BITMOVIN_ANALYTICS_KEY,
                videoId: props.video.id,
                title: props.video.title,
                userId: props.user.id,
                cdnProvider: 'AWS CloudFront',
                customData1: 'pre-algebra', // video series
                customData2: 'student',   // user role
                customData3: 'account name',   // account name
                customData4: 'account type',   // account type
                customData5: 'account style'   // account style
            };
            config = { ...config, analytics };
        }

        // create the player and ui
        let bitmovinPlayer = null;
        try {
            console.debug('[BitmovinPlayer] create bitmovin player:', config);
            bitmovinPlayer = new Player(element, config);

            // load the ui manager
            uiManager.current = buildMuzologyVideoPlayerUI(bitmovinPlayer);
            console.log('[BitmovinPlayer] UI Manager:', uiManager.current);


            // const myUiManager = null;
            // const currentUiManager = UIFactory.buildDefaultUI(bitmovinPlayer);
            // uiManager.current = UIFactory.buildDefaultSmallScreenUI(bitmovinPlayer);


            // uiManager.current = UIFactory.buildDefaultUI(bitmovinPlayer);
            // when video switches small to large screen, remove the ui manager
            // const onSwitchMobileUI = (event) => {
            //     // remove the ui manager
            //     console.debug('<BitmovinPlayer> [onSwitchMobileUI] on playing');
            //     // remove the play button
            //     if (myUiManager) {
            //         console.debug('[SwitchUI] removing play button');
            //
            //         // remove the ui manager
            //         myUiManager.release();
            //
            //         setPlayButtonUIManager(null);
            //
            //         UIFactory.buildDefaultUI(bitmovinPlayer);
            //
            //
            //         // remove this event handler
            //         bitmovinPlayer.off(PlayerEvent.Playing, onSwitchMobileUI);
            //     }
            // };
            // bitmovinPlayer.on(PlayerEvent.Playing, onSwitchUI);

            /*
            function toggleSmallScreenUI() {
                currentUiManager.release();
                if (!isSmallscreen) {
                    currentUiManager = bitmovin.playerui.UIFactory.buildModernSmallScreenUI(player);
                } else {
                    currentUiManager = bitmovin.playerui.UIFactory.buildDefaultUI(player);
                }
                isSmallscreen = !isSmallscreen;
            }
            */

            // const myUiManager = UIFactory.buildDefaultUI(bitmovinPlayer);
            // setPlayButtonUIManager(myUiManager);
            // const myUiManager = new UIManager(bitmovinPlayer, playButtonUI);

            // build a simple UI with just a playback button
            // const playButtonUI = new UIContainer({
            //     components: [
            //         new PlaybackToggleOverlay()
            //     ]
            // });
            // const myUiManager = new UIManager(bitmovinPlayer, playButtonUI);

            // when video switches from paused to playing, remove the ui manager
            /*
            const onSwitchUI = (event) => {
                // remove the ui manager
                console.debug('[SwitchUI] on playing');
                // remove the play button
                if (myUiManager) {
                    console.debug('[SwitchUI] removing play button');
                    myUiManager.release();
                    setPlayButtonUIManager(null);
                    UIFactory.buildDefaultUI(bitmovinPlayer);

                    // remove this event handler
                    bitmovinPlayer.off(PlayerEvent.Playing, onSwitchUI);
                }
            };
            */

            // exit fullscreen on playback end
            const onPlaybackFinished = (event) => {
                if (bitmovinPlayer.getViewMode() === ViewMode.Fullscreen)
                    bitmovinPlayer.setViewMode(ViewMode.Inline);
            };

            bitmovinPlayer.on(PlayerEvent.PlaybackFinished, onPlaybackFinished);

            // save the player in the reference
            playerRef.current = bitmovinPlayer;

            // attach to the caller reference if provided
            if (props.playerRef)
                props.playerRef.current = bitmovinPlayer;

            // done
            // console.debug('[BitmovinPlayer] Player created:', bitmovinPlayer);

        } catch (error) {
            console.error('[BitmovinPlayer] Error creating Bitmovin player:', error);
            throw error;
        }

        // TODO: setup mux

        // load the video sources
        const sourceConfig = createSourceConfig(props.video);
        // console.debug('[BitmovinPlayer] Loading source config:', props.video, sourceConfig);
        return bitmovinPlayer.load(sourceConfig)
            .then(() => {
                console.info('[BitmovinPlayer] Successfully created Bitmovin Player instance', bitmovinPlayer);

                const languages = {
                    en: 'English',
                    es: 'Spanish',
                    'es-es': 'Spanish',
                    'es-es.es': 'Spanish',
                    'es-la': 'Spanish'
                };

                // add subtitles
                if (props.video?.captions?.length > 0) {
                    props.video.captions.forEach((caption, i) => {
                        let subtitle = {
                            id: `sub${i+1}`,
                            lang: caption.language,
                            label: languages[caption.language] ?? caption.language,
                            url: caption.url,
                            kind: 'subtitle'
                        };

                        bitmovinPlayer.subtitles.add(subtitle);
                    });
                }
                // add any subtitles
                // for( video.)


            })
            .catch((err) => {
                // TODO: notify parent that something went wrong
                console.error('[BitmovinPlayer] Error creating player:', err);
                console.dir(err);
                // setError(err);
            });
    }, [props]);

    // load video player on mount
    useEffect(() => {
        // create the video player
        // console.debug('[BitmovinPlayer] Creating player on node:', elementRef.current);
        const player = createPlayer(elementRef.current);
        // console.debug('[BitmovinPlayer] Player created:', player);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id='player' ref={elementRef} />
    );
});

export const createSourceConfig = (video) => {

    // create a source config
    return {
        // title: video.title,
        // description: video.description,

        dash: video.url_dash,
        hls: video.url_hls,
        progressive: video.url_mp4,
        // smooth: 'https://test.playready.microsoft.com/smoothstreaming/SSWSS720H264/SuperSpeedway_720.ism/manifest',

        poster: createVideoPoster(video.url_thumbnail)
    };
};

export default BitmovinPlayer;
