import React, { useMemo, useRef, useEffect, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useForm, Controller } from 'react-hook-form';
import PlatinumRecord from 'static/images/records/DTC/platinum-createmath.png';
import SymbolsBackground from 'static/images/textures/create-math-playlist-symbols.png';
import SymbolsRecordMedium from 'static/images/textures/SymbolsRecordMedium.png';
import SymbolsRecordMobile from 'static/images/textures/SymbolsRecordMobile.png';
import BackgroundImage from 'lib/components/BackgroundImage';
import CreateMathPlaylistBackground from 'static/images/background_images/CreateMathPlaylist.png';
import TimesTablesCreateMathPlaylistBackground from 'static/images/background_images/TimesTablesCreateMathPlaylistBackground.jpg';
import TimesTablesChild from 'static/images/TimesTableCreateAccount/TimesTablesChild.png';
import PurpleStar from 'static/images/TimesTableCreateAccount/PurpleStar.png';
import MultiplicationSign from 'static/images/TimesTableCreateAccount/MultiplicationSign.png';
import GoldMusicNote from 'static/images/TimesTableCreateAccount/GoldMusicNote.png';
import GradeListBox from 'components/GradeListBox/GradeListBox';
import { useUser } from 'state/User/UserState';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useUserAction } from 'state/User/UserState';
import { ONBOARD_STAGE_AGREE_TO_TERMS } from 'app/AccountOnboard/OnboardStages';
import { useOnboardStageFinished } from 'state/OnboardState';
import { useProduct } from 'state/User/UserState';
import { useMedia } from 'react-use';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';
import { LoadingOverlay } from '../../../lib/components/loading';
import SchoolAutoSuggest from '../../SchoolAutoSuggest/SchoolAutoSuggest';

const GradeContainer = styled.label(({ active }) => [
    tw` w-full rounded-lg bg-[#F4FBFF] text-[#6E7191] p-2 text-base font-medium font-Poppins text-center cursor-pointer sm:hover:scale-110 transform transition-all duration-300 ease-in-out`,
    css`
        /* Hide the browser's default checkbox */

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    @media (max-width: 640px) {
        &:last-child {
				width: 80px;
			}
    }

    `,
    active && tw` bg-[#6F5FE6] text-white`
]);

const MathLevelContainer = styled.label(({ active, index }) => [
    tw`relative w-full rounded-lg bg-white text-[15px] text-center text-[#1C0057] px-2.5 py-1.5 sm:py-3.5 cursor-pointer sm:hover:scale-105 transform transition-all duration-300 ease-in-out  max-w-[100%] md:max-w-[170px] lg:max-w-[150px] xl:max-w-[170px] max-h-[44px] sm:max-h-[47px]`,
    index === 0 && tw`max-w-[100%] md:max-w-[185px] px-0`,
    css`
        /* Hide the browser's default checkbox */

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        position: relative;
        box-shadow: 0px 0px 10px -4px rgba(138, 138, 138, 1);
        box-sizing: border-box;
        background-clip: padding-box;
        border: solid 3px transparent;
    `,

    active && css`
        background: linear-gradient(white, white) padding-box,
        linear-gradient(
            180deg,
            hsl(269deg 60% 46%) 0%,
            hsl(265deg 50% 52%) 11%,
            hsl(259deg 48% 58%) 21%,
            hsl(250deg 47% 63%) 31%,
            hsl(238deg 46% 68%) 41%,
            hsl(223deg 50% 69%) 52%,
            hsl(209deg 55% 71%) 63%,
            hsl(197deg 61% 71%) 75%,
            hsl(185deg 67% 72%) 87%,
            hsl(176deg 84% 73%) 100%
        ) border-box;
        border-radius: 0.5rem;
        border: 3px solid transparent;
        box-shadow: 0px 0px 5.66px 0px #AD63E9;

    `

]);

const rotateCss = css`
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite;
    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;

const BoxShadowCss = css`
    @media (min-width: 640px) {
        box-shadow: 0px 12px 24px 0px rgba(20, 20, 43, 0.13), 0px -2px 4px 0px rgba(20, 20, 43, 0.02), 0px 3px 14px 0px rgba(74, 58, 255, 0.03);

    }
`;

const CreateMathPlaylist = ({ setOnboardState, isOpen, teacher = false }) => {

    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    const [state, setState] = useState(null);
    const { updateUser } = useUserAction();
    const stageFinished = useOnboardStageFinished();
    const [schoolNotSelected, setSchoolNotSelected] = useState(false);
    const [selectedGrade, setGrade] = useState(null);
    const isSmall = useMedia('(max-width: 639px)');
    const user = useUser();
    const timesTableProduct = useProduct('times-tables');
    const showMathLevel = !teacher && !timesTableProduct;
    // school = school name, selectedSchool = school object
    const [school, setSchool] = useState('');
    const [selectedSchool, setSelectedSchool] = useState(null);
    // get the initial show email value
    const [showEmail, setShowEmail] = useState(user?.email?.length <= 0);
    const defaultValues = {
        level: 'at',
        terms: false,
        user_name: user.username ?? user.email
    };
    if (showEmail) {
        defaultValues.email = user.email;
    }
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    const header = teacher ? 'Let\'s Customize Your Muzology Experience' : timesTableProduct ? 'Create Your Child’s Account' : 'We\'re Creating A Math Playlist Just For Your Child!';
    const buttonCopy = teacher || timesTableProduct ? 'Create Account' : 'Go to Playlist';
    const formSubCopy = teacher ? '(This is the name your students will see on Muzology.)' : 'Child’s Name (optional)';
    const GradeCopy = teacher ? 'What Grade(s) Do You Teach' : 'Grade';
    const SubheaderCopy = timesTableProduct ? 'Let’s Go!' : 'One Last Thing.';

    useEffect(() => {
        isOpen && setError('grade', {
            type: 'manual',
            message: ''
        });
    }, [isOpen, setError]);

    // store grade and level choices in references
    const gradeChoice = useRef({});
    const levelChoice = useRef({});
    gradeChoice.current = watch('grade', '');

    const currentLevel =  watch('level', '');
    levelChoice.current = currentLevel;
    console.log('LEVEL:', levelChoice.current);

    // create grade choice boxes
    const gradeChoices = useMemo(() => {
        const boxes = Array(timesTableProduct ? 10 : 9).fill(0);
        return boxes.map((x, index) => {
            let label = (index + 2).toString();
            let value = String(index + 2);
            if (timesTableProduct) {
                label = (index - 1).toString();
                value = String(index - 1);

            }
            if (index === 0) {
                label = 'K-2';
                if (timesTableProduct) {
                    label = 'PreK';
                    value = 'PreK';
                }
            }
            if (index === 1 && timesTableProduct) {
                label = 'K';
                value = 'K';
            }
            if (index === 8 && !timesTableProduct) {
                label = '10-12+';
            }
            if (index === 9 && timesTableProduct) {
                label = '8+';
                value = '8+';

            }

            let active = null;
            if (gradeChoice.current)
                active = teacher ? gradeChoice.current.find(choice => choice === value) : gradeChoice.current === value;
            return (
                <GradeContainer active={active} key={index} onClick={() => clearErrors('grade')}>
                    <div className='title' tw='select-none'>{label}</div>
                    <input
                        type={teacher ? 'checkbox' : 'radio'} name='grade'
                        value={value}
                        ref={register({ required: true })}
                    />
                </GradeContainer>
            );
        });
    }, [timesTableProduct, teacher, register, clearErrors, gradeChoice, gradeChoice.current]);

    // create math level check boxes
    const mathLevelChoices = useMemo(() => {
        const levels = ['below', 'at', 'above'];
        return levels.map((value, index) => {
            let label;
            if (index === 0) {
                label = (<div>Need to catch up &#128338;</div>);
            } else if (index === 1) {
                label = (<div>On track &#9989;</div>);
            } else if (index === 2) {
                label = (<div>Advanced &#128640;</div>);
            }
            return (
                <MathLevelContainer active={currentLevel === value} index={index} key={value}>
                    <div tw='h-full flex justify-center items-center select-none'>
                        {label}
                    </div>
                    <input
                        name='level'
                        type='radio'
                        value={value}
                        ref={register({ required: true })}
                    />
                </MathLevelContainer>
            );
        });
    }, [currentLevel, register]);

    // user selects the state
    const selectState = (data) => {
        setState(data.abbreviation);
        clearErrors('state');
    };

    const selectGradeMobile = (data) => {
        setValue('grade', data, { shouldDirty: true });
        setGrade(data);
        clearErrors('grade');
    };

    // parent submits onboard form
    const [saving, setSaving] = useState(false);
    const onSubmit = (data) => {
        const payload = {};

        if (timesTableProduct) {
            payload.email = data.email;
            payload.username = data.user_name;
            payload.password = data.password;
            payload.name = data.name;
            payload.grade = isSmall ? selectedGrade : data.grade;
            payload.terms = data.terms;
        } else {
            payload.username = data.user_name;
            payload.name = data.name;
            payload.terms = data.terms;
            payload.grade = isSmall ? selectedGrade : data.grade;
            payload.proficiency_level = data.level;
        }
        setSaving(true);
        apiClient.post('/api/studentprofile/', payload)
            .then(response => {
                // student profile created
                const { data } = response;
                const { user, errors = null } = data;

                // check for error
                if (errors) {
                    for (const key in errors) {
                        console.log('error', key, '=', errors[key]);
                        setError(key, { type: 'manual', message: errors[key] });
                    }
                    console.error('Form errors: ', errors);
                    setSaving(false);
                    return;
                }

                // onboarding complete
                if (!user?.id) {
                    console.error('error saving proficiency level', data);
                    setSaving(false);
                    return;
                }

                // track the event
                console.log('Student profile setup');
                track('Student Profile Created', { name: data.name, grade: data.grade, proficiency_level: data.level });

                // update student data on FE
                updateUser();

                // Onboarding complete - save the student and close the dialog
                track('Student Onboarding Complete', { name: data.name, grade: data.grade, proficiency_level: data.level });
                setOnboardState({ registration_required: false });
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);
                setSaving(false);
            });
    };

    // teacher submits onboard form
    const teacherOnSubmit = async (data) => {
        if (!selectedSchool) {
            // setError('school', { type: 'manual', message: 'This field is required' });
            setSchoolNotSelected(true);
            return;
        }
        setSchoolNotSelected(false);
        const payload = { ...data, state, school: selectedSchool?.pk };
        try {
            console.log('Payload:', payload);
            await stageFinished(ONBOARD_STAGE_AGREE_TO_TERMS, payload);
            // update the user's state
            updateUser();
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <div tw='relative w-full mx-auto max-w-[380px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-full overflow-auto'>
            <BackgroundImage image={timesTableProduct ? TimesTablesCreateMathPlaylistBackground : CreateMathPlaylistBackground} tw=' hidden sm:block absolute inset-0 rounded-lg' />
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <LoadingOverlay show={saving} fixed={false} background='#35107688' />
                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 pt-0 lg:pt-5'>
                    <div tw='w-full lg:w-[44%] relative'>
                        <div
                            tw='flex flex-col items-center w-full max-w-[100%] sm:max-w-[300px] lg:max-w-[378px] mr-auto lg:ml-auto pl-0 md:pl-2.5 lg:pl-0'
                            css={[timesTableProduct && tw`sm:max-w-[300px] lg:max-w-[455px]`]}>
                            <div tw='hidden md:block font-normal text-xl text-[#4E4E4E] w-full text-left '>{SubheaderCopy}</div>
                            <div
                                tw='pt-1.5 w-full text-[#303030] font-semibold text-xl sm:text-xl md:text-2.5xl lg:text-[40px] leading-[1.3] md:[line-height: 47px] max-w-[350px] sm:max-w-full mr-auto '
                                css={[timesTableProduct && tw`text-center sm:text-left ml-0 max-w-[100%]`]}
                            >
                                {header}
                            </div>
                        </div>
                        {timesTableProduct &&
                            <>
                                <div tw=' hidden lg:block absolute top-[32.66%] left-[30px]  z-20'>
                                    <img src={TimesTablesChild} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
                                </div>
                                <div tw=' hidden lg:block absolute bottom-[12.76%] left-[-50px]  z-10'>
                                    <img src={PurpleStar} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
                                </div>
                                <div tw=' hidden lg:block absolute top-[36.76%] left-[-10px]  z-20'>
                                    <img src={MultiplicationSign} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
                                </div>
                                <div tw=' hidden lg:block absolute top-[26.76%] right-[50px] xl:right-[90px]  z-20'>
                                    <img src={GoldMusicNote} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
                                </div>
                            </>
                        }

                        {
                            !timesTableProduct &&
                            <>
                                <div tw=' hidden lg:block absolute top-[46%] left-1/4  z-20'>
                                    <img src={PlatinumRecord} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' css={[rotateCss]} alt='Platinum Record' />
                                </div>
                                <div tw='hidden lg:block  absolute top-[37%] left-[3.5%] z-10 '>
                                    <img src={SymbolsBackground} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
                                </div>
                                <div tw='hidden md:block lg:hidden absolute top-2 right-2 z-10 '>
                                    <img src={SymbolsRecordMedium} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' />
                                </div>
                            </>
                        }

                    </div>
                    <div tw='w-full lg:w-[56%] relative  '>
                        <div
                            tw='rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            <form onSubmit={handleSubmit(teacher ? teacherOnSubmit : onSubmit)}>

                                {showEmail &&
                                    <div tw='mb-4 md:mb-5 '>
                                        <label tw='block text-base font-normal text-[#303030]'>Your Email</label>
                                        <input
                                            autoFocus
                                            type='input'
                                            name='email'
                                            ref={register({ required: true })}
                                            style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                                            tw='block w-full  px-4 py-1.5 md:py-3 mt-2 text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF]  focus:outline-none focus:ring focus:ring-opacity-40 '
                                        />
                                        {formErrors.email && <div tw='text-red-500 font-bold mt-1'>
                                            {formErrors.email.message?.length ? formErrors.email.message : 'Please enter an email address.'}
                                        </div>}
                                        {/*
                                        {formErrors.email && <div tw='text-red-500 font-bold mt-1'>{formErrors.email.type}</div>}
                                        {formErrors.email?.type === 'required' && <div tw='text-red-500 font-bold mt-1'>Email is required.</div>}
                                        */}
                                    </div>}

                                {!timesTableProduct &&
                                    <div tw='mb-4 md:mb-5'>
                                        {teacher && <label tw='text-lg font-normal text-[#303030] text-justify'>Your Teacher Name</label>}
                                        <label tw='block text-base font-normal text-[#303030]'>{formSubCopy}</label>
                                        <input
                                            autoFocus
                                            type='input'
                                            name='name'
                                            ref={register({ required: false })}
                                            style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                                            tw='block w-full  px-4 py-1.5 md:py-3 mt-1 sm:mt-2 text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                                        />
                                        {/* {formErrors.name && <div tw='text-red-500 font-bold mt-1'>Please enter a name/nickname for your child.</div>} */}
                                    </div>
                                }

                                {timesTableProduct &&
                                    <div tw='mb-4 md:mb-5'>
                                        <label tw='block text-base font-normal text-[#303030]'>Username</label>
                                        <input
                                            autoFocus
                                            type='input'
                                            name='user_name'
                                            ref={register({ required: true })}
                                            style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                                            tw='block w-full  px-4 py-1.5 md:py-3 mt-1 sm:mt-2  text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF]  focus:ring focus:ring-opacity-40'
                                        />
                                        {formErrors.username?.message &&
                                            <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.username?.message}</div>}
                                        {formErrors.user_name &&
                                            <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>Please enter a username for your child.</div>}
                                    </div>
                                }

                                {timesTableProduct &&
                                    <div tw='mb-4 md:mb-5'>
                                        <label tw='block text-base font-normal text-[#303030]'>Password</label>
                                        <input
                                            autoFocus
                                            type='input'
                                            name='password'
                                            ref={register({
                                                required: true,
                                                minLength: { value: 6, message: 'Password must have at least 6 characters' }
                                            })}
                                            style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                                            tw='block w-full  px-4 py-1.5 md:py-3 mt-1 sm:mt-2  text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                                        />
                                        {formErrors.password && <div
                                            tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.password.message?.length ? formErrors.password.message : 'Password must have at least 6 characters'}</div>}
                                    </div>
                                }

                                <div tw='pt-2 pb-3 md:pb-7 ' css={[formErrors.grade && tw`border-2 border-solid border-red-400 px-4`]}>
                                    {formErrors.grade ? <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base'>Grade - Required </div> :
                                        <div tw='text-base font-normal text-[#303030]'>{GradeCopy}</div>}
                                    <div tw='hidden sm:grid grid-cols-5 lg:grid-cols-5 gap-2.5 mt-5 ' css={[teacher && tw`grid`]}> {gradeChoices}</div>
                                    <div tw='block sm:hidden ' css={[teacher && tw`hidden`]}>
                                        {/* <Controller
                                            control={control}
                                            name='grade'
                                            defaultValue={selectedGrade}
                                            render={({ field: { onChange } }) => (
                                                <GradeListBox timesTableProduct={timesTableProduct}
                                                    onSelectItem={onSelectItem}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        onSelectItem(e);
                                                    }}
                                                />
                                            )}
                                        /> */}
                                        <GradeListBox onSelectItem={selectGradeMobile} selected={selectedGrade} timesTableProduct={timesTableProduct} />
                                    </div>
                                </div>

                                {teacher &&
                                    <div tw='relative w-full'>
                                        <div tw='mb-3 text-base font-normal text-[#303030] text-justify'> Your School?</div>
                                        <SchoolAutoSuggest
                                            value={school}
                                            onChange={setSchool}
                                            onSelect={(s) => {
                                                console.log('School selected:', s);
                                                setSelectedSchool(s);
                                                if (s) {
                                                    setSchoolNotSelected(false);
                                                }
                                            }}
                                            selected={selectedSchool}
                                        />
                                        {schoolNotSelected && <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-0'>Please select your school.</div>}
                                    </div>
                                }

                                {timesTableProduct &&
                                    <div tw='mb-4 md:mb-5 pt-4'>
                                        <label tw='block text-base font-normal text-[#303030]'>{formSubCopy}</label>
                                        <input
                                            autoFocus
                                            type='input'
                                            name='name'
                                            ref={register({ required: false })}
                                            style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                                            tw='block w-full  px-4 py-1.5 md:py-3 mt-2 text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                                        />
                                    </div>
                                }

                                {timesTableProduct &&
                                    <div tw='pt-4'>
                                        <div tw='flex justify-start items-start'>
                                            <div tw='flex items-center mt-1'>
                                                <input
                                                    id='checkbox-terms'
                                                    type='checkbox'
                                                    name='terms'
                                                    ref={register({ required: true })}
                                                    // {...register('terms', { required: true })}

                                                    tw='w-5 h-5 text-black bg-gray-100 border-gray-300 rounded  focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                                />
                                            </div>

                                            <div tw='font-Poppins block text-sm font-normal text-[#303030] ml-2'>
                                                Before creating your child's account, certify you're 18+ and agree to our{' '}
                                                <a tw='text-black underline' href='https://muzology.com/tos' target='_blank' rel='noopener noreferrer'>
                                                    Terms of Use
                                                </a>{' '}
                                                and{' '}
                                                <a tw='text-black underline' href='https://muzology.com/privacy' target='_blank' rel='noopener noreferrer'>
                                                    Privacy Policy
                                                </a>
                                                , including collection/use of personal information to provide our service.
                                            </div>
                                        </div>
                                        {formErrors.terms && <div tw='pt-2 pb-3 md:pb-7 text-red-500 font-medium sm:font-bold text-sm sm:text-base'>* This field is required</div>}
                                    </div>
                                }

                                {showMathLevel &&
                                    <div tw='pt-4'>
                                        <div tw='text-base font-normal text-[#303030]'>Math Level</div>
                                        <div tw='flex flex-col md:flex-row gap-4 md:gap-0 justify-between mt-2 sm:mt-3 px-2 sm:px-0'>{mathLevelChoices}</div>
                                    </div>
                                }
                                <div tw='w-full flex justify-center  pt-7 md:pt-[43px]'>
                                    <button
                                        tw='w-full max-w-[280px] m-auto h-[62px] p-0 py-1 bg-[#6F5FE6] font-semibold text-xl text-white rounded-2xl border-solid border-2 sm:border-white justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-100 transform transition-all duration-100 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                                        type='submit'
                                        onClick={(event) => {
                                            clearErrors();
                                            trigger();
                                        }}
                                    >
                                        {buttonCopy}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default CreateMathPlaylist;