import React, { useState, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { NullContainer, Plaque, TitleDivider } from 'components';
import moment from 'moment';
import AchievementConstants from '../../../../constants/AchievementConstants';
import { useVideoAchievements } from '../../../../../state/Student/VideoStats';

const options = [
    { id: 1, name: 'Achievement Title', type: 'alphabetical', unavailable: false },
    { id: 2, name: 'Recent to Old', type: 'date', unavailable: false }
];

const MyAchievements = ({ videos, teacher, parent }) => {
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const achievements = useVideoAchievements();

    const makeAchievementPlaques = (items, search) => {
        // handle filter dropdown
        switch (selectedOption.type) {
            case 'date':
                items = items.sort((a, b) => moment(b.datetime_awarded).diff(moment(a.datetime_awarded)));
                break;
            case 'alphabetical':
                // items = items.sort((a, b) => a.title.localeCompare(b.title));
                break;
            default:
                break;
        }

        // handle search for video titles
        if (search) {
            items = items.filter((video) => video.title.toLowerCase().includes(search));
        }

        return items.map((item, index) => {
            let copy = {
                title: item.title,
                videoTitle: item.videoTitle
            };
            return (
                <Plaque
                    isAchievement={true}
                    key={index}
                    copy={copy}
                    achievementImage={item.achievementImage}
                    achievementDescription={item.achievementDescription}
                />
            );
        });
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    // if this is not a teacher, use our achievements
    let dashboardStudentAchievements = achievements;

    // for teachers, gather achievements from each video
    if (teacher && videos?.length > 0) {
        videos.forEach((v) => {
            let achievements = v?.stats?.achievements ?? [];
            achievements.forEach((a) => {
                dashboardStudentAchievements.push(a);
            });
        });
    }

    const items = useMemo(() => {
        if (!videos) {
            return [];
        }
        // filter videos that have no stats or quiz_level data
        // create plaque items to easy filter and sort
        // let achievementItems = dashboardStudentAchievements.length > 0 ? dashboardStudentAchievements : achievements;
        return dashboardStudentAchievements.map((achievement) => {
            const achievementTitle = AchievementConstants[achievement.badge_type].header;
            const achievementDescription = AchievementConstants[achievement.badge_type].description;
            const achievementVideo = videos.find((video) => video.id === achievement.video_id);
            const achievementImage = AchievementConstants[achievement.badge_type].image;
            return {
                title: achievementTitle,
                videoTitle: achievementVideo?.title,
                achievementImage: achievementImage,
                date: achievement.datetime_awarded,
                achievementDescription: achievementDescription
            };
        });
    }, [videos, dashboardStudentAchievements]);

    let nullStateCheck = items.length === 0;

    return (
        <div tw='mt-0 sm:mt-11'>
            {!nullStateCheck && (
                <>
                    <TitleDivider
                        placeholder='Search Achievements'
                        search={search}
                        handleSearch={handleSearch}
                        selectOptions={selectedOption}
                        handleSelect={setSelectedOption}
                        options={options}
                        header=''
                    />
                    <div tw='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-0 sm:mt-6 px-10 sm:px-0 justify-items-center'>
                        {makeAchievementPlaques(items, search)}
                    </div>
                </>
            )}
            {nullStateCheck &&
                (parent ? (
                    <NullContainer
                        tw='text-white text-base sm:text-2xl font-medium'
                        style={parent && { marginTop: '20px' }}
                    >
                        <div tw='flex flex-col justify-around [height:260px]'>
                            <div>
                                As your child watches Muzology videos and takes the associated math Challenges, you’ll see their progress here.
                            </div>
                            {/*
                            <div>
                                Want to help guide your child’s learning?{' '}
                                <span tw='underline cursor-pointer' onClick={() => navigate('/playlists')}>
                                    Click here
                                </span>{' '}
                                to create a playlist of Muzology videos and share them with your child.
                            </div>
                            */}
                        </div>
                    </NullContainer>
                ) : (
                    <NullContainer>
                        <div tw='text-white text-2xl font-medium text-center'>
                            Keep watching videos and taking the math Challenges to earn Achievements.
                        </div>
                    </NullContainer>
                ))}
        </div>
    );
};

export default MyAchievements;
