import React from 'react';
import 'twin.macro';

const SvgWhiteXButton = (props) => (
    <svg
        viewBox='0 0 31.313 31.313'
        width='1em'
        height='1em'
        tw='h-2.5 sm:h-4 w-2.5 sm:w-4'
        {...props}>
        <g
            transform='translate(-1562.531 -250.707)'
            fill='#6A7277'>
            <rect
                data-name='Rectangle 3622'
                width={39.165}
                height={5.114}
                rx={2.557}
                transform='rotate(45 480.443 2015.862)' />
            <rect
                data-name='Rectangle 3623'
                width={39.165}
                height={5.117}
                rx={2.559}
                transform='rotate(135 744.25 457.258)' />
        </g>
    </svg>
);

export default SvgWhiteXButton;
