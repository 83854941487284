import * as React from 'react';

function SvgClassIcon07(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 42'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#0BE921' />
            <path
                d='M22.55 16.294h-8.312v-3.278h12.303v3.135l-6.721 13.68h-4.157l6.888-13.537z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon07;
