import React from 'react';

const StartSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none'>
            <path fill='#1C0057' fillRule='evenodd' d='m10.61.844.17.021c.45.062 1.044.146 1.38.483.277.275.383.723.446 1.123l.037.258a7.785 7.785 0 0 1-.037 2.284c-.283 1.742-1.21 3.82-3.539 5.43a3.116 3.116 0 0 0-.01.379l.006.189c.011.291.022.582-.06.865-.126.44-.578.73-1 .938l-.206.098-.267.118c-.499.212-1.172.43-1.594.006-.253-.252-.357-.623-.442-.992l-.031-.138a5.038 5.038 0 0 0-.134-.516 8.135 8.135 0 0 0-.108-.296 1.974 1.974 0 0 1-.135.15c-.23.23-.573.39-.856.503a9.624 9.624 0 0 1-.982.317l-.166.044-.318.079-.288.067-.344.073-.216.042a.673.673 0 0 1-.784-.785l.057-.287.103-.465.082-.34.063-.24a9.44 9.44 0 0 1 .317-.98c.112-.284.273-.627.503-.857l.053-.051-.042-.018a5.272 5.272 0 0 0-.346-.118l-.185-.056c-.463-.14-.957-.291-1.265-.6C.1 7.2.225 6.633.405 6.162l.073-.183.118-.267.098-.206c.208-.422.498-.873.938-1 .234-.066.476-.07.72-.064l.147.005c.19.007.38.014.567-.004 1.61-2.33 3.688-3.257 5.43-3.54A7.867 7.867 0 0 1 10.61.845Zm-6.542 8.45a.667.667 0 0 0-.733-.04l-.073.05-.062.055-.084.105c-.173.25-.272.598-.348.922l-.072.312-.034.142.127-.031.279-.064c.385-.09.812-.207 1.075-.443a.667.667 0 0 0 .059-.876l-.055-.063-.016-.015-.063-.054Zm4.79-4.65A1.334 1.334 0 1 0 6.97 6.53a1.334 1.334 0 0 0 1.886-1.885Z' clipRule='evenodd' />
        </svg>
    );
};

export default StartSVG;
