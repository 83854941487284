import React from 'react';

const ChoiceButton = (props) => {
    return (
        <button className='choice-button' {...props}>
            {props.children}
        </button>
    );
};

export default ChoiceButton;
