import React, { useMemo } from 'react';
import tw, { styled, css } from 'twin.macro';
import ChoiceItem from './ChoiceItem';

const GridContainer = tw.div`
    w-full
    grid 
    grid-cols-1 
    auto-rows-fr
    gap-[30px]
    md:gap-[20px]
    text-[3rem]
`;



const OneColumnGridContainer = styled(GridContainer)`
    //background: green;
`;

const OneColumnGrid = tw(OneColumnGridContainer)`
    sm:grid-cols-1
`;

const TwoColumnGridContainer = styled(GridContainer)`
    .choice-item-container {
        //background: red;
        max-height: 130px;
        @media (max-width: 460px) {
            max-height: 100%;
            }

    }
`;

const TwoColumnGrid = tw(TwoColumnGridContainer)`
    sm:grid-cols-2
`;

const gridLayouts = {
    'grid-1-col': OneColumnGrid,
    'grid-2-col': TwoColumnGrid
};

const ChoicesGrid = (props) => {

    // get the layout
    let ChoiceLayout = GridContainer;
    let isTwoCol = false;
    if (props.question?.layout) {
        isTwoCol = props.question?.layout === 'grid-2-col' && true;
        ChoiceLayout = gridLayouts[props.question.layout] ?? GridContainer;
    }

    // render the choices
    const gridItems = useMemo(() => {
        const { question, choiceColor, enableAnswerButtons, buttonState, onClickChoice, audioButtonClicked, answerKey } = props;
        if (!question?.choices)
            return null;
        // check if buttons are disabled
        const handleClickChoice = (index) => {
            if (enableAnswerButtons && onClickChoice)
                onClickChoice(index);
        };
        return question.choices.map((choice, i) => {
            return (
                <ChoiceItem
                    key={choice.id}
                    id={`question-choice-${i}`}
                    index={i}
                    choice={choice}
                    question={question}
                    buttonState={buttonState}
                    enableAnswerButtons={enableAnswerButtons}
                    audioButtonClicked={audioButtonClicked}
                    choiceColor={choiceColor}
                    onClickChoice={handleClickChoice}
                    answerKey={answerKey}
                    twoColLayout={isTwoCol}
                />
            );
        });
    }, [isTwoCol, props]);

    if (!props.question)
        return null;



    return (
        <ChoiceLayout
            key={`${props.question?.id}-${props.question?.layout}`}
            className={`choices-layout--${props.question?.layout}`}
            data-testid='choices-layout'
            data-layout={props.question?.layout}
        >
            {gridItems}
        </ChoiceLayout>
    );
};

export default ChoicesGrid;
