import * as React from 'react';

function SvgClassIcon04(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF8C4B' />
            <path
                d='M21.268 25.169H13.93v-2.993l6.863-10.45h3.895v10.45h2.162v2.993h-2.162v3.372h-3.42v-3.372zm0-8.788h-.047l-3.729 5.795h3.776v-5.795z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon04;
