import React, { useState } from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';

import { RecordPlaque, Plaque, ListBox } from 'components';
import { useClasses, useClassStudents } from 'state/Teacher/ClassState';
import { useStudentStats } from 'state/Student/VideoStats';
import { recordImageURL } from 'static/records/FilledRecordsAssets';

const MaxWidthWrapper = styled.div`
    max-width: 330px;
    @media screen and (max-width: 1300px) {
        width: 320px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 20px;
    }
`;

const ParentRecordNullStateMessage = styled.div`
    background: rgba(117, 105, 214, 0.5);
    text-align: left;
    margin-top: 17px;
    border-radius: 20px;
    padding: 27px 40px;
    font-family: Avenir, serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
`;

const AssignmentProgressContainer = (props) => {
    let { video, handleTakeChallenge, instructor, parent, selectedStudent, setSelectedStudent, listBoxItems } = props;
    const classes = useClasses();
    const { students } = useClassStudents(null);
    let showListBox = listBoxItems?.length > 1;
    const selectedStudentStats = useStudentStats(selectedStudent?.id ?? null);
    let videoId = video.id;
    const levelTitles = ['', 'Silver', 'Gold', 'Platinum'];

    if (!video) return null;

    // TODO break this out into a reusable function
    const calcRecordValue = (videoId, level) => {
        if (!students) return { value: 0, students: 0, records: 0 };
        let value = 0;
        let recordCount = 0;
        let studentCount = 0;
        students.forEach((student) => {
            if (student.video_stats) {
                const stats = student.video_stats[videoId];
                if (stats) {
                    if (stats.current_level > level) {
                        recordCount += 1;
                    }
                }
            }
            studentCount += 1;
        });

        if (studentCount !== 0) {
            value = (recordCount / studentCount) * 100;
        }

        return {
            value: value,
            students: studentCount,
            records: recordCount
        };
    };

    let assignmentData = [];
    let currentLevel = 0;
    let level1 = 0;
    let level2 = 0;
    let level3 = 0;
    if (video.stats) {
        currentLevel = video.stats?.current_level ?? 0;
        if (video.stats.quiz_levels) {
            level1 = video.stats?.quiz_levels[1] ?? 0;
            level2 = video.stats?.quiz_levels[2] ?? 0;
            level3 = video.stats?.quiz_levels[3] ?? 0;
        }
    }

    if (!instructor) {
        assignmentData = [
            {
                title: 'Silver',
                level: 1,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level1 ? level1.attempts : null
            },
            {
                title: 'Gold',
                level: 2,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level2 ? level2.attempts : null
            },
            {
                title: 'Platinum',
                level: 3,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level3 ? level3.attempts : null
            }
        ];
    }

    if (instructor) {
        for (let level = 1; level <= 3; level++) {
            const { value, students, records } = calcRecordValue(videoId, level);
            const pct = Math.round(value);
            assignmentData.push({
                recordTitle: levelTitles[level],
                date: null,
                level: level,
                description: `${records} of ${students} Students earned the ${levelTitles[level]} Record`,
                recordImages: recordImageURL(level, pct),
                recordText: `${pct}%`,
                title: video.title,
                popUpCompletion: `${records} of ${students} students`
            });
        }
    }

    if (parent && selectedStudentStats) {
        let selectedStatsWithVideo = selectedStudentStats.find((stat) => stat.video_id === videoId);
        if (selectedStatsWithVideo) {
            currentLevel = selectedStatsWithVideo.current_level ?? 0;
            if (selectedStatsWithVideo.quiz_levels) {
                level1 = selectedStatsWithVideo?.quiz_levels[1] ?? 0;
                level2 = selectedStatsWithVideo?.quiz_levels[2] ?? 0;
                level3 = selectedStatsWithVideo?.quiz_levels[3] ?? 0;
            }
        }
        assignmentData = [
            {
                title: 'Silver',
                level: 1,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level1 ? level1.attempts : null
            },
            {
                title: 'Gold',
                level: 2,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level2 ? level2.attempts : null
            },
            {
                title: 'Platinum',
                level: 3,
                currentLevel: currentLevel,
                currentScore: video.stats?.current_score ?? 0,
                attempts: level3 ? level3.attempts : null
            }
        ];
    }

    const progressNodes = assignmentData.map((item, index) => {
        if (instructor && !parent) {
            // teacher - return plaque
            const copy = {
                title: item.title,
                videoTitle: item.description
            };
            return (
                <MaxWidthWrapper key={index} id='video-session-records'>
                    <Plaque
                        record={true}
                        recordImage={item.recordImages}
                        recordText={item.recordText}
                        multiplier='6x'
                        copy={copy}
                        teacher={true}
                        level={item.level}
                        classes={classes}
                        useClassStudents={useClassStudents}
                        popUpCompletion={item.popUpCompletion}
                        videoId={videoId}
                        tour={index === 0}
                    />
                </MaxWidthWrapper>
            );
        }
        // student
        return (
            <MaxWidthWrapper key={index}>
                <RecordPlaque
                    key={item.title}
                    title={item.title}
                    level={item.level}
                    currentLevel={item.currentLevel}
                    currentScore={item.currentScore}
                    attempts={item.attempts}
                    handleTakeChallenge={handleTakeChallenge}
                    parent={parent}
                />
            </MaxWidthWrapper>
        );
    });

    return (
        <div tw=' py-4 md:py-0 lg:py-4'>
            {parent && showListBox && (
                <>
                    <div tw='maxWidth[300px] my-2.5'>
                        <ListBox items={listBoxItems} onChange={(value) => setSelectedStudent(value)} selected={selectedStudent} variant='transparent' />
                        <div tw='mt-6 text-white text-2xl'>Records Earned</div>
                    </div>
                    {currentLevel === 0 && (
                        <div tw='mt-4 mb-10'>
                            <ParentRecordNullStateMessage>
                                Your child earns Records by taking the math Challenges, which can be accessed from your child’s Muzology account.
                            </ParentRecordNullStateMessage>
                        </div>
                    )}
                </>
            )}
            <div tw='flex flex-col lg:flex-row justify-between items-center'>{progressNodes}</div>
        </div>
    );
};

export default AssignmentProgressContainer;
