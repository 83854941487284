import * as React from 'react';

function SvgClassIcon00(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#fff' />
            <g
                clipPath='url(#ClassIcon_00_svg__clip0)'
                fillRule='evenodd'
                clipRule='evenodd'
            >
                <path
                    d='M8 10.885v9.906c0 .44.368.796.823.796a.81.81 0 00.822-.796v-9.906a.81.81 0 00-.822-.796.81.81 0 00-.823.796z'
                    fill='url(#ClassIcon_00_svg__paint0_linear)'
                />
                <path
                    d='M12.57 7.022a.81.81 0 00-.822.797v17.07c0 .44.368.796.823.796a.81.81 0 00.823-.796V7.82a.81.81 0 00-.823-.797z'
                    fill='url(#ClassIcon_00_svg__paint1_linear)'
                />
                <path
                    d='M15.313 12.064v17.071c0 .44.369.796.823.796a.81.81 0 00.823-.796v-17.07a.81.81 0 00-.823-.797.81.81 0 00-.822.796z'
                    fill='url(#ClassIcon_00_svg__paint2_linear)'
                />
                <path
                    d='M30.489 10.885v9.906c0 .44.368.796.822.796a.81.81 0 00.823-.796v-9.906a.81.81 0 00-.823-.796.81.81 0 00-.822.796z'
                    fill='url(#ClassIcon_00_svg__paint3_linear)'
                />
                <path
                    d='M27.564 7.022a.81.81 0 00-.823.797v17.07c0 .44.368.796.823.796a.81.81 0 00.822-.796V7.82a.81.81 0 00-.822-.797z'
                    fill='url(#ClassIcon_00_svg__paint4_linear)'
                />
                <path
                    d='M23.175 12.064v17.071c0 .44.369.796.823.796a.81.81 0 00.823-.796v-17.07a.81.81 0 00-.823-.797.81.81 0 00-.823.796z'
                    fill='url(#ClassIcon_00_svg__paint5_linear)'
                />
                <path
                    d='M19.244 16.133v17.07a.81.81 0 00.814.797h.018a.81.81 0 00.814-.796V16.133a.81.81 0 00-.823-.796.81.81 0 00-.823.796z'
                    fill='url(#ClassIcon_00_svg__paint6_linear)'
                />
            </g>
            <defs>
                <linearGradient
                    id='ClassIcon_00_svg__paint0_linear'
                    x1={8.764}
                    y1={11.899}
                    x2={18.704}
                    y2={25.591}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint1_linear'
                    x1={12.537}
                    y1={10.353}
                    x2={22.411}
                    y2={23.954}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint2_linear'
                    x1={16.077}
                    y1={10.179}
                    x2={25.952}
                    y2={23.78}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint3_linear'
                    x1={31.099}
                    y1={1.553}
                    x2={41.039}
                    y2={15.245}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint4_linear'
                    x1={27.491}
                    y1={3.455}
                    x2={37.365}
                    y2={17.057}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint5_linear'
                    x1={23.919}
                    y1={6.562}
                    x2={33.793}
                    y2={20.163}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <linearGradient
                    id='ClassIcon_00_svg__paint6_linear'
                    x1={19.983}
                    y1={9.776}
                    x2={29.858}
                    y2={23.377}
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#6E60E6' />
                    <stop
                        offset={1}
                        stopColor='#1CC8E1' />
                </linearGradient>
                <clipPath id='ClassIcon_00_svg__clip0'>
                    <path
                        fill='#fff'
                        transform='translate(8 7)'
                        d='M0 0h24v27H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgClassIcon00;
