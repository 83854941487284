import React from 'react';

const SvgCategoryExpressions = (props) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 22 22'
        {...props}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z'
            fill='#fff' />
        <path
            d='M6.286 7.072h9.429v2.336H6.286V7.072zm0 5.522h9.429v2.335H6.286v-2.335z'
            fill='url(#paint0_linear)' />
        <defs>
            <linearGradient
                id='paint0_linear'
                x1='11'
                y1='7.072'
                x2='11'
                y2='14.929'
                gradientUnits='userSpaceOnUse'>
                <stop stopColor='#1E215F' />
                <stop
                    offset='1'
                    stopColor='#070A3B' />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgCategoryExpressions;
