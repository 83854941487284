import React, { useEffect, useRef } from 'react';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const initialState = 0;
const elementClassName = 'main-content-container';

const reducer = (state, action) => {
    let element = null;
    let position = null;

    switch (action.type) {
        case 'SET_POSITION':
            // console.log('scroll position:', action.payload);
            return action.payload;

        case 'ON_SCROLL_EVENT':
            console.log('scroll event:', action);

            element = action.event.target;

            if (elementClassName && !element.className.includes(elementClassName)) {
                // console.debug('Ignore Scroll:', element.className);
                return state;
            }

            position = element.scrollTop;
            // console.log('scroll position:', position);
            return position;

        default:
            return state;
    }
};

const ScrollState = ({ children }) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    // const elementClassName = props.elementClassName || 'main-content-container';
    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

export function useScrollStore() {
    return React.useContext(StoreContext);
}

export function useScrollDispatch() {
    return React.useContext(DispatchContext);
}

export function useSetScrollPosition() {
    const dispatch = React.useContext(DispatchContext);
    return (position) => {
        dispatch({ type: 'SET_POSITION', payload: position });
    };
}

export function useScrollPositionEvent(elementClassName = null) {
    const dispatch = React.useContext(DispatchContext);
    const container = useRef(null);

    useEffect(() => {
        const onSetScrollPosition = (event) => {
            // const elementClassName = 'main-content-container';

            // get the target element
            const element = event.target;

            // check a class name if specified
            if (elementClassName && !element.className.includes(elementClassName)) {
                // console.debug('Ignore Scroll:', element.className);
                return;
            }

            // get the position
            const scrollPosition = element.scrollTop;
            dispatch({ type: 'SET_POSITION', payload: scrollPosition });
            // scrollDispatch({ type: "ON_SCROLL_EVENT", event })
        };
        const currentContainer = container.current;
        currentContainer.addEventListener('scroll', onSetScrollPosition);
        return () => currentContainer.removeEventListener('scroll', onSetScrollPosition);
    }, [container, dispatch, elementClassName]);

    return container;
}

export default ScrollState;
