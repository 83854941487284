import * as React from 'react';

function SvgClassIcon09(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#D2FF1D' />
            <path
                d='M20.107 22.532c-.19.048-.38.087-.57.119-.19.032-.388.047-.594.047-.792 0-1.512-.142-2.162-.427a4.998 4.998 0 01-1.662-1.164 5.236 5.236 0 01-1.069-1.733 6.147 6.147 0 01-.38-2.162c0-.87.159-1.654.475-2.351a5.73 5.73 0 011.33-1.829 5.965 5.965 0 011.995-1.164 6.979 6.979 0 012.47-.427c.871 0 1.686.143 2.447.427.775.27 1.44.658 1.994 1.164a5.288 5.288 0 011.33 1.829c.333.697.5 1.48.5 2.351a7.78 7.78 0 01-.167 1.663c-.11.49-.261.958-.451 1.401-.174.443-.388.87-.642 1.282-.237.396-.49.808-.76 1.236l-3.68 5.747h-4.347l3.942-6.009zm-2.874-5.177c0 .76.245 1.393.736 1.9.507.49 1.164.736 1.971.736.808 0 1.457-.245 1.948-.736.506-.507.76-1.14.76-1.9s-.253-1.386-.76-1.877c-.491-.506-1.14-.76-1.948-.76-.807 0-1.464.254-1.971.76-.49.491-.736 1.117-.736 1.877z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon09;
