import axios from 'axios';
import axiosRetry from 'axios-retry';
import { configure as configureUseAxios } from 'axios-hooks';
import { MUZOLOGY_API_URL } from '../config';
import { handleNetworkError } from '../lib/axios';

export const getAuthToken = (initialValue = null) => {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('auth');
        const auth = JSON.parse(item);
        return auth ? auth['token'] : initialValue;
    } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
    }
};

// axios.defaults.baseURL = MUZOLOGY_API_URL;
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// configure client for API calls
export const apiClient = axios.create({
    baseURL: MUZOLOGY_API_URL,
    withCredentials: true,
    timeout: 100000,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: { 'Content-Type': 'application/json' }
});

// retry network requests 3 times on network failure
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

// Add a response interceptor to check for 401/403 (unauthenticated) and redirect to login
apiClient.interceptors.response.use(
    (response) => {
        // if( response.status !== 200 ) {
        //     console.log('[API Client] Intercept response', response.status, response.statusText);
        // }
        return response;
    },
    (err) => {
        console.log('[API Client] Intercept error', err.response?.status, err.response?.statusText);
        if (err.response?.status === 403 || err.response?.status === 401) {
            // redirect to login
            // if location starts with any login path then don't redirect
            // console.log('[API Client] Intercept 403 - redirect to login from', currentPath);
            const loginPaths = ['/login', '/join', '/sign_up', '/password_reset', '/new_password'];
            const currentPath = window.location.pathname;
            const isLoginPath = loginPaths.some((path) => currentPath.startsWith(path));
            if (isLoginPath) {
                // already in the right place...
                console.log('[API Client] rejecting login redirect', currentPath);
                return;
            }

            console.error('[API Client] User is not authenticated, redirecting to login');

            // get current path, query, and hash
            const current = window.location.href.replace(window.location.origin, '');
            console.log('current', current);

            // redirect to login
            // const redirect = `/login/?next=${encodeURIComponent(current)}`;
            const redirect = '/login';
            console.error('[API Client] Redirecting to:', redirect);
            window.location.href = redirect;

        } else {
            // console.log('-----');
            console.log('[API Client] Intercept error', err);
            handleNetworkError(err);
            // console.log('-----');
            // handleAPIError(err);
            // console.log('-----');
        }
        return Promise.reject(err);
    }
);

// configure useAxios to use this apiClient
configureUseAxios({ axios: apiClient });

export const handleAPIError = (error) => {
    console.error('API error: ', error);
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
};

// standard api fetcher for all useSwr calls
export const apiFetcher = (url) => {
    // console.debug('[api fetcher] get', url);
    return apiClient.get(url, { withCredentials: true })
        .then(response => {
            // console.debug(`[api fetcher] complete ${url}`, response?.data);
            // console.log(`[apiFetcher] ${response.config?.method} ${response.config?.url}`, response.data);
            // console.dir(response);
            return response.data;
        })
        .catch(err => {
            handleNetworkError(err);
        });
    // .then((response) => response.data)
    // .catch(err => {
    //     console.error(`[api fetcher] Error fetching ${url}: ${err}`);
    // });
};
