import React from 'react';

const LockSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' fill='none'>
            <path stroke='#9D91BB' strokeLinecap='round' strokeWidth='1.2' d='M3.834 5V3.667A2.667 2.667 0 0 1 6.501 1v0a2.667 2.667 0 0 1 2.666 2.667V5' />
            <path fill='#9D91BB' fillRule='evenodd' d='M.5 7a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2V7Zm6.732 1.835a.888.888 0 1 0-1.464.002A.753.753 0 0 0 5.75 9v1.333a.75.75 0 0 0 1.5 0V9a.753.753 0 0 0-.018-.165Z' clipRule='evenodd' />
        </svg>
    );
};

export default LockSVG;
