import React from 'react';

const SvgRightArrow = (props) => (
    <svg
        viewBox='0 0 13.449 23.421'
        width='1em'
        height='1em'
        {...props}>
        <path
            data-name='Path 16'
            d='M10.444 12.923L.526 3.005a1.725 1.725 0 010-2.48 1.725 1.725 0 012.48 0l9.918 9.918a1.725 1.725 0 010 2.48 1.725 1.725 0 01-2.48 0z'
            fill='currentColor'
        />
        <path
            data-name='Path 17'
            d='M.525 20.416l9.915-9.918a1.725 1.725 0 012.48 0 1.725 1.725 0 010 2.48l-9.915 9.917a1.725 1.725 0 01-2.48 0 1.826 1.826 0 010-2.479z'
            fill='currentColor'
        />
    </svg>
);

export default SvgRightArrow;
