import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import { Button, Modal } from 'components';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { useLesson } from 'state/CatalogState';
import VideoThumbnail from '../../../src/components/VideoThumbnail';

const VideoDescriptionModal = ({ video, ...props }) => {
    const lesson = useLesson(video?.lesson_id);
    return (
        <>
            <Modal
                modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
                isOpen={props.isOpen}
                setIsOpen={props.setIsOpen}>
                <div tw='mx-6 '>
                    <div tw='h-14 bg-gradient-to-r to-PholxPink from-SkyBlueCrayola flex justify-center items-center relative rounded-tl-xl rounded-tr-xl'>
                        <h1 tw=' text-lg font-medium  text-white align-middle '>{video.title}</h1>
                        <button
                            onClick={() => props.setIsOpen(false)}
                            tw='bg-white rounded-full cursor-pointer absolute -right-3 -top-3 w-10 h-10 flex justify-center items-center '
                        >
                            <SvgWhiteXButton tw='w-4 h-4' />
                        </button>
                    </div>

                    <div tw='bg-white p-4 md:p-6 rounded-b-xl flex-col '>
                        <div tw='flex flex-col md:flex-row justify-between md:space-x-8 space-y-6 md:space-y-0 mt-2'>
                            <div tw='w-full'>
                                <h2 tw='text-lg font-medium text-center md:text-left'>Description:</h2>
                                <p tw='py-3 text-DarkLiver'>{lesson?.description}</p>
                            </div>
                            <div tw='w-full maxWidth[361px] '>
                                <VideoThumbnail video={video} />
                            </div>
                        </div>
                        <div
                            tw='text-right mt-16'
                            value='done'>
                            <Button
                                onClick={() => props.setIsOpen(false)}
                                tw=''>
                                Done
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

VideoDescriptionModal.propTypes = {
    isOpen: PropTypes.bool,
    video: PropTypes.object
};

export default VideoDescriptionModal;
