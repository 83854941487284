import React from 'react';
import { css } from 'twin.macro';



const ActionBox = ({ children, containerStyle }) => {
    return (
        <div tw='rounded-2xl relative' css={[containerStyle]}>
            {children}
        </div>
    );
};

export default ActionBox;