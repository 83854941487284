import React from 'react';
import ReactDOM from 'react-dom';
import './preflight.css';
import 'utils/string.js'; // to attach the plural methods to String
import GlobalStyles from 'app/styles/GlobalStyles';

// import { inject } from '@vercel/analytics';
import * as serviceWorker from './serviceWorker';
import App from './app/index';
import AppProviders from './context';
import store from './store';
import 'static/fonts/fonts.css';
import 'static/fonts/monotype/monotype.css';
import SiteProvider from './context/SiteProvider';
import './index.css';

import './styles.scss';
import 'react-tooltip/dist/react-tooltip.css';
import 'bitmovin-player/bitmovinplayer-ui.css';

// render the app
ReactDOM.render(
    <AppProviders store={store}>
        <GlobalStyles />
        <App />
    </AppProviders>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// hot module reload
if (module.hot) {
    module.hot.accept();
}
