import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import 'twin.macro';
import { Doughnut } from 'react-chartjs-2';
import { Button, ClassIcon } from 'components';
import RecordImg from 'components/RecordImg';
import moment from 'moment';
import { ArrowDown, ArrowUp } from 'images/svg';
import InformationIcon from 'static/images/information-circle-icon.svg';
import MessageIcon from 'static/images/message-icon.svg';
import { useAllStudents, useClasses } from '../../state/Teacher/ClassState';
import { createAssignmentGradesURL } from '../../hooks/useGradeExport';
import { useStudentProgressModal } from '../AssignmentGradebook/StudentProgressModal/StudentProgressModal';
import { openURL } from '../../utils';

const PlaylistAssignmentCard = ({ assignment, index, editAssignment, editDraft, onDeleteAssignment, defaultOpen, isParent }) => {
    // const { status, classGrades, startDate, dueDate } = assignmentData;

    const [cardOpen, setCardOpen] = useState(defaultOpen ?? false);
    const { draft, assigned_classes, assigned_students, due, start, published_date, quiz_level_goal, quiz_score_goal, message_content, student_instances, id } =
        assignment;

    const { students, isLoading } = useAllStudents();

    // all classes
    const classes = useClasses();
    const totalAssignedStudents = assignment.student_instances.length;
    const totalStudentsCompleted = assignment.student_instances.filter((student) => student.completed).length;

    const [isStudentProgressModalOpen, openStudentProgressModal] = useState(null);

    // get a list of all classes that have at least 1 assigned student
    const assignedClasses = useMemo(
        () =>
            classes.filter((cls) => {
                // first check if this class is assigned outright
                if (assigned_classes.includes(cls.id)) return true;

                // second check if any students in this class are assigned individually
                if (assigned_students.some((id) => cls.students.includes(id))) return true;

                // class has no assignments
                return false;
            }),
        [assigned_classes, assigned_students, classes]
    );

    console.log('*** found assignedClasses', assignedClasses);

    // get the student progress modal
    const [ProgressModal, openProgressModal] = useStudentProgressModal();

    // construct the class panels
    const classNodes = useMemo(() => {
        return assignedClasses.map((cls) => {
            let foundStudents = [];

            // check if this class is assigned outright
            if (assigned_classes.includes(cls.id)) {
                // this class is assigned, add all students
                foundStudents = cls.students.map((student_id) => students.find((s) => s.user.id === student_id));
                // console.log('*** class is assigned, adding all students', assigned_students);
            } else {
                // gather explicitly assigned students - perform an intersection of assigned students and class students
                foundStudents = students.filter((student) => cls.students.includes(student.user.id) && assigned_students.includes(student.user.id));
                // foundStudents = cls.students.filter(student_id => assigned_students.includes(student_id));
                // console.log('*** class is assigned, adding all students', foundStudents);
            }
            // console.log('*** foundStudents', foundStudents);

            // let assigned_students = students.reduce((acc, student) => {
            //     student.classes.forEach((class_id) => {
            //         if (cls.id === class_id) acc.push(student);
            //     });
            //     return acc;
            // }, []);

            let total = 0;
            let completed = 0;
            let progress = 0;

            foundStudents.forEach((student) => {
                const student_id = student.user.id;
                total += 1;
                const instance = student_instances.find((instance) => instance.student_id === student_id);
                if (!instance) return;
                if (instance.completed) completed += 1;
                else if (instance.completion_percent > 0) progress += 1;
            });

            return (
                <div key={cls.id} className='playlist-assignment-card--grade-item' id='playlist-classes-status'>
                    <div className='playlist-assignment-card--grade-info'>
                        <div className='playlist-assignment-card--grade-class-name'>
                            <ClassIcon name={cls.display_icon ?? 'ClassIcon00'} />
                            <span>{cls.title}</span>
                        </div>
                        <button aria-label='View Progress' className='playlist-assignment-card--grade-status' onClick={() => openProgressModal(assignment, foundStudents, cls.id)}>
                            {`${completed} of ${total} Students Complete`}
                        </button>
                    </div>

                    <div className='playlist-assignment-card--grade-chart'>
                        <Doughnut
                            data={{
                                datasets: [
                                    {
                                        data: [completed, progress, total - completed - progress],
                                        backgroundColor: ['#5FC17A', '#887BE3', '#A5A5A5']
                                    }
                                ]
                            }}
                            options={{
                                cutout: '70%',
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                    reverse: true
                                },
                                layout: {
                                    padding: {
                                        top: -10,
                                        left: 0,
                                        right: 0,
                                        bottom: 0
                                    }
                                },
                                rotation: -1 * Math.PI,
                                animation: false,
                                plugins: {
                                    tooltip: {
                                        enabled: false
                                    }
                                }
                            }}
                            redraw={false}
                        />
                    </div>
                </div>
            );
        });
    }, [assignedClasses, assigned_classes, assigned_students, assignment, openProgressModal, student_instances, students]);

    return (
        <PlaylistAssignmentCardContainer>
            <div
                className='playlist-assignment-card--header'
                tw='bg-PurpleWhite text-EarieBlack font-sans font-black text-lg flex py-4 px-3 cursor-pointer'
                onClick={() => setCardOpen(!cardOpen)}
            >
                <div tw='w-1/2 flex items-center'>
                    <span>Assignment {index + 1}</span>
                    <span tw='ml-0.5'> (Due: {moment.utc(assignment.due).local().format('MM-DD-YYYY')})</span>
                    <button
                        tw='font-medium text-DavysGrey underline ml-2'
                        onClick={() => {
                            editAssignment(id);
                            // setEditAssignmentModal(true);
                            // setAssignmentId(id);
                        }}
                    >
                        Edit
                    </button>
                </div>
                <div tw='w-1/2 flex items-center justify-end'>
                    <div>
                        Completed:{' '}
                        <span tw='font-medium'>
                            {totalStudentsCompleted} of {totalAssignedStudents} students
                        </span>
                    </div>
                    <button tabIndex={0} tw='ml-4'>{cardOpen ? <ArrowUp /> : <ArrowDown />}</button>
                </div>
            </div>
            <div className={`playlist-assignment-card--body ${cardOpen ? 'body-opened' : ''}`}>
                {draft && (
                    <div className='playlist-assignment-card--draft'>
                        <img src={InformationIcon} />
                        <span>
                            You saved this assignment as a draft.{' '}
                            <a
                                onClick={() => {
                                    editDraft(id);
                                }}
                            >
                                Click Here
                            </a>{' '}
                            to continue.
                        </span>
                    </div>
                )}
                <div className='playlist-assignment-card--color-info'>
                    <div className='playlist-assignment-card--color-info-item color-info--completed'>
                        <span />
                        <p>Completed</p>
                    </div>
                    <div className='playlist-assignment-card--color-info-item color-info--in-progress'>
                        <span />
                        <p>In Progress</p>
                    </div>
                    <div className='playlist-assignment-card--color-info-item color-info--not-started'>
                        <span />
                        <p>Not started</p>
                    </div>
                </div>
                <div className='playlist-assignment-card--grades'>{classNodes}</div>
                <div className='playlist-assignment-card--grades-export'>
                    <Button
                        variant='purple'
                        onClick={() => {
                            openURL(createAssignmentGradesURL(assignment.id));
                        }}
                        id='playlist-export-grades'
                    >
                        EXPORT ALL CLASS GRADES
                    </Button>
                </div>
                <div className='playlist-assignment-card--info'>
                    <div className='playlist-assignment-card--info-item info-date'>
                        {/*
                        <b>Start Date:</b>
                        <span>{moment(start).format('MM/DD/YYYY')}</span>
                        */}
                        <b>Due Date:</b>
                        <span>{moment(due).format('MM/DD/YYYY')}</span>
                    </div>
                    <div className='playlist-assignment-card--info-item info-goal'>
                        <b>Goal:</b>
                        <span>{`${quiz_score_goal}%`}</span>
                        <RecordImg level={quiz_level_goal} />
                    </div>
                    {message_content && (
                        <div className='playlist-assignment-card--info-item info-message'>
                            <b>Message:</b>
                            <div className='info-message-container'>
                                <img src={MessageIcon} alt='message' />
                                <span>{message_content}</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='playlist-assignment-card--delete'>
                    <a onClick={onDeleteAssignment}>{`Delete This Assignment${draft ? ' Draft' : ''}`}</a>
                </div>
            </div>
            <ProgressModal />
        </PlaylistAssignmentCardContainer>
    );
};

PlaylistAssignmentCard.propTypes = {
    assignment: PropTypes.object,
    index: PropTypes.number
};

export default PlaylistAssignmentCard;

const PlaylistAssignmentCardContainer = styled.div`
    .playlist-assignment-card--header {
        border: 1px solid #c7c7ff;
    }

    .playlist-assignment-card--body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0;
        height: 100%;
        max-height: 0;
        transition: all 0.2s ease-in-out;

        &.body-opened {
            max-height: 1000px;
        }

        .playlist-assignment-card--draft {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            padding: 12px;
            background: #ee7171;

            span {
                font-weight: 800;
                font-size: 14px;
                line-height: 19px;
                color: #ffffff;
                margin-left: 6px;
            }

            a {
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        .playlist-assignment-card--color-info {
            display: flex;
            justify-content: flex-end;
            padding: 26px 9px 19px;

            .playlist-assignment-card--color-info-item {
                display: flex;
                align-items: center;
                padding: 0 9px;

                span {
                    display: block;
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #1a1717;
                    margin-left: 5px;
                }

                &.color-info--completed {
                    span {
                        background: #5fc17a;
                    }
                }

                &.color-info--in-progress {
                    span {
                        background: #887be3;
                    }
                }

                &.color-info--not-started {
                    span {
                        background: #a5a5a5;
                    }
                }
            }
        }

        .playlist-assignment-card--grades {
            display: flex;
            flex-wrap: wrap;
            padding: 0 9px;
            margin-top: 3px;

            .playlist-assignment-card--grade-item {
                margin: 10px 9px;
                border: 2px solid #c7c7ff;
                background: #ffffff;
                border-radius: 5px;
                padding: 11px 24px;
                width: calc(50% - 18px);
                display: flex;
                align-items: center;

                .playlist-assignment-card--grade-info {
                    flex: 1;

                    .playlist-assignment-card--grade-class-name {
                        display: flex;
                        align-items: center;

                        svg {
                            width: 29px;
                            height: 29px;
                        }

                        span {
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 25px;
                            display: flex;
                            color: #1a1717;
                            margin-left: 12px;
                        }
                    }

                    .playlist-assignment-card--grade-status {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;
                        text-decoration-line: underline;
                        margin-top: 10px;
                        cursor: pointer;
                        color: #5a5353;

                        &:hover {
                            color: black;
                        }

                        &:active {
                            color: #999999;
                        }
                    }
                }

                .playlist-assignment-card--grade-chart {
                    width: 68px;
                    height: 68px;
                    transform: scaleX(-1);
                }
            }
        }

        .playlist-assignment-card--grades-export {
            padding: 10px 18px;
            text-align: right;
        }

        .playlist-assignment-card--info {
            display: flex;
            flex-wrap: wrap;
            padding: 0 9px;
            margin: 3px 2px;

            .playlist-assignment-card--info-item {
                background: #ffffff;
                border: 2px solid #c7c7ff;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
                margin: 7px;
                padding: 13px;
                display: flex;
                align-items: center;
                justify-content: center;

                b {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 25px;
                    color: #1a1717;
                    text-align: right;
                    padding: 2px 0;
                }

                span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 25px;
                    color: #1a1717;
                    padding: 2px 9px;
                }

                &.info-date {
                    width: calc(60% - 14px);
                    flex-wrap: wrap;

                    b {
                        width: 45%;
                    }

                    span {
                        width: 55%;
                    }
                }

                &.info-goal {
                    width: calc(40% - 14px);

                    span {
                        padding-right: 7px;
                    }

                    img {
                        width: 26px;
                        height: 26px;
                    }
                }

                &.info-message {
                    flex-wrap: wrap;

                    b {
                        width: 100%;
                        text-align: center;
                    }

                    .info-message-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 12px 17px 0;

                        span {
                            padding: 0 0 0 14px;
                        }
                    }
                }
            }
        }

        .playlist-assignment-card--delete {
            text-align: right;
            padding: 6px 18px;
            margin-bottom: 50px;

            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                text-decoration-line: underline;
                color: #ee7171;
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
`;

const assignmentData = {
    // status: 'draft',
    classGrades: [
        {
            icon: 'ClassIcon01',
            name: '1st Period',
            total: 25,
            completed: 15,
            progress: 7
        },
        {
            icon: 'ClassIcon27',
            name: 'Gamer Class',
            total: 25,
            completed: 12,
            progress: 6
        },
        {
            icon: 'ClassIcon01',
            name: '1st Period',
            total: 25,
            completed: 19,
            progress: 3
        },
        {
            icon: 'ClassIcon27',
            name: 'Gamer Class',
            total: 25,
            completed: 13,
            progress: 14
        },
        {
            icon: 'ClassIcon01',
            name: '1st Period',
            total: 25,
            completed: 10,
            progress: 9
        },
        {
            icon: 'ClassIcon27',
            name: 'Gamer Class',
            total: 25,
            completed: 13,
            progress: 15
        }
    ],
    startDate: '03/04/2021',
    dueDate: '03/21/2021',
    goal: 100,
    message: 'Get 80% on Platinum. Extra credit for 100% on Platinum. You can do it! I believe in you!'
};
