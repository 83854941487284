import React from 'react';
import SlideWrapper from '../../Components/SlideWrapper';
import ActionBox from '../../Components/ActionBoxThird';
import { css } from 'twin.macro';
import ThreeRecords from '../../imgs/shared_slide_imgs/ThreeRecords.png';
import HeadPhones from '../../imgs/shared_slide_imgs/HeadPhones.png';
import QuestionMark from '../../imgs/shared_slide_imgs/QuestionMark.png';
import WaveBackground from '../../imgs/WaveBackground.png';
import LinesBackground from '../../imgs/LinesBackground.png';
import BackgroundImage from 'lib/components/BackgroundImage';
import SmallDots from '../../imgs/SmallDots.png'

const ContainerStyle = css`
    background: rgba(100, 65, 185, 0.60);
    backdrop-filter: blur(10px);
    min-height: 179px;
    padding: 10px 0; 
    display: flex;
    justify-content: flex-end;
`;

const StudentExperience = () => {
    return (
        <SlideWrapper slide={'purple-pink'} >
            <BackgroundImage image={LinesBackground} tw='mix-blend-soft-light' />
            <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light' />
            <div tw='bg-[#76E6FC] h-[123px] md:h-[123px] w-[3px] absolute top-1/2 transform -translate-y-1/2' />
            <div tw='absolute top-5 lg:top-[210px] banner_xl:top-44  right-10 lg:right-0 lg:left-10'>
                <img src={SmallDots} />
            </div>
            <div tw='font-Poppins font-semibold flex flex-col lg:flex-row min-h-[400px] lg:min-h-[280px] banner_xl:min-h-[250px] px-4 banner_xl:px-6 pt-6'>
                <div tw='w-[300px]'>
                    <div tw='text-white text-2xl lg:text-3xl   max-w-full md:max-w-[220px] mb-8 md:mb-0 md:mt-3 banner_xl:mt-1 text-left '>The Student Experience</div>
                    <div tw='text-white text-lg lg:text-xl w-[290px] mt-2'>Here’s how your students use Muzology.</div>
                </div>
                <div tw='flex flex-col md:flex-row gap-3 w-full mt-3 lg:mt-0'>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center pb-3'>
                                    <div>
                                        <div tw='font-semibold'>Answer Warm Up Questions </div>
                                        <div tw='font-normal -mt-1.5'>to Unlock Music Videos. </div>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='absolute -top-0 lg:-top-8 left-1/2 transform -translate-x-1/2 z-10 '>
                                <div tw='max-w-[140px] max-h-[140px]'>
                                    <img src={QuestionMark} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center pb-3'>
                                    <div>
                                        <div tw='font-semibold'>Watch Music Videos </div>
                                        <div tw='font-normal -mt-1.5'>Multiple Times to Remember. </div>
                                    </div>

                                </div>
                            </ActionBox>
                            <div tw='absolute -top-3 left-1/2 transform -translate-x-1/2 z-10 '>
                                <div tw='max-w-[130px] max-h-[105px]'>
                                    <img src={HeadPhones} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center pb-3'>
                                    <div>
                                        <div tw='font-semibold'>Take Math Challenges</div>
                                        <div tw='font-normal -mt-1.5'>(formative quizzes) to Level Up.</div>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='absolute -top-0 lg:-top-3 left-1/2 transform -translate-x-1/2 '>
                                <div tw='max-w-[155px] max-h-[105px]'>
                                    <img src={ThreeRecords} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </SlideWrapper>
    );
};

export default StudentExperience;