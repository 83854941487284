import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import moment from 'moment/moment';
import { ChallengeButton, ListBox } from 'components';
import icon_video from 'static/images/badges/MuzologyHeart.png';
import { useClassStudents } from 'state/Teacher/ClassState';
import FilledRecords from 'static/records/FilledRecordsAssets';
import { useLesson } from '../../../../state/CatalogState';
import './Session.css';

const VideoInfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    font-family: 'Avenir', sans-serif;
    border-radius: 8px;
    /* padding: 20px 0px; */
    margin-top: 32px;

    .header {
        color: white;
        font-size: 1.5rem;

        display: flex;
        flex-direction: column;
        flex-grow: 0;
        align-items: stretch;

        width: 100%;

        .title-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap-reverse;

            .title {
                font-size: 32px;
                color: #ffffff;
                font-family: Avenir, serif;
                font-weight: 800;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @media only screen and (max-width: 768px) {
                    font-size: 1.25rem;
                }
            }

            .favorite-button {
                font-size: 28px;
                width: 28px;
                height: auto;

                color: white;

                &:hover {
                    background: none;
                    color: #ddd;
                }

                &:active {
                    background: none;
                    color: #aaa;
                }
            }

            .vertical-divider {
                width: 2px;
                height: 48px;
                background-color: #ffffff;
                margin-left: 18px;
                margin-right: 18px;

                @media only screen and (max-width: 640px) {
                    display: none;
                }
            }
        }

        hr {
            width: 100%;
            height: 2px;
            background-color: #ffffff;
            border: none;
            padding: 0;
            margin: 11px 0;
        }

        .sub-header {
            color: white;
            font-family: Avenir, serif;
            font-weight: 300;
            font-size: 16px;
            //font-family: "Source Sans Pro",sans-serif;
            display: flex;
        }

        .description {
            line-height: normal;
            font-family: Avenir, serif;
            font-weight: 600;
            font-size: 18px;
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @media only screen and (max-width: 768px) {
                margin-top: 26px;
            }
            @media only screen and (max-width: 500px) {
                margin-top: 8px;
            }
        }
    }

    .view-content {
        display: flex;
        flex: content;
        margin-right: 350px;

        .view_count_not_met {
            background-color: #070707;
        }

        .view_count_met {
            background-color: #fb1681;
        }
    }
`;

const BorderGradientSmall = styled.div``;

function uppercaseFirstCharacter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const VideoInfo = (props) => {
    // get the video
    const { video, quiz_level_goal, quiz_score_goal, hideProgressBar, assignment, parent } = props;
    const { students } = useClassStudents(null);
    const listBoxItems = students.map((student) => {
        let views = 0;
        if( student?.video_stats ) {
            if( video.id in student.video_stats ) {
                views = student.video_stats[video.id]?.view_count ?? 0;
            }
        }
        return {
            name: `${student?.user?.first_name}'s Views: ${views}`,
            id: student?.user?.id,
            item: student
        };
    });
    const [selectedStudent, setSelectedStudent] = useState(listBoxItems[0]);
    let showListBox = listBoxItems?.length > 1;

    const lesson = useLesson(video?.lesson_id);

    if (!video) return null;
    // get the stats
    let currentLevel = 0;
    let lastView = null;
    let favorite = false;
    const stats = video?.stats ?? null;
    const videoViews = stats?.view_count === 0 ? null : stats?.view_count ?? null;
    if (stats) {
        currentLevel = stats.current_level;
        favorite = stats.favorite;
        lastView = moment.utc(stats.last_viewed).local();
    }

    const takeChallenge = () => {
        if (props.onTakeChallenge) props.onTakeChallenge();
    };

    // if this video has a quiz, create a challenge button
    let challengeButton = null;
    if (video.quiz_id && currentLevel > 0 && currentLevel < 4) {
        challengeButton = (
            <div tw='flex xsm:block justify-start sm:justify-center w-full xsm:w-72'>
                <div tw='maxWidth[280px] md:maxWidth[100%]'>
                    <ChallengeButton level={currentLevel} onClick={takeChallenge}/>
                </div>
            </div>
        );
    }

    let goalLoading = !quiz_level_goal ? 0 : quiz_level_goal;

    return (
        <VideoInfoContainer className='video-info-container'>
            <div className='header'>
                <div className='title-row'>
                    <div tw='flex items-center flex-wrap'>
                        <h1 className='title' tw='w-96 sm:w-auto my-1 mt-4 sm:my-0 sm:mt-0'>
                            {video.title}
                        </h1>

                        {/* <FavoriteButton
                            favorite={favorite}
                            toggleFavorite={props.toggleFavorite} /> */}

                        <React.Fragment>
                            <div className='vertical-divider' />
                            {parent && showListBox ? (
                                <div tw='maxWidth[500px] my-2.5'>
                                    <ListBox
                                        items={listBoxItems}
                                        onChange={(value) => setSelectedStudent(value)}
                                        selected={selectedStudent}
                                        variant='transparent'
                                    />
                                </div>
                            ) : (
                                <>
                                    <h2 tw='font-sans text-xl md:text-2.5xl font-extrabold'>Your views: {videoViews ?? 0}</h2>
                                    {videoViews > 7 && <img src={icon_video} tw='w-full maxWidth[64px] ml-6' alt={`${videoViews} views`} />}
                                </>
                            )}
                        </React.Fragment>

                        {/* <ReactHoverObserver>
                            <ViewCounter
                                className='view-counter'
                                views={videoViews} />
                        </ReactHoverObserver> */}
                    </div>
                    {challengeButton}
                </div>

                {/* <div className={'sub-header'}> Last Watched: {lastView ? uppercaseFirstCharacter(lastView.fromNow()) : ''} </div> */}

                {/* <hr className={'divider-line'} /> */}

                <div className='description'>
                    <div tw='w-full md:w-4/5'>{lesson?.description}</div>
                    {!assignment
                        ? null
                        : hideProgressBar && (
                            <div tw='relative'>
                                <div
                                    tw=' bg-white bg-opacity-0 font-black text-center mt-4'
                                    css={[
                                        css`
                                        max-width: 155px;
                                        border-left: 2px solid #1cc8e1;
                                        border-right: 2px solid #d022ed;
                                        background-image: linear-gradient(90deg, #1cc8e1, #d022ed), linear-gradient(90deg, #1cc8e1, #d022ed);
                                        background-size: 100% 2px;
                                        background-position: 0 0, 0 100%;
                                        background-repeat: no-repeat;
                                        border-radius: 5px;
                                        left: -4rem;
                                    `
                                    ]}
                                >
                                    <div tw='p-2.5 flex items-center justify-center' style={{ borderRadius: '5px', margin: '2px 0' }}>
                                        <div tw='text-white'>Goal:</div>
                                        <div tw=' text-white ml-1'>{quiz_score_goal}%</div>
                                        <div tw='h-6 w-6 ml-2'>
                                            <img src={FilledRecords[goalLoading][100]} alt={`Level ${quiz_level_goal}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </VideoInfoContainer>
    );
};

export default VideoInfo;
