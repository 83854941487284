import React from 'react';
import tw, { css, styled } from 'twin.macro';
import PropTypes from 'prop-types';

const StyledButton = styled.button(({ variant }) => [
    tw`bg-[#6E60E6] text-base rounded-lg text-white py-2 px-12 cursor-pointer leading-normal font-Inter hover:bg-[#8B7FED]`,
    variant === 'Gradient' && tw`bg-gradient-to-r to-[#d98648] from-[#d55096] hover:from-FrostBite hover:to-FrostBite`,
    variant === 'Blue' && tw`bg-[#46A6F8] text-base rounded-lg text-white w-full max-w-[350px] font-Poppins font-medium hover:bg-[#176BB2] focus:bg-[#ADD9FF]`,
    variant === 'Purple' && tw`bg-[#B679F4] text-base rounded-lg text-white w-full max-w-[350px] font-Poppins font-medium hover:bg-[#8438D2] focus:bg-[#CFA8F6] px-5`,

]);

const LoginButton = ({ children, iconLeft, iconRight, ...props }) => {
    return (
        <StyledButton {...props}>
            <div>{children}</div>
        </StyledButton>
    );
};

LoginButton.propTypes = {
    children: PropTypes.any,
    variant: PropTypes.string,
    size: PropTypes.string,
    /** pass a component */
    iconLeft: PropTypes.element,
    /** pass a component */
    iconRight: PropTypes.element,
    disabled: PropTypes.bool
};

export default LoginButton;
