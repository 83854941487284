import React from 'react';
import tw, { styled } from 'twin.macro';
// import { useEvents } from 'context/EventProvider';
// import PusherContainer from 'lib/pusher/PusherContainer';
// import { handleNotification } from 'modules/notifications';
// import { closeMessageCenter } from 'modules/ui';
// import { initNotifications, terminateNotifications } from 'app/notifications';
import LoadingPage from 'components/LoadingPage';

// global modals
// import MessageCenter from './containers/shared/MessageCenter/MessageCenter';
// import CreateAssignmentContainer from './containers/teacher/CreateAssignment/CreateAssignmentContainer';
// import NotificationSystem from './containers/shared/NotificationCenter/NotificationSystem';
// application layout & content
import { useAccount, useUser } from 'state/User/UserState';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { useActivityCount } from '../context/LoadingContext';
import GuidanceProvider from '../state/Guidance/GuidanceProvider';
import OnboardState from '../state/OnboardState';
import { Error404Page } from '../components';
import KeepLoggedInProvider from '../context/KeepLoggedInProvider';
import DTCAppLayout from './layout/DTC/AppLayout';
import AppLayout from './layout/B2b/AppLayout';
import MainContent from './MainContent';


// global style
import './AppContainer.scss';
import 'static/fonts/monotype/monotype.css';

/*
    this is the root application style
 */
const AppContainerStyle = styled.div`
    font-family: 'Avenir', sans-serif;
    height: 100%;
    position: relative;
    @import url('https://fonts.googleapis.com/css?family=Racing+Sans+One');
`;


const AppContainer = () => {
    const { is_parent_account } = useAccount();
    if (is_parent_account) {
        console.debug('[AppContainer] render d2c app');
        return (
            <ErrorBoundary FallbackComponent={Error404Page}>
                <AppContainerStyle id='app-container' className='app-container'>
                    <GuidanceProvider>
                        <KeepLoggedInProvider>
                            <OnboardState>
                                <DTCAppLayout>
                                    <MainContent />
                                </DTCAppLayout>
                            </OnboardState>
                        </KeepLoggedInProvider>
                    </GuidanceProvider>
                </AppContainerStyle>
            </ErrorBoundary>
        );
    }

    console.debug('[AppContainer] render b2b app');
    return (
        <ErrorBoundary FallbackComponent={Error404Page}>
            <AppContainerStyle id='app-container' className='app-container'>
                <OnboardState>
                    <AppLayout>
                        <MainContent />
                    </AppLayout>
                </OnboardState>
            </AppContainerStyle>
        </ErrorBoundary>
    );
};

export default AppContainer;
