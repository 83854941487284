import { css } from 'twin.macro';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { InitialTeacherBanner, SecondTeacherBanner, ThirdTeacherBanner } from './TeacherBanners';

const StyledSlider = styled(Slider)`
    .slick-list {
        padding: 4rem 0;
    }

    .slick-prev,
    .slick-next {
        height: unset;
        width: unset;
        z-index: 10;
    }

    .slick-prev {
        left: -15px;
        display: ${(props) => props.parent && 'none !important'};
    }

    .slick-next {
        right: -15px;
        display: ${(props) => props.parent && 'none !important'};
    }
`;

const ArrowButton = styled.button`
    &:before {
        content: '';
    }

    &.slick-next, &.slick-prev {
        width: 44px;
        height: 44px;

        &:focus {
            outline: 5px auto -webkit-focus-ring-color;
        }
    }

`;


function RightArrow({ className, onClick, style, ...props }) {
    // console.log('extra arrow', props);
    return (
        <ArrowButton
            tabIndex='0'
            aria-label='More Videos Right'
            className={className}
            onClick={onClick}
            style={style}
        >
            <svg width={44} height={44} fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M42 22c0-11.046-8.954-20-20-20S2 10.954 2 22s8.954 20 20 20 20-8.954 20-20z'
                    fill='#fff'
                    stroke='#887BE3'
                    strokeWidth={4}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M20.204 29l6.853-6.852-6.853-6.853'
                    stroke='#887BE3'
                    strokeWidth={3.5}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </ArrowButton>
    );
}

function LeftArrow({ className, onClick, style }) {
    // console.log('Left Arrow', currentSlide, props);
    return (
        <ArrowButton
            tabIndex='0'
            aria-label='More Videos Left'
            className={className}
            onClick={onClick}
            style={style}
        >
            <svg width={44} height={44} fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M2 22c0 11.046 8.954 20 20 20s20-8.954 20-20S33.046 2 22 2 2 10.954 2 22z'
                    fill='#fff'
                    stroke='#887BE3'
                    strokeWidth={4}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M23.796 15l-6.853 6.852 6.853 6.853' stroke='#887BE3' strokeWidth={3.5} strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </ArrowButton>
    );
}

const WelcomeBanner = (props) => {
    const { parent } = props;
    const settings = {
        // dots: true,
        accessibility: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />
    };

    //
    return (
        <div tw='mx-auto hidden md:block'>
            <div id='home-banner-tour'>
                <StyledSlider {...settings} parent={parent}>
                    <InitialTeacherBanner onPlayTutorial={() => props.setPlayTutorialVideo(true)} parent={parent} />
                    {!parent && <SecondTeacherBanner />}
                    {!parent && <ThirdTeacherBanner />}
                </StyledSlider>
            </div>
        </div>
    );
};

WelcomeBanner.propTypes = {
    // anyProp: PropTypes.any
};

export default WelcomeBanner;
