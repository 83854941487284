import { styled } from 'twin.macro';

export const CancelButton = styled.button`
    background: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    color: #6a7277;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 2.5px rgba(0, 0, 0, 0.25));

    z-index: 1000000;
    //position: absolute;
    //right: 1.125rem;
    //top: 1.125rem;

    &:hover {
        background: #dddddd;
    }

    &:active {
        background: #bbbbbb;
        filter: none;
    }
`;
