import React from 'react';

const HeartFavoriteIcon = (props) => {
    let icon = null;

    if (props.favorite) {
        icon = (

            <svg
                className='fav'
                width='58px'
                height='58px'
                viewBox='0 0 57 53'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <title>Favorite Video</title>
                <g filter='url(#filter0_d)'>
                    <path
                        d='M42.1075 14.1237C40.2933 12.3282 37.8971 11.2403 35.3513 11.0562C32.8054 10.8721 30.2776 11.6041 28.224 13.1199C26.0592 11.5097 23.3647 10.7796 20.6832 11.0766C18.0016 11.3735 15.5322 12.6754 13.7721 14.7202C12.0121 16.765 11.0922 19.4007 11.1976 22.0966C11.3031 24.7924 12.4261 27.3482 14.3405 29.2493L24.5489 39.5428C25.506 40.4987 26.8033 41.0356 28.1559 41.0356C29.5086 41.0356 30.8059 40.4987 31.7629 39.5428L41.9714 29.2493C42.9768 28.267 43.7782 27.0956 44.3295 25.8026C44.8807 24.5096 45.1711 23.1204 45.1837 21.7148C45.1964 20.3092 44.9311 18.915 44.4032 17.6123C43.8753 16.3095 43.0951 15.124 42.1075 14.1237Z'
                        fill='white' />
                </g>
                <defs>
                    <filter
                        id='filter0_d'
                        x='0.180703'
                        y='0.00296879'
                        width='56.0122'
                        height='52.0414'
                        filterUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'>
                        <feFlood
                            floodOpacity='0'
                            result='BackgroundImageFix' />
                        <feColorMatrix
                            in='SourceAlpha'
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                        <feOffset />
                        <feGaussianBlur stdDeviation='5.50437' />
                        <feColorMatrix
                            type='matrix'
                            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.75 0' />
                        <feBlend
                            mode='normal'
                            in2='BackgroundImageFix'
                            result='effect1_dropShadow' />
                        <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_dropShadow'
                            result='shape' />
                    </filter>
                </defs>
            </svg>
        );
    } else {
        icon = (
            <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1.0314 8.2706C1.54555 14.6909 8.52937 18.7653 11.957 20C15.61 18.8336 22.3065 14.7514 22.9154 8.2706C24.0081 0.172198 14.2004 -0.929609 11.957 3.81613C9.7998 -0.929609 0.388721 0.245228 1.0314 8.2706Z' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
            </svg>

        );
    }

    return icon;
};


export default HeartFavoriteIcon;
