/**
 * created by Jason Duncan on 3/1/18.
 */
import React, { Component } from 'react';
import tw from 'twin.macro';
// import { Col, Row } from 'react-bootstrap';


export const IncorrectMessage = ({ correct_choices, question_state }) => {
    console.log('correct', correct_choices);
    console.log('selected', question_state);
    if( !question_state || !correct_choices ) return null;
    // export default class IncorrectMessage extends Component {
    //     static defaultProps = {
    //         correct_choices: [],
    //         question_state: []
    //     };
    //     constructor(props) {
    //         super(props);
    //         this.state = {};
    //     }

    // collect the correct choice bitmask from the question
    //finished = session.getIn(['quiz_state', 'quiz_finished'], false);
    //assert(question_state.length == correct.length);
    let missing_selected = false;
    let incorrect_selected = false;
    let correct_selected = false;

    for (let i = 0; i < correct_choices.length; i++) {
        let correct = correct_choices[i];
        let select = question_state[i];

        if (correct && !select) {
            missing_selected = true;
        }

        if (select && !correct) {
            incorrect_selected = true;
        }

        if (correct && select) {
            correct_selected = true;
        }
    }

    console.log('missing_selected', missing_selected);
    console.log('incorrect_selected', incorrect_selected);
    console.log('correct_selected', correct_selected);

    let text = '';
    // if (missing_selected && incorrect_selected)
    // {
    // 	text = <div>Not <u><i><strong>ALL</strong></i></u> correct answers were selected.<br/>At least one answer was incorrect.</div>;
    // }
    // else
    if (incorrect_selected && correct_selected) {
        // text = <div>Not <u>ALL</u> correct answers were selected.</div>;
        text = <div>At least one of your selections was incorrect.</div>;
    } else if (missing_selected && !incorrect_selected) {
        text = (
            <div>
                Not&nbsp;<u><i><strong>ALL</strong></i></u>&nbsp;correct answers were selected.
            </div>
        );
    } else {
        return null;
    }
    return (
        <div tw='bg-QuizBlue flex justify-end mx-5 mt-1'>
            <div tw='flex justify-center items-center bg-OverdueRed w-full p-2'>
                <svg
                    width='19' height='19' viewBox='0 0 19 19' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M11.5569 9.50011L17.8277 3.24386C18.1023 2.96925 18.2566 2.5968 18.2566 2.20844C18.2566 1.82008 18.1023 1.44763 17.8277 1.17302C17.5531 0.898415 17.1807 0.744141 16.7923 0.744141C16.404 0.744141 16.0315 0.898415 15.7569 1.17302L9.50064 7.44386L3.24439 1.17302C2.96978 0.898415 2.59733 0.744141 2.20898 0.744141C1.82062 0.744141 1.44817 0.898415 1.17356 1.17302C0.89895 1.44763 0.744676 1.82008 0.744676 2.20844C0.744676 2.5968 0.89895 2.96925 1.17356 3.24386L7.44439 9.50011L1.17356 15.7564C1.03687 15.8919 0.928381 16.0532 0.854344 16.2309C0.780306 16.4086 0.742188 16.5993 0.742188 16.7918C0.742188 16.9843 0.780306 17.1749 0.854344 17.3526C0.928381 17.5303 1.03687 17.6916 1.17356 17.8272C1.30913 17.9639 1.47042 18.0724 1.64814 18.1464C1.82585 18.2204 2.01646 18.2586 2.20898 18.2586C2.40149 18.2586 2.59211 18.2204 2.76982 18.1464C2.94753 18.0724 3.10882 17.9639 3.24439 17.8272L9.50064 11.5564L15.7569 17.8272C15.8925 17.9639 16.0538 18.0724 16.2315 18.1464C16.4092 18.2204 16.5998 18.2586 16.7923 18.2586C16.9848 18.2586 17.1754 18.2204 17.3532 18.1464C17.5309 18.0724 17.6922 17.9639 17.8277 17.8272C17.9644 17.6916 18.0729 17.5303 18.1469 17.3526C18.221 17.1749 18.2591 16.9843 18.2591 16.7918C18.2591 16.5993 18.221 16.4086 18.1469 16.2309C18.0729 16.0532 17.9644 15.8919 17.8277 15.7564L11.5569 9.50011Z'
                        fill='white'
                    />
                </svg>
                <div tw='font-extrabold text-lg text-white ml-2'>
                    {text}
                </div>
            </div>
        </div>
    );

    // return (
    //     <div className='quiz-answer-row'>
    //         <div
    //             tw='w-full w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'
    //             // xs={12}
    //             // xsOffset={0}
    //
    //             // sm={10}
    //             // smOffset={1}
    //
    //             // md={8}
    //             // mdOffset={2}
    //             style={{
    //                 // paddingRight: '76px',
    //                 borderRadius: '10px',
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 height: '60px',
    //                 lineHeight: '22px',
    //                 textAlign: 'center',
    //                 fontSize: '19px',
    //                 marginTop: '10px',
    //                 fontFamily: 'Avenir',
    //                 userSelect: 'none'
    //             }}
    //         >
    //             <div
    //                 style={{
    //                     background: 'red',
    //                     color: 'white',
    //                     width: '60px',
    //                     borderTopLeftRadius: '10px',
    //                     borderBottomLeftRadius: '10px',
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     display: 'flex'
    //                 }}
    //             >
    //                 <div
    //                     style={{
    //                         transform: 'rotate(45deg)',
    //                         fontSize: '40px'
    //                     }}
    //                 >
    //                     &#43;
    //                 </div>
    //             </div>
    //             <div
    //                 style={{
    //                     flex: 1,
    //                     background: 'black',
    //                     color: 'white',
    //                     borderTopRightRadius: '10px',
    //                     borderBottomRightRadius: '10px',
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     display: 'flex'
    //                 }}
    //             >
    //                 {text}
    //             </div>
    //         </div>
    //     </div>
    // );
};
