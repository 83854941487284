import React, { useMemo } from 'react';
import { styled } from 'twin.macro';
import { useParent, useTeacher } from 'state/User/UserState';
import { useClasses, useStudents } from 'state/Teacher/ClassState';
import { useAssignments } from 'state/Student/AssignmentsState';
import TeacherBannerBackgroundImage1 from 'static/images/banners/teacher-banner-start.jpg';
import TeacherBannerBackgroundImage2 from 'static/images/banners/teacher-banner-playlists.jpg';
import TeacherBannerBackgroundImage3 from 'static/images/banners/teacher-banner-3-step-method.jpg';
import TeacherBannerBackgroundImage4 from 'static/images/banners/teacher-banner-demo.jpg';
import TeacherBannerBackgroundImageNewVideo from 'static/images/banners/building-block.png';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import { openInNewTab } from '../../utils';
import { BannerButtons, BannerContent, BannerSlide, BannerText, BannerTitle } from './BannerSlide';

const PlayButton = styled.button`
    z-index: 8;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: linear-gradient(135deg, #e637a8 2.69%, #d940ab 2.7%, #de24b8 21.9%, #a563b9 39.6%, #1ac1dd 75%);
    border: 4px solid #ffffff;
    position: absolute;
    left: 65%;
    right: 0;
    top: 60%;
    bottom: 0;
    transform: translate(0, -50%) scale(1.25);
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    &:hover {
        background: linear-gradient(135deg, rgba(230, 55, 168, 0.67) 2.69%, rgba(26, 193, 221, 0.66) 75%);
    }

    &:active {
        background: linear-gradient(135deg, #e637a8 2.69%, #d940ab 2.7%, #de24b8 21.9%, #a563b9 39.6%, #1ac1dd 75%);
    }

    &:focus {
        box-shadow: 0 0 8px 2px #1cc8e1;
    }

    @media (max-width: 1300px) {
        transform: translate(0, -50%) scale(1);
        left: 61%;
    }
    @media (max-width: 1195px) {
        left: 58%;
    }
    @media (max-width: 1074px) {
        left: 55%;
        width: 60px;
        height: 60px;
        transform: translate(0, -50%) scale(1);
    }
    @media (max-width: 1000px) {
        left: 50%;
    }
    @media (max-width: 900px) {
        left: 44%;
    }
    @media (max-width: 800px) {
        left: 40%;
    }

    @media (max-width: 769px) {
        top: 55%;
        left: 38%;
        transform: translate(0, -50%) scale(0.9);
    }

    @media (max-width: 600px) {
        width: 70px;
        height: 70px;
        top: 50%;
    }

    .triangle {
        line-height: 1;
        width: 18px;
        height: 18px;
        border-top-right-radius: 20%;
        background-color: #fff;
        text-align: left;
        display: inline-block;
        margin: 1rem;
        transition: background-color 0.45s ease;

        &:hover {
            background-color: #ffffff;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            width: 100%;
            height: 100%;
            border-top-right-radius: 20%;
        }

        &.right {
            transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
            transform-origin: 72% 82%;
        }

        &:before {
            transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
        }

        &:after {
            transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
        }
    }
`;

const new_video_panel = true;

export const InitialTeacherBanner = ({ onPlayTutorial }) => {
    const navigate = useNavigate();
    const teacher = useTeacher();
    const parent = useParent();
    const classes = useClasses();
    const students = useStudents();
    const assignments = useAssignments();

    // state flags
    const classCreated = useMemo(() => classes?.length !== 0, [classes]);
    const studentsAdded = useMemo(() => students?.length !== 0, [students]);
    const assignmentCreated = useMemo(() => assignments?.length !== 0, [assignments]);

    // select which buttons to show
    const buttons = [];

    /**
     * show "Watch Tutorial" until they have created an assignment,
     * otherwise show "See Class Stats"
     */
    if (!assignmentCreated || parent) {
        buttons.push(
            <Button key='watch-tutorial' tw='text-lg mr-3' onClick={onPlayTutorial}>
                {parent ? 'How It Works' : 'Watch Tutorial'}
            </Button>
        );
    } else {
        buttons.push(
            <Button key='class-status' tw='text-lg mr-3' onClick={() => navigate('/classes')}>
                See Class Stats
            </Button>
        );
    }

    /**
     * show "Add A Class" until they have created a class,
     * then show "Invite Students" until they have added students,
     * otherwise show "Create Playlists"
     */

    if (!classCreated) {
        buttons.push(
            <Button key='add-class' variant='teal' tw='text-lg ml-0 lg:ml-3' onClick={() => navigate('/classes')}>
                Add A Class
            </Button>
        );
    } else if (!studentsAdded) {
        buttons.push(
            <Button key='invite-students' variant='teal' tw='text-lg ml-0 lg:ml-3' onClick={() => navigate('/classes')}>
                Invite Students
            </Button>
        );
    } else {
        buttons.push(
            <Button key='create-playlists' variant='teal' tw='text-lg mt-3 lg:mt-0 ml-0 lg:ml-3' onClick={parent ? () => navigate('/playlists') : () => navigate('/videos')}>
                Create Playlists
            </Button>
        );
    }

    // set the background image
    let background = TeacherBannerBackgroundImage1;
    if (classCreated && studentsAdded && assignmentCreated) {
        background = TeacherBannerBackgroundImage2;
    }

    if (new_video_panel) {
        background = TeacherBannerBackgroundImageNewVideo;
    }

    return (
        <BannerSlide background={background}>
            <BannerTitle>Hello {parent ? parent?.first_name : teacher?.name}</BannerTitle>
            <BannerText>Welcome to Muzology! Math is about to get really fun.</BannerText>
            <BannerButtons>{buttons}</BannerButtons>
            {new_video_panel && <PlayButton onClick={() => navigate('/video/f028e2e5-af95-4aba-99f8-971eb3129e75')}>
                <div className='triangle right'></div>
            </PlayButton>}
        </BannerSlide>
    );
};

export const SecondTeacherBanner = (props) => {
    return (
        <BannerSlide background={TeacherBannerBackgroundImage3}>
            <BannerTitle>3 Easy Ways to Use Muzology</BannerTitle>
            <BannerContent>
                <ol style={{ listStyle: 'decimal', marginLeft: '22px' }}>
                    <li>Play music videos at the beginning and end of class to review previous material and introduce new topics.</li>
                    <li>Create and assign playlists to guide students and differentiate instruction.</li>
                    <li>Make 1-2 days a week Muzology days (e.g., Muzology Mondays).</li>
                </ol>
            </BannerContent>
        </BannerSlide>
    );
};

export const ThirdTeacherBanner = (props) => {
    return (
        <BannerSlide background={TeacherBannerBackgroundImage4}>
            <BannerTitle>Hey rockstar teacher, get a FREE Muzology demo!</BannerTitle>
            <BannerText>
                In 20 minutes or less, we’ll show you around the platform, share best practices, discuss implementation tips, and answer any questions.
            </BannerText>
            <BannerButtons>
                <Button onClick={() => openInNewTab('https://meetings.hubspot.com/rachel654/20-minute-demo')} tw='text-lg'>
                    Book Demo
                </Button>
            </BannerButtons>
        </BannerSlide>
    );
};
