import React from 'react';
import 'twin.macro';

const FormError = (error, text = null) => {
    if (error) {
        let message = text;
        if (!message) {
            if(typeof error === 'object') {
                if (error.type === 'required')
                    message = 'This field is required';
                else
                    message = error.message;
            } else if( typeof error === 'string') {
                // if no error message or object was provided, use it as an error message
                message = error;
            }
        }

        return (
            <div tw='pt-1 text-left'>
                <p tw='text-red-500 text-lg font-medium font-sans'>{`* ${message}`}</p>
            </div>
        );
    }
    return null;
};

export default FormError;
