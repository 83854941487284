import React, { useState, useEffect, useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useCourseAndVideos } from 'state/CatalogState';

import AllVideosActiveSVG from '../../../../static/icons/DTC-NavBar/AllVideos/AllVideosActiveSVG';
import AllVideosSVG from '../../../../static/icons/DTC-NavBar/AllVideos/AllVideosSVG';
import ActiveGradeSVG from '../../../../static/icons/DTC-NavBar/Grade/ActiveGradeSVG';
import GradeSVG from '../../../../static/icons/DTC-NavBar/Grade/GradeSVG';
import ActiveMyPlaylistsSVG from '../../../../static/icons/DTC-NavBar/MyPlaylists/ActiveMyPlaylistsSVG';
import MyPlaylistsSVG from '../../../../static/icons/DTC-NavBar/MyPlaylists/MyPlaylistsSVG';
import ActiveTopicSVG from '../../../../static/icons/DTC-NavBar/Topics/ActiveTopicSVG';
import TopicsSVG from '../../../../static/icons/DTC-NavBar/Topics/TopicsSVG';
import PlaylistHoverBG from 'static/icons/DTC-NavBar/hoverBackgrounds/Playlist2.png';

const StyledLink = styled(Link)(({ $isTop, $isBottom, $selected }) => [
    tw`text-[#C2C3CB] active:text-[#ddd] hover:text-[#bbb]`,
    tw`cursor-pointer relative flex items-center justify-items-stretch gap-2`,
    tw`py-3`,
    $selected && tw`text-white`
]);


const LeftBlockContainer = styled.div(({ }) => [
    tw`absolute left-0 top-0 bottom-0 w-[6px]`,
    tw`flex flex-col items-center`
]);

const LeftDot = styled.div(({ $selected }) => [
    tw`w-[6px] h-[6px] flex-shrink-0 rounded-full`,
    $selected ? tw`bg-[#ffffff]` : tw`bg-[#55408A]`
]);

const LeftLine = styled.div(({ $hide }) => [
    tw`w-[2px] h-[100%] flex-shrink flex-grow bg-[#55408A]`,
    $hide && tw`invisible`
]);

const RightTitle = styled.div(({ selected }) => [
    tw`text-xs font-medium text-left hover:text-white`,
    tw`ml-[22px] h-[100%] flex-shrink flex-grow`,
    css` text-wrap: balance;`,
    selected && tw`text-[#fff]`
]);

const HoverBackground = css`
    &:hover { 
        background-image: url(${PlaylistHoverBG}); 
        background-size: 100% 100%;
    } 
    &.active {
        background-image: url(${PlaylistHoverBG}); 
        background-size: 100% 100%;
        color: white;
        font-weight: 600;
    }  
    @media (max-width: 900px) {
        &:hover, &.active { 
            background: none; 
        }
    }
`;

const LeftBlock = ({ $selected, $isTop, $isBottom }) => {
    return (
        <LeftBlockContainer>
            <LeftLine $hide={$isTop} />
            <LeftDot $selected={$selected} />
            <LeftLine $hide={$isBottom} />
        </LeftBlockContainer>
    );
};


const handleSvg = (open) => {
    return (
        <div>
            {open ?
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M12.666 10.666L8.37564 5.76273C8.17643 5.53506 7.82227 5.53506 7.62306 5.76273L3.33268 10.666'
                        stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                </svg>
                :
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M3.33398 5.33398L7.62436 10.2373C7.82357 10.4649 8.17773 10.4649 8.37694 10.2373L12.6673 5.33398'
                        stroke='#C2C3CB' strokeWidth='1.5' strokeLinecap='round' />
                </svg>
            }
        </div>
    );
};

const courseGetParentGroups = (course) => {
    return course?.groups?.filter(item => item.parent === null) ?? [];
};

// create the subgroups inside the top level groups
const SubGroups = ({ items, sectionCourseId, itemSelected, handleCloseSearch, studentId = null }) => {
    const studentIdPath = studentId ? `/${studentId}` : '';
    return items.map((item, index) => {
        const isTop = index === 0;
        const isBottom = index === items.length - 1;
        const isSelected = itemSelected === item.id;

        return (
            <StyledLink
                key={index}
                to={`playlists/${sectionCourseId}/${item.id}${studentIdPath}`}
                onClick={() => {
                    handleCloseSearch();
                }}
                $selected={isSelected}
                $isTop={isTop}
                $isBottom={isBottom}
            >
                <LeftBlock $selected={isSelected} $isTop={isTop} $isBottom={isBottom} />
                <RightTitle $selected={isSelected}>{item.title}</RightTitle>
            </StyledLink>
        );
    });
};

// handle creating the top level groups
// By Grade, By Topic, My Playlist
const handleGroups = (sections, itemSelected, selectedCourseID, handleCloseSearch, navigate, handleToggleCourse, isCourseExpanded, studentId = null) => {
    if (!sections) return null;
    // each section contains:
    //   title: the title of the section
    //   course_id: the course id of the section
    //   items: the sub groups inside the section
    //   icon: the icon for the section
    //   activeIcon: the active icon for the section

    return sections.map((section, index) => {
        const isSectionOpen = isCourseExpanded(section.course_id);
        const isSectionSelected = section.course_id === selectedCourseID;
        const studentIdPath = studentId ? `/${studentId}` : '';

        return (
            <div tw=' w-full cursor-pointer select-none' key={index} css={[!isSectionOpen && HoverBackground]}>
                <div
                    tw='flex items-center justify-between min-h-[3.5rem] px-4 w-full'
                    // onClick={() => section.noOpen ? navigate(section.route) : handleToggleCourse(section.course_id)}
                    onClick={() => {
                        if (section.route) {
                            // console.log('route', section.route, { isSectionSelected, isSectionOpen });
                            // this is a route, not a course to expand - close any expanded courses
                            handleCloseSearch();
                            navigate(`${section.route}${studentIdPath}`);
                            // close any expanded sections
                            if (selectedCourseID && isCourseExpanded(selectedCourseID)) {
                                handleToggleCourse(selectedCourseID);
                            }

                        } else {
                            // if (course_id !== selectedCourseID) {
                            //     setExpandedCourses({ [course_id]: course_id });
                            //     return;
                            // } else if (course_id === selectedCourseID) {
                            //     setExpandedCourses({});
                            //     return;
                            // }

                            // if the course is not selected, just select it but dont expand it
                            if (isSectionSelected) {
                                // section is already selected - toggle it
                                handleToggleCourse(section.course_id);
                            } else {
                                // close any expanded sections
                                if (selectedCourseID && isCourseExpanded(selectedCourseID)) {
                                    handleToggleCourse(selectedCourseID);
                                }

                                // section is not selected, route to the first item in the section
                                // but do not expand the section
                                handleCloseSearch();
                                navigate(`playlists/${section.course_id}/${section.items[0]?.id}${studentIdPath}`);
                            }
                        }

                        // if the course is not selected, navigate to the first item in the course
                        // if( !isSectionSelected ) {
                        //     console.log('Course not selected, navigate to first item in course');
                        //     navigate(`playlists/${section.course_id}/${section.items[0]?.id}${studentIdPath}`);
                        // }

                    }}

                >
                    <div
                        tw='hover:opacity-80 active:opacity-60 flex flex-row gap-1 w-full items-center'
                        data-testid={`navigation-button-${section.testId}`}
                    >
                        <div tw=''>
                            {isSectionSelected ? section.activeIcon : section.icon}
                        </div>
                        <div
                            tw='text-[#C2C3CB] text-xs font-medium w-full hidden iPadPro:block hover:text-[#fff]'
                            css={[isSectionSelected && tw`text-white font-semibold`]}
                        >
                            {section.title}
                        </div>
                        <div tw='hidden xl:block'>
                            {!section.route && handleSvg(isSectionOpen)}
                        </div>
                    </div>
                </div>

                {isSectionOpen &&
                    <div tw='-mt-3 pl-6 pr-2 hidden xl:block'>
                        <SubGroups
                            items={section.items}
                            sectionCourseId={section.course_id}
                            itemSelected={itemSelected}
                            handleCloseSearch={handleCloseSearch}
                            studentId={studentId}
                        />
                    </div>
                }
            </div>
        );
    });
};

/*
    1. moved the course selected state to a single state instead of handled by each section
    2. active state is handled by the route
 */

const PlaylistSection = ({ handleCloseSearch, studentId, timesTablesProduct }) => {
    const navigate = useNavigate();

    // get the courses TODO: remove hard-coded course ids
    const category_course = useCourseAndVideos('course-by-category');
    const category_grades = useCourseAndVideos('course-by-grade');


    // get the selected playlist & item from the route
    const parentMatch = useMatch('/playlists/:course_id/:item_id/:student_id');
    const studentMatch = useMatch('/playlists/:course_id/:item_id');
    const timesTablesMatch = useMatch('/playlists/:course_id');

    // get the selected playlist & item from the parent or student routes
    const { course_id: selectedCourseID, item_id: selectedItemID } = useMemo(() => {
        if (parentMatch)
            return parentMatch?.params;
        if (timesTablesMatch)
            return { ...timesTablesMatch?.params, student_id: null };
        else if (studentMatch)
            return { ...studentMatch?.params, student_id: null };
        else
            return { course_id: null, item_id: null, student_id: null };
    }, [parentMatch, studentMatch, timesTablesMatch]);
    // console.log('studentId', studentId);

    // handle expanded state of the dropdown links
    const [expandedCourses, setExpandedCourses] = useState({ [selectedCourseID]: selectedCourseID });
    const isExpanded = (course_id) => {
        return !!expandedCourses[course_id];
    };

    const handleToggleCourse = (course_id) => {
        console.log('toggle course', course_id);
        setExpandedCourses(values => {
            if (values[course_id]) {
                return {};
            } else {
                return { [course_id]: course_id };
            }
        });
    };

    // the side panel sections TODO: partially generated from the backend
    let sections = [
        {
            title: 'By Topic',
            testId: 'topic',
            course_id: category_course?.id,
            items: courseGetParentGroups(category_course),
            icon: <TopicsSVG />,
            activeIcon: <ActiveTopicSVG />
        },
        {
            title: 'By Grade',
            testId: 'grade',
            course_id: category_grades?.id,
            items: courseGetParentGroups(category_grades),
            icon: <GradeSVG />,
            activeIcon: <ActiveGradeSVG />
        },
        {
            title: 'Favorites',
            testId: 'favorites',
            course_id: 'favorites',
            items: [],
            icon: <MyPlaylistsSVG />,
            activeIcon: <ActiveMyPlaylistsSVG />,
            noOpen: true,
            route: 'playlists/favorites/all'
        },
        {
            title: 'All Videos',
            testId: 'all_videos',
            course_id: 'all_videos',
            items: [],
            icon: <AllVideosSVG />,
            activeIcon: <AllVideosActiveSVG />,
            noOpen: true,
            route: 'playlists/all_videos/all'
        }
    ];

    if (timesTablesProduct) {
        sections = [
            {
                title: 'Favorites',
                testId: 'favorites',
                course_id: 'favorites',
                items: [],
                icon: <MyPlaylistsSVG />,
                activeIcon: <ActiveMyPlaylistsSVG />,
                noOpen: true,
                route: 'playlists/favorites/all'
            },
            {
                title: 'Times Tables',
                testId: 'times_tables',
                course_id: '1090c873-05d0-4161-99a9-78acc86a09fd',
                items: [],
                icon: <AllVideosSVG />,
                activeIcon: <AllVideosActiveSVG />,
                noOpen: true,
                route: 'playlists/1090c873-05d0-4161-99a9-78acc86a09fd'
            },
            {
                title: 'Bonus Videos',
                testId: 'bonus_videos',
                course_id: 'c9fefed9-d66c-4b6d-afeb-0a8ca28f40ab',
                items: [],
                icon: <TopicsSVG />,
                activeIcon: <ActiveTopicSVG />,
                noOpen: true,
                route: 'playlists/c9fefed9-d66c-4b6d-afeb-0a8ca28f40ab'
            },

        ]
    }

    return <>{handleGroups(sections, selectedItemID, selectedCourseID, handleCloseSearch, navigate, handleToggleCourse, isExpanded, studentId)}</>;
};

export default PlaylistSection;