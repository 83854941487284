import React from 'react';

export default function SortArrowUp(props) {
    return (
        <svg
            width={10}
            height={7}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M4.826 0l4.18 6.75H.646L4.827 0z'
                fill='#fff'/>
        </svg>
    );
}