import React from 'react';

const TopicsSVG = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2 10C2 6.68629 4.68629 4 8 4H16C19.3137 4 22 6.68629 22 10V12.8085C22 16.1222 19.3137 18.8085 16 18.8085H14.2715C14.0926 18.8085 13.9158 18.8469 13.753 18.9211L7.30982 21.8587C7.20395 21.907 7.09534 21.7971 7.14484 21.6918L8.41626 18.9867C8.45522 18.9038 8.39473 18.8085 8.30313 18.8085H8C4.68629 18.8085 2 16.1222 2 12.8085V10Z' stroke='#E775EF' strokeWidth='1.5' />
            <path d='M11.603 7.2952C11.7339 6.9016 12.2661 6.9016 12.397 7.2952L13.0897 9.37932C13.1474 9.55295 13.3024 9.67068 13.4781 9.67442L15.5876 9.71938C15.9859 9.72787 16.1504 10.2573 15.8329 10.509L14.1516 11.842C14.0115 11.9531 13.9523 12.1436 14.0032 12.3195L14.6142 14.4314C14.7296 14.8303 14.299 15.1574 13.9719 14.9194L12.24 13.6592C12.0958 13.5542 11.9042 13.5542 11.76 13.6592L10.0281 14.9194C9.70104 15.1574 9.27042 14.8303 9.3858 14.4314L9.99678 12.3195C10.0477 12.1436 9.98849 11.9531 9.84842 11.842L8.16711 10.509C7.84959 10.2573 8.01407 9.72787 8.41245 9.71938L10.5219 9.67442C10.6976 9.67068 10.8526 9.55295 10.9103 9.37932L11.603 7.2952Z' stroke='#E775EF' strokeWidth='1.5' />
        </svg>
    );
};

export default TopicsSVG;
