/* react-hooks/exhaustive-deps: 0 */
import SearchInput from 'components/SearchInput';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import React, { useEffect, useRef, useState } from 'react';
import SortDropdown from 'components/SortDropdown';
import { handleCreateNewPlaylistModal, handlePlaylistDropdown, handlePlaylistStepsModal, resetPlaylistDropdown, updatePlaylistName, usePlaylistState } from 'state/PlaylistState';
import { Button, PlaylistAssignWizard } from 'components';
import { useForm } from 'react-hook-form';
import { showError } from 'app/AccountOnboard/Components/Components';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useParent } from 'state/User/UserState.js';
import TitleDivider from './TitleDivider/TitleDivider';
import Modal from './Modal/Modal';

/**
 ContentPage
 a content page contains a header with a title on the left and
 an optional sort dropdown or custom content on the right side.

 */

const Container = styled.div`
    position: relative;
    //margin: 1.5rem;
    //	background: red;
    margin: ${(props) => props.margin || 0};

    padding: ${(props) => props.padding || 0};
    height: ${(props) => props.height || 'unset'};

    .content-page-contents {
        display: flex;
        flex-direction: column;
        margin-top: ${(props) => props.marginTop || 0};
    }
`;
const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 1024px) {
        flex-wrap: nowrap;
    }

    @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .search-input-container {
        margin: 4px 15px 0px 15px;
        @media only screen and (max-width: 768px) {
            border: 1.5px solid #fff;
            background: #ffffff52;
            height: 38px;
            /* width: 240px; */
            border-radius: 7px;
            margin: 6px 60px 0 0;
        }
        @media only screen and (max-width: 375px) {
            border: none;
            border-radius: 0;
            background: none;
            height: 38px;
            width: 220px;
            margin: 6px 0 0 0;
        }

        img {
            height: 26px;
        }
    }
`;

const options = [
    { id: 1, name: 'A - Z', unavailable: false },
    { id: 2, name: 'Score: High to Low', unavailable: false },
    { id: 3, name: 'Watched: Recent to Old', unavailable: false },
    { id: 4, name: 'Assigned: Recent to Old', unavailable: false },
    { id: 5, name: 'Assigned: Overdue to Complete', unavailable: false }
];

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
};

const CreatePlaylistModal = ({ isOpen, setIsOpen, modalChildrenStyle, onCreatePlaylist, parent }) => {
    /*
        This opens a dialog box asking for a playlist name
     */
    const { register, handleSubmit, errors: formErrors, reset: resetPlaylistName, setFocus, formState } = useForm();
    const ref = register({ required: true });
    // const nameRef = useRef(null);
    const [nameRef, setInputFocus] = useFocus();

    return (
        <Modal
            isOpen={isOpen}
            // when a user clicks outside this modal it will fire this off
            setIsOpen={setIsOpen}
            modalChildrenStyle={modalChildrenStyle}
        >
            <div tw='mx-4'>
                <div tw='bg-white p-6 rounded-xl relative'>
                    <h3 tw='font-medium text-2xl mb-5'>{parent ? 'Create a Playlist' : 'Create New Playlist'}</h3>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center shadow-md '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                    <form onSubmit={handleSubmit(onCreatePlaylist)}>
                        <input
                            name='name'
                            ref={(e) => {
                                ref(e);
                                nameRef.current = e; // assign to ref
                            }}
                            css={[
                                tw`bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full`,
                                formErrors.name && tw`border-red-400 border focus:outline-none focus:ring-2 focus:ring-red-400 focus:border-transparent`
                            ]}
                            type='text'
                            placeholder='Enter Playlist Name'
                            autoFocus
                        />
                        {formErrors && showError(formErrors.name)}
                        <div tw='ml-auto w-max mt-6'>
                            <Button type='submit'>Next</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

const ContentPage = (props) => {
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [
        { playlist, isCreateNewPlaylistModalOpen, isPlaylistDropdownOpen, isPlaylistStepsModalOpen },
        playlistDispatch,
        savePlaylist,
        editPlaylist,
        resetPlaylist
    ] = usePlaylistState();

    const { register, handleSubmit, errors: formErrors, reset: resetPlaylistName, setFocus } = useForm();
    const parent = useParent();

    const navigate = useNavigate();
    const routeParams = useParams();

    function handleCreatePlaylist() {
        resetPlaylistDropdown(playlistDispatch);
        handleCreateNewPlaylistModal(playlistDispatch, true);
        handlePlaylistDropdown(playlistDispatch, true);
    }

    function onBeginNewPlaylist(data) {
        // reset the form for next time
        resetPlaylistName(playlist);
        // update the state to have current playlist name
        updatePlaylistName(playlistDispatch, data);
        // close the "Create New Playlist" modal after name has been entered
        handleCreateNewPlaylistModal(playlistDispatch, false);
    }

    // console.log('location', location);
    // console.log('location state', location.state);
    // useEffect(() => {
    //     // check the location state
    //     const routeState = location.state;
    //     if (routeState && routeState.action) {
    //         const { action, playlist } = routeState;
    //         if (action === 'create_playlist') {
    //             // reset the params
    //             // navigate('/videos');
    //             handleCreatePlaylist();
    //         }
    //         else if (action === 'edit_videos') {
    //             handlePlaylistDropdown(playlistDispatch, true);
    //             // editPlaylist(playlist_id);
    //         }
    //         // this is a playlist edit state
    //         console.log('EDIT PLAYLIST', routeState.playlist);
    //         // load the playlist
    //
    //         // clear the state
    //         window.history.replaceState({}, document.title);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // if the create_playlist is in the route params
    // start playlist creation
    useEffect(() => {
        const { action, playlist_id, assignment_id } = routeParams;
        // console.log('handle route params', routeParams);

        if (action === 'create_playlist') {
            // reset the params
            navigate('/videos', { replace: true });
            handleCreatePlaylist();
        } else if (action === 'edit_videos') {
            // reset the params
            // navigate.replace('/videos');
            handlePlaylistDropdown(playlistDispatch, true);
            editPlaylist(playlist_id);
        } else {
            // handlePlaylistDropdown(playlistDispatch, false);
            // resetPlaylist();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const { createAssignmentOverlay } = props;
    // const margin = props.margin || '30px';
    // const padding = props.padding || '0';
    const renderSortDropdown = () => {
        if (!props.sortItems) return null;

        return (
            <SortDropdown
                style={{ width: props.sortOrder === 'alphabetical' ? '190px' : '240px' }}
                selected={props.sortOrder}
                onSelectItem={props.onSelectSortOrder}
                items={props.sortItems}
                borderColor={props.borderColor}
                color={props.color}
                fontSize={props.fontSize}
                button_img_src={props.DropDownArrow}
                img_width='none'
                border='none'
                selectedBackgroundColor='linear-gradient(96.81deg, #1CC8E1 0.89%, #D022ED 98.12%)'
            />
        );
    };

    const renderSearchInput = () => {
        return;
    };


    return (
        <>
            <div
                tw='px-0 sm:px-4 py-0 sm:py-7 my-0 xsm:bg-none pb-4'
                css={[
                    css`
                        @media only screen and (max-width: 500px) {
                            background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);
                            margin: 17px 0;
                        }
                    `
                ]}
            >
                <div tw='[maxWidth:var(--layout-width)] mx-auto'>
                    <TitleDivider
                        teacher={props.teacher}
                        header={props.title}
                        hasBorder={false}
                        placeholder='Search Achievements'
                        search={<SearchInput inputSelected={props.onSearch} search={props.search} />}
                        selectOptions={selectedOption}
                        handleSelect={setSelectedOption}
                        options={options}
                        showButton={!isCreateNewPlaylistModalOpen && !isPlaylistDropdownOpen && props.teacher}
                        buttonAction={handleCreatePlaylist}
                    />
                </div>
            </div>

            <PlaylistAssignWizard
                playlistDispatch={playlistDispatch}
                setIsOpen={(open) => handlePlaylistStepsModal(playlistDispatch, open)}
                isOpen={isPlaylistStepsModalOpen}
                playlist={playlist}
                parent={parent}
            />

            <CreatePlaylistModal
                isOpen={isCreateNewPlaylistModalOpen}
                // when a user clicks outside of this modal it will fire this off
                setIsOpen={(open) => {
                    if (!open) {
                        // sets "Create New Playlist" modal to close
                        handleCreateNewPlaylistModal(playlistDispatch, false);
                        // closes the playlist wizard dropdown thing
                        handlePlaylistDropdown(playlistDispatch, false);
                        // reset anything in the playlist state
                        resetPlaylistDropdown(playlistDispatch);
                    }
                }}
                modalChildrenStyle={tw`w-full maxWidth[650px] mx-auto`}
                onCreatePlaylist={onBeginNewPlaylist}
                parent={parent}
            />

            <div tw='bg-white w-full px-4 pb-2'>
                <div tw='[maxWidth:var(--layout-width)] mx-auto '>
                    <div tw='pt-8'>{props.children}</div>
                </div>
            </div>
        </>
    );
};

export default ContentPage;
