import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import BalanceText from 'react-balance-text';
import tw, { styled, css } from 'twin.macro';
import { Button, Modal, VideoPlayerProgress } from 'components';
import { useAssignments } from 'state/Student/AssignmentsState';
import moment from 'moment';
import { useMedia } from 'react-use';
import { wrapTitleParens } from 'lib/string';
import { useVideoWithStats } from '../../state/Student/VideoStats';
import { ordinalNum } from '../../utils/pluralize';
import { challenge_titles } from '../../app/constants/constants';
import QuizIntroAttempts from './QuizIntroAttempts';
import GradientBorder from './GradientBorder';
import ProgressDots from './ProgressDots';
import SilverRecord from './record_images/CD_SILVER.png';
import GoldRecord from './record_images/CD_GOLD.png';
import PlatinumRecord from './record_images/CD_PLATINUM.png';
import WarmUp from './record_images/Warm_Up.png';
import SilverRecordSmall from './record_images/CD_SILVER_SMALL.png';
import GoldRecordSmall from './record_images/CD_GOLD_SMALL.png';
import PlatinumRecordSmall from './record_images/CD_PLATINUM_SMALL.png';

const LevelText = styled.h1(({ level }) => [
    tw`flex justify-center uppercase font-black text-center text-2xl xsm:text-3xl md:text-[40px] font-Poppins`,
    css`text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);`,
    level === 1 && tw`text-[#C6C6C6]`,
    level === 2 && tw`text-[#FFC96E]`,
    level === 3 && css`
    background: linear-gradient(90deg, #867CC0 2.88%, #C6EDF2 54.9%, #82F4A2 98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
]);

const rotateCss = css`
    -webkit-animation:spin 30s linear infinite;
    -moz-animation:spin 30s linear infinite;
    animation:spin 30s linear infinite;
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
`;



const QuizIntro = ({ teacher, videoId, level, currentScore, previousScores, onStart, videoTitle, progress, type }) => {

    const [isOpen, setIsOpen] = useState(false);
    const buttonLevels = [
        'silver-dtc',
        'silver-dtc',
        'gold-dtc',
        'platinum-dtc'
    ];
    const recordImages = [WarmUp, SilverRecord, GoldRecord, PlatinumRecord];
    const recordImagesSmall = [, SilverRecordSmall, GoldRecordSmall, PlatinumRecordSmall];
    const buttonHover = ['silver', ' #C2D7D7', '#D5A04D', '#5EC4DE'];

    // get assignment list
    const assignments = useAssignments();

    // get video with stats
    const videoWithStats = useVideoWithStats(videoId);
    const isReviewQuiz = videoWithStats.stats?.current_level > level;


    // sort assignment by most recent
    const videoAssignmentByDate = useMemo(() => {
        // filter assignments related to the video
        const videoAssignment = assignments.filter(assignment => assignment.items.some(item => item === videoId));
        return videoAssignment.sort((a, b) => moment(b.created).diff(moment(a.created)));
    }, [assignments, videoId]);

    let excludeZeroFromCurrentScore = currentScore > 0 && currentScore;

    const isSmall = useMedia('(min-width: 769px)');
    // const isTablet = useMedia('(min-width: 768px)');
    // const isLarge = useMedia('(min-width: 1024px)');
    // const isXL = useMedia('(min-width: 1280px)');
    // const is2XL = useMedia('(min-width: 1536px)');
    const miniQuiz = type === 'mini';

    const progressDots = useMemo(() => {
        if (miniQuiz) {
            return (
                <ProgressDots tw='sm:mt-16 quiz_intro_md:mt-14 mb-[60px] sm:mb-16 quiz_intro_md:mb-12' progress={progress.slice(0, level === 0 ? 3 : 5)} />
            );
        } else {
            if (isSmall) {
                return (
                    <ProgressDots tw='mt-12 mb-16 quiz_intro_md:mb-12' progress={progress} />
                );
            } else {
                return (
                    <>
                        <ProgressDots tw='mt-10 sm:mt-14 mb-3.5' progress={progress.slice(0, 5)} />
                        <ProgressDots tw='mt-6 mb-10 sm:mb-20' progress={progress.slice(5)} startIndex={5} />
                    </>
                );
            }
        }
    }, [isSmall, level, miniQuiz, progress]);

    return (
        <div 
            tw='absolute inset-0 top-0 sm:top-12 m-auto max-w-[100%] xsm:max-w-[658px] quiz_intro_md:max-w-[760px] lg:max-w-[810px] max-h-[100%] xsm:max-h-[630px] quiz_intro_md:max-h-[447px]' 
            data-testid='quiz-intro'
            css={[previousScores.length > 0 && tw` max-h-[100%] xsm:max-h-[630px] quiz_intro_md:max-h-[467px]`]}
        >
            {level === 0 ?
                <div tw='hidden xsm:block absolute z-10 right-[-42%] sm:right-0 bottom-[58%] sm:bottom-[65%] quiz_intro_md:bottom-[40%] '>
                    <img src={recordImages[level]} tw='h-auto sm:h-full sm:max-h-[100%] w-[521px] sm:w-full max-w-none sm:max-w-[100%]' />
                </div>
                :
                <div tw='hidden xsm:block absolute z-10 right-[-62%] sm:right-0 bottom-[25%] sm:bottom-[48%] quiz_intro_md:bottom-[19%] lg:bottom-[18%] '>
                    <img src={recordImages[level]} tw='h-auto sm:h-full sm:max-h-[100%] w-[651px] sm:w-full max-w-none  sm:max-w-[100%]' css={[rotateCss]} />
                </div>
            }
            <div tw='block xsm:hidden top-0 left-0 z-10'>
                <img tw='w-[80%] h-auto' src={recordImagesSmall[level]} />
            </div>
            <GradientBorder key={level} level={level} >
                <div tw='h-full relative z-50 pt-12 font-Poppins' data-testid='quiz-intro-contents'>
                    <LevelText level={level}>
                        {challenge_titles[level]}
                    </LevelText>
                    <div tw='flex font-Poppins justify-center font-bold text-center text-base sm:text-xl text-white pt-4 mx-6'>{videoTitle}</div>
                    {progressDots}
                    {level === 0 ?
                        <div tw='flex items-center justify-center font-Poppins font-normal text-base text-center sm:text-[22px] text-white -mt-5 sm:-mt-1 mb-4'>
                            <div>
                                See what you already know!<br />
                                <div tw='pt-1'>
                                    <BalanceText>
                                        Answer Warm Up questions to unlock the music video.
                                    </BalanceText>
                                </div>
                            </div>

                        </div>
                        :
                        <div tw='flex items-center justify-center font-Poppins font-semibold quiz_intro_md:font-normal text-lg sm:text-[22px] text-white' css={[previousScores.length > 0 && tw`-mt-4`]}>
                            {isReviewQuiz ? 'REVIEW' : `${ordinalNum(previousScores.length + 1)} Attempt`}
                        </div>
                    }
                    <div tw='flex justify-center mt-6 sm:mt-12 quiz_intro_md:mt-8'>
                        <Button
                            id='start-quiz-button'
                            tabIndex={1}
                            onClick={onStart}
                            variant={buttonLevels[level]}
                            tw='min-w-[150px] sm:min-w-[160px]'
                            css={[css`  &:hover {background: ${buttonHover[level]}}`]}
                        >
                            <div tw='font-Poppins font-medium text-base'>Start</div>
                        </Button>
                    </div>
                    <div tw='flex justify-center pt-12'>
                        <div tw='text-white text-base font-normal quiz_intro_md:font-bold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                            {previousScores.length > 0 && 'See Attempts'}
                        </div>
                    </div>
                </div>
            </GradientBorder>
            <div tw='block xsm:hidden absolute w-full z-50 top-[11rem] left-1/2 -translate-x-1/2 font-Poppins' data-testid='quiz-intro-contents'>
                <LevelText level={level}>
                    {challenge_titles[level]}
                </LevelText>
                <div tw='flex font-Poppins justify-center font-semibold text-center text-lg xsm:text-xl text-white pt-4 mx-2'>
                    <BalanceText>
                        {wrapTitleParens(videoTitle)}
                    </BalanceText>
                </div>
                {progressDots}
                {level === 0 ?
                    <div tw='flex items-center justify-center font-Poppins font-normal text-base text-center sm:text-[22px] text-white -mt-5 sm:mt-4 mb-4'>
                        <div>
                            See what you already know!<br />
                            <div tw='pt-1 mx-2'>
                                <BalanceText>
                                    Answer Warm Up questions to unlock the music video.
                                </BalanceText>
                            </div>
                        </div>

                    </div>
                    :
                    <div tw='flex items-center justify-center font-Poppins font-semibold quiz_intro_md:font-normal text-lg sm:text-[22px] text-white' css={[previousScores.length > 0 && tw`-mt-4`]}>
                        {isReviewQuiz ? 'REVIEW' : `${ordinalNum(previousScores.length + 1)} Attempt`}
                    </div>
                }
                <div tw='flex justify-center mt-6 sm:mt-12 quiz_intro_md:mt-8'>
                    <Button
                        id='start-quiz-button'
                        tabIndex={1}
                        onClick={onStart}
                        variant={buttonLevels[level]}
                        tw='min-w-[150px] sm:min-w-[160px]'
                        css={[css`  &:hover {background: ${buttonHover[level]}}`]}
                    >
                        <div tw='font-Poppins font-medium text-base'>START</div>
                    </Button>
                </div>
                <div tw='flex justify-center pt-5 pb-3.5'>
                    <div tw='text-white text-lg font-semibold quiz_intro_md:font-bold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                        {previousScores.length > 0 && 'See Attempts'}
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full max-w-[340px] sm:max-w-[630px] max-h-[330px] m-auto`} modalChildrenStyleOpacity={tw`opacity-50 blur-3xl`}>
                <QuizIntroAttempts videoTitle={videoTitle} level={level} previousScores={previousScores} setIsOpen={setIsOpen} />
            </Modal>
        </div>
    );
};

QuizIntro.propTypes = {
    previousScores: PropTypes.array,
    currentScore: PropTypes.number,
    level: PropTypes.number
};

export default QuizIntro;
