import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { useQuizSession } from 'hooks/quiz';
import { level_styles } from 'app/constants/constants';
import { PROGRESS_ITEM_CORRECT, PROGRESS_ITEM_INCORRECT, PROGRESS_ITEM_NONE } from 'modules/quiz/constants';
import { QuizIntroMessage } from '../QuizConstants';
// the quiz session page & panel work together to create a consistent style for every quiz page
import { QuizSessionPage } from '../QuizSessionPage';
import QuizPanel from '../components/QuizPanel';
import { useQuizSessionActions } from '../../../../../state/Quizzing/QuizSessionProvider';
import QuizIntro from '../../../../../components/QuizIntro/QuizIntro';

const QuizIntroContents = styled.div`
    //height: 460px;
    //padding: 80px 20px;
    //padding: 30px 55px 14px;

    //background: linear-gradient(127.46deg, #402898, #4b40c2);
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;

    // Style for "Answer the"

    font-family: Avenir, serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;

    // Text style for "5 question"
    .bold {
        font-family: Avenir, serif;
        font-weight: 800;
    }

    /*
    @media only screen and (min-width: 1200px) {
        // 1200 and over
        font-size: 30px;
        //padding: 80px 20px; // 80 tb, 20 lr
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        // 992 - 1199
        font-size: 30px;
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
        font-size: 26px;
        padding: 40px 10px; // 80 tb, 20 lr
        height: unset;
    }
    @media only screen and (max-width: 767px) {
        // 767 and lower
        font-size: 20px;
        padding: 10px;
        height: unset;
    }
    */
`;

const QuizIntroContainer = ({
    video,
    quizSession,
    level,
    colors,
    setOpen,
    hideProgressHeader,
    teacher,
    stats
}) => {
    const { quiz } = useQuizSession();
    const { beginQuiz } = useQuizSessionActions();
    // const {
    //     video,
    //     quizSession,
    //     level,
    //     colors,
    //     setOpen,
    //     hideProgressHeader,
    //     teacher,
    //     stats
    // } = props;

    // if (!quiz)
    //     return <h1>QUIZ INTRO NO QUIZ</h1>;

    // get the title
    // let quiz_title = quiz?.title;

    let numberAttempts = 1;
    // let numberCorrect = 0;
    // let numberIncorrect = 0;

    // get stats from the quiz instance
    if (quizSession && quizSession.instance && quizSession.instance.instance) {
        // numberCorrect = parseInt(quizSession.instance.instance.number_correct);
        // numberIncorrect = parseInt(quizSession.instance.instance.number_incorrect);
        numberAttempts = parseInt(quizSession.instance.instance.attempts);
        // increment if this is a new attempt
        if (quizSession.instance.no_results) numberAttempts++;
    }

    let messageText = QuizIntroMessage[level];

    // TODO: assignment goal description
    // if (assignment) {
    //     messageText = 'Get X% or more to reach assignment goal.';
    // }

    // if (level === 0) {
    //     // warmup
    //     // record = <img src={barbells} alt={'barbells'}/>;
    //     messageText = "See what you already know!</br>Answer these 5 warm-up questions and unlock the music video.";
    // } else {
    //     // level 1 - 3 intro message
    //     // record = <RecordImg level={level} style={{ width: '100%' }} />;
    //     if (assignment) {
    //         // TODO: assignment goal description
    //         messageText = 'Get X% or more to reach assignment goal.';
    //     } else {
    //         if (level === 3) {
    //             messageText = "Get <strong>100%</strong> to go <strong>Platinum</strong>";
    //         } else {
    //             messageText = `Get <strong>100%</strong> to earn a <strong>${record_colors[level]}</strong> Record`;
    //         }
    //         // attempt = <div className={'bold'}>Attempt: {numberAttempts}</div>;
    //     }
    // }

    // console.log('Attempts: ', numberAttempts);

    // let imageOverlay = null;
    // if (level !== 0) {
    //     imageOverlay = (
    //         <div
    //             style={{
    //                 position: 'absolute',
    //                 left: 0,
    //                 right: 0,
    //                 top: 0,
    //                 bottom: 0,
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center'
    //             }}
    //         >
    //             <div style={{ color: 'white', fontSize: 30 }}>
    //                 {numberCorrect} / {numberCorrect + numberIncorrect}
    //             </div>
    //         </div>
    //     );
    // }
    //

    // default the progress state to the number of questions for this quiz type
    const miniQuiz = quiz?.quiz_type === 'mini';
    const questionCount = miniQuiz ? 5 : (level === 0 ? 5 : 10);
    let progress = Array(questionCount).fill(PROGRESS_ITEM_NONE);

    console.log('QuizIntroContainer', video, questionCount, progress);

    // get the progress from the current state
    if (quizSession?.instance) {
        console.log('.    instance', quizSession.instance);

        // get the progress from the current instance
        let results = quizSession.instance;
        if (results?.instance?.questions) {
            console.log('.    questions', quizSession.instance.instance.questions);

            // TODO either mark the quiz instance as complete,
            // or get a new instance when the quiz instance is searched for,
            // or dont return anything if the quiz is complete

            // if all questions are correct, this is a completed instance
            if (results?.instance?.questions?.length === results?.instance?.number_correct) {
                // all progress items should be empty, since we will be getting a new instance when we begin a quiz
                progress = results.instance.questions.map((question) => {
                    return PROGRESS_ITEM_NONE;
                });
            } else {
                // get the progress from the instance questions
                progress = results.instance.questions.map((question) => {
                    if (!question?.answered)
                        return PROGRESS_ITEM_NONE;
                    return question?.correct ? PROGRESS_ITEM_CORRECT : PROGRESS_ITEM_INCORRECT;
                });
            }
        }

        // console.log('PROGRESS:', progress);
        // update the progress from the current session
        if (results.responses) {
            progress = results.responses.reduce((prog, response, index) => {
                // replace missing & correct responses
                if (prog[response.index] === PROGRESS_ITEM_NONE) prog[response.index] = response?.correct ? PROGRESS_ITEM_CORRECT : PROGRESS_ITEM_INCORRECT;
                else if (prog[response.index] === PROGRESS_ITEM_INCORRECT && response?.correct) prog[response.index] = PROGRESS_ITEM_CORRECT;
                return prog;
            }, progress);
            // console.log('INSTANCE PROGRESS:', progress);
        }
    }
    console.log('PROGRESS:', progress);
    // get the current score & previous attempts from the stats
    const getLevelStats = (stats, level) => stats?.quiz_levels?.[level] ?? null;

    const getPreviousAttempts = (stats, level) => {
        const levelStats = getLevelStats(stats, level);
        const instances = levelStats?.instances ?? [];
        // filter out the 'extra challenges' from previous attempts
        return instances.filter(({ extra_challenge }) => !extra_challenge);
    };

    const getPreviousAttemptScores = (stats, level) => {
        return getPreviousAttempts(stats, level).map(({ score }) => score);
    };

    // let prev_attempts = [];
    // let current_score = 0;
    // if (stats?.quiz_levels?.[level]) {
    //     // console.log('STATS:',stats, level);
    //     current_score = stats.quiz_levels[level].score;
    //     if (stats.quiz_levels[level].instances) {
    //         const instance = stats.quiz_levels[level].instances;
    //         prev_attempts = instance.filter(({ extra_challenge }) => !extra_challenge).map(({ score }) => score);
    //     }
    // }
    const prevAttempts = getPreviousAttempts(stats, level);
    const prevScores = getPreviousAttemptScores(stats, level);
    const levelStats = getLevelStats(stats, level);
    const currentScore = levelStats?.score ?? 0;

    // prev_attempts = prev_attempts.filter((attempt) => !attempt.extra_challenge);

    return (
        <QuizSessionPage className={level_styles[level]}>
            <QuizPanel
                className={`quiz-intro-panel ${level_styles[level]}`}
                title={video?.title}
                level={level}
                colors={colors}
                progress={progress}
                // onCancel={onCancel}
                setOpen={setOpen}
                hideProgressHeader={hideProgressHeader}
            >
                <QuizIntroContents tw='flex-grow'>
                    <QuizIntro
                        teacher={teacher}
                        videoId={video?.id}
                        level={level}
                        currentScore={currentScore}
                        previousScores={prevScores}
                        onStart={() => beginQuiz(level)}
                        videoTitle={video?.title}
                        progress={progress}
                        type={quiz?.quiz_type}
                    />
                </QuizIntroContents>
            </QuizPanel>
        </QuizSessionPage>
    );
};

export default QuizIntroContainer;
