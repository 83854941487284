import React, { useMemo, useState } from 'react';
import tw, { styled } from 'twin.macro';
import ReactMarkdown from 'react-markdown';
// import Doodles1 from 'static/images/doodles1.png';
// import Doodles2 from 'static/images/doodles2.png';

const LyricsContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 40px 0;
    @media only screen and (max-width: 768px) {
        padding: 30px 0;
    }
    .show-lyrics-button-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media screen and (max-width: 500px) {
            padding-left: 20px;
        }

        .show-lyrics-button,
        .print-lyrics-button {
            background: none;
            font-family: 'Avenir', serif;
            font-weight: 800;
            font-size: 18px;
            line-height: 23px;
            text-decoration: underline;
            text-align: left;
            color: #fff;
            border: none;
            margin-right: 50px;
        }
    }

    .lyrics-container {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        border: solid 1px #b9b9c2;
        border-radius: 13px;
        display: none;
        margin: 23px 0 0;
        padding: 20px 0;

        &.show-lyrics {
            display: block;
        }

        .lyrics-wrapper {
            position: relative;
            padding-left: 23px;
            padding-right: 23px;

            .lyrics-main {
                position: relative;
                width: 100%;
                height: 590px;
                overflow-y: scroll;
                z-index: 20;
            }

            .lyrics {
                font-family: Avenir, serif;
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                color: #1a1717;
                //width: 40%;

                p {
                    margin-bottom: 12px;
                }

                @media only screen and (max-width: 768px) {
                    font-size: 18px;
                    width: 85%;
                }
            }

            .copyright {
                color: #1a1717;
                font-family: Avenir, serif;
                font-weight: 500;
                font-size: 20px;
                margin: 40px 23px 10px 0;
                text-align: center;

                @media only screen and (max-width: 768px) {
                    font-size: 15px;
                }
            }

            .drawings {
                width: 55%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                height: calc(100% - 70px);
                padding: 30px 0;
                z-index: 10;
            }
        }
    }
`;

const VideoLyrics = ({ hidePrint, lyrics, lyricsFile, copyrightYear }) => {
    const [showLyrics, setShowLyrics] = useState(false);
    const toggleShowLyrics = () => {
        setShowLyrics(!showLyrics);
    };

    const printLyrics = (lyricsFile) => {
        console.log('print lyrics', lyricsFile);
        if (lyricsFile) {
            window.open(`https://public.muzology.com/lyrics/${lyricsFile}`);
        }
    };

    const lyricsNode = useMemo(() => {
        if (!showLyrics) {
            return null;
        }

        // FIXME: this is a hack for markdown
        // const newLyrics = props.lyrics?.replaceAll('\r\n', '\r\n\r\n') ?? '';
        const newLyrics = lyrics ?? '';

        const copyright = `Copyright © ${copyrightYear ?? '2017'} Muzology, LLC. All rights reserved.`;
        return (
            <div className='lyrics-wrapper'>
                <div className='lyrics-main'>
                    <div className='lyrics' tw='whitespace-pre-wrap'>
                        {newLyrics}
                        {/*<ReactMarkdown source={newLyrics} />*/}
                    </div>
                    <div className='copyright'>{copyright}</div>
                </div>
            </div>
        );
    }, [showLyrics, lyrics, copyrightYear]);

    return (
        <LyricsContainer>
            <div className='show-lyrics-button-container'>
                <button tabIndex='0' onClick={toggleShowLyrics} className='show-lyrics-button'>
                    {showLyrics === true ? 'HIDE LYRICS' : 'SHOW LYRICS'}
                </button>
                {!hidePrint && (
                    <button tabIndex='0' className='print-lyrics-button' onClick={() => printLyrics(lyricsFile)}>
                        PRINT LYRICS
                    </button>
                )}
            </div>
            <div className={`lyrics-container ${showLyrics ? 'show-lyrics' : ''}`}>
                {lyricsNode}
            </div>
        </LyricsContainer>
    );
};
export default VideoLyrics;
