import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

import { useVideos } from 'state/CatalogState';
import { useUser } from 'state/User/UserState.js';
import { Button, ChildLearningCategories, Modal } from 'components';
import CloseButton from '../../../../../components/AnswerKey/CloseButton';
import { useNotifications } from '../../../../../context/NotificationProvider';
import CurrentAssignment from './CurrentAssignment';
//<editor-fold desc="Style">
const Container = styled.div`
    background: #2d0569;
    height: 0;
    width: 100%;
    padding-top: 40%; // wide aspect ratio per design
    background-size: cover;
    position: relative;
    @media only screen and (max-width: 640px) {
        min-height: 875px;
    }
`;

const ContentContainer = styled.div`
    // absolutely place this inside the parent
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
`;
//</editor-fold>

const HeaderContainer = ({ assignment, children }) => {
    const navigate = useNavigate();
    const videos = useVideos();
    const { notifications } = useNotifications();
    const user = useUser();
    const isChild = user.is_parent_account;
    const [studentLearningModal, setStudentLearningModal] = useState(false);

    // find out if they have an assignment and are a first time user
    // let firstTimeUser = null;
    let firstTimeNotificationId = null;
    notifications.forEach((n) => {
        if (n.type === 'muzology.welcome.user') {
            // firstTimeUser = true;
            firstTimeNotificationId = n.id;
        }
    });
    const handlePlayVideo = (video_id) => {
        navigate(`/video/${video_id}`);
    };

    if (!videos) return null;
    return (
        <Container>
            <ContentContainer>
                <CurrentAssignment assignment={assignment} isChild={isChild}  setStudentLearningModal={setStudentLearningModal}/>
                {children}
            </ContentContainer>
            <Modal
                isOpen={studentLearningModal}
                setIsOpen={(value) => {
                    setStudentLearningModal(value);
                }}
                modalChildrenStyle={tw`maxWidth[740px] mx-auto`}
            >
                <div tw='relative rounded bg-white'>
                    <ChildLearningCategories handleModal={setStudentLearningModal} user={user}/>
                    <CloseButton
                        tw='absolute -top-4 -right-4'
                        onClick={() => setStudentLearningModal(false)}
                    />
                </div>
            </Modal>
        </Container>
    );
};

export default HeaderContainer;
