import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { styled } from 'twin.macro';
import ReactJson from 'react-json-view';
import { useKey } from 'react-use';
import { isProduction } from '../../config';

const S = {
    Container: styled.div`
        user-select: none;
        position: fixed;
        //left: 0;
        //right: 0;
        top: 0;
        bottom: 0;
        width: 50vw;
        z-index: 1000000000;
        background: white;
        transition: right 0.2s;
        right: ${props => props.open ? '0' : '-50vw'};
        overflow-y: auto;
    `
};

const DevToolDataViewer = ({ data, open }) => {
    return ReactDOM.createPortal(
        <S.Container open={open} aria-hidden='true'>
            <ReactJson
                name='State'
                src={data}
                collapsed={1}
                sortKeys={true}
            />
        </S.Container>,
        document.getElementById('dev-tools-root') // Make sure this element exists in your HTML.
    );

    // return (
    //     <S.Container open={open} aria-hidden='true'>
    //         <ReactJson
    //             name='State'
    //             src={data}
    //             collapsed={1}
    //             sortKeys={true}
    //         />
    //     </S.Container>
    // );
};

const DevTool = ({ data, open, children }) => {
    return (
        <>
            {open && <DevToolDataViewer data={data} name='State' />}
            {children}
        </>
    );
};

const DevToolContext = React.createContext();

const getOperatingSystem = () => {
    if (navigator.userAgentData) {
        return navigator.userAgentData.platform;
    }
    // userAgentData is not available, fall back to navigator.platform
    return navigator.platform;
};

export const DevToolProvider = (props) => {
    const [states, setStates] = useState({});
    const [open, setOpen] = useState(false);

    const userOperatingSystem = navigator.platform;
    const isWindows = userOperatingSystem.indexOf('Win') !== -1;
    const isMacOS = userOperatingSystem.indexOf('Mac') !== -1;

    useEffect(() => {
        function ensureDevToolsRootExists() {
            // Check if the element already exists
            if (!document.getElementById('dev-tools-root')) {
                // Create a new div element
                const devToolsRoot = document.createElement('div');
                devToolsRoot.setAttribute('id', 'dev-tools-root');
                // Append it to the body (or any other high-level element you wish)
                document.body.appendChild(devToolsRoot);
            }
        }
        ensureDevToolsRootExists();
    }, []);

    // Shift+S toggles state viewer
    const predicate = (event) => {
        if (event.key === 's') {
            if (isWindows) {
                return event.altKey;
            } else if (isMacOS) {
                return event.ctrlKey;
            }
        }
        return false;
    };
    useKey(predicate, () => setOpen((state) => !state), { event: 'keydown' });

    return (
        <DevToolContext.Provider value={setStates}>
            {open && <DevToolDataViewer
                open={open}
                data={states}
            />}
            {props.children}
        </DevToolContext.Provider>
    );
};

export const ConditionalDevToolProvider = (props) => {
    if (isProduction && !props.enable) {
        return props.children;
    }
    return (
        <DevToolProvider>
            {props.children}
        </DevToolProvider>
    );
};

export function useDevTool(name, data) {
    const setStates = useContext(DevToolContext);
    useEffect(() => {
        if (setStates) {
            setStates((state) => ({ ...state, [name]: data }));
        }
    }, [name, data, setStates]);
    return () => {
        if (setStates)
            setStates((state) => state.filter(key => key !== name));
    };
}

// export function ensureDevToolsRootExists() {
//     // Check if the element already exists
//     if (!document.getElementById('dev-tools-root')) {
//         // Create a new div element
//         const devToolsRoot = document.createElement('div');
//         devToolsRoot.setAttribute('id', 'dev-tools-root');
//         // Append it to the body (or any other high-level element you wish)
//         document.body.appendChild(devToolsRoot);
//     }
// }

export default DevTool;
