import theme from 'styled-theming';
import { styled } from 'twin.macro';

const backgroundColor = theme.variants('mode', 'kind', {
    default: { light: '#123456', dark: '#123456' },
    primary: { light: '#123456', dark: '#123456' },
    success: { light: '#123456', dark: '#123456' },
    danger: { light: '#123456', dark: '#123456' },
    warning: { light: '#123456', dark: '#123456' }
});

const Button = styled.button`
	background-color: ${backgroundColor};
`;

Button.defaultProps = {
    kind: 'default'
};

export default Button;
