const NavItems = {
    teacher: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/classes',
            title: 'Classes'
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/assignments',
            title: 'Playlists'
        },
        {
            path: '/awards',
            title: 'My Awards'
        }
        // {
        //     path: MUZOLOGY_ACE_URL,
        //     title: 'Go To ACE',
        //     redirect: true,
        //     feature: 'ACE'
        // }
    ],
    teacherPhone: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/awards',
            title: 'My Awards'
        }
    ],
    parent: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/playlists',
            title: 'Playlists'
        }
    ],
    parentPhone: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/playlists',
            title: 'Playlists'
        }
    ],
    student: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/assignments',
            title: 'Assignments'
        },
        {
            path: '/stats',
            title: 'My Stats'
        }
        // {
        //     path: MUZOLOGY_ACE_URL,
        //     title: 'Go To ACE',
        //     redirect: true,
        //     feature: 'ACE'
        // }
    ],
    child: [
        {
            path: '/',
            title: 'Home',
            exact: true
        },
        {
            path: '/videos',
            title: 'Videos'
        },
        {
            path: '/playlists',
            title: 'Playlists'
        },
        {
            path: '/stats',
            title: 'My Stats'
        }
    ]
};

export default NavItems;
