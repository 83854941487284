import React from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';

import QuestionContents from '../../app/containers/shared/QuizSessionContainer/QuestionPage/QuestionContents';
import ChoicesGrid from '../../app/containers/shared/QuizSessionContainer/QuestionPage/ChoicesGrid.js';
import Input from '../../app/containers/shared/QuizSessionContainer/FreeEntry/Input';
import { QUESTION_TYPE_FREE_ENTRY } from '../../modules/quiz/constants';
import {
    QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS,
    QUESTION_TYPE_FILL_IN_THE_BLANK
} from '../../app/containers/shared/QuizSessionContainer/QuestionTypes/constants';

const ContentsContainer = styled.div`
  .question-text {
    //text-align: left;
    //font-family: KaTeX_Main, serif;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    white-space: pre-line;
    //background-color: red;
  }
`;

const formatChoiceText = (text) => {
    if (text.startsWith('[')) {
        const choices = JSON.parse(text);
        // combine choices into a single string, using commas to separate them. The last choice should have an 'or' before it.
        const lastChoice = choices.pop();
        choices.push(`or ${lastChoice}`);
        return choices.join(', ');
    } else {
        return text;
    }
};

const QuizAnswer = ({ question, parent }) => {
    const renderFreeEntryInput = ({ showAllCorrectAnswers = false, showAllAnswers = false }) => {
        let answers = [];

        // filter out any choices that contain math
        answers = question.choices.filter(choice => !choice.choice_text.includes('<math>'));

        if (showAllCorrectAnswers) {
            // show all the correct answers
            answers = answers.filter(choice => choice.correct);
        } else if (showAllAnswers) {
            // show all the answers
            // answers = question.choices;
        } else {
            // show the correct answer
            const correctAnswer = question.choices.find((choice) => choice.correct === true);
            if (correctAnswer) {
                answers = [correctAnswer];
            }
        }

        // return question.choices.filter(choice=>choice.correct).map((choice) => {
        return answers.map((choice) => {
            const text = formatChoiceText(choice.choice_text);
            return (
                <div key={question.id} className='free-entry-contents'>
                    <Input disabled={true} text={text} />
                </div>
            );
        });

        // return (
        //     <div
        //         className='free-entry-contents'
        //         // tw='minHeight[400px]'
        //     >
        //         <Input ref={inputElement} key={question.id} disabled={disabled} text={correctAnswer.choice_text} />
        //     </div>
        // );
    };

    const renderInputPanel = () => {
        if (question.type === QUESTION_TYPE_FREE_ENTRY) {
            const showAllAnswers = question.attributes?.answer_key_show_all_answers ?? false;
            return renderFreeEntryInput({ showAllAnswers: true, showAllCorrectAnswers: false });
        } else if (question.type === QUESTION_TYPE_FILL_IN_THE_BLANK || question.type === QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS) {
            return renderFreeEntryInput({ showAllAnswers: true });
        } else {
            return <ChoicesGrid question={question} answerKey={parent ? false : true} />;
        }
    };

    let instructions = null;

    // add some instructional text for free entry questions
    if (question.type === QUESTION_TYPE_FREE_ENTRY) {
        // instructions = 'Any of the items below are correct:';
    }

    if (question.type === QUESTION_TYPE_FILL_IN_THE_BLANK || question.type === QUESTION_TYPE_FILL_IN_MULTIPLE_BLANKS) {
        instructions = 'Two of the items below must be selected:';
    }

    return (
        <div tw='bg-QuizPurple flex flex-col px-10 rounded-2xl mt-5 pb-4'>
            <ContentsContainer tw='mt-8 mb-4'>
                <QuestionContents
                    question={question}
                    questionIndex={question.question_index}
                    hideAudioButton={true}
                />
                {instructions && <div
                    className='instructions'
                    tw='text-[#c5bff5] text-xl font-bold mt-4'
                >
                    {instructions}
                </div>}
            </ContentsContainer>
            {renderInputPanel()}
        </div>
    );
};

export default QuizAnswer;
