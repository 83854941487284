import React from 'react';
import { styled } from 'twin.macro';

const tab_item_hover = '#aaa';
const tab_item_active = '#ddd';
const tab_item_selected = '#7bdef9';
const tab_item_normal = '#fff';

const TabItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.leftAlign ? 'none' : 'center')};
    flex-grow: 1;
    flex-shrink: 0;

    @media only screen and (max-width: 600px) {
        margin: ${(props) => props.teacher && '45px 0 15px 0'};
    }

    @media only screen and (max-width: 500px) {
        justify-content: center;
        margin: ${(props) => props.teacher && '45px 0 15px 0'};
    }

    @media only screen and (max-width: 375px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    // put some space between the tabs
    .tab-item {
        margin-right: 32px;

        &:last-child {
            margin-right: 0;
            @media only screen and (max-width: 414px) {
                /* margin-left: -50px; */
            }
            @media only screen and (max-width: 375px) {
                /* margin-left: -50px; */
            }
        }
    }
`;

const TabItem = styled.div`
    user-select: none;
    color: #ffffff;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: stretch;

    font-family: 'Avenir', serif;
    @media only screen and (max-width: 375px) {
        justify-content: flex-start;
        margin-left: 5px;
    }

    .tab-item-container {
        margin-bottom: 24px;
        position: relative;

        display: flex;
        flex-direction: row;

        align-items: center;
        overflow: hidden;
        white-space: nowrap;

        .tab-item-text {
            line-height: normal;
            font-size: 24px;
            font-weight: 800;
            @media only screen and (max-width: 1024px) {
                font-size: 24px;
                font-weight: 600;
            }
            @media only screen and (max-width: 724px) {
                font-size: 20px;
                font-weight: 600;
            }

            @media only screen and (max-width: 414px) {
                font-size: 18px;
                text-decoration: ${(props) => props.active && 'underline'};
            }

            @media only screen and (max-width: 375px) {
                font-size: 16px;
                text-decoration: ${(props) => props.active && 'underline'};
            }
        }

        .tab-icon-container {
            font-size: 26px;
            margin-right: 15px;
        }

        .tab-item-underline {
            opacity: ${(props) => (props.active ? 1 : 0)};
            transition: 0.2s opacity;

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            @media only screen and (max-width: 414px) {
                top: 60px;
            }
            //border-top-right-radius: 8px;
            //border-top-left-radius: 8px;

            background-color: ${(props) => (props.selectedColor ? props.selectedColor : tab_item_selected)};
            border: none;
            z-index: 49;
        }

        // color is selected or normal
        color: ${(props) => (props.active ? props.selectedColor || tab_item_selected : props.color || tab_item_normal)};

        // on hover - color hover unless selected
        &:hover {
            color: ${(props) => (props.active ? props.selectedColor || tab_item_selected : props.hoverColor || tab_item_hover)};

            .tab-item-underline {
                background-color: ${(props) => (props.active ? props.selectedColor || tab_item_selected : props.hoverColor || tab_item_hover)};
            }
        }

        // on click - color active unless selected
        &:active {
            color: ${(props) => (props.active ? props.selectedColor || tab_item_selected : props.activeColor || tab_item_active)};

            .tab-item-underline {
                background-color: ${(props) => (props.active ? props.selectedColor || tab_item_selected : props.activeColor || tab_item_active)};
            }
        }
    }
`;

export const TabMenu = ({ onSelectTab, tabs, activeTab, color, selectedColor, leftAlign, teacher }) => {
    const handleSelectTab = (key) => {
        if (onSelectTab) onSelectTab(key);
    };
    let items = tabs;
    let itemNodes = null;
    if (items) {
        itemNodes = items.map((tab) => {
            let icon = tab.icon;
            if (tab.icon_component) {
                let IconComponent = tab.icon_component;
                icon = <IconComponent className='tab-icon' />;
            }
            return (
                <TabItem
                    selectedColor={selectedColor}
                    className='tab-item'
                    key={tab.id}
                    active={tab.id === activeTab}
                    color={color || '#fff'}
                >
                    {(!tab.hide) && (
                        <button
                            tabIndex='0'
                            className='tab-item-container'
                            onClick={() => handleSelectTab(tab.id)}
                        >
                            {icon && <div className='tab-icon-container'>{icon}</div>}

                            <div className='tab-item-text'>{tab.name}</div>

                            <div className='tab-item-underline' />
                        </button>
                    )}
                </TabItem>
            );
        });
    }

    return (
        <TabItems leftAlign={leftAlign} className='tab-items' teacher={teacher}>
            {itemNodes}
        </TabItems>
    );
};

export default TabMenu;
