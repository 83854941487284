import React from 'react';
import { styled } from 'twin.macro';
import { BeatLoader, ScaleLoader } from 'react-spinners';

const LoadingOverlayContainer = styled.div`
    // set position from props
    position: ${props => props.position ?? 'relative'};

    // for absolute and fixed position
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.4);
`;
export const LoadingOverlay = ({ text = null, show = false, style = {}, fixed = false, absolute = true, color = '#ffffff', background = undefined }) => {
    if (!show) return null;
    return (
        <LoadingOverlayContainer
            style={{ background, ...style }}
            position={fixed ? 'fixed' : (absolute ? 'absolute' : 'relative')}
            tw='flex flex-col justify-center items-center inset-0'

        >
            <ScaleLoader color={color} />
            {/*<BeatLoader color={color}/>*/}
            {!!text && <div tw='fontSize[32px] color[white] marginTop[10px]'>{text}</div>}
        </LoadingOverlayContainer>
    );
};
