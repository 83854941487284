
import axios from 'axios';
import Cookies from 'js-cookie';

export const submitHubspotForm = (portalId, formGuid, formData, pageName) => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const fields = [];
    for (const [key, value] of Object.entries(formData)) {
        console.log(`${key}: ${value}`);
        fields.push({
            name: key,
            value
        });
    }

    const context = {
        hutk: null,
        pageName: null,
        pageUri: null
    };

    // save the hubspot tracking cookie
    const hutk = Cookies.get('hubspotutk');
    if (hutk) {
        context.hutk = hutk;
    }

    // save the page name
    if (pageName) context.pageName = pageName;

    // save the url
    context.pageUri = window.location.href;

    // post the form
    return axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`, { portalId, formGuid, fields, context }, config);
};