import * as React from 'react';

function SvgClassIcon03(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF57BC' />
            <path
                d='M18.49 17.811h1.163c.333 0 .657-.023.974-.071.317-.047.602-.135.855-.261.27-.143.483-.333.641-.57.159-.254.238-.578.238-.974 0-.507-.206-.926-.618-1.259-.396-.332-.886-.498-1.472-.498-.554 0-1.014.15-1.378.45-.364.302-.601.674-.712 1.117l-3.919-.807c.19-.729.475-1.346.855-1.853.38-.523.831-.942 1.354-1.259a5.76 5.76 0 011.733-.689 8.594 8.594 0 012.043-.237c.744 0 1.457.095 2.137.285.697.174 1.307.46 1.83.855.522.38.933.863 1.234 1.449.317.586.475 1.282.475 2.09 0 .934-.245 1.733-.736 2.398-.49.665-1.203 1.093-2.138 1.283v.071c.507.064.95.214 1.33.451.396.238.729.53.998.88.27.348.475.743.617 1.187.143.443.214.91.214 1.401 0 .84-.166 1.575-.498 2.209a4.556 4.556 0 01-1.307 1.591c-.538.412-1.18.72-1.923.926a8.52 8.52 0 01-2.328.309c-1.504 0-2.803-.348-3.895-1.045-1.077-.697-1.79-1.797-2.138-3.301l3.753-.879c.127.602.38 1.077.76 1.425.396.348.974.523 1.734.523.776 0 1.346-.198 1.71-.594.38-.412.57-.934.57-1.568 0-.475-.103-.839-.309-1.092a1.665 1.665 0 00-.76-.594 3.208 3.208 0 00-1.069-.214 14.919 14.919 0 00-1.187-.047h-.832V17.81z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon03;
