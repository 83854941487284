const FilledRecords = [
    { blank: require('./record_0_0.png') }
];

for (let level = 0; level < 3; level++) {
    const RecordImages = {};
    for (let score = 0; score <= 100; score++) {
        RecordImages[score] = require(`./record_${level}_${score}.png`);
    }
    FilledRecords.push(RecordImages);
}

const FilledRecords2 = [
    { blank: require('./record_0_0.png') },
    // {
    //     0: require(`./record_${1}_0.png`),
    //     10: require('./record_0_10.png'),
    //     20: require('./record_0_20.png'),
    //     30: require('./record_0_30.png'),
    //     40: require('./record_0_40.png'),
    //     50: require('./record_0_50.png'),
    //     60: require('./record_0_60.png'),
    //     70: require('./record_0_70.png'),
    //     80: require('./record_0_80.png'),
    //     90: require('./record_0_90.png'),
    //     100: require('./record_0_100.png')
    // },
    {
        0: require('./record_1_0.png'),
        10: require('./record_1_10.png'),
        20: require('./record_1_20.png'),
        30: require('./record_1_30.png'),
        40: require('./record_1_40.png'),
        50: require('./record_1_50.png'),
        60: require('./record_1_60.png'),
        70: require('./record_1_70.png'),
        80: require('./record_1_80.png'),
        90: require('./record_1_90.png'),
        100: require('./record_1_100.png')
    },
    {
        0: require('./record_2_0.png'),
        10: require('./record_2_10.png'),
        20: require('./record_2_20.png'),
        30: require('./record_2_30.png'),
        40: require('./record_2_40.png'),
        50: require('./record_2_50.png'),
        60: require('./record_2_60.png'),
        70: require('./record_2_70.png'),
        80: require('./record_2_80.png'),
        90: require('./record_2_90.png'),
        100: require('./record_2_100.png')
    }
];

// get the url to use directly
export const recordImageURL = (level, value = 100) => {
    // console.log(level,value);
    if (level === undefined || isNaN(level) || value === undefined || isNaN(value))
        return undefined;

    // clamp the level [0..2]
    level = Math.min(Math.max(level, 0), 3);

    if (level === 0)
        value = 'blank';
    else {
        // clamp the value [0..100]
        value = parseInt(value);
        value = Math.min(Math.max(value, 0), 100);
    }
    // console.log('FilledRecords', level, value);
    return FilledRecords[level][value];
};


export default FilledRecords;
