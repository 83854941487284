import { fromJS } from 'immutable';

import { toJS } from 'utils';


import { scoreCurrentQuestion } from './grading';

import {
    BEGIN_QUIZ_QUESTION,
    CHOICE_CORRECT,
    CHOICE_INCORRECT,
    CHOICE_SELECTED,
    CLEAR_BUTTON_STATE,
    CONTEXT_STATE_CHECKING,
    CONTEXT_STATE_SUBMITTING,
    CONTEXT_STATE_WAIT_NEXT,
    CONTEXT_STATE_WAITING_FOR_USER,
    ENABLE_ANSWER_BUTTONS,
    ENABLE_SUBMIT_BUTTON,
    FREE_RESPONSE_TEXT_CHANGED,
    MARK_QUESTION_RESULTS,
    QUIZ_STATE_COMPLETED, QUIZ_STATE_RUNNING_BUT_FINISHED,
    SELECT_QUESTION_CHOICE,
    SET_BUTTON_STATE,
    SET_CONTEXT_STATE,
    SET_CURRENT_QUESTION,
    SET_QUESTION_INDEX,
    SET_SESSION_STATE,
    SUBMIT_QUESTION_ANSWER
} from './constants.js';

import { getButtonStates, getQuizContext, getQuizInstanceQuestions } from './selectors.js';
import { BUTTON_STATE_UNSELECTED, CONTEXT_STATE_SUBMITTING_COMPLETE } from './constants';
import { postResponse } from './index.js';
import { postCompleted } from './index';

// question

// begin a quiz question

// 	input:
// 		session.context.questions
//		session.context.question_index
//
//	output:
// 		session.context.question: the current question definition
//		session.context.button_state: reset to empty state

export function beginQuizQuestion() {
    return async (dispatch) => {
        // begin the question
        dispatch({ type: BEGIN_QUIZ_QUESTION });

        /*
        // reset the button state
        // dispatch(clearButtonState());

        // clear the free entry text
        dispatch({ type: FREE_RESPONSE_TEXT_CHANGED, text: '' });

        // enable the answer buttons
        dispatch(enableAnswerButtons(true));

        // disable the submit button until an answer has been selected
        dispatch(enableSubmitButton(false));

        // set the context state
        dispatch({ type: SET_CONTEXT_STATE, state: CONTEXT_STATE_WAITING_FOR_USER });
        */
    };
}

export function clearButtonState() {
    return {
        type: CLEAR_BUTTON_STATE
    };
}

export function setButtonState(button, state) {
    return {
        type: SET_BUTTON_STATE,
        button,
        state
    };
}

export function setQuestionChoice(index) {
    return function (dispatch, getState) {
        dispatch({
            type: SELECT_QUESTION_CHOICE,
            index
        });

        // in production mode - disallow clicking submit when there is no free entry text
        //if (process.env.NODE_ENV === 'production')
        {
            // check the button state to determine if the submit button should be enabled
            let buttonState = getState().getIn(['quiz', 'session', 'context', 'button_state']);
            if (buttonState) {
                buttonState = buttonState.toJS();

                let itemSelected = false;

                for (let i = 0; i < buttonState.length; i++) {
                    if (buttonState[i] !== BUTTON_STATE_UNSELECTED) {
                        itemSelected = true;
                        break;
                    }
                }

                // enable the submit button
                console.log('[setQuestionChoice] enableSubmitButton', itemSelected);
                dispatch(enableSubmitButton(itemSelected));
            }
        }
    };
}

export function setAnswerData(data) {
    // console.log('-> setAnswerData', data);
    let text = '';
    if (data) {
        text = JSON.stringify(data);
    }
    // console.log('  new text:', text);
    return function (dispatch) {
        // console.log('-> dispatch -> setAnswerData:', text);
        // set the current text
        dispatch({ type: FREE_RESPONSE_TEXT_CHANGED, text });
        // dispatch(enableSubmitButton(text && text.length));
    };
}

function isNonEmptyString(str) {
    return str !== null && str.length > 0;
}

export function freeEntryTextChanged(text) {
    // console.log('-> freeEntryTextChanged', text);
    return function (dispatch, getState) {
        // set the text
        dispatch({ type: FREE_RESPONSE_TEXT_CHANGED, text });

        // enable/disable the submit button based on the text
        const state = getState();
        const currentButtonState = state.getIn(['quiz', 'session', 'context', 'enable_submit_button'], false);
        const validText = isNonEmptyString(text);
        if (validText !== currentButtonState) {
            console.log('[freeEntryTextChanged] enableSubmitButton', currentButtonState, validText);
            dispatch(enableSubmitButton(validText));
        }

        // in non-production mode - allow clicking submit when there is no free entry text
        // if (process.env.NODE_ENV !== 'production')
        // {
        // }

    };
}

export function markQuestionResults(results) {
    return {
        type: MARK_QUESTION_RESULTS,
        results
    };
}

// this is called when the user clicks "check" to submit a question for grading
export function submitQuestionAnswer(disableNextButton = false) {
    return async (dispatch, getState) => {
        // change context state to "checking"
        await dispatch({ type: SET_CONTEXT_STATE, state: CONTEXT_STATE_CHECKING });

        // TODO: move the ui actions to a single call
        // 1. disable user input
        await dispatch(enableAnswerButtons(false));
        await dispatch(enableSubmitButton(false));

        // 2. score the question
        await dispatch(scoreCurrentQuestion());

        // 3. submit this response to the backend
        await dispatch({ type: SET_CONTEXT_STATE, state: CONTEXT_STATE_SUBMITTING });

        // get the context
        const context = toJS(getQuizContext(getState()));
        if (!context) throw Error('Quiz session has no context');

        // get the current response
        const response = context.response;

        // save the response to the server
        await dispatch(postResponse(response));

        if (context.config && context.config.autoAdvance) {
            // continue to the next question
            return await dispatch(nextQuestion());
        } else {
            // show the feedback and wait for the user to continue

            // 6. enable submit button & wait for user to click 'next'
            if (!disableNextButton)
                await dispatch(enableSubmitButton(true));
            await dispatch({ type: SET_CONTEXT_STATE, state: CONTEXT_STATE_WAIT_NEXT });

            return false;
        }
    };
}

export function setQuestionIndex(index) {
    return { type: SET_QUESTION_INDEX, index: index };
}

export function setQuizContextState(state) {
    return { type: SET_CONTEXT_STATE, state: state };
}

export function setQuizSessionState(state) {
    return { type: SET_SESSION_STATE, state: state };
}

export function quizFinished() {
    return async (dispatch, getState) => {
        // set state so ui can show a wait message
        // dispatch({ type: SET_CONTEXT_STATE, state: CONTEXT_STATE_SUBMITTING_COMPLETE });

        // call /api/session/complete
        return dispatch(postCompleted())
            .then((payload) => {
                console.log('postCompleted completed:');
                console.log(payload);

                // session is now complete
                dispatch(setQuizSessionState(QUIZ_STATE_RUNNING_BUT_FINISHED));
                // dispatch({ type: SET_SESSION_STATE, state: QUIZ_STATE_COMPLETED });

                // return  the payload
                return payload;
            })
            .catch((error) => {
                console.log('Error calling quiz session complete', error);
                console.error(error);
                alert(error);
            });
    };
}

//

/**
 *  find the next question to answer
 *  this looks for the next question that is not correct
 */

export const getNextQuestionIndex = (state) => {
    // get the questions
    const questions = getQuizInstanceQuestions(state);
    if (!questions)
        return 0;
    const question_count = questions.count();

    // get the current question index
    const context = getQuizContext(state);
    if (!context)
        return 0;
    const current_question_index = context.get('question_index');

    // look for the next question that is not correct
    let next_question_index = current_question_index + 1;
    for (; next_question_index < question_count; next_question_index++) {
        const question = questions.get(next_question_index);
        if (!question.get('correct')) {
            break;
        }
    }
    return next_question_index;
};

export function nextQuestion() {
    // advance to the next question
    return async (dispatch, getState) => {
        // console.log("NEXT QUESTION");

        // reset the button state
        dispatch(clearButtonState());

        // get the current context
        let context = getQuizContext(getState());
        if (!context) {
            console.error('Invalid, no context');
            return;
        }

        let question_index = context.get('question_index');

        let questions = getQuizInstanceQuestions(getState());
        let question_count = questions.count();

        // find the next question to answer
        // let next_question_index = question_index + 1;
        // for (; next_question_index < question_count; next_question_index++) {
        //     let question = questions.get(next_question_index);
        //     // console.log("   checking question:", question );
        //     if (!question.get('correct')) {
        //         // console.log("   found next question:", question );
        //         break;
        //     }
        // }

        const next_question_index = getNextQuestionIndex(getState());
        console.log('[*] next_question_index:', next_question_index);
        console.log('[*] question_count:', question_count);

        // clear the free response
        dispatch({ type: FREE_RESPONSE_TEXT_CHANGED, text: '' });

        // let question_count = context.questions.length;
        if (next_question_index >= question_count) {
            console.log('[*] quiz finished!');
            // this quiz is finished
            return await dispatch(quizFinished());
            // console.log('finished', finished);
            // save the assignments
            // const { results, stats, assignments, badges } = payload;
            // console.log('assignments updated: ', assignments);
            // this is finished
            // return finished;
        } else {
            // set the next question state
            dispatch({
                type: SET_CURRENT_QUESTION,
                index: next_question_index
            });

            // begin the question
            // dispatch({type: BEGIN_QUIZ_QUESTION});
            dispatch(beginQuizQuestion());

            // this is not finished
            return false;
        }
    };
}

// enable or disable choice buttons
export function enableAnswerButtons(enable) {
    return { type: ENABLE_ANSWER_BUTTONS, enable };
}

export function enableSubmitButton(enable) {
    // console.log('action enableSubmitButton: ', enable);
    return { type: ENABLE_SUBMIT_BUTTON, enable };
}

// Reducer
export default function quizContextReducer(state = [], action = {}) {
    switch (action.type) {
        case SET_CURRENT_QUESTION: {
            let questions = getQuizInstanceQuestions(state);

            let index = action.index;
            // console.log("Set next question: #", index);

            if (index >= questions.count()) {
                console.error('Invalid question index: ', index);
                return state;
            }

            let question = questions.get(index);

            state = state.setIn(['quiz', 'session', 'context', 'question'], question);
            return state.setIn(['quiz', 'session', 'context', 'question_index'], index);
        }

        case SET_QUESTION_INDEX: {
            return state.updateIn(['quiz', 'session', 'context'], (context) => {
                // let questions = context.get('questions');
                let question = context.getIn(['questions', action.index]);
                context = context.set('question_index', action.index);
                context = context.set('question', question);
                return context;
            });
        }

        case MARK_QUESTION_RESULTS:
            // set the current response choice_id
            state = state.setIn(['quiz', 'session', 'context', 'current_response', 'selected_choice'], action.choice_id);

            // mark the response correct / incorrect
            return state.setIn(['quiz', 'session', 'context', 'current_response', 'correct'], action.correct);

            // 		}

        case CLEAR_BUTTON_STATE:
            return state.setIn(['quiz', 'session', 'context', 'button_state'], fromJS([0, 0, 0, 0]));
            // return setSessionState(state, QUIZ_STATE_READY);
            // return state.mergeIn(['quiz', 'session'], fromJS(session));

        case SET_BUTTON_STATE:
            // console.log('SET_BUTTON_STATE', action);
            if (action.button_state) {
                // set all button states
                return state.setIn(['quiz', 'session', 'context', 'button_state'], fromJS(action.button_state));
            } else {
                // set a single button state directly
                return state.setIn(['quiz', 'session', 'context', 'button_state', action.button], action.state);
            }

        case ENABLE_ANSWER_BUTTONS:
            return state.setIn(['quiz', 'session', 'context', 'enable_answer_buttons'], action.enable);

        case ENABLE_SUBMIT_BUTTON:
            // console.log('[reducer] ENABLE_SUBMIT_BUTTON: ', action.enable);
            return state.setIn(['quiz', 'session', 'context', 'enable_submit_button'], action.enable);

        case SUBMIT_QUESTION_ANSWER: {
            // 1. score this question immediately
            const buttons = getButtonStates(state);
            const context = toJS(getQuizContext(state));
            // const question = context.question;
            const response = context.response;
            // console.log('Submit context: ', context);
            // console.log('Submit response: ', response);

            // // const params = getQuizParams(state);
            // const params = {};
            //!!!
            const submission = {
                buttons,
                response
            };

            // console.log('Submit submission: ', submission);
            // score this submission
            // const correct = scoreQuestion(question, submission);
            const correct = false;

            // disable buttons
            state = state.setIn(['quiz', 'session', 'context', 'enable_answer_buttons'], false);
            state = state.setIn(['quiz', 'session', 'context', 'enable_submit_button'], false);

            // TODO: use params to determine process
            // 2. setup the state to display results
            const button_state = submission.map((button) => {
                // TODO: handle multiple select
                if (button === CHOICE_SELECTED) return correct ? CHOICE_CORRECT : CHOICE_INCORRECT;
                return button;
            });

            // console.log("NEW BUTTON STATE: ", button_state);
            state = state.setIn(['quiz', 'session', 'context', 'button_state'], fromJS(button_state));

            // update progress
            // state = state.setIn(['quiz', 'session', 'context', 'progress', context.current_question], (correct ? -1 : 1));

            // 3. submit this response to the backend

            // 4. wait for user to click 'next'

            return state;
        }

        default:
            return state;
    }
}
