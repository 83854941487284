import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { Button, Modal } from 'components';
import { handlePlaylistDropdown, resetPlaylistDropdown } from 'state/PlaylistState';

const QuitPlaylistCreationModal = ({
    isOpen,
    setIsOpen,
    playlist,
    playlistDispatch,
    handleFinishedCreatingPlaylist,
    setAssignPlaylistNowOrLaterModalOpen,
    parent
}) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[806px] mx-auto`}>
            <div tw='mx-4'>
                <div tw='w-full px-6 py-3 text-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-tl-xl rounded-tr-xl relative'>
                    <h1 tw='text-white text-2xl font-medium'>Quit Playlist Creation</h1>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center '
                    >
                        <SvgWhiteXButton tw='w-4 h-4'/>
                    </button>
                </div>

                <div tw='bg-white rounded-bl-xl rounded-br-xl px-4 py-8 text-center'>
                    <div tw='space-y-6'>
                        <h3 tw='font-black text-2xl text-MediumPurple'>{playlist.name}</h3>
                        <p tw='font-extrabold text-lg text-DarkLiver'>Are you sure you want to quit creating this playlist?</p>
                    </div>
                    <div tw='space-x-4 mt-7'>
                        {parent ? (
                            <>
                                <Button
                                    variant='secondary'
                                    onClick={() => {
                                        setIsOpen(false);
                                        handlePlaylistDropdown(playlistDispatch, false);
                                        resetPlaylistDropdown(playlistDispatch);
                                    }}
                                >
                                    Quit
                                </Button>
                                <Button  onClick={() => setIsOpen(false)}>Continue Creating</Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={() => setIsOpen(false)} variant='secondary'>
                                    Continue Creating
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsOpen(false);
                                        handlePlaylistDropdown(playlistDispatch, false);
                                        resetPlaylistDropdown(playlistDispatch);
                                    }}
                                    variant='secondary'
                                >
                                    Quit Without Saving
                                </Button>
                                <Button
                                    onClick={() => {
                                        // save the playlist
                                        handleFinishedCreatingPlaylist(true).then(() => {
                                            handlePlaylistDropdown(playlistDispatch, false);
                                            resetPlaylistDropdown(playlistDispatch);
                                            setAssignPlaylistNowOrLaterModalOpen(false);
                                        });
                                        // close this dialog, close & reset the playlist dialog, and close the "assign now or later" dialog
                                        setIsOpen(false);
                                    }}
                                >
                                    Save And Quit
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

QuitPlaylistCreationModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    playlist: PropTypes.object
};

export default QuitPlaylistCreationModal;
