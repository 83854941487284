import qs from 'qs';

export const getQueryStringValue = (key, queryString = window.location.search.substring(1)) => {
    const values = qs.parse(queryString);
    // console.log('qs:', values);
    return values[key];
};

// immutable to js helper
export const toJS = (item) => (item ? item.toJS() : null);

export function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

export function getQueryVariableFromFragment(variable) {
    let fragment = window.location.hash;
    let parts = fragment.split('?');
    // console.log('parts', parts);
    if (parts.length > 1) {
        let query = parts[1];
        // console.log('query', query);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }
    // console.log('Query variable %s not found', variable);
    return null;
}

export function is_username_valid(username) {
    const username_pattern = /^[0-9a-zA-Z_.@+-]+$/;
    return username.match(username_pattern);
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

