/**
 * Created by jasonduncan on 7/13/18.
 */
import React from 'react';
import { styled } from 'twin.macro';
import { LoadingPage } from 'components/LoadingPage';
import { level_styles } from 'app/constants/constants';
import QuizPanel, { QuizPanelContents } from '../components/QuizPanel';

const Screen = styled.div`
    //background: grey;
    position: fixed;
    height: 100%;
    width: 100%;
    
    //margin-top: -25px;

    //position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000000;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .text {
        font-size: 32px;
        color: black;
    }
`;

const QuizLoading = ({ level, color, background, setIsOpen, message }) => {
    return (
        <Screen className='quiz-loading-screen'>
            <LoadingPage color={color} background={background} message={message} />
        </Screen>
    );
    // return (
    //     <Screen className='quiz-loading-screen'>
    //         <QuizPanel
    //             className={` quiz-loading-panel ${level_styles[level]}`} title={null} level={level} setIsOpen={setIsOpen}
    //             isLoading={true}
    //         >
    //             <QuizPanelContents>
    //                 <LoadingPage color={color} background={background} message={message} />
    //             </QuizPanelContents>
    //         </QuizPanel>
    //     </Screen>
    // );
};

export default QuizLoading;
