import * as React from 'react';

function SvgClassIcon10(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#F55' />
            <path
                d='M15.218 25.106c-.438-.417-.21-.3-1.266-.568-.48-.122-.9-.357-1.282-.638l-2.287 5.327c-.22.515.192 1.076.778 1.055l2.656-.097 1.826 1.833c.404.404 1.111.279 1.332-.237l2.624-6.11a3.807 3.807 0 01-1.78.458 3.755 3.755 0 01-2.601-1.023zm14.399 4.12L27.33 23.9c-.38.282-.802.516-1.281.638-1.063.27-.83.152-1.267.568a3.757 3.757 0 01-2.601 1.023c-.627 0-1.234-.17-1.78-.459l2.623 6.11c.222.517.93.642 1.332.238l1.828-1.833 2.655.097c.586.02 1-.54.778-1.055zm-6.038-5.204c.77-.745.858-.68 1.955-.964.7-.182 1.247-.71 1.435-1.388.377-1.36.28-1.196 1.308-2.19a1.918 1.918 0 00.525-1.896c-.377-1.359-.377-1.169 0-2.53a1.918 1.918 0 00-.525-1.895c-1.029-.995-.931-.83-1.308-2.19a1.997 1.997 0 00-1.435-1.388c-1.405-.364-1.236-.269-2.266-1.264a2.078 2.078 0 00-1.96-.508c-1.405.364-1.209.364-2.616 0a2.076 2.076 0 00-1.96.508c-1.029.995-.86.9-2.265 1.264-.7.182-1.248.71-1.435 1.388-.377 1.36-.28 1.196-1.308 2.19a1.917 1.917 0 00-.525 1.896c.376 1.358.377 1.168 0 2.529a1.92 1.92 0 00.525 1.895c1.029.995.931.831 1.308 2.191a1.998 1.998 0 001.435 1.388c1.128.292 1.212.246 1.955.964.666.645 1.705.76 2.507.279.32-.194.692-.296 1.071-.296.38 0 .752.102 1.072.296.801.481 1.84.366 2.507-.279zm-8.334-7.855c0-2.539 2.13-4.597 4.755-4.597 2.626 0 4.755 2.058 4.755 4.597 0 2.54-2.129 4.598-4.755 4.598s-4.755-2.058-4.755-4.598z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon10;
