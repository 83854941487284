import { fromJS, Map } from 'immutable';

export default function appReducer(state = Map(), action = {}) {

    switch (action.type) {
        case 'SET_ROOT_STORE':
            return state.merge(fromJS(action.payload));
        case 'SET_CONTENT_COURSES':
            return state.mergeIn(['content', 'courses'], fromJS(action.payload));
        case 'SET_CONTENT_VIDEOS':
            return state.mergeIn(['content', 'videos'], fromJS(action.payload));
        case 'SET_CONTENT_PLAYLISTS':
            return state.mergeIn(['content', 'playlists'], fromJS(action.payload));
        default:
            return state;
    }
}


