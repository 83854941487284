import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import BalanceText from 'react-balance-text';
import PropTypes from 'prop-types';
import { Modal, QuizIntroAttemptsDTC } from 'components';
import { useAssignments } from 'state/Student/AssignmentsState';
import { WarmupFinishedMessage } from '../QuizConstants';
import { useMedia } from 'react-use';
import moment from 'moment';
import { useUser } from 'state/User/UserState';
import { useVideoWithStats } from '../../../../../state/Student/VideoStats';
import RecordProgress from './RecordProgress';
import GradientBorder from '../../../../../components/QuizIntroDTC/GradientBorder';
import { challenge_titles, level_titles } from '../../../../constants/constants';
import { recordImageURL } from '../../../../../static/records/FilledRecordsAssets';
import AchievementConfig from '../../../../constants/AchievementConstants';
import { default as DTCAchievementConfig } from '../../Progress/AchievementConstants';
import SilverRecord from 'components/QuizIntroDTC/record_images/CD_SILVER.png';
import GoldRecord from 'components/QuizIntroDTC/record_images/CD_GOLD.png';
import PlatinumRecord from 'components/QuizIntroDTC/record_images/CD_PLATINUM.png';
import SilverRecordSmall from 'components/QuizIntroDTC/record_images/CD_SILVER_SMALL.png';
import GoldRecordSmall from 'components/QuizIntroDTC/record_images/CD_GOLD_SMALL.png';
import PlatinumRecordSmall from 'components/QuizIntroDTC/record_images/CD_PLATINUM_SMALL.png';
import WarmUp from 'components/QuizIntroDTC/record_images/Warm_Up.png';
import ParticleBackground from '../animation/ParticleBackground';
import { wrapTitleParens } from 'lib/string';

const LevelText = styled.h1(({ level }) => [
    tw`flex justify-center uppercase font-black text-center text-3xl md:text-[40px] font-Poppins`,
    css`text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);`,
    level === 1 && tw`text-[#C6C6C6]`,
    level === 2 && tw`text-[#FFC96E]`,
    level === 3 && css`
    background: linear-gradient(90deg, #F5F4F9 2.88%, #C6EDF2 54.9%, #82F4A2 98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`,
]);

const rotateCss = css`
    -webkit-animation:spin 30s linear infinite;
    -moz-animation:spin 30s linear infinite;
    animation:spin 30s linear infinite;
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
`


const BadgeAchievedMessage = ({ badge, image, header }) => {
    return (
        <div tw='flex items-center justify-center pt-2 text-base sm:text-lg font-extrabold px-10'>
            <img tw='h-[36px] sm:h-[48px] pr-2' src={image} alt={header} />
            <div tw='text-left'>You earned the {header} Achievement!</div>
        </div>
    );
};


export const VideoWarmupMessage = ({ correct, total }) => {

    const randomNumber = Math.round(Math.random() * 2);
    const score = Math.round((correct / total) * 100);
    const scoreConditionalText = WarmupFinishedMessage[score];

    let badges = null;
    if (correct === total) {
        const badge = DTCAchievementConfig.single_video_pretest_perfect_score
        const badeImage = badge.non_ribbon_image;
        badges = (<BadgeAchievedMessage badge={badge} image={badeImage} header={badge.header} />);
    }

    return (
        <div tw='flex flex-col justify-center text-center mt-5 sm:mt-10 mb-4 sm:mb-7'>
            <div tw='text-xl sm:text-3xl font-Poppins font-extrabold text-[#B9EAFF] uppercase [text-shadow: 0px 0px 8.136449813842773px #A692D3 ]'>
                {`Score: ${correct} out of ${total}`}
            </div>
            {badges}
            <div tw='text-base sm:text-lg font-bold mt-5 sm:mt-8 px-4 sm:px-10'>
                <BalanceText >{scoreConditionalText[randomNumber]}</BalanceText>
            </div>
        </div>
    );
};

const QuizFinishedMessage = ({ buttons, delay, level, message, score, startScore, video, videoTitle, correct, total, }) => {

    const [isOpen, setIsOpen] = useState(false);
    const user = useUser();
    const isDtc = user.is_homeschool_account;
    const assignments = useAssignments();
    const recordImages = [WarmUp, SilverRecord, GoldRecord, PlatinumRecord]
    const recordImagesSmall = [, SilverRecordSmall, GoldRecordSmall, PlatinumRecordSmall]


    // get video with stats
    let videoWithStats = useVideoWithStats(video?.id);

    // get the previous attempts from the stats
    let prev_attempts = [];
    if (videoWithStats?.stats?.quiz_levels && videoWithStats?.stats?.quiz_levels[level]) {
        if (videoWithStats?.stats?.quiz_levels[level].instances) {
            const instance = videoWithStats?.stats?.quiz_levels[level]?.instances;
            prev_attempts = instance.filter(({ extra_challenge }) => !extra_challenge).map(({ score }) => score);
        }
    }

    // filter assignments related to the video
    let videoAssignment = [];
    assignments.forEach((assignment) => {
        assignment.items.forEach((item) => {
            if (item === video.id) videoAssignment.push(assignment);
        });
    });

    // particles background
    let particlesBackground = null;

    // create the particle background
    if (level !== 0 && score === 100) {
        particlesBackground = <ParticleBackground level={level - 1}/>;

    }

    // progress indicator
    let progress = null;
    // create the progress bar
    if (level === 0) {
        // warmup level
        progress = (
            <VideoWarmupMessage
                key='warmup-message'
                correct={correct}
                total={total}
                isDtc={isDtc}
            />
        );
    } else {
        // progress bar
        progress = (
            <RecordProgress
                key='progress'
                score={score}
                level={level}
                // onClickRecord={startAnimation}
                tw='pt-4 paddingBottom[20px]'
            />
        );
    }

    return (
        <div tw='block xsm:flex flex-col justify-items-stretch items-center font-Poppins relative w-full h-full m-auto' data-testid='quiz-finished'>
            {particlesBackground}
            <div
                tw='absolute inset-0 m-0 sm:m-auto  max-w-[100%] sm:max-w-[658px] quiz_intro_md:max-w-[760px] lg:max-w-[810px] max-h-[100%] sm:max-h-[550px] quiz_intro_md:max-h-[550px]'
                // css={[level !== 0 && tw`quiz_height:m-[130px auto auto]`]}
                // css={[prev_attempts.length > 0 && tw` max-h-[100%] sm:max-h-[550px] quiz_intro_md:max-h-[540px]`]}
            >
                {level === 0 ?
                    <div tw='hidden xsm:block absolute z-10 right-[-42%] sm:right-0 bottom-[50%] sm:bottom-[65%] quiz_intro_md:bottom-[50%] '>
                        <img src={recordImages[level]} tw='h-auto sm:h-full sm:max-h-[100%] w-[521px] sm:w-full max-w-none sm:max-w-[100%]' />
                    </div>
                    :
                    <div tw='hidden xsm:block  absolute z-10 right-0 bottom-[40%] quiz_intro_md:bottom-[28.5%] lg:bottom-[22%] '>
                        <img src={recordImages[level]} tw='h-auto sm:h-full sm:max-h-[100%] w-[651px] sm:w-full max-w-none  sm:max-w-[100%]' css={[rotateCss]} />
                    </div>
                }
                <div tw='block xsm:hidden top-0 left-0 z-10'>
                    <img tw='w-full h-auto' src={recordImagesSmall[level]} />
                </div>

                <div tw='hidden xsm:block'>
                    <GradientBorder key={level} level={level} >
                        <div tw='h-full relative z-50 font-Poppins'>
                            <div
                                tw='text-white flex justify-center uppercase text-center '
                                css={[
                                    level !== 0 && tw`pt-[50px] sm:pt-[50px]`,
                                    level === 0 && tw`pt-[25px] sm:pt-[50px]`
                                ]}
                            >
                                <LevelText level={level}>
                                    {challenge_titles[level]}
                                </LevelText>
                            </div>
                            <div tw='flex justify-center font-bold text-center text-lg sm:text-xl max-w-[245px] sm:max-w-full mx-auto'
                                css={[
                                    level !== 0 && tw`pt-10 sm:pt-8`,
                                    level === 0 && tw`pt-3 sm:pt-8`
                                ]}
                            >
                                {video?.title}
                            </div>
                            {progress}
                            <div tw='pt-10 sm:pt-5 flex flex-col sm:flex-row items-center sm:items-stretch mx-auto sm:w-[100%] max-w-[300px] sm:max-w-[100%] px-1 justify-center'
                                css={[
                                    level === 0 && tw`pt-0`
                                ]}
                            >
                                {buttons}
                            </div>
                            {(level !== 0 && prev_attempts.length > 0) &&
                                <div tw='flex justify-center pt-6 pb-3.5'>
                                    <div tw='text-white text-base font-normal underline cursor-pointer' onClick={() => setIsOpen(true)}>
                                        See Attempts
                                    </div>
                                </div>
                            }
                        </div>
                    </GradientBorder>
                </div>
                <div tw='block xsm:hidden px-4 absolute z-50 top-16 w-full'>
                    <div tw='h-full relative z-50 font-Poppins'>
                        <div
                            tw='text-white flex justify-center uppercase text-center '
                            css={[
                                level !== 0 && tw`pt-[50px] sm:pt-[50px]`,
                                level === 0 && tw`pt-[25px] sm:pt-[50px]`
                            ]}
                        >
                            <LevelText level={level}>
                                {challenge_titles[level]}
                            </LevelText>
                        </div>
                        <div tw='flex justify-center font-semibold text-center text-xl  mx-auto'
                            css={[
                                level !== 0 && tw`pt-10 sm:pt-8`,
                                level === 0 && tw`pt-3 sm:pt-8`
                            ]}
                        >
                            <BalanceText>
                                {wrapTitleParens(video?.title)}
                            </BalanceText>
                        </div>
                        {progress}
                        <div tw='pt-4 sm:pt-0 flex flex-col sm:flex-row items-center sm:items-stretch mx-auto sm:w-[100%] max-w-[300px] sm:max-w-[100%] px-1 justify-center'
                            css={[
                                level === 0 && tw`pt-0`
                            ]}
                        >
                            {buttons}
                        </div>
                        {(level !== 0 && prev_attempts.length > 0) &&
                            <div tw='flex justify-center pt-6 pb-3.5'>
                                <div tw='text-white text-lg font-extrabold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                                    See Attempts
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
                <QuizIntroAttemptsDTC videoTitle={videoTitle} level={level} previousScores={prev_attempts} setIsOpen={setIsOpen} />
            </Modal>
        </div>
    );
};

QuizFinishedMessage.propTypes = {
    startScore: PropTypes.number,
    buttons: PropTypes.any,
    delay: PropTypes.number,
    level: PropTypes.number,
    message: PropTypes.any,
    score: PropTypes.number
};

export default QuizFinishedMessage;
