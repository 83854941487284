import React from 'react';
import { styled } from 'twin.macro';
import { PROGRESS_ITEM_CORRECT, PROGRESS_ITEM_INCORRECT } from 'modules/quiz/constants';
import progress_check from 'static/images/white-check.png';
import progress_x from 'static/images/white-x.png';
import { css } from 'twin.macro';

const Container = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;

    .indicator-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .connector-line {
            height: 5px;
            border-radius: 2.5px;

            width: 29px;
            margin: auto 6px;
            background: white;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            @media only screen and (max-width: 767px) and (min-width: 600px) {
                // 767 - 600
                width: 20px;
                margin: auto 4px;
                // 2 + 10 + 2 = 14px
            }
            @media only screen and (max-width: 600px) {
                width: 16px;
                margin: auto 1px;
                // 2 + 8 + 2 = 12px
            }
            @media only screen and (max-width: 640px) {
                width: 75%;
                margin: 20px auto 0;
            }
        }

        @media only screen and (max-width: 640px) {
            display: grid;
            grid-template-columns: repeat(10, minmax(0, 1fr));
            margin-top: 1rem;
        }
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
    }
    @media only screen and (max-width: 767px) and (min-width: 500px) {
        // 767 and lower
    }
    @media only screen and (max-width: 640px) {
        margin-left: 30px;
    }
`;

const Indicator = styled.div`
    z-index: 100;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.$color ?? '#5D686F'};
    border: solid 2px white;
    border-radius: 50%;

    .indicator-center {
        // Style for "Ellipse 40"
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        font-family: Avenir, serif;
        font-weight: 900;
        font-size: 18px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    margin: 0;
    //border-image linear-gradient(184deg, #ffffff, #a8a8a8);
    transition: width 0.1s, height 0.1s, margin 0.1s, box-shadow 0.1s, text-shadow 0.1s;

    &.current {
        border-color: #ffffff;
    }

    &.enable-input {
        cursor: pointer;

        &.current,
        &:hover,
        &:active {
            transition: width 0.1s, height 0.1s, margin 0.1s, box-shadow 0.1s, text-shadow 0.1s;

            height: 56px;
            width: 56px;

            margin-left: 0;
            margin-right: 0;

            font-size: 32px;
            color: #ffffff;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

            &:active {
                text-shadow: 0 -1px -2px rgba(0, 0, 0, 0.5);
                box-shadow: 0 -2px -4px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }

    // correct & incorrect overrides current
    &.correct {
        border-color: white;
        .indicator-center {
            background-color: #5fc17a;
        }
    }

    &.incorrect {
        border-color: white;
        .indicator-center {
            background-color: #ee7171;
        }
    }

    &.unanswered {
        border-color: white;
        .indicator-center {
            /* background-color: yellow; */
        }
    }

    /* @media only screen and (max-width: 991px) and (min-width: 768px) {
        height: 38px;
        width: 38px;
        border-width: 3px;

        .indicator-center {
            font-size: 16px;
            height: 24px;
            width: 24px;
        }
    } */

    // mobile first
    height: 30px;
    width: 30px;
    margin-top: 20px;
    .indicator-center {
        font-size: 12px;
        height: 22px;
        width: 22px;
    }

    // small
    @media only screen and (min-width: 640px) {
        height: 26px;
        width: 26px;
        margin-top: 0px;

        .indicator-center {
            font-size: 10px;
            height: 18px;
            width: 18px;
        }

        //height: 28px;
        //width: 28px;
        //
        //.indicator-center {
        //    font-size: 14px;
        //    height: 26px;
        //    width: 26px;
        //}
    }

    // med
    @media only screen and (min-width: 768px) {
        height: 30px;
        width: 30px;
        margin-top: 0px;

        .indicator-center {
            font-size: 12px;
            height: 22px;
            width: 22px;
        }
    }

    // large
    @media only screen and (min-width: 1024px) {
        height: 35px;
        width: 35px;
        margin-top: 0px;
    }

    /*

    @media only screen and (max-width: 600px) {
        height: 30px;
        width: 30px;

        .indicator-center {
            font-size: 12px;
            height: 22px;
            width: 22px;
        }
    }
    @media only screen and (max-width: 425px) {
        height: 26px;
        width: 26px;

        .indicator-center {
            font-size: 10px;
            height: 18px;
            width: 18px;
        }
    }
    @media only screen and (max-width: 350px) {
        height: 22px;
        width: 22px;

        .indicator-center {
            font-size: 8px;
            height: 14px;
            width: 14px;
        }
    }

    @media only screen and (min-width: 1200px) {
        // 1200 and over
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        // 992 - 1199
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
    }
    @media only screen and (max-width: 767px) {
        // 767 and lower
    }
    */
`;

const QuizProgress = ({ className, backgroundColor, backgroundImage, color, current, enableUserInteraction, onSelect, progress }) => {
    const handleClick = (index) => {
        if (enableUserInteraction) {
            if (onSelect) onSelect(index);
        }
    };

    const renderIndicators = (indicators) => {
        if (!indicators) return [];
        let count = typeof indicators !== 'undefined' ? indicators.length : indicators.count();

        let nodes = [];
        indicators.forEach((value, index) => {
            if (index >= count) return;
            let content = index + 1;

            let className = 'progress-indicator ';

            // get the class name
            if (index === current) {
                className += ' current ';
            } else {
                if (value === PROGRESS_ITEM_CORRECT) {
                    className += ' correct ';
                } else if (value === PROGRESS_ITEM_INCORRECT) {
                    className += ' incorrect ';
                } else {
                    if (index < current) {
                        // past
                        className += ' normal';
                    } else {
                        // future
                        className += ' normal unanswered';
                    }
                }
            }

            if (value === PROGRESS_ITEM_CORRECT) {
                content = <img src={progress_check} width='60%' height='auto' alt='correct' />;
                className += ' correct ';

                // content = <span>&#10004;</span>;
            } else if (value === PROGRESS_ITEM_INCORRECT) {
                content = <img src={progress_x} width='50%' height='auto' alt='incorrect' />;
                className += ' incorrect ';
            }

            if (enableUserInteraction) className = ' enable-input ';

            let indicator = (
                <Indicator
                    key={index}
                    className={className}
                    onClick={() => handleClick(index)}
                >
                    <div className='indicator-center'>{content}</div>
                </Indicator>
            );

            nodes.push(indicator);
            // console.log('current:', current);
            if (index !== count - 1) {
                // add a connector line
                nodes.push(
                    <div
                        key={`${index}-connector`}
                        className='connector-line'
                        css={[
                            index === 4 &&
                                css`
                                    @media only screen and (max-width: 640px) {
                                        background: transparent !important;
                                    }
                                `
                        ]}
                    />
                );
            }
        });

        return nodes;
    };
    // console.log('QuizProgress', progress);
    return (
        <Container
            className={className}
            style={{
                backgroundImage: backgroundImage,
                backgroundColor: backgroundColor,
                color: color
            }}
            $color={color}
        >
            <div className='indicator-container'>{renderIndicators(progress)}</div>
        </Container>
    );
};

export default QuizProgress;
