import React, { useState, lazy, Suspense } from 'react';
import tw, { css } from 'twin.macro';
import { VideoCard } from 'components';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { StandardVideoGrid } from '../../styles';
import LazyLoadVideoGrid from 'components/LazyLoadVideo/LazyLoadVideoGrid';

const HomePageVideoRows = ({ title, videos, favorites, teacher, recently_watched, timesTableProduct }) => {
    const emptyVideos = videos.length === 0 ? true : false;
    // grab screen width, so we can slice video row down according the grid-col
    const sm = useMedia('(min-width: 640px)');
    const ipad = useMedia('(max-width: 1025px)');
    const xl = useMedia('(min-width: 1024px)');
    // const xl = useMedia('(min-width: 1280px)');

    const sliceNumber = xl ? 3 : sm ? 2 : 1;
    const expandedSliceNumber = favorites ? favorites.length : videos.length;
    let showMoreVideos = videos?.length > 4;
    if(timesTableProduct) showMoreVideos = false
    const [handleShowMoreVideos, setHandleShowMoreVideos] = useState(recently_watched ? false : true);
    const slicedVideos = !handleShowMoreVideos ? videos?.slice(0, sliceNumber) : videos?.slice(0, expandedSliceNumber);

    const videoCards = slicedVideos?.map((video, index) => {
        if (!video) return null;
        return (
            <div
                key={video.id}
                id={index === 1 ? 'video-page-tour-video-thumbnail' : null}
                className='video-card-container'
                data-video-index={index}
            >
                <VideoCard
                    data-video-id={video.id}
                    data-video-title={video.title}
                    style={{ marginBottom: 30 }}
                    index={index}
                    video={video}
                    showTitle={true}
                    libraryView
                    showViewCount={true}
                    showRecords={true}
                    teacher={teacher}
                    disableHover={ipad ? true : false}

                    stats={video.stats}
                />
            </div>
        );
    });
    return (
        <div tw='px-0 2xl:px-5'>
            <div tw='text-white md:text-lg lg:text-xl font-semibold hidden md:block'>{title}</div>
            <div tw='text-white text-lg font-semibold pl-5 md:hidden'>{title}</div>
            <StandardVideoGrid tw='mt-6'>
                {videoCards}
            </StandardVideoGrid>
            {
                showMoreVideos &&
                <div onClick={() => setHandleShowMoreVideos(!handleShowMoreVideos)} tw='flex justify-center items-center w-full mt-4 cursor-pointer' css={[handleShowMoreVideos && tw`rotate-180`]}>
                    <svg xmlns='http://www.w3.org/2000/svg' tw='w-6 h-6' fill='none' viewBox='0 0 16 10'>
                        <path stroke='#fff' strokeLinecap='round' strokeWidth='1.5' d='m1 1 6.624 7.57a.5.5 0 0 0 .752 0L15 1' />
                    </svg>
                </div>
            }
        </div>

    );
};

HomePageVideoRows.propType = {
    title: PropTypes.string,
    videos: PropTypes.array
};

export default HomePageVideoRows;


