import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { useForm } from 'react-hook-form';
import { MUZOLOGY_WEBSITE_URL } from 'config';
import useAxios from 'axios-hooks';
import LoadingOverlay from 'components/LoadingOverlay';
import { useAccount, useOnboardState, useUser } from '../../state/User/UserState';
import { maybePlural } from '../../utils/string';
import CloseButton from '../AnswerKey/CloseButton';

const EndTrialDialog = ({ setOnboardState, show, parent }) => {
    const { register, handleSubmit, errors } = useForm();
    const account = useAccount();
    const { is_parent_account } = account;
    console.log('🚀 ~ EndTrailDialog ~ is_parent_account:', is_parent_account);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { trial_days_remaining, subscription_expired } = account;
    const user = useUser();
    const isStudent = !user.instructor;


    const backgroundGradient = css`
    background-image: linear-gradient(
        180deg,
        hsl(273deg 100% 89%) 0%,
        hsl(269deg 100% 91%) 14%,
        hsl(264deg 100% 93%) 28%,
        hsl(257deg 100% 94%) 42%,
        hsl(245deg 100% 96%) 56%,
        hsl(226deg 100% 96%) 71%,
        hsl(207deg 100% 96%) 81%,
        hsl(186deg 100% 96%) 100%
        );
    `;

    useEffect(() => {
        if (show) setIsOpen(true);
    }, [show]);

    // get the header text
    let headerCopy = null;
    let accountHolderCopy = 'Let’s keep the math music playing. We’d love you and your students to continue to  benefit from Muzology!';
    let accountHolderCopy2 = 'Click below for special educator pricing.';
    if( subscription_expired ) {
        if (isStudent){
            headerCopy = "OH NO! Your teacher's Muzology subscription has ended";
            accountHolderCopy = '';
            accountHolderCopy2 = '';
        }
        else {
            headerCopy = 'Oh No! Your Subscription Has Ended.';
            // accountHolderCopy = 'Let’s keep the math music playing.';
            // accountHolderCopy2 = 'Click below to reactivate your Muzology subscription.';
        }
    } else {
        if (isStudent) headerCopy = "OH NO! Your teacher's Muzology trial has ended";
        else if (trial_days_remaining < 0) headerCopy = 'Oh No! Your Free Trial is Up.';
        else if(trial_days_remaining === 0) headerCopy = 'Your Free Trial Ends Today!';
        else if (trial_days_remaining <= 5) headerCopy = `Your Free Trial Ends in ${trial_days_remaining} ${maybePlural('Day', trial_days_remaining)}!`;
    }
    if (is_parent_account) {
        headerCopy = 'Oh No! You Subscription Was Canceled.';
        accountHolderCopy = 'Let’s keep the math music playing.';
        accountHolderCopy2 = 'Click below to reactivate your Muzology subscription.';
    }

    const [{ loading: isPosting }, manageSubscription] = useAxios({ url: '/api/stripe/portal/', method: 'GET' }, { manual: true });


    const handleOnClick = () => {
        if (is_parent_account) {
            manageSubscription()
                .then((response) => {
                    try {
                        const { redirect } = response.data;
                        // console.log('portal redirect', redirect);
                        if (redirect) {
                            setLoading(true);
                            window.location = redirect;
                        }
                    } catch (err) {
                        console.log('error redirecting to stripe portal', err);
                        console.error(err);
                        setLoading(false);
                        throw err;
                    }

                })
                .catch(err => {
                    console.log('portal error', err);
                    setLoading(false);
                });
        } else window.location = `${MUZOLOGY_WEBSITE_URL}educator/${account.account_uuid}`;


    };

    return (
        <Modal isOpen={isOpen} modalChildrenStyle={tw`w-full max-w-[650px] lg:max-w-[720px] mx-auto px-5`}>
            <div tw='py-14 sm:py-[103px] px-5 md:px-8 rounded-xl text-center relative font-Poppins' css={[backgroundGradient]}>
                <LoadingOverlay show={loading} fixed={true} />
                <div tw='text-black text-xl sm:text-2xl md:text-3xl mt-5 font-semibold text-center'>{headerCopy}</div>
                <div>
                    {isStudent && !is_parent_account ? (
                        <div tw='text-center text-base sm:text-lg md:text-xl '>
                            <div tw='mt-9'>Please see your teacher about continuing to use Muzology. </div>
                            <div tw='mt-9 mb-6'>
                                Or, you can ask your parent to get you an at-home subscription:{' '}
                                <span tw='underline cursor-pointer'>
                                    <a href='https://www.muzology.com/parents'>muzology.com/parent</a>
                                </span>{' '}
                            </div>
                        </div>
                    ) : (
                        <div tw='text-black text-base sm:text-xl'>
                            <div tw='max-w-[530px] text-center mx-auto  mt-10'>
                                {accountHolderCopy}
                            </div>
                            <div tw='mt-6'>{accountHolderCopy2}</div>
                        </div>

                    )}
                </div>
                <div tw='flex justify-center mt-10'>
                    <button tw='bg-[#6E60E6] w-[250px] sm:w-[360px] h-[65px] text-white text-xl sm:text-2xl font-medium rounded-[12px]' onClick={handleOnClick}>Continue</button>
                </div>
                {!is_parent_account && trial_days_remaining >= 0 && (
                    <div tw='absolute top-3 right-6 cursor-pointer' onClick={() => setIsOpen(false)}>
                        <div tw='text-[rgba(178, 178, 181, 0.95)] text-4xl'>X</div>
                    </div>

                )}
            </div>
        </Modal>
    );
};

EndTrialDialog.propTypes = {
    anyProp: PropTypes.any
};

export default EndTrialDialog;
