import React from 'react';

const SvgAllVideosIcon = (props) => (
    <svg
        width='1em'
        height='1em'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z'
            fill='#fff'
        />
        <path
            d='M4.07 16.098c.258.258.607.402.972.402h11.917a1.375 1.375 0 001.375-1.375V8.71a1.375 1.375 0 00-1.375-1.375H5.042a1.375 1.375 0 00-1.375 1.375v6.416c0 .365.145.715.403.973z'
            fill='url(#prefix__paint0_linear)'
        />
        <path
            d='M5.635 6.283a.458.458 0 01.324-.783h10.083a.459.459 0 010 .917H5.959a.458.458 0 01-.324-.134z'
            fill='url(#prefix__paint1_linear)'
        />
        <path
            d='M7.468 4.45a.458.458 0 01.324-.783h6.417a.458.458 0 010 .917H7.792a.458.458 0 01-.324-.135z'
            fill='url(#prefix__paint2_linear)'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.403 9.225a.458.458 0 01.465.012l3.667 2.292a.458.458 0 010 .777l-3.667 2.292a.46.46 0 01-.701-.39V9.627a.458.458 0 01.236-.401z'
            fill='#fff'
        />
        <defs>
            <linearGradient
                id='prefix__paint0_linear'
                x1={11}
                y1={3.667}
                x2={11}
                y2={16.5}
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1E215F' />
                <stop
                    offset={1}
                    stopColor='#070A3B' />
            </linearGradient>
            <linearGradient
                id='prefix__paint1_linear'
                x1={11}
                y1={3.667}
                x2={11}
                y2={16.5}
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1E215F' />
                <stop
                    offset={1}
                    stopColor='#070A3B' />
            </linearGradient>
            <linearGradient
                id='prefix__paint2_linear'
                x1={11}
                y1={3.667}
                x2={11}
                y2={16.5}
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1E215F' />
                <stop
                    offset={1}
                    stopColor='#070A3B' />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgAllVideosIcon;
