import { useState } from 'react';
import tw, { css } from 'twin.macro';

const Tab = ({ title, selected, onClick }) => {

    const handleSelected = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if( onClick )
            onClick();
    };

    return (
        <li>
            <a
                href='#'
                onClick={handleSelected}
                tw='inline-block px-4 py-2 text-gray-600 bg-white rounded shadow'
                css={[selected && tw`bg-blue-500 text-white`]}
            >
                {title}
            </a>
        </li>

    );
};

const TabBar = ({ tabs, selected, setSelected }) => {
    const [openTab, setOpenTab] = useState(selected ?? null);

    const handleSelected = (value) => {
        setOpenTab(value);
        if( setSelected )
            setSelected(value);
    };

    return (
        <div>
            <div tw='container mx-auto'>
                <div tw='flex flex-col items-center justify-center max-w-xl'>
                    <ul tw='flex space-x-2'>
                        {tabs?.map((tab, index) => {
                            return (
                                <Tab
                                    key={index}
                                    title={tab.title}
                                    selected={openTab === index}
                                    onClick={() => handleSelected(index)}
                                />
                            );
                        })}
                    </ul>

                    {/*<div tw='p-3 mt-6 bg-white border'>
                        <div css={[openTab === 1 ? tw`block` : tw`hidden`]}>
                            {' '}
                            React JS with Tailwind CSS Tab 1 Content show
                        </div>
                        <div css={[openTab === 2 ? tw`block` : tw`hidden`]}>
                            React JS with Tailwind CSS Tab 2 Content show
                        </div>
                        <div css={[openTab === 3 ? tw`block` : tw`hidden`]}>
                            React JS with Tailwind CSS Tab 3 Content show
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>

    );
};

export default TabBar;