import React, { useState, useEffect } from 'react';
import tw, { css, styled } from 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import background_flare from './background/Inner_Background.png';
import background_flare_warmup from './background/Inner_Background_WarmUp.png';

const InnerContainer = styled.div(({ level }) => [
    tw` absolute inset-0 z-40 rounded-[27px] w-full h-full `,
    css`box-shadow: 0px 0px 2.2165px 0px #401E8F, 0px 0px 4.43299px 0px #401E8F, 0px 0px 15.51547px 0px #401E8F, 0px 0px 31.03094px 0px #401E8F, 0px 0px 53.19589px 0px #401E8F, 0px 0px 93.0928px 0px #401E8F;`,
    level === 0 && tw`bg-white`,
    level === 0 && css`box-shadow: 0px 4px 17px 5px #888282;`,
    level === 1 && css`
    background: linear-gradient(0deg, #5EC4DE, #847EDD);
    @keyframes background_rotate {
    1%{background: linear-gradient(3.6deg, #5EC4DE, #847EDD);}
      2%{background: linear-gradient(7.2deg, #5EC4DE, #847EDD);}
      3%{background: linear-gradient(10.8deg, #5EC4DE, #847EDD);}
      4%{background: linear-gradient(14.4deg, #5EC4DE, #847EDD);}
      5%{background: linear-gradient(18deg, #5EC4DE, #847EDD);}
      6%{background: linear-gradient(21.6deg, #5EC4DE, #847EDD);}
      7%{background: linear-gradient(25.2deg, #5EC4DE, #847EDD);}
      8%{background: linear-gradient(28.8deg, #5EC4DE, #847EDD);}
      9%{background: linear-gradient(32.4deg, #5EC4DE, #847EDD);}
      10%{background: linear-gradient(36deg, #5EC4DE, #847EDD);}
      11%{background: linear-gradient(39.6deg, #5EC4DE, #847EDD);}
      12%{background: linear-gradient(43.2deg, #5EC4DE, #847EDD);}
      13%{background: linear-gradient(46.8deg, #5EC4DE, #847EDD);}
      14%{background: linear-gradient(50.4deg, #5EC4DE, #847EDD);}
      15%{background: linear-gradient(54deg, #5EC4DE, #847EDD);}
      16%{background: linear-gradient(57.6deg, #5EC4DE, #847EDD);}
      17%{background: linear-gradient(61.2deg, #5EC4DE, #847EDD);}
      18%{background: linear-gradient(64.8deg, #5EC4DE, #847EDD);}
      19%{background: linear-gradient(68.4deg, #5EC4DE, #847EDD);}
      20%{background: linear-gradient(72deg, #5EC4DE, #847EDD);}
      21%{background: linear-gradient(75.6deg, #5EC4DE, #847EDD);}
      22%{background: linear-gradient(79.2deg, #5EC4DE, #847EDD);}
      23%{background: linear-gradient(82.8deg, #5EC4DE, #847EDD);}
      24%{background: linear-gradient(86.4deg, #5EC4DE, #847EDD);}
      25%{background: linear-gradient(90deg, #5EC4DE, #847EDD);}
      26%{background: linear-gradient(93.6deg, #5EC4DE, #847EDD);}
      27%{background: linear-gradient(97.2deg, #5EC4DE, #847EDD);}
      28%{background: linear-gradient(100.8deg, #5EC4DE, #847EDD);}
      29%{background: linear-gradient(104.4deg, #5EC4DE, #847EDD);}
      30%{background: linear-gradient(108deg, #5EC4DE, #847EDD);}
      31%{background: linear-gradient(111.6deg, #5EC4DE, #847EDD);}
      32%{background: linear-gradient(115.2deg, #5EC4DE, #847EDD);}
      33%{background: linear-gradient(118.8deg, #5EC4DE, #847EDD);}
      34%{background: linear-gradient(122.4deg, #5EC4DE, #847EDD);}
      35%{background: linear-gradient(126deg, #5EC4DE, #847EDD);}
      36%{background: linear-gradient(129.6deg, #5EC4DE, #847EDD);}
      37%{background: linear-gradient(133.2deg, #5EC4DE, #847EDD);}
      38%{background: linear-gradient(136.8deg, #5EC4DE, #847EDD);}
      39%{background: linear-gradient(140.4deg, #5EC4DE, #847EDD);}
      40%{background: linear-gradient(144deg, #5EC4DE, #847EDD);}
      41%{background: linear-gradient(147.6deg, #5EC4DE, #847EDD);}
      42%{background: linear-gradient(151.2deg, #5EC4DE, #847EDD);}
      43%{background: linear-gradient(154.8deg, #5EC4DE, #847EDD);}
      44%{background: linear-gradient(158.4deg, #5EC4DE, #847EDD);}
      45%{background: linear-gradient(162deg, #5EC4DE, #847EDD);}
      46%{background: linear-gradient(165.6deg, #5EC4DE, #847EDD);}
      47%{background: linear-gradient(169.2deg, #5EC4DE, #847EDD);}
      48%{background: linear-gradient(172.8deg, #5EC4DE, #847EDD);}
      49%{background: linear-gradient(176.4deg, #5EC4DE, #847EDD);}
      50%{background: linear-gradient(180deg, #5EC4DE, #847EDD);}
      51%{background: linear-gradient(183.6deg, #5EC4DE, #847EDD);}
      52%{background: linear-gradient(187.2deg, #5EC4DE, #847EDD);}
      53%{background: linear-gradient(190.8deg, #5EC4DE, #847EDD);}
      54%{background: linear-gradient(194.4deg, #5EC4DE, #847EDD);}
      55%{background: linear-gradient(198deg, #5EC4DE, #847EDD);}
      56%{background: linear-gradient(201.6deg, #5EC4DE, #847EDD);}
      57%{background: linear-gradient(205.2deg, #5EC4DE, #847EDD);}
      58%{background: linear-gradient(208.8deg, #5EC4DE, #847EDD);}
      59%{background: linear-gradient(212.4deg, #5EC4DE, #847EDD);}
      60%{background: linear-gradient(216deg, #5EC4DE, #847EDD);}
      61%{background: linear-gradient(219.6deg, #5EC4DE, #847EDD);}
      62%{background: linear-gradient(223.2deg, #5EC4DE, #847EDD);}
      63%{background: linear-gradient(226.8deg, #5EC4DE, #847EDD);}
      64%{background: linear-gradient(230.4deg, #5EC4DE, #847EDD);}
      65%{background: linear-gradient(234deg, #5EC4DE, #847EDD);}
      66%{background: linear-gradient(237.6deg, #5EC4DE, #847EDD);}
      67%{background: linear-gradient(241.2deg, #5EC4DE, #847EDD);}
      68%{background: linear-gradient(244.8deg, #5EC4DE, #847EDD);}
      69%{background: linear-gradient(248.4deg, #5EC4DE, #847EDD);}
      70%{background: linear-gradient(252deg, #5EC4DE, #847EDD);}
      71%{background: linear-gradient(255.6deg, #5EC4DE, #847EDD);}
      72%{background: linear-gradient(259.2deg, #5EC4DE, #847EDD);}
      73%{background: linear-gradient(262.8deg, #5EC4DE, #847EDD);}
      74%{background: linear-gradient(266.4deg, #5EC4DE, #847EDD);}
      75%{background: linear-gradient(270deg, #5EC4DE, #847EDD);}
      76%{background: linear-gradient(273.6deg, #5EC4DE, #847EDD);}
      77%{background: linear-gradient(277.2deg, #5EC4DE, #847EDD);}
      78%{background: linear-gradient(280.8deg, #5EC4DE, #847EDD);}
      79%{background: linear-gradient(284.4deg, #5EC4DE, #847EDD);}
      80%{background: linear-gradient(288deg, #5EC4DE, #847EDD);}
      81%{background: linear-gradient(291.6deg, #5EC4DE, #847EDD);}
      82%{background: linear-gradient(295.2deg, #5EC4DE, #847EDD);}
      83%{background: linear-gradient(298.8deg, #5EC4DE, #847EDD);}
      84%{background: linear-gradient(302.4deg, #5EC4DE, #847EDD);}
      85%{background: linear-gradient(306deg, #5EC4DE, #847EDD);}
      86%{background: linear-gradient(309.6deg, #5EC4DE, #847EDD);}
      87%{background: linear-gradient(313.2deg, #5EC4DE, #847EDD);}
      88%{background: linear-gradient(316.8deg, #5EC4DE, #847EDD);}
      89%{background: linear-gradient(320.4deg, #5EC4DE, #847EDD);}
      90%{background: linear-gradient(324deg, #5EC4DE, #847EDD);}
      91%{background: linear-gradient(327.6deg, #5EC4DE, #847EDD);}
      92%{background: linear-gradient(331.2deg, #5EC4DE, #847EDD);}
      93%{background: linear-gradient(334.8deg, #5EC4DE, #847EDD);}
      94%{background: linear-gradient(338.4deg, #5EC4DE, #847EDD);}
      95%{background: linear-gradient(342deg, #5EC4DE, #847EDD);}
      96%{background: linear-gradient(345.6deg, #5EC4DE, #847EDD);}
      97%{background: linear-gradient(349.2deg, #5EC4DE, #847EDD);}
      98%{background: linear-gradient(352.8deg, #5EC4DE, #847EDD);}
      99%{background: linear-gradient(356.4deg, #5EC4DE, #847EDD);}
      100%{background: linear-gradient(360deg, #5EC4DE, #847EDD);} 
      }
        animation-name: background_rotate;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        `,
    level === 2 && css`
    background: linear-gradient(0deg, #1CC8E1, #D5A04D);
    @keyframes background_rotate {      
    1%{background: linear-gradient(0.6deg, #1CC8E1, #D5A04D);}
      2%{background: linear-gradient(7.2deg, #1CC8E1, #D5A04D);}
      3%{background: linear-gradient(10.8deg, #1CC8E1, #D5A04D);}
      4%{background: linear-gradient(14.4deg, #1CC8E1, #D5A04D);}
      5%{background: linear-gradient(18deg, #1CC8E1, #D5A04D);}
      6%{background: linear-gradient(21.6deg, #1CC8E1, #D5A04D);}
      7%{background: linear-gradient(25.2deg, #1CC8E1, #D5A04D);}
      8%{background: linear-gradient(28.8deg, #1CC8E1, #D5A04D);}
      9%{background: linear-gradient(32.4deg, #1CC8E1, #D5A04D);}
      10%{background: linear-gradient(36deg, #1CC8E1, #D5A04D);}
      11%{background: linear-gradient(39.6deg, #1CC8E1, #D5A04D);}
      12%{background: linear-gradient(43.2deg, #1CC8E1, #D5A04D);}
      13%{background: linear-gradient(46.8deg, #1CC8E1, #D5A04D);}
      14%{background: linear-gradient(50.4deg, #1CC8E1, #D5A04D);}
      15%{background: linear-gradient(54deg, #1CC8E1, #D5A04D);}
      16%{background: linear-gradient(57.6deg, #1CC8E1, #D5A04D);}
      17%{background: linear-gradient(61.2deg, #1CC8E1, #D5A04D);}
      18%{background: linear-gradient(64.8deg, #1CC8E1, #D5A04D);}
      19%{background: linear-gradient(68.4deg, #1CC8E1, #D5A04D);}
      20%{background: linear-gradient(72deg, #1CC8E1, #D5A04D);}
      21%{background: linear-gradient(75.6deg, #1CC8E1, #D5A04D);}
      22%{background: linear-gradient(79.2deg, #1CC8E1, #D5A04D);}
      23%{background: linear-gradient(82.8deg, #1CC8E1, #D5A04D);}
      24%{background: linear-gradient(86.4deg, #1CC8E1, #D5A04D);}
      25%{background: linear-gradient(90deg, #1CC8E1, #D5A04D);}
      26%{background: linear-gradient(93.6deg, #1CC8E1, #D5A04D);}
      27%{background: linear-gradient(97.2deg, #1CC8E1, #D5A04D);}
      28%{background: linear-gradient(100.8deg, #1CC8E1, #D5A04D);}
      29%{background: linear-gradient(104.4deg, #1CC8E1, #D5A04D);}
      30%{background: linear-gradient(108deg, #1CC8E1, #D5A04D);}
      31%{background: linear-gradient(111.6deg, #1CC8E1, #D5A04D);}
      32%{background: linear-gradient(115.2deg, #1CC8E1, #D5A04D);}
      33%{background: linear-gradient(118.8deg, #1CC8E1, #D5A04D);}
      34%{background: linear-gradient(122.4deg, #1CC8E1, #D5A04D);}
      35%{background: linear-gradient(126deg, #1CC8E1, #D5A04D);}
      36%{background: linear-gradient(129.6deg, #1CC8E1, #D5A04D);}
      37%{background: linear-gradient(133.2deg, #1CC8E1, #D5A04D);}
      38%{background: linear-gradient(136.8deg, #1CC8E1, #D5A04D);}
      39%{background: linear-gradient(140.4deg, #1CC8E1, #D5A04D);}
      40%{background: linear-gradient(144deg, #1CC8E1, #D5A04D);}
      41%{background: linear-gradient(147.6deg, #1CC8E1, #D5A04D);}
      42%{background: linear-gradient(151.2deg, #1CC8E1, #D5A04D);}
      43%{background: linear-gradient(154.8deg, #1CC8E1, #D5A04D);}
      44%{background: linear-gradient(158.4deg, #1CC8E1, #D5A04D);}
      45%{background: linear-gradient(162deg, #1CC8E1, #D5A04D);}
      46%{background: linear-gradient(165.6deg, #1CC8E1, #D5A04D);}
      47%{background: linear-gradient(169.2deg, #1CC8E1, #D5A04D);}
      48%{background: linear-gradient(172.8deg, #1CC8E1, #D5A04D);}
      49%{background: linear-gradient(176.4deg, #1CC8E1, #D5A04D);}
      50%{background: linear-gradient(180deg, #1CC8E1, #D5A04D);}
      51%{background: linear-gradient(183.6deg, #1CC8E1, #D5A04D);}
      52%{background: linear-gradient(187.2deg, #1CC8E1, #D5A04D);}
      53%{background: linear-gradient(190.8deg, #1CC8E1, #D5A04D);}
      54%{background: linear-gradient(194.4deg, #1CC8E1, #D5A04D);}
      55%{background: linear-gradient(198deg, #1CC8E1, #D5A04D);}
      56%{background: linear-gradient(201.6deg, #1CC8E1, #D5A04D);}
      57%{background: linear-gradient(205.2deg, #1CC8E1, #D5A04D);}
      58%{background: linear-gradient(208.8deg, #1CC8E1, #D5A04D);}
      59%{background: linear-gradient(212.4deg, #1CC8E1, #D5A04D);}
      60%{background: linear-gradient(216deg, #1CC8E1, #D5A04D);}
      61%{background: linear-gradient(219.6deg, #1CC8E1, #D5A04D);}
      62%{background: linear-gradient(223.2deg, #1CC8E1, #D5A04D);}
      63%{background: linear-gradient(226.8deg, #1CC8E1, #D5A04D);}
      64%{background: linear-gradient(230.4deg, #1CC8E1, #D5A04D);}
      65%{background: linear-gradient(234deg, #1CC8E1, #D5A04D);}
      66%{background: linear-gradient(237.6deg, #1CC8E1, #D5A04D);}
      67%{background: linear-gradient(241.2deg, #1CC8E1, #D5A04D);}
      68%{background: linear-gradient(244.8deg, #1CC8E1, #D5A04D);}
      69%{background: linear-gradient(248.4deg, #1CC8E1, #D5A04D);}
      70%{background: linear-gradient(252deg, #1CC8E1, #D5A04D);}
      71%{background: linear-gradient(255.6deg, #1CC8E1, #D5A04D);}
      72%{background: linear-gradient(259.2deg, #1CC8E1, #D5A04D);}
      73%{background: linear-gradient(262.8deg, #1CC8E1, #D5A04D);}
      74%{background: linear-gradient(266.4deg, #1CC8E1, #D5A04D);}
      75%{background: linear-gradient(270deg, #1CC8E1, #D5A04D);}
      76%{background: linear-gradient(273.6deg, #1CC8E1, #D5A04D);}
      77%{background: linear-gradient(277.2deg, #1CC8E1, #D5A04D);}
      78%{background: linear-gradient(280.8deg, #1CC8E1, #D5A04D);}
      79%{background: linear-gradient(284.4deg, #1CC8E1, #D5A04D);}
      80%{background: linear-gradient(288deg, #1CC8E1, #D5A04D);}
      81%{background: linear-gradient(291.6deg, #1CC8E1, #D5A04D);}
      82%{background: linear-gradient(295.2deg, #1CC8E1, #D5A04D);}
      83%{background: linear-gradient(298.8deg, #1CC8E1, #D5A04D);}
      84%{background: linear-gradient(302.4deg, #1CC8E1, #D5A04D);}
      85%{background: linear-gradient(306deg, #1CC8E1, #D5A04D);}
      86%{background: linear-gradient(309.6deg, #1CC8E1, #D5A04D);}
      87%{background: linear-gradient(313.2deg, #1CC8E1, #D5A04D);}
      88%{background: linear-gradient(316.8deg, #1CC8E1, #D5A04D);}
      89%{background: linear-gradient(320.4deg, #1CC8E1, #D5A04D);}
      90%{background: linear-gradient(324deg, #1CC8E1, #D5A04D);}
      91%{background: linear-gradient(327.6deg, #1CC8E1, #D5A04D);}
      92%{background: linear-gradient(331.2deg, #1CC8E1, #D5A04D);}
      93%{background: linear-gradient(334.8deg, #1CC8E1, #D5A04D);}
      94%{background: linear-gradient(338.4deg, #1CC8E1, #D5A04D);}
      95%{background: linear-gradient(342deg, #1CC8E1, #D5A04D);}
      96%{background: linear-gradient(345.6deg, #1CC8E1, #D5A04D);}
      97%{background: linear-gradient(349.2deg, #1CC8E1, #D5A04D);}
      98%{background: linear-gradient(352.8deg, #1CC8E1, #D5A04D);}
      99%{background: linear-gradient(356.4deg, #1CC8E1, #D5A04D);}
      100%{background: linear-gradient(360deg, #1CC8E1, #D5A04D);} 
      }
        animation-name: background_rotate;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        `,
    level === 3 && css`
         background: linear-gradient(0deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);
         @keyframes background_rotate {      
         1%{background: linear-gradient(0.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           2%{background: linear-gradient(7.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           3%{background: linear-gradient(10.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           4%{background: linear-gradient(14.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           5%{background: linear-gradient(18deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           6%{background: linear-gradient(21.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           7%{background: linear-gradient(25.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           8%{background: linear-gradient(28.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           9%{background: linear-gradient(32.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           10%{background: linear-gradient(36deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           11%{background: linear-gradient(39.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           12%{background: linear-gradient(43.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           13%{background: linear-gradient(46.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           14%{background: linear-gradient(50.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           15%{background: linear-gradient(54deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           16%{background: linear-gradient(57.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           17%{background: linear-gradient(61.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           18%{background: linear-gradient(64.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           19%{background: linear-gradient(68.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           20%{background: linear-gradient(72deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           21%{background: linear-gradient(75.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           22%{background: linear-gradient(79.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           23%{background: linear-gradient(82.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           24%{background: linear-gradient(86.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           25%{background: linear-gradient(90deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           26%{background: linear-gradient(93.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           27%{background: linear-gradient(97.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           28%{background: linear-gradient(100.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           29%{background: linear-gradient(104.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           30%{background: linear-gradient(108deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           31%{background: linear-gradient(111.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           32%{background: linear-gradient(115.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           33%{background: linear-gradient(118.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           34%{background: linear-gradient(122.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           35%{background: linear-gradient(126deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           36%{background: linear-gradient(129.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           37%{background: linear-gradient(133.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           38%{background: linear-gradient(136.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           39%{background: linear-gradient(140.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           40%{background: linear-gradient(144deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           41%{background: linear-gradient(147.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           42%{background: linear-gradient(151.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           43%{background: linear-gradient(154.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           44%{background: linear-gradient(158.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           45%{background: linear-gradient(162deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           46%{background: linear-gradient(165.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           47%{background: linear-gradient(169.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           48%{background: linear-gradient(172.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           49%{background: linear-gradient(176.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           50%{background: linear-gradient(180deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           51%{background: linear-gradient(183.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           52%{background: linear-gradient(187.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           53%{background: linear-gradient(190.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           54%{background: linear-gradient(194.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           55%{background: linear-gradient(198deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           56%{background: linear-gradient(201.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           57%{background: linear-gradient(205.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           58%{background: linear-gradient(208.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           59%{background: linear-gradient(212.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           60%{background: linear-gradient(216deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           61%{background: linear-gradient(219.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           62%{background: linear-gradient(223.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           63%{background: linear-gradient(226.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           64%{background: linear-gradient(230.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           65%{background: linear-gradient(234deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           66%{background: linear-gradient(237.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           67%{background: linear-gradient(241.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           68%{background: linear-gradient(244.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           69%{background: linear-gradient(248.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           70%{background: linear-gradient(252deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           71%{background: linear-gradient(255.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           72%{background: linear-gradient(259.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           73%{background: linear-gradient(262.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           74%{background: linear-gradient(266.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           75%{background: linear-gradient(270deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           76%{background: linear-gradient(273.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           77%{background: linear-gradient(277.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           78%{background: linear-gradient(280.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           79%{background: linear-gradient(284.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           80%{background: linear-gradient(288deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           81%{background: linear-gradient(291.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           82%{background: linear-gradient(295.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           83%{background: linear-gradient(298.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           84%{background: linear-gradient(302.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           85%{background: linear-gradient(306deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           86%{background: linear-gradient(309.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           87%{background: linear-gradient(313.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           88%{background: linear-gradient(316.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           89%{background: linear-gradient(320.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           90%{background: linear-gradient(324deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           91%{background: linear-gradient(327.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           92%{background: linear-gradient(331.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           93%{background: linear-gradient(334.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           94%{background: linear-gradient(338.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           95%{background: linear-gradient(342deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           96%{background: linear-gradient(345.6deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           97%{background: linear-gradient(349.2deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           98%{background: linear-gradient(352.8deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           99%{background: linear-gradient(356.4deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);}
           100%{background: linear-gradient(360deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);} 
           }
             animation-name: background_rotate;
             animation-duration: 18s;
             animation-timing-function: linear;
             animation-iteration-count: infinite;
             `,
]);

const GradientBorder = ({ children, level }) => {
    const background = [background_flare_warmup, background_flare, background_flare, background_flare]

    return (
        <div
            tw='hidden xsm:block flex-col overflow-hidden w-full h-full'
        >
            <InnerContainer className='gradient-border-contents' level={level}>
                <BackgroundImage image={background[level]} tw='rounded-[12px] absolute inset-0 z-30 m-3' />
                {children}
            </InnerContainer>
        </div>
    );
};

export default GradientBorder;