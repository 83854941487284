import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    EVENT_BROWSER_CLOSED,
    EVENT_BROWSER_LOADED,
    EVENT_BROWSER_PAGE_HIDDEN,
    EVENT_BROWSER_PAGE_VISIBLE,
    EVENT_BROWSER_REFRESHED,
    EVENT_ROUTE_CHANGED,
    useEvents
} from 'context/EventProvider';
import { useChannel } from '@harelpls/use-pusher';
import { useUser } from '../state/User/UserState';

/*
	The Presence provider handles real time communications between the front end and the muzology-app-server,
	where specifically the muzology.presence package monitors user presence in real time

	This is enabled by:
		* hooking into route location history and sending an event each time location changes
		* providing a hook to allow components to indicate activity
 */


export const PresenceContext = React.createContext();

const PresenceProvider = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const postEvent = useEvents();
    const [lastLocation, setLastLocation] = useState(null);
    const user = useUser();

    const channel = useChannel(`user-${user.id}`);
    // const location = useLocation();

    // send route:changed events on location change
    useEffect(() => {
        const handleLocationChange = (location, action) => {
            // send changes to Sematext RUM
            // if (action !== 'REPLACE') {
            //     if (window['strum'])
            //         window['strum']('routeChange', window.location.href);
            // }

            // check if the route actually changed
            const path = location.pathname;
            if (path === lastLocation)
                return;
            setLastLocation(path);

            // post the route changed event
            const payload = { path, search: location.search, location: `${path}${location.search}` };
            postEvent(EVENT_ROUTE_CHANGED, payload);
        };

        if( location ) {
            handleLocationChange(location);
        }
        // subscribe to URL location changes & post an event
        // const unsubscribeFromHistory = history.listen(handleLocationChange);
        // unmount
        // return () => {
        //     // unsubscribe from route changes
        //     if (unsubscribeFromHistory) {
        //         unsubscribeFromHistory();
        //     }
        // };
    }, [location, lastLocation, setLastLocation, postEvent]);

    const handleClientEvent = useCallback((event) => {
        // post the route changed event
        let payload = { location: lastLocation, date: Date.now() / 1000.0 };
        postEvent(event, payload);
    }, [lastLocation, postEvent]);

    useEffect( () => {
        // check if the page has loaded before
        if( sessionStorage.getItem('page_has_loaded') ) {
            console.debug('[Browser] Page has just refreshed');
            handleClientEvent(EVENT_BROWSER_REFRESHED);
        } else {
            console.debug('[Browser] Page has loaded the first time');
            handleClientEvent(EVENT_BROWSER_LOADED);
        }
        // add a value to session storage to let us know when the page has been loaded the first time
        sessionStorage.setItem('page_has_loaded', 'yes');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // handle browser tab close
    useEffect(() => {

        const handleBeforeTabClosing = (event) => {
            console.log('[Browser Event] before unload');
            handleClientEvent(EVENT_BROWSER_CLOSED);
        };

        const handleVisibilityState = (event) => {
            console.log('[Browser Event] document visibility change:', document.visibilityState);
            handleClientEvent(document.visibilityState === 'visible' ? EVENT_BROWSER_PAGE_VISIBLE : EVENT_BROWSER_PAGE_HIDDEN);
        };

        // const handleUnload = () => {
        //     console.log('[Browser Event] unload');
        // };
        // const handlePageHide = (event) => {
        //     console.log('[Browser Event] page hide');
        // };
        // const handlePageShow = (event) => {
        //     console.log('[Browser Event] page show');
        // };

        document.addEventListener('visibilitychange', handleVisibilityState);
        window.addEventListener('beforeunload', handleBeforeTabClosing);
        // window.addEventListener('unload', handleUnload);
        // window.addEventListener('pagehide', handlePageHide);
        // window.addEventListener('pageshow', handlePageShow);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityState);
            window.removeEventListener('beforeunload', handleBeforeTabClosing);
            // window.removeEventListener('unload', handleUnload);
            // window.removeEventListener('pagehide', handlePageHide);
            // window.removeEventListener('pageshow', handlePageShow);
        };
    }, [handleClientEvent, postEvent]);

    const context = {};

    return <PresenceContext.Provider value={context}>{props.children}</PresenceContext.Provider>;
};

export const usePresence = () => {
    return useContext(PresenceContext);
};

export default PresenceProvider;
