import React, { useCallback } from 'react';
import { useMeasure } from 'react-use';
import tw from 'twin.macro';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { tsParticles } from 'tsparticles-engine';
import { confetti_silver, confetti_gold, confetti_platinum, shooting_stars } from './particles';

const particle_defs = [
    confetti_silver,
    confetti_gold,
    // shooting_stars
    confetti_platinum,
];


const ParticleBackground = ({ className, params, style, level }) => {
    const [ref, { width, height }] = useMeasure();
    console.log('particles:', params);

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);


    return (
        <div
            id='particles'
            ref={ref}
            className={`relative particle-background-container ${className || ''}`}
            data-testid='particles-background'
        >
            <Particles
                id='tsparticles'
                className='particle-background'
                init={particlesInit}
                loaded={particlesLoaded}
                options={particle_defs[level]}
            />
        </div>
    );
};

export default ParticleBackground;
