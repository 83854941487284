import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import tw, { css } from 'twin.macro';

import { LoadingOverlay } from 'lib/components/loading';
import { Button, Modal, QuitAssigningPlaylist } from 'components';

import { addMessage, selectDueDate, selectGoal, selectRecordLevel, selectStartDate, selectStudentsOrClass, usePlaylistAssignWizardState } from 'state/PlaylistAssignWizardState';
import { useClasses, useClassStudents } from 'state/Teacher/ClassState';
import { usePlaylistActions } from 'state/Teacher/TeacherProvider';
import { usePlaylistState } from 'state/PlaylistState';

import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { useAssignmentActions } from 'state/Student/AssignmentsState';
import Calendar from '../Calendar';
import { SelectStudents } from './steps/SelectStudents';
import { AddDetails } from './steps/AddDetails';
import { AssignmentDetails } from './steps/AssignmentDetails';
import { ErrorMessage } from './components/ErrorMessage';

const StepNode = ({ setCurrentStep, currentStep, step, index }) => {
    let nodeType = null;
    let text = null;
    let line = null;
    const isCurrent = currentStep === index;

    if (isCurrent) {
        nodeType = <div tw='w-4 h-4 rounded-full border[2px solid] border-white bg-PinkCrayola' />;
        text = <div tw='text-white mt-1 text-sm xsm:text-lg'>{step}</div>;
    } else if (index < currentStep) {
        // completed step
        nodeType = <div tw='w-4 h-4 rounded-full bg-white' />;
        text = <div tw='text-white mt-1 text-sm xsm:text-lg'>{step}</div>;
    } else {
        // incomplete step
        nodeType = <div tw='w-4 h-4 rounded-full bg-UnselectedGray' />;
        text = <div tw='text-UnselectedGray mt-1 text-sm xsm:text-lg'>{step}</div>;
    }

    if (index !== 0) {
        if (currentStep >= index) line = <div tw='border[2px solid] border-white bg-white absolute top[5px] left[calc(-50% + 20px)] right[calc(50% + 20px)]' />;
        else line = <div tw='border[2px solid] border-UnselectedGray absolute top[5px] left[calc(-50% + 20px)] right[calc(50% + 20px)]' />;
    }
    return (
        <div tw='cursor-pointer font-extrabold text-lg relative flex-1' onClick={() => setCurrentStep(index)}>
            <div tw='flex flex-col items-center'>
                {line}
                {nodeType}
                {text}
            </div>
        </div>
    );
};

const BottomProgressBar = ({ currentStep, back, next, saveAsDraft }) => {
    return (
        <div tw='rounded-b-xl bg-white  px-12  height[70px] flex justify-end items-center space-x-4' style={{ boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)' }}>
            {currentStep !== 0 && (
                <Button variant='secondary' onClick={back}>
                    Back
                </Button>
            )}

            <Button onClick={next} tw=''>
                {currentStep === 2 ? 'Assign' : 'Next'}
            </Button>
        </div>
    );
};

const ParentBottomProgressBar = ({ next }) => {
    const { students } = useClassStudents(null);
    let oneStudent = students.length === 1;

    return (
        <div
            tw='rounded-b-xl bg-white  px-2 xsm:px-12 h-[90px] xsm:h-[70px] flex items-center space-x-4'
            css={oneStudent ? tw`justify-around` : tw`justify-end`}
            style={{ boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)' }}
        >
            {oneStudent && (
                <div tw='text-DarkLiver mt-2 text-sm xsm:text-base'>Once you share, this playlist will appear in your child's Muzology account.</div>
            )}
            <Button onClick={next} tw=''>
                Share
            </Button>
        </div>
    );
};

const TopCopy = (props) => {
    const copyParts = [
        { title: 'Select Students', bottom: <><div>Who should receive this assignment?</div> <br /> <div tw='-mt-7'>(TIP: You can select entire classes and/or individual students.)</div></> },
        { title: 'Add Details', bottom: 'Set the dates, goal, and message for your assignment.' },
        { title: 'Confirm Assignment Details and Assign', bottom: '' }
    ];

    const currentCopy = copyParts[props.currentStep];

    return (
        <div tw='flex flex-col md:flex-row justify-between md:space-x-8 space-y-6 md:space-y-0 mt-2 text-center'>
            <div tw='w-full'>
                <h1 tw='text-2xl font-medium '>{currentCopy.title}</h1>
                <p tw='py-3 text-sm xsm:text-lg text-DarkLiver'>{currentCopy.bottom}</p>
            </div>
        </div>
    );
};

const PlaylistAssignWizard = (props) => {
    const classes = useClasses();
    const steps = ['Select Students', 'Add Details', 'Assign'];
    const [state, playlistAssignWizardDispatch, loadPlaylistDetails, saveAssignment] = usePlaylistAssignWizardState();
    const [playlistState, resetPlaylist] = usePlaylistState();
    const { fetchAssignments } = useAssignmentActions();

    const { deleteAssignment: deleteAssignmentAction } = usePlaylistActions();

    const [currentStep, setCurrentStep] = useState(0);
    const [saving, setSaving] = useState(false);
    const [errors, setErrors] = useState({});
    const [showQuitAssigning, setShowQuitAssigning] = useState(false);

    const resetState = useCallback(() => {
        setCurrentStep(0);
        setSaving(false);
        playlistAssignWizardDispatch({ type: 'reset state' });
    }, [playlistAssignWizardDispatch]);

    // load playlist wizard state if the playlist ID changes
    useEffect(() => {
        if (props.playlistId && props.playlistId !== state.playlistId) {
            console.log('*** Load playlist into assignment wizard', props.playlistId);
            loadPlaylistDetails(props.playlistId);
        }
    }, [loadPlaylistDetails, props, state]);

    // handle hiding the wizard - reset state on hide
    const setOpen = useCallback((open) => {
        if (props.setIsOpen) props.setIsOpen(open);
        if (!open) {
            // closing - reset the state
            setTimeout(resetState, 200);
        }
    }, [props, resetState]);

    // save the assignment
    const save = useCallback((publish) => {
        console.log('SAVING ASSIGNMENT: ', state, 'publish:', publish);
        setSaving(true);
        return saveAssignment(publish)
            .then((data) => {
                console.log('assignment saved:', data);
                setOpen(false);
            })
            .catch((err) => {
                // TODO: error message
                setSaving(false);
                console.error('error saving assignment: ', err);
                alert('There was an error saving the assignment, please try again');
            });
    }, [saveAssignment, setOpen, state]);

    /*
    useEffect(() => {
        // console.log('validate assignment');
        const e = {};

        // validate the details form
        // console.log('validate assignment:', state);

        // due date required
        if (!state.dueDate) {
            e['dueDate'] = 'Set Assignment Start and Due Date *';
        }

        // start date required
        if (!state.startDate) {
            e['startDate'] = 'Set Assignment Start and Due Date *';
        }

        // due date should be after start date
        if (state.startDate && state.dueDate && moment(state.startDate).isAfter(moment(state.dueDate), 'day')) {
            e['dueDate'] = 'The due date must be after the start date';
        }

        // start date should not be before today's date
        if (state.startDate && moment(state.startDate).isBefore(moment(), 'day')) {
            e['dueDate'] = 'The start date should not be in the past';
        }

        // set the errors
        setErrors(e);
    }, [state]);
    */

    const validateAssignment = useCallback(() => {
        console.log('validate assignment');
        const e = {};

        // validate the details form
        // console.log('validate assignment:', state);

        // due date required
        if (!state.dueDate) {
            e['dueDate'] = '* Set Assignment Start and Due Date';
        }

        // start date required
        if (!state.startDate) {
            e['startDate'] = '* Set Assignment Start and Due Date';
        }

        // due date should be after start date
        if (state.startDate && state.dueDate && moment(state.startDate).isAfter(moment(state.dueDate), 'day')) {
            e['dueDate'] = 'The due date must be after the start date';
        }

        // start date should not be before today's date
        if (state.startDate && moment(state.startDate).isBefore(moment(), 'day')) {
            e['startDate'] = 'The start date should not be in the past';
        }

        // set the errors
        setErrors(e);
        return !Object.values(e).length;
    }, [state]);

    const parentSubmit = useCallback(() => {
        // publish the assignment
        const publish = () => {
            return save(true);
        };

        if (props.parent) {
            // if no student is selected throw error
            const found = state.classes.find((cls) => cls.allStudentsSelected || cls.assignedStudents?.length > 0);
            if (!found) {
                setErrors((e) => ({ ...e, child: 'Please select a child' }));
                return false;
            } else {
                setErrors((e) => {
                    delete e['child'];
                    return e;
                });
                publish()
                    .then((data) => {
                        if (props.setIsOpen) props.setIsOpen(false);
                        fetchAssignments();
                    })
                    .catch((err) => {
                        console.error('error saving assignment: ', err);
                    });
                return false;
            }
        }
    }, [props, save, state.classes, fetchAssignments]);

    // next step
    const next = useCallback(() => {
        console.log('current step', currentStep);

        // select students
        if (currentStep === 0) {
            // check if any students have been assigned
            console.log(state);
            // find the first class that is selected or has student selected
            const found = state.classes.find((cls) => cls.allStudentsSelected || cls.assignedStudents?.length > 0);
            if (!found) {
                setErrors((e) => ({ ...e, classes: 'Please select a class or student to assign to' }));
                return false;
            }
            // remove the classes error message
            setErrors((e) => {
                delete e['classes'];
                return e;
            });
        }

        // assignment details
        if (currentStep === 1) {
            let valid = true;

            // due date required
            if (!state.dueDate) {
                setErrors((e) => ({ ...e, dueDate: '* Set Assignment Due Date' }));
                valid = false;
            }

            // due date should not be before today's date
            if (state.dueDate && moment(state.dueDate).isBefore(moment(), 'day')) {
                setErrors((e) => ({ ...e, dueDate: '* The due date should not be in the past' }));
                valid = false;
            }

            // start date required
            // if (!state.startDate) {
            //     e['startDate'] = 'Set Assignment Start and Due Date *';
            // }

            // due date should be after start date
            // if (state.startDate && state.dueDate && moment(state.startDate).isAfter(moment(state.dueDate), 'day')) {
            //     e['dueDate'] = 'The due date must be after the start date';
            // }

            // start date should not be before today's date
            if (state.startDate && moment(state.startDate).isBefore(moment(), 'day')) {
                setErrors((e) => ({ ...e, dueDate: '* The start date should not be in the past' }));
                valid = false;
            }

            console.log('assignment step 2 valid:', valid);
            if (!valid) return false;
        }

        // publish the assignment
        const publish = () => {
            return save(true);
        };

        // confirm & publish
        if (currentStep === 2) {
            publish()
                .then((data) => {
                    if (props.setIsOpen) props.setIsOpen(false);
                })
                .catch((err) => {
                    console.error('error saving assignment: ', err);
                });
            return false;
        }

        // advance to the next step
        setCurrentStep((current) => current + 1);
    }, [currentStep, state, save, props]);

    // previous step
    const back = () => {
        if (currentStep !== 0) {
            setCurrentStep((prevState) => {
                return prevState - 1;
            });
        }
    };

    // progress nodes
    const stepNodes = steps.map((step, index) => {
        return <StepNode
            key={index} index={index} step={step} currentStep={currentStep}
            setCurrentStep={setCurrentStep} />;
    });

    const parentTitle = <div tw='text-white text-center font-extrabold text-xl tiny:text-2xl'>Share Playlist With Your Child</div>;

    // save as a draft
    const saveAsDraft = () => {
        return save(false);
    };

    const stepContents = useMemo(() => {
        if (props.parent) {
            return (
                <ParentPlaylist
                    playlistDetails={state}
                    playlist={props.playlist}
                    setMessage={(message) => addMessage(playlistAssignWizardDispatch, message)}
                    errors={errors}
                    cls={classes[0]}
                    playlistAssignWizardDispatch={playlistAssignWizardDispatch}
                    setDueDate={(date) => {
                        selectDueDate(playlistAssignWizardDispatch, date);
                        // validateAssignment();
                    }}
                    setStartDate={(date) => {
                        selectStartDate(playlistAssignWizardDispatch, date);
                        // validateAssignment();
                    }}
                    assignment={state}
                />
            );
        }
        if (currentStep === 0) {
            return (
                <SelectStudents
                    playlistDetails={state}
                    playlistAssignWizardDispatch={playlistAssignWizardDispatch}
                    classes={classes}
                    currentStep={currentStep}
                    errors={errors}
                />
            );
        } else if (currentStep === 1) {
            return (
                <AddDetails
                    playlistDetails={state}
                    setGoalScore={(score) => selectGoal(playlistAssignWizardDispatch, score)}
                    setGoalLevel={(level) => selectRecordLevel(playlistAssignWizardDispatch, level)}
                    setStartDate={(date) => {
                        selectStartDate(playlistAssignWizardDispatch, date);
                        // validateAssignment();
                    }}
                    setDueDate={(date) => {
                        selectDueDate(playlistAssignWizardDispatch, date);
                        // validateAssignment();
                    }}
                    setMessage={(message) => addMessage(playlistAssignWizardDispatch, message)}
                    errors={errors}
                />
            );
        } else if (currentStep === 2) {
            return (
                <AssignmentDetails
                    assignment={state}
                    playlist={props.playlist}
                    setCurrentStep={setCurrentStep}
                    saveAsDraft={() => save(false)}
                    setIsOpen={props.setIsOpen}
                />
            );
        }
    }, [props.parent, props.playlist, props.setIsOpen, currentStep, state, errors, classes, playlistAssignWizardDispatch, save]);


    return (
        <>
            <Modal modalChildrenStyle={tw`w-full tiny:w-[761px]  lg:w-[961px] mx-auto`} isOpen={props.isOpen}>
                <div tw='m-1 xsm:m-6'>
                    <LoadingOverlay show={saving} fixed={true} />
                    <div
                        tw='height[74px] flex justify-center items-center relative rounded-tl-xl rounded-tr-xl'
                        css={
                            props.parent
                                ? tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)]`
                                : tw`bg-gradient-to-r to-PholxPink from-SkyBlueCrayola`
                        }
                    >
                        <div tw='w-full flex justify-between items-center maxWidth[615px]' css={[props.parent && tw`justify-center`]}>
                            {props.parent ? parentTitle : stepNodes}
                        </div>

                        <button
                            tabIndex={10}
                            onClick={() => {
                                setShowQuitAssigning(true);
                                // reset the step to 0
                                // setCurrentStep(0);
                                // close the modal
                                // if (props.setIsOpen) props.setIsOpen(false);
                                // if (props.onFinished) props.onFinished();
                                // reset anything in the playlist state
                                // reset anything in playlist wizard state
                                // resetState(playlistAssignWizardDispatch);
                            }}
                            tw='bg-white rounded-full cursor-pointer absolute  w-8 xsm:w-10 h-8 xsm:h-10 flex justify-center items-center '
                            css={[
                                props.parent
                                    ? tw`right-0 tiny:-right-1 xsm:-right-3 top-1 tiny:-top-1 xsm:-top-3`
                                    : tw`right-0 tiny:-right-1 xsm:-right-3 -top-1.5 tiny:-top-3 `
                            ]}
                        >
                            <SvgWhiteXButton tw='w-4 h-4' />
                        </button>
                    </div>

                    <div
                        tw='bg-white flex-col overflow-y-auto'
                        css={[
                            props.parent
                                ? css`
                                    height: 100%;
                                `
                                : css`
                                    height: 675px;
                                    @media (max-height: 900px) {
                                        height: 600px;
                                    }

                                    @media (max-height: 768px) {
                                        height: 440px;
                                    }
                                    @media (max-height: 610px) {
                                        height: 400px;
                                    }
                                    box-shadow: 12px 0px 10px 0px rgb(0 0 0 / 10%);
                                `
                        ]}
                    >
                        <div tw='p-4 md:p-6 '>
                            {!props.parent && <TopCopy currentStep={currentStep} />}
                            {stepContents}
                        </div>
                    </div>
                    {props.parent ? (
                        <ParentBottomProgressBar next={parentSubmit} />
                    ) : (
                        <BottomProgressBar currentStep={currentStep} back={back} next={next} saveAsDraft={saveAsDraft} />
                    )}
                </div>
            </Modal>
            <QuitAssigningPlaylist
                isOpen={showQuitAssigning}
                parent={props.parent}
                setIsOpen={setShowQuitAssigning}
                playlist={props.playlist}
                onCancel={() => {
                    setCurrentStep(0);
                    resetState(playlistAssignWizardDispatch);
                    setShowQuitAssigning(false);
                    props.setIsOpen(false);
                }}
                onSaveDraft={() => {
                    saveAsDraft();
                    setShowQuitAssigning(false);
                    props.setIsOpen(false);
                }}
                onContinue={() => setShowQuitAssigning(false)}
                onQuitParent={() => {
                    setShowQuitAssigning(false);
                    // resetState(playlistAssignWizardDispatch);
                    // resetPlaylist()
                    deleteAssignmentAction(playlistState.playlist.id);
                    props.setIsOpen(false);
                }}
            />
        </>
    );
};

const CheckBox = (props) => {
    return (
        <div tw='w-8 h-8 rounded-lg border-MuzologyLightBlue  border-2 flex  items-center justify-center cursor-pointer' {...props}>
            {props.selected && <div tw='w-5 h-5 rounded-md bg-MuzologyLightBlue' />}
        </div>
    );
};

const ParentPlaylist = ({ cls, playlistDetails, setMessage, errors, setDueDate, setStartDate, playlistAssignWizardDispatch, assignment, playlist }) => {
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [classSelected, setClassSelected] = useState(false);
    const { students } = useClassStudents(null);
    let oneStudent = students.length === 1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setStartDate(moment().toDate()), []);

    useEffect(() => {
        if (assignment) {
            // console.log('Assignment:', assignment);
            // console.log('Class:', cls);

            // get the state for this class
            const classState = assignment.classes?.find(({ classId }) => classId === cls.id);
            if (classState) {
                // class found
                // console.log('current class state', classState);
                // set initial state
                const assigned = classState.allStudentsSelected ?? false;
                setClassSelected(assigned);
                let selectedIds = [];
                if (!assigned) {
                    selectedIds = classState.assignedStudents ?? [];
                }
                setSelectedStudentIds(selectedIds);
            }
        }
    }, [assignment, cls?.id]);

    useEffect(() => {
        if (selectedStudentIds) {
            selectStudentsOrClass(playlistAssignWizardDispatch, {
                classId: cls.id,
                classIcon: cls.display_icon,
                name: cls.title,
                assignedStudents: selectedStudentIds ?? [],
                students: students
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudentIds]);

    const setSelectedStudent = (studentId, selected) => {
        if (classSelected && !selected) {
            // select all students
            let studentIds = cls.student_ids ?? cls.students ?? [];

            // unselect this student
            studentIds = studentIds.filter((id) => id !== studentId);

            // select the students & unselect the class
            setSelectedStudentIds(studentIds);
            setClassSelected(false);
        } else if (!classSelected && selected) {
            const classSize = (cls.student_ids ?? cls.students ?? []).length;

            // check if this student already exists
            if (!selectedStudentIds.find((id) => id === studentId)) {
                // student not found, see if this student would complete the class
                if (classSize > 0 && classSize === selectedStudentIds.length + 1) {
                    setClassSelected(true);
                    setSelectedStudentIds([]);
                } else {
                    // add the student
                    setSelectedStudentIds((oldArray) => [...oldArray, studentId]);
                }
            }
        } else {
            // set or unset the student id
            if (selected) {
                // add the student id
                setSelectedStudentIds((oldArray) => [...oldArray, studentId]);
            } else {
                // remove the student id
                setSelectedStudentIds((oldArray) => oldArray.filter((id) => id !== studentId));
            }
        }
    };

    useEffect(() => {
        if (oneStudent) setSelectedStudentIds([students[0].user.id]);
    }, [oneStudent, students]);

    return (
        <div tw='w-full flex flex-col  justify-between space-y-6 md:space-y-0 text-center'>
            <div>
                <h1 tw='text-lg font-extrabold text-EarieBlack mb-2'>Playlist Name: {playlist?.name} </h1>
                <div tw='gap-2 my-4 flex flex-wrap justify-center max-w-2xl mx-auto'>
                    {playlist?.selectedVideos?.map((video) => {
                        return (
                            <div key={video.id} tw='maxWidth[200px]'>
                                <img src={video.videoThumbnail} tw='max-w-full w-full' alt='thumbnail' />
                                <div tw='text-left bg-GainsboroGrey text-EarieBlack p-2 text-sm'>{video.videoName}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {!oneStudent && (
                <>
                    <span style={{ width: '100%', display: 'block', borderTop: '2px solid #C7C7FF', height: '1px', margin: '10px 0' }} />
                    <div
                        tw='bg-white flex flex-wrap xsm:flex-nowrap justify-start xsm:justify-center items-center mx-auto w-[380px] xsm:w-full'
                        style={{ marginTop: '1.5rem' }}
                    >
                        <div tw='text-lg font-extrabold text-EarieBlack mr-0 xsm:mr-3  mt-2 xsm:mt-0'>Share With</div>
                        {students.map((student, index) => {
                            const userId = student.user.id;
                            const hasStudentBeenSelected = selectedStudentIds.find((id) => id === student.user.id);
                            return (
                                <div key={userId} tw='flex items-center  mt-2 xsm:mt-0'>
                                    <CheckBox
                                        tw='mx-2'
                                        onClick={() => setSelectedStudent(userId, !hasStudentBeenSelected)}
                                        // onClick={() => (hasStudentBeenSelected ? setSelectedStudentIds((oldArray) => oldArray.filter((id) => id !== userId)) : setSelectedStudentIds((oldArray) => [...oldArray, userId]))}
                                        selected={hasStudentBeenSelected}
                                    />
                                    <p tw='mr-1.5 '>{student.user.first_name}</p>
                                </div>
                            );
                        })}
                    </div>
                    {errors['child'] && (
                        <div>
                            <ErrorMessage style={{ marginLeft: '0' }}>*&nbsp;{errors['child']}</ErrorMessage>
                        </div>
                    )}

                    <div>
                        <div tw='text-DarkLiver mt-2 mb-6'>Once you share, this playlist will appear in your child's Muzology account.</div>
                    </div>
                </>
            )}

            <div>
                <span style={{ width: '100%', display: 'block', borderTop: '2px solid #C7C7FF', height: '1px', margin: '10px 0' }} />
                <div tw='space-y-2 my-4 flex flex-col justify-center mx-auto'>
                    <div tw='flex flex-col items-center mt-4'>
                        <label tw='text-DarkLiver text-lg font-extrabold mb-4'>
                            When do you want your child to learn this by? <span tw='font-normal text-DarkLiver' />
                        </label>
                        <Calendar
                            date={playlistDetails?.dueDate ? moment(playlistDetails?.dueDate) : null}
                            onChange={(date) => setDueDate(date._i)}
                            placeholder={true}
                            borderError={errors.dueDate === 'The due date must be after the start date'}
                        />
                    </div>
                </div>
            </div>
            <div>
                <span style={{ width: '100%', display: 'block', borderTop: '2px solid #C7C7FF', height: '1px', margin: '10px 0' }} />

                <h1 tw='text-lg font-extrabold text-EarieBlack mt-6'>
                    Include a message for your child <span tw='font-normal text-DarkLiver' />
                </h1>
                <textarea
                    name='message'
                    value={playlistDetails.message}
                    onChange={(data) => setMessage(data.target.value)}
                    tw='w-3/4 h-16 border-LavenderBlue border-2 rounded-md text-left p-4 mt-2'
                />
            </div>
        </div>
    );
};

export default PlaylistAssignWizard;
