import React from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import moment from 'moment';
import { AchievementItem } from 'components';
import { useMedia } from 'react-use';
import AchievementConstants from '../../../../constants/AchievementConstants';

const ContentContainer = styled.div`
    font-family: Avenir, serif;
    font-weight: 500;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;

    .header-content {
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;

        .header {
            color: #fff;
            font-weight: 800;
            font-size: 24px;
        }

        // Style for "Video Achi"
    }
`;

const NoAchievementsMessage = styled.div`
    background: rgba(117, 105, 214, 0.5);
    text-align: left;
    margin-top: 17px;
    border-radius: 20px;
    padding: 27px 40px;
    font-family: Avenir, serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
`;

const VideoAchievementContent = (props) => {
    const isTablet = useMedia('(max-width: 768px)');

    // get all the achievements
    let achievements = [];
    if (props.achievements) {
        achievements = props.achievements.map((badge) => {
            return {
                id: badge.id,
                type: badge.badge_type,
                title: badge.badge_type,
                description: badge.description,
                date: moment(badge.datetime_awarded)
            };
        });
    }

    // sort achievements by date
    achievements = achievements.sort((a, b) => a.date.diff(b.date));
    let height = isTablet ? '123px' : '161px';
    let width = isTablet ? '107px' : '161px';
    // create a node for each achievement
    let nodes = achievements.map((achievement, index) => {
        return (
            <AchievementItem
                height={height}
                width={width}
                achievement={achievement}
                AchievementConstants={AchievementConstants}
                hoverBoxBackground='#2A264D'
            />
        );
    });

    let achievementItems = <div tw='flex flex-wrap mt-8 marginLeft[-2.5rem] xsm:ml-0'>{nodes}</div>;
    const title = props.parent ? 'Video Achievements' : 'Your Video Achievements ';
    if (!nodes || !nodes.length) {
        if (props.parent) {
            achievementItems = (
                <NoAchievementsMessage>As your child progresses through Muzology, they will earn Achievements (which you can see here).</NoAchievementsMessage>
            );
        } else
            achievementItems = (
                <NoAchievementsMessage>Earn Achievements by watching videos and taking Challenges. Remember the more you watch, the more you learn!</NoAchievementsMessage>
            );
    }
    return (
        <ContentContainer>
            <div className='header-content'>
                <h2 className='header'>{title}</h2>
            </div>
            {achievementItems}
        </ContentContainer>
    );
};

export default VideoAchievementContent;
