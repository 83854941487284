import React from 'react';
import tw from 'twin.macro';


const FooterContainer = tw.footer`
    relative
    bg-[#342974] 
    flex justify-center items-center

    text-[#aaa] 
    text-[18px] 
    text-center 

    py-5
`;


const Footer = ({ className, hide }) => {
    const copyright = `Copyright © ${new Date().getFullYear()} Muzology, LLC. All rights reserved.`;
    if( hide )
        return null;
    return (
        <FooterContainer className={className}>
            <div className='footer-message'>{copyright}</div>
        </FooterContainer>
    );
};

export default Footer;
