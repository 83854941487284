import React from 'react';

const SvgCheckmark = (props) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 42 32'
        {...props}>
        <path
            fill='currentColor'
            d='M.879 15.22c1.207-1.207 3.623-1.207 4.83 0l10.266 10.267c1.208 1.207 1.208 3.623 0 4.83-1.207 1.208-3.623 1.208-4.83 0L.878 20.052c-1.208-1.208-1.208-3.624 0-4.831z'
        />
        <path
            fill='currentColor'
            d='M40.266 1.179c1.208 1.208 1.208 3.623 0 4.831L15.892 30.384c-1.208 1.208-3.623 1.208-4.831 0-1.208-1.208-1.208-3.623 0-4.831L35.435 1.179c1.208-1.208 3.623-1.208 4.831 0z'
        />
    </svg>
);

export default SvgCheckmark;
