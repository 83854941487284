import * as React from 'react';

function SvgClassIcon18(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#BE6CFF' />
            <path
                d='M28.507 18.082C30.634 21.312 25.317 31 23.19 31c-2.127 0-2.127-1.076-3.19-1.076-1.063 0-1.063 1.076-3.19 1.076s-7.444-9.689-5.317-12.918c2.127-3.23 5.317-3.23 7.444-2.153v-3.23c-5.977 3.305-7.327-1.313-7.327-1.313s2.828-3.865 7.327 1.313v-2.153h2.126v5.383c2.127-1.077 5.317-1.077 7.444 2.153z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon18;
