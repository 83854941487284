import React, { useState } from 'react';
import heart from './heart.png'
import BackgroundImage from 'lib/components/BackgroundImage';
import tw, { css, styled } from 'twin.macro';
import { motion, AnimatePresence } from "framer-motion"
import ReactHoverObserver from 'react-hover-observer';

const LargeCircle = styled(motion.div)(({ color }) => [
    tw`w-6 lg:w-8 h-6 lg:h-8 rounded-full`,
    color === 'blue-gradient' && css`background: linear-gradient(46deg, #6E60E6 17.15%, #1CC8E1 87.5%);`,
    color === 'yellow-gradient' && css`background: linear-gradient(223deg, #FADF58 16.34%, #D36B18 100%);`,
    color === 'purple-gradient' && css`background: linear-gradient(217deg, #C050FC 3.71%, #6B57DC 93.58%);`,
]);

const MediumCircle = styled(motion.div)(({ color }) => [
    tw`w-4 lg:w-5 h-4 lg:h-5 rounded-full`,
    color === 'pink-gradient' && css`background: linear-gradient(223deg, #FFF 16.34%, #E6409B 72.11%);`,

]);

const SmallCircle = styled(motion.div)(({ color }) => [
    tw`w-1.5 lg:w-2 h-1.5 lg:h-2 rounded-full`,
    color === 'blue' && css`background: #46A6F8;`,
    color === 'green' && css`background: #02AC6F;`,
]);

const HeartMotionContainer = () => {
    const [isHovering, setIsHovering] = useState(false);
    return (
        // animation WIP
        // <ReactHoverObserver
        //     onHoverChanged={() => setIsHovering(true)}
        //     onMouseOver={() => setIsHovering(true)}
        // // onMouseLeave={() => setIsHovering(false)}
        // >
        //     <div tw='rounded-lg h-[240px] lg:h-[300px] w-[300px] lg:w-[360px] flex items-center justify-center relative' style={{ background: 'linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%)' }}>
        //         <BackgroundImage image={heart} />
        //         <AnimatePresence>
        //             <div tw='absolute -left-4 top-[40%] rounded-full'>
        //                 {!isHovering && <LargeCircle tw='absolute top-[3%]' color='blue-gradient' />}
        //                 {isHovering && (
        //                     <LargeCircle
        //                         color='blue-gradient'
        //                         key="blue-gradient"
        //                         animate={{ x: 280, y: -120, }}
        //                         transition={{ type: 'tween', duration: 7, }}

        //                     />
        //                 )}
        //             </div>
        //             <div tw='absolute -right-1 top-[40%] rounded-full'>
        //                 {!isHovering && <LargeCircle tw='absolute top-[3%]' color='yellow-gradient' />}
        //                 {isHovering && (
        //                     <LargeCircle
        //                         color='yellow-gradient'
        //                         key="yellow-gradient"
        //                         animate={{ x: -120, y: 180, }}
        //                         transition={{ type: 'tween', duration: 7, }}
        //                     />
        //                 )}
        //             </div>
        //             <div tw='absolute -right-20 bottom-[10%] rounded-full'>

        //                 {isHovering && (
        //                     <MediumCircle
        //                         color='pink-gradient'
        //                         key="pink-gradient"
        //                         animate={{ x: -50, y: -150, }}
        //                         transition={{ type: 'tween', duration: 7, ease:'easeIn'}}
        //                     />
        //                 )}
        //             </div>
        //         </AnimatePresence>
        //     </div>
        // </ReactHoverObserver>

        <div tw='rounded-lg h-[240px] lg:h-[300px] w-[300px] lg:w-[360px] flex items-center justify-center relative' style={{ background: 'linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%)' }}>
            <BackgroundImage image={heart} />
            <div tw='absolute -right-1.5 bottom-[40%] rounded-full'>
                <LargeCircle tw='absolute top-[3%]' color='yellow-gradient' />
            </div>
            <div tw='absolute -right-2 top-[38%] rounded-full'>
                <SmallCircle tw='absolute top-[3%]' color='green' />
            </div>
            <div tw='absolute -right-0.5 top-[6%] rounded-full'>
                <SmallCircle tw='absolute top-[3%]' color='blue' />
            </div>
            <div tw='absolute -left-4 top-[40%] rounded-full'>
                <LargeCircle tw='absolute top-[3%]' color='blue-gradient' />
            </div>
            <div tw='absolute right-14 -top-3.5 rounded-full'>
                <LargeCircle tw='absolute top-[3%]' color='purple-gradient' />
            </div>
            <div tw='absolute left-10 -bottom-1 rounded-full'>
                <MediumCircle tw='absolute top-[3%]' color='pink-gradient' />
            </div>
        </div>
    );
};

export default HeartMotionContainer;