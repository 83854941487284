import React from 'react';
import tw, { css } from 'twin.macro';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { useFlexLayout, useSortBy, useTable } from 'react-table';

function SortArrows(props) {
    return (
        <svg
            width={10} height={16} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.826 0l4.18 6.75H.646L4.827 0zM4.828 16L.648 9.25h8.36L4.828 16z' fill='#fff' />
        </svg>
    );
}

function SortArrowDown(props) {
    return (
        <svg
            width={10} height={7} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.828 7L.648.25h8.36L4.828 7z' fill='#fff' />
        </svg>
    );
}

function SortArrowUp(props) {
    return (
        <svg
            width={10} height={7} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.826 0l4.18 6.75H.646L4.827 0z' fill='#fff' />
        </svg>
    );
}

function SortArrowsDTC(props) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none'>
            <path fill='#BCB5CD' d='m4.827 0 4.18 6.75H.647L4.827 0ZM4.826 16 .646 9.25h8.36L4.826 16Z' />
        </svg>
    );
}

function SortArrowDownDTC(props) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'>
            <path fill='#BCB5CD' d='M4.826 7 .646.25h8.36L4.826 7Z' />
        </svg>
    );
}

function SortArrowUpDTC(props) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'>
            <path fill='#BCB5CD' d='m4.827 0 4.18 6.75H.647L4.827 0Z' />
        </svg>
    );
}

const Table = ({ headerStyle, rowStyle, cellStyle, columns, data, isSortable, dtc, ...rest }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data
        },
        useFlexLayout,
        isSortable && useSortBy
    );

    return (
        <div {...getTableProps()} className='table' {...rest} tw='overflow-visible'>
            <div tw='hidden md:block z-20' style={{ position: 'sticky', top: '0' }}>
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <button
                                tabIndex={0}
                                css={[headerStyle]}
                                {...column.getHeaderProps(isSortable && column.getSortByToggleProps())}
                                className='th'
                                tw='flex items-center justify-center pb-5'
                            >
                                {column.render('Header')}
                                {isSortable && (
                                    dtc ?
                                        <span tw='ml-2'>{column.isSorted ? (column.isSortedDesc ? <SortArrowDownDTC /> : <SortArrowUpDTC />) : <SortArrowsDTC />}</span> :
                                        <span tw='ml-2'>{column.isSorted ? (column.isSortedDesc ? <SortArrowDown /> : <SortArrowUp />) : <SortArrows />}</span>
                                )}
                            </button>
                        ))}
                    </div>
                ))}
            </div>

            <div {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()} css={[rowStyle]} className='tr'>
                            {row.cells.map((cell) => {
                                return (

                                    <div css={[cellStyle]} {...cell.getCellProps()} className='td'>
                                        {cell.render('Cell')}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Table.propTypes = {
    isSortable: PropTypes.bool,
    headerStyle: PropTypes.object,
    rowStyle: PropTypes.object,
    cellStyle: PropTypes.object,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default Table;
