import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStudents } from '../../../state/Teacher/ClassState';

const SetupContext = createContext();

export const useCreateChild = () => {
    return useContext(SetupContext);
};

export const CreateChildProvider = ({ children, setup, selectedChild }) => {
    const [step, setStep] = useState(1);
    const [childCount, setChildCount] = useState(0);
    const [childIndex, setChildIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [childData, setChildData] = useState({});
    console.log('CreateChildProvider: setup = ', setup, ', selectedChild = ', selectedChild);
    const students = useStudents();

    // Load the saved step from local storage
    useEffect(() => {
        if (setup) {
            if (selectedChild) {
                setStep(2); // if a child is selected, go to step 2
            } else {
                setStep(1); // default first step
            }
            setChildCount(1); // we are only going to setup one child
            setLoaded(true); // finished loading
            saveChildIndex(students.length);
        } else {
            const savedCount = window.localStorage.getItem('setupChildCount');
            console.log('loaded setupChildCount = ', savedCount);
            console.log('current student count = ', students.length);
            if (savedCount) {
                setChildCount(Number(savedCount));
            }
            const savedChild = window.localStorage.getItem('setupChildIndex');
            console.log('loaded setupChildIndex = ', savedChild);
            if (savedChild) {
                setChildIndex(Number(savedChild));
            }
            const savedStep = window.localStorage.getItem('setupStep');
            if (savedStep) {
                setStep(Number(savedStep));
            }
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // set the step and save it
    const saveStep = (newStep) => {
        setStep(newStep);
        localStorage.setItem('setupStep', newStep);
    };

    const saveChildIndex = (index) => {
        setChildIndex(index);
        localStorage.setItem('setupChildIndex', index);
    };

    const saveChildCount = (count) => {
        setChildCount(count);
        console.log('setupChildCount =', count);
        localStorage.setItem('setupChildCount', count);
    };

    const resetSetup = () => {
        setStep(1);
        localStorage.removeItem('setupChildIndex');
        localStorage.removeItem('setupChildCount');
        localStorage.removeItem('setupStep');
    };

    const value = {
        childCount,
        childIndex,
        step,
        childData,
        setChildData,
        saveStep,
        saveChildCount,
        saveChildIndex,
        resetSetup,
        isSetup: setup,
        selectedChild: selectedChild
    };
    if (!loaded)
        return null;
    return <SetupContext.Provider value={value}>{children}</SetupContext.Provider>;
};
