import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { useActivity } from '../context/LoadingContext';
import { useDevTool } from '../lib/DevTool/DevTool';
import { apiClient, apiFetcher } from '../utils/api-client';

export const ADD_LESSONS = 'ADD_LESSONS';
export const ADD_VIDEOS = 'ADD_VIDEOS';
export const ADD_PLAYLISTS = 'ADD_PLAYLISTS';
export const ADD_COURSES = 'ADD_COURSES';
export const SET_LIBRARY = 'SET_LIBRARY';
export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';

const StoreContext = React.createContext(undefined);
const DispatchContext = React.createContext(undefined);

const initialState = {
    lessons: {},
    videos: {},
    courses: {},
    course_library: {},
    playlists: {},
    loading: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case ADD_COURSES:
            return { ...state, courses: { ...state.courses, ...action.payload } };

        case ADD_PLAYLISTS:
            return { ...state, playlists: { ...state.playlists, ...action.payload } };

        case ADD_LESSONS:
            return { ...state, lessons: { ...state.lessons, ...action.payload } };

        case ADD_VIDEOS:
            return { ...state, videos: { ...state.videos, ...action.payload } };

        case SET_LIBRARY:
            state = { ...state, course_library: action.payload };
            console.log('state', state);
            return state;

        case ADD_LOADING:
            return { ...state, loading: [...state.loading, action.payload] };

        case REMOVE_LOADING:
            return { ...state, loading: [...state.loading.filter(item => item !== action.payload)] };

        default:
            return state;
    }
};

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

const mapById = (array) => {
    return array.reduce((map, obj) => ({ ...map, [obj.id]: obj }), {});
};

const CatalogState = ({ children }) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    const [endActivity] = useActivity(2);
    useDevTool('CatalogState', store);

    // fetch all our courses
    const fetchCourses = async (courseId = null) => {
        // console.log('<CatalogState> fetchCourses: ', courseId);
        const courses = await apiFetcher('/api/courses/');
        if (courses) {
            addCourses(courses);
        }
        return courses;
    };

    // fetch a single course
    const fetchCourse = async (courseId) => {
        const course = await apiFetcher(`/api/courses/${courseId}/`);
        if (course) {
            addCourses([course]);
        }
        return course;
    };

    // add courses to the store
    const addCourses = (courses) => {
        if (courses) {
            const payload = mapById(courses);
            dispatch({ type: ADD_COURSES, payload });
        }
    };

    // add lessons to the store
    const addLessons = (lessons) => {
        if (lessons) {
            const payload = mapById(lessons);
            dispatch({ type: ADD_LESSONS, payload });
        }
    };

    // add videos to the store
    const addVideos = (videos) => {
        if (videos) {
            const payload = mapById(videos);
            dispatch({ type: ADD_VIDEOS, payload });
        }
    };

    // add course library to the store
    // const addLibrary = (lib) => {
    //     if (lib) {
    //         // const payload = mapById(videos);
    //         dispatch({ type: SET_LIBRARY, lib });
    //     }
    // };

    // load the initial store with courses, lessons, and videos
    useEffect(() => {
        // console.log('<CatalogState> mounted - loading courses & playlists');

        fetchCourses()
            .then(courses => {
                // endActivity();
                console.debug('[CatalogState] courses loaded', courses);
                courses?.forEach(course => {
                    // load the playlists
                    course.playlists?.forEach(playlist_id => {
                        apiFetcher(`/api/playlists/${playlist_id}/`)
                            .then(playlist => {
                                console.debug('[CatalogState] playlist loaded', playlist);
                                if (playlist) {
                                    dispatch({ type: ADD_PLAYLISTS, payload: { [playlist.id]: playlist } });
                                }
                            });
                    });
                });

                // if (courses) {
                //     addCourses(courses);
                // }
            });

        apiFetcher('/api/playlists/')
            .then(playlists => {
                // endActivity();
                // console.debug('[CatalogState] playlists loaded', playlists);
                if (playlists) {
                    dispatch({ type: ADD_PLAYLISTS, payload: playlists });
                }
            });

        apiFetcher('/api/videos/')
            .then(videos => {
                endActivity();
                // console.debug('[CatalogState] videos loaded', videos);
                if (videos) {
                    addVideos(videos);
                }
            });

        apiFetcher('/api/lessons/')
            .then(lessons => {
                endActivity();
                // console.debug('[CatalogState] lessons loaded', lessons);
                if (lessons) {
                    addLessons(lessons);
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                {/*
                <div style={{position:"absolute", right: 0, top: 100, bottom: 100, width: "300px", background:'white', zIndex:100}}>
                    <h1>Catalog State</h1>
                    <ReactJson src={store} collapsed={2} />
                </div>
                */}
                {children}
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
};


export function useCatalogSetLibrary() {
    const dispatch = React.useContext(DispatchContext);
    if (dispatch === undefined) {
        throw new Error('useCatalogAddLibrary must be used within an CatalogState');
    }
    return (lib) => {
        if (lib) {
            // const payload = mapById(videos);
            dispatch({ type: SET_LIBRARY, payload: lib });
        }
    };
}

export function useCatalogStore() {
    return React.useContext(StoreContext);
}

export function useCatalogDispatch() {
    const dispatch = React.useContext(DispatchContext);
    if (dispatch === undefined) {
        throw new Error('useCatalogDispatch must be used within an CatalogState');
    }
    return dispatch;
}

// returns a map of playlists by id
export function useCatalogPlaylists() {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useCatalogPlaylists must be used within an CatalogState');
    }
    return state.playlists;
}

// returns a map of lessons by id
export function useLessons() {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useLessons must be used within an CatalogState');
    }
    return state.lessons;
}

// returns a single lesson
export function useLesson(lesson_id) {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useLesson must be used within an CatalogState');
    }
    return state.lessons[lesson_id];
}

// returns a map of videos by id
export function useVideoMap() {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useVideos must be used within an CatalogState');
    }
    return state.videos ?? {};
}

// returns an array of videos
export function useVideos() {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useVideos must be used within an CatalogState');
    }
    // convert to array
    return useMemo(() => {
        return Object.values(state.videos ?? {});
    }, [state.videos]);
}

// returns a single video by id
export function useVideo(video_id) {
    const state = React.useContext(StoreContext);
    if (state === undefined) {
        throw new Error('useVideo must be used within an CatalogState');
    }
    return useMemo(() => {
        if (state.videos && video_id) {
            // convert to array
            return state.videos[video_id];
        }
        return null;
    }, [state.videos, video_id]);
}

// returns the video for a given quiz id
export function useQuizVideo(quiz_id, video_id) {
    const videos = useVideos();
    if (videos) {
        const found = videos?.find((video) => video.quiz_id === quiz_id);
        if (found)
            return found;
        if (video_id)
            // video not found - return the video_id
            return videos.find((video) => video.id === video_id);
    }
}

export function useMainCourseAndVideos() {

    const state = React.useContext(StoreContext);

    return useMemo(() => {

        if (state && state.courses) {
            // get the main course
            // console.log(state.courses);
            const mainCourse = state.courses[Object.keys(state.courses)[0]];
            // console.log('main course', mainCourse);
            if (mainCourse && mainCourse.playlists && state.playlists) {
                // get the playlists
                const playlists = mainCourse.playlists.map(playlist_id => {
                    const playlist = state.playlists[playlist_id];
                    // get the videos
                    if (playlist && playlist.videos) {
                        const videos = playlist.videos.map(video_id => {
                            return state.videos[video_id];
                        });
                        return { ...playlist, videos: videos };
                    }
                    return playlist;
                });

                return { ...mainCourse, playlists: playlists.filter(playlist => playlist) };
            }

            return { ...mainCourse };
        }
        return null;
    }, [state]);
}

function actionLoadCourse(courseId) {
    return (dispatch, getState) => {
        // console.log('actionLoadCourse', courseId, dispatch, getState);
        // if (!getState().courses[courseId]) {
        //     dispatch({ type: 'LOAD_COURSE', payload: courseId });
        //     axios.get(`/api/courses/${courseId}/`)
        //         .then(response => {
        //             dispatch({ type: 'ADD_COURSES', payload: { [courseId]: response.data } });
        //         })
        //         .catch(error => {
        //             console.error('Error loading course', courseId, error);
        //         });
        // }
        // return {
        //     type: 'LOAD_COURSE',
        //     payload: courseId
        // };
    };
}

export function useCourseAndVideos(courseId) {
    const loading = useRef([]);
    const state = React.useContext(StoreContext);
    const dispatch = React.useContext(DispatchContext);
    if (!state || !dispatch) {
        throw new Error('useCourseAndVideos must be used within an CatalogState');
    }
    console.log('[useCourseAndVideos] loading', courseId, state);
    if (!state.courses)
        return null;

    let check = state.courses[courseId];
    if (check) {
        // the course exists
        return check;
    }

    // check if this is already loading
    if (loading.current.includes(courseId)) {
        // the course is already loading
        // console.log('useCourseAndVideos() - course is already loading', courseId);
        return null;
    }

    // load the course
    loading.current.push(courseId);
    apiClient.get(`/api/courses/${courseId}/`)
        .then(response => {
            // console.log('loaded course', courseId, response.data);
            const payload = { [courseId]: response.data };
            dispatch({ type: ADD_COURSES, payload });
            // loading.current = loading.current.filter(item => item !== courseId);
            // dispatch({ type: REMOVE_LOADING, courseId });
            // remove loading
            loading.current = loading.current.filter(item => item !== courseId);
        })
        .catch(error => {
            console.log('Error loading course', courseId, error);
            // console.error('Error loading course', courseId, error);
            // loading.current = loading.current.filter(item => item !== courseId);
            // dispatch({ type: REMOVE_LOADING, courseId });
            loading.current = loading.current.filter(item => item !== courseId);
        });

    return null;
}

export function useCourseAndVideos_old(courseId) {
    const loading = useRef([]);
    const state = React.useContext(StoreContext);
    const dispatch = React.useContext(DispatchContext);
    if (!state || !dispatch) {
        throw new Error('useCourseAndVideos must be used within an CatalogState');
    }
    // const [course, setCourse] = useState(null);
    console.log('[useCourseAndVideos] loading', courseId, state);
    useEffect(() => {
        // console.log('useCourseAndVideos()', courseId, state);

        // check if the course exists
        let check = state.courses[courseId];
        if (check) {
            // the course exists
            return;
        }

        if (state.course_library?.courses) {
            console.log('useCourseAndVideos() - Checking course library for', courseId, state.course_library);
            // check the course library
            const library_course_id = state.course_library.courses[courseId];
            if (library_course_id) {
                console.log('useCourseAndVideos() - course found in library', courseId, library_course_id);
                let check = state.courses[courseId];
                if (check) {
                    // the course exists
                    return;
                }
                return;
            } else {
                console.log('useCourseAndVideos() - course not found in library', courseId);
            }
        } else {
            console.log('useCourseAndVideos() - course not found - no library: ', courseId);
        }

        // check if the course is already loading
        if (loading.current.includes(courseId)) {
            // the course is already loading
            // console.log('useCourseAndVideos() - course is already loading', courseId);
            return;
        }


        // add the course id as loading
        loading.current.push(courseId);
        // dispatch({ type: ADD_LOADING, courseId });
        // console.log('useCourseAndVideos() - loading course', courseId);

        // load the course
        apiClient.get(`/api/courses/${courseId}/`)
            .then(response => {
                // console.log('loaded course', courseId, response.data);
                const payload = { [courseId]: response.data };
                dispatch({ type: ADD_COURSES, payload });
                // loading.current = loading.current.filter(item => item !== courseId);
                // dispatch({ type: REMOVE_LOADING, courseId });
            })
            .catch(error => {
                console.log('Error loading course', courseId, error);
                // console.error('Error loading course', courseId, error);
                // loading.current = loading.current.filter(item => item !== courseId);
                // dispatch({ type: REMOVE_LOADING, courseId });
            });

        // if the course wasn't found by id, find it by slug
        // if (!course) {
        //     course = Object.values(state.courses).find(course => course.slug === courseId);
        // }

        // if the course is not found, load it
        // if (!course) {
        //     console.log('Course not found', courseId);
        //     // console.log('slugs=', Object.values(state.courses));
        //     setCourse(null);
        //     return;
        // }
    }, [courseId, dispatch, state]);


    console.log('[useCourseAndVideos] getting', courseId, state);
    // return useMemo(() => {
    let course = state.courses[courseId];
    console.log('[useCourseAndVideos] getting course:', courseId, course);
    // if( course ) {
    //     return course;
    // }

    if (state.course_library?.courses) {
        console.log('[useCourseAndVideos] - Checking library:', courseId, state.course_library);
        // check the course library
        const library_course_id = state.course_library.courses[courseId];
        if (library_course_id) {
            course = state.courses[courseId];
            console.log('[useCourseAndVideos] - found in library', courseId, library_course_id, course, state.courses);
            if (!course)
                return null;
            // if (check) {
            //     // the course exists
            //     console.log('[useCourseAndVideos] - return course:', check);
            //     return check;
            // }
            // return null;
        } else {
            console.log('useCourseAndVideos() - course not found in library', courseId);
        }
    } else {
        console.log('useCourseAndVideos() - course not found - no library: ', courseId);
    }

    // attach external playlists to the course
    if (course?.playlists && state?.playlists && state?.videos) {
        // get the playlists
        const playlists = course.playlists.map(playlist_id => {
            const playlist = state.playlists[playlist_id];
            // get the videos
            if (playlist && playlist.videos) {
                const videos = playlist.videos.map(video_id => {
                    return state.videos[video_id];
                });
                return { ...playlist, videos: videos };
            }
            return playlist;
        });

        return { ...course, playlists: playlists.filter(playlist => playlist) };
    }

    console.log('[useCourseAndVideos] - course:', course);
    return course;
    // }, [courseId, state]);
}

export default CatalogState;


/*
export const getMainCourseAndVideosSelector = createSelector([getMainCourse, getPlaylists, getVideosAndStatsSelector], (courseSource, playlists, videos) => {
    // console.log('getMainCourseAndVideosSelector', courseSource, playlists, videos);

    // get the course and convert it to json
    if (!courseSource) return null;
    let course = courseSource.toJS();

    if (course.playlists && playlists) {
        // convert playlist IDs to real playlists
        course.playlists = course.playlists.map((playlist_id) => {
            let playlist = playlists.get(playlist_id, Map()).toJS();

            playlist.name = playlist.title;

            if (playlist.videos) {
                playlist.videos = playlist.videos.map((video_id) => {
                    return videos[video_id];
                });
            }

            return playlist;
        });
    }

    return course;
});
*/
