import React, { useCallback, useContext, useState } from 'react';
import { styled } from 'twin.macro';
/*
	The error provider sits near the top of the hierarchy and shows an error message if
	anything triggers an error via the context
 */

// the context
export const ErrorContext = React.createContext(null);

// const ErrorProviderContainer = styled.div`
//     position: relative;
// `;

const ErrorMessageContainer = styled.div`
    //background: red;
    //color: white;
    padding: 20px;

    text-align: center;
    font-size: 30px;
    z-index: 10000;

    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    .close-button {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;

        padding: 10px;

        background: transparent;
        border: none;

        color: white;

        cursor: pointer;

        &:hover {
            color: gray;
        }

        &:active {
            color: black;
        }
    }
`;

export const ErrorMessage = ({ message, close }) => {
    if (!message)
        return null;
    return (
        <ErrorMessageContainer>
            {message}
            <button className='close-button' onClick={close}>&times;</button>
        </ErrorMessageContainer>
    );
};

const ErrorProvider = (props) => {
    const [errorMessage, setErrorMessage] = useState(null);

    const hideError = () => {
        setErrorMessage(null);
    };

    return (
        <ErrorContext.Provider value={{ errorMessage, setErrorMessage, hideError }}>
            {errorMessage !== null && <ErrorMessage message={errorMessage} close={hideError} />}
            {props.children}
        </ErrorContext.Provider>
    );
};

export function useError() {
    const context = useContext(ErrorContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an ErrorProvider');
    }
    return context;
}

export default ErrorProvider;
