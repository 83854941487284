import React, { useState, useCallback } from 'react';
import moment from 'moment/moment';
import { DTCStatsBanner, DTCPlaque, NullContainer } from 'components';
import tw, { css } from 'twin.macro';
import SilverRecord from 'static/images/records/DTC/silver.png';
import GoldRecord from 'static/images/records/DTC/gold.png';
import PlatinumRecord from 'static/images/records/DTC/platinum.png';
import { useMedia } from 'react-use';

const ScrollBarStyle = css`
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #4D3095;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }
`;

const options = [
    { id: 1, name: 'Video Title', type: 'alphabetical', unavailable: false },
    { id: 2, name: 'Recent to Old', type: 'date', unavailable: false },
    { id: 3, name: 'Challenge Level', type: 'record_level', unavailable: false }
];

const recordImages = ['', SilverRecord, GoldRecord, PlatinumRecord];
const smallRecordImages = ['', SilverRecord, GoldRecord, PlatinumRecord];
const recordTitle = ['', 'Silver', 'Gold', 'Platinum'];

const MyRecords = ({ stats, activity, videos, selectedStudent, timesTableProduct }) => {

    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState(options[0]);

    // get stats length to check against if all filtered stats with an empty object are returned
    let recordCount = stats?.filter((stat) => stat.current_level > 1).length;

    const makeRecordsNullPlaques = () => {
        return [...Array(4)].map((item) => {
            return (
                <div key={item} tw='border-[#3E257A] border-solid border-[2px] flex flex-col justify-center items-center h-[256px] max-w-[197px]'>
                    <div tw='w-[130px] h-[130px] bg-[#3E257A] rounded-full' />
                    <div tw='w-[110px] h-[20px] rounded-2xl bg-[#3E257A] mt-3' />
                </div>
            );
        });
    };
    const makeRecordsPlaques = useCallback((items) => {
        return items.map((item, index) => {
            let copy = {
                title: item.recordTitle,
                videoTitle: item.title
            };
            const badge = item.completions > 1 ? `${item.completions}x` : null;

            return (
                <DTCPlaque
                    key={index}
                    multiplier={badge}
                    recordImage={item.recordImages}
                    copy={copy}
                    level={item.level}
                    minHeight='208px'
                />
            );
        });
    }, [selectedStudent]);

    let plaqueItems = [];
    if (videos) {
        // filter videos that have no stats or quiz_level data
        // create plaque items to easy filter and sort
        let items = videos.filter((v) => v?.stats?.quiz_levels);
        plaqueItems = items.reduce((items, video) => {
            Object.values(video.stats.quiz_levels).forEach((quiz) => {
                const completions = quiz.instances ? quiz.instances.filter((instance) => instance.score === 100).length : 0;
                if (quiz.score === 100 && quiz.level > 0) {
                    items.push({
                        recordTitle: recordTitle[quiz.level],
                        date: quiz.date,
                        level: quiz.level,
                        recordImages: recordImages[quiz.level],
                        title: video.title,
                        completions: completions
                    });
                }
            });
            return items;
        }, []);
    }

    // sort records by recency
    const recentRecordsEarned = plaqueItems.sort((a, b) => moment(b.date, 'YYYY-MM-DDTHH:mm:ss').diff(moment(a.date, 'YYYY-MM-DDTHH:mm:ss')));
    const threeRecentlyEarned = useMedia('(max-width: 1280px)');
    const twoRecentlyEarned = useMedia('(max-width: 1024px)');
    let recentlyEarnedItems =  recentRecordsEarned.slice(0, 3);
    if (threeRecentlyEarned) recentlyEarnedItems = recentRecordsEarned.slice(0, 3);
    if (twoRecentlyEarned) recentlyEarnedItems = recentRecordsEarned.slice(0, 3);
    const itemsWithoutMostRecent = recentRecordsEarned.slice(3);

    // sort by video & level
    const allRecordsEarned = [...itemsWithoutMostRecent].sort((a, b) => a.title.localeCompare(b.title) || a.level - b.level);

    const isSmallAndNoStats = useMedia('(max-width: 640px)') && stats.length === 0;
    // construct arr with info for the stats banner
    // find total records for each level
    const silver = stats.filter((stat) => stat.current_level === 2 || stat.current_level === 3 || stat.current_level === 4).length;
    const gold = stats.filter((stat) => stat.current_level === 3 || stat.current_level === 4).length;
    const platinum = stats.filter((stat) => stat.current_level === 4).length;

    // find most earned record
    let mostEarned;

    if (silver === 0 && gold === 0 && platinum === 0) {
        // all records are 0 - make this an invisible record to maintain layout
        mostEarned = null;
    }
    else if (silver > gold && silver > platinum) {
        // silver is the largest 
        mostEarned = (
            <div tw='max-w-[38px] -mt-[9px]'>
                <img tw='w-full h-auto' src={smallRecordImages[1]} alt='silver' />
            </div>
        );
    } else if (gold > silver && gold > platinum) {
        // gold is the largest 
        mostEarned = (
            <div tw='max-w-[38px] -mt-[9px]'>
                <img tw='w-full h-auto' src={smallRecordImages[2]} alt='gold' />
            </div>
        );

    } else if (platinum > silver && platinum > gold) {
        // platinum is the largest 
        mostEarned = (
            <div tw='max-w-[38px] -mt-[9px]'>
                <img tw='w-full h-auto' src={smallRecordImages[3]} alt='platinum' />
            </div>
        );

    } else if (silver === gold && gold === platinum) {
        // 'All variables have the same value
        mostEarned = (
            <div tw='flex -mt-[9px]'>
                <div tw='max-w-[38px]'>
                    <img tw='w-full h-auto' src={smallRecordImages[1]} alt='silver' />
                </div>
                <div tw='max-w-[38px]'>
                    <img tw='w-full h-auto -ml-2' src={smallRecordImages[2]} alt='gold' />
                </div>
                <div tw='max-w-[38px]'>
                    <img tw='w-full h-auto -ml-2' src={smallRecordImages[3]} alt='paltinum' />
                </div>
            </div>
        );

    } else {
        if (silver === gold) {
            mostEarned = (
                <div tw='flex -mt-[9px]'>
                    <div tw='max-w-[38px]'>
                        <img tw='w-full h-auto' src={smallRecordImages[1]} alt='silver' />
                    </div>
                    <div tw='max-w-[38px]'>
                        <img tw='w-full h-auto -ml-2' src={smallRecordImages[2]} alt='gold' />
                    </div>
                </div>
            );
        }
        if (silver === platinum) {
            mostEarned = (
                <div tw='flex -mt-[9px]'>
                    <div tw='max-w-[38px]'>
                        <img tw='w-full h-auto' src={smallRecordImages[1]} alt='silver' />
                    </div>
                    <div tw='max-w-[38px]'>
                        <img tw='w-full h-auto -ml-2' src={smallRecordImages[3]} alt='platinum' />
                    </div>

                </div>
            );
        }
        if (gold === platinum) {
            mostEarned = (
                <div tw='flex -mt-[9px]'>
                    <div tw='max-w-[38px]'>
                        <img tw='w-full h-auto' src={smallRecordImages[2]} alt='gold' />
                    </div>
                    <div tw='max-w-[38px]' >
                        <img tw='w-full h-auto -ml-2' src={smallRecordImages[3]} alt='platinum' />
                    </div>
                </div>
            );
        }
    }

    // filter activity for only quiz taken with a score of 100
    // filter activity that is after the 7 days or 30 days
    let loadedActivity = activity ? (Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity) : null;
    let filterActivity = loadedActivity?.filter((a) => a.name === 'quiz:taken');
    filterActivity = filterActivity?.filter((a) => a.data.score === 100);
    filterActivity = filterActivity?.filter((a) => a.data.quizLevel !== 0);
    const totalRecordsEarned = filterActivity.length;
    const recordsEarnedThisWeek = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const recordsEarnedMonth = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(30, 'days'))).length;

    // create contents to be passed down to stats banner
    const contents = [
        { title: 'total earned', value: totalRecordsEarned },
        { title: 'earned this week', value: recordsEarnedThisWeek },
        { title: 'earned this month', value: recordsEarnedMonth },
        { title: 'most earned', value: mostEarned }

    ];
    return (
        <div tw=''>
            {!isSmallAndNoStats && (
                <div>
                    <DTCStatsBanner
                        title='My Records'
                        contents={contents}
                    />
                </div>
            )}
            {recordCount > 0 ? (
                <div tw='bg-[#3E257A] rounded-b-lg pb-5'>
                    <div tw='px-4 lg:max-h-[555px] overflow-x-auto lg:overflow-y-auto mr-4' css={[ScrollBarStyle]}>
                        <div tw='bg-[#4D3095] rounded-lg px-4 pt-6 pb-9 mt-6'>
                            <div tw='text-[#E3E2E3] font-medium text-base'>Recently Earned</div>
                            <div tw='grid grid-cols-2 xsm:px-0 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-1.5 md:gap-3 mt-4 justify-items-center'>
                                {makeRecordsPlaques(recentlyEarnedItems, search)}
                            </div>
                        </div>
                        <div tw=' xsm:px-5'>
                            <div tw='text-[#E3E2E3] font-medium text-base mt-6'>More Records Earned</div>
                            <div tw='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-1.5 md:gap-3 mt-6 justify-items-center pb-6'>
                                {makeRecordsPlaques(allRecordsEarned, search)}
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <div tw='bg-[#3E257A] rounded-b-lg pb-5 '>
                    <div tw='px-4 lg:max-h-[555px] overflow-x-auto lg:overflow-y-auto mr-4' css={[ScrollBarStyle]}>
                        <div tw='bg-[#4D3095] rounded-lg px-4 pt-6 pb-9 mt-6'>
                            <div tw='text-[#E3E2E3] font-medium text-base'>Recently Earned</div>
                            <div tw='grid xsm:px-0 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-1.5 mt-8 justify-items-center'>
                                {/*{makeRecordsPlaques(recentlyEarnedItems, search)}*/}
                                <svg tw='w-[165px] 2xl:w-[200px] h-[225px] 2xl:h-[250px] ' width='197' height='256' viewBox='0 0 197 256' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect x='1' y='1' width='195' height='254' stroke='#3E257A' strokeWidth='2' />
                                    <rect x='41.9937' y='184.276' width='110.907' height='19.3939' rx='9.69697' fill='#3E257A' />
                                    <ellipse cx='97.9854' cy='107.407' rx='65.6827' ry='64.2333' fill='#3E257A' />
                                </svg>
                                <svg tw='w-[165px] 2xl:w-[200px] h-[225px] 2xl:h-[250px] ' width='197' height='256' viewBox='0 0 197 256' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect x='1' y='1' width='195' height='254' stroke='#3E257A' strokeWidth='2' />
                                    <rect x='41.9937' y='184.276' width='110.907' height='19.3939' rx='9.69697' fill='#3E257A' />
                                    <ellipse cx='97.9854' cy='107.407' rx='65.6827' ry='64.2333' fill='#3E257A' />
                                </svg>
                                <svg tw='w-[165px] 2xl:w-[200px] h-[225px] 2xl:h-[250px] ' width='197' height='256' viewBox='0 0 197 256' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect x='1' y='1' width='195' height='254' stroke='#3E257A' strokeWidth='2' />
                                    <rect x='41.9937' y='184.276' width='110.907' height='19.3939' rx='9.69697' fill='#3E257A' />
                                    <ellipse cx='97.9854' cy='107.407' rx='65.6827' ry='64.2333' fill='#3E257A' />
                                </svg>
                                <svg tw='w-[165px] 2xl:w-[200px] h-[225px] 2xl:h-[250px] ' width='197' height='256' viewBox='0 0 197 256' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect x='1' y='1' width='195' height='254' stroke='#3E257A' strokeWidth='2' />
                                    <rect x='41.9937' y='184.276' width='110.907' height='19.3939' rx='9.69697' fill='#3E257A' />
                                    <ellipse cx='97.9854' cy='107.407' rx='65.6827' ry='64.2333' fill='#3E257A' />
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default MyRecords;
