import React, { Component } from 'react';
import { styled } from 'twin.macro';
import blue_arrow_down from 'static/images/blue_arrow_down.png';
import Dropdown from './Dropdown.js';

const SortButton = styled.button`
    height: ${(props) => (props.height ? props.height : '36px')};
    width: ${(props) => (props.width ? props.width : '100%')};
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : ' 20px ')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '5px')};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
    border: ${(props) => (props.border ? props.border : 'solid 1px #1abdd7')};
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'none')};
    background-image: ${(props) => (props.backgroundImage ? props.backgroundImage : 'none')};

    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.assignmentsPage ? 'none ' : ' space-between')};
    align-items: center;

    .title {
        font-family: Avenir, serif;
        font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
        font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};

        margin: ${(props) => (props.margin ? props.margin : '0px')};
        color: ${(props) => (props.color ? props.color : '#1abdd7')};
        flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : 'none')};

        padding-right: 10px !important;
    }

    img {
        width: ${(props) => (props.img_width ? props.img_width : '24px')};
    }

    .dropdown-arrow {
        margin: 0px 5px 0 0;
    }
`;

export default class SortDropdown extends Component {
    static defaultProps = {
        // title: 'View By',
        selected: null,
        onSelectItem: null,
        showSortTitle: true,
        items: [
            {
                id: 'alphabetical',
                name: 'A-Z'
            },
            {
                id: 'due_date',
                name: 'Due Date'
            }
        ],
        className: null,
        style: {}
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleItemSelection = (item_id) => {
        if (this.props.onSelectItem) this.props.onSelectItem(item_id);
    };

    render() {
        let title = this.props.defaultTitle;
        if (this.props.showSortTitle && this.props.selected) {
            // find the item
            let item = this.props.items.find((item) => item.id === this.props.selected);
            if (item) {
                title = item.name;
            }
        }
        let dropdown_button = (
            <SortButton
                className='sort-dropdown-button'
                backgroundImage={this.props.backgroundImage}
                dropDownColor={this.props.dropDownColor}
                borderRadius={this.props.borderRadius}
                boxShadow={this.props.boxShadow}
                width={this.props.width}
                height={this.props.height}
                margin={this.props.margin}
                fontSize={this.props.fontSize}
                fontWeight={this.props.fontWeight}
                color={this.props.color}
                backgroundColor={this.props.backgroundColor}
                borderColor={this.props.borderColor}
                flexGrow={this.props.flexGrow}
                marginTop={this.props.marginTop}
                paddingLeft={this.props.paddingLeft}
                assignmentsPage={this.props.assignmentsPage}
                img_width={this.props.img_width}
                border={this.props.border}
            >
                <div className='title'>{title}</div>
                <img
                    className='dropdown-arrow'
                    style={{ ...this.props.arrowStyle }}
                    src={this.props.button_img_src ? this.props.button_img_src : blue_arrow_down}
                    alt='dropdown'
                />

            </SortButton>
        );

        return (
            <Dropdown
                className={this.props.className}
                style={{ width: this.props.width ? this.props.width : '275px', height: '39px', ...this.props.style }}
                color={this.props.color}
                value={dropdown_button}
                items={this.props.items}
                selected={this.props.selected}
                onSelectItem={this.handleItemSelection}
                dropDownHeight={this.props.dropDownHeight}
                dropDownBackgroundColor={this.props.dropDownBackgroundColor}
                dropDownTextAlign={this.props.dropDownTextAlign}
                dropDownFontSize={this.props.dropDownFontSize}
                dropDownPadding={this.props.dropDownPadding}
                selectedBackgroundColor={this.props.selectedBackgroundColor}
                dropDownColor={this.props.dropDownColor}
            />
        );
    }
}
