import React, { useMemo } from 'react';
import tw, { styled, css } from 'twin.macro';

import LargeImageCard from '../LargeQuizCard/LargeImageCard';
import LargeQuizCard from '../LargeQuizCard/LargeQuizCard';
import LargeVideoCard from '../LargeVideoCard/LargeVideoCard';

const GuidanceCards = ({ actions, videos }) => {
    // console.log('              <GuidanceCards> actions:', actions, 'videos:', videos);
    // create the cards
    return useMemo(() => {
        if (!actions || !videos?.length) return null;
        // console.log('create activity cards:', actions, videos);
        return actions?.map((activity, index) => {
            // console.log('action', activity);
            let video = null;
            if (activity.video_id) {
                video = videos?.find(video => video.id === activity.video_id);
                if (!video) {
                    console.warn('Guidance action: video not found:', activity.video_id);
                    return null;
                }
            }
            const key = `${activity?.action}-${activity.video_id ?? activity.image}-${index}`;
            const quiz_actions = [
                'take-quiz',
                'silver-record',
                'gold-record',
                'platinum-record'
            ];

            if (activity.action === 'image') {
                return (
                    <div key={key} tw='w-full lg:w-1/2'>
                        <LargeImageCard
                            key={key}
                            image={activity.image}
                        />
                    </div>
                );
            }

            // action is in quiz_actions this is a quiz card
            if (quiz_actions.includes(activity.action)) {
                return (
                    <div key={key} tw='w-full lg:w-1/2'>
                        <LargeQuizCard
                            key={key}
                            action={activity.action}
                            video={video}
                        />
                    </div>
                );
            } else {
                // 'watch-video' || 'unlock-video'
                return (
                    <div key={key} tw='w-full lg:w-1/2'>
                        <LargeVideoCard
                            key={key}
                            action={activity.action}
                            video={video}
                        />
                    </div>
                );
            }
        });
    }, [actions, videos]);
};

export default GuidanceCards;
