// setup axios defaults
import axios from 'axios';

// django csrf token
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// create a string from a network error
export const formatNetworkError = (error) => {
    if (error.response) {
        let msg = `Network error: ${error.response?.status} ${error.response?.statusText}`;
        if( error.response?.data ) {
            msg = `${msg}\n${error.response?.data}`;
        }
        return msg;
    }
    return `Error: ${error.message}`;

};

// network error
export const handleNetworkError = (error) => {
    // console.error(error);
    if (error.response) {
        // create toast
        // status
        // :
        // 500
        // statusText
        // :
        // "Internal Server Error"
        /*
        if( error.response?.status >= 400  ) {
            console.log('Server Error:', error.response?.status, error.response?.statusText);
            console.dir(error.response);
            // toast.error(error.response?.data);
            // if( window._addNotification ) {
            //     console.log('adding notification');
            //     window._addNotification('Success!', 'Your playlist was  created.', 'gradient');
            // }
            if( window._addToastNotification) {
                window._addToastNotification('Error', `There was a network error: ${error.response.statusText}`, 'success', 6, null);
            }
        }
        */

        console.groupCollapsed('Error Response');
        console.log(formatNetworkError(error));
        console.error(formatNetworkError(error));
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response?.config?.method, error.response?.config?.url, error.response?.status, error.response?.statusText);
        // console.log(error.response?.data);
        console.dir(error.response);
        console.groupEnd();

        // eslint-disable-next-line eqeqeq
        if (error.response.status == 403) {
            console.error('User is not authenticated, redirecting to login');
            // window.location.href = '/login/';
        }


    } else {
        console.dir(error);
    }
};
