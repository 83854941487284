import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import ReactHoverObserver from 'react-hover-observer';
import { HoverBoxSmall } from 'components';

const DTCVideoViewIcon = ({ selectedStudentName }) => {
    const [isHovering, setIsHovering] = useState(false);
    const hoverText = selectedStudentName ? `${selectedStudentName}'s views` : 'your views';
    return (
        <ReactHoverObserver
            onHoverChanged={() => setIsHovering(true)}
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div tw='cursor-pointer relative'>
                <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none'>
                    <path stroke='#9D91BB' strokeLinecap='round' strokeWidth='1.235' d='M10.353 14.823c-3.795 0-6.278-2.36-7.45-3.818a1.772 1.772 0 0 1 0-2.246c1.172-1.457 3.655-3.818 7.45-3.818 3.795 0 6.278 2.36 7.45 3.818.534.666.534 1.58 0 2.246-1.172 1.458-3.655 3.818-7.45 3.818Z' />
                    <circle
                        cx='10.353' cy='9.882' r='2.471' stroke='#9D91BB'
                        strokeWidth='1.235' />
                </svg>
                <HoverBoxSmall isHovering={isHovering}>
                    <div tw='uppercase text-white text-xs text-center flex justify-center items-center h-full'>{hoverText}</div>
                </HoverBoxSmall>
            </div>
        </ReactHoverObserver>
    );
};

DTCVideoViewIcon.propTypes = {
    anyProp: PropTypes.any
};

export default DTCVideoViewIcon;

