import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import moment from 'moment';

moment.updateLocale('en', {
    relativeTime: {
        future: 'In %s',
        past: '%s Ago',
        s: 'a Few Seconds',
        ss: '%d Seconds',
        m: 'a Minute',
        mm: '%d Minutes',
        h: 'An Hour',
        hh: '%d Hours',
        d: 'a Day',
        dd: '%d Days',
        M: 'a Month',
        MM: '%d Months',
        y: 'A Year',
        yy: '%d Years'
    }
});

const StyledTag = styled.div(({ variant, assignmentStatus, teacher }) => [
    // The common button styles added with the tw import
    tw`absolute left-0 top-0 flex justify-center items-center text-white py-1 px-2 border-2 border-white text-xl`,

    // Use props  to conditionally style your components
    assignmentStatus === 'UPCOMING' && tw`bg-UpcomingBlue`,
    assignmentStatus === 'OVERDUE' && tw`bg-OverdueRed`,
    assignmentStatus === 'COMPLETED' && tw`bg-CompletedGreen`,
    teacher && tw`bg-TeacherAssignedGray`,
    variant === 'rounded' && tw`rounded-t-lg rounded-br-lg`
]);

const VideoProgressIndicator = ({ variant, assignments, teacher, storybook }) => {
    let assignmentStatus;
    let assignmentStatusCopy;
    let dueDate;
    // console.log('VideoProgressIndicator', assignments);

    assignments.forEach((a) => {
        if (a.assignment) {
            dueDate = moment(a.assignment.due).fromNow('D');
            assignmentStatus = moment().isAfter(a.assignment.due) ? 'OVERDUE' : 'UPCOMING';
            if (assignmentStatus === 'OVERDUE') {
                assignmentStatusCopy = ` ${dueDate} Overdue`;
            }
            if (assignmentStatus === 'UPCOMING') {
                assignmentStatusCopy = ` Due In ${dueDate}`;
            }
            if (dueDate === 'a day') {
                if (assignmentStatus === 'OVERDUE') {
                    assignmentStatusCopy = ` ${dueDate} Day Overdue`;
                }
                if (assignmentStatus === 'UPCOMING') {
                    assignmentStatusCopy = ` Due In ${dueDate} Day`;
                }
            }

            // checking if assignment has been complete
            let assignmentProgress = a.assignment.progress?.completed ?? false;
            if (assignmentProgress === true) {
                assignmentStatusCopy = 'Completed';
                assignmentStatus = 'COMPLETED';
            }
        }
        if (dueDate === 'Invalid date') {
            return null;
        }
        if (teacher) {
            assignmentStatusCopy = 'Assigned';
        }

        // check against dummy obj to give different states in storybook
        if (storybook) {
            assignmentStatus = storybook.assignmentStatus;
            assignmentStatusCopy = storybook.assignmentStatusCopy;
        }
    });
    if (dueDate === 'Invalid date') {
        return null;
    }
    return (
        <StyledTag variant={variant} assignmentStatus={assignmentStatus} teacher={teacher}>
            <div tw='pointer-events-none'>{assignmentStatusCopy}</div>
        </StyledTag>
    );
};

VideoProgressIndicator.propType = {
    anyProp: PropTypes.any
};

export default VideoProgressIndicator;
