import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import RecordIndicator from 'components/RecordIndicator';
import useOnClickOutside from '../../hooks/userOnClickOutside';

export const StyledChallengeButton = styled.button(({ isMenuShowing, level }) => [
    tw`hover:bg-opacity-75 flex items-center justify-between w-full py-1 px-4 bg-FrostBite rounded-full`,
    isMenuShowing && tw`borderRadius[30px] rounded-b-none`,
    level === 3 && tw`bg-PlatinumChallenge mt-5 lg:mt-0`,
    level === 2 && tw`bg-GoldChallenge mt-5 lg:mt-0`,
    level === 1 && tw`bg-SilverChallenge mt-5 lg:mt-0`
]);

const ChallengeButton = (props) => {
    const { level, teacher, children, onClick, setShowAnswerKey, setLevel } = props;
    const [isMenuShowing, setMenuShowing] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setMenuShowing(false));
    return (
        <div tw='relative w-full maxWidth[330px]' ref={ref}>
            <StyledChallengeButton
                tabIndex='0'
                level={teacher && level}
                isMenuShowing={teacher && isMenuShowing}
                onClick={() => (teacher ? setMenuShowing(teacher && !isMenuShowing) : onClick())}
                aria-label='Take Challenge'
                data-testid='challenge-button'
            >
                <div tw='h-5 w-5 lg:h-10 lg:w-10 mr-2'>
                    <RecordIndicator level={level} />
                </div>
                <div tw='uppercase fontSize[18px] font-extrabold leading-normal text-white'>{children || 'Take Challenge'}</div>
                <svg
                    width='24' height='24' viewBox='0 0 24 24' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M9 18L15 12L9 6' stroke='white' strokeWidth='2' strokeLinecap='round'
                        strokeLinejoin='round' />
                </svg>
            </StyledChallengeButton>
            {isMenuShowing && (
                <div
                    tw='bg-white absolute w-full left-0 top-full px-6 text-DarkLiver text-sm font-medium rounded-b-xl text-center z-30'
                    style={{ boxShadow: '0px 10px 24px rgb(0 0 0 / 20%)' }}
                >
                    <button
                        tabIndex={0}
                        tw='w-full border-b-2 border-GainsboroGrey py-3 cursor-pointer'
                        onClick={() => {
                            setShowAnswerKey(true);
                            setLevel(level);
                        }}
                    >
                        See Answer Key
                    </button>
                    <button tabIndex={0} tw='w-full py-3 cursor-pointer' onClick={props.onChallenge}>
                        Take Challenge
                    </button>
                </div>
            )}
        </div>
    );
};

ChallengeButton.propTypes = {
    level: PropTypes.number,
    teacher: PropTypes.bool,
    children: PropTypes.any
};

export default ChallengeButton;
