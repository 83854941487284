import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { useLesson } from 'state/CatalogState';
import { useParent, useTeacher } from 'state/User/UserState';

const ProgressBoxes = styled.div(({ variant }) => [
    tw`rounded-md w-1/4 min-height[63px]`,
    variant === 'Warm Up' && tw`bg-LavenderBlue`,
    variant === 'Warm Up' &&
    css`
        .header {
            background: #a0a0e2;
        }
    `,
    variant === 'Silver' && tw`background[#E0E5EC] `,
    variant === 'Silver' &&
    css`
        .header {
            background: #b8c4d6;
        }
    `,
    variant === 'Gold' && tw`background[#EFD080]  `,
    variant === 'Gold' &&
    css`
        .header {
            background: #d6b562;
        }
    `,
    variant === 'Platinum' && tw`background[#A8E5F8]  `,
    variant === 'Platinum' &&
    css`
        .header {
            background: #6bb3c9;
        }
    `
]);

const LineClamp = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const VideoPlayingInfo = ({ video, selectedStudent }) => {
    const quizTitle = ['Warm Up', 'Silver', 'Gold', 'Platinum'];
    const lesson = useLesson(video?.lesson_id);
    const parent = useParent();
    const teacher = useTeacher();
    let hideProgress = teacher && !parent;

    let userTitle = 'Your';
    if (parent) userTitle = selectedStudent?.name_string;

    let userViews = `Your Views: ${video?.stats?.view_count ?? 0}`;
    if (parent) userViews = selectedStudent?.name;

    const creatProgress = () => {
        return quizTitle.map((quizLevel, index) => {
            let score = video?.stats?.quiz_levels[index]?.score ? `${video?.stats?.quiz_levels[index]?.score}%` : '';
            let attempts = video?.stats?.quiz_levels[index]?.attempts
                ? `${video?.stats?.quiz_levels[index]?.attempts} ${video?.stats?.quiz_levels[index]?.attempts > 1 ? 'Attempts ' : 'Attempt'}`
                : 'Not Started';
            if (parent) {
                let childVideoStats = selectedStudent?.video_stats[video.id];
                score = childVideoStats?.quiz_levels[index]?.score ? `${childVideoStats?.quiz_levels[index]?.score}%` : '';
                attempts =
                    childVideoStats?.quiz_levels[index]?.attempts && quizLevel === 'Warm Up'
                        ? ''
                        : childVideoStats?.quiz_levels[index]?.attempts
                            ? `${childVideoStats?.quiz_levels[index]?.attempts}  ${childVideoStats?.quiz_levels[index]?.attempts > 1 ? 'Attempts ' : 'Attempt'}`
                            : 'Not Started';
            }

            return (
                <ProgressBoxes variant={quizLevel} key={index}>
                    <div className='header' tw='text-white fontSize[10px] text-center rounded-t-md py-1'>
                        {quizLevel}
                    </div>
                    <div tw='flex items-center justify-center mt-2'>
                        <div tw='fontSize[10px] font-extrabold text-EarieBlack'>{score}</div>
                        <div />
                    </div>
                    <div tw='fontSize[10px] text-SonicSilver text-center'>{attempts}</div>
                </ProgressBoxes>
            );
        });
    };

    return (
        <div tw='bg-VideoHoverPurple text-white pl-2.5 pr-3 py-3 font-medium rounded-b-md'>
            <div tw='flex justify-between text-white text-sm items-center'>
                <div tw='fontSize[14px]'> {video?.title}</div>
                <div tw='fontSize[12px]'>{userViews}</div>
            </div>
            <div tw='fontSize[10px] text-white ' css={hideProgress && tw`py-6`}>
                <div css={[LineClamp]}>{lesson?.description}</div>
            </div>
            {!hideProgress && (
                <>
                    <div tw='height[1px] bg-GainsboroGrey w-full mt-1' />

                    <div tw='flex items-start justify-between mt-3'>
                        <div tw='fontSize[12px]'>{userTitle} Stats</div>
                        {/* <div tw='flex items-center'>
                            <div>
                                <svg
                                    width='14' height='14' viewBox='0 0 14 14' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z'
                                        fill='#FCA500'
                                    />
                                    <path
                                        d='M9.22749 4.77344L4.77295 9.22798'
                                        stroke='white'
                                        strokeWidth='1.44628'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M5.51514 4.77246H9.22726V8.48458'
                                        stroke='white'
                                        strokeWidth='1.44628'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                            <div tw='fontSize[10px] text-white ml-1.5'>Improving</div>
                        </div> */}
                    </div>

                    <div tw='flex items-start pt-2 pb-4 gap-1.5'>{creatProgress()}</div>
                </>
            )}
        </div>
    );
};

export default VideoPlayingInfo;
