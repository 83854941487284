import React from 'react';
import tw from 'twin.macro';

import Modal from '../Modal/Modal.js';
import Button from '../Button/Button.js';
import CloseButton from '../AnswerKey/CloseButton';

const RemoveStudentDialog = ({ studentId, currentClass, removeStudent, open, onClose }) => {

    const handleSubmitDeleteStudent = () => {
        console.log('handleSubmitDeleteStudent', currentClass, studentId);
        if (studentId && currentClass && removeStudent) {
            removeStudent(studentId, currentClass.id);
        }
        onClose();
    };

    return (
        <Modal
            isOpen={open}
            setIsOpen={(value) => {
                if (!value)
                    onClose();
            }}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            <div tw='relative bg-white rounded flex-col justify-center font-sans rounded-t-lg' style={{ minWidth: '800px' }}>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-2xl rounded-t-lg'>Delete Student</div>
                <div tw='text-center text-lg text-DarkLiver pt-11'>
                    <div>This will remove the student and their data from your Muzology class.</div>
                    <div tw='pt-5'>Are you sure you want to remove this student?</div>
                </div>
                <div tw='flex justify-center space-x-2 py-10'>
                    <Button
                        variant='RosterTable'
                        onClick={onClose}
                    >
                        Keep
                    </Button>
                    <Button onClick={() => handleSubmitDeleteStudent()}>Remove</Button>
                </div>
                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={onClose}
                />

            </div>

        </Modal>
    );
};

export default RemoveStudentDialog;
