import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import RecordImageSilver from 'static/images/records/silver.png';
import RecordImageGold from 'static/images/records/gold.png';
import RecordImagePlatinum from 'static/images/records/platinum.png';
import { maybePlural } from '../../utils/string';
import { useVideoStats } from '../../state/Student/VideoStats';


const RecordCounter = (props) => {
    const stats = useVideoStats();
    const statsValues = Object.values(stats);
    const silver = statsValues.filter(stat => stat.current_level >= 2).length;
    const gold = statsValues.filter(stat => stat.current_level >= 3).length;
    const platinum = statsValues.filter(stat => stat.current_level >= 4).length;
    return (
        <div tw='flex space-x-2'>
            <div tw='relative flex items-center justify-center'>
                <img
                    tw='w-12 h-12'
                    src={RecordImageSilver}
                    alt={`${silver} Silver ${maybePlural('Record', silver)}`}
                />
                <span aria-hidden={true} tw='text-xs font-black absolute text-white'>{silver}</span>
            </div>
            <div tw='relative flex items-center justify-center'>
                <img
                    tw='w-12 h-12'
                    src={RecordImageGold}
                    alt={`${gold} Gold ${maybePlural('Record', gold)}`}
                />
                <span aria-hidden={true} tw='text-xs font-black absolute text-white'>{gold}</span>
            </div>
            <div tw='relative flex items-center justify-center'>
                <img
                    tw='w-12 h-12'
                    src={RecordImagePlatinum}
                    alt={`${platinum} Platinum ${maybePlural('Record', platinum)}`}
                />
                <span aria-hidden={true} tw='text-xs font-black absolute text-white'>{platinum}</span>
            </div>
        </div>
    );
};

RecordCounter.propTypes = {
    anyProp: PropTypes.any
};

export default RecordCounter;
