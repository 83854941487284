import React, { useMemo } from 'react';
import 'twin.macro';
import { useAuth } from 'context/Auth/AuthProvider.js';
import { Helmet } from 'react-helmet';
import { MUZOLOGY_APP_TITLE, MUZOLOGY_APP_URL, MUZOLOGY_TITLE_TEMPLATE } from 'config';
import AuthenticatedApp from 'app/AuthenticatedApp';
import UnauthenticatedApp from 'unauthenticated/UnauthenticatedApp';
import SiteProvider from '../context/SiteProvider';
import 'array.prototype.tosorted';


/*
    This selects the correct app based on authentication
 */
// const AppSelector = (props) => {
//     const { isLoggedIn, initialStore } = useAuth();
//     // const location = useLocation();
//     // console.log('[App] isLoggedIn:', isLoggedIn, location.pathName);
//
//     return useMemo(() => {
//         // console.log('[App] building app node');
//         if (isLoggedIn === null) {
//             // console.log('[App] checking authentication');
//             return (<div tw='w-full h-full background[red]' />);
//         } else if (isLoggedIn) {
//             // console.log('[App] Render authenticated app');
//             return (<AuthenticatedApp initialStore={initialStore} />);
//         } else {
//             // console.log('[App] Render unauthenticated app');
//             return (<UnauthenticatedApp />);
//         }
//     }, [isLoggedIn, initialStore]);
// };

/*
    UnauthenticatedApp is the app that is shown when the user is not logged in.
    Its routes are always mounted.
    AnalyticsProvider is mounted at the very top so that it can be used by unauthenticated users.
 */


const App = (props) => {
    const { isLoggedIn, initialStore } = useAuth();
    console.debug('[App] isLoggedIn', isLoggedIn);
    return (
        <div
            id='muzology-app'
            style={{ height: '100%', background: 'linear-gradient(180deg, #290865 0%, #4A40BA 100%)' }}
            className={`application ${isLoggedIn ? 'authenticated' : 'unauthenticated'}`}
            data-testid={isLoggedIn ? 'authenticated-app' : 'unauthenticated-app'}
        >
            <Helmet
                titleTemplate={MUZOLOGY_TITLE_TEMPLATE}
                defaultTitle={MUZOLOGY_APP_TITLE}
            >
                <meta charSet='utf-8' />
                <link rel='canonical' href={MUZOLOGY_APP_URL} />
            </Helmet>
            {isLoggedIn ? <AuthenticatedApp initialStore={initialStore} /> : <UnauthenticatedApp isLoggedIn={isLoggedIn} />}
        </div>
    );
};

export default App;
