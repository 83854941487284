import * as React from 'react';

function SvgClassIcon16(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF57BC' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.866 20.294a1.2 1.2 0 011.697 0L26 21.732l1.437-1.438a1.2 1.2 0 011.697 1.697l-1.437 1.438 1.437 1.437a1.2 1.2 0 11-1.697 1.697L26 25.126l-1.437 1.437a1.2 1.2 0 11-1.697-1.697l1.437-1.437-1.437-1.438a1.2 1.2 0 010-1.697z'
                fill='#12154C'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.132 11.67a1.2 1.2 0 011.154-.87h8a1.2 1.2 0 010 2.4H21.19l-4.323 15.13a1.2 1.2 0 01-2.227.207l-3.097-6.194H10a1.2 1.2 0 010-2.4h2.286a1.2 1.2 0 011.073.663l2.049 4.098 3.724-13.034z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon16;
