import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { styled } from 'twin.macro';

const PopUpStyle = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    bottom: 40px;
    right: -30px;
    background: ${(props) => props.backgroundColor || '#fff'};
    border: ${(props) => `2px solid ${props.borderColor}` || '2px solid #1ac1dd'};
    height: ${(props) => props.height || '130px'};
    width: ${(props) => props.width || '280px'};
    transition: opacity 0.3s;
    z-index: 10;
    border-radius: 5px;
    &:after,
    &:before {
        top: 100%;
        left: 88%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(97, 181, 231, 0);
        border-top-color: ${(props) => props.backgroundColor || '#fff'};
        border-width: 10px;
        margin-left: -20px;
    }

    &:before {
        border-color: rgba(34, 34, 34, 0);
        border-top-color: ${(props) => props.borderColor || '#61b5e7'};
        border-width: 13px;
        margin-left: -23px;
    }
`;

const HoverBoxLeft = ({ show, backgroundColor, height, children, width, borderColor }) => {
    if (!show) return null;
    return (
        <PopUpStyle backgroundColor={backgroundColor} borderColor={borderColor} height={height} width={width}>
            {children}
        </PopUpStyle>
    );
};

HoverBoxLeft.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string
};

export default HoverBoxLeft;
