import * as React from 'react';

function SvgClassIcon12(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#80A3FF' />
            <path
                d='M26.991 10.823a1.636 1.636 0 112.316 2.315l-16.21 16.21a1.637 1.637 0 01-2.315-2.316l16.21-16.21zM16.572 16.613a3.275 3.275 0 11-4.636-4.636 3.275 3.275 0 014.637 4.637l-.001-.001zM23.518 28.19a3.275 3.275 0 104.632-4.633 3.275 3.275 0 00-4.632 4.632z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon12;
