/*
    selects a list of videos from a list of videos and a list of IDs
*/

export const selectVideosWithIDs = (videos, video_ids) => {
    // loop through selected playlists videos
    // match videos with playlist videos
    // Create new list with videos
    let selectedVideosList = [];
    if (videos && video_ids) {
        video_ids.forEach((video_id) => {
            let video = videos.find((video) => video_id === video.id);
            if (video) selectedVideosList.push(video);
        });
    }
    return selectedVideosList;
};
