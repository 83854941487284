import React from 'react';
// import PerfectWarmup from 'static/images/achievements/final_achievements/perfect_warmup.png';
import PerfectWarmup from 'static/images/achievements/dtc-achievements/ribbon/perfect_warmup.png';
import NonRibbonPerfectWarmup from 'static/images/achievements/dtc-achievements/non_ribbon/perfect_warmup.png';
// import PerfectSilver from 'static/images/achievements/final_achievements/perfect_silver.png';
import PerfectSilver from 'static/images/achievements/dtc-achievements/ribbon/perfect_silver.png';
import NonRibbonPerfectSilver from 'static/images/achievements/dtc-achievements/non_ribbon/perfect_silver.png';
// import PerfectGold from 'static/images/achievements/final_achievements/perfect_gold.png';
import PerfectGold from 'static/images/achievements/dtc-achievements/ribbon/perfect_gold.png';
import NonRibbonPerfectGold from 'static/images/achievements/dtc-achievements/non_ribbon/perfect_gold.png';
// import PerfectPlatinum from 'static/images/achievements/final_achievements/perfect_platinum.png';
import PerfectPlatinum from 'static/images/achievements/dtc-achievements/ribbon/perfect_platinum.png';
import NonRibbonPerfectPlatinum from 'static/images/achievements/dtc-achievements/non_ribbon/perfect_platinum.png';
import EarnedPlatinum from 'static/images/achievements/dtc-achievements/ribbon/platinum.png';
import NonRibbonEarnedPlatinum from 'static/images/achievements/dtc-achievements/non_ribbon/platinum.png';
// import GrowthPlatinum from 'static/images/achievements/final_achievements/GrowthPlatinumVsGold.png';
import GrowthPlatinum from 'static/images/achievements/dtc-achievements/ribbon/GrowthPlatinumVsGold.png';
import NonRibbonGrowthPlatinum from 'static/images/achievements/dtc-achievements/non_ribbon/GrowthPlatinumVsGold.png';
// import GrowthGold from 'static/images/achievements/final_achievements/GrowthGoldVsSilver.png';
import GrowthGold from 'static/images/achievements/dtc-achievements/ribbon/GrowthGoldVsSilver.png';
import NonRibbonGrowthGold from 'static/images/achievements/dtc-achievements/non_ribbon/GrowthGoldVsSilver.png';
// import BreakthroughGold from 'static/images/achievements/final_achievements/GrowthGold_LowWarmup.png';
import BreakthroughGold from 'static/images/achievements/dtc-achievements/ribbon/GrowthGold_LowWarmup.png';
import NonRibbonBreakthroughGold from 'static/images/achievements/dtc-achievements/non_ribbon/GrowthGold_LowWarmup.png';
// import BreakthroughPlatinum from 'static/images/achievements/final_achievements/GrowthPlatinum_LowWarmup.png';
import BreakthroughPlatinum from 'static/images/achievements/dtc-achievements/ribbon/GrowthPlatinum_LowWarmup.png';
import NonRibbonBreakthroughPlatinum from 'static/images/achievements/dtc-achievements/non_ribbon/GrowthPlatinum_LowWarmup.png';
// import MuzologyHeart from 'static/images/achievements/final_achievements/MuzologyHeart.png';
import MuzologyHeart from 'static/images/achievements/dtc-achievements/ribbon/MuzologyHeart.png';
import NonRibbonMuzologyHeart from 'static/images/achievements/dtc-achievements/non_ribbon/MuzologyHeart.png';

const AchievementConfig = {
    login_streak: {
        header: 'Login Streak',
        icon: 'icon_url',
        levels: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        single_video: false
    },

    single_video_watched_7_times: {
        image: MuzologyHeart,
        non_ribbon_image: NonRibbonMuzologyHeart,
        title: 'Video Watched 7 Times',
        header: 'Watched 7 Times',
        icon: 'icon_url',
        description: 'VIDEO WATCHED 7 TIMES',
        single_video: true,
        show_null_state: true
    },

    // single video perfect achievements
    single_video_pretest_perfect_score: {
        image: PerfectWarmup,
        non_ribbon_image: NonRibbonPerfectWarmup,
        title: 'Perfect Warmup Score',
        header: 'Warmup Wizard',
        icon: 'icon_url',
        description: 'PERFECT WARM UP SCORE',
        single_video: true
    },

    single_video_silver_first_try_perfect: {
        image: PerfectSilver,
        non_ribbon_image: NonRibbonPerfectSilver,
        header: 'Hot Streak',
        icon: 'icon_url',
        color: '#839daa',
        description: 'PERFECT SCORE ON SILVER (1ST ATTEMPT)',
        single_video: true
    },

    single_video_gold_first_try_perfect: {
        image: PerfectGold,
        non_ribbon_image: NonRibbonPerfectGold,
        header: 'Hot Streak',
        icon: 'icon_url',
        description: 'PERFECT SCORE ON GOLD (1ST ATTEMPT)',
        single_video: true
    },

    single_video_platinum_first_try_perfect: {
        image: PerfectPlatinum,
        non_ribbon_image: NonRibbonPerfectPlatinum,
        header: 'Hot Streak',
        icon: 'icon_url',
        color: '#00b5d0',
        description: 'PERFECT SCORE ON PLATINUM (1ST ATTEMPT)',
        single_video: true
    },

    // platinum record achievement
    single_video_platinum_record: {
        image: EarnedPlatinum,
        non_ribbon_image: NonRibbonEarnedPlatinum,
        title: 'Earned Platinum Record',
        header: 'You’ve Gone Platinum',
        icon: 'icon_url',
        color: '#4c34ff',
        description: 'EARNED THE PLATINUM RECORD!',
        single_video: true,
        show_null_state: true
    },

    // growth achievements
    single_video_growth_low_pretest_gold: {
        image: BreakthroughGold,
        non_ribbon_image: NonRibbonBreakthroughGold,
        header: 'Breakthrough',
        icon: 'icon_url',
        color: '#e6b900',
        description: 'BOUNCED BACK FROM WARMUP WITH HIGH GOLD SCORE',
        single_video: true
    },

    single_video_growth_low_pretest_platinum: {
        image: BreakthroughPlatinum,
        non_ribbon_image: NonRibbonBreakthroughPlatinum,
        header: 'Breakthrough',
        icon: 'icon_url',
        color: '#6800c4',
        description: 'BOUNCED BACK FROM WARMUP WITH HIGH PLATINUM SCORE',
        single_video: true
    },

    single_video_growth_faster_gold: {
        image: GrowthGold,
        non_ribbon_image: NonRibbonGrowthGold,
        header: 'Accelerator',
        icon: 'icon_url',
        color: '#38db96',
        description: 'HIGHER FIRST ATTEMPT SCORE ON GOLD VS SILVER',
        single_video: true
    },

    single_video_growth_faster_platinum: {
        image: GrowthPlatinum,
        non_ribbon_image: NonRibbonGrowthPlatinum,
        header: 'Accelerator',
        color: '#e637a8',
        description: 'HIGHER FIRST ATTEMPT SCORE ON PLATINUM VS GOLD',
        single_video: true
    }
};

export default AchievementConfig;
