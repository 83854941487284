import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { usePlaylists } from 'state/Teacher/TeacherProvider';
import useSWR from 'swr';
import { useDevTool } from '../../lib/DevTool/DevTool';
import { filterIncompleteAssignments, filterRecentlyOverDueAssignments } from '../../app/model/muzology';
import { apiFetcher } from '../../utils/api-client';
import { useVideosWithStatsMap } from './VideoStats';

const AssignmentsContext = React.createContext();

export const AssignmentsState = (props) => {
    const [assignments, setAssignments] = useState({});
    useDevTool('AssignmentsState', assignments);

    // fetch all my assignments
    const { data, error, isValidating, mutate } = useSWR('/api/assignments/', apiFetcher, {
        // revalidateOnFocus: false,
        onSuccess: (data) => {
            setAssignments(data);
        }
    });

    // pass down the context
    // const context = [assignments, setAssignments, mutate];
    const context = useMemo( () => {
        const mutate = () => {};
        return [assignments, setAssignments, mutate];
    }, [assignments]);

    return (
        <AssignmentsContext.Provider value={context} displayName='AssignmentContext'>
            {props.children}
        </AssignmentsContext.Provider>
    );
};

// export function useAssignments2() {
//     const [assignments, setAssignments] = React.useContext(AssignmentsContext);
//     // const stats = React.useContext(StatsContext);
//     const { data } = useSWR('/api/assignments/', apiFetcher, {
//         onSuccess: setAssignments
//         // onSuccess: (data) => dispatch({ type: ADD_CLASSES, classes: data })
//     });
//
//     // useSWR('/api/video_stats/all/', apiFetcher, {
//     //     onSuccess: (data) => {
//     //         console.log('api/video_stats/all/', data);
//     //         const dict = data.reduce((all, item) => {
//     //             return { ...all, [item.video_id]: item };
//     //         }, {});
//     //         // console.log("GOT STATS:", dict);
//     //         actions.addStats(dict);
//     //     }
//     // });
//     // return stats;
//     return assignments;
// }

// assignment actions for the student
export function useAssignmentActions() {
    const context = React.useContext(AssignmentsContext);
    if (context === undefined) {
        throw new Error('useAssignmentActions must be used within an AssignmentsState');
    }
    const [assignments, setAssignments, mutate] = context;

    const updateAssignment = (instance) => {
        setAssignments((items) => {
            return [...items.filter((item) => item.id !== instance.id), instance];
        });
    };

    const addAssignment = (instance) => {
        setAssignments((items) => [...items, instance]);
    };

    const fetchAssignments = () => mutate();

    return { updateAssignment, addAssignment, fetchAssignments };
}

export function useAssignments() {
    const context = React.useContext(AssignmentsContext);
    if (context === undefined) {
        throw new Error('useAssignments must be used within an AssignmentsState');
    }
    const videos = useVideosWithStatsMap();
    const [assignments] = context;

    return useMemo(() => {
        // console.log('useAssignments', assignments, videos);
        return getAssignmentsAndVideos(Object.values(assignments ?? {}), videos ?? {});
    }, [assignments, videos]);
    // return useMemo(() => Object.values(assignments ?? {}), [assignments, videos]);
}

// filter a list of assignments to only those that have the given video id
export const filterAssignmentsWithVideo = (assignments, videoId) => assignments.filter((assignment) => assignment.items.some((id) => id === videoId));

// get a list of assignments that contain the given video id
export function useAssignmentsForVideo(videoId) {
    const assignments = useAssignments();
    return filterAssignmentsWithVideo(assignments, videoId);
}

// get assignments for a specific student
export function useStudentAssignments(studentId) {
    const playlists = usePlaylists();
    const student_assignments = playlists?.reduce((all, playlist) => {
        if (playlist.assignments?.length) {
            const instances = playlist.assignments.reduce((instances, assignment) => {
                const student_instances = assignment.student_instances.filter((instance) => instance.student_id === studentId);
                if (student_instances?.length) {
                    const this_assignment = {
                        ...assignment,
                        ...playlist,
                        progress: student_instances[0]
                    };
                    delete this_assignment.student_instances;
                    return [...instances, this_assignment];
                }
                return instances;
            }, []);

            // if this assignment has any instances belonging to this student, add the assignment and instance to the list
            if (instances?.length) {
                return [...all, ...instances];
            }
        }
        // console.log(playlist);
        return all;
    }, []);
    // console.log(' FOUND ALL STUDENT ASSIGNMENTS: ', student_assignments);
    return student_assignments;
}

// special selectors
// export const getUpcomingAssignmentSelector = createSelector([getAssignmentsListSelector], filterUpcomingAssignments);
export const getAssignmentsAndVideos = (assignments, videos) => {
    // console.log('getAssignmentsAndVideos', assignments);
    if (!assignments) return [];
    return assignments.map((assignment) => {
        // console.log('+  assignment', assignment);
        let items = assignment.items;
        if (items) {
            let index = 1;
            let new_videos = items.map((video_id) => {
                // find the video
                // let video = videos.find(video => video.id === video_id);
                let video = videos[video_id];
                if (!video) {
                    return null;
                }

                // access the progress for this assignment item by index
                // let progress = assignment.getIn(['progress', 'progress', index.toString()], Map());
                const progress = assignment.progress;

                // attach the assignment progress to the video
                if (progress) {
                    // console.log('progress:', progress);
                    if (progress.progress) {
                        const video_progress = progress.progress[video_id];
                        if (video_progress === false || video_progress === true) {
                            // this is legacy data - just skip it for now
                        } else if (video_progress) {
                            video.assignment_progress = video_progress;
                            video.assignment_completed = video_progress.completed;
                            video.assignment_index = index;
                        }
                    }
                }

                // let completed = progress.completed;
                // video = video.set('assignment_progress', progress);
                // video = video.set('assignment_completed', completed);
                // video = video.set('assignment_index', index);
                index += 1;
                return video;
            });

            // filter out missing videos
            new_videos = new_videos.filter((video) => video !== null);

            // attach the videos to the assignment
            return { ...assignment, videos: new_videos };
        }
        return assignment;
    });
};

export function useAssignedVideos() {
    // get assignments & videos
    const assignments = useAssignments();
    const videos = useVideosWithStatsMap();
    // console.log('assignments', assignments);
    // console.log('videos', videos);
    // combine assignments & videos
    const vids = Object.values(assignments).reduce((all, assignment) => {
        if (assignment.items) {
            const assigned = assignment.items
                .map((videoId) => videos[videoId])
                .filter((video) => !!video)
                .reduce((v, vid) => ({ ...v, [vid.id]: vid }), {});
            return { ...all, ...assigned };
        }
        return all;
    }, {});
    // console.log('useAssignedVideos', vids);
    return Object.values(vids);
}

export function usePlaylistVideos() {
    const playlists = usePlaylists();
    const videos = useVideosWithStatsMap();
    const vids = Object.values(playlists).reduce((all, playlist) => {
        const inPlaylist = playlist?.videos
            ?.map((videoId) => videos[videoId])
            .filter((video) => !!video)
            .reduce((v, vid) => ({ ...v, [vid.id]: vid }), {});
        return { ...all, ...inPlaylist };
    }, {});
    return Object.values(vids);
}

// most recent upcoming assignment
export const filterUpcomingAssignments = (assignments) => {
    if (assignments && assignments.length) {
        let incomplete = assignments.filter((assignment) => (assignment.progress ? !assignment.progress.completed : true));
        if (incomplete && incomplete.length) {
            let upcoming = incomplete.filter((assignment) => moment.utc().local().isBefore(assignment.due));
            let sort = upcoming.sort((a, b) => {
                let last_a = moment(a.due);
                let last_b = moment(b.due);
                return last_a.diff(last_b);
            });
            return sort[0];
        }
        return null;
    }
    return null;
};

// most recent overdue assignment
export const filterClosestOverDueAssignments = (assignments) => {
    // console.log('assignments', assignments);
    if (assignments) {
        let recent = filterRecentlyOverDueAssignments(assignments);
        let list = recent.sort((a, b) => moment(b.due).diff(moment(a.due)));
        return list[0];
    }
    return null;
};

// most recent completed assignment
export const filterUpcomingCompletedAssignments = (assignments) => {
    if (assignments) {
        let completed_upcoming_assignments;
        let upcoming_assignments = assignments.filter((assignment) => moment().isBefore(assignment.due));
        if (upcoming_assignments) {
            completed_upcoming_assignments = upcoming_assignments.filter((assignment) => assignment.progress && assignment.progress.completed === true);
            return completed_upcoming_assignments[0];
        }
        if (!completed_upcoming_assignments === undefined) {
            let closest_to_furthest_upcoming_assignments = upcoming_assignments.sort((a, b) => moment(b.due).diff(moment(a.due)));
            return closest_to_furthest_upcoming_assignments[0];
        }
    }
    return null;
};

// most recent past-due completed assignment
// [getAssignmentsSelector],
export const filterPastDueCompletedAssignments = (assignments) => {
    if (assignments) {
        let completed_past_due_assignments_list;
        let past_due_assignments_list = assignments.filter((assignment) => moment().isAfter(assignment.due));
        if (past_due_assignments_list) {
            completed_past_due_assignments_list = past_due_assignments_list.filter(
                (assignment) => assignment.progress && assignment.progress.completed === true
            );
        }
        if (!completed_past_due_assignments_list === undefined) {
            let closest_to_furthest_completed_past_due_assignments = completed_past_due_assignments_list.sort((a, b) => moment(b.due).diff(moment(a.due)));
            return closest_to_furthest_completed_past_due_assignments[0];
        }
    }
    return null;
};

export function useCurrentAssignment() {
    const assignments = useAssignments();

    const incomplete = filterIncompleteAssignments(assignments);

    const upComingAssignment = filterUpcomingAssignments(incomplete);
    if (upComingAssignment) {
        return upComingAssignment;
    }

    // if there is no upcoming assignment, look for an assignment without a due date
    if (incomplete.length && incomplete[0].due === null)
        return incomplete[0];

    const closestOverDueAssignment = filterClosestOverDueAssignments(incomplete);
    if (closestOverDueAssignment) {
        return closestOverDueAssignment;
    }

    const upcomingCompletedAssignment = filterUpcomingCompletedAssignments(assignments);
    if (upcomingCompletedAssignment) {
        return upcomingCompletedAssignment;
    }

    const pastDueCompletedAssignments = filterPastDueCompletedAssignments(assignments);
    if (pastDueCompletedAssignments) {
        return pastDueCompletedAssignments;
    }

    return null;
}

export default AssignmentsState;
