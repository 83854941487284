import React, { useRef } from 'react';
import useFirstViewportEntry from 'utils/useFirstViewportEntry';
import LazyLoadVideoGrid from 'components/LazyLoadVideo/LazyLoadVideoGrid';

const RenderOnViewPortEntry = ({ children, threshold = 0, root = null, rootMargin = '0px 0px 0px 0px', ...wrapperDivProps }) => {
    const ref = useRef();
    const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin })
    return (
        <div {...wrapperDivProps} ref={ref}>
            {entered ? <>{children}</> : <LazyLoadVideoGrid numberOfVideoCards={3}/>}
        </div>
    );
};

export default RenderOnViewPortEntry;