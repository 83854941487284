import React from 'react';

const AllVideosSVG = () => {
    return (
        <svg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect
                x='9.5' y='15' width='13' height='3' rx='1.5' stroke='#E775EF'
                strokeWidth='1.5' />
            <rect
                x='2.5' y='8' width='20' height='3' rx='1.5' stroke='#E775EF'
                strokeWidth='1.5' />
            <rect
                x='2.5' y='1' width='20' height='3' rx='1.5' stroke='#E775EF'
                strokeWidth='1.5' />
            <path d='M6 15.634C6.66667 16.0189 6.66667 16.9811 6 17.366L3.75 18.6651C3.08333 19.05 2.25 18.5688 2.25 17.799L2.25 15.201C2.25 14.4312 3.08333 13.95 3.75 14.3349L6 15.634Z' stroke='#E775EF' strokeWidth='1.5' />
        </svg>
    );
};

export default AllVideosSVG;