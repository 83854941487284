import React, { createContext, useCallback, useContext } from 'react';
import useAxios from 'axios-hooks';
import { useDevTool } from '../lib/DevTool/DevTool';
import { ADD_PLAYLIST, UPDATE_PLAYLIST, usePlaylists, useTeacherDispatch } from './Teacher/TeacherProvider';
import { useVideoMap } from './CatalogState';
import { useParent } from './User/UserState';

const LocalStateContext = createContext();

const LocalStateProvider = LocalStateContext.Provider;

function updatePlaylistName(dispatch, updates) {
    dispatch({ type: 'start creating a playlist', updates });
}

function addVideosToPlaylist(dispatch, updates) {
    dispatch({ type: 'add video to playlist', updates });
}

function removeVideoFromPlaylist(dispatch, updates) {
    dispatch({ type: 'remove video from playlist', updates });
}

function handlePlaylistDropdown(dispatch, updates) {
    dispatch({ type: 'handle if playlist dropdown is open', updates });
}

function handleCreateNewPlaylistModal(dispatch, updates) {
    dispatch({ type: 'handle if create new playlist modal is open', updates });
}

function handlePlaylistStepsModal(dispatch, open) {
    dispatch({ type: 'handle if playlist creation steps modal is open', payload: open });
}

function resetPlaylistDropdown(dispatch) {
    dispatch({ type: 'reset' });
}

function playlistReducer(state, action) {
    console.debug('  PLAYLIST ACTION:', action);
    switch (action.type) {
        case 'start creating a playlist': {
            return {
                ...state,
                playlist: { ...state.playlist, ...action.updates },
                status: ''
            };
        }
        case 'finish creating playlist': {
            return {
                ...state,
                playlist: action.updatedPlaylist,
                status: 'resolved',
                // storedPlaylist: null,
                error: null
            };
        }
        case 'create playlist failed': {
            return {
                ...state,
                status: 'rejected',
                error: action.error
                // user: state.storedPlaylist
                // storedPlaylist: null
            };
        }
        case 'add video to playlist': {
            return {
                ...state,
                playlist: { ...state.playlist, selectedVideos: [...state.playlist.selectedVideos, action.updates] },
                status: '',
                error: action.error
            };
        }
        case 'remove video from playlist': {
            const videos = state.playlist.selectedVideos.filter(({ videoId }) => videoId !== action.updates.videoId);
            return {
                ...state,
                playlist: { ...state.playlist, selectedVideos: [...videos] },
                status: '',
                error: action.error
            };
        }
        case 'finish adding adding videos to playlists': {
            return {
                ...state,
                playlist: action.updatedPlaylist,
                status: 'resolved',
                error: null
            };
        }
        case 'handle if playlist dropdown is open': {
            return {
                ...state,
                isPlaylistDropdownOpen: action.updates,
                error: null
            };
        }
        case 'handle if create new playlist modal is open': {
            return {
                ...state,
                isCreateNewPlaylistModalOpen: action.updates,
                error: null
            };
        }
        case 'handle if playlist creation steps modal is open': {
            return {
                ...state,
                isPlaylistStepsModalOpen: !!action.payload,
                error: null
            };
        }
        case 'set playlist id': {
            return {
                ...state,
                playlist: { ...state.playlist, id: action.payload },
                error: null
            };
        }
        case 'load a playlist into state': {
            return {
                ...state,
                playlist: action.payload,
                error: null
            };
        }
        case 'reset': {
            return {
                ...state,
                playlist: { name: '', selectedVideos: [] },
                status: null,
                error: null
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function PlaylistStateProvider({ children }) {
    const [state, dispatch] = React.useReducer(playlistReducer, {
        status: null,
        error: null,
        isPlaylistDropdownOpen: false,
        isCreateNewPlaylistModalOpen: false,
        isPlaylistStepsModalOpen: false,
        playlist: {
            name: '',
            selectedVideos: []
        }
    });
    useDevTool('PlaylistState', state);
    const teacherDispatch = useTeacherDispatch();
    const playlists = usePlaylists();
    const parent = useParent();
    // save playlist
    const [{ loading: isSaving }, savePlaylist] = useAxios({ url: '/api/assignment_playlists/', method: 'POST' }, { manual: true });

    const save = useCallback(() => {
        const playlist = {
            name: state.playlist.name,
            videos: state.playlist.selectedVideos.map(({ videoId }) => videoId)
        };
        if (state.playlist.id) {
            // update existing playlist
            console.log('save existing playlist', state.playlist.id);
            return savePlaylist({ url: `/api/assignment_playlists/${state.playlist.id}/`, data: playlist, method: 'PUT' })
                .then(({ data }) => {
                    console.log('update playlist successful', data);

                    // update the playlist in the teacher state
                    if (teacherDispatch)
                        teacherDispatch({ type: UPDATE_PLAYLIST, payload: data });

                    // close the dropdown
                    handlePlaylistDropdown(dispatch, false);

                    // notify the playlist has been created
                    window._addNotification('Success!', 'Your playlist was updated.', 'gradient');

                    return data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
        console.log('save new playlist', playlist);


        return savePlaylist({ data: playlist })
            .then(({ data }) => {
                console.log('save playlist successful', data);

                // store the ID
                dispatch({ type: 'set playlist id', payload: data.id });

                // add the playlist to the teacher state
                if (teacherDispatch)
                    teacherDispatch({ type: ADD_PLAYLIST, payload: data });

                // close the dropdown
                handlePlaylistDropdown(dispatch, false);

                // notify the playlist has been created
                window._addNotification('Success!', 'Your playlist was  created.', 'gradient');

                // return the playlist
                return data;
            })
            .catch((err) => {
                console.error(err);
                throw err;
            });
    }, [state, savePlaylist, teacherDispatch, dispatch]);

    const videoMap = useVideoMap(); // getVideosWithStatsAndAssignmentsSelector

    const edit = useCallback(
        (playlist_id) => {
            if (playlist_id) {
                const playlist = playlists.find((playlist) => playlist.id === playlist_id);
                if (playlist) {
                    // format playlist data to match
                    // map selected playlist videos to the formate need in the playlist wizard
                    let selectedVideos = [];
                    console.log('getting videos', playlist.videos, videoMap);
                    selectedVideos = playlist.videos.map((videoId) => {
                        const video = videoMap[videoId];
                        if (!video)
                            return null;
                        return {
                            videoId: videoId,
                            videoName: videoMap[videoId].title,
                            videoThumbnail: videoMap[videoId].url_thumbnail
                        };
                    }).filter(video => !!video);

                    let formattedPlaylist = {
                        id: playlist_id,
                        name: playlist.name,
                        selectedVideos: selectedVideos
                    };
                    dispatch({ type: 'load a playlist into state', payload: formattedPlaylist });
                }
            }
        },
        [playlists, videoMap]
    );

    const reset = () => resetPlaylistDropdown(dispatch);

    const playlistState = [state, dispatch, save, edit, reset];
    return <LocalStateProvider value={playlistState} displayName='PlaylistStateContext'>{children}</LocalStateProvider>;
}

function usePlaylistState() {
    const context = useContext(LocalStateContext);
    if (context === undefined) {
        throw new Error('usePlaylistState must be used within an PlaylistStateProvider');
    }
    return context;
}

export {
    PlaylistStateProvider,
    usePlaylistState,
    updatePlaylistName,
    addVideosToPlaylist,
    removeVideoFromPlaylist,
    handlePlaylistDropdown,
    handleCreateNewPlaylistModal,
    resetPlaylistDropdown,
    handlePlaylistStepsModal
};
