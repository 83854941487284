import React from 'react';
import { styled } from 'twin.macro';

const PopUpStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    padding-top: 0.3rem;
    position: absolute;
    background: #6441B9;
    height: 40px;
    width: 140px;
    transition: opacity 0.3s;
    z-index: 10;
    border-radius: 5px;
    bottom: 32px;
    right: -55px;

    @media (max-width: 640px) {
        right: -10px;
    }

    &:after,
    &:before {
        top: 100%;
        left: 60%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

        @media (max-width: 640px) {
            left: 92%;
        }
    }

    &:after {
        border-color: rgba(97, 181, 231, 0);
        border-top-color: #6441B9;
        border-width: 10px;
        margin-left: -20px;
    }

    &:before {
        border-width: 13px;
        margin-left: -23px;
    }


`;

const HoverBoxHeart = ({ isHovering, children }) => {
    if (!isHovering) return null;
    return (
        <PopUpStyle >
            {children}
        </PopUpStyle>
    );
};

export default HoverBoxHeart;