import React from 'react';
import 'twin.macro';


const PageContainer = ({ children, ...props }) => {
    return (
        <div
            tw='h-full background[linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)] text-white font-sans'
            {...props}
        >
            {children}
        </div>
    );
};

export default PageContainer;

