import { QuizFinalScoreMessage } from 'app/containers/shared/QuizSessionContainer/QuizConstants';
import tw, { css, styled } from 'twin.macro';
import { useToast } from 'hooks/toast';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { level_styles, level_titles } from 'app/constants/constants';
import { useGuidance } from 'state/Guidance/GuidanceProvider';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { useProduct } from 'state/User/UserState';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { useDevTool } from '../../../../../lib/DevTool/DevTool';

import QuizPanel from '../components/QuizPanel';
import SubmitButton from '../components/SubmitButton';
import QuizFinishedMessage from '../components/QuizFinishedMessage';
import { QuizSessionPage } from '../QuizSessionPage';
import { useVideoStatsActions } from '../../../../../state/Student/VideoStats';
import { useTeacher } from '../../../../../state/User/UserState';

// the buttons used on the quiz finished screen
const ActionButton = styled.div(({ variant }) => [
    tw`font-Poppins text-center text-lg sm:text-xl rounded-lg py-4 w-full max-w-[210px] sm:max-w-[260px]`,
    variant === 'purple' && css`
    background: #6441B9;
    color: white;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.10), 0px 12px 12px 0px rgba(0, 0, 0, 0.09), 0px 27px 16px 0px rgba(0, 0, 0, 0.05), 0px 49px 19px 0px rgba(0, 0, 0, 0.01), 0px 76px 21px 0px rgba(0, 0, 0, 0.00);
    margin-left: 12px;
    border: 2px solid transparent;
    &:hover {
        border: 2px solid white;
        };
    &:active{
        border: 2px solid white;
        background: #49337C;
    } 
    `,
    variant === 'purple' && tw`ml-0 sm:ml-3 mt-4 sm:mt-0 cursor-pointer`,
    variant === 'white' && css`
    background: #FFF;
    color: #1C0057;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 3px 7px 0px rgba(0, 0, 0, 0.10), 0px 12px 12px 0px rgba(0, 0, 0, 0.09), 0px 27px 16px 0px rgba(0, 0, 0, 0.05), 0px 49px 19px 0px rgba(0, 0, 0, 0.01), 0px 76px 21px 0px rgba(0, 0, 0, 0.00);
    border: 2px solid transparent;
    &:hover {
        border: 2px solid #7345E3;
        };
    &:active{
        border: 2px solid #7345E3;
        background: #B7B6B6;
    } 
    `,
    variant === 'white' && tw`mr-0 sm:mr-3 mt-4 sm:mt-0 cursor-pointer`

]);


const QuizFinished = ({ video_id, video, quizSession, colors, quiz_id, initQuizSession, endQuizSession, hideProgressHeader }) => {
    const { quiz, instance, context } = quizSession;
    const navigate = useNavigate();
    const teacher = useTeacher();
    const timesTableProduct = useProduct('times-tables');
    const [loading, setLoading] = React.useState(false);
    const { actions: suggestedActions } = useGuidance();
    const videoLibraryWithStats = useVideosWithStats();
    const challengeAction = suggestedActions?.find(action => action.action_type === 'challenge');
    const nextQuizVideo = videoLibraryWithStats?.find(video => video.id === challengeAction?.video_id);


    // const video = useQuizVideo(quiz_id, video_id);
    // get the quiz, context, and instance
    useDevTool('quiz finished', { video, video_id, quiz, quiz_id });
    console.log('<QuizFinished>', { quizSession });

    // take another quiz
    const takeQuiz = useCallback(() => {
        // reset the quiz session and grab a new one
        console.log('take quiz clicked');
        console.assert(quiz_id, 'quiz_id missing');
        const next_quiz_id = video?.quiz_id ?? quiz_id;
        if (instance?.instance) {
            const number_correct = instance.instance.number_correct;
            const number_total = instance.instance.number_incorrect + number_correct;
            const perfect = number_correct === number_total;
            setLoading(true);
            if (perfect) {
                // take the next quiz
                const level = Math.max(1, Math.min(3, instance.level + 1));
                console.log('<QuizFinished> Next quiz level: ', level, instance.level);

                // go to the next quiz
                navigate(`/quiz/${next_quiz_id}?video_id=${video_id ?? video?.id}&level=${level}`, { replace: true });
            } else {
                // retake this quiz
                initQuizSession(quiz_id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, initQuizSession, instance, quiz_id, teacher]);

    const takeNewQuiz = useCallback(() => {
        navigate(`/quiz/${nextQuizVideo.quiz_id}`, { replace: true });
    }, [suggestedActions]);

    const handleExit = () => endQuizSession();
    const watchVideo = () => endQuizSession();

    // check the quiz session
    if (!quizSession || !quiz || !instance || !context) {
        console.error('Error: invalid quiz session', quizSession);
        return null;
    }

    // get the level details
    const level = instance.level;
    const number_correct = instance.instance.number_correct;
    const number_total = instance.instance.number_incorrect + number_correct;
    const perfect = number_correct === number_total;
    const score = (number_correct / parseFloat(number_total)) * 100; // score: 0 - 100
    // console.log('Finished: correct: ', number_correct, ' total: ', number_total);

    // construct the message
    let message = [
        <div>
            You got &nbsp;
            <span className='bold'>
                {number_correct} out of {number_total}
            </span>
            &nbsp; correct.
        </div>,
        <br />
    ];

    if (level === 0) {
        // messaging for level 0 (warmup)
        message = (
            <div>
                You got&nbsp;
                <span className='bold'>
                    {number_correct} of {number_total}
                </span>
                &nbsp; correct and
                <br />
                unlocked this video!
            </div>
        );

        let warmup_messages = [
            'You can do it! This video will help :)',
            'You’re moving in the right direction. Check out the video to learn more. ',
            'Good start! Learn even more by watching the video.',
            'Nicely done! Watch the video and keep up the good work. ',
            ['Impressive! You already know a lot about this topic.', <br />, 'Watch the video to keep crushing it :) '],
            [
                'You aced it! You’re great at this topic. ',
                <br />,
                <br />,
                'Plus, you just earned the Perfect Warmup achievement',
                <br />,
                <br />,
                'Watch the video and keep progressing.'
            ]
        ];

        const message_index = Math.max(Math.min(number_correct, 5), 0);
        // messageText = WarmupFinishedMessage[message_index];
        message = warmup_messages[message_index];
    } else {
        /*
        If got 10 of 10:
        */
        if (perfect) {
            // silver
            if (level === 1)
                message = (
                    <div className='finished'>
                        Great job. You got <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
            // gold
            else if (level === 2)
                message = (
                    <div className='finished'>
                        Amazing. You got <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
            // platinum
            else if (level === 3)
                message = (
                    <div className='finished'>
                        You’re a Muzology rockstar. <br />
                        You’ve gone <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
        } else {
            // get the message from the message list
            const message_index = Math.max(Math.min(number_correct, 10), 0);
            message.push(QuizFinalScoreMessage[message_index]);
        }
    }

    // "Watch video" is default
    const buttons = [
        <ActionButton
            key='watch-video'
            variant='white'
            level={level}
            onClick={watchVideo}
            data-testid='watch-video-button'
            aria-label='Watch Video'
        >
            Watch Video
        </ActionButton>
    ];

    // add a "re-take challenge" button
    if (level !== 0 && !(level === 3 && perfect)) {
        buttons.push(
            <ActionButton
                key='re-take-quiz'
                variant='purple'
                onClick={takeQuiz}
                data-testid='retake-challenge-button'
                aria-label='Retake Challenge'
            >
                {perfect ? 'Next Challenge' : 'Retake Challenge'}
            </ActionButton>
        );
    }

    // because of how the algorithm works for times table 
    // this will always have take a quiz as an one of the options
    // not for non TT users so hide this for them 
    if (timesTableProduct && (level === 3 && perfect)) {
        buttons.push(
            <ActionButton
                key='take-next-quiz'
                variant='purple'
                onClick={takeNewQuiz}
                data-testid='take-next-quiz'
                aria-label='take-next-quiz'
            >
                Next Challenge
            </ActionButton>
        );
    }

    return (
        <QuizSessionPage data-testid='quiz-finished-container'>
            <LoadingOverlay show={loading} fixed={true} />
            <QuizPanel
                className={`question-finished-panel ${level_styles[level]}`}
                title={quiz.title}
                level={level}
                onCancel={handleExit}
                colors={colors}
                style={{ minHeight: '600px' }}
                progress={context ? context.progress : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                hideProgressHeader={hideProgressHeader}
                data-testid='quiz-finished-panel'
                quizFinished={true}
            >
                <QuizFinishedMessage
                    key='quiz-finished-message'
                    message={message}
                    level={level}
                    startScore={0}
                    score={score}
                    buttons={buttons}
                    video={video}
                    videoTitle={video?.title}
                    correct={number_correct}
                    total={number_total}
                    watchVideo={watchVideo}
                />
            </QuizPanel>

        </QuizSessionPage>
    );
};

export default QuizFinished;
