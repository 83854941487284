import React from 'react';

const ActiveGradeSVG = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.9264 14.3036L0.798223 9.85236C0.630594 9.78531 0.630594 9.54802 0.798223 9.48097L11.9264 5.02971C11.974 5.01064 12.0272 5.01064 12.0749 5.02971L23.2031 9.48097C23.3707 9.54802 23.3707 9.78531 23.2031 9.85236L12.0749 14.3036C12.0272 14.3227 11.974 14.3227 11.9264 14.3036Z' fill='#E775EF' />
            <path fillRule='evenodd' clipRule='evenodd' d='M21.9173 15.4993C21.5952 15.4993 21.334 15.2382 21.334 14.916V9.66602H22.5007V14.916C22.5007 15.2382 22.2395 15.4993 21.9173 15.4993V15.4993Z' fill='#E775EF' />
            <path fillRule='evenodd' clipRule='evenodd' d='M6.16797 13.2207V16.7773C6.16797 18.0046 7.16289 18.9996 8.39019 18.9996H15.6124C16.8397 18.9996 17.8346 18.0046 17.8346 16.7773V13.2207L12.342 15.5454C12.1242 15.6376 11.8784 15.6376 11.6606 15.5454L6.16797 13.2207ZM10.4159 13.3898L12.0013 13.9086L13.5867 13.3898L12.0013 14.0608L10.4159 13.3898Z' fill='#E775EF' />
        </svg>
    );
};

export default ActiveGradeSVG;