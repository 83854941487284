import React, { useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import tw from 'twin.macro';
import { useLocation, useNavigate, useMatch, Route } from 'react-router-dom';
import { getProfileInitial } from 'app/util';
import { useProduct } from 'state/User/UserState';
import NavItems from './NavItems';
import { TopRightNav, TopLeftNav, SideNav, TopMobileNav } from './components';
const DTCNavbar = ({ user }) => {
    const [isMobileMenuOpen, openMobileMenu] = useState(false);
    let items = user?.is_parent ? NavItems.parent : NavItems.child;
    const initials = getProfileInitial(user);
    const noFirstName = user.user.first_name.length === 0;
    const location = useLocation();
    const isQuiz = location.pathname.startsWith('/quiz/');
    const isNotProd = process.env.REACT_APP_VERCEL_ENV !== 'production';
    const [overlayVisible, setOverlayVisible] = useState(false);
    const matchSettings = useMatch('/settings/*');
    const isPhone = useMedia('(max-width: 640px)');
    const hidSideNav = isPhone ? false : !!matchSettings;

    // determine which route we are on
    const matchParentHome = useMatch('/home/:student_id');
    const matchParentStats = useMatch('/stats/:student_id/*');
    const matchParentPlaylists = useMatch('/playlists/:course_id/:group_id/:student_id');

    // check if it is a times table product 
    const timesTablesProduct = useProduct('times-tables');

    // we need the student id if this is a parent account
    let studentId = useMemo(() => {
        if (user?.is_parent) {
            // check if we have a student id in the url
            if (matchParentHome) {
                return matchParentHome?.params?.student_id;
            } else if (matchParentStats) {
                return matchParentStats?.params?.student_id;
            } else if (matchParentPlaylists) {
                return matchParentPlaylists?.params?.student_id;
            }
        }
        return null;
    }, [matchParentHome, matchParentPlaylists, matchParentStats, user?.is_parent]);

    return (
        <nav
            aria-label='Main Navigation'
            aria-hidden={isQuiz ? 'true' : 'false'}
            css={[isQuiz && tw`hidden`]}
            tw='z-50 fixed w-full bg-[#3E257A] h-16 font-Poppins'
            data-testid='nav_bar'
        >
            {!hidSideNav && <SideNav
                items={items}
                setOverlayVisible={setOverlayVisible}
                overlayVisible={overlayVisible}
                timesTablesProduct={timesTablesProduct}
                studentId={studentId}
            />}

            <div tw='hidden md:block'>
                <div
                    tw='relative flex items-center justify-between h-16 px-6'
                    style={{ boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)' }}>
                    <TopLeftNav user={user} isNotProd={isNotProd} timesTablesProduct={timesTablesProduct} />
                    <TopRightNav
                        user={user}
                        initials={initials}
                        noFirstName={noFirstName}
                        isMobileMenuOpen={isMobileMenuOpen}
                        openMobileMenu={openMobileMenu}
                    />
                </div>
            </div>
            {!overlayVisible &&
                <div tw='md:hidden'>
                    <div
                        tw='relative items-center justify-between h-16 px-6 pt-2'
                        style={{ boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)' }}>
                        <TopMobileNav
                            noFirstName={noFirstName}
                            user={user} initials={initials} isMobileMenuOpen={isMobileMenuOpen}
                            openMobileMenu={openMobileMenu} timesTablesProduct={timesTablesProduct} />
                    </div>
                </div>
            }
        </nav>
    );
};

export default DTCNavbar;


