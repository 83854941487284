import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import PropTypes from 'prop-types';
import ReactHoverObserver from 'react-hover-observer';
import { HoverBox } from 'components';
import AchievementConstants from '../../app/constants/AchievementConstants';

const LineClamp = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const AchievementItem = (props) => {
    let { achievement, hoverBoxBackground, height, width } = props;
    const [isHovering, setIsHovering] = useState(false);
    const config = AchievementConstants[achievement.type];
    height = height ? height : '105px';
    width = width ? width : '105px';
    return (
        <ReactHoverObserver
            onHoverChanged={() => setIsHovering(true)}
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <div tw='relative ml-10 lg:ml-20 mt-3 sm:mt-0 pb-[40px]'>
                <div style={{ height: height, width: width }}>
                    <img src={config.image} alt={config.header} />
                </div>
                <HoverBox show={isHovering} backgroundColor={hoverBoxBackground}>
                    <div tw='text-lg text-white font-black'>{config.header}</div>
                    <div tw='text-lg text-white mt-1' css={[LineClamp]}>
                        {config.description}
                    </div>
                </HoverBox>
            </div>
        </ReactHoverObserver>
    );
};

AchievementItem.propTypes = {
    achievement: PropTypes.object
};

export default AchievementItem;
