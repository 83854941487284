import React from 'react';
import { styled } from 'twin.macro';

const ProgressBarContainer = styled.div`
    &.progress-bar {
        position: relative;
        height: 50px;
        width: 100%;
        background: white;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        border-bottom-left-radius: 24px;
        border-top-left-radius: 24px;

        .indicator-container {
            position: absolute;
            left: 10px;
            right: 10px;
            height: 30px;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            top: 10px;
            bottom: 10px;
            background-color: #717171;

            //background: red;
            .indicator {
                position: absolute;

                border-bottom-left-radius: 15px;
                border-top-left-radius: 15px;

                left: 0;
                top: 0;
                bottom: 0;
                right: ${(props) => props.percentage};
                //right: 0;
                background: ${(props) => props.background};
                //width: 50%;

                .position-indicator {
                    position: absolute;

                    top: -5px;
                    right: -5px;
                    //left: ${(props) => props.percentage || 0};
                    bottom: -5px;
                    width: 8px;

                    background-color: #b6bbbe;
                    border-radius: 2px;

                    //background: yellow;
                }
            }
        }
    }
`;

const ProgressBar = ({ percent, level }) => {
    let pct = 1 - (percent || 0);
    let percentage = `${pct * 100 || 0}%`;
    let showProgress = false;
    let levelColors = ['#DADADA', '#FCC510', '#2B90EA'];
    return (
        <ProgressBarContainer
            className='progress-bar'
            percentage={percentage}
            indicatorBackgroundImage='linear-gradient(to right, #e8ebee 0%, #d0d4d6 100%);'
            backgroundColor='#f0f0f0'
            background={levelColors[level - 1]}
        >
            <div className='indicator-container'>
                <div className='indicator' pose={showProgress ? 'start' : 'stop'}>
                    <div className='position-indicator ' />
                </div>
            </div>
        </ProgressBarContainer>
    );
};

export default ProgressBar;
