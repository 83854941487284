import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useClassActions, useClassStudents } from 'state/Teacher/ClassState';
import { useForm } from 'react-hook-form';
import { is_username_valid } from 'lib/util';
import { ONBOARD_STAGE_SETUP_CHILD } from 'app/AccountOnboard/OnboardStages';
import { useOnboardStageFinished } from 'state/OnboardState';
import FormError from '../FormError/FormError';
import 'twin.macro';


const ChildUserNameForm = ({ classes, setStudentName, setFirstStep, studentCount, setStudentId, childCount, setNoChildSelection, studentLimit }) => {
    const stageFinished = useOnboardStageFinished();
    const { fetchStudents } = useClassActions();

    const { addStudent } = useClassStudents(classes[0]?.id);
    const { register, handleSubmit, watch, errors: formErrors, setError, reset, clearErrors } = useForm({});
    // get a reference to the password for validation
    const password = useRef({});
    password.current = watch('password', '');

    const onSubmitFirstStageFinished = (data) => {
        if (childCount === 0 && studentLimit > 1) {
            setNoChildSelection(true);
            return;
        }
        data['state'] = 'tn';
        addStudent(data)
            .then(async ({ data }) => {
                console.log('Submit successful', data);
                const { error } = data;
                if (error) {
                    console.log('Submit error: ', error);
                    Object.keys(error).forEach((name) => {
                        setError(name, { type: 'manual', message: error[name] });
                    });
                    // setSubmitErrors(error);
                } else {
                    console.log('Submit successful', data);
                    setStudentName(data.studentprofile.user.first_name);
                    setStudentId(data.studentprofile.user.id);
                    setFirstStep(false);
                    fetchStudents();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <form>
            <div tw='max-w-[523px] mx-auto '>
                <div tw='mt-10 mb-2 font-medium  text-EarieBlack text-justify'>
                    Child's Name/Nickname
                </div>
                <input
                    tw='bg-white border-gray-200 border-2 px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                    type='text'
                    name='fullname'
                    ref={register({ required: true })}
                />
                {FormError(formErrors.fullname)}
            </div>
            <div tw='max-w-[523px] mx-auto '>
                <div tw='mt-5 mb-2 font-medium  text-EarieBlack text-justify'>
                    Child's Username <br />{' '}
                    <span tw='text-DavysGrey text-sm font-light'>(This is the username your child will use to log in to Muzology.)</span>
                </div>
                <input
                    tw='select-all bg-white border-gray-200 border-2  px-4 py-3 rounded-md placeholder-DavysGrey text-base font-medium w-full lowercase'
                    type='text'
                    name='username'
                    ref={register({
                        required: true,
                        validate: (value) => is_username_valid(value) || 'The username can only contain letters, numbers, _, @, +, . and -'
                    })}
                />
                {FormError(formErrors.username)}
            </div>
            <div tw='max-w-[523px] mx-auto '>
                <div tw='mt-5 mb-2 font-medium  text-EarieBlack text-justify'>Create Child's Password</div>
                <input
                    tw='bg-white border-gray-200 border-2  px-4 py-3 rounded-md placeholder-DavysGrey text-base font-medium w-full'
                    type='password'
                    name='password'
                    placeholder=''
                    ref={register({
                        required: true,
                        minLength: { value: 6, message: 'Password must have at least 6 characters' }
                    })}
                />
                {FormError(formErrors.password)}
            </div>
            <div tw='max-w-[523px] mx-auto '>
                <div tw='mt-5 mb-2 font-medium  text-EarieBlack text-justify'>Confirm Child's Password</div>
                <input
                    tw='bg-white border-gray-200 border-2 px-4 py-3 rounded-md placeholder-DavysGrey text-base font-medium w-full'
                    type='password'
                    name='confirmPassword'
                    placeholder=''
                    ref={register({ validate: (value) => value === password.current || 'The passwords do not match' })}
                />
                {FormError(formErrors.confirmPassword)}
            </div>
            <div tw='mt-10 flex flex-col sm:flex-row justify-center gap-10'>
                {studentCount > 1 && (
                    <div>
                        <button
                            id='skip-button'
                            tw='py-2 px-8 bg-[#E637A8] rounded-lg text-sm font-extrabold leading-normal text-white cursor-pointer min-w-[200px] min-h-[40px] w-full'
                            onClick={() => stageFinished(ONBOARD_STAGE_SETUP_CHILD)}
                        >
                            Skip for Now
                        </button>
                    </div>
                )}
                <button
                    id='submit-button'
                    tw='py-2 px-8 bg-[#1CC8E1] rounded-lg text-sm font-extrabold leading-normal text-white cursor-pointer min-w-[200px] min-h-[40px]'
                    onClick={handleSubmit(onSubmitFirstStageFinished)}
                >
                    Continue
                </button>
            </div>
        </ form>
    );
};

ChildUserNameForm.propTypes = {
    anyProp: PropTypes.any
};

export default ChildUserNameForm;

