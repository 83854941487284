import React, { useState } from 'react';
import { useMedia } from 'react-use';
import tw, { css } from 'twin.macro';

// import LogoFull from 'static/images/logo/logo_heart_tm.png';
// import LogoHeart from 'static/images/logo/logo_heart@2x.png';
import LogoFull from 'static/images/logo/logo-nav-dtc.png';
import LogoHeart from 'static/images/logo/logo_heart@2x.png';
import { NavLink, useLocation } from 'react-router-dom';
import { getProfileInitial } from 'app/util';
import BugReport from 'components/BugReport/BugReport';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import RecordCounter from 'components/RecordCounter/RecordCounter';
import { useFeatures } from '../../state/User/Features';
import { openURL } from '../../utils';
import NavItems from './NavItems';

const Navbar = ({ user }) => {
    const isFeatureEnabled = useFeatures();
    const [isMobileMenuOpen, openMobileMenu] = useState(false);
    const showSmallLogo = useMedia(user?.instructor ? '(max-width: 767px)' : '(max-width: 1055px)');
    let items = user?.is_parent ? NavItems.parent : user?.instructor ? NavItems.teacher : user.is_parent_account ? NavItems.child : NavItems.student;
    let isParent = user.is_parent_account;
    const isPhone = useMedia('(max-width: 500px)');
    if (isPhone) items = user?.is_parent ? NavItems.parentPhone : user?.instructor ? NavItems.teacherPhone : NavItems.student;
    const initials = getProfileInitial(user);
    const location = useLocation();
    const isQuiz = location.pathname.startsWith('/quiz/');
    const isNotProd = process.env.REACT_APP_VERCEL_ENV !== 'production';


    return (
        <nav
            aria-label='Main Navigation'
            aria-hidden={isQuiz ? 'true' : 'false'}
            css={[isQuiz && tw`hidden`]}
            tw='p-0.5 px-2 sm:px-4 z-50 fixed w-full bg-[#3E257A]'
            data-testid='nav_bar'
        >
            <div>
                <div tw='relative flex items-center justify-between h-16'>
                    <div
                        tw='absolute inset-y-0 left-0 flex items-center '
                        css={[
                            css`
                                @media (min-width: 900px) {
                                    display: none;
                                }
                            `
                        ]}
                    >
                        <button
                            onClick={() => openMobileMenu(!isMobileMenuOpen)}
                            type='button'
                            tw='inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                            aria-controls='mobile-menu'
                            aria-expanded='false'
                        >
                            <span tw='sr-only'>Open main menu</span>

                            {isMobileMenuOpen ? (
                                <svg
                                    tw='block h-6 w-6'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke='currentColor'
                                    aria-hidden='true'
                                >
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                                </svg>
                            ) : (
                                <svg
                                    tw='block h-6 w-6'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke='currentColor'
                                    aria-hidden='true'
                                >
                                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16' />
                                </svg>
                            )}
                        </button>
                    </div>

                    <div
                        tw='flex-1 flex items-center justify-center'
                        css={[
                            css`
                                @media (min-width: 900px) {
                                    align-items: stretch;
                                    justify-content: start;
                                }
                            `
                        ]}
                    >
                        <div tw='flex-shrink-0 flex items-center'>
                            {/* Logo */}

                            <NavLink to='/'>
                                <div tw='flex items-center justify-center'>
                                    <img id='muzology-logo' tw='h-12 w-auto' src={showSmallLogo ? LogoHeart : LogoFull} alt='Muzology Logo' />
                                </div>
                            </NavLink>
                        </div>
                        {isNotProd && (
                            <div
                                tw='mt-1'
                                css={[
                                    css`
                                        @media (max-width: 900px) {
                                            display: none;
                                        }
                                    `
                                ]}
                            >
                                <BugReport tw='ml-0' user={user}>
                                    <div
                                        tw='py-2 px-6 rounded-full uppercase text-sm font-extrabold leading-normal text-white cursor-pointer'
                                        css={[
                                            css`
                                                background: linear-gradient(90deg, #e637a8 0%, #e68137 100%);
                                            `
                                        ]}
                                    >
                                        FEEDBACK
                                    </div>
                                </BugReport>
                            </div>
                        )}
                    </div>
                    <div tw='absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0 space-x-3 md:space-x-6'>
                        <div
                            tw='hidden sm:ml-8'
                            css={[
                                css`
                                    @media (min-width: 900px) {
                                        display: block;
                                    }
                                `
                            ]}
                        >
                            <div tw='flex space-x-6 items-center'>
                                {items
                                    .filter(({ feature }) => {
                                        if (feature) {
                                            // check feature flags
                                            return isFeatureEnabled(feature);
                                        }
                                        return true;
                                    })
                                    .map(({ path, title, exact, redirect }) => {
                                        if (redirect) {
                                            // redirect to another site
                                            return (
                                                <div key={path} onClick={() => openURL(path)} tw='text-white text-lg font-extrabold cursor-pointer'>
                                                    {title}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <NavLink
                                                    key={path}
                                                    to={path}
                                                    end={exact}
                                                    aria-current='page'
                                                    tw='text-white text-lg font-extrabold'
                                                    style={({ isActive }) => {
                                                        return isActive ? { textDecoration: "underline" } : {};
                                                    }}
                                                // activeStyle={tw`border-b-2 border-white`}
                                                >
                                                    {title}
                                                </NavLink>
                                            );
                                        }
                                    })}
                                {!user?.instructor && <RecordCounter />}
                            </div>
                        </div>

                        {/* {!user?.instructor && (
                            <NotificationDropdown>
                                <button type='button' tw='rounded-full text-white hover:text-white'>
                                    <span tw='sr-only'>View notifications</span>
                                    <svg
                                        tw='h-8 w-8'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                        stroke='currentColor'
                                        viewBox='0 0 32 32'
                                        aria-hidden='true'
                                    >
                                        <path
                                            d='M24 10.666a8 8 0 00-16 0c0 9.334-4 12-4 12h24s-4-2.666-4-12zM18.307 28a2.667 2.667 0 01-4.614 0'
                                            strokeWidth='2'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </button>
                            </NotificationDropdown>
                        )} */}
                        <DropdownMenu user={user}>
                            {user?.is_parent ? (
                                <svg
                                    width='32' height='32' viewBox='0 0 32 32' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M31.231 17.736C31.294 17.166 31.334 16.588 31.334 16C31.334 15.412 31.294 14.834 31.23 14.263L26.852 12.706C26.634 11.99 26.348 11.306 26.002 10.656L27.994 6.46399C27.269 5.55485 26.4445 4.72971 25.536 4.00399L21.344 5.99999C20.6906 5.64926 20.0038 5.36452 19.294 5.14999L17.738 0.771992C17.167 0.707992 16.589 0.666992 16.001 0.666992C15.413 0.666992 14.835 0.706992 14.264 0.771992L12.708 5.14999C11.992 5.36699 11.308 5.65199 10.658 5.99999L6.46497 4.00399C5.55497 4.72899 4.73097 5.55399 4.00497 6.46399L6.00097 10.654C5.65297 11.304 5.36797 11.989 5.15097 12.704L0.772969 14.264C0.708969 14.834 0.667969 15.414 0.667969 16.001C0.667969 16.588 0.707969 17.166 0.772969 17.737L5.15097 19.295C5.36797 20.01 5.65297 20.695 6.00097 21.345L4.00597 25.537C4.73097 26.447 5.55597 27.27 6.46597 27.995L10.655 26C11.305 26.348 11.99 26.634 12.705 26.852L14.263 31.229C14.833 31.292 15.411 31.332 16 31.332C16.588 31.332 17.165 31.292 17.736 31.228L19.294 26.85C20.009 26.632 20.694 26.346 21.344 26L25.536 27.992C26.446 27.267 27.269 26.442 27.994 25.534L26.001 21.343C26.349 20.696 26.635 20.009 26.853 19.293L31.23 17.736H31.231ZM16.001 20.87C15.3613 20.8701 14.7279 20.7443 14.1368 20.4996C13.5458 20.2549 13.0088 19.8962 12.5564 19.444C11.6427 18.5307 11.1292 17.2919 11.129 16C11.1287 14.7081 11.6416 13.4691 12.5549 12.5554C13.4682 11.6417 14.7071 11.1283 15.999 11.128C17.2908 11.1277 18.5299 11.6407 19.4436 12.554C20.3573 13.4673 20.8707 14.7061 20.871 15.998C20.8712 17.2899 20.3583 18.5289 19.445 19.4426C18.5317 20.3563 17.2928 20.8697 16.001 20.87Z'
                                        fill='white'
                                    />
                                </svg>
                            ) : (
                                <button
                                    type='button'
                                    tw='text-white font-thin text-2xl bg-OceanBlue border-white border-2 h-11 w-11 flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                                    id='user-menu'
                                    aria-expanded='false'
                                    aria-haspopup='true'
                                >
                                    <span tw='sr-only'>Open user menu</span>
                                    {initials}
                                </button>
                            )}
                        </DropdownMenu>
                    </div>
                </div>
            </div>
            {isMobileMenuOpen && (
                <div
                    // tw='md:hidden'
                    id='mobile-menu'
                    css={[
                        css`
                            @media (min-width: 900px) {
                                display: none;
                            }
                            @media (max-width: 550px) {
                                height: 100vh;
                            }
                        `
                    ]}
                >
                    <div tw='pt-9 pb-6 space-y-4 sm:space-y-2 text-center'>
                        <div tw='h-0.5 bg-white mb-10 absolute left-0 right-0 bottom-0' style={{ top: '75px' }} />
                        {items.map(({ path, title, exact }) => (
                            <NavLink
                                key={path}
                                to={path}
                                end={exact}
                                aria-current='page'
                                tw='text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-lg font-extrabold'
                                activeStyle={tw`bg-PinkCrayola`}
                                onClick={() => openMobileMenu(false)}
                            >
                                {title}
                            </NavLink>
                        ))}
                        {isNotProd && (
                            <div tw='flex items-center justify-center'>
                                <BugReport tw='ml-0' user={user}>
                                    <div
                                        tw='py-2 px-6 rounded-full uppercase text-sm font-extrabold leading-normal text-white cursor-pointer'
                                        css={[
                                            css`
                                                background: linear-gradient(90deg, #e637a8 0%, #e68137 100%);
                                            `
                                        ]}
                                    >
                                        FEEDBACK
                                    </div>
                                </BugReport>
                            </div>
                        )}

                        {items === NavItems.teacherPhone ||
                            (items === NavItems.parentPhone && (
                                <div tw='text-white text-center text-lg px-5'> For the full Muzology experience, please log in using a computer or tablet.</div>
                            ))}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
