/**
 * Created by jasonduncan on 7/13/18.
 */
import React from 'react';
import tw, { styled } from 'twin.macro';

const QuizHeaderStyle = styled.div`
    //position:relative;
    //display: flex;
    //flex-direction: row;
    //justify-content: center;
    //align-items: center;

    flex-grow: 0;
    flex-shrink: 0;

    //background-image: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    font-family: Avenir, serif;

    // this Quiz Title component is only shown in the header when in mobile mode
    .quiz-header-title {
        color: #fff;
        font-family: Avenir, serif;
        font-weight: 900;

        //padding-bottom: 10px;
        //background: transparent;
        user-select: none;
        //display: none;
        //display: block;
    }

    padding: 24px 0 14px 20px;
`;


const QuizHeader = (props) => {
    // const isSmall = useMedia('(min-width: 640px)');

    return (
        <QuizHeaderStyle
            tw='block justify-between padding[24px 20px 14px 20px] sm:padding[66px 20px 24px 20px] md:padding[20px 20px 24px 20px] items-center background[linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)]'
            className={props.className}
            style={{ backgroundImage: props.backgroundImage, backgroundColor: props.backgroundColor }}
            isLoading={props.isLoading}
        >
            <div
                className='quiz-header-content'
            >
                <>
                    <div tw='text-left sm:text-center'>
                        <span tw='text-sm sm:text-xl sm:font-black'>{props.title}</span>
                    </div>
                </>

                {props.children}
            </div>

        </QuizHeaderStyle>
    );
};

export default QuizHeader;
