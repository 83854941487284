/**
 * Created by jasonduncan on 7/13/18.
 */
import React, { useCallback, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';

import Page from 'components/Page';
import {
    CONTEXT_STATE_CHECKING,
    CONTEXT_STATE_SUBMITTING,
    CONTEXT_STATE_WAIT_NEXT,
    CONTEXT_STATE_WAITING_FOR_USER, PROGRESS_ITEM_INCORRECT,
    QUESTION_TYPE_MULTIPLE_SELECT
} from 'modules/quiz/constants.js';

import SubmitButton from '../components/SubmitButton';
import Question from './Question';
import { IncorrectMessage } from './IncorrectMessage';

const QuestionPageContainer = styled(Page)`
    align-items: stretch;
    position: relative;

    .page-container {
        display: flex;
        flex-direction: column;
    }

    .question-page-contents {
        .question-section {
            //background:red;
        }

        .answer-section {
            /* background: #7569d6; */
            @media only screen and (min-width: 1200px) {
                // 1200 and over
            }
            @media only screen and (max-width: 1199px) and (min-width: 992px) {
                // 992 - 1199
            }
            @media only screen and (max-width: 991px) and (min-width: 768px) {
                // 768 - 992
                padding-right: 40px;
                padding-left: 40px;
            }
            @media only screen and (max-width: 767px) and (min-width: 401px) {
                // 767 - 401
                padding-right: 20px;
                padding-left: 20px;
            }
            @media only screen and (max-width: 400px) {
                // 400 and lower
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .submit-section {
            /* padding-top: 30px; */
            padding-bottom: 20px;
            max-width: 921px;
            display: flex;
            justify-content: center;
            //background: #7569d6;
            border-radius: 0px 0px 20px 20px;
            margin: auto;

            .button {
                width: 200px;
                background-color: #8e71e9;
            }
        }
    }

    .question-text {
        font-family: KaTeX_Main, serif;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.72);

        //margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const QuestionPage = (props) => {
    // get properties
    const {
        context,
        question,
        className,
        style,
        level,
        buttonState,
        choiceColor,
        submitButtonTextOverride,
        submitQuestion,
        nextQuestion,
        freeEntryTextChanged,
        setAnswerData,
        answerData,
        enableSubmitButton,
        submitQuestionAnswer,
        setQuestionChoice
    } = props;

    // get the button text & allow it to be overridden
    const buttonText = useMemo(() => {
        const BUTTON_TEXT = {
            [CONTEXT_STATE_WAITING_FOR_USER]: 'CHECK',
            [CONTEXT_STATE_CHECKING]: 'CHECKING...',
            [CONTEXT_STATE_WAIT_NEXT]: 'NEXT',
            [CONTEXT_STATE_SUBMITTING]: 'Please wait...'
        };

        if (submitButtonTextOverride) return submitButtonTextOverride;
        return BUTTON_TEXT[context.state] || null;
    }, [context.state, submitButtonTextOverride]);

    const handleButtonClick = useCallback(() => {
        const BUTTON_ACTION = {
            [CONTEXT_STATE_WAITING_FOR_USER]: submitQuestion,
            [CONTEXT_STATE_WAIT_NEXT]: nextQuestion
        };
        const action = BUTTON_ACTION[context.state];
        if (action) action();
    }, [context.state, nextQuestion, submitQuestion]);

    // if multiple select and the student answer only some of the selection correctly
    // show indicator they missed the other ones
    // let NotAllSelectedState = false;
    // if (context.question?.type === QUESTION_TYPE_MULTIPLE_SELECT) {
    //     if (context.progress)
    //         NotAllSelectedState = context.progress[context.question_index] === -1;
    // }
    const context_state = context?.state;

    // check if this question failed
    let thisQuestionFailed = false;
    if (context_state !== CONTEXT_STATE_WAITING_FOR_USER && context.question?.type === QUESTION_TYPE_MULTIPLE_SELECT && context.progress) {
        thisQuestionFailed = context.progress[context.question_index] === PROGRESS_ITEM_INCORRECT;
        // console.log('progress',context.progress);
    }

    // console.log('context', context);

    // get special error message for multiple select - if the question is incorrect
    let errorMessage = null;
    if (context_state !== CONTEXT_STATE_WAITING_FOR_USER && question?.type === QUESTION_TYPE_MULTIPLE_SELECT && thisQuestionFailed) {
        const question_state = context.button_state?.map((state) => state !== 0);
        const correct_choices = question.choices.map((choice) => choice.correct);
        // console.log('question_state', question_state);
        // const question_state = context.progress[context.question_index];
        // const question_state = context.button_state.map((state) => state !== 0);
        console.log('correct_choices', correct_choices, '<-> question_state', question_state);
        // questionInstructions = (<div className="quiz-question-instructions">(Answers will only appear green if ALL correct responses are selected.)</div>);
        errorMessage = <IncorrectMessage
            correct_choices={correct_choices}
            question_state={question_state}
        />;
    }

    // create the page
    return (
        <QuestionPageContainer className={`question-page ${className ?? ''}`} style={style ?? {}}>
            <div className='question-page-contents' tw='p-0'>
                <div
                    className='question-section'
                    tw='p-0 pb-4 md:pb-12 flex items-stretch flex-col flex-grow w-full sm:maxWidth[1024px] m-[0.5rem auto 0] not_cb:m-[2rem auto 0]'
                >
                    <Question
                        tw='background[blue]'
                        context={context}
                        enableAnswerButtons={context.enable_answer_buttons}
                        questionIndex={context.question_index}
                        question={question}
                        buttonState={buttonState}
                        choiceColor={choiceColor}

                        onClickChoice={setQuestionChoice}
                        submitQuestionAnswer={submitQuestionAnswer}
                        enableSubmitButton={enableSubmitButton}

                        // these are linked...
                        setAnswerData={setAnswerData}
                        answerData={answerData}

                        // these are linked...
                        onFreeEntryChanged={freeEntryTextChanged}
                        text={context && context.response ? context.response.free_entry : null}
                    />

                    {/*
                    {errorMessage && <div tw='relative w-full mt-2 mb-[-60px]'>
                        <div tw='absolute left-0 right-0 -top-2'>
                            {errorMessage}
                        </div>
                    </div>}
                    */}

                    {errorMessage && <div tw='relative w-full mt-2'>
                        {errorMessage}
                    </div>}

                    {/*
                    {NotAllSelectedState && (
                        <div tw='bg-QuizBlue flex justify-end margin[0 20px 20px 20px]'>
                            <div tw='flex justify-center items-center bg-OverdueRed w-full p-2'>
                                <svg
                                    width='19' height='19' viewBox='0 0 19 19' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.5569 9.50011L17.8277 3.24386C18.1023 2.96925 18.2566 2.5968 18.2566 2.20844C18.2566 1.82008 18.1023 1.44763 17.8277 1.17302C17.5531 0.898415 17.1807 0.744141 16.7923 0.744141C16.404 0.744141 16.0315 0.898415 15.7569 1.17302L9.50064 7.44386L3.24439 1.17302C2.96978 0.898415 2.59733 0.744141 2.20898 0.744141C1.82062 0.744141 1.44817 0.898415 1.17356 1.17302C0.89895 1.44763 0.744676 1.82008 0.744676 2.20844C0.744676 2.5968 0.89895 2.96925 1.17356 3.24386L7.44439 9.50011L1.17356 15.7564C1.03687 15.8919 0.928381 16.0532 0.854344 16.2309C0.780306 16.4086 0.742188 16.5993 0.742188 16.7918C0.742188 16.9843 0.780306 17.1749 0.854344 17.3526C0.928381 17.5303 1.03687 17.6916 1.17356 17.8272C1.30913 17.9639 1.47042 18.0724 1.64814 18.1464C1.82585 18.2204 2.01646 18.2586 2.20898 18.2586C2.40149 18.2586 2.59211 18.2204 2.76982 18.1464C2.94753 18.0724 3.10882 17.9639 3.24439 17.8272L9.50064 11.5564L15.7569 17.8272C15.8925 17.9639 16.0538 18.0724 16.2315 18.1464C16.4092 18.2204 16.5998 18.2586 16.7923 18.2586C16.9848 18.2586 17.1754 18.2204 17.3532 18.1464C17.5309 18.0724 17.6922 17.9639 17.8277 17.8272C17.9644 17.6916 18.0729 17.5303 18.1469 17.3526C18.221 17.1749 18.2591 16.9843 18.2591 16.7918C18.2591 16.5993 18.221 16.4086 18.1469 16.2309C18.0729 16.0532 17.9644 15.8919 17.8277 15.7564L11.5569 9.50011Z'
                                        fill='white'
                                    />
                                </svg>
                                <div tw='font-extrabold text-lg text-white ml-2'>
                                    <span tw='whitespace-nowrap'>Not ALL correct answers</span>
                                    &nbsp; were selected.
                                </div>
                            </div>
                        </div>
                    )}
                    */}

                </div>

                <div className='submit-section'>
                    <SubmitButton
                        title={buttonText}
                        disabled={!context.enable_submit_button}
                        onClick={handleButtonClick}
                        className={buttonText === 'NEXT' && 'aqua'}
                    />
                </div>

                {props.children}
            </div>

            {/*
            <div tw='hidden sm:block absolute bottom-0 left-0'>
                <img src={SplicedRecords(level)} alt={`level ${level}`} />
            </div>
            */}
        </QuestionPageContainer>
    );
};

export default QuestionPage;
