import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Modal from 'components/Modal/Modal';
import moment from 'moment';
import RecordImg from 'components/RecordImg';
import { level_titles } from '../../app/constants/constants';
import AchievementConstants from '../../app/containers/child/Progress/AchievementConstants';

const ScrollBarStyle = css`
    &::-webkit-scrollbar {
    width: 12px;
    background-color: #4D3095;
    border-radius: 6px;
    }

    @media (max-width: 575.98px) {
        &::-webkit-scrollbar {
        width: 6px;
        }
    }

    &::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
    border-radius: 6px;
    }
`;

const ListItemCss = css`
    li::marker {
        content: ' ';
        color: transparent;
    }
`;

const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {});
};

const ParentHomePageActivityModal = ({ isOpen, setIsOpen, selectedChild, activity, videos }) => {

    // create a list of filtered activity items with a calendar_date value converted to local time
    const activityItems = useMemo(() => {
        if (!activity) return null;

        // filter activity that shouldn't be showed in activity stream
        const activityFiltered = activity?.filter(
            (activity) =>
                activity.name !== 'user:login' && activity.name !== 'quiz:started' && activity.name !== 'route:changed' && activity.name !== 'video:start'
        );
        // attach a calendar_date
        return activityFiltered.map((item) => {
            const datestamp = moment.utc(item.date).local().calendar(null, {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: '[Last] dddd',
                nextWeek: 'dddd',
                sameElse: 'L'
            });
            return { ...item, calendar_date: datestamp };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // group into date buckets
    const activityGroupedByDate = groupBy(activityItems, 'calendar_date');

    // sort the dates
    let orderedDates = {};
    Object.keys(activityGroupedByDate)
        .sort((a, b) => {
            return moment(a, 'DD/MM/YYYY').toDate() - moment(b, 'DD/MM/YYYY').toDate();
        })
        .forEach((key) => {
            orderedDates[key] = activityGroupedByDate[key];
        });

    // helper to sort type of activity
    const ActivityTypeHelper = (item, date) => {
        switch (item.name) {
            case 'video:watched': {
                const video = videos.find((video) => video.id === item.data.video_id);
                const within_a_day = moment(item.date).diff(moment(), 'hours') > -25;
                let timeTitle = `${item.calendar_date}`;
                if (within_a_day) timeTitle = `${moment(item.date).fromNow()}`;
                return (
                    <li tw='text-sm md:text-lg text-white mt-4 pb-6  border-b-2 border-[#1C0057] flex flex-col md:flex-row items-start md:items-center gap-2 '>
                        <span>
                            <div tw='bg-white flex justify-center items-center rounded-full h-11 w-11'>
                                <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M14.7607 8.12251C15.4274 8.50741 15.4274 9.46966 14.7607 9.85456L2.35313 17.0181C1.68646 17.403 0.853133 16.9219 0.853133 16.1521L0.853134 1.825C0.853134 1.0552 1.68647 0.574074 2.35313 0.958974L14.7607 8.12251Z' fill='#7959FA' />
                                </svg>
                            </div>
                        </span>
                        <div tw='ml-0 md:ml-5'>Watched "{video?.title}"</div>
                        <div tw='text-xs text-[#BBCCBB] flex items-center ml-0 lg:ml-auto'>{timeTitle}</div>

                    </li>
                );
            }
            case 'achievement:created': {
                const video = videos.find((video) => video.id === item.data.videoId);
                const achievementTitle = AchievementConstants[item.data.achievement].header;
                const achievementImage = AchievementConstants[item.data.achievement].image;
                const within_a_day = moment(item.date).diff(moment(), 'hours') > -25;
                let timeTitle = `${item.calendar_date}`;
                if (within_a_day) timeTitle = `${moment(item.date).fromNow()}`;

                return (
                    <li tw='text-sm md:text-lg text-white mt-4 pb-6  border-b-2 border-[#1C0057] flex flex-col md:flex-row items-start md:items-center gap-2 '>
                        <div tw='max-w-[48px] mr-0 md:mr-5'>
                            <img tw='w-full h-auto' src={achievementImage} alt='record' />
                        </div>
                        <div tw='whitespace-normal md:whitespace-nowrap overflow-hidden text-ellipsis max-w-[550px]'>Earned the {achievementTitle} for "{video?.title}"</div>
                        <div tw='text-xs text-[#BBCCBB] flex items-center ml-0 lg:ml-auto'>{timeTitle}</div>
                    </li>
                );
            }
            case 'quiz:taken': {
                const video = videos.find((video) => video.id === item.data.videoId);
                const score = item.data.score;
                const hideRecord = score > 90;
                const within_a_day = moment(item.date).diff(moment(), 'hours') > -25;
                let timeTitle = `${item.calendar_date}`;
                if (within_a_day) timeTitle = `${moment(item.date).fromNow()}`;
                if (item.data.quizLevel === 0) {
                    return (
                        <li tw='text-sm md:text-lg text-white mt-4 pb-6  border-b-2 border-[#1C0057] flex flex-col md:flex-row items-start md:items-center gap-2 '>
                            <div>
                                Scored {score}% on "{video?.title}" Warm Up
                            </div>
                            <div tw='text-xs text-[#BBCCBB] flex items-center ml-0 lg:ml-auto'>{timeTitle}</div>

                        </li>
                    );
                } else
                    return (
                        <li tw='text-sm md:text-lg text-white mt-4 pb-6  border-b-2 border-[#1C0057] flex flex-col md:flex-row items-start md:items-center gap-2 '>
                            <div>
                                <span tw='mx-1'>
                                    {hideRecord && <RecordImg level={item.data.quizLevel} dtc={true} tw='mr-5' style={{ width: '48px', height: 'auto', display: 'inline' }} />}
                                </span>
                                Got {score}%

                                on "{video?.title}" {level_titles[item.data.quizLevel]} Challenge
                            </div>
                            <div tw='text-xs text-[#BBCCBB] flex items-center ml-0 lg:ml-auto'>{timeTitle}</div>

                        </li>
                    );
            }
        }
    };

    // loop over grouped => sorted activity to create activity stream
    const createActivity = (sortedActivity) => {
        return Object.keys(sortedActivity).map((date, key) => {
            return (
                <div key={key}>
                    <div tw='text-base text-[#BBCCBB] my-6' css={[key === 0 && tw`mt-0`]}>{date}</div>
                    {sortedActivity[date].map((item, key) => {
                        return (
                            <ul css={[ListItemCss]} tw='list-inside' key={key}>
                                {ActivityTypeHelper(item, date)}
                            </ul>
                        );
                    })}
                </div>
            );
        });
    };



    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full max-w-[920px] mx-auto`}>
            <div tw='mx-4 relative font-Poppins'>
                <div tw='bg-[#3E257A] p-3 md:p-6 rounded-xl'>
                    <div tw='text-white font-bold text-xl text-center pt-6 px-12 flex'>
                        <div tw='flex-1'>{selectedChild?.user.first_name}'s' Activity</div>
                        <div tw='cursor-pointer' onClick={() => setIsOpen(false)}>
                            <svg
                                width='24' height='24' viewBox='0 0 24 24' fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M4.4254 4.44406C4.69787 4.17137 5.06737 4.01818 5.45264 4.01818C5.83791 4.01818 6.20741 4.17137 6.47988 4.44406L11.9909 9.96115L17.502 4.44406C17.636 4.30513 17.7963 4.19432 17.9736 4.11809C18.1508 4.04186 18.3415 4.00173 18.5344 4.00005C18.7273 3.99838 18.9187 4.03518 19.0972 4.10831C19.2758 4.18145 19.438 4.28945 19.5744 4.42603C19.7109 4.5626 19.8187 4.725 19.8918 4.90376C19.9649 5.08252 20.0016 5.27405 19.9999 5.46718C19.9983 5.66032 19.9582 5.85118 19.882 6.02864C19.8059 6.2061 19.6952 6.36661 19.5564 6.50078L14.0454 12.0179L19.5564 17.535C19.8211 17.8093 19.9675 18.1767 19.9642 18.5581C19.9609 18.9395 19.8081 19.3043 19.5387 19.574C19.2693 19.8437 18.9049 19.9966 18.524 19.9999C18.143 20.0033 17.776 19.8566 17.502 19.5917L11.9909 14.0746L6.47988 19.5917C6.20585 19.8566 5.83883 20.0033 5.45787 19.9999C5.07691 19.9966 4.71249 19.8437 4.4431 19.574C4.17371 19.3043 4.02091 18.9395 4.0176 18.5581C4.01429 18.1767 4.16074 17.8093 4.4254 17.535L9.93645 12.0179L4.4254 6.50078C4.15302 6.22801 4 5.85811 4 5.47242C4 5.08673 4.15302 4.71682 4.4254 4.44406Z' fill='#9D91BB' />
                            </svg>
                        </div>
                    </div>
                    <div tw='h-full mt-4 px-8 lg:px-12 max-h-[475px] overflow-y-auto' css={[ScrollBarStyle]}>
                        <div tw='pt-1 pb-4 pr-2'>{createActivity(orderedDates)}</div>
                    </div>
                </div>


            </div>
        </Modal>
    );
};

ParentHomePageActivityModal.propTypes = {
    anyProp: PropTypes.any
};

export default ParentHomePageActivityModal;

