import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { css } from 'twin.macro';
import { useSearch } from '../../context/SearchProvider';


const SearchInput = (props) => {
    const { search, query, setDropDown } = useSearch();
    const [isFocused, setIsFocused] = useState(false);

    const debounced = useDebouncedCallback(
        // function
        (value) => {
            props.inputSelected(value);
        },
        // delay in ms
        1000
    );
    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            search(event.target.value);
            setDropDown(false);
        }
    };

    const handleChange = (event) => {
        if (props.inputSelected) debounced(event.target.value);
        search(event.target.value); setDropDown(true);
        setDropDown(true);

        // If the input is empty, call the onInputEmpty prop
        if (props.onInputChange) props.onInputChange(event.target.value);
    };

    // Reset Input Field handler
    const resetInputField = useCallback(() => {
        search(null);
    }, [search]);

    useEffect(() => {
        if (!props.search) resetInputField();
    }, [props.search, resetInputField]);

    return (
        <>
            <div tw='w-full hidden md:block relative min-w-[400px]'>
                <input
                    tw='py-2.5 pl-4 bg-[#1C0057] rounded-lg w-full text-[#9D91BB] '
                    // type='search'
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={isFocused ? '' : 'Search All Videos'}
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    value={query ?? ''}
                    autoComplete='disabled'
                    css={[
                        css`
                        &:focus-visible {
                            outline-offset: 0px !important;
                        }
                    `
                    ]} />
                {query ?
                    <div onClick={resetInputField}>
                        <svg
                            tw='w-2.5 h-2.5 absolute right-3 top-1/3' width='10' height='10' viewBox='0 0 10 10'
                            fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M9.92 1.42L8.5 0L4.96 3.55L1.42 0L0 1.42L3.55 4.96L0 8.5L1.42 9.92L4.96 6.37L8.5 9.92L9.92 8.5L6.37 4.96L9.92 1.42Z' fill='#9D91BB' />
                        </svg>
                    </div>
                    :
                    <svg
                        tw='w-4 h-4 absolute right-3 top-1/4' width='21' height='20' viewBox='0 0 21 20'
                        fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M20 18.9999L17 15.9999' stroke='#9D91BB' strokeWidth='1.5' strokeLinecap='round' />
                        <circle
                            cx='10' cy='9.99994' r='9' stroke='#9D91BB'
                            strokeWidth='1.5' />
                    </svg>}


            </div>
            
            
            <div tw='w-full relative md:hidden min-w-[200px]'>
                <input
                    tw='py-2.5 pl-4 bg-[#1C0057] rounded-lg w-full text-[#9D91BB] '
                    // type='search'
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder={isFocused ? '' : 'Search All Videos'}
                    onKeyDown={handleKeyPress}
                    onChange={handleChange}
                    value={query ?? ''}
                    css={[
                        css`
            &:focus-visible {
                outline-offset: 0px !important;
            }
        `
                    ]} />
        


            </div>
        </>
    );
};

export default SearchInput;
