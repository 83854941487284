import { PrimaryButton } from 'app/ui/buttons';
import React from 'react';
import { styled } from 'twin.macro';
import RightArrow from 'images/icons/RightArrow';

export const SubmitButtonStyle = styled(PrimaryButton)`
	background-image: none;
	font-weight: 500;
	font-size: 18px;
	height: 48px;
	min-width: 150px;
	border-radius: 8px;
	line-height: normal;
	padding: 0;
	margin: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	color: #1D1C21;
	text-shadow: none !important;

	.right-arrow {
		font-size: 20px;
		margin-left: 10px;
	}

    &:focus-visible {
        outline-style: solid;
        outline-color: red;
    }

	// normal - pink

	&:hover {
		background-image: none;
		background-color: #6441B9;
		color: white;
	}
    
    :hover {
        background: none;
    }
    
	&:active {
		background-image: none;
		background-color: #404040;
		color: white;
	}

	// purple
	&.purple {
		background-color: #8f71ea;
		border: 4px solid #8f71ea;

		&:hover {
			background-color: #8f71ea;
			border: 4px solid #341983;
			color: white;
		}

		&:active {
			background-color: #341983;
			border: 4px solid #341983;
			color: white;
		}
	}

	// aqua
	&.aqua {
        background-color: #404040;
		color: white;

		&:hover {
			background-color: #4b4444;

			color: white;
		}

		&:active {
			background-color: #4c4444;
			color: white;
		}
	}

	&.disabled {
		background: #dedede;
		color: #b4b4b4;
		border: 4px solid transparent;
		cursor: not-allowed;
		pointer-events: none;
	}
`;

const SubmitButton = ({ title, onClick, className, disabled, backgroundColor, children }) => {
    return (
        <SubmitButtonStyle
            disabled={disabled}
            aria-disabled={disabled}
            tabIndex={disabled ? -1 : 2}
            onClick={onClick}
            className={`quiz-styled-button ${className || ''} ${disabled ? 'disabled' : ''}`}
            backgroundColor={backgroundColor}
            tw='font-Poppins'
        >
            {title || children}
        </SubmitButtonStyle>
    );
};

export default SubmitButton;
