import React, { useCallback } from 'react';
import { useMeasure } from 'react-use';
import tw from 'twin.macro';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { tsParticles } from 'tsparticles-engine';

let default_params = {
    background: {
        color: {
            value: '#0d47a1'
        }
    },
    // width: '50%',
    // height: '50%',
    // fullScreen: false,
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: 'push'
            },
            onHover: {
                enable: true,
                mode: 'repulse'
            },
            resize: true
        },
        modes: {
            push: {
                quantity: 4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: {
            value: '#ffffff'
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        collisions: {
            enable: true
        },
        move: {
            directions: 'none',
            enable: true,
            outModes: {
                default: 'bounce'
            },
            random: false,
            speed: 6,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 80
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: 'circle'
        },
        size: {
            value: { min: 1, max: 5 }
        }
    }
};

const defaults = {
    background: {
        color: {
            value: '#0d47a1'
        }
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: 'push'
            },
            onHover: {
                enable: true,
                mode: 'repulse'
            },
            resize: true
        },
        modes: {
            push: {
                quantity: 4
            },
            repulse: {
                distance: 200,
                duration: 0.4
            }
        }
    },
    particles: {
        color: {
            value: '#ffffff'
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1
        },
        collisions: {
            enable: true
        },
        move: {
            directions: 'none',
            enable: true,
            outModes: {
                default: 'bounce'
            },
            random: false,
            speed: 6,
            straight: false
        },
        number: {
            density: {
                enable: true,
                area: 800
            },
            value: 80
        },
        opacity: {
            value: 0.5
        },
        shape: {
            type: 'circle'
        },
        size: {
            value: { min: 1, max: 5 }
        }
    },
    detectRetina: true
};

const ParticleBackground = ({ className, params, style }) => {
    const [ref, { width, height }] = useMeasure();
    console.log('particles:', params);
    // let newStyle = {
    //     position: 'absolute',
    //     left: 0,
    //     top: 0,
    //     bottom: 0,
    //     right: 0,
    //     background: 'black',
    //     ...(style)
    // };

    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    // return (
    //     <Particles id='tsparticles' url='http://foo.bar/particles.json' init={particlesInit} loaded={particlesLoaded} />
    // );

    return (
        <div
            id='particles'
            ref={ref}
            className={`relative particle-background-container ${className || ''}`}
            data-testid='particles-background'
            // style={newStyle}
        >
            <Particles
                id='tsparticles'
                className='particle-background'
                // tw='bg-red-400'
                // url='http://foo.bar/particles.json'
                init={particlesInit}
                loaded={particlesLoaded}
                // options={{
                //     particles: { ...(params ?? default_params) }
                // }}
                options={default_params}
                // width={`${width}px`}
                // height={`${height}px`}
            />
        </div>
    );
};

export default ParticleBackground;
