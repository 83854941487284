import moment from 'moment';
import React from 'react';
import { CircleCheckIcon, CirclePlayButton } from 'app/images/icons';
import 'twin.macro';

export const PlayButtonOverlay = (props) => {
    return (
        <div
            className='assignment-overlay play-button-overlay'
            style={{ opacity: props.isHovering ? '1' : '0' }} tw='rounded-tl-lg rounded-tr-lg'>

            <div className='play-button-container'>
                <CirclePlayButton
                    color='#ffffff'
                    className='play-button' />
            </div>
        </div>
    );
};

export const CompletedOverlay = (props) => {
    return (
        <div className='completed-overlay'>
            <div className='checked-icon-container'>
                <CircleCheckIcon
                    color='#ffffff'
                    width='40%' />
            </div>
        </div>
    );
};

export const AssignmentOverlay = (props) => {
    if (!props.assignments) {
        return null;
    }

    let challenge_goal;
    let goal;
    let due;

    let assignments = props.assignments;
    assignments.forEach((i) => {
        return (challenge_goal = i.item.quiz_level_goal);
    });
    assignments.forEach((i) => {
        return (goal = i.item.quiz_score_goal);
    });
    assignments.forEach((i) => {
        return (due = moment.utc(i.assignment.due).local().format('MMMM DD'));
    });

    let title = ['', 'Silver', 'Gold', 'Platinum'];

    let info = `Get ${goal} % on ${title[challenge_goal]}`;
    return (
        <div
            className='assignment-overlay'
            style={{ opacity: props.isHovering ? '1' : '0' }}>
            <div className='play-button-container'>
                <CirclePlayButton
                    color='#ffffff'
                    className='play-button' />
            </div>
            <div className='info'>
                <div className='info-title'> Assignment Details</div>
                <div className='info-due'>{due}</div>
                <div className='info-desc'>{info}</div>
            </div>
        </div>
    );
};
