import React from 'react';
import 'twin.macro';
import tw, { css, styled } from 'twin.macro';
import * as keys from './keys';

const KeyboardContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .free-entry-keyboard {
        .free-entry-keyboard-row {
            .btn-default {
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;

                // smallest
                font-size: 20px;
                width: 35px;
                height: 35px;

                // 350 & up
                @media only screen and (min-width: 350px) {
                    font-size: 20px;
                    width: 40px;
                    height: 40px;

                }

            }
        }
    }
`;

const DeleteCSS = css`
    width: 115px !important; 
    margin-left: 20px;

    @media (max-width: 768px) {
        margin-left: 0px;
        /* font-size: 10px;
        width: 36px !important; 
        height: 36px !important; */

    }
    @media (max-width: 500px) {
        font-size: 10px;
        width: 36px !important; 
        height: 36px !important;
    }


`

const Button = styled.button`
    background: #4D3095;
    border-radius: 0;
    margin: 2px;
    // smallest
    font-size: 18px;

    // 350 & up
    @media only screen and (min-width: 350px) {
        font-size: 20px;
        //width: 40px;
        //height: 40px;
    }

    font-weight: 600;
    border-radius: 4.525px;
    color: #fff;
    /* background-image: linear-gradient(180deg, #ffffff 0%, #c3c7ca 100%); */
    border: 2px solid #C4B1E5;

    &:hover {
        background: #6441B9;
        box-shadow: 0px 0px 59.215px 0px rgba(224, 179, 255, 0.50);
    }

    &:active {
        background: #6441B9;
    }

    &:focus {
        border: 2px solid #a1a2a2;
        outline: none;
    }
`;

// the keys placed into rows and columns
const KeyboardLayout = [
    [keys.KEY_7, keys.KEY_8, keys.KEY_9, keys.KEY_X, keys.KEY_Y, keys.KEY_EQUAL, keys.KEY_INFINITY, keys.KEY_EXPONENT, keys.KEY_DELETE],
    [keys.KEY_4, keys.KEY_5, keys.KEY_6, keys.KEY_ADD, keys.KEY_SUBTRACT, keys.KEY_MULTIPLY, keys.KEY_DIVIDE, keys.KEY_SLASH],
    [keys.KEY_1, keys.KEY_2, keys.KEY_3, keys.KEY_GREATER, keys.KEY_GREATER_EQUAL, keys.KEY_LESS, keys.KEY_LESS_EQUAL, keys.KEY_UNEQUAL],
    [keys.KEY_PERIOD, keys.KEY_0, keys.KEY_COMMA, keys.KEY_PAREN_OPEN, keys.KEY_PAREN_CLOSED, keys.KEY_COLON, keys.KEY_DOLLAR, keys.KEY_PERCENT],

];

// build the keyboard from arrays of rows and columns
const Keyboard = ({ onKey }) => {
    // const [containerRef, { width, height }] = useMeasure();
    // const keySize = useMemo(() => {
    //     return width / 10;
    // }, [width]);

    return (
        <KeyboardContainer>
            {KeyboardLayout.map((row, rowIndex) => (
                <div key={rowIndex} className='free-entry-keyboard-row' tw='flex items-center justify-center'>
                    {row.map((key) => (
                        <Button
                            id={key.value}
                            key={key.key}
                            tw='w-9 h-9 xsm:w-14 xsm:h-14 font-Poppins z-30'
                            css={[
                                key.value === 46 && DeleteCSS,
                                key.value === 7 && tw`ml-0 md:ml-[135px]`,
                            ]}
                            className='keypad-button'
                            onClick={onKey ? () => onKey(key) : null}
                            dangerouslySetInnerHTML={{ __html: key.key }}
                            data-key-value={key.value}
                            data-key-key={key.value}
                        />
                    ))}

                </div>
            ))}


        </KeyboardContainer>
    );
};

export default Keyboard;
