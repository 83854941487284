import React, { useMemo, useState, useEffect } from 'react';
import tw, { css } from 'twin.macro';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import styled from 'styled-components';
import LoadingOverlay from 'components/LoadingOverlay';
import { useUser, useTeacher, useUserAction } from 'state/User/UserState';
import { FontFamilySelectBox } from 'bitmovin-player-ui';
import { showError } from '../../../../AccountOnboard/Components/Components';
import { apiClient, handleAPIError } from '../../../../../utils/api-client';
import { useClassActions } from '../../../../../state/Teacher/ClassState';
import { useToast } from '../../../../../hooks/toast';
import StateSelectListBox from '../../../../../components/StateSelectListBox/StateSelectListBox';
import ResetStudentPassword from './Modal/ResetStudentPassword';
import { useProduct } from 'state/User/UserState';


export const studentGradeToValue = (grade) => {
    // convert from studentprofile.grade to value for select
    if (!grade)
        return null;
    return String(grade);
};

export const valueToStudentGrade = (value) => {
    // convert from value for select to studentprofile.grade
    if (!value)
        return null;
    if (value === '0')
        return 0;
    return parseInt(value);
};

export const studentGradeToString = (grade) => {
    if (!grade)
        return null;
    if (grade === 0)
        return 'K';
    if (grade >= 13)
        return 'Other';
    return String(grade);
};


const GradesContainer = styled.div`
    flex: 1 1 100%;
    min-width: calc(20% - 0px);
    max-width: calc(20% - 0px);

    .radiobutton-grid {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 24px 0 0;

        .checkbox-item {
            /* width: 50%; */
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            @media (max-width: 700px) {
                width: 100%;
            }
        }

        .container {
            display: block;
            position: relative;
            margin: 0;
            padding-left: 35px;
            margin-bottom: 15px;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            line-height: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: 30px;
            
            @media (max-width: 640px) {
                padding-left: 27px;
        
            }

            .title {   
                line-height: normal;
                a {
                    color: white;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }

        /* Hide the browser's default checkbox */

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */

        .radiobutton {
            position: absolute;
            top: 0;
            left: 0;
            height: 27px;
            width: 27px;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 50%;

            @media (max-width: 640px) {
                height: 20px;
                width: 20px;
            }
        }

        /* On mouse-over, add a grey background color */

        .container:hover input ~ .radiobutton {
            background: white;
        }

        /* When the checkbox is checked, add a blue background */

        .container input:checked ~ .radiobutton {
            border: 1px solid #fff;
        }

        .container input:checked ~ .radiobutton::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 21px; /* Adjust the size of the inner white dot */
            height: 21px;
            background-color: white;
            border-radius: 50%;

            @media (max-width: 640px) {
                height: 20px;
                width: 20px;
            }
        
          }
    }

    .mobile-style {
        @media (max-width: 400px) {
            margin-bottom: 30px;
        }
    }
`;

const CheckBox = styled.div`
    .checkbox-item {
        /* width: 50%; */
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        margin-left: 0px;
        @media (max-width: 700px) {
            width: 100%;
        }
        @media (max-width: 640px) {
            margin-top: 25px;
        }
    }

    .container {
        display: block;
        position: relative;
        margin-left: 0;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        max-height: 30px;
        max-width: 500px;
        margin: 16px;
        margin-bottom: 15px;
        @media (max-width: 640px) {
            font-size: 16px;
        }

        .title {
            font-weight: 400;
            font-size: 18px;
            margin-left: 35px;
            line-height: normal;
            color: #fff;
            text-align: left;
            @media (max-width: 640px) {
                font-size: 16px;
            }

            a {
                color: white;
                text-decoration: underline;
                white-space: nowrap;
            }
        }
    }

    /* Hide the browser's default checkbox */

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 27px;
        width: 27px;
        background-color: #fff;
        border: 1px solid #1A1717;
        border-radius: 99px;
    }

    /* On mouse-over, add a grey background color */

    .container:hover input ~ .checkmark {
        background: #1cc8e1;
        border: 1px solid #1cc8e1;
    }

    /* When the checkbox is checked, add a blue background */

    .container input:checked ~ .checkmark {
        background: #1cc8e1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */

    .container .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }


    .mobile-style {
        @media (max-width: 400px) {
            margin-bottom: 30px;
        }
    }
`;
const AUTH_LOGIN = 1;

const SettingInfo = ({ }) => {
    const teacher = useTeacher();
    const user = useUser();
    console.log("🚀 ~ SettingInfo ~ user:", user)
    const { saveSettings } = useUserAction();
    const { updateStudent } = useClassActions();
    const { updateUser } = useUserAction();
    const timesTableProduct = useProduct('times-tables');

    const [isEdited, setIsEdited] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const studentName = user?.user?.first_name;
    const [loading, setLoading] = useState(false);
    const addToast = useToast();
    const [state, setState] = useState(user?.student?.state);
    const editPassword = useMemo(() => !user.auth_method || user.auth_method === 0 || user.auth_method === AUTH_LOGIN, [user.auth_method]);
    const [listBoxDirty, setDirtyListBox] = useState(false);

    const { register, handleSubmit, watch, errors: formErrors, setError, setValue, formState, reset } = useForm({
        defaultValues: {
            name: `${user?.user.first_name} ${user?.user.last_name}`,
            grade: studentGradeToValue(user?.student?.grade),
            // state: user?.student?.state
        }
    });
    console.log("🚀 ~ SettingInfo ~ formErrors:", formErrors)

    const disabled = listBoxDirty || formState.isDirty;

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        setDirtyListBox(false);
        setValue('name', `${user?.user.first_name} ${user?.user.last_name}`, { shouldDirty: false });
        setValue('grade', studentGradeToValue(user?.grade), { shouldDirty: false });
        setValue('state', user?.state, { shouldDirty: false });
        setState(user.state);
    }, [setValue, user]);

    const selectState = (data) => {
        setValue('state', data.abbreviation, { shouldDirty: true });
        setState(data.abbreviation);
        setDirtyListBox(true);
    };


    // save data
    const onSubmit = (data) => {
        // TODO: handle invalid username
        // save the data for this student

        console.log('Payload:', { user_id: user?.user?.id, state, ...data }); // debug line

        setLoading(true);
        const payload = { state, ...data };
        apiClient.post('/api/studentprofile/', payload)
            .then(response => {
                const { data } = response;
                console.log(data);
                if (data?.user?.id) {
                    // save the student and close the dialog
                    updateUser();
                    setLoading(false);
                    addToast('Success!', 'Your information is updated.');
                } else {
                    console.error('error saving student profile', data);
                    setLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);
                setLoading(false);
            });
    };

    const gradeChoices = useMemo(() => {
        const checkbox = Array(timesTableProduct ? 10 : 9).fill(0);
        return checkbox.map((x, index) => {
            let label = (index + 2).toString();
            let value = String(index + 2);
            if (timesTableProduct) {
                label = (index - 1).toString();
                value = String(index - 1);

            }
            if (index === 0) {
                label = 'K-2';
                if (timesTableProduct) {
                    label = 'Pre K'
                    value = 'Pre K'
                }
            }
            if (index === 1 && timesTableProduct) {
                label = 'K'
                value = 'K'
            }
            if (index === 8 && !timesTableProduct) {
                label = '10-12+';
            }
            if (index === 9 && timesTableProduct) {
                label = '8+'
                value = '8+'

            }

            return (
                <GradesContainer key={index}>
                    <div className='radiobutton-grid'>
                        <label className='container'>
                            <div className='title' tw='text-base sm:text-lg font-normal sm:font-semibold mr-2 sm:mr-8'>{label}</div>
                            <input
                                type='radio'
                                name='grade'

                                ref={register({
                                    required: false
                                    // validate: (value) => {
                                    //     console.log('validate', value);
                                    //     return (value.length ? true : 'Please select at least one grade');
                                    // }
                                })}
                                value={String(value)}
                                data-test={`RadiobuttonCypressId${label}`}
                            />
                            <span className='radiobutton' />
                        </label>
                    </div>
                </GradesContainer>
            );
        });
    }, [register]);


    return (
        <div tw='relative h-full'>

            <LoadingOverlay show={loading} fixed={true} />
            <div tw='bg-[#1C0057] sm:bg-[#3E257A] sm:py-6 rounded-xl'>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' id='settings'>
                    <React.Fragment>
                        <div tw='sm:mt-5 mb-2 font-bold text-base xsm:text-lg  text-white'>
                            Child's Name
                        </div>
                        <input
                            tw='bg-[#1C0057] sm:bg-[#3E257A] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full md:w-2/3 max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                            type='input'
                            name='name'
                            placeholder='Your Name'
                            ref={register({ required: false })}
                        />
                        {showError(formErrors.name)}

                        <div tw='mt-5 mb-2 font-bold text-base xsm:text-lg  text-white'>
                            Username
                        </div>
                        <div tw='bg-[#1C0057] sm:bg-[#3E257A] border-[#B5AAD0] border-2 px-4 py-3 w-full md:w-2/3 max-w-[600px] rounded-md text-sm font-medium flex justify-between gap-2 text-[#B5AAD0]'>
                            <div>{user?.user.username}</div>
                            <div>
                                <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 26 26' fill='none'>
                                    <path fillRule='evenodd' clipRule='evenodd' d='M0.5625 10.8867C0.5625 9.78215 1.45793 8.88672 2.5625 8.88672H23.875C24.9796 8.88672 25.875 9.78215 25.875 10.8867V23.1155C25.875 24.22 24.9796 25.1155 23.875 25.1155H2.5625C1.45793 25.1155 0.5625 24.22 0.5625 23.1155V10.8867ZM13.9688 17.2995C14.6297 17.0214 15.0916 16.3869 15.0916 15.6487C15.0916 14.654 14.2531 13.8476 13.2187 13.8476C12.1844 13.8476 11.3459 14.654 11.3459 15.6487C11.3459 16.3869 11.8078 17.0214 12.4688 17.2995V19.7059C12.4688 20.1201 12.8045 20.4559 13.2188 20.4559C13.633 20.4559 13.9688 20.1201 13.9688 19.7059V17.2995Z' fill='white' />
                                    <path d='M7.59375 8.88586V6.39648C7.59375 3.28988 10.1121 0.771484 13.2188 0.771484H13.8725C16.6181 0.771484 18.8438 2.99718 18.8438 5.74271V5.74271' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                                </svg>
                            </div>
                        </div>

                        {editPassword && <div tw='font-bold text-base xsm:text-lg text-[#FFF] underline mt-6 cursor-pointer' onClick={() => setIsOpen(true)}>
                            Change Password
                        </div>}

                        <div tw='mt-10 mb-2  font-bold text-base xsm:text-lg text-white'>Grade Level</div>
                        <div tw='flex flex-wrap text-white'>{gradeChoices}</div>
                        {/* {showError(formErrors.grade)} */}

                        <div tw='mt-10 mb-2 font-bold ml-0 mx-auto max-w-[380px] text-white text-base xsm:text-lg'>
                            {/*{studentName}’s state.*/}
                            State
                        </div>
                        <div tw='mt-2 mx-auto flex'>
                            <StateSelectListBox
                                tw='max-w-[420px]'
                                selected={state}
                                onSelectItem={selectState}
                                cypressName='state-selection'
                                user={user}
                            />
                        </div>
                        {showError(formErrors.state)}

                    </React.Fragment>
                    <div tw='w-max mt-6'>
                        <Button
                            type='submit'
                            tw='bg-[#3E257A] z-60 border-[#B5AAD0] border-[1px] rounded-md text-[#B5AAD0] font-Poppins font-medium  cursor-not-allowed'
                            css={[disabled && tw`bg-[#FEEA50] border-[#404040] text-[#404040] cursor-pointer`]}
                            disabled={!disabled}
                        >
                            Save Changes
                        </Button>

                    </div>
                </form>
            </div>
            <ResetStudentPassword
                user={user}
                addToast={addToast}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
            />
        </div>
    );
};

export default SettingInfo;
