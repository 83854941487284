import React from 'react';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';
import Slider from 'react-slick/lib';
import VideoCard from 'components/VideoCard';

// Custom button styles
const SlickButtonStyle = css`
    &&& {
        position: absolute;
        width: 55px;
        height: 55px;
        @media only screen and (max-width: 1024px) {
            width: 45px;
            height: 45px;
        }
        @media only screen and (max-width: 414px) {
            width: 33px;
            height: 33px;
        }
        // center it within its parent...
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;
        &.next {
            left: 102%;
            right: 0px;
            @media only screen and (max-width: 500px) {
                left: 93%;
            }
        }
        &.prev {
            right: 99%;
            @media only screen and (max-width: 500px) {
                right: 93%;
            }
        }
        &.disabled {
            display: none;
        }
        &:active {
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }
`;

// custom card styles
// targeting the children of the slider
const VideoCardStyles = styled.div`
    &.left-alignment {
        .slick-list > .slick-track {
            margin-left: 0;
        }
    }

    .video-item {
        &:focus {
            outline: none;
        }
        padding-left: 12px;
        padding-right: 12px;

        .video-item-card {
            width: 100%;
            height: 100%;
        }
    }
    .slick-slider {
        max-width: ${(props) => props.maxWidth || '1260px'};
        margin: 0 auto;
        /* .slick-slide {
            width: ${(props) => props.cardWidth || '361px '} !important;
            height: ${(props) => props.cardHeight || '294px '} !important;
        } */
        .prev {
            right: 100% !important;
        }
        .next {
            left: 100% !important;
        }
    }
`;

function NextArrow(props) {
    let { className, onClick, ArrowRight } = props;
    if (className.includes('slick-disabled')) className = 'next disabled';
    else className = 'next';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg width='18' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M2.75 27.5L15.25 15 2.75 2.5' stroke='#6160D9' strokeWidth='4.167' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

function PrevArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'prev disabled';
    else className = 'prev';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg width='18' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M15.25 27.5L2.75 15l12.5-12.5' stroke='#6160D9' strokeWidth='4.167' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

const VideoSlider = (props) => {
    // when dragging is true, prevent clicking the video card
    let dragging = false;

    // generate the cards
    let videos = props.videos.map((video, index) => {
        let stats = video.stats;
        return (
            <div key={video.id} className='video-item'>
                <VideoCard
                    className='video-item-card'
                    onClick={() => {
                        if (props.onClickVideo) return props.onClickVideo(video, index);
                    }}
                    index={index}
                    video={video}
                    assignment={props.assignment}
                    teacher={props.teacher}
                    stats={stats}
                    hideDuration={props.hideDuration}
                    showTitle={true}
                    showViewCount={true}
                    showRecords={true}
                    assignmentPage={props.assignmentPage}
                />
            </div>
        );
    });

    // default settings
    let settings = {
        dots: false,
        infinite: false,
        arrows: props.arrows ? true : false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        initialSlide: 0,
        slidesToShow: props.cols || 10,
        slidesToScroll: props.cols || 10,
        beforeChange: () => (dragging = true),
        afterChange: () => (dragging = false),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <VideoCardStyles
            className={props.leftAlignment && 'left-alignment'}
            // leftAlignment={props.leftAlignment}
            maxWidth={props.maxWidth}
            cardWidth={props.cardWidth}
            cardHeight={props.cardHeight}
        >
            <Slider {...settings}>{videos}</Slider>
        </VideoCardStyles>
    );
};

VideoSlider.propTypes = {
    videos: PropTypes.array,
    stats: PropTypes.object,
    onClickVideo: PropTypes.func,
    teacher: PropTypes.bool,
    arrows: PropTypes.bool,
    leftAlignment: PropTypes.bool,
    cols: PropTypes.number
};

export default VideoSlider;