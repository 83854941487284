import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { Modal } from 'components';

const MessageDialog = (props) => {
    return (
        <div>MessageDialog</div>
    );
};

MessageDialog.propTypes = {
    anyProp: PropTypes.any
};


export function useMessageDialog() {
    const [isOpen, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [buttons, setButtons] = useState([]);

    const addButton = useCallback((text, handler) => {
        setButtons((current) => [...current, { text, handler }]);
        return { addButton, setMessage, setOpen };
    }, []);

    const showMessageDialog = useCallback((text, config) => {
        setMessage(text);
        setOpen(true);
        return { addButton, setMessage, setOpen };
    }, [addButton]);

    const close = useCallback(() => setOpen(false), []);

    const MessageDialogWrapper = useMemo(() => {
        return (props) => {
            return (
                <Modal
                    isOpen={isOpen}
                    onClose={() => setOpen(false)}
                >
                    <h1>{message}</h1>
                    {buttons?.map(button => <button onClick={button.handler}>{button.text}</button>)}
                </Modal>
            );
        };
    }, [buttons, isOpen, message]);

    // return the Modal and a cancel method
    return [MessageDialogWrapper, showMessageDialog, close];
}

export default MessageDialog;

