import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Modal from 'components/Modal/Modal';
import { apiClient, handleAPIError } from 'utils/api-client';
import CloseButton from '../AnswerKey/CloseButton';

const NewContentPopup = ({ showModal, setShowModal, setOnboardState }) => {
    const handleClose = () => {
        return apiClient
            .delete('/api/announcements/new_content_message/')
            .then(() => {
                setShowModal(false);
            })
            .catch((err) => {
                console.error(err);
                handleAPIError(err);
            });
    };
    return (
        <Modal isOpen={showModal} modalChildrenStyle={tw`maxWidth[729px] mx-10 sm:mx-auto`}>
            <div tw='relative bg-white rounded flex-col justify-center font-sans'>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-2xl min-h-[40px] rounded-t'> Great News!</div>
                <div tw='text-center text-lg text-DarkLiver p-11 mx-auto max-w-[650px]'>
                    New videos are being added and existing videos are being updated. We hope you enjoy.
                </div>
                <CloseButton tw='absolute -top-4 -right-4' onClick={() => handleClose()} />
            </div>
        </Modal>
    );
};

NewContentPopup.propTypes = {
    anyProp: PropTypes.any
};

export default NewContentPopup;
