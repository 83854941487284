import React from 'react';
import tw, { css } from 'twin.macro';
import * as PropTypes from 'prop-types';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import { Modal, Button } from '../../../../../components';

export default function ConfirmQuitDialog({ isOpen, onYes, onNo, open }) {
    return (
        <Modal isOpen={open} setIsOpen={isOpen} modalChildrenStyle={tw`w-full maxWidth[806px] mx-auto`}>
            <div tw='mx-4'>
                <div tw='w-full px-6 py-3 text-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-tl-xl rounded-tr-xl relative'>
                    <h1 tw='text-white text-2xl font-medium'>Are you sure you want to quit?</h1>
                    <button
                        onClick={() => isOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                </div>

                <div tw='bg-white rounded-bl-xl rounded-br-xl px-4 py-8 text-center focus-visible:outlineColor[#7675e7]'>
                    <div tw='space-y-6'>
                        <p tw='font-extrabold text-lg text-DarkLiver'>Your progress will be saved.</p>
                    </div>
                    <div tw='space-x-4 mt-7'>
                        <Button tabIndex={0} onClick={onNo} variant='secondary'>
                            Don't Quit
                        </Button>
                        <Button tabIndex={0} onClick={onYes}>Quit</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ConfirmQuitDialog.propTypes = {
    open: PropTypes.bool,
    isOpen: PropTypes.func,
    onYes: PropTypes.func,
    onNo: PropTypes.func
};
