import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import useAxios from 'axios-hooks';
import { compareNumbers } from 'utils';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useTeacher } from 'state/User/UserState';
import { useDevTool } from '../../lib/DevTool/DevTool';
import { useActivity } from '../../context/LoadingContext';

const ADD_CLASSES = 'ADD_CLASSES';
const DELETE_CLASS = 'DELETE_CLASS';
const ADD_STUDENTS = 'ADD_STUDENTS';
const ADD_STUDENTS_TO_CLASS = 'ADD_STUDENTS_TO_CLASS';

const REMOVE_STUDENT = 'REMOVE_STUDENT';
const ENROLL_STUDENT = 'ENROLL_STUDENT';
const UPDATE_STUDENT = 'UPDATE_STUDENT';
const ADD_ASSIGNMENT_INSTANCE = 'ADD_ASSIGNMENT_INSTANCE';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const initialState = {
    classes: {},
    students: {},
    student_states: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case ADD_CLASSES: {
            // reduce array to object
            const classes = action.payload?.reduce((all, cls) => ({ ...all, [cls.id]: cls }), {});
            const newState = { ...state, classes: { ...state.classes, ...classes } };

            // add temporary students
            // console.log('CLASSES:', classes);
            const students = action.payload?.reduce((all, cls) => {
                const students = cls.students.reduce((students, id) => {
                    return {
                        ...students,
                        [id]: {
                            id: id,
                            loading: true,
                            user: {
                                id: id,
                                username: '',
                                first_name: '',
                                last_name: ''
                            }
                        }
                    };
                }, {});
                // console.log('     COLLECT STUDENTS:', students);
                return { ...all, ...students };
            }, {});

            // console.log('TEMP STUDENTS:', students);
            return { ...newState, students: { ...students, ...state.students } };
        }

        case DELETE_CLASS:
            let classes = Object.values(state.classes).filter((cls) => cls.id !== action.payload);
            classes = classes.reduce((all, cls) => ({ ...all, [cls.id]: cls }), {});
            return { ...state, classes: classes };

        case ADD_STUDENTS: {
            const students = action.payload?.reduce((all, student) => ({ ...all, [student.user.id]: student }), {});
            // console.debug('*** ADD_STUDENTS', action.payload, students);
            return { ...state, students: { ...state.students, ...students } };
        }

        case ADD_STUDENTS_TO_CLASS: {
            let { students, classId } = action.payload;
            console.log('🚀 ~ file: ClassState.js ~ line 78 ~ reducer ~ students', students);
            let cls = state.classes[classId];
            if (!cls) {
                console.error('Class not found: ', classId);
                return state;
            }
            cls = { ...cls, students: [...(cls.students.concat(students.map(({ user }) => user.id)))] };
            return { ...state, classes: { ...state.classes, [classId]: cls } };
        }

        // case RENAME_STUDENTS: {
        //     const student = action.payload;
        //     if (student?.user?.id) {
        //         return { ...state, students: { ...state.students, [student.user.id]: student } };
        //     }
        //     return state;
        // }

        case UPDATE_STUDENT: {
            const student = action.payload;
            if (student?.user?.id) {
                return { ...state, students: { ...state.students, [student.user.id]: student } };
            }
            return state;
        }

        case REMOVE_STUDENT: {
            const cls = state.classes[action.payload.classId];
            if (!cls) {
                console.error('Error: REMOVE_STUDENT: classId not found ', action.payload);
                return state;
            }
            return { ...state, classes: { ...state.classes, [cls.id]: { ...cls, students: cls.students.filter((s) => s !== action.payload.studentId) } } };
        }

        case ADD_ASSIGNMENT_INSTANCE: {
            // append the payload to a student 'assignment_instance' list
            const instance = action.payload;
            const user_id = instance.student_id;
            return {
                ...state,
                students: {
                    ...state.students,
                    [user_id]: {
                        ...state.students[user_id],
                        assignment_instances: [...(state.students[user_id].instances ?? []), instance]
                    }
                }
            };
        }

        default:
            return state;
    }
};

const ClassState = (props) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);
    useDevTool('ClassState', store);
    const teacher = useTeacher();
    const [downloadComplete, setDownloadComplete] = useState(false);
    const [endActivity, addActivity] = useActivity(teacher ? 1 : 0);

    // const onClassesSuccessful = async (data) => {
    //     console.log('Downloaded classes', data);
    //     dispatch({ type: ADD_CLASSES, payload: data });
    //     // TODO: this is terrible...
    //     data.forEach((cls) => {
    //         // download students
    //         apiClient.get(`/api/classrooms/${cls.id}/students/`).then(({ data }) => {
    //             // console.log('download students');
    //             dispatch({ type: ADD_STUDENTS, payload: data });
    //         });
    //     });
    // };

    const onClassesDownloaded = useCallback((data) => {
        // console.debug('onClassesDownloaded:', data);
        dispatch({ type: ADD_CLASSES, payload: data });
        // console.debug('[activity] endActivity: onClassesDownloaded');
        endActivity();
    }, [endActivity]);

    const fetchStudents = useCallback(() => {
        const handleNewStudents = (data) => {
            const { next, results } = data;
            console.debug('[ClassState] downloaded students ', results);

            const students = results.map((student) => {
                // convert the video_stats to a map
                const video_stats = student.video_stats?.reduce(
                    (all, stats) => ({
                        ...all,
                        [stats.video_id]: stats
                    }),
                    {}
                );

                return { ...student, video_stats };
            });

            // put the students in the store
            dispatch({ type: ADD_STUDENTS, payload: students });

            // grab the next page
            if (next) {
                // console.log('[ClassState] download next page of students');
                setTimeout(() => apiClient.get(next).then(({ data }) => handleNewStudents(data)), 0);
            }
        };

        // begin downloading students
        apiClient.get('/api/students/').then(({ data }) => handleNewStudents(data));

    }, []);

    // grab the students
    useEffect(() => {
        const handleNewStudents = (data) => {
            const { next, results } = data;
            console.debug('[ClassState] downloaded students ', results);

            const students = results.map((student) => {
                // convert the video_stats to a map
                const video_stats = student.video_stats?.reduce(
                    (all, stats) => ({
                        ...all,
                        [stats.video_id]: stats
                    }),
                    {}
                );

                return { ...student, video_stats };
            });

            // put the students in the store
            dispatch({ type: ADD_STUDENTS, payload: students });

            // grab the next page
            if (next) {
                // console.log('[ClassState] download next page of students');
                setTimeout(() => apiClient.get(next).then(({ data }) => handleNewStudents(data)), 0);
            }
        };

        // begin downloading students
        apiClient.get('/api/students/').then(({ data }) => handleNewStudents(data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // automatically download classes
    const { error } = useSWR(teacher && '/api/classrooms/', { onSuccess: onClassesDownloaded });

    useEffect(() => {
        if (error) {
            console.error('Fetch Classes Error:', error);
        }
    }, [error]);

    // only teachers use class state
    // console.log('teacher', teacher, user);
    // if (!teacher) {
    //     // return props.children;
    // }

    const removeClass = (classId) => {
        dispatch({ type: DELETE_CLASS, payload: classId });
    };

    const addClasses = (classes) => {
        console.log('Add classes', classes);
        // .then(r => dispatch({ DELETE_CLASS }, classId));
        dispatch({ type: ADD_CLASSES, payload: classes });
    };

    const addClass = (cls) => {
        addClasses([cls]);
    };

    const createClass = async (cls) => {
        console.log('post to api/classrooms:', cls);
        const { data } = await apiClient.post('api/classrooms/', cls);
        console.log('Success:', data);
        addClasses([data]);
        return data;
    };

    const updateClass = async (cls) => {
        console.log('post to api/classrooms:', cls);
        const { data } = await apiClient.patch(`api/classrooms/${cls.id}/`, cls);
        console.log('Success:', data);

        // update the store
        addClasses([data]);

        // dispatch({type: ADD_CLASSES, payload: {[classId]: cls}});
    };

    // const addStudents = (newStudents) => setStudents({ ...students, ...newStudents });
    const addStudents = (newStudents) => {
        console.log('ADD STUDENTS', newStudents);
        dispatch({ type: ADD_STUDENTS, payload: newStudents });
        // dispatch({ type: ADD_CLASSES, payload: data });
        //setStudents({ ...students, ...newStudents });
    };

    const addStudentsToClass = (payload) => {
        dispatch({ type: ADD_STUDENTS_TO_CLASS, payload: payload });
    };

    const reNameStudent = (user) => {
        dispatch({ type: UPDATE_STUDENT, payload: user });
    };

    const updateStudent = (user) => {
        dispatch({ type: UPDATE_STUDENT, payload: user });
    };

    const removeStudent = (studentId, classId) => {
        return dispatch({ type: REMOVE_STUDENT, payload: { classId, studentId } });
    };

    const enrollStudent = (studentId, classId) => {
        return dispatch({ type: ENROLL_STUDENT, payload: { studentId, classId } });
    };

    const reorderClass = (from_index, new_index) => {
        const classes = Object.values(store.classes);
        const sorted = classes.sort((a, b) => compareNumbers(a.display_order, b.display_order));
        const cls = sorted[from_index];

        let payload = { index: new_index, from_index };
        apiClient
            .post(`/api/classrooms/${cls.id}/order/`, payload)
            .then((response) => {
                console.log('order changed', response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const addStudentAssignments = (instances) => {
        console.log('add student instances', instances);
        instances.forEach((instance) => {
            dispatch({ type: ADD_ASSIGNMENT_INSTANCE, payload: instance });
        });
    };

    const actions = {
        classesDownloaded: downloadComplete,
        updateClass,
        removeClass,
        addClasses,
        addClass,
        createClass,
        addStudents,
        removeStudent,
        reorderClass,
        enrollStudent,
        reNameStudent,
        updateStudent,
        addStudentAssignments,
        addStudentsToClass,
        fetchStudents
    };

    // if (!downloadComplete) {
    //     return (
    //         <LoadingPage
    //             css={[css`height: 100vh;`]}
    //             color='#123abc'
    //             background='#450762'
    //             message='Loading classes'
    //             // message=' '
    //         />
    //     );
    // }
    return (
        <DispatchContext.Provider value={actions}>
            <StoreContext.Provider value={store}>{props.children}</StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

export function useStore() {
    return React.useContext(StoreContext);
}

export function useClassActions() {
    return React.useContext(DispatchContext) ?? {};
}

// use an array of all classes
export function useClasses() {
    const store = React.useContext(StoreContext);
    // if (store === undefined) {
    //     throw new Error('useClasses must be used within a ClassState');
    // }
    // convert to array & sort by display_order
    return useMemo(() => Object.values(store?.classes ?? {}).sort((a, b) => compareNumbers(a.display_order, b.display_order)), [store]);
}

// use an array of all students
export function useStudents() {
    const store = React.useContext(StoreContext);
    // if (store === undefined) {
    //     throw new Error('useStudents must be used within a ClassState');
    // }
    return useMemo(() => Object.values(store?.students ?? {}), [store]);
}

export function useStudent(student_id) {
    const store = React.useContext(StoreContext);
    if (store === undefined) {
        throw new Error('useStudent must be used within an ClassState');
    }
    return useMemo(() => {
        if (!student_id) return null;
        return store?.students[student_id];
    }, [store, student_id]);
}

export function useClass(classId) {
    const store = React.useContext(StoreContext);
    return store.classes[classId] ?? null;
    // let selectedClass = classes ? classes.find((cls) => cls.id === class_id) : null;
    // return selectedClass;
}

export function useAllStudents() {
    return useClassStudents(null);
}

// students in a class (use NULL to get all students)
export function useClassStudents(classId) {
    const store = React.useContext(StoreContext);
    // const { addStudents, enrollStudent } = React.useContext(DispatchContext);
    const dispatchActions = React.useContext(DispatchContext);
    const classActions = useClassActions();
    const removeStudentAction = classActions?.removeStudent;

    // function isLoading(object) {
    //     return Object.keys(object).length === 0;
    // }

    // let isLoadingStudents = isLoading(store?.students);

    // memoize a list of students in this class
    const students = useMemo(() => {
        if (!store) return [];

        if (!classId) {
            // all students
            return Object.values(store.students ?? {});
        }

        // get the class
        const cls = store.classes[classId] ?? null;
        if (!cls) {
            return null;
        }

        const students = [];
        cls.students.forEach((id) => {
            const student = store.students[id];
            if (student) students.push(student);
        });
        return students;
    }, [classId, store]);

    // add a student
    const [{ loading: isCreating }, createStudent] = useAxios({ url: `api/classes/${classId}/assign/`, method: 'POST' }, { manual: true });
    const addStudent = useCallback(
        (student) => {
            const body = { ...student, class_id: classId };
            // create the student then reload the class
            return createStudent({ data: body })
                .then((response) => {
                    // mutate();
                    const { data } = response;
                    if (data.error) {
                        console.log('Add student failed', data.error);
                    } else {
                        console.log('Student created: ', data);
                        if (dispatchActions) {
                            // add the student to the store
                            const { studentprofile } = data;
                            dispatchActions.addStudents([studentprofile]);
                            dispatchActions.addStudentsToClass({ students: [studentprofile], classId: classId });
                            // enrol the student in this class
                            dispatchActions.enrollStudent(studentprofile.user.id, classId);
                        }
                    }

                    return response;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        [classId, createStudent, dispatchActions]
    );

    // remove a student
    const [{ loading: isPosting }, deleteStudent] = useAxios({ method: 'DELETE' }, { manual: true });
    const removeStudent = useCallback(
        async (studentId) => {
            // first mutate the local data
            // await mutate(
            //     data.filter(({ user }) => user.id !== studentId),
            //     false
            // );

            // remove from the server
            await deleteStudent({ url: `api/classes/${classId}/students/${studentId}/` });

            // revalidate
            // await mutate();
            console.log('remove data');
            if (removeStudentAction)
                removeStudentAction(studentId, classId);

            // show notification
            if (window._addToastNotification)
                window._addToastNotification('Success', 'The student was removed.', 'success', 6, null);
        },
        [classId, deleteStudent, removeStudentAction]
    );

    // change student name
    const [{ loading: isChangingName }, changeName] = useAxios({ url: 'api/studentprofile/', method: 'POST' }, { manual: true });
    const changeStudentName = useCallback(
        async (user_id, first_name, last_name) => {
            let body = { user_id, first_name, last_name };

            // create the student then reload the class
            return changeName({ data: body })
                .then((response) => {
                    if (dispatchActions) {
                        // update store
                        const { data } = response;
                        dispatchActions.reNameStudent(data);
                    }

                    return response;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        [changeName, dispatchActions]
    );

    const updateStudentSettings = useCallback(
        async (user_id, settings) => {
            let body = { user_id, ...settings };
            // create the student then reload the class
            return apiClient
                .post('', body)
                .then((response) => {
                    if (dispatchActions) {
                        // update store
                        const { data } = response;
                        dispatchActions.updateStudent(data);
                    }

                    return response;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        [dispatchActions]
    );

    const reloadStudents = () => {
    };

    return {
        students,
        classesDownloaded: dispatchActions?.classesDownloaded ?? false,
        isLoading: false,
        addStudent,
        removeStudent,
        reloadStudents,
        changeStudentName
    };
}

/*
export function useClassStudents_get(classId) {
    const actions = React.useContext(DispatchContext);
    // get class students
    const { data, error, mutate } = useSWR(classId ? `/api/classrooms/${classId}/students/` : null, apiFetcher, {
        onSuccess: (data) => {
            console.log('api/classrooms/:id/students', data);
            actions.addStudents(data);
        }
        // revalidateOnFocus: false,
        // revalidateOnMount: true,
        // revalidateOnReconnect: false,
        // refreshWhenOffline: false,
        // refreshWhenHidden: false,
        // refreshInterval: 0
    });
    const students = useMemo(() => Object.values(data || {}), [data]);
    // console.log('class students', data, students);

    // add a student
    const [{ loading: isCreating }, createStudent] = useAxios({ url: `api/classes/${classId}/assign/`, method: 'POST' }, { manual: true });
    const addStudent = useCallback(
        (student) => {
            console.log('addStudent', student);
            // first mutate the local data
            // await mutate(data.filter(({ user }) => user.id !== studentId), false);
            const body = {
                ...student,
                class_id: classId
            };

            // create the student then reload the class
            return createStudent({ data: body }).then((response) => {
                mutate();
                return response;
            });
        },
        [classId, createStudent, mutate]
    );

    // remove a student
    const [{ loading: isPosting }, deleteStudent] = useAxios({ method: 'DELETE' }, { manual: true });
    const removeStudent = useCallback(
        async (studentId) => {
            // first mutate the local data
            await mutate(
                data.filter(({ user }) => user.id !== studentId),
                false
            );

            // remove from the server
            await deleteStudent({ url: `api/classes/${classId}/students/${studentId}/` });

            // revalidate
            await mutate();
        },
        [classId, data, deleteStudent, mutate]
    );

    return {
        classId: classId,
        students: students,
        isLoading: data === undefined,
        isError: error,
        addStudent: addStudent,
        removeStudent: removeStudent,
        reloadStudents: mutate
    };
}
*/

export function useAddStudent(class_id) {
    const [{ loading: isSaving }, createStudent] = useAxios(
        {
            url: `api/classes/${class_id}/assign/`,
            method: 'POST'
        },
        { manual: true }
    );

    return useCallback(
        (student) => {
            const body = {
                ...student,
                class_id: class_id
            };
            return createStudent({ data: body });
        },
        [class_id, createStudent]
    );
}

// export function useVideoStats(video_id = null) {
//     const actions = React.useContext(DispatchContext);
//     const stats = React.useContext(StatsContext);
//
//     console.log('useVideoStats', video_id );
//
//     useSWR('/api/video_stats/all/', apiFetcher, {
//         // revalidateOnFocus: false,
//         onSuccess: (data) => {
//             console.log('api/video_stats/all/', data);
//             const dict = data.reduce((all, item) => {
//                 return { ...all, [item.video_id]: item };
//             }, {});
//             console.log('GOT STATS:', dict);
//             actions.addStats(dict);
//         }
//     });
//
//     return useMemo(() => {
//         if (video_id && stats) {
//             return stats[video_id];
//         }
//         return stats;
//     }, [stats, video_id]);
// }
//

export default ClassState;
