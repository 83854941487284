import React from 'react';
import tw, { styled } from 'twin.macro';
import NullBadge from 'static/icons/null_badge.png';

const NullRecentAchievement = () => {
    return (
        <div tw='flex flex-col'>
            <div tw='flex items-center py-6'>
                <img src={NullBadge} tw='w-auto h-full max-h-32' />
                <div className='null_copy' tw='text-white text-lg pl-4 sm:pl-8'>
                    Watch Muzology videos and take Challenges to start earning awards!
                </div>
            </div>
            <div tw='h-0.5 bg-ShadowPurple' />
            <div tw='flex items-center py-6'>
                <img tw='w-full h-auto' style={{ maxWidth: '110px' }} src={NullBadge} />
                <div tw='text-white text-lg pl-4 sm:pl-8'>Remember, the more you watch, the faster you learn!</div>
            </div>
        </div>
    );
};

export default NullRecentAchievement;
