import React from 'react';

const SvgSortIndicatorUp = (props) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 11 6'
        {...props}>
        <defs>
            <path
                id='sort_indicator_up_svg__a'
                d='M648.725 751.27l-4.195 4.195a.73.73 0 01-1.048 0 .73.73 0 010-1.048l4.194-4.195a.73.73 0 011.049 0 .73.73 0 010 1.049z'
            />
            <path
                id='sort_indicator_up_svg__b'
                d='M651.893 755.465l-4.194-4.194a.73.73 0 010-1.049.73.73 0 011.049 0l4.194 4.195a.73.73 0 010 1.048c-.296.274-.775.274-1.049 0z'
            />
        </defs>
        <use
            fill='currentColor'
            xlinkHref='#sort_indicator_up_svg__a'
            transform='translate(-643 -750)' />
        <use
            fill='currentColor'
            xlinkHref='#sort_indicator_up_svg__b'
            transform='translate(-643 -750)' />
    </svg>
);

export default SvgSortIndicatorUp;
