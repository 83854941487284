import AchievementConfig, { record_colors } from 'app/constants/constants';

export const profileIsAdmin = (profile) => profile && profile.user && profile.user.is_staff;

export const profileIsTeacher = (profile) => profile && !!profile.instructor;

export const recordColor = (level) => record_colors[level % 4];

export const getProfileInitial = (profile) => {
    if (profile && profile.user) {
        let initials = profile.user.initials;
        if (!initials) {
            const user = profile.user;
            if (user.first_name)
                initials = user.first_name[0];
            else if (user.username)
                initials = user.username[0];
            else
                initials = '?';
        }
        if (initials) initials = initials.toUpperCase();
        return initials;
    }
    return null;
};

export const achievementConfig = (type) => AchievementConfig[type];
export const achievementName = (type) => (AchievementConfig[type] || { header: null }).header || null;

export const achievementLevels = (type) => (AchievementConfig[type] || { levels: null }).levels || null;

export const achievementLevelMetric = (badgeType, level) => {
    let levels = achievementLevels(badgeType);
    // console.log("badge", badgeType,levels);
    if (!levels) return null;
    return levels[level - 1] || null;
};

export const achievementNextLevel = (type, metric) => {
    let levels = achievementLevels(type);
    if (!levels) return null;
    for (let i = 0; i < levels.length; i++) {
        if (levels[i] > metric) return i + 1;
    }
    // -1 indicates there are no more levels
    return -1;
};

export const formatAssignmentGoal = (assignment) => {
    if (!assignment) return null;
    if (assignment.quiz_level_goal && assignment.quiz_score_goal) {
        let format = `${assignment.quiz_score_goal}% ${recordColor(assignment.quiz_level_goal)}`;
        return format;
    }
    return '50% Gold';
};

// this takes a video thumbnail and attempts to replace the suffix with a different size
export const createVideoPoster = (url) => {
    // typical url: https://muzology-public-media.s3.amazonaws.com/presentations/add_and_subtract_integers_4712981a3b8a2f930e290ed5e08923eb3d79-480w.jpg
    // replace '-480w.jpg' with 1440 pixels wide
    if (url.endsWith('-480w.jpg')) {
        return url.replace('-480w.jpg', '-1440w.jpg');
    }
    return url;
};
