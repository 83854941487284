import React from 'react';
import tw, { css } from 'twin.macro';
import TeacherBannerBackgroundImage1 from 'static/images/banners/teacher-banner-start.jpg';

export const BannerSlide = ({ background, children }) => {
    // let style = null;
    // if (background)
    //     style = { backgroundPosition: 'center', backgroundImage: `url(${background})` };
    return (
        <div
            tw='p-2 flex relative bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-xl font-sans '
            css={[
                css`
                    height: 280px;
                `
            ]}
        >
            <div
                tw='flex flex-col flex-1 px-7 lg:px-14 relative rounded-xl font-sans'
                css={[
                    css`
                        background-position: center;
                        background-size: cover;
                        background-position: right;
                        background-image: url(${background ?? TeacherBannerBackgroundImage1});
                    `
                ]}
            >
                <div tw='my-auto max-w-sm lg:max-w-xl'>{children}</div>
            </div>
        </div>
    );
};

export const BannerTitle = tw.p`text-base lg:text-2xl text-white font-extrabold mb-3 max-w-xl`;
export const BannerText = tw.p`text-base lg:text-lg text-white font-extrabold mb-6 max-w-[200px] lg:max-w-xl`;
export const BannerContent = tw.div`text-base lg:text-lg text-white font-extrabold mb-6 max-w-xl`;
export const BannerButtons = tw.div`flex items-start flex-col lg:flex-row max-w-[300px] lg:max-w-[100%]`;
