import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import moment from 'moment/moment';
import RecordImg from 'components/RecordImg';
import { level_titles } from '../../app/constants/constants';

const ListItemCss = css`
    li::marker {
        content: ' ';
        color: transparent;
    }
`;

const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
    }, {});
};

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #c7c7ff;
        border-radius: 20px;
        max-height: 60px;
    }

    ::-webkit-scrollbar-track {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`;

const ActivityStream = ({ activity, videos, parentStyle }) => {
    // create a list of filtered activity items with a calendar_date value converted to local time
    const activityItems = useMemo(() => {
        // filter activity that shouldn't be showed in activity stream
        const activityFiltered = activity?.filter(
            (activity) =>
                activity.name !== 'user:login' && activity.name !== 'quiz:started' && activity.name !== 'route:changed' && activity.name !== 'video:start'
        );
        // attach a calendar_date
        return activityFiltered.map((item) => {
            const datestamp = moment.utc(item.date).local().calendar(null, {
                lastDay: '[Yesterday]',
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                lastWeek: '[Last] dddd',
                nextWeek: 'dddd',
                sameElse: 'L'
            });
            return { ...item, calendar_date: datestamp };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activity]);

    // group into date buckets
    const activityGroupedByDate = groupBy(activityItems, 'calendar_date');

    // sort the dates
    let orderedDates = {};
    Object.keys(activityGroupedByDate)
        .sort((a, b) => {
            return moment(a, 'DD/MM/YYYY').toDate() - moment(b, 'DD/MM/YYYY').toDate();
        })
        .forEach((key) => {
            orderedDates[key] = activityGroupedByDate[key];
        });

    // helper to sort type of activity
    const ActivityTypeHelper = (item, date) => {
        switch (item.name) {
            case 'video:watched': {
                const video = videos.find((video) => video.id === item.data.video_id);
                let localTime = moment.utc(item.date).local();
                return (
                    <li tw='text-lg text-EarieBlack mt-4 pb-2  border-b-2 border-GainsboroGrey flex justify-between'>
                        <div>Watched "{video?.title}"</div>
                        <div tw='text-xs text-SonicSilver flex items-center'>{`${item.calendar_date}  ${localTime.format('LT')}`}</div>
                    </li>
                );
            }
            case 'quiz:taken': {
                // console.log('quiz taken', item);
                const video = videos.find((video) => video.id === item.data.videoId);
                const score = item.data.score;
                let localTime = moment.utc(item.date).local();
                if (item.data.quizLevel === 0) {
                    return (
                        <li tw='text-lg text-EarieBlack mt-4 pb-2  border-b-2 border-GainsboroGrey flex justify-between'>
                            <div>
                                Scored {score}% on "{video?.title}" Warm Up
                            </div>
                            <div tw='text-xs text-SonicSilver flex items-center'>{`${item.calendar_date}  ${localTime.format('LT')}`}</div>
                        </li>
                    );
                } else
                    return (
                        <li tw='text-lg text-EarieBlack mt-4 pb-2  border-b-2 border-GainsboroGrey flex justify-between'>
                            <div>
                                Scored {score}%
                                <span tw='mx-1'>
                                    <RecordImg level={item.data.quizLevel} style={{ width: '20px', height: 'auto', display: 'inline' }} />
                                </span>
                                on "{video?.title}" {level_titles[item.data.quizLevel]} Challenge
                            </div>
                            <div tw='text-xs text-SonicSilver flex items-center'>{`${item.calendar_date}  ${localTime.format('LT')}`}</div>
                        </li>
                    );
            }
        }
    };

    // loop over grouped => sorted activity to create activity stream
    const createActivity = (sortedActivity) => {
        return Object.keys(sortedActivity).map((date, key) => {
            return (
                <div key={key}>
                    <div tw='text-base text-SonicSilver mt-6'>{date}</div>
                    {sortedActivity[date].map((item, key) => {
                        return (
                            <ul css={[ListItemCss]} tw='list-inside' key={key}>
                                {ActivityTypeHelper(item, date)}
                            </ul>
                        );
                    })}
                </div>
            );
        });
    };

    return (
        <div tw='bg-white font-sans'>
            <div css={[ScrollBarStyle, parentStyle]} tw='overflow-y-auto h-80'>
                <div tw='pt-1 pb-4 pr-2'>{createActivity(orderedDates)}</div>
            </div>
        </div>
    );
};

ActivityStream.propTypes = {
    activity: PropTypes.array,
    videos: PropTypes.array
};

export default ActivityStream;
