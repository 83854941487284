import React from 'react';
import 'twin.macro';

const NeedHelp = ({ onClose }) => {
    return (
        <div tw='bg-white font-Inter rounded-tl-[20px] sm:rounded-tl-[0px] rounded-bl-[20px] sm:rounded-bl-[0px] rounded-tr-[20px] rounded-br-[20px]  shadow-2xl py-4 sm:py-5 px-4 sm:px-10 w-full sm:w-[500px] md:w-[600px] lg:w-[750px] lg:h-[520px]'>
            <div tw='bg-[#FBFAFA] relative h-full w-full rounded-[20px] '>
                <div tw='text-center text-[#494949] pt-10 max-w-[400px] mx-auto'>
                    <div tw='text-lg sm:text-2xl font-bold'>Google</div>
                    <div tw='text-sm sm:text-base pt-2 '>If you signed up for Muzology using your Google login, please continue to sign in using Google.</div>
                </div>
                <div tw='text-center text-[#494949] pt-10 max-w-[400px] mx-auto'>
                    <div tw='text-lg sm:text-2xl font-bold '>Classlink</div>
                    <div tw='text-sm sm:text-base pt-2'>If your school uses Classlink. Please use the Classlink button available on the Log In tab.</div>
                </div>
                {/*
                <div tw='text-center text-[#494949] max-w-[400px] pt-10 mx-auto'>
                    <div tw='text-lg sm:text-2xl font-bold'>Facebook</div>
                    <div tw='text-base pt-2'>If you signed up for Muzology using your Facebook login, please continue to sign in using Facebook.</div>
                </div>
                */}
                <div tw='text-sm sm:text-base font-bold pt-10 text-center pb-3 sm:pb-0 '>
                    Need Help? <span tw='font-normal'></span>Contact us at -{' '}
                    <span tw='text-[#6C61DE] cursor-pointer'>
                        <a href='mailto:support@muzology.com' target='_blank' rel='noreferrer'>
                            “support@muzology.com”
                        </a>
                    </span>
                </div>
                <div tw='absolute -top-3 sm:top-1 right-1 cursor-pointer pt-5' onClick={() => onClose(false)}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'>
                        <path
                            fill='#131313'
                            fillRule='evenodd'
                            d='M27.566 17.52a1 1 0 0 0-1.475-1.346l-4.296 4.189-4.188-4.297-.087-.078a1 1 0 0 0-1.345 1.475l4.188 4.296-4.296 4.188-.08.086a1 1 0 0 0 1.476 1.346l4.296-4.188 4.188 4.296.087.079a1 1 0 0 0 1.346-1.475l-4.189-4.296 4.296-4.189.08-.086Z'
                            clipRule='evenodd'
                        />
                        <path
                            stroke='#000'
                            strokeWidth='1.5'
                            d='M37.25 22c0 8.422-6.828 15.25-15.25 15.25S6.75 30.422 6.75 22 13.578 6.75 22 6.75 37.25 13.578 37.25 22Z'
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default NeedHelp;
