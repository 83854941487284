import React, { useEffect, useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import { PlaylistAssignWizardStateProvider } from 'state/PlaylistAssignWizardState';
import VideoSessionProvider from 'context/VideoSession/VideoSessionProvider';
import GuidanceProvider from './Guidance/GuidanceProvider';
import TeacherProvider from './Teacher/TeacherProvider';
import StudentProvider from './Student/StudentProvider';
import { PlaylistStateProvider } from './PlaylistState';
import CatalogState from './CatalogState';
import { WebTourState } from './WebTourState';
import { useOnboardState } from './User/UserState';
import { ActivityState } from './User/ActivityState';

// const LoadInitialStore = (props) => {
//     const dispatch = useDispatch();
//
//     // load the root store
//     useEffect(() => {
//         apiFetcher('/api/store/')
//             .then(data => dispatch({ type: 'SET_ROOT_STORE', payload: data }));
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//
//
//     return null;
// };

const AppState = (props) => {
    const onboard = useOnboardState();
    const flipSwitch = onboard?.enable_demo_mode ? 'demo' : 'normal';
    // const onboard = useOnboardState();
    // const appMode = useMemo(() => onboard?.enable_demo_mode ? 'demo' : 'normal', [onboard?.enable_demo_mode]);
    // console.log('[AppState] render', appMode);
    return (
        <div key={flipSwitch} tw='h-full'>
            <CatalogState>
                <WebTourState>
                    <ActivityState>

                        <TeacherProvider>
                            <StudentProvider>
                                <VideoSessionProvider>


                                    <PlaylistStateProvider>
                                        <PlaylistAssignWizardStateProvider>

                                            {props.children}

                                        </PlaylistAssignWizardStateProvider>
                                    </PlaylistStateProvider>


                                </VideoSessionProvider>
                            </StudentProvider>
                        </TeacherProvider>
                    </ActivityState>
                </WebTourState>
            </CatalogState>
        </div>
    );
};

export default AppState;
