import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Error404Page } from 'components';
import { withFixedLayout, withScrollLayout } from '../layout/Layouts';
import { HomeContainer, PlaylistsContainer, ProfileContainer, ProgressContainer, PlaylistOptions } from '../containers/child';
import TimesTablePlaylistContainer from '../containers/child/Playlists/TimesTables/PlaylistContainer';
import VideoSessionContainer from '../containers/child/VideoSessionContainer/VideoSessionContainer';
import QuizSession from '../containers/child/QuizSessionContainer/QuizSession';
import ParentSettings from 'app/containers/child/Settings/Settings';
import { useProduct } from 'state/User/UserState';
const ChildApp = () => {
    const product = useProduct();
    const timesTableProduct = product.includes('times-tables')
    return (
        <Routes>
            <Route path='*' element={<Error404Page />} />
            <Route path='/quiz/:quiz_id' element={withScrollLayout(QuizSession)} />
            <Route path='/home' element={withScrollLayout(HomeContainer)} />
            <Route path='/video/:video_id' element={withScrollLayout(VideoSessionContainer)} />
            {timesTableProduct && <Route path='/playlists/:course_id' element={withScrollLayout(TimesTablePlaylistContainer)} />}
            <Route path='/playlists/:course_id/:group_id' element={withScrollLayout(PlaylistsContainer)} />
            <Route path='/playlists/favorites' element={withScrollLayout(PlaylistsContainer)} />
            <Route path='/stats/*' element={withScrollLayout(ProgressContainer)} />
            <Route path='/profile/' element={withScrollLayout(ProfileContainer)} />
            <Route path='/profile/fixed/' element={withFixedLayout(ProfileContainer)} />
            <Route path='/settings/*' element={withScrollLayout(ParentSettings)} />
            <Route path='/' element={<Navigate to='/home' />} />
        </Routes>
    );
};

export default ChildApp;
