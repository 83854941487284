import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(42, 38, 77, 0.5);
        border-radius: 20px;
        max-height: 60px;
    }

    ::-webkit-scrollbar-track {
    }
`;
const Tab = styled.button(({ $active }) => [
    tw`text-lg py-2 px-8 bg-DeepPurple text-SlatePurple text-center rounded-t-2xl hover:bg-white cursor-pointer`,
    $active && tw`bg-white`
]);

const VideoPlayerLyricsPopOver = ({ lyrics, lyricsFile, copyrightYear }) => {
    const [open, setOpen] = useState(false);
    // const newLyrics = lyrics?.replaceAll('\r\n', '\r\n\r\n') ?? '';
    const newLyrics = lyrics ?? '';
    const copyright = `Copyright © ${copyrightYear ?? '2017'} Muzology, LLC. All rights reserved.`;
    return (
        <div>
            <div
                tw='hidden sm:flex absolute py-3 px-4 -right-4 text-center '
                style={{ top: '-3.5rem' }}
                css={[
                    css`
                        @media only screen and (max-height: 740px) {
                            right: 7.75rem !important;
                        }
                    `
                ]}
                id='video-session-print-show-lyrics'
            >
                <Tab tabIndex='0' onClick={() => setOpen(!open)} $active={open} style={{ width: '160px' }}>
                    {open ? 'Hide Lyrics' : 'Show Lyrics'}
                </Tab>

                <Tab tabIndex='0' tw='ml-2' onClick={() => window.open(`https://public.muzology.com/lyrics/${lyricsFile}`)} style={{ width: '160px' }}>
                    Print Lyrics
                </Tab>
            </div>
            {open && (
                <div
                    tw='background[rgba(244, 255, 255, 0.9)] whitespace-pre-wrap rounded-lg text-EarieBlack font-sans text-lg font-medium max-w-md pt-5 px-6 overflow-auto absolute top-0 right-0 h-full xl:height[542px]'
                    // style={{ backgroundColor: 'rgba(244, 255, 255, 0.9)' }}
                    // css={[ScrollBarStyle]}
                    css={[
                        // css`background-color: rgba(244, 255, 255, 0.9)`,
                        // ScrollBarStyle,
                        css`
                            z-index: 100;
                            @media only screen and (max-height: 740px) {
                                right: 141px !important;
                                height: 460px;
                            }
                        `
                    ]}
                >
                    {newLyrics}
                    <div className='copyright' tw='text-center pt-8 pb-4 text-sm'>{copyright}</div>
                    {/*<ReactMarkdown source={newLyrics} />*/}
                </div>
            )}
        </div>
    );
};

VideoPlayerLyricsPopOver.propTypes = {
    lyrics: PropTypes.string
};

export default VideoPlayerLyricsPopOver;
