import * as React from 'react';

function SvgClassIcon06(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#F55' />
            <path
                d='M20.176 18.137c.19-.047.38-.087.57-.119.19-.031.388-.047.594-.047.791 0 1.512.142 2.16.427.65.285 1.204.673 1.663 1.164.46.49.816 1.077 1.069 1.758.253.665.38 1.377.38 2.137 0 .87-.166 1.663-.499 2.375a5.352 5.352 0 01-1.33 1.805 5.967 5.967 0 01-1.995 1.188c-.76.269-1.575.403-2.446.403-.887 0-1.71-.134-2.47-.403a6.298 6.298 0 01-1.995-1.188 5.809 5.809 0 01-1.33-1.805c-.317-.712-.475-1.504-.475-2.375 0-.602.055-1.148.166-1.639.111-.506.254-.981.428-1.425.19-.443.403-.863.641-1.258.253-.412.515-.832.784-1.26l3.681-5.747h4.346l-3.942 6.01zm2.873 5.178c0-.76-.253-1.386-.76-1.877-.49-.506-1.14-.76-1.947-.76-.807 0-1.465.254-1.971.76-.491.491-.736 1.117-.736 1.877s.245 1.393.736 1.9c.506.49 1.164.736 1.971.736.808 0 1.457-.246 1.948-.736.506-.507.76-1.14.76-1.9z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon06;
