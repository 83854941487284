import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import progress_check from '../../static/images/white-check.png';
import progress_x from '../../static/images/white-x.png';

const RemoveLastLineCss = css`
    :nth-last-child(19n) {
        display: none;
    }
`;

const ProgressDots = ({ progress, className, startIndex = 0 }) => {
    const dots = useMemo(() => {
        let nodes = [];
        progress.forEach((p, index) => {
            let contents;
            if (p === +1) {
                // correct
                contents = (
                    <div tw='rounded-full flex-shrink-0 text-center h-6 w-6 md:h-7 md:w-7 background[#5fc17a] flex items-center justify-center'>
                        <img src={progress_check} width='60%' height='auto' alt='correct' />
                    </div>
                );
            } else if (p === -1) {
                // incorrect
                contents = (
                    <div tw='rounded-full flex-shrink-0 text-center h-6 w-6 md:h-7 md:w-7 background[#ee7171] flex items-center justify-center'>
                        <img src={progress_x} width='50%' height='auto' alt='incorrect' />
                    </div>
                );
            } else {
                contents = startIndex + index + 1;
            }

            let indicator = (
                <div
                    key={`${index}-${p}`}
                    tw='rounded-full flex-shrink-0 text-center h-7 w-7 md:h-9 md:w-9 border-2 border-EarieBlack mx-1.5 flex items-center justify-center text-EarieBlack text-lg font-extrabold'
                >
                    {contents}
                </div
                >
            );
            nodes.push(indicator);

            if (index !== (0, 1, -1)) {
                nodes.push(<div key={index * 1000} tw='h-1 w-7 bg-EarieBlack rounded-sm' />);
            }
        });
        nodes.pop();
        return nodes;
    }, [progress, startIndex]);

    return (
        <div tw='flex items-center justify-center px-5 lg:px-12' className={className} data-testid='progress-dots'>
            {dots}
        </div>
    );
};

export default ProgressDots;