import React, { useMemo } from 'react';
import tw, { styled, css } from 'twin.macro';
import { useMedia } from 'react-use';
import QuizHeader from './QuizHeader';
import QuizProgress from './QuizProgress';


const QuizPanel = (
    {
        children,
        colors,
        current_question_index,
        hideProgressHeader,
        isLoading,
        level,
        onCancel,
        onProgressSelect,
        progress,
        setOpen,
        title,
        quizInSession,
        quizFinished,
        ...props
    }
) => {


    const isSmall = useMedia('(min-width: 640px)');

    /* NOTE: REMOVE 'overflow-scroll' here to have a fixed quiz header */
    return (
        <div
            tw='relative flex flex-col  w-full h-screen overflow-scroll sm:overflow-auto overflow-x-hidden text-white '
            {...props}
        >

            {!hideProgressHeader && (
                <QuizHeader
                    tw='hidden sm:block'
                    className='quiz-header '
                    level={level}
                    title={title}
                    progress={progress}
                    onCancel={onCancel}
                    setOpen={setOpen}
                    isLoading={isLoading}
                >
                    {isSmall && (
                        <QuizProgress
                            color={colors ? colors.progressColor : null}
                            progress={progress}
                            current={current_question_index}
                            onSelect={onProgressSelect}
                        />
                    )}
                </QuizHeader>
            )}

            {/* NOTE: add ' overflow-scroll' here to have a fixed quiz header */}
            <div
                className='quiz-panel-contents' tw='flex flex-col items-stretch grow' css={[css`justify-content: stretch;`]}
            >
                {children}
            </div>
    
        </div>
    );
};

export default QuizPanel;
