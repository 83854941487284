import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { SidebarItem } from 'components';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { useWindowSize } from 'react-use';

const StyledSideBar = styled.div`
    //&::-webkit-scrollbar {
    //    width: 0; /* Remove scrollbar space */
    //    background: transparent;
    //}
`;

const SlideOutSidebar = ({ classes, onAddClass, selectedClass }) => {
    const [isSidebarExpanded, setSidebarExpanded] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setSidebarExpanded(false));
    const { height } = useWindowSize();
    let calculateHeight = height - 150;
    return (
        <StyledSideBar
            ref={ref}
            css={[
                tw`h-full bg-gradient-to-t to-RoyalDarkBlue from-OxfordBlue width[var(--sidebar-width-collapsed)] fixed left-0 z-10 transition-width duration-300 ease-in-out top[68px] `,
                isSidebarExpanded && tw`width[var(--sidebar-width-expanded)]`
            ]}
            id='classes-tour-sidebar'
            // ? 68px is the is exact height of the navbar This allows the sidebar to set flush underneath the navbar
        >
            <button
                aria-label={isSidebarExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
                type='button'
                css={[
                    tw`p-3 bg-white width[var(--sidebar-width-collapsed)] focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-800 focus:ring-white absolute right-0`,
                    isSidebarExpanded && tw`bg-transparent transform rotate-180`
                ]}
                onClick={() => setSidebarExpanded(!isSidebarExpanded)}
            >
                <span tw='sr-only'>Expand Sidebar</span>
                <svg tw='w-6 h-6 ' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M20.125 22.886c.762 0 1.494-.302 2.033-.838.54-.537.842-1.264.842-2.023V2.86c0-.758-.303-1.486-.842-2.022A2.882 2.882 0 0020.125 0H2.875C2.112 0 1.381.3.842.838A2.854 2.854 0 000 2.86v17.165c0 .759.303 1.486.842 2.023.54.536 1.27.838 2.033.838h17.25zM10.991 7.658a.715.715 0 01.509-1.222c.19 0 .374.075.509.21l4.312 4.29a.716.716 0 01.157.78.717.717 0 01-.157.233L12.01 16.24a.72.72 0 01-1.018 0 .717.717 0 010-1.012l3.086-3.07h-6.89a.72.72 0 01-.718-.715.713.713 0 01.718-.716h6.89l-3.086-3.07z'
                        fill={isSidebarExpanded ? 'white' : '#080B3D'}
                    />
                </svg>
            </button>

            <div tw='mt-20 space-y-4 overflow-auto ' style={{ height: calculateHeight }}>
                {classes.map(({ id, title, display_icon }) => (
                    <SidebarItem
                    selectedClass={selectedClass}
                        classId={id}
                        classTitle={title}
                        displayIcon={display_icon ?? 'ClassIcon00'}
                        isSidebarExpanded={isSidebarExpanded}
                        key={id}
                    />
                ))}

                <button
                    type='button'
                    css={[
                        tw`p-2 text-white hover:bg-Haiti hover:text-white flex text-lg w-full focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-800 focus:ring-white`,
                        isSidebarExpanded && tw`pl-4`
                    ]}
                    onClick={onAddClass}
                >
                    <span tw='sr-only'>Expand Sidebar</span>
                    <svg width='25' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M24 0H1C.447 0 0 .447 0 1v23c0 .553.447 1 1 1h23c.553 0 1-.447 1-1V1c0-.553-.447-1-1-1zm-5.5 13.25a.25.25 0 01-.25.25H13.5v4.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V13.5H6.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h4.75V6.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v4.75h4.75a.25.25 0 01.25.25v1.5z'
                            fill='#fff'
                        />
                    </svg>
                    {isSidebarExpanded && <div tw='whitespace-nowrap pl-5'>Add Class</div>}
                </button>
            </div>
        </StyledSideBar>
    );
};

SlideOutSidebar.propTypes = {
    classes: PropTypes.array.isRequired
};

export default SlideOutSidebar;
