export const sortUsersByName = (a, b) => {
    if (a && b) {
        // compare last names
        const last_a = a.last_name;
        const last_b = b.last_name;
        if (last_a < last_b)
            return -1;
        if (last_a > last_b)
            return 1;

        // compare first names
        const first_a = a.first_name;
        const first_b = b.first_name;
        if (first_a < first_b)
            return -1;
        if (first_a > first_b)
            return 1;
    }
    return 0;
};


export const sortStudentsByName = (a, b) => {
    // place loading at the bottom
    if (a.loading && b.loading) {
        return 0;
    } else if (a.loading) {
        return 1;
    } else if (b.loading) {
        return -1;
    }

    // sort by last name
    if (a?.user && b?.user) {
        // compare last names
        const last_a = a.user.last_name;
        const last_b = b.user.last_name;
        if (last_a < last_b)
            return -1;
        if (last_a > last_b)
            return 1;

        // compare first names
        const first_a = a.user.first_name;
        const first_b = b.user.first_name;
        if (first_a < first_b)
            return -1;
        if (first_a > first_b)
            return 1;
    } else if (a.user) {
        return -1;
    } else if (b.user) {
        return 1;
    }

    return 0;
};

