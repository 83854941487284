import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import tw, { css } from 'twin.macro';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AchievementConstants from '../../app/containers/child/Progress/AchievementConstants';
import { NOTIFY_SUCCESS } from '../../context/NotificationProvider';
import { useVideos } from '../../state/CatalogState';
import { wrapTitleParens } from 'lib/string';

const formatNotificationItem = (notification, videos, navigate) => {
    // test change log 
    const { data, message, type, id, selected } = notification;

    const assignment_title = data?.assignment_title;
    // const { assignment_title } = data || {};

    switch (type) {
        case 'achievement': {
            // format the message
            let title;
            const badge = AchievementConstants[data.badge_type];
            if (badge) {
                title = badge.header;
            } else {
                title = data.description;
            }
            const video = videos.find((video) => video.id === data.video_id);
            return (
                <div tw='flex items-center relative font-medium' >
                    {!selected && <div tw='w-[10px] h-[10px] absolute -left-5 sm:-left-7 top-6 sm:top-4 bg-[#02AC6F] rounded' />}
                    <div
                        tw='max-w-[44px]'>
                        <img tw='w-full h-auto' src={badge.non_ribbon_image} alt={badge.title}/>
                    </div>
                    <div
                        tw='font-Poppins text-black ml-4'
                        // onClick={() => navigate(`video/${data.video_id}`)}
                    >
                        You earned {title} on &ldquo;{wrapTitleParens(video?.title)}&rdquo;
                    </div>
                </div>
            );
        }
        case 'new_video':
            return (
                <div tw=''>
                    <div tw='font-Poppins text-black'>Check out our new video, &ldquo;{data?.additional_data}&rdquo; </div>
                </div>
            );
        case 'assignment.due':
            return (
                <div tw='truncate w-64'>
                    Assignment <b tw='font-black'>{assignment_title}</b> is due tomorrow
                </div>
            );
        case 'assignment.created':
            return (
                <div tw='truncate w-64'>
                    You have a new assignment: <b tw='font-black'>{assignment_title}</b> due on ...
                </div>
            );

        case 'message':
            return (
                <div tw='truncate w-64'>
                    Message: <b tw='font-black'>{message}</b>
                </div>
            );

        default:
            return <>Notification: {type}</>;
    }
};

const formatDate = (date) => {
    // const datestamp = moment.utc(date).local().calendar(null, {
    const datestamp = moment(date).calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: 'dddd',
        sameElse: 'L'
    });
    // Today 12:41PM
    // return `${moment(date).fromNow()} ${moment(date).format('h:mm A')}`;
    return `${datestamp}`;
};

const NotificationItem = ({ notification, onCloseAllNotifications, setOpen }) => {
    const videos = useVideos();
    const navigate = useNavigate();
    return (
        <div
            key={notification.id}
            aria-current='page'
            tw='mx-4 md:mx-8 py-6 block border-b-2 border-[#cdc4e3]'
        >
            <div tw='mx-4 py-2 relative flex flex-col md:flex-row justify-between items-start md:items-center gap-3'>
                <p tw='text-sm '>{formatNotificationItem(notification, videos, navigate)}</p>
                <span tw='text-xs text-black uppercase whitespace-nowrap'>{formatDate(notification.created)}</span>
            </div>
        </div>
    );
};

NotificationItem.propTypes = {
    notification: PropTypes.object,
    onClick: PropTypes.func,
    onClose: PropTypes.func
};

export default NotificationItem;
