import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';

const StateSelectListBox = ({ selected, selectedChild, onSelectItem, className, cypressName, id, onBoard }) => {
    const items = [
        {
            name: 'Alabama',
            abbreviation: 'AL'
        },
        {
            name: 'Alaska',
            abbreviation: 'AK'
        },
        {
            name: 'American Samoa',
            abbreviation: 'AS'
        },
        {
            name: 'Arizona',
            abbreviation: 'AZ'
        },
        {
            name: 'Arkansas',
            abbreviation: 'AR'
        },
        {
            name: 'California',
            abbreviation: 'CA'
        },
        {
            name: 'Colorado',
            abbreviation: 'CO'
        },
        {
            name: 'Connecticut',
            abbreviation: 'CT'
        },
        {
            name: 'Delaware',
            abbreviation: 'DE'
        },
        {
            name: 'District Of Columbia',
            abbreviation: 'DC'
        },
        {
            name: 'Federated States Of Micronesia',
            abbreviation: 'FM'
        },
        {
            name: 'Florida',
            abbreviation: 'FL'
        },
        {
            name: 'Georgia',
            abbreviation: 'GA'
        },
        {
            name: 'Guam',
            abbreviation: 'GU'
        },
        {
            name: 'Hawaii',
            abbreviation: 'HI'
        },
        {
            name: 'Idaho',
            abbreviation: 'ID'
        },
        {
            name: 'Illinois',
            abbreviation: 'IL'
        },
        {
            name: 'Indiana',
            abbreviation: 'IN'
        },
        {
            name: 'Iowa',
            abbreviation: 'IA'
        },
        {
            name: 'Kansas',
            abbreviation: 'KS'
        },
        {
            name: 'Kentucky',
            abbreviation: 'KY'
        },
        {
            name: 'Louisiana',
            abbreviation: 'LA'
        },
        {
            name: 'Maine',
            abbreviation: 'ME'
        },
        {
            name: 'Marshall Islands',
            abbreviation: 'MH'
        },
        {
            name: 'Maryland',
            abbreviation: 'MD'
        },
        {
            name: 'Massachusetts',
            abbreviation: 'MA'
        },
        {
            name: 'Michigan',
            abbreviation: 'MI'
        },
        {
            name: 'Minnesota',
            abbreviation: 'MN'
        },
        {
            name: 'Mississippi',
            abbreviation: 'MS'
        },
        {
            name: 'Missouri',
            abbreviation: 'MO'
        },
        {
            name: 'Montana',
            abbreviation: 'MT'
        },
        {
            name: 'Nebraska',
            abbreviation: 'NE'
        },
        {
            name: 'Nevada',
            abbreviation: 'NV'
        },
        {
            name: 'New Hampshire',
            abbreviation: 'NH'
        },
        {
            name: 'New Jersey',
            abbreviation: 'NJ'
        },
        {
            name: 'New Mexico',
            abbreviation: 'NM'
        },
        {
            name: 'New York',
            abbreviation: 'NY'
        },
        {
            name: 'North Carolina',
            abbreviation: 'NC'
        },
        {
            name: 'North Dakota',
            abbreviation: 'ND'
        },
        {
            name: 'Northern Mariana Islands',
            abbreviation: 'MP'
        },
        {
            name: 'Ohio',
            abbreviation: 'OH'
        },
        {
            name: 'Oklahoma',
            abbreviation: 'OK'
        },
        {
            name: 'Oregon',
            abbreviation: 'OR'
        },
        {
            name: 'Palau',
            abbreviation: 'PW'
        },
        {
            name: 'Pennsylvania',
            abbreviation: 'PA'
        },
        {
            name: 'Puerto Rico',
            abbreviation: 'PR'
        },
        {
            name: 'Rhode Island',
            abbreviation: 'RI'
        },
        {
            name: 'South Carolina',
            abbreviation: 'SC'
        },
        {
            name: 'South Dakota',
            abbreviation: 'SD'
        },
        {
            name: 'Tennessee',
            abbreviation: 'TN'
        },
        {
            name: 'Texas',
            abbreviation: 'TX'
        },
        {
            name: 'Utah',
            abbreviation: 'UT'
        },
        {
            name: 'Vermont',
            abbreviation: 'VT'
        },
        {
            name: 'Virgin Islands',
            abbreviation: 'VI'
        },
        {
            name: 'Virginia',
            abbreviation: 'VA'
        },
        {
            name: 'Washington',
            abbreviation: 'WA'
        },
        {
            name: 'West Virginia',
            abbreviation: 'WV'
        },
        {
            name: 'Wisconsin',
            abbreviation: 'WI'
        },
        {
            name: 'Wyoming',
            abbreviation: 'WY'
        }
    ];
    const [selectedItem, setSelectedItem] = useState(null);

    React.useEffect(() => {
        if (selected) {
            // find the selected state
            const found = items.find(state => state.abbreviation === selected);
            if (found) {
                setSelectedItem(found);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChild, selected]);

    const handleChanged = (item) => {
        setSelectedItem(item);
        if (onSelectItem)
            onSelectItem(item);
    };

    return (
        <Listbox
            id={id}
            value={selected}
            onChange={handleChanged}
            className={className}
            data-testid={cypressName}
        >
            <div tw='relative mt-1 w-full'>
                <Listbox.Button
                    tw='relative flex items-center justify-between text-[#6E7191] w-full bg-[#1C0057] sm:bg-[#3E257A]   border-[#F4FBFF] border-2 py-0.5 px-2 text-left rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:border-[#F4FBFF] sm:text-sm'
                    css={[onBoard && tw`bg-[#F4FBFF] sm:bg-[#F4FBFF] text-[#6E7191]`]}
                >

                    <span tw='block truncate text-justify'>
                        {selectedItem ? selectedItem.abbreviation : 'State '}
                        {/* {selected ?? 'State'} */}
                    </span>
                    <span tw='pr-2 pointer-events-none minHeight[33px] ml-3.5 flex items-center justify-center'>
                        <svg
                            width='11' height='6' viewBox='0 0 11 6' fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.300573 0.306863C0.714038 -0.0866995 1.28966 -0.117584 1.79512 0.306863L5.50112 3.61332L9.20713 0.306863C9.71258 -0.117584 10.2892 -0.0866995 10.6998 0.306863C11.1132 0.699543 11.0867 1.36313 10.6998 1.73198C10.3148 2.10084 6.24745 5.70467 6.24745 5.70467C6.15059 5.79807 6.0345 5.87237 5.90611 5.92311C5.77773 5.97385 5.63966 6 5.50017 6C5.36068 6 5.22262 5.97385 5.09424 5.92311C4.96585 5.87237 4.84976 5.79807 4.7529 5.70467C4.7529 5.70467 0.687485 2.10084 0.300573 1.73198C-0.0872867 1.36313 -0.112891 0.699543 0.300573 0.306863Z'
                                fill={onBoard ? '#6E7191' : '#fff'}
                            />
                        </svg>
                    </span>
                </Listbox.Button>

                <Listbox.Options
                    tw='absolute z-20 w-full mt-1 overflow-auto text-base rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none sm:text-sm'
                    style={{ height: '150px' }}
                >
                    {items.map((item) => (
                        <Listbox.Option
                            key={item.name}
                            value={item}
                            disabled={item.unavailable}
                            tw='bg-white text-black select-none relative py-2 px-2 cursor-pointer text-justify hover:bg-GhostWhite focus:outline-none focus:ring-2 focus:ring-GhostWhite focus:ring-opacity-50'
                        >
                            {item.abbreviation}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

StateSelectListBox.propTypes = {
    anyProp: PropTypes.any
};

export default StateSelectListBox;
