import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import * as ClassIcons from 'assets/class_icons';

export const ClassIcon = ({ name, width, height, className, ...props }) => {
    if (!(name in ClassIcons)) {
        return null;
    }
    const Icon = ClassIcons[name];
    return (
        <Icon
            className={className}
            width={width ?? '100%'}
            height={height ?? '100%'}
            {...props}
        />
    );
};
ClassIcon.propTypes = {
    name: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

export default ClassIcon;

