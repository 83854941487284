import React, { useCallback } from 'react';
import useAxios from 'axios-hooks';
import { handleNetworkError } from '../lib/axios';
import { createMuzologyAPIURL } from '../utils/url';

export const createAssignmentGradesURL = (assignmentId, classId=null) => {
    console.log('createAssignmentGradesURL', assignmentId, classId);
    let url = createMuzologyAPIURL(`/reports/assignment_scores/${assignmentId}/`);
    if( classId )
        return `${url}?class=${classId}`;
    return url;
};

export const createVideoScoresURL = (videoId) => {
    return createMuzologyAPIURL(`/reports/video_scores/${videoId}/`);
};


export function useGradeExport() {
    const [{ loading }, downloadFile] = useAxios({ method: 'GET', responseType: 'blob' }, { manual: true });
    // console.log('query params = ', location.search);
    // return new URLSearchParams(location.search);
    return useCallback((assignmentId) => {
        console.log('Download grades for', assignmentId);
        // window.open();
        downloadFile({ url: createAssignmentGradesURL(assignmentId) })
            .then(response => {
                console.log('downloaded grades', response.data);
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', `grades-${assignmentId}.csv`);
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);
            })
            .catch(err => {
                console.log('Error exporting assignment grades:', err);
                handleNetworkError(err);
            });

    }, [downloadFile]);

}

export default useGradeExport;
