import React, { useMemo, useRef, useState } from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import RosterTable from 'components/RosterTable/RosterTable';
import Button from 'components/Button/Button';
import { useClassStudents } from 'state/Teacher/ClassState';
import { Modal, ClassIcon, FormError, NullContainer } from 'components';
import { useForm } from 'react-hook-form';
import CSRFToken from 'lib/components/csrftoken';
import { is_username_valid } from 'lib/util';
import LoadingOverlay from 'components/LoadingOverlay';
import { sortStudentsByName } from '../../../../../utils/sorting';
import CloseButton from '../../../../../components/AnswerKey/CloseButton';
import { useAccount } from '../../../../../state/User/UserState';
import ClassInviteModal from '../ClassInviteModal';

const S = {
    Container: styled.div`
        height: 100%;
    `,
    Wrapper: styled.div`
        ::-webkit-scrollbar {
            display: none;
        }

        @media only screen and (max-height: 768px) {
            max-height: 410px;
        }
        padding-bottom: 50px;
    `,
    ButtonRow: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;

        button {
            margin-left: 10px;
        }
    `
};

const RosterTab = ({ classId, selectedClass, setOpenNewClass }) => {
    const { students, addStudent: addStudentAction, removeStudent, reloadStudents, changeStudentName } = useClassStudents(classId);
    const [showModalLoading, setShowModalLoading] = useState(false);
    const [addStudent, setAddStudent] = useState(false);
    const [inviteStudent, setInviteStudent] = useState(false);
    const [submitErrors, setSubmitErrors] = useState({});
    // const addStudentAction = useAddStudent(classId);
    const {
        register,
        handleSubmit,
        watch,
        errors: formErrors
        // formState: { isSubmitting }
    } = useForm();

    const password = useRef({});
    password.current = watch('password', '');

    // sort the students
    const sortedStudents = useMemo(() => [...students].sort(sortStudentsByName), [students]);

    const handleStudentSubmit = (data) => {
        setShowModalLoading(true);

        // createStudent({ url: `api/classes/${classId}/assign/`, data: body })
        addStudentAction(data)
            .then((value) => {
                console.log('promise value', value);
                return value;
            })
            .then(async ({ data }) => {
                const { error } = data;
                if (error) {
                    console.log('Submit error: ', error);
                    setSubmitErrors(error);
                    setShowModalLoading(false);
                } else {
                    console.log('Submit successful', data);
                    await reloadStudents();
                    setSubmitErrors({});
                    setAddStudent(false);
                    setShowModalLoading(false);
                }
            })
            .catch((err) => {
                console.error(err);
                // setSubmitErrors(err.data);
                setShowModalLoading(false);
            });
    };

    // if join codes are disabled - don't show join code (CoreCivic modification)
    const { disable_join_code } = useAccount();

    return (
        <S.Container>
            <S.Wrapper>
                {!!selectedClass && (
                    <S.ButtonRow>
                        <Button
                            onClick={() => {
                                setAddStudent(true);
                            }}
                        >
                            ADD STUDENT
                        </Button>
                        {!disable_join_code && <Button
                            onClick={() => {
                                setInviteStudent(true);
                            }}
                        >
                            INVITE STUDENTS
                        </Button>}
                    </S.ButtonRow>
                )}

                {sortedStudents?.length > 0 ? (
                    <RosterTable currentClass={selectedClass} students={sortedStudents} removeStudent={removeStudent} changeStudentName={changeStudentName} />
                ) : (
                    <NullContainer>

                        <p>When you create classes and add students, this is where you can manage your class roster.</p>

                        <p tw='pt-8'>
                            <span>Create a class by </span>
                            <span tw='underline cursor-pointer' onClick={() => setOpenNewClass(true)}>
                                clicking here
                            </span>{' '}
                            <span>or using the</span>
                            <svg
                                width='25' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'
                                style={{ display: 'inline', margin: '0 10px' }}>
                                <path
                                    d='M24 0H1C.447 0 0 .447 0 1v23c0 .553.447 1 1 1h23c.553 0 1-.447 1-1V1c0-.553-.447-1-1-1Zm-5.5 13.25a.25.25 0 0 1-.25.25H13.5v4.75a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25V13.5H6.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h4.75V6.75a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v4.75h4.75a.25.25 0 0 1 .25.25v1.5Z'
                                    fill='#fff'
                                />
                            </svg>
                            <span>
                                button in the upper left. Once you create a class, you will see a Class Join Code button on this page (in the upper right).
                                Click on the button for info on how students can join your Muzology classes.
                            </span>
                        </p>

                        <p tw='pt-8'>
                            (If your school uses Google Classroom, you and your students can go to app.muzology.com and log in using Google. This way, students will automatically be added to your classes.)
                        </p>
                    </NullContainer>
                )}

                <ClassInviteModal inviteStudent={inviteStudent} setInviteStudent={setInviteStudent} selected={selectedClass} />

                <Modal isOpen={addStudent} setIsOpen={setAddStudent} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
                    <div tw='relative bg-white p-7 rounded flex-col justify-center font-sans' style={{ width: '650px' }}>
                        <LoadingOverlay show={showModalLoading} />

                        <form onSubmit={handleSubmit(handleStudentSubmit)}>
                            <CSRFToken />
                            {FormError(submitErrors.error)}

                            <div tw='flex items-center font-medium text-2xl text-EarieBlack'>
                                Add a student to
                                <div tw='w-9 h-9 mx-1.5'>
                                    <ClassIcon name={selectedClass?.display_icon ?? 'ClassIcon00'} />
                                </div>
                                {selectedClass?.title}
                            </div>
                            <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Full Name *</div>
                            <input
                                type='text'
                                name='fullname'
                                ref={register({ required: true })}
                                tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                            />
                            {FormError(formErrors.fullname)}

                            <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Username *</div>
                            <input
                                tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                                type='text'
                                name='username'
                                ref={register({
                                    required: true,
                                    validate: (value) => is_username_valid(value) || 'The username can only contain letters, numbers, _, @, +, . and -'
                                })}
                            />
                            {FormError(submitErrors.username)}
                            {FormError(formErrors.username)}

                            <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Create Password *</div>
                            <input
                                tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                                type='password'
                                name='password'
                                ref={register({
                                    required: true,
                                    minLength: { value: 6, message: 'Password must have at least 6 characters' }
                                })}
                            />
                            {FormError(formErrors.password)}

                            <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Confirm Password *</div>
                            <input
                                tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                                type='password'
                                name='confirmPassword'
                                ref={register({ validate: (value) => value === password.current || 'The passwords do not match' })}
                            />
                            {FormError(formErrors.confirmPassword)}
                            <div tw='flex justify-end mt-6'>
                                <Button type='submit'>Submit</Button>
                            </div>
                        </form>
                        <CloseButton
                            tw='absolute -top-4 -right-4'
                            onClick={() => setAddStudent(false)}
                        />

                    </div>
                </Modal>
            </S.Wrapper>
        </S.Container>
    );
};

export default RosterTab;
