/**
 * Created by jasonduncan on 7/24/18.
 */
import React from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

const InputContainer = styled.div`
    font-family: Avenir, sans-serif;
    font-size: 20px;

    .text-wrapper {
        position: relative;

        .input-text-area {
            padding: 4px 8px;
            border: ${(props) => `5px solid ${props.resultColor}`};
            //height: 143px;
        }
    }
`;

const ResultIcon = styled.div`
    position: absolute;
    top: 0;
    bottom: 14px;
    right: 0;
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.resultColor};
`;

// import IncorrectChoiceIcon from './icon-incorrect-choice.svg';
// console.log(IncorrectChoiceIcon);
// const IncorrectChoiceSVG = (props) => {
//     return (
//         <IncorrectChoiceIcon/>
//     );
// };

class Input extends React.Component {
    static defaultProps = {
        text: '',
        onChanged: null,
        question: null,
        context: null,
        singleLine: false,
        disabled: false
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.textArea = React.createRef();
    }

    onInputChanged = (event) => {
        // get the text from the element
        if (!event || !event.target)
            return;
        let inputText = event.target.value;

        // pass the text to the parent
        if (this.props.onChanged && !this.props.disabled)
            this.props.onChanged(inputText);
    };

    // get the current cursor location
    getCaretPosition = () => {
        const ctrl = this.textArea.current;
        let caretPos = 0;

        if (ctrl.selectionStart || ctrl.selectionStart === 0) {
            // Standard.
            caretPos = ctrl.selectionStart;
        } else if (document.selection) {
            // Legacy IE
            ctrl.focus();
            let range = document.selection.createRange();
            range.moveStart('character', -ctrl.value.length);
            caretPos = range.text.length;
        }

        return caretPos;
    };

    // set the current cursor location
    setCaretPosition = (pos) => {
        const ctrl = this.textArea.current;
        if (ctrl) {
            if (ctrl.setSelectionRange) {
                // ctrl.focus();
                ctrl.setSelectionRange(pos, pos);
            } else if (ctrl.createTextRange) {
                let range = ctrl.createTextRange();
                range.collapse(true);
                range.moveEnd('character', pos);
                range.moveStart('character', pos);
                range.select();
            }
        }
    };

    // insert a character at the current cursor location
    insertCharacter = (char) => {
        console.log('insertCharacter:', char);
        const currentText = this.textArea.current.value;
        const newText = currentText + char;
        this.textArea.current.value = newText;

        /*
        // insert this char at the cursor location
        let pos = this.getCaretPosition();
        let new_text = char;
        if (this.props.text) {
            // TODO: guard against pos overflow
            new_text = this.props.text.slice(0, pos) + char + this.props.text.slice(pos);
        }
        this.textArea.current.value = new_text;

        // move the cursor
        this.setCaretPosition(pos + 1);
        */

        // notify the parent
        if (this.props.onChanged)
            this.props.onChanged(newText);
    };

    // check if answer is correct / incorrect to display outline
    render() {
        let resultColor;
        let waitNext = this.props.context?.state === 'wait_next';
        if (this.props.context?.response?.correct && waitNext) {
            resultColor = '#5FC17A';
        }
        if (!this.props.context?.response?.correct && waitNext) {
            resultColor = '#EE7171';
        }

        // build the input control
        let inputControl = null;
        if (this.props.singleLine) {
            inputControl = (
                <input
                    ref={this.textArea}
                    className='input-text-area'
                    onChange={this.onInputChanged}
                    style={{ width: '100%', height: '44px' }}
                    value={this.props.text ?? ''}
                    name='free-entry-input'
                    data-testid='free-entry-input'
                />
            );
        } else {
            inputControl = (
                <textarea
                    ref={this.textArea}
                    className='input-text-area'
                    onChange={this.onInputChanged}
                    style={{ width: '100%', height: '44px' }}
                    value={this.props.text ?? ''}
                    name='free-entry-input'
                    data-testid='free-entry-input'
                />
            );
        }

        return (
            <InputContainer
                resultColor={resultColor}
                className='input-container'
            >
                <div className='text-wrapper'>
                    {inputControl}
                    {waitNext && (
                        <ResultIcon resultColor={resultColor} style={{ bottom: this.props.singleLine ? 0 : '13px' }}>
                            {this.props.context?.response?.correct ? (
                                <svg
                                    width='21' height='15' viewBox='0 0 21 15' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M20.2855 0.51438C20.1499 0.377693 19.9886 0.269201 19.8109 0.195163C19.6332 0.121126 19.4426 0.0830078 19.2501 0.0830078C19.0576 0.0830078 18.8669 0.121126 18.6892 0.195163C18.5115 0.269201 18.3502 0.377693 18.2147 0.51438L7.35007 11.3935L2.78549 6.81438C2.64473 6.67841 2.47856 6.57149 2.29648 6.49974C2.1144 6.42798 1.91997 6.39279 1.72429 6.39618C1.52861 6.39956 1.33551 6.44145 1.15602 6.51947C0.976533 6.59748 0.814167 6.71008 0.678194 6.85084C0.542221 6.9916 0.435305 7.15776 0.363549 7.33984C0.291793 7.52192 0.256603 7.71636 0.259989 7.91204C0.263374 8.10772 0.305269 8.30081 0.38328 8.4803C0.461291 8.65979 0.573892 8.82216 0.714652 8.95813L6.31465 14.5581C6.45022 14.6948 6.61152 14.8033 6.78923 14.8773C6.96694 14.9514 7.15755 14.9895 7.35007 14.9895C7.54259 14.9895 7.7332 14.9514 7.91091 14.8773C8.08862 14.8033 8.24992 14.6948 8.38549 14.5581L20.2855 2.65813C20.4335 2.52157 20.5516 2.35583 20.6325 2.17135C20.7133 1.98687 20.755 1.78765 20.755 1.58626C20.755 1.38486 20.7133 1.18564 20.6325 1.00116C20.5516 0.816684 20.4335 0.650942 20.2855 0.51438Z'
                                        fill='white'
                                    />
                                </svg>
                            ) : (
                                <svg
                                    width='19' height='19' viewBox='0 0 19 19' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M11.5569 9.50011L17.8277 3.24386C18.1023 2.96925 18.2566 2.5968 18.2566 2.20844C18.2566 1.82008 18.1023 1.44763 17.8277 1.17302C17.5531 0.898415 17.1807 0.744141 16.7923 0.744141C16.404 0.744141 16.0315 0.898415 15.7569 1.17302L9.50064 7.44386L3.24439 1.17302C2.96978 0.898415 2.59733 0.744141 2.20898 0.744141C1.82062 0.744141 1.44817 0.898415 1.17356 1.17302C0.89895 1.44763 0.744676 1.82008 0.744676 2.20844C0.744676 2.5968 0.89895 2.96925 1.17356 3.24386L7.44439 9.50011L1.17356 15.7564C1.03687 15.8919 0.928381 16.0532 0.854344 16.2309C0.780306 16.4086 0.742188 16.5993 0.742188 16.7918C0.742188 16.9843 0.780306 17.1749 0.854344 17.3526C0.928381 17.5303 1.03687 17.6916 1.17356 17.8272C1.30913 17.9639 1.47042 18.0724 1.64814 18.1464C1.82585 18.2204 2.01646 18.2586 2.20898 18.2586C2.40149 18.2586 2.59211 18.2204 2.76982 18.1464C2.94753 18.0724 3.10882 17.9639 3.24439 17.8272L9.50064 11.5564L15.7569 17.8272C15.8925 17.9639 16.0538 18.0724 16.2315 18.1464C16.4092 18.2204 16.5998 18.2586 16.7923 18.2586C16.9848 18.2586 17.1754 18.2204 17.3532 18.1464C17.5309 18.0724 17.6922 17.9639 17.8277 17.8272C17.9644 17.6916 18.0729 17.5303 18.1469 17.3526C18.221 17.1749 18.2591 16.9843 18.2591 16.7918C18.2591 16.5993 18.221 16.4086 18.1469 16.2309C18.0729 16.0532 17.9644 15.8919 17.8277 15.7564L11.5569 9.50011Z'
                                        fill='white'
                                    />
                                </svg>
                            )}
                        </ResultIcon>
                    )}
                </div>
            </InputContainer>
        );
    }
}

export default Input;
