import tw from 'twin.macro';
import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ClassIcon from '../ClassIcon/ClassIcon';

const SidebarItem = ({ classId, classTitle, displayIcon, isSidebarExpanded, selectedClass }) => {
    const isItemSelected = selectedClass?.id === classId
    const match = useMatch('/classes/:class_id/:tab_name');
    const { tab_name } = match?.params ?? {};
    return (
        <NavLink
            to={`/classes/${classId}/${tab_name ?? 'stats'}`}
            aria-current='page'
            aria-label={classTitle}
            css={[
                tw`p-2 text-white hover:bg-Haiti hover:text-white flex text-lg relative z-10`,
                isSidebarExpanded && tw`pl-4`,
            ]}
            activeStyle={tw`bg-RoyalPurple`}
        >
            <div tw='w-[31px] h-[31px] rounded-full flex items-center justify-center' css={[isItemSelected && tw`border-white border-2`]}>
                <ClassIcon
                    name={displayIcon ?? 'ClassIcon1'}
                    width='29px'
                    height='29px' />
            </div>

            {isSidebarExpanded && <div tw='whitespace-nowrap absolute left-16'>{classTitle}</div>}
        </NavLink>
    );
};

SidebarItem.propTypes = {
    classId: PropTypes.string.isRequired,
    classTitle: PropTypes.string.isRequired,
    isSidebarExpanded: PropTypes.bool.isRequired
};

export default SidebarItem;
