import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';

// video player types
import { VideoPlayerBitmovin, VideoPlayerDummy, VideoPlayerVideoJS } from './constants';
import { BitmovinPlayer, DummyPlayer, VideoJSPlayer } from './Players';

// video player types
const VideoPlayerTypes = {
    [VideoPlayerDummy]: DummyPlayer,
    [VideoPlayerVideoJS]: VideoJSPlayer,
    [VideoPlayerBitmovin]: BitmovinPlayer
};

/*
    returns the video player type provided by playerType
 */
const VideoPlayer = (props) => {
    // get the props
    const { playerType } = props;

    // get the video player
    const VideoPlayerType = VideoPlayerTypes[playerType ?? VideoJSPlayer] ?? VideoJSPlayer;

    // post the ranges watched in a video session
    //     postVideoSessionRanges(session_id, ranges) {
    //         return async (dispatch) => {
    //             console.assert(session_id != null);
    //             if (ranges.length <= 0) return;
    //
    //             // convert the ranges to an array
    //             let data = time_ranges_to_array(ranges);
    //             console.log('played ranges:', data);
    //
    //             // construct the URL
    //             const url = `/api/video_sessions/${session_id}/ranges/`;
    //
    //             // post a call to the API
    //             dispatch({
    //                 [RSAA]: {
    //                     types: [POST_VIDEO_RANGES, POST_VIDEO_RANGES_SUCCESS, POST_VIDEO_RANGES_FAILURE],
    //                     endpoint: url,
    //                     method: 'POST',
    //                     headers: {
    //                         'X-CSRFToken': getCookie('csrftoken'),
    //                         'Content-Type': 'application/json'
    //                     },
    //                     credentials: 'include',
    //                     body: JSON.stringify(data)
    //                 }
    //             });
    //         };
    //     }

    // const { video, metadata } = props;
    // const sources = createVideoSources(video);
    // const videoJsOptions = {
    //     autoplay: false,
    //     controls: true,
    //     sources: sources,
    //     poster: video?.url_thumbnail
    // };

    // create the session id
    // const [sessionId, setSessionId] = useState(null);
    // useEffect(() => {
    //     setSessionId(uuid());
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // const onVideoStart = () => {
    //     console.log('onVideoStart');
    // };
    //
    // const onVideoPlay = () => {
    //     console.log('onVideoPlay');
    // };
    //
    // const onVideoPause = () => {
    //     console.log('onVideoPause');
    // };
    //
    // const onVideoEnd = () => {
    //     console.log('onVideoEnd');
    // };
    //
    // const handleVideoFinished = () => {
    //     console.log('video finished');
    // };
    //
    // const postVideoSessionRanges = (sessionID, ranges) => {
    //     console.log('postVideoSessionRanges', sessionID, ranges);
    // };

    return (
        <VideoPlayerType
            // className='sticky-video'
            // videoJsOptions={videoJsOptions}
            // metaData={metaData}
            // onReady={onVideoStart}
            // onPlay={onVideoPlay}
            // onPause={onVideoPause}
            // onEnd={onVideoEnd}
            // postVideoSessionRanges={postVideoSessionRanges}
            {...props}
        />
    );
};

VideoPlayer.propTypes = {
    playerType: PropTypes.string
};

/*
    useVideoPlayer
 */

export function useVideoPlayer(sources, options) {

    // create a reference for the video player
    const playerRef = useRef(null);
    const elementRef = useRef(null);

    // get the options
    const { playerType } = (options ?? {});

    // get the options
    // const playerType = options['playerType'] ?? 'dummy';

    // get the video player
    const VideoPlayerType = VideoPlayerTypes[playerType ?? VideoPlayerVideoJS];

    // useEffect(() => {
    //     console.log('VideoPlayer playerRef', playerRef);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [playerRef]);

    // create the wrapper
    const VideoPlayerWrapper = (props) => {
        return (
            <VideoPlayerType
                ref={elementRef}
                playerRef={playerRef}
                {...props}
            />
        );
    };

    // console.log('VideoPlayer', elementRef, playerRef);
    return VideoPlayerWrapper;
}


export default VideoPlayer;

