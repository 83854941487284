import moment from 'moment';

////////////////////////////////
// sort the videos

// sort by video watched date
export const sortWatchDate = (a, b) => {
    if ((!a.stats || !a.stats.last_viewed) && (!b.stats || !b.stats.last_viewed)) {
        return 0;
    }
    if (!a.stats || !a.stats.last_viewed) {
        return 1;
    } else if (!b.stats || !b.stats.last_viewed) {
        return -1;
    } else if (a.stats.last_viewed === b.stats.last_viewed) {
        return 0;
    } else {
        return moment(b.stats.last_viewed).diff(moment(a.stats.last_viewed));
    }
};

// sort by assignment due date
export const sortDueDate = (a, b) => {
    if (!a.assignment && !b.assignment) {
        return 0;
    } else if (!a.assignment) {
        return 1;
    } else if (!b.assignment) {
        return -1;
    } else {
        return moment(b.assignment[0].assignment.created).diff(moment(a.assignment[0].assignment.created));
    }
};

// sort by high score
export const sortHighScore = (a, b) => {
    if (!a.stats || !b.stats) return 0;
    else if (a.stats && b.stats) return b.stats.current_level * 100 + b.stats.current_score - (a.stats.current_level * 100 + a.stats.current_score);
    else if (a.stats) return 1;
    else if (b.stats) return -1;
    return 0;
};

// sort by assigned progress
export const sortOverdueToComplete = (a, b) => {
    if (!a.assignment || !a.assignment[0].progress || !b.assignment || !b.assignment[0].progress) return 0;
    else if (a.assignment[0].progress.completed && b.assignment[0].progress.completed)
        return b.assignment[0].progress.completed - a.assignment[0].progress.completed;
    else if (a.assignment[0].progress.completed) return 1;
    else if (b.assignment[0].progress.completed) return -1;
    return 0;
};
