import React, { useState } from 'react';
import ScrollState from 'context/ScrollState';
import { styled } from 'twin.macro';

import { DEFAULT_NAVBAR_HEIGHT } from 'app/layout/Layouts';
import { useUser } from 'state/User/UserState';
import Navbar from 'components/Navbar/Navbar.js';
import { ErrorBoundary } from 'react-error-boundary';

const LayoutContainerStyle = styled.div`
    height: 100%;
`;

const AppLayoutStyle = styled.main`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .app-content {
        flex: 1;
    }
`;

// const HamburgerNavigationContainer = styled.div`
//     display: flex;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 200;
//     width: 100%;
//     transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
//     transition: transform 0.1s ease-in-out;
//
// `;

const AppLayout = ({ children }) => {
    const user = useUser();
    const { is_hijacked } = user;

    return (
        <ScrollState elementClassName='main-content-container'>
            <LayoutContainerStyle>
                {/* Navigation bar */}
                <ErrorBoundary>
                    <Navbar user={user} is_hijacked={is_hijacked} />
                </ErrorBoundary>

                {/* application contents */}
                <ErrorBoundary style={{ marginTop: DEFAULT_NAVBAR_HEIGHT }}>
                    <AppLayoutStyle>
                        <div className='app-content'>{children}</div>
                        {/* <HamburgerNavigationContainer
                            open={open}
                            isSmall={isSmall}>
                            <HamburgerNavigation
                                handleToggle={handleToggle}
                                open={open}
                                setOpen={setOpen}
                                user={user} />
                        </HamburgerNavigationContainer> */}
                    </AppLayoutStyle>
                </ErrorBoundary>
            </LayoutContainerStyle>
        </ScrollState>
    );
};

export default AppLayout;
