import { useEffect, useState } from 'react';
import useAlgolia from 'use-algolia';
import { apiClient } from '../../../../utils/api-client';

// TODO put in env var
const APP_ID = 'D88SWT8FNZ';
const SEARCH_KEY = '82f5b78274d8e616cc6145cbd2deab22';
const INDEX_NAME = 'Lesson_prod';

export function useVideoSearch(props) {
    const [searchQuery, setSearchQuery] = useState(null);
    const [results, setResults] = useState(null);
    const [searchState, requestDispatch, getMore] = useAlgolia(APP_ID, SEARCH_KEY, INDEX_NAME);
    const { hits, response, loading, hasMore } = searchState;
    // console.log('[search] Search state', searchState);

    // perform search
    useEffect(() => {
        if (searchQuery && searchQuery !== '') {
            console.debug('[search] Search query', searchQuery);
            requestDispatch({ query: searchQuery });

            // send the query to the server
            apiClient.post('/api/search/', { query: searchQuery });
        } else {
            setResults(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    // save results
    useEffect(() => {
        console.debug('[search] Search hits', hits);
        if (searchQuery) {
            setResults(hits);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, hits]);

    return {
        query: searchQuery,
        search: setSearchQuery,
        hits: results,
        response,
        loading,
        hasMore,
        getMore
    };
}
