import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import tw, { styled } from 'twin.macro';
import { useKey } from 'react-use';

const StyledTransitionChildOverlay = styled(Transition.Child)`
    &.enter {
        ${tw`ease-out duration-300`}
    }

    &.enterFrom {
        ${tw`opacity-0`}
    }

    &.enterTo {
        ${tw`opacity-100`}
    }

    &.leave {
        ${tw`ease-in duration-200`}
    }

    &.leaveFrom {
        ${tw`opacity-100`}
    }

    &.leaveTo {
        ${tw`opacity-0`}
    }
`;

const StyledTransitionChildContent = styled(Transition.Child)`
    ${tw`z-20`}
    &.enter {
        ${tw`ease-out duration-500`}
    }

    &.enterFrom {
        ${tw`scale-95 opacity-0`}
    }

    &.enterTo {
        ${tw`scale-100 opacity-100`}
    }

    &.leave {
        ${tw`duration-500 ease-out`}
    }

    &.leaveFrom {
        ${tw`scale-100 opacity-100`}
    }

    &.leaveTo {
        ${tw`scale-95 opacity-0`}
    }
`;

const Modal = ({ isOpen, setIsOpen, children, modalChildrenStyle, setConditionalChildren, modalChildrenStyleOpacity, fakeSetIsOpen }) => {
    useKey('Escape', () => {
        if (isOpen && setIsOpen)
            setIsOpen(false);
    });
    let ref = useRef();
    return (
        <Transition show={!!isOpen} id='modal'>
            <Dialog
                static
                open={isOpen}
                initialFocus={ref}
                onClose={() => {
                    fakeSetIsOpen && fakeSetIsOpen(true);
                    setIsOpen && setIsOpen(false);
                    setConditionalChildren && setConditionalChildren(false);
                }}
                tw='fixed z-10 inset-0 overflow-y-auto'
            >
                <div tw='flex items-center justify-center min-h-screen '>
                    <StyledTransitionChildOverlay
                        enter='enter'
                        enterFrom='enterFrom'
                        enterTo='enterTo'
                        leave='leave'
                        leaveFrom='leaveFrom'
                        leaveTo='leaveTo'>
                        <Dialog.Overlay tw='fixed inset-0 bg-black opacity-60' css={[modalChildrenStyleOpacity]} />
                    </StyledTransitionChildOverlay>
                    <StyledTransitionChildContent
                        enter='enter'
                        enterFrom='enterFrom'
                        enterTo='enterTo'
                        leave='leave'
                        leaveFrom='leaveFrom'
                        leaveTo='leaveTo'
                        css={[modalChildrenStyle]}
                    >
                        <div ref={ref}>{children}</div>
                    </StyledTransitionChildContent>
                </div>
            </Dialog>
        </Transition>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    modalChildrenStyle: PropTypes.object
};

export default Modal;
