// button state
export const BUTTON_STATE_UNSELECTED = 0;
export const BUTTON_STATE_SELECTED = 1;
export const BUTTON_STATE_CORRECT = 2;
export const BUTTON_STATE_INCORRECT = 3;

// choice state
export const CHOICE_UNSELECTED = 0;
export const CHOICE_SELECTED = 1;
export const CHOICE_CORRECT = 2;
export const CHOICE_INCORRECT = 3;

// progress states
export const PROGRESS_ITEM_NONE = 0;
export const PROGRESS_ITEM_CORRECT = 1;
export const PROGRESS_ITEM_INCORRECT = -1;

// quiz question types
export const QUESTION_TYPE_MULTIPLE_CHOICE = 0;
export const QUESTION_TYPE_MULTIPLE_SELECT = 1;
export const QUESTION_TYPE_TRUE_FALSE = 2;
export const QUESTION_TYPE_FREE_ENTRY = 3;

// quiz session state
export const QUIZ_STATE_INIT = 'quiz_session_init'; // the quiz session is beginning to initialize
export const QUIZ_STATE_CREATE_SESSION = 'quiz_create_session'; // quiz session is being created by the server and returned
export const QUIZ_STATE_READY = 'quiz_session_ready'; // quiz session is initialized and waiting to begin
export const QUIZ_STATE_RUNNING = 'quiz_running'; // quiz session context is up and running
export const QUIZ_STATE_RUNNING_BUT_FINISHED = 'quiz_running_finished'; // the quiz session is finished but still running and waiting for user input
export const QUIZ_STATE_COMPLETED = 'quiz_completed'; // quiz session is finished
export const QUIZ_STATE_CANCELLED = 'quiz_cancelled'; // quiz session has been cancelled

export const QUIZ_STATE_LOADING = 'quiz_loading'; // quiz definition is loading
export const QUIZ_STATE_LOADED = 'quiz_loaded'; // quiz definition is loaded
export const QUIZ_STATE_LOAD_FAILED = 'quiz_load_failed'; // failed to load the quiz definition

export const QUIZ_STATE_INSTANCE_LOADING = 'quiz_instance_loading'; // quiz instance is loading
export const QUIZ_STATE_INSTANCE_LOADED = 'quiz_instance_loaded'; // quiz instance is loaded
export const QUIZ_STATE_INSTANCE_LOAD_FAILED = 'quiz_instance_load_failed'; // failed to load the quiz instance

// running context state
export const CONTEXT_STATE_INITIALIZING = 'context_init'; // the context is starting up
export const CONTEXT_STATE_INITIALIZED = 'context_initialized'; // the context has been created
export const CONTEXT_STATE_WAITING_FOR_USER = 'user_answering'; // user is selecting an input
export const CONTEXT_STATE_CHECKING = 'checking'; // question check process has started
export const CONTEXT_STATE_SUBMITTING = 'submitting'; // question is being submitted to the server
export const CONTEXT_STATE_WAIT_NEXT = 'wait_next'; // waiting for the user to proceed to the next question
export const CONTEXT_STATE_SUBMITTING_COMPLETE = 'submitting_complete'; // question is finished submitting to the server
export const CONTEXT_STATE_FINISHED = 'finished'; // the quiz is finished

// Action Identifiers

// session.js
export const INIT_QUIZ_CONTEXT = 'muzology/quiz_session/INIT_QUIZ_CONTEXT';
export const INIT_QUIZ_SESSION = 'muzology/quiz_session/INIT_QUIZ_SESSION';
export const QUIZ_SESSION_READY = 'muzology/quiz_session/QUIZ_SESSION_READY';
export const SET_SESSION_STATE = 'muzology/quiz_session/SET_SESSION_STATE';
export const END_QUIZ_SESSION = 'muzology/quiz_session/END_QUIZ_SESSION';

// context.js
export const BEGIN_QUIZ_QUESTION = 'muzology/quiz_context/BEGIN_QUIZ_QUESTION';
export const CLEAR_BUTTON_STATE = 'muzology/quiz_context/CLEAR_BUTTON_STATE';

export const SET_CURRENT_QUESTION = 'muzology/quiz_context/SET_CURRENT_QUESTION';
export const SET_QUESTION_INDEX = 'muzology/quiz_context/SET_QUESTION_INDEX';

export const SET_BUTTON_STATE = 'muzology/quiz_context/SET_BUTTON_STATE';
export const SELECT_QUESTION_CHOICE = 'muzology/quiz_context/SELECT_QUESTION_CHOICE';
export const FREE_RESPONSE_TEXT_CHANGED = 'muzology/quiz_context/FREE_RESPONSE_TEXT_CHANGED';
export const SUBMIT_QUESTION_ANSWER = 'muzology/quiz_context/SUBMIT_QUESTION_ANSWER';
export const NEXT_QUESTION = 'muzology/quiz_context/NEXT_QUESTION';
export const MARK_QUESTION_RESULTS = 'muzology/quiz_context/MARK_QUESTION_RESULTS';

export const ADD_QUIZ_RESPONSE = 'muzology/quiz_context/ADD_QUIZ_RESPONSE';

export const ENABLE_ANSWER_BUTTONS = 'muzology/quiz_context/ENABLE_ANSWER_BUTTONS';
export const ENABLE_SUBMIT_BUTTON = 'muzology/quiz_context/ENABLE_SUBMIT_BUTTON';
export const SET_CONTEXT_STATE = 'muzology/quiz_context/SET_CONTEXT_STATE';

export const SETUP_QUIZ_PROGRESS = 'muzology/quiz_context/SETUP_QUIZ_PROGRESS';
