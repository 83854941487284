import React from 'react';
import PropTypes from 'prop-types';
import tw, { css, styled } from 'twin.macro';
import FilledRecords from 'static/records/FilledRecordsAssets';
import { Button } from 'components';

export const BorderGradient = css`
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    min-height: 460px;
    max-height: 460px;
    @media only screen and (max-width: 500px) {
        min-height: 332px;
    }
`;

export const BorderGradientParent = css`
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    min-height: 365px;
    max-height: 365px;
    @media only screen and (max-width: 500px) {
        min-height: 332px;
    }
`;

const DynamicText = styled.div(({ $variant }) => [
    tw` text-3xl lg:text-2xl xl:text-3xl text-white font-bold font-sans text-center mt-4 xsm:mt-12 sm:px-6 md:px-8 lg:px-16`,
    $variant && tw`text-sm md:text-base lg:text-lg mt-10 px-4 lg:px-8`
]);

const RecordPlaque = (props) => {
    const { level, currentLevel, currentScore, parent } = props;

    // determine the filled record to show from the config
    let FilledRecordWithLevelAndScore;
    let ScoreText = currentScore;
    if (currentLevel === level) {
        FilledRecordWithLevelAndScore = FilledRecords[level][currentScore];
        if (props.attempts === null) {
            ScoreText = '';
        }
    } else if (currentLevel < level) {
        ScoreText = (
            <svg
                viewBox='0 0 121 149' width='121' height='149' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M120.834 74.5c0-8.135-6.765-14.75-15.084-14.75h-7.541V37.625C98.209 17.292 81.293.75 60.5.75c-20.792 0-37.708 16.542-37.708 36.875V59.75H15.25C6.932 59.75.167 66.365.167 74.5v59c0 8.135 6.765 14.75 15.083 14.75h90.5c8.319 0 15.084-6.615 15.084-14.75v-59zM37.875 37.625C37.875 25.427 48.026 15.5 60.5 15.5s22.625 9.927 22.625 22.125V59.75h-45.25V37.625z'
                    fill='#515151'
                />
            </svg>
        );
        FilledRecordWithLevelAndScore = FilledRecords[level][100];
    } else if (currentLevel > level) {
        ScoreText = '100';
        FilledRecordWithLevelAndScore = FilledRecords[level][100];
    }

    let handleQuizButton = () => props.handleTakeChallenge();

    // change the plurality of the button based on multiple attempts
    // or no attempts at all
    let buttonLabel = 'Locked';
    if (currentLevel === level) {
        buttonLabel = props.attempts ? 'Continue' : 'Start';
    } else if (currentLevel > level) {
        buttonLabel = 'Review';
        handleQuizButton = () => props.handleTakeChallenge(level);
    }

    // check if the current level is the same as the level
    // attempts could be 0 if they havent started the quiz which would be null in the data
    // change this to 0 so the copy will say 0 Attempts
    let attemptCopy = props.attempts === 1 ? 'Attempt' : 'Attempts';
    let attempts = props.attempts;
    if (currentLevel === level) {
        if (props.attempts === null) {
            attempts = 0;
        }
    }

    // create copy for different levels if the challenge is locked
    let lockedLevelCopy;
    if (level > currentLevel) {
        if (level === 1 && props.parent) {
            lockedLevelCopy = 'Complete the Warm Up to unlock';
        }
        if (level === 2) {
            lockedLevelCopy = 'Complete the Silver challenge to unlock';
        }
        if (level === 3) {
            lockedLevelCopy = 'Complete the Gold challenge to unlock';
        }
    }
    const noEvent = (e) => {
        e.preventDefault();
    };

    return (
        <div
            tw='bg-EarieBlack flex-col justify-center items-center relative'
            css={[parent ? BorderGradientParent : BorderGradient]}
            data-testid={`record-plaque-${level}`}
        >
            <div tw='relative flex justify-center pt-6 px-14 md:px-8 lg:px-8 xl:px-14 items-center'>
                <div tw='absolute text-4xl lg:fontSize[46px] text-white font-sans font-black'>{ScoreText}</div>
                <div
                    tw=' max-width[220px] max-height[220px] md:max-width[160px] md:max-height[160px] lg:max-width[180px] lg:max-height[180px] xl:max-width[220px] xl:max-height[220px]'>
                    <img src={FilledRecordWithLevelAndScore} tw='w-full h-full' alt='Score' />
                </div>
            </div>

            <DynamicText $variant={level > currentLevel}>
                {currentLevel >= level && attempts} {currentLevel >= level ? attemptCopy : lockedLevelCopy}
            </DynamicText>

            {!parent && (
                <>
                    {currentLevel >= level ? (
                        <div tw=' pt-4 xsm:pt-9 pb-6 flex justify-center items-center h-24'>
                            <Button
                                onClick={handleQuizButton}
                                variant={buttonLabel}
                                size='large'
                                data-testid={`record-plaque-button-${level}`}
                            >
                                {buttonLabel}
                            </Button>
                        </div>
                    ) : (
                        <div tw=' py-6 flex justify-center items-center h-24'>
                            <Button
                                onClick={noEvent}
                                disabled={true}
                                variant={buttonLabel}
                                size='large'
                                data-testid={`record-plaque-button-${level}`}
                            >
                                {buttonLabel}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

RecordPlaque.propTypes = {
    handleTakeChallenge: PropTypes.func,
    attempts: PropTypes.number,
    currentScore: PropTypes.number,
    level: PropTypes.number,
    currentLevel: PropTypes.number
};

export default RecordPlaque;
