import React from 'react';

const SvgLikeIconUnselected = (props) => (
    <svg
        viewBox='0 0 27.756 24.514'
        width='1em'
        height='1em'
        {...props}>
        <g
            data-name='Like Button'
            transform='translate(-2.75 -2.748)'
            fill='none'
            stroke='currentColor'
            strokeMiterlimit={10}
            strokeWidth={1.5}>
            <path
                data-name='Path 685'
                d='M12.803 13.86s4.835-2.594 4.554-9.947c0 0 2.394-2.037 4.2 2.943a14.239 14.239 0 01-.441 4.625h5.535s4.548.265 2.576 4.284a2.515 2.515 0 01-.493 3.071 3.478 3.478 0 01-1.08 3.472s.05 2.309-1.738 2.955h-10.8a3.791 3.791 0 01-2.315-.944z'
            />
            <rect
                data-name='Rectangle 3845'
                width={9.303}
                height={15.369}
                rx={2}
                transform='translate(3.5 11.143)' />
        </g>
    </svg>
);

export default SvgLikeIconUnselected;
