// aspect ratios
export const AspectRatio16x9 = '16x9';
export const AspectRatio4x3 = '4x3';

// player types
export const VideoPlayerDummy = 'dummy';
export const VideoPlayerVideoJS = 'videojs';
export const VideoPlayerBitmovin = 'bitmovin';

// mux key
export const MUX_KEY = '09d67efcbef75fc3431efd78c';

