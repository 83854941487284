import * as React from 'react';

function SvgClassIcon22(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#0BE921' />
            <path
                d='M11.477 20.916v3.382c0 .879.478 1.697 1.243 2.118l5.979 3.286a2.36 2.36 0 002.296 0l5.979-3.286a2.423 2.423 0 001.243-2.118v-3.382l-7.222 3.972a2.36 2.36 0 01-2.296 0l-7.222-3.972zm7.222-11.627l-10.08 5.537c-.825.457-.825 1.66 0 2.118l10.08 5.536a2.36 2.36 0 002.296 0l9.613-5.283v7.113c0 .662.539 1.204 1.196 1.204.658 0 1.196-.542 1.196-1.204v-7.715c0-.446-.24-.843-.622-1.06L20.995 9.29a2.426 2.426 0 00-2.296 0z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon22;
