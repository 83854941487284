import React from 'react';
import { useDevTool } from '../lib/DevTool/DevTool';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const INITIAL_STATE = null;

function activityReducer(state, action) {
    switch (action.type) {
        case 'add activity': {
            if (state === null)
                return 1;
            return state + (action.payload ?? 1);
        }
        case 'remove activity': {
            if (state === null)
                return 0;
            return state - (action.payload ?? 1);
        }
        default:
            return state;
    }
}

export const LoadingContext = ({ children }) => {
    const [store, dispatch] = React.useReducer(activityReducer, INITIAL_STATE, undefined);
    // console.log('render activityState');
    // useDevTool('Activity', { activity: store });
    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={store}>
                {children}
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );

};

export function useActivityCount() {
    return React.useContext(StoreContext);
}

export function useActivity(initialCount = 1) {
    const dispatch = React.useContext(DispatchContext);

    const addActivity = React.useCallback((count = 1) => {
        dispatch({ type: 'add activity', payload: count });
    }, [dispatch]);

    const endActivity = React.useCallback((count = 1) => {
        dispatch({ type: 'remove activity', payload: count });
    }, [dispatch]);

    // set the initial count
    React.useEffect(() => {
        if (initialCount !== null) {
            addActivity(initialCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [endActivity, addActivity];
}

export default LoadingContext;
