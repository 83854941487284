import React from 'react';
import { styled } from 'twin.macro';

export const FormContainer = styled.div`
    display: flex;
    min-width: 0;
    flex-direction: column;
    justify-content: flex-start;
    font-family: Avenir, serif;
    position: relative;
    font-size: 1em;

    @media only screen and (max-width: 1200px) {
        min-width: inherit;
    }

    .input-sub-message {
        font-size: 1.125rem;
        margin-top: 0.687rem;
        text-transform: uppercase;
        font-family: Avenir, sans-serif;
        font-weight: 900;
    }

    .form-copy {
        font-size: 1rem;
        /* line-height: 0.8em; */
        color: #fff;
        margin: 5px 0;
        font-weight: 900;
        font-family: Avenir, serif;
    }

    .input {
        font-size: 18px; //font-size: 0.75em; // 18px
        height: 38px; //height: 2.11em;
        padding: 0 24px; //padding: 0 1.333em;
        margin: 5px 0;
        border: none;
        font-family: Avenir, serif;
        font-weight: bold;
        color: #4a4a4a;
        border: 1px solid #7b7b7b;
        border-radius: 25px;
    }

    .input-locked {
        opacity: inherit;
    }

    .form-lock {
        max-width: 25px;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const StudentSetupContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: Avenir, serif;
    color: #ffffff;
    align-items: flex-start;
    padding: 45px 170px 0 70px;
    width: 100%;

    @media only screen and (max-width: 1100px) {
        padding: 40px 70px;
    }
    //     @media only screen and (max-width: 900px) {
    //         padding: 0px 20px;
    //     }
    //     @media only screen and (max-width: 700px) {
    //         padding: 0 20px;
    //         align-items: flex-start;
    //     }
    @media only screen and (max-width: 450px) {
        padding: 2em 25px;
        align-items: flex-start;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //margin-top: 60px;

        .title {
            font-size: 2em;
            font-weight: 900;
            line-height: normal;
            align-items: center;
            //white-space: nowrap;
            margin-bottom: 30px;
            @media only screen and (max-width: 450px) {
                font-size: 22px;
            }
        }

        .sub-header {
            font-size: 18px;
            line-height: 22px;
            margin-top: ${(props) => props.subHeaderMarginTop};
        }
    }

    .name-yourself-text {
        margin-top: 26px;
        font-size: 1em;
        font-weight: 900;
        align-items: center;
        white-space: nowrap;
        text-transform: uppercase;
        @media only screen and (max-width: 450px) {
            font-size: 16px;
            white-space: initial;
        }
    }

    .name-yourself-subtext {
        font-size: 18px;
        margin-top: 19px;
        @media only screen and (max-width: 450px) {
            font-size: 16px;
        }
    }

    .name-yourself-form {
        width: 100%;
        margin-top: 19px;
    }

    .select-grade-title {
        font-family: Avenir, serif;
        font-weight: 900;
        font-size: 18px;
        margin-top: 50px;
    }

    .checkbox-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        //margin: 24px 0 0;

        .checkbox-item {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 700px) {
                width: 100%;
            }

            .checkbox-title {
                font-weight: 500;
                font-size: 18px;
                color: #000;
                margin-left: 15px;
                line-height: normal;
            }
        }

        .container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 35px;
            cursor: pointer;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: 30px;
            margin: 24px 11px 0px 11px;
            width: 15%;
            white-space: nowrap;

            &:nth-child(1) {
                margin-left: 0px;
            }

            &:nth-child(6) {
                margin-left: 0px;
            }

            &:nth-child(4) {
                margin-right: 20px;
            }

            &:nth-child(9) {
                padding-left: 28px;
                margin-right: 20px;
                white-space: normal;
            }

            @media only screen and (max-width: 1000px) {
                &:nth-child(9) {
                    white-space: nowrap;
                }
            }

            @media only screen and (max-width: 620px) {
                width: 50%;
                margin: 12px 0;

                &:nth-child(4) {
                    margin-right: 0px;
                }

                &:nth-child(9) {
                    padding-left: 35px;
                    margin-right: 0px;
                }
            }
            @media only screen and (max-width: 350px) {
                width: 100%;
                margin: 12px 0;
            }
        }

        /* Hide the browser's default checkbox */

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #fff;
            border: 1px solid #7e7e7e;
        }

        /* On mouse-over, add a grey background color */

        .container:hover input ~ .checkmark {
            background-color: #e637a8;
        }

        /* When the checkbox is checked, add a blue background */

        .container input:checked ~ .checkmark {
            background-color: #e637a8;
        }

        /* Create the checkmark/indicator (hidden when not checked) */

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */

        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */

        .container .checkmark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .student-setup-button-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 44px;
    }

    .parent-student-forms-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 42px;
    }

    .parent-student-button-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-bottom: 44px;

        .button-sub-text {
            margin-top: 30px;
            line-height: 26px;
        }
    }

    .add-info-button-container {
        padding: 15px 0px 40px;
        display: flex;
        justify-content: space-between;

        /* @media only screen and (max-width: 1200px) {
            flex-direction: column;
        } */
    }
`;

export const StyledButton = styled.button`
    background: ${(props) => (props.background ? props.background : 'linear-gradient(90deg, #e63796 10%, #671faf 100%)')};
    color: #fff;
    font-weight: 900;
    min-height: 62px;
    min-width: 175px;
    padding: 13px 35px;
    font-size: 20px;
    font-family: Avenir, serif;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    z-index: 2;
    position: relative;

    &:hover {
        border: 1px solid #fff;
        background: ${(props) => (props.background ? props.background : '#e637a8')};
        cursor: pointer;
    }

    &:active {
        color: ${(props) => (props.background ? '#fff' : '#9c2a6e')};
        border: 1px solid ${(props) => (props.background ? '#fff' : '#9c2a6e')};
        background: ${(props) => (props.background ? props.background : '#9c2a6e')};
    }

    border: none;
    box-shadow: none;
`;

const ErrorContainer = styled.div`
    margin-top: 10px;

    .error-tag {
        font-family: Avenir, serif;
        font-weight: 900;
        font-style: italic;
        font-size: 18px;
        color: #fa2a2a;
        margin-bottom: 10px;
    }
`;
export const ErrorMessage = (name, formErrors) => {
    const error = formErrors[name];
    if (error) {
        return (
            <ErrorContainer>
                <p className='error-tag'>{error}</p>
            </ErrorContainer>
        );
    }
    return null;
};

export const showError = (error, text = null) => {
    if (error) {
        let message = text;
        if (!message) {
            if (error.type === 'required')
                message = 'This field is required';
            else
                message = error.message;
        }
        return (
            <ErrorContainer className='error-container'>
                <p className='error-tag'>{`* ${message}`}</p>
            </ErrorContainer>
        );
    }
    return null;
};

const S = {
    Checkbox: styled.input`
        transform: scale(1.5);
    `
};

export const Checkbox = (props) => {
    return <S.Checkbox type='checkbox' {...props} />;
};
