import React, { useMemo } from 'react';
import { NullContainer, StatsBanner, Table, VideoStatsMobileCard } from 'components';
import tw, { css } from 'twin.macro';
import RecordImageSilver from 'static/images/records/silver.png';
import RecordImageGold from 'static/images/records/gold.png';
import RecordImagePlatinum from 'static/images/records/platinum.png';
import FilledRecords from 'static/records/FilledRecordsAssets';
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const MyVideoStats = ({ tableData, stats, activity, parent }) => {
    // get stats length to check against if all filtered stats with an empty object are returned
    const statsLength = stats?.length;
    let statsEmptyLength = stats?.filter((stat) => Object.getOwnPropertyNames(stat).length === 0).length;
    let emptyStats = statsLength === statsEmptyLength;
    let fullTable = useMedia('(min-width: 1280px)');
    let fourRows = useMedia('(min-width: 900px)');
    const navigate = useNavigate();

    let preSortTableData = [...tableData].sort((a, b) =>
        moment(b.stats.quiz_levels[b.stats.current_level - 1]?.date).diff(moment(a.stats.quiz_levels[a.stats.current_level - 1]?.date))
    );

    const columns = React.useMemo(
        () => [
            {
                id: 'title',
                Header: 'Video',
                accessor: (d) => d.title,
                width: 300,
                Cell: ({ row }) => {
                    return (
                        <div tw='cursor-pointer flex items-center' onClick={() => navigate(`/video/${row.original.id}`)}>
                            <img tw='h-16 w-24 object-cover' src={row.original.url_thumbnail} alt='thumbnail' />
                            <h1 tw='font-sans text-base md:text-lg ml-6'>{row.original.title}</h1>
                        </div>
                    );
                }
            },
            {
                id: 'view_count',
                Header: 'Views',
                accessor: (d) => d?.stats?.view_count ?? 0
                // Filter: SelectColumnFilter,
            },

            {
                id: 'warm_up',
                Header: 'Warm Up Score',
                accessor: (d) => {
                    if (d?.stats?.quiz_levels) return d.stats.quiz_levels[0]?.score ?? 0;
                    return 0;
                },
                // Cell: ({ row }) => {
                //     return `${row?.original?.stats?.quiz_levels[0]?.score ?? 0}%`;
                // }
                Cell: ({ value }) => {
                    return `${value}%`;
                    // return `${row?.original?.stats?.quiz_levels[0]?.score ?? 0}%`;
                }
            },
            {
                id: 'attempts',
                Header: 'Attempts',
                // adds up silver gold and plat attempts to allow sorting to work properly
                // the ?? will default the value to be zero if the quiz has not been taken
                accessor: (d) => {
                    if (d?.stats?.quiz_levels) {
                        return (
                            (d?.stats?.quiz_levels[1]?.attempts ?? 0) + (d?.stats?.quiz_levels[2]?.attempts ?? 0) + (d?.stats?.quiz_levels[3]?.attempts ?? 0)
                        );
                    }
                    return 0;
                },
                Cell: ({ row }) => {
                    const levels = row?.original?.stats?.quiz_levels;
                    return (
                        <div tw='flex space-x-2'>
                            <div tw='flex space-x-2 items-center'>
                                <span>{levels ? levels[1]?.attempts ?? 0 : 0}</span>
                                <img tw='w-5 h-5' src={RecordImageSilver} alt='img' />
                            </div>
                            <div tw='flex space-x-2 items-center'>
                                <span>{levels ? levels[2]?.attempts ?? 0 : 0}</span>
                                <img tw='w-5 h-5' src={RecordImageGold} alt='img' />
                            </div>
                            <div tw='flex space-x-2 items-center'>
                                <span>{levels ? levels[3]?.attempts ?? 0 : 0}</span>
                                <img tw='w-5 h-5' src={RecordImagePlatinum} alt='img' />
                            </div>
                        </div>
                    );
                }
            },
            {
                id: 'records',
                Header: 'Records Earned',
                // accessor: (d) => {
                //     if (d?.stats?.quiz_levels)
                //         // return (d?.stats?.quiz_levels[1]?.score ?? 0) + (d?.stats?.quiz_levels[2]?.score ?? 0) + (d?.stats?.quiz_levels[3]?.score ?? 0);
                //         return (d?.stats?.current_level ?? 0) + (d?.stats?.current_level ?? 0) + (d?.stats?.current_level ?? 0);

                //     return 0;
                // },
                canSort: false,
                Cell: ({ row }) => {
                    const levels = row?.original?.stats?.quiz_levels;
                    let stats = row?.original?.stats ?? null;
                    // check if stats object is empty this is the same as null
                    let emptyStats = stats && Object.keys(stats).length === 0 && stats.constructor === Object;
                    let silverScore = levels ? levels[1]?.score ?? 0 : 0;
                    let SilverRecord =
                        !stats || emptyStats ? RecordImageSilver : row?.original?.stats?.current_level < 1 ? RecordImageSilver : FilledRecords[1][silverScore];
                    let goldScore = levels ? levels[2]?.score ?? 0 : 0;
                    let GoldRecord =
                        !stats || emptyStats ? RecordImageGold : row?.original?.stats?.current_level < 2 ? RecordImageGold : FilledRecords[2][goldScore];
                    let platScore = levels ? levels[3]?.score ?? 0 : 0;
                    let PlatRecord =
                        !stats || emptyStats
                            ? RecordImagePlatinum
                            : row?.original?.stats?.current_level < 3
                                ? RecordImagePlatinum
                                : FilledRecords[3][platScore];

                    return (
                        <div tw='flex space-x-2'>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-14' src={SilverRecord} alt='img' />
                                <span tw='text-xs font-black absolute text-white'>{levels ? levels[1]?.score ?? '' : ''}</span>
                                {(!stats || emptyStats) && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                                {row?.original?.stats?.current_level < 1 && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-14' src={GoldRecord} alt='img' />
                                <span tw='text-xs font-black absolute text-white'>{levels ? levels[2]?.score ?? '' : ''}</span>

                                {(!stats || emptyStats) && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                                {row?.original?.stats?.current_level < 2 && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-14' src={PlatRecord} alt='img' />
                                <span tw='text-xs font-black absolute text-white'>{levels ? levels[3]?.score ?? '' : ''}</span>

                                {(!stats || emptyStats) && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                                {row?.original?.stats?.current_level < 3 && (
                                    <div tw='absolute bg-gray-600 bg-opacity-50 w-full h-full rounded-full flex justify-center items-center'>
                                        <svg
                                            width='25' height='30' viewBox='0 0 25 30' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                d='M24.134 15c0-1.562-1.307-2.833-2.914-2.833h-1.456v-4.25c0-3.906-3.268-7.084-7.284-7.084S5.197 4.011 5.197 7.917v4.25H3.74C2.133 12.167.827 13.437.827 15v11.334c0 1.562 1.306 2.833 2.913 2.833h17.48c1.607 0 2.914-1.27 2.914-2.834V15zM8.11 7.917c0-2.343 1.96-4.25 4.37-4.25s4.37 1.907 4.37 4.25v4.25H8.11v-4.25z'
                                                fill='#2A295E'
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }
            }
        ],
        [navigate]
    );
    console.log('activity', activity);
    const myActivity = useMemo(() => {
        if(!activity) return null;
        return Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity;
    }, [activity]);

    return (
        <div>
            <div tw='mt-8 sm:mt-11'>
                <StatsBanner
                    stats={stats ?? []}
                    activity={myActivity}
                />
                <div tw='mt-8 hidden md:block'>
                    {tableData && !emptyStats && (
                        <Table
                            data={preSortTableData}
                            columns={
                                !fourRows
                                    ? columns.filter((c) => c.id !== 'warm_up' && c.id !== 'attempts')
                                    : !fullTable
                                        ? columns.filter((c) => c.id !== 'attempts')
                                        : 
                                        columns
                            }
                            headerStyle={tw`text-center text-sm text-white mb-3 first:text-left first:pl-6`}
                            rowStyle={tw`font-sans mb-4 py-2 bg-gradient-to-r to-PholxPink from-SkyBlueCrayola text-white text-lg font-extrabold rounded-lg text-center`}
                            cellStyle={tw`px-6 flex items-center justify-center first:justify-start first:text-left first:border-r-2 border-GainsboroGrey`}
                            isSortable
                            getHeaderProps={(row) => ({
                                style:
                                    row.index === 4
                                        ? {
                                            position: 'sticky'
                                        }
                                        : {}
                            })}
                        />
                    )}
                    {emptyStats &&
                        (parent ? (
                            <NullContainer tw='text-white text-base sm:text-2xl font-medium'>
                                <div tw='flex flex-col justify-around [height:260px]'>
                                    <div>As your child watches Muzology videos and takes the associated math Challenges, you’ll see their progress here.</div>
                                    {/*
                                <div>
                                    Want to help guide your child’s learning?{' '}
                                    <span tw='underline cursor-pointer' onClick={() => navigate('/playlists')}>
                                        Click here
                                    </span>{' '}
                                    to create a playlist of Muzology videos and share them with your child.
                                </div>
                                */}
                                </div>
                            </NullContainer>
                        ) : (
                            <NullContainer tw='text-white text-base sm:text-2xl font-medium'>
                                Watch your first Muzology &nbsp;
                                <span tw='underline cursor-pointer' onClick={() => navigate('/videos')}>
                                    video
                                </span>
                                , take the math Challenges and come here to see your stats!
                            </NullContainer>
                        ))}
                </div>
            </div>
            <div tw='mt-8 block md:hidden'>
                <div tw='flex flex-col'>
                    {tableData.map((stats) => (
                        <VideoStatsMobileCard key={stats.id} stats={stats} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyVideoStats;
