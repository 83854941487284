import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { Button, Modal } from 'components';
import { useClassActions, useClassStudents } from 'state/Teacher/ClassState';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useOnboardStageFinished } from 'state/OnboardState';
import { ONBOARD_STAGE_SETUP_CHILD } from 'app/AccountOnboard/OnboardStages';
import { LoadingOverlay } from 'lib/components/loading';
import { useDevTool } from '../../lib/DevTool/DevTool';
import { usePlaylistActions } from '../../state/Teacher/TeacherProvider';

const BorderGradient = css`
    color: #313149;
    display: inline-block;
    position: relative;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 3px;
        border-radius: 5px;
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }

    &:hover {
        background: #e7e7ff;
    }

    &:active {
        background: #c7c7ff;
    }

    .selected {
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        color: white;
    }
`;

const TextGradient = css`
    background: -webkit-linear-gradient(#e637a8, #e68037);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a5a5a5;
        border-radius: 20px;
        /* max-height: 60px; */
        border-right: 10px white solid;
        background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
        /* margin-top: 30px;
        margin-bottom: 30px; */
    }
`;

const categories = [
    { name: 'Long Division' },
    { name: 'Fractions' },
    { name: 'Decimals' },
    { name: 'Positive and Negative Numbers' },
    { name: 'Absolute Value' },
    { name: 'Graphing Order Pairs' },
    { name: 'Graphing Inequalities and Lines' },
    { name: 'Square Roots' },
    { name: 'Pythagorean Theorem' },
    { name: 'Rational and Irrational Numbers' },
    { name: 'Ratios' },
    { name: 'Solving Equations' },
    { name: 'Solving Equations - Advanced' },
    { name: 'Exponent Rules' },
    { name: 'Scientific Notation' },
    { name: 'Functions' }
];
// api/assignment_topics/ - the new AssignmentTopic system
// api/topic_definitions/ - the old PlaylistDefinition system

function useAssignmentTopics() {
    const [topics, setTopics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        apiClient
            .get('/api/assignment_topics/')
            .then((response) => {
                const { data } = response;
                setTopics(data);
                setLoading(false);
            })
            .catch((error) => {
                handleAPIError(error);
                setLoading(false);
            });
    }, []);

    return [topics, loading];
}

function TopicListModal(props) {
    const { topics, parentTopicId, onSelectTopic, selected } = props;

    const createSubCards = () => {
        // find topics with a parent ID of any selected topics
        let subTopics = [];
        const parentTopic = topics?.find((t) => t.id === parentTopicId);
        // find all children for this topic
        const children = topics?.filter((t) => t.parent === parentTopicId);
        // selected.forEach((topicId) => {
        //     const sub = topics?.filter((t) => t.parent === topicId);
        //     if (sub.length > 0) parentTopics.push(topicId);
        // });
        return children?.map((item) => {
            return (
                <div
                    key={item.name}
                    tw=' py-5 px-3 cursor-pointer'
                    css={[
                        BorderGradient,
                        selected.includes(item.id) &&
                        tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] text-white rounded hover:bg-MediumPurple active:bg-SlateBlue`
                    ]}
                    onClick={() => onSelectTopic(item.id)}
                    data-testid={item.name}
                >
                    <div tw='text-base select-none'> {item.name}</div>
                </div>
            );
        });
    };
    return (
        <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
            <div tw='bg-white' data-testid='secondary-topics-modal-container'>
                <div tw='px-12 py-5'>
                    <div tw='text-white text-4xl font-extrabold pt-10 text-center' css={[TextGradient]}>
                        More specific topic
                    </div>
                    <div tw=' overflow-y-scroll grid grid-cols-1 gap-4 mt-6 maxHeight[494px]' css={[!props.onBoarding && tw`px-20`, ScrollBarStyle]}>
                        {createSubCards()}
                    </div>
                </div>
                <div tw=' height[70px] boxShadow[0px -2px 10px rgba(0, 0, 0, 0.1)]'>
                    <div tw='flex justify-end items-center py-4'>
                        <div tw='flex'>
                            <div tw='ml-1.5 mr-9'>
                                <Button variant='hotPink' onClick={props.onClick} data-testid='secondary-topics-modal-button'>
                                    DONE
                                    {/*{studentIndex === studentsLength ? 'DONE' : 'NEXT CHILD'}*/}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

TopicListModal.propTypes = {
    showChildModal: PropTypes.func,
    isOpen: PropTypes.bool,
    parentTopicId: PropTypes.string,
    onBoarding: PropTypes.any,
    onClick: PropTypes.func
};
const ParentLearningCategories = ({ onBoarding, setParentLearningModal, onboardingStudentId, studentCount, currentStudent, studentName, setStep, resetSetup }) => {
    console.log('🚀 ~ file: ParentLearningCategories.js:183 ~ ParentLearningCategories ~ studentCount:', studentCount);
    const { students } = useClassStudents(null);
    const { updateStudent } = useClassActions();
    const stageFinished = useOnboardStageFinished();
    let studentsLength = students.length - 1;
    const [studentIndex, setStudentIndex] = useState(0);
    const [selected, setSelected] = useState([]);
    // const [categories, setCategories] = useState([]);
    const [secondaryStep, setSecondaryStep] = useState(false);
    const [showChildModal, setShowChildModal] = useState(false);
    // const [loading, setLoading] = useState(false);
    const [topics, loadingTopics] = useAssignmentTopics();

    // build the topic tree
    const rootTopics = useMemo(() => {
        const root = topics?.filter((t) => t.parent === null) ?? [];
        return root.map((t) => {
            return {
                ...t,
                children: topics?.filter((c) => c.parent === t.id) ?? []
            };
        });
    }, [topics]);

    // console.log('🚀 ~ file: ParentLearningCategories.js ~ line 91 ~ ParentLearningCategories ~ selected', selected);
    // console.log('[topics]', topics);
    // console.log('[topics] root:', rootTopics);
    useDevTool('Topics', { topics, rootTopics });

    // store the selected topics when the student changes
    useEffect(() => {
        // when the student index changes, select their topics
        if (studentIndex >= 0 && studentIndex < students?.length) {
            const student = students[studentIndex];
            setSelected(student.assigned_topics ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students, studentIndex]);

    // toggle a category on or off
    const handleAddingCategories = (topicId) => {
        // if this has children, we need to show the secondary step
        const children = topics.filter((c) => c.parent === topicId);
        if (children.length > 0) {
            // show the child popup
            setShowChildModal(topicId);
        } else {
            // toggle the selection state
            setSelected((values) => {
                if (values.includes(topicId)) return values.filter((s) => s !== topicId);
                else return [...values, topicId];
            });
        }
    };

    // after clicking "previous"
    const handlePrevChild = () => {
        handleCategorySave().then(() => {
            if (studentIndex === 0) return;
            setStudentIndex(studentIndex - 1);
        });
    };

    // after clicking "next"
    const handleNextChild = () => {
        // save this topic
        handleCategorySave().then(() => {
            // determine if this needs to close
            if (studentIndex === studentsLength && !onBoarding) setParentLearningModal(false);
            if (studentIndex === studentsLength && onBoarding) stageFinished(ONBOARD_STAGE_SETUP_CHILD);
            else {
                // move to the next student, and select their topics
                setStudentIndex(studentIndex + 1);
                // setSelected(students[studentIndex + 1].topics);
            }
        });
    };

    const { fetchAssignments } = usePlaylistActions();
    const [loading, setLoading] = useState(false);

    const handleCategorySave = () => {
        //  handle saving the learning categories for each child
        const data = { topics: selected };
        const payload = { user_id: students[studentIndex]?.user?.id, ...data };
        setLoading(true);
        return apiClient
            .post('/api/studentprofile/', payload)
            .then((response) => {
                const { data } = response;
                if (data?.user?.id) {
                    updateStudent(data);
                }
                fetchAssignments().then(() => {
                    setLoading(false);
                });
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                handleAPIError(err);
            });
    };

    const handleOnboardSave = () => {
        onBoardHandleCategorySave().then(() => {
            if (studentCount > 1) {
                setStudentIndex(currentStudent + 1);
                setStep(1);
            } else {
                stageFinished(ONBOARD_STAGE_SETUP_CHILD)
                    .then(() => resetSetup());
            }
        });
    };

    const onBoardHandleCategorySave = () => {
        const data = { topics: selected };
        const payload = { user_id: onboardingStudentId, ...data };
        setLoading(true);
        return apiClient
            .post('/api/studentprofile/', payload)
            .then((response) => {
                const { data } = response;
                if (data?.user?.id) {
                    updateStudent(data);
                }
                fetchAssignments().then(() => {
                    setLoading(false);
                });
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                handleAPIError(err);
            });
    };

    const createCards = (user = null) => {
        // console.log('cards:', user);
        return rootTopics?.map((topic, index) => {
            // determine if any children are selected
            const hasSelectedChildren = topic.children?.find((c) => selected?.find((s) => s === c.id)) !== undefined;
            const isSelected = hasSelectedChildren || selected?.find((s) => s === topic.id) === topic.id;
            return (
                <div
                    key={index}
                    tw=' py-5 px-3 cursor-pointer select-none h-[90px] sm:h-full'
                    data-testid={topic.name}
                    css={[
                        BorderGradient,
                        isSelected &&
                        tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] text-white rounded hover:bg-MediumPurple active:bg-SlateBlue`
                    ]}
                    onClick={() => handleAddingCategories(topic.id)}
                >
                    <div tw='text-sm sm:text-base select-none'>{topic.name}</div>
                </div>
            );
        });
    };

    return (
        <div id='student-topic-selection-form' tw='relative rounded bg-white'>
            <LoadingOverlay show={loading || loadingTopics} fixed={true} />

            {/* Topics list */}
            <>
                <div tw='text-EarieBlack text-4xl font-extrabold pt-8 text-center'>Let's Guide {onBoarding ? studentName : students[studentIndex]?.user?.first_name}</div>
                {onBoarding ? <div>
                    <div tw='text-base text-EarieBlack pt-6 text-center px-0 sm:px-12'>
                            Select a topic, and we’ll create a custom math playlist just for {studentName}.
                    </div>
                    <div tw='text-base text-EarieBlack pt-2 pb-2 text-center px-0 sm:px-12'>
                            (This step is not required; you or your child can pick additional topics anytime!)
                    </div>
                </div> :
                    <p tw='text-base text-EarieBlack pt-8 text-center px-12'>
                        Muzology can automatically generate learning playlists for your child. Just select a few topics your child would like to learn, and presto!
                    </p>}
                <div
                    tw=' overflow-y-scroll grid grid-cols-2 gap-4 mt-6 maxHeight[494px]'
                    css={[!onBoarding && tw`px-20`, ScrollBarStyle]}
                    data-testid='topics-modal-cards'
                >
                    {createCards()}
                </div>
            </>

            {/*
                Buttons:
                    onboarding: skip, [next child / done]
                    not onboarding: [previous child], [next child / done]
             */}
            {onBoarding ? (
                <div tw='flex justify-end items-center height[70px] mt-3.5'>
                    <div tw='flex'>
                        <div tw='mr-1.5'>
                            <Button variant='RosterTable' onClick={() => stageFinished(ONBOARD_STAGE_SETUP_CHILD)} data-testid='topics-onboard-button-skip'>
                                SKIP
                            </Button>
                        </div>
                        <div tw='ml-1.5'>
                            <Button variant='hotPink' onClick={handleOnboardSave} data-testid='topics-onboard-button'>
                                Done
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div tw='flex justify-end items-center height[70px] boxShadow[0px -2px 10px rgba(0, 0, 0, 0.1)]'>
                    <div tw='flex'>
                        {studentIndex !== 0 && (
                            <div tw='mr-1.5'>
                                <Button variant='RosterTable' onClick={handlePrevChild}>
                                    PREVIOUS
                                </Button>
                            </div>
                        )}

                        <div tw='ml-1.5 mr-9'>
                            <Button variant='hotPink' onClick={handleNextChild} data-testid='topics-modal-button'>
                                {studentIndex === studentsLength ? 'DONE' : 'NEXT CHILD'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {/* Child topics modal dialog */}
            <TopicListModal
                isOpen={showChildModal !== false}
                setIsOpen={setShowChildModal}
                parentTopicId={showChildModal}
                onBoarding={onBoarding}
                topics={topics}
                selected={selected}
                onSelectTopic={handleAddingCategories}
                onClick={() => setShowChildModal(false)}
            />
        </div>
    );
};

ParentLearningCategories.propTypes = {
    anyProp: PropTypes.any
};

export default ParentLearningCategories;
