import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import { useVideos } from 'state/CatalogState';
import { useNavigate } from 'react-router-dom';

const PlayButton = styled.button`
    z-index: 8;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: linear-gradient(135deg, #e637a8 2.69%, #d940ab 2.7%, #de24b8 21.9%, #a563b9 39.6%, #1ac1dd 75%);
    border: 4px solid #ffffff;
    position: absolute;
    left: -2%;
    right: 0;
    top: 60%;
    bottom: 0;
    transform: translate(0, -50%) scale(1.25);
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    &:hover {
        background: linear-gradient(135deg, rgba(230, 55, 168, 0.67) 2.69%, rgba(26, 193, 221, 0.66) 75%);
    }

    &:active {
        background: linear-gradient(135deg, #e637a8 2.69%, #d940ab 2.7%, #de24b8 21.9%, #a563b9 39.6%, #1ac1dd 75%);
    }

    &:focus {
        box-shadow: 0 0 8px 2px #1cc8e1;
    }

    @media (max-width: 1280px) {
        transform: translate(0, -50%) scale(1.125);
    }
    @media (max-width: 1024px) {
        width: 60px;
        height: 60px;
        transform: translate(0, -50%) scale(1);
    }
    @media (max-width: 768px) {
        top: 65%;
        transform: translate(0, -50%) scale(.9);

    }

    @media (max-width: 600px) {
        width: 70px;
        height: 70px;
        top: 60%;
    }

    .triangle {
        line-height: 1;
        width: 18px;
        height: 18px;
        border-top-right-radius: 20%;
        background-color: #fff;
        text-align: left;
        display: inline-block;
        margin: 1rem;
        transition: background-color 0.45s ease;
        &:hover {
            background-color: #ffffff;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            width: 100%;
            height: 100%;
            border-top-right-radius: 20%;
        }

        &.right {
            transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
            transform-origin: 72% 82%;
        }

        &:before {
            transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
        }

        &:after {
            transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
        }
    }
`;

const NewVideoStudentHero = (props) => {
    const videos = useVideos();

    const navigate = useNavigate();
    const newVideo = videos.find((v) => v.id === 'f028e2e5-af95-4aba-99f8-971eb3129e75');

    return (
        <div tw='bg-LavenderWeb rounded-lg relative'>
            <div
                tw='text-center rounded-t-lg text-white text-lg lg:text-2xl font-extrabold py-2.5 sm:py-1 lg:py-2.5 mt-4 sm:mt-0 md:mt-1.5 lg:mt-3.5 xl:mt-7'
                style={{ background: 'linear-gradient(90deg, #4C3FC1 0%, rgba(31, 242, 255, 0.97) 100%)', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.14)' }}
            >
                New Video
            </div>
            <div tw='h-full w-full flex items-center justify-center'>
                <div tw='max-w-[350px] xl:max-w-[400px] px-5 lg:px-0 py-[1.99rem] md:py-[2.2rem] lg:py-5'>
                    <img tw='w-full maxWidth[280px] sm:max-w-full maxHeight[225px] p-1 rounded-[.65rem]' src={newVideo?.url_thumbnail} alt='' />
                </div>
            </div>
            <PlayButton onClick={() => navigate(`/video/${newVideo.id}`)}>
                <div className='triangle right'></div>
            </PlayButton>
        </div>
    );
};

NewVideoStudentHero.propTypes = {
    anyProp: PropTypes.any
};

export default NewVideoStudentHero;
