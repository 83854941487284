import React, { useMemo, useCallback } from 'react';
import {
    NullContainer,
    StatsBanner,
    Table,
    VideoStatsMobileCard,
    VideoStatsMobileCardDTC,
    DTCStatsBanner
} from 'components';
import BalanceText from 'react-balance-text';
import tw, { css, styled } from 'twin.macro';
import RecordImageSilver from 'static/images/records/DTC/silver.png';
import RecordImageGold from 'static/images/records/DTC/gold.png';
import RecordImagePlatinum from 'static/images/records/DTC/platinum.png';
import { useMedia } from 'react-use';
import { useAccount } from 'state/User/UserState';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import WhiteLogo from 'static/images/logo/logo-white-small.png';
import { useVideoStats } from 'state/Student/VideoStats';
import { useVideos } from 'state/CatalogState';
import { wrapTitleParens } from '../../../../../../lib/string';
import { useGuidance } from '../../../../../../state/Guidance/GuidanceProvider';

const ProgressBar = styled.div(({ score }) => [
    tw` bg-[#02AC6F] rounded-xl text-center h-[17px] `,
    score < 100 && tw`bg-[#46A6F8] rounded-none rounded-l-lg`
]);

const ScrollBarStyle = css`
    &::-webkit-scrollbar {
        width: 12px;
        background-color: #4D3095;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #6441B9;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 6px;
    }
`;


const MyVideoStats = ({ tableData, stats, activity, selectedStudent, parent, timesTableProduct }) => {

    // get stats length to check against if all filtered stats with an empty object are returned
    const statsLength = stats?.length;
    let statsEmptyLength = stats?.filter((stat) => Object.getOwnPropertyNames(stat).length === 0).length;
    let emptyStats = statsLength === statsEmptyLength;
    let conditionalCopy = !parent && statsLength > 0 && statsLength <= 3;
    const navigate = useNavigate();
    const { is_parent_account } = useAccount();
    const videoStats = useVideoStats();
    let videosWithVideoViews = Object.values(videoStats).filter(v => v.view_count > 0);
    if (parent) videosWithVideoViews = stats;
    let totalViews = 0;
    for (const video of videosWithVideoViews) {
        totalViews += video.view_count;
    }

    const videos = useVideos();

    let preSortTableData = [...tableData].sort((a, b) =>
        moment(b.stats.quiz_levels[b.stats.current_level - 1]?.date).diff(moment(a.stats.quiz_levels[a.stats.current_level - 1]?.date))
    );

    const { videos: recommendedVideos } = useGuidance();
    const recommendedVideosWithStats = recommendedVideos.slice(0, 3).map(video => {
        if (video.stats) {
            return video;
        } else {
            return {
                ...video,
                stats: {
                    view_count: 0,
                    quiz_levels: [],
                    current_level: 0
                }
            };
        }
    });

    const handleRecordSort = (a, b, id) => {

        // First, sort by current level
        if (a.original.stats.current_level !== b.original.stats.current_level) {
            return a.original.stats.current_level - b.original.stats.current_level;
        }

        if (a.original.stats.current_level === b.original.stats.current_level) {
            if (a.original.stats.current_score !== b.original.stats.current_score)
                return a.original.stats.current_score - b.original.stats.current_score;
        }

        const aMaxScore = a.original.stats.quiz_levels[a.original.current_level]?.score || 0;
        const bMaxScore = b.original.stats.quiz_levels[b.original.current_level]?.score || 0;

        // Sort by highest score first
        if (aMaxScore !== bMaxScore) {
            return bMaxScore - aMaxScore;
        }

        // Calculate total completions for each object
        const aTotalCompletions = Object.values(a.original.stats.quiz_levels).reduce((sum, level) => sum + level.completions, 0);
        const bTotalCompletions = Object.values(b.original.stats.quiz_levels).reduce((sum, level) => sum + level.completions, 0);

        if (a.original.stats.current_level === b.original.stats.current_level) {
            if (a.original.stats.current_score === b.original.stats.current_score)
                return aTotalCompletions - bTotalCompletions;
        }

        return bTotalCompletions - aTotalCompletions;

    };

    const columns = React.useMemo(
        () => [
            {
                id: 'title',
                Header: 'VIDEO',
                accessor: (d) => d.title,
                width: 250,
                Cell: ({ row }) => {
                    return (
                        <div
                            tw='cursor-pointer flex items-center'
                            onClick={() => navigate(`/video/${row.original.id}`)}>
                            <img tw='h-16 object-cover rounded-[4px]' src={row.original.url_thumbnail} alt='thumbnail' />
                            <h1
                                tw='text-sm font-normal md:text-base ml-6 font-Poppins hover:text-[#FEEA50] '
                                css={[
                                    emptyStats && tw`text-[#919597] hover:text-white`,
                                    row.original.styled_title && tw`whitespace-pre` // styled_title is pre-wrapped
                                ]}
                            >
                                {/*<VideoTitle video={row.original} />*/}
                                {row.original.styled_title ? row.original.styled_title : <BalanceText>{wrapTitleParens(row.original.title)}</BalanceText>}
                            </h1>
                        </div>
                    );
                }
            },
            {
                id: 'view_count',
                Header: 'VIEWS',
                width: 100,

                accessor: (d) => d?.stats?.view_count ?? 0,
                Cell: ({ value }) => {
                    return <div css={[emptyStats && tw`text-[#919597]`]}>{value}</div>;
                }
                // Filter: SelectColumnFilter,
            },

            {
                id: 'warm_up',
                Header: 'WARM UP SCORE',
                width: 100,
                accessor: (d) => {
                    if (d?.stats?.quiz_levels) return d.stats.quiz_levels[0]?.score ?? null;
                    return 0;
                },
                Cell: ({ row }) => {
                    return `${row?.original?.stats?.quiz_levels[0]?.score ?? 0}%`;
                }
            },
            {
                id: 'records',
                Header: 'RECORDS EARNED',
                accessor: (d) => d?.stats?.current_level,
                sortType: handleRecordSort,
                Cell: ({ row }) => {
                    let stats = row?.original?.stats ?? null;
                    const levels = stats?.quiz_levels;
                    // check if stats object is empty this is the same as null
                    let emptyStats = stats && Object.keys(stats).length === 0 && stats.constructor === Object;
                    // let silverScore = levels ? levels[1]?.score ?? 0 : 0;
                    // let SilverRecord = !stats || emptyStats ? RecordImageSilver : stats.current_level < 1 ? RecordImageSilver : RecordImageSilver;
                    // let goldScore = levels ? levels[2]?.score ?? 0 : 0;
                    // let GoldRecord = !stats || emptyStats ? RecordImageGold : stats.current_level < 2 ? RecordImageGold : RecordImageGold;
                    // let platScore = levels ? levels[3]?.score ?? 0 : 0;
                    // let PlatRecord = !stats || emptyStats ? RecordImagePlatinum : stats.current_level < 3 ? RecordImagePlatinum : RecordImagePlatinum;
                    const level1Attempts = (levels && levels[1]) ? levels[1].attempts : 0;
                    const level2Attempts = (levels && levels[2]) ? levels[2].attempts : 0;
                    const level3Attempts = (levels && levels[3]) ? levels[3].attempts : 0;
                    return (
                        <div tw='flex space-x-2'>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageSilver} alt='Silver' />
                                {levels[1]?.score ?
                                    <>
                                        {levels[1]?.completions > 0 ?
                                            <span
                                                tw='text-[12px] text-center font-medium  absolute top-[-0.25rem] right-[-0.2rem]  bg-[#02AC6F] h-[20px] w-[20px] text-white rounded-full'>
                                                {levels[1]?.completions + 1}X
                                            </span> : null}
                                        <div
                                            tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.42rem] left-0 right-0 text-white rounded-xl'>
                                            <ProgressBar
                                                score={levels[1]?.score}
                                                css={{ width: `${levels[1]?.score}%` }} />
                                        </div>
                                        <div tw='text-sm absolute bottom-[-0.57rem]'>
                                            {levels ? `${levels[1]?.score}%` ?? '' : ''}
                                        </div>
                                        <span
                                            tw='text-xs font-medium text-[#cec2f0] absolute bottom-[-1.52rem] left-0 right-0'>
                                            {levels ? `${levels[1]?.attempts} att` ?? '' : ''}
                                        </span>
                                    </> :
                                    null}

                                {(!stats || emptyStats) && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                {/* Silver locked */}
                                {stats?.current_level < 1 && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                {/* Silver unlocked but no attempts */}
                                {(stats?.current_level >= 1 && !level1Attempts) && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center' />
                                )}

                            </div>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageGold} alt='Gold' />
                                {levels[2]?.score ?
                                    <>
                                        {levels[2]?.completions > 0 ?
                                            <span
                                                tw='text-[12px] text-center font-medium  absolute top-[-0.25rem] right-[-0.2rem]  bg-[#02AC6F] h-[20px] w-[20px] text-white rounded-full'>
                                                {levels[2]?.completions + 1}X
                                            </span> : null}
                                        <div
                                            tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.42rem] left-0 right-0 text-white rounded-xl'>
                                            <ProgressBar
                                                score={levels[2]?.score}
                                                css={{ width: `${levels[2]?.score}%` }} />
                                        </div>
                                        <div
                                            tw='text-sm absolute bottom-[-0.52rem]'> {levels ? `${levels[2]?.score}%` ?? '' : ''}</div>
                                        <span
                                            tw='text-xs font-medium text-[#cec2f0] absolute bottom-[-1.55rem] left-0 right-0'>{levels ? `${levels[2]?.attempts} att` ?? '' : ''}</span>
                                    </> :
                                    null}

                                {(!stats || emptyStats) && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                {/* Gold locked */}
                                {stats?.current_level < 2 && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                {/* Gold unlocked but no attempts */}
                                {(stats?.current_level >= 2 && !level2Attempts) && (
                                    <div tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center' />
                                )}

                            </div>
                            <div tw='relative flex items-center justify-center'>
                                <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImagePlatinum} alt='Platinum' />
                                {levels[3]?.score ?
                                    <>
                                        {levels[3]?.completions > 0 ?
                                            <span
                                                tw='text-[12px] text-center font-medium  absolute top-[-0.25rem] right-[-0.2rem]  bg-[#02AC6F] h-[20px] w-[20px] text-white rounded-full'>
                                                {levels[3]?.completions + 1}X
                                            </span> : null}
                                        <div
                                            tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.42rem] left-0 right-0 text-white rounded-xl'>
                                            <ProgressBar
                                                score={levels[3]?.score}
                                                css={{ width: `${levels[3]?.score}%` }} />
                                        </div>
                                        <div
                                            tw='text-sm absolute bottom-[-0.52rem]'> {levels ? `${levels[3]?.score}%` ?? '' : ''}</div>
                                        <span
                                            tw='text-xs font-medium text-[#cec2f0] absolute bottom-[-1.55rem] left-0 right-0'>{levels ? `${levels[3]?.attempts} att` ?? '' : ''}</span>
                                    </> :
                                    null}
                                {(!stats || emptyStats) && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>
                                    </div>
                                )}

                                {/* Platinum locked */}
                                {stats?.current_level < 3 && (
                                    <div
                                        tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                        <div tw='absolute -top-2 -right-1'>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                                <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                                <path
                                                    fill='#fff'
                                                    d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                            </svg>
                                        </div>

                                    </div>
                                )}

                                {/* Gold unlocked but no attempts */}
                                {(stats?.current_level >= 3 && !level3Attempts) && (
                                    <div tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center' />
                                )}

                            </div>
                        </div>
                    );
                }
            }
        ],
        [emptyStats, navigate, handleRecordSort]
    );


    // filter activity for only video:watched
    // filter activity that is after the 7 days or 30 days
    let loadedActivity = activity ? (Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity) : null;
    let filterActivity = loadedActivity?.filter((a) => a.name === 'video:watched');
    const viewsThisWeek = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const viewsThisMonth = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(30, 'days'))).length;

    const objectWithLargestViewCount = tableData.length > 0 ? tableData.reduce((acc, curr) => {
        if (curr.stats.view_count > acc.stats.view_count) {
            return curr;
        } else {
            return acc;
        }
    }) : null;

    const mostEarned = (
        <div tw='relative mt-2'>
            <div tw='absolute -top-1.5 -left-1.5 z-10' css={{ boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.35)' }}>
                <svg xmlns='http://www.w3.org/2000/svg' width='27' height='24' fill='none'>
                    <rect width='27' height='24' fill='#22C3E1' rx='4.61' />
                    <path
                        fill='#fff'
                        d='M11.393 18.225c-.31.214-.622.226-.939.037-.317-.19-.475-.482-.476-.878V7.144c0-.396.159-.689.476-.879.317-.19.63-.177.939.038l7.56 5.12c.277.198.417.478.417.84 0 .364-.14.644-.418.842l-7.56 5.12Z' />
                </svg>
            </div>
            {emptyStats ?
                <div
                    tw='w-[71px] h-[46px] ' css={[css`border-radius: 4px;
                    background: rgba(255, 255, 255, 0.25);
                    backdrop-filter: blur(4px);`]}>
                    <div tw='w-full h-full pt-3 flex justify-center items-center '>
                        <img tw='h-auto w-[25px]' src={WhiteLogo} alt='thumbnail' />
                    </div>
                </div>
                : <img
                    tw='h-[46px] w-[71px] object-cover rounded-[4px]' src={objectWithLargestViewCount?.url_thumbnail}
                    alt='thumbnail' />
            }
        </div>
    );

    const contents = [
        { title: 'my total views', value: totalViews },
        { title: 'views this week', value: viewsThisWeek },
        { title: 'views this month', value: viewsThisMonth },
        { title: 'most viewed', value: mostEarned }

    ];
    const mobileData = emptyStats ? recommendedVideosWithStats : preSortTableData;
    return (
        <div>
            <div>
                <DTCStatsBanner
                    title='My Video Stats'
                    contents={contents}
                />
                <div tw='bg-[#3E257A] rounded-b-lg'>
                    <div
                        tw='hidden nav_md:block px-4 max-h-[555px] overflow-x-auto overflow-y-auto mr-4'
                        css={[ScrollBarStyle]}>

                        <Table
                            data={emptyStats ? recommendedVideosWithStats : preSortTableData}
                            columns={
                                // timesTableProduct ?
                                //     columns.filter((c) => c.id !== 'view_count')
                                //     : 
                                    columns
                            }
                            headerStyle={tw`text-center bg-[#3E257A] text-sm text-[#cec2f0] pt-8 first:text-left first:justify-start first:pl-10 last:pr-4 `}
                            rowStyle={tw`font-Poppins py-8 bg-[#3E257A] text-white text-sm font-normal text-center border-b-[1px] border-black px-4`}
                            cellStyle={tw`px-6 flex items-center text-sm  justify-center first:justify-start first:text-left font-Poppins`}
                            isSortable
                            dtc={true}
                        // getHeaderProps={(row) => ({
                        //     style:
                        //         row.index === 4
                        //             ? {
                        //                 position: 'sticky'
                        //             }
                        //             : {}
                        // })}
                        />
                        {emptyStats &&
                            <div
                                tw='w-full bg-[#3E257A] rounded-b-lg h-[150px] mt-[-20px] flex flex-col items-center justify-center'>
                                <div tw=' text-white font-medium'>Start watching videos and taking Challenges to get stats!</div>
                                {!timesTableProduct && <div tw='mt-4 text-white font-medium'>Above are some videos to get started.</div>}
                                <div tw='mt-4 text-white font-medium'>Remember, the more times you watch a video…the faster you learn!</div>

                            </div>
                        }

                        {conditionalCopy &&
                            <div tw='w-full bg-[#3E257A] rounded-b-lg h-[150px] mt-[-20px] flex flex-col items-center justify-center text-center'>
                                <div tw='text-white font-medium'>Congrats! You’ve unlocked your first video!</div>
                                <div tw='mt-4 text-white font-medium max-w-[345px] md:max-w-[485px] lg:max-w-[780px]'>When you unlock videos, you’ll see your video stats here. Keep
                                    watching videos and taking Challenges to learn math the fast and fun way. Remember, the more times you watch a video…the faster you learn!
                                </div>
                            </div>
                        }

                    </div>

                </div>
            </div>

            <div tw='block nav_md:hidden h-full  bg-[#3E257A] rounded-b-lg' css={[emptyStats && tw`pb-14`, conditionalCopy && tw`pb-14`]}>
                <div tw='flex flex-col'>
                    {mobileData.map((stats) => (
                        <VideoStatsMobileCardDTC key={stats.id} stats={stats} />
                    ))}

                </div>
                {emptyStats &&
                    <div
                        tw='w-full bg-[#3E257A] rounded-b-lg h-[150px] mt-[-20px] flex flex-col items-center justify-center text-sm md:text-base font-normal md:font-medium text-white text-center px-4'>
                        <div tw='pt-20 '>Start watching videos and taking Challenges to get stats!</div>
                        {!timesTableProduct && <div tw='mt-4 text-white font-medium'>Above are some videos to get started.</div>}
                        <div tw='mt-4 '>Remember, the more times you watch a video…the faster you learn!</div>

                    </div>
                }

                {conditionalCopy &&
                    <div
                        tw='w-full bg-[#3E257A] rounded-b-lg h-[150px] pt-10 sm:pt-20 md:mt-[-20px] flex flex-col items-center justify-center text-sm md:text-base font-normal md:font-medium text-white text-center px-4'>
                        <div tw=''>Congrats! You’ve unlocked your first video!</div>
                        <div tw='mt-4 max-w-[375px] sm:max-w-[485px] lg:max-w-[780px]'>When you unlock videos, you’ll see your video stats here. Keep watching videos and taking
                            Challenges to learn math the fast and fun way. Remember, the more times you watch a video…the faster you learn!
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default MyVideoStats;
