// create a url for the muzology api, irrespective of slashes
import { MUZOLOGY_API_URL, MUZOLOGY_WEBSITE_URL } from '../config';

export const createMuzologyAPIURL = (path) => {
    return new URL(path, MUZOLOGY_API_URL).href;
};

export const createMuzologyWebURL = (path) => {
    return new URL(path, MUZOLOGY_WEBSITE_URL).href;
};

// ?utm_source=${encodeURIComponent('muzology platform')}
// &utm_medium=banner
// &utm_campaign=${encodeURIComponent('Book VIP Tour Banner')}`;
