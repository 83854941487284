import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import RecordImageSilver from 'static/images/records/DTC/silver.png';
import RecordImageGold from 'static/images/records/DTC/gold.png';
import RecordImagePlatinum from 'static/images/records/DTC/platinum.png';
import tw, { css, styled } from 'twin.macro';
import { useVideoStats } from 'state/Student/VideoStats';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useStudentStats } from 'state/Student/VideoStats';

const ProgressBar = styled.div(({ $score, $numberOfVideos }) => [
    tw` bg-[#3E257A] rounded-xl text-center h-[17px] `,
    $score > 0 && tw`bg-[#46A6F8]`,
    $score === $numberOfVideos && tw`bg-[#02AC6F]`

]);


const VideoViewProgress = ({ videos, student_id, favorites }) => {
    const childStats = useStudentStats(student_id);
    const allStats = useVideoStats();
    if( !videos )
        return null;
    const findStats = (stats, videoId) => {
        return Object.values(stats ?? {}).find((s) => s.video_id === videoId);
    };
    let stats = videos?.map((v) => findStats(allStats, v.video) ?? null).filter((v) => v !== null);
    if (student_id) stats = videos.map((v) => findStats(childStats, v.video) ?? null).filter((v) => v !== null);
    if (favorites) stats = videos.map(v => v.stats);

    const statsReduceByLevel = stats.reduce((acc, stat) => {
        let countLevel1 = acc[1] || 0; // Initialize count for level 1
        let countLevel2 = acc[2] || 0; // Initialize count for level 2
        let countLevel3 = acc[3] || 0; // Initialize count for level 3

        // Check if each condition is true and update the counts accordingly
        if (stat?.quiz_levels) {
            if (stat?.quiz_levels[1] && stat.quiz_levels[1]?.score === 100) countLevel1++;
            if (stat?.quiz_levels[2] && stat.quiz_levels[2]?.score === 100) countLevel2++;
            if (stat?.quiz_levels[3] && stat.quiz_levels[3]?.score === 100) countLevel3++;
        }

        // Return the updated counts as an object in the accumulator
        return {
            ...acc,
            1: countLevel1,
            2: countLevel2,
            3: countLevel3
        };
    }, { 1: 0, 2: 0, 3: 0 });

    // sum up the view counts for circular progress bar
    const totalViews = stats.reduce((acc, video) => acc + (video.view_count > 0 ? 1 : 0), 0);

    // hide the progress if there are no videos
    if (videos.length === 0)
        return null;

    const progressValue = stats.length > 0 ? totalViews / videos.length * 100 : 0;
    const sliceProgressValue = progressValue < 100 ? progressValue.toString().slice(0, 2) : progressValue;

    return (
        <>
            <div tw='hidden sm:flex items-center justify-center '>
                <div tw='text-[#9D91BB] text-xs font-medium mr-4 '>VIDEO VIEW PROGRESS</div>
                <div tw='mr-4'>
                    <div tw='w-12 h-12'>
                        <CircularProgressbar
                            value={progressValue}
                            text={`${sliceProgressValue}%`}
                            strokeWidth={10}
                            styles={buildStyles({
                                textColor: 'white',
                                pathColor: progressValue === 100 ? '#02AC6F' : '#46A6F8',
                                trailColor: '#6441B9',
                                textSize: '24px'
                            })}
                        />
                    </div>
                </div>
                <div tw='bg-[#3E257A] w-[2px] h-[47px] mr-4' />
                <div tw='flex items-center justify-center gap-5'>
                    <div tw='relative flex items-center justify-center '>
                        <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageSilver} alt='img' />
                        <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                            <ProgressBar $score={statsReduceByLevel[1]} $numberOfVideos={videos.length}>
                                {statsReduceByLevel[1] ?? 0} of {videos.length}
                            </ProgressBar>
                        </div>
                        {statsReduceByLevel[1] === 0 &&
                            <div
                                tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                <div tw='absolute -top-2 -right-1'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                        <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                        <path
                                            fill='#fff'
                                            d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                    <div tw='relative flex items-center justify-center'>
                        <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageGold} alt='img' />
                        <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                            <ProgressBar $score={statsReduceByLevel[2]} $numberOfVideos={videos.length}>
                                {statsReduceByLevel[2] ?? 0} of {videos.length}
                            </ProgressBar>
                        </div>
                        {statsReduceByLevel[2] === 0 &&
                            <div
                                tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                <div tw='absolute -top-2 -right-1'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                        <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                        <path
                                            fill='#fff'
                                            d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                    <div tw='relative flex items-center justify-center'>
                        <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImagePlatinum} alt='img' />
                        <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                            <ProgressBar $score={statsReduceByLevel[3]} $numberOfVideos={videos.length}>
                                {statsReduceByLevel[3] ?? 0} of {videos.length}
                            </ProgressBar>
                        </div>
                        {statsReduceByLevel[3] === 0 &&
                            <div
                                tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                <div tw='absolute -top-2 -right-1'>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                        <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                        <path
                                            fill='#fff'
                                            d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div tw='flex flex-col sm:hidden items-start justify-center gap-4 '>
                <div tw='text-[#9D91BB] text-xs font-medium mr-4 '>VIDEO VIEW PROGRESS</div>
                <div tw='flex items-center justify-center'>
                    <div tw='mr-4'>
                        <div tw='w-12 h-12'>
                            <CircularProgressbar
                                value={progressValue}
                                text={`${sliceProgressValue}%`}
                                strokeWidth={10}
                                styles={buildStyles({
                                    textColor: 'white',
                                    pathColor: progressValue === 100 ? '#02AC6F' : '#6441B9',
                                    trailColor: '#46A6F8',
                                    textSize: '24px'
                                })}
                            />
                        </div>

                    </div>
                    <div tw='bg-[#3E257A] w-[2px] h-[47px] mr-4' />
                    <div tw='flex items-center justify-center gap-5'>
                        <div tw='relative flex items-center justify-center '>
                            <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageSilver} alt='img' />
                            <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                                <ProgressBar $score={statsReduceByLevel[1]} $numberOfVideos={videos.length}>
                                    {statsReduceByLevel[1] ?? 0} of {videos.length}
                                </ProgressBar>
                            </div>
                            {statsReduceByLevel[1] === 0 &&
                                <div
                                    tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-2 -right-1'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                            <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                            <path
                                                fill='#fff'
                                                d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImageGold} alt='img' />
                            <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                                <ProgressBar $score={statsReduceByLevel[2]} $numberOfVideos={videos.length}>
                                    {statsReduceByLevel[2] ?? 0} of {videos.length}
                                </ProgressBar>
                            </div>
                            {statsReduceByLevel[2] === 0 &&
                                <div
                                    tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-2 -right-1'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                            <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                            <path
                                                fill='#fff'
                                                d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14 sm:w-10 lg:w-20 max-w-[44px]' src={RecordImagePlatinum} alt='img' />
                            <div tw='w-full bg-[#1C0057] font-medium absolute h-[17px] max-w-[43px] bottom-[-0.3rem] left-[2px] right-0 text-white rounded-xl text-xs '>
                                <ProgressBar $score={statsReduceByLevel[3]} $numberOfVideos={videos.length}>
                                    {statsReduceByLevel[3] ?? 0} of {videos.length}
                                </ProgressBar>
                            </div>
                            {statsReduceByLevel[3] === 0 &&
                                <div
                                    tw='absolute bg-gray-500 bg-opacity-40 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-2 -right-1'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='23' height='22' fill='none'>
                                            <circle cx='11.063' cy='11' r='11' fill='#FA6778' />
                                            <path
                                                fill='#fff'
                                                d='M16 11c0-.662-.56-1.2-1.25-1.2h-.625V8c0-1.654-1.402-3-3.125-3S7.875 6.346 7.875 8v1.8H7.25C6.56 9.8 6 10.338 6 11v4.8c0 .662.56 1.2 1.25 1.2h7.5c.69 0 1.25-.538 1.25-1.2V11ZM9.125 8c0-.992.841-1.8 1.875-1.8s1.875.808 1.875 1.8v1.8h-3.75V8Z' />
                                        </svg>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

VideoViewProgress.propTypes = {
    anyProp: PropTypes.any
};

export default VideoViewProgress;

