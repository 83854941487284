/* the whole pattern here is ripped from the documentation:
 * https://github.com/gaearon/redux-devtools/blob/master/docs/Walkthrough.md
 *
 * its slightly clunky (IMO) but leaves open the possibility of using Uglify to
 * completely exclude dev-only code from the production builds.  This is a
 * pattern we can feasibly use in other places throughout the codebase.
 */

import rootReducer from 'app/reducers.js';
import { fromJS } from 'immutable';
import { applyMiddleware, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import packageInfo from '../package.json';

/**
 * @param {Immutable.Map} initialData - only of note because it is expected to
 * have `immutable.js`'s API by the time it hits the store.
 *
 * In-between creating the store and returning it we are free to dispatch any
 * asynchronous calls to get more initial data.
 */

// the middleware
const middleware = [apiMiddleware, thunk];

// this takes the initial store loaded from the front end
const configureStore = (initialStore) => {
    // console.time("configure store");
    // additional keys
    const otherData = {
        ...initialStore,

        ui: {
            sidebar_dock: true,
            sidebar_open: true,

            // default order
            categories: {
                videos: [
                    {
                        name: 'All Videos',
                        id: 'all_videos',
                        index: 0
                    },
                    {
                        name: 'Recently Watched',
                        id: 'recently_watched',
                        index: 1
                    },
                    {
                        name: 'Assigned Videos',
                        id: 'assigned_videos',
                        index: 2
                    },
                    {
                        name: 'Favorites',
                        id: 'favorites',
                        index: 3
                    },
                    {
                        name: 'Needs Improvement',
                        id: 'needs_improvement',
                        index: 4
                    },
                    {
                        name: 'My Strengths',
                        id: 'my_strengths',
                        index: 5
                    }
                ],
                assignments: [
                    {
                        name: 'All Assignments',
                        id: 'all_assignments',
                        index: 0
                    },
                    {
                        name: 'Past Due',
                        id: 'past_due',
                        index: 1
                    },
                    {
                        name: 'Not Started',
                        id: 'not_started',
                        index: 2
                    },
                    {
                        name: 'In Progress',
                        id: 'in_progress',
                        index: 3
                    },
                    {
                        name: 'Completed',
                        id: 'completed',
                        index: 4
                    }
                ],
                stats: [
                    {
                        name: 'My Progress',
                        id: 'video_progress',
                        index: 0
                    },
                    {
                        name: 'My Records',
                        id: 'records_earned',
                        index: 1
                    },
                    {
                        name: 'My Achievements',
                        id: 'achievements_earned',
                        index: 2
                    },
                    {
                        name: 'Needs Improvement',
                        id: 'needs_improvement',
                        index: 3
                    },
                    {
                        name: 'My Strengths',
                        id: 'my_strengths',
                        index: 4
                    },
                    {
                        name: 'My Growth',
                        id: 'my_growth',
                        index: 5
                    }
                ]
            }
        }
    };

    // merge and convert to immutable
    const initialData = fromJS(initialStore || {}).merge(fromJS(otherData));
    // initialStore = Object.assign({}, initialStore, otherData);
    // use compose, or redux-devtools compose
    const enhancer = composeWithDevTools(applyMiddleware(...middleware));

    // build the redux store
    return createStore(rootReducer, initialData, enhancer);
};

// initial redux store
const initialStore = {
    appInfo: {
        name: 'muzology-app',
        version: packageInfo.version
    }
};

export default configureStore(initialStore);
