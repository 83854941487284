import React, { useEffect, useMemo, useState } from 'react';
import 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { styled } from 'twin.macro';
import { Set } from 'immutable';
import { Helmet } from 'react-helmet';

import CategoryListView from 'components/CategoryListView';
import ContentPage from 'components/ContentPage';
import MobileDropDown from 'components/mobile/MobileDropDown';
import { useCourseAndVideos, useLessons } from 'state/CatalogState';
import { useTeacher, useParent, useAccount } from 'state/User/UserState';
import { useFavoriteVideos, useRecentlyWatchedVideos, useVideosWithStats } from 'state/Student/VideoStats';
import { useAssignmentActions, useAssignedVideos } from 'state/Student/AssignmentsState';
import { handleCreateNewPlaylistModal, handlePlaylistDropdown, resetPlaylistDropdown, usePlaylistState } from 'state/PlaylistState';

import IconAssignedVideos from 'images/icons/IconAssignedVideos';
import CategoryGeometry from 'images/icons/CategoryGeometry';
import CategoryMathVocabulary from 'images/icons/CategoryMathVocabulary';
import CategoryNumberSystem from 'images/icons/CategoryNumberSystem';
import CategoryFractions from 'images/icons/CategoryFractions';
import CategoryRatios from 'images/icons/CategoryRatios';
import CategoryFunctions from 'images/icons/CategoryFunctions';
import AllVideosIcon from 'images/icons/AllVideosIcon';
import IconLikedVideos from 'images/icons/IconLikedVideos';
import IconRecentlyWatched from 'images/icons/IconRecentlyWatched';
import CategoryExpressions from 'images/icons/CategoryExpressions';
import DropDownArrow from 'static/icons/drop-down-arrow.png';

import { useDevTool } from '../../../../lib/DevTool/DevTool';
import VideosGrid from './VideosGrid';
import { useVideoSearch } from './VideoSearch';
import './videocontainer.css';
import { Tooltip } from 'react-tooltip';

//<editor-fold desc="Styled Components">
const VideosCategoryListView = styled(CategoryListView)`
  .contents {
    background-image: linear-gradient(135.24deg, #2d0569 13.37%, #4c3fc1 71.65%);
  }

  .header-items {
    .header-title {
      font-size: 24px;
      color: white;
    }

    color: white;
    border-bottom: 2px solid white;

    margin-bottom: 30px;
  }

  .section-subtitle {
    font-family: 'Avenir', serif;
    padding-bottom: 0.5rem;
    font-weight: 500;
    font-size: 24px;
    color: #1a1717;
    line-height: normal;
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #1a1717;
  }

  .styled-header {
    margin-top: 50px;
  }
`;

const VideosContentPage = styled(ContentPage)`
  .null-state {
    font-size: 30px;
    color: #fff;
    text-align: center;
    margin-left: 30px;
    line-height: 1.75;
  }
`;

const filterSearchVideos = (videos, search) => {
    // console.log('Filter search: ', search, videos);
    if (search?.length > 0) {
        return videos.filter((video) => {
            return search?.find((r) => r.objectID === video.lesson_id);
        });
    }
    return videos;
};
//</editor-fold>

// sidebar categories
const CATEGORY_ALL_VIDEOS = 'all_videos';
const CATEGORY_RECENTLY_WATCHED = 'recently_watched';
const CATEGORY_ASSIGNED_VIDEOS = 'assigned_videos';
const CATEGORY_FAVORITES = 'favorites';
const SORT_TYPE_ALPHA = 'alphabetical';
const SORT_TYPE_WATCH_DATE = 'watch_date';
const SORT_TYPE_ASSIGNED_DATE = 'due_date';
const SORT_TYPE_HIGH_SCORE = 'highest_scored';
const SORT_TYPE_PAST_DUE = 'past_due';

const sortItems = [
    {
        id: SORT_TYPE_ALPHA,
        name: 'A - Z'
    },
    {
        id: SORT_TYPE_HIGH_SCORE,
        name: 'Score: High to Low'
    },
    {
        id: SORT_TYPE_WATCH_DATE,
        name: 'Watched: Recent to Old'
    },
    {
        id: SORT_TYPE_ASSIGNED_DATE,
        name: 'Assigned: Recent to Old'
    },
    {
        id: SORT_TYPE_PAST_DUE,
        name: 'Assigned: Overdue to Complete'
    }
];

const video_library = [
    { id: CATEGORY_ALL_VIDEOS, name: 'All Videos', icon: <AllVideosIcon aria-hidden='true' /> },
    { id: CATEGORY_RECENTLY_WATCHED, name: 'Recently Watched', icon: <IconRecentlyWatched aria-hidden='true' /> },
    { id: CATEGORY_ASSIGNED_VIDEOS, name: 'Assigned Videos', icon: <IconAssignedVideos aria-hidden='true' /> },
    { id: CATEGORY_FAVORITES, name: 'Favorites', icon: <IconLikedVideos aria-hidden='true' /> }
];

const parent_video_library = [
    { id: CATEGORY_ALL_VIDEOS, name: 'All Videos', icon: <AllVideosIcon aria-hidden='true' /> },
    { id: CATEGORY_RECENTLY_WATCHED, name: 'Recently Watched', icon: <IconRecentlyWatched aria-hidden='true' /> },
    { id: CATEGORY_ASSIGNED_VIDEOS, name: 'Shared Videos', icon: <IconAssignedVideos aria-hidden='true' /> },
    { id: CATEGORY_FAVORITES, name: 'Favorites', icon: <IconLikedVideos aria-hidden='true' /> }
];


const CATEGORY_ICONS = {
    CategoryMathVocabulary: <CategoryMathVocabulary aria-hidden='true' />,
    CategoryNumberSystem: <CategoryNumberSystem aria-hidden='true' />,
    CategoryExpressions: <CategoryExpressions aria-hidden='true' />,
    CategoryFractions: <CategoryFractions aria-hidden='true' />,
    CategoryFunctions: <CategoryFunctions style={{ color: '#cccccc' }} aria-hidden='true' />,
    CategoryGeometry: <CategoryGeometry aria-hidden='true' />,
    CategoryRatios: <CategoryRatios aria-hidden='true' />
};

function useCourseTree(course) {
    // construct a course tree from course lesson groups, lessons, and videos
    // const course = useMainCourseAndVideos();
    const allLessons = useLessons();
    const allVideos = useVideosWithStats();

    const roots = useMemo(() => {

        // create a fake playlist for a group
        const group_playlist = (group) => {
            return {
                id: group.id,
                name: group.title,
                // video_ids: group.videos?.map(video_id => video_id),
                lessons: group.lessons,
                videos: (group.lessons?.map(lesson_id => {
                    const lesson = allLessons[lesson_id];
                    if (lesson) {
                        const video_id = lesson.video;
                        if (video_id) {
                            const video = allVideos.find(video => video.id === video_id);
                            // console.log('all videos: ', allVideos);
                            // console.log('found lesson video:', video);
                            return video ?? null;
                        }
                    }
                    return null;
                }) ?? []).filter(video => video !== null)
            };
        };

        // create a tree of groups and playlists
        const create_course_tree = (group) => {
            // console.log('create tree', group);
            const node = {
                id: group.id,
                name: group.title,
                icon: CATEGORY_ICONS[group.icon ?? 'CategoryMathVocabulary'],
                playlists: []
                // playlists: [group_playlist(group)]
                // playlists: course_playlists.filter((playlist) => playlist.name.startsWith('Math Vocabulary'))
            };

            // if there are lessons in this group create a playlist
            if (group.lessons.length) {
                node.playlists = [group_playlist(group)];
            }

            // look for child groups
            const children = course?.groups?.filter(g => g.parent === group.id);

            // sort the child groups by 'index'
            children.sort((a, b) => a.index - b.index);

            // create playlists
            const playlists = children?.map(child => group_playlist(child));

            // merge playlist lists
            node.playlists = node.playlists.concat(playlists);
            return node;
        };

        const root_groups = course?.groups?.filter(group => group.parent === null) ?? [];
        // sort the groups by the 'index' field
        const sorted_groups = root_groups.sort((a, b) => a.index - b.index);
        const groups = sorted_groups.map(create_course_tree);
        // console.log('Create root groups: ', groups);
        return groups;
    }, [course, allLessons, allVideos]);

    // console.log('Return root groups: ', roots);
    return roots;
}

const VideosContainer = (props) => {
    const navigate = useNavigate();
    const { fetchAssignments } = useAssignmentActions();

    const [category_id, setCategory] = useState(CATEGORY_ALL_VIDEOS); // default to all videos
    const [sortOrder, setSortOrder] = useState(SORT_TYPE_ALPHA);

    const [selected, setSelected] = useState(Set());

    const [
        playlistDispatch
    ] = usePlaylistState();

    // const category_course = useMainCourseAndVideos();
    let grade_course_id = null;
    let category_course_id = null;
    let main_course_id = null;

    //////////
    // B2B COURSES
    grade_course_id = 'course-by-grade';
    category_course_id = 'course-by-category';
    // grade_course_id = 'e2d99bb6-19ca-4269-8bb6-a21bd86c2a99';
    // category_course_id = 'a7be8d0c-1e0f-47fa-9bcd-a7f9fb5a0358';

    //////////
    // D2C COURSE
    const main_course_id_v1 = 'pre-algebra';
    const main_course_id_v2 = 'course-by-category';

    // if (true) {
    //     //////////
    //     // B2B COURSES
    //     grade_course_id = 'course-by-grade-b2b';
    //     category_course_id = 'course-by-category-b2b';
    //     main_course_id = 'pre-algebra';
    // } else {
    //     /////////
    //     // D2C COURSES
    //     // grade_course_id = 'e2d99bb6-19ca-4269-8bb6-a21bd86c2a99';
    //     // category_course_id = 'a7be8d0c-1e0f-47fa-9bcd-a7f9fb5a0358';
    //     // main_course_id = '891dafea-8e12-4187-93d1-e869b036d2a3';
    //     grade_course_id = 'course-by-grade';
    //     category_course_id = 'course-by-category';
    //     main_course_id = 'pre-algebra';
    // }

    // B2B:
    // attempt to use the v2 course if it exists
    const main_course_v1 = useCourseAndVideos(main_course_id_v1);
    const main_course_v2 = useCourseAndVideos(main_course_id_v2);
    const main_course = main_course_v2 ?? main_course_v1;

    // D2C:
    // course-by-grade
    const grades_course = useCourseAndVideos(grade_course_id);

    // course-by-category
    const category_course = useCourseAndVideos(category_course_id);

    const teacher = useTeacher();
    const parent = useParent();
    const { is_parent_account } = useAccount();

    const isDesktop = useMedia('(min-width: 1400px)');
    const isTablet = useMedia('(min-width: 500px)');

    const { query: searchQuery, search: searchVideos, hits: searchResults } = useVideoSearch();

    // functions to help us with reordering the result of teachers selecting videos to be added to an assignment
    const onClickVideo = (video) => {
        // if (props.showFlexRow) {
        //     // if currently editing an assignment, add this to selected videos
        //     handleSelectVideo(video);
        // } else {
        // open the video
        navigate(`/video/${video.id}`);
        // }
        return true;
    };

    // start creating playlist
    const handleCreateNewPlaylist = () => {
        setCategory(CATEGORY_ALL_VIDEOS);
        resetPlaylistDropdown(playlistDispatch);
        handleCreateNewPlaylistModal(playlistDispatch, true);
        handlePlaylistDropdown(playlistDispatch, true);

    };

    // re grab playlist data on load
    useEffect(() => {
        fetchAssignments();
    }, [fetchAssignments]);

    // get the course playlists
    // let course_playlists = useMemo(() => {
    //     if (main_course && main_course.playlists) {
    //         return main_course.playlists.map((playlist) => {
    //             if (playlist) {
    //                 // console.log('PLAYLIST', playlist);
    //                 return {
    //                     id: playlist.id,
    //                     name: `${playlist.title} (${playlist.videos ? playlist.videos.length : 0})`,
    //                     videos: playlist.videos
    //                 };
    //             }
    //         });
    //     }
    //     return [];
    // }, [main_course]);

    const handleSelectVideo = ({ id }) => {
        // add or remove the class id from the classes set
        console.log('TOGGLE', id);
        if (selected.contains(id)) {
            setSelected(selected.remove(id));
        } else {
            setSelected(selected.add(id));
        }
    };

    // const allLessons = useLessons();
    const allVideos = useVideosWithStats();

    // get the root lesson groups of the content tree
    const main_groups = useCourseTree(main_course);
    const grade_groups = useCourseTree(grades_course);
    const category_groups = useCourseTree(category_course);

    let sections = [
        {
            items: video_library
        },
        {
            title: 'Video Categories',
            items: main_groups
        }
    ];

    if (is_parent_account) {
        sections = [
            {
                items: parent_video_library
            },
            {
                title: 'Grade Levels',
                items: grade_groups
            },
            {
                title: 'Video Categories',
                items: category_groups
            }
        ];
    }

    const assigned_videos = useAssignedVideos();
    const recent_videos = useRecentlyWatchedVideos();
    const favorite_videos = useFavoriteVideos();

    useDevTool('VideosContainer', {
        'video course': main_course,
        main_groups: main_groups,
        grade_groups: grade_groups,
        category_groups: category_groups
    });

    const find_group = (groupId) => {
        if (main_groups) {
            const group = main_groups.find((group) => group.id === groupId);
            if (group)
                return group;
        }
        if (category_groups) {
            const group = category_groups.find((group) => group.id === groupId);
            if (group)
                return group;
        }
        if (grade_groups) {
            const group = grade_groups.find((group) => group.id === groupId);
            if (group)
                return group;
        }
        return null;
    };

    // filter the videos & get the category name
    let videos = [];
    let categoryName = category_id;
    switch (category_id) {
        case CATEGORY_ALL_VIDEOS:
            videos = allVideos ?? [];
            videos = videos.sort((a, b) => a.title.localeCompare(b.title));
            categoryName = 'All Videos';
            break;
        case CATEGORY_RECENTLY_WATCHED:
            // recently watched are already sorted by the hook
            videos = recent_videos ?? [];
            categoryName = 'Recently Watched';
            break;
        case CATEGORY_ASSIGNED_VIDEOS:
            videos = assigned_videos ?? [];
            videos = videos.sort((a, b) => a.title.localeCompare(b.title));
            categoryName = parent ? 'Shared Videos' : 'Assigned Videos';
            break;
        case CATEGORY_FAVORITES:
            videos = favorite_videos ?? [];
            videos = videos.sort((a, b) => a.title.localeCompare(b.title));
            categoryName = 'Favorites';
            break;
        default:
            // see if we can find a group with this category id
            const group = find_group(category_id);
            if (group) {
                categoryName = group.name;
                if (group.playlists && group.playlists.length) {
                    const playlist = group.playlists[0];
                    if (playlist) {
                        videos = playlist.videos;
                    }
                }
            }
            break;
    }
    // default videos to empty list
    // videos = videos || [];
    // sort the videos - do not re-sort recently watched videos, they are already sorted by the hook
    // if (videos && category_id !== CATEGORY_RECENTLY_WATCHED) {
    //     switch (sortOrder) {
    //         case SORT_TYPE_ALPHA:
    //             videos = videos.sort((a, b) => a.title.localeCompare(b.title));
    //             break;
    //         case SORT_TYPE_HIGH_SCORE:
    //             videos = videos.sort(sortHighScore);
    //             break;
    //         case SORT_TYPE_WATCH_DATE:
    //             videos = videos.sort(sortWatchDate);
    //             break;
    //         case SORT_TYPE_ASSIGNED_DATE:
    //             videos = videos.sort(sortDueDate);
    //             break;
    //         case SORT_TYPE_PAST_DUE:
    //             videos = videos.sort(sortOverdueToComplete);
    //             break;
    //         default:
    //     }
    // }

    let rows = null;
    let totalVideos = 0;

    const Videos = (props) => {
        return (
            <>
                {props.children}
                <VideosGrid
                    cols={isDesktop ? 3 : 3}
                    key={category_id}
                    onClickVideo={onClickVideo}
                    videos={filterSearchVideos(props.videos, searchResults)}
                    teacher={!!teacher}
                    selectedVideos={selected ? selected.toJS() : null}
                    playlistLength={props.playlistLength}
                />
            </>
        );
    };

    // find the selected group
    const group = find_group(category_id);
    useDevTool('selected group', group);
    if (group) {
        // Condition for if the video container is being used inside the pop-up
        console.log('[VideoContainer] group.playlists', group.playlists);
        if (group.playlists) {
            // if (props.showFlexRow === true) {
            //     rows = group.playlists.map((playlist, i) => {
            //         totalVideos += playlist.videos.length;
            //         return (
            //             <VideoFlex
            //                 key={playlist.id}
            //                 onClickVideo={onClickVideo}
            //                 videos={filterSearchVideos(playlist.videos, searchResults)}
            //                 teacher={!!teacher}
            //                 selectedVideos={selected ? selected.toJS() : null}
            //             />
            //         );
            //     });
            // } else {
            rows = group.playlists.map((playlist, i) => {
                totalVideos += playlist.videos.length;
                return (
                    <Videos key={`playlist${playlist.id}`} videos={playlist.videos} playlistLength={group.playlists.length}>
                        {/* {i !== 0 ? <div style={{ height: '2px', background: '#ffffff', margin: '15px', marginTop: '-30px', opacity: 0.29 }} /> : ''} */}
                        {group.playlists.length > 1 ? <div className='section-subtitle'>{playlist.name}</div> : ''}
                        {/*{group.playlists.length > 1 ? <div className='section-subtitle'>{`PART ${i + 1}`}</div> : ''}*/}
                    </Videos>
                );
            });
            // }
        }
    }
    else {
        // no group selected
        const filteredVideos = filterSearchVideos(videos, searchResults);

        if (searchResults?.length === 0) {
            rows = (
                <div tw='h-screen'>
                    <div tw='text-[32px] font-bold pb-4 '>No videos matched your search...</div>
                    <div tw='pb-4 text-xl'>Here are some videos you might like.</div>
                    <Videos videos={filteredVideos} playlistLength={1} />;
                </div>
            )
        }
        else rows = <Videos videos={filteredVideos} playlistLength={1} />;
    }

    // Condition for if the video container is being used inside the pop up
    // if (props.showFlexRow) {
    //     rows = (
    //         <VideoFlex
    //             teacher={!!teacher}
    //             key={category_id}
    //             onClickVideo={onClickVideo}
    //             videos={filterSearchVideos(videos, searchResults)}
    //             selectedVideos={selected ? selected.toJS() : null}
    //         />
    //     );
    // }

    // NULL STATES
    if (category_id === CATEGORY_RECENTLY_WATCHED && videos.length === 0) {
        rows = (
            <div tw='h-screen flex items-start justify-center mt-8'>
                <div tw='text-EarieBlack text-2xl w-full text-center'>
                    See your recently watched videos here.
                </div>
            </div>
        );
        // teacher
    } else if (category_id === CATEGORY_FAVORITES && favorite_videos.length === 0) {
        rows = (
            <div tw='h-screen flex-col items-center justify-start mt-8'>
                <div tw='text-EarieBlack text-2xl text-center'>See your favorited videos here.</div>
                <div tw='text-EarieBlack text-2xl text-center mt-4'>To favorite videos, click the heart in the lower right part of a video thumbnail.</div>
            </div>
        );
    } else if (category_id === CATEGORY_ASSIGNED_VIDEOS && assigned_videos.length === 0) {
        rows = (
            <div tw='h-screen flex flex-col items-center justify-start mt-8'>
                <div tw='text-EarieBlack text-2xl text-center'>
                    You haven't assigned any videos yet.
                </div>
                <div tw='text-EarieBlack text-2xl text-center mx-auto max-w-[1100px] px-8 mt-5'>
                    Assigning Muzology playlists is a great way to keep your students focused and differentiate instruction. Teachers that assign
                    playlists typically get the best Muzology results. Assigning playlists is fast and easy!{' '}
                    Click the “+ NEW PLAYLIST” button in the upper right.
                </div>
            </div>
        );
    }

    // JTD 2/16/24 - removed the total video count from the title per LI
    // let title = `${categoryName} (${totalVideos})`;
    let title = categoryName;
    if (isTablet) {
        // tablet or larger
        return (
            <VideosCategoryListView
                sections={sections}
                teacher={!!teacher}
                selectedCategory={category_id}
                onSelectCategory={setCategory}
                setSearch={searchVideos}
            >
                <Helmet>
                    <title>Videos</title>
                </Helmet>
                <VideosContentPage
                    title={title}
                    sortItems={sortItems}
                    sortOrder={sortOrder}
                    onSelectSortOrder={(sortOrder) => setSortOrder(sortOrder)}
                    borderColor='#6A7277'
                    color='#6A7277'
                    onSearch={searchVideos}
                    search={searchQuery}
                    teacher={!!teacher}
                    marginTop='25px'
                    DropDownArrow={DropDownArrow}
                    fontSize='12px'
                >
                    {rows}
                </VideosContentPage>
                <Tooltip
                    id='my-tooltip'
                    style={{
                        backgroundColor: '#6441B9',
                        color: '#fff',
                        fontSize: '12px',
                        // center the text in the tooltip
                        textAlign: 'center'


                    }}
                    render={({ content, activeAnchor }) => (
                        <span>
                            {content}
                        </span>
                    )}
                />
            </VideosCategoryListView>
        );
    } else {
        // smaller than tablet
        return (
            <VideosCategoryListView
                sections={sections}
                teacher={!!teacher}
                selectedCategory={category_id}
                onSelectCategory={setCategory}
                setSearch={searchVideos}
            >
                <Helmet>
                    <title>Videos</title>
                </Helmet>
                <MobileDropDown videoItems={sections} activeCategory={title} onSelectCategory={setCategory} selectedCategory={category_id} />
                <VideosContentPage
                    sortItems={sortItems}
                    sortOrder={sortOrder}
                    onSelectSortOrder={(sortOrder) => setSortOrder(sortOrder)}
                    borderColor='#6A7277'
                    color='linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)'
                    onSearch={searchVideos}
                    teacher={!!teacher}
                    marginTop='25px'
                >
                    {rows}
                </VideosContentPage>
            </VideosCategoryListView>
        );
    }
};

export default VideosContainer;
