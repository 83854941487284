import React, { createContext, useCallback, useContext, useReducer } from 'react';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { useDevTool } from '../lib/DevTool/DevTool';
import { useToast } from '../hooks/toast';
import { usePlaylistActions, usePlaylists } from './Teacher/TeacherProvider';
import { useClassActions, useClasses } from './Teacher/ClassState';
import { useParent } from './User/UserState';

const LocalStateContext = createContext();

function selectStudentsOrClass(dispatch, updates) {
    return dispatch({ type: 'select students or class', updates });
}

function selectStartDate(dispatch, updates) {
    return dispatch({ type: 'select start date', updates });
}

function selectDueDate(dispatch, updates) {
    return dispatch({ type: 'select due date', updates });
}

function selectGoal(dispatch, goal) {
    return dispatch({ type: 'select goal', payload: goal });
}

function selectRecordLevel(dispatch, level) {
    return dispatch({ type: 'select record level', payload: level });
}

function addMessage(dispatch, updates) {
    return dispatch({ type: 'add message', updates });
}

function resetState(dispatch) {
    return dispatch({ type: 'reset state' });
}

function playlistAssignWizardReducer(state, action) {
    console.debug('ASSIGNMENT ACTION:', action);
    switch (action.type) {
        case 'select students or class': {
            const id = action.updates.classId;
            const index = state.classes.findIndex(({ classId }) => classId === id);
            let updatedValues;
            if (index === -1) {
                updatedValues = [...state.classes, action.updates];
            } else {
                const replace = (array, index, ...items) => [...array.slice(0, index), ...items, ...array.slice(index + 1)];
                updatedValues = replace(state.classes, index, action.updates);
            }
            return {
                ...state,
                classes: updatedValues
            };
        }
        case 'select start date': {
            return {
                ...state,
                startDate: action.updates
            };
        }
        case 'select due date': {
            return {
                ...state,
                dueDate: action.updates
            };
        }
        case 'select goal': {
            return {
                ...state,
                goal: action.payload
            };
        }
        case 'select record level': {
            return {
                ...state,
                level: action.payload
            };
        }
        case 'add message': {
            return {
                ...state,
                message: action.updates
            };
        }
        case 'load a playlist details into state': {
            return {
                ...state,
                ...action.payload
                // startDate: action.payload.startDate,
                // dueDate: action.payload.dueDate,
                // goal: action.payload.goal,
                // level: action.payload.level,
                // message: action.payload.message,
                // playlistId: action.payload.playlistId
            };
        }
        case 'set playlist id': {
            return {
                ...state,
                playlistId: action.payload
            };
        }
        case 'reset state': {
            return {
                ...state,
                ...INITIAL_STATE
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

// default assignment state
const INITIAL_STATE = {
    startDate: '',
    dueDate: moment().toDate(),
    goal: 100,
    level: 2,
    message: '',
    classes: [],
    playlistId: null
};

function PlaylistAssignWizardStateProvider({ children }) {
    const [state, dispatch] = useReducer(playlistAssignWizardReducer, INITIAL_STATE, undefined);
    useDevTool('PlaylistAssignWizard', state);
    const playlists = usePlaylists();
    const { addAssignment } = usePlaylistActions();
    const classes = useClasses();
    const { addStudentAssignments } = useClassActions();
    const addToast = useToast();
    const parent = useParent();

    const loadAssignmentDetails = useCallback(
        (playlist_id, assignment_id, playlist = null) => {
            console.log('load assignment details', playlist_id, playlists);
            if (playlist_id && playlists) {
                // find the playlist
                if (!playlist)
                    playlist = playlists.find((p) => p.id === playlist_id);
                if (playlist) {
                    // find the assignment inside the playlist
                    let assignment = null;
                    if (assignment_id && playlist.assignments) assignment = playlist.assignments.find((assignment) => assignment.id === assignment_id);

                    // format playlist details data to match the store and put it in the store
                    let formattedDetails = {
                        playlistId: playlist.id,
                        assignmentId: assignment?.id ?? null,
                        startDate: assignment?.start ?? null,
                        dueDate: assignment?.due ?? null,
                        goal: assignment?.quiz_score_goal ?? 100,
                        level: assignment?.quiz_level_goal ?? 2,
                        message: assignment?.message_content ?? '',
                        assignedStudents: assignment?.assigned_students ?? [],
                        assignedClasses: assignment?.assigned_classes ?? []
                    };

                    console.log('  format classes:', classes);
                    formattedDetails.classes = classes?.map((cls) => {
                        const assigned = assignment?.assigned_classes?.find((classId) => classId === cls.id);
                        const studentIds = cls.students.filter((value) => assignment?.assigned_students?.includes(value));
                        console.log('    assigned class', cls, !!assigned, studentIds);
                        return {
                            classId: cls.id,
                            name: cls.name,
                            classIcon: cls.display_icon,
                            // assignment selection
                            allStudentsSelected: !!assigned,
                            assignedStudents: studentIds
                        };
                    });
                    dispatch({ type: 'load a playlist details into state', payload: formattedDetails });
                } else {
                    console.error('Error: calling loadAssignmentDetails with playlist id not found', playlist_id);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, playlists]
    );

    // save assignment
    const [{ loading: isSaving }, saveAssignment] = useAxios({ url: '/api/assignments/', method: 'POST' }, { manual: true });
    const save = useCallback(
        (publish) => {
            console.log('Saving assignment: ', state);
            const payload = {
                publish: publish ?? false,
                // id = UUIDField(required=False)
                // playlistId = CharField(required=False)
                playlistId: state.playlistId ?? state.playlist?.id,
                // message = CharField(required=False)
                message: state.message,
                // startDate = DateTimeField(required=False)
                startDate: state.startDate,
                // dueDate = DateTimeField(required=False)
                dueDate: state.dueDate,
                // goalLevel = IntegerField(required=False)
                goalLevel: state.level,
                // goalScore = IntegerField(required=False)
                goalScore: state.goal
                // classes = ListField(child=UUIDField(), required=False)
                // classes: state.classes.map((cls) => cls.classId)
                // students = ListField(child=UUIDField(), required=False)
            };

            // get ids of classes & students
            payload.classes = state.classes.filter((cls) => cls.allStudentsSelected).map((cls) => cls.classId);
            payload.students = state.classes.filter((cls) => !cls.allStudentsSelected).reduce((students, cls) => students.concat(cls.assignedStudents), []);
            console.log('Assignment payload', payload);

            // create or update the assignment
            if (state.assignmentId) {
                // update existing assignment
                console.log('Saving existing assignment', state);
                return saveAssignment({ url: `/api/assignments/${state.assignmentId}/`, method: 'patch', data: payload })
                    .then(({ data }) => {
                        // save the assignment and student instances
                        console.log('Saved assignment', data);
                        const { assignment, instances } = data;
                        // update the assignment in the state
                        addAssignment(data);
                        console.log('NEW ASSIGNMENT: ', assignment);
                        console.log('NEW INSTANCES: ', instances);
                    })
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });
            } else {
                // create new assignment
                return saveAssignment({ data: payload })
                    .then(({ data }) => {
                        const { assignment, instances } = data;

                        // add the assignment to the assignment state
                        addAssignment(assignment);

                        // add the student instances to the student state
                        addStudentAssignments(instances);

                        // notify the playlist has been assigned
                        addToast('Success!', `Your playlist was ${parent ? 'shared' : 'assigned'}.`);

                        // return the playlist
                        return data;
                    })
                    .catch((err) => {
                        console.error(err);
                        throw err;
                    });
            }
        },
        [addAssignment, addStudentAssignments, addToast, parent, saveAssignment, state]
    );

    const reset = (playlistId = null) => {
        resetState(dispatch);
        if (playlistId) {
            dispatch({ type: 'set playlist id', payload: playlistId });
        }
    };

    const playlistState = [state, dispatch, loadAssignmentDetails, save, reset];

    return <LocalStateContext.Provider value={playlistState} displayName='PlaylistWizardContext'>{children}</LocalStateContext.Provider>;
}

function usePlaylistAssignWizardState() {
    const context = useContext(LocalStateContext);
    if (!context) {
        // this is for student
        // console.error('usePlaylistAssignWizardState must be called inside PlaylistAssignWizardStateProvider');
        return [null, null, null, null, null];
    }
    return context;
}

export {
    PlaylistAssignWizardStateProvider,
    usePlaylistAssignWizardState,
    selectStudentsOrClass,
    selectStartDate,
    selectDueDate,
    selectRecordLevel,
    selectGoal,
    addMessage,
    resetState
};
