import React, { useMemo, useState, useEffect } from 'react';
import tw, { css } from 'twin.macro';
import { Helmet } from 'react-helmet';
import { Navigate, useMatch, useNavigate, useParams, useRoutes } from 'react-router-dom';
import { useVideoStats, useVideosWithStats, useStudentStats } from 'state/Student/VideoStats';
import { useMedia } from 'react-use';
import { TabNavigator, StatsOverViewCard, ListBox } from 'components';
import { useLocation } from 'react-router-dom';
import { useParent, useUser } from 'state/User/UserState';
import { useClassStudents } from 'state/Teacher/ClassState';
import { useVideos } from 'state/CatalogState';
import { useProduct } from 'state/User/UserState';
import { useActivity, useActivityForUser } from '../../../../state/User/ActivityState';
import { MyVideoStats, MyRecords, MyAchievements } from './tabs';

const ProgressContainer = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const location = useLocation();
    const videos = useVideosWithStats();
    const stats = useVideoStats();
    const isPhone = useMedia('(max-width: 1280px)');
    const { activity, downloadActivity } = useActivity();
    const { students } = useClassStudents(null);
    const { is_parent } = useUser();
    const timesTableProduct = useProduct('times-tables');

    // parent routes
    const matchNoTab = useMatch('/stats/:student_id');
    const match = useMatch('/stats/:student_id/:tab_name');

    // const { student_id, tab_name } = match?.params ?? {};
    const { student_id, tab_name } = useMemo(() => {

        // TODO: this whole route system is backwards - the user_id should be the last param, otherwise it's making this more difficult than it needs to be
        if( is_parent ) {
            // the parent routes are different than the child routes
            // the parent routes have a student_id in the url
            if (match) {
                return match.params;
            }
            if (matchNoTab) {
                return { student_id: matchNoTab.params.student_id, tab_name: 'my-video-stats' };
            }
            // need to add a default route here
            return { student_id: null, tab_name: 'my-video-stats' };
        } else {
            if (matchNoTab) {
                return { tab_name: matchNoTab.params.student_id, student_id: null };
            }
            if (match) {
                // return match.params;
                return { tab_name: matchNoTab.params.student_id, student_id: null };
            }
            return { student_id: null, tab_name: 'my-video-stats' };
        }
    }, [is_parent, match, matchNoTab]);

    const navigate = useNavigate();
    const parent = useParent();
    const childStats = useStudentStats(student_id);
    const studentActivity = useActivityForUser(student_id);


    const childAchievements = studentActivity?.filter((a) => a.name === 'achievement:created') ?? [];


    const allVideos = useVideos();
    const listBoxItems = useMemo(
        () =>
            [...students]
                .sort((a, b) => a.user.first_name.localeCompare(b.user.first_name))
                .map((student) => ({
                    name: student.user.first_name,
                    id: student.user.id,
                    item: student
                })),
        [students]
    );

    let showListBox = listBoxItems.length > 1;

    useEffect(() => {
        downloadActivity();
    }, []);

    useEffect(() => {
        if (parent && !student_id && listBoxItems?.length > 0) {
            const first_id = listBoxItems[0].id;
            navigate(`/stats/${first_id}/my-video-stats`, { replace: true });
        }
    }, [listBoxItems, navigate, parent, student_id]);

    useEffect(() => {
        setSelectedStudent(students.find(s => s.user.id.toString() === student_id));
    }, [student_id, students]);

    // filter stats for only unlocked videos
    const unlockedStats = useMemo(() => Object.values(stats ?? {}).filter((stat) => stat.current_level > 0), [stats]);

    // filter stats for only unlocked videos for a child
    const unlockedChildStats = useMemo(() => Object.values(childStats ?? {}).filter((stat) => stat.current_level > 0), [childStats]);

    // filter videos that are unlocked
    const unlockedVideos = useMemo(() => {
        return videos.reduce((acc, video) => {
            if (unlockedStats?.find((stat) => stat.video_id === video.id))
                return [...acc, video];
            return acc;
        }, []);
    }, [videos, unlockedStats]);

    // filter videos that are unlocked and add the stats that match that video for a child
    const unlockedChildVideos = useMemo(() => {
        return videos.reduce((acc, video) => {
            if (unlockedChildStats)
                unlockedChildStats.forEach((stat) => {
                    if (stat.video_id === video.id)
                        acc.push({
                            ...video,
                            stats: childStats ? childStats.find((s) => s.video_id === video.id) ?? null : null
                        });
                });
            return acc;
        }, []);
    }, [childStats, unlockedChildStats, videos]);



    const items = useMemo(() => [
        {
            name: 'My Video Stats',
            path: 'my-video-stats',
            component: <MyVideoStats tableData={unlockedVideos} activity={activity} stats={unlockedStats} timesTableProduct={timesTableProduct}/>
        },
        {
            name: 'My Records',
            path: 'my-records',
            component: <MyRecords videos={videos} activity={activity} stats={unlockedStats} timesTableProduct={timesTableProduct}/>
        },
        {
            name: 'My Achievements',
            path: 'my-achievements',
            component: <MyAchievements videos={videos} activity={activity} timesTableProduct={timesTableProduct}/>
        }
    ], [activity, timesTableProduct, unlockedStats, unlockedVideos, videos]);

    const parentItems = useMemo(() => [
        {
            name: 'Video Stats',
            path: 'my-video-stats',
            component: <MyVideoStats
                tableData={unlockedChildVideos} activity={studentActivity ?? []}
                stats={unlockedChildStats} parent={parent} selectedStudent={selectedStudent} />
        },
        {
            name: 'Records',
            path: 'my-records',
            component: <MyRecords
                videos={unlockedChildVideos} activity={studentActivity ?? []}
                stats={unlockedChildStats} selectedStudent={selectedStudent} />
        },
        {
            name: 'Achievements',
            path: 'my-achievements',
            component: <MyAchievements
                videos={unlockedChildVideos} activity={studentActivity ?? []} childAchievements={childAchievements} parent={parent}
                selectedStudent={selectedStudent} allVideos={allVideos} />
        }
    ], [allVideos, childAchievements, parent, selectedStudent, studentActivity, unlockedChildStats, unlockedChildVideos]);


    const parentRoutes = useRoutes([
        ...parentItems.map((item) => ({
            path: `:student_id/${item.path}`,
            element: item.component
        })), { path: '*', element: <Navigate to={`${student_id}/my-video-stats`} /> }]);

    const routes = useRoutes([
        ...items.map((item) => ({
            path: item.path,
            element: item.component
        })), { path: '*', element: <Navigate to='my-video-stats' /> }]);

    const fullItems = useMemo(() => {
        if (parent) {
            return parentItems.map(item => {
                return {
                    ...item,
                    path: `/stats/${student_id}/${item.path}`
                };
            });
        }
        return items;
    }, [items, parent, parentItems, student_id]);

    useEffect(() => {
        const currentPath = parent ? location.pathname : location.pathname.split('/')[2]; // Get the current path
        const tabIndex = fullItems.findIndex(item => item.path === currentPath); // Find the index of the current path in the items array
        setActiveTab(tabIndex !== -1 ? tabIndex : 0); // If the path is found, set the activeTab to its index, otherwise set it to 0
    }, [location, items, parent, fullItems]);

    return (
        <div tw='h-full px-0 sm:px-6 py-6 sm:py-10 bg-[#1C0057] font-Poppins' css={[parent && tw`sm:py-1 sm:pb-6`]}>
            <Helmet>
                <title>My Stats</title>
            </Helmet>
            {parent &&
                <div tw='flex flex-row justify-end pb-10 w-full'>
                    <div>
                        {showListBox ? (
                            <div tw='maxWidth[300px] mt-2.5 mr-3 md:mr-0'>
                                <ListBox
                                    items={listBoxItems}
                                    onChange={(value) => navigate(`/stats/${value.id}/${tab_name ?? ''}`, { replace: true })}
                                    selected={listBoxItems.find((s) => s.id.toString() === student_id)}
                                    // initialSelected={defaultSelected}
                                    variant='transparent-small'
                                    pushHistory={true}
                                />
                            </div>
                        ) : (
                            <div tw='text-2xl text-white'>{listBoxItems[0]?.name}</div>
                        )}
                    </div>
                </div>
            }

            <div tw=' max-w-full 2xl:[max-width:var(--layout-width)] mx-auto flex gap-8'>
                {!isPhone ? (
                    <div tw='w-full max-w-[300px]'>
                        <StatsOverViewCard
                            stats={parent ? unlockedChildStats : unlockedStats}
                            activity={parent ? childAchievements ?? [] : activity}
                            childName={selectedStudent?.user.first_name} parent={parent} />

                    </div>
                ) : null}
                <div tw='w-full ml-2'>
                    <TabNavigator
                        activeTab={activeTab} activeTabHandler={setActiveTab} variant='dtc-child'
                        items={fullItems} parent={parent} />
                    {parent ? parentRoutes : routes}
                </div>
            </div>
        </div>
    );
};

export default ProgressContainer;