import React, { useMemo } from 'react';
import RichText from 'components/RichText.js';
import tw, { css } from 'twin.macro';
import AudioButton from 'components/AudioButton/AudioButton.js';
import { QUESTION_TYPE_MULTIPLE_CHOICE, QUESTION_TYPE_MULTIPLE_SELECT } from '../../../../../modules/quiz/constants';

const QuestionContents = ({ audioSample, children, question, questionIndex, style, hideAudioButton }) => {
    // a question can be several things, collect them in an array
    const questionNodes = useMemo(() => {
        if (!question) return null;

        let questionNodes = [];

        // a question might contain text
        let questionText = question.question_text ?? 'no question text';
        if (questionIndex !== null && typeof questionIndex !== 'undefined') {
            // attach a question number
            const questionNumber = questionIndex + 1;
            // questionText = `${questionNumber}. ${question.question_text}`;
            questionNodes.push(
                <div key={`number-${question.id}`} className='question-text'>
                    <div tw='flex flex-row text-left gap-3 relative' style={{ display: 'flex', flexDirection: 'row' }}>
                        <span
                            tw='text-base sm:text-2xl text-center flex items-center justify-center relative w-[40px] '>
                            {`${questionNumber}.`}
                        </span>

                        <RichText text={question.question_text} />

                        <span tw='flex items-center relative w-[40px] '>
                            <div tw='absolute w-[40px] right-[0px] top-0 bottom-0 flex items-center'>

                                {!hideAudioButton && <AudioButton
                                    tabIndex={1}
                                    tw='mx-auto text-[#dddddd] hover:text-[#ffffff] active:text-[#cccccc]'
                                    className='sound-button'
                                    audioSample={audioSample}
                                />}
                            </div>
                        </span>


                    </div>
                </div>
            );
        } else {
            // add the question text
            questionNodes.push(<RichText
                key={`richtext-${question.id}`} className='question-text'
                text={question.question_text} />);
        }

        // a question might contain an image
        let image_url = question.image_url;
        if (image_url) {
            const imageNode = (
                <img
                    key={`image-${question.id}`}
                    src={image_url}
                    // style={{ maxHeight: '250px', maxWidth: '60%', margin: 'auto', marginBottom: '10px', marginTop: '10px' }}
                    // tw='bg-white p-1.5 height[auto] width[auto] maxWidth[100%] margin[16px auto]'
                    tw='bg-white p-1.5 height[auto] width[auto] maxWidth[100%] margin[16px auto]'
                    css={[
                        tw`max-w-[700px]`,
                        tw`max-h-[160px] sm:max-h-[260px]`
                    ]}
                    alt='question diagram'
                    // style={{
                    //     maxHeight: '160px',
                    //     maxWidth: '700px'
                    // }}
                />
            );
            questionNodes.push(imageNode);
        }

        // instructions
        let instructions = question.instruction_text;
        if (!instructions) {
            if (question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
                instructions = '(Select all options that apply.)';
                // } else if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE) {
                //     instructions = '';
            }
        }

        // instructions = '(use the number lines below)';

        if (instructions)
            questionNodes.push(
                <div
                    key={`instruction_text-${question.id}`}
                    className='question-instructions'
                    tw='mt-2 text-[15px] sm:text-xl font-normal text-[#C1C1C1]'
                >
                    {instructions}
                </div>
            );
        return questionNodes;
    }, [audioSample, hideAudioButton, question, questionIndex]);

    // wrap them - return all or nothing
    if (questionNodes?.length) {
        return (
            <div
                tw='top-0 relative flex flex-col bg-[transparent] px-2.5 sm:px-8 flex-grow items-center text-center z-10 pb-6'
                className='question-contents'
                // style={style}
                // css={[
                //     audioSample &&
                //     css`
                //         padding-left: 42px;
                //     `
                // ]}

            >
                <div tw='flex flex-grow relative'>
                    <div tw='relative flex items-center justify-center flex-wrap flex-col '>
                        {questionNodes}
                    </div>
                </div>

                {children}
            </div>
        );
    }
    return null;
};

export default QuestionContents;

