import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import moment from 'moment/moment';
import { compareDates, toJS } from 'utils';
import {
    filterClosestOverDueAssignments,
    filterPastDueAssignments,
    filterPastDueCompletedAssignments,
    filterRecentlyOverDueAssignments,
    filterUpcomingAssignments,
    filterUpcomingCompletedAssignments,
    getAssignmentsAndVideos
} from 'app/model/muzology';

/**
 * @module selectors
 *
 */

/////////////////////////////////////////////////////////////////////////////////////
// getters
////////////////////////////////////////////////////////////////////////////////////

// app items
export const getApp = (state) => state.getIn(['app']);
export const getSettings = (state) => state.getIn(['settings']);
export const getConfig = (state) => state.getIn(['config']);
export const getFeatures = (state) => state.getIn(['features']);

// current user
export const getProfile = (state) => state.getIn(['user']);

export const getUser = (state) => state.getIn(['user', 'user']);

export const getStudent = (state) => state.getIn(['user', 'student']);
export const getTeacher = (state) => state.getIn(['user', 'instructor']);

export const getAccount = (state) => state.getIn(['user', 'account']);
export const getProgram = (state) => state.getIn(['user', 'program']);

export const getMainCourseID = (state) => state.getIn(['user', 'main_course_id']);

// notifications
export const getNotifications = (state) => state.getIn(['notifications'], List());

////////////
// catalog

// videos
export const getVideos = (state) => state.getIn(['catalog', 'videos'], Map());
export const getVideo = (state, video_id) => state.getIn(['catalog', 'videos', video_id]);

// catalog playlists
export const getPlaylists = (state) => state.getIn(['catalog', 'playlists']);

// courses
export const getCourse = (state, { course_id }) => state.getIn(['catalog', 'courses', course_id]);

// quizzes
export const getQuiz = (state, quiz_id) => state.getIn(['quiz', 'quizzes', quiz_id], null);

// video standards
export const getVideoStandards = (state) => state.getIn(['user_data', 'standards'], Map());

////////////
// stats

// video stats
export const getAllVideoStats = (state) => state.getIn(['stats', 'video_stats'], Map());
export const getVideoStats = (state, video_id) => state.getIn(['stats', 'video_stats', video_id], null);
export const getVideoFavoriteState = (state, video_id) => state.getIn(['stats', 'video_stats', video_id, 'favorite'], false);

////////////
// students

// student assignments
export const getAssignments = (state) => state.getIn(['user_data', 'assignments'], Map());

// achievements
export const getBadges = (state) => state.getIn(['stats', 'badges'], Map());

////////////
// teachers

// assigned playlists
export const getAssignedPlaylists = (state) => state.getIn(['instructor', 'playlists'], Map());

// students
export const getStudents = (state) => state.getIn(['user_data', 'students'], Map());

// user states
export const getUserStates = (state) => state.getIn(['user_data', 'user_states'], Map());

// classes
export const getClasses = (state) => state.getIn(['instructor', 'classes'], Map()).toList();
export const getClass = (state, { classId }) => state.getIn(['instructor', 'classes', classId], null);

/////////////////////////////////////////////////////////////////////////////
// app selectors

export const getAppSelector = createSelector([getApp], (val) => toJS(val));
export const getSettingsSelector = createSelector([getSettings], (val) => toJS(val));
export const getConfigSelector = createSelector([getConfig], (val) => toJS(val));
export const getFeaturesSelector = createSelector([getFeatures], (val) => toJS(val));

/**
 * @function
 * @name getUserSelector
 * @memberof module:selectors
 * @description
 * get the current user
 */

export const getUserSelector = createSelector([getUser], (user) => toJS(user));

/**
 * @function
 * @name getStudentSelector
 * @description
 * get the current user profile
 *
 * @returns
 *
 */

export const getProfileSelector = createSelector([getProfile], (profile) => toJS(profile));

/**
 * @function
 * @name getStudentSelector
 * @description
 * get the current student profile
 *
 */

export const getStudentSelector = createSelector([getStudent], (student) => toJS(student));

/**
 * @function
 * @name getStudentSelector
 * @description
 * get the current teacher profile
 */

export const getTeacherSelector = createSelector([getTeacher], (teacher) => toJS(teacher));

/////////////////////////////////////////////////////////////////////////////
export const getNotificationsSelector = createSelector([getNotifications], (notifications) => notifications.toJS());

/////////////////////////////////////////////////////////////////////////////
// videos

// videos as a js array
export const getVideosListSelector = createSelector([getVideos], (videos) => (videos ? videos.toList().toJS() : null));

export const getVideoSelector = createSelector([getVideos], (videos) => {
    return videos.toList().toJS();
});

export const getSingleVideoSelector = createSelector([getVideo], (video) => {
    if (video) return video.toJS();
    return null;
});

// all videos as a js object
export const getVideoMapSelector = createSelector([getVideos], (videos) => {
    return videos.toJS();
});

// quizzing
export const getQuizSelector = createSelector([getQuiz], (quiz) => toJS(quiz));

export const getVideoForQuiz = createSelector([getVideosListSelector, (_, props) => props.quiz_id], (videos, quiz_id) => {
    // console.log('getVideoForQuiz ', props.video_id);
    let video = videos.toJS().find((video) => video.quiz_id === quiz_id);
    if (video) return video.id;
    return null;
});

////////////////////////////////////////////////////////////
// stats

export const getSingleVideoStatsSelector = createSelector([getVideoStats], (stats) => toJS(stats));

export const getVideoFavoriteSelector = createSelector([getAllVideoStats], (stats) => (stats ? stats.get('favorite') : false));

export const selectRecentBadges = createSelector([getBadges], (badges) => {
    // console.log('badges', badges);
    // return badges.toJS();
    return badges.sort((a, b) => compareDates(Date.parse(a.datetime_awarded), Date.parse(b.datetime_awarded)));
    // return toJS(categories);
});

export const getAcheivementsSelector = createSelector([getAllVideoStats], (stats) => {
    let list = [];
    stats
        .toList()
        .toJS()
        .forEach((item) => {
            if (item.achievements.length) {
                item.achievements.map((item) => list.push(item));
            }
        });
    return list;
});

export const getVideoStatsSelector = createSelector([getAllVideoStats], (stats) => {
    return stats.toJS();
});

//////////////////////////////////////////////////////
//Records
export const getRecordsSelector = createSelector([getAllVideoStats], (stats) => {
    return stats.toList().toJS();
});

////////////////////////////////////////////////////////////
// videos and stats

export const getStatsListSelector = createSelector([getAllVideoStats], (stats) => {
    return stats.toList().toJS();
});

export const getStatsMapSelector = createSelector([getAllVideoStats], (stats) => {
    return stats.toJS();
});

const reduceVideoStats = (videos, stats) => {
    return videos.reduce((all, video) => {
        if (!video) return all;
        const video_id = video.get('id');
        return all.set(video_id, video.set('stats', stats.get(video_id)));
    }, Map());
};

// get all video as a js object with stats attached
export const getVideosAndStatsSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
    return reduceVideoStats(videos, stats).toJS();
});

export const getVideosAndStatsListSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
    return reduceVideoStats(videos, stats).toList().toJS();
});

export const getOverDueVideosAndStatsListSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
    return reduceVideoStats(videos, stats).toList().toJS();
});

// get a video as a js object with stats attached
export const getVideoAndStatsSelector = createSelector([getVideo, getAllVideoStats], (video, stats) => {
    if (video) {
        const video_id = video.get('id');
        return video.set('stats', stats.get(video_id, null)).toJS();
    }
    return null;
});

/////////////////////////////////////////////////////////////////////////////
// courses

export const getMainCourse = (state) => getCourse(state, { course_id: getMainCourseID(state) });

export const getMainCourseAndVideosSelector = createSelector([getMainCourse, getPlaylists, getVideosAndStatsSelector], (courseSource, playlists, videos) => {
    // console.log('getMainCourseAndVideosSelector', courseSource, playlists, videos);

    // get the course and convert it to json
    if (!courseSource) return null;
    let course = courseSource.toJS();

    if (course.playlists && playlists) {
        // convert playlist IDs to real playlists
        course.playlists = course.playlists.map((playlist_id) => {
            let playlist = playlists.get(playlist_id, Map()).toJS();

            playlist.name = playlist.title;

            if (playlist.videos) {
                playlist.videos = playlist.videos.map((video_id) => {
                    return videos[video_id];
                });
            }

            return playlist;
        });
    }

    return course;
});

////////////////////////////////////////////////////////////
// assignment selectors - student assignments
////////////////////////////////////////////////////////////

// this attaches a .videos list to each assignment
export const getAssignmentsSelector = createSelector([getAssignments, getVideosAndStatsSelector], getAssignmentsAndVideos);

// videos past due
export const getPastDueSelector = createSelector([getAssignmentsSelector], filterPastDueAssignments);

//////////////////////
// assignments accessors
//////////////////////

// a list of all assignments
export const getAssignmentsListSelector = createSelector([getAssignmentsSelector], (assignments) => {
    return assignments.toList().toJS();
});

// recently overdue list
export const getRecentlyOverDueAssignmentsSelector = createSelector([getAssignmentsListSelector], filterRecentlyOverDueAssignments);

// most recent overdue assignment
export const getClosestOverDueAssignmentSelector = createSelector([getAssignmentsListSelector], filterClosestOverDueAssignments);

// most recent upcoming assignment
export const getUpcomingAssignmentSelector = createSelector([getAssignmentsListSelector], filterUpcomingAssignments);

// most recent completed assignment
export const getUpcomingCompletedAssignmentSelector = createSelector([getAssignmentsListSelector], filterUpcomingCompletedAssignments);

// most recent past-due completed assignment
export const getPastDueCompletedAssignmentSelector = createSelector([getAssignmentsListSelector], filterPastDueCompletedAssignments);

//////////////////////
// video standards

export const videoStandardsSelector = createSelector([getVideoStandards], (standards) => toJS(standards));

////////////////////////////////////////////////////////////////////////////////////
// videos

// this returns a map of video IDs to lists of assignments
export const videoAssignmentsSelector = createSelector([getAssignments, getVideos], (assignments, videos) => {
    if (assignments && videos) {
        assignments = assignments.toList().toJS();
        // console.log("reducing video assignment", assignments, videos);
        return assignments.reduce((assigned_videos, assignment) => {
            // console.log('all 1', all);
            // console.log("reduce assignment", assignment);
            if (assignment.items)
                return assignment.items.reduce((all, item) => {
                    let video_id = item;
                    const data = {
                        video_id: video_id,
                        assignment: assignment
                        // item: { video_id: video_id, index: 1 }
                    };

                    if (!(video_id in all)) all[video_id] = [data];
                    else all[video_id].push(data);
                    return all;
                }, assigned_videos);
            // console.log('All Videos:', all);
            return assigned_videos;
        }, {});
    }
});

export const getVideoWithStatsAndAssignmentsSelector = createSelector([getVideoAndStatsSelector, videoAssignmentsSelector], (video, assignments) => {
    if (video) {
        video.assignment = assignments[video.id] || null;
    }
    return video;
});

export const getOverDueVideoAssignmentsSelector = createSelector([getRecentlyOverDueAssignmentsSelector, getVideos], (assignments, videos) => {
    if (assignments && videos) {
        return assignments.reduce((assigned_videos, assignment) => {
            if (assignment.items)
                return assignment.items.reduce((all, item) => {
                    let video_id = item['video_id'];

                    const data = {
                        video_id,
                        assignment: assignment,
                        item: item
                    };

                    if (!(video_id in all)) all[video_id] = [data];
                    else all[video_id].push(data);
                    return all;
                }, assigned_videos);
            return assigned_videos;
        }, {});
    }
});

// videos with everything
//  - stats
//  - assignments
//  - standards

export const getVideosWithStatsAndAssignmentsSelector = createSelector(
    [getVideosAndStatsSelector, videoAssignmentsSelector, videoStandardsSelector],
    (videos, assignments, standards) => {
        if (videos && assignments) {
            Object.keys(videos).forEach(video_id => videos[video_id].assignment = assignments[video_id] ?? null);
        }
        return videos;
    }
);

export const getOverDueVideosWithStatsAndAssignmentsSelector = createSelector(
    [getVideosAndStatsSelector, getOverDueVideoAssignmentsSelector],
    (videos, assignments) => {
        if (videos && assignments) {
            // attach assignments to each video
            Object.keys(videos).forEach(video_id => videos[video_id].assignment = assignments[video_id] ?? null);
        }
        return videos;
    }
);

// 'recently watched' - this returns a list of videos with a 'last_viewed' date within 14 days of today
export const getRecentlyWatchedSelector = createSelector([getVideosWithStatsAndAssignmentsSelector], (videos) => {
    let today = moment();
    return Object.values(videos ?? {}).filter((video) => {
        if (video.stats && video.stats.last_viewed) {
            let last_view = moment(video.stats.last_viewed.toString(), 'YYYYMMDD');
            last_view = today.diff(last_view, 'days');
            const two_weeks_ago = 14;
            return last_view < two_weeks_ago;
        }
        return false;
    });
});

// 'assigned videos' this returns a list of videos which have assignments
export const getAssignedVideosSelector = createSelector([getVideosWithStatsAndAssignmentsSelector], (videos) => {
    return Object.values(videos || {}).filter((video) => !!video.assignment);
});

// 'favorites'
export const getFavoritesSelector = createSelector([getVideosWithStatsAndAssignmentsSelector], (videos) => {
    return Object.values(videos || {}).filter((video) => !!(video.stats && video.stats.favorite));
});

// 'needs improvement'
export const needsImprovementSelector = createSelector([getVideosWithStatsAndAssignmentsSelector], (videos) => {
    return Object.values(videos || {});
});

// 'my strengths'
export const strengthSelector = createSelector([getVideosWithStatsAndAssignmentsSelector], (videos) => {
    return Object.values(videos || {});
});

// 'assigned videos' on home page for student
export const getAssignedVidsHomePage = createSelector(
    [getVideosWithStatsAndAssignmentsSelector, getOverDueVideosWithStatsAndAssignmentsSelector],
    (videos, overDueVideos) => Object.values(videos || {}).filter((video) => !!video.assignment)
);

export const getHomePageCurrentAssignmentSelector = createSelector(
    [getUpcomingAssignmentSelector, getClosestOverDueAssignmentSelector, getUpcomingCompletedAssignmentSelector, getPastDueCompletedAssignmentSelector],
    (upComingAssignment, closestOverDueAssignment, upcomingCompletedAssignment, pastDueCompletedAssignments) => {
        if (upComingAssignment) {
            return upComingAssignment;
        } else if (closestOverDueAssignment) {
            return closestOverDueAssignment;
        } else if (upcomingCompletedAssignment) {
            return upcomingCompletedAssignment;
        } else if (pastDueCompletedAssignments) {
            return pastDueCompletedAssignments;
        } else {
            return null;
        }
    }
);

////////////////////////////////////////////////////////////////////////////////////
// Teacher selectors

///////////
// assigned playlists

// assignments for a teacher for a given class
export const getAssignedAssignmentsForClass = (state, { classId }) =>
    getAssignedPlaylists(state).filter((assignment) => assignment.get('assigned_classes', List()).includes(classId));

export const getAssignedPlaylistsSelector = createSelector([getAssignedPlaylists], (assignments) => {
    return assignments.toList().toJS();
});

export const getAssignedPlaylistsDueThisWeek = createSelector([getAssignedPlaylistsSelector], (assignments) => {
    // let today = moment.utc().local();
    return assignments;
});

//////////////
// students

export const getStudentsSelector = createSelector([getStudents], (students) => {
    // console.log('getStudentsSelector', students.toJS());
    return students.toJS();
});
// a list of all students
export const getStudentsListSelector = createSelector([getStudents], (students) => students.toList().toJS());

export const getStudentsWithStateSelector = createSelector([getStudents, getUserStates], (students, states) => {
    if (!students) return null;
    let allStudents = toJS(students);

    let allStates = toJS(states);
    if (allStates) {
        Object.keys(allStudents).forEach((studentId) => {
            let student = allStudents[studentId];
            student.state = allStates[studentId] || null;
            allStudents[studentId] = student;
        });
    }
    return allStudents;
});

//////////////
// classes

export const getClassesSelector = createSelector([getClasses], (classes) => {
    return classes.toJS();
});

export const getClassesWithStudentsListSelector = createSelector([getClasses, getStudents], (classes, students) => {
    if (!classes) return null;
    let allStudents = toJS(students);
    let allClasses = toJS(classes);
    return allClasses.map((cls) => {
        if (cls.students) {
            cls.student_ids = cls.students;
            cls.students = cls.students.map((studentId) => allStudents[studentId] || null).filter((student) => !!student);
        }
        return cls;
    });
});

// returns a list of classes that contains a list of students with stats and state
export const getClassesWithStudentsAndStateListSelector = createSelector([getClasses, getStudentsWithStateSelector], (classes, students) => {
    if (!classes) return null;
    let allClasses = toJS(classes);
    return allClasses.map((cls) => {
        if (cls && cls.students) {
            cls.student_ids = cls.students;
            cls.students = cls.students.map((studentId) => students[studentId] || null).filter((student) => !!student);
        }
        return cls;
    });
});

export const getStudentsForClassListSelector = createSelector([getStudents, getClass], (students, classroom) => {
    let allStudents = toJS(students);

    let cls = toJS(classroom);
    if (cls && cls.students && allStudents) {
        let classStudents = cls.students
            .map((student_id) => {
                return allStudents[student_id] || null;
            })
            .filter((student) => !!student);
        return classStudents;
    }
    return null;
});

////////////////////////////////////////////////////////////////////////////////////
// UI stuff

export const getCategoryItems = (state, { category_type }) => {
    if (category_type && state) return state.getIn(['ui', 'categories', category_type], null);
    return null;
};
export const categoryItemsListSelector = createSelector([getCategoryItems], (categories) => toJS(categories));
export const getCurrentCategoryType = (state) => state.getIn(['ui', 'current_category_type'], null);
export const getCurrentCategoryID = (state) => state.getIn(['ui', 'current_category_id'], null);
export const currentCategoryIDSelector = createSelector([getCurrentCategoryID, getCurrentCategoryType], (id, type) => id);

// category orders
export const getAllCategoryOrders = (state) => state.getIn(['user', 'category_orders'], List());
export const getCategoryOrder = (state, { category_id }) => state.getIn(['user', 'category_orders'], List()).find((category) => category.name === category_id);
export const getCategoryOrderSelector = createSelector([getCategoryOrder], (cat) => toJS(cat));

////////////////////////////////////////////////////////////////////////////////////
// Route Selector

export const getUserRoute = (state) => state.getIn(['user_data', 'user_routes'], Map());

export const getRoutesSelector = createSelector([getUserRoute], (routes) => {
    return routes.toJS();
});

////////////////////////////////////////////////////////////////////////////////////
// research experiment

// experiment context
export const getExperimentContext = (state) => state.getIn(['experimental_context'], null);
export const getExperimentContextSelector = createSelector([getExperimentContext], (context) => toJS(context));

// current sequence
export const getSequence = (state) => state.getIn(['sequence'], null);
export const getSequenceSelector = createSelector([getSequence], (sequence) => toJS(sequence));

////////////////////////////////////////
// experiment admin

export const getExperiments = (state) => state.getIn(['research', 'experiments'], null);
export const getExperimentsSelector = createSelector([getExperiments], (experiments) => toJS(experiments));

// current experiment
export const getExperiment = (state) => state.getIn(['research', 'experiment'], null);
export const getExperimentSubjects = (state) => state.getIn(['research', 'subjects'], null);
export const getExperimentGroups = (state) => state.getIn(['research', 'groups'], null);

export const getExperimentSelector = createSelector([getExperiment], (experiment) => toJS(experiment));
export const getExperimentGroupsSelector = createSelector([getExperimentGroups], (groups) => toJS(groups));

export const getExperimentSubjectsListSelector = createSelector([getExperimentSubjects], (subjects) => (subjects ? subjects.toList().toJS() : null));
