import React, { useCallback, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useQuizSession } from 'hooks/quiz';
import { useQuizVideo, useVideos } from 'state/CatalogState';
import { QuizBackgroundProps, QuizLevelColors } from 'app/containers/shared/QuizSessionContainer/Style';
import {
    QUIZ_STATE_CANCELLED,
    QUIZ_STATE_COMPLETED,
    QUIZ_STATE_CREATE_SESSION,
    QUIZ_STATE_INIT,
    QUIZ_STATE_INSTANCE_LOADED,
    QUIZ_STATE_INSTANCE_LOADING,
    QUIZ_STATE_READY,
    QUIZ_STATE_RUNNING, QUIZ_STATE_RUNNING_BUT_FINISHED
} from 'modules/quiz/constants';
import WhiteXButton from 'images/icons/WhiteXButton';
import { useQuery } from '../../../../hooks/useQuery';
import { useTeacher, useUser } from '../../../../state/User/UserState';
import { useVideoStats, useVideoStatsActions } from '../../../../state/Student/VideoStats';
import { useAssignmentsForVideo } from '../../../../state/Student/AssignmentsState';
import { useDevTool } from '../../../../lib/DevTool/DevTool';
import { useQuizSessionActions } from '../../../../state/Quizzing/QuizSessionProvider';
import QuizIntroContainer from './States/QuizIntroContainer';
import QuizLoading from './States/QuizLoading';
import QuizSession from './States/QuizSession';
import QuizFinished from './States/QuizFinished';
import { QuizSessionPage } from './QuizSessionPage';
import ConfirmQuitDialog from './components/ConfirmQuitDialog';
import { CancelButton } from './components/CancelButton';

const QuizSessionContainer = (props) => {
    const { quiz_id, quiz_level } = props;
    const navigate = useNavigate();
    const query = useQuery();
    const teacher = useTeacher();
    // get the video for this quiz
    const video = useQuizVideo(quiz_id, query.video_id);

    // get the quiz_id from the url & the level from the query string
    // let quiz_id = props.quiz_id ?? params.quiz_id ?? null;
    // let quiz_level = props.quiz_level ?? query.level ?? null;
    // console.log('quiz_level=', quiz_level);
    // let level = parseInt(quiz_level);
    // console.log('level=', level);

    const [showExitMessage, setShowExitMessage] = useState(false);
    let [isOpen, setIsOpen] = useState(false);


    // get the stats & assignments for this video
    const stats = useVideoStats(video?.id);
    const { loadStats } = useVideoStatsActions();
    const assignments = useAssignmentsForVideo(video?.id);

    // get the quiz session
    const { session, instance, progress } = useQuizSession();
    const { initQuizSession, endQuizSession } = useQuizSessionActions();

    // React.useEffect( () => {
    //
    // }, []);
    // console.log('QuizSessionContainer', quiz_id, quiz_level );
    // find the video id
    // let video_id = null;
    // if (quiz_id) {
    //     let video = videos.find((video) => video.quiz_id === quiz_id);
    //     if (video) {
    //         video_id = video.id;
    //     }
    // }
    // console.log('Quiz Video:', video);
    // console.log('Video Stats:', stats);
    // console.log('Video assignments:', assignments);
    // let level = null;
    //
    // // get the level from the url
    // if (quiz_level) {
    //     // quiz_id = params.quiz_id;
    //     level = parseInt(quiz_level);
    //     // console.log('* INIT - URL level: ', quiz_level, level);
    // }

    // get the level from the loaded instance
    // if (instance && instance.instance) {
    //     level = instance.instance.level;
    // }

    // check the level
    // console.log('Teacher:', teacher);
    // console.log('stats:', stats);
    // if( !teacher && stats ) {
    //     if( stats && stats?.current_level <= level ) {
    //         console.log('Current Level: ', stats.current_level );
    //         console.log('Invalid level!');
    //     }
    // }


    // check for invalid level
    let level = quiz_level;
    if (isNaN(level) || level === null || typeof level === 'undefined') {
        console.log('LEVEL NONE:', level);
        // level = 0;
        // if (teacher) {
        //     // a teacher defaults to silver
        //     level = 1;
        //     navigate.replace(`/quiz/${quiz_id}/${level}`);
        // }
        // else {
        //     // TODO: a student should get the current level from the api
        //     // current level
        //     level = stats?.current_level ?? 0;
        //     // navigate.replace(`/quiz/${quiz_id}/${level}`);
        // }
        //
    } else {
        // cap level at 3
        level = Math.min(Math.max(level ?? 0, 0), 3);
        if (!teacher) {
            if (level > (stats?.current_level ?? 0)) {
                // go to the current level
                console.log('WARNING: level > current_level');
                console.log('.   stats:', stats);
                console.log('.   current level:', stats?.current_level);
                console.log('.   level:', level);
            }
        }
    }
    // level = Math.min(Math.max(level ?? 0, 0), 3);

    // check for locked level
    // if (!teacher && level > (stats?.current_level ?? 0)) {
    //     // go to the current level
    //     console.log('WARNING: level > current_level');
    //     navigate.replace(`/quiz/${quiz_id}/${stats?.current_level ?? 0}`);
    // }

    // if (level > 3) {
    //     return <div>Invalid Level: {level}</div>;
    // }

    // console.log('Quiz Session Level = ', level);

    // get the style for this level
    // const bgProps = QuizBackgroundProps[4];
    // console.log('QuizSessionContainer -> bgProps', bgProps);

    // begin the quiz
    // const beginQuiz = useCallback(() => {
    //     console.log('QuizSessionContainer.beginQuiz() level:', level, props.beginQuizSession);
    //     // begin the quiz session - this will get a quiz instance from the server, set parameters and start the first question
    //     let quizConfig = {};
    //     if (props.beginQuizSession) {
    //         console.log('.beginQuizSession() quizConfig:', quizConfig, 'level:', level);
    //         props.beginQuizSession(quizConfig, level);
    //     }
    // }, [level, props]);

    // stop the quiz
    const stopQuizSession = useCallback(() => {
        console.log('stop quiz session');

        // end the quiz session
        endQuizSession();

        // close the modal
        if (props.onClose) {
            console.log('on close');
            props.onClose();
        } else if (video) {
            console.log('push video id:', video.id);
            navigate(`/video/${video.id}`);
        }
        // console.assert(video_id, 'video_id missing');
    }, [endQuizSession, navigate, props, video]);

    // cancel the quiz
    const onCancel = useCallback( () => {
        // unless this is the quiz finished screen, require a confirmation
        if (session?.state !== QUIZ_STATE_COMPLETED) {
            setIsOpen(true);
        } else {
            // need to load stats before we exit
            loadStats();
            navigate(-1);
        }
    }, [loadStats, navigate, session?.state] );

    // render the current session state
    const currentState = useMemo(() => {
        if (!session) {
            console.error('renderState with no quiz session');
            return null;
        }

        let colors = QuizLevelColors[level || 0] || {};

        switch (session.state) {
            case QUIZ_STATE_INIT:
            case QUIZ_STATE_CREATE_SESSION:
            case QUIZ_STATE_INSTANCE_LOADING:
            case QUIZ_STATE_INSTANCE_LOADED: {
                // initial quiz is loading
                // return (
                //     <QuizLoading
                //         level={level}
                //         color={colors.loadingColor}
                //         background={colors.backgroundImage}/>
                // );
                // quiz instance is loading
                // loadingColor = QuizLevelColors[level];
                return (
                    <QuizLoading
                        level={level}
                        color={colors?.loadingColor}
                        background={colors?.backgroundImage}
                        message={!colors && `Invalid quiz level: ${level}`}
                    />
                );
            }

            case QUIZ_STATE_READY:
                // quiz is loaded, but not started, waiting on the user to click "begin"
                // const { video, onCancel, quizSession, level, colors, setOpen, hideProgressHeader } = props;
                return (
                    <QuizIntroContainer
                        video={props.video}
                        onCancel={onCancel}
                        quizSession={session}
                        level={level}
                        colors={colors}
                        setOpen={props.setOpen}
                        hideProgressHeader={true}
                        teacher={teacher}
                        stats={stats}
                        // unused?
                        quizInstance={instance}
                        assignments={assignments}
                        progress={progress}
                    />
                );
            case QUIZ_STATE_RUNNING:
            case QUIZ_STATE_RUNNING_BUT_FINISHED:
                // quiz is running normally
                return (
                    <QuizSession
                        quizSession={session}
                        colors={colors}
                        onCancel={onCancel}
                        setOpen={props.setOpen}
                    />
                );

            case QUIZ_STATE_COMPLETED: // quiz is finished
            case QUIZ_STATE_CANCELLED: // quiz was cancelled by the user
                return (
                    <QuizFinished
                        quizSession={session}
                        colors={colors}
                        quiz_id={quiz_id}
                        video_id={props.video_id}
                        video={props.video}

                        initQuizSession={initQuizSession}
                        endQuizSession={stopQuizSession}
                        cancelled={session.state === QUIZ_STATE_CANCELLED}
                        hideProgressHeader={true}
                    />
                );

            default:
                // unknown state
                console.error('unknown state', session.state);
                return <h1>Quiz session state: {session.state}</h1>;
        }
    }, [assignments, initQuizSession, instance, level, onCancel, progress, props.setOpen, props.video, props.video_id, quiz_id, session, stats, stopQuizSession, teacher]);

    // const QuizBackground = (props) => {
    //     return <div>{props.children}</div>;
    // };
    // console.log('state:', session?.state);

    return (
        <QuizSessionPage
            className='quiz-session-page'
            //{...bgProps}
            css={[props.quiz_level >= 1 && tw`bg-QuizBackground`]}
        >
            <ConfirmQuitDialog
                open={isOpen}
                isOpen={setIsOpen}
                onYes={() => {
                    console.log('GO BACK');
                    if (session?.state !== QUIZ_STATE_COMPLETED) {
                        // need to load stats before we exit
                        loadStats();
                        navigate(-1);
                    } else {
                        navigate(-1);
                    }
                }}
                onNo={() => setIsOpen(false)}
            />

            <div tw='relative'>
                {currentState}
                <CancelButton
                    tabIndex={100}
                    tw='absolute top[12px] right[15px] w-8 h-8  sm:w-12 sm:h-12'
                    className='quiz-cancel-button'
                    onClick={onCancel}
                    aria-label='Cancel Quiz'
                    test-id='cancel-quiz-button'
                >
                    <WhiteXButton style={{ color: '#6A7277' }} />
                </CancelButton>
            </div>

        </QuizSessionPage>
    );
};

export default QuizSessionContainer;
