import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { useUser } from 'state/User/UserState';

const CompletedAssignments = ({ assignments }) => {
    const user = useUser();
    let isChild = user.is_parent_account;

    let totalAssignments = assignments.length;
    let totalCompletedAssignments = assignments.filter((assignment) => assignment.progress?.completed === true).length;
    return (
        <div tw='bg-PicoteeBlue text-white rounded-lg font-sans px-6 pt-6 pb-4 text-center'>
            <div tw='text-xl xl:text-3xl font-extrabold'>
                {totalCompletedAssignments} of {totalAssignments}
            </div>
            <div tw=' text-base xl:text-lg mt-1'>Completed {isChild ? 'Playlists' : 'Assignments'}</div>
        </div>
    );
};

CompletedAssignments.propTypes = {
    anyProp: PropTypes.any
};

export default CompletedAssignments;
