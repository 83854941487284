import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { catalog_math_v1 } from '../../config';
import { useAccount } from '../../state/User/UserState';

import { withScrollLayout } from '../layout/Layouts';
import { Error404Page } from '../../components';
import PlaylistsContainer from '../containers/teacher/Playlists/PlaylistsContainer';
import TeacherHomeContainer from '../containers/teacher/TeacherHomeContainer/TeacherHomeContainer';
import TeacherHomeContainerV1 from '../containers/teacher/TeacherHomeContainer/TeacherHomeContainerV1';
import VideoSessionContainer from '../containers/shared/VideoSessionContainer/VideoSessionContainer';
import ClassesContainer from '../containers/teacher/ClassesContainer/ClassesContainer';
import TeacherAwardsContainer from '../containers/teacher/TeacherAwardsContainer/TeacherAwardsContainer';
import StudentDashboard from '../containers/teacher/ClassesContainer/StudentDashboard';
import QuizSession from '../containers/child/QuizSessionContainer/QuizSession';


const TeacherApp = () => {

    // if they are not on course v2, show the old teacher home page
    const account = useAccount();

    let HomeContainer = TeacherHomeContainer;
    if (account?.course_library_id === catalog_math_v1) {
        HomeContainer = TeacherHomeContainerV1;
    }

    return (
        <Routes>
            <Route path='*' element={<Error404Page />} />
            <Route path='/quiz/:quiz_id' element={withScrollLayout(QuizSession)} />

            <Route path='/' element={withScrollLayout(HomeContainer)} />

            <Route path='/video/:video_id' element={withScrollLayout(VideoSessionContainer)} />

            <Route path='/classes/:class_id?/*' element={withScrollLayout(ClassesContainer)} />

            <Route path='/assignments/:playlist_id?' element={withScrollLayout(PlaylistsContainer)} />

            <Route path='/student/:student_id/*' element={withScrollLayout(StudentDashboard)} />

            <Route path='/awards' exact={true} element={withScrollLayout(TeacherAwardsContainer)} />
        </Routes>
    );
};

export default TeacherApp;
