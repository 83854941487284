import React, { Component } from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';

const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.color || '#12154C'};
    padding: 20px 0px;

    .gradient-line-break {
        background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
        height: 1px;
        width: 100%;
        margin-top: 10px;
    }
`;

const MainButton = styled.a`
    border: none;
    color: #fff;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    margin-left: 20px;

    :hover {
        color: #fff;
    }

    .title {
        margin-left: 10px;
        line-height: 26px;
    }
`;

const ItemsContainer = styled.div`
    display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    background: ${(props) => props.active && 'linear-gradient(91.31deg, #1CC8E1 0.89%, #D022ED 98.12%)'};
    margin: 0 20px;
    padding: 11px 0;

    &:nth-child(1) {
        margin-top: 20px;
    }

    &.selected {
        color: #6ee3ff;
        background: linear-gradient(98.43deg, #1cc8e1 0.89%, #d022ed 98.12%);
        background-size: cover;
        color: white;
        margin: 0;
        padding-left: 20px;
    }

    .category-icon {
        min-width: 22px;
        text-align: center;
        font-size: 22px;
    }
`;

const TabItem = styled.a`
    color: #fff;
    font-size: 18px;
    margin-left: 10px;
    margin: 0px 0px 0px 10px;
    font-weight: 500;
`;

export default class MobileDropDown extends Component {
    constructor() {
        super();

        this.state = {
            showMenu: false
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    render() {
        const handleSelectTab = (key) => {
            if (this.props.onSelectTab) this.props.onSelectTab(key);
        };

        const handleSelectCategory = (key, index) => {
            if (this.props.onSelectCategory) this.props.onSelectCategory(key);
        };

        // for student home page
        let items = this.props.items;
        let itemNodes = null;
        if (items) {
            itemNodes = items.map((tab) => {
                return (
                    <ItemsContainer key={tab.id} active={tab.id === this.props.activeTab} onClick={() => handleSelectTab(tab.id)}>
                        <TabItem>{tab.id === this.props.activeTab ? null : tab.name}</TabItem>
                    </ItemsContainer>
                );
            });
        }
        // for assignment items
        let title = '';
        if (this.props.assignmentItems) {
            title = this.props.assignmentItems.find((name) => {
                return name.id === this.props.activeAssignmentTab;
            });
            console.log(title);
            itemNodes = this.props.assignmentItems.map((tab) => {
                return tab.id === 1 ? (
                    <ItemsContainer key={tab.id} active={tab.id === this.props.activeAssignmentTab} onClick={() => this.props.onClickUpcoming()}>
                        <TabItem>{tab.id === this.props.activeAssignmentTab ? null : tab.name}</TabItem>
                    </ItemsContainer>
                ) : (
                    <ItemsContainer key={tab.id} active={tab.id === this.props.activeAssignmentTab} onClick={() => this.props.onClickPast()}>
                        <TabItem>{tab.id === this.props.activeAssignmentTab ? null : tab.name}</TabItem>
                    </ItemsContainer>
                );
            });
        }

        // for student video page
        if (this.props.videoItems) {
            let playlists = this.props.videoItems[0].items;
            let categories = this.props.videoItems[1].items;

            let both = playlists.concat(categories);

            itemNodes = this.props.videoItems[0].items.map((tab, index) => {
                return (
                    <ItemsContainer
                        className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}
                        key={tab.id}
                        active={tab.id === this.props.selectedCategory}
                        onClick={() => handleSelectCategory(tab.id)}
                    >
                        {tab.id === 'assigned_videos' ? (
                            <div className='category-icon'>
                                <svg width='22' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M1 0a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 6.552a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 13.104a1 1 0 0 0-1 1v2.551a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.035 1.517 1.517 0 0 0 0 3.035Z'
                                        fill='#fff'
                                    />
                                </svg>
                            </div>
                        ) : (
                            <div className='category-icon'>{tab.icon ? tab.icon : ''}</div>
                        )}

                        <TabItem className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}>
                            {tab.id === this.props.activeCategory ? null : tab.name}
                        </TabItem>
                    </ItemsContainer>
                );
            });


            if( this.props.videoItems.length >= 2 ) {
                let title = this.props.videoItems[1].title;
                let categories = this.props.videoItems[1].items;
                let sectionItemNodes = categories.map((tab, index) => {
                    return (
                        <>
                            {index === 0 && (
                                <div>
                                    <div tw='text-lg text-white mt-6 ml-5'>{title}</div>
                                    <div className='gradient-line-break' />
                                </div>
                            )}
                            <ItemsContainer
                                className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}
                                key={tab.id}
                                active={tab.id === this.props.selectedCategory}
                                onClick={() => handleSelectCategory(tab.id)}
                            >
                                {tab.id === 'assigned_videos' ? (
                                    <div className='category-icon'>
                                        <svg width='22' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                fillRule='evenodd'
                                                clipRule='evenodd'
                                                d='M1 0a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 6.552a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 13.104a1 1 0 0 0-1 1v2.551a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.035 1.517 1.517 0 0 0 0 3.035Z'
                                                fill='#fff'
                                            />
                                        </svg>
                                    </div>
                                ) : (
                                    <div className='category-icon'>{tab.icon ? tab.icon : ''}</div>
                                )}

                                <TabItem className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}>
                                    {tab.id === this.props.activeCategory ? null : tab.name}
                                </TabItem>
                            </ItemsContainer>
                        </>
                    );
                });
                itemNodes = itemNodes.concat(sectionItemNodes);
            }


            if( this.props.videoItems.length >= 3 ) {
                let title = this.props.videoItems[2].title;
                let categories = this.props.videoItems[2].items;
                let sectionItemNodes = categories.map((tab, index) => {
                    return (
                        <>
                            {index === 0 && (
                                <div>
                                    <div tw='text-lg text-white mt-6 ml-5'>{title}</div>
                                    <div className='gradient-line-break' />
                                </div>
                            )}
                            <ItemsContainer
                                className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}
                                key={tab.id}
                                active={tab.id === this.props.selectedCategory}
                                onClick={() => handleSelectCategory(tab.id)}
                            >
                                {tab.id === 'assigned_videos' ? (
                                    <div className='category-icon'>
                                        <svg width='22' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                            <path
                                                fillRule='evenodd'
                                                clipRule='evenodd'
                                                d='M1 0a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 6.552a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 13.104a1 1 0 0 0-1 1v2.551a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.035 1.517 1.517 0 0 0 0 3.035Z'
                                                fill='#fff'
                                            />
                                        </svg>
                                    </div>
                                ) : (
                                    <div className='category-icon'>{tab.icon ? tab.icon : ''}</div>
                                )}

                                <TabItem className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}>
                                    {tab.id === this.props.activeCategory ? null : tab.name}
                                </TabItem>
                            </ItemsContainer>
                        </>
                    );
                });
                itemNodes = itemNodes.concat(sectionItemNodes);
            }


            // title = both.find((name) => {
            //     return name.id === this.props.activeCategory;
            // });


            let itemNodes0 = both.map((tab, index) => {
                return (
                    <>
                        {index === 4 && (
                            <div>
                                <div tw='text-lg text-white mt-6 ml-5'>Video Categories</div>
                                <div className='gradient-line-break' />
                            </div>
                        )}
                        <ItemsContainer
                            className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}
                            key={tab.id}
                            active={tab.id === this.props.selectedCategory}
                            onClick={() => handleSelectCategory(tab.id)}
                        >
                            {tab.id === 'assigned_videos' ? (
                                <div className='category-icon'>
                                    <svg width='22' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M1 0a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 6.552a1 1 0 0 0-1 1v2.552a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V7.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.034 1.517 1.517 0 0 0 0 3.034ZM1 13.104a1 1 0 0 0-1 1v2.551a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1v-2.552a1 1 0 0 0-1-1H1Zm1.517 3.793a1.517 1.517 0 1 0 0-3.035 1.517 1.517 0 0 0 0 3.035Z'
                                            fill='#fff'
                                        />
                                    </svg>
                                </div>
                            ) : (
                                <div className='category-icon'>{tab.icon ? tab.icon : ''}</div>
                            )}

                            <TabItem className={`${tab.id === this.props.selectedCategory ? 'selected' : ''}`}>
                                {tab.id === this.props.activeCategory ? null : tab.name}
                            </TabItem>
                        </ItemsContainer>
                    </>
                );
            });
        }
        // for student assignment page

        return (
            <Container color={this.props.color}>
                <MainButton onClick={this.showMenu}>
                    {/* <div className={'title'}>
                        {this.props.activeAssignmentTab
                            ? title.name
                            : this.props.activeTab
                                ? homeScreenReference[this.props.activeTab].header
                                : this.props.activeCategory
                                    ? title.name
                                    : 'null'}
                    </div> */}

                    <div tw='flex items-center w-full' style={this.state.showMenu ? { paddingBottom: '17px' } : null}>
                        {this.state.showMenu ? (
                            <svg width='23' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M22.886 3.769c0-.763-.302-1.494-.838-2.033a2.853 2.853 0 0 0-2.023-.842H2.86c-.758 0-1.486.302-2.022.842A2.882 2.882 0 0 0 0 3.769v17.25c0 .762.301 1.493.838 2.033.536.539 1.264.842 2.022.842h17.165c.759 0 1.486-.303 2.023-.842.536-.54.838-1.27.838-2.033V3.769ZM7.658 12.902a.714.714 0 0 1-1.222-.508c0-.191.075-.374.21-.51l4.29-4.312a.714.714 0 0 1 1.013 0l4.291 4.313a.717.717 0 0 1 .155.784.718.718 0 0 1-.935.39.713.713 0 0 1-.233-.157l-3.07-3.086v6.89a.72.72 0 0 1-.714.719.713.713 0 0 1-.716-.719v-6.89l-3.07 3.086Z'
                                    fill='#fff'
                                />
                            </svg>
                        ) : (
                            <svg width='23' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M.001 21.019c0 .762.301 1.493.838 2.033.536.539 1.264.842 2.023.842h17.164c.759 0 1.487-.303 2.023-.842.537-.54.838-1.27.838-2.033V3.769c0-.763-.301-1.494-.838-2.033a2.853 2.853 0 0 0-2.023-.842H2.862c-.759 0-1.487.302-2.023.842A2.882 2.882 0 0 0 0 3.769v17.25Zm15.228-9.134a.714.714 0 0 1 1.222.509c0 .19-.075.373-.21.508l-4.29 4.313a.713.713 0 0 1-1.013 0l-4.291-4.313a.72.72 0 0 1 .78-1.173c.087.036.166.089.232.156l3.07 3.086v-6.89a.72.72 0 0 1 .715-.719.713.713 0 0 1 .715.72v6.889l3.07-3.086Z'
                                    fill='#fff'
                                />
                            </svg>
                        )}

                        <div tw='ml-3 text-lg font-black'>{this.props.activeCategory}</div>
                    </div>
                </MainButton>

                {this.state.showMenu ? (
                    <div
                        className='menu'
                        tw='border-t-2 border-solid border-white pt-2.5'
                        ref={(element) => {
                            this.dropdownMenu = element;
                        }}
                    >
                        {itemNodes}
                    </div>
                ) : null}
            </Container>
        );
    }
}
