import React, { useEffect } from 'react';
import { useAuth } from 'context/Auth/AuthProvider';
import { useAccount, useUser } from 'state/User/UserState.js';

// const Context = React.createContext();

const LogRocketProvider = (props) => {
    // only
    const { isLoggedIn } = useAuth();
    const user = useUser();
    const account = useAccount();

    // setup logrocket
    useEffect(() => {
        if (!user) {
            return;
        }
        const logRocket = window.LogRocket;
        if (!logRocket) {
            console.warn('LogRocket is not installed');
            return;
        }

        // identify the user with logrocket
        try {
            console.log('[LogRocket] identify user', user);
            logRocket.identify(user.id, {
                name: `${user.is_teacher ? 'teacher' : 'student'} #${user.user.id}`,
                // name: user.full_username || user.username,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                account_name: account.name
            });
        } catch (e) {
            console.error(e);
        }

        // cleanup
        return () => {
            console.log('logrocket cleanup');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     // when the login state changes, update log rocket
    //     console.log('user/login changed: ', isLoggedIn, user);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isLoggedIn, user]);

    return null;
    // const value = {};
    // return (
    //     <Context.Provider value={value}>
    //         {props.children}
    //     </Context.Provider>
    // );
};

export function setup_logrocket(user) {
    const logRocket = window.LogRocket;
    if (!logRocket || !user) {
        return false;
    }

    //const isStaging = process.env.NODE_ENV !== 'production';
    // LogRocket.init(api_key, {
    //     shouldShowReportingButton: is_staging ? 'MdZ7Y8zk0f' : false
    // });

    logRocket.identify(user.id, {
        name: user.full_username || user.username,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name
    });

    // const getLogRocketSessionURL = () => {
    //     return window.LogRocket.sessionURL;
    // };
    //
    // const getLogRocketSessionURLCallback = function (sessionURLCallback) {
    //     console.log('calling session url function');
    //     LogRocket.getSessionURL((sessionURL) => {
    //         console.log('GOT SESSION URL', sessionURL);
    //         //!! sessionURLCallback(sessionURL, initialStore);
    //     });
    // };
    //
    // // set the session url
    // LogRocket.getSessionURL(function (sessionURL) {
    //     window.logRocketSessionURL = sessionURL;
    // });

    // console.log('[Heap Analytics] identify user', user);
    // heap.identify(user.user_id);
    // heap.addUserProperties({
    //     user_id: user.user_id,
    //     account_id: user.account,
    //     auth_method: user.auth_method_name,
    //     role: user.role,
    //     user_role: user.role === 1 ? 'student' : 'teacher',
    //     is_student: user.role === 1,
    //     is_teacher: user.role === 2,
    //     is_staff: user.is_staff
    // });
}

export default LogRocketProvider;
