import * as React from 'react';

function SvgClassIcon02(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#33FFCE' />
            <path
                d='M14.523 24.763l6.532-5.866c.332-.3.665-.641.997-1.021.332-.38.499-.824.499-1.33 0-.57-.206-1.013-.618-1.33a2.236 2.236 0 00-1.448-.499c-.665 0-1.188.206-1.568.617-.364.412-.57.919-.618 1.52l-3.562-.26c.047-.872.23-1.624.546-2.257a4.733 4.733 0 011.259-1.615 5.16 5.16 0 011.829-.95 7.716 7.716 0 012.303-.332c.76 0 1.473.11 2.138.332a4.736 4.736 0 011.734.95 4.1 4.1 0 011.14 1.568c.285.633.427 1.37.427 2.208 0 .539-.055 1.03-.166 1.473a4.9 4.9 0 01-.475 1.187c-.19.349-.42.673-.689.974-.269.3-.562.594-.879.879l-5.106 4.394h7.458v3.135H14.523v-3.777z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon02;
