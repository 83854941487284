import React from 'react';
import { styled } from 'twin.macro';
import { withErrorBoundary } from 'react-error-boundary';

export const DEFAULT_NAVBAR_HEIGHT = 68;
export const DEFAULT_FOOTER_HEIGHT = 68;
// export const FooterLayout = styled.div`
// `;
/**
 * The layout system is designed to hold the contents of the application
 * and conform to different uses cases.
 *
 *  The layouts fit inside the MainContent.Container with the
 *
 +-----------------+
 |    navbar       |
 +-----------------+
 |LayoutContainer |
 |+--------------+|
 || Layout       ||
 ||              ||
 ||              ||
 ||              ||
 |+--------------+|
 |+--------------+|
 ||    Footer    ||
 |+--------------+|
 +-----------------+
 */

export const LayoutContainer = styled.div`
    // fill the parent just below the navbar
    position: absolute;
    top: ${DEFAULT_NAVBAR_HEIGHT}px;
    bottom: 0;
    left: 0;
    right: 0;


    // fill the remaining space with the child contents
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    //  scroll the contents
    overflow-y: scroll;
    overflow-x: hidden;

    // white background and user select disabled
    background: #fff;
    user-select: none;
`;

// Fixed Layout is fixed to the height of the screen minus the footer
// and the contents are scrolled inside of the layout
export const FixedLayoutStyle = styled.div`
    flex: 1 1 calc(100% - ${DEFAULT_NAVBAR_HEIGHT}px);
    overflow-y: scroll;
    overflow-x: hidden;
        //height: calc(100% - ${DEFAULT_NAVBAR_HEIGHT}px);
    //@media (max-width: 500px) {
    //    height: inherit;
    //}
    //flex-grow: 1;
`;

// Scroll Layout grows as large as the content needs and the
// LayoutContainer scrolls vertically
export const ScrollLayoutStyle = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
`;

export const FixedLayout = ({ children }) => {
    return <FixedLayoutStyle className='fixed-layout-style'>{children}</FixedLayoutStyle>;
};

export const ScrollLayout = ({ children }) => {
    return <ScrollLayoutStyle className='scroll-layout-style'>{children}</ScrollLayoutStyle>;
};

////////////////////////////////////////////////////////////
// wrappers - these HOC functions are used to wrap components with a layout
// they also wrap the component in an ErrorBoundary for convenience
//

export const withLayout = (Control, Layout) => {
    return (props) => (
        <Layout>
            <Control {...props} />
        </Layout>
    );
};

export const withFixedLayout = (Control) => {
    const Layout = withErrorBoundary(withLayout(Control, FixedLayout), {});
    return <Layout />;
};

export const withScrollLayout = (Control) => {
    const Layout = withErrorBoundary(withLayout(Control, ScrollLayout), {});
    return <Layout />;
};

/*
    background: red;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;
 */

// legacy
// export const FixedAspect = styled.div`
//     width: 100%;
//     height: 0;
//     padding-top: ${(props) => (props ? props.aspect : 56.25)}%;
// `;


