import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import { Button, WelcomeBanner } from 'components';
import { Helmet } from 'react-helmet';
import { useTeacher, useParent, useUser } from 'state/User/UserState';
import { useTeacherAssignedVideos, useTeacherAssignments } from 'state/Teacher/TeacherProvider';
import HomeVideoTabs from '../../student/StudentHomeContainer/HomeVideoTabs';
import VideoPlayerOverlay from '../../../../components/VideoPlayerOverlay/VideoPlayerOverlay';
import LeaderboardTable from './classes/LeaderboardTable';

const S = {
    Container: styled.div`
        background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);

        h2 {
            color: white;
            font-size: 24px;
            line-height: 33px;
            font-weight: 800;

            margin-left: 0;
            margin-top: 60px;
            margin-bottom: 20px;
        }

        //.home-contents {
        //    @media only screen and (max-width: 1389px) {
        //        width: calc(100% - 2rem);
        //    }
        //}
    `
};


function MobileTeacherHomeHeader({ onClickTutorial, onClickVideos, teacher }) {
    return <div tw='block md:hidden bg-red-400'>
        <div tw='h-0.5 background[linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%)]' />
        <div tw=' height[380px] px-8 background[linear-gradient(180deg, #533db4 0%, #1e6db7 100%)]'>
            <div tw='text-white text-2xl font-black pt-10 '>Welcome back, {teacher?.name},</div>
            <div tw='text-white text-lg mt-4 font-black'>Let’s keep your students moving, grooving and learning to the beat.</div>
            <div tw='flex flex-col items-center justify-center'>
                <div tw='mt-8'>
                    <Button size='large' tw='text-lg minWidth[226px]' onClick={onClickVideos}>
                        SEE VIDEOS
                    </Button>
                </div>
                <div tw='mt-6'>
                    <Button variant='teal-large' tw='text-lg minWidth[226px]' onClick={onClickTutorial}>
                        Watch Tutorial
                    </Button>
                </div>
            </div>
        </div>
        <div tw='h-0.5 background[linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%)]' />
    </div>;
}


const TeacherHomeContainer = () => {
    // console.log('render TeacherHomeContainer');
    const assignments = useTeacherAssignments();
    const assignedVideos = useTeacherAssignedVideos();
    const teacher = useTeacher();
    const navigate = useNavigate();
    // const user = useUser();
    const [playTutorialVideo, setPlayTutorialVideo] = useState(false);

    return (
        <S.Container tw='background[var(--linear-gradient-background)]'>
            <Helmet>
                <title>Home</title>
            </Helmet>

            <div className='home-contents' tw='[maxWidth:var(--layout-width)] mx-auto' id='home-tour'>

                {/* desktop welcome banner */}
                <WelcomeBanner setPlayTutorialVideo={setPlayTutorialVideo} />

                {/* mobile teacher header */}
                <MobileTeacherHomeHeader
                    teacher={teacher}
                    onClickVideos={() => navigate('/videos')}
                    onClickTutorial={() => setPlayTutorialVideo(true)}
                />

                <HomeVideoTabs
                    assignments={assignments}
                    assignedVideos={assignedVideos}
                    id='home-videos-tab-tour'
                />

                <div tw='hidden sm:block' id='home-leaderboard-tour'>
                    <h2>Class Overview and Leaderboards</h2>
                    <LeaderboardTable />
                </div>

            </div>

            {/* play tutorial video */}
            <VideoPlayerOverlay
                videoId='670115629'
                isOpen={playTutorialVideo}
                setIsOpen={setPlayTutorialVideo}
            />;

        </S.Container>
    );
};

export default TeacherHomeContainer;
