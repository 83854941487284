import React, { useMemo, useState, useEffect } from 'react';
import tw from 'twin.macro';
import { TabNavigator } from 'components';
import { Helmet } from 'react-helmet';
import { useMedia } from 'react-use';
import { Navigate, useRoutes } from 'react-router-dom';
import { useVideoStats, useVideosWithStats } from '../../../../state/Student/VideoStats';
import { useActivity } from '../../../../state/User/ActivityState';
import { MyVideoStats, MyRecords, MyAchievements } from './tabs';

const Stats = () => {
    const [activeTab, setActiveTab] = useState(0);
    const videos = useVideosWithStats();
    const stats = useVideoStats();
    const isPhone = useMedia('(max-width: 768px)');
    const { activity, downloadActivity } = useActivity();

    useEffect(() => {
        downloadActivity();
    }, []);
    

    // filter stats for only unlocked videos
    const unlockedStats = useMemo(() => Object.values(stats ?? {}).filter((stat) => stat.current_level > 0), [stats]);

    // filter videos that are unlocked
    const unlockedVideos = useMemo(() => {
        return videos.reduce((acc, video) => {
            if (unlockedStats.find((stat) => stat.video_id === video.id)) return [...acc, video];
            return acc;
        }, []);
    }, [videos, unlockedStats]);

    const items = [
        { name: isPhone ? 'Video Stats' : 'My Video Stats', path: 'my-video-stats' },
        { name: isPhone ? 'Records' : 'My Records', path: 'my-records' },
        { name: isPhone ? 'Achievements' : 'My Achievements', path: 'my-achievements' }
    ];


    const path = '/stats/';
    const handleTabs = (item) => {
        switch (item.path) {
            case 'my-video-stats':
                return <MyVideoStats tableData={unlockedVideos} activity={activity} stats={unlockedStats} />;
            case 'my-records':
                return <MyRecords videos={videos} activity={activity} stats={unlockedStats} />;
            case 'my-achievements':
                return <MyAchievements videos={videos} />;
            default:
                return null;
        }
    };

    const routes = useRoutes([
        ...items.map((item) => ({
            path: item.path,
            element: handleTabs(item)
        })),
        {
            path: '*',
            element: <Navigate to={`${path}${items[0].path}`} replace />
        }
    ]);


    return (
        <div tw='h-full px-0 sm:px-6 py-3 sm:py-10 background[linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)]'>
            <Helmet>
                <title>My Stats</title>
            </Helmet>
            <div tw='[max-width:var(--layout-width)] mx-auto'>
                <TabNavigator activeTab={activeTab} activeTabHandler={setActiveTab} variant='enclosed' items={items} />
                {routes}
            </div>
        </div>
    );
};

export default Stats;
