import React from 'react';
import SlideWrapper from '../../Components/SlideWrapper';
import ActionBox from '../../Components/ActionBoxThird';
import { css } from 'twin.macro';
import CDSILVER from '../imgs/SlideThree/CD_SILVER.png';
import CDGOLD from '../imgs/SlideThree/CD_GOLD.png';
import CDPLAT from '../imgs/SlideThree/CD_PLATINUM.png';
import WaveBackground from '../../imgs/WaveBackground.png';
import BackgroundImage from 'lib/components/BackgroundImage';

const ContainerStyle = css`
    background: rgba(100, 65, 185, 0.60);
    backdrop-filter: blur(10px);
    min-height: 179px;
    padding: 10px 0; 
    display: flex;
    justify-content: flex-end;
`;

const SlideThree = () => {
    return (
        <SlideWrapper slide={'green'}>
            <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light' />
            <div tw='bg-[#76E6FC] h-[123px] md:h-[123px] w-[3px] absolute top-1/2 transform -translate-y-1/2' />
            <div tw='font-Poppins font-semibold flex flex-col banner_xl:flex-row min-h-[400px] lg:min-h-[320px] banner_xl:min-h-[250px] px-4 banner_xl:px-6 pt-3'>
                <div tw='w-full banner_xl:w-[300px] mb-4 banner_xl:mb-0 mt-0 banner_xl:mt-10 flex justify-center'>
                    <div tw='text-white text-2xl lg:text-3xl   max-w-full banner_xl:max-w-[220px] mb-8 md:mb-0 md:mt-3 banner_xl:mt-4 text-left '>How Math Challenges Work</div>
                </div>
                <div tw='flex flex-col md:flex-row gap-3 w-full mt-3 lg:mt-3.5'>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center'>
                                    <div>
                                        <div tw='font-semibold'>Silver Record </div>
                                        <div tw='text-white font-normal'>Here’s Where You Start.</div>
                                        <div tw='text-white font-normal'>(Get 100% to Unlock Gold.)</div>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='absolute -top-6 left-1/2 transform -translate-x-1/2 '>
                                <div tw='max-w-[135px] max-h-[105px]'>
                                    <img src={CDSILVER} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center'>
                                    <div>
                                        <div tw='font-semibold'>Gold Challenge</div>
                                        <div tw='text-white font-normal'>Show Off Your Skills!</div>
                                        <div tw='text-white font-normal'>(Get 100% to Unlock Platinum.)</div>
                                    </div>

                                </div>
                            </ActionBox>
                            <div tw='absolute -top-6 left-1/2 transform -translate-x-1/2 '>
                                <div tw='max-w-[135px] max-h-[105px]'>
                                    <img src={CDGOLD} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div tw='w-1/3'>
                        <div tw='relative w-full h-full'>
                            <ActionBox containerStyle={ContainerStyle}>
                                <div tw='text-center text-sm lg:text-base xl:text-lg w-full text-white flex items-end justify-center'>
                                    <div>
                                        <div tw='font-semibold'>Platinum Challenge</div>
                                        <div tw='text-white font-normal'>Flex Your Brainpower!</div>
                                        <div tw='text-white font-normal'>(This is Advanced Math)</div>
                                    </div>
                                </div>
                            </ActionBox>
                            <div tw='absolute -top-6 left-1/2 transform -translate-x-1/2 '>
                                <div tw='max-w-[130px] max-h-[105px]'>
                                    <img src={CDPLAT} tw='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SlideWrapper>
    );
};

export default SlideThree;