import React from 'react';

const HomeSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'>
            <path d='M23.2942 8.91535L14.8257 2.91775C14.331 2.56741 13.669 2.56741 13.1743 2.91775L4.70584 8.91535C3.94952 9.45098 3.5 10.3202 3.5 11.247V22.8092C3.5 24.3872 4.77919 25.6663 6.35714 25.6663H9.57143C10.3604 25.6663 11 25.0267 11 24.2378V21.2616C11 20.4726 11.6396 19.833 12.4286 19.833H15.5714C16.3604 19.833 17 20.4726 17 21.2616V24.2378C17 25.0267 17.6396 25.6663 18.4286 25.6663H21.6429C23.2208 25.6663 24.5 24.3872 24.5 22.8092V11.247C24.5 10.3202 24.0505 9.45098 23.2942 8.91535Z' stroke='#46A6F8' strokeWidth='1.5' strokeLinecap='round'/>
        </svg>
    );
};

export default HomeSVG;
