import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import tw, { styled } from 'twin.macro';
import { ListBox, Table } from 'components';
import RecordImage from 'components/RecordImage/RecordImage';
import { Link } from 'react-router-dom';
import { useClasses } from '../../../../../state/Teacher/ClassState';

const S = {
    Container: styled.div`
        padding-bottom: 5px;
    `
};

const Hexagon = (props) => {
    return (
        <div {...props} tw='relative flex items-center justify-center' style={{ width: '99px', height: '103px' }}>
            <svg
                width='99' height='103' fill='none' xmlns='http://www.w3.org/2000/svg'
                color={props.color ?? '#FCA500'}>
                <path
                    d='M49.231 1.534c-1.063 0-2.127.25-3.088.75L4.14 24.39c-1.922 1-3.089 2.845-3.089 4.877v44.179c0 2 1.167 3.877 3.089 4.877l42.003 22.105c.96.5 2.025.75 3.088.75 1.064 0 2.128-.25 3.089-.75l42.003-22.105c1.922-1 3.089-2.845 3.089-4.877v-44.21c0-2.001-1.167-3.877-3.089-4.878L52.32 2.285c-.961-.5-2.025-.75-3.089-.75z'
                    fill='#fff'
                />

                <path
                    d='M49.196 5.64c-.973 0-1.945.228-2.824.685L7.964 26.517c-1.757.913-2.824 2.599-2.824 4.455v40.354c0 1.828 1.067 3.541 2.824 4.455l38.408 20.191a6.126 6.126 0 002.824.686c.973 0 1.946-.229 2.824-.686l38.408-20.19c1.758-.915 2.824-2.6 2.824-4.456V30.943c0-1.828-1.066-3.541-2.824-4.455L52.02 6.325a6.125 6.125 0 00-2.824-.685z'
                    fill='currentColor'
                />

                <path
                    d='M49.196 2.492c.666 0 1.332.16 1.928.48l42.889 22.58c1.191.638 1.927 1.788 1.927 3.033v45.127c0 1.246-.736 2.427-1.927 3.034l-42.89 22.58a4.084 4.084 0 01-1.927.479c-.665 0-1.331-.16-1.927-.48L4.38 76.747c-1.191-.638-1.927-1.788-1.927-3.034V28.553c0-1.245.736-2.427 1.927-3.034L47.27 2.971c.56-.319 1.225-.479 1.926-.479zm0-2.235a6.832 6.832 0 00-3.153.766l-42.89 22.58C1.192 24.625 0 26.509 0 28.585v45.127c0 2.044 1.191 3.96 3.154 4.983l42.889 22.579a6.832 6.832 0 003.153.767 6.832 6.832 0 003.154-.767l42.889-22.58c1.962-1.022 3.154-2.906 3.154-4.982V28.553c0-2.044-1.192-3.96-3.154-4.982L52.349 1.023a6.831 6.831 0 00-3.153-.766z'
                    fill='#000'
                />

                {/*
                <text
                    x='0' y='0' fontFamily='Verdana' fontSize='55'
                    fill='#1A1717'>
                    test
                </text>
                <path
                d='M37.635 46.235l-3.571 3.302-1.88-2.228 5.746-4.779h2.926v19.008h-3.221V46.235zM55.026 52.222c-.626-.877-1.468-1.315-2.524-1.315-.43 0-.85.107-1.261.322-.412.215-.618.564-.618 1.047 0 .394.17.68.51.859.34.179.77.331 1.289.456l1.665.403a5.971 5.971 0 011.69.617 3.55 3.55 0 011.29 1.128c.34.483.51 1.146.51 1.987 0 .77-.17 1.423-.51 1.96-.323.519-.752.94-1.29 1.261-.518.323-1.109.555-1.771.698a9.375 9.375 0 01-1.987.215c-1.002 0-1.924-.143-2.765-.43-.841-.286-1.593-.805-2.255-1.556l2.148-2.014c.411.465.85.85 1.315 1.154.483.287 1.065.43 1.745.43.233 0 .474-.027.725-.08.25-.054.483-.144.698-.269.215-.125.385-.277.51-.456.143-.197.215-.421.215-.672 0-.447-.17-.778-.51-.993-.34-.215-.77-.385-1.289-.51a18.403 18.403 0 00-1.691-.376 7.289 7.289 0 01-1.665-.564 3.649 3.649 0 01-1.288-1.1c-.34-.466-.51-1.11-.51-1.933 0-.716.143-1.334.43-1.853a3.986 3.986 0 011.18-1.315 5.048 5.048 0 011.665-.752 7.449 7.449 0 014.457.215 4.089 4.089 0 012.04 1.503l-2.148 1.933zM58.579 51.39V48.65h2.658V44.92h3.221v3.731h3.544v2.739H64.46v5.638c0 .644.116 1.154.349 1.53.25.376.733.564 1.45.564.286 0 .599-.027.939-.08.34-.072.608-.18.805-.323v2.712c-.34.16-.76.268-1.261.322a8.903 8.903 0 01-1.29.107c-.858 0-1.565-.098-2.12-.295-.537-.179-.967-.456-1.289-.832-.304-.394-.519-.877-.644-1.45a10.878 10.878 0 01-.161-1.987V51.39h-2.658z'
                fill='#1A1717'/>
                */}
            </svg>

            <div tw='text-2xl absolute' style={{ fontSize: '26.85px', fontWeight: 800 }}>
                {props.text}
            </div>
        </div>
    );
};

const IconColors = ['#FFD233', '#33FFCE', '#D44BA4', '#FF8C4B', '#BE6CFF'];

function formatNumber(x) {
    // return Number.parseFloat(x).toPrecision(10);
    if (Number.parseFloat(x) === Number.parseFloat(Number.parseInt(x))) return Number.parseFloat(x).toFixed(0);
    return Number.parseFloat(x).toFixed(1);
}

const RecordCell = ({ data, level, ...props }) => {
    const { value } = data;
    return (
        <div tw='flex items-center justify-center'>
            <p tw='pr-2'>{value}</p>
            <RecordImage style={{ width: '60px' }} level={level} />
        </div>
    );
};

const LeaderboardTable = (props) => {
    // const [sortOrder, setSortOrder] = useState({ title: 'Gold Records Leaderboard', id: 'gold_records', leaderBoardDataIndex: 5 });
    const [leaderboard, setLeaderboard] = useState(null);
    // const [sortedTableData, setSortedTableData] = useState([]);

    const classes = useClasses();
    

    // download the leaderboard data
    const { data } = useSWR('/api/leaderboards/');
    console.log("🚀 ~ LeaderboardTable ~ data:", data)

    // create the dropdown items from the list of leaderboards returned from the server
    // return leaderboards?.map((board, index) => ({ ...board, leaderboardIndex: index }));
    const listBoxItems = useMemo(() => data?.leaderboards, [data?.leaderboards]);

    const tableData = useMemo(() => {
        // ensure we have data and a selected leaderboard
        if (!data || !leaderboard) {
            // console.log('tableData = NO DATA');
            return [];
        }

        // deconstruct the data
        const { keys, rows, leaderboards } = data;
        console.log('[LeaderboardTable] generate taleData', data);
        // return the rows in the order of places
        return (leaderboard.places.map((item) => {
            const { place, value, class_id } = item;

            // find the class
            const cls = classes?.find((cls) => cls.id === class_id);

            // find the data row
            const row = rows.find((row) => row[0] === class_id);

            // check for missing class data
            if (!row) {
                return null;
            }

            // TODO: use the key values
            // data.keys.map( (key,index) => )
            // const classId = row[0];
            const studentCount = row[1];
            const totalRecords = row[2];
            const averageRecords = formatNumber(row[3]);
            const averageViews = formatNumber(row[4]);
            const averageTime = formatNumber(row[5]);
            const silverRecords = row[6];
            const goldRecords = row[7];
            const blueRecords = row[8];

            return {
                place,
                cls: cls,
                icon: cls?.display_icon ?? 'ClassIcon00',
                name: cls?.title ?? row[0],
                studentCount,
                totalRecords,
                averageRecords,
                averageViews,
                averageTime,
                silverRecords,
                goldRecords,
                blueRecords
            };
            // filter out null items
        }) ?? []).filter( item => item !== null);
    }, [classes, data, leaderboard]);

    // console.log('tableData:', tableData);

    // construct the colunms
    const columns = React.useMemo(
        () => [
            {
                Header: 'Class Name',
                accessor: 'cls',
                // accessor: (row, i) => {
                //     console.log('access', i, row);
                //     return { cls: row.cls, index: i };
                // },
                Cell: (data, cell) => {
                    const { value, rows } = data;

                    const cls = value;
                    // find the current row number
                    const place = data.cell.row.original.place;
                    return (
                        <div tw=' flex items-center'>
                            <Hexagon
                                key={`${cls ? cls.id : ''}-${place}`}
                                tw='absolute -left-16'
                                color={IconColors[place % IconColors.length]}
                                text={`#${place}`}
                            />

                            {/*
                            <ClassIcon
                                css={[tw`absolute -left-16`]}
                                tw='absolute -left-16'
                                name={'ClassIcon00'}
                                width={'59px'}
                                height={'60px'}
                            />
                            */}
                            <span tw='-ml-12 text-xl text-white maxWidth[12rem] '>{cls?.title}</span>
                        </div>
                    );
                }
            },
            // {
            //     Header: 'Total Records',
            //     accessor: 'totalRecords'
            // },
            // {
            //     // Header: 'Average Time On Muzology',
            //     Header: 'Student Count',
            //     accessor: 'studentCount'
            //
            // },
            {
                Header: 'Avg. Video Views',
                accessor: 'averageViews'
            },
            // {
            //     Header: 'Avg. Time On Muzology',
            //     accessor: 'averageTime'
            // },
            {
                Header: 'Silver Records',
                accessor: 'silverRecords',
                Cell: (data) => <RecordCell data={data} level={1} />
            },
            {
                Header: 'Gold Records',
                accessor: 'goldRecords',
                Cell: (data) => <RecordCell data={data} level={2} />
            },
            {
                Header: 'Platinum Records',
                accessor: 'blueRecords',
                Cell: (data) => <RecordCell data={data} level={3} />
            }
            // {
            //     Header: 'Average Records Earned',
            //     accessor: 'averageRecords'
            // },
            // {
            //     Header: 'Total Records Earned',
            //     accessor: 'col5',
            //     Cell: () => <RecordCounter/>
            // }
        ],
        []
    );
    /*
    let customSort = () => {
        // sort table
        let items = null;
        switch (leaderboard?.id) {
            case 'video_views':
                items = [...tableData].sort((a, b) => {
                    return b.averageViews - a.averageViews;
                });
                setSortedTableData(items);
                break;
            case 'silver_records':
                items = [...tableData].sort((a, b) => {
                    return b.silverRecords - a.silverRecords;
                });
                setSortedTableData(items);

                break;
            case 'gold_records':
                items = [...tableData].sort((a, b) => {
                    return b.goldRecords - a.goldRecords;
                });
                setSortedTableData(items);

                break;
            case 'plat_records':
                items = [...tableData].sort((a, b) => {
                    return b.blueRecords - a.blueRecords;
                });
                setSortedTableData(items);
                break;
            // case 'class_order':
            //     items = [...tableData];
            //     setSortedTableData(items);
            //     break;
        }
    };
    */

    useEffect(() => {
        // load the initial leaderboard
        if (data?.leaderboards?.length > 0 && !leaderboard) {
            // console.log('setting initial leaderboard: ', data.leaderboards[0]);
            setLeaderboard(data.leaderboards[0]);
        } else {
            // console.log('leaderboard = ', leaderboard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, leaderboard]);

    return (
        <S.Container>
            {tableData?.length > 0 ? (
                <>
                    <div tw='w-full flex justify-end'>
                        <div tw='width[275px] pb-8'>
                            <ListBox
                                placeholder='Gold Records Leaderboard'
                                items={listBoxItems}
                                selected={leaderboard}
                                onChange={(value) => setLeaderboard(value)}
                            />
                        </div>
                    </div>
                    <Table
                        tw='ml-16'
                        isSortable={false}
                        data={tableData ?? []}
                        columns={columns}
                        headerStyle={tw`text-center text-white text-sm mb-3 first:text-left first:pl-6 first:width[230px !important]`}
                        rowStyle={tw`relative bg-LavenderWeb even:bg-LavenderBlue mb-9 rounded-lg height[79px]`}
                        cellStyle={tw`text-2xl font-medium p-4 flex items-center justify-center first:justify-start first:text-left first:bg-gradient-to-r to-PholxPink from-SkyBlueCrayola first:width[230px !important]`}
                    />
                </>
            ) : (
                <div tw='bg-QuizBlue text-justify borderRadius[20px] text-white font-medium text-2xl p-24 bg-opacity-50 mb-12'>
                    <Link to='/classes' tw='underline'>
                        Add your first class
                    </Link>{' '}
                    to Muzology and see a stats overview here.
                    <p tw='pt-4'>This section also serves as a Class Leaderboard. Let the math music games begin!</p>
                </div>
            )}
        </S.Container>
    );
};

export default LeaderboardTable;
