import React, { useEffect, useMemo, useState } from 'react';
import 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useFavoriteVideos, useRecentlyWatchedVideos } from '../../../../state/Student/VideoStats';
import { useStudent, useTeacher, useUser } from '../../../../state/User/UserState';
import VideoSlider from '../../../../components/VideoSlider';
import RecommendedPlaylist from '../../../../components/RecommendedPlaylist';
import HomeTabs from './HomeTabs';

const HomeVideoTabs = ({ assignments, assignedVideos, ...props }) => {
    const navigate = useNavigate();
    const favorites = useFavoriteVideos();
    const recent_videos = useRecentlyWatchedVideos();
    const student = useStudent();
    const teacher = useTeacher();
    const isTablet = useMedia('(min-width: 500px)');
    const user = useUser();
    const isChild = user.is_parent_account;


    // handle selection of tab if there are no recently watched videos
    const [tab, setTab] = useState(recent_videos?.length > 0 ? 'recently_watched' : 'recommended_videos');

    // need to switch back to recently watched if they are now loaded and 'recommended videos' is selected
    useEffect(() => {
        if (tab === 'recommended_videos' && recent_videos?.length > 0) setTab('recently_watched');
    }, [recent_videos, tab]);


    // create the tabs
    const tabs = useMemo(() => {
        let tabs = [];
        if (recent_videos.length && !isChild) {
            // recently watched videos
            tabs.push({
                id: 'recently_watched',
                name: 'Recently Watched',
                icon_active_color: '#fff'
            });
        } else {
            // recommended videos
            tabs.push({
                id: 'recommended_videos',
                name: 'Recommended Videos',
                icon_active_color: '#fff'
            });
        }

        if (recent_videos.length && isChild) {
            tabs.push({
                id: 'recently_watched',
                name: 'Recently Watched',
                icon_active_color: '#fff'
            });
        }

        // assigned videos tab
        if (assignments.length > 0 && !isChild) {
            tabs.push({
                id: 'assigned_videos',
                name: 'Assigned Videos',
                icon_active_color: '#fff'
            });
        }

        // favorite video tabs
        if (favorites.length > 0 && isTablet) {
            tabs.push({
                id: 'favorites',
                name: 'Favorited Videos',
                icon_active_color: '#fff'
            });
        }
        return tabs;
    }, [assignments.length, favorites.length, isChild, isTablet, recent_videos.length]);

    // create the tab contents
    const tabContents = useMemo(() => {
        const sliderResponsive = [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 938,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ];
        const onClickVideo = (video) => {
            // open the video
            navigate(`/video/${video.id}`);
        };
        switch (tab) {
            case 'recently_watched':
                return (
                    <VideoSlider
                        key='recently_watched'
                        videos={recent_videos}
                        teacher={teacher}
                        onClickVideo={onClickVideo}
                        arrows={true}
                        cols={3}
                        sliderResponsive={sliderResponsive}

                    />
                );

            case 'recommended_videos': {
                let grade = student?.grade;
                if (teacher) {
                    const { grades_data } = teacher;
                    if (grades_data?.length > 0) {
                        grade = parseInt(grades_data[0]);
                    } else {
                        grade = teacher.defaultGrade;
                    }
                    console.log('<RecommendedPlaylist> teacher grade:', grade);
                } else {
                    console.log('<RecommendedPlaylist> student grade:', grade);
                }

                return (
                    <RecommendedPlaylist
                        key='recommended_videos'
                        grade={grade ?? 6}
                        teacher={teacher}
                        arrows={true}
                        sliderResponsive={sliderResponsive}
                    />
                );
            }

            case 'assigned_videos':
                if (assignedVideos.length > 0) {
                    return (
                        <VideoSlider
                            videos={assignedVideos}
                            teacher={teacher}
                            onClickVideo={onClickVideo}
                            arrows={true}
                            cols={3}
                            sliderResponsive={sliderResponsive}
                        />
                    );
                }
                return null;
                // return <div> Need null state for assigned student </div>;

            case 'favorites':
                return (
                    <VideoSlider
                        key='favorites'
                        videos={favorites}
                        teacher={teacher}
                        onClickVideo={onClickVideo}
                        arrows={true}
                        cols={3}
                        sliderResponsive={sliderResponsive}
                    />
                );

            default:
                return <div> Invalid tab: {tab} </div>;
        }
    }, [tab, navigate, recent_videos, teacher, student?.grade, assignedVideos, favorites]);

    return (
        <div tw='mx-auto ' {...props}>
            <HomeTabs
                // tw='mt-16'
                tabs={tabs}
                onSelectTab={setTab}
                activeTab={tab}
            />

            <div tw='bg-white py-7 !pt-0 rounded-none sm:rounded-lg'>{tabContents}</div>
        </div>
    );
};
export default HomeVideoTabs;
