import React from 'react';
import tw, { styled } from 'twin.macro';
import { useMatch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import ScrollState from 'context/ScrollState';
import { useUser } from 'state/User/UserState';
import { DTCNavbar } from 'components';
import SearchResultsContainer from '../../containers/shared/SearchResults/SearchResultsContainer';



const LayoutContainerStyle = styled.div`
    height: 100%;
`;

const AppLayoutStyle = styled.main`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ $matchSettings }) => (!$matchSettings ? '181px' : '0px')};
    right: 0;

    @media (max-width: 1280px) { 
    left: ${({ $matchSettings }) => (!$matchSettings ? '121px' : '0px')};
    }
    @media (max-width: 1024px) { 
        left: ${({ $matchSettings }) => (!$matchSettings ? '60px' : '0px')};

    }
    @media (max-width: 900px) { 
      left: 0; 
    }

    .app-content {
        flex: 1;
    }
`;

// const HamburgerNavigationContainer = styled.div`
//     display: flex;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 200;
//     width: 100%;
//     transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
//     transition: transform 0.1s ease-in-out;
//
// `;

const DTCAppLayout = ({ children }) => {
    const user = useUser();
    const matchSettings = useMatch('/settings/*');
    console.log('[App]                     <DTCAppLayout> render matchSettings:', matchSettings);
    return (
        <ScrollState elementClassName='main-content-container'>
            <LayoutContainerStyle>
                {/* Navigation bar */}
                <ErrorBoundary>
                    <DTCNavbar user={user} />
                </ErrorBoundary>

                {/* application contents */}
                <ErrorBoundary>
                    <AppLayoutStyle $matchSettings={matchSettings}>
                        <SearchResultsContainer>
                            <div className='app-content'>
                                {children}
                            </div>
                        </SearchResultsContainer>
                    </AppLayoutStyle>
                </ErrorBoundary>
            </LayoutContainerStyle>
        </ScrollState>
    );
};

export default DTCAppLayout;
