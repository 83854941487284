import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFilters, useGlobalFilter, useTable } from 'react-table';
import { useLocalStorage } from 'react-use';
import tw, { css, styled } from 'twin.macro';
import { Button, Modal, NullContainer } from 'components';
import SvgWhiteXButton from 'images/icons/WhiteXButton';
import moment from 'moment';
import iconWarning from 'static/images/warning-icon.svg';
import RecordImg from 'components/RecordImg';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { useNavigate } from 'react-router-dom';
import { useWebTourState } from 'state/WebTourState';
import { useStudentProgressModal } from './StudentProgressModal/StudentProgressModal';

const Styles = styled.div`
    table {
        width: 100%;
        border-spacing: 0px;
        background: rgba(42, 38, 77, 0.5);
    }

    thead {
        tr {
            &:nth-child(1) {
                th:not(:nth-child(1)) {
                    background: linear-gradient(91.31deg, #1cc8e1 0.89%, #0ec86f 98.12%);
                    font-family: 'Avenir Medium', sans-serif;
                }

                th:nth-child(2n) {
                    background: linear-gradient(93.96deg, #867ef9 19.73%, #f059bb 73.17%);
                    font-family: 'Avenir Medium', sans-serif;
                }
            }

            th {
                text-align: center;
                padding: 1rem;
                color: white;
            }
        }
    }

    thead {
        tr {
            &:first-of-type {
                th {
                    font-size: 18px;
                    font-weight: 800;
                    width: 250px;
                }
            }

            &:nth-child(1) {
                font-size: 18px;

                th:not(:nth-child(1)) {
                    background: #c7c7ff;
                    font-family: 'Avenir Medium', sans-serif;
                    padding: 0;
                }
            }

            &:nth-child(2) {
                display: none;
            }

            th {
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                padding: 1rem;
                color: #1a1717;

                position: relative;

                svg {
                    display: inline;
                }

                &:nth-of-type(1) {
                    .header {
                        display: none;
                    }
                }

                &:nth-of-type(2) {
                    /* background: white; */
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(3) {
                    background: white;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(4) {
                    background: #d6dfe3;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(5) {
                    background: #d6dfe3;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(6) {
                    background: #ecd9b4;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(7) {
                    background: #ecd9b4;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(8) {
                    background: #9de2ee;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(9) {
                    background: #9de2ee;
                    border-bottom: 2px solid #5a5353;
                    border-right: none;
                }
            }
        }
    }

    tbody {
        tr {
            background: #ebebff;

            td:nth-child(1) {
                border-right: none;
                background: #f1f4fd;
                border-right: 2px solid #5a5353;
            }

            &:nth-child(2n) {
                background: #d7d7ff;

                td:nth-child(1) {
                    background: #6160d9;
                }

                /* td:nth-child(n + 4) {
                    background: #d4dbee;
                } */
                /* td:nth-child(n + 6) {
                    background: #ebd7b0;
                }
                td:nth-child(n + 8) {
                    background: #9de2ee;
                } */
            }

            td {
                padding: 1.15rem;
                text-align: center;

                &:nth-child(1) {
                    background: #7776f4;
                    color: white;
                }

                &:nth-child(2) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-child(3) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-child(5) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-child(7) {
                    border-right: 2px solid #5a5353;
                }
            }
        }
    }
`;

const AssignmentTooltip = styled.div`
    position: absolute;
    left: -24px;
    top: -5px;
    right: 0;
    transform: translate(50%, 0px);

    .assignment-tooltip--container {
        position: absolute;
        transform: translate(-50%, calc(-100% - 14px));
        box-shadow: 0px 1px 10px 0px #0000001a;

        .assignment-tooltip--wrapper {
            border-radius: 5px;
            background: #ffffff;
            background-clip: padding-box;
            border: solid 2px transparent;
            padding: 18px 30px;
            width: 259px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                border-radius: inherit;
                background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
            }

            &:after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                bottom: -14px;
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                border-top: none;
                border-left: none;
                border-image: initial;
                border-right: 2px solid #7378e7;
                border-bottom: 2px solid #7774e7;
                z-index: 1000;
                background: white;
            }

            .assignment-tooltip--title {
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ee7171;
                margin: 0 0 9px;

                img {
                    margin-left: 4px;
                }

                &.status-overdue {
                    color: #ee7171;
                }

                &.status-complete {
                    color: #5fc17a;
                }

                &.status-progress {
                    color: #887be3;
                }

                &.status-not-start {
                    color: #a5a5a5;
                }
            }

            .assignment-tooltip--info {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #1a1717;
                margin: 1px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;

                b {
                    font-weight: 800;
                    margin-right: 2px;
                }

                img {
                    margin-left: 3px;
                }
            }

            .assignment-tooltip--videos {
                width: 100%;
                margin: 5px 0 9px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                &.justify-left {
                    justify-content: left;
                }

                .assignment-tooltip--video-bar {
                    width: calc(33% - 4px);
                    height: 10px;
                    margin: 2px;

                    &.status-complete {
                        background: #5fc17a;
                        border: 1px solid #1d562d;
                    }

                    &.status-progress {
                        background: #887be3;
                        border: 1px solid #24238d;
                    }

                    &.status-not-start {
                        background: #a5a5a5;
                        border: 1px solid #5a5353;
                    }
                }
            }
        }
    }
`;

const CheckBox = (props) => {
    return (
        <button
            css={[tw`w-8 h-8 rounded-lg border-MuzologyLightBlue border-2 flex items-center justify-center`, props.disabled && tw`cursor-not-allowed`]}
            {...props}
        >
            {props.selected && <div tw='w-5 h-5 rounded-md bg-MuzologyLightBlue'/>}
        </button>
    );
};

function SelectAssignmentModal({ isOpen, setIsOpen, assignments, selectAssignments, selectedAssignments, toggleSelectedAssignment }) {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[682px] mx-auto`}>
            <div>
                <div tw='w-full px-12 py-3 rounded-tl-xl rounded-tr-xl relative bg-white'>
                    <h1 tw='text-white text-2xl font-medium text-EarieBlack pt-3'>Select Up to 3 Assignments to View</h1>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='cursor-pointer bg-white rounded-full absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center shadow-md '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                </div>
                <div tw='bg-white overflow-auto p-6 px-12 pb-0 maxHeight[560px] overflow-auto'>
                    {assignments.map((assignment) => {
                        const hasAssignmentBeenSelected = selectedAssignments.find((selectedAssignment) => selectedAssignment.id === assignment.id);

                        return (
                            <div key={assignment.id} tw='mb-6 flex space-x-7 items-center'>
                                <CheckBox
                                    selected={hasAssignmentBeenSelected}
                                    disabled={!hasAssignmentBeenSelected && selectedAssignments.length === 3}
                                    onClick={() => toggleSelectedAssignment(assignment)}
                                />
                                <p
                                    css={[
                                        tw`text-DarkLiver`,
                                        selectedAssignments.length === 3 && !hasAssignmentBeenSelected && tw`text-DarkLiver text-opacity-50`
                                    ]}
                                >
                                    {assignment.name}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <div tw='bg-white flex items-center justify-end px-6 py-7 rounded-bl-xl rounded-br-xl'>
                    <Button onClick={() => setIsOpen(false)}>Done</Button>
                </div>
            </div>
        </Modal>
    );
}

function InformationIcon() {
    return (
        <svg width='29' height='29' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle
                cx='14.5' cy='14.5' r='13.982' fill='#fff'
                stroke='#1AC1DD' strokeWidth='1.036' />
            <path d='M10 21.5H7.5v-6H10v6ZM17 21.5h-2.5v-9H17v9ZM20.5 21.5H18V9h2.5v12.5ZM13.5 21.5H11v-15h2.5v15Z' fill='#1AC1DD' />
        </svg>
    );
}

const AssignmentGradebook = ({ students, assignments, classId }) => {
    const navigate = useNavigate();
    // const [currentModal, setCurrentModalOpen] = useState();

    // filter assignments for this class
    const filteredAssignments = useMemo(() => {
        return assignments.filter((assignment) => {
            return assignment.assigned_classes?.includes(classId) || students.find((student) => assignment.assigned_students?.includes(student.user.id));
        });
    }, [assignments, students, classId]);

    console.log('          class id        = ', classId);
    console.log('          all students    = ', students);
    console.log('          all assignments = ', assignments);
    console.log('          filtered        = ', filteredAssignments);

    // persist selected assignments by classId
    const [savedAssignments, saveAssignments] = useLocalStorage(`selectedAssignments_${classId}`, null);
    const [selectedAssignmentIDs, selectAssignmentIDs] = useState(
        savedAssignments
            ? JSON.parse(savedAssignments)
            : filteredAssignments.slice(0, filteredAssignments.length > 3 ? 3 : filteredAssignments.length).map((assignment) => assignment.id)
    );

    // convert selected assignment IDs into a list of selected assignments
    const selectedAssignments = useMemo(() => {
        if (selectedAssignmentIDs.map((id) => assignments.find((assignment) => assignment.id === id)).filter((a) => !!a).length === 0) {
            // filter and sort the 3 most recent assignments if none have been selected
            return [...assignments].sort((a, b) => moment(b.created).diff(moment(a.created))).slice(0, 3);
        }
        return selectedAssignmentIDs.map((id) => assignments.find((assignment) => assignment.id === id)).filter((a) => !!a);
    }, [assignments, selectedAssignmentIDs]);

    // selectedAssignments;
    // toggle an assignment ID on or off
    const toggleSelectedAssignment = useCallback(
        (assignment) => {
            const hasAssignmentBeenSelected = selectedAssignmentIDs.find((id) => id === assignment.id);
            if (hasAssignmentBeenSelected) {
                // remove the assignment id
                const filteredStudents = selectedAssignmentIDs.filter((id) => id !== assignment.id);
                selectAssignmentIDs(filteredStudents);
                saveAssignments(JSON.stringify(filteredStudents));
            } else {
                // add the assignment id
                selectAssignmentIDs([...selectedAssignmentIDs, assignment.id]);
                saveAssignments(JSON.stringify([...selectedAssignmentIDs, assignment.id]));
            }
        },
        [saveAssignments, selectedAssignmentIDs]
    );

    const [isSelectAssignmentsModalOpen, openSelectAssignmentsModal] = useState(false);
    const [toolTipShowing, setToolTipShowing] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setToolTipShowing(false));

    const { stepIndex } = useWebTourState();
    useEffect(() => {
        if (stepIndex === 9) setToolTipShowing({ idx: 2, id: '49ac43ea-017b-4363-93e8-a7d34e7141a5' });
    }, [stepIndex]);

    function ToolTip({ completeStatus, isOverdue, statusText, videoCount, assignmentInstance, totalSpent, completeVideo, progressArray, assignment }) {
        // videoCount = 3;
        if (completeVideo > videoCount) completeVideo = videoCount;
        return (
            <AssignmentTooltip ref={ref}>
                <div className='assignment-tooltip--container' id='classes-tour-grades-pill-expanded'>
                    <div className='assignment-tooltip--wrapper'>
                        <p className={`assignment-tooltip--title status-${completeStatus === 'complete' || !isOverdue ? completeStatus : 'overdue'}`}>
                            {statusText}
                            {isOverdue && <img src={iconWarning} />}
                        </p>
                        <p className='assignment-tooltip--info'>
                            <b>Videos Complete:</b> {`${completeVideo} of ${videoCount}`}
                        </p>
                        <div className={`assignment-tooltip--videos ${videoCount > 3 ? 'justify-left' : ''}`}>
                            {[...Array(videoCount).keys()].map((index) => {
                                const item = progressArray[index];
                                // const item = { completed: true };
                                return (
                                    <div
                                        className={`assignment-tooltip--video-bar status-${
                                            !item ? 'not-start' : item.completed ? 'complete' : item.progress > 0 ? 'progress' : 'not-start'
                                        }`}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                        <p className='assignment-tooltip--info'>
                            <b>Goal:</b> {assignment.quiz_score_goal}%
                            <RecordImg level={assignment.quiz_level_goal} style={{ width: '17px', height: 'auto', display: 'inline' }} />
                        </p>
                        {/*
                        <p className='assignment-tooltip--info'>
                            <b>Time Spent:</b> {moment.duration(totalSpent, 'seconds').format('h [hr] m [min]', { trim: true })}
                        </p>
                        */}
                        {completeStatus === 'complete' && assignmentInstance?.progress.completion_date && (
                            <p className='assignment-tooltip--info'>
                                <b>Date Completed:</b> {moment(assignmentInstance?.progress.completion_date).format('MM/DD/YY')}
                            </p>
                        )}
                    </div>
                </div>
            </AssignmentTooltip>
        );
    }

    const dynamicColumns = React.useMemo(
        () =>
            selectedAssignments.map((assignment) => {
                function renderStatus(assignmentInstance, idx) {
                    const progressArray = assignmentInstance?.progress && !undefined && Object.values(assignmentInstance.progress);
                    console.log(progressArray);
                    const completeStatus = assignmentInstance?.progress.completed ? 'complete' : progressArray?.length > 0 ? 'progress' : 'not-start';
                    const isOverdue = moment().isAfter(assignment.due);
                    const statusText =
                        completeStatus === 'complete'
                            ? isOverdue
                                ? 'Completed Late'
                                : 'Completed On Time'
                            : isOverdue
                                ? 'Overdue'
                                : completeStatus === 'progress'
                                    ? 'In Progress'
                                    : 'Not Started';
                    const videoCount = assignment.items?.length;
                    const completeVideo = progressArray?.filter((item) => item.completed).length;
                    const totalSpent = progressArray
                        ?.map((item, index) => ({ video: assignment.videos.find((video) => video.id === assignment.videos[index]), progress: item.progress }))
                        ?.filter((item) => item.video && item.progress > 0)
                        ?.reduce((acc, cur) => acc + cur.video.duration * cur.progress, 0);
                    // if( assignmentInstance.student_id === 46382 && assignmentInstance.assignment_id === '' ) {
                    //     assignmentInstance.completion_percent = 1;
                    //     // assignmentInstance.progress = { test: 1 };
                    // }

                    if (!assignmentInstance) {
                        return <div tw='text-sm font-medium text-DarkLiver'>Not Assigned</div>;
                        // check if progress is empty
                    } else if (Object.keys(assignmentInstance.progress).length === 0 && assignmentInstance.progress.constructor === Object) {
                        return (
                            <div tw='relative flex items-center justify-center space-x-4'>
                                <div
                                    tw='rounded-full px-8 text-white inline-block bg-[#A5A5A5] text-sm font-medium'
                                    onClick={() => setToolTipShowing({ idx, id: assignmentInstance?.assignment_id })}
                                    css={[
                                        !toolTipShowing &&
                                            css`
                                                cursor: pointer;

                                                &:hover {
                                                    background: #828282;
                                                }

                                                &:active {
                                                    background: #5f5f5f;
                                                    box-shadow: 0px 0px 8px 2px #1cc8e1;
                                                }
                                            `
                                    ]}
                                >
                                    Not Started
                                </div>
                                {isOverdue && <img tw='absolute right-8' src={iconWarning} />}
                                {toolTipShowing.idx === idx && toolTipShowing.id === assignmentInstance.assignment_id && (
                                    <ToolTip
                                        ref={ref}
                                        progressArray={progressArray}
                                        completeStatus={completeStatus}
                                        isOverdue={isOverdue}
                                        statusText={statusText}
                                        videoCount={videoCount}
                                        assignmentInstance={assignmentInstance}
                                        totalSpent={totalSpent}
                                        completeVideo={completeVideo}
                                        assignment={assignment}
                                    />
                                )}
                            </div>
                        );
                    } else if (
                        (assignmentInstance?.completion_percent > 0 && assignmentInstance?.completion_percent < 1) ||
                        (Object.keys(assignmentInstance.progress).length !== 0 && assignmentInstance?.completion_percent !== 1)
                    ) {
                        return (
                            <div tw='relative flex items-center justify-center space-x-4'>
                                <div
                                    tw='rounded-full px-8 text-white inline-block bg-MediumPurple text-sm font-medium'
                                    onClick={() => setToolTipShowing({ idx, id: assignmentInstance?.assignment_id })}
                                    css={[
                                        !toolTipShowing &&
                                            css`
                                                cursor: pointer;

                                                &:hover {
                                                    background: #6160d9;
                                                }

                                                &:active {
                                                    background: #3a39b0;
                                                    box-shadow: 0px 0px 8px 2px #1cc8e1;
                                                }
                                            `
                                    ]}
                                >
                                    In Progress
                                </div>
                                {isOverdue && <img tw='absolute right-8' src={iconWarning} />}
                                {toolTipShowing.idx === idx && toolTipShowing.id === assignmentInstance.assignment_id && (
                                    <ToolTip
                                        ref={ref}
                                        progressArray={progressArray}
                                        completeStatus={completeStatus}
                                        isOverdue={isOverdue}
                                        statusText={statusText}
                                        videoCount={videoCount}
                                        assignmentInstance={assignmentInstance}
                                        totalSpent={totalSpent}
                                        completeVideo={completeVideo}
                                        assignment={assignment}
                                    />
                                )}
                            </div>
                        );
                    } else if (assignmentInstance?.completion_percent === 1) {
                        return (
                            <div tw='relative flex items-center justify-center space-x-4'>
                                <div
                                    tw='rounded-full px-8 text-white inline-block bg-CompletedGreen  text-sm font-medium'
                                    onClick={() => setToolTipShowing({ idx, id: assignmentInstance?.assignment_id })}
                                    css={[
                                        !toolTipShowing &&
                                            css`
                                                cursor: pointer;

                                                &:hover {
                                                    background: #479b5e;
                                                }

                                                &:active {
                                                    background: #237339;
                                                    box-shadow: 0px 0px 8px 2px #1cc8e1;
                                                }
                                            `
                                    ]}
                                >
                                    Complete
                                </div>
                                {/* {isOverdue && <img tw='absolute right-8' src={iconWarning} />} */}
                                {toolTipShowing.idx === idx && toolTipShowing.id === assignmentInstance.assignment_id && (
                                    <ToolTip
                                        ref={ref}
                                        progressArray={progressArray}
                                        completeStatus='complete'
                                        // isOverdue={isOverdue}
                                        statusText='Complete'
                                        videoCount={videoCount}
                                        assignmentInstance={assignmentInstance}
                                        totalSpent={totalSpent}
                                        assignment={assignment}
                                        completeVideo={completeVideo}
                                    />
                                )}
                            </div>
                        );
                    } else {
                        console.log('MISSING ASSIGNMENT', assignmentInstance);
                        return '';
                    }
                }

                return {
                    Header: assignment?.id,
                    Cell: (row) => {
                        const assignmentInstance = row.row?.original?.assignment_instances?.find((instance) => instance.assignment_id === row.column.Header);
                        return renderStatus(assignmentInstance, row.row.index, row.column.Header);
                    }
                };
            }),
        [selectedAssignments, toolTipShowing]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: 'Students',
                accessor: 'user.full_name',
                canSort: false,
                width: 50
            },
            ...dynamicColumns
        ],
        [dynamicColumns]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: students
        },
        useFilters,
        useGlobalFilter
    );

    // start creating playlist
    const handleCreateNewPlaylist = () => {
        navigate('/videos/create_playlist');
    };

    // get the student progress modal
    const [ProgressModal, openProgressModal] = useStudentProgressModal();

    // show a null state if there are no assignments for this class
    if (filteredAssignments.length === 0) {
        return (
            <NullContainer tw='z-10 m-auto font-medium text-2xl text-white'>
                <p>
                    Assigning Muzology playlists is a great way to keep your students focused and differentiate instruction. Teachers that assign playlists
                    typically get the best Muzology results. Assigning playlists is fast and easy! &nbsp;
                    <span tw='underline cursor-pointer' onClick={handleCreateNewPlaylist}>
                        Click here
                    </span>{' '}
                    to get started.
                </p>
                <p tw='pt-5'>Going forward, you’ll see your students’ assignment progress on this page.</p>
            </NullContainer>
        );
    }

    return (
        <Styles>
            <div tw='text-2xl font-bold text-white py-4 text-center' id='classes-tour-grades-view-assignments'>
                <span tw='underline cursor-pointer' onClick={() => openSelectAssignmentsModal(true)}>
                    Click here
                </span>{' '}
                to select which three assignments you'd like to view.
            </div>

            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                // get specific assignment data based on column
                                const studentAssignmentData = column.preFilteredRows.flatMap(
                                    (row) =>
                                        row.original.assignment_instances &&
                                        row.original.assignment_instances.find((assignment) => assignment.assignment_id === column.Header)
                                );
                                const completeCount = studentAssignmentData.filter((data) => data?.completed);
                                const onlyAssignedStudentsCount = studentAssignmentData.filter((data) => data).length;
                                // overall assignment data
                                const assignment = selectedAssignments.find((assignment) => assignment.id === column.Header);
                                return (
                                    <th {...column.getHeaderProps()} tw='text-EarieBlack'>
                                        {/* {column.id === 'user.full_name' && <Button onClick={() => openSelectAssignmentsModal(true)}>Select Assignments</Button>} */}
                                        {column.id === 'user.full_name' && ''}

                                        <div
                                            className='header'
                                            tw='relative cursor-pointer py-2 bg-LavenderBlue hover:background[#F1F4FD]'
                                            css={[
                                                css`
                                                    &:active {
                                                        background: #ababfe;
                                                        box-shadow: 0px 0px 8px 2px #1cc8e1;
                                                    }
                                                `
                                            ]}
                                            onClick={() => openProgressModal(assignment, students)}
                                        >
                                            <h1 tw='text-lg'>{assignment?.name}</h1>
                                            <p tw='text-sm'>
                                                <b>Due:</b>{' '}
                                                {new Date(assignment?.due).toLocaleDateString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                })}
                                            </p>
                                            <p tw='text-sm'>
                                                <b>Complete:</b> {completeCount.length} of {onlyAssignedStudentsCount} Students
                                            </p>
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} id={index === 1 && 'classes-tour-grades-pill'}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <ProgressModal />

            <SelectAssignmentModal
                selectedAssignments={selectedAssignments}
                toggleSelectedAssignment={toggleSelectedAssignment}
                assignments={filteredAssignments}
                isOpen={isSelectAssignmentsModalOpen}
                setIsOpen={openSelectAssignmentsModal}
            />
        </Styles>
    );
};

export default AssignmentGradebook;
