import React from 'react';

const ActiveProgressSVG = ({ style }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' style={style}>
            <path
                d='M2.5 17.5L8.5 11.5L14.5 15.5L23 6M23 6V10M23 6H19' stroke='currentColor' strokeWidth='1.5'
                strokeLinecap='round' strokeLinejoin='round' />
            <circle cx='2.5' cy='17.5' r='1.5' fill='currentColor' stroke='currentColor' strokeWidth='1.5' />
            <circle cx='8.5' cy='11.5' r='1.5' fill='currentColor' stroke='currentColor' strokeWidth='1.5' />
            <circle cx='14.5' cy='15.5' r='1.5' fill='currentColor' stroke='currentColor' strokeWidth='1.5' />
        </svg>

    );
};

export default ActiveProgressSVG;