import React, { useState, useMemo, useEffect } from 'react';
import { ListBox, HomePageVideoRows, ParentHomePageActivityModal } from 'components';
import { useParent, useUser } from 'state/User/UserState';
import { useParams, useNavigate } from 'react-router-dom';
import { useClassStudents } from 'state/Teacher/ClassState';
import 'twin.macro';
import { useStudentStats, useVideosWithStats } from 'state/Student/VideoStats';
import { sortWatchDate } from 'app/containers/shared/VideosContainer/SortVideos';
import { useActivityForUser } from 'state/User/ActivityState';
import VideoSlider from 'components/VideoSlider';
import moment from 'moment';
import { useRecommendedVideos } from 'state/Guidance/recommendations';
import VideoPlayerOverlay from 'components/VideoPlayerOverlay/VideoPlayerOverlay';
import Button from 'components/Button/Button';
import { PARENT_TUTORIAL_VIDEO_ID } from '../../../../config';
import { useGuidance, useGuidanceForStudent } from '../../../../state/Guidance/GuidanceProvider';

const ParentHomeContainer = () => {
    const parent = useParent();
    const user = useUser();
    const navigate = useNavigate();
    const { student_id, tab_name } = useParams();
    const { students } = useClassStudents(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [playTutorialVideo, setPlayTutorialVideo] = useState(false);
    const studentActivity = useActivityForUser(selectedStudent?.user?.id);

    const selectedChildAchievements = studentActivity?.filter((a) => a.name === 'achievement:created');
    const achievementsEarnedThisWeek = selectedChildAchievements?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const selectedChildRecordsEarned = studentActivity?.filter((a) => a.data.score === 100 && a.data.quizLevel > 0);
    const recordsEarnedThisWeek = selectedChildRecordsEarned?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const totalSpecificActivity = recordsEarnedThisWeek + achievementsEarnedThisWeek;
    const recordCopy = recordsEarnedThisWeek === 1 ? `${recordsEarnedThisWeek} record` : `${recordsEarnedThisWeek} records `;
    const achievementCopy = achievementsEarnedThisWeek === 1 ? `${achievementsEarnedThisWeek} achievement` : `${achievementsEarnedThisWeek} achievements`;
    let conditionalCopy;
    switch (true) {
        case totalSpecificActivity === 1:
            conditionalCopy = `${selectedStudent?.user.first_name} earned ${achievementCopy} and ${recordCopy} last week. `;
            break;
        case totalSpecificActivity <= 3:
            conditionalCopy = `Nice! ${selectedStudent?.user.first_name} earned ${achievementCopy} and ${recordCopy} last week`;
            break;
        case totalSpecificActivity <= 7:
            conditionalCopy = `Amazing! ${selectedStudent?.user.first_name} earned ${achievementCopy} and ${recordCopy} last week`;
            break;
        case totalSpecificActivity <= 12:
            conditionalCopy = `Looks like a math rockstar! ${selectedStudent?.user.first_name} earned ${achievementCopy} and ${recordCopy} last week`;
            break;
        case totalSpecificActivity <= 19:
            conditionalCopy = `We are seriously impressed. ${selectedStudent?.user.first_name} earned ${achievementCopy} and ${recordCopy} last week`;
            break;
        case totalSpecificActivity >= 20:
            conditionalCopy = `This is next level. ${selectedStudent?.user.first_name} a TOP Muzology performer and earned ${achievementCopy} and ${recordCopy} last week`;
            break;
        default:
            break;
    }

    // use the guidance system to get the recommended videos and refresh videos
    const guidance = useGuidanceForStudent(student_id);
    const { videos: allRecommendedVideos, refresh: refreshVideos } = guidance;
    const recommendedVideos = allRecommendedVideos.slice(0, 2);
    const showRecommendedVideos = recommendedVideos.length > 0;
    const showRefreshVideos = refreshVideos.length > 0;

    // first, sort the students and create listbox items
    const listBoxItems = useMemo(
        () =>
            [...students]
                .sort((a, b) => a.user.first_name.localeCompare(b.user.first_name))
                .map((student) => ({ name: student.user.first_name, id: student.user.id, item: student })),
        [students]
    );

    // hide list box if there is only one child account
    let showListBox = listBoxItems.length > 1;

    // second, if there is no student_selected, and we have student data,
    // select the first student
    useEffect(() => {
        if (student_id === undefined && listBoxItems?.length > 0) {
            const first_id = listBoxItems[0].id;
            navigate(`/home/${first_id}`, { replace: true });
        }
    }, [student_id, listBoxItems, navigate, tab_name]);

    useEffect(() => {
        setSelectedStudent(students.find(s => s.user.id.toString() === student_id));
    }, [student_id, students]);

    const stats = useStudentStats(student_id);
    const videos = useVideosWithStats();
    // filter stats for only unlocked videos
    // const unlockedStats = useMemo(() => Object.values(stats ?? {}).filter((stat) => stat.current_level > 0), [stats]);

    // filter videos that are unlocked and add the stats that match that video
    const videosWithStats = useMemo(() => {
        return videos.reduce((acc, video) => {
            if (stats)
                stats.forEach((stat) => {
                    if (stat.video_id === video.id)
                        acc.push({
                            ...video,
                            stats: stats ? stats.find((s) => s.video_id === video.id) ?? null : null
                        });
                });
            return acc;
        }, []);
    }, [stats, videos]);

    const nullState = videosWithStats.length === 0 || totalSpecificActivity === 0;
    const name = user.instructor?.name ?? user.first_name;
    const header = nullState ? `Welcome, ${name}!` : `Welcome Back, ${user.instructor.name}!`;
    const childrenConditional = students.length > 1 ? `${students[0]?.user.first_name} and ${students[1]?.user.first_name}` : `${students[0]?.user.first_name} `;
    const nullStateHeader = `
    We are so excited for  ${childrenConditional} to experience math success using Muzology. 
    Using engaging music videos and associated math Challenges, Muzology makes learning math fast, fun and easy. 
    Remember, the more your child watches a video…the more the information sticks! `;

    const recentlyWatched = useMemo(() => {
        // filter viewed videos
        const watched = videosWithStats.filter((video) => video?.stats?.last_viewed);
        // sort by recent date
        return watched.sort(sortWatchDate);
    }, [videosWithStats]);
    const showRecentlyWatchedVideos = recentlyWatched.length > 0;

    return (
        <div tw=' h-full min-h-full overflow-hidden px-3 xl:px-6 py-3 sm:py-10 bg-[#1C0057] font-Poppins relative'>
            <div tw=' [max-width:var(--layout-width)] px-0 2xl:px-5 mx-auto flex flex-col lg:flex-row justify-between'>
                <div>
                    <div tw='text-white text-3xl pt-5 font-semibold'>
                        {header}
                    </div>
                    {nullState ?
                        <div tw='text-[#9D91BB] text-lg font-medium pt-4'>
                            <div>{nullStateHeader}</div>
                            <div tw='pt-4'>
                                <Button variant='blue-dtc' tw='w-[160px]' onClick={() => setPlayTutorialVideo(true)}>
                                    <div tw='flex items-center justify-center'>
                                        <div>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='13' height='15' fill='none'>
                                                <path fill='#fff' d='M11.802 6.293c.93.65.93 1.764 0 2.414L4.145 14.06C2.755 15.034.5 14.287.5 12.853V2.147C.5.714 2.754-.033 4.145.939l7.657 5.354Z' />
                                            </svg>
                                        </div>
                                        <div tw='text-sm font-medium pl-2'>Watch Tutorial</div>
                                    </div>
                                </Button>
                            </div>

                        </div> :
                        <div tw='text-[#9D91BB] text-lg font-medium pt-4'> {conditionalCopy}
                            {' '}<span
                                tw='underline text-[#9D91BB] text-lg font-medium cursor-pointer'
                                onClick={() => setIsOpen(true)}>See Activity.</span>
                        </div>}

                </div>
                <div>
                    {showListBox ? (
                        <div tw='maxWidth[300px] mt-2.5 pl-0 md:pl-8'>
                            <ListBox
                                items={listBoxItems}
                                onChange={(value) => navigate(`/home/${value.id}`, { replace: true })}
                                selected={listBoxItems.find((s) => s.id.toString() === student_id)}
                                // initialSelected={defaultSelected}
                                variant='transparent-small-parent-home'
                                pushHistory={true}
                            />
                        </div>
                    ) : (
                        <div tw='text-2xl text-white'>{listBoxItems[0]?.name}</div>
                    )}
                </div>
            </div>

            <div tw='[max-width:var(--layout-width)] mx-auto max-h-full hidden md:block'>
                {nullState ?
                    <>
                        <div tw='mt-14'>
                            <HomePageVideoRows
                                title={`Recommended Videos for ${selectedStudent?.user.first_name}`}
                                videos={recommendedVideos} />
                        </div>
                    </> :
                    <>
                        {
                            showRecentlyWatchedVideos &&
                            <div tw='mt-14'>
                                <HomePageVideoRows
                                    title={`Recently Watched Videos by ${selectedStudent?.user.first_name}`}
                                    videos={recentlyWatched} />
                            </div>
                        }
                        {
                            showRecommendedVideos &&
                            <div tw='mt-14'>
                                <HomePageVideoRows
                                    title={`Recommended Videos for ${selectedStudent?.user.first_name}`}
                                    videos={recommendedVideos} />
                            </div>
                        }
                        {
                            showRefreshVideos &&
                            <div tw='mt-14'>
                                <HomePageVideoRows
                                    title={`Refresh Your Memory Videos for ${selectedStudent?.user.first_name}`}
                                    videos={refreshVideos} />
                            </div>
                        }
                    </>}

            </div>
            <div tw='[max-width:var(--layout-width)] mx-auto max-h-full md:hidden'>
                {
                    showRecentlyWatchedVideos &&
                    <div tw='mt-14 ml-[-22vw] mr-[-7vw]'>
                        <h2 tw='text-white text-xl font-semibold ml-[28vw]'>Recently Watched Videos
                            by {selectedStudent?.user.first_name}</h2>
                        <VideoSlider
                            videos={recentlyWatched} noSideArrow={true} />
                    </div>
                }
                {
                    showRecommendedVideos &&
                    <div tw='mt-14 ml-[-22vw] mr-[-7vw]'>
                        <h2 tw='text-white text-xl font-semibold ml-[28vw]'>Recommended Videos
                            for {selectedStudent?.user.first_name}</h2>
                        <VideoSlider
                            videos={recommendedVideos} noSideArrow={true} />
                    </div>
                }
                {
                    showRefreshVideos &&
                    <div tw='mt-14 ml-[-22vw] mr-[-7vw]'>
                        <h2 tw='text-white text-xl font-semibold ml-[28vw]'>Refresh Your Memory Videos
                            for {selectedStudent?.user.first_name}</h2>
                        <VideoSlider
                            videos={refreshVideos} noSideArrow={true} />
                    </div>
                }
            </div>
            <ParentHomePageActivityModal
                isOpen={isOpen} setIsOpen={setIsOpen} selectedChild={selectedStudent}
                activity={studentActivity ?? []} videos={videos} />
            <VideoPlayerOverlay
                videoId={PARENT_TUTORIAL_VIDEO_ID}
                isOpen={playTutorialVideo}
                setIsOpen={setPlayTutorialVideo} />;
        </div>
    );
};

export default ParentHomeContainer;
