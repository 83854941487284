import React from 'react';

const SvgCategoryNumberSystem = (props) => (
    <svg
        viewBox='0 0 28 28'
        width='1em'
        height='1em'
        {...props}>
        <g transform='translate(-38 -614)'>
            <circle
                data-name='category_number_system'
                cx={14}
                cy={14}
                r={14}
                transform='translate(38 614)'
                fill='white' />
            <text
                data-name={123}
                transform='translate(41 620)'
                fill='#12154C'
                fontSize={12}
                fontFamily='Avenir-Black,Avenir'
                fontWeight={800}>
                <tspan
                    x={0}
                    y={12}>
                    123
                </tspan>
            </text>
        </g>
    </svg>
);

export default SvgCategoryNumberSystem;
