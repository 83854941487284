import React, { useCallback, useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import moment from 'moment/moment';
import { useVideos } from 'state/CatalogState';
import { Button, ListBox, PageContainer, Plaque, NullContainer } from 'components';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useClasses, useClassStudents } from '../../../../state/Teacher/ClassState';
import { recordImageURL } from '../../../../static/records/FilledRecordsAssets';

const levelTitles = ['', 'Silver', 'Gold', 'Platinum'];

const TeacherAwardsContainer = (props) => {
    const classes = useClasses();
    const videos = useVideos();
    const navigate = useNavigate();

    const classItems = [{ name: 'All Classes', id: null }, ...classes?.map((cls) => ({ name: cls.title, id: cls.id }))];
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedClass, setSelectedClass] = useState(classes.length ? classItems[0] : null);

    const { students } = useClassStudents(selectedClass?.id ?? null);

    const calcRecordValue = useCallback(
        (videoId, level) => {
            if (!students) return { value: 0, students: 0, records: 0 };
            let value = 0;
            let recordCount = 0;
            let studentCount = 0;
            students.forEach((student) => {
                if (student.video_stats) {
                    const stats = student.video_stats[videoId];
                    if (stats) {
                        if (stats.current_level > level) {
                            recordCount += 1;
                        }
                    }
                }
                studentCount += 1;
            });
            if (studentCount !== 0) {
                value = (recordCount / studentCount) * 100;
            }

            return {
                value: value,
                students: studentCount,
                records: recordCount
            };
        },
        [students]
    );

    const validVideos = videos?.filter((video) => {
        const { value } = calcRecordValue(video.id, 1);
        return value > 0;
    });

    const handleCreateNewPlaylist = () => {
        navigate('/videos/create_playlist');
    };

    const sortedVideos = validVideos?.sort((a, b) => a.title.localeCompare(b.title));
    const videoItems = [...sortedVideos?.map((cls) => ({ name: cls.title, id: cls.id }))];
    const videosSortedAlphabetical = [{ name: 'All Videos' }, ...videoItems];

    const makeRecordsPlaques = (items, search = null) => {
        // handle filter dropdown
        // switch ('date') {
        //     case 'date':
        //         items = items.sort((a, b) => moment(b.dates).diff(moment(a.dates)));
        //         break;
        //     case 'alphabetical':
        //         items = items.sort((a, b) => a.title.localeCompare(b.title));
        //         break;
        //     case 'record_level':
        //         items = items.sort((a, b) => a.level - b.level);
        //         break;
        // }

        // handle search for video titles
        // if (search) {
        //     items = items.filter((video) => video.title.toLowerCase().includes(search));
        // }

        return items.map((item, index) => {
            let copy = {
                title: item.title,
                videoTitle: item.description
            };
            return (
                <Plaque
                    record={true}
                    key={index}
                    recordImage={item.recordImages}
                    recordText={item.recordText}
                    // multiplier={'6x'}
                    copy={copy}
                    teacher={true}
                    classes={classes}
                    useClassStudents={useClassStudents}
                    popUpCompletion={item.popUpCompletion}
                    level={item.level}
                    videoId={item.videoId}
                />
            );
        });
    };

    let copy = {
        title: 'record title',
        videoTitle: 'video title'
    };

    let plaqueItems = [];
    if (validVideos) {
        // filter videos that have no stats or quiz_level data
        // create plaque items to easy filter and sort
        // let items = videos.filter((v) => v?.stats?.quiz_levels);

        // filter the videos by the dropdown
        let filteredVideos = validVideos;
        if (selectedVideo && selectedVideo.id) {
            filteredVideos = validVideos.filter((video) => video.id === selectedVideo.id);
        }
        filteredVideos.forEach((video) => {
            for (let level = 1; level <= 3; level++) {
                const { value, students, records } = calcRecordValue(video.id, level);
                const pct = Math.round(value);
                if (pct > 0) {
                    plaqueItems.push({
                        recordTitle: levelTitles[level],
                        date: null,
                        level: level,
                        videoId: video.id,
                        description: `${records} of ${students} Students earned the ${levelTitles[level]} Record`,
                        recordImages: recordImageURL(level, pct),
                        recordText: `${pct}%`,
                        title: video.title,
                        popUpCompletion: `${records} of ${students} students`,
                        recordCompletions: { value, students, records }
                    });
                }
            }

            // Object.values(video.stats.quiz_levels).forEach((quiz) => {
            //     if (quiz.score === 100 && quiz.level > 0) {
            //         plaqueItems.push({
            //             recordTitle: recordTitle[quiz.level],
            //             date: quiz.date,
            //             level: quiz.level,
            //             recordImages: recordImages[quiz.level],
            //             title: video.title
            //         });
            //     }
            // });
        });
    }
    return (
        <PageContainer>
            <Helmet>
                <title>My Awards</title>
            </Helmet>

            <div
                tw='[maxWidth:var(--layout-width)] mx-auto w-full py-10'
                css={[
                    css`
                        @media only screen and (max-width: 1375px) {
                            padding: 2.5rem;
                        }
                    `
                ]}
            >
                <div tw='flex flex-wrap justify-between pt-0 xsm:pt-7'>
                    <div tw='text-white font-sans font-extrabold text-2xl leading-8 flex-1 text-center xsm:text-left minWidth[258px]'>
                        My Teacher Records
                    </div>

                    <div tw='hidden md:flex flex-wrap items-center'>
                        <div tw='mt-4 xsm:mt-0' style={{ minWidth: '280px', paddingRight: '10px' }}>
                            <ListBox
                                items={classItems}
                                onChange={setSelectedClass}
                                selected={selectedClass}

                            />
                        </div>
                        {/* <div tw='mt-4 xsm:mt-0' style={{ minWidth: '280px' }}>
                            <ListBox items={videosSortedAlphabetical} onChange={setSelectedVideo} selected={selectedVideo ?? videosSortedAlphabetical[0]} />
                        </div> */}
                    </div>
                </div>

                <div tw='text-white font-sans font-semibold text-lg leading-normal pt-4 text-center xsm:text-justify' id='awards'>
                    Collect teacher awards as your students score 100% on math Challenges.
                </div>

                <hr tw='hidden sm:block' />
                {sortedVideos?.length ? (
                    <div tw='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6'>{makeRecordsPlaques(plaqueItems)}</div>
                ) : (
                    /*
                    <div
                        tw='absolute inset-0 z-10 maxWidth[1275px] m-auto top[185px] bottom[100px] font-medium fontSize[16px] sm:text-lg lg:text-2xl text-white'
                        css={[
                            css`
                                @media only screen and (max-width: 1372px) {
                                    padding: 0 30px;
                                }
                                @media only screen and (max-width: 1282px) {
                                    top: 195px;
                                    padding: 0 4.5rem;
                                }
                                @media only screen and (max-width: 1026px) {
                                    top: 225px;
                                    padding: 0 4.5rem;
                                }
                                @media only screen and (max-width: 559px) {
                                    top: 250px;
                                    padding: 0 2.5rem;
                                }
                            `
                        ]}
                    >
*/
                    <NullContainer tw='height[90%] xsm:h-full marginTop[30px]'>
                        <p>When students earn 100% on Math Challenges they earn records (badges) and you earn teacher awards!</p>
                        <p tw='pt-8'> 
                            To get your students started, encourage them to watch Muzology videos and take the Math Challenges. Or, assign Muzology playlists to your students by <span tw='underline cursor-pointer' onClick={() => navigate('/assignments')}>clicking here.</span></p>
                        <p tw='pt-8'>
                        (If you haven't done so already, <span tw='underline cursor-pointer' onClick={() => navigate('/classes')}>click here</span> to add a class and invite your students to join.)

                        </p>
                    </NullContainer>
                )}
            </div>
        </PageContainer>
    );
};

export default TeacherAwardsContainer;
