import React, { useState } from 'react';
import { css } from 'twin.macro';
import Button from 'components/Button/Button';
import VideoPlayerOverlay from 'components/VideoPlayerOverlay/VideoPlayerOverlay';
import NullImage from 'static/images/banners/NullChildHomeImage.png';
import { CHILD_TUTORIAL_VIDEO_ID } from '../../config';

const ChildHomeBannerNull = () => {
    const [playTutorialVideo, setPlayTutorialVideo] = useState(false);

    return (
        <div tw='relative'>
            <div tw='pl-0 xl:pl-10 relative z-10 hidden md:block'>
                <div tw='bg-[#B031FA] opacity-60 blur-[250px] w-full absolute h-[600px] z-[-1]' />
                <div tw='flex w-full justify-between z-10'>
                    <div tw='pt-20'>
                        <div tw='text-[#9D91BB] md:text-lg lg:text-xl font-medium'>
                            A fast and fun way to learn math
                        </div>
                        <div tw='text-white md:text-4xl lg:text-5xl leading-tight pt-5 font-semibold max-w-[375px]'>
                            Welcome to Muzology
                        </div>
                        <div tw='max-w-[520px] text-white font-normal md:text-sm lg:text-lg mt-7'>It's time to learn math the fast and fun way! Just like you know the words to your favorite songs, Muzology uses music videos to help you learn and remember math. Remember, the more you watch a video...the faster you learn!</div>
                        {/* <div tw='max-w-[520px] text-white font-semibold md:text-sm lg:text-lg pt-2'>Watch a video multiple times to increases your understanding and memory of the information.</div> */}
                        <div tw='flex flex-row md:flex-col gap-2.5 lg:flex-row mt-8'>
                            <Button variant='blue-dtc' tw='w-[160px]' onClick={() => setPlayTutorialVideo(true)}>
                                <div tw='flex items-center justify-center'>
                                    <div>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='15' fill='none'>
                                            <path fill='#fff' d='M11.802 6.293c.93.65.93 1.764 0 2.414L4.145 14.06C2.755 15.034.5 14.287.5 12.853V2.147C.5.714 2.754-.033 4.145.939l7.657 5.354Z' />
                                        </svg>
                                    </div>
                                    <div tw='text-sm font-medium pl-2'>Watch Tutorial</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div tw='mt-6'>
                        <img src={NullImage} />
                    </div>
                </div>
            </div>



            <div tw='pl-5 relative z-10 md:hidden'>
                <div tw='bg-[#B031FA] opacity-60 blur-[250px] w-full absolute h-[600px] z-[-1]' />
                <div tw='flex w-full justify-between z-10'>
                    <div tw='pt-6'>
                        <div tw='text-white font-Poppins text-4xl pt-5 font-bold leading-snug max-w-[375px]'>
                            Welcome to Muzology
                        </div>
                        <div tw='max-w-[520px] text-[#9D91BB] font-Poppins font-medium leading-relaxed text-base pt-4'>Watch a video multiple times to increases your understanding and memory of the information.</div>
                        <div tw='flex mt-8'>
                            <Button variant='blue-dtc' tw='ml-2.5' onClick={() => setPlayTutorialVideo(true)}>
                                <div tw='flex items-center'>
                                    <div>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='15' fill='none'>
                                            <path fill='#fff' d='M11.802 6.293c.93.65.93 1.764 0 2.414L4.145 14.06C2.755 15.034.5 14.287.5 12.853V2.147C.5.714 2.754-.033 4.145.939l7.657 5.354Z' />
                                        </svg>
                                    </div>
                                    <div tw='text-sm font-medium pl-2'>Watch Tutorial</div>
                                </div>
                            </Button>
                        </div>
                        <div tw='ml-[-20px]'>
                            <img src={NullImage} />
                        </div>
                    </div>

                </div>
            </div>
            <VideoPlayerOverlay
                videoId={CHILD_TUTORIAL_VIDEO_ID}
                isOpen={playTutorialVideo}
                setIsOpen={setPlayTutorialVideo} />;
        </div>

    );
};

export default ChildHomeBannerNull;