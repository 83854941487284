import React from 'react';
import moment from 'moment';
import { css } from 'twin.macro';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { useVideos } from 'state/CatalogState';
import { useMedia } from 'react-use';

const LineClampCss = css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CurrentAssignmentCard = ({ assignment, recommendedVideo, isChild }) => {
    const navigate = useNavigate();
    const videos = useVideos();
    const isSmall = useMedia('(max-width: 540px)');
    const isMedium = useMedia('(max-width: 768px)');
    const isLarge = useMedia('(max-width: 1024px)');
    // find out the due date and generate the correct day with the tense
    let dueDate = moment.utc(assignment?.due).local();
    let daysDue = dueDate.diff(moment.now(), 'days');
    if (daysDue === 0) daysDue = 1;
    let daysNumber = Math.abs(daysDue);
    let assignmentStatus;
    let assignmentName = assignment?.name;
    let headerBackground;
    let buttonCTA = 'CONTINUE';
    let recommendedVideoPadding;
    let assignmentVideoUrlThumbnail = assignment?.videos[0]?.url_thumbnail;
    let assignmentVideoUrlThumbnailAlt = assignment?.videos[0]?.title;

    if (daysDue < 0) {
        headerBackground = '#E04E4E';
        if (daysDue === -1) assignmentStatus = `${daysNumber} DAY OVERDUE!`;
        else assignmentStatus = `${daysNumber} DAYS OVERDUE!`;
    } else if (daysDue > 0) {
        headerBackground = 'linear-gradient(90deg, #E637A8 0%, #E68137 100%)';
        if (daysDue === 1) assignmentStatus = `DUE IN ${daysNumber} DAY!`;
        else assignmentStatus = `DUE IN ${daysNumber} DAYS!`;
    }

    if (assignment?.progress?.completed === true) {
        headerBackground = '#5FC17A';
        buttonCTA = 'REVIEW';
        assignmentStatus = 'YOUR RECENT PLAYLIST - COMPLETE!';
    }
    let link = '/assignments/what-to-do-now';
    if (recommendedVideo) {
        let video = videos.find((v) => v.title === 'Order of Operations');
        recommendedVideoPadding = isSmall ? '.75rem' : isLarge ? '2.25rem' : '4.75rem';
        assignmentName = video?.title;
        assignmentVideoUrlThumbnail = video?.url_thumbnail;
        assignmentVideoUrlThumbnailAlt = video?.title;
        headerBackground = 'linear-gradient(90deg, #E637A8 0%, #E68137 100%)';
        buttonCTA = 'WATCH';
        link = `/video/${video?.id}`;
    }

    let videoPlurality = assignment?.items.length > 1 ? 'VIDEOS' : 'VIDEO';

    let title = recommendedVideo ? 'recommended video' : 'your current playlist';
    let subTitle = recommendedVideo ? 'Watch the video and take the Challenges!' : isChild ? 'Move through the playlist and reach your goal!' : 'Move through the playlist and reach your assignment goal!';
    if (recommendedVideo) assignmentStatus = 'WE THOUGHT YOU MIGHT LIKE...';
    return (
        <div tw='w-full h-full max-h-80 maxWidth[725px] lg:px-0 '>
            <div tw='flex flex-col justify-center text-center mt-1 lg:mt-2 xl:mt-6'>
                <div
                    tw='text-4xl sm:text-2xl lg:text-3xl xl:text-5xl text-white uppercase'
                    css={[
                        css`
                            font-family: 'Edo SZ', serif;
                        `
                    ]}
                >
                    {title}
                </div>
                <div tw='block sm:hidden md:block text-base lg:text-lg text-white font-extrabold mt-4 sm:mt-0'>{subTitle}</div>
            </div>
            <div
                tw='flex justify-center rounded-t-lg text-white text-lg lg:text-2xl font-extrabold items-center py-2.5 sm:py-1 lg:py-2.5 mt-4 sm:mt-0 md:mt-1.5 lg:mt-3.5 xl:mt-7'
                style={{ background: headerBackground, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.14)' }}
            >
                {assignmentStatus}
            </div>
            <div tw='flex flex-col-reverse  sm:flex-row items-center bg-LavenderWeb rounded-b-lg pr-0 sm:pr-6 h-full sm:height[65%] lg:height[75%] xl:h-full maxHeight[325px] sm:maxHeight[266px] '>
                <div tw='height[50%] sm:h-full width[100%] sm:width[50%] flex flex-col items-center'>
                    <div tw=' pt-2 lg:pt-6 xl:pt-12 font-medium'>
                        <div tw='text-lg sm:text-base md:text-xl lg:text-2xl text-center px-4 pt-3 lg:pt-0 w-full' css={[LineClampCss]}>
                            {assignmentName}
                        </div>
                        <div tw='text-lg sm:text-sm lg:text-lg text-SonicSilver text-center'>
                            {!recommendedVideo && `(${assignment?.items.length} ${videoPlurality})`}
                        </div>
                    </div>
                    <div tw='pt-2 md:pt-8 lg:pt-11 pb-4 lg:pb-16' style={{ paddingTop: recommendedVideoPadding }}>
                        <Button size='large' onClick={() => navigate(link)}>
                            {buttonCTA}
                        </Button>
                    </div>
                </div>
                <div tw='h-full w-full sm:w-1/2 flex items-center justify-center'>
                    <div tw='xl:maxWidth[400px] py-2 lg:py-5'>
                        <img
                            tw='w-full maxWidth[280px] sm:max-w-full maxHeight[225px] p-1 rounded-[.65rem]'
                            src={assignmentVideoUrlThumbnail}
                            alt={assignmentVideoUrlThumbnailAlt}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrentAssignmentCard;
