import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { apiFetcher } from 'utils/api-client';

const QueryConfig = {
    queries: {
        queryFn: apiFetcher
    }
};

const QueryProvider = (props) => {
    return <ReactQueryConfigProvider config={QueryConfig}>{props.children}</ReactQueryConfigProvider>;
};

export default QueryProvider;
