import React, { useMemo, useState } from 'react';
import 'twin.macro';
import useAxios from 'axios-hooks';

import { useOnboardStageFinished } from 'state/OnboardState';
import { ONBOARD_STAGE_VERIFY } from 'app/AccountOnboard/OnboardStages';
import { useAccount, useUser } from 'state/User/UserState';
import { useToast } from 'hooks/toast';
import { handleNetworkError } from '../../../lib/axios';
import { useCreateChild } from './CreateChildProvider';

const Intro = (props) => {
    const stageFinished = useOnboardStageFinished();
    const user = useUser();
    console.log('🚀 ~ file: Intro.js:14 ~ Intro ~ user:', user);
    const { is_parent_account } = useAccount();
    const addToast = useToast();
    const [{ loading: isRequesting }, requestResend] = useAxios({ url: '/api/resend_verification_email/', method: 'GET' }, { manual: true });
    const [sentMessage, setSentMessage] = useState('');
    const { resetSetup } = useCreateChild();

    const resendEmail = () => {
        console.log('Resending');
        setSentMessage('Sending...');
        requestResend()
            .then(() => {
                setSentMessage(`We resent the verification email to ${user.email}.`);
                addToast('Success', `We resent the verification email to ${user.email}.`, 'gradient');
            })
            .catch(handleNetworkError);
    };

    React.useEffect(() => {
        resetSetup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const skipEmail = () => {
        return stageFinished(ONBOARD_STAGE_VERIFY);
    };

    const copy = useMemo(() => {
        if (is_parent_account) {
            return 'To enter Muzology and create your child’s account, please carefully review the information in the email and click the activation link.';
        } else {
            return 'Please click the link to activate your account.';
        }
    }, [is_parent_account]);

    return (
        <div id='onboard-stage-email-verification' tw='text-EarieBlack px-4 sm:px-16 relative'>
            <div id='onboard-stage-heading' data-testid='heading' tw='text-2xl sm:text-3xl font-extrabold mt-4 sm:mt-6 text-center'>
                Verify Your Email
            </div>

            <div tw='mt-10 text-lg text-center'>
                We’ve sent an email to <span tw='font-extrabold'>{user.user.email}</span>
            </div>

            <div tw='mt-10 text-lg text-center'>
                {copy}
            </div>

            <div tw='flex items-center justify-center'>
                <div tw='background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] height[8px] w-80 mt-12 rounded-full' />
            </div>
            <div tw='mt-12 text-center font-extrabold'>
                Didn't receive an email?<br />
                <span tw='underline cursor-pointer font-extrabold' onClick={resendEmail}>
                    Click here to resend.
                </span>
            </div>
            {sentMessage && <div tw='mt-1 text-center text-[red]'>
                {sentMessage}
            </div>}
            <div tw='font-light text-center mt-2'>(Also, check your spam folder.)</div>
            <div tw='font-light text-center mt-10'>Need help? Contact us: support@muzology.com</div>

            <div id='continue' tw='color[transparent]' onClick={skipEmail}>
                continue
            </div>
            <div tw='hidden sm:absolute bottom-20 right-20'>
                <svg
                    width='63' height='68' viewBox='0 0 63 68' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M48.6003 29.4341C41.9997 30.599 36.7283 36.2936 36.5596 42.3793C36.4822 45.1727 37.4702 47.6962 39.3145 49.5444C41.1162 51.3416 43.6447 52.3602 46.4198 52.4371C47.3007 52.4616 48.2285 52.3874 49.1591 52.2136C55.7156 51.0474 61.0311 45.354 61.1998 39.2684L62.0959 6.9444C62.1 6.79475 62.1028 6.69499 62.1069 6.54534C62.0659 4.84689 61.4053 3.2311 60.2905 2.10193C59.0463 0.869339 57.3422 0.322897 55.572 0.573349L25.1858 5.82137C22.0418 6.33327 19.5292 9.50847 19.4338 12.9504L18.5599 44.4762C16.6824 43.8251 14.5281 43.6156 12.3119 44.0534C5.71126 45.2183 0.439816 50.9129 0.271114 56.9986C0.193677 59.792 1.1817 62.3155 3.02602 64.1637C4.82767 65.9609 7.35622 66.9795 10.1313 67.0565C11.0123 67.0809 11.9401 67.0068 12.8706 66.8329C19.4712 65.668 24.7426 59.9734 24.9113 53.8877L25.665 26.7016L55.0765 21.6263L54.847 29.9068C52.9708 29.2058 50.8152 29.0462 48.6003 29.4341ZM48.173 44.8478C45.9582 45.2357 44.4317 44.6443 43.7022 43.9252C43.3168 43.5151 43.1517 43.1112 43.1669 42.5625C43.2264 40.4175 45.9963 37.499 49.585 36.8497C50.1604 36.7658 50.6463 36.7294 51.1308 36.7428C52.5404 36.7819 53.497 37.2577 54.0558 37.7724C54.4412 38.1824 54.6063 38.5864 54.5911 39.1351C54.5317 41.28 51.7617 44.1985 48.173 44.8478ZM11.8405 59.4659C9.62564 59.8538 8.09914 59.2624 7.36967 58.5432C6.98429 58.1332 6.81917 57.7293 6.83438 57.1805C6.89384 55.0356 9.66378 52.1171 13.2525 51.4678C13.8279 51.3839 14.3138 51.3474 14.7983 51.3609C16.2079 51.4 17.2086 51.877 17.7233 52.3905C18.1087 52.8005 18.2738 53.2045 18.2586 53.7532C18.2432 55.8993 15.4733 58.8178 11.8405 59.4659ZM25.8765 19.0696L26.0356 13.3331C26.081 13.2844 26.1251 13.2856 26.1264 13.2357L55.4485 8.20783L55.2867 14.0441L25.8765 19.0696Z'
                        fill='url(#paint0_linear_101_62)'
                    />
                    <defs>
                        <linearGradient
                            id='paint0_linear_101_62' x1='-12.1132' y1='17.3952' x2='35.367'
                            y2='62.1229' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#E637A8' />
                            <stop offset='1' stopColor='#E68137' />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div tw='hidden sm:absolute -top-16 right-20'>
                <svg
                    width='58' height='69' viewBox='0 0 58 69' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <rect
                        x='27' y='15' width='4' height='54'
                        rx='2' fill='url(#paint0_linear_4_21)' />
                    <rect
                        x='18' y='11' width='4' height='47'
                        rx='2' fill='url(#paint1_linear_4_21)' />
                    <rect
                        x='36' y='11' width='4' height='47'
                        rx='2' fill='url(#paint2_linear_4_21)' />
                    <rect
                        x='45' width='4' height='47' rx='2'
                        fill='url(#paint3_linear_4_21)' />
                    <rect
                        x='9' width='4' height='47' rx='2'
                        fill='url(#paint4_linear_4_21)' />
                    <rect
                        y='7' width='4' height='30' rx='2'
                        fill='url(#paint5_linear_4_21)' />
                    <rect
                        x='54' y='7' width='4' height='30'
                        rx='2' fill='url(#paint6_linear_4_21)' />
                    <defs>
                        <linearGradient
                            id='paint0_linear_4_21' x1='26.4483' y1='25.9565' x2='32.774'
                            y2='26.3842' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint1_linear_4_21' x1='17.4483' y1='20.5362' x2='23.7648'
                            y2='21.0269' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint2_linear_4_21' x1='35.4483' y1='20.5362' x2='41.7648'
                            y2='21.0269' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint3_linear_4_21' x1='44.4483' y1='9.53623' x2='50.7648'
                            y2='10.0269' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint4_linear_4_21' x1='8.44828' y1='9.53623' x2='14.7648'
                            y2='10.0269' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint5_linear_4_21' x1='-0.551724' y1='13.087' x2='5.71017'
                            y2='13.849' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                        <linearGradient
                            id='paint6_linear_4_21' x1='53.4483' y1='13.087' x2='59.7102'
                            y2='13.849' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#6D1DF0' />
                            <stop offset='1' stopColor='#52D2DA' />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div tw='hidden sm:absolute -top-16 left-20'>
                <svg
                    width='72' height='70' viewBox='0 0 72 70' fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M64.6956 33.4942C66.3193 34.175 68.2131 33.5112 68.9409 31.7754L71.4362 25.824C72.1121 24.2122 71.31 22.2723 69.6864 21.5914L21.1008 1.21979C18.9775 0.329508 16.6153 0.359507 14.4926 1.21892L5.64835 4.79979C3.8795 5.51597 3.87001 7.99033 5.74349 8.77587L19.7321 14.6412L12.298 32.3713C10.2705 37.2068 6.72329 41.1135 2.16611 43.5763C0.823938 44.3256 0.273006 45.9898 0.856299 47.4006L3.4082 53.5731C4.13732 55.3367 6.36457 55.979 8.06051 55.0864C15.2501 51.2491 21.0651 45.086 24.2882 37.3988L31.7224 19.6687L39.7159 23.0203L29.1625 48.1896C26.3033 55.0089 28.3251 63.1459 34.5498 67.2137C39.3796 70.4051 45.2803 70.6925 50.2432 67.9625L53.6351 66.1775C55.2791 65.4089 55.8091 63.4444 54.8511 61.8764L51.7895 56.2191C50.9564 54.7036 49.0309 54.042 47.335 54.9345L44.12 56.648C43.4124 56.9345 42.7569 57.0969 42.0075 56.7827L42.0594 56.6587C40.9354 56.1874 40.425 54.9529 40.8929 53.837L51.7062 28.0478L64.6956 33.4942Z'
                        fill='url(#paint0_linear_101_59)'
                    />
                    <defs>
                        <linearGradient
                            id='paint0_linear_101_59' x1='-11.6281' y1='4.31073' x2='5.8904'
                            y2='64.8605' gradientUnits='userSpaceOnUse'>
                            <stop stopColor='#E637A8' />
                            <stop offset='1' stopColor='#E68137' />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default Intro;

