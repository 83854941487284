import gold_star from 'static/images/particles/gold_star.png';
import SilverBackground from '../components/backgrounds/Silver.png';
import GoldBackground from '../components/backgrounds/Gold.png';
import PlatinumBackground from '../components/backgrounds/PLATINUM.png';

export const confetti_silver = {
    name: 'Silver Confetti',
    "background": {
        "image": SilverBackground,
    },
    "fullScreen": {
        "zIndex": 1
    },
    "particles": {
        "number": {
            "value": 0
        },
        "color": {
            "value": [
                "#FFFFFF",
                "#A8A8A8",
                "#EEEEEE",
                "#989898"
            ]
        },
        "shape": {
            "type": [
                "circle",
                "triangle",
                "polygon"
            ],
            "options": {
                "polygon": [
                    {
                        "sides": 5
                    },
                    {
                        "sides": 6
                    }
                ]
            }
        },
        "opacity": {
            "value": {
                "min": 0,
                "max": 1
            },
            "animation": {
                "enable": true,
                "speed": 2,
                "startValue": "max",
                "destroy": "min"
            }
        },
        "size": {
            "value": {
                "min": 2,
                "max": 4
            }
        },
        "links": {
            "enable": false
        },
        "life": {
            "duration": {
                "sync": true,
                "value": 5
            },
            "count": 1
        },
        "move": {
            "enable": true,
            "gravity": {
                "enable": true,
                "acceleration": 10
            },
            "speed": {
                "min": 20,
                "max": 20
            },
            "decay": 0.1,
            "direction": "none",
            "straight": false,
            "outModes": {
                "default": "destroy",
                "top": "none"
            }
        },
        "rotate": {
            "value": {
                "min": 0,
                "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
                "min": 0,
                "max": 360
            },
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "roll": {
            "darken": {
                "enable": true,
                "value": 25
            },
            "enable": true,
            "speed": {
                "min": 15,
                "max": 25
            }
        },
        "wobble": {
            "distance": 30,
            "enable": true,
            "move": true,
            "speed": {
                "min": -15,
                "max": 15
            }
        }
    },
    "emitters": {
        "life": {
            "count": 0,
            "duration": 0.1,
            "delay": 0.4
        },
        "rate": {
            "delay": 0.1,
            "quantity": 150
        },
        "size": {
            "width": 0,
            "height": 0
        }
    }
}

export const confetti_gold = {
    name: 'Gold Confetti',
    "background": {
        "image": GoldBackground,
    },
    "fullScreen": {
        "zIndex": 1
    },
    "particles": {
        "color": {
            "value": [
                "#A06D28",
                "#F4DA92",
                "#F2B845",
                "#F3B945"
            ]
        },
        "move": {
            "direction": "bottom",
            "enable": true,
            "outModes": {
                "default": "out"
            },
            "size": true,
            "speed": {
                "min": 1,
                "max": 3
            }
        },
        "number": {
            "value": 500,
            "density": {
                "enable": true,
                "area": 800
            }
        },
        "opacity": {
            "value": 1,
            "animation": {
                "enable": false,
                "startValue": "max",
                "destroy": "min",
                "speed": 0.3,
                "sync": true
            }
        },
        "rotate": {
            "value": {
                "min": 0,
                "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
                "min": 0,
                "max": 360
            },
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "shape": {
            "type": [
                "circle",
                "square",
                "triangle",
                "polygon"
            ],
            "options": {
                "polygon": [
                    {
                        "sides": 5
                    },
                    {
                        "sides": 6
                    }
                ]
            }
        },
        "size": {
            "value": {
                "min": 2,
                "max": 4
            }
        },
        "roll": {
            "darken": {
                "enable": true,
                "value": 30
            },
            "enlighten": {
                "enable": true,
                "value": 30
            },
            "enable": true,
            "speed": {
                "min": 15,
                "max": 25
            }
        },
        "wobble": {
            "distance": 30,
            "enable": true,
            "move": true,
            "speed": {
                "min": -15,
                "max": 15
            }
        }
    }
};

export const confetti_platinum = {
    name: 'Platinum Confetti',
    "background": {
        "image": PlatinumBackground,
    },
    "fullScreen": {
        "zIndex": 1
    },
    "particles": {
        "color": {
            "value": [
                "#C8DBFF",
                "#C8DBFF",
                "#AAC4F7",
                "#90D9D5",
                "#497CE1",
                "#FCFCFC",
                "#E2ADDA",
                "#BBD1FC",
             
            ]
        },
        "move": {
            "direction": "bottom",
            "enable": true,
            "outModes": {
                "default": "out"
            },
            "size": true,
            "speed": {
                "min": 1,
                "max": 3
            }
        },
        "number": {
            "value": 500,
            "density": {
                "enable": true,
                "area": 800
            }
        },
        "opacity": {
            "value": 1,
            "animation": {
                "enable": false,
                "startValue": "max",
                "destroy": "min",
                "speed": 0.3,
                "sync": true
            }
        },
        "rotate": {
            "value": {
                "min": 0,
                "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
                "min": 0,
                "max": 360
            },
            "animation": {
                "enable": true,
                "speed": 60
            }
        },
        "shape": {
            "type": [
                "circle",
                "square",
                "triangle",
                "polygon",
                "emoji"
            ],
            "options": {
                "polygon": [
                    {
                        "sides": 5
                    },
                    {
                        "sides": 6
                    }
                ],
                "emoji": {
                    "particles": {
                        "size": {
                            "value": 8
                        }
                    },
                    "value": [
                        "🎤",
                        ""
                    ]
                }
            }
        },
        "size": {
            "value": {
                "min": 2,
                "max": 8
            }
        },
        "roll": {
            "darken": {
                "enable": true,
                "value": 30
            },
            "enlighten": {
                "enable": true,
                "value": 30
            },
            "enable": true,
            "speed": {
                "min": 15,
                "max": 25
            }
        },
        "wobble": {
            "distance": 30,
            "enable": true,
            "move": true,
            "speed": {
                "min": -15,
                "max": 15
            }
        }
    }

};




export const shooting_stars = {
    name: 'Shooting Stars',
    background: {},
    particles: {
        number: {
            value: 100,
            density: {
                enable: false
            }
        },

        shape: {
            type: 'images',
            images: [{ src: gold_star, height: 20, width: 20 }]
        },

        size: {
            value: 40,
            random: true
        },

        move: {
            out_mode: 'out',
            enable: true,
            random: true
        },
        line_linked: {
            enable: false
        }
    }
};



export const confetti_side = {
    name: 'Silver Confetti',
    "background": {
        "image": SilverBackground,
    },
    "fullScreen": {
        "zIndex": 1
    },
    "emitters": [
        {
            "position": {
                "x": 0,
                "y": 30
            },
            "rate": {
                "quantity": 5,
                "delay": 0.15
            },
            "particles": {
                "move": {
                    "direction": "top-right",
                    "outModes": {
                        "top": "none",
                        "left": "none",
                        "default": "destroy"
                    }
                }
            }
        },
        {
            "position": {
                "x": 100,
                "y": 30
            },
            "rate": {
                "quantity": 5,
                "delay": 0.15
            },
            "particles": {
                "move": {
                    "direction": "top-left",
                    "outModes": {
                        "top": "none",
                        "right": "none",
                        "default": "destroy"
                    }
                }
            }
        }
    ],
    "particles": {
        "color": {
            "value": [
                "#FFFFFF",
                "#A06D28",
                "#F4DA92",
                "#C78F31"
            ]
        },
        "move": {
            "decay": 0.05,
            "direction": "top",
            "enable": true,
            "gravity": {
                "enable": true
            },
            "outModes": {
                "top": "none",
                "default": "destroy"
            },
            "speed": {
                "min": 10,
                "max": 50
            }
        },
        "number": {
            "value": 0
        },
        "opacity": {
            "value": 1
        },
        "rotate": {
            "value": {
                "min": 0,
                "max": 360
            },
            "direction": "random",
            "animation": {
                "enable": true,
                "speed": 30
            }
        },
        "tilt": {
            "direction": "random",
            "enable": true,
            "value": {
                "min": 0,
                "max": 360
            },
            "animation": {
                "enable": true,
                "speed": 30
            }
        },
        "size": {
            "value": {
                "min": 0,
                "max": 2
            },
            "animation": {
                "enable": true,
                "startValue": "min",
                "count": 1,
                "speed": 16,
                "sync": true
            }
        },
        "roll": {
            "darken": {
                "enable": true,
                "value": 25
            },
            "enable": true,
            "speed": {
                "min": 5,
                "max": 15
            }
        },
        "wobble": {
            "distance": 30,
            "enable": true,
            "speed": {
                "min": -7,
                "max": 7
            }
        },
        "shape": {
            "type": [
                "circle",
                "triangle",
                "polygon"
            ],
            "options": {
                "polygon": [
                    {
                        "sides": 5
                    },
                    {
                        "sides": 6
                    }
                ]
            }
        }
    }
}

