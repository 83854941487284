import React from 'react';
import 'twin.macro';
import RecordImagePlatinum from 'static/images/records/platinum.png';

const AssignmentDetailsCard = (props) => {
    return (
        <div tw='bg-white py-4 px-6 rounded-lg w-full'>
            <div tw='font-medium'>Details</div>
            <div tw='flex items-baseline justify-between space-x-8 mt-4'>
                <div tw='flex flex-col items-center justify-items-end'>
                    <svg
                        width='42'
                        height='42'
                        viewBox='0 0 42 42'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M21 33.25a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm8.75 0a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm0-7a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm-8.75 0a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm12.25-21H31.5V3.5a1.75 1.75 0 00-3.5 0v1.75H14V3.5a1.75 1.75 0 00-3.5 0v1.75H8.75A5.25 5.25 0 003.5 10.5V35a5.25 5.25 0 005.25 5.25h24.5A5.25 5.25 0 0038.5 35V10.5a5.25 5.25 0 00-5.25-5.25zM35 35a1.75 1.75 0 01-1.75 1.75H8.75A1.75 1.75 0 017 35V19.25h28V35zm0-19.25H7V10.5a1.75 1.75 0 011.75-1.75h1.75v1.75a1.75 1.75 0 003.5 0V8.75h14v1.75a1.75 1.75 0 003.5 0V8.75h1.75A1.75 1.75 0 0135 10.5v5.25zm-22.75 10.5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5zm0 7a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z'
                            fill='#5FC17A'
                        />
                    </svg>
                    <div tw='font-bold mt-2'>Due:</div>
                    <div tw='font-medium'>12/20/20</div>
                </div>
                <div tw='flex flex-col items-center justify-items-end'>
                    <svg
                        width='32'
                        height='36'
                        viewBox='0 0 32 36'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M28.444 3.6h-7.43C20.266 1.512 18.31 0 16 0c-2.311 0-4.267 1.512-5.013 3.6H3.556C1.6 3.6 0 5.22 0 7.2v25.2C0 34.38 1.6 36 3.556 36h24.888C30.4 36 32 34.38 32 32.4V7.2c0-1.98-1.6-3.6-3.556-3.6zM16 3.6c.978 0 1.778.81 1.778 1.8s-.8 1.8-1.778 1.8-1.778-.81-1.778-1.8.8-1.8 1.778-1.8zm0 7.2c2.951 0 5.333 2.412 5.333 5.4 0 2.988-2.382 5.4-5.333 5.4s-5.333-2.412-5.333-5.4c0-2.988 2.382-5.4 5.333-5.4zm10.667 21.6H5.333v-2.52c0-3.6 7.111-5.58 10.667-5.58s10.667 1.98 10.667 5.58v2.52z'
                            fill='#515151'
                        />
                    </svg>
                    <div tw='font-bold mt-2'>Assigned:</div>
                    <div tw='font-medium'>12/20/20</div>
                </div>
                <div tw='flex flex-col items-center justify-items-end'>
                    <img
                        tw='w-10 h-10'
                        src={RecordImagePlatinum}
                        alt='img' />
                    <div tw='font-bold mt-2'>Goal:</div>
                    <div tw='font-medium'>100%</div>
                </div>
            </div>
        </div>
    );
};

AssignmentDetailsCard.propTypes = {};

export default AssignmentDetailsCard;
