import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import moment from 'moment';
import { useMedia } from 'react-use';
import RecordIndicator from '../RecordIndicator/RecordIndicator';

function RecordTile({ record, count, className }) {
    const label = `${count} ${record} Records`;
    return (
        <div aria-label={label} tw='w-full sm:w-1/3 flex flex-row sm:flex-col justify-center sm:justify-between items-center ml-0 xl:ml-8' className={className}>
            <div aria-hidden={true} tw='mr-2 mt-2 sm:mt-0 '>{count}</div>
            <RecordIndicator aria-hidden={true} level={record} />
        </div>
    );
}

const StatsBanner = ({ stats, activity }) => {
    // let statsArr = Object.values(stats);

    // find total records for each level
    const silver = stats.filter((stat) => stat.current_level === 2 || stat.current_level === 3 || stat.current_level === 4).length;
    const gold = stats.filter((stat) => stat.current_level === 3 || stat.current_level === 4).length;
    const platinum = stats.filter((stat) => stat.current_level === 4).length;
    let totalRecordsEarned = silver + gold + platinum;

    // filter activity for only quiz taken with a score of 100
    // filter activity that is after the 7 days or 30 days
    let filterActivity = activity?.filter((a) => a.name === 'quiz:taken');
    filterActivity = filterActivity?.filter((a) => a.data.score === 100);
    filterActivity = filterActivity?.filter((a) => a.data.quizLevel !== 0);
    const recordsEarnedThisWeek = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const recordsEarnedMonth = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(30, 'days'))).length;

    const largeText = useMedia('(min-width: 1150px)');

    return (
        <div tw='flex flex-col xl:flex-row items-center justify-between bg-LightPurple rounded-none sm:rounded-xl py-6 text-white font-sans '>
            <div tw='flex flex-col sm:flex-row justify-center items-center text-4xl w-full xl:w-1/2 text-center font-extrabold'>
                <RecordTile count={silver} record='Silver' tw='xl:ml-8' />
                <RecordTile count={gold} record='Gold' tw='xl:ml-6' />
                <RecordTile count={platinum} record='Platinum' tw='xl:ml-6' />
            </div>
            <div
                tw='w-full xl:w-1/2 flex flex-col sm:flex-row justify-center items-center pr-0 xl:pr-8 text-center divide-y-4 sm:divide-y-0  divide-x-0 sm:divide-x-4 pt-6 xl:pt-0'>

                <div
                    aria-label={`${totalRecordsEarned} Total records`}
                    tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center px-2 lg:px-8 minHeight[90px] xl:minHeight[150px] pt-2 xl:pt-8'>
                    <div aria-hidden={true} tw='text-4xl sm:text-lg lg:text-4xl font-extrabold'>{totalRecordsEarned}</div>
                    <div aria-hidden={true} tw='text-lg sm:text-base lg:text-lg font-medium pt-0 lg:pt-1.5'>Total Records</div>
                </div>

                <div
                    aria-label={`${recordsEarnedThisWeek} Records earned this week`}
                    tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center px-2 lg:px-8 minHeight[90px] xl:minHeight[150px] pt-2 xl:pt-8'>
                    <div aria-hidden={true} tw='text-4xl sm:text-lg lg:text-4xl font-extrabold '>{recordsEarnedThisWeek}</div>
                    <div
                        aria-hidden={true}
                        tw='text-lg sm:text-base lg:fontSize[1.16rem] font-medium pt-0 lg:pt-1.5'>{largeText ? 'New Records This Week' : ' Records This Week'}</div>
                </div>

                <div
                    aria-label={`${recordsEarnedMonth} Records earned this month`}
                    tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center px-2 lg:px-8 minHeight[90px] xl:minHeight[150px] pt-2 xl:pt-8'>
                    <div aria-hidden={true} tw='text-4xl sm:text-lg lg:text-4xl font-extrabold '>{recordsEarnedMonth}</div>
                    <div
                        aria-hidden={true}
                        tw='text-lg sm:text-base lg:fontSize[1.16rem]  font-medium pt-0 lg:pt-1.5'> {largeText ? 'New Records This Month' : ' Records This Month'}</div>
                </div>
            </div>
        </div>
    );
};

StatsBanner.propTypes = {
    stats: PropTypes.array,
    activity: PropTypes.array
};

export default StatsBanner;
