import * as React from 'react';

function SvgClassIcon21(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#F55' />
            <path
                d='M12.143 16.938h.663A7.85 7.85 0 0020 21.625a7.85 7.85 0 007.194-4.688h.663a.786.786 0 00.786-.78v-4.688a.786.786 0 00-.786-.781h-.663A7.85 7.85 0 0020 6a7.85 7.85 0 00-7.194 4.688h-.663a.786.786 0 00-.786.78v4.688c0 .43.354.782.786.782zm1.964-4.297c0-1.08 1.056-1.954 2.357-1.954h7.072c1.301 0 2.357.875 2.357 1.954v1.171c0 2.588-2.112 4.688-4.714 4.688H18.82c-2.602 0-4.714-2.1-4.714-4.688v-1.171zm3.536 3.515l.59-1.758L20 13.813l-1.768-.586-.59-1.758-.588 1.758-1.768.585 1.768.586.589 1.758zm7.444 5.537A9.385 9.385 0 0120 23.187a9.385 9.385 0 01-5.088-1.494C11.598 22.04 9 24.8 9 28.187v.47A2.351 2.351 0 0011.357 31h3.929v-3.125c0-.864.702-1.563 1.571-1.563h6.286c.87 0 1.571.699 1.571 1.563V31h3.929A2.351 2.351 0 0031 28.656v-.468c0-3.39-2.598-6.148-5.913-6.495zm-2.73 6.182a.786.786 0 00-.786.781.786.786 0 001.572 0 .786.786 0 00-.786-.781zm-4.714 0a.786.786 0 00-.786.781V31h1.572v-2.344a.786.786 0 00-.786-.781z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon21;
