export const ROLE_UNKNOWN = 0;
export const ROLE_STUDENT = 1;
export const ROLE_TEACHER = 2;
export const ROLE_MANAGER = 3;
export const ROLE_PARENT = 4;
export const ROLE_ADMIN = 100;


export const AUTH_UNKNOWN = 0;
export const AUTH_LOGIN = 1;  // Normal username / password login
export const AUTH_LDAP = 2;  // Active Directory
export const AUTH_GOOGLE = 3;  // Google Login
export const AUTH_LTI = 4;  // Canvas LTI SSO
export const AUTH_CLASSLINK = 5;  // ClassLink SSO
