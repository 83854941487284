import React, { Component } from 'react';
import { styled } from 'twin.macro';

const DropdownContainer = styled.div`
    position: relative;
`;

const DropdownControl = styled.div`
    height: 100%;
    width: 100%;
`;

const DropdownMenuContainer = styled.div`
    background-color: ${(props) => (props.dropDownBackgroundColor ? props.dropDownBackgroundColor : '#fff')};
    color: ${(props) => (props.color ? props.color : '#000')};
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    top: ${(props) => (props.dropDownHeight ? props.dropDownHeight : '100%')};
    width: 100%;
    right: 0;
    z-index: 1000;
    cursor: pointer;
    -webkit-overflow-scrolling: touch;
`;

const ItemTitle = styled.div`
    font-family: Avenir, serif;
    font-weight: 500;
    font-size: ${(props) => (props.dropDownFontSize ? props.dropDownFontSize : '14px')};
    padding: ${(props) => (props.dropDownPadding ? props.dropDownPadding : '8px')};
    cursor: pointer;
    &:hover {
        color: #fff;
        background-color: #d6d6d6;
    }
    color: ${(props) => (props.dropDownColor ? props.dropDownColor : props.selected ? 'white' : props.color)};
    background: ${(props) => (props.selected && props.selectedBackgroundColor ? props.selectedBackgroundColor : props.selected ? '#76767633' : 'transparent')};
    text-align: ${(props) => (props.dropDownTextAlign ? props.dropDownTextAlign : 'none')};
`;

const ItemSeparator = styled.div`
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const DropdownItem = ({
    item,
    selected,
    onClick,
    color,
    dropDownTextAlign,
    dropDownFontSize,
    dropDownPadding,
    selectedBackgroundColor,
    dropDownColor
}) => {
    if (item.name === '-') return <ItemSeparator />;
    return (
        <ItemTitle
            className='item-title'
            selectedBackgroundColor={selectedBackgroundColor}
            color={color}
            selected={selected}
            onClick={onClick}
            dropDownTextAlign={dropDownTextAlign}
            dropDownFontSize={dropDownFontSize}
            dropDownPadding={dropDownPadding}
            dropDownColor={dropDownColor}
        >
            {item.name || item.title}
        </ItemTitle>
    );
};

export default class Dropdown extends Component {
    static defaultProps = {
        selected: null,
        disabled: false,
        onFocus: null,
        value: null,
        onSelectItem: null,
        isHovering: false,
        menuClassName: null,
        className: null,
        items: [
            {
                id: 'profile',
                name: 'User name'
            },
            {
                id: 'settings',
                name: 'Settings'
            },
            {
                id: 'separator',
                name: '------------'
            },
            {
                id: 'log_off',
                name: 'Log Off'
            }
        ]
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.mounted = true;
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, false);
        document.addEventListener('touchend', this.handleDocumentClick, false);
        document.addEventListener('touchstart', this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleDocumentClick, false);
        document.removeEventListener('touchend', this.handleDocumentClick, false);
    }

    handleMouseDown = (event) => {
        if (this.props.onFocus && typeof this.props.onFocus === 'function') {
            this.props.onFocus(this.state.isOpen);
        }

        if (event.type === 'mousedown' && event.button !== 0) return;

        event.stopPropagation();
        event.preventDefault();

        if (!this.props.disabled) {
            this.setState({ isOpen: !this.state.isOpen });
        }
    };

    handleDocumentClick = () => {};

    handleSelection = (item) => {
        // close the dropdown on selection
        this.setState({ isOpen: false }, () => {
            if (this.props.onSelectItem) this.props.onSelectItem(item.id || item.value);
        });
    };

    renderMenu = () => {
        return (
            <DropdownMenuContainer
                dropDownHeight={this.props.dropDownHeight}
                dropDownBackgroundColor={this.props.dropDownBackgroundColor}>
                {this.props.children}
                {this.props.items
                    ? this.props.items.map((item) => {
                        const selected = this.props.selected === item.id;
                        return (
                            <DropdownItem
                                className='drop-down-item'
                                color={this.props.color}
                                key={item.id}
                                item={item}
                                selected={selected}
                                onClick={() => this.handleSelection(item)}
                                dropDownTextAlign={this.props.dropDownTextAlign}
                                dropDownFontSize={this.props.dropDownFontSize}
                                dropDownPadding={this.props.dropDownPadding}
                                dropDownColor={this.props.dropDownColor}
                                selectedBackgroundColor={this.props.selectedBackgroundColor}
								
                            />
                        );
                    })
                    : null}
            </DropdownMenuContainer>
        );
    };

    render() {
        const value = <div className='dropdown-value'>{this.props.value}</div>;
        const menu =
            this.state.isOpen || this.props.isHovering ? <div className={`dropdown-menu ${this.props.menuClassName || ''}`}>{this.renderMenu()}</div> : null;
        return (
            <DropdownContainer
                className={`dropdown ${this.props.className || ''}`}
                style={this.props.style}>
                <DropdownControl
                    className=' dropdown-control'
                    onMouseDown={this.handleMouseDown}
                    onTouchEnd={this.handleMouseDown}>
                    {value}
                </DropdownControl>
                {menu}
            </DropdownContainer>
        );
    }
}
