import * as React from 'react';

function SvgClassIcon11(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#0BE921' />
            <path
                d='M15.997 26.892c0 1.54-1.56 2.786-3.482 2.786-1.923 0-3.483-1.247-3.483-2.786 0-1.537 1.56-2.786 3.483-2.786 1.922 0 3.482 1.248 3.482 2.786zm12.536-2.786c0 1.54-1.56 2.786-3.482 2.786-1.922 0-3.482-1.246-3.482-2.786 0-1.539 1.56-2.785 3.482-2.785 1.922 0 3.482 1.246 3.482 2.785z'
                fill='#12154C'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M27.14 24.107V11.57h1.393v12.537H27.14zM15.997 12.963v13.93h-1.393v-13.93h1.393z'
                fill='#12154C'
            />
            <path
                d='M14.604 12.83a1.393 1.393 0 011.254-1.385L27 10.33a1.394 1.394 0 011.532 1.386v1.247l-13.929 1.393V12.83z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon11;
