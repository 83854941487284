import React from 'react';
import 'twin.macro';
import { useQuizSession } from 'hooks/quiz';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';
import LoadingPage from 'components/LoadingPage';
import { CONTEXT_STATE_SUBMITTING_COMPLETE } from 'modules/quiz/constants';
import { enableSubmitButton, nextQuestion, setAnswerData, setQuestionIndex } from 'modules/quiz/context';
import { level_styles } from 'app/constants/constants';
import { useMedia } from 'react-use';
import QuizPanel, { QuizPanelContents } from '../components/QuizPanel';
import QuestionPage from '../QuestionPage/QuestionPage';
import { QuizSessionPage } from '../QuizSessionPage';
import QuizProgress from '../components/QuizProgress';
import { useDevTool } from '../../../../../lib/DevTool/DevTool';
import { useQuizSessionActions } from '../../../../../state/Quizzing/QuizSessionProvider';
import { useQuizVideo } from 'state/CatalogState';

export const Container = styled.div`
    width: 990px;
    border: ${(props) => props.color};
    background: ${(props) => props.background ?? '#fff'};
`;

const QuizSession = ({ colors, onCancel, panelComponent, progress_offset, setOpen, submitButtonTextOverride, total_progress }) => {
    const dispatch = useDispatch();
    const session = useQuizSession();
    useDevTool('_Quiz Session', session);
    const {
        quiz,
        context,
        instance,
        question,
        question_text,
        button_states,
        answerData
    } = session;


    // actions
    const {
        setChoice,
        textChanged,
        setAnswerData,
        enableSubmitButton,
        submitAnswer,
        nextQuestion
    } = useQuizSessionActions();

    // quiz: getQuiz(state, quiz_id),
    const handleProgressSelection = (index) => {
        dispatch(setQuestionIndex(index));
    };
    const isSmall = useMedia('(min-width: 640px)');

    // get the video for this quiz
    const video = useQuizVideo(quiz.id);

    if (!context) {
        return <h1>Error: QuizSession with no quiz context </h1>;
    }

    // get the contents
    let contents = null;
    if (context.state === CONTEXT_STATE_SUBMITTING_COMPLETE) {
        // show a wait screen until the quiz is finished submitting
        contents = (
            <QuizPanelContents>
                <LoadingPage
                    color={colors ? colors.loadingColor : null}
                    background={colors ? colors.backgroundImage : null}
                    message='Please wait, submitting...'
                />
            </QuizPanelContents>
        );
    } else {
        contents = (
            <QuestionPage
                // the system state
                context={context}
                // the question content
                question={question}
                question_text={question_text}
                // ui state
                level={instance.level}
                buttonState={button_states}
                choiceColor={colors?.choiceColor ?? null}
                submitButtonTextOverride={submitButtonTextOverride}

                // input state
                answerData={answerData}

                // actions
                submitQuestion={submitAnswer}
                nextQuestion={nextQuestion}
                enableSubmitButton={enableSubmitButton}
                setQuestionChoice={setChoice}
                freeEntryTextChanged={textChanged}
                setAnswerData={setAnswerData}
            />
        );
    }
    // get the panel type
    const PanelType = panelComponent || QuizPanel;
    return (
        <QuizSessionPage full={true} className='quiz-session-page'>
            <PanelType
                className={` quiz-session-panel ${level_styles[instance.level]}`}
                colors={colors}
                // header
                title={video ? video?.title : ''}
                level={instance.level}
                onCancel={onCancel}
                setOpen={setOpen}
                // progress={progress}
                current_question_index={context.question_index}
                onProgressSelect={handleProgressSelection}
                currentStep={context.question_index}
                stepCount={10}
                // the system state
                context={context}
                contextState={context.state}
                // augmented progress
                total_progress={total_progress}
                progress_offset={progress_offset}
                progress={context.progress}
            >
                {!isSmall && (
                    <QuizProgress
                        tw='mb-4 sm:mb-10'
                        color={colors}
                        progress={context.progress}
                        current={context.question_index}
                        onSelect={handleProgressSelection}
                    />
                )}
                {contents}
            </PanelType>
        </QuizSessionPage>
    );
};

export default QuizSession;
