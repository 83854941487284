import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';


const AddToPlaylistIcon = (props) => {
    return (
        <svg
            width='38'
            height='35'
            viewBox='0 0 38 35'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0)'>
                <path
                    d='M25.3476 11.0225H36.7204C36.9451 11.0225 37.1417 10.8244 37.1417 10.5979V8.13564C37.1417 7.90922 36.9451 7.71111 36.7204 7.71111H26.3023L21.5566 2.92802C21.4443 2.81482 21.332 2.78651 21.1916 2.81482C21.1354 2.70161 20.995 2.6167 20.8546 2.6167H18.4115C18.1869 2.6167 17.9903 2.81482 17.9903 3.04123V24.1547C16.6705 23.6736 15.098 23.5321 13.4692 23.8717C9.79061 24.6642 7.29139 27.6076 7.8811 30.4378C8.4708 33.2681 11.9529 34.9096 15.6034 34.1171C19.0574 33.3813 21.4443 30.7492 21.2477 28.117V7.31488L24.6175 10.7112C24.7017 10.7961 24.814 10.8244 24.9264 10.8244C24.9544 10.8244 24.9825 10.8244 24.9825 10.8244C25.0668 10.9376 25.2072 11.0225 25.3476 11.0225Z'
                    fill='white'/>
                <path
                    d='M8.20771 2.6167C8.57769 2.6167 8.93252 2.76227 9.19414 3.0214C9.45575 3.28052 9.60272 3.63197 9.60272 3.99843V8.14363H13.7878C14.1577 8.14363 14.5126 8.2892 14.7742 8.54833C15.0358 8.80745 15.1828 9.1589 15.1828 9.52536C15.1828 9.89182 15.0358 10.2433 14.7742 10.5024C14.5126 10.7615 14.1577 10.9071 13.7878 10.9071H9.60272V15.0523C9.60272 15.4187 9.45575 15.7702 9.19414 16.0293C8.93252 16.2884 8.57769 16.434 8.20771 16.434C7.83773 16.434 7.48291 16.2884 7.22129 16.0293C6.95968 15.7702 6.8127 15.4187 6.8127 15.0523V10.9071H2.62768C2.2577 10.9071 1.90287 10.7615 1.64126 10.5024C1.37964 10.2433 1.23267 9.89182 1.23267 9.52536C1.23267 9.1589 1.37964 8.80745 1.64126 8.54833C1.90287 8.2892 2.2577 8.14363 2.62768 8.14363H6.8127V3.99843C6.8127 3.63197 6.95968 3.28052 7.22129 3.0214C7.48291 2.76227 7.83773 2.6167 8.20771 2.6167V2.6167Z'
                    fill='white'/>
                <path
                    d='M36.9176 14.8999H25.5448C25.3201 14.8999 25.1235 15.098 25.1235 15.3244V17.7867C25.1235 18.0132 25.3201 18.2113 25.5448 18.2113H36.9176C37.1423 18.2113 37.3388 18.0132 37.3388 17.7867V15.3244C37.3388 15.098 37.1423 14.8999 36.9176 14.8999Z'
                    fill='white'/>
                <path
                    d='M36.9176 22.145H25.5448C25.3201 22.145 25.1235 22.3431 25.1235 22.5696V25.0318C25.1235 25.2583 25.3201 25.4564 25.5448 25.4564H36.9176C37.1423 25.4564 37.3388 25.2583 37.3388 25.0318V22.5696C37.3388 22.3431 37.1423 22.145 36.9176 22.145Z'
                    fill='white'/>
            </g>
            <defs>
                <clipPath id='clip0'>
                    <rect
                        width='36.9267'
                        height='33.3241'
                        fill='white'
                        transform='translate(0.412109 0.990967)'/>
                </clipPath>
            </defs>
        </svg>
    );
};

AddToPlaylistIcon.propTypes = {
    anyProp: PropTypes.any
};

export default AddToPlaylistIcon;

