import React from 'react';

const SvgIconLikedVideos = (props) => (
    <svg
        width='1em'
        height='1em'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z'
            fill='#fff'
        />
        <path
            d='M16.79 6.722a4.575 4.575 0 00-2.81-1.205 4.645 4.645 0 00-2.965.81 4.635 4.635 0 00-3.138-.802 4.526 4.526 0 00-2.875 1.43 4.044 4.044 0 00-1.07 2.896 4.081 4.081 0 001.307 2.808l4.247 4.04a2.19 2.19 0 001.5.587 2.19 2.19 0 001.5-.586l4.248-4.04c.418-.386.752-.846.981-1.354a3.981 3.981 0 00.03-3.215 4.175 4.175 0 00-.954-1.37z'
            fill='url(#prefix__paint0_linear)'
        />
        <defs>
            <linearGradient
                id='prefix__paint0_linear'
                x1={10.999}
                y1={5.5}
                x2={10.999}
                y2={17.286}
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1E215F' />
                <stop
                    offset={1}
                    stopColor='#070A3B' />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgIconLikedVideos;
