import React from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import FilledRecords from 'static/records/FilledRecordsAssets';
import VideoInfoIcon from 'images/icons/VideoInfoIcon';

const RecordPlaque2 = (props) => {
    const { level, total, earned, title } = props;

    const percentage = parseInt(earned / total * 100);
    let ScoreText = `${percentage}%`;
    let FilledRecordWithLevelAndScore = FilledRecords[level][percentage];
    
    return (
        <RecordPlaqueContainer>
            <div className='record-plaque--percent'>
                <div className='record-plaque--percent-text'>{ScoreText}</div>
                <div className='record-plaque--percent-image'>
                    <img src={FilledRecordWithLevelAndScore} />
                </div>
            </div>

            <p className='record-plaque--text'>
                {`${earned} of ${total} Students`}<br/>{`Earned the ${title} Record`}
            </p>

            <div className='record-plaque--info'>
                <VideoInfoIcon />
            </div>
        </RecordPlaqueContainer>
    );
};

RecordPlaque2.propTypes = {
    handleTakeChallenge: PropTypes.func,
    level: PropTypes.number,
    total: PropTypes.number,
    earned: PropTypes.number
};

export default RecordPlaque2;

const RecordPlaqueContainer = styled.div`
    background: #1A1717;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
    border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    min-height: 364px;
    padding: 26px 10px 32px;
    margin: 0 10px;
    width: 330px;

    .record-plaque--percent {
        position: relative;
        width: 212px;
        height: 212px;

        .record-plaque--percent-text {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #FFFFFF;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .record-plaque--percent-image {
            width: 100%;
            height: 100%;
        }
    }

    .record-plaque--text {
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF;
        margin: 25px 0 0;
    }

    .record-plaque--info {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 26px;
        top: 18px;
        cursor: pointer;
    }
`;