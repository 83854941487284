import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { recordImageURL } from 'static/records/FilledRecordsAssets';
// import RecordImageSilver from 'static/images/records/silver.png';
// import RecordImageGold from 'static/images/records/gold.png';
// import RecordImagePlatinum from 'static/images/records/platinum.png';
//
// const RecordImages = [
//     RecordImageSilver,
//     RecordImageGold,
//     RecordImagePlatinum
// ];


const RecordImage = ({ level = 0, value = 100, text = undefined, ...props }) => {
    // const levelIndex = Math.min(Math.max(level ?? 0, 0), 3);
    // const index = level % RecordImages.length;

    // clamp the level [0..2]
    level = Math.min(Math.max(level, 0), 3);

    if (level === 0)
        value = 'blank';
    else
        // clamp the value [0..100]
        value = Math.min(Math.max(value, 0), 100);

    // get the correct record url
    const url = recordImageURL(level, value);

    return (
        <div
            tw='relative flex items-center justify-center w-20'
            {...props}
        >
            <img
                tw='w-full h-full'
                src={url}
                alt={`Level ${level} record (${value}%)`}
            />
            {text && <span tw='text-xs font-black absolute text-white'>{text}</span>}
        </div>
    );
};

RecordImage.propTypes = {
    level: PropTypes.number,
    value: PropTypes.number,
    text: PropTypes.string
};

export default RecordImage;

