import React from 'react';

export const RemoveFromPlaylistIcon = (props) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.36 10.1642C11.5304 10.3353 11.6261 10.5675 11.6261 10.8096C11.6261 11.0517 11.5304 11.2838 11.36 11.455C11.1896 11.6262 10.9585 11.7224 10.7175 11.7224C10.4766 11.7224 10.2455 11.6262 10.0751 11.455L6.22058 7.58244L2.36603 11.4679C2.28167 11.5527 2.18151 11.6199 2.07129 11.6658C1.96106 11.7117 1.84292 11.7353 1.72361 11.7353C1.6043 11.7353 1.48616 11.7117 1.37593 11.6658C1.2657 11.6199 1.16555 11.5527 1.08118 11.4679C0.99682 11.3832 0.929898 11.2826 0.884241 11.1718C0.838583 11.0611 0.815084 10.9424 0.815084 10.8225C0.815084 10.7026 0.838583 10.5839 0.884241 10.4732C0.929898 10.3625 0.99682 10.2618 1.08118 10.1771L4.93573 6.27867L0.971972 2.26408C0.801591 2.0929 0.705872 1.86073 0.705872 1.61865C0.705872 1.37657 0.801591 1.1444 0.971972 0.973218C1.14235 0.802039 1.37344 0.705872 1.6144 0.705872C1.85535 0.705872 2.08644 0.802039 2.25682 0.973218L6.22058 5.00071L10.1843 1.0184C10.2687 0.933639 10.3689 0.866404 10.4791 0.820533C10.5893 0.774661 10.7074 0.751052 10.8268 0.751052C10.9461 0.751052 11.0642 0.774661 11.1744 0.820533C11.2847 0.866404 11.3848 0.933639 11.4692 1.0184C11.5535 1.10316 11.6205 1.20378 11.6661 1.31452C11.7118 1.42527 11.7353 1.54396 11.7353 1.66383C11.7353 1.7837 11.7118 1.90239 11.6661 2.01314C11.6205 2.12388 11.5535 2.2245 11.4692 2.30926L7.50543 6.27867L11.36 10.1642Z" fill="white" />
        </svg>

    );
};
