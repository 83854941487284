import React, { useMemo, useRef, useState } from 'react';
import 'twin.macro';
import tw, { styled } from 'twin.macro';
import { AssignmentGradebook, ClassIcon, SlideOutSidebar, TabNavigator, VideoGradebook, NullContainer, Modal } from 'components';
import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import LoadingPage from 'components/LoadingPage';
import LoadingOverlay from 'components/LoadingOverlay';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { useClassActions, useClasses, useClassStudents } from 'state/Teacher/ClassState';
import { ThreeDots } from 'assets/icons';
import { compareNumbers } from 'utils';
import { Helmet } from 'react-helmet';
import CloseButton from '../../../../components/AnswerKey/CloseButton';
import Button from '../../../../components/Button/Button';
import { useTeacherAssignments } from '../../../../state/Teacher/TeacherProvider';
import { sortStudentsByName } from '../../../../utils/sorting';
import { useAccount, useUser } from '../../../../state/User/UserState';
import ClassInviteModal from './ClassInviteModal';
import EditClassDialog from './EditClassDialog/EditClassDialog';
import RosterTab from './tabs/RosterTab';
import StudentsStats from './tabs/StudentStats';
import DeleteClassModal from './DeleteClassModal';
import ImportClassTypeDialog from './ImportClassDialog/ImportClassTypeDialog';
import ImportClassDialog from './ImportClassDialog/ImportClassDialog';

const S = {
    Container: styled.div`
        position: relative;
        background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;

        height: 100%;
        overflow-y: auto;

        font-family: Avenir, serif;

        ::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 768px) {
            padding: 6px;
        }
    `,
    TitleRow: styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
    `,
    ClassName: styled.div`
        position: relative;
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
        color: #ffffff;

        display: flex;
        flex-direction: row;
    `,
    MenuButton: styled.button`
        margin-left: 15px;

    `,
    InviteCode: styled.div`
        background: rgba(42, 38, 77, 0.5);
        border-radius: 5px;

        height: 38px;
        padding-left: 20px;
        padding-right: 20px;

        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;

        display: flex;
        align-items: center;
        cursor: pointer;
        color: #ffffff;

        .code-select {
            user-select: text;
        }
    `,
    ClassIcon: styled.div`
        width: 29px;
        height: 29px;
        margin-right: 10px;
    `,
    MaxWidthContainer: styled.div`
        font-family: Avenir, serif;

        width: 1492px;
        max-width: 1492px;
        margin: 0 auto;
        padding: 40px 27px;

        @media only screen and (max-width: 1492px) {
            max-width: 100%;
            width: 100%;
        }
    `
};

const ActionMenu = ({ onEdit, onDelete }) => {
    return (
        <div tw='mx-4 relative rounded-lg'>
            <div tw='absolute top-0 right-0 transform translate-x-full -translate-y-full '>
                <ActionMenuCloseIcon />
            </div>
            <div tw='text-left block py-0.5 my-4 px-2 text-sm rounded-md text-DarkLiver hover:bg-LavenderWeb' onClick={onEdit}>
                Edit Class
            </div>
            <span tw='bg-GainsboroGrey h-0.5 block' />
            <div tw='text-left block py-0.5 my-4 px-2 text-sm rounded-md text-DestructiveAction hover:bg-LavenderWeb' onClick={onDelete}>
                Delete Class
            </div>
        </div>
    );
};

function ActionMenuCloseIcon(props) {
    return (
        <svg
            width={31} height={31} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <g filter='url(#prefix__filter0_d)'>
                <circle cx={15.5} cy={15.5} r={12.5} fill='#fff' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M19.513 11.467a.891.891 0 00-1.293 0l-2.708 2.714-2.709-2.714a.891.891 0 00-1.293 0 .896.896 0 000 1.296l2.709 2.714-2.709 2.714a.896.896 0 000 1.296c.185.185.431.247.678.247.246 0 .492-.062.677-.247l2.708-2.715 2.709 2.715c.184.185.43.247.677.247.246 0 .492-.062.677-.247a.896.896 0 000-1.296l-2.709-2.714 2.709-2.714c.246-.37.246-.926-.123-1.296z'
                    fill='#6A7277'
                />
            </g>
            <defs>
                <filter
                    id='prefix__filter0_d' x={0.5} y={0.5} width={30}
                    height={30} filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity={0} result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                    <feOffset />
                    <feGaussianBlur stdDeviation={1.25} />
                    <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                    <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
                    <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
                </filter>
            </defs>
        </svg>
    );
}

const AddIcon = () => <svg
    width='25' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'
    style={{ display: 'inline', margin: '0 10px' }}>
    <path
        d='M24 0H1C.447 0 0 .447 0 1v23c0 .553.447 1 1 1h23c.553 0 1-.447 1-1V1c0-.553-.447-1-1-1Zm-5.5 13.25a.25.25 0 0 1-.25.25H13.5v4.75a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25V13.5H6.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h4.75V6.75a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v4.75h4.75a.25.25 0 0 1 .25.25v1.5Z'
        fill='#fff'
    />
</svg>;


const ClassesContainer = () => {
    // get the class and mode from the URL parameters
    const { google_for_classroom } = useUser();
    // const { class_id, tab_name } = useParams();
    const match = useMatch('/classes/:class_id/:tab_name');
    const { class_id = null, tab_name = 'stats' } = match?.params ?? {};
    const navigate = useNavigate();
    const account = useAccount();
    const classes = useClasses();
    const assignments = useTeacherAssignments();
    // if join codes are disabled - don't show join code (CoreCivic modification)
    const { disable_join_code } = useAccount();

    const { removeClass, addClasses } = useClassActions();
    const [activeTab, setActiveTab] = useState(0);

    const { students, isLoading } = useClassStudents(class_id ?? null);

    // sort students and attach 'last, first' as full_name to each student
    const amendedStudentsFullName = useMemo(() => {
        if (!students) return [];
        return students
            .map((student) => {
                let fullName = '';
                if (student.loading) {
                    // this student is still loading
                    fullName = 'Loading';
                } else {
                    fullName = `${student?.user?.last_name}, ${student?.user?.first_name}`;
                }
                return { ...student, user: { ...student.user, full_name: fullName } };
            })
            .sort(sortStudentsByName);
    }, [students]);

    // get the selected class
    const selected = useMemo(() => classes?.find((cls) => cls.id === class_id), [class_id, classes]);

    const path = `/classes/${class_id}`;
    const tabs = [
        {
            name: 'Student Stats',
            path: 'stats'
        },
        {
            name: 'Assignment Grades',
            path: 'assignment_grades'
        },
        {
            name: 'Video Grades',
            path: 'video_grades'
        },
        {
            name: 'Student Roster',
            path: 'roster'
        }
    ];

    const ref = useRef();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [openNewClass, setOpenNewClass] = useState(!classes?.length ? true : false);
    const [openDeleteClass, setOpenDeleteClass] = useState(false);
    const [openImportType, setOpenImportType] = useState(false);
    const [openImportClass, setOpenImportClass] = useState(false);

    // called when used clicks "+ Add Class" button
    const createClass = () => {
        // if this is a Google Classroom user, prompt to import class
        if (google_for_classroom) {
            setOpenImportType(true);
        } else {
            setOpenNewClass(true);
        }
    };

    // called when user clicks "Import Class" button
    const createClassType = (classType) => {
        // class type will be 'google' or 'muzology'
        if (classType === 'google') {
            setOpenImportClass(true);
        } else {
            setOpenNewClass(true);
        }
        setOpenImportType(false);
    };

    const classesImported = (classes) => {
        console.log('classes imported', classes);
        addClasses(classes);
    };

    useOnClickOutside(ref, () => setMenuOpen(false));

    const onClickMenu = () => setMenuOpen(!isMenuOpen);
    const onEdit = () => setEditing(!isEditing);
    const onDelete = () => setOpenDeleteClass(true);
    const [inviteStudent, setInviteStudent] = useState(false);

    // delete this class
    const [_, deleteClass] = useAxios({ url: `/api/classrooms/${class_id}/`, method: 'DELETE' }, { manual: true });
    const handleDeleteClass = () => {
        // delete the class
        deleteClass()
            .then((response) => {
                // remove the class from the list
                removeClass(class_id);

                // change to another tab
                navigate('/classes/', { replace: true });

                // close the modal
                setOpenDeleteClass(false);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    // on class created
    const onClassCreated = (cls) => {
        console.log('class created', cls);
        if (cls) {
            navigate(`/classes/${cls.id}/${tab_name ?? ''}`);
        }
    };

    const inviteCodeRef = useRef(null);

    function selectContents(el) {
        let range = document.createRange();
        range.selectNodeContents(el);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    // sort classes
    const sortedClasses = useMemo(() => classes?.sort((a, b) => compareNumbers(a.display_order, b.display_order)), [classes]);

    // TODO: make distinction between loading & an empty list
    if (!classes) {
        return <LoadingPage color='#123abc' background='#450762' message='Loading classes' />;
    }

    // if no class or tab selected - redirect to first class
    if ((!class_id || !tab_name) && sortedClasses?.length) {
        return <Navigate to={`/classes/${class_id ? class_id : sortedClasses[0].id}/${tab_name ? tab_name : tabs[0].path}`} />;
    }

    // if name includes 'corecivic' - don't show join code
    let showJoinCode = true;
    if (account.name.includes('corecivic')) {
        showJoinCode = false;
    }
    const videoGradesPanel = students?.length ? (
        <VideoGradebook students={amendedStudentsFullName} />
    ) : (
        <NullContainer>
            <p>
                Your students can progress through Muzology at their own pace or by using the playlists you assign. Once your
                students start watching videos and taking the math Challenges, their performance data will appear on this
                page.&nbsp;
            </p>
            {/*
                    <p tw='mt-10'>
                (If you haven’t done so already,&nbsp;
                <span tw='underline cursor-pointer' onClick={createClass}>
                                                click here
                </span>
                &nbsp; to add a class and invite your students to join.)
            </p>*/}
        </NullContainer>
    );

    return (
        <S.Container>
            <Helmet>
                <title>Classes</title>
            </Helmet>

            <SlideOutSidebar classes={sortedClasses} onAddClass={createClass} selectedClass={selected} />
            <EditClassDialog isOpen={isEditing} setIsOpen={setEditing} classId={class_id} />
            <EditClassDialog isOpen={openNewClass} setIsOpen={setOpenNewClass} onSaveClass={onClassCreated} />
            <DeleteClassModal show={openDeleteClass} onAccept={handleDeleteClass} onCancel={() => setOpenDeleteClass(false)} />

            <ImportClassTypeDialog isOpen={openImportType} setIsOpen={setOpenImportType} onSelected={createClassType} />
            <ImportClassDialog isOpen={openImportClass} setIsOpen={setOpenImportClass} onClassesImported={classesImported} />

            {selected && (
                <div tw='[max-width:var(--layout-width)] mx-auto w-full py-10 pl-10'>
                    <S.TitleRow>
                        <S.ClassName>
                            <S.ClassIcon>
                                <ClassIcon name={selected?.display_icon ?? 'ClassIcon00'} />
                            </S.ClassIcon>

                            <h1>{selected?.title}</h1>

                            <S.MenuButton aria-label='Class Menu' onClick={onClickMenu} tabIndex='0'>
                                <ThreeDots />
                                {isMenuOpen && (
                                    <div
                                        ref={ref}
                                        tw='left-0 absolute mt-3 width[212px] z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                                        role='menu'
                                        aria-orientation='vertical'
                                        aria-labelledby='user-menu'
                                    >
                                        <ActionMenu onEdit={onEdit} onDelete={onDelete} />
                                    </div>
                                )}
                            </S.MenuButton>
                        </S.ClassName>

                        {!disable_join_code && (
                            <S.InviteCode
                                onClick={() => {
                                    setInviteStudent(true);
                                    // navigator.clipboard.writeText(selected.invite_code);
                                    // window._addNotification('Class join code copied!', `${selected.invite_code}`, 'gradient');
                                }}

                            >
                                Class Join Code &nbsp;
                                <span ref={inviteCodeRef} className='code-select'>
                                    {selected.invite_code}
                                </span>
                            </S.InviteCode>
                        )}
                    </S.TitleRow>

                    <TabNavigator
                        variant='enclosed'
                        items={tabs}
                        path={path}
                        activeTabHandler={setActiveTab}
                        activeTab={activeTab}
                    />

                    <div>
                        <LoadingOverlay show={isLoading} />
                        <Routes>
                            <Route path='stats' element={<StudentsStats classId={class_id} setOpenNewClass={setOpenNewClass} />} />
                            <Route path='assignment_grades' element={<AssignmentGradebook students={amendedStudentsFullName} classId={class_id} assignments={assignments} />} />
                            <Route path='video_grades' element={videoGradesPanel} />
                            <Route path='roster' element={<RosterTab classId={class_id} selectedClass={selected} setOpenNewClass={setOpenNewClass} />} />
                        </Routes>
                    </div>
                </div>
            )}

            {classes?.length === 0 && <div>
                <NullContainer tw='z-10 m-auto font-medium text-2xl text-white' style={{ background:'transparent' }}>
                    <div tw='pt-10'>
                        <span>Create a class by </span>
                        <span tw='underline cursor-pointer' onClick={() => setOpenNewClass(true)}>
                            clicking here
                        </span>{' '}
                        <span>or using the</span>
                        <AddIcon />
                        <span>
                            button in the upper left. Once you create a class, you will see a Class Join Code button on this page (in the upper right).
                            Click on the button for info on how students can join your Muzology classes.
                        </span>
                    </div>
                    <p tw='mt-10'>
                        (If your school uses Google Classroom, you and your students can go to app.muzology.com and log in using Google. This way, students will automatically be added to your classes.)
                    </p>
                </NullContainer>
            </div>}
            <ClassInviteModal inviteStudent={inviteStudent} setInviteStudent={setInviteStudent} selected={selected} />

        </S.Container>
    );
};

export default ClassesContainer;
