import React from 'react';

const PlaylistSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'>
            <path d='M16.5789 11.25C16.5789 10.8358 16.2432 10.5 15.8289 10.5H7.75C7.33579 10.5 7 10.8358 7 11.25C7 11.6642 7.33579 12 7.75 12H15.8289C16.2432 12 16.5789 11.6642 16.5789 11.25ZM16.5789 14.25C16.5789 13.8358 16.2432 13.5 15.8289 13.5H7.75C7.33579 13.5 7 13.8358 7 14.25C7 14.6642 7.33579 15 7.75 15H15.8289C16.2432 15 16.5789 14.6642 16.5789 14.25ZM7 17.25C7 17.6642 7.33579 18 7.75 18H12.636C13.0502 18 13.386 17.6642 13.386 17.25C13.386 16.8358 13.0502 16.5 12.636 16.5H7.75C7.33579 16.5 7 16.8358 7 17.25ZM19.1754 10.5C18.6232 10.5 18.1754 10.9477 18.1754 11.5V16.635C17.928 16.5525 17.6566 16.5 17.3772 16.5C16.7421 16.5 16.133 16.7371 15.6839 17.159C15.2348 17.581 14.9825 18.1533 14.9825 18.75C14.9825 19.3467 15.2348 19.919 15.6839 20.341C16.133 20.7629 16.7421 21 17.3772 21C18.0123 21 18.6214 20.7629 19.0705 20.341C19.5196 19.919 19.7719 19.3467 19.7719 18.75V13C19.7719 12.4477 20.2196 12 20.7719 12H21.4167C21.8309 12 22.1667 11.6642 22.1667 11.25C22.1667 10.8358 21.8309 10.5 21.4167 10.5H19.1754Z' fill='#E73CA2'/>
            <path d='M4.66537 23.3346C4.0237 23.3346 3.4742 23.106 3.01687 22.6486C2.55953 22.1913 2.33126 21.6422 2.33203 21.0013V7.0013C2.33203 6.35964 2.5607 5.81014 3.01803 5.3528C3.47537 4.89547 4.02448 4.66719 4.66537 4.66797H11.6654L13.9987 7.0013H23.332C23.9737 7.0013 24.5232 7.22997 24.9805 7.6873C25.4379 8.14464 25.6661 8.69375 25.6654 9.33464V21.0013C25.6654 21.643 25.4367 22.1925 24.9794 22.6498C24.522 23.1071 23.9729 23.3354 23.332 23.3346H4.66537Z' stroke='#E73CA2' strokeWidth='1.5'/>
        </svg>

    );
};

export default PlaylistSVG;