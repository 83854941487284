import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWebTourState } from 'state/WebTourState';
import { useSetOnboardState } from '../../state/OnboardState';

const WebTour = () => {
    const { tourSteps, stepIndex, setIndex } = useWebTourState();
    const setOnboardState = useSetOnboardState();
    const [run, setRun] = useState(true);
    const navigate = useNavigate();
    const classesPath = '/classes/d37a864c-8e0f-42d2-a477-f32db81b56bb';
    const location = useLocation();
    let disableScroll = location.pathname === `${classesPath}/stats` || location.pathname === '/videos';

    useEffect(() => {
        navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCallback = (data) => {
        const { action, index, status, type } = data;

        if (action === 'close') {
            setOnboardState({ enable_demo_mode: false });
        }
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            if (type === 'step:after' && index === 3) {
                setRun(false);
                navigate('/classes');
                setTimeout(() => {
                    setRun(true);
                }, 1200);
            }
            if (type === 'step:after' && index === 6) {
                setRun(false);
                navigate(`${classesPath}/assignment_grades`);
                setTimeout(() => {
                    setRun(true);
                }, 800);
            }
            if (type === 'step:after' && index === 8) {
                setRun(false);
                setTimeout(() => {
                    setRun(true);
                }, 500);
            }
            if (type === 'step:after' && index === 9) {
                setRun(false);
                navigate(`${classesPath}/video_grades`);
                setTimeout(() => {
                    setRun(true);
                }, 800);
            }
            if (type === 'step:after' && index === 13) {
                setRun(false);
                navigate(`${classesPath}/roster`);
                setTimeout(() => {
                    setRun(true);
                }, 800);
            }
            if (type === 'step:after' && index === 14) {
                setRun(false);
                navigate('/videos');
                setTimeout(() => {
                    setRun(true);
                }, 1200);
            }
            if (type === 'step:after' && index === 17) {
                setRun(false);
                navigate('/video/7eaf12ea-de19-4797-be77-90b9c83c7fef');
                setTimeout(() => {
                    setRun(true);
                }, 1200);
            }
            if (type === 'step:after' && index === 20) {
                setRun(false);
                navigate('/assignments');
                setTimeout(() => {
                    setRun(true);
                }, 1200);
            }
            if (type === 'step:after' && index === 25) {
                setRun(false);
                navigate('/awards');
                setTimeout(() => {
                    setRun(true);
                }, 1200);
            }
            if (type === 'step:after' && index === 29) {
                setOnboardState({ enable_demo_mode: false });
            }
            setIndex(index + (action === ACTIONS.PREV ? -1 : 1));

        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            setRun(false);
        }
    };
    return (
        <Joyride
            callback={handleCallback}
            run={run}
            stepIndex={stepIndex}
            steps={tourSteps}
            continuous
            disableBeacon={true}
            hideBackButton={true}
            disableScrolling={disableScroll}
            disableCloseOnEsc={true}
            disableOverlayClose={true}
            scrollDuration={0}
            styles={{
                options: {
                    arrowColor: '#E0E2F7',
                    backgroundColor: '#E0E2F7',
                    primaryColor: '#887BE3',
                    textColor: '#1A1717',
                    width: 450,
                    zIndex: 1000,
                    marginTop: 20
                }
            }}
        />
    );
};
WebTour.propTypes = {
    anyProp: PropTypes.any
};

export default WebTour;
