import React from 'react';
import tw, { css } from 'twin.macro';
import { RemoveFromPlaylistIcon } from '../PlaylistSvgs/RemovePlaylistIcon';
import { AddToPlaylistIcon } from '../PlaylistSvgs/AddPlaylistIcon';
import HeartFavoriteIconNew from 'images/icons/HeartFavoriteIconNew';
import { useToggleFavoriteVideo } from 'state/Student/VideoStats';

const TeacherInfo = ({ video, showTitle, handleAddingVideoToPlaylist, isPlaylistDropdownOpen, selectedVideo, tour, isHovering, view_count, videoStats }) => {
    const toggleFavoriteVideo = useToggleFavoriteVideo(video?.id);
    const isFavorite = videoStats?.favorite ?? false;

    const handleToggleFavorite = (e) => {
        e.stopPropagation();
        toggleFavoriteVideo(e);
    };

    return (
        <div tw='flex flex-col justify-between rounded-b-md h-full md:min-h-[115px] ' id='no-hover'
            css={[isHovering ? tw`bg-VideoHoverPurple` : tw`bg-VideoPurple`]}
        >
            <div tw='pt-3 px-1.5 rounded-bl-md'
            >
                <div tw='whitespace-normal pl-2.5 text-white font-Poppins text-sm sm:text-base font-medium leading-snug '>
                    {showTitle && <div>{video.title}</div>}
                    <div
                        tw='hidden mt-2 pb-3 flex-col flex-shrink-0 text-VideoDescription font-Poppins text-xs font-normal leading-snug'
                        css={[isHovering && tw`flex`]}
                    >
                        {video?.description}
                    </div>
                </div>
                {/* {children} */}

            </div>
            <div tw='flex justify-between px-5 pb-3'
                css={[isHovering && tw`pb-3`]}

            >
                <div
                    data-tooltip-id='my-tooltip'
                    data-tooltip-content={selectedVideo && isPlaylistDropdownOpen ? 'REMOVE FROM PLAYLIST' : 'ADD VIDEO TO PLAYLIST'}
                >
                    <button
                        css={[
                            tw`bg-[#DBBCEF] p-2 rounded-md`,
                        ]}
                        onClick={handleAddingVideoToPlaylist}
                        id={tour ? 'video-page-tour-add-playlist' : 'add-video-to-playlist'}
                    >
                        {selectedVideo && isPlaylistDropdownOpen ? <RemoveFromPlaylistIcon /> : <AddToPlaylistIcon />}
                    </button>
                </div>
                <div tw='flex items-center'>
                    <div
                        tw='flex items-center uppercase' data-tooltip-id='my-tooltip'
                        data-tooltip-content={'YOUR VIEWS'}>
                        <div tw='pr-1 text-[#9D91BB]'>{view_count}</div>
                        <div tw='pr-3'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20'
                                fill='none'>
                                <path
                                    d='M10.3535 14.8235C6.5589 14.8235 4.07565 12.4632 2.90371 11.0052C2.36893 10.3399 2.36893 9.4248 2.90371 8.75949C4.07565 7.30151 6.5589 4.94116 10.3535 4.94116C14.148 4.94116 16.6313 7.30151 17.8032 8.75949C18.338 9.4248 18.338 10.3399 17.8032 11.0052C16.6313 12.4632 14.148 14.8235 10.3535 14.8235Z'
                                    stroke='#9D91BB' strokeWidth='1.23529' strokeLinecap='round' />
                                <circle
                                    cx='10.3529' cy='9.88233' r='2.47059' stroke='#9D91BB'
                                    strokeWidth='1.23529' />
                            </svg>
                        </div>
                    </div>

                    <div
                        tw='flex items-center'
                        data-tooltip-id='my-tooltip'
                        data-tooltip-content={isFavorite ? 'REMOVE FROM FAVORITES PLAYLIST' : 'ADD TO FAVORITES PLAYLIST'}
                    >
                        <button
                            aria-label='Toggle Favorite Video'
                            data-testid='favorite-button'
                            className='outline-offset-0 favorite-button-heart'
                            onClick={(e) => handleToggleFavorite(e)}
                        >
                            <HeartFavoriteIconNew className='fav' favorite={isFavorite} />
                        </button>
                    </div>


                </div>
            </div>

        </div>
    );
};

export default TeacherInfo;
