import React from 'react';

const ProgressSVG = ({ style }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none' style={style}>
            <path d='M2.91699 20.4167L9.91699 13.4167L16.917 18.0833L26.8337 7M26.8337 7V11.6667M26.8337 7H22.167' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            <circle cx='2.91699' cy='20.417' r='1.75' fill='#1C0057' stroke='currentColor' strokeWidth='1.5'/>
            <circle cx='9.91699' cy='13.417' r='1.75' fill='#1C0057' stroke='currentColor' strokeWidth='1.5'/>
            <ellipse
                cx='16.917' cy='18.083' rx='1.75' ry='1.75' fill='#1C0057' stroke='currentColor'
                strokeWidth='1.5'/>
        </svg>

    );
};

// original colors:
// fill: #1C0057
// stroke: #02AC6F

// new colors:
// stroke: #ECA121


export default ProgressSVG;