import React from 'react';

const ActiveTopicSVG = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M8 4C4.68629 4 2 6.68629 2 10V12.8085C2 16.1222 4.68629 18.8085 8 18.8085H8.30313C8.39473 18.8085 8.45522 18.9038 8.41626 18.9867L7.14484 21.6918C7.09534 21.7971 7.20395 21.907 7.30982 21.8587L13.753 18.9211C13.9158 18.8469 14.0926 18.8085 14.2715 18.8085H16C19.3137 18.8085 22 16.1222 22 12.8085V10C22 6.68629 19.3137 4 16 4H8ZM12.397 7.2952C12.2661 6.9016 11.7339 6.9016 11.603 7.2952L10.9103 9.37932C10.8526 9.55295 10.6976 9.67068 10.5219 9.67442L8.41245 9.71938C8.01407 9.72787 7.84959 10.2573 8.16711 10.509L9.84842 11.842C9.98849 11.9531 10.0477 12.1436 9.99678 12.3195L9.38581 14.4314C9.27042 14.8303 9.70104 15.1574 10.0281 14.9194L11.76 13.6592C11.9042 13.5542 12.0958 13.5542 12.24 13.6592L13.9719 14.9194C14.299 15.1574 14.7296 14.8303 14.6142 14.4314L14.0032 12.3195C13.9523 12.1436 14.0115 11.9531 14.1516 11.842L15.8329 10.509C16.1504 10.2573 15.9859 9.72787 15.5876 9.71938L13.4781 9.67442C13.3024 9.67068 13.1474 9.55295 13.0897 9.37932L12.397 7.2952Z' fill='#E775EF' />
        </svg>
    );
};

export default ActiveTopicSVG;
