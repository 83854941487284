import mimeTypes from 'mime-types';

// if (!src) return source || {};
// const mime = mimeTypes.lookup(src);
// let newSource = {};
// if (mime === 'application/vnd.apple.mpegurl') newSource.hls = src;
// else if (mime === 'application/dash+xml') newSource.dash = src;
// else if (mime) newSource.progressive = [{ url: src, type: mime }];
// newSource = { ...source, ...newSource };
// return newSource;

/*
    converts a url to a video source record
 */
export const createVideoSource = (src) => {
    const mime = mimeTypes.lookup(src);
    // console.log('mime:', mime, src);
    return {
        src: src,
        type: mime
    };
};

/*
    create <video> sources from a video object

    Video Object Properties:
        audio - url to audio file
        url_webm
        url_dash
        url_hls
        url_mp4
 */

export const createVideoSources = (video) => {
    // console.log('createVideoSources: video = ', video);
    if (!video)
        return false;
    createVideoSource(video.audio);
    createVideoSource(video.url_webm);
    createVideoSource(video.url_dash);
    createVideoSource(video.url_hls);

    let sources = [];

    if (video.audio) {
        sources.push({
            src: video.audio,
            type: 'audio/wav'
        });
    }

    if (video.url_webm) {
        sources.push({
            src: video.url_webm,
            type: 'video/webm'
        });
    }

    if (video.url_dash) {
        sources.push({
            src: video.url_dash,
            type: 'application/dash+xml'
        });
    }

    if (video.url_hls) {
        sources.push({
            src: video.url_hls,
            type: 'application/vnd.apple.mpegurl'
        });
    }

    if (video.url_mp4) {
        sources.push({
            src: video.url_mp4,
            type: 'video/mp4'
        });
    }
    return sources;
};

/**
 * time_ranges_to_array() - convert a TimeRanges object to a list of objects
 *
 *  TimeRanges Object Properties:
 *      length - get the number of played ranges in the audio/video
 *      start(index) - get the start position of a played range
 *      end(index) - get the end position of a played range
 *
 * @param time_ranges
 * @returns {*[{start,end}]}
 */

export const time_ranges_to_array = (time_ranges) => {
    // console.log('[time_ranges_to_array]', time_ranges);

    if (!time_ranges)
        return [];

    const ranges = [];
    for (let i = 0; i < time_ranges.length; i++) {
        ranges.push([parseFloat(time_ranges.start(i)), parseFloat(time_ranges.end(i))]);
    }
    // console.log('[time_ranges_to_array] =>', ranges);
    return ranges;
};
