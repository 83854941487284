import { useEvent } from '@harelpls/use-pusher';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import ReactJson from 'react-json-view';
import tw, { styled } from 'twin.macro';
import { useList } from 'react-use';
import { ReactNotifications } from 'react-notifications-component';
import { createSelector } from 'reselect';
import { List } from 'immutable';
import useSWR from 'swr';
import useAxios from 'axios-hooks';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import AchievementConstants from '../app/containers/child/Progress/AchievementConstants';

import { useToast } from '../hooks/toast';
import { useUser } from '../state/User/UserState';
import { apiClient, apiFetcher } from '../utils/api-client';
import { useDevTool } from '../lib/DevTool/DevTool';
import { usePushEvent } from './PusherProvider';

export const NOTIFY_DEFAULT = 'default';
export const NOTIFY_SUCCESS = 'success';
export const NOTIFY_DANGER = 'danger';
export const NOTIFY_INFO = 'info';
export const NOTIFY_WARNING = 'warning';

const NotificationWrapper = styled.div`
    //background: #ff0d;
    z-index: 10000000;
    //position: absolute;
    //position: relative;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .notification__item--gradient {
        background: #3E257A;
        border-radius: 10px;
    }
`;
const NotificationContext = React.createContext();

// notifications

const getToasts = (state) => state.getIn(['toast'], List());

const toastSelector = createSelector([getToasts], (toast) => toast.toJS());

const initialState = {
    notifications: []
};

const notificationReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_NOTIFICATION': {
            // if the notification id exists, update it
            const existing = state.notifications.find(n => n.id === action.payload.id);
            if (existing) {
                return state;
                // return {
                //     ...state,
                //     notifications: state.notifications.map(n =>
                //         n.id === action.payload.id ? action.payload : n
                //     )
                // };
            }
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            };
        }
        case 'REMOVE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.filter(n => n.id !== action.payload)
            };
        case 'UPDATE_NOTIFICATION':
            return {
                ...state,
                notifications: state.notifications.map(n =>
                    n.id === action.payload.id ? action.payload : n
                )
            };
        case 'ADD_NOTIFICATIONS':
            return {
                ...state,
                notifications: [...state.notifications, ...action.payload]
            };
        default:
            return state;
    }
};

const NotificationsPortal = () => {
    return ReactDOM.createPortal(
        <NotificationWrapper>
            <ReactNotifications
                types={[{
                    htmlClasses: ['notification__item--gradient'],
                    name: 'gradient'
                }]}
            />
        </NotificationWrapper>,
        document.getElementById('toast-root') // Make sure this element exists in your HTML.
    );
};

const NotificationProvider = (props) => {
    // const [notifications, { push, filter, update }] = useList();
    const [state, dispatch] = useReducer(notificationReducer, initialState);
    const addToast = useToast();
    useDevTool('NotificationProvider', { state });

    // pull new toasts out of the store
    // const toasts = useSelector(toastSelector);
    // React.useEffect(() => {
    //     if (toasts) {
    //         toasts.forEach(toast => {
    //             addToast(toast.title, toast.message, toast.type);
    //             // dispatch({ type: REMOVE_TOAST, payload: toast });
    //         });
    //     }
    // }, [addToast, dispatch, toasts]);

    // connect to push events
    // usePushEvent('notification', (notification) => {
    //     // setMessages((messages) => [...messages, data]);
    //     console.log(notification);
    //     push(notification);
    //     addToast('Notification', notification.message, notification.style ?? NOTIFY_DEFAULT);
    // });


    // download initial notifications
    const { data, error } = useSWR('/api/notifications/', apiFetcher, { onSuccess: (data) => addNotifications(data) });

    // const {data, error} = useSWR('/api/notifications/', apiFetcher, {onSuccess: (data) => dispatch({type: ADD_CLASSES, classes: data})});
    // download initial notifications
    React.useEffect(() => {
    }, []);


    // create _addNotification global
    useEffect(() => {
        window._addNotification = (title = 'Toast Notification', message, level = 'success', timeout = 0, children = null) => {
            addToast(title, message, level);
        };
        window._addToastNotification = (title, message, level = 'success', timeout = 0, children = null) => {
            addToast(title, message, level);
        };
        return () => {
            delete window._addNotification;
            delete window._addToastNotification;
        };
    }, [addToast]);

    // notification mgmt
    // const onNotificationRemoval = (id, removedBy) => {
    //     console.log('notification removed', id, removedBy);
    //     filter((notification) => notification.id !== id);
    // };

    const addNotifications = (notifications) => {
        // console.log('Add initial notifications', notifications);
        notifications.forEach((notification) => addNotification(notification));
    };

    const addNotification = useCallback((notification, showToast=false) => {
        // console.log('[NotificationProvider] Add notification', notification);

        // add the notification to the store
        dispatch({ type: 'ADD_NOTIFICATION', payload: notification });

        // create a toast notification
        // if (!notification.selected) {
        if( showToast ) {
            // TODO: allow disabling toasts in settings
            // if( DISABLE_NOTIFICATION_ALERTS ) {
            //     return;
            // }

            // check if the current url has a query parameter for notifications
            if (window.location.search.includes('hide_toasts')) {
                console.log('Hiding toasts due to query parameter:', window.location.search);
                return;
            }

            // send a toast alert
            const type = notification.type;
            if (type === 'achievement') {
                // format the message
                let message;
                const badge = AchievementConstants[notification.data.badge_type];
                if (badge) {
                    message = <div tw='flex items-start'>
                        <div tw='max-w-[20px] mr-1.5'><img tw='w-full h-auto' src={badge.image} /></div>
                        You've earned {badge.header}</div>;
                } else {
                    message = `You've earned ${notification.data.description}`;
                }
                addToast('New Achievement', message);
            }
        }

    }, [addToast]);

    // delete the notification
    const [, deleteNotification] = useAxios({ method: 'DELETE' }, { manual: true });

    const removeNotification = useCallback(
        (notificationId) => {
            console.log('removing notification', notificationId);

            // delete the notification
            deleteNotification({ url: `/api/notifications/${notificationId}/` });

            // remove it from the notification list
            // filter((notification) => notification.id !== notificationId);
            dispatch({ type: 'REMOVE_NOTIFICATION', payload: notificationId });
        },
        [deleteNotification]
    );

    const selectNotification = useCallback((notificationId) => {
        // console.log('select notification', notificationId);

        apiClient.post(`/api/notifications/${notificationId}/selected/`)
            .then((response) => {
                console.log('select notification response', response);
            })
            .catch((error) => {
                console.log('select notification error', error);
            });

        // find the notification and update it locally
        let item = state.notifications.find(a => a.id === notificationId);
        item.selected = true;
        dispatch({ type: 'UPDATE_NOTIFICATION', payload: item });
        // update((a) => a.id === item.id, item);
        // delete the notification
        // deleteNotification({ url: `/api/notifications/${notificationId}/` });

        // remove it from the notification list
        // filter((notification) => notification.id !== notificationId);
    }, [state.notifications]);

    const closeNotification = useCallback((notificationId) => {
        // console.log('close notification', notificationId);

        apiClient.post(`/api/notifications/${notificationId}/closed/`)
            .then((response) => {
                console.log('close notification response', response);
            })
            .catch((error) => {
                console.log('close notification error', error);
            });

        // postSelectNotification({ url: `/api/notifications/${notificationId}/closed/` });
        // find the notification and update it locally
        let item = state.notifications.find((a) => a.id === notificationId);
        item.closed = true;
        dispatch({ type: 'UPDATE_NOTIFICATION', payload: item });

        return () => {
            console.log('cancel close notification');
        };
    }, [state.notifications]);

    const context = {
        notifications: state.notifications,
        addNotification,
        removeNotification,
        selectNotification,
        closeNotification
    };

    // usePushEvent('notification', (data) => {
    //     console.log('PUSH notification', data);
    //     const existing = state.notifications.find(n => n.id === data.id);
    //     if( !existing )
    //         addNotification(data);
    // });


    return (
        <NotificationContext.Provider value={context}>
            <NotificationsPortal />
            {/*
            <NotificationWrapper>
                <ReactNotifications
                    types={[{
                        htmlClasses: ['notification__item--gradient'],
                        name: 'gradient'
                    }]}
                />
            </NotificationWrapper>
            */}
            {props.children}
        </NotificationContext.Provider>
    );
};

export function useNotifications() {
    return React.useContext(NotificationContext);
}

export function ensureToastRoot() {
    // Check if the element already exists
    if (!document.getElementById('toast-root')) {
        // Create a new div element
        const devToolsRoot = document.createElement('div');
        devToolsRoot.setAttribute('id', 'toast-root');
        // Append it to the body (or any other high-level element you wish)
        document.body.appendChild(devToolsRoot);
    }
}

export default NotificationProvider;
