import React, { useMemo, useRef } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useForm } from 'react-hook-form';
import { showError } from '../../app/AccountOnboard/Components/Components';
import Button from '../Button/Button';
import { useToast } from '../../hooks/toast';
import { useTeacher, useUser, useUserAction } from '../../state/User/UserState';
import { AUTH_LOGIN } from '../../constants';

const CheckBoxStyle = css`
    input:checked + div {
    @apply border-blue-500;
    }

    input:checked + div svg {
    @apply block;
    }
`;

const S = {
    Container: styled.div`
        // position: relative;
        display: flex;
        flex-direction: column;
        font-family: Avenir, serif;
        color: #ffffff;
        align-items: flex-start;
        padding: 0px 90px;

        a {
            color: white;
            text-decoration: underline;
            white-space: nowrap;
        }

        .header {
            display: flex;
            flex-direction: column;
            margin-top: 60px;

            .title {
                font-size: 2em;
                line-height: 40px;
                font-weight: 900;
                white-space: nowrap;
                line-height: normal;
                @media only screen and (max-width: 450px) {
                    font-size: 30px;
                }
            }

            .sub-header {
                font-size: 18px;
                line-height: 22px;
                /* margin-top: 7px; */
            }
        }

        .main-content {
            display: flex;
            flex-direction: column;
            margin-top: 24px;
            width: 100%;
        }

        .check-box-container {
            display: flex;
            align-items: center;
            margin-top: 36px;

            .title {
                font-weight: 500;
                font-size: 18px;
                margin-right: 15px;
                line-height: normal;
                color: #121651;
            }
        }
    `,
    CheckboxContainer: styled.div`
        .checkbox-grid {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            margin: 24px 0 0;

            .checkbox-item {
                /* width: 50%; */
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 20px;

                @media (max-width: 700px) {
                    width: 100%;
                }
            }

            .container {
                display: block;
                position: relative;
                margin: 0;
                padding-left: 35px;
                margin-bottom: 15px;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                line-height: normal;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                max-height: 30px;

                .title {
                    font-weight: 600;
                    font-size: 18px;
                    margin-right: 32px;
                    line-height: normal;

                    a {
                        color: white;
                        text-decoration: underline;
                        white-space: nowrap;
                    }
                }
            }

            /* Hide the browser's default checkbox */

            .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 27px;
                width: 27px;
                background-color: #fff;
                border: 2px solid #121651;
                border-radius: 4px;
            }

            /* On mouse-over, add a grey background color */

            .container:hover input ~ .checkmark {
                background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);
            }

            /* When the checkbox is checked, add a blue background */

            .container input:checked ~ .checkmark {
                background: linear-gradient(135deg, #e637a8 2.69%, #e68137 75%);
            }

            /* Create the checkmark/indicator (hidden when not checked) */

            .checkmark:after {
                content: '';
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */

            .container input:checked ~ .checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */

            .container .checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        .mobile-style {
            @media (max-width: 400px) {
                margin-bottom: 30px;
            }
        }
    `,
    Checkbox: styled.input`
        transform: scale(1.5);

        &:focus {
            outline: none;
            box-shadow: 0 0 8px #1cc8e1;
        }
    `
};

const SettingsDialog = (props) => {
    const addToast = useToast();

    const user = useUser();
    const teacher = useTeacher();
    const { saveSettings } = useUserAction();

    const editPassword = useMemo(() => user.auth_method === AUTH_LOGIN, [user.auth_method]);
    console.log(user);

    // form hook
    const {
        register,
        handleSubmit,
        watch,
        errors: formErrors,
        setError
    } = useForm({
        defaultValues: {
            name: teacher.name,
            grade: teacher.grades_data
            // display_icon: cls?.display_icon ?? 'ClassIcon13'
        }
    });

    // get a reference to the password for validation
    const password = useRef({});
    const currentPassword = useRef({});
    password.current = watch('password', '');
    currentPassword.current = watch('current_password', '');

    const onSubmitTeacherSettings = async (data) => {
        console.debug(data);

        // create the payload
        const payload = {
            name: data.name,
            grades: data.grade
        };

        // attach the password if set
        if (editPassword && data.current_password.length > 0) {
            payload['current_password'] = data.current_password;
            payload['new_password'] = data.password;
        }

        // save the settings
        saveSettings(payload)
            .then((data) => {
                addToast('Success', 'You updated your settings', 'gradient');
                props.setIsOpen(false);
            })
            .catch((error) => {
                addToast('Error', `There was an error saving your settings: ${error}`, 'error');
            });
        //
        // new Promise((resolve, reject) => resolve(dispatch(saveProfile(payload)))).finally(() => {
        //     addToast('Success', 'You updated your settings', 'gradient');
        //     props.setIsOpen(false);
        // });
    };

    const makeCheckbox = () => {
        let checkbox = Array(10).fill(0);
        return checkbox.map((x, index) => {
            let label = index + 3;
            let value = String(index + 3);
            if (index === 0) {
                label = 'K-3';
                value = '3';
            }
            else if (index === 8) {
                label = '11-12';
                value = '11';
            }
            else if (index === 9) {
                label = 'Other';
                value = '12';
            }
            return (
                <S.CheckboxContainer key={index}>
                    <div className='checkbox-grid'>
                        <label className='container'>
                            <div className='title'>{label}</div>
                            <input
                                type='checkbox'
                                name='grade'
                                ref={register({
                                    required: false,
                                    validate: (value) => (value.length ? true : 'Please select at least one grade')
                                })}
                                value={value}
                                data-test={`CheckboxCypressId${label}`}
                            />
                            <span className='checkmark' />
                        </label>
                    </div>
                </S.CheckboxContainer>
            );
        });
    };

    return (
        <>
            <div tw='font-medium text-2xl text-EarieBlack'>Your Settings</div>
            <form onSubmit={handleSubmit(onSubmitTeacherSettings)} autoComplete='off'>
                <div tw='mt-5 mb-2  font-medium text-sm text-EarieBlack'>This is the name students will see when you send messages or assign playlists.</div>
                <input
                    tw='bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                    placeholder='Your name'
                    type='text'
                    name='name'
                    autoFocus
                    ref={register({ required: true })}
                />
                {showError(formErrors.name)}

                <div>
                    <div tw='mt-5 mb-2  font-medium text-sm text-EarieBlack'>
                        What grade(s) do you teach? (So we can recommend videos; you will still have access to the full video library.)
                    </div>
                    <div tw='flex flex-wrap'>{makeCheckbox()}</div>
                    <div> {showError(formErrors.grades)}</div>
                </div>

                {editPassword && (
                    <React.Fragment>
                        <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Current Password</div>
                        <input
                            tw='bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                            type='password'
                            name='current_password'
                            ref={register({
                                required: false,
                                minLength: {
                                    value: 6,
                                    message: 'Password must have at least 6 characters'
                                }
                            })}
                        />
                        {showError(formErrors.current_password)}
                        <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>New Password</div>
                        <input
                            tw='bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                            type='password'
                            name='password'
                            ref={register({
                                required: currentPassword.current !== '',
                                minLength: {
                                    value: 6,
                                    message: 'Password must have at least 6 characters'
                                }
                            })}
                        />
                        {showError(formErrors.password)}
                        <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Confirm New Password</div>
                        <input
                            tw='bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                            name='confirmPassword'
                            type='password'
                            ref={register({
                                validate: (value) => value === password.current || 'The passwords do not match'
                            })}
                        />
                        {showError(formErrors.confirmPassword)}
                    </React.Fragment>
                )}

                <div tw='ml-auto w-max mt-6'>
                    <Button type='submit'>Submit</Button>
                </div>
            </form>
        </>
    );
};

export default SettingsDialog;
