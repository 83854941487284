import React, { useMemo, useState } from 'react';
import tw from 'twin.macro';
import { useVideos } from '../../../state/CatalogState';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import RecordIcon from '../../RecordIcon';
import SvgWhiteXButton from '../../../images/icons/WhiteXButton';
import { openURL } from '../../../utils';
import { createAssignmentGradesURL } from '../../../hooks/useGradeExport';

export const StudentProgressModal = ({ isOpen, setIsOpen, assignment, students, classId }) => {
    console.log('StudentProgressModal', students);
    const videos = useVideos();

    // get the videos in this assignment
    const assignmentVideos = useMemo(() => assignment?.items?.map(video_id => videos?.find(video => video.id === video_id)) ?? [], [assignment?.items, videos]);

    // sort users into buckets
    const {
        studentsNotStarted,
        studentsInProgress,
        studentsComplete
    } = useMemo(() => {
        const notStarted = [];
        const started = [];
        const completed = [];
        if (students) {
            students.forEach((student) => {
                // first get the assignment instance for this student
                const instance = student.assignment_instances.find(instance => instance.assignment_id === assignment?.id);

                // sort into buckets
                if (!instance) {
                    // not started
                    notStarted.push(student);
                } else if (instance.completion_percent === 1) {
                    // completed
                    completed.push(student);
                } else if (instance.progress && Object.keys(instance.progress).length > 0) {
                    // if (instance.completion_percent > 0 && instance?.completion_percent < 1)
                    // in progress
                    started.push(student);
                } else {
                    // not started
                    notStarted.push(student);
                }
            });
        }
        return {
            studentsInProgress: started,
            studentsComplete: completed,
            studentsNotStarted: notStarted
        };
    }, [assignment, students]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[682px] mx-auto`}>
            <div>
                <div tw='w-full px-6 py-3 flex justify-between items-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-tl-xl rounded-tr-xl relative'>
                    <h1 tw='text-white text-2xl font-medium'>Class Progress</h1>

                    <div tw='flex space-x-1 items-center pr-3'>
                        <h1 tw='text-white text-2xl font-medium'>{assignment?.name}</h1>
                    </div>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center shadow-md '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                </div>
                <div tw='py-4 px-6 bg-white flex flex-wrap'>
                    <div tw='font-extrabold text-EarieBlack text-lg'>Videos in this Playlist:</div>
                    {assignmentVideos.map((video, index) => {
                        return (
                            <div key={index} tw='text-lg text-SonicSilver font-medium ml-1'>
                                {video.title}
                                {index === assignmentVideos?.length - 1 ? '' : ','}
                            </div>
                        );
                    })}
                </div>
                <div tw='px-6 bg-white'>
                    <div tw='height[1px] w-full bg-LavenderBlue' />
                </div>
                <div tw='bg-white overflow-auto p-6 pb-0'>
                    <div tw='flex justify-between mb-2'>
                        <div tw='flex space-x-2'>
                            <p tw='text-lg'>
                                <span tw='text-lg font-extrabold'>Goal:</span> {assignment?.quiz_score_goal}%
                            </p>
                            <span tw='h-7 w-7'>
                                <RecordIcon level={assignment?.quiz_level_goal ?? 1} />
                            </span>
                        </div>
                        <p tw='font-medium text-lg'>
                            {studentsComplete.length ?? 0} of {students?.length ?? 0} Students Complete
                        </p>
                    </div>
                    <div tw='flex items-stretch'>
                        <div tw='w-full bg-GhostWhite'>
                            <div tw='background[#E0E2F7] flex p-4 justify-between items-center'>
                                <p tw='font-medium text-lg'>Completed</p>
                                <span tw='bg-CompletedGreen px-5 py-1 rounded-full text-white text-sm font-black'>{studentsComplete.length}</span>
                            </div>
                            <div tw=' py-3 px-5 space-y-3 text-sm'>
                                {studentsComplete.map((student) => (
                                    <p key={student?.user?.id}>{`${student?.user?.last_name}, ${student?.user?.first_name}`}</p>
                                ))}
                            </div>
                        </div>
                        <div tw='w-full background[#EAEAFB]'>
                            <div tw='bg-Remy flex p-4 justify-between items-center'>
                                <p tw='font-medium text-lg'>In Progress</p>
                                <span tw='bg-MediumPurple px-5 py-1 rounded-full text-white text-sm font-black'>{studentsInProgress.length}</span>
                            </div>
                            <div tw=' py-3 px-5 space-y-3 text-sm'>
                                {studentsInProgress.map((student) => (
                                    <p key={student?.user?.id}>{`${student?.user?.last_name}, ${student?.user?.first_name}`}</p>
                                ))}
                            </div>
                        </div>
                        <div tw='w-full bg-GhostWhite'>
                            <div tw='background[#E0E2F7] flex p-4 justify-between items-center'>
                                <p tw='font-medium text-lg'>Not Started</p>
                                <span tw='background[#A5A5A5] px-5 py-1 rounded-full text-white text-sm font-black'>{studentsNotStarted.length}</span>
                            </div>
                            <div tw=' py-3 px-5 space-y-3 text-sm'>
                                {studentsNotStarted.map((student) => (
                                    <p key={student?.user?.id}>{`${student?.user?.last_name}, ${student?.user?.first_name}`}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div tw='bg-white space-x-3 flex items-center justify-end px-6 py-7 rounded-bl-xl rounded-br-xl'>
                    <Button variant='purple' onClick={() => openURL(createAssignmentGradesURL(assignment.id, classId))}>
                        EXPORT GRADES
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

/*
    useStudentProgressModal - hook to assist using the StudentProgressModal
    useStudentProgressModal()
 */

export const useStudentProgressModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [assignment, setAssignment] = useState(null);
    const [students, setStudents] = useState(null);
    const [classId, setClassId] = useState(null);

    const open = (assignment, students, classId=null) => {
        setAssignment(assignment);
        setStudents(students);
        setClassId(classId);
        setIsOpen(true);
    };

    const Modal = useMemo(() => (props) => {
        return (
            <StudentProgressModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                students={students}
                assignment={assignment}
                classId={classId}
            />
        );
    }, [isOpen, students, assignment, classId]);

    return [Modal, open];
};

export default StudentProgressModal;
