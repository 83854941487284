import React from 'react';
import { Helmet } from 'react-helmet';
import { styled, css } from 'twin.macro';
import Page from 'components/Page';
import { useAssignedVideos, useAssignments, useCurrentAssignment } from '../../../../state/Student/AssignmentsState';
import HeaderContainer from './HeaderContainer/HeaderContainer';
import AchievementsContainer from './AchievementsContainer/AchievementsContainer';
import './HomeContainer.css';
import HomeVideoTabs from './HomeVideoTabs';

const S = {
    Container: styled(Page)`
        background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
    `
};



const HomeContainer = (props) => {
    // assignments
    const assignments = useAssignments();
    const assignedVideos = useAssignedVideos();
    const current_assignment_header = useCurrentAssignment();

    return (
        <S.Container tw='background[var(--linear-gradient-background)]'>
            <Helmet>
                <title>Home</title>
            </Helmet>

            <header>
                <HeaderContainer assignment={current_assignment_header} />
            </header>

            <div
                tw='px-10 [maxWidth:var(--layout-width)] mx-auto'
                css={[
                    css`
                        @media only screen and (max-width: 500px) {
                            padding: 0;
                        }
                    `
                ]}
            >
                <section aria-label='Videos'>
                    <HomeVideoTabs tw='mt-8 sm:mt-16' assignments={assignments} assignedVideos={assignedVideos} />
                </section>

                <AchievementsContainer />

            </div>
        </S.Container>
    );
};

export default HomeContainer;
