import React, { useContext } from 'react';
import { PusherProvider as PusherService, useChannel, useEvent } from '@harelpls/use-pusher';
import { useUser } from '../state/User/UserState';
import { PUSHER_CLIENT_KEY, PUSHER_CLUSTER } from '../config';

const pusher_config = {
    // required config props
    clientKey: PUSHER_CLIENT_KEY,
    cluster: PUSHER_CLUSTER,
    encrypted: true

    // optional if you'd like to trigger events. BYO endpoint.
    // see "Trigger Server" below for more info
    // triggerEndpoint: "/pusher/trigger",
    // required for private/presence channels
    // also sends auth headers to trigger endpoint
    // authEndpoint: `${process.env.REACT_APP_PUSHER_AUTH_API_URL}`,
    // auth: {
    //     headers: {
    //         Authorization: `Token ${getAuthToken()}`
    //     }
    // }
};

const PusherProvider = ({ children }) => {
    // check for pusher configuration
    if (!PUSHER_CLIENT_KEY || !PUSHER_CLUSTER) {
        console.error('Pusher not configured');
        return children;
    }

    // wrap the channel provider with pusher service provider
    return (
        <PusherService {...pusher_config}>
            <PusherChannelProvider>
                {children}
            </PusherChannelProvider>
        </PusherService>
    );
};

const Context = React.createContext();

// connect to user channel
const PusherChannelProvider = ({ children }) => {
    const user = useUser();
    const channel = useChannel(user ? `user-${user?.id}` : null);

    useEvent(channel, 'message', (data) => {
        console.log('message', data);
        alert(data['message']);
    });

    // const { addNotification } = useNotifications();
    useEvent(channel, 'notification', (data) => {
        console.log('<PusherProvider> Push notification received:', data);
        // addNotification(data);
    });

    return (
        <Context.Provider value={{ channel }}>
            {children}
        </Context.Provider>
    );
};

// get events from the user channel
export function usePushEvent(eventName, callback) {
    const { channel } = useContext(Context);
    useEvent(channel, eventName, callback);
}

export default PusherProvider;
