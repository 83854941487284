import React from 'react';
import tw from 'twin.macro';
import { Modal } from 'components';
import CloseButton from '../../../../../../components/AnswerKey/CloseButton';

/*
 * ModalDialog - White modal dialog with rounded corners and a close button in the upper right
 */

const ParentSettingsModal = ({ children, isOpen, setIsOpen }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[650px] mx-auto`}>
            <div tw='mx-4 sm:mx-20 relative'>
                <div tw='bg-[#4D3095] rounded-xl'>{children}</div>

                <button onClick={() => setIsOpen(false)} tw='absolute -top-0 -right-0 mr-4 mt-4'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                        <path d='M1 1L13 13' stroke='white' strokeWidth='1.5' strokeLinecap='round'/>
                        <path d='M13 1L1.00001 13' stroke='white' strokeWidth='1.5' strokeLinecap='round'/>
                    </svg>
                </button>
                
            </div>
        </Modal>
    );
};

export default ParentSettingsModal;
