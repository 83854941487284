import React from 'react';

const RetakeSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' fill='none'>
            <path
                stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2'
                d='M2.658 6.346a6 6 0 0 1 11.406-.398m-.11 4.384a6 6 0 0 1-10.966.204m1.321-.58-1.71.254-.825 1.522M12.59 6.487l1.711-.254.824-1.521' />
        </svg>
    );
};

export default RetakeSVG;
