import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { withScrollLayout } from '../layout/Layouts';
import ParentHomeContainer from '../containers/parent/ParentHomeContainer/ParentHomeContainer';
import ParentSettings from '../containers/parent/ParentSettings/ParentSettings';
import { Error404Page } from '../../components';
import { PlaylistsContainer, ProgressContainer } from '../containers/child';
import VideoSessionContainer from '../containers/child/VideoSessionContainer/VideoSessionContainer';

const ParentApp = () => {
    return (
        <Routes>
            <Route path='*' element={<Error404Page />} />
            <Route path='/video/:video_id' element={withScrollLayout(VideoSessionContainer)} />
            <Route path='/playlists/:course_id/:group_id/:student_id?' element={withScrollLayout(PlaylistsContainer)} />
            <Route path='/playlists/favorites' element={withScrollLayout(PlaylistsContainer)} />
            <Route path='/stats/*' element={withScrollLayout(ProgressContainer)} />
            <Route path='/settings/*' element={withScrollLayout(ParentSettings)} />
            <Route path='/home/:student_id?' element={withScrollLayout(ParentHomeContainer)} />
            <Route path='/' element={<Navigate to='/home' />} />
        </Routes>
    );
};

export default ParentApp;
