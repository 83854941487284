import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';

const AssignmentMessage = (props) => {
    return (
        <div tw='bg-white rounded-lg px-7 py-3 text-EarieBlack font-medium font-sans'>
            <div tw='text-base'>Message</div>
            <div tw='pt-4 flex items-center justify-center'>
                <svg
                    width='35'
                    height='35'
                    viewBox='0 0 35 35'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M15.625 17.5a1.875 1.875 0 103.75 0 1.875 1.875 0 00-3.75 0zm7.812 0a1.875 1.875 0 103.75 0 1.875 1.875 0 00-3.75 0zm-15.624 0a1.875 1.875 0 103.75 0 1.875 1.875 0 00-3.75 0zm25.827-6.781A17.507 17.507 0 0029.88 5.12 17.358 17.358 0 0024.28 1.36 17.416 17.416 0 0017.5 0h-.078A17.459 17.459 0 005.059 5.172 17.342 17.342 0 001.34 10.75 17.507 17.507 0 000 17.582a17.555 17.555 0 001.875 7.809v5.937a1.797 1.797 0 001.797 1.797h5.941A17.554 17.554 0 0017.422 35h.082c2.34 0 4.61-.453 6.746-1.34a17.363 17.363 0 005.578-3.719A17.46 17.46 0 0035 17.578a17.434 17.434 0 00-1.36-6.86zm-5.902 17.11A14.454 14.454 0 0117.5 32.03h-.066a14.581 14.581 0 01-6.762-1.699l-.328-.176h-5.5v-5.5l-.176-.328a14.583 14.583 0 01-1.7-6.762c-.015-3.894 1.474-7.55 4.204-10.304 2.727-2.754 6.371-4.278 10.265-4.293h.067c1.953 0 3.848.379 5.633 1.129a14.438 14.438 0 014.648 3.125 14.458 14.458 0 013.125 4.648 14.472 14.472 0 011.129 5.695c-.024 3.891-1.55 7.536-4.3 10.262z'
                        fill='#515151'
                    />
                </svg>

                <div tw='text-lg pl-3'>{props.Message}</div>
            </div>
        </div>
    );
};

AssignmentMessage.propTypes = {
    Message: PropTypes.string
};

export default AssignmentMessage;
