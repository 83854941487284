import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';


const DTCStatsBanner = ({ title, contents }) => {
    const hidMostEarnedElement = contents[3]?.value === null;

    return (

        <>
            <div
                tw='flex flex-col xl:flex-row items-center justify-center bg-[#4D3095] py-7 text-white font-Poppins h-full xl:h-[111px] hidden xl:flex'
            >
                <div
                    tw='hidden flex flex-col sm:flex-row justify-center items-center text-xl w-full xl:w-[30%] text-center font-semibold pl-10'>
                    {title}
                </div>
                <div
                    tw='w-full xl:w-[70%] flex flex-col sm:flex-row justify-center items-center pr-0 xl:pr-8 text-center pt-6 xl:pt-0'>

                    <div
                        aria-label={`${contents[0]?.value} Total records`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center '>
                        <div
                            aria-hidden={true}
                            tw='text-4xl sm:text-lg lg:text-xl font-semibold'>{contents[0]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-lg sm:text-sm text-[#cec2f0] w-[125px]  font-normal  pt-0 lg:pt-1.5'>{contents[0]?.title}</div>
                    </div>

                    <div
                        aria-label={`${contents[1]?.value} Records earned this week`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                        <div
                            aria-hidden={true}
                            tw='text-4xl sm:text-lg lg:text-xl font-semibold '>{contents[1]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-lg sm:text-sm text-[#cec2f0] w-[125px] font-normal pt-0 lg:pt-1.5'>{contents[1]?.title}</div>
                    </div>

                    <div
                        aria-label={`${contents[2]?.value} earned this month`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                        <div
                            aria-hidden={true}
                            tw='text-4xl sm:text-lg lg:text-xl font-semibold '>{contents[2]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-lg sm:text-sm text-[#cec2f0] w-[130px] font-normal pt-0 lg:pt-1.5'> {contents[2]?.title}</div>
                    </div>
                    {!hidMostEarnedElement &&
                        <div
                            aria-label={`${contents[3]?.value} Records earned this month`}
                            tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                            <div aria-hidden={true} tw=''>{contents[3]?.value}</div>
                            <div
                                aria-hidden={true}
                                tw='text-lg sm:text-sm text-[#cec2f0] w-[125px] font-normal pt-0 lg:pt-1.5'> {contents[3]?.title}</div>
                        </div>
                    }

                </div>
            </div>

            <div
                tw='flex flex-col xl:flex-row items-center justify-between bg-[#4D3095] px-4 pt-2 pb-3 text-white font-Poppins h-full xl:h-[111px] flex xl:hidden'>

                <div
                    tw='w-full flex sm:flex-row justify-center items-center text-center pt-4 xl:pt-0 mx-10'>

                    <div
                        aria-label={`${contents[0]?.value} Total records`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center '>
                        <div
                            aria-hidden={true}
                            tw='text-xl sm:text-lg lg:text-xl font-semibold'>{contents[0]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-xs sm:text-sm text-[#cec2f0] w-[70px]  font-normal  pt-0 lg:pt-1.5'>{contents[0]?.title}</div>
                    </div>

                    <div
                        aria-label={`${contents[1]?.value} Records earned this week`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                        <div
                            aria-hidden={true}
                            tw='text-xl sm:text-lg lg:text-xl font-semibold '>{contents[1]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-xs sm:text-sm text-[#cec2f0] w-[90px] font-light pt-0 lg:pt-1.5'>{contents[1]?.title}</div>
                    </div>

                    <div
                        aria-label={`${contents[2]?.value} earned this month`}
                        tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                        <div
                            aria-hidden={true}
                            tw='text-xl sm:text-lg lg:text-xl font-semibold '>{contents[2]?.value}</div>
                        <div
                            aria-hidden={true}
                            tw='text-xs sm:text-sm text-[#cec2f0] w-[90px] font-light pt-0 lg:pt-1.5'> {contents[2]?.title}</div>
                    </div>
                    {!hidMostEarnedElement &&
                        <div
                            aria-label={`${contents[3]?.value} Records earned this month`}
                            tw='w-1/2 sm:w-1/3 flex flex-col justify-center xl:justify-start items-center'>
                            <div aria-hidden={true} tw=''>{contents[3]?.value}</div>
                            <div
                                aria-hidden={true}
                                tw='text-xs sm:text-sm text-[#cec2f0] w-[100px] font-light pt-0 lg:pt-1.5'> {contents[3]?.title}</div>
                        </div>
                    }
                </div>
            </div>
        </>

    );
};

DTCStatsBanner.propTypes = {
    stats: PropTypes.array,
    activity: PropTypes.array,
    contents: PropTypes.array
};

export default DTCStatsBanner;