import * as React from 'react';

function SvgClassIcon20(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#BE6CFF' />
            <path
                d='M14.012 26H29V12a2 2 0 00-2-2H14c-1.206 0-3 .799-3 3v14c0 2.201 1.794 3 3 3h15v-2H14.012C13.55 27.988 13 27.805 13 27c0-.805.55-.988 1.012-1zM16 14h9v2h-9v-2z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon20;
