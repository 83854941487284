import React from 'react';

const SvgCategoryMathVocabulary = (props) => (
    <svg
        viewBox='0 0 28 28'
        width='1em'
        height='1em'
        {...props}>
        <g transform='translate(-38 -556)'>
            <circle
                data-name='category_math_vocabulary'
                cx={14}
                cy={14}
                r={14}
                transform='translate(38 556)'
                fill='white' />
            <path
                data-name='Rectangle 1551'
                fill='#12154C'
                d='M43 569h18v3H43z' />
            <circle
                data-name='Ellipse 523'
                cx={2.178}
                cy={2.178}
                r={2.178}
                transform='translate(50 562.299)'
                fill='#12154C' />
            <circle
                data-name='Ellipse 524'
                cx={2.178}
                cy={2.178}
                r={2.178}
                transform='translate(50 574.387)'
                fill='#12154C' />
        </g>
    </svg>
);

export default SvgCategoryMathVocabulary;
