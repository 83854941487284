import * as React from 'react';

function SvgClassIcon25(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FFD233' />
            <path
                d='M25.627 18.895H31a11 11 0 00-1.703-4.865 6.619 6.619 0 00-3.67 4.865zm-11.254 0a6.62 6.62 0 00-3.67-4.865A11 11 0 009 18.895h5.373zm9.021 0a8.837 8.837 0 014.488-6.634A11.02 11.02 0 0021.105 9v9.895h2.289zm-6.788 0h2.288V9a10.992 10.992 0 00-6.776 3.261 8.836 8.836 0 014.488 6.634zm6.788 2.21h-2.288V31a10.992 10.992 0 006.776-3.261 8.836 8.836 0 01-4.488-6.634zM10.703 25.97a6.664 6.664 0 003.67-4.864H9a11 11 0 001.703 4.864zm14.924-4.864a6.62 6.62 0 003.67 4.864A11 11 0 0031 21.106h-5.373zm-9.021 0a8.837 8.837 0 01-4.488 6.633A11.021 11.021 0 0018.895 31v-9.895h-2.289z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon25;
