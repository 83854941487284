import React from 'react';

export default function ArrowDown() {
    return (
        <svg
            width='34'
            height='34'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M17 32c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15z'
                stroke='#D44BA4'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.89 14.223l8 8 8-8'
                stroke='#D44BA4'
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round' />
        </svg>
    );
}
