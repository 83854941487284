import React from 'react';
import tw, { styled, css } from 'twin.macro';
import { apiClient, handleAPIError } from 'utils/api-client';
export const studentGradeToValue = (grade) => {
    // convert from studentprofile.grade to value for select
    if (!grade)
        return null;
    return String(grade);
};

const Container = styled.div(() => [
    tw` w-full rounded-lg bg-white text-2xl px-2.5 py-1.5 cursor-pointer hover:scale-105 transform transition-all duration-300 ease-in-out`,
    css`
    background: linear-gradient(0deg, #82F4EC, #742FBC);
    box-shadow: 0px 0px 15.66px 0px #AD63E9;
    `
]);


const ProficiencySelection = ({ user }) => {

    // save data
    const onSubmit = (proficiency) => {
        // save the data for this student

        console.log('Payload:', { proficiency_level: proficiency }); // debug line


        const payload = { proficiency_level: proficiency };
        apiClient.post('/api/studentprofile/', payload)
            .then(response => {
                const { data } = response;
                console.log(data);
                if (data?.user?.id) {
                    // save the student and close the dialog
                    updateStudent(data);
                    addToast('Success!', 'Your current math level was updated.');
                } else {
                    console.error('error saving proficiency level', data);
                }
            })
            .catch(err => {
                console.error(err);
                handleAPIError(err);
            });
    };



    return (
        <div tw='max-w-[700px] font-Poppins pt-6 sm:py-0 px-6 sm:px-0'>
            <div tw='text-center md:text-left text-white text-lg sm:text-xl md:text-lg lg:text-xl font-bold sm:font-semibold '>What’s Your Current Math Level?</div>
            <div tw='flex flex-col md:flex-row items-start sm:items-center justify-start gap-4 text-white mt-6 '>
                <Container onClick={() => onSubmit('below')} tw='md:max-w-[200px]'>
                    <div tw='rounded-lg bg-white font-normal text-lg text-DarkBlue w-full xl:min-w-[130px]  text-center py-2  lg:leading-[14px] flex items-center justify-center'>
                        <div tw='lg:max-w-[100px]'> Need to catch up</div>
                        <div tw='ml-3 md:ml-0'> &#128338;</div>
                    </div>
                </Container>
                <Container onClick={() => onSubmit('at')} tw='md:max-w-[180px]'>
                    <div tw='rounded-lg bg-white font-normal text-lg text-DarkBlue min-w-[130px] text-center py-2'> On track &#9989;</div>
                </Container>
                <Container onClick={() => onSubmit('above')} tw='md:max-w-[180px]'>
                    <div tw='rounded-lg bg-white font-normal text-lg text-DarkBlue min-w-[130px] text-center py-2'> Advanced &#128640;</div>
                </Container>
            </div>

        </div>
    );
};

export default ProficiencySelection;