import React from 'react';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import tw from 'twin.macro';
import { showError } from '../../app/AccountOnboard/Components/Components';
import Button from '../Button/Button';
import { handleNetworkError } from '../../lib/axios';
import { useToast } from '../../hooks/toast';

const JoinClassDialog = (props) => {
    const addToast = useToast();
    // post a join code
    const [{ loading: isPosting }, postInviteCode] = useAxios({ url: '/api/join/', method: 'POST' }, { manual: true });

    // form hook
    const { register, handleSubmit, watch, errors: formErrors, setError } = useForm();

    // submit the form
    const onSubmit = async (data) => {
        postInviteCode({ data })
            .then(({ data }) => {
                console.log(data);
                if (data.error) {
                    // invalid join code
                    setError('join_code', { type: 'manual', message: data.error });
                } else if (data.ok) {
                    // join code OK - show a toast
                    addToast('Class Joined', 'You joined the class!', 'gradient');
                    // close the dialog
                    props.setIsOpen(false);
                }
            })
            .catch((error) => {
                handleNetworkError(error);
            });
        // await apiCall(`${process.env.REACT_APP_MUZOLOGY_API_URL}api/join/`, {method: 'post', body: data}, [400]);
    };

    // the form
    return (
        <>
            <h3 tw='font-medium text-2xl mb-5'>Join a Class</h3>
            <p tw='text-sm font-medium mb-2'>Join Code *</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    tw='bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full'
                    type='text'
                    placeholder='Join Code'
                    name='join_code'
                    ref={register({
                        required: true,
                        maxLength: {
                            value: 4,
                            message: 'The join code must be 4 characters'
                        },
                        minLength: {
                            value: 4,
                            message: 'The join code must be 4 characters'
                        }
                    })}
                />
                {formErrors && showError(formErrors.join_code)}
                <div tw='ml-auto w-max mt-6'>
                    <Button type='submit'>Join</Button>
                </div>
            </form>
        </>
    );
};

export default JoinClassDialog;