/**
 * Created by jasonduncan on 7/18/18.
 */
import React, { Component } from 'react';
import { styled } from 'twin.macro';
import { RecordCheckIcon, RecordLockIcon, RecordNewIcon } from 'app/images/icons';
import RecordImg from './RecordImg';

/**
 * RecordIndicator - the RecordIndicator is a record icon with an overlay image or text
 */

const RecordIndicatorContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.overlay-container {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		display: flex;
		justify-content: center;
		align-items: center;

		padding: 0;
	}
`;

export const RECORD_INDICATOR_NONE = null;
export const RECORD_INDICATOR_CHECK = 'check';
export const RECORD_INDICATOR_LOCK = 'lock';
export const RECORD_INDICATOR_NEW = 'new';
export const RECORD_INDICATOR_TEXT = 'text';
export const RECORD_INDICATOR_DTC = 'dtc';
export default class RecordIndicator extends Component {
    static defaultProps = {
	    greyed: false,
	    level: 0,
	    color: '#ffffff',
	    opacity: 0.4,
	    type: RECORD_INDICATOR_NONE,
	    text: null, 
	    dtc: false
    };

    renderOverlay = () => {
	    const iconProps = {
	        fill: this.props.color,
	        width: '20px',
	        opacity: 0.8
	    };

	    switch (this.props.type) {
	        case RECORD_INDICATOR_CHECK:
	            return <RecordCheckIcon {...iconProps} />;
	        case RECORD_INDICATOR_LOCK:
	            return <RecordLockIcon {...iconProps} />;
	        case RECORD_INDICATOR_NEW:
	            return <RecordNewIcon {...iconProps} />;
	        case RECORD_INDICATOR_TEXT:
	            return <div>{this.props.text}</div>;
	        default:
	            return null;
	    }
    };

    render() {
	    const locked = this.props.type === RECORD_INDICATOR_LOCK;

	    return (
	        <RecordIndicatorContainer className='record-indicator'>
	            <RecordImg
	                style={this.props.style}
	                locked={this.props.greyed || locked}
	                level={this.props.level} 
	                dtc={this.props.dtc}
	            />
	            <div className='overlay-container'>{this.renderOverlay()}</div>
	        </RecordIndicatorContainer>
	    );
    }
}
