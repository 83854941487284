import React, { useCallback, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import tw, { styled } from 'twin.macro';

const DEFAULT_COLOR = '#70d8f7';
const DEFAULT_BACKGROUND = '#450762';

const LoadingContainerStyle = styled.div`
    //background: grey;
    //height: 100%;
    //display: ${(props) => (props.$show ? 'flex' : 'none')};
    
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 10000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #7569D6;

    .loading-message {
        margin-top: 10px;
        font-size: 32px;
        color: #fff;
        background: none;
    }
`;

/**
 * The LoadingPageContainer full screen positioned absolutely inside the positioned parent with a loading indicator
 *
 * @param color
 * @param textColor
 * @param message
 * @returns {*}
 * @constructor
 */

export const LoadingPage = ({ color, background, message, show, className, style }) => {
    if (show === undefined)
        show = true;
    if( !show )
        return null;
    return (
        <LoadingContainerStyle
            className={`loading-page-container ${className}`}
            style={style ?? {}}
            // $show={show}
            // color={color ?? DEFAULT_COLOR}
            // background={background ?? DEFAULT_BACKGROUND}
        >
            <ScaleLoader color='#fff' />
            <div className='loading-message'>{message || 'Please wait...'}</div>
        </LoadingContainerStyle>
    );
};

export default LoadingPage;

export const useLoadingView = (showing = false, screen=true) => {
    const [isShowing, setShowing] = useState(showing);
    const [message, setMessage] = useState(null);

    const open = useCallback(
        (msg) => {
            setShowing(true);
            setMessage(msg);
        },
        [setShowing]
    );

    const close = useCallback(() => setShowing(false), [setShowing]);

    const LoadingPageWrapper = (props) => {
        return <LoadingPage
            show={isShowing}
            message={message}
            css={[
                screen && tw`fixed w-screen h-screen top-0 left-0`
            ]}
            {...props}
        />;
    };

    return [LoadingPageWrapper, open, close];
};
