import React, { useState, useRef, useMemo, useEffect } from 'react';
import tw from 'twin.macro';
import { useCourseAndVideos } from 'state/CatalogState';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useClassStudents } from 'state/Teacher/ClassState';
import RocketImage from 'static/images/rocket.png';
import { ListBox } from 'components';
import PlaylistVideoRow from './PlaylistVideoRow';
import ProgressModal from './ProgressModal';

const CreatePlaylistChips = (groups, course_id, group_id, navigate) => {
    if (!groups) return null;
    return groups.map((item, index) => {
        return (
            <div
                key={index}
                tw='bg-[#6441B9] text-white font-Poppins text-sm font-medium text-center py-2 px-4 rounded-3xl cursor-pointer sm:hover:bg-[#3130AF] whitespace-nowrap'
                css={[item.id === group_id && tw`bg-[#46A6F8]`]}
                onClick={() => navigate(`../playlists/${course_id}/${item.id}`, { replace: true })}
            >
                {item.title}
            </div>
        );
    });
};


const CreatePlaylistDirectory = (groups, selectedGroupTitle, activeDirectory, setaActiveDirectory, handleScrollToElement) => {
    if (!groups) return null;
    return (
        <>
            <div tw='items-start hidden md:flex ml-8'>
                <div tw='text-white text-base font-medium mr-9 whitespace-nowrap'>{selectedGroupTitle} Playlists:</div>
                <div
                    tw='flex flex-wrap gap-8 items-center cursor-pointer text-sm text-[#AB9FC9] pt-1'>{groups.map((group) => {
                        return (
                            <div
                                key={group.id}
                                onClick={() => {
                                    setaActiveDirectory(group.id);
                                    handleScrollToElement(group.id);
                                }}
                                tw='hover:text-white'
                                css={[group.id === activeDirectory && tw`text-white`]}>
                                {group.title}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div tw='flex-col items-start md:hidden'>
                <div tw='text-white text-base font-medium whitespace-nowrap mx-6'>{selectedGroupTitle} Playlists:</div>
                <div
                    tw='flex overflow-x-auto gap-8 items-center cursor-pointer text-sm text-[#AB9FC9] mt-3 px-6'
                    css={{
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }}>{groups.map((group) => {
                        return (
                            <div
                                key={group.id}
                                onClick={() => {
                                    setaActiveDirectory(group.id);
                                    handleScrollToElement(group.id);
                                }}
                                tw='hover:text-white whitespace-nowrap'
                                css={[group.id === activeDirectory && tw`text-white`]}>
                                {group.title}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>


    );
};

const CreateVideoRows = (groups, selectedGroupTitle, favorites, allVideos, student_id) => {

    if (favorites || allVideos) {
        return (
            <div tw='mt-6' css={[allVideos && tw`mt-0`]}>
                <PlaylistVideoRow title='Favorites' favorites={favorites} allVideos={allVideos} student_id={student_id} />
            </div>
        );
    }

    if (!groups)
        return null;

    return groups.map((group, index) => {
        return (
            <div tw='mt-14' key={index} id={group.id}>
                <PlaylistVideoRow
                    group={group}
                    title={favorites ? 'Favorites' : group.title}
                    groupTitle={selectedGroupTitle}
                    favorites={favorites}
                    student_id={student_id}
                    allVideos={allVideos}
                />
            </div>
        );
    });
};

const PlaylistsContainer = () => {
    const { course_id, group_id, student_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const course = useCourseAndVideos(course_id);
    const { students } = useClassStudents(null);
    const filterGroups = course?.groups?.filter(item => item.parent === null) ?? [];
    const selectedGroupTitle = filterGroups.find(g => g.id === group_id)?.title;
    const [activeDirectory, setaActiveDirectory] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const progressTitle = student_id ? 'Progress' : 'My Progress';
    const [selectedStudent, setSelectedStudent] = useState(null);

    // first, sort the students and create listbox items
    const listBoxItems = useMemo(
        () =>
            students
                .sort((a, b) => a.user.first_name.localeCompare(b.user.first_name))
                .map((student) => ({ name: student.user.first_name, id: student.user.id, item: student })),
        [students]
    );

    // hide list box if there is only one child account
    let showListBox = listBoxItems.length > 1;

    console.log('<PlaylistsContainer> student_id', student_id);
    console.log('<PlaylistsContainer> students', students);
    console.log('<PlaylistsContainer> listBoxItems', listBoxItems);

    // second, if there is no student_selected, and we have student data,
    // select the first student
    useEffect(() => {
        if (student_id === undefined && listBoxItems?.length > 0) {
            const first_id = listBoxItems[0].id;
            navigate(`/playlists/${course_id}/${group_id}/${first_id}`, { replace: true });
        }
    }, [student_id, listBoxItems, navigate, course_id, group_id]);

    useEffect(() => {
        setSelectedStudent(students.find(s => s.user.id.toString() === student_id));
    }, [student_id, students]);

    // if group has sub groups create a new arr
    const reduceCourseGroups = course?.groups?.reduce((acc, group) => {
        if (group.parent === group_id) {
            acc.push(group);
        }
        return acc;
    }, []);
    // if there are no sub groups check to see if top level group has lessons
    // add it to0 the arr 
    let TopLevelGroup = course?.groups?.find(g => g.id === group_id);
    if (TopLevelGroup?.lessons.length > 0) {
        reduceCourseGroups.push(TopLevelGroup);
    }

    const handleScrollToElement = (id) => {
        const element = document.getElementById(`${id}`);
        element.scrollIntoView({ behavior: 'smooth' });
        setaActiveDirectory(null);
    };

    // get course title
    let courseTitle = course?.section_title ?? course?.title;

    // "Favorites" exception
    let favorites = false;
    let allVideos = false;
    if (location.pathname.startsWith('/playlists/favorites')) {
        courseTitle = '';
        favorites = true;
    }
    if (location.pathname.startsWith('/playlists/all_videos')) {
        courseTitle = 'All Videos';
        allVideos = true;
    }

    const hideModalButton = favorites || allVideos;
    return (
        <div tw='bg-[#1C0057] h-full min-h-full pb-10'>
            <div tw='2xl:[max-width:var(--layout-width)] mx-auto max-h-full'>
                {!allVideos &&
                    <div tw='flex justify-end'>
                        {showListBox ? (
                            <div tw='maxWidth[300px] mt-2.5 pr-7 lg:pr-14'>
                                <ListBox
                                    items={listBoxItems}
                                    onChange={(value) => navigate(`/playlists/${course_id}/${group_id}/${value.id}`, { replace: true })}
                                    selected={listBoxItems.find((s) => s.id.toString() === student_id)}
                                    // initialSelected={defaultSelected}
                                    variant='transparent-small'
                                    pushHistory={true}
                                />
                            </div>
                        ) : (
                            <div tw='text-2xl text-white'>{listBoxItems[0]?.name}</div>
                        )}
                    </div>
                }
                <div tw='flex justify-between items-center pr-7 lg:pr-14 px-7 p-4 '>
                    <div tw='text-white text-2xl'>
                        {courseTitle}
                    </div>
                    {!hideModalButton &&
                        <div
                            tw='hover:opacity-80 active:opacity-70 items-center h-16 cursor-pointer hidden md:flex'
                            onClick={() => setIsOpen(true)}>
                            <div>
                                <img src={RocketImage} alt='rocket' />
                            </div>
                            <div tw='text-white text-base font-medium ml-1'>{progressTitle}</div>
                        </div>
                    }
                </div>

                <div
                    tw='flex overflow-x-auto gap-1 items-center px-6 md:hidden'
                    css={{
                        scrollbarWidth: 'none', // For Firefox
                        '&::-webkit-scrollbar': { // For Chrome, Safari, and Opera
                            display: 'none'
                        }
                    }}
                >{CreatePlaylistChips(filterGroups, course_id, group_id, navigate)}</div>

                <div
                    tw='flex-wrap gap-2 items-center px-6 hidden md:flex'>{CreatePlaylistChips(filterGroups, course_id, group_id, navigate)}</div>

                {CreateVideoRows(reduceCourseGroups, selectedGroupTitle, favorites, allVideos, student_id)}
            </div>
            <ProgressModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                groups={reduceCourseGroups}
                selectedGroupTitle={selectedGroupTitle}
                student_id={student_id}
                student_name={selectedStudent?.user.first_name}
            />
        </div>
    );
};

export default PlaylistsContainer;