import React, { useState, useMemo } from 'react';
import { NullContainer, DTCStatsBanner } from 'components';
import moment from 'moment';
import tw, { css } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import AchievementConstants from '../AchievementConstants';
import { useVideoAchievements } from '../../../../../state/Student/VideoStats';
import { wrapTitleParens } from '../../../../../lib/string';

const options = [
    { id: 1, name: 'Achievement Title', type: 'alphabetical', unavailable: false },
    { id: 2, name: 'Recent to Old', type: 'date', unavailable: false }
];

const ScrollBarStyle = css`
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #4D3095;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }
`;

const MyAchievements = ({ videos, activity, parent, childAchievements, selectedStudent, allVideos, timesTableProduct }) => {
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const achievements = useVideoAchievements();
    const filteredChildAchievements = childAchievements?.map(ca => ca.data);

    const makeNullAchievements = () => {
        return [...Array(4)].map((item) => {
            return (
                <div key={item} tw='flex items-center justify-center mt-4'>
                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' tw='h-[174px] w-[127px]' viewBox='0 0 140 181'>
                        <path fill='#3E257A' d='M22.282 91.509h99.703v88.759L70.139 162.44l-47.856 17.828v-88.76Z' />
                        <path
                            fill='#3E257A' stroke='#3E257A' strokeWidth='5.934'
                            d='m64.613 4.676-.012.007L9.075 37.155l-.007.004C5.593 39.173 3.51 42.872 3.51 46.89v64.912c0 3.963 2.075 7.713 5.558 9.732l.007.003L64.6 154.01l.012.007a11.116 11.116 0 0 0 5.57 1.501c1.926 0 3.845-.504 5.57-1.501l.011-.007 55.527-32.473.006-.003c3.475-2.014 5.559-5.713 5.559-9.732V46.844c0-3.963-2.076-7.714-5.559-9.732l-.006-.003L75.763 4.682l-.01-.006a11.11 11.11 0 0 0-5.57-1.502 11.11 11.11 0 0 0-5.57 1.502Z' />
                        <path
                            fill='#3E257A' stroke='#3E257A' strokeWidth='2.967'
                            d='m11.303 41.008.006-.004L66.843 8.528c.001 0 .002 0 .003-.002a6.66 6.66 0 0 1 3.338-.9 6.66 6.66 0 0 1 3.338.9l.003.002 55.533 32.43.005.004c2.068 1.196 3.343 3.455 3.343 5.882v64.958c0 2.469-1.271 4.684-3.343 5.883l-.006.003-55.532 32.476-.003.002a6.667 6.667 0 0 1-3.338.901 6.667 6.667 0 0 1-3.338-.901l-.003-.002-55.532-32.476-.007-.003c-2.068-1.197-3.343-3.455-3.343-5.883V46.89c0-2.469 1.271-4.684 3.343-5.882Z' />
                    </svg>
                </div>
            );
        });
    };

    const makeAchievementItems = (items, search, sortAlpha) => {
        if (sortAlpha) items = items.sort((a, b) => a.videoTitle.localeCompare(b.videoTitle));

        // handle search for video titles
        if (search) {
            items = items.filter((video) => video.title.toLowerCase().includes(search));
        }

        return items.map((item, index) => {

            return (
                <>
                    <div tw=' flex flex-col items-center justify-center'>
                        <div tw='relative z-20'>
                            <div
                                tw='max-w-[122px] z-20' data-tooltip-id='tooltip-achievement'
                                data-tooltip-content={item.achievementDescription} data-tooltip-delay-show={500}>
                                <img tw='w-full h-auto' src={item.achievementImage} alt='record' />
                            </div>
                        </div>
                        <div tw='text-white text-sm font-normal pt-4 text-center'>{item.title}</div>
                        <div tw='text-[#cec2f0] text-[13px] font-normal text-center '>{wrapTitleParens(item.videoTitle)}</div>
                    </div>

                    <Tooltip
                        id='tooltip-achievement'
                        style={{
                            backgroundColor: '#6441B9',
                            color: '#fff',
                            fontSize: '12px',
                            width: '200px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            zIndex: 50

                        }}
                    />
                </>
            );
        });
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    // if this is not a teacher, use our achievements
    let dashboardStudentAchievements = achievements;

    // for parents, gather achievements from each video
    if (parent && videos?.length > 0) {
        videos.forEach((v) => {
            let achievements = v?.stats?.achievements ?? [];
            achievements.forEach((a) => {
                dashboardStudentAchievements.push(a);
            });
        });
    }

    const items = useMemo(() => {
        if (!videos) {
            return [];
        }
        // filter videos that have no stats or quiz_level data
        // create plaque items to easy filter and sort
        // let achievementItems = dashboardStudentAchievements.length > 0 ? dashboardStudentAchievements : achievements;
        return dashboardStudentAchievements.map((achievement) => {
            const achievementTitle = AchievementConstants[achievement.badge_type].header;
            const achievementDescription = AchievementConstants[achievement.badge_type].description;
            const achievementVideo = videos.find((video) => video.id === achievement.video_id);
            const achievementImage = AchievementConstants[achievement.badge_type].image;
            return {
                title: achievementTitle,
                videoTitle: achievementVideo?.title,
                achievementImage: achievementImage,
                date: achievement.datetime_awarded,
                achievementDescription: achievementDescription
            };
        }); // filter out missing videos
    }, [videos, dashboardStudentAchievements]).filter((achievement) => achievement.videoTitle);

    const nullAchievementList = Object.keys(AchievementConstants).reduce((accumulator, achievementKey) => {
        const achievement = AchievementConstants[achievementKey];

        // check if it's a single video constant
        if (achievement?.single_video && achievement?.show_null_state) {
            let videosInstance = videos;
            if (parent) videosInstance = allVideos;
            // loop through all videos
            videosInstance.forEach(video => {
                // create a list of achievements that have been earned for this specific video
                let foundAchievements = dashboardStudentAchievements.find(a => a.video_id === video.id && a.badge_type === achievementKey);
                if (!foundAchievements && video?.stats?.current_level > 0) {
                    const achievementTitle = achievement.header;
                    const achievementDescription = achievement.description;
                    const achievementVideo = video;
                    const achievementImage = achievement.image;
                    accumulator.push({
                        title: achievementTitle,
                        videoTitle: achievementVideo?.title,
                        achievementImage: achievementImage,
                        date: achievement.datetime_awarded,
                        achievementDescription: achievementDescription
                    });
                }
            });
        }

        // sort by videoTitle then title
        return [...accumulator].sort((a, b) => {
            if (a.videoTitle === b.videoTitle) {
                return a.title.localeCompare(b.title);
            }
            return a.videoTitle.localeCompare(b.videoTitle);
        });
    }, []);

    let nullStateCheck = items.length === 0;
    const allItemsEarned = items.sort((a, b) => moment(b.date, 'YYYY-MM-DDTHH:mm:ss').diff(moment(a.date, 'YYYY-MM-DDTHH:mm:ss')));
    const recentlyEarnedItems = allItemsEarned.slice(0, 3);
    const itemsWithoutMostRecent = allItemsEarned.slice(3);

    // filter activity for only achievement:created
    // filter activity that is after the 7 days or 30 days
    let loadedActivity = activity ? (Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity) : null;
    let filterActivity = loadedActivity?.filter((a) => a.name === 'achievement:created');
    const achievementsEarnedThisWeek = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(7, 'days'))).length;
    const achievementsEarnedMonth = filterActivity?.filter((a) => moment.utc(a.date).isAfter(moment().subtract(30, 'days'))).length;


    // group achievement items to determine the most earned achievement 
    const groupReduce = (objects, key) => {
        const groupedObjects = {};
        for (const obj of objects) {
            const objKey = obj[key];
            if (!(objKey in groupedObjects)) {
                groupedObjects[objKey] = {
                    count: 0,
                    data: []
                };
            }
            groupedObjects[objKey].count++;
            groupedObjects[objKey].data.push(obj);
        }

        const reducedObjects = {};

        for (const [objKey, { count, data }] of Object.entries(groupedObjects)) {
            reducedObjects[objKey] = {
                count,
                data: data.reduce((acc, curr) => ({ ...acc, ...curr }))
            };
        }

        return reducedObjects;
    };
    const reducedObjects = parent ? groupReduce(filteredChildAchievements, 'achievement') : groupReduce(achievements, 'badge_type');


    // find which bade has the highest count (most times earned)
    const highestCountBadge = Object.values(reducedObjects).find(badge => {
        return badge.count === Math.max(...Object.values(reducedObjects).map(b => b.count));
    });

    const highestCountBadgeType = parent ? highestCountBadge?.data?.achievement : highestCountBadge?.data?.badge_type;
    const highestCountBadgeCount = highestCountBadge?.count;

    let mostEarned = null;


    if (highestCountBadge && AchievementConstants[highestCountBadgeType]) {
        const badge = AchievementConstants[highestCountBadgeType];
        mostEarned = (
            <div tw='max-w-[37px] relative -mt-2.5'>
                <img tw='w-full h-auto ' src={badge.non_ribbon_image} alt={badge.title} />
                <div tw='absolute -top-1 -right-2.5 text-white bg-[#02AC6F] rounded-full h-5 w-5'>
                    {highestCountBadgeCount}
                </div>
            </div>
        );
    };

    const contents = [
        { title: 'total earned', value: items.length },
        { title: 'earned this week', value: achievementsEarnedThisWeek },
        { title: 'earned this month', value: achievementsEarnedMonth },
        { title: 'most earned', value: mostEarned }
    ];

    return (
        <div>
            <DTCStatsBanner
                title='My Achievements'
                contents={contents}
            />
            {!nullStateCheck ?
                <div tw='bg-[#3E257A] rounded-b-lg pb-5'>
                    <div tw='px-4 lg:max-h-[555px] overflow-x-auto lg:overflow-y-auto mr-4' css={[ScrollBarStyle]}>
                        <div tw='bg-[#4D3095] rounded-lg px-4 pt-6 pb-9 mt-6'>
                            <div tw='text-[#E3E2E3] font-medium text-base'>Recently Earned</div>
                            <div
                                tw='grid grid-cols-2 px-1 xsm:px-0 md:grid-cols-2 lg:grid-cols-3  gap-2 mt-4 justify-center items-start' >
                                {makeAchievementItems(recentlyEarnedItems, search)}
                            </div>
                        </div>
                        <div tw='px-1 xsm:px-5'>
                            <div tw='text-[#E3E2E3] font-medium text-base mt-6'>More Achievements Earned</div>
                            <div tw='grid grid-cols-2  md:grid-cols-2 lg:grid-cols-3 gap-2 gap-y-4 mt-6 justify-center items-start pb-5' >
                                {makeAchievementItems(itemsWithoutMostRecent, search, true)}

                                {nullAchievementList && nullAchievementList.map((achievement, index) => (
                                    <div key={index}>

                                        <div tw=' flex flex-col items-center justify-center'>
                                            <div tw='relative z-20'>
                                                <div tw='max-w-[122px] z-20'>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg' width='45' height='41'
                                                        viewBox='130 15 40 50'
                                                        fill='none' tw='z-60 absolute top-[5px] right-[-12px]'>
                                                        <circle cx='151.191' cy='42.1367' r='21.6406' fill='#FA6778' />
                                                        <path
                                                            d='M161.084 42.1366C161.084 40.9773 160.044 40.0344 158.766 40.0344H157.606V36.8811C157.606 33.9831 155.006 31.6255 151.81 31.6255C148.613 31.6255 146.013 33.9831 146.013 36.8811V40.0344H144.854C143.575 40.0344 142.535 40.9773 142.535 42.1366V50.5456C142.535 51.705 143.575 52.6478 144.854 52.6478H158.766C160.044 52.6478 161.084 51.705 161.084 50.5456V42.1366ZM148.332 36.8811C148.332 35.1425 149.892 33.7277 151.81 33.7277C153.727 33.7277 155.288 35.1425 155.288 36.8811V40.0344H148.332V36.8811Z'
                                                            fill='white' />
                                                    </svg>

                                                    <img
                                                        tw='w-full h-auto opacity-40'
                                                        src={achievement.achievementImage} alt='record' />

                                                </div>
                                            </div>
                                            <div
                                                tw='text-white text-sm font-normal pt-4 text-center opacity-40'>{achievement.title}</div>
                                            <div
                                                tw='text-[#9D91BB] text-sm font-normal text-center opacity-40'>{wrapTitleParens(achievement.videoTitle)}</div>
                                        </div>
                                    </div>

                                ))}


                            </div>
                        </div>
                    </div>
                </div>
                :
                <div tw='bg-[#3E257A] rounded-b-lg pb-5'>
                    <div tw='px-4 lg:max-h-[555px] overflow-x-auto lg:overflow-y-auto mr-4' css={[ScrollBarStyle]}>
                        <div tw='bg-[#4D3095] rounded-lg px-4 pt-6 pb-9 mt-6'>
                            <div tw='text-[#BCB5CD] font-normal text-base'>Recently Earned</div>
                            <div
                                tw='grid grid-cols-2 px-1 xsm:px-0 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2.5 mt-4 justify-center'>
                                {makeNullAchievements()}
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Tooltip
                id='tooltip-achievement'
                style={{
                    backgroundColor: '#6441B9',
                    color: '#fff',
                    fontSize: '12px',
                    width: '200px',
                    textAlign: 'center',
                    borderRadius: '8px',
                    zIndex: 50

                }}
            />

        </div>
    );
};

export default MyAchievements;
