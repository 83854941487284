export const KEY_0 = { value: 0, key: '0' };
export const KEY_1 = { value: 1, key: '1' };
export const KEY_2 = { value: 2, key: '2' };
export const KEY_3 = { value: 3, key: '3' };
export const KEY_4 = { value: 4, key: '4' };
export const KEY_5 = { value: 5, key: '5' };
export const KEY_6 = { value: 6, key: '6' };
export const KEY_7 = { value: 7, key: '7' };
export const KEY_8 = { value: 8, key: '8' };
export const KEY_9 = { value: 9, key: '9' };

export const KEY_X = { value: 26, key: 'x' };
export const KEY_Y = { value: 27, key: 'y' };

export const KEY_PERIOD = { value: 10, key: '.' };

export const KEY_COMMA = { value: 11, key: ',' };
export const KEY_COLON = { value: 12, key: ':' };
export const KEY_DOLLAR = { value: 13, key: '$' };
export const KEY_ADD = { value: 14, key: '+' };
export const KEY_SUBTRACT = { value: 15, key: '-' };

export const KEY_GREATER = { value: 16, key: '>' };
export const KEY_GREATER_EQUAL = { value: 17, key: '&ge;', unicode: 8805 };

export const KEY_LESS = { value: 18, key: '<' };
export const KEY_LESS_EQUAL = { value: 19, key: '&le;', unicode: 8804 };

export const KEY_PAREN_OPEN = { value: 20, key: '(' };
export const KEY_PAREN_CLOSED = { value: 21, key: ')' };
export const KEY_EQUAL = { value: 22, key: '=' };
export const KEY_INFINITY = { value: 23, key: '&infin;', unicode: 8734 };
export const KEY_MULTIPLY = { value: 24, key: '&times;', unicode: 215 };
export const KEY_DIVIDE = { value: 25, key: '&divide;', unicode: 247 };

export const KEY_SLASH = { value: 26, key: '/' };
export const KEY_EXPONENT = { value: 27, key: '^' };
export const KEY_PERCENT = { value: 28, key: '%' };

export const KEY_UNEQUAL = { value: 28, key: '&ne;', unicode: 8800 };
export const KEY_DELETE = { value: 46, key: 'delete',  };
