export const record_colors = ['Warm Up', 'Silver', 'Gold', 'Platinum'];

export const level_titles = ['Warmup', 'Silver', 'Gold', 'Platinum'];

export const level_styles = ['warmup', 'silver', 'gold', 'platinum'];

export const challenge_titles = ['Warm Up', 'Silver Challenge', 'Gold Challenge', 'Platinum Challenge'];

const AchievementConfig = {
    login_streak: {
        header: 'Login Streak',
        icon: 'icon_url',
        levels: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    },

    single_video_times_watched: {
        header: 'Times Video Watched',
        icon: 'icon_url'
    },

    single_video_minutes_watched: {
        header: 'Minutes video watched',
        icon: 'icon_url'
    },

    single_video_pretest_perfect_score: {
        header: 'Perfect score on Warm up',
        icon: 'icon_url'
    },

    single_video_total_records: {
        header: 'Records on this Video',
        icon: 'icon_url'
    },

    single_video_mastery: {
        header: 'Video Mastered ',
        icon: 'icon_url'
    },

    single_video_3_times_watched: {
        header: 'Watched video 3 times',
        icon: 'icon_url'
    },

    single_video_6_times_watched: {
        header: 'Watched video 6 times',
        icon: 'icon_url'
    },

    single_video_10_times_watched: {
        header: 'Watched video 10 times',
        icon: 'icon_url'
    },

    single_video_silver_first_try_perfect: {
        header: 'Silver Record on First Attempt',
        icon: 'icon_url'
    },

    single_video_gold_first_try_perfect: {
        header: 'Gold Record on First Attempt',
        icon: 'icon_url'
    },

    single_video_platinum_first_try_perfect: {
        header: 'Platinum Record on First Attempt',
        icon: 'icon_url'
    },

    single_video_growth_low_pretest_gold: {
        header: 'Low Pretest Growth Gold',
        icon: 'icon_url'
    },

    single_video_growth_low_pretest_platinum: {
        header: 'Low Pretest Growth Platinum',
        icon: 'icon_url'
    },

    single_video_growth_faster_gold: {
        header: 'Fast Growth Gold',
        icon: 'icon_url'
    },
    single_video_growth_faster_platinum: {
        header: 'Fast Growth Platinum',
        icon: 'icon_url'
    },
    single_video_weakness_to_strength: {
        header: 'Weakness to strength',
        icon: 'icon_url'
    },

    single_video_platinum_record: {
        header: 'Got a platinum record',
        icon: 'icon_url'
    }
};

export default AchievementConfig;
