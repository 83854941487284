import React from 'react';

const GradeSVG = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.9537 14.2044L1.17692 9.89373C1.07284 9.8521 1.07284 9.70476 1.17692 9.66313L11.9537 5.35243C11.9833 5.34059 12.0163 5.34059 12.0459 5.35243L22.8226 9.66313C22.9267 9.70476 22.9267 9.8521 22.8226 9.89373L12.0459 14.2044C12.0163 14.2163 11.9833 14.2163 11.9537 14.2044Z' stroke='#E775EF' strokeWidth='1.5' />
            <path d='M22 9.7793V15.3349' stroke='#E775EF' strokeWidth='1.5' strokeLinecap='round' />
            <path d='M17.5545 12L11.9989 13.8182L6.44336 12V16.4444C6.44336 17.6717 7.43828 18.6667 8.66558 18.6667H15.3322C16.5595 18.6667 17.5545 17.6717 17.5545 16.4444V12Z' stroke='#E775EF' strokeWidth='1.5' />
        </svg>
    );
};

export default GradeSVG;