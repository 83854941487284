import React, { useCallback } from 'react';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import NotificationDropdown from 'components/NotificationDropdown/NotificationDropdown';
import MXPSVGSmall from 'components/MXPSVGSmall/MXPSVGSmall';
import LogoFull from 'static/images/logo/logo-nav-dtc.png';
import LogoHeart from 'static/images/logo/logo_heart@2x.png';
import { css } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useSearch } from 'context/SearchProvider';
import SearchInput from '../SearchInput';
import NullUserImg from 'static/images/user.png';

const TopMobileNav = ({ user, initials, openMobileMenu, isMobileMenuOpen, noFirstName }) => {
    const showSmallLogo = useMedia(user?.instructor ? '(max-width: 767px)' : '(max-width: 1055px)');
    const { search, setDropDown } = useSearch();
    const [showInput, setShowInput] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    const resetInputField = useCallback(() => {
        search(null);
    }, [search]);

    const handleClick = () => {
        setShowInput(!showInput);
        resetInputField();
        setSearchValue('');
    };

    return (

        <div tw='relative flex items-center justify-between'>
            {/* Logo */}
            <div tw='flex items-center'>
                <NavLink to='/' onClick={() => { search(null); setDropDown(null); }}>
                    <div tw='flex items-center justify-center'>
                        <img id='muzology-logo' tw='h-12 w-auto' src={showSmallLogo ? LogoHeart : LogoFull} alt='Muzology Logo' />
                    </div>
                </NavLink>
            </div>

            <div tw='flex items-center'>
                <div tw='absolute inset-0 flex items-center justify-between' css={showInput ? { backgroundColor: '#3E257A' } : { display: 'none' }}>
                    <SearchInput onInputEmpty={() => setShowInput(false)} onInputChange={value => setSearchValue(value)} />
                    <button tw='pl-2 text-[#46A6F8]' onClick={handleClick}>Cancel</button>

                </div>
                {showInput && searchValue === '' && <div tw='fixed bg-gray-900 bg-opacity-50 w-full h-full left-[0px] top-[64px]'></div>}

                <div tw='flex items-center justify-end space-x-3 md:space-x-8' css={showInput ? { display: 'none' } : {}}>
                    <button onClick={handleClick}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                            fill='none'>
                            <path d='M21 21L18 18' stroke='#9D91BB' strokeWidth='1.5' strokeLinecap='round' />
                            <circle
                                cx='11' cy='12' r='9' stroke='#9D91BB'
                                strokeWidth='1.5' />
                        </svg>
                    </button>

                    {/* notifications  */}
                    {!user?.instructor && (
                        <NotificationDropdown>
                            <button type='button' tw='rounded-full text-white hover:text-white'>
                                <span tw='sr-only'>View notifications</span>
                                <svg
                                    width='26' height='26' viewBox='0 0 26 26' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M10.8333 4.33329C10.8333 3.13668 11.8034 2.16663 13 2.16663V2.16663C14.1966 2.16663 15.1667 3.13668 15.1667 4.33329V4.33329H10.8333V4.33329Z' stroke='#9D91BB' strokeWidth='1.5' />
                                    <path d='M9.75 20.5833C9.75 22.3782 11.2051 23.8333 13 23.8333V23.8333C14.7949 23.8333 16.25 22.3782 16.25 20.5833V20.5833H9.75V20.5833Z' stroke='#9D91BB' strokeWidth='1.5' />
                                    <path d='M6.5 9.88881C6.5 6.82056 8.98731 4.33325 12.0556 4.33325H13.9444C17.0127 4.33325 19.5 6.82056 19.5 9.88881V17.3333H6.5V9.88881Z' stroke='#9D91BB' strokeWidth='1.5' />
                                    <rect
                                        x='4.33334' y='17.3333' width='17.3333' height='3.25'
                                        rx='1.625' stroke='#9D91BB' strokeWidth='1.5' />
                                </svg>

                            </button>
                        </NotificationDropdown>
                    )}
                    <div tw='flex'>
                        <DropdownMenu user={user}>
                            {user?.is_parent ? (
                                <svg
                                    width='32' height='32' viewBox='0 0 32 32' fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M31.231 17.736C31.294 17.166 31.334 16.588 31.334 16C31.334 15.412 31.294 14.834 31.23 14.263L26.852 12.706C26.634 11.99 26.348 11.306 26.002 10.656L27.994 6.46399C27.269 5.55485 26.4445 4.72971 25.536 4.00399L21.344 5.99999C20.6906 5.64926 20.0038 5.36452 19.294 5.14999L17.738 0.771992C17.167 0.707992 16.589 0.666992 16.001 0.666992C15.413 0.666992 14.835 0.706992 14.264 0.771992L12.708 5.14999C11.992 5.36699 11.308 5.65199 10.658 5.99999L6.46497 4.00399C5.55497 4.72899 4.73097 5.55399 4.00497 6.46399L6.00097 10.654C5.65297 11.304 5.36797 11.989 5.15097 12.704L0.772969 14.264C0.708969 14.834 0.667969 15.414 0.667969 16.001C0.667969 16.588 0.707969 17.166 0.772969 17.737L5.15097 19.295C5.36797 20.01 5.65297 20.695 6.00097 21.345L4.00597 25.537C4.73097 26.447 5.55597 27.27 6.46597 27.995L10.655 26C11.305 26.348 11.99 26.634 12.705 26.852L14.263 31.229C14.833 31.292 15.411 31.332 16 31.332C16.588 31.332 17.165 31.292 17.736 31.228L19.294 26.85C20.009 26.632 20.694 26.346 21.344 26L25.536 27.992C26.446 27.267 27.269 26.442 27.994 25.534L26.001 21.343C26.349 20.696 26.635 20.009 26.853 19.293L31.23 17.736H31.231ZM16.001 20.87C15.3613 20.8701 14.7279 20.7443 14.1368 20.4996C13.5458 20.2549 13.0088 19.8962 12.5564 19.444C11.6427 18.5307 11.1292 17.2919 11.129 16C11.1287 14.7081 11.6416 13.4691 12.5549 12.5554C13.4682 11.6417 14.7071 11.1283 15.999 11.128C17.2908 11.1277 18.5299 11.6407 19.4436 12.554C20.3573 13.4673 20.8707 14.7061 20.871 15.998C20.8712 17.2899 20.3583 18.5289 19.445 19.4426C18.5317 20.3563 17.2928 20.8697 16.001 20.87Z'
                                        fill='white'
                                    />
                                </svg>
                            ) : (
                                <div tw='flex items-center cursor-pointer'>
                                    <button
                                        type='button'
                                        tw='text-white font-semibold text-2xl bg-OceanBlue border-white border-2 h-10 w-10 flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'
                                        id='user-menu'
                                        aria-expanded='false'
                                        aria-haspopup='true'
                                    >
                                        <span tw='sr-only'>Open user menu</span>
                                        {noFirstName ? <img src={NullUserImg} /> : initials}
                                    </button>
                                    <div tw='ml-2 '>
                                        <svg
                                            width='12' height='8' viewBox='0 0 12 8' fill='none'
                                            xmlns='http://www.w3.org/2000/svg'>
                                            <path d='M1.33334 1.33331L5.62372 6.2366C5.82293 6.46427 6.17709 6.46427 6.3763 6.2366L10.6667 1.33331' stroke='#9D91BB' strokeWidth='1.5' strokeLinecap='round' />
                                        </svg>
                                    </div>
                                </div>

                            )}
                        </DropdownMenu >
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TopMobileNav;

