// website url: www.muzology.com
export const MUZOLOGY_WEBSITE_URL = process.env.REACT_APP_MUZOLOGY_WEBSITE_URL ?? 'https://web-dev.muzology.com/';
// console.log('MUZOLOGY_WEBSITE_URL', MUZOLOGY_WEBSITE_URL);
// frontend url (this site): app.muzology.com
export const MUZOLOGY_APP_URL = process.env.REACT_APP_MUZOLOGY_PLATFORM_URL ?? 'https://app.muzology.com/';

// these are used by Helmet for the browser window title
export const MUZOLOGY_APP_TITLE = 'Muzology';
export const MUZOLOGY_TITLE_TEMPLATE = '%s - Muzology';

// server url: api.muzology.com
export const MUZOLOGY_API_URL = process.env.REACT_APP_MUZOLOGY_API_URL ?? 'https://app-server-staging.muzology.com/';

// ACE frontend url
export const MUZOLOGY_ACE_URL = process.env.REACT_APP_MUZOLOGY_ACE_URL ?? 'https://ace-staging.muzology.com/';

// terms of service & privacy policy
export const URL_TERMS_OF_SERVICE = 'https://www.muzology.com/tos/';
export const URL_PRIVACY_POLICY = 'https://www.muzology.com/privacy/';

// MUX
export const MUX_KEY = process.env.REACT_APP_MUX_KEY;
export const MUX_PLAYER_NAME = process.env.REACT_APP_MUX_PLAYER_NAME;

// Pusher
export const PUSHER_CLIENT_KEY = process.env.REACT_APP_PUSHER_CLIENT_KEY;
export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
export const PUSHER_AUTH_API_URL = process.env.REACT_APP_PUSHER_AUTH_API_URL;


// production vs development
// environment
export const environment = process.env.REACT_APP_VERCEL_ENV ?? 'development';
export const isProduction = environment === 'production';
export const isDevelopment = environment === 'development' || environment === '';
export const isStaging = environment === 'staging';
export const isPreview = environment === 'preview';

// videos
export const STUDENT_TUTORIAL_VIDEO_ID = '937450653'; // was: 791725993
export const CHILD_TUTORIAL_VIDEO_ID = '937450653'; // was: 791732231
export const PARENT_TUTORIAL_VIDEO_ID = '937450653';
export const TEACHER_TUTORIAL_VIDEO_ID = '935586319';
export const TIMES_TABLES_TUTORIAL_VIDEO_ID = '994811213';

export const STUDENT_TUTORIAL_VIDEO_URL = `https://vimeo.com/${STUDENT_TUTORIAL_VIDEO_ID}`;
export const CHILD_TUTORIAL_VIDEO_URL = `https://vimeo.com/${CHILD_TUTORIAL_VIDEO_ID}`;
export const PARENT_TUTORIAL_VIDEO_URL = `https://vimeo.com/${PARENT_TUTORIAL_VIDEO_ID}`;
export const TEACHER_TUTORIAL_VIDEO_URL = `https://vimeo.com/${TEACHER_TUTORIAL_VIDEO_ID}`;
export const TIMES_TABLES_TUTORIAL_VIDEO_URL = `https://vimeo.com/${TIMES_TABLES_TUTORIAL_VIDEO_ID}`;

// course libraries
export const catalog_math_v1 = '86bad3bb-51ae-4f64-947c-26c296901639';
export const catalog_math_v2 = '8b3df708-5f98-436e-96d5-006e734b1b65';
export const catalog_times_tables = '1b2a019e-3ae2-447c-b470-0bbba294358b';
