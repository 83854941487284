import * as React from 'react';

function SvgClassIcon13(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#D2FF1D' />
            <path
                d='M31.466 15.084l-7.35-1.118-3.285-6.975a.944.944 0 00-.42-.44c-.457-.236-1.013-.04-1.242.44l-3.285 6.975-7.35 1.118a.91.91 0 00-.53.282.997.997 0 00-.262.69.994.994 0 00.28.683l5.317 5.429-1.256 7.666c-.03.178-.01.361.055.53a.962.962 0 00.313.418.89.89 0 00.975.073L20 27.236l6.574 3.62c.18.1.388.133.587.096.504-.09.843-.59.756-1.118l-1.256-7.666 5.317-5.429a.984.984 0 00.27-.555c.077-.53-.276-1.021-.782-1.1z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon13;
