import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import useAxios from 'axios-hooks';
import { v4 as uuidv4 } from 'uuid';
import { useDevTool } from 'lib/DevTool/DevTool';
import './VideoPlayer_bitmovin.css';

// muzology api client
// integrations
import { useVideoStatsUpdate } from 'state/Student/VideoStats';
import { time_ranges_to_array } from 'components/VideoPlayer/util';
import { useVideoPlayer } from '../../components/VideoPlayer/VideoPlayer';

const Context = createContext();

const CREATE_VIDEO_SESSION = 'create-video-session';
const ADD_EVENT = 'add-event';
const SESSION_FINISHED = 'finished';

const POSTING_EVENTS_BEGAN = 'posting-events-began';
const POSTING_EVENTS_SUCCESS = 'posting-events-success';
const POSTING_EVENTS_FAILED = 'posting-events-failed';

const POSTING_COMPLETION_BEGAN = 'posting-completion-began';
const POSTING_COMPLETION_SUCCESS = 'posting-completion-success';
const POSTING_COMPLETION_FAILED = 'posting-completion-failed';

function videoSessionReducer(state, action) {
    switch (action.type) {
        case CREATE_VIDEO_SESSION: {
            // console.log('[VideoSessionProvider]  Reducer: create video session', action.payload);
            return {
                ...state,
                ...action.payload,
                // video_id: action.payload.video_id,
                // lesson_id: action.payload.lesson_id,
                session_id: null,
                session_key: uuidv4(),
                start_time: new Date().toISOString(),
                start_timestamp: Date.now(),
                stop_time: null,
                stop_timestamp: null,
                context: {},
                events: [],
                playing: false,
                finished: false,
                state: 'created'
            };
        }

        case 'set state':
            // console.log(action,state);
            return {
                ...state,
                state: action.payload ?? state.state
            };

        case ADD_EVENT:
            // console.log(action,state);
            return {
                ...state,
                events: [...(state.events ?? []), action.payload]
            };

        case SESSION_FINISHED:
            return {
                ...state,
                finished: true,
                state: 'finished'
            };

        case POSTING_EVENTS_BEGAN: {
            // copy the events to posting_events
            if (state.posting || state.events?.length === 0) return state;
            return {
                ...state,
                events: [],
                posting: true,
                posting_events: state.events
            };
        }

        case POSTING_EVENTS_SUCCESS: {
            // posting succeeded - empty posting_events
            return {
                ...state,
                posting: false,
                posting_events: null
            };
        }

        case POSTING_EVENTS_FAILED: {
            // posting failed - copy posting_events back to events
            if (!state.posting || state.posting_events?.length === 0) return state;
            return {
                ...state,
                events: [...state.events, ...state.posting_events],
                posting: false,
                posting_events: null
            };
        }

        case POSTING_COMPLETION_BEGAN:
            return state;
        case POSTING_COMPLETION_SUCCESS:
            return state;
        case POSTING_COMPLETION_FAILED:
            return state;

        default:
            console.log('[VideoSessionProvider] unknown action', action);
            return state;
    }
}

export const VideoSessionProvider = ({ children }) => {

    const [state, dispatch] = useReducer(
        videoSessionReducer,
        {
            state: 'created',
            session_id: null,
            events: [],
            error: null,
            finished: false,
            posting: false,
            posted: false
        },
        undefined
    );
    // sessions that are being uploaded
    const [postingSessions, setPostingSessions] = useState([]);
    const [postedSessions, setPostedSessions] = useState([]);
    const [failedSessions, setFailedSessions] = useState([]);

    useDevTool('VideoSessionProvider', {
        state,
        postingSessions,
        postedSessions,
        failedSessions
    });

    // create a reference to the state
    const stateRef = useRef();
    stateRef.current = state;

    // create a new session
    const createSession = useCallback(
        (video_id = null, lesson_id = null, context = {}) => {
            console.log('* [VideoSessionProvider] createSession: ', video_id, lesson_id, context);
            // a video id is required
            if (!video_id) {
                console.log('* [VideoSessionProvider] Warning: createSession video_id is invalid', video_id);
                // re-create session
                video_id = state?.video_id;
            } else {
                // calling init - invalid state
                if (video_id === state?.video_id && !state.finished) {
                    console.log('* [VideoSessionProvider] Warning: createSession video session already initialized (and not finished)', state?.session_id);
                    // don't do anything
                    // return;
                }
            }

            // create the session
            const session_key = uuidv4();
            console.log('* [VideoSessionProvider] createSession:', session_key, video_id, lesson_id, context);
            const session = {
                video_id: video_id,
                lesson_id: lesson_id ?? null,
                context: context ?? {}
            };

            console.log('* [VideoSessionProvider] createSession:', session);
            return dispatch({ type: CREATE_VIDEO_SESSION, payload: session });
        },
        [state?.finished, state?.session_id, state?.video_id]
    );

    // begin playing a session
    const [{ loading: isPosting }, postSession] = useAxios({
        url: '/api/video_sessions/',
        method: 'POST',
        withCredentials: true
    }, { manual: true });

    // const beginSession = useCallback(
    //     () => {
    //         // const session_id = uuidv4();
    //         console.log('* BEGIN SESSION *', state);
    //         // create session
    //         dispatch({ type: 'set state', payload: 'creating' });
    //
    //         // dispatch({ type: 'initialize', payload: { session_id, video_id, lesson_id } });
    //         const data = {
    //             session_id: state.session_id,
    //             video_id: state.video_id,
    //             lesson_id: state.lesson_id
    //         };
    //
    //         console.log('Posting video session');
    //         return postSession({ data })
    //             .then(({ data }) => {
    //                 console.log('Video session posted complete: ', data);
    //                 return dispatch({ type: 'create', payload: data });
    //             });
    //     },
    //     [state, postSession]
    // );

    // const postEvents = useCallback(
    //     () => {
    //         const currentState = stateRef.current;
    //         return postSession({ url: `/api/video_sessions/${currentState.session_id}/events/`, data: { events: currentState.events } });
    //     },
    //     [postSession]
    // );

    // session finished
    const updateStats = useVideoStatsUpdate();
    // const [{ loading: isPostingFinished }, postSessionFinished] = useAxios({ url: '/api/video_sessions/', method: 'POST', withCredentials: true }, { manual: true });


    // upload the current session
    const uploadSession = useCallback(() => {

    }, []);

    const finishSession = useCallback(
        (played) => {
            /* session finished - save to server */
            console.log('* [VideoSessionProvider] finishSession: ', stateRef.current, played);

            const currentState = stateRef.current;

            // check if played ranges are empty
            if (!played || played.length === 0) {
                console.log('* [VideoSessionProvider] finishSession() called with no played ranges. Skipping.');
                // <<<<<<< development
                //                 return;
                //             }

                //             // check if this is flagged as finished
                //             if (currentState.finished) {
                //                 console.log('* [VideoSessionProvider] finishSession() called on already finished session. Skipping.');
                //                 // return;
                //             }

                //             // check if the session has no player events
                //             if (currentState.events.length === 0) {
                //                 // session is empty - delete it
                //                 console.log('* [VideoSessionProvider] finishSession() called on empty session. Deleting.');

                //                 // start a new session
                //                 createSession(currentState.video_id, currentState.lesson_id, currentState.context);
                //                 return;
                //             }

                //             // check if the session is already being posted
                //             if (postingSessions.includes(currentState.session_key)) {
                //                 console.log('* [VideoSessionProvider] finishSession() called on already posting session. Skipping.');
                //                 return;
                //             }

                //             // check if the session is already posted
                //             if (postedSessions.includes(currentState.session_key)) {
                //                 console.log('* [VideoSessionProvider] finishSession() called on already posted session. Skipping.');
                //                 return;
                // =======
                // start a new session
                createSession();
                return;
            }

            if (currentState.finished) {
                console.log('* [VideoSessionProvider] finishSession() called on already finished session. Skipping.');
                // start a new session
                createSession();
                return;
            }

            // check if the session has no player events
            if (currentState.events.length === 0) {
                // session is empty - delete it
                console.log('* [VideoSessionProvider] finishSession() called on empty session. Deleting.');
                // start a new session
                createSession();
                return;
            }

            // check if the session is already being posted
            if (postingSessions.includes(currentState.session_key)) {
                console.log('* [VideoSessionProvider] finishSession() called on already posting session. Skipping.');
                // start a new session
                createSession();
                return;
            }
            // check if the session is already posted
            if (postedSessions.includes(currentState.session_key)) {
                console.log('* [VideoSessionProvider] finishSession() called on already posted session. Skipping.');
                // start a new session
                createSession();
                return false;
                // >>>>>>> production
            }

            // stop the session
            currentState.stop_time = new Date().toISOString();
            currentState.stop_timestamp = Date.now();
            currentState.played = played;

            // mark the current state as finished
            dispatch({
                type: 'finished',
                payload: {
                    stop_time: currentState.stop_time,
                    stop_timestamp: currentState.stop_timestamp,
                    played_ranges: played
                }
            });

            // get current state
            // currentState = stateRef.current.getState();

            // post the session
            const payload = {
                ...currentState,
                video: currentState.video_id,
                lesson: currentState.lesson_id,
                played: played
            };

            // mark the session as posting
            console.log('* [VideoSessionProvider] * Posting finish session', currentState, payload);
            setPostingSessions((sessions) => [...sessions, payload]);

            // create a new session
            createSession();

            // post the session
            console.log('[VideoSessionProvider] * Posting finish session', payload);
            return postSession({ data: payload })
                .then(({ data }) => {
                    console.log('[VideoSessionProvider] finish session:', data);

                    // remove the session from the posting list, and add it to the posted list
                    setPostingSessions((sessions) => sessions.filter((session) => session.session_key !== payload.session_key));
                    setPostedSessions((sessions) => [...sessions, payload]);

                    // update the stats
                    const { stats } = data;
                    if (stats) {
                        updateStats(stats);
                    }

                    // mark state as finished
                    // dispatch({ type: 'finished' });

                    // reset the session to the beginning
                    // if (state.session?.video_id)
                    //     createSession(state.session.video_id);

                })
                .catch((err) => {
                    console.log('[VideoSessionProvider] error posting session', err);
                    console.error(err);

                    // remove the session from the posting list, and add it to the failed list
                    setPostingSessions((sessions) => sessions.filter((session) => session.session_key !== payload.session_key));
                    setFailedSessions((sessions) => [...sessions, payload]);
                    // TODO: retry failed sessions
                });
        },
        [createSession, postSession, postedSessions, postingSessions, updateStats]
    );

    // video events
    const onVideoEvent = useCallback((event) => {
        if (!event) {
            console.error('[VideoSessionProvider] onVideoEvent called with no event');
            return;
        }
        
        // add the event to the store
        dispatch({ type: ADD_EVENT, payload: event });

        // check for playbackfinished event
        if (event.type === 'playbackfinished') {
            // the session has ended, post it to the back end
            return finishSession(event.played);
        }

        // check for play event
        if (event.type === 'play') {
            console.log('[VideoSessionProvider] play event');
            // if the session has ended, re-create it
            if (state.finished) {
                console.log('[VideoSessionProvider] Re-creating session');
                createSession(state.video_id, state.lesson_id, state.context);
            }
            // finishSession(event.played);
        }

    }, [createSession, finishSession, state.context, state.finished, state.lesson_id, state.video_id]);

    // const onPlay = useCallback(() => {
    //     // addEvent('play');
    // }, [addEvent]);

    // const onPlayed = useCallback((played) => {
    //     // addEvent('played', played);
    // }, [addEvent]);

    // case POSTING_COMPLETION_BEGAN:
    //         return state;
    // case POSTING_COMPLETION_SUCCESS:
    //         return state;
    // case POSTING_COMPLETION_FAILED:

    // attempt to post events
    // const postEvents = useCallback(() => {
    //     // check if state is posting
    //     if (state.posting) {
    //         return false;
    //     }
    //
    //     if (state.events) {
    //         dispatch({ type: POSTING_EVENTS_BEGAN });
    //
    //         // post the session finished
    //         apiClient
    //             .post(`/api/video_sessions/${state.session_id}/finished/`)
    //             .then((response) => {
    //                 console.log('response', response);
    //                 dispatch({ type: POSTING_EVENTS_SUCCESS });
    //             })
    //             .catch((error) => {
    //                 handleNetworkError(error);
    //                 dispatch({ type: POSTING_EVENTS_FAILED });
    //             });
    //     }
    // }, [state]);

    // the session context
    const session = {
        // state
        session: state,

        // lifecycle
        createSession,
        // video events
        // addEvent,
        // onPlay,
        // onPlayed
        onVideoEvent,
        finishSession
    };

    // console.log('[VideoSessionProvider] render', state);
    return (
        <Context.Provider value={session} displayName='VideoSessionContext'>
            {children}
            {/*
            <VideoSessionDebugger
                session={session}
                state={state}
                postingSessions={postingSessions}
                postedSessions={postedSessions}
                failedSessions={failedSessions}
            />
            */}
        </Context.Provider>
    );
};

/*
    useVideoSession
 */

export function useVideoSession(video_id, options = {}) {
    // console.log('[useVideoSession]', video_id, options);

    // get the session context
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useVideoSession must be used within a VideoSessionProvider');
    }

    // get the session and actions
    const {
        session,
        createSession,
        finishSession,
        // addEvent
        onVideoEvent
    } = context;

    // create a map between event names and event handlers
    const eventHandlers = useMemo(() => {
        const handlers = options?.events ?? {};
        // const EVENT_MAP = [
        //     ['playing', 'onPlay'],
        //     ['paused', 'onPause'],
        //     ['playbackfinished', 'onEnd']
        // ];
        //
        //
        // const eventHandlers = EVENT_MAP.reduce((handlerMap, [event_type, handler_name]) => {
        //     if (handlers[handler_name])
        //         handlerMap[event_type] = handlers[handler_name];
        //     return handlerMap;
        // }, {});

        // get the handlers
        const {
            onPlay,
            onPause,
            onEnd
        } = handlers;

        const eventHandlers = {};
        if (onPlay)
            eventHandlers['playing'] = onPlay;

        if (onPause)
            eventHandlers['paused'] = onPause;

        if (onEnd)
            eventHandlers['playbackfinished'] = onEnd;

        return eventHandlers;
    }, [options?.events]);

    // the event handler for player events
    const onEvent = useCallback(
        (event) => {
            if( !event ) {
                console.error('[useVideoSession] onEvent called with no event');
                return;
            }

            // pass all events to the video session
            onVideoEvent(event);

            // pass special events to the hook caller
            if (eventHandlers[event.type])
                eventHandlers[event.type](event);
        },
        [eventHandlers, onVideoEvent]
    );

    // reference to the player
    const playerRef = useRef(null);

    // create a video player
    const VideoPlayer = useVideoPlayer(video_id, { playerType: options?.playerType });

    // player controls (play/pause)
    const play = useCallback(() => {
        // add the play event - this will begin the session if not already
        // context.addEvent('');
        console.log('[useVideoSession] play', playerRef.current);

        // playback
        if (playerRef.current)
            playerRef.current.play();
    }, []);

    const pause = useCallback(() => {
        if (playerRef.current) playerRef.current.pause();
    }, []);

    // let session_id = uuidv4();
    // const [sessionId] = useState(session_id);

    // const cleanup = useCallback(() => {
    //     // finish session
    //     // console.log('[useVideoSession] cleanup', video_id, session?.session_id);
    //     const played_ranges = time_ranges_to_array(playerRef.current?.played);
    //     console.log('[useVideoSession] cleanup played', played_ranges, session);
    //     finishSession(played_ranges);
    // }, [session, finishSession]);

    // initialize the video session
    useEffect(() => {
        // a video id is required
        if (!video_id) {
            console.error('[useVideoSession] Error: useVideoSession video_id is invalid', video_id);
            return;
        }

        // create a new session when the hook is mounted
        console.log('[useVideoSession] createSession', video_id);
        createSession(video_id);

        // Perform cleanup on hook termination
        return () => {
            console.debug('[VideoSessionProvider] useVideoSession cleanup', video_id, session?.session_id, playerRef.current, session);
            const currentPlayer = playerRef.current;
            if (currentPlayer) {
                // TODO: refactor this down into the player
                const played = currentPlayer.getVideoElement()?.played;
                const played_ranges = time_ranges_to_array(played);
                console.debug('[VideoSessionProvider] useVideoSession cleanup', video_id, session?.session_id, currentPlayer, session);
                finishSession(played_ranges);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // the video player
    const Player = useMemo(() => {
        // console.log('* [VideoSessionPlayer] render session Player', session.session_id);
        // const onPlayerEvent = (event) => {
        //     // console.log('[VideoSessionPlayer] onPlayerEvent:', event.type, 'data:', event);
        //     onVideoEvent(event);
        //     /*
        //     const played_ranges = time_ranges_to_array(playerRef.current?.played);
        //     console.log('player event', event, played_ranges);
        //     // console.log('event:', type, 'played:', played_ranges);
        //
        //     const eventHandlers = options?.events ?? {};
        //
        //     const getEventData = (data = {}, current = null) => {
        //         const seeking = current?.seeking ?? false;
        //         const scrubbing = current?.scrubbing ?? false;
        //         // console.log('seeking:', seeking, 'scrubbing:', scrubbing);
        //         return {
        //             time: current?.currentTime,
        //             seeking: seeking || scrubbing,
        //             scrubbing: scrubbing,
        //             ...data
        //         };
        //     };
        //
        //     const handleEvent = (eventName, handler, data) => {
        //         const eventData = getEventData(data, playerRef.current);
        //         addEvent(eventName, eventData);
        //         if (handler) {
        //             handler(eventData);
        //         }
        //     };
        //
        //
        //     switch (type) {
        //         case 'play':
        //             handleEvent('play', eventHandlers.onPlay);
        //             break;
        //         case 'pause':
        //             handleEvent('pause', eventHandlers.onPause);
        //             break;
        //         case 'ended':
        //             handleEvent('ended', eventHandlers.onEnd, { played: played_ranges });
        //             finishSession(played_ranges);
        //             break;
        //         default:
        //             console.log('Player event: unknown: ', type);
        //             break;
        //     }
        //     // console.log('Player Event', type);
        //     */
        // };

        return (props) => {
            // console.log('VideoJSVideoPlayer', session.session_id);
            return (
                <VideoPlayer
                    key={video_id}
                    playerRef={playerRef}
                    onPlayerEvent={onEvent}
                    {...props}
                />
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.session_id]);

    // return the player & controls
    return [Player, session.session_id, { play, pause }, createSession, video_id];
}

export default VideoSessionProvider;
