import React, { useMemo, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import moment from 'moment';
import { NullRecentAchievement, RecentAchievement } from 'components';
import { useActivity } from '../../../../../state/User/ActivityState';

const S = {
    Container: styled.section`
        width: 38%;
        height: 100%;
        background: RGBA(42, 38, 77, 0.4);
        border-radius: 18px;
        padding: 20px;

        .header {
            color: #fff;
            font-size: 24px;
            line-height: 33px;
        }

        @media only screen and (max-width: 1100px) {
            width: 100%;
            margin-top: 24px;
        }
        @media only screen and (max-width: 500px) {
            margin-top: 0;
            border-radius: 0;
        }
    `
};

const RecentAwards = ({ allVideos }) => {
    const { activity, downloadActivity } = useActivity();

    useEffect(() => {
        downloadActivity();
    }, []);
    
    let videos = Object.values(allVideos);
    // const stats = useVideoStats();
    // console.log(videos);

    // get the awards from activity
    const activityNodes = useMemo(() => {
        const filteredEvents = (a) => {
            if (!a?.length)
                return [];

            const activity = Object.values(a);

            // filter the event by type
            const filtered = activity.filter((item) => {
                // if (item.name === 'video:watched')
                //     return true;
                if (item.name === 'quiz:taken' && item.data.totalCorrect === 10)
                    return true;
                else if (item.name === 'achievement:created')
                    return true;
            });

            // sort by date
            return filtered.sort((a, b) => {
                let last_a = moment(a.date._i);
                let last_b = moment(b.date._i);
                return last_b.diff(last_a);
            });
        };

        // filter & grab 5 events
        const current_activity = filteredEvents(activity).slice(0, 5);
        if (!current_activity || !current_activity.length)
            return <NullRecentAchievement />;
        return current_activity.map((activityItem, key) => (
            <>
                <RecentAchievement key={key} videos={videos} item={activityItem} iconStyle={{ width: '96px', height: 'auto' }} />
                {(key !== current_activity.length-1) &&<div tw='h-0.5 bg-ShadowPurple' />}
            </>
        ));

    }, [activity, videos]);

    return (
        <S.Container aria-label='Recent Awards'>
            <div className='header'>Recent Awards</div>
            <div className='item-list'>{activityNodes}</div>
        </S.Container>
    );
};

export default RecentAwards;
