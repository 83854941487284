import background_image_quiz_0 from 'static/images/background_quiz_warmup.png';
import background_image_quiz_1 from 'static/images/background_quiz_silver.png';
import background_image_quiz_2 from 'static/images/background_quiz_gold.png';
import background_image_quiz_3 from 'static/images/background_quiz_platinum.png';

export const QuizBackgroundProps = [
    {
        backgroundImage: background_image_quiz_0
    },
    {
        backgroundImage: background_image_quiz_1
    },
    {
        backgroundImage: background_image_quiz_2
    },
    {
        backgroundImage: background_image_quiz_3
    },
    // new design 11-24-20
    {
        backgroundImage: 'linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)'
    }
];

export const QuizLevelColors = [
    {
        backgroundImage: 'linear-gradient(to right, #e1d8ff 0%, #a98efc 100%)',
        progressColor: '#43356e',
        loading: '#937ce8',
        loadingColor: '#937ce8',
        header: '#7055d5',
        progress: '#937ce8',
        background: '#937ce8',
        choiceColor: '#7055d5'
    },
    {
        loading: '#86868d',
        loadingColor: '#86868d',
        header: 'linear-gradient(to right, #eaedf0, #9da2a5)',
        progress: 'linear-gradient(to right, #eaedf0, #9da2a5)',
        backgroundImage: 'linear-gradient(to right, #eaedf0, #9da2a5)',
        choiceColor: '#9ea3a6'
    },
    {
        loading: '#f3cf79',
        loadingColor: '#f3cf79',
        header: 'linear-gradient(to right, #fae895, #ffcb2c)',
        progress: 'linear-gradient(to right, #fae895, #ffcb2c)',
        backgroundImage: 'linear-gradient(to right, #fae895, #ffcb2c)',
        choiceColor: '#ffc000'
    },
    {
        backgroundImage: 'linear-gradient(to right, #7bdde8, #14b1c7)',
        loading: '#63b8ea',
        loadingColor: '#63b8ea',
        loadingBackground: '#63b8ea',
        header: 'linear-gradient(to right, #7bdde8, #14b1c7)',
        progress: 'linear-gradient(to right, #7bdde8, #14b1c7)',
        choiceColor: '#1abdd7'
    },
    // new design 11-24-20
    {
        backgroundImage: 'linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)',
        loading: '#63b8ea',
        loadingColor: '#63b8ea',
        loadingBackground: '#63b8ea',
        header: 'linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)',
        progress: 'linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)',
        choiceColor: 'linear-gradient(93.96deg, #867EF9 19.73%, #F059BB 73.17%)'
    }
];
