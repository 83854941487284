import * as React from 'react';

function SvgClassIcon23(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#80A3FF' />
            <path
                d='M12.096 9c-.29 0-.57.077-.775.214-.205.137-.321.323-.321.517v21.923c0 .194.116.38.321.517.206.137.484.214.775.214.29 0 .57-.077.775-.214.206-.137.321-.323.321-.517V9.73c0-.194-.115-.38-.32-.517A1.42 1.42 0 0012.095 9zM29.635 10.33a.731.731 0 00-.731 0 10.889 10.889 0 01-4.48.848 9.58 9.58 0 01-3.785-1.09A9.34 9.34 0 0016.992 9a7.936 7.936 0 00-3.069.621v12.533a6.43 6.43 0 013.106-.79 8.199 8.199 0 013.07.936c1.358.71 2.853 1.123 4.384 1.213a12.27 12.27 0 005.115-1.001.73.73 0 00.402-.65v-10.91a.731.731 0 00-.365-.622z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon23;
