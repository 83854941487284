import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import RecordIndicator from 'components/RecordIndicator';
import useOnClickOutside from '../../hooks/userOnClickOutside';

export const StyledChallengeButton = styled.button(({ isMenuShowing, level }) => [
    tw`hover:bg-[#FEC32C] text-[#1C0057] flex items-center justify-center  py-4 px-10 bg-white rounded-bl-lg rounded-tl-lg mt-5 lg:mt-0 relative mr-[18px]`,
    isMenuShowing && tw`rounded-[30px] rounded-b-none`,
    level === 1 && tw`hover:bg-[#d0d0d0]`,
    level === 2 && tw`hover:bg-[#F2B845]`,
    level === 3 && tw`hover:bg-[#24A6D3]`

]);

const ChallengeButtonDTC = (props) => {
    const { level, teacher, children, onClick, setShowAnswerKey, setLevel } = props;
    const [isMenuShowing, setMenuShowing] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setMenuShowing(false));
    return (
        <div tw='relative w-full maxWidth[330px]' ref={ref}>
            <StyledChallengeButton
                tabIndex='0'
                level={level}
                isMenuShowing={teacher && isMenuShowing}
                onClick={() => (teacher ? setMenuShowing(teacher && !isMenuShowing) : onClick())}
                aria-label='Take Challenge'
                data-testid='challenge-button'
            >
                <div tw='text-base font-semibold w-[150px] md:w-auto leading-normal text-black'>{children || 'Take Challenge'}</div>
                <div tw='h-6 w-[62px] mr-1 absolute top-[-4px] md:top-[-4px] bottom-[0] right-[-36px]'>
                    <RecordIndicator level={level} dtc={true} />
                </div>
            </StyledChallengeButton>
            {isMenuShowing && (
                <div
                    tw='bg-white absolute w-full left-0 top-full px-6 text-DarkLiver text-sm font-medium rounded-b-xl text-center z-30'
                    style={{ boxShadow: '0px 10px 24px rgb(0 0 0 / 20%)' }}
                >
                    <button
                        tabIndex={0}
                        tw='w-full border-b-2 border-GainsboroGrey py-3 cursor-pointer'
                        onClick={() => {
                            setShowAnswerKey(true);
                            setLevel(level);
                        }}
                    >
                        See Answer Key
                    </button>
                    <button tabIndex={0} tw='w-full py-3 cursor-pointer' onClick={props.onChallenge}>
                        Take Challenge
                    </button>
                </div>
            )}
        </div>
    );
};

ChallengeButtonDTC.propTypes = {
    level: PropTypes.number,
    teacher: PropTypes.bool,
    children: PropTypes.any
};

export default ChallengeButtonDTC;