import React from 'react';

export const AddToPlaylistIcon = () => {
    return (
        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.90884 3.70578H14.425C14.5142 3.70578 14.5923 3.62711 14.5923 3.53719V2.55942C14.5923 2.46951 14.5142 2.39084 14.425 2.39084H10.288L8.40346 0.491485C8.35886 0.44653 8.31426 0.435292 8.2585 0.44653C8.2362 0.401575 8.18044 0.367859 8.12469 0.367859H7.15455C7.06535 0.367859 6.98729 0.44653 6.98729 0.536441V8.92057C6.46319 8.72951 5.83874 8.67332 5.19198 8.80818C3.73121 9.12287 2.73877 10.2917 2.97294 11.4156C3.20711 12.5395 4.58983 13.1913 6.03946 12.8766C7.41103 12.5844 8.35886 11.5392 8.2808 10.494V2.2335L9.61892 3.58215C9.65237 3.61587 9.69698 3.62711 9.74158 3.62711C9.75273 3.62711 9.76388 3.62711 9.76388 3.62711C9.79733 3.67206 9.85309 3.70578 9.90884 3.70578Z" fill="white" />
            <path d="M3.10266 0.367859C3.24958 0.367859 3.39048 0.425667 3.49437 0.528565C3.59826 0.631463 3.65662 0.771022 3.65662 0.916542V2.56259H5.31849C5.4654 2.56259 5.60631 2.6204 5.71019 2.7233C5.81408 2.82619 5.87244 2.96575 5.87244 3.11127C5.87244 3.25679 5.81408 3.39635 5.71019 3.49925C5.60631 3.60215 5.4654 3.65996 5.31849 3.65996H3.65662V5.30601C3.65662 5.45153 3.59826 5.59109 3.49437 5.69398C3.39048 5.79688 3.24958 5.85469 3.10266 5.85469C2.95575 5.85469 2.81484 5.79688 2.71096 5.69398C2.60707 5.59109 2.54871 5.45153 2.54871 5.30601V3.65996H0.886841C0.739923 3.65996 0.599023 3.60215 0.495136 3.49925C0.391249 3.39635 0.332886 3.25679 0.332886 3.11127C0.332886 2.96575 0.391249 2.82619 0.495136 2.7233C0.599023 2.6204 0.739923 2.56259 0.886841 2.56259H2.54871V0.916542C2.54871 0.771022 2.60707 0.631463 2.71096 0.528565C2.81484 0.425667 2.95575 0.367859 3.10266 0.367859V0.367859Z" fill="white" />
            <path d="M14.5034 5.24551H9.98721C9.898 5.24551 9.81995 5.32419 9.81995 5.4141V6.39187C9.81995 6.48178 9.898 6.56045 9.98721 6.56045H14.5034C14.5926 6.56045 14.6706 6.48178 14.6706 6.39187V5.4141C14.6706 5.32419 14.5926 5.24551 14.5034 5.24551Z" fill="white" />
            <path d="M14.5034 8.12262H9.98721C9.898 8.12262 9.81995 8.20129 9.81995 8.2912V9.26897C9.81995 9.35888 9.898 9.43756 9.98721 9.43756H14.5034C14.5926 9.43756 14.6706 9.35888 14.6706 9.26897V8.2912C14.6706 8.20129 14.5926 8.12262 14.5034 8.12262Z" fill="white" />
        </svg>

    );
};
