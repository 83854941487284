import React, { useState } from 'react';
import 'twin.macro';
import { Button, CancelSubscriptionPopUp } from 'components';
import { useAccount, useSubscription } from 'state/User/UserState';
import moment from 'moment';
import useAxios from 'axios-hooks';
import { useDevTool } from '../../../../../lib/DevTool/DevTool';
// import ParentSettingsModal from './Modal/ParentSettingsModal';
// import Subscription from './Modal/Subscription';

const ManageSubscription = () => {
    const [isOpen, setIsOpen] = useState(false);
    console.log('🚀 ~ file: ManageSubscription.js:12 ~ ManageSubscription ~ setIsOpen', setIsOpen);
    const [loading, setLoading] = useState(false);
    const subscription = useSubscription();
    const { account_variant } = useAccount();
    const [{ loading: isPosting }, manageSubscription] = useAxios({ url: '/api/stripe/portal/', method: 'GET' }, { manual: true });
    const isStepUp = subscription?.source === 'ariba';
    const isStripe = subscription?.source === 'stripe';
    const isRockers = account_variant === 'parent-rockers-trial';
    const handleStripeRedirect = () => {
        setLoading(true);
        manageSubscription()
            .then((response) => {
                try {
                    const { redirect } = response.data;
                    // console.log('portal redirect', redirect);
                    if (redirect) {
                        setLoading(true);
                        window.location = redirect;
                    }
                } catch (err) {
                    console.log('error redirecting to stripe portal', err);
                    console.error(err);
                    setLoading(false);
                    throw err;
                }
            })
            .catch((err) => {
                console.log('portal error', err);
                setLoading(false);
            });
    };
    useDevTool('ManageSubscription', { subscription, isStepUp, isStripe });
    let duration = '';
    if (isStripe && subscription) {
        if (subscription.duration === 'Monthly') {
            duration = `${subscription.price} / Month`;
        } else if (subscription.duration === 'Yearly') {
            duration = `${subscription.price} / Year`;
        }
    }

    return (
        <>
            <div tw='text-white text-lg flex-col mt-14'>

                {/*<div>{`Muzology ${subscription.plan?.title} Subscription`}</div>*/}
                <div tw='pt-6 sm:pt-0'>Your Muzology Subscription</div>
                {isStepUp && <>
                    <div tw='mt-5'>
                        Source: <span tw='font-black'>Step Up for Students</span>
                    </div>
                    <div tw='mt-5 text-white'>
                        Status: <span tw='font-black'>{subscription?.status?.toUpperCase()}</span>
                    </div>
                </>}
                {isStripe && <>
                    {duration && <div tw='mt-5'>
                        Price: <span tw='font-black'>{duration}</span>
                    </div>}
                    <div tw='mt-5 text-white'>
                        Status: <span tw='font-black'>{subscription?.status?.toUpperCase()}</span>
                    </div>
                    <div tw='mt-5'>
                        Next billing cycle: <span tw='text-[#B5AAD0]'>{moment.utc(subscription.stop).format('l')}</span>
                    </div>
                    <div tw='mt-5'>
                        <Button tw='bg-[#FEEA50] z-60 border-black border-[1px] rounded-md text-black font-Poppins font-medium' onClick={handleStripeRedirect}>
                            Update Plan
                        </Button>
                    </div>
                    <div tw='text-[#B5AAD0] mt-8 font-Poppins text-sm'>
                        You will be redirected to our trusted partner Stripe to update or cancel your subscription. <br /> (For any other account inquiries, please email
                        us at <a href='mailto:support@muzology.com'>support@muzology.com</a>)
                    </div>
                </>}
                {isRockers && <>
                    <div tw='mt-5'>
                        Source: <span tw='font-black'>Rockers on Broadway 30 Day Trial</span>
                    </div>
                    {/*
                    <div tw='mt-5 text-white'>
                        Status: <span tw='font-black'>{subscription?.status?.toUpperCase()}</span>
                    </div>
                    */}
                </>}
                {/*
                <div tw='mt-5'>
                    <Button variant='ParentSettings' onClick={() => setIsOpen(true)}>
                        Cancel Plan
                    </Button>
                </div>
                */}
            </div>
        </>
    );
};

export default ManageSubscription;
