import React from 'react';
import { useCookie } from 'react-use';

const CSRFToken = () => {
    const [csrftoken] = useCookie('csrftoken');
    if (!csrftoken)
        return null;
    return <input
        type='hidden'
        name='csrfmiddlewaretoken'
        value={csrftoken} />;
};

export default CSRFToken;
