import React from 'react';
import TimesTableFirstQuizImg from 'static/images/guidance/times-table-first-quiz.png';
import tw, { styled, css } from 'twin.macro';

const TimesTableFirstQuiz = ({ image }) => {
    return (
        <>
            <div tw='absolute text-white text-2xl xl:text-3xl bottom-12 font-bold left-3 xl:left-5'>Take the Math Challenges</div>
            <div tw='absolute text-white text-base tiny:text-xl xl:text-2xl bottom-4 font-medium left-3 xl:left-5'>Reach the Platinum level!</div>
            <img src={TimesTableFirstQuizImg} alt={image} tw='h-full w-full object-cover' />
        </>
    );
};

export default TimesTableFirstQuiz;