import React, { useEffect, useMemo, useState } from 'react';
import { useClassStudents } from 'state/Teacher/ClassState';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { ActivityStream, Button, NullContainer, StatsBannerTeacher, StudentAssignmentProgress, StudentSelectionCard } from 'components';
import { useStudentAssignments } from 'state/Student/AssignmentsState';
import { useVideos } from 'state/CatalogState';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { sortStudentsByName } from '../../../../../utils/sorting';
import { useActivityForUser } from '../../../../../state/User/ActivityState';

const ScrollBarStyle = css`
    height: 100vh;
    max-height: 100%;
    ::-webkit-scrollbar {
        display: none;
    }
`;


const AddIcon = () => <svg
    width='25' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'
    style={{ display: 'inline', margin: '0 10px' }}>
    <path
        d='M24 0H1C.447 0 0 .447 0 1v23c0 .553.447 1 1 1h23c.553 0 1-.447 1-1V1c0-.553-.447-1-1-1Zm-5.5 13.25a.25.25 0 0 1-.25.25H13.5v4.75a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25V13.5H6.75a.25.25 0 0 1-.25-.25v-1.5a.25.25 0 0 1 .25-.25h4.75V6.75a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v4.75h4.75a.25.25 0 0 1 .25.25v1.5Z'
        fill='#fff'
    />
</svg>;

const StudentStats = ({ classId, setOpenNewClass }) => {
    const navigate = useNavigate();
    const { students, isLoading } = useClassStudents(classId);
    const videos = useVideos();
    const isTablet = useMedia('(max-width: 1023px)');

    const [activeId, setActiveId] = useState(null);
    const [activeStudent, setActiveStudent] = useState({});

    let assignments = useStudentAssignments(activeId);
    let activity = useActivityForUser(activeId);

    // spread the array to prevent mutating original list
    const sortedStudents = useMemo(() => [...students].sort(sortStudentsByName), [students]);

    // when the students load and no student has been selected, select the first student
    useEffect(() => {
        if (activeId === null && sortedStudents?.length > 0)
            setActiveId(sortedStudents[0].user.id);
    }, [activeId, sortedStudents]);

    // any time a student id has been selected - set the active student
    useEffect(() => {
        // select the active student
        if (activeId !== null)
            setActiveStudent(sortedStudents.find((student) => student.user.id === activeId));
    }, [activeId, sortedStudents]);

    return (
        <div tw='flex font-sans'>
            {!isLoading && sortedStudents?.length === 0 && (
                <NullContainer tw='z-10 m-auto font-medium text-2xl text-white'>
                    <p>
                        Your students can progress through Muzology at their own pace or by using the playlists you assign. Once your
                        students start watching videos and taking the Math Challenges, their performance data will appear on this
                        page.&nbsp;
                    </p>
                </NullContainer>
            )}

            {sortedStudents?.length > 0 && (
                <div
                    tw='flex-col sticky overflow-x-scroll  w-1/4 xl:w-1/5 px-3'
                    css={[
                        ScrollBarStyle,
                        isTablet &&
                        css`
                            flex-grow: 1;
                        `,
                        !isTablet &&
                        css`
                            max-width: 590px;
                        `
                    ]}
                    id='classes-tour-students'
                >
                    {sortedStudents?.map((student, key) => <StudentSelectionCard
                        key={student?.user?.id}
                        student={student}
                        active={activeId}
                        setActive={setActiveId}
                    />)}
                </div>
            )}
            {(!activeStudent?.loading) && <div tw='flex-col ml-4 w-4/5 overflow-x-scroll' css={[ScrollBarStyle, !activeId && tw`hidden`]}>
                <div
                    tw='flex flex-col lg:flex-row justify-center lg:justify-between items-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-t-lg py-3 pl-3 pr-9 mt-4' >
                    <div tw='text-2xl text-white font-medium pb-2.5 lg:pb-0'>{`${activeStudent?.user?.last_name}, ${activeStudent?.user?.first_name}`}</div>
                    <Button variant='StudentDashBoard' onClick={() => navigate(`/student/${activeId}/video-stats`)} id='classes-tour-student-stats'>
                        <div tw='flex items-center' >
                            GO TO STUDENT DASHBOARD
                            <svg
                                width='12' height='14' viewBox='0 0 12 14' fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M11.92 6.61994C11.8724 6.49719 11.801 6.38505 11.71 6.28994L6.71 1.28994C6.61676 1.1967 6.50607 1.12274 6.38425 1.07228C6.26243 1.02182 6.13186 0.99585 6 0.99585C5.7337 0.99585 5.4783 1.10164 5.29 1.28994C5.19676 1.38318 5.1228 1.49387 5.07234 1.61569C5.02188 1.73751 4.99591 1.86808 4.99591 1.99994C4.99591 2.26624 5.1017 2.52164 5.29 2.70994L8.59 5.99994H1C0.734784 5.99994 0.48043 6.1053 0.292893 6.29283C0.105357 6.48037 0 6.73472 0 6.99994C0 7.26516 0.105357 7.51951 0.292893 7.70705C0.48043 7.89458 0.734784 7.99994 1 7.99994H8.59L5.29 11.2899C5.19627 11.3829 5.12188 11.4935 5.07111 11.6154C5.02034 11.7372 4.9942 11.8679 4.9942 11.9999C4.9942 12.132 5.02034 12.2627 5.07111 12.3845C5.12188 12.5064 5.19627 12.617 5.29 12.7099C5.38296 12.8037 5.49356 12.8781 5.61542 12.9288C5.73728 12.9796 5.86799 13.0057 6 13.0057C6.13201 13.0057 6.26272 12.9796 6.38458 12.9288C6.50644 12.8781 6.61704 12.8037 6.71 12.7099L11.71 7.70994C11.801 7.61484 11.8724 7.50269 11.92 7.37994C12.02 7.13648 12.02 6.8634 11.92 6.61994Z'
                                    fill='black'
                                />
                            </svg>
                        </div>
                    </Button>
                </div>
                <div tw='bg-white px-16 pb-6 rounded-bl-lg rounded-br-lg'>
                    <StatsBannerTeacher
                        studentId={activeId}
                        stats={activeStudent?.video_stats}
                        // stats={Object.keys(activeStudent).length !== 0 ? activeStudent.video_stats : {}}
                        assignments={assignments}
                    />
                    <StudentAssignmentProgress assignments={assignments} videos={videos} />
                    <div tw='text-lg text-SlateBlue font-extrabold my-4'>Activity Stream</div>
                    {!activity ? null : Object.keys(activity).length === 0 ? (
                        <div tw='bg-QuizBlue bg-opacity-20 p-12 text-center text-black borderRadius[20px]'>No student activity yet.</div>
                    ) : (
                        <ActivityStream activity={activity} videos={videos} />
                    )}
                </div>
            </div>}
        </div>
    );
};

StudentStats.propTypes = {
    classId: PropTypes.string
};

export default StudentStats;
