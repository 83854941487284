import React from 'react';
import tw, { css } from 'twin.macro';
import { VideoCard } from 'components';
import LazyLoadVideoGrid from 'components/LazyLoadVideo/LazyLoadVideoGrid';

const VideosGrid = (props) => {
    const videos = props.videos || [];
    const showLazyVideos = videos.length === 0;
    const videoCards = videos.map((video, index) => {
        if (!video) return null;
        const video_id = video.id;
        let selected = null;
        if (props.selectedVideos) selected = props.selectedVideos.find((id) => id === video_id);
        const handleVideoClick = (video, index) => {
            if (props.onClickVideo) return props.onClickVideo(video, index);
        };
        // Grabbing stats indexed on video id then passing it down to video card
        // let stats = props.stats[video.id];
        return (
            <div
                key={video.id}
                id={index === 1 ? 'video-page-tour-video-thumbnail' : null}
                className='video-card-container'
                data-video-index={index}
            >
                <VideoCard
                    style={{ marginBottom: 30 }}
                    onClick={handleVideoClick}
                    index={index}
                    video={video}
                    showTitle={true}
                    libraryView
                    showViewCount={true}
                    showRecords={true}
                    stats={video.stats}
                    teacher={props.teacher}
                    assignment={props.assignment}
                    handleProgressOverlay={props.handleProgressOverlay}
                    selected={selected}
                    beginCreateAssignment={props.beginCreateAssignment}
                />
            </div>
        );
    });
    return (
        <div style={props.playlistLength === 1 ? { minHeight: '100vh', paddingBottom: '60px' } : {}}>
            {showLazyVideos && <LazyLoadVideoGrid numberOfVideoCards={9} />}
            <div
                tw='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pb-8'
                data-video-count={videos.length}
            // css={[
            //     css`
            //         @media only screen and (min-width: 1134px) {
            //             grid-template-columns: repeat(3, minmax(0, 1fr));
            //         }
            //     `
            // ]}
            >
                {videoCards}
            </div>
        </div>
    );
};

export default VideosGrid;
