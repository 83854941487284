/*

    Quiz Levels:
        0 = warmup
        1 = silver
        2 = gold
        3 = platinum

    Quiz Stats:

    assignments:

    quiz_level_goal:

 */

import { fromJS, List, Map } from 'immutable';
// function to determine the number of stars
import moment from 'moment';

export const determineStars = (assignment) => {
    let oneStar = null;
    let twoStar = null;
    let threeStar = null;
    let assignmentVideos = assignment.videos.length;
    let completeStars = null;
    assignment.videos.forEach((v) => {
        if (v && v.stats) {
            if (v.stats.current_level >= 2) {
                if (v.stats.quiz_levels[2]) {
                    let goldScore = v.stats.quiz_levels[2].score;
                    if (goldScore >= 80) {
                        oneStar += 1;
                    }
                    // fill second star if they gotten 100% gold
                    if (goldScore === 100) {
                        twoStar += 1;
                    }
                }
                // fill third star if they gotten 80% on plat
                if (v.stats.quiz_levels[3]) {
                    let platScore = v.stats.quiz_levels[3].score;
                    if (platScore >= 80) {
                        threeStar += 1;
                    }
                }
            }
        }
    });

    if (oneStar === assignmentVideos) {
        completeStars = 1;
    }
    if (twoStar === assignmentVideos) {
        completeStars = 2;
    }
    if (threeStar === assignmentVideos) {
        completeStars = 3;
    }

    return completeStars;
};

/**
 * determine the progress of a given user on a given assignment
 * @param assignment - the assignment
 * @param video_stats - the video stats for the user as a map indexed by video ids
 * @returns the assignment progress in terms of 0.0 -> 1.0, or null if there is no progress at all
 */

// TODO: this still needs to determine the difference between an assigned video that has not been started, and an attempt with score 0

export const getAssignmentProgress = (assignment, video_stats) => {
    // ensure we have an assignment
    console.assert(assignment != null);

    // check if we even have video stats
    if (!video_stats) return null;

    let total_progress = 0; // this should be a 1 for each video completed, and 0-1 with progress for each video not completed

    // calculate the total 'points' for a single video
    let max_points = assignment.quiz_level_goal * 10 + assignment.quiz_score_goal;

    // check the cumulative progress on each video
    assignment.items.forEach((item) => {
        // get the stats for this item
        const videoId = item.video_id;
        const stats = video_stats[videoId];
        if (!stats) {
            // if there are no stats for this video, then it has not been attempted
            return;
        }

        // check if this item has been completed
        let current_level = stats.current_level;
        let current_score = stats.current_score;
        if (current_level >= assignment.quiz_level_goal && current_score >= assignment.quiz_score_goal) {
            // this item is completed
            total_progress += 1;
        } else {
            // check if there is any progress

            // calculate the actual points performed
            let cur_points = stats.current_level * 10 + stats.current_score;

            if (cur_points >= max_points) {
                // this item is complete ... how does this happen
            } else if (cur_points !== 0) {
                // calculate a progress percentage
                let pct = cur_points / max_points;
                total_progress += pct;
            }
        }
    });

    // calculate the progress as a percent of all videos
    let video_count = assignment.items.length;
    return total_progress / video_count;
};

// assignment filters

// [getAssignments, getVideosAndStatsSelector],
export const getAssignmentsAndVideos = (assignments, videos) => {
    // console.log('getAssignmentsAndVideos', assignments);
    if (!assignments) return List();
    // console.log('getAssignmentsAndVideos', assignments.toJS());
    return assignments.mapEntries(([assignment_id, assignment], index) => {
        // console.log('+  assignment', assignment);
        let items = assignment.get('items');
        if (items) {
            let video_map = fromJS(videos);
            let index = 1;
            let new_videos = items.map((video_id) => {
                // find the video
                let video = video_map.get(video_id, null);
                if (!video) {
                    return null;
                }

                // access the progress for this assignment item by index
                let progress = assignment.getIn(['progress', 'progress', index.toString()], Map());
                if (progress === false || progress === true) {
                    // this is legacy data - just skip it for now
                    return video;
                }

                progress = progress.toJS();
                let completed = progress.completed;
                video = video.set('assignment_progress', progress);
                video = video.set('assignment_completed', completed);
                video = video.set('assignment_index', index);

                index += 1;
                return video;
            });
            new_videos = new_videos.filter(video => video !== null);
            assignment = assignment.set('videos', new_videos);
        }

        return [assignment_id, assignment];
    });
};

//
export const assignmentIsOverdue = (assignment) => {
    let today = moment();
    return moment(assignment.due).isBefore(today);
};

// videos completed
export const filterCompletedAssignments = (assignments) => {
    return assignments.filter((assignment) => assignment.progress?.completed);
};

// videos not completed
export const filterIncompleteAssignments = (assignments) => {
    return assignments.filter((assignment) => !assignment.progress?.completed);
};

// videos not past due
export const filterUpcommingAssignments = (assignments) => {
    let today = moment();
    return assignments.filter((assignment) => assignment.due === null || moment(today).isBefore(assignment.due));
};

// videos past due
export const filterPastDueAssignments = (assignments) => {
    let today = moment();
    return assignments.filter((assignment) => moment(assignment.due).isBefore(today));
};

// recently overdue list
export const filterRecentlyOverDueAssignments = (assignments) => {
    let today = moment();
    return assignments.filter((assignment) => today.isAfter(assignment.due));
};

// most recent overdue assignment
export const filterClosestOverDueAssignments = (assignments) => {
    if (assignments) {
        let recent = filterRecentlyOverDueAssignments(assignments);
        let list = recent.sort((a, b) => moment(b.due).diff(moment(a.due)));
        return list[0];
    }
    return null;
};

// most recent upcoming assignment
export const filterUpcomingAssignments = (assignments) => {
    if (assignments && assignments.length) {
        let incomplete = assignments.filter((assignment) => assignment.progress ? !assignment.progress.completed : true);
        if (incomplete && incomplete.length) {
            let upcoming = incomplete.filter((assignment) => moment.utc().local().isBefore(assignment.due));
            let sort = upcoming.sort((a, b) => {
                let last_a = moment(a.due);
                let last_b = moment(b.due);
                return last_a.diff(last_b);
            });
            return sort[0];
        }
    }
    return null;
};

// most recent completed assignment
export const filterUpcomingCompletedAssignments = (assignments) => {
    if (assignments) {
        let completed_upcoming_assignments;
        let upcoming_assignments = assignments.filter((assignment) => moment().isBefore(assignment.due));
        if (upcoming_assignments) {
            completed_upcoming_assignments = upcoming_assignments.filter((assignment) => assignment.progress && assignment.progress.completed === true);
        }
        if (!completed_upcoming_assignments === undefined) {
            let closest_to_furthest_upcoming_assignments = upcoming_assignments.sort((a, b) => moment(b.due).diff(moment(a.due)));
            return closest_to_furthest_upcoming_assignments[0];
        }
    }
    return null;
};

// most recent past-due completed assignment
// [getAssignmentsSelector],
export const filterPastDueCompletedAssignments = (assignments) => {
    if (assignments) {
        let completed_past_due_assignments_list;
        let past_due_assignments_list = assignments.filter((assignment) => moment().isAfter(assignment.due));
        if (past_due_assignments_list) {
            completed_past_due_assignments_list = past_due_assignments_list.filter(
                (assignment) => assignment.progress && assignment.progress.completed === true
            );
        }
        if (!completed_past_due_assignments_list === undefined) {
            let closest_to_furthest_completed_past_due_assignments = completed_past_due_assignments_list.sort((a, b) => moment(b.due).diff(moment(a.due)));
            return closest_to_furthest_completed_past_due_assignments[0];
        }
    }
    return null;
};

// convert a quiz question or choice id to a url
export const getQuizAudioURL = (id) => `https://public.muzology.com/quiz-audio/${id}.mp3`;
