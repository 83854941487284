import React from 'react';
import tw from 'twin.macro';
import { Button, Modal } from 'components';
import CloseButton from '../../../../components/AnswerKey/CloseButton';

const DeleteClassModal = ({ show, onAccept, onCancel }) => {
    return ( 
        <Modal
            isOpen={show}
            setIsOpen={(value) => {
                if( !value && onCancel )
                    onCancel();
            }}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            <div
                tw='relative bg-white rounded flex-col justify-center font-sans'
                style={{ minWidth: '800px' }}
            >
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-2xl'>Delete Class</div>
                <div tw='text-center text-lg text-DarkLiver pt-11'>
                    <div>You will no longer be able to see data for students in this class.</div>
                    <div tw='pt-5'>Are you sure you want to delete this class?</div>
                </div>
                <div tw='flex justify-center space-x-2 py-10'>
                    <Button
                        variant='RosterTable'
                        onClick={onCancel}>
                        Keep
                    </Button>
                    <Button onClick={onAccept}>Delete Class</Button>
                </div>
                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={onCancel}
                />
            </div>
        </Modal>
    );
};

export default DeleteClassModal;