import * as React from 'react';

function SvgClassIcon19(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FF8C4B' />
            <path
                d='M20 8C13.383 8 8 13.383 8 20s5.383 12 12 12 12-5.383 12-12S26.617 8 20 8zm9.517 10.8H21.2v-8.317a9.61 9.61 0 018.317 8.317zM10.4 20c0-4.886 3.673-8.923 8.4-9.517V20c.003.183.05.363.133.526.018.036.027.075.05.111l5.042 8.068A9.538 9.538 0 0120 29.6c-5.293 0-9.6-4.307-9.6-9.6zm15.662 7.435L22.166 21.2h7.351a9.59 9.59 0 01-3.455 6.235z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon19;
