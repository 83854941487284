import React from 'react';
import { styled } from 'twin.macro';

const S = {
    Button: styled.button`
      border: 2px solid #d44ba4;
      border-radius: 9999px;
      cursor: pointer;

      font-weight: 500;

      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      background: none;

      margin: auto;

      width: 1.333em;
      height: 1.333em;
      font-size: 1em;

      position: absolute;
      right: 0.5em;
      top: 0.5em;
      //bottom: 0;

      color: #d44ba4;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        color: white;
        border: 2px solid white;
      }

      &:focus {
        outline: none;
      }

      &.black-button {
        color: #000000;
        border-color: #000000;
        //right: -16px;
        //top: -16px;
        width: 30px;
        height: 30px;
        border-width: 3px;
        font-size: 20px;
        font-weight: 600;

        &:hover {
          color: #878787;
          border-color: #878787;
        }

        &:active {
          color: #ACACAC;
          border-color: #ACACAC;
        }

      }
    `
};

const CloseButton = ({ className, onClick }) => {
    const handleClick = (e) => {
        e.stopPropagation();
        if (onClick)
            onClick();
    };

    return (
        <S.Button
            className={`close-button ${className}`}
            onClick={handleClick}
        >
            &times;
        </S.Button>
    );
};

export default CloseButton;
