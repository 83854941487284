import * as React from 'react';

function SvgThreeDots(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 30 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <ellipse
                cx={25.311}
                cy={4.089}
                rx={3.862}
                ry={3.911}
                transform='rotate(-180 25.31 4.09)'
                fill='#fff'
            />
            <ellipse
                cx={14.587}
                cy={3.996}
                rx={3.862}
                ry={3.911}
                transform='rotate(-180 14.587 3.996)'
                fill='#fff'
            />
            <ellipse
                cx={3.862}
                cy={3.911}
                rx={3.862}
                ry={3.911}
                transform='rotate(-180 3.862 3.91)'
                fill='#fff'
            />
        </svg>
    );
}

export default SvgThreeDots;
