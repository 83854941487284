import React from 'react';

const RecordProgress = () => {
    const gradientId = 'progressGradient';

    return (
        <svg style={{ height: 0, width: 0}}>
            <defs>
                <linearGradient id={gradientId}>
                    <stop offset='0%' stopColor='#46A6F8' />
                    <stop offset='100%' stopColor='#B15AFE' />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default RecordProgress;
