import React from 'react';

// NEWEST / FINAL ACHIEVEMENTS
// TODO: CLEAN UP THE IMPORTS ABOVE AFTER WE VERIFY THEY ARE NOT NEED IN THIS FILE
import PerfectWarmup from 'static/images/achievements/final_achievements/perfect_warmup.png';
import PerfectSilver from 'static/images/achievements/final_achievements/perfect_silver.png';
import PerfectGold from 'static/images/achievements/final_achievements/perfect_gold.png';
import PerfectPlatinum from 'static/images/achievements/final_achievements/perfect_platinum.png';
import EarnedPlatinum from 'static/images/achievements/final_achievements/platinum.png';
import GrowthPlatinum from 'static/images/achievements/final_achievements/GrowthPlatinumVsGold.png';
import GrowthGold from 'static/images/achievements/final_achievements/GrowthGoldVsSilver.png';
import BreakthroughGold from 'static/images/achievements/final_achievements/GrowthGold_LowWarmup.png';
import BreakthroughPlatinum from 'static/images/achievements/final_achievements/GrowthPlatinum_LowWarmup.png';
import MuzologyHeart from 'static/images/achievements/final_achievements/MuzologyHeart.png';

const AchievementConfig = {
    // login_streak: {
    //     header: 'Login Streak',
    //     icon: 'icon_url',
    //     levels: [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    // },
    single_video_watched_7_times: {
        image: MuzologyHeart,
        title: 'Video Watched 7 Times',
        header: 'Watched 7 Times',
        icon: 'icon_url',
        description: 'VIDEO WATCHED 7 TIMES',
        single_video: true,
        show_null_state: true
    },

    single_video_pretest_perfect_score: {
        title: 'Perfect Warmup Score',
        header: 'Warmup Wizard',
        image: PerfectWarmup,
        icon: 'icon_url',
        description: 'PERFECT WARM UP SCORE',
        single_video: true
    },

    single_video_silver_first_try_perfect: {
        header: 'Hot Streak',
        icon: 'icon_url',
        color: '#839daa',
        image: PerfectSilver,
        description: 'PERFECT SCORE ON SILVER (1ST ATTEMPT)',
        single_video: true
    },

    single_video_gold_first_try_perfect: {
        header: 'Hot Streak',
        image: PerfectGold,
        icon: 'icon_url',
        description: 'PERFECT SCORE ON GOLD (1ST ATTEMPT)',
        single_video: true
    },

    single_video_platinum_first_try_perfect: {
        image: PerfectPlatinum,
        header: 'Hot Streak',
        icon: 'icon_url',
        color: '#00b5d0',
        description: 'PERFECT SCORE ON PLATINUM (1ST ATTEMPT)',
        single_video: true
    },

    single_video_growth_low_pretest_gold: {
        image: BreakthroughGold,
        header: 'Breakthrough',
        icon: 'icon_url',
        color: '#e6b900',
        description: 'BOUNCED BACK FROM WARMUP WITH HIGH GOLD SCORE',
        single_video: true
    },

    single_video_growth_low_pretest_platinum: {
        image: BreakthroughPlatinum,
        header: 'Breakthrough',
        icon: 'icon_url',
        color: '#6800c4',
        description: 'BOUNCED BACK FROM WARMUP WITH HIGH PLATINUM SCORE',
        single_video: true
    },

    single_video_growth_faster_gold: {
        image: GrowthGold,
        header: 'Accelerator',
        icon: 'icon_url',
        color: '#38db96',
        description: 'HIGHER FIRST ATTEMPT SCORE ON GOLD VS SILVER',
        single_video: true
    },

    single_video_platinum_record: {
        title: 'Earned Platinum Record',
        image: EarnedPlatinum,
        header: 'You’ve Gone Platinum',
        icon: 'icon_url',
        color: '#4c34ff',
        description: 'EARNED THE PLATINUM RECORD!',
        single_video: true
    },

    single_video_growth_faster_platinum: {
        image: GrowthPlatinum,
        header: 'Accelerator',
        color: '#e637a8',
        description: 'HIGHER FIRST ATTEMPT SCORE ON PLATINUM VS GOLD',
        single_video: true
    }
};

export default AchievementConfig;
