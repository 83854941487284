import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import { ClassIcon } from 'components';
import SvgSortIndicatorDown from 'images/icons/SortIndicatorDown';
import SvgSortIndicatorUp from 'images/icons/SortIndicatorUp';
import { useClassStudents } from 'state/Teacher/ClassState';
import { selectStudentsOrClass } from 'state/PlaylistAssignWizardState';
import { formatPlural } from '../../lib/string';

const CheckBox = (props) => {
    return (
        <button tw='w-8 h-8 rounded-lg border-MuzologyLightBlue  border-2 flex  items-center justify-center cursor-pointer' {...props}>
            {props.selected && <div tw='w-5 h-5 rounded-md bg-MuzologyLightBlue' />}
        </button>
    );
};

const OpenButton = (props) => {
    return (
        <button
            tabIndex={0}
            tw='w-[30px] h-[30px] rounded-full border-black border-2 flex  items-center justify-center cursor-pointer'
            onClick={props.handleOpen}
        >
            {!props.isOpen ? <SvgSortIndicatorDown tw='relative top-0.5' /> : <SvgSortIndicatorUp tw='relative' />}
        </button>
    );
};

const SelectStudentGrid = ({ students, selectedStudentIds, setSelectedStudentIds, classSelected, setSelectedStudent }) => {
    // Function to compare last names alphabetically
    function compareByLastName(a, b) {
        const lastNameComparison = a.user.last_name.localeCompare(b.user.last_name);
        if (lastNameComparison !== 0) {
          // If last names are different, sort by last name
          return lastNameComparison;
        } else {
          // If last names are the same, sort by first name
          return a.user.first_name.localeCompare(b.user.first_name);
        }
    }

    const reOrderStudentsByName = students.sort(compareByLastName)

    return (
        <div tw='bg-white px-0 xsm:px-10 py-8 rounded-b-xl flex flex-col w-full justify-start gap-4 items-center border-2 border-PurpleWhite'>
            {reOrderStudentsByName.map((student, index) => {
                const userId = student.user.id;
                const hasStudentBeenSelected = classSelected || selectedStudentIds.find((id) => id === student.user.id);
                return (
                    <div key={userId} tw='flex items-center w-[300px] mr-auto'>
                        <CheckBox
                            tw='mr-3'
                            onClick={() => setSelectedStudent(userId, !hasStudentBeenSelected)}
                            // onClick={() => (hasStudentBeenSelected ? setSelectedStudentIds((oldArray) => oldArray.filter((id) => id !== userId)) : setSelectedStudentIds((oldArray) => [...oldArray, userId]))}
                            selected={hasStudentBeenSelected}
                        />
                        <p tw='mx-3'>{student.user.last_name}, {student.user.first_name}</p>
                    </div>
                );
            })}
        </div>
    );
};

const getSelectedStudents = (assignment, cls) => {
    console.log('select students:', assignment, cls);
    return [];
};

const SelectClassStudentsDropdown = ({ cls, assignment, assignmentDispatch }) => {
    const [studentSelectorIsOpen, setSelector] = useState(false);
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [classSelected, setClassSelected] = useState(false);
    const { students } = useClassStudents(cls.id);
    // const studentsInPlaylist = props.playlistDetails.classes.map((cls) => cls.students);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const selectStudents = useCallback((students) => {
    //     console.log('select students', students);
    //     assignmentDispatch({ type: 'replace class', updates: { students } });
    // }, [assignmentDispatch]);
    //
    // const selectedStudents = useMemo(() => {
    //     console.log('assignment students:', assignment.assignedStudents);
    //     return assignment.assignedStudents ?? [];
    // }, [assignment.assignedStudents]);

    useEffect(() => {
        if (assignment) {
            // console.log('Assignment:', assignment);
            // console.log('Class:', cls);

            // get the state for this class
            const classState = assignment.classes?.find(({ classId }) => classId === cls.id);
            if (classState) {
                // class found
                // console.log('current class state', classState);
                // set initial state
                const assigned = classState.allStudentsSelected ?? false;
                setClassSelected(assigned);
                let selectedIds = [];
                if (!assigned) {
                    selectedIds = classState.assignedStudents ?? [];
                }
                setSelectedStudentIds(selectedIds);
            }
        } else {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // console.log('Assignment:', assignment);
        // if (assigned) {
        //     // this class is selected - get the list of all student ids
        //     // selectedIds = cls.student_ids?.map(studentId => studentId);
        //     selectedIds = cls.students_ids ?? (cls.students ?? []);
        // } else {
        //     // check this class for selected students
        //     selectedIds = assignment.assignedStudents?.reduce((all, studentId) => {
        //         if (cls.students.find(id => studentId)) {
        //             return [...all, studentId];
        //         } else {
        //             return all;
        //         }
        //     }, []) ?? [];
        // }
        // setSelectedStudentIds(selectedIds);
        /*

        // let selected = [];
        if (assignment) {
            get the state for this class
            const state = assignment.classes?.find(classId => classId === cls.id);

            // if( assignment.classes ) {
            //     console.log('initial classes:', assignment.classes);
            //     if( assignment.classes.find( c => c.id === cls.id ) ) {
            //         // console.log('Found class: ', cls.student_ids);
            //         // const classSelected = cls.student_ids.map( studentId => {
            //         //     return students.find(student => student.user.id === studentId );
            //         // });
            //         // selected = selected.concat(classSelected);
            //     }
            // } else {
            // initial load
            // if( assignment.assignedClasses?.find( classId => classId === cls.id ) ) {

            // convert student IDs into student objects
            const selected = assignment.assignedStudents?.map(studentId => {
                return students.find(student => student.user.id === studentId);
            });

            // console.log('initial assignment', assignment);
            // if (assignment.assignedClasses?.find(classId => classId === cls.id)) {
            //     console.log('Found class: ', cls.student_ids);
            //     const classSelected = cls.student_ids.map(studentId => {
            //         return students.find(student => student.user.id === studentId);
            //     });
            //     selected = selected.concat(classSelected);
            // }


            let selectedIds = [];
            // check if the class is already assigned
            // const assigned = assignment.assignedClasses?.find(classId => classId === cls.id);
            const assigned = cls.allStudentsSelected;

            console.log('Class', cls);
            if (assigned) {
                // this class is selected - get the list of all student ids
                // selectedIds = cls.student_ids?.map(studentId => studentId);
                selectedIds = cls.students_ids ?? (cls.students ?? []);
            } else {
                // check this class for selected students
                selectedIds = assignment.assignedStudents?.reduce((all, studentId) => {
                    if (cls.students.find(id => studentId)) {
                        return [...all, studentId];
                    } else {
                        return all;
                    }
                }, []) ?? [];
            }
            // console.log('selectedIds =', selectedIds);
            setSelectedStudentIds(selectedIds);

            // selectedIds = assignment.assignedClasses?.reduce((all, clsId) => {
            //     if (cls.id === clsId) {
            //         all = cls.student_ids.reduce((all, studentId) => {
            //             if (!(studentId in all)) {
            //                 return [...all, studentId];
            //             }
            //             return all;
            //         }, all);
            //     }
            //     return all;
            // }, selectedIds);
            // setSelectedStudentIds(selectedIds);

            // selectedIds = cls.student_ids.reduce( (all,studentId) => {
            //     if( !( studentId in all) ) {
            //         return [...all, studentId];
            //     }
            //     return all;
            // }, selectedIds);
            // console.log('initial selected', selectedIds);
            // selectStudents(selected ?? []);
        }
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if( assignment ) {
        //     const selected = assignment.assignedStudents.map( studentId => {
        //         return students.find(student => student.user.id === studentId );
        //     });
        //     console.log('initial selected', selected);
        //     selectStudents(selected);
        // }

        // return () => {
        //     // save it
        //     console.log('Final', selectedStudents);
        //     selectStudentsOrClass(assignmentDispatch, {
        //         classId: cls.id,
        //         classIcon: cls.display_icon,
        //         name: cls.title,
        //         allStudentsSelected: selectedStudents.length === students.length,
        //         students: selectedStudents
        //     });
        // };
        if (selectedStudentIds) {
            selectStudentsOrClass(assignmentDispatch, {
                classId: cls.id,
                classIcon: cls.display_icon,
                name: cls.title,
                allStudentsSelected: classSelected,
                assignedStudents: selectedStudentIds ?? [],
                students: students
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudentIds, classSelected]);

    // construct the text
    const selectedStudentCopy = useMemo(() => {
        if (classSelected) {
            return '(All students selected)';
        } else {
            return `${formatPlural('student', selectedStudentIds?.length)} selected`;
        }
        // else if (selectedStudentIds?.length === 1) {
        //     return `(${selectedStudentIds?.length} student selected)`;
        // } else {
        //     return `(${selectedStudentIds?.length} students selected)`;
        // }
    }, [selectedStudentIds, classSelected]);

    const setSelectedStudent = (studentId, selected) => {
        // any time a student has been unselected and the class is selected - convert to unselected class
        if (classSelected && !selected) {
            // select all students
            let studentIds = cls.student_ids ?? cls.students ?? [];

            // unselect this student
            studentIds = studentIds.filter((id) => id !== studentId);

            // select the students & unselect the class
            setSelectedStudentIds(studentIds);
            setClassSelected(false);
        } else if (!classSelected && selected) {
            const classSize = (cls.student_ids ?? cls.students ?? []).length;

            // check if this student already exists
            if (!selectedStudentIds.find((id) => id === studentId)) {
                // student not found, see if this student would complete the class
                if (classSize > 0 && classSize === selectedStudentIds.length + 1) {
                    setClassSelected(true);
                    setSelectedStudentIds([]);
                } else {
                    // add the student
                    setSelectedStudentIds((oldArray) => [...oldArray, studentId]);
                }
            }
        } else {
            // set or unset the student id
            if (selected) {
                // add the student id
                setSelectedStudentIds((oldArray) => [...oldArray, studentId]);
            } else {
                // remove the student id
                setSelectedStudentIds((oldArray) => oldArray.filter((id) => id !== studentId));
            }
        }
    };

    // call dispatch any time local state changes
    // useEffect(() => {
    //     selectStudentsOrClass(assignmentDispatch, {
    //         classId: cls.id,
    //         classIcon: cls.display_icon,
    //         name: cls.title,
    //         allStudentsSelected: selectedStudents.length === students.length,
    //         students: selectedStudents
    //     });
    //     // console.log('*** Update Assignment state:', assignment);
    //
    // }, [cls.display_icon, cls.id, cls.title, assignmentDispatch, selectedStudents, students, assignment]);
    // console.log('Selected students:', selectedStudents);
    // console.log('Assignment state:', assignment);

    // useEffect(() => {
    //     // find this class & place the students into the state
    //     const c = assignment?.classes?.find(c => c.classId === cls.id);
    //     selectStudents(c?.students ?? []);
    // }, [assignment.classes, cls.id]);

    // useEffect(() => {
    //     selectStudentsOrClass(assignmentDispatch, {
    //         classId: cls.id,
    //         classIcon: cls.display_icon,
    //         allStudentsSelected: selectedStudents.length === students.length,
    //         name: cls.title,
    //         students: selectedStudents
    //     });
    // }, [assignmentDispatch, cls, selectedStudents, students]);

    return (
        <div tw='w-full maxWidth[800px] '>
            <div
                css={[
                    tw`w-full maxWidth[800px] h-14 rounded-xl bg-GhostWhite py-1 xsm:py-0 px-2 xsm:px-10 flex items-center justify-between`,
                    studentSelectorIsOpen && tw`rounded-b-none`
                ]}
            >
                <div tw='flex flex-row items-center space-x-3'>
                    <CheckBox
                        tabIndex={0}
                        selected={classSelected}
                        onClick={() => {
                            // toggle selection & clear all student selection
                            setClassSelected((value) => !value);
                            setSelectedStudentIds([]);

                            // open the dropdown
                            // setSelector(true);

                            // toggle between selecting all and 0 students
                            // selectedStudentIds?.length ? setSelectedStudentIds([]) : setSelectedStudentIds(cls.student_ids ?? cls.students ?? []);
                        }}
                    />
                    <ClassIcon name={cls?.display_icon ?? 'ClassIcon00'} width='29px' height='29px' />
                    <div tw='hidden xsm:block'>
                        {cls?.title} {selectedStudentCopy}
                    </div>
                    <div tw='flex flex-col xsm:hidden'>
                        <div>{cls?.title}</div>
                        <div> {selectedStudentCopy}</div>
                    </div>
                </div>
                <OpenButton isOpen={studentSelectorIsOpen} handleOpen={() => setSelector(!studentSelectorIsOpen)} />
            </div>
            {studentSelectorIsOpen && (
                <SelectStudentGrid
                    students={students}
                    selectedStudentIds={selectedStudentIds}
                    setSelectedStudent={setSelectedStudent}
                    classSelected={classSelected}
                />
            )}
        </div>
    );
};

SelectClassStudentsDropdown.propTypes = {
    cls: PropTypes.any,
    assignment: PropTypes.any
};

export default SelectClassStudentsDropdown;
