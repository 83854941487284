import React from 'react';

const SvgIconAssignedVideos = (props) => (
    <svg
        viewBox='0 0 26.756 26.756'
        width='1em'
        height='1em'
        {...props}>
        <path
            data-name='Path 261'
            d='M0 0v6.689h26.756V0zm5.017 5.017H1.672V1.672h3.345zM0 16.722h26.756v-6.689H0zm1.672-5.017h3.345v3.345H1.672zM0 26.756h26.756v-6.689H0zm1.672-5.017h3.345v3.344H1.672z'
            fill='currentColor'
        />
    </svg>
);

export default SvgIconAssignedVideos;
