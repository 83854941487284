import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import RecordIndicator from '../RecordIndicator/RecordIndicator';
import { LoadingOverlay } from '../../lib/components/loading';

const BorderBottom = css`
    ::after {
        position: absolute;
        content: '';
        border-bottom: 2px solid #c7c7ff;
        width: 90%;
        transform: translateX(-50%);
        bottom: 0px;
        left: 50%;
    }
`;

const StatsBannerTeacher = ({ stats, assignments }) => {
    return useMemo(() => {
        if (stats === undefined || !assignments) {
            return (
                <div
                    tw='relative flex flex-col xl:flex-row items-center justify-between bg-white pt-11 pb-8 text-EarieBlack font-sans font-medium pl-7 pr-11'
                    css={[BorderBottom]}
                >
                    <LoadingOverlay color='#717ae6' background='#ffffff' show={true} />
                </div>
            );
        }

        let statsArr = Object.values(stats);

        // find total records for each level
        const silver = statsArr.filter((stat) => stat.current_level >= 2).length;
        const gold = statsArr.filter((stat) => stat.current_level >= 3).length;
        const platinum = statsArr.filter((stat) => stat.current_level >= 4).length;
        // find total assignments and completed assignments
        let totalAssignments = assignments.length;
        let totalCompletedAssignments = assignments.filter((assignment) => assignment.progress.completed === true).length;
        // find totalAchievements
        let achievements = [];
        statsArr.forEach((s) => {
            s.achievements.forEach((a) => achievements.push(a));
        });

        let viewCount = statsArr.reduce(function (accum, val) {
            return accum + Number(val.view_count);
        }, 0);

        return (
            <div tw='relative flex flex-col xl:flex-row items-center justify-between bg-white pt-11 pb-8 text-EarieBlack font-sans font-medium border-b-2 borderColor[#c7c7ff]'>
                <div tw=' w-full xl:w-1/2 flex justify-evenly xl:justify-center items-center text-center divide-x-4 divide-EarieBlack'>
                    {/* give containers hard height so the divide is the same size no matter the content */}
                    <div tw='w-1/3 flex flex-col justify-between items-center px-4' style={{ height: '72px' }}>
                        <div tw='text-2xl'>
                            {totalCompletedAssignments} of {totalAssignments}
                        </div>
                        <div tw='text-sm pt-1'>Completed Assignments</div>
                    </div>
                    <div tw='w-1/3 flex flex-col justify-between items-center px-6' style={{ height: '72px' }}>
                        <div tw='text-2xl'>{achievements.length}</div>
                        <div tw='text-sm pt-1 '>
                            Total
                            <br />
                            <span>Achievements</span>
                        </div>
                    </div>
                    <div tw='w-1/3 flex flex-col justify-between items-center px-6' style={{ height: '72px' }}>
                        <div tw='text-2xl '>{viewCount}</div>
                        <div tw='text-sm pt-1'>
                            Total
                            <br />
                            <span>Video Views</span>
                        </div>
                    </div>
                </div>
                <div tw=' w-full xl:w-1/2 flex justify-evenly xl:justify-center text-2xl text-center pt-6 xl:pt-0'>
                    <div tw='xl:pl-8'>
                        <div>{silver}</div>
                        <RecordIndicator level='Silver' minWidth='100px' tw='minWidth[100px]' />
                    </div>
                    <div tw='pl-8'>
                        <div>{gold}</div>
                        <RecordIndicator level='Gold' minWidth='100px' tw='minWidth[100px]' />
                    </div>
                    <div tw='pl-8'>
                        <div>{platinum}</div>
                        <RecordIndicator teacherPlat={true} level='Platinum' minWidth='136px' tw='minWidth[130px]' />
                    </div>
                </div>
            </div>
        );
    }, [assignments, stats]);
};

StatsBannerTeacher.propTypes = {
    stats: PropTypes.object,
    assignments: PropTypes.array
};

export default StatsBannerTeacher;
