import React from 'react';
import tw, { css, styled } from 'twin.macro';

const Container = styled.div(({ level, border }) => [
    tw`flex flex-col rounded-[0] sm:rounded-[10px] w-full h-full p-5 pb-6 pt-20 sm:pt-6 boxShadow[0 0.904687px 9.04688px rgba(0, 0, 0, 0.1)]`,
    border !== 'orange' && css`background: linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%);`,
    border === 'orange' && css`background: linear-gradient(135deg, #E637A8 0%, #E68137 100%);`,

]);



const GradientBorder = ({ style, children, className, variant, border, }) => {
    return (
        <Container
            border={border}
            style={style ?? null}
            className={className ?? ''}
        >
            <div
                tw='relative rounded-[10px] w-full h-full bg-white boxShadow[0 0.904687px 9.04688px rgba(0, 0, 0, 0.1)]'
                css={[
                    variant !== 'dark' && tw`text-EarieBlack bg-white`,
                    variant === 'dark' && tw`text-white bg-[linear-gradient(180deg, #1E215F 0%, #070A3B 100%)]`
                ]}
                className='gradient-border-contents'
            >
                {children}
            </div>
        </Container>
    );
};

export default GradientBorder;