import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import ReactHoverObserver from 'react-hover-observer';
import { useNavigate } from 'react-router-dom';
// import { useTeacher } from 'state/UserState';
import moment from 'moment';
import { AddAssignmentButton, ListBox, VideoDescriptionModal, VideoProgressIndicator } from 'components';
import { CircleCheckIcon } from 'app/images/icons';
import { toMMSS } from 'utils/time';
// Progress Imports -----
// Overlay Imports --------
import { PlayButtonOverlay } from 'components/VideoCardOverlays';
import HeartFavoriteIcon from 'images/icons/HeartFavoriteIcon';
import { addVideosToPlaylist, handleCreateNewPlaylistModal, handlePlaylistDropdown, removeVideoFromPlaylist, usePlaylistState } from 'state/PlaylistState';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { useParent } from 'state/User/UserState.js';
import { useClassStudents } from 'state/Teacher/ClassState';
import { useToggleFavoriteVideo, useVideoStats } from '../state/Student/VideoStats';
import { isAssignedVideoCompleted } from '../assignments';
import { usePlaylistVideos } from '../state/Student/AssignmentsState';

import Records from './videoCardProgress/Records';
import VideoThumbnail from './VideoThumbnail.js';
import { CompletedOverlay } from './VideoCardOverlays.js';

import AddToPlaylistIcon from './AddToPlaylistIcon/AddToPlaylistIcon';

function RemoveFromPlaylistIcon(props) {
    return (
        <svg
            width={25} height={25} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M24.15 21.439a2.074 2.074 0 010 2.926 2.054 2.054 0 01-2.913 0L12.5 15.587l-8.737 8.807A2.06 2.06 0 012.307 25a2.05 2.05 0 01-1.456-.606 2.07 2.07 0 01-.447-2.255 2.07 2.07 0 01.447-.67l8.737-8.837-8.985-9.1a2.074 2.074 0 010-2.926 2.054 2.054 0 012.912 0L12.5 9.735 21.485.708A2.059 2.059 0 0122.94.102a2.05 2.05 0 011.456.606A2.067 2.067 0 0125 2.171a2.077 2.077 0 01-.603 1.463l-8.985 8.998 8.737 8.807z'
                fill='#fff'
            />
        </svg>
    );
}

function ParentAddPlaylistIcon() {
    return (
        <svg
            width='35' height='35' viewBox='0 0 35 35' fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M17.5 0C7.83594 0 0 7.83594 0 17.5C0 27.1641 7.83594 35 17.5 35C27.1641 35 35 27.1641 35 17.5C35 7.83594 27.1641 0 17.5 0ZM25 18.4375C25 18.6094 24.8594 18.75 24.6875 18.75H18.75V24.6875C18.75 24.8594 18.6094 25 18.4375 25H16.5625C16.3906 25 16.25 24.8594 16.25 24.6875V18.75H10.3125C10.1406 18.75 10 18.6094 10 18.4375V16.5625C10 16.3906 10.1406 16.25 10.3125 16.25H16.25V10.3125C16.25 10.1406 16.3906 10 16.5625 10H18.4375C18.6094 10 18.75 10.1406 18.75 10.3125V16.25H24.6875C24.8594 16.25 25 16.3906 25 16.5625V18.4375Z'
                fill='#1AC1DD'
            />
        </svg>
    );
}

function ParentRemovePlaylistIcon() {
    return (
        <svg
            width='35' height='35' viewBox='0 0 35 35' fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M29.8744 5.12559C23.0408 -1.70793 11.9592 -1.70793 5.12563 5.12559C-1.70789 11.9591 -1.70789 23.0408 5.12563 29.8743C11.9592 36.7079 23.0408 36.7079 29.8744 29.8743C36.7079 23.0408 36.7079 11.9591 29.8744 5.12559ZM22.1404 23.4662C22.0189 23.5877 21.82 23.5877 21.6984 23.4662L17.5 19.2677L13.3016 23.4662C13.18 23.5877 12.9811 23.5877 12.8596 23.4662L11.5338 22.1403C11.4123 22.0188 11.4123 21.8199 11.5338 21.6984L15.7322 17.5L11.5338 13.3015C11.4123 13.18 11.4123 12.9811 11.5338 12.8596L12.8596 11.5337C12.9811 11.4122 13.18 11.4122 13.3016 11.5337L17.5 15.7322L21.6984 11.5337C21.82 11.4122 22.0189 11.4122 22.1404 11.5337L23.4662 12.8596C23.5877 12.9811 23.5877 13.18 23.4662 13.3015L19.2678 17.5L23.4662 21.6984C23.5877 21.8199 23.5877 22.0188 23.4662 22.1403L22.1404 23.4662Z'
                fill='#E04E4E'
            />
        </svg>
    );
}

const formatViewCount = (stats) => {
    // let viewCount = stats.view_count;
    let lastWatched = stats.last_viewed;
    let formattedDate = moment.utc(lastWatched).local().fromNow();
    if (formattedDate === 'a day ago') formattedDate = '1 day ago';
    return ` ${formattedDate === 'Invalid date' ? '' : ` Last viewed: ${formattedDate}`} `;
};

const formatPlaylistInclusion = (video, playlistVideos, videosWithStats) => {
    let findVideo = playlistVideos.find((v) => v.id === video.id);
    let matchVideo = findVideo && videosWithStats.find((v) => v.id === findVideo.id);
    if (!findVideo || !matchVideo.playlist || matchVideo?.playlist.length === 0) return 'Not in a Playlist';
    let firstAssignment = matchVideo.playlist[0];
    return `In Playlist: ${firstAssignment.playlist.name}`;
};

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'A Few seconds',
        ss: '%d Seconds',
        m: 'A Minute',
        mm: '%d Minutes',
        h: 'An Hour',
        hh: '%d Hours',
        d: 'A Day',
        dd: '%d Days',
        M: 'A Month',
        MM: '%d Months',
        y: 'A Year',
        yy: '%d Years'
    }
});

const CardContainer = styled.a`
    font-family: Avenir, serif;
    /* overflow: hidden; */
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0;
    background: #fff;
    padding: 0;
    border: none;
    -webkit-transition: box-shadow 0.1s ease, -webkit-transform 0.1s ease;
    transition: box-shadow 0.1s ease, transform 0.1s ease, -webkit-transform 0.1s ease;
    width: 100%;
    max-width: 9999px;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    cursor: pointer;
    user-select: none;
    /* margin-bottom: 10px; */
    padding-bottom: ${(props) => (props.assignment && !props.teacher ? '10px' : '0px')};

    .selected-overlay {
        background: green;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        font-size: 30px;
        font-family: Avenir, serif;
        font-weight: 500;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:active {
        box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .thumbnail {
        position: relative;

        // this is the little info box in the upper right corner
        .label {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 2px;
            border: solid 3px #fff;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: Avenir, serif;
            font-weight: 500;
            font-size: 18px;
            min-width: 45%;
            padding: 10px;

            @media only screen and (max-width: 1024px) {
                font-size: 15px;
                padding: 3px;
            }

            .text {
                pointer-events: none;
            }

            .icon {
                pointer-events: none;
            }
        }

        .faded {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(180deg, #00000000, #000000af);
        }

        .duration-label {
            font-family: 'Open Sans', serif;
            font-size: 14px;
            color: #ffffff;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

            /* position: absolute;
            right: 8px;
            bottom: 8px; */
        }

        .favorite {
            position: absolute;
            right: 8px;
            bottom: 30px;
        }

        &.aspect-16-9 {
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
        }
    }

    .info {
        padding: 10px 5px; // t, r, b, l

        /* padding-bottom: 10px; */
        display: flex;
        flex: 1;
        min-width: 0;
        justify-content: space-between;
            /* color: ${(props) => (props.isTeacher ? 'black' : '#fff')}; */
            /* background: ${(props) => (props.isTeacher ? '#EAEAFB' : 'linear-gradient(96.81deg, #1cc8e1 0.89%, #d022ed 98.12%)')}; */
        color: #1a1717;
        background: #eaeafb;
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;

        .title-count-container {
            white-space: normal;
            padding-left: 10px;
        }

        .teacher-info-text {
            padding-left: 10px;
            white-space: normal;
        }

        .title {
            font-family: Avenir, serif;
            font-size: 14px;
            font-weight: 500;
            height: 50px;
            line-height: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;

            @media only screen and (max-width: 1024px) {
                font-size: 14px;
            }
        }

        .secondary-text {
            /* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: 500;
            font-family: Avenir, serif;

            font-size: 14x;

            /* padding-bottom: 4px; */

            @media only screen and (max-width: 1024px) {
                font-size: 13px;
                font-size: 12px;
                margin-top: 10px;
            }
            @media only screen and (max-width: 640px) {
                font-size: 12px;
                margin-top: 10px;
            }
        }

        .score-text {
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.72);
            display: flex;
            flex-direction: column;
        }

        .info-label {
        }

        .record-list {
            background: orange;
            margin-bottom: 12px;
        }
    }

    .info-none {
        display: none;
    }

    .completed-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        background-color: #1db86b;
        opacity: 0.64;

        .checked-icon-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
    }

    .assignment-overlay {
        //border-top-left-radius: .28571429rem;
        //border-top-right-radius: .28571429rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;
        transition: opacity 0.3s;
        pointer-events: none;

        .play-button-container {
            height: 80px;
            display: flex;
            justify-content: flex-end;
            margin-right: 8px;
            margin-top: 16px;

            .play-button {
                height: 100%;
                width: 80px;
            }
        }

        &.play-button-overlay {
            justify-content: center;

            .play-button-container {
                height: 120px;
                display: flex;
                justify-content: center;
                margin: 0;

                .play-button {
                    height: 100%;
                    width: 80px;
                }
            }
        }

        .locked-button-container {
            height: 40px;
            display: flex;
            justify-content: flex-end;
            margin-right: 8px;
            margin-top: 100px;

            .locked-button {
                height: 100%;
                width: 80px;
            }
        }

        .info-locked {
            color: #ffffff;
            font-size: 16px;
            font-family: Avenir, serif;
            margin-bottom: 20px;
            margin-left: 20px;

            .info-button {
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
        }

        .info-label {
            flex-grow: 1;
            color: #ffffff;

            font-size: 16px;
            font-family: Avenir, serif;

            .info-title {
                font-weight: 600;
            }

            .info-desc {
            }

            .info-due {
            }
        }
    }

    .teacher-assign-button {
        width: 100%;
        border: 2px solid #ffffff;
        background-color: #e637a8;
        height: 34px;
        color: #fff;
        cursor: pointer;

        &:hover {
            background-color: #c22e8e;
        }

        &:active {
            background-color: #711b53;
        }
    }
`;

const SelectedOverlay = styled(CircleCheckIcon)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(29, 184, 107, 0.7);
`;

/*
    Label: The small label that appears in the upper left of the thumbnail
 */
export const Label = ({ text, type, color }) => (
    <div style={{ background: color }} className={`label ${type}`}>
        <div className='text'>{text}</div>
    </div>
);

export const VideoCard = (props) => {
    const parent = useParent();

    const showTitle = props.showTitle || true;
    const showViewCount = props.showViewCount || false;
    // const showRecords = props.showRecords || false;
    const showDuration = props.showDuration || true;
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [descriptionModalIsOpen, setDescriptionModal] = useState(false);

    const [{ playlist, isPlaylistDropdownOpen }, playlistDispatch] = usePlaylistState();
    const selectedVideo = playlist.selectedVideos.find((video) => video.videoId === props.video?.id);

    const toggleFavoriteVideo = useToggleFavoriteVideo(props.video?.id);
    const videoStats = useVideoStats(props.video?.id);

    const playlistVideos = usePlaylistVideos();
    const videosWithStats = useVideosWithStats();

    const { students } = useClassStudents(null);
    const listBoxItems = students.map((student) => ({
        name: `${student.user?.first_name}'s Views: ${student.video_stats ? student.video_stats[props.video.id]?.view_count ?? 0 : 0}`,
        id: student.user.id,
        item: student
    }));
    const [selectedStudent, setSelectedStudent] = useState(listBoxItems[0]);
    let showListBox = listBoxItems?.length > 1;

    function handleSetSelectedStudent(event) {
        event.stopPropagation();
    }

    function handleAddingVideoToPlaylist(event) {
        // prevent clicking through to the parent
        event.stopPropagation();

        if (!playlist.name) {
            handleCreateNewPlaylistModal(playlistDispatch, true);
        }

        handlePlaylistDropdown(playlistDispatch, true);
        // if video has not been selected then allow user to add to playlist
        if (!selectedVideo) {
            addVideosToPlaylist(playlistDispatch, { videoId: props.video.id, videoName: props.video.title, videoThumbnail: props.video.url_thumbnail });
        } else {
            handleCreateNewPlaylistModal(playlistDispatch, false);
            // if the video has been selected when they click the button again it will remove from the playlist
            removeVideoFromPlaylist(playlistDispatch, selectedVideo);
        }
    }

    const handleHoverChanged = (props) => {
        console.log('handle hover changed', props);
        setIsHovering(props.isHovering);
    };

    const handleMouseOver = ({ e, setIsHovering, unsetIsHovering }) => {
        console.log('hover', e.target.className);
        if (e.target.className === 'example__child') {
            unsetIsHovering();
        } else {
            setIsHovering();
        }
        // if (e.target.className === 'label info-label') {
        //     setIsHoveringOverLabel(true);
        // }
        // setIsHovering();
    };

    const handleMouseOut = ({ e, unsetIsHovering }) => {
        unsetIsHovering();
        // if (e.target.className === 'label info-label') {
        //     setIsHoveringOverLabel(false);
        // }
    };

    const handleInfoClick = (e) => {
        setDescriptionModal(true);
    };

    const handleClick = (e) => {
        if (descriptionModalIsOpen) {
            return;
        }

        // if icon do nothing
        if (typeof e.target.className === 'object') {
            return;
        }

        if (typeof props.className === 'string') {
            const buttonClasses = ['teacher-progress-button', 'teacher-assign-button'];
            for (let i = 0; i < buttonClasses.length; i++) {
                if (props.className.includes(buttonClasses[i])) return;
            }
        }

        // handle onClick OR push the route
        if (props.onClick && props.onClick(e)) {
            return;
        }

        // route to the video page if requested
        if (props.routeToVideoSession) {
            if (props.video && navigate)
                navigate(`/video/${props.video.id}`);
        }
    };

    // const renderCompletedOverlay = () => {
    //     if (props.video.assignment) {
    //         if (props.isCompleted) return <CompletedOverlay isHovering={true} />;
    //     }
    //     return null;
    // };

    const renderOverlay = () => {
        // default to the play button
        return <PlayButtonOverlay onClick={handleClick} isHovering={isHovering} />;
    };

    // const renderActions = () => {
    //     <div style={{zIndex: "20"}}>
    //     {teacherInfoIcon}
    //     {(props.video.assignment && !props.teacher)&& <VideoProgressIndicator
    //         assignments={props.video.assignment}
    //         teacher={props.teacher} />}
    //     {favoriteIcon}

    // ensure a video exists
    if (!props.video) return 'No Video';

    let scoreText = null;
    let viewsText = null;
    let standardsText = null;

    // SAVE: Saving for when standards are put back into place
    // if (this.props.teacher === true) {
    //     let standardArr = [];
    //     let standard = null
    //     if (this.props.video.standard) {
    //         this.props.video.standard.forEach(s => {
    //             standardArr.push(s.name);
    //             standard = standardArr.map((item, index) => <div className={"standard"} key={index}>{item}</div>)
    //         });
    //     }
    //     standardsText = (
    //         <div className={'view-count'}>
    //             Standards: {standard}
    //         </div>
    //     ); }

    // view count text
    if (!props.teacher) {
        viewsText = 'Your views: 0';
        if (showViewCount && videoStats) {
            viewsText = `Your ${videoStats?.view_count > 1 ? 'views:' : videoStats?.view_count === 0 ? 'views:' : 'view:'} ${videoStats?.view_count ?? 0}`;
        }
    }

    // in playlist text
    let inPlaylistText = null;
    if (props.video && props.teacher) {
        inPlaylistText = formatPlaylistInclusion(props.video, playlistVideos, videosWithStats);
    }

    // selected overlay
    let completedOverlay = props.video.assignment_completed ? <CompletedOverlay isHovering={true} /> : null;
    let selectedOverlay = props.selected ? <SelectedOverlay /> : null;

    // check an assignment for this video
    if (props.video && props.assignment && isAssignedVideoCompleted(props.assignment, props.video.id)) {
        completedOverlay = <CompletedOverlay isHovering={true} />;
    }

    // the video duration shown on the lower right
    let duration = toMMSS(props.video.duration);
    let durationNode = null;
    if (showDuration)
        durationNode = (
            <div className='faded'>
                <div className='duration-label'>{duration}</div>
            </div>
        );

    // little heart in top right corner
    const favoriteIcon = (
        <button
            tw='absolute right-0 -top-1.5'
            onClick={toggleFavoriteVideo}
            css={[css`
                &:focus-visible {
                    outline-offset: -8px;
                }
            `]}
        >
            <HeartFavoriteIcon className='fav' favorite={videoStats?.favorite ?? false} />
        </button>
    );

    let teacherInfoIcon = null;
    /*
    if (props.teacher) {
        teacherInfoIcon = (
            <div tw='absolute left-2 top-2' onClick={handleInfoClick}>
                <VideoInfoIcon />
            </div>
        );
    }
    */

    let secondaryText = null;
    secondaryText = <div className='secondary-text'>{props.teacher ? inPlaylistText : viewsText}</div>;

    // TODO: change this ...
    // the Assign this Video button at the bottom - unless its being shown in an assignment
    let assignButton = null;
    if (props.teacher && !props.assignment) assignButton = <AddAssignmentButton toggle={toggle} />;

    let infoBar = null;

    if (props.teacher) {
        if (props.libraryView) {
            infoBar = (
                <div tw='flex'>
                    <div className={`info ${props.info}`} tw='w-10/12'>
                        <div className='teacher-info-text'>
                            {showTitle && <div className='title'>{props.video.title}</div>}
                            {secondaryText}
                        </div>
                        {props.children}
                    </div>
                    <div
                        css={[tw`bg-MuzologyLightBlue flex justify-center items-center w-1/5`, selectedVideo && isPlaylistDropdownOpen && tw`bg-gray-400`]}
                        onClick={handleAddingVideoToPlaylist}
                    >
                        {selectedVideo && isPlaylistDropdownOpen ? <RemoveFromPlaylistIcon /> : <AddToPlaylistIcon />}
                    </div>
                </div>
            );
        } else {
            infoBar = (
                <div tw='flex'>
                    <div className={`info ${props.info}`} tw='w-full h-full'>
                        <div className='teacher-info-text'>{showTitle && <div className='title'>{props.video.title}</div>}</div>
                        {props.children}
                    </div>
                </div>
            );
        }
    } else {
        infoBar = (
            <div className={`info ${props.info}`}>
                <div className='title-count-container' style={{ paddingRight: '8px' }}>
                    {showTitle && <div className='title'>{props.video.title}</div>}
                    {secondaryText}
                </div>
                <Records className='record-list' stats={videoStats} />

                {props.children}
            </div>
        );
    }
    if (parent) {
        if (props.libraryView) {
            infoBar = (
                <div tw='flex'>
                    <div tw='w-10/12 flex justify-between background[#eaeafb] height[92px]'>
                        <div className='teacher-info-text' tw='minWidth[140px] w-full pl-2.5 pt-2.5 relative' onClick={handleSetSelectedStudent}>
                            {showTitle && <div className='title'>{props.video.title}</div>}
                            <div tw='maxWidth[180px] mb-2.5 '>
                                <ListBox
                                    items={listBoxItems}
                                    onChange={(value) => setSelectedStudent(value)}
                                    selected={selectedStudent}
                                    variant='transparent-small'
                                />
                            </div>
                        </div>
                    </div>
                    <div css={[tw`flex justify-center items-center w-1/5 background[#eaeafb]`]} onClick={handleAddingVideoToPlaylist}>
                        {selectedVideo && isPlaylistDropdownOpen ? <ParentRemovePlaylistIcon /> : <ParentAddPlaylistIcon />}
                    </div>
                </div>
            );
        }
    }

    return (
        <CardContainer
            key={props.video.id}
            data-video-id={props.video.id}
            className={`video-card ${props.className || ''}`}
            // style={props.style}
            isTeacher={props.teacher}
            // style={props.style}
            onClick={handleClick}
            labelFontSize={props.teacher === true ? '16px' : '20px'}
            assignment={props.assignment}
            teacher={props.teacher}
        >
            <ReactHoverObserver onHoverChanged={handleHoverChanged} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div className='thumbnail aspect-16-9'>
                    {/* The thumbnail image, blur it when hovering */}
                    <VideoThumbnail video={props.video} className={props.teacher === true ? null : isHovering ? 'blur' : ''} hideDuration={props.hideDuration} assignmentPage={props.assignmentPage} />

                    {/* The assignment label in the upper left */}

                    {/* The video duration shown in the lower right */}
                    {/* {durationNode} */}

                    {/* show a checkmark */}
                    {/* {completedOverlay && completedOverlay} */}
                    {completedOverlay}

                    {selectedOverlay}

                    {renderOverlay()}
                </div>
            </ReactHoverObserver>

            <div>
                {teacherInfoIcon}
                {props.video.assignment && !props.teacher && <VideoProgressIndicator assignments={props.video.assignment} teacher={props.teacher} />}
                {/* {favoriteIcon} */}
            </div>

            {infoBar}

            <VideoDescriptionModal isOpen={descriptionModalIsOpen} setIsOpen={setDescriptionModal} video={props.video} />
        </CardContainer>
    );
};

// export default withRouter(connect(null, { beginCreateAssignment })(VideoCard));
export default VideoCard;
