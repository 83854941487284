import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tw, { theme, GlobalStyles as BaseStyles } from 'twin.macro';

// custom global styles
const CustomStyles = createGlobalStyle`
    html {
        font-size: 16px !important;
        --sidebar-width-collapsed: 45px;
        --sidebar-width-expanded: 260px;
        --linear-gradient-background: linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%);
        --linear-gradient-bright-background: linear-gradient(98.43deg, #1CC8E1 0.89%, #D022ED 98.12%);
        --layout-width: 1325px;
        --video-session-layout-width: 1100px;
        --video-session-layout-width-1: 1250px;
        --playlist-wizard-height: 0;

        --video-side-bar-width: 260px;

        @media only screen and (max-width: 1024px) {
            --video-side-bar-width: 190px;
        }

        @media only screen and (max-width: 1421px) {
            --layout-width: calc(100% - 4rem);
        }
        @media only screen and (max-width: 575px) {
            --layout-width: calc(100% - 0px);
        }
    }

    /*
        Disable focus outline, enable focus-visible by default
    */
    a, button, input {
        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline-style: auto !important;
            outline-offset: 4px;

            //outline-style: solid;
            //outline-color: white;
            //border: 2px solid #59aee2;
            //outline-offset: 4px;
            //outline: 2px solid white;
            //outline-color: white !important;
            //outline: initial;
            //outline: 2px solid #59aee2;
            //outline: none;
            //box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 80%);
        }

        &.outline-offset-0 {
            &:focus-visible {
                outline-offset: 0;
            }
        }
    }
`;

const GlobalStyles = () => (
    <>
        <BaseStyles />
        <CustomStyles />
    </>
);

export default GlobalStyles;
