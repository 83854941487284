import React, { useState } from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import { Button, ClassIconPalette, Modal } from 'components';
import { useForm } from 'react-hook-form';
import { useClass, useClassActions } from '../../../../../state/Teacher/ClassState';
import CloseButton from '../../../../../components/AnswerKey/CloseButton';

const S = {
    Container: styled.div``
};

const EditClassDialog = ({ isOpen, setIsOpen, classId, onSaveClass }) => {
    const cls = useClass(classId);

    const {
        register,
        handleSubmit,
        setValue,
        errors: formErrors
    } = useForm({
        defaultValues: {
            title: cls?.title,
            display_icon: cls?.display_icon ?? 'ClassIcon13'
        }
    });

    React.useEffect(() => {
        register({ name: 'display_icon', type: 'custom' }, { required: true });
    }, [register]);

    const [selectedIcon, setSelectedIcon] = useState(null);
    const { updateClass, createClass } = useClassActions();

    const isNew = !classId;

    const onSubmit = (data) => {
        console.log('saving class', data);
        if (classId) {
            // update class
            updateClass({ ...data, id: classId }).then((cls) => {
                console.log('class updated', cls);
                if (onSaveClass) onSaveClass(cls);
                if (setIsOpen) setIsOpen(false);
            });
        } else {
            // create new class
            createClass(data).then((cls) => {
                console.log('class created', cls);
                if (onSaveClass) onSaveClass(cls);
                if (setIsOpen) setIsOpen(false);
            });
        }
    };

    const onSelectIcon = (icon) => {
        setSelectedIcon(icon);
        setValue('display_icon', icon, { shouldDirty: true });
    };

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            <S.Container>
                <div tw='mx-4 bg-white p-8 rounded-xl relative'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div tw='flex flex-col space-x-1 items-start '>
                            <h1 tw='text-black text-2xl font-medium mb-8'>{isNew ? 'Create New Class' : 'Edit Class'}</h1>

                            <div>Class Name *</div>
                            <input
                                name='title'
                                ref={register({ required: true })}
                                // value={cls?.title}
                                style={{
                                    width: '100%',
                                    height: '46px',
                                    background: '#E0E0E5',
                                    borderRadius: '4px',
                                    marginBottom: '20px',
                                    marginTop: '10px',
                                    padding: '0 16px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            />

                            <div>Select Class Icon *</div>
                            <ClassIconPalette
                                defaultSelected={cls?.display_icon ?? 'ClassIcon00'}
                                setSelected={onSelectIcon} />

                            <Button
                                tw='self-center mt-8'
                                type='submit'>
                                Create Class
                            </Button>
                        </div>
                    </form>
                    <CloseButton
                        tw='absolute -top-4 -right-4'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </S.Container>
        </Modal>
    );
};

export default EditClassDialog;
