import React, { useRef } from 'react';
import 'twin.macro';
import { useForm } from 'react-hook-form';
import { showError } from '../../../../../AccountOnboard/Components/Components';
import { Button } from '../../../../../../components';
import { useLoadingOverlay } from '../../../../../../components/LoadingOverlay';
import ParentSettingsModal from './ParentSettingsModal';

const ChangePasswordModal = ({ isOpen, setIsOpen, teacher, saveSettings, addToast }) => {
    const [isLoading, setIsLoading, Loading] = useLoadingOverlay();

    // form hook
    const {
        register,
        trigger,
        handleSubmit,
        watch,
        errors: formErrors,
        setError
    } = useForm({
        defaultValues: {
            name: teacher.name
        }
    });

    // get a reference to the password for validation
    const password = useRef({});
    const currentPassword = useRef({});
    password.current = watch('password', '');
    currentPassword.current = watch('current_password', '');

    const onSubmitPasswordChange = async (data) => {
        let payload = {};

        // attach the password if set
        if (data.current_password.length > 0) {
            payload['current_password'] = data.current_password;
            payload['new_password'] = data.password;
        }
        
        setIsLoading(true);
        // save the settings
        saveSettings(payload)
            .then((data) => {
                setIsLoading(false);
                addToast('Success!', 'Your password was changed.');
                setIsOpen(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('error', error);
                const errorData = error?.response?.data;
                if (errorData.current_password === 'invalid_password') {
                    setError('current_password', {
                        type: 'manual',
                        message: 'The current password is incorrect'
                    });
                }
            });
    };

    console.log('Error', formErrors);

    return (
        <ParentSettingsModal isOpen={isOpen} setIsOpen={() => setIsOpen(false)}>
            <Loading />
            <div tw='mx-4 relative'>
                <div tw='bg-[#4D3095] py-6 sm:p-6 rounded-xl'>
                    <div tw='mt-5 mb-2 font-medium text-lg text-white text-center sm:text-left'>Change Your Password</div>
                    <div tw='text-[#B5AAD0] pl-1 mt-3 text-center sm:text-left'>
                        This is the password you will use to log in to Muzology. To change your child’s password, please go to <a href='/settings/child_settings' tw='text-sm text-[#FFF] underline mt-6 cursor-pointer'>Child Settings</a>.
                    </div>

                    <form onSubmit={handleSubmit(onSubmitPasswordChange)} autoComplete='off' id='settings'>
                        <React.Fragment>
                            <div tw='mt-5 mb-2 font-medium  text-white'>Current Password</div>
                            <input
                                tw='bg-[#4D3095] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                                type='password'
                                name='current_password'
                                ref={register({
                                    required: true,
                                    minLength: {
                                        value: 6,
                                        message: 'Password must have at least 6 characters'
                                    }
                                })}
                            />
                            {showError(formErrors.current_password)}

                            <div tw='mt-5 mb-2 font-medium  text-white'>New Password</div>
                            <input
                                tw='bg-[#4D3095] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                                type='password'
                                name='password'
                                ref={register({
                                    required: currentPassword.current !== '',
                                    minLength: {
                                        value: 6,
                                        message: 'Password must have at least 6 characters'
                                    }
                                })}
                            />
                            {showError(formErrors.password)}
                            <div tw='mt-5 mb-2 font-medium text-white'>Confirm New Password</div>
                            <input
                                tw='bg-[#4D3095] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                                name='confirmPassword'
                                type='password'
                                ref={register({
                                    validate: (value) => value === password.current || 'The passwords do not match'
                                })}
                            />
                            {showError(formErrors.confirmPassword)}
                        </React.Fragment>
                        <div tw=' w-max mt-6'>
                            <Button type='submit' tw='bg-[#FEEA50] z-60 border-[#404040] border-[1px] rounded-md text-[#404040] font-Poppins font-medium'>
                                Save Changes
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </ParentSettingsModal>
    );
};

export default ChangePasswordModal;