import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { Button, Modal } from 'components';
import { submitHubspotForm } from 'lib/hubspot';
import { useUser, useUserAction } from 'state/User/UserState.js';
import { LoadingOverlay } from 'lib/components/loading';
import { ErrorMessage } from '../../app/containers/admin/Controls';
import { apiClient } from '../../utils/api-client';
import CloseButton from '../AnswerKey/CloseButton';


const placeHolderCss = css`
    ::placeholder {
        text-align: center;
        padding-top: 5px;
    }
`;
const CancelSubscriptionPopUp = ({ isOpen, setIsOpen, setOnboardState }) => {
    const [posting, setPosting] = useState(false);
    const [commentText, setCommentText] = useState('');
    const [reason, setReason] = useState('');
    const user = useUser();
    const { saveAccount } = useUserAction();
    const [error, setError] = useState(null);

    const reasons = [
        { title: 'Math was too advanced', value: 'too_advanced' },
        { title: 'My child did not like the curriculum', value: 'too_advanced' },
        { title: 'My child did not like the platform', value: 'not_advanced' },
        { title: "My child wasn't using it enough / didn't like it", value: 'no_use' },
        { title: 'Not sure how the platform works', value: 'confused' },
        { title: 'Had technical issues', value: 'technical_issues' },
        { title: 'Too expensive', value: 'too_expensive' },
        { title: 'Not enough content', value: 'not_enough_content' },
        { title: 'Other (please explain below)', value: 'other' }
    ];

    const onSubmit = (data) => {
        console.log('submit', data);

        if (!reason) {
            setError('Please select a reason');
            return;
        }

        const payload = {
            email: user.email,
            reason: reason,
            other: commentText
            // do_you_want_to_keep_using_muzology_: data.choice
        };
        setPosting(true);
        // setIsOpen(false);
        // submitHubspotForm('8464088', 'c4593a5e-7b21-4c0e-a201-8831677d7c40', payload)
        apiClient.post('/api/subscription/cancel/', payload)
            .then((response) => {
                // the response should contain the new account data
                const { data } = response;
                saveAccount(data);
                setOnboardState?.({ trial_end_survey_response: 'yes' });
                setIsOpen?.(false);
                setPosting(false);
            })
            .catch((err) => {
                window.Rollbar?.error('Error submitting cancel subscription form', err);
                console.error(err);
                alert(err.message);
                setPosting(false);
            });
    };
    return (
        <Modal isOpen={isOpen} modalChildrenStyle={tw`maxWidth[500px] mx-auto`}>
            <LoadingOverlay show={posting} fixed={true} />

            <div tw='mx-4 relative'>
                <div tw='bg-white p-6 rounded-xl'>
                    <div tw='flex justify-center'>
                        <p tw='text-6xl'>&#128546;</p>
                    </div>
                    <div tw='text-center text-2xl font-extrabold text-RoyalDarkBlue'>We're sad to see you go...</div>
                    <div tw='text-center text-DavysGrey'>Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps.</div>
                    <div tw='flex flex-col mt-5 margin-left[5%]'>
                        {reasons.map((reason, index) => (
                            <div key={index} tw='flex items-center' onClick={() => setReason(reason.value)}>
                                <input
                                    tw='w-5 h-5 border border-EarieBlack rounded-sm cursor-pointer'
                                    type='radio'
                                    name='reason'
                                    // ref={register({ required: false })}
                                    value={reason}
                                />
                                <div tw='ml-2 text-DavysGrey text-[1rem]'>{reason.title}</div>
                            </div>
                        ))}
                    </div>
                    {error && <ErrorMessage>*&nbsp;{error}</ErrorMessage>}
                    <div tw='mt-4 flex justify-center'>
                        <textarea
                            css={[placeHolderCss]}
                            tw='border[1px solid] border-DavysGrey width[90%] minHeight[100px] p-3'
                            placeholder='Anything you want to share? (Optional)'
                            onChange={(e) => setCommentText(e.target.value)}
                        />
                    </div>
                    <div tw='mt-10 flex flex-col justify-center items-center '>
                        <Button variant='gradient-orange' onClick={onSubmit}>
                            Continue
                        </Button>
                    </div>
                </div>
                <CloseButton tw='absolute -top-4 -right-4' onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    );
};

export default CancelSubscriptionPopUp;
