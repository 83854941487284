import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { Modal, LoginButton } from 'components';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { apiLoginClient } from 'context/Auth/AuthProvider';
import StudentSignUpForm from './StudentSignUpForm';

const ClassJoinCode = ({ isOpen, handleCloseJoinCodeModal, setIsLoading, setIsOpen }) => {

    const navigate = useNavigate();
    const [validJoinCode, setValidJoinCode] = useState(null);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        setError
    } = useForm();
    const params = useQuery();
    const { join_code } = params;

    const [{ loading: isPosting }, postInviteCode] = useAxios(
        {
            url: '/api/join/code/',
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
        },
        { manual: true, axios: apiLoginClient }
    );

    // when the page mounts, check the url query for a join code and load it
    useEffect(() => {
        console.log('JOIN CODE:', join_code);
        if (join_code) {
            setValue('join_code', join_code);
            onSubmit({ join_code });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data) => {
        setIsLoading(true);
        postInviteCode({ data: qs.stringify(data) })
            .then((response) => response.data)
            .then(({ valid, code, error }) => {
                setIsLoading(false);
                console.log(valid, error);
                if (valid) {
                    // valid join code
                    setValidJoinCode(code);
                } else {
                    // invalid join code
                    setValidJoinCode(null);
                    setError('join_code', { type: 'manual', message: error });
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Join error:', error);
                console.error(error);
            });
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={handleCloseJoinCodeModal} modalChildrenStyle={tw`w-full max-w-[700px] mx-4 md:mx-auto`} modalChildrenStyleOpacity={tw`opacity-80`}>
            {validJoinCode ? <StudentSignUpForm joinCode={validJoinCode} setIsLoading={setIsLoading} setIsOpen={setIsOpen} /> :
                <>
                    <div tw='bg-[#3E257A] font-Poppins flex flex-col items-center rounded-t-2xl relative px-3 '>
                        <div tw='text-white text-base font-medium text-center max-w-[380px] mt-10 '>Enter the Class Join Code provided by your teacher to create your Muzology account.</div>
                        <form tw='w-full max-w-[350px]' onSubmit={handleSubmit(onSubmit)}>
                            <div tw='relative mt-5'>
                                <input
                                    placeholder='Enter class join code'
                                    data-testid='join-field'
                                    name='join_code'
                                    ref={register({
                                        required: true,
                                        maxLength: {
                                            value: 4,
                                            message: 'The join code must be 4 characters'
                                        },
                                        minLength: {
                                            value: 4,
                                            message: 'The join code must be 4 characters'
                                        }
                                    })}
                                    tw='block w-full px-4 py-2.5 mt-2 font-normal text-base text-white bg-transparent border-white border-[1px] border-solid rounded-[8px] focus:border-none focus:ring-[#9D91BB] focus:outline-none focus:ring'
                                />
                                {errors.join_code && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                            </div>
                            <div tw='mt-6'>
                                <LoginButton
                                    tw='text-black bg-LoginYellowButton hover:bg-LoginYellowButton hover:bg-opacity-80  focus:bg-LoginYellowButton focus:bg-opacity-50'
                                    id='login-button'
                                    data-testid='login-button'
                                    variant='Blue'

                                >
                                    Continue
                                </LoginButton>
                            </div>
                        </form>
                        <div tw='flex items-center justify-center mt-8 pb-8'>
                            <div>
                                <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z' stroke='#FA6778' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                                    <path d='M9 12H9.0075V12.0075H9V12Z' stroke='#FA6778' strokeWidth='2' strokeLinejoin='round' />
                                    <path d='M9 9V6' stroke='#FA6778' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
                                </svg>
                            </div>
                            <div tw='text-[#FA6778] font-normal text-sm ml-2 '>Do not use a Class Join Code more than once.</div>
                        </div>
                        <div tw='absolute top-3 right-3' onClick={() => handleCloseJoinCodeModal()}>
                            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M16.3398 9.3218C16.4358 9.23224 16.5133 9.12464 16.5678 9.00513C16.6223 8.88563 16.6527 8.75656 16.6573 8.62531C16.6619 8.49405 16.6406 8.36318 16.5946 8.24015C16.5486 8.11713 16.4788 8.00437 16.3893 7.90831C16.2997 7.81224 16.1921 7.73476 16.0726 7.68029C15.9531 7.62581 15.824 7.59541 15.6928 7.59081C15.5615 7.58621 15.4306 7.60751 15.3076 7.6535C15.1846 7.69948 15.0718 7.76924 14.9758 7.85881L12.0498 10.5868L9.32176 7.65981C9.13923 7.47285 8.89078 7.36476 8.62956 7.35867C8.36834 7.35258 8.11512 7.44897 7.92407 7.62721C7.73302 7.80545 7.61932 8.05139 7.6073 8.3124C7.59529 8.57341 7.6859 8.82876 7.85977 9.02381L10.5878 11.9498L7.66077 14.6778C7.56131 14.7665 7.48057 14.8741 7.4233 14.9944C7.36602 15.1147 7.33336 15.2452 7.32723 15.3783C7.3211 15.5114 7.34163 15.6444 7.38761 15.7694C7.43359 15.8944 7.5041 16.009 7.59498 16.1065C7.68587 16.2039 7.79531 16.2822 7.91687 16.3367C8.03843 16.3912 8.16966 16.4209 8.30286 16.424C8.43605 16.4271 8.56853 16.4036 8.6925 16.3548C8.81647 16.306 8.92944 16.2329 9.02476 16.1398L11.9508 13.4128L14.6788 16.3388C14.7668 16.4401 14.8744 16.5226 14.9951 16.5814C15.1157 16.6403 15.247 16.6742 15.381 16.6812C15.5151 16.6883 15.6492 16.6682 15.7753 16.6223C15.9015 16.5764 16.0171 16.5056 16.1153 16.414C16.2134 16.3225 16.2922 16.2121 16.3468 16.0895C16.4014 15.9669 16.4308 15.8345 16.4332 15.7003C16.4356 15.5661 16.4109 15.4328 16.3607 15.3083C16.3104 15.1839 16.2356 15.0708 16.1408 14.9758L13.4138 12.0498L16.3398 9.3218Z' fill='#9D91BB' />
                                <path fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.925 5.925 1 12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z' fill='#9D91BB' />
                            </svg>
                        </div>
                    </div>
                    <div tw='bg-[#6441B9] text-center text-white text-sm font-normal  py-5 rounded-b-2xl font-Poppins px-3'>
                        <div>If you already have a Muzology account, <br tw='block md:hidden' /> <span tw='underline cursor-pointer' onClick={() => setIsOpen(false)}>click here to log in.</span></div>
                        <div tw='mt-2'>Forgot your username or password? <br tw='block md:hidden' /> Pease see your teacher.</div>
                    </div>
                </>
            }
        </Modal>
    );
};

export default ClassJoinCode;