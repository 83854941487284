import * as React from 'react';

function SvgClassIcon05(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#BE6CFF' />
            <path
                d='M25.638 14.861h-6.674l-.07 2.589c.363-.127.862-.19 1.496-.19.854 0 1.638.126 2.35.38a4.993 4.993 0 011.83 1.116c.522.475.926 1.06 1.21 1.758.286.68.428 1.448.428 2.303 0 .95-.166 1.797-.498 2.541a5.42 5.42 0 01-1.33 1.9c-.57.507-1.251.895-2.043 1.164a7.707 7.707 0 01-2.541.404c-1.457 0-2.676-.356-3.658-1.069-.981-.712-1.654-1.789-2.019-3.23l3.73-.855c.094.602.348 1.093.76 1.473.41.364.925.546 1.543.546.76 0 1.362-.238 1.805-.712.46-.476.689-1.077.689-1.805 0-.555-.119-1.006-.357-1.354a2.343 2.343 0 00-.902-.855 3.492 3.492 0 00-1.235-.451 8.09 8.09 0 00-1.378-.12 9.82 9.82 0 00-1.78.167c-.587.11-1.157.261-1.71.451l.19-9.286h10.164v3.135z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon05;
