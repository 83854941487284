/**
 * Created by jasonduncan on 6/28/18.
 */
import tw from 'twin.macro';
import React from 'react';
import { styled } from 'twin.macro';
import { useAccount } from 'state/User/UserState';
import { toMMSS } from '../utils/time';
import VideoCardIndicator from './VideoCard/VideoCardInfo/VideoCardIndicator';

const ThumbnailContainer = styled.button`
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
`;

const StyledImg = styled.img`
    background-image: linear-gradient(104deg, #3ce6f2, #0a75e2);
    transition: filter 0.3s;

    &.blur {
        filter: blur(4px);
        overflow: hidden;
    }
`;


const VideoThumbnail = ({ className, video, thumbnail, image, onClick, videoDuration, isNew, isMini, isAssigned, assignment, teacher, teacherHasAssignedVideo, hideDuration, assignmentPage }) => {
    const image_src = image ?? thumbnail ?? video?.url_thumbnail ?? null;

    return (
        <ThumbnailContainer
            className='video-thumbnail outline-offset-0'
            tw='w-full relative overflow-hidden block rounded-t-md'
            onClick={onClick}
            aria-label={video?.title ?? 'Play Video'}
            data-testid={`play-video-${video?.id}`}
        >
            <StyledImg
                className={`styled-image ${className ?? ''}`}
                width='100%'
                height='100%'
                src={image_src}
                // srcSet={image_srcset}
                alt={video?.title ?? 'video thumbnail'}
            />
            <VideoCardIndicator
                assignments={assignment}
                hideDuration={hideDuration}
                teacher={teacher}
                isNew={isNew}
                isMini={isMini}
                isAssigned={isAssigned}
                videoDuration={videoDuration}
                teacherHasAssignedVideo={teacherHasAssignedVideo}
                assignmentPage={assignmentPage}
            />
        </ThumbnailContainer>
    );
};



export default VideoThumbnail;