import React from 'react';
import { css } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import BugReport from 'components/BugReport/BugReport';
import LogoFull from 'static/images/logo/logo-nav-dtc.png';
import LogoHeart from 'static/images/logo/logo_heart@2x.png';
import { useMedia } from 'react-use';
import { useSearch } from 'context/SearchProvider';
import SearchInput from '../SearchInput';

const TopLeftNav = ({ isNotProd, timesTablesProduct, user }) => {
    const showSmallLogo = useMedia(user?.instructor ? '(max-width: 767px)' : '(max-width: 1055px)');
    const { search, setDropDown } = useSearch();

    return (
        <>
            <div tw='select-none flex items-start nav_md:items-stretch justify-center nav_md:justify-start '>
                <div tw='flex-shrink-0 flex items-center'>
                    {/* Logo */}

                    <NavLink to='/' onClick={() => { search(null); setDropDown(null); }}>
                        <div tw='flex items-center justify-center'>
                            <img id='muzology-logo' tw='h-12 w-auto' src={showSmallLogo ? LogoHeart : LogoFull} alt='Muzology Logo' />
                        </div>
                    </NavLink>
                </div>
                {!timesTablesProduct &&
                    <div tw='max-w-[400px]  hidden nav_md:flex items-center justify-center ml-16 '>
                        <SearchInput />
                    </div>
                }

                {isNotProd && (
                    <div tw='mt-1 hidden nav_md:block'>
                        <BugReport tw='ml-0' user={user}>
                            <div tw='py-2 px-6 rounded-full uppercase text-sm font-extrabold leading-normal text-white cursor-pointer bg-gradient-to-r from-[#e68137] to-[#e637a8]'>
                                FEEDBACK
                            </div>
                        </BugReport>
                    </div>
                )}
            </div>
        </>
    );
};

export default TopLeftNav;