/**
 * A collection of miscellaneous functions for easier dealing with `fetch` and
 * API calls in general.
 */

/**
 * @param {Integer} expected_status_codes - additional expected status codes to
 * supply to the function.  By default it checks for codes in the 200's.
 *
 * The intended usage is to throw errors for wrong status codes, since `fetch`
 * by default will only `reject` based on network / js errors, not HTTP codes.
 */
export function throwForAPI(...expected_status_codes) {
    /**
	 * @param {Response} response - ES6 Response object (such as from fetch)
	 */
    return function (response) {
        if (expected_status_codes.includes(response.status) || response.ok) return response;
        else return Promise.reject(response);
    };
}

/**
 * @param {string} endpoint - the API endpoint to hit
 * @param {Object} extraParams - extra fetch parameters to pass (method, etc)
 * @param {Array[Integers]} extraCodes - extra codes to accept as successful
 * @returns {Promise} - either the response object, if the response was OK, or a
 * rejected Promise containing an Error object containing the statusText (see
 * above)
 *
 * This is a generic API call wrapper around the `fetch` API. The extraParams
 * object can encode things like POST bodies (and the POST method itself), etc.
 * The only required argument is the API endpoint.  Default method is GET.
 */

export function apiCallRaw(endpoint, extraParams = {}, extraCodes = []) {
    // ensure headers contains the CSRF token
    if (extraParams.headers) {
        extraParams.headers['X-CSRFToken'] = parse_cookies().csrftoken;
    } else {
        extraParams['headers'] = { 'X-CSRFToken': parse_cookies().csrftoken };
    }

    let params = {
        ...extraParams,
        mode: 'cors',
        credentials: 'include'
        // mode: 'same-origin',
        // credentials: 'same-origin'
    };

    const method = extraParams['method'] || 'GET';

    return fetch(endpoint, params)
        .then(throwForAPI(...extraCodes, endpoint))
        .then((response) => {
            console.groupCollapsed(`[apiCall] ${method} ${endpoint} <= ${response.statusText}`);
            console.log([
                ['URL', endpoint],
                ['Extra Params', extraParams],
                ['Extra Codes', extraCodes]
            ]);
            console.dir(response);
            console.groupEnd();

            return response;
        })

        .catch((response) => {
            console.groupCollapsed(`[apiCall ERROR] ${method} ${endpoint} => ${response.statusText}`);
            console.dir(response);
            console.groupEnd();

            let error = response.statusText;

            // 403 'forbidden' means unauthorized
            if (response.status === 403) {
                console.log('API Error: Unauthorized', method, endpoint, error);
                if (window.Rollbar)
                    window.Rollbar.error('User logged out', error, {
                        endpoint,
                        extraParams,
                        extraCodes
                    });

                // redirect to login
                window.location.href = '/login?';
                // window.muzology_redirect = {
                // 	reason : '',
                // };

                return null;
            } else {
                console.log('API Error', endpoint, error);
                if (window.Rollbar)
                    window.Rollbar.error('API Call Error', error, {
                        endpoint,
                        extraParams,
                        extraCodes
                    });
                return Promise.reject(error);
            }
        });
}

export function apiCall(endpoint, extraParams = {}, extraCodes = []) {
    // default to json content if no headers are supplied
    if (!extraParams.headers) {
        extraParams.withCredentials = true;
        extraParams['headers'] = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
    } else {
        console.error('headers supplied');
    }
    return apiCallRaw(endpoint, extraParams, extraCodes);
}

/**
 * Get the freaking cookies.
 * @link http://musings.tinbrain.net/blog/2015/aug/28/vanilla-js-meets-djangos-csrf/
 */
function parse_cookies() {
    let cookies = {};
    if (document.cookie && document.cookie !== '') {
        document.cookie.split(';').forEach(function (c) {
            let m = c.trim().match(/(\w+)=(.*)/);
            if (m !== undefined) {
                cookies[m[1]] = decodeURIComponent(m[2]);
            }
        });
    }
    return cookies;
}
