import React, { useState, useEffect } from 'react';
import tw, { css } from 'twin.macro';
import moment from 'moment/moment';
import { StatsBanner, Plaque, NullContainer } from 'components';
import SilverRecord from 'static/records/record_0_100.png';
import GoldRecord from 'static/records/record_1_100.png';
import PlatinumRecord from 'static/records/record_2_100.png';
import { useMedia } from 'react-use';

const options = [
    { id: 1, name: 'Video Title', type: 'alphabetical', unavailable: false },
    { id: 2, name: 'Recent to Old', type: 'date', unavailable: false },
    { id: 3, name: 'Challenge Level', type: 'record_level', unavailable: false }
];

const recordImages = ['', SilverRecord, GoldRecord, PlatinumRecord];
const recordTitle = ['', 'Silver', 'Gold', 'Platinum'];

const MyRecords = ({ stats, activity, videos, parent }) => {
    const [search, setSearch] = useState('');
    const [selectedOption, setSelectedOption] = useState(options[0]);

    // get stats length to check against if all filtered stats with an empty object are returned
    let recordCount = stats?.filter((stat) => stat.current_level > 1).length;

    const makeRecordsPlaques = (items, search) => {
        // handle filter dropdown
        switch (selectedOption.type) {
            case 'date':
                items = items.sort((a, b) => moment(b.dates).diff(moment(a.dates)));
                break;
            case 'alphabetical':
            default:
                items = items.sort((a, b) => a.title.localeCompare(b.title));
                break;
            case 'record_level':
                items = items.sort((a, b) => a.level - b.level);
                break;
        }

        // handle search for video titles
        if (search) {
            items = items.filter((video) => video.title.toLowerCase().includes(search));
        }

        return items.map((item, index) => {
            let copy = {
                title: item.recordTitle,
                videoTitle: item.title
            };
            const badge = item.completions > 1 ? `${item.completions}x` : null;
            return (
                <Plaque
                    key={index}
                    teacher={false}
                    record={true}
                    multiplier={badge}
                    recordImage={item.recordImages}
                    copy={copy}
                    minHeight='362px'
                />
            );
        });
    };

    let plaqueItems = [];
    if (videos) {
        // filter videos that have no stats or quiz_level data
        // create plaque items to easy filter and sort
        let items = videos.filter((v) => v?.stats?.quiz_levels);
        plaqueItems = items.reduce((items, video) => {
            Object.values(video.stats.quiz_levels).forEach((quiz) => {
                const completions = quiz.instances ? quiz.instances.filter((instance) => instance.score === 100).length : 0;
                if (quiz.score === 100 && quiz.level > 0) {
                    items.push({
                        recordTitle: recordTitle[quiz.level],
                        date: quiz.date,
                        level: quiz.level,
                        recordImages: recordImages[quiz.level],
                        title: video.title,
                        completions: completions
                    });
                }
            });
            return items;
        }, []);
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const isSmallAndNoStats = useMedia('(max-width: 640px)') && stats.length === 0;

    return (
        <div tw='mt-8 sm:mt-11'>
            {!isSmallAndNoStats && (
                <div>
                    <StatsBanner
                        stats={stats ?? []}
                        activity={activity ? (Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity) : null}
                    />
                </div>
            )}

            {recordCount > 0 ? (
                <div tw='grid grid-cols-1 px-10 xsm:px-0 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6 justify-center'>
                    {makeRecordsPlaques(plaqueItems, search)}
                </div>
            ) : (
                parent ? (
                    <NullContainer
                        tw='text-white text-base sm:text-2xl font-medium'
                        style={parent && { marginTop: '20px' }}
                    >
                        <div tw='flex flex-col justify-around [height:260px]'>
                            <div>As your child watches Muzology videos and takes the associated math Challenges, you’ll see their progress here.</div>
                            {/*
                            <div>
                                Want to help guide your child’s learning?{' '}
                                <span tw='underline cursor-pointer' onClick={() => navigate('/playlists')}>
                                    Click here
                                </span>{' '}
                                to create a playlist of Muzology videos and share them with your child.
                            </div>
                            */}
                        </div>
                    </NullContainer>
                ) : (
                    <NullContainer tw='mt-8'>
                        <div tw='text-white text-2xl font-medium text-center'>Take the math Challenges, level up and start earning Records!</div>
                    </NullContainer>
                )
            )}
        </div>
    );
};

export default MyRecords;
