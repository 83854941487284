import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import ListBox from '../../../../../components/ListBox/ListBox';
import ParticleBackground from './ParticleBackground';

import { bouncing_balls, bouncing_dots, demo1, falling_snow, shooting_stars, silver_confetti, spinning_stars } from './particles';

// const particle_defs = [bouncing_balls, silver_confetti, spinning_stars, falling_snow, shooting_stars];
const particle_defs = [
    bouncing_dots,
    bouncing_balls,
    silver_confetti,
    spinning_stars,
    falling_snow,
    shooting_stars,
    demo1
];

const ParticlesViewerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: center;
    flex-grow: 1;
    position: relative;
    background-color: #090808;
`;

const ParticlesContainer = styled.div`
    flex-grow: 1;
    background: green;
    position: relative;
`;

const items = [
    { name: 'All Playlists' },
    { name: '1st Period Playlists' },
    { name: '2nd Period Playlists' },
    { name: 'Gamer Class' },
    { name: '4th Period Playlists' }
];

const Template = (props) => (
    <div style={{ maxWidth: '200px' }}>

    </div>
);


const ParticlesViewer = () => {
    const [selectedParticleIndex, setSelectedParticleIndex] = useState(1);
    const [selectedParticle, setSelectedParticle] = useState(null);

    const selectParticle = (item) => {
        console.log('[particles] selected', item);
        setSelectedParticleIndex(item.value);
        const selectedParticleIndex = item.value;
        // if (particle_defs.length && selectedParticleIndex >= 0) {
        const def = particle_defs[selectedParticleIndex % particle_defs.length];
        console.log('[particles] selecting particle:', def);
        setSelectedParticle(def);
    };

    // useEffect(() => {
    //     if (particle_defs.length && selectedParticleIndex >= 0) {
    //         const def = particle_defs[selectedParticleIndex % particle_defs.length];
    //         console.log('selecting particle:', def);
    //         setSelectedParticle(def);
    //     }
    // }, [selectedParticleIndex]);
    //
    // let particles = particle_defs[selectedParticle % particle_defs.length];

    // const options = [
    //     { name: 'bouncing_balls', value: 0 },
    //     { name: 'silver_confetti', value: 1 },
    //     { name: 'spinning_stars', value: 2 },
    //     { name: 'falling_snow', value: 3 },
    //     { name: 'shooting_stars', value: 4 }
    // ];
    const options = particle_defs.map((def, index) => {
        return { name: def.name, value: index };
    });

    return (
        <ParticlesViewerContainer tw='w-full h-full flex flex-row'>

            <div tw='w-[300px] z-50 absolute'>
                <ListBox
                    className='relative z-40 w-[300px] '
                    items={options}
                    onChange={selectParticle}
                    initialSelected={options[selectedParticleIndex]}
                    // selected={selectedParticleIndex}
                />
            </div>

            <div className='flex-grow bg-red-300 relative'>
                <ParticlesContainer tw='w-full relative bg-red-400  '>
                    <ParticleBackground params={selectedParticle} />
                </ParticlesContainer>
            </div>
        </ParticlesViewerContainer>
    );
};

export default ParticlesViewer;
