import React, { useEffect } from 'react';
import tw, { styled, css } from 'twin.macro';

const Bar = styled.div(({ level }) => [
    tw`absolute rounded-[27.5px] top-0 bottom-0 left-0`,
    css`
        /* right: ${(props) => props.$percentage}; */
        animation: progressBar 3s ease-in-out;
        animation-fill-mode:both; 
        @keyframes progressBar {
        0% { width: 0; }
        100% { width:  ${(props) => props.$percentage}}
}
    `,
    level === 1 && css`background: linear-gradient(180deg, #EEEDED 0%, #CCC 100%); box-shadow: 0px 0px 18.4464px 0px #E4E4E4, 0px 0px 31.6224px 0px rgba(255, 255, 255, 0.66), 0px 0px 55.3392px 0px rgba(255, 255, 255, 0.53);`,
    level === 2 && css`background: linear-gradient(180deg, #FFF6E6 0%, #FFC96E 100%); box-shadow: 0px 0px 18.4464px 0px #CF9839, 0px 0px 31.6224px 0px #E1A93F, 0px 0px 55.3392px 0px #D59F43;`,
    level === 3 && css`background: linear-gradient(180deg, #F6F6FA 0%, #99CBE8 100%); box-shadow: 0px 0px 18.4464px 0px #A1C5ED, 0px 0px 31.6224px 0px #BADDED, 0px 0px 55.3392px 0px #97CAE9;`,
]);

const ProgressBar = ({ percent, level }) => {
    let percentage = `${percent * 100 || 0}%`;

    return (
        <div tw='relative h-[37px] w-full rounded-[24px] border-[3px] border-solid border-[#E5E3E3] bg-[#6441B9] mt-7'>
            <div tw='absolute inset-0 h-[32px]'>
                <Bar key={percent} level={level} $percentage={percentage} tw='absolute rounded-[27.5px] top-0 bottom-0 left-0' />
            </div>
        </div>
    );
};

export default ProgressBar;
