import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import Button from '../Button/Button';
import RecordPercentage from '../RecordProgress/RecordPercentage';
import { LockSVG, ContinueSVG, RetakeSVG, StartSVG } from './DTCButtonSVGs/index';

export const BorderGradient = css`
  border: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
  @media only screen and (max-width: 768px) {
    max-height: 600px;
  }
  @media only screen and (max-width: 500px) {
    min-height: 332px;
  }
`;

export const BorderGradientParent = css`
  border: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
  min-height: 400px;
`;

const TitleBackground = styled.div(({ $level }) => [
    tw`rounded-2xl max-w-[109px] mx-auto mt-5 py-1`,
    $level === 1 && css`background: linear-gradient(270deg, #B1B1B1 -1.03%, #898989 59.91%);`,
    $level === 2 && css`background: linear-gradient(270deg, #F2B845 -0.61%, #A06F27 99.39%);`,
    $level === 3 && css`background: linear-gradient(90deg, #46A6F8 0%, #B15AFE 100%); `

]);

const DynamicText = styled.div(({ $variant }) => [
    tw` text-xs text-[#BCB5CD] text-center mt-4 `

]);

const RecordPlaqueDTC = (props) => {
    const { level, score, currentLevel, currentScore, parent, attempts, unlocked, setLevel, setShowModal } = props;
    const levelText = ['', 'SILVER', 'GOLD', 'PLATINUM'];
    // determine the filled record to show from the config
    let ScoreText = currentScore;
    if (currentLevel === level) {
        ScoreText += '%';
        if (attempts === null) {
            ScoreText = (
                <svg width='31' height='36' viewBox='0 0 31 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path opacity='0.5' d='M4 5.77938C4 4.99924 4.85355 4.51955 5.51995 4.92519L25.5967 17.1458C26.2367 17.5354 26.2367 18.4646 25.5967 18.8542L5.51995 31.0748C4.85355 31.4804 4 31.0008 4 30.2206V5.77938Z' stroke='#FCFCFC' strokeWidth='8' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
            );
        }
    } else if (currentLevel < level) {
        ScoreText = (
            <svg xmlns='http://www.w3.org/2000/svg' width='47' height='47' fill='none'>
                <path
                    stroke='#9D91BB' strokeLinecap='round' strokeWidth='7.2'
                    d='M15.669 17.625v-3.917a7.833 7.833 0 0 1 7.833-7.833v0a7.833 7.833 0 0 1 7.833 7.833v3.917' />
                <path
                    fill='#9D91BB' fillRule='evenodd'
                    d='M5.875 19.625a2 2 0 0 1 2-2h31.25a2 2 0 0 1 2 2v19.5a2 2 0 0 1-2 2H7.875a2 2 0 0 1-2-2v-19.5Zm18.375 10.29a2.61 2.61 0 1 0-1.5 0v3.377a.75.75 0 0 0 1.5 0v-3.378Z'
                    clipRule='evenodd' />
            </svg>
        );
    } else if (currentLevel > level) {
        ScoreText = '100%';
    }

    let handleQuizButton = () => props.handleTakeChallenge();

    // change the plurality of the button based on multiple attempts
    // or no attempts at all
    let buttonLabel = 'Locked';
    let buttonVariant = 'locked-dtc';
    let buttonSVG = <LockSVG />;
    if (currentLevel === level) {
        buttonLabel = attempts ? 'Continue' : 'Start';
        buttonVariant = 'active-dtc';
        buttonSVG = attempts ? <ContinueSVG /> : <StartSVG />;
        handleQuizButton = () => props.handleTakeChallenge(level);
    } else if (currentLevel > level) {
        buttonLabel = 'Retake';
        buttonVariant = 'review-dtc';
        buttonSVG = <RetakeSVG />;
        handleQuizButton = () => props.handleTakeChallenge(level);
    }

    // check if the current level is the same as the level
    // attempts could be 0 if they havent started the quiz which would be null in the data
    // change this to 0 so the copy will say 0 Attempts
    let attemptCopy = attempts === 1 ? 'Attempt' : 'Attempts';

    let attemptCount = attempts ?? 0;
    if (currentLevel === level) {
        if (attempts === null) {
            attemptCount = 0;
        }
    }

    // create copy for different levels if the challenge is locked
    let lockedLevelCopy;
    if (level > currentLevel) {
        if (level === 1 && props.parent) {
            lockedLevelCopy = 'Complete the Warm Up to unlock';
        }
        if (level === 2) {
            lockedLevelCopy = 'Complete the Silver challenge to unlock';
        }
        if (level === 3) {
            lockedLevelCopy = 'Complete the Gold challenge to unlock';
        }
    }
    const noEvent = (e) => {
        e.preventDefault();
    };
    // const [percentage, setPercentage] = useState(0);
    // React.useEffect( () => {
    //     const update = () => {
    //         setPercentage(value => value + 0.01);
    //         setTimeout(update, 100);
    //     };
    //
    //     setTimeout(update, 100);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    return (
        <div
            tw='bg-[#E6E2E6] flex-col justify-center items-center relative'
            css={[parent ? BorderGradientParent : BorderGradient]}
            data-testid={`record-plaque-${level}`}
        >
            <div tw='bg-[#1C0057] p-2' css={[parent && tw`h-[400px]`]}>
                <div tw='bg-[#E6E2E6] p-0.5 ' >
                    <div tw='bg-[#1C0057] ' css={[parent && tw`h-[380px]`]}>
                        <div tw='relative flex justify-center pt-6 px-6 md:px-8 lg:px-3 xl:px-14 items-center'>
                            <div tw='absolute z-10 text-2xl text-white font-bold'>
                                {ScoreText}
                            </div>
                            <div
                                tw=' max-w-[220px] max-h-[220px] md:max-w-[160px] md:max-h-[160px] lg:max-w-[180px] lg:max-h-[180px] xl:max-w-[200px] xl:max-h-[200px]'>
                                <RecordPercentage
                                    level={level}
                                    percentage={score / 100.0}
                                    locked={currentLevel < level}
                                    tw='w-full h-full relative'
                                />
                            </div>
                        </div>


                        <TitleBackground $level={level}>
                            <div tw='text-sm font-medium text-center '>
                                {levelText[level]}
                            </div>
                        </TitleBackground>

                        <DynamicText $variant={level > currentLevel}>
                            {attemptCount} {attemptCopy}
                        </DynamicText>

                        {!parent ?
                            <>
                                {currentLevel >= level ? (
                                    <div tw=' pt-4 xsm:pt-8 pb-6 flex justify-center items-center h-24'>
                                        <Button
                                            onClick={handleQuizButton}
                                            variant={buttonVariant}
                                            data-testid={`record-plaque-button-${level}`}
                                        >
                                            <div tw='flex items-center'>
                                                {buttonSVG}
                                                <div tw='ml-2'>
                                                    {buttonLabel}
                                                </div>

                                            </div>

                                        </Button>
                                    </div>
                                ) : (
                                    <div tw=' py-6 flex justify-center items-center h-24'>
                                        <Button
                                            onClick={noEvent}
                                            variant={buttonVariant}
                                            data-testid={`record-plaque-button-${level}`}
                                        >
                                            <div tw='flex items-center'>
                                                {buttonSVG}
                                                <div tw='ml-2'>
                                                    {buttonLabel}
                                                </div>

                                            </div>

                                        </Button>
                                    </div>
                                )}
                            </> : null

                        }
                    </div>
                </div>
            </div>
            {props.completions > 1 && (
                <span
                    tw='h-9 w-9 text-lg font-extrabold rounded-full bg-[#02AC6F] text-white flex justify-center items-center absolute top-4 right-4'>
                    {props.completions}X
                </span>
            )}

        </div>
    );
};

RecordPlaqueDTC.propTypes = {
    handleTakeChallenge: PropTypes.func,
    attempts: PropTypes.number,
    currentScore: PropTypes.number,
    level: PropTypes.number,
    currentLevel: PropTypes.number
};

export default RecordPlaqueDTC;
