import React from 'react';
import { SWRConfig } from 'swr';
import { apiFetcher } from 'utils/api-client';


const SWRProvider = (props) => {

    // const onError = (err, key, config) => {
    //     console.log('[useSWR] error: ', key, err);
    //     console.error(err);
    //     console.log(err);
    //     console.dir(err);
    // };
    //
    // const onSuccess = (data, key, config) => {
    //     console.log(`[useSWR] ${key} = `, data);
    // };

    // global options
    const options = {
        revalidateOnFocus: false,
        fetcher: apiFetcher
        // onSuccess: onSuccess,
        // onError: onError
    };

    return (
        <SWRConfig value={options}>
            {props.children}
        </SWRConfig>
    );
};

export default SWRProvider;
