import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { Provider as ReduxProvider } from 'react-redux';

import AuthProvider from 'context/Auth/AuthProvider.js';
import SWRProvider from 'context/SWRProvider';
// import { PlaylistStateProvider } from 'state/PlaylistState';
// import ErrorProvider from 'context/ErrorProvider';
// import PusherProvider from 'context/PusherProvider';
// import ClassState from 'state/ClassState';
// import CatalogState from 'state/CatalogState';
// import features from "features";
// import { FlagsProvider } from "utils/feature-flags";
import QueryProvider from 'context/QueryProvider';

import theme from 'app/theme/Theme';
import { ConditionalDevToolProvider } from 'lib/DevTool/DevTool';
import AnalyticsProvider from '../analytics/AnalyticsProvider';
import EventProvider from './EventProvider';
import ErrorProvider from './ErrorProvider';
import SiteProvider from './SiteProvider';
import BrowserTracker from './Auth/BrowserTracker';

/**
 * The provider for the entire app, authenticated and unauthenticated
 */
const AppProviders = ({ children, store }) => {
    return (
        <BrowserTracker>
            <ErrorProvider>
                <ConditionalDevToolProvider enable={false}>
                    <ReduxProvider store={store}>
                        <BrowserRouter>
                            <AnalyticsProvider>
                                <AuthProvider>
                                    <SWRProvider>
                                        <QueryProvider>
                                            <EventProvider>
                                                <ThemeProvider theme={theme}>
                                                    <SiteProvider>
                                                        {children}
                                                    </SiteProvider>
                                                </ThemeProvider>
                                            </EventProvider>
                                        </QueryProvider>
                                    </SWRProvider>
                                </AuthProvider>
                            </AnalyticsProvider>
                        </BrowserRouter>
                    </ReduxProvider>
                </ConditionalDevToolProvider>
            </ErrorProvider>
        </BrowserTracker>
    );
};

export default AppProviders;
