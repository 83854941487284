import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from 'twin.macro';
import { Modal } from 'components';
import tw from 'twin.macro';
import { useVideoStats, useVideoWithStats } from '../../../../state/Student/VideoStats';
import { useTeacher } from '../../../../state/User/UserState';
import QuizSession from './QuizSession';

const StyledModal = styled(Modal)`
    //&&& {
    //    width: 100%;
    //    height: 100%;
    //    background: green;
    //    box-shadow: none;
    //    overflow-y: scroll;
    //}
    /* width: 100%; */
`;

const QuizModal = (props) => {
    let show = props.show;
    // continue to show modal if the quiz session is finished
    if (props.quizSession && props.quizSession.state === 'quiz_completed') {
        show = true;
    }

    useEffect(() => {
        console.log('QuizModal did mount');
    }, []);
    return (
        <StyledModal open={show}>
            <QuizSession {...props} isModal={true} />
        </StyledModal>
    );
};

export default QuizModal;

// QuizModal hook
export function useQuizModal(props) {
    const { show, quiz_id } = props;
    let [isOpen, setOpen] = useState(show);

    // only allow the 'show' property to open the dialog,
    // once it is open, it can only be closed by itself
    // this prevents the dialog from being force closed
    // after the user completes the unlock challenge
    React.useEffect(() => {
        if (show) setOpen(show);
    }, [show]);

    // useEffect(() => {
    //     console.log('useQuizModal did mount');
    // }, []);

    const close = useCallback(() => setOpen(false), [setOpen]);

    const QuizModalWrapper = useMemo(() => {
        return (props) => {
            console.info('render QuizModalWrapper');
            return (
                <Modal isOpen={show} onClose={close}>
                    <QuizSession quiz_id={quiz_id} quiz_level={0} isModal={true} onClose={close} />
                </Modal>
            );
        };
    }, [close, quiz_id, show]);

    // return the Modal and a cancel method
    return [QuizModalWrapper, close];
}

const isVideoLocked = (video) => {
    // if (!video || !user)
    //     return false;
    // if (instructor)
    //     return false;
    if (video?.quiz_id) {
        return video.lesson_locked && (!video.stats || video.stats.current_level === 0);
    }
    return false;
};

// QuizModal hook for a locked video
export function useLockedVideoQuiz({ videoId, onCancel }) {
    const teacher = useTeacher();
    const video = useVideoWithStats(videoId);
    let [isOpen, setOpen] = useState(undefined);

    // console.info('[useLockedVideoQuiz] render', videoId);

    React.useEffect(() => {
        console.info('[useLockedVideoQuiz] Checking if video is locked', video);
        if (teacher) return;
        console.info('[useLockedVideoQuiz] is locked = ', isVideoLocked(video));
        if(isVideoLocked(video))
            setOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video, teacher]);

    // only allow the 'show' property to open the dialog,
    // once it is open, it can only be closed by itself
    // this prevents the dialog from being force closed
    // after the user completes the unlock challenge
    // React.useEffect(() => {
    //     if (show)
    //         setOpen(show);
    // }, [show]);

    // useEffect(() => {
    //     console.info('useQuizModal did mount');
    // }, []);

    const close = useCallback(() => {
        console.info('[useLockedVideoQuiz] close');
        setOpen(false);
    }, []);

    const QuizModalWrapper = useMemo(() => {
        return (props) => {
            // console.info('[useLockedVideoQuiz] render QuizModalWrapper', isOpen);
            // if (isOpen === undefined)
            //     return null;
            return (
                <Modal isOpen={isOpen} onClose={close} modalChildrenStyle={tw`width[100%]`}>
                    <QuizSession
                        quiz_id={video?.quiz_id}
                        quiz_level={0}
                        isModal={true}
                        onClose={close}
                        onCancel={onCancel}
                    />
                </Modal>
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // return the Modal and a cancel method
    return [QuizModalWrapper, close, isOpen];
}
