import React from 'react';

const ContinueSVG = () => {
    return (
        <svg
            width='14' height='12' viewBox='0 0 14 12' fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M8.39889 0.501985C8.51215 0.404939 8.64293 0.331772 8.78375 0.286665C8.92456 0.241558 9.07265 0.225397 9.21954 0.239104C9.36644 0.25281 9.50927 0.296117 9.63986 0.366548C9.77046 0.43698 9.88626 0.533155 9.98064 0.649577L13.7223 5.26183C13.8209 5.37755 13.8959 5.51235 13.943 5.65829C13.9901 5.80424 14.0083 5.95838 13.9965 6.11166C13.9848 6.26493 13.9433 6.41425 13.8745 6.55081C13.8057 6.68737 13.7111 6.80843 13.5961 6.90686C13.4811 7.00529 13.3481 7.0791 13.2049 7.12396C13.0617 7.16881 12.9112 7.1838 12.7623 7.16804C12.6134 7.15229 12.4691 7.1061 12.3378 7.03221C12.2066 6.95831 12.091 6.8582 11.998 6.73775L8.25519 2.1255C8.06472 1.8906 7.97287 1.58763 7.99981 1.28319C8.02676 0.978746 8.1703 0.697754 8.39889 0.501985Z' fill='#1C0057' />
            <path fillRule='evenodd' clipRule='evenodd' d='M8.39889 11.4983C8.1703 11.3025 8.02676 11.0215 7.99981 10.7171C7.97287 10.4127 8.06472 10.1097 8.25519 9.87479L11.998 5.26253C12.091 5.14209 12.2066 5.04198 12.3378 4.96808C12.4691 4.89418 12.6134 4.848 12.7623 4.83224C12.9112 4.81649 13.0617 4.83148 13.2049 4.87633C13.3481 4.92118 13.4811 4.995 13.5961 5.09343C13.7111 5.19186 13.8057 5.31291 13.8745 5.44948C13.9433 5.58604 13.9848 5.73535 13.9965 5.88863C14.0083 6.04191 13.9901 6.19605 13.943 6.342C13.8959 6.48794 13.8209 6.62274 13.7223 6.73846L9.98064 11.3507C9.88626 11.4671 9.77046 11.5633 9.63986 11.6337C9.50927 11.7042 9.36644 11.7475 9.21954 11.7612C9.07265 11.7749 8.92456 11.7587 8.78375 11.7136C8.64293 11.6685 8.51215 11.5953 8.39889 11.4983Z' fill='#1C0057' />
            <path fillRule='evenodd' clipRule='evenodd' d='M11.7375 5.99974C11.7375 6.30555 11.6193 6.59884 11.4087 6.81508C11.1982 7.03132 10.9127 7.15281 10.6149 7.15281L1.63405 7.15281C1.33632 7.15281 1.05078 7.03132 0.840247 6.81508C0.629717 6.59884 0.511442 6.30555 0.511442 5.99974C0.511442 5.69393 0.629717 5.40065 0.840247 5.1844C1.05078 4.96816 1.33632 4.84668 1.63405 4.84668L10.6149 4.84668C10.9127 4.84668 11.1982 4.96816 11.4087 5.1844C11.6193 5.40065 11.7375 5.69393 11.7375 5.99974Z' fill='#1C0057' />
        </svg>

    );
};

export default ContinueSVG;