import React, { useState } from 'react';
import 'twin.macro';
import { ChildInfoForm, ChildUserNameForm, CreateSecondChildDropDown, ParentLearningCategories } from 'components';
import { useClasses, useStudents } from 'state/Teacher/ClassState';
import { useAccount } from 'state/User/UserState.js';
import { useCreateChild } from './CreateChildProvider';

const STEP_USERNAME = 1;
const STEP_GRADE_LEVEL = 2;
const STEP_TOPICS = 3;

const CreatChildAccount = () => {
    const classes = useClasses();
    const students = useStudents();
    const account = useAccount();
    // context data for the children setup process
    const {
        childCount,
        childIndex,
        step,
        saveStep,
        saveChildCount,
        saveChildIndex,
        resetSetup,
        isSetup,
        selectedChild
    } = useCreateChild();

    // form data
    const [studentName, setStudentName] = useState('');
    const [studentId, setStudentId] = useState(null);
    const [noChildSelected, setNoChildSelection] = useState(null);
    const { address_zip: zipCode, address_state, student_limit: studentLimit } = account;

    React.useEffect(() => {
        console.log('MOUNT', step, students);
        if (isSetup && selectedChild) {
            console.log('setting up selectedChild', selectedChild);
            // this is continuing the setup process
        
            setStudentId(selectedChild.user.id);
            setStudentName(selectedChild.user.first_name === '' ? 'your child' : selectedChild.user.first_name);
        } else if (step !== STEP_USERNAME) {
            // if we are not on the first step (creating a new student), determine what student we are on
            if (students?.length > childIndex) {
                // if we have students, and the child index is valid, set the student name
                const student = students[childIndex];
                setStudentId(student.user.id);
                setStudentName(student.user.first_name === '' ? 'your child' : student.user.first_name);
            } else {
                // if we don't have students, or the child index is not valid, reset the setup
                console.log('Invalid child index, resetting setup');
                resetSetup();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, students]);

    const selectNumberOfChildren = (data) => {
        // console.log(data);
        saveChildCount(data.value);
    };

    let header = null;
    if (step === STEP_USERNAME)
        header = childIndex === 0 ? 'Create Your Child’s Account' : 'Create Your Second Child’s Account';
    if (step === STEP_GRADE_LEVEL)
        header = `To best guide ${studentName}’s learning on Muzology...`;
    if (step === STEP_TOPICS)
        return (
            <ParentLearningCategories
                onBoarding={true}
                onboardingStudentId={studentId}
                studentName={studentName}
                // which student this is
                studentCount={childCount}
                currentStudent={childIndex}
                // steps
                setStep={saveStep}
                resetSetup={resetSetup}
            // setShowTopicSelection={() => saveStep(STEP_TOPICS)}
            // setFirstStep={(first) => saveStep(first ? STEP_USERNAME : STEP_GRADE_LEVEL)}
            />
        );

    return (
        <div
            id='onboard-stage-create-child-account'
            data-testid='onboard-stage-create-child-account'
            tw='text-EarieBlack text-center px-2 sm:px-10 relative'
        >
            <div tw='text-3xl font-extrabold mt-10 mx-auto text-center max-w-[510px]'>
                {header}
            </div>

            {!isSetup && studentLimit > 1 && childIndex <= 0 && (step === STEP_USERNAME) && (
                <>
                    <div tw='font-light mx-auto max-w-[440px] text-center sm:text-left mt-3'>
                        With your annual subscription, you have two Child Accounts. <br />
                        How many accounts would you like to set up now? <br />
                        (You can always set up your second child account later.)
                    </div>
                    <div tw='mt-9 mx-auto flex justify-center'>
                        <CreateSecondChildDropDown
                            tw='max-w-[220px]'
                            onSelectItem={selectNumberOfChildren}
                            selectedValue={childCount}
                            setNoChildSelection={setNoChildSelection}
                        />


                    </div>
                    <div tw='mt-1 mx-auto flex justify-center'>
                        {noChildSelected && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                    </div>
                </>
            )}

            {(step === STEP_USERNAME) ?
                <ChildUserNameForm
                    classes={classes}
                    setStudentName={setStudentName}
                    setStudentId={setStudentId}
                    setFirstStep={(first) => saveStep(first ? STEP_USERNAME : STEP_GRADE_LEVEL)}
                    studentCount={childIndex + 1}
                    setNoChildSelection={setNoChildSelection}
                    childCount={childCount}
                    studentLimit={studentLimit}
                />
                :
                <ChildInfoForm
                    account={account}
                    defaultState={address_state}
                    classes={classes}
                    setChildren={saveChildCount}
                    studentName={studentName}
                    studentId={studentId}
                    addCount={saveChildIndex}
                    studentCount={childIndex}
                    twoChildren={childCount === 2 && childIndex < 1}
                    saveStep={saveStep}
                    setShowTopicSelection={() => saveStep(STEP_TOPICS)}
                />}

        </div>
    );
};

export default CreatChildAccount;
