import './string.js'; // attach pluralize methods to String

export { getCurrentTimestamp, getCurrentTimestampMS } from './time.js';

/**
 * Convert an immutablejs object to js object - this handles checking for null
 * @param {Object} obj - an immutable js object
 * @returns {Object} that object converted to javascript
 */

export const toJS = (obj) => {
    // console.log("toJS: ", obj);
    if (!obj) return null;
    if (!obj.toJS) {
        console.log('Warning: toJS called on invalid object: ', obj);
        return obj;
    }
    return obj && obj.toJS ? obj.toJS() : null;
};

export const compareStrings = (a, b, direction = 1) => {
    if (!a && !b) return 1;
    else if (!a) return direction ? -1 : 1;
    else if (!b) return direction ? 1 : -1;

    if (direction === 1) return a.toLowerCase().localeCompare(b.toLowerCase());
    else return b.toLowerCase().localeCompare(a.toLowerCase());
};

export const compareDates = (a, b) => {
    // return a < b;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export const compareNumbers = (a, b) => {
    const aa = a || 0;
    const bb = b || 0;
    if (aa < bb) return -1;
    if (aa > bb) return 1;
    return 0;
};

export const compareNumbersDesc = (a, b) => {
    const aa = a || 0;
    const bb = b || 0;
    if (aa < bb) return 1;
    if (aa > bb) return -1;
    return 0;
};

export function cleanString(input) {
    let output = '';
    if (input) {
        for (let i = 0; i < input.length; i++) {
            if (input.charCodeAt(i) <= 127) {
                output += input.charAt(i);
            }
        }
    }
    // console.log(input, output);
    return output;
}

export const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            // let cookie = jQuery.trim(cookies[i]);
            let cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// reduce a list to a map of objects by id
export const list_to_map_by_id = (item_map, item) => {
    item_map[item.id] = item;
    return item_map;
};
export const reduce_list_to_map = (list) => list.reduce(list_to_map_by_id, {});

// open a link in a new browser tab
export const openInNewTab = (url) => {
    window.open(url, '_blank');
};

export const openURL = (url) => {
    window.open(url);
};
