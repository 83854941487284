import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import RecordIcon from 'components/RecordIcon';

const PlaylistDetails = ({ playlistDetails }) => {
    return (
        <div tw='maxWidth[712px] mx-auto'>
            <div tw='text-EarieBlack text-lg font-extrabold flex w-full space-x-6'>
                <div tw='border-2 border-LavenderBlue flex flex-col space-y-2 items-center bg-white rounded-md w-full py-6'>
                    {/*
                    <div tw='flex space-x-2 '>
                        <h3>Start Date:</h3>
                        <span tw='font-medium'>{new Date(playlistDetails.startDate).toLocaleDateString()}</span>
                    </div>
                    */}
                    <div tw='flex flex-col xsm:flex-row space-x-0 xsm:space-x-2'>
                        <h3>Due Date:</h3>
                        <span tw='font-medium'>{new Date(playlistDetails.dueDate).toLocaleDateString()}</span>
                    </div>
                </div>
                <div tw='py-6 border-2 border-LavenderBlue flex justify-center items-center bg-white rounded-md w-full'>
                    <div tw='flex flex-col xsm:flex-row space-x-0 xsm:space-x-2'>
                        <h3>
                            Goal: <span tw='hidden xsm:inline'>{`${playlistDetails.goal}%`}</span>
                        </h3>
                        <div tw='flex xsm:block  '>
                            <div tw='w-8 h-8  xsm:block'>
                                <RecordIcon level={playlistDetails.level} />
                            </div>
                            <span tw='block xsm:hidden ml-1.5'>{`${playlistDetails.goal}%`}</span>

                        </div>
                        
                    </div>
                </div>
            </div>
            {playlistDetails.message && (
                <div tw='py-6 mt-6 border-2 border-LavenderBlue text-center bg-white rounded-md w-full'>
                    <h3 tw='text-EarieBlack text-lg font-extrabold'>Message:</h3>
                    <p>{playlistDetails.message}</p>
                </div>
            )}
        </div>
    );
};

PlaylistDetails.propTypes = {
    playlistDetails: PropTypes.object
};

export default PlaylistDetails;
