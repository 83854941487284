
// theme.js
const theme = {
    colors: {
        black: '#000e1a',
        white: '#fff',
        muz_pink: '#e637a8',
        indigo: '#473FBE',
        royalBlue: '#1E215F',
        oxfordBlue: '#070A3B'
    },
    gradients: {
        navBar: 'linear-gradient(180deg, #1E215F 0%, #070A3B 100%);',
        dark:'linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%)',
        bright: 'linear-gradient(93.18deg, #1CC8E1 1.16%, #D022ED 98.48%)'
    },
    breakpoints: {
        xs: '0',
        sm: '600px',
        md: '960px',
        lg: '1280px',
        xl: '1920px'
    },
    space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768],
    fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
    fontWeights: [200, 300, 400, 500, 600, 700, 800, 900],
    fonts: {
        body: 'Avenir, sans-serif'
    }
};

// font weights
theme.fontWeights.extraLight = theme.fontWeights[0]; // 200
theme.fontWeights.light = theme.fontWeights[1]; // 300
theme.fontWeights.regular = theme.fontWeights[2]; // 400
theme.fontWeights.medium = theme.fontWeights[3]; // 500
theme.fontWeights.heavy = theme.fontWeights[6]; // 700
theme.fontWeights.black = theme.fontWeights[7]; // 800

// font sizes
theme.fontSizes.tiny = theme.fontSizes[0]; // 12px
theme.fontSizes.small = theme.fontSizes[1]; // 14px
theme.fontSizes.base = theme.fontSizes[2]; // 16px
theme.fontSizes.medium = theme.fontSizes[3]; // 18px
theme.fontSizes.large = theme.fontSizes[4]; // 20px
theme.fontSizes.xlarge = theme.fontSizes[5]; // 24px
theme.fontSizes.xxlarge = theme.fontSizes[6]; // 30px
theme.fontSizes.xxxlarge = theme.fontSizes[7]; // 36px

// spacing used with margin and padding
theme.space.tiny = theme.space[1]; // 4px
theme.space.small = theme.space[2]; // 8px
theme.space.medium = theme.space[3]; // 12px
theme.space.base = theme.space[4]; // 16px
theme.space.large = theme.space[5]; // 24px
theme.space.xlarge = theme.space[6]; // 32px
theme.space.xxlarge = theme.space[7]; // 48px
theme.space.xxxlarge = theme.space[8]; // 64px


theme.space.baseVariant = 18;

export default theme;
