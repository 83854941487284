import React from 'react';

const SvgCategoryGeometry = (props) => (
    <svg
        viewBox='0 0 28 28'
        width='1em'
        height='1em'
        {...props}>
        <g transform='translate(-38 -788)'>
            <circle
                data-name='category_geometry'
                cx={14}
                cy={14}
                r={14}
                transform='translate(38 788)'
                fill='white' />
            <path
                data-name='Path 306'
                d='M45.131 794.159v14.1H60.1z'
                fill='#1E215' />
        </g>
    </svg>
);

export default SvgCategoryGeometry;
