import { Store } from 'react-notifications-component';

/**
 * type:
 *  "success", "default", "warning", "info", "danger"
 * @returns {Function}
 */

export const TOAST_DEFAULT = 'default';
export const TOAST_SUCCESS = 'success';
export const TOAST_DANGER = 'danger';
export const TOAST_INFO = 'info';
export const TOAST_WARNING = 'warning';

export function useToast() {
    // return a function to create a notification
    return (title, message, type = 'gradient', duration = 5000) => {
        if (!title || !message) {
            console.error('title and message required');
            return null;
        }
        if (!Store) {
            console.error('Notification Store not found');
            return null;
        }
        return Store.addNotification({
            title: title,
            message: message,
            type: type ?? 'default',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeInRight'],
            animationOut: ['animate__animated', 'animate__fadeOutDown'],
            showIcon: true,
            dismiss: {
                duration: duration,
                onScreen: true
            }
        });
    };
}
