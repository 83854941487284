import React from 'react';
import tw from 'twin.macro';

const DummyPlayer = (props) => {
    const {
        aspectRatio,
        video,
        onPlayerEvent,
        sources
    } = props;

    return (
        <div tw='w-full background[green] aspectRatio[16/9] text-white text-left whitespace-pre p-3 font-extrabold'>
            {JSON.stringify(sources, null, 4)}
        </div>
    );
};

export default DummyPlayer;
