import React from 'react';
import TimesTableFirstVideoImg from 'static/images/guidance/times-table-first-video.png';
import tw, { styled, css } from 'twin.macro';


const TimesTableFirstVideo = ({ image }) => {
    return (
        <>
            <div tw='absolute text-white text-2xl xl:text-3xl bottom-12 font-bold left-3 xl:left-5'>Pick a Times Table Video</div>
            <div tw='absolute text-white text-base tiny:text-xl xl:text-2xl bottom-4 font-medium left-3 xl:left-5'>Keep watching until you know it all.</div>
            <img src={TimesTableFirstVideoImg} alt={image} tw='h-full w-full object-cover' />
        </>
    );
};

export default TimesTableFirstVideo;