import React, { useMemo, useState } from 'react';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import { useVideos } from 'state/CatalogState';
import Select from 'react-select';
import RecordIcon from 'components/RecordIcon';
import { Button } from 'components';
import { createVideoScoresURL } from '../../hooks/useGradeExport';
import { openURL } from '../../utils';

function SortArrows(props) {
    return (
        <svg
            width={10} height={16} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.826 0l4.18 6.75H.646L4.827 0zM4.828 16L.648 9.25h8.36L4.828 16z' fill='#1A1717' />
        </svg>
    );
}

function SortArrowDown(props) {
    return (
        <svg
            width={10} height={7} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.828 7L.648.25h8.36L4.828 7z' fill='#1A1717' />
        </svg>
    );
}

function SortArrowUp(props) {
    return (
        <svg
            width={10} height={7} fill='none' xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M4.826 0l4.18 6.75H.646L4.827 0z' fill='#1A1717' />
        </svg>
    );
}

const Styles = styled.div`
    .wrapper {
        margin: 0 auto;
        max-width: 1300px;
    }

    table {
        width: 100%;
        border-spacing: 0px;
        background: rgba(42, 38, 77, 0.5);
    }

    thead {
        tr {
            &:nth-child(1) {
                th:not(:nth-child(1)) {
                    background: linear-gradient(91.31deg, #1cc8e1 0.89%, #0ec86f 98.12%);
                    font-family: 'Avenir Medium', sans-serif;
                }

                th:nth-child(2n) {
                    background: linear-gradient(93.96deg, #867ef9 19.73%, #f059bb 73.17%);
                    font-family: 'Avenir Medium', sans-serif;
                }
            }

            th {
                text-align: center;
                padding: 1rem;
                color: white;
            }
        }
    }

    thead {
        tr {
            &:first-of-type {
                th {
                    font-size: 18px;
                    font-weight: 800;
                }
            }

            &:nth-child(1) {
                font-size: 18px;

                th:not(:nth-child(1)) {
                    background: #c7c7ff;
                    font-family: 'Avenir Medium', sans-serif;
                    padding: 0;
                }
            }

            &:nth-child(2) {
                background: white;
                border-right: 2px solid #5a5353;
                border-bottom: 2px solid #5a5353;
            }

            th {
                text-align: center;
                font-weight: 500;
                font-size: 14px;
                padding: 1rem;
                color: #1a1717;

                position: relative;

                svg {
                    display: inline;
                }

                &:nth-of-type(1) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-of-type(2) {
                    /* background: white; */
                    /* border-right: 2px solid #5a5353; */
                    /* border-bottom: 2px solid #5a5353;  */
                }

                &:nth-of-type(3) {
                    background: white;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(4) {
                    background: #d6dfe3;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(5) {
                    background: #d6dfe3;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(6) {
                    background: #ecd9b4;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(7) {
                    background: #ecd9b4;
                    border-right: 2px solid #5a5353;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(8) {
                    background: #9de2ee;
                    border-bottom: 2px solid #5a5353;
                }

                &:nth-of-type(9) {
                    background: #9de2ee;
                    border-bottom: 2px solid #5a5353;
                    border-right: none;
                }
            }
        }
    }

    tbody {
        tr {
            background: #ebebff;

            td:nth-child(1) {
                border-right: none;
                background: #f1f4fd;
                border-right: 2px solid #5a5353;
            }

            &:nth-child(2n) {
                background: #d7d7ff;

                td:nth-child(1) {
                    background: #6160d9;
                }

                /* td:nth-child(n + 4) {
                    background: #d4dbee;
                } */
                /* td:nth-child(n + 6) {
                    background: #ebd7b0;
                }
                td:nth-child(n + 8) {
                    background: #9de2ee;
                } */
            }

            td {
                padding: 1.15rem;
                text-align: center;

                &:nth-child(1) {
                    background: #7776f4;
                    color: white;
                    padding: 0 3.25rem;
                }

                &:nth-child(3) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-child(5) {
                    border-right: 2px solid #5a5353;
                }

                &:nth-child(7) {
                    border-right: 2px solid #5a5353;
                }
            }
        }
    }
`;

const VideoGradebook = ({ students }) => {
    const videos = useVideos();

    const items = videos.map((video) => {
        return {
            id: video.id,
            value: video.title,
            label: video.title
        };
    });
    let sortedItems = items.sort((a, b) => a.label?.localeCompare(b.label));
    const [selectedVideo, setSelectedVideo] = useState(items?.length ? items[0].id : null);

    const columns = useMemo(
        () => {

            const getVideoStats = (d) => {
                if( d?.video_stats )
                    return d.video_stats[selectedVideo];
                return null;
            };

            return [
                {
                    Header: 'Students',
                    accessor: (d) => d.user.full_name,
                    canSort: true
                },
                {
                    Header: "What's in a Fraction?",
                    canSort: false,
                    columns: [
                        {
                            Header: 'Views',
                            accessor: (d) => getVideoStats(d)?.view_count ?? 0
                        },
                        {
                            Header: 'Warm-Up',
                            accessor: (d) =>
                                getVideoStats(d)?.quiz_levels[0]?.score ? `${getVideoStats(d)?.quiz_levels[0]?.score}%` : ''
                        },
                        {
                            Header: 'Attempts',
                            id: 'Silver Attempts',

                            accessor: (d) => getVideoStats(d)?.quiz_levels[1]?.attempts ?? ''
                        },
                        {
                            Header: 'Silver Score',
                            accessor: (d) => getVideoStats(d)?.quiz_levels[1]?.score,
                            Cell: ({ row }) => {
                                return (
                                    <div tw='flex space-x-2 items-center justify-end'>
                                        {getVideoStats(row?.original)?.quiz_levels[1]?.score ? (
                                            <>
                                                <span>{`${getVideoStats(row?.original)?.quiz_levels[1]?.score}%`}</span>
                                                {getVideoStats(row?.original)?.quiz_levels[1]?.score === 100 ? (
                                                    <div tw='h-6 w-6'>
                                                        <RecordIcon level={1} />
                                                    </div>
                                                ) : (
                                                    <div tw='background[#D6DFE3] h-6 w-6 rounded-full' />
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                );
                            }
                        },
                        {
                            Header: 'Attempts',
                            id: 'Gold Attempts',
                            accessor: (d) => getVideoStats(d)?.quiz_levels[2]?.attempts ?? ''
                        },
                        {
                            Header: 'Gold Score',
                            accessor: (d) => getVideoStats(d)?.quiz_levels[2]?.score,
                            Cell: ({ row }) => {
                                return (
                                    <div tw='flex space-x-2 items-center justify-end'>
                                        {getVideoStats(row?.original)?.quiz_levels[2]?.score ? (
                                            <>
                                                <span>{`${getVideoStats(row?.original)?.quiz_levels[2]?.score}%`}</span>
                                                {getVideoStats(row?.original)?.quiz_levels[2]?.score === 100 ? (
                                                    <div tw='h-6 w-6'>
                                                        <RecordIcon level={2} />
                                                    </div>
                                                ) : (
                                                    <div tw='background[#FA9A00] h-6 w-6 rounded-full' />
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                );
                            }
                        },
                        {
                            Header: 'Attempts',
                            id: 'Plat Attempts',

                            accessor: (d) => getVideoStats(d)?.quiz_levels[3]?.attempts ?? ''
                        },
                        {
                            Header: 'Plat. Score',
                            accessor: (d) => getVideoStats(d)?.quiz_levels[3]?.score,
                            Cell: ({ row }) => {
                                return (
                                    <div tw='flex space-x-2 items-center justify-end'>
                                        {getVideoStats(row?.original)?.quiz_levels[3]?.score ? (
                                            <>
                                                <span>{`${getVideoStats(row?.original)?.quiz_levels[3]?.score}%`}</span>
                                                {getVideoStats(row?.original)?.quiz_levels[3]?.score === 100 ? (
                                                    <div tw='h-6 w-6'>
                                                        <RecordIcon level={3} />
                                                    </div>
                                                ) : (
                                                    <div tw='background[#9DE2EE] h-6 w-6 rounded-full' />
                                                )}
                                            </>
                                        ) : null}
                                    </div>
                                );
                            }
                        }
                    ]
                }
            ];
        },
        [selectedVideo]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: students
        },
        useFilters,
        useGlobalFilter,
        useSortBy
    );

    function renderArrows(column) {
        if (column.canSort) {
            return column.isSorted ? column.isSortedDesc ? <SortArrowDown tw='ml-2' /> : <SortArrowUp tw='ml-2' /> : <SortArrows tw='ml-2' id='classes-tour-video-grades-arrows' />;
        }
    }

    return (
        <Styles>
            <div className='wrapper'>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => {
                                    return (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.columns ? (
                                                <div tw='flex'>
                                                    <div tw='w-1/3' />
                                                    <div tw='w-1/3 mx-auto' id='classes-tour-video-grades-dropdown'>
                                                        <Select
                                                            className='basic-single'
                                                            classNamePrefix='select'
                                                            isSearchable
                                                            name='color'
                                                            onChange={({ id }) => setSelectedVideo(id)}
                                                            defaultValue={items[0]}
                                                            options={sortedItems}
                                                        />
                                                    </div>
                                                    <div tw='w-1/3 flex justify-end' >
                                                        <Button
                                                            variant='teal-large'
                                                            tw='mr-2 py-1.5'
                                                            onClick={() => openURL(createVideoScoresURL(selectedVideo))}
                                                            id='classes-tour-video-grades-export'
                                                        >
                                                        EXPORT GRADES
                                                        </Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                column.render('Header')
                                            )}
                                            {renderArrows(column)}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => {
                                        return <td {...cell.getCellProps()} id={ index === 0 && 'classes-tour-video-grades-student'}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </Styles>
    );
};

VideoGradebook.propTypes = {
    students: PropTypes.array
};

export default VideoGradebook;
