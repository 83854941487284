import React, { useState } from 'react';
import tw, { styled, css } from 'twin.macro';
import { RecordPlaqueDTC } from 'components';
import { useStudentStats } from 'state/Student/VideoStats';
import { AnswerKeyModal } from 'components/AnswerKey/AnswerKey';

const MaxWidthWrapper = styled.div`
  max-width: 290px;
  @media screen and (max-width: 1300px) {

  }
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 275px;
  }
`;

const ScrollBarStyle = css`

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
`;

const ParentRecordNullStateMessage = styled.div`
  background: rgba(117, 105, 214, 0.5);
  text-align: left;
  margin-top: 17px;
  border-radius: 20px;
  padding: 27px 40px;
  font-family: Avenir, serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
`;

const levelTitles = ['', 'Silver', 'Gold', 'Platinum'];

const createAssignmentData = (currentLevel, stats) => {
    return [1, 2, 3].map((level) => {
        let score = null;
        let unlocked;
        if (level < currentLevel) {
            // this level is completed
            score = 100;
            unlocked = true;
        }
        else if (level > currentLevel) {
            // this level is locked
            unlocked = false;
        }
        else if (level === currentLevel) {
            // this level is current
            score = stats?.current_score ?? 0;
            unlocked = true;
        } else {
            // currentLevel is 0, all levels are locked (we shouldn't even see this component if the video is locked)
            unlocked = false;
        }
        // attempts
        let attempts = null;
        let completions = null;
        if (stats?.quiz_levels) {
            if (stats?.quiz_levels[level]) {
                attempts = stats.quiz_levels[level].attempts;
                completions = stats.quiz_levels[level].completions + 1;
            }
        }

        return {
            title: levelTitles[level],
            level: level,
            score: score,
            unlocked: unlocked,
            currentLevel: currentLevel,
            currentScore: score,
            attempts: attempts,
            completions: completions
        };
    });

};

const AssignmentProgressContainer = (props) => {
    const { video, handleTakeChallenge, instructor, parent, selectedStudent, setSelectedStudent, listBoxItems } = props;
    const [showModal, setShowModal] = useState(false);
    const [answerKeyLevel, setLevel] = useState(0);
    const selectedStudentStats = useStudentStats(selectedStudent?.id ?? null);
    const videoId = video.id;
    const recordsTitle = parent ? ` ${selectedStudent?.item?.user?.first_name}'s Records` : 'My Records';
    if (!video) return null;

    let assignmentData = [];
    let currentLevel = 0;
    if (video.stats) {
        currentLevel = video.stats?.current_level ?? 0;
    }

    // child
    if (!instructor) {
        assignmentData = createAssignmentData(currentLevel, video.stats);
    }

    // parents
    if (parent && selectedStudentStats) {
        let selectedStatsWithVideo = selectedStudentStats.find((stat) => stat.video_id === videoId);
        if (selectedStatsWithVideo) {
            currentLevel = selectedStatsWithVideo.current_level ?? 0;
        }
        // construct the plaque data for this video and level
        assignmentData = createAssignmentData(currentLevel, selectedStatsWithVideo);
    }

    const progressNodes = assignmentData.map((item, index) => {
        return (
            <div tw=' mr-8 lg:mr-0' key={item.title}>
                <MaxWidthWrapper key={index}>
                    <RecordPlaqueDTC
                        key={item.title}
                        title={item.title}
                        level={item.level}
                        score={item.score}
                        currentLevel={item.currentLevel}
                        currentScore={item.currentScore}
                        completions={item.completions}
                        attempts={item.attempts}
                        unlocked={item.unlocked}
                        handleTakeChallenge={handleTakeChallenge}
                        parent={parent}
                        setShowModal={setShowModal}
                        setLevel={setLevel}
                    />
                </MaxWidthWrapper>
            </div>
        );
    });

    return (

        <>
            <div tw='pt-7 hidden lg:block'>
                <div tw='bg-[#3E257A] text-white px-4 xl:px-9 pt-6 pb-9 rounded-lg'>
                    <div tw='text-xl font-semibold'>{recordsTitle}</div>
                    <div tw='flex flex-col lg:flex-row justify-between items-center mt-5 gap-2 xl:gap-4'>{progressNodes}</div>

                </div>
            </div>

            <div tw='lg:hidden'>
                <div tw='bg-[#3E257A] text-white px-0 pt-6 pb-9 rounded-lg md:mt-24'>
                    <div tw='text-xl font-semibold ml-6'>{recordsTitle}</div>
                    <div
                        tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-6' css={[ScrollBarStyle]}>{progressNodes}</div>
                </div>
            </div>
            <AnswerKeyModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                video={video}
                level={answerKeyLevel}
                parent={parent}
            />
        </>
    );
};

export default AssignmentProgressContainer;
