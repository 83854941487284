import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';

//bg-red-400 sm:bg-green-400
const NullContainer = ({ copy, linkCopy, children, style, className }) => {
    return (
        <div
            id='null-state-message'
            tw='bg-NullStateBackground flex flex-col items-center justify-center minHeight[400px] w-full h-full rounded-2xl py-8'
            style={style}
            className={className}
        >
            <div tw='width[75%] sm:maxWidth[100%] m-auto text-white text-base sm:text-2xl font-medium'>
                {copy && <div tw='text-center text-white text-lg sm:text-2xl'>{copy}</div>}
                {linkCopy}
                {children}
            </div>
        </div>
    );
};

NullContainer.propTypes = {
    copy: PropTypes.string
};

export const NullMessage = ({ children, ...props }) => {
    return (
        <NullContainer
            tw='text-white text-base sm:text-2xl font-medium'
            {...props}
        >
            <div tw='flex flex-col justify-around [minHeight:260px]'>
                {children}
            </div>
        </NullContainer>
    );
};

export default NullContainer;
