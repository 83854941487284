import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { styled } from 'twin.macro';

// If anyone wants to take a shot at trying to get the gradient border
// along with the arrow in the pop up it is pretty close below
// const PopUpCss = css`
//     width: 260px;
//     position: relative;
//     &:before {
//         top: 100%;
//         left: 50%;
//         border: solid transparent;
//         content: ' ';
//         height: 0;
//         width: 0;
//         position: absolute;
//         pointer-events: none;
//     }

//     &:after {
//         border-top-color: #2a264d;
//         border-width: 10px;
//         margin-left: -20px;
//     }

//     &:before {
//         border-top-color: #2a264d;
//         border-width: 10px;
//         margin-left: -23px;
//     }
// `;

// const WrapperCss = css`
//     max-width: 280px;
//     position: relative;
//     background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
//     padding: 4px;
//     border-radius: 0.5rem;
// `;

// const HoverBox = ({ show, title, description }) => {
//     if (!show) return null;
//     return (
//         <div tw='absolute -top-44 -right-32'>
//             <div css={[WrapperCss]}>
//                 <div tw='bg-DarkBlue font-sans px-4 py-8' css={[PopUpCss]}>
//                     <div tw='text-lg text-white font-black'>{title}</div>

//                     <div tw='text-lg text-white mt-1'>{description}</div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// HoverBox.propTypes = {
//     show: PropTypes.bool,
//     title: PropTypes.string,
//     description: PropTypes.string
// };

// export default HoverBox;

const PopUpStyle = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: absolute;
    bottom: 150px;
    left: -20px;
    background: ${(props) => props.backgroundColor || '#fff'};
    border: 2px solid #1ac1dd;
    height: ${(props) => props.height || '130px'};
    width: 255px;
    transition: opacity 0.3s;
    z-index: 10;
    border-radius: 5px;
    &:after,
    &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(97, 181, 231, 0);
        border-top-color: ${(props) => props.backgroundColor || '#fff'};
        border-width: 10px;
        margin-left: -20px;
    }

    &:before {
        border-color: rgba(34, 34, 34, 0);
        border-top-color: #61b5e7;
        border-width: 13px;
        margin-left: -23px;
    }
`;

const HoverBox = ({ show, backgroundColor, height, children }) => {
    if (!show) return null;
    return (
        <PopUpStyle backgroundColor={backgroundColor} height={height}>
            {children}
        </PopUpStyle>
    );
};

HoverBox.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string
};

export default HoverBox;
