import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { useQuery } from 'hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuizSession } from 'hooks/quiz';
import { useVideoStats } from 'state/Student/VideoStats';
import { useQuizVideo } from 'state/CatalogState';
import { LoadingOverlay } from '../../../../lib/components/loading';
import { useDevTool } from '../../../../lib/DevTool/DevTool';
import { useQuizSessionActions } from '../../../../state/Quizzing/QuizSessionProvider';
import QuizSessionContainer from './QuizSessionContainer';

// Route: /quiz/<quiz_id>?level=<level>&video_id=<video_id>
//  'quiz_id' is the quiz ID
//  'level' is the level of the quiz
//  'video_id' is the video ID
const QuizSession = (props) => {
    /**
     *  The QuizSession is the root component for the quiz session.
     *  It is responsible for initializing the quiz session and loading the quiz session data.
     *      It uses the url and query string to determine the quiz_id and level.
     *      It then validates the level against the current level in the video stats.
     *      It then initializes the quiz session.
     *
     */
    // console.log('[QuizSession] props', props);

    const params = useParams();
    const query = useQuery();

    // const [quizId, setQuizId] = useState(props.quiz_id ?? params.quiz_id);
    const quizId = props.quiz_id ?? params.quiz_id;
    const [quizLevel, setQuizLevel] = useState(props.quiz_level ?? query.level ?? null);

    console.log('[QuizSession] quizId', quizId, 'quizLevel', quizLevel);

    // get the video for this quiz
    const video = useQuizVideo(quizId, query.video_id);
    console.log('[QuizSession] video', video);

    // init the quiz session when selections change
    const stats = useVideoStats(video?.id);

    // get the quiz session
    const { store } = useQuizSession();
    const { initQuizSession } = useQuizSessionActions();
    useDevTool('_Quiz Root', store);

    // if we don't have a level - use the video stats
    useEffect(() => {
        if (stats && quizLevel === null && stats.current_level >= 0) {
            console.log('Quiz: no level - using current level');
            setQuizLevel(stats.current_level);
        } else {
            console.log('Quiz: using level from url: ', quizLevel);
        }
    }, [quizLevel, stats]);

    // call initQuizSession & load the current stats
    useEffect(() => {
        // get the quiz_id from the url & the level from the query string
        // let quiz_id = props.quiz_id ?? params.quiz_id ?? null;
        // let quiz_level = props.quiz_level ?? query.level ?? null;
        // let level = parseInt(quiz_level);
        if (quizId) {
            console.log('initializing quiz level:', quizLevel);
            initQuizSession(quizId, quizLevel)
                .then((response) => {
                    console.log('Quiz Session Initialized', response);
                    // check the instance here to see if this is a migrated quiz
                    const { payload } = response;
                    // const { quiz_id, instance } = payload;
                    console.log('[QuizSession Init] initialized:', payload);

                    // if( payload.quiz_id && payload.quiz_id !== quizId ) {
                    //     // this is a migrated quiz, navigate to the new quiz
                    //     console.log('QuizSession: init returned a different quiz id, migrating to an old quiz', payload.quiz_id, payload.level);
                    //     const video_id= video?.id ?? query.video_id;
                    //     navigate(`/quiz/${payload.quiz_id}/?level=${payload.level}&video_id=${video_id}`);
                    // }

                    // check if the instance is different from the quiz we are loading
                    const current_instance = payload.instance?.instance;
                    if (current_instance) {
                        console.log('[QuizSession Init] Checking returned instance', current_instance);
                        const real_level = current_instance.level;
                        const real_quiz_id = current_instance.quiz_id;
                        if (real_quiz_id && real_quiz_id !== quizId) {
                            // this is a migrated quiz, navigate to the new quiz
                            console.log('QuizSession: migrating to new quiz', real_quiz_id);
                            const video_id = video?.id ?? query.video_id;
                            navigate(`/quiz/${real_quiz_id}/?level=${real_level}&video_id=${video_id}`, { replace: true });
                        }
                    }

                });
        }
        // console.log('QuizSessionContainer: initialization', quiz_id, 'quiz_level=', quiz_level, 'level=', level);
        // if (quiz_id !== selectedQuizId || quiz_level !== selectedQuizLevel) {
        //     console.log('QuizSessionContainer: Initializing quiz session with ID and level: ', quiz_id, quiz_level, level);
        //     initQuizSession(quiz_id, true, quiz_level);
        //
        //     setSelectedQuizId(quiz_id);
        //     setSelectedQuizLevel(quiz_level);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check for a migration quiz
    const navigate = useNavigate();

    /*
    React.useEffect( () => {
        console.log('QuizSession: checking for migration');
        const current_instance = store?.session?.instance?.instance;
        if( current_instance ) {
            console.log('returned instance', current_instance);
            const real_level = current_instance.level;
            const real_quiz_id = current_instance.quiz_id;
            if( real_quiz_id && real_quiz_id !== quizId ) {
                // this is a migrated quiz, navigate to the new quiz
                console.log('QuizSession: migrating to new quiz', real_quiz_id);
                const video_id= video?.id ?? query.video_id;
                navigate(`/quiz/${real_quiz_id}/?level=${real_level}&video_id=${video_id}`);
            }
        }

    }, [navigate, query.video_id, quizId, quizLevel, store?.session?.instance?.instance, store.session?.instance?.instance?.quiz_id, video?.id]);
    */

    // if (stats === undefined) {
    //     console.log('[QuizSession] loading stats');
    //     return '[QuizSession] loading stats';
    // }


    if (quizLevel === null || !quizId) {
        console.log('[QuizSession] loading', quizId, quizLevel, stats);
        // return `[QuizSession] loading ${quizId} ${quizLevel} ${stats}`;
        return (
            <div tw='w-full h-full bg-[red] min-h-[200px]'>
                <LoadingOverlay show={true} fixed={true} background='#351076'/>
            </div>
        );

    }

    // console.log('[QuizSession] loaded', quizId, quizLevel, stats);
    // create the quiz session
    return (
        <QuizSessionContainer
            {...props}
            video={video}
            video_id={video?.id}
            quiz_id={quizId}
            quiz_level={quizLevel}
        />
    );
};

export default QuizSession;