import React, { useRef, useState } from 'react';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';

import CSRFToken from '../../lib/components/csrftoken';

import Modal from '../Modal/Modal.js';
import Button from '../Button/Button.js';
import FormError from '../FormError/FormError.js';
import CloseButton from '../AnswerKey/CloseButton';
import { changeStudentPassword } from '../../state/Teacher/StudentSettings';
import { useToast } from 'hooks/toast';

const ChangeStudentPasswordDialog = ({ studentId, open, onClose }) => {
    const { register, handleSubmit, watch, errors: formErrors } = useForm();
    const [submitErrors, setSubmitErrors] = useState({});
    const addToast = useToast('Success!');
    const password = useRef({});
    password.current = watch('password', '');

    const handleResetPassword = (data) => {
        console.log('reset password', data);
        const { confirmPassword } = data;
        setSubmitErrors({});
        if (studentId) {
            changeStudentPassword(studentId, confirmPassword, addToast)
                .then((response) => {
                    console.log('response', response);
                    onClose();
                    // onClose();
                })
                .catch((error) => {
                    console.log('error', error);
                    setSubmitErrors(error);
                    // setSubmitErrors(error);
                });
        }
        // setShowModal(false);
        // setResetPassword(false);
    };


    return (
        <Modal
            isOpen={open}
            setIsOpen={(value) => {
                if (!value)
                    onClose();
                // setShowModal(value);
                // setDeleteStudentModal(value);
                // setResetPassword(value);
            }}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            <div tw='relative bg-white rounded flex-col justify-center px-7 ' style={{ minWidth: '800px' }}>
                <div tw='text-EarieBlack text-2xl pt-6'>Reset Student Password</div>
                <form onSubmit={handleSubmit(handleResetPassword)}>
                    <CSRFToken />
                    <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Create Password *</div>
                    <input
                        autoFocus={true}
                        tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                        type='password'
                        name='password'
                        ref={register({
                            required: true,
                            minLength: { value: 6, message: 'Password must have at least 6 characters' }
                        })}
                    />
                    {FormError(formErrors.password)}

                    <div tw='mt-5 mb-2 uppercase font-medium text-sm text-EarieBlack'>Confirm Password *</div>
                    <input
                        tw='rounded py-2 px-3 bg-GainsboroGrey text-EarieBlack h-12 w-full'
                        type='password'
                        name='confirmPassword'
                        ref={register({
                            validate: (value) => value === password.current || 'The passwords do not match'
                        })}
                    />
                    {FormError(formErrors.confirmPassword)}
                    <div tw='flex justify-end space-x-2 pt-4 pb-10'>
                        <Button type='submit'>Submit</Button>
                    </div>
                </form>

                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={() => {
                        onClose();
                    }}
                />
            </div>
        </Modal>
    );
};

export default ChangeStudentPasswordDialog;
