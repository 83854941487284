import React from 'react';

const MXPSVGSmall = ({ className }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='none'
            viewBox='0 0 20 20'
            className={className}
        >
            <circle cx='10.055' cy='10.445' r='9.555' fill='#D36B18' />
            <circle cx='10.055' cy='9.555' r='9.555' fill='#D36B18' />
            <mask
                id='a' width='20' height='20' x='0'
                y='0' maskUnits='userSpaceOnUse'>
                <circle cx='10.055' cy='9.555' r='9.555' fill='#F0B84E' />
            </mask>
            <g mask='url(#a)'>
                <circle cx='11' cy='10.5' r='8.5' fill='#F0B84E' />
            </g>
            <path
                fill='#FADF58' fillRule='evenodd' d='M10.055 17.595a8.04 8.04 0 1 0 0-16.08 8.04 8.04 0 0 0 0 16.08Zm0 1.514a9.555 9.555 0 1 0 0-19.11 9.555 9.555 0 0 0 0 19.11Z'
                clipRule='evenodd' />
            <g fill='#D36B18' fillRule='evenodd' clipRule='evenodd' filter='url(#b)'>
                <path
                    d='M5.5 7.458v3.74c0 .166.137.3.307.3.17 0 .307-.134.307-.3v-3.74c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3ZM8.227 7.903v6.445c0 .166.137.3.307.3.17 0 .307-.134.307-.3V7.903c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3ZM6.872 6.497v6.444c0 .166.138.3.307.3.17 0 .307-.134.307-.3V6.497c0-.166-.137-.3-.307-.3a.304.304 0 0 0-.307.3ZM13.886 7.458v3.74c0 .166.138.3.307.3.17 0 .307-.134.307-.3v-3.74c0-.166-.137-.3-.307-.3a.304.304 0 0 0-.307.3ZM12.796 6a.304.304 0 0 0-.307.3v6.445c0 .166.137.3.306.3.17 0 .307-.134.307-.3V6.301c0-.166-.137-.301-.306-.301ZM11.16 7.903v6.445c0 .166.136.3.306.3.17 0 .307-.134.307-.3V7.903c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3ZM9.693 9.44v6.444c0 .165.136.299.304.3h.006a.304.304 0 0 0 .304-.3V9.439c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
            </g>
            <g fillRule='evenodd' clipRule='evenodd' filter='url(#c)'>
                <path fill='url(#d)' d='M5.5 6.458v3.74c0 .166.137.3.307.3.17 0 .307-.134.307-.3v-3.74c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
                <path fill='url(#e)' d='M8.227 6.903v6.445c0 .166.137.3.307.3.17 0 .307-.134.307-.3V6.903c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
                <path fill='url(#f)' d='M6.872 5.497v6.444c0 .166.138.3.307.3.17 0 .307-.134.307-.3V5.497c0-.166-.137-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
                <path fill='url(#g)' d='M13.886 6.458v3.74c0 .166.138.3.307.3.17 0 .307-.134.307-.3v-3.74c0-.166-.137-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
                <path fill='url(#h)' d='M12.796 5a.304.304 0 0 0-.307.3v6.445c0 .166.137.3.306.3.17 0 .307-.134.307-.3V5.301c0-.166-.137-.301-.306-.301Z' />
                <path fill='url(#i)' d='M11.16 6.903v6.445c0 .166.136.3.306.3.17 0 .307-.134.307-.3V6.903c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
                <path fill='url(#j)' d='M9.693 8.44v6.444c0 .165.136.299.304.3h.006a.304.304 0 0 0 .304-.3V8.439c0-.166-.138-.3-.307-.3a.304.304 0 0 0-.307.3Z' />
            </g>
            <defs>
                <linearGradient
                    id='d' x1='5.728' x2='9.494' y1='2.935'
                    y2='8.06' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='e' x1='8.455' x2='14.762' y1='1.373'
                    y2='6.66' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='f' x1='7.1' x2='13.407' y1='-.034'
                    y2='5.253' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='g' x1='14.114' x2='17.881' y1='2.935'
                    y2='8.06' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='h' x1='12.716' x2='19.024' y1='-.23'
                    y2='5.057' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='i' x1='11.387' x2='17.694' y1='1.373'
                    y2='6.66' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <linearGradient
                    id='j' x1='9.921' x2='16.228' y1='2.909'
                    y2='8.196' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#FEC424' />
                    <stop offset='1' stopColor='#FDF660' />
                </linearGradient>
                <filter
                    id='b' width='9.72' height='10.904' x='5.14'
                    y='6' colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                    <feOffset dy='.36' />
                    <feGaussianBlur stdDeviation='.18' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix values='0 0 0 0 0.7125 0 0 0 0 0.695986 0 0 0 0 0.299844 0 0 0 0.38 0' />
                    <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_216_3520' />
                    <feBlend in='SourceGraphic' in2='effect1_dropShadow_216_3520' result='shape' />
                </filter>
                <filter
                    id='c' width='9.72' height='10.904' x='5.14'
                    y='5' colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                    <feOffset dy='.36' />
                    <feGaussianBlur stdDeviation='.18' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix values='0 0 0 0 0.7125 0 0 0 0 0.695986 0 0 0 0 0.299844 0 0 0 0.38 0' />
                    <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_216_3520' />
                    <feBlend in='SourceGraphic' in2='effect1_dropShadow_216_3520' result='shape' />
                </filter>
            </defs>
        </svg>
    );
};

export default MXPSVGSmall;

