// selectors
import { List } from 'immutable';
import { createSelector } from 'reselect';
import { toJS } from 'utils';

//the quiz
export const getQuiz = (state, quiz_id) => state.getIn(['quiz', 'quizzes', quiz_id], null);

// session
export const getQuizSession = (state) => state.getIn(['quiz', 'session'], null);

export const getQuizState = (state) => state.getIn(['quiz', 'session', 'state'], null);
export const getQuizID = (state) => state.getIn(['quiz', 'session', 'quiz_id'], null);
export const getQuizLevel = (state) => state.getIn(['quiz', 'session', 'level'], null);

// current / previous quiz instance/response
export const getQuizInstance = (state) => state.getIn(['quiz', 'session', 'instance'], null);
export const getQuizInstanceQuestions = (state) => state.getIn(['quiz', 'session', 'instance', 'instance', 'questions'], null);

// running context
export const getQuizContext = (state) => state.getIn(['quiz', 'session', 'context'], null);

// ui state
export const getButtonStates = (state) => state.getIn(['quiz', 'session', 'context', 'button_state'], List()).toJS();
export const getButtonState = (state, button) => state.getIn(['quiz', 'session', 'context', 'button_state', button], 0);
export const getQuizProgress = (state) => state.getIn(['quiz', 'session', 'context', 'progress'], List());
export const getQuizWaiting = (state) => state.getIn(['quiz', 'session', 'context', 'waiting'], null);

export const getFreeEntryText = (state) => state.getIn(['quiz', 'session', 'context', 'response', 'free_entry'], null);
export const freeEntryTextSelector = createSelector([getFreeEntryText], (text) => {
    // console.log('[freeEntryTextSelector] text:', text);
    return text;
});
export const answerDataSelector = createSelector([getFreeEntryText], (text) => {
    if (text) {
        // console.log('[answerDataSelector] text:', text);
        try {
            const data = JSON.parse(text);
            // console.log('[answerDataSelector] data:', data);
            return data;
        } catch (e) {
            // console.log('[answerDataSelector] error:', e);
            return null;
        }
    }
    return text;
});

export const quizSessionSelector = createSelector([getQuizSession], (session) => toJS(session));
export const quizInstanceSelector = createSelector([getQuizInstance], (instance) => toJS(instance));

export const quizContextSelector = createSelector([getQuizContext], (context) => toJS(context));
export const quizProgressSelector = createSelector([getQuizProgress], (progress) => toJS(progress));

// question
export const getQuizStore = (state) => state.get('quiz', null);

// get all the questions
export const getQuestions = (state) => state.getIn(['quiz', 'session', 'context', 'questions'], null);

// get the index of the current question
export const getQuestionIndex = (state) => state.getIn(['quiz', 'session', 'context', 'question_index']);

// get the current question object
export const getQuestion = (state) => state.getIn(['quiz', 'session', 'context', 'question'], null);

// get the current question text
export const getQuestionText = (state) => state.getIn(['quiz', 'session', 'context', 'question', 'question_text'], null);
