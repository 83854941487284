import React, { createContext, useContext, useState } from 'react';

const WebTourContext = createContext();

const Steps = [
    {
        target: '#home-tour',
        content: "Learn how to use Muzology in less than a minute. We'll load some data here just for the tour.",

        placement: 'center'
    },
    {
        target: '#home-banner-tour',
        content: "Here's where you can quickly access key site features...and see special messages from us. ",
        placement: 'bottom'
    },
    {
        target: '#home-videos-tab-tour',
        content: 'Jump back into videos here. This is an easy way to play (and replay!) videos you are using for your classes. ',
        placement: 'bottom'
    },
    {
        target: '#home-leaderboard-tour',
        content: 'Students asked. We delivered. Class leaderboards! Use the dropdown menu to sort by different categories.  ',
        placement: 'top'
    },
    {
        target: '#classes-tour-sidebar',
        content: 'Move between classes, add classes, and enter class names and icons. ',
        placement: 'right'
    },
    {
        target: '#classes-tour-students',
        content: "See each student's progress and activity stream. ",
        placement: 'right'
    },
    {
        target: '#classes-tour-student-stats',
        content: 'Dig deeper and see each student\'s individual dashboard. (This is what each of your students will see when they click on their "Stats.")  ',
        placement: 'bottom'
    },
    {
        target: '#classes-tour-grades-view-assignments',
        content: "You can create custom playlists and assign them to your students. Here's where you can assignment progress by student.",
        placement: 'bottom'
    },
    {
        target: '#classes-tour-grades-pill',
        content: "You can create custom playlists and assign them to your students. Here's where you can assignment progress by student.",
        placement: 'bottom'
    },
    {
        target: '#classes-tour-grades-pill-expanded',
        content: 'Clicking on a pill shows more detail.',
        placement: 'right'
    },
    {
        target: '#classes-tour-video-grades-dropdown',
        content: "Select a video and see each student's progress.",
        placement: 'bottom'
    },
    {
        target: '#classes-tour-video-grades-student',
        content: 'For each student, see their total Video Views, Warmup Score, and current Challenge Score and Attempts (by Challenge Level).',
        placement: 'right'
    },
    {
        target: '#classes-tour-video-grades-export',
        content: 'Export video grades.',
        placement: 'left'
    },
    {
        target: '#classes-tour-video-grades-arrows',
        content: 'Anywhere you see these double triangles, you can sort the data (by high to low, or low to high). ',
        placement: 'bottom'
    },
    {
        target: '#classes-tour-roster',
        content: 'Manage your student roster here ',
        placement: 'top'
    },
    {
        target: '#video-page-tour-video-thumbnail',
        content: 'Hover over a video for a sneak peak. Click on the video to go to the video view page. ',
        placement: 'top'
    },
    {
        target: '#video-page-tour-add-playlist',
        content: 'To add a video to a playlist, click here (or click the blue + NEW PLAYLIST button in the upper right). ',
        placement: 'left'
    },
    {
        target: '#video-page-tour-video-search',
        content: 'The heart and soul of Muzology -- the video library! Find videos by category or by entering topics in the search bar.',
        placement: 'bottom'
    },
    {
        target: '#video-session-print-show-lyrics',
        content: 'Show or print lyrics. ',
        placement: 'bottom'
    },
    {
        target: '#video-session-challenges',
        content:
            'Take the Challenges yourself or see the answer key. (Note: Students can NOT see the answer key and have to level up to unlock subsequent Challenge levels.)',
        placement: 'bottom'
    },
    {
        target: '#video-session-records',
        content: 'See how ALL of your students are doing on each Challenge.',
        placement: 'right'
    },
    {
        target: '#playlist-playlists',
        content: "See a list of all of the playlists you've created. ",
        placement: 'right'
    },
    {
        target: '#playlist-info',
        content: 'See playlist info (videos, assignment details, etc). ',
        placement: 'left'
    },
    {
        target: '#playlist-classes-status',
        content: 'See how each class is doing on the assigned playlist. ',
        placement: 'left'
    },
    {
        target: '#playlist-export-grades',
        content: 'Export assignment grades.',
        placement: 'bottom'
    },
    {
        target: '#playlist-create',
        content: 'Click to create a new playlist. (This will take you back the Videos section.) ',
        placement: 'left'
    },
    {
        target: '#awards',
        content: 'You get Awards too!! Your records fill as your students earn 100% on each Challenge level.  ',
        placement: 'bottom'
    },
    {
        target: '#awards-dropdown',
        content:
            ' You can filter by Class and even specific Videos. This provides a quick, visual overview of how classes are progressing through the content.',
        placement: 'bottom'
    },
    {
        target: '#awards-classes-status',
        content: 'See who has earned 100% on a Challenge. ',
        placement: 'right'
    },
    {
        target: '#settings',
        content: 'Access your Teacher settings here. If we can help in any way, we are an email away:  ',
        placement: 'bottom'
    }
];

export const WebTourState = ({ children }) => {
    const [stepIndex, setIndex] = useState(0);

    return <WebTourContext.Provider value={{ tourSteps: Steps, stepIndex: stepIndex, setIndex: setIndex }}>{children}</WebTourContext.Provider>;
};

export function useWebTourState() {
    return useContext(WebTourContext);
}
