import React from 'react';
import tw, { css } from 'twin.macro';

const VideoSurveyEndMobile = ({ onSubmit }) => {
    return (
        <div tw='p-4  w-full flex flex-col items-center justify-center'>
            <div tw='text-white text-sm xsm:text-lg font-medium'>
                How's the level of math in this video for you?
            </div>
            <>
                <div tw='w-full flex justify-center'>
                    <div
                        tw='mt-1.5 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                        onClick={() => onSubmit(1)}>
                        Easy 
                    </div>
                </div>
                <div tw='w-full flex justify-center'>
                    <div
                        tw='mt-1.5 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                        onClick={() => onSubmit(2)}>
                        Just right 
                    </div>
                </div>
                <div tw='w-full flex justify-center'>
                    <div
                        tw='mt-1.5 text-xs md:text-base rounded-lg border-[1px] border-solid border-white  py-2 md:py-5 md:pl-6 text-white cursor-pointer hover:bg-[#46A6F8] w-full  max-w-[180px] md:max-w-[100%] text-center md:text-left'
                        onClick={() => onSubmit(3)}>
                        Advanced 
                    </div>
                </div>
            </>

        </div>

    );
};

export default VideoSurveyEndMobile;