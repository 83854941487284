import { Map } from 'immutable';
import quizLoadReducer from 'modules/quiz';
import quizReducer from 'modules/quiz/reducer.js';
import quizSessionReducer from 'modules/quiz/session.js';
import quizContextReducer from 'modules/quiz/context.js';
import appReducer from '../modules/app';

function reduceImmutableReducers(...reducers) {
    return (state, action) => Map(reducers.reduce((accumulated, current) => current(accumulated, action), state));
}

const all_reducers = [
    quizReducer,
    quizLoadReducer,
    quizSessionReducer,
    quizContextReducer,
    appReducer
];

const reducers = reduceImmutableReducers(...all_reducers);

export default reducers;
