import React, { useState, useEffect } from 'react';
import tw, { css } from 'twin.macro';
import Button from 'components/Button/Button';
import RecordIndicator from 'components/RecordIndicator';

function DTCCardsMobile(props) {
    const buttonCopy = props.level === 1 ? 'Silver' : props.level === 2 ? 'Gold' : 'Platinum';
    return (
        <>
            <div tw='absolute top-14 xsm:top-28 xsm:w-80 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg flex md:hidden'>
                <div
                    tw='cursor-pointer p-2 lg:p-5 bg-contain bg-no-repeat w-80 h-20  flex '
                    css={[
                        css`
                        background-size: 100% 100%;
                            `,
                        tw`hover:[box-shadow:0 0 20px 20px #ffffff22] active:[box-shadow:0 0 20px 20px #ffffff44]`
                    ]}
                    onClick={props.watchAgain}
                >
                    <div tw='flex items-end w-full lg:relative'>
                        <div tw='flex items-center gap-4 py-2 sm:py-4'>
                            <div
                                tw='cursor-pointer min-w-[30px] min-h-[30px] bg-white rounded-full flex items-center justify-center'
                                onClick={props.watchAgain}
                            >
                                <div>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none' viewBox='1 0 16 18' preserveAspectRatio='xMidYMid meet'
                                        tw='pl-1'>
                                        <path fill='#7959FA' d='M14.5 7.634a1 1 0 0 1 0 1.732L1.75 16.727a1 1 0 0 1-1.5-.866V1.14a1 1 0 0 1 1.5-.866L14.5 7.634Z' />
                                    </svg>

                                </div>
                            </div>
                            <div tw='text-[0.95rem] xsm:text-[1rem] text-white font-semibold'>Watch "{props.video.title}" again!</div>
                        </div>

                    </div>
                </div>
            </div>

            <div tw='absolute top-36 xsm:w-80 xsm:top-52 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg flex md:hidden'>
                <div tw=' flex flex-row items-center sm:flex-col justify-end w-80 h-20  rounded-lg relative'>
                    <div tw='absolute bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[28px] w-[28px] flex items-center justify-center top-[22px] left-[10px] z-20 rounded'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='14' height='20' fill='none'>
                            <path fill='#fff' fillRule='evenodd' d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z' clipRule='evenodd' />
                        </svg>
                    </div>
                    <div tw='h-[23px] w-[23px] z-10 absolute top-[25px] sm:top-[26px] left-[26px]'>
                        <RecordIndicator level={props.level} dtc={true} />
                    </div>
                    <div tw='flex items-end justify-start w-full'>
                        <div>
                            <Button variant='' tw='font-semibold bg-transparent border-none w-full text-left' onClick={props.takeChallenge}>
                                <div tw='text-[0.95rem] xsm:text-[1rem] text-white font-semibold w-full ml-6 mb-0 sm:mb-3'>Take "{props.video.title}" {buttonCopy} Challenge</div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DTCCardsMobile;