import React from 'react';

export default function SortArrows(props) {
    return (
        <svg
            width={10}
            height={16}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M4.826 0l4.18 6.75H.646L4.827 0zM4.828 16L.648 9.25h8.36L4.828 16z'
                fill='#fff'
            />
        </svg>
    );
}