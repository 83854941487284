import React, { useEffect, useMemo, useState } from 'react';
import videojs from 'video.js';

import 'video.js/dist/video-js.min.css';
import './VideoPlayer_videojs.css';
import { AspectRatio16x9, AspectRatio4x3 } from '../../constants';

export const VideoJSPlayer = React.forwardRef((props, videoNode) => {
    // const videoNode = useRef();
    const [, setVideoPlayer] = useState(null);

    // construct the className
    const className = useMemo(() => {
        let aspect = '';
        if (props.aspectRatio === AspectRatio16x9)
            aspect = 'vjs-16-9';
        else if (props.aspectRatio === AspectRatio4x3)
            aspect = 'vjs-4-3';
        return `video-js ${aspect} vjs-default-skin ${props.className}`;
    }, [props]);

    // initialize the video player
    useEffect(() => {
        /*
        let videoProps = props.videoJsOptions;
        const sources = createVideoSources(video);
        const videoProps = {
            autoplay: false,
            controls: true,
            sources: sources,
            poster: video.url_thumbnail,
            playbackRates: [0.5, 1, 1.5, 2],
            plugins: {
                // dynamoPlugin: {
                //     metadata: metadata
                // },

                // abLoopPlugin: {
                //     start: 35.4,
                //     end: 38.52,
                //     enabled: true			    //defaults to false
                //     , loopIfBeforeStart: false    //allow video to play normally before the loop section? defaults to true
                //     , loopIfAfterEnd: true	    // defaults to true
                //     , pauseAfterLooping: false   //if true, after looping video will pause. Defaults to false
                //     , pauseBeforeLooping: false  //if true, before looping video will pause. Defaults to false
                //     , createButtons: true        //defaults to true
                // }
            }
        };
        */
        const { autoplay, controls, sources, poster } = props;
        const options = {
            controls: controls ?? true,
            autoplay: autoplay ?? false,
            sources: sources,
            plugins: {
                // eventTracking: true
            },
            controlBar: {
                pictureInPictureToggle: false
            },
            playbackRates: [0.25, 0.5, 1.0, 1.5, 2.0],
            poster: poster
        };

        // check the node
        if (!videoNode.current) {
            console.error('VideoJSVideoPlayer error videoNode.current is', videoNode.current);
        }

        // instantiate Video.js
        console.log('videojs init', props);
        videojs(videoNode.current, options, function ready(a) {
            const player = this;
            console.log('player ready:', player);
            setVideoPlayer(player);
        });

        // add event handlers
        videoNode.current.addEventListener('play', props.onPlayerEvent);
        videoNode.current.addEventListener('pause', props.onPlayerEvent);
        videoNode.current.addEventListener('ended', props.onPlayerEvent);

        // let lastTime = 0;
        //
        // const frameUpdate = () => {
        //     let time = player.currentTime();
        //     if(time !== lastTime) {
        //         console.log('time: ' + time);
        //         lastTime = time;
        //
        //         if(currentLoop) {
        //             console.log( currentLoop );
        //             if(time >= currentLoop.end) {
        //                 console.log('loop end');
        //                 player.currentTime(currentLoop.start)
        //             }
        //         }
        //
        //     }
        //
        //     //wait approximately 16ms and run again
        //     requestAnimationFrame(frameUpdate);
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // wrap the player in a div with a `data-vjs-player` attribute so videos won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    return (
        <div data-vjs-player>
            <video
                // id={metaData.video_id}
                ref={videoNode}
                className='video-js vjs-16-9 vjs-default-skin'
                // className='video-js vjs-4-3 vjs-default-skin'
                preload='auto'
                controls
            />
        </div>
    );
});

export default VideoJSPlayer;
