import { PrimaryButton } from 'app/ui/buttons';
import React from 'react';
import { styled } from 'twin.macro';
import RightArrow from 'images/icons/RightArrow';

export const SubmitButtonStyle = styled(PrimaryButton)`
	background-image: none;
	font-family: Avenir, serif;
	font-weight: 500;
	font-size: 21px;
	text-transform: uppercase;
	height: 46px;
	min-width: 150px;
	border-radius: 23px;
	line-height: normal;
	padding: 0;
	margin: 0;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	text-shadow: none !important;

	.right-arrow {
		font-size: 20px;
		margin-left: 10px;
	}

    &:focus-visible {
        outline-style: solid;
        outline-color: red;
    }

	// normal - pink
	background-color: ${(props) => props.backgroundColor || '#e637a8'};
	border: 4px solid ${(props) => props.backgroundColor || '#e637a8'};

	&:hover {
		background-image: none;
		background-color: #e637a8;
		border: 4px solid #91005e;
		color: white;
	}
    
    :hover {
        background: none;
    }
    
	&:active {
		background-image: none;
		background-color: #91005e;
		border: 4px solid #91005e;
		color: white;
	}

	// purple
	&.purple {
		background-color: #8f71ea;
		border: 4px solid #8f71ea;

		&:hover {
			background-color: #8f71ea;
			border: 4px solid #341983;
			color: white;
		}

		&:active {
			background-color: #341983;
			border: 4px solid #341983;
			color: white;
		}
	}

	// aqua
	&.aqua {
		background-color: #1abdd7;
		border: 4px solid #1abdd7;

		&:hover {
			background-color: #1abdd7;
			border: 4px solid #005865;
			color: white;
		}

		&:active {
			background-color: #005865;
			border: 4px solid #005865;
			color: white;
		}
	}

	&.disabled {
		background: #dedede;
		color: #b4b4b4;
		border: 4px solid transparent;
		cursor: not-allowed;
		pointer-events: none;
	}
`;

const SubmitButton = ({ title, onClick, className, disabled, backgroundColor, children }) => {
    return (
        <SubmitButtonStyle
            disabled={disabled}
            aria-disabled={disabled}
            tabIndex={disabled ? -1 : 2}
            onClick={onClick}
            className={`quiz-styled-button ${className || ''} ${disabled ? 'disabled' : ''}`}
            backgroundColor={backgroundColor}
        >
            {title || children}
            <RightArrow className='right-arrow' />
        </SubmitButtonStyle>
    );
};

export default SubmitButton;
