import React from 'react';
import tw from 'twin.macro';
import Modal from 'components/Modal/Modal';

/*
 * ModalDialog - White modal dialog with rounded corners and a close button in the upper right
 */

const ModalDialog = ({ children, isOpen, setIsOpen, modalChildrenStyle }) => {
    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={modalChildrenStyle ?? tw`w-full maxWidth[650px] mx-auto`}>
            <div tw='mx-4 relative'>
                <div tw='bg-white p-6 rounded-xl'>
                    {children}
                </div>
                <button
                    tabIndex={100}
                    tw='bg-white h-12 w-12 absolute -top-4 -right-4 rounded-full flex justify-center items-center shadow-md cursor-pointer'
                    onClick={() => setIsOpen(false)}
                >
                    <svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M16.705 1.257c-.71-.711-1.773-.711-2.482 0l-5.2 5.211-5.201-5.211c-.71-.711-1.773-.711-2.482 0-.71.71-.71 1.776 0 2.487l5.2 5.212-5.2 5.211c-.71.71-.71 1.777 0 2.487.355.356.827.474 1.3.474.473 0 .946-.118 1.3-.474l5.2-5.211 5.2 5.211c.355.356.828.474 1.301.474s.946-.118 1.3-.474c.71-.71.71-1.776 0-2.487l-5.2-5.211 5.2-5.212c.473-.71.473-1.777-.236-2.487z'
                            fill='#6A7277'
                        />
                    </svg>
                </button>
            </div>
        </Modal>
    );
};

export default ModalDialog;