import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'components';
import 'twin.macro';
import { useUser, useTeacher, useUserAction } from 'state/User/UserState';
import { useToast } from 'hooks/toast';
import { showError } from '../../../../AccountOnboard/Components/Components';

import LoadingOverlay, { useLoadingOverlay } from '../../../../../components/LoadingOverlay';
import ParentSettingsModal from './Modal/ParentSettingsModal';
import ChangePasswordModal from './Modal/ChangePasswordModal';

const AUTH_LOGIN = 1; // Normal username / password login

const Settings = () => {
    const teacher = useTeacher();
    console.log('🚀 ~ file: Settings.js:17 ~ Settings ~ teacher:', teacher);
    const user = useUser();
    console.log('🚀 ~ file: Settings.js ~ line 16 ~ Settings ~ user', user);
    const addToast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const { saveSettings } = useUserAction();
    const [isLoading, setIsLoading, Loading] = useLoadingOverlay();
    // const [isLoading, setIsLoading] = useState(false);

    const editPassword = useMemo(() => !user.auth_method || user.auth_method === 0 || user.auth_method === AUTH_LOGIN, [user.auth_method]);

    // form hook
    const {
        register,
        trigger,
        handleSubmit,
        errors: formErrors,
        watch
        // setError
    } = useForm({
        defaultValues: {
            name: teacher.name
        }
    });

    const nameWatched = watch('name');
    const [isEdited, setIsEdited] = useState(false);

    // get a reference to the password for validation
    // const password = useRef({});
    // const currentPassword = useRef({});
    // password.current = watch('password', '');
    // currentPassword.current = watch('current_password', '');

    useEffect(() => {
        // Compare the watched value with the default value
        if (nameWatched !== teacher.name) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    }, [nameWatched, teacher.name]); // Dependency array


    const onSubmitNameChange = async (data) => {
        // alert('ok');
        console.log('saving', data);
        // create the payload
        const payload = {
            name: data.name
        };
        setIsLoading(true);
        // save the settings
        saveSettings(payload)
            .then((data) => {
                setIsLoading(false);
                addToast('Success!', 'Your name was updated.');
            })
            .catch((error) => {
                setIsLoading(false);
                addToast('Error', `There was an error saving your settings: ${error}`, 'error');
            });
    };

    console.log('formErrors', formErrors);
    return (
        <div tw='mx-0'>
            <Loading fixed={true} />
            <form onSubmit={handleSubmit(onSubmitNameChange)} autoComplete='off' id='settings'>
                <div tw='mt-9 mb-2 pt-20 sm:pt-0 font-medium text-sm text-[#FFF]'>
                    Your Name
                </div>
                <input
                    tw='bg-[#1C0057] sm:bg-[#3E257A] border-[#B5AAD0] border-2 px-4 py-3 rounded-md text-sm font-medium w-full md:w-2/3 max-w-[600px] text-[#B5AAD0] focus:outline-none focus:border-none focus:text-[#FFF] focus:bg-[#B5AAD01A]'
                    placeholder='Your name'
                    type='text'
                    name='name'
                    autoFocus
                    ref={register({ required: true })}
                />
                {showError(formErrors.name)}
                <div tw='mt-7 mb-2  font-medium text-sm text-[#FFF]'>Your Email</div>
                <div tw='bg-[#1C0057] sm:bg-[#3E257A] border-[#B5AAD0] border-2 px-4 py-3 w-full md:w-2/3 max-w-[600px] rounded-md text-sm font-medium flex justify-between gap-2 text-[#B5AAD0]'>
                    <div>{user.user.email}</div>
                    <div>
                        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 26 26' fill='none'>
                            <path fillRule='evenodd' clipRule='evenodd' d='M0.5625 10.8867C0.5625 9.78215 1.45793 8.88672 2.5625 8.88672H23.875C24.9796 8.88672 25.875 9.78215 25.875 10.8867V23.1155C25.875 24.22 24.9796 25.1155 23.875 25.1155H2.5625C1.45793 25.1155 0.5625 24.22 0.5625 23.1155V10.8867ZM13.9688 17.2995C14.6297 17.0214 15.0916 16.3869 15.0916 15.6487C15.0916 14.654 14.2531 13.8476 13.2187 13.8476C12.1844 13.8476 11.3459 14.654 11.3459 15.6487C11.3459 16.3869 11.8078 17.0214 12.4688 17.2995V19.7059C12.4688 20.1201 12.8045 20.4559 13.2188 20.4559C13.633 20.4559 13.9688 20.1201 13.9688 19.7059V17.2995Z' fill='white' />
                            <path d='M7.59375 8.88586V6.39648C7.59375 3.28988 10.1121 0.771484 13.2188 0.771484H13.8725C16.6181 0.771484 18.8438 2.99718 18.8438 5.74271V5.74271' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                        </svg>
                    </div>
                </div>
                <div tw='text-[#B5AAD0] text-sm pl-1 mt-3 '>
                    If you need to change the email associated with your account, please contact us directly at support@muzology.com.
                </div>

                {editPassword && <div tw='text-sm text-[#FFF] underline mt-6 cursor-pointer' onClick={() => setIsOpen(true)}>
                    Change Your Password
                </div>}

                <div tw='w-max mt-6'>
                    {isEdited ? (
                        <Button
                            type='submit'
                            tw='bg-[#FEEA50] z-60 border-black border-[1px] rounded-md text-black font-Poppins font-medium'
                            onClick={() => trigger()}
                        >
                            Save Changes
                        </Button>

                    ) : (
                        <Button
                            type='submit'
                            tw='bg-[#3E257A] border-[#B5AAD0] border-[1px] rounded-md text-[#B5AAD0] font-Poppins font-medium cursor-not-allowed'
                            disabled
                        >
                            Save Changes
                        </Button>
                    )}

                </div>
            </form>

            <ChangePasswordModal
                teacher={teacher}
                saveSettings={saveSettings}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                addToast={addToast}
            />

        </div>
    );
};

export default Settings;
