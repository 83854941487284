// new user onboarding
export const ONBOARD_STAGE_INTRO = 'intro'; // verify email

export const ONBOARD_STAGE_VERIFY = 'verify_email'; // verify email
export const ONBOARD_STAGE_AGREE_TO_TERMS = 'agree_to_terms'; // agree to legal terms
export const ONBOARD_STAGE_SETUP_PARENT = 'setup_parent'; // setup homeschool parent
export const ONBOARD_STAGE_SETUP_CHILD = 'setup_child'; // setup homeschool students
export const ONBOARD_STAGE_SETUP_CHILD_TOPICS = 'setup_child_topics'; // setup homeschool students playlist/learning topics
export const ONBOARD_STAGE_SETUP_TUTORIAL_VIDEO = 'show_tutorial_video'; // show tutorial video

export const ONBOARD_STAGE_REGISTER = 'register_user'; // set password
export const ONBOARD_REGISTRATION_REQUIRED = 'registration_required'; // updated registration screen 2024
export const ONBOARD_STAGE_SETUP_TEACHER = 'setup_teacher'; // set name and grade level
export const ONBOARD_STAGE_SETUP_STUDENTS = 'setup_students'; // setup homeschool students
export const ONBOARD_STAGE_COMPLETED = 'completed'; // done
export const ONBOARD_STAGE_ENROLL_EDUCATOR_PROGRAM = 'enroll_educator_program'; // expired users enroll in the educator program
export const ONBOARD_STAGE_CUSTOMER_SUCCESS_QUESTION = 'customer_success_question'; // user answers a customer success question
export const ONBOARD_STAGE_CONTINUE_SETUP_CHILD = 'continue_setup_child'; // continue setup children
export const ONBOARD_CLICKED_DEMO_SIGNUP = 'click_demo_signup'; // the user clicked "schedule demo"


// in platform education program signup
export const EDUCATOR_PROGRAM_WELCOME_SCREEN = 'educator_program_welcome_screen'; // show intro screen
export const EDUCATOR_PROGRAM_SIGNUP_FORM = 'educator_program_signup_form'; // show intro screen
export const EDUCATOR_PROGRAM_STAGE_AGREE_TO_TERMS = 'educator_program_agree_to_terms'; // agree to legal terms
export const EDUCATOR_PROGRAM_COMPLETED_SCREEN = 'educator_program_completed_screen'; // show completed screen

// trial expired stages
export const ONBOARD_STAGE_TRIAL_EXPIRING_SOON = 'trial_expiring_soon'; // trial is expiring within 5 days
export const ONBOARD_STAGE_TRIAL_EXPIRED = 'trial_expired'; // trial is expired

// subscription cancelled
export const ONBOARD_STAGE_SUBSCRIPTION_CANCELLED = 'subscription_cancelled'; // subscription cancelled

// show popup for in platform messaging 
export const ONBOARD_STAGE_SHOW_ANNOUNCEMENTS = 'show_announcements';

// re-activated trial
export const ONBOARD_STAGE_RE_ACTIVATED_TRIAL = 're_activated_trial'; // re-activated trial
