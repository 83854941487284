import React from 'react';
import tw from 'twin.macro';
import ListBox from 'components/ListBox/ListBox';
import { ParentAddPlaylistIcon } from '../PlaylistSvgs/ParentAddPlaylistIcon';
import { ParentRemovePlaylistIcon } from '../PlaylistSvgs/ParentRemovePlaylistIcon';

const ParentInfo = (props) => {
    let { video, showTitle, handleAddingVideoToPlaylist, isPlaylistDropdownOpen, selectedVideo, setSelectedStudent, selectedStudent, listBoxItems } = props;
    function handleSetSelectedStudent(event) {
        event.stopPropagation();
    }

    return (
        <div tw='flex ' id='no-hover'>
            <div tw='w-10/12 flex justify-between background[#eaeafb] height[92px] rounded-bl-md'>
                <div className='teacher-info-text' tw='minWidth[140px] w-full pl-2.5 pt-2.5 relative' onClick={handleSetSelectedStudent}>
                    {showTitle && <div className='title'>{video.title}</div>}
                    <div tw='maxWidth[180px] mb-2.5 '>
                        <ListBox items={listBoxItems} onChange={(value) => setSelectedStudent(value)} selected={selectedStudent} variant='transparent-small' />
                    </div>
                </div>
            </div>
            <div css={[tw`flex justify-center items-center w-1/5 background[#eaeafb] rounded-br-md`]} onClick={handleAddingVideoToPlaylist}>
                {selectedVideo && isPlaylistDropdownOpen ? <ParentRemovePlaylistIcon /> : <ParentAddPlaylistIcon />}
            </div>
        </div>
    );
};

export default ParentInfo;
