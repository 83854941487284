import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { Listbox } from '@headlessui/react';

const CreateSecondChildDropDown = ({ className, onSelectItem, selectedValue, setNoChildSelection }) => {

    const items = [
        { name: 'Create 1 Child Account', key: 1, value: 1 },
        { name: 'Create 2 Child Accounts', key: 2, value: 2 }
    ];
    // const [selectedItem, setSelectedItem] = useState(items.find(item => item.value === initialValue));
    const selectedItem = items.find(item => item.value === selectedValue);
    console.log('setupChildCount initialValue = ', selectedValue);
    console.log('setupChildCount selectedItem =', selectedItem);
    const handleChanged = (item) => {
        console.log('🚀 ~ file: CreateSecondChildDropDown.js:17 ~ handleChanged ~ item:', item);
        // setSelectedItem(item);
        setNoChildSelection(false);
        if (onSelectItem) onSelectItem(item);
    };

    return (
        <Listbox value={selectedItem} onChange={handleChanged} className={className} id='child-count-dropdown'>
            <div tw='relative mt-1 w-full'>
                <Listbox.Button
                    id='child-count-dropdown-button'
                    tw='relative flex items-center justify-between w-full bg-white border-gray-200 border-2 py-0.5 px-2 text-left rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'>
                    <span tw='block truncate text-justify'>{selectedItem ? selectedItem.name : 'please make a selection'}</span>
                    <span tw='pr-2 pointer-events-none minHeight[33px] ml-3.5 flex items-center justify-center'>
                        <svg
                            width='11' height='6' viewBox='0 0 11 6' fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.300573 0.306863C0.714038 -0.0866995 1.28966 -0.117584 1.79512 0.306863L5.50112 3.61332L9.20713 0.306863C9.71258 -0.117584 10.2892 -0.0866995 10.6998 0.306863C11.1132 0.699543 11.0867 1.36313 10.6998 1.73198C10.3148 2.10084 6.24745 5.70467 6.24745 5.70467C6.15059 5.79807 6.0345 5.87237 5.90611 5.92311C5.77773 5.97385 5.63966 6 5.50017 6C5.36068 6 5.22262 5.97385 5.09424 5.92311C4.96585 5.87237 4.84976 5.79807 4.7529 5.70467C4.7529 5.70467 0.687485 2.10084 0.300573 1.73198C-0.0872867 1.36313 -0.112891 0.699543 0.300573 0.306863Z'
                                fill='#1A1717'
                            />
                        </svg>
                    </span>
                </Listbox.Button>

                <Listbox.Options
                    tw='absolute z-20 w-full mt-1 overflow-auto text-base rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    style={{ height: '80px' }}
                >
                    {items.map((item) => (
                        <Listbox.Option
                            key={item.name}
                            value={item}
                            tw='bg-white select-none relative py-2 px-2 cursor-pointer text-justify hover:bg-GhostWhite focus:outline-none focus:ring-2 focus:ring-GhostWhite focus:ring-opacity-50'
                        >
                            {item.name}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

CreateSecondChildDropDown.propTypes = {
    anyProp: PropTypes.any
};

export default CreateSecondChildDropDown;
