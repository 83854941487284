import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

// static resources
import MinusSymbol from 'static/icons/teacher/AddAssignmentButton/minus.png';
import PlusSymbol from 'static/icons/teacher/AddAssignmentButton/plus.png';

const AddAssignmentButton = ({ toggle }) => {
    return (
        <div
            toggle={toggle}
            tw='bg-white rounded-full flex items-center justify-center w-8 h-8'>
            <img src={toggle ? MinusSymbol : PlusSymbol}  alt={toggle ? 'Plus' : 'Minus'}/>
        </div>
    );
};

AddAssignmentButton.propType = {
    anyProp: PropTypes.any
};

export default AddAssignmentButton;
