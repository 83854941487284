import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import styled from 'styled-components';
import moment from 'moment';
import Slider from 'react-slick/lib';
import RecordImg from 'components/RecordImg';
import iconWarning from 'static/images/warning-icon.svg';

require('moment-duration-format')(moment);

moment.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'A Few seconds',
        ss: '%d Seconds',
        m: 'A Minute',
        mm: '%d Minutes',
        h: 'An Hour',
        hh: '%d Hours',
        d: 'A Day',
        dd: '%d Days',
        M: 'A Month',
        MM: '%d Months',
        y: 'A Year',
        yy: '%d Years'
    }
});

/*
    SlickButton - custom react-slick button
 */
const SlickButtonStyle = css`
    &&& {
        position: absolute;
        width: 40px;
        height: 40px;
        @media only screen and (max-width: 1024px) {
            width: 35px;
            height: 35px;
        }
        @media only screen and (max-width: 414px) {
            width: 20px;
            height: 20px;
        }
        // center it within its parent...
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;

        &.next {
            left: 100%;
            right: 0px;
        }

        &.prev {
            right: 100%;
        }

        &.disabled {
            display: none;
        }

        &:active {
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.16);
        }
    }
`;

const BorderBottom = css`
    ::after {
        position: absolute;
        content: '';
        border-bottom: 2px solid #c7c7ff;
        width: 90%;
        transform: translateX(-50%);
        bottom: -2px;
        left: 50%;
    }
`;

function NextArrow(props) {
    let { className, onClick, ArrowRight } = props;
    if (className.includes('slick-disabled')) className = 'next disabled';
    else className = 'next';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg
                width='12' height='21' viewBox='0 0 12 21' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M2 19L10.5 10.5L2 2' stroke='#1A1717' strokeWidth='2.83333' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

function PrevArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'prev disabled';
    else className = 'prev';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg
                width='12' height='21' viewBox='0 0 12 21' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M10.5 2L2 10.5L10.5 19' stroke='#1A1717' strokeWidth='2.83333' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

let CompleteSymbol = (
    <svg width='50' height='50' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M46 21a21 21 0 11-42 0 21 21 0 0142 0zm-10.421-7.954a1.969 1.969 0 00-2.835.058L23.627 24.72l-5.494-5.497a1.969 1.969 0 00-2.782 2.782l6.945 6.949a1.97 1.97 0 002.833-.053l10.479-13.098a1.969 1.969 0 00-.027-2.757h-.002z'
                fill='#00CB46'
            />
        </g>
        <defs>
            <filter
                id='filter0_d' x='0' y='0' width='50'
                height='50' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
                <feOffset dy='4' />
                <feGaussianBlur stdDeviation='2' />
                <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
                <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
            </filter>
        </defs>
    </svg>
);

const StudentAssignmentProgress = ({ videos, assignments }) => {
    const [windowSize, setWindowSize] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipPos, setTooltipPos] = useState(false);

    let dragging = false;

    // default settings
    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,

        initialSlide: 0,
        slidesToShow: 5,
        slidesToScroll: 5,

        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 1084,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 938,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        beforeChange: () => (dragging = true),
        afterChange: () => (dragging = false)
    };

    useEffect(() => {
        window.addEventListener('resize', setWindowSize);
        return () => window.removeEventListener('resize', setWindowSize);
    }, []);

    useEffect(() => {
        const handleChangeTooltipPos = () => {
            let selObj;
            if (typeof window === 'object' && (selObj = document.getElementById(`assignment-item--${tooltipOpen}-status`))) {
                const rect = selObj.getBoundingClientRect();
                setTooltipPos(rect);
            }
        };
        if (tooltipOpen !== false) {
            handleChangeTooltipPos();
        }
    }, [tooltipOpen]);

    const renderAssignmentTooltip = () => {
        if (tooltipOpen === false) {
            return <></>;
        }

        const { due, progress, quiz_level_goal, quiz_score_goal, videos: assignmentVideos } = assignments[tooltipOpen];
        const progressArray = Object.values(progress.progress);
        const isOverdue = moment().isAfter(due);
        const completeStatus = progress.completed ? 'complete' : progressArray.length > 0 ? 'progress' : 'not-start';
        const statusText =
            completeStatus === 'complete'
                ? isOverdue
                    ? 'Completed Late'
                    : 'Completed On Time'
                : isOverdue
                    ? 'Overdue'
                    : completeStatus === 'progress'
                        ? 'In Progress'
                        : 'Not Started';
        const videoCount = assignmentVideos.length;
        const completeVideo = progressArray.filter((item) => item.completed).length;
        const totalSpent = progressArray
            .map((item, index) => ({ video: videos.find((video) => video.id === assignmentVideos[index]), progress: item.progress }))
            .filter((item) => item.video && item.progress > 0)
            .reduce((acc, cur) => acc + cur.video.duration * cur.progress, 0);

        return (
            <AssignmentTooltip>
                <div className='assignment-tooltip--overlay' onClick={() => setTooltipOpen(false)} />
                <div className='assignment-tooltip--container' style={{ left: tooltipPos.left + tooltipPos.width / 2, top: tooltipPos.top }}>
                    <div className='assignment-tooltip--wrapper'>
                        <p className={`assignment-tooltip--title status-${completeStatus === 'complete' || !isOverdue ? completeStatus : 'overdue'}`}>
                            {statusText}
                            {isOverdue && <img src={iconWarning} />}
                        </p>
                        <p className='assignment-tooltip--info'>
                            <b>Videos Complete:</b> {`${completeVideo} of ${videoCount}`}
                        </p>
                        <div className={`assignment-tooltip--videos ${videoCount > 3 ? 'justify-left' : ''}`}>
                            {[...Array(videoCount).keys()].map((index) => {
                                const item = progressArray[index];
                                return (
                                    <div
                                        className={`assignment-tooltip--video-bar status-${
                                            !item ? 'not-start' : item.completed ? 'complete' : item.progress > 0 ? 'progress' : 'not-start'
                                        }`}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                        <p className='assignment-tooltip--info'>
                            <b>Goal:</b> {quiz_score_goal}%
                            <RecordImg level={quiz_level_goal} style={{ width: '17px', height: 'auto', display: 'inline' }} />
                        </p>
                        {/*
                        <p className='assignment-tooltip--info'>
                            <b>Time Spent:</b> {moment.duration(totalSpent, 'seconds').format('h [hr] m [min]', { trim: true })}
                        </p>
                        */}
                        {completeStatus === 'complete' && progress.completion_date && (
                            <p className='assignment-tooltip--info'>
                                <b>Date Completed:</b> {moment(progress.completion_date).format('MM/DD/YY')}
                            </p>
                        )}
                    </div>
                </div>
            </AssignmentTooltip>
        );
    };

    return (
        <div tw='bg-white pt-8 pb-11 border-b-2 borderColor[#c7c7ff]'>
            <div tw='text-lg text-SlateBlue my-3 font-extrabold'>Assignment Progress</div>
            <div>
                {assignments?.length ? (
                    <Slider {...settings}>
                        {assignments.map((assignment, index) => {
                            let video = videos.find((video) => video.id === assignment.items[0]);
                            let assignmentHeaderColorState;
                            let assignmentPillColorState;
                            let assignmentStatusState;
                            let assignmentHoverColor;
                            // assignment is overdue
                            if (moment().isAfter(assignment.due) && !assignment.progress.completed) {
                                assignmentHeaderColorState = '#EE7171';
                                if (Object.keys(assignment.progress.progress).length === 0 && assignment.progress.progress.constructor === Object) {
                                    assignmentStatusState = 'Not Started';
                                    assignmentPillColorState = '#A5A5A5';
                                    assignmentHoverColor = '#828282';
                                }
                                assignmentStatusState = 'In Progress';
                                assignmentPillColorState = '#887BE3';
                            }
                            // assignment isnt over due hasnt started
                            // the progress.progress object will empty
                            else if (Object.keys(assignment.progress.progress).length === 0 && assignment.progress.progress.constructor === Object) {
                                assignmentHeaderColorState = '#A5A5A5';
                                assignmentPillColorState = '#A5A5A5';
                                assignmentStatusState = 'Not Started';
                                assignmentHoverColor = '#828282';
                            } else if (Object.keys(assignment.progress.progress).length > 0 && !assignment.progress.completed) {
                                assignmentHeaderColorState = '#887BE3';
                                assignmentPillColorState = '#887BE3';
                                assignmentStatusState = 'In Progress';
                                assignmentHoverColor = '#6160d9';
                            } else if (assignment.progress.completed) {
                                assignmentHeaderColorState = '#5FC17A';
                                assignmentHoverColor = '#479b5e';
                            }

                            return (
                                <AssignmentItemContainer
                                    assignmentHoverColor={assignmentHoverColor}
                                    className='assignment-item'
                                    key={assignment?.progress?.id}
                                >
                                    <button
                                        tw='w-full block'
                                        tabIndex='0'
                                        onClick={() => setTooltipOpen(index)}
                                    >
                                        <div
                                            className='assignment-item--header'
                                            tw='w-full p-2 rounded-t-lg text-white text-center'
                                            style={{ backgroundColor: assignmentHeaderColorState }}
                                        >
                                            <div tw=' text-sm overflow-hidden truncate w-11/12 font-extrabold'>{assignment.name}</div>
                                            <div tw='text-sm'>
                                                <span tw='font-extrabold mr-1'>Due:</span>
                                                {moment(assignment.due).format('MM/DD/YY')}
                                            </div>
                                        </div>
                                        <div className='assignment-item--body'>
                                            <div className='assignment-item--overlay' />
                                            <div className='assignment-item--status'>
                                                <div className='assignment-item--status-wrapper' id={`assignment-item--${index}-status`}>
                                                    {assignment.progress.completed === true ? (
                                                        <div>{CompleteSymbol}</div>
                                                    ) : (
                                                        <div
                                                            tabIndex=''
                                                            tw='rounded-full py-1 px-9 text-white text-xs xl:text-sm font-medium'
                                                            style={{ backgroundColor: assignmentPillColorState }}
                                                            className='assignment-item-pill'
                                                        >
                                                            {assignmentStatusState}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <img
                                                className='assignment-item--thumbnail'
                                                src={video?.url_thumbnail || 'https://via.placeholder.com/80'}
                                                alt='video thumbnail'
                                            />
                                        </div>
                                    </button>
                                </AssignmentItemContainer>
                            );
                        })}
                    </Slider>
                ) : (
                    <div tw='bg-QuizBlue bg-opacity-20 p-12 text-center text-black borderRadius[20px]'>No assignments have been made.</div>
                )}

                {tooltipOpen !== false && renderAssignmentTooltip()}
            </div>
        </div>
    );
};

StudentAssignmentProgress.propTypes = {
    student: PropTypes.object
};

export default StudentAssignmentProgress;

const AssignmentTooltip = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 1000;

    .assignment-tooltip--overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .assignment-tooltip--container {
        position: absolute;
        transform: translate(-50%, calc(-100% - 14px));
        box-shadow: 0px 1px 10px 0px #0000001a;

        .assignment-tooltip--wrapper {
            border-radius: 5px;
            background: #ffffff;
            background-clip: padding-box;
            border: solid 2px transparent;
            padding: 18px 30px;
            width: 259px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                border-radius: inherit;
                background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
            }

            &:after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                bottom: -14px;
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                border-top: none;
                border-left: none;
                border-image: initial;
                border-right: 2px solid #7378e7;
                border-bottom: 2px solid #7774e7;
                z-index: 1000;
                background: white;
            }

            .assignment-tooltip--title {
                font-weight: 800;
                font-size: 18px;
                line-height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ee7171;
                margin: 0 0 9px;

                img {
                    margin-left: 4px;
                }

                &.status-overdue {
                    color: #ee7171;
                }

                &.status-complete {
                    color: #5fc17a;
                }

                &.status-progress {
                    color: #887be3;
                }

                &.status-not-start {
                    color: #a5a5a5;
                }
            }

            .assignment-tooltip--info {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #1a1717;
                margin: 1px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;

                b {
                    font-weight: 800;
                    margin-right: 2px;
                }

                img {
                    margin-left: 3px;
                }
            }

            .assignment-tooltip--videos {
                width: 100%;
                margin: 5px 0 9px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                &.justify-left {
                    justify-content: left;
                }

                .assignment-tooltip--video-bar {
                    width: calc(33% - 4px);
                    height: 10px;
                    margin: 2px;

                    &.status-complete {
                        background: #5fc17a;
                        border: 1px solid #1d562d;
                    }

                    &.status-progress {
                        background: #887be3;
                        border: 1px solid #24238d;
                    }

                    &.status-not-start {
                        background: #a5a5a5;
                        border: 1px solid #5a5353;
                    }
                }
            }
        }
    }
`;

const AssignmentItemContainer = styled.div`
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;

    .assignment-item--header {
        padding: 0.5rem;
        border-radius: 0.5rem 0.5rem 0 0;
        color: #ffffff;
        text-align: center;

        &:hover {
            background: ${(props) => props.assignmentHoverColor} !important;
        }
    }

    .assignment-item--body {
        position: relative;

        .assignment-item--overlay {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border-radius: 0 0 0.5rem 0.5rem;
            background-color: rgba(0, 0, 0, 0.6);
        }

        .assignment-item--status {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            z-index: 20;

            .assignment-item--status-wrapper {
                .assignment-item-pill {
                    &:hover {
                        background: ${(props) => props.assignmentHoverColor} !important;
                    }
                }
            }
        }

        .assignment-item--thumbnail {
            width: auto;
            border-radius: 0 0 0.5rem 0.5rem;
        }
    }
`;
