export function isAssignedVideoCompleted(assignment, video_id) {
    if (assignment && assignment.progress && assignment.progress.progress && video_id) {
        const progress = assignment.progress.progress[video_id];
        // console.log('isAssignedVideoCompleted', video_id, progress);
        return progress?.completed ?? false;
    }
    return false;
}

export function assignedVideoProgress(assignment, video_id) {
    if (assignment && assignment.progress && assignment.progress.progress && video_id) {
        const progress = assignment.progress.progress[video_id];
        // console.log('assignedVideoProgress', video_id, progress);
        return progress?.progress ?? null;
    }
    return null;
}

