import * as React from 'react';

function SvgClassIcon24(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#D2FF1D' />
            <path
                d='M30 28.562V31l-2.137-1.346-5.689-10.35a6.057 6.057 0 002.114-1.15L30 28.563zM24.062 13.75c0 .915-.375 1.793-1.043 2.44A3.622 3.622 0 0120.5 17.2h-.523l-6.84 12.454L11 31v-2.438l6.876-12.512a3.38 3.38 0 01.285-4.876A3.6 3.6 0 0120.5 10.3V8c.315 0 .617.121.84.337.222.215.348.508.348.813v1.357c1.425.483 2.375 1.783 2.375 3.243zm-2.375 0c0-.305-.125-.598-.347-.813a1.208 1.208 0 00-.84-.337c-.315 0-.617.121-.84.337a1.132 1.132 0 00-.348.813c0 .305.126.598.348.813.223.216.525.337.84.337.315 0 .617-.121.84-.337.222-.216.348-.508.348-.813z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon24;
