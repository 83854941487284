import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled, css } from 'twin.macro';
import moment from 'moment';
import PopupMenu from 'components/PopupMenu/PopupMenu';
import ReactHoverObserver from 'react-hover-observer';
import { HoverBoxRight } from 'components';

const BorderGradient = css`
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box; /* !importanté */
    outline: solid 5px transparent; /* !importanté */
    border-radius: 0.5rem;
    background-color: white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -5px; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
    }
`;

const PlaylistSelectionCard = ({
    playlist,
    active,
    setActive,
    onEditPlaylist,
    setEditAssignmentModal,
    setDeletePlaylistModal,
    setDeleteAssignmentModal,
    setDeletePlaylistId,
    setDeleteAssignmentId,
    HandleAssignAgain,
    isParent
}) => {
    const [showThreeDotPopup, setThreeDotShowPopup] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    // if playlist has assignments find total number of students complete
    let totalAssignedStudents = 0;
    let totalStudentsCompleted = 0;
    if (playlist.assignments.length > 0) {
        playlist.assignments.forEach((assignment) => {
            totalAssignedStudents = assignment.student_instances.length;
            totalStudentsCompleted = assignment.student_instances.filter((student) => student.completed === true).length;
        });
    }

    const actions = [
        {
            name: 'Edit Playlist Videos',
            onAction: () => onEditPlaylist(playlist),
            color: '#5A5353'
        },
        {
            name: 'Assign Again',
            onAction: () => HandleAssignAgain(),
            color: '#5A5353'
        },

        { separator: true },
        {
            name: 'Delete Playlist',
            onAction: () => {
                setDeletePlaylistModal(true);
                setDeletePlaylistId(playlist.id);
            },
            color: '#E04E4E'
        }
    ];

    const parentActions = [
        {
            name: 'Edit Playlist Videos',
            onAction: () => onEditPlaylist(playlist),
            color: '#5A5353'
        },
        { separator: true },
        {
            name: 'Delete Playlist',
            onAction: () => {
                console.log('test;;;;;');
                setDeletePlaylistModal(true);
                setDeletePlaylistId(playlist.id);
            },
            color: '#E04E4E'
        }
    ];

    if (playlist.assignments.length > 0) {
        actions.push({ separator: true });
        playlist.assignments.map((assignment, index) => {
            actions.push({
                name: `Delete Assignment ${index + 1}`,
                due: moment.utc(assignment.due).local().format('MM-DD-YYYY'),
                onAction: () => {
                    setDeleteAssignmentModal(true);
                    setDeleteAssignmentId(assignment.id);
                },
                color: '#E04E4E'
            });
            return assignment;
        });
    }

    const assigned = totalAssignedStudents > 0;
    const completed = assigned && (totalStudentsCompleted === totalAssignedStudents);

    // logic for completion dot
    let completionDotColor = '#A5A5A5';
    if (totalStudentsCompleted > 0) completionDotColor = '#887be3';
    if (completed) completionDotColor = '#5FC17A';
    // console.log('totalStudentsCompleted', totalStudentsCompleted, 'totalAssignedStudents', totalAssignedStudents);
    return (
        <button
            tabIndex={0}
            tw='text-left bg-PurpleWhite rounded-lg flex justify-between items-center py-4 mt-4 cursor-pointer w-full text-EarieBlack text-lg'
            css={[playlist.id === active && BorderGradient]}
            onClick={() => setActive(playlist.id)}
            style={{
                maxHeight: '60px'
            }}
        >
            <div tw='pl-4 overflow-ellipsis truncate w-full maxWidth[100%]'>{playlist.name}</div>

            <div tw='w-full' css={isParent ? tw`maxWidth[475px] lg:maxWidth[175px]` : tw`maxWidth[105px]`}>
                {playlist.assignments.length > 0 && playlist.assignments[0].due ? moment.utc(playlist.assignments[0].due).local().format('MM/DD/YYYY') : ''}
            </div>

            {((playlist.assignments.length > 0) && !isParent) && (
                <ReactHoverObserver
                    onHoverChanged={() => setIsHovering(isHovering)}
                    onMouseOver={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <div tw='relative height[28px] width[28px] rounded-full flex items-center justify-center' style={{ background: completionDotColor }}>
                        {completed && (
                            <svg
                                width='14' height='12' viewBox='0 0 14 12' fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M11.7727 1.25256C12.0072 1.02925 12.3194 0.905788 12.6432 0.908239C12.9671 0.910689 13.2773 1.03886 13.5085 1.2657C13.7397 1.49253 13.8737 1.8003 13.8822 2.12406C13.8908 2.44781 13.7732 2.76223 13.5544 3.00097L6.91043 11.3101C6.79619 11.4331 6.6583 11.5319 6.50502 11.6004C6.35174 11.669 6.18621 11.7059 6.01833 11.709C5.85045 11.7121 5.68366 11.6813 5.52795 11.6185C5.37223 11.5557 5.23079 11.4621 5.11207 11.3434L0.706079 6.93738C0.583379 6.82305 0.484966 6.68518 0.416708 6.53198C0.34845 6.37879 0.311747 6.21342 0.308788 6.04573C0.305829 5.87805 0.336676 5.71148 0.399487 5.55598C0.462299 5.40047 0.555788 5.25921 0.674378 5.14062C0.792968 5.02203 0.934228 4.92854 1.08973 4.86573C1.24524 4.80292 1.4118 4.77208 1.57949 4.77503C1.74717 4.77799 1.91255 4.8147 2.06574 4.88295C2.21893 4.95121 2.35681 5.04963 2.47114 5.17232L5.95796 8.65748L11.741 1.28919C11.7514 1.27637 11.7626 1.26414 11.7743 1.25256H11.7727Z'
                                    fill='white'
                                />
                            </svg>
                        )}

                        {assigned && <HoverBoxRight
                            borderColor={completionDotColor} show={isHovering} backgroundColor='#fff' height='70px'
                            width='260px'>
                            <div tw='text-sm text-EarieBlack font-black text-center'>Completed Assignment</div>
                            <div tw='text-lg text-EarieBlack mt-0.5 text-center'>
                                {totalStudentsCompleted} of {totalAssignedStudents} Students
                            </div>
                        </HoverBoxRight>}

                    </div>
                </ReactHoverObserver>
            )}

            <button
                tw='relative overflow-visible'
                onClick={() => {
                    if (!showThreeDotPopup) {
                        setThreeDotShowPopup(true);
                    }
                }}
                // onBlur={() => setThreeDotShowPopup(false)}
            >
                <svg width='34' height='50' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <ellipse
                        cx='17' cy='35.189' rx='3.811' ry='4'
                        transform='rotate(-90 17 35.189)' fill='#5A5353' />
                    <ellipse
                        cx='17' cy='24.706' rx='3.811' ry='4'
                        transform='rotate(-90 17 24.706)' fill='#5A5353' />
                    <ellipse
                        cx='17' cy='15.179' rx='3.811' ry='4'
                        transform='rotate(-90 17 15.179)' fill='#5A5353' />
                </svg>
            </button>
            {showThreeDotPopup && <PopupMenu
                top='0px'
                right='0px'
                actions={isParent ? parentActions : actions}
                setShowPopup={setThreeDotShowPopup}
            />}
        </button>
    );
};

PlaylistSelectionCard.propTypes = {
    anyProp: PropTypes.any
};

export default PlaylistSelectionCard;
