import React, { useMemo } from 'react';
import { useVideoStats } from 'state/Student/VideoStats';
import { useVideos } from 'state/CatalogState';
import AchievementConstants from 'app/constants/AchievementConstants';
import AchievementCard from './AchievementCard';
import 'twin.macro';

const Achievements = ({ achievements, variant, columns }) => {
    const videoMap = useVideoStats();
    const videosArr = useVideos();

    // check to see if there is columns if not default to 3
    columns = columns ? columns : '3';
    const achievementNodes = useMemo(() => {
        // check for null achievements
        // figure out a better way to do this
        if (achievements.length === 0) {
            achievements.push({ nullAchievement: true });
        }

        return achievements.map((item, index) => {
            let nullAchievement = item.nullAchievement;
            let title = item.badge_type;
            // const video = videoMap[item.video_id];

            let videoTitle = '';
            if (item.video_id) {
                // videoTitle = videoMap[item.video_id].title;
                // videoTitle = videosArr.find((video) => video.id === item.video_id).title;
                const vv = videosArr.find((video) => video.id === item.video_id);
                if( vv ) {
                    videoTitle = vv.title;
                }
            }

            const config = AchievementConstants[title];
            // let Svg = null;
            let image = null;
            if (config) {
                image = config.image;
                // Svg = config.svg;
                if (config.title) title = config.title;
                else if (config.header) title = config.header;
            }

            return (
                <AchievementCard
                    image={image}
                    title={title}
                    nullAchievement={nullAchievement}
                    variant={variant}
                    key={index}
                    videoTitle={videoTitle}
                />
            );
        });
    }, [achievements, variant, videoMap, videosArr]);

    return (
        <div tw='flex flex-col md:grid md:gap-4' style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}>
            {achievementNodes}
        </div>
    );
};
export default Achievements;
