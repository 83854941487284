import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { NavLink } from 'react-router-dom';

const Tab = styled(NavLink)(({ variant, index }) => [
    tw`text-white relative float-right w-full text-base md:text-lg py-3 bg-SlateBlue flex justify-center rounded-t-none xsm:rounded-t-2xl 
    sm:max-w-sm hover:bg-SlateBlue sm:hover:bg-gradient-to-r to-SkyBlueCrayola from-DarkIndigo  active:bg-SlateBlue sm:active:bg-DarkIndigo active:text-white cursor-pointer border-r-2 border-SlateBlue`,
    
    css`&.active {  
        border-width: 0; 
        background-color: transparent;
        margin-bottom: -2px;
        border-color: #D44BA4;
        }`,
        
    variant === 'underline' && tw`bg-transparent hover:bg-transparent text-white`,
    variant === 'enclosed' && css`&.active {  color: #6160D9; background: white;}`,
    variant === 'dtc-child' && tw`bg-[#3E257A] border-[#1C0057] bg-opacity-30 w-1/3 rounded-t-lg xsm:rounded-t-none sm:max-w-[100%] text-xl font-normal py-0 sm:py-2 md:py-1 lg:py-4 xl:py-6 sm:hover:bg-gradient-to-r to-[#805CD6] from-[#805CD6]  hover:bg-opacity-0`,
    variant === 'dtc-child' && css`&.active {  border-width: 0; background: #4D3095;}`,
    variant === 'dtc-child' && index === 0 && tw` xsm:rounded-tl-lg xsm:rounded-tr-lg`,
    variant === 'dtc-child' && index === 1 && tw` xsm:rounded-tl-lg xsm:rounded-tr-lg`,
    variant === 'dtc-child' && index === 2 && tw` xsm:rounded-tl-lg xsm:rounded-tr-lg`
    // variant === 'underline' && isActive && tw`border-b-4 marginBottom[-2px] border-PinkCrayola bg-transparent`
]);

const Tabs = (props) => {
    const { children, variant, path, index, activeTab, activeTabHandler, isPhone } = props;
    const [hover, isHovering] = useState(false);

    function handleMouseOver(event) {
        isHovering(true);
    }

    function handleMouseOut(event) {
        isHovering(false);
    }

    return (
        <Tab
            aria-current='page'
            replace={true}
            to={path}
            variant={variant}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            index={index}
            // isPhone={isPhone}
            onClick={() => activeTabHandler && activeTabHandler(index)}

            // activeStyle={
            //     // I don't think this is working after upgrading tailwind / twin macro moved styles in the styled component above
            //     variant === 'enclosed' ? (hover ? null : tw`bg-white text-SlateBlue`) : variant === 'dtc-child' ? tw`border-0 bg-[#3E257A] ` : tw`border-b-4 marginBottom[-2px] border-PinkCrayola bg-transparent`
            // }

            css={[

                css`
                    @media only screen and (max-width: 500px) {
                        margin: 0 !important;
                    }
                `
            ]}
            children={({ isActive, variant }) => {
                return (
                    <>
                        {variant === 'dtc-child' && isActive ? <div tw='bg-gradient-to-r from-[#46A6F8] to-[#B15AFE] w-20 h-1 absolute top-0 m-auto left-0 right-0 rounded-b-sm ' /> : null}
                    </>
                );
            }}
        >
            {children}
        </Tab>

    );
};

export default Tabs;
