import React, { useMemo } from 'react';
import { css } from 'twin.macro';
import WhiteXButton from 'images/icons/WhiteXButton';

const BorderGradient = css`
    margin: auto;
    
    //width: 652px;
    //height: 362px;
    padding: 20px;
    
    position: relative;
    box-sizing: border-box;
    background: #fff;
    background-clip: padding-box;
    border: solid 15px transparent;
    border-radius: 20px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -15px;
        border-radius: inherit;
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
    }
`;

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 18px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #a5a5a5;
        border-radius: 20px;
        max-height: 60px;
        border: 6px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

const CloseButtonCss = css`
    background: white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: #6a7277;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 2.5px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    position: absolute;
    z-index: 1000000;

    &:hover {
        background: #dddddd;
    }

    &:active {
        background: #bbbbbb;
        filter: none;
    }
    :nth-last-child(1n) {
        padding-top: 0;
    }
`;

const QuizIntroAttempts = ({ previousScores, level, videoTitle, setIsOpen }) => {
    let levels = ['', 'Silver', 'Gold', 'Platinum'];

    const previousScoreList = useMemo(() => {
        if (!previousScores) return null;
        const scores = [...previousScores];
        // scores.reverse();
        return scores.map((score, index) => {
            // eslint-disable-next-line no-sparse-arrays
            let ordinalNum = (n) => (n += [, 'st', 'nd', 'rd'][(n % 100 >> 3) ^ 1 && n % 10] || 'th');
            return (
                <div tw='text-lg font-extrabold pt-4' key={index}>
                    {ordinalNum(index + 1)} Attempt: <span tw='text-SonicSilver font-normal'>{score}%</span>
                </div>
            );
        });
    }, [previousScores]);
    return (
        <div tw='bg-white relative rounded-2xl' css={[BorderGradient]}>
            <div tw='uppercase text-3xl font-black text-center pt-8'>{levels[level]} challenge attempts</div>
            <div tw='text-2xl font-extrabold text-center'>{videoTitle}</div>
            <div tw='relative maxHeight[188px] overflow-y-auto mt-10 '>
                <div tw='flex justify-center items-center flex-col h-full  ' css={[ScrollBarStyle]}>
                    {previousScoreList}
                </div>
            </div>

            <div tw='right[0] top[0px] sm:top[-40px] sm:right[-40px]' onClick={() => setIsOpen(false)} css={[CloseButtonCss]}>
                <WhiteXButton style={{ color: '#6A7277' }} />
            </div>
        </div>
    );
};

export default QuizIntroAttempts;
