/**
 * Created by jasonduncan on 7/13/18.
 */
import React from 'react';
import BalanceText from 'react-balance-text';
import { css, styled } from 'twin.macro';
import { wrapTitleParens } from '../../../../../lib/string';

const lineClampCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

`;


const QuizHeader = (props) => {

    return (
        <div
            tw='block justify-between  items-center font-Poppins'
            className={props.className}
        >
            <div>
                {props.children}
            </div>

        </div>
    );
};

export default QuizHeader;
