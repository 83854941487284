import { QuizFinalScoreMessage } from 'app/containers/shared/QuizSessionContainer/QuizConstants';
import tw from 'twin.macro';
import { useToast } from 'hooks/toast';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { level_styles, level_titles } from 'app/constants/constants';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { useDevTool } from '../../../../../lib/DevTool/DevTool';

import QuizPanel from '../components/QuizPanel';
import SubmitButton from '../components/SubmitButton';
import QuizFinishedMessage from '../animation/QuizFinishedMessage';
import { QuizSessionPage } from '../QuizSessionPage';
import { useVideoStatsActions } from '../../../../../state/Student/VideoStats';
import { useTeacher } from '../../../../../state/User/UserState';

// the buttons used on the quiz finished screen
const ActionButton = tw(SubmitButton)`max-w-[255px] m-[auto] sm:w-[unset] grow mx-5 my-4 whitespace-nowrap text-lg font-extrabold`;

const QuizFinished = ({ video_id, video, quizSession, colors, quiz_id, initQuizSession, endQuizSession, hideProgressHeader }) => {
    const navigate = useNavigate();
    const showToast = useToast();
    const { loadStats } = useVideoStatsActions();
    const teacher = useTeacher();
    const [loading, setLoading] = React.useState(false);
    // const video = useQuizVideo(quiz_id, video_id);
    // get the quiz, context, and instance
    const { quiz, instance, context } = quizSession;
    useDevTool('quiz finished', { video, video_id, quiz, quiz_id });
    // take another quiz
    const takeQuiz_old = useCallback(() => {
        // reset the quiz session and grab a new one
        console.log('take quiz clicked');
        console.assert(quiz_id, 'quiz_id missing');
        const next_quiz_id = video?.quiz_id ?? quiz_id;
        if (instance?.instance) {
            const number_correct = instance.instance.number_correct;
            const number_total = instance.instance.number_incorrect + number_correct;
            const perfect = number_correct === number_total;
            setLoading(true);
            if (perfect) {
                // take the next quiz
                const level = Math.max(1, Math.min(3, instance.level + 1));
                if (teacher) {
                    // go to the next quiz
                    navigate(`/quiz/${next_quiz_id}/?level=${level}`, { replace: true });
                } else {
                    // load the stats and then begin the next quiz
                    if( next_quiz_id !== quiz_id ) {
                        navigate(`/quiz/${next_quiz_id}?video_id=${video?.id}&level=${level}`, { replace: true });
                    } else {
                        loadStats();
                        initQuizSession(next_quiz_id, level);
                    }
                }
            } else {
                // retake this quiz
                if (quiz_id) {
                    // load the stats and then re-take the quiz
                    loadStats();
                    initQuizSession(quiz_id);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, initQuizSession, instance, quiz_id, teacher]);

    const takeQuiz = useCallback(() => {
        // reset the quiz session and grab a new one
        console.log('take quiz clicked');
        console.assert(quiz_id, 'quiz_id missing');
        const next_quiz_id = video?.quiz_id ?? quiz_id;
        if (instance?.instance) {
            const number_correct = instance.instance.number_correct;
            const number_total = instance.instance.number_incorrect + number_correct;
            const perfect = number_correct === number_total;
            setLoading(true);
            if (perfect) {
                // take the next quiz
                const level = Math.max(1, Math.min(3, instance.level + 1));
                console.log('<QuizFinished> Next quiz level: ', level, instance.level);

                // go to the next quiz
                navigate(`/quiz/${next_quiz_id}?video_id=${video_id ?? video?.id}&level=${level}`, { replace: true });
            } else {
                // retake this quiz
                initQuizSession(quiz_id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, initQuizSession, instance, quiz_id, teacher]);


    // load new stats on exit
    const handleExit = () => endQuizSession();
    const watchVideo = () => endQuizSession();

    // check the quiz session
    if (!quizSession || !quiz || !instance || !context) {
        console.error('Error: invalid quiz session', quizSession);
        return null;
    }

    // get the level details
    const level = instance.level;
    const number_correct = instance.instance.number_correct;
    const number_total = instance.instance.number_incorrect + number_correct;
    const perfect = number_correct === number_total;
    const score = (number_correct / parseFloat(number_total)) * 100; // score: 0 - 100
    // console.log('Finished: correct: ', number_correct, ' total: ', number_total);

    // construct the message
    let message = [
        <div>
            You got &nbsp;
            <span className='bold'>
                {number_correct} out of {number_total}
            </span>
            &nbsp; correct.
        </div>,
        <br />
    ];

    if (level === 0) {
        // messaging for level 0 (warmup)
        message = (
            <div>
                You got&nbsp;
                <span className='bold'>
                    {number_correct} of {number_total}
                </span>
                &nbsp; correct and
                <br />
                unlocked this video!
            </div>
        );

        let warmup_messages = [
            'You can do it! This video will help :)',
            'You’re moving in the right direction. Check out the video to learn more. ',
            'Good start! Learn even more by watching the video.',
            'Nicely done! Watch the video and keep up the good work. ',
            ['Impressive! You already know a lot about this topic.', <br />, 'Watch the video to keep crushing it :) '],
            [
                'You aced it! You’re great at this topic. ',
                <br />,
                <br />,
                'Plus, you just earned the Perfect Warmup achievement',
                <br />,
                <br />,
                'Watch the video and keep progressing.'
            ]
        ];

        const message_index = Math.max(Math.min(number_correct, 5), 0);
        // messageText = WarmupFinishedMessage[message_index];
        message = warmup_messages[message_index];
    } else {
        /*
        If got 10 of 10:
        */
        if (perfect) {
            // silver
            if (level === 1)
                message = (
                    <div className='finished'>
                        Great job. You got <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
            // gold
            else if (level === 2)
                message = (
                    <div className='finished'>
                        Amazing. You got <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
            // platinum
            else if (level === 3)
                message = (
                    <div className='finished'>
                        You’re a Muzology rockstar. <br />
                        You’ve gone <span className={level_styles[level]}>{level_titles[level]}!</span>
                    </div>
                );
        } else {
            // get the message from the message list
            const message_index = Math.max(Math.min(number_correct, 10), 0);
            message.push(QuizFinalScoreMessage[message_index]);
        }
    }

    // create the buttons

    // "Watch video" is default
    const buttons = [
        <ActionButton
            key='watch-video'
            className='aqua watch-video-button'
            title='Watch Video'
            onClick={watchVideo}
            data-testid='watch-video-button'
            aria-label='Watch Video'
        />
    ];

    // add a "re-take challenge" button
    if (level !== 0 && !(level === 3 && perfect)) {
        buttons.push(
            <ActionButton
                key='re-take-quiz'
                className='pink retake-challenge-button'
                title={perfect ? 'Next Challenge' : 'Retake Challenge'}
                onClick={takeQuiz}
                data-testid='retake-challenge-button'
                aria-label='Retake Challenge'
            />
        );
    }

    return (
        <QuizSessionPage data-testid='quiz-finished-container'>
            <LoadingOverlay show={loading} fixed={true} />
            <QuizPanel
                className={`question-finished-panel ${level_styles[level]}`}
                title={quiz.title}
                level={level}
                onCancel={handleExit}
                colors={colors}
                style={{ minHeight: '600px' }}
                progress={context ? context.progress : [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                hideProgressHeader={hideProgressHeader}
                data-testid='quiz-finished-panel'
            >
                <QuizFinishedMessage
                    key='quiz-finished-message'
                    message={message}
                    level={level}
                    startScore={0}
                    score={score}
                    buttons={buttons}
                    video={video}
                    videoTitle={video?.title}
                    correct={number_correct}
                    total={number_total}
                    watchVideo={watchVideo}
                />
            </QuizPanel>
        </QuizSessionPage>
    );
};

export default QuizFinished;
