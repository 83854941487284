import React from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { Button } from 'components';

const TitleDivider = ({ header, hasBorder, search, showButton, buttonAction }) => {
    return (
        <div css={[tw`flex items-end flex-wrap space-y-4 lg:space-y-0 justify-start`, hasBorder && tw`border-b-2 border-white py-3`]}>
            {header && <h1 tw='text-2xl text-white mr-4'>{header}</h1>}
            <div
                css={[
                    css`
                        margin-top: 0 !important;
                        @media only screen and (max-width: 730px) {
                            margin-top: 20px !important;
                        }
                        @media only screen and (max-width: 499px) {
                            margin-top: 0px !important;
                            margin-left: 20px;
                        }
                    `
                ]}
            >
                {search}
            </div>
            {showButton && (
                <div tw='ml-0 md:ml-4 lg:ml-auto mt-5 lg:mt-0 hidden xsm:block'>
                    <Button onClick={buttonAction} variant='teal-large'>
                        <div tw='flex items-center justify-center'>
                            <span tw='mr-0.5'>
                                <svg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M11.935 6.206a.914.914 0 0 0-.948-.948l-4.007.036.035-4.008A.914.914 0 0 0 6.068.34a.954.954 0 0 0-.965.965L5.067 5.31l-4.008.036a.954.954 0 0 0-.965.965c-.002.273.132.5.313.68.18.18.407.315.68.313l4.008-.036-.036 4.008c-.002.273.132.5.313.68.18.18.407.315.68.313a.954.954 0 0 0 .965-.965l.036-4.008 4.007-.036c.457-.095.87-.508.875-1.055Z'
                                        fill='#fff'
                                    />
                                </svg>
                            </span>
                            NEW PLAYLIST
                        </div>
                    </Button>
                </div>
            )}
        </div>
    );
};

TitleDivider.propTypes = {
    placeholder: PropTypes.string,
    header: PropTypes.string,
    search: PropTypes.any,
    // search: PropTypes.element,
    hasBorder: PropTypes.bool,
    showButton: PropTypes.bool,
    buttonAction: PropTypes.func
};

export default TitleDivider;
