import React from 'react';
import { css } from 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';

import Background from '../../app/containers/child/QuizSessionContainer/components/backgrounds/Gold.png';
import LoadingRecord from '../../app/containers/child/QuizSessionContainer/components/backgrounds/Loading_Record.png';

const rotateCss = css`
    -webkit-animation:spin 30s linear infinite;
    -moz-animation:spin 30s linear infinite;
    animation:spin 30s linear infinite;
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
`;

const LoadingScreen = () => {
    return (
        <div tw='fixed inset-0 h-full w-full flex justify-center items-center'>
            <BackgroundImage image={Background} />
            <div tw='absolute inset-0 h-full w-full flex flex-col justify-center items-center'>
                <img src={LoadingRecord} css={[rotateCss]}/>
                <div tw='font-Poppins text-2xl text-white mt-12'>Please Wait...</div>
            </div>
        </div>
    );
};

export default LoadingScreen;
