import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import RecordImageSilver from 'static/images/records/DTC/silver.png';
import RecordImageGold from 'static/images/records/DTC/gold.png';
import RecordImagePlatinum from 'static/images/records/DTC/platinum.png';

const ProgressBar = styled.div(({ score }) => [
    tw` bg-[#02AC6F] rounded-xl text-center h-[15px] text-xs `,
    score < 100 && tw`bg-[#46A6F8] rounded-none rounded-l-lg`
]);

const VideoStatsMobileCardDTC = ({ stats }) => {
    const navigate = useNavigate();
    const levels = stats?.stats?.quiz_levels;

    // check if stats object is empty this is the same as null
    let emptyStats = stats && Object.keys(stats).length === 0 && stats.constructor === Object;
    let silverScore = levels ? levels[1]?.score ?? 0 : 0;
    let SilverRecord = !stats || emptyStats ? RecordImageSilver : stats?.stats?.current_level < 1 ? RecordImageSilver : RecordImageSilver;
    let goldScore = levels ? levels[2]?.score ?? 0 : 0;
    let GoldRecord = !stats || emptyStats ? RecordImageGold : stats?.stats?.current_level < 2 ? RecordImageGold : RecordImageGold;
    let platScore = levels ? levels[3]?.score ?? 0 : 0;
    let PlatRecord = !stats || emptyStats ? RecordImagePlatinum : stats?.stats?.current_level < 3 ? RecordImagePlatinum : RecordImagePlatinum;
    return (
        <div tw='bg-[#3E257A] to-PholxPink from-SkyBlueCrayola px-4 pt-4 text-white'>
            <div tw='flex'>
                <div tw='cursor-pointer flex items-center min-w-[100px]' onClick={() => navigate(`/video/${stats.id}`)}>
                    <img tw='h-16 w-24 object-cover rounded' src={stats.url_thumbnail} alt='thumbnail' />
                </div>
                <div tw='mx-3 w-full'>
                    <span tw='block mb-2 text-[14px] font-normal leading-5 text-white' style={{ fontFamily: 'Poppins', lineHeight: '120%' }} onClick={() => navigate(`/video/${stats.id}`)}>{stats.title}</span>
                    <div tw='flex justify-between items-center'>
                        <span tw='mr-4'>
                            <span tw='text-xs font-light tracking-tight uppercase text-right text-[#BCB5CD] font-[Poppins] leading-5 pr-2'>
                                VIEWS:
                            </span>

                            {stats.stats.view_count}
                        </span>
                        <div>
                            <span>
                                <span tw='text-xs font-light tracking-tight uppercase text-right text-[#BCB5CD] font-[Poppins] leading-5 pr-2'>
                                    WARMUP: </span>
                                <span tw='mt-2'>{stats.stats.quiz_levels[0]?.score ?? 0}%</span>
                            </span>
                        </div>
                    </div>


                    <div tw='flex space-x-2 mt-2'>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-10 sm:w-8 lg:w-16 max-w-[32px]' src={SilverRecord} alt='img' />
                            {levels[1]?.score ?
                                <>
                                    {levels[1]?.completions > 0 ?
                                        <span
                                            tw='text-[8px] font-medium  absolute top-[-0.25rem] right-[-0.2rem] px-[3px] py-[1px] bg-[#02AC6F] text-white rounded-full'>
                                            {levels[1]?.completions + 1}X
                                        </span> : null}

                                    <div tw='w-full bg-[#1C0057] font-medium absolute h-[15px] max-w-[43px] bottom-[-0.38rem] left-0 right-0 text-white rounded-xl'>
                                        <ProgressBar score={levels[1]?.score} css={{ width: `${levels[1]?.score}%` }} />
                                    </div>
                                    <div tw='text-[10px] absolute bottom-[-0.38rem]'> {levels ? `${levels[1]?.score}%` ?? '' : ''}</div>
                                </> :
                                null}

                            {(!stats || emptyStats) && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {stats?.stats?.current_level < 1 && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-10 sm:w-8 lg:w-16 max-w-[32px]' src={GoldRecord} alt='img' />
                            {levels[2]?.score ?
                                <>
                                    {levels[2]?.completions > 0 ?
                                        <span
                                            tw='text-[8px] font-medium  absolute top-[-0.25rem] right-[-0.2rem] px-[3px] py-[1px] bg-[#02AC6F] text-white rounded-full'>
                                            {levels[2]?.completions + 1}X
                                        </span> : null}
                                    <div tw='w-full bg-[#1C0057] font-medium absolute h-[15px] max-w-[43px] bottom-[-0.38rem] left-0 right-0 text-white rounded-xl'>
                                        <ProgressBar score={levels[2]?.score} css={{ width: `${levels[2]?.score}%` }} />
                                    </div>
                                    <div tw='text-[10px] absolute bottom-[-0.38rem]'> {levels ? `${levels[2]?.score}%` ?? '' : ''}</div>
                                </> :
                                null}
                            {(!stats || emptyStats) && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5 '>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {stats?.stats?.current_level < 2 && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div tw='relative flex items-center justify-center'>
                            <img tw='w-14 sm:w-10 lg:w-20 max-w-[32px]' src={PlatRecord} alt='img' />
                            {levels[3]?.score ?
                                <>
                                    {levels[3]?.completions > 0 ?
                                        <span
                                            tw='text-[8px] font-medium  absolute top-[-0.25rem] right-[-0.2rem] px-[3px] py-[1px] bg-[#02AC6F] text-white rounded-full'>
                                            {levels[3]?.completions}X
                                        </span> : null}
                                    <div tw='w-full bg-[#1C0057] font-medium absolute h-[15px] max-w-[43px] bottom-[-0.42rem] left-0 right-0 text-white rounded-xl'>
                                        <ProgressBar score={levels[3]?.score + 1} css={{ width: `${levels[3]?.score}%` }} />
                                    </div>
                                    <div tw='text-[10px] absolute bottom-[-0.4rem]'> {levels ? `${levels[3]?.score}%` ?? '' : ''}</div>
                                </> :
                                null}
                            {(!stats || emptyStats) && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {stats?.stats?.current_level < 3 && (
                                <div tw='absolute bg-gray-500 bg-opacity-60 w-full h-full rounded-full flex justify-center items-center'>
                                    <div tw='absolute -top-1 -right-0.5'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
                                            <circle cx='6.8712' cy='6.64024' r='6.64024' fill='#FA6778' />
                                            <path d='M9.85153 6.64026C9.85153 6.24076 9.5131 5.91587 9.09695 5.91587H8.71967V4.82929C8.71967 3.83071 7.87341 3.01831 6.83323 3.01831C5.79305 3.01831 4.9468 3.83071 4.9468 4.82929V5.91587H4.56951C4.15337 5.91587 3.81494 6.24076 3.81494 6.64026V9.53782C3.81494 9.93732 4.15337 10.2622 4.56951 10.2622H9.09695C9.5131 10.2622 9.85153 9.93732 9.85153 9.53782V6.64026ZM5.70137 4.82929C5.70137 4.23022 6.2092 3.7427 6.83323 3.7427C7.45727 3.7427 7.96509 4.23022 7.96509 4.82929V5.91587H5.70137V4.82929Z' fill='white' />
                                        </svg>
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div tw='w-full flex justify-center mt-3.5'>
                <div tw='bg-[#252550] w-full h-[1px] box-shadow[0px 1px 1px rgba(255, 255, 255, 0.05)]' />
            </div>


        </div>
    );
};

VideoStatsMobileCardDTC.propTypes = {
    anyProp: PropTypes.any
};

export default VideoStatsMobileCardDTC;
