import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import BalanceText from 'react-balance-text';
import PropTypes from 'prop-types';
import { Modal, QuizIntroAttempts } from 'components';
import { useAssignments } from 'state/Student/AssignmentsState';
import { WarmupFinishedMessage } from 'app/containers/shared/QuizSessionContainer/QuizConstants';
import { useMedia } from 'react-use';
import moment from 'moment';
import { useUser } from 'state/User/UserState';
import { useVideoWithStats } from '../../../../../state/Student/VideoStats';
import RecordProgress from '../components/RecordProgress';
import GradientBorder from '../../../../../components/QuizIntro/GradientBorder';
import { challenge_titles, level_titles } from '../../../../constants/constants';
import { recordImageURL } from '../../../../../static/records/FilledRecordsAssets';
import AchievementConfig from '../../../../constants/AchievementConstants';
import { default as DTCAchievementConfig } from '../../../child/Progress/AchievementConstants';
import { bouncing_balls, shooting_stars, silver_confetti, spinning_stars } from './particles';
import ParticleBackground from './ParticleBackground';

const particle_defs = [
    shooting_stars, // 0
    bouncing_balls,
    // silver_confetti,
    spinning_stars,
    bouncing_balls,
    bouncing_balls,
    shooting_stars
];

const Container = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    justify-content: stretch;
    align-items: center;

    font-family: Avenir, sans-serif;
    line-height: normal;

    //height: 460px;
    //padding-top: 80px;
    //padding-bottom: 80px;
    //padding-top: 50px;

    color: white;

    .quiz-finished-message-foreground {
        width: 100%;
        max-width: 835px;
        height: 590px;
        //background: linear-gradient(180deg, #1e215f 0%, #070a3b 100%);
        margin: auto;
        position: relative;

        .gradient-border-contents {
            background: linear-gradient(180deg, #1e215f 0%, #070a3b 100%);
        }

        //border: 19px solid;
        //border-image-slice: 1;
        //border-width: 19px;
        //border-image-source: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);

        /* box-sizing: border-box;
        background-clip: padding-box;
        border: solid 15px transparent;
        border-radius: 20px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -15px;
            border-radius: inherit;
            background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        } */
    }

    .highlight-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: -55px;
        bottom: 0;
        width: 100%;
        margin: auto;
    }

    .message {
            /* color: ${(props) => (props.score === 100 && props.level === 1 ? 'aquamarine' : 'black')}; */
        color: #fff;

        font-size: 30px;
        font-family: Avenir, serif;
        font-weight: 500;

        text-align: center;
        //padding-left: 50px;
        //padding-right: 50px;

        .bold {
            font-family: Avenir, sans-serif;
            font-weight: 800;
        }

        .silver {
            color: #b8bcbf;
            text-transform: uppercase;
        }

        .gold {
            color: #ffcb2c;
            text-transform: uppercase;
        }

        .platinum {
            color: #27c0d9;
            text-transform: uppercase;
        }

        .finished {
            color: white;
        }
    }

    .subtitle {
        //color: #4f5a60;

        font-size: 26px;
        font-family: Avenir, serif;
        font-weight: 500;

        text-align: center;
    }
`;

const ParentHeightCss = css`
    max-height: 594px;
`;

const BadgeAchievedMessage = ({ badge, image, header }) => {
    return (
        <div tw='flex items-center justify-center pt-2 text-lg font-extrabold px-10'>
            <img tw='height[48px] pr-2' src={image} alt={header} />
            <div tw='text-left'>You earned the {header} Achievement!</div>
        </div>
    );
};
const VideoUnlockedHeader = () => {
    return (
        <div tw='flex justify-center text-center'>
            <svg
                width='35' height='48' viewBox='0 0 35 48' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M23.502 20.9691V11.8744C23.502 8.53963 20.802 5.8112 17.502 5.8112C14.202 5.8112 11.502 8.53963 11.502 11.8744H5.50195C5.50195 5.17457 10.872 -0.251953 17.502 -0.251953C24.132 -0.251953 29.502 5.17457 29.502 11.8744V20.9691H32.502C34.152 20.9691 35.502 22.3333 35.502 24.0007V45.2217C35.502 46.8891 34.152 48.2533 32.502 48.2533H2.50195C0.851953 48.2533 -0.498047 46.8891 -0.498047 45.2217V24.0007C-0.498047 22.3333 0.851953 20.9691 2.50195 20.9691H23.502ZM20.502 42.1902L19.422 35.6723C20.952 34.9447 22.002 33.3986 22.002 31.5796C22.002 29.0634 19.992 27.0323 17.502 27.0323C15.012 27.0323 13.002 29.0634 13.002 31.5796C13.002 33.3986 14.052 34.9447 15.582 35.6723L14.502 42.1902H20.502Z'
                    fill='url(#paint0_linear_8971_146544)'
                />
                <defs>
                    <linearGradient
                        id='paint0_linear_8971_146544' x1='29.6465' y1='6.76086' x2='-0.436443'
                        y2='34.9323' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='#F523C7' />
                        <stop offset='1' stopColor='#FFA724' stopOpacity='0.97' />
                    </linearGradient>
                </defs>
            </svg>
            <span tw='pl-2.5 pt-2.5 font-extrabold text-2xl' style={{ color: '#FF64CA' }}>
                VIDEO UNLOCKED!
            </span>
        </div>
    );
};

export const VideoWarmupMessage = ({ correct, total, isDtc }) => {
    let badges = null;
    if (correct === total) {
        const badge = isDtc ? DTCAchievementConfig.single_video_pretest_perfect_score : AchievementConfig.single_video_pretest_perfect_score;
        const badeImage = isDtc ? badge.non_ribbon_image : badge.image;
        badges = (<BadgeAchievedMessage badge={badge} image={badeImage} header={badge.header} />);
    }
    return (
        <div tw='flex flex-col justify-center text-center mt-10 mb-7'>
            <div tw='text-4xl sm:fontSize[48px] color[#1CC8E1]'>
                {`Score: ${correct} out of ${total}`}
            </div>
            {badges}
            <div tw='text-lg font-extrabold mt-8 px-4'>
                {WarmupFinishedMessage[correct % WarmupFinishedMessage.length].map(text => <BalanceText key={text}>{text}</BalanceText>)}
            </div>
            {/*
            <BalanceText tw='text-lg font-extrabold mt-8 px-4'>
                {WarmupFinishedMessage[correct % WarmupFinishedMessage.length]}
            </BalanceText>
            */}
        </div>
    );
};

const QuizFinishedMessage = ({ buttons, delay, level, message, score, startScore, video, videoTitle, correct, total, watchVideo }) => {
    const isSmall = useMedia('(min-width: 640px)');
    const [, setPose] = useState('score0');
    const [isOpen, setIsOpen] = useState(false);
    const user = useUser();
    const isDtc = user.is_homeschool_account;
    const assignments = useAssignments();

    const currentScore = score;
    const excludeZeroFromCurrentScore = currentScore > 0 && currentScore;

    /*
    const startAnimation = useCallback(() => {
        console.log('Start Animation');
        let initial = `score${startScore}`;
        setPose(initial);
        setTimeout(() => {
            console.log('Animation timeout');
            let target = `score${score}`;
            setPose(target);
        }, delay);
    }, [delay, score, startScore]);
    // begin animations
    useEffect(() => {
        if (level !== 0) startAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    */


    // get video with stats
    let videoWithStats = useVideoWithStats(video?.id);

    // get the previous attempts from the stats
    let prev_attempts = [];
    if (videoWithStats?.stats?.quiz_levels && videoWithStats?.stats?.quiz_levels[level]) {
        if (videoWithStats?.stats?.quiz_levels[level].instances) {
            const instance = videoWithStats?.stats?.quiz_levels[level]?.instances;
            prev_attempts = instance.filter(({ extra_challenge }) => !extra_challenge).map(({ score }) => score);
        }
    }

    // filter assignments related to the video
    let videoAssignment = [];
    assignments.forEach((assignment) => {
        assignment.items.forEach((item) => {
            if (item === video.id) videoAssignment.push(assignment);
        });
    });
    // sort assignment by most recent
    let videoAssignmentByDate = videoAssignment.sort((a, b) => moment(b.created).diff(moment(a.created)));

    // return this.props.buttons;
    // message
    let message_color = 'white';
    // if (level === 0 && score === 100)
    //     message_color = 'green';
    // let messageNode = (
    //     <div
    //         key='message'
    //         className='message'
    //         style={{ color: message_color }}
    //     >
    //         {message}
    //     </div>
    // );

    // progress indicator
    let progress = null;

    // particles background
    let particlesBackground = null;
    let highlight = null;

    // create the particle background
    if (level !== 0 && score === 100) {
        particlesBackground = <ParticleBackground params={particle_defs[level % particle_defs.length]} />;
        // highlight overlay
        // highlight = <HighlightAnimation src={highlight_overlay} className={'highlight-overlay'} />;
    }
    // console.log('level', level);
    // particlesBackground = <ParticleBackground params={particle_defs[level]} />;


    // create the progress bar
    if (level === 0) {
        // warmup level
        progress = (
            <VideoWarmupMessage
                key='warmup-message'
                correct={correct}
                total={total}
                isDtc={isDtc}
            />
        );
    } else if (isSmall) {
        // progress bar
        progress = (
            <RecordProgress
                key='progress'
                score={score}
                level={level}
                // onClickRecord={startAnimation}
                tw='pt-4 paddingBottom[20px]'
            />
        );
    }
    //
    //     font-family: Avenir;
    //     font-style: normal;
    //     font-weight: 800;
    //     font-size: 25.5255px;
    //     line-height: 35px;
    //     text-align: center;
    //     color: #FF64CA;
    const recordProgress = recordImageURL(level, currentScore);

    // construct a quiz title
    const quizTitle = level === 0 ? 'Warmup' : (currentScore === 100 ? `YOU GOT ${level_titles[level]}!` : challenge_titles[level]);

    /*
    if (level === 0) {
        const header = (
            <div tw='z-10 flex justify-center marginTop[40px] sm:marginTop[25px]'>
                <VideoUnlockedHeader />
            </div>
        );
        return (
            <Container
                key='quiz-finished-container'
                lassName='quiz-finished-message-container'
                level={level}
                score={score}
                tw='relative w-full h-full m-auto background[linear-gradient(127.46deg, #2D0569 13.37%, #4C3FC1 71.65%)]'
            >
                {/!*
                <motion.div tw='w-full'>
                    {particlesBackground}
                    {highlight}
                *!/}

                <QuizInfoScreen
                    variant='dark'
                    level={level}
                    currentScore={currentScore}
                    videoTitle={videoTitle}
                    attempts={prev_attempts}
                    header={header}
                >
                    {progress}
                </QuizInfoScreen>
            </Container>
        );
    }
    */

    return (
        <Container tw='relative w-full h-full m-auto' data-testid='quiz-finished'>

            {/*
            <motion.div tw='w-full'>
                {highlight}
            */}

            {particlesBackground}

            <div tw='absolute inset-0 m-auto sm:maxWidth[873px] sm:maxHeight[590px]'>
                <GradientBorder variant='dark' border={level === 0 ? 'orange' : null}>
                    {level === 0 ? (
                        <div tw='absolute z-10 flex justify-center right[0] left[0] top[40px] sm:top[25px]'>
                            <VideoUnlockedHeader />
                        </div>
                    ) : (
                        <div tw='top[-50px] sm:hidden absolute z-10 m-auto right[0] left[0] sm:top[1.8125rem] maxWidth[10rem] sm:maxWidth[12.1875rem]'>
                            <img src={recordProgress} tw='w-full maxWidth[10rem] sm:maxWidth[12.1875rem]' alt='Record progress' />
                            <div tw='absolute inset-0 text-white font-black text-4xl flex items-center justify-center'>{currentScore}</div>
                        </div>
                    )}
                    <div
                        tw='text-white flex justify-center uppercase font-black text-center text-2xl sm:text-5xl'
                        css={[
                            level !== 0 && tw`paddingTop[200px] sm:paddingTop[32px]`,
                            level === 0 && tw`paddingTop[120px] sm:paddingTop[95px]`
                        ]}
                    >
                        {quizTitle}
                    </div>
                    <div tw='flex justify-center font-black text-center text-base sm:text-2xl pt-2.5'>
                        {video?.title}
                    </div>

                    {progress}

                    <div tw='pt-10 sm:pt-0 flex flex-col sm:flex-row items-stretch m-[20px auto 0 auto] sm:w-[100%] max-w-[300px] sm:max-w-[100%] px-0 justify-center'>
                        {buttons}
                    </div>

                    {(level !== 0 && prev_attempts.length > 0) &&
                        <div tw='flex justify-center pt-6 pb-3.5'>
                            <div tw='text-white text-lg font-extrabold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                                See Attempts
                            </div>
                        </div>
                    }

                    {/*
                    {level !== 0 && (
                        <React.Fragment>
                            <div tw='flex justify-center pt-6 pb-3.5'>
                                <div tw='text-white text-lg font-extrabold underline cursor-pointer' onClick={() => setIsOpen(true)}>
                                    {prev_attempts.length > 0 && 'See Attempts'}
                                </div>
                            </div>
                            <div tw='absolute' css={[ProgressBarContainerCss]}>
                                {videoAssignmentByDate.length >= 1 && (
                                    <VideoPlayerProgress
                                        quiz_level_goal={videoAssignmentByDate[0].quiz_level_goal}
                                        quiz_score_goal={videoAssignmentByDate[0].quiz_score_goal}
                                        stats={videoWithStats.stats}
                                        ParentBorderGradient={ParentBorderGradient}
                                        ParentHeightCss={ParentHeightCss}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    */}
                </GradientBorder>
            </div>

            {/*
            </motion.div>
            */}

            <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`maxWidth[929px] mx-auto`}>
                <QuizIntroAttempts videoTitle={videoTitle} level={level} previousScores={prev_attempts} setIsOpen={setIsOpen} />
            </Modal>
        </Container>
    );
};

QuizFinishedMessage.propTypes = {
    startScore: PropTypes.number,
    buttons: PropTypes.any,
    delay: PropTypes.number,
    level: PropTypes.number,
    message: PropTypes.any,
    score: PropTypes.number
};

export default QuizFinishedMessage;
