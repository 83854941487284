export const student = {
    user: {
        id: 138316,
        username: 'student1',
        first_name: 'student 1',
        last_name: 'test-roster',
        date_joined: '2019-05-04T06:25:04.827828',
        full_username: 'student1'
    },
    grade_level: 0,
    classes: ['f0b607bc-e1ba-4dff-bbbd-d4beaa04ae60', '62bfa80c-c9f0-4c48-880b-75c196972bab'],
    assignments: [],
    video_stats: {
        'dc948ec5-6d14-42c5-a5fa-3cf93c18fd52': {
            video_id: 'dc948ec5-6d14-42c5-a5fa-3cf93c18fd52',
            user_id: 138316,
            view_count: 3,
            last_viewed: '2019-07-16T19:13:28.978299',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T20:22:40.328356',
                    attempts: 1,
                    score: 60,
                    correct: 3,
                    incorrect: 2,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        '092fbe10-302a-4d0f-a867-695e6028f2e0': {
            video_id: '092fbe10-302a-4d0f-a867-695e6028f2e0',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2019-11-27T17:49:15.665717',
            favorite: false,
            current_level: 2,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T22:20:20.704576',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-11-27T17:49:21.281980',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        '6dd6e3d2-ed26-4465-99a0-ea12233d9a73': {
            video_id: '6dd6e3d2-ed26-4465-99a0-ea12233d9a73',
            user_id: 138316,
            view_count: 16,
            last_viewed: '2020-08-14T20:19:10.854107',
            favorite: true,
            current_level: 4,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-06T19:07:10.375813',
                    attempts: 1,
                    score: 0,
                    correct: 0,
                    incorrect: 5,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-30T17:15:29.463362',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-07-16T20:53:56.811324',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                3: {
                    level: 3,
                    date: '2019-11-27T21:00:31.325188',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_platinum',
                    badge_level: 1,
                    video_id: '6dd6e3d2-ed26-4465-99a0-ea12233d9a73',
                    datetime_awarded: '2020-08-13T18:38:50.881620',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_platinum-06375a51-85a1-4aad-8e27-f45dd178a17c',
                    quiz_instance: '06375a51-85a1-4aad-8e27-f45dd178a17c',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_platinum_record',
                    badge_level: 1,
                    video_id: '6dd6e3d2-ed26-4465-99a0-ea12233d9a73',
                    datetime_awarded: '2020-08-13T18:38:50.865851',
                    description: 'Unable to generate description for Badge: single_video_platinum_record-06375a51-85a1-4aad-8e27-f45dd178a17c',
                    quiz_instance: '06375a51-85a1-4aad-8e27-f45dd178a17c',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: '6dd6e3d2-ed26-4465-99a0-ea12233d9a73',
                    datetime_awarded: '2019-07-16T20:54:37.294256',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-7d5f730f-98f2-461b-8439-d9ab250f6a3c',
                    quiz_instance: '7d5f730f-98f2-461b-8439-d9ab250f6a3c',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '6dd6e3d2-ed26-4465-99a0-ea12233d9a73',
                    datetime_awarded: '2019-07-16T20:45:58.266556',
                    description: 'Perfect First Attempt on Exponent Rules Level 1',
                    quiz_instance: 'adf0a6b4-f7f9-4225-afab-27a7d83f0ddb',
                    additional_data: null
                }
            ]
        },
        'd84f2455-a8ff-4e6b-ba0c-a769ce11c04d': {
            video_id: 'd84f2455-a8ff-4e6b-ba0c-a769ce11c04d',
            user_id: 138316,
            view_count: 14,
            last_viewed: '2020-08-13T18:36:17.697784',
            favorite: true,
            current_level: 2,
            current_score: 70,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-13T17:09:10.676237',
                    attempts: 1,
                    score: 20,
                    correct: 1,
                    incorrect: 4,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-30T17:39:27.175375',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-08-14T17:39:42.713255',
                    attempts: 1,
                    score: 70,
                    correct: 7,
                    incorrect: 3,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08': {
            video_id: '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08',
            user_id: 138316,
            view_count: 6,
            last_viewed: '2019-07-22T20:01:39.196918',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T22:03:28.253662',
                    attempts: 1,
                    score: 60,
                    correct: 3,
                    incorrect: 2,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-30T22:16:54.270913',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-07-22T20:01:46.918815',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08',
                    datetime_awarded: '2019-07-22T20:02:35.627918',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-57eb3577-627b-451c-8dcb-82e7b5593acc',
                    quiz_instance: '57eb3577-627b-451c-8dcb-82e7b5593acc',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08',
                    datetime_awarded: '2019-07-22T20:02:35.348801',
                    description: 'Perfect First Attempt on Operations of Fractions Level 2',
                    quiz_instance: '57eb3577-627b-451c-8dcb-82e7b5593acc',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08',
                    datetime_awarded: '2019-05-30T22:18:01.194743',
                    description: 'Perfect First Attempt on Operations of Fractions Level 1',
                    quiz_instance: '1870aa21-d1b0-4110-ad21-f0be9e961fca',
                    additional_data: null
                }
            ]
        },
        'c1ebfe80-7924-4f73-aa7f-1b16fd6b4d6e': {
            video_id: 'c1ebfe80-7924-4f73-aa7f-1b16fd6b4d6e',
            user_id: 138316,
            view_count: 35,
            last_viewed: '2020-08-14T19:29:58.480240',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T17:14:07.390423',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-07-16T20:39:27.247353',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-08-09T21:36:37.027021',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: 'c1ebfe80-7924-4f73-aa7f-1b16fd6b4d6e',
                    datetime_awarded: '2019-08-14T17:37:57.766200',
                    description: 'Perfect First Attempt on Fractions into Decimals Level 2',
                    quiz_instance: '7d5aead0-770d-4a30-96ff-bf7f7ba87d3b',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: 'c1ebfe80-7924-4f73-aa7f-1b16fd6b4d6e',
                    datetime_awarded: '2019-07-16T20:40:19.770869',
                    description: 'Perfect First Attempt on Fractions into Decimals Level 1',
                    quiz_instance: '211c8d87-bdb9-4ce2-ac70-aed2a44d0cf5',
                    additional_data: null
                }
            ]
        },
        '7b21113b-d4e1-46fc-9eb5-3886df868f70': {
            video_id: '7b21113b-d4e1-46fc-9eb5-3886df868f70',
            user_id: 138316,
            view_count: 6,
            last_viewed: '2020-08-14T20:19:25.647664',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T17:51:35.558077',
                    attempts: 1,
                    score: 20,
                    correct: 1,
                    incorrect: 4,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-30T18:08:49.812166',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-05-30T18:15:10.600566',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '7b21113b-d4e1-46fc-9eb5-3886df868f70',
                    datetime_awarded: '2019-05-30T18:13:31.152387',
                    description: 'Perfect First Attempt on Graphing Lines Level 1',
                    quiz_instance: '08af2e58-6178-499e-8654-57c30ef43451',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: '7b21113b-d4e1-46fc-9eb5-3886df868f70',
                    datetime_awarded: '2019-05-30T18:18:06.975768',
                    description: 'Perfect First Attempt on Graphing Lines Level 2',
                    quiz_instance: '05db95fa-e6b4-4e93-9b0e-08f504a56ff2',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: '7b21113b-d4e1-46fc-9eb5-3886df868f70',
                    datetime_awarded: '2019-05-30T18:18:07.314125',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-05db95fa-e6b4-4e93-9b0e-08f504a56ff2',
                    quiz_instance: '05db95fa-e6b4-4e93-9b0e-08f504a56ff2',
                    additional_data: null
                }
            ]
        },
        'e3e8c6cc-23f9-405c-8fa9-11e685ccc619': {
            video_id: 'e3e8c6cc-23f9-405c-8fa9-11e685ccc619',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2020-06-28T16:31:49.769892',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2020-01-24T22:39:27.186257',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_pretest_perfect_score',
                    badge_level: 1,
                    video_id: 'e3e8c6cc-23f9-405c-8fa9-11e685ccc619',
                    datetime_awarded: '2020-01-24T22:39:39.544462',
                    description: 'Unable to generate description for Badge: single_video_pretest_perfect_score-a33ccd15-ac65-4d3e-b850-2a2f7733c0fc',
                    quiz_instance: 'a33ccd15-ac65-4d3e-b850-2a2f7733c0fc',
                    additional_data: null
                }
            ]
        },
        '6a5280c8-684f-4100-91e9-f473519c8ea9': {
            video_id: '6a5280c8-684f-4100-91e9-f473519c8ea9',
            user_id: 138316,
            view_count: 23,
            last_viewed: '2020-08-19T16:04:21.224251',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-16T19:10:19.130074',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-07-16T20:17:40.622803',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-10-30T20:21:05.201260',
                    attempts: 3,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                3: {
                    level: 3,
                    date: '2019-10-30T20:22:01.677360',
                    attempts: 1,
                    score: 0,
                    correct: 0,
                    incorrect: 10,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '6a5280c8-684f-4100-91e9-f473519c8ea9',
                    datetime_awarded: '2019-07-16T20:35:50.394552',
                    description: 'Perfect First Attempt on Graphing Ordered Pairs Level 1',
                    quiz_instance: '04111ca6-2116-4d42-bdb4-01017358a2ca',
                    additional_data: null
                }
            ]
        },
        '869a4518-4c37-46d0-82e4-69ed9282b80f': {
            video_id: '869a4518-4c37-46d0-82e4-69ed9282b80f',
            user_id: 138316,
            view_count: 2,
            last_viewed: '2020-08-14T20:19:13.348205',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-10-02T11:35:41.482019',
                    attempts: 1,
                    score: 40,
                    correct: 2,
                    incorrect: 3,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-11-27T21:51:21.382241',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2020-01-23T16:00:28.945058',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_growth_faster_gold',
                    badge_level: 1,
                    video_id: '869a4518-4c37-46d0-82e4-69ed9282b80f',
                    datetime_awarded: '2020-01-23T16:01:01.804913',
                    description: 'Unable to generate description for Badge: single_video_growth_faster_gold-1c3c52ff-7ce3-4f1a-acb4-ef6d023a0e73',
                    quiz_instance: '1c3c52ff-7ce3-4f1a-acb4-ef6d023a0e73',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: '869a4518-4c37-46d0-82e4-69ed9282b80f',
                    datetime_awarded: '2020-01-23T16:01:01.608232',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-1c3c52ff-7ce3-4f1a-acb4-ef6d023a0e73',
                    quiz_instance: '1c3c52ff-7ce3-4f1a-acb4-ef6d023a0e73',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: '869a4518-4c37-46d0-82e4-69ed9282b80f',
                    datetime_awarded: '2020-01-23T16:01:01.342153',
                    description: 'Perfect First Attempt on Multiply and Divide Integers Level 2',
                    quiz_instance: '1c3c52ff-7ce3-4f1a-acb4-ef6d023a0e73',
                    additional_data: null
                }
            ]
        },
        'e8381dd9-c359-4dfb-9052-9478fbc50d24': {
            video_id: 'e8381dd9-c359-4dfb-9052-9478fbc50d24',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2019-05-30T22:18:59.247243',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-23T16:41:02.602543',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'fd480f4f-f531-4d7f-bfd6-642fec6b253c': {
            video_id: 'fd480f4f-f531-4d7f-bfd6-642fec6b253c',
            user_id: 138316,
            view_count: 10,
            last_viewed: '2020-06-28T16:31:57.945243',
            favorite: false,
            current_level: 2,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-16T19:14:34.897761',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2020-06-28T16:33:16.344515',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        '4b228642-70ec-4955-ad9a-671acd0e5bd9': {
            video_id: '4b228642-70ec-4955-ad9a-671acd0e5bd9',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2019-11-14T12:14:30.465901',
            favorite: false,
            current_level: 0,
            current_score: 0,
            quiz_levels: {},
            achievements: []
        },
        '2abd56fb-2e8b-4981-87bf-5e241fe3978c': {
            video_id: '2abd56fb-2e8b-4981-87bf-5e241fe3978c',
            user_id: 138316,
            view_count: 141,
            last_viewed: '2020-07-02T15:43:15.052020',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-14T13:16:42.959518',
                    attempts: 1,
                    score: 80,
                    correct: 4,
                    incorrect: 1,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-14T13:22:21.724826',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-05-14T14:42:29.972593',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'b0952001-4bf2-42e0-a294-66bd39047417': {
            video_id: 'b0952001-4bf2-42e0-a294-66bd39047417',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2020-08-19T16:14:50.792887',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-08-26T21:04:12.823518',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_pretest_perfect_score',
                    badge_level: 1,
                    video_id: 'b0952001-4bf2-42e0-a294-66bd39047417',
                    datetime_awarded: '2020-06-30T20:52:01.679857',
                    description: 'Unable to generate description for Badge: single_video_pretest_perfect_score-276219ba-fdb1-48d7-974c-cc8cfb986e93',
                    quiz_instance: '276219ba-fdb1-48d7-974c-cc8cfb986e93',
                    additional_data: null
                }
            ]
        },
        '4b15fedc-be07-49ac-81bd-c7a4bc01995d': {
            video_id: '4b15fedc-be07-49ac-81bd-c7a4bc01995d',
            user_id: 138316,
            view_count: 2,
            last_viewed: '2019-11-14T12:13:15.912434',
            favorite: false,
            current_level: 2,
            current_score: 50,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T17:52:37.350215',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-10-30T20:19:08.394129',
                    attempts: 3,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-10-30T20:19:19.373433',
                    attempts: 1,
                    score: 50,
                    correct: 5,
                    incorrect: 5,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        '21a52c1e-bb97-4cd0-b90e-60de5652db7d': {
            video_id: '21a52c1e-bb97-4cd0-b90e-60de5652db7d',
            user_id: 138316,
            view_count: 1,
            last_viewed: '2020-06-30T20:51:05.523808',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-30T22:28:43.034017',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'd8f3000a-f506-4ca6-912b-196643b25cc6': {
            video_id: 'd8f3000a-f506-4ca6-912b-196643b25cc6',
            user_id: 138316,
            view_count: 2,
            last_viewed: '2020-08-19T16:06:21.890203',
            favorite: false,
            current_level: 1,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-16T19:28:02.223442',
                    attempts: 1,
                    score: 40,
                    correct: 2,
                    incorrect: 3,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'cd11153d-aaab-48c7-85bd-72fc4f559cdd': {
            video_id: 'cd11153d-aaab-48c7-85bd-72fc4f559cdd',
            user_id: 138316,
            view_count: 4,
            last_viewed: '2020-06-30T20:50:56.224794',
            favorite: false,
            current_level: 2,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-19T18:59:52.869723',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2020-06-30T20:46:44.893816',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'ad1ebbbd-221a-4c70-92ee-b0ab7f0f3b63': {
            video_id: 'ad1ebbbd-221a-4c70-92ee-b0ab7f0f3b63',
            user_id: 138316,
            view_count: 2,
            last_viewed: '2019-07-19T19:49:15.008103',
            favorite: false,
            current_level: 2,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-19T19:47:43.900750',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-07-19T19:48:22.064103',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: 'ad1ebbbd-221a-4c70-92ee-b0ab7f0f3b63',
                    datetime_awarded: '2019-07-19T19:49:06.149875',
                    description: 'Perfect First Attempt on Tools to Simplify Level 1',
                    quiz_instance: '6ae5c5b6-72cb-4d0d-b320-6716d0c59d32',
                    additional_data: null
                }
            ]
        },
        '729ae9ec-9616-42b9-8e9d-2a6b7ed92a7c': {
            video_id: '729ae9ec-9616-42b9-8e9d-2a6b7ed92a7c',
            user_id: 138316,
            view_count: 0,
            last_viewed: '2020-06-29T20:39:33.214965',
            favorite: false,
            current_level: 2,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-08-09T17:28:51.976233',
                    attempts: 1,
                    score: 80,
                    correct: 4,
                    incorrect: 1,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-11-01T19:41:11.688926',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: []
        },
        'bb858956-48fb-4768-bad2-0dfbd334c483': {
            video_id: 'bb858956-48fb-4768-bad2-0dfbd334c483',
            user_id: 138316,
            view_count: 0,
            last_viewed: '2020-08-19T16:04:35.797891',
            favorite: false,
            current_level: 3,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-09-09T16:56:47.801357',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-11-14T12:22:43.404628',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2020-08-14T20:20:35.604639',
                    attempts: 3,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: 'bb858956-48fb-4768-bad2-0dfbd334c483',
                    datetime_awarded: '2019-11-14T12:23:40.322592',
                    description: 'Perfect First Attempt on Scientific Notation Level 1',
                    quiz_instance: '073eb10c-1e00-4f81-b0d0-03b42ec3271d',
                    additional_data: null
                }
            ]
        },
        '37c04ef8-5bb6-44eb-84e6-bd6d39b2a3ae': {
            video_id: '37c04ef8-5bb6-44eb-84e6-bd6d39b2a3ae',
            user_id: 138316,
            view_count: 5,
            last_viewed: '2020-08-14T20:19:22.681628',
            favorite: false,
            current_level: 2,
            current_score: 80,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-07-16T19:29:33.787072',
                    attempts: 1,
                    score: 100,
                    correct: 5,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-09-05T21:31:09.805044',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-09-11T19:06:06.964037',
                    attempts: 4,
                    score: 80,
                    correct: 8,
                    incorrect: 2,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '37c04ef8-5bb6-44eb-84e6-bd6d39b2a3ae',
                    datetime_awarded: '2019-09-05T21:32:06.773788',
                    description: 'Perfect First Attempt on Multiply and Divide Decimals Level 1',
                    quiz_instance: '84ec1911-ea79-44f8-9823-663d480c5ed2',
                    additional_data: null
                }
            ]
        },
        '7eaf12ea-de19-4797-be77-90b9c83c7fef': {
            video_id: '7eaf12ea-de19-4797-be77-90b9c83c7fef',
            user_id: 138316,
            view_count: 525,
            last_viewed: '2020-08-14T20:44:35.174069',
            favorite: true,
            current_level: 4,
            current_score: 0,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-05-07T19:13:18.383374',
                    attempts: 1,
                    score: 40,
                    correct: 2,
                    incorrect: 3,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-05-16T18:26:39.483561',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-05-30T18:19:56.387437',
                    attempts: 1,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                3: {
                    level: 3,
                    date: '2019-10-22T04:18:38.026144',
                    attempts: 9,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: '7eaf12ea-de19-4797-be77-90b9c83c7fef',
                    datetime_awarded: '2019-05-30T18:20:26.372400',
                    description: 'Perfect First Attempt on Add and Subtract Integers Level 2',
                    quiz_instance: '8cf94bd0-0461-4a88-ae1f-e246de1198a6',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: '7eaf12ea-de19-4797-be77-90b9c83c7fef',
                    datetime_awarded: '2019-05-30T18:20:26.391013',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-8cf94bd0-0461-4a88-ae1f-e246de1198a6',
                    quiz_instance: '8cf94bd0-0461-4a88-ae1f-e246de1198a6',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_silver_first_try_perfect',
                    badge_level: 1,
                    video_id: '7eaf12ea-de19-4797-be77-90b9c83c7fef',
                    datetime_awarded: '2019-05-16T18:31:19.423238',
                    description: 'Perfect First Attempt on Add and Subtract Integers Level 1',
                    quiz_instance: '00ebceff-26e6-415e-be43-6d5b5bc29866',
                    additional_data: null
                }
            ]
        },
        'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3': {
            video_id: 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3',
            user_id: 138316,
            view_count: 0,
            last_viewed: '2020-08-14T19:27:28.576601',
            favorite: false,
            current_level: 3,
            current_score: 30,
            quiz_levels: {
                0: {
                    level: 0,
                    date: '2019-10-22T04:24:58.994768',
                    attempts: 1,
                    score: 80,
                    correct: 4,
                    incorrect: 1,
                    first_attempt_correct: 0
                },
                1: {
                    level: 1,
                    date: '2019-11-14T11:36:07.946475',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                2: {
                    level: 2,
                    date: '2019-11-14T11:45:20.399550',
                    attempts: 2,
                    score: 100,
                    correct: 10,
                    incorrect: 0,
                    first_attempt_correct: 0
                },
                3: {
                    level: 3,
                    date: '2019-11-14T12:01:12.425855',
                    attempts: 1,
                    score: 30,
                    correct: 3,
                    incorrect: 7,
                    first_attempt_correct: 0
                }
            },
            achievements: [
                {
                    user: 138316,
                    badge_type: 'single_video_gold_first_try_perfect',
                    badge_level: 1,
                    video_id: 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3',
                    datetime_awarded: '2019-05-30T18:19:39.347283',
                    description: 'Perfect First Attempt on Definitions Level 2',
                    quiz_instance: 'e35b9a36-40a6-4a6f-97e2-28febd835c04',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_faster_gold',
                    badge_level: 1,
                    video_id: 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3',
                    datetime_awarded: '2019-05-30T18:19:39.388435',
                    description: 'Unable to generate description for Badge: single_video_growth_faster_gold-e35b9a36-40a6-4a6f-97e2-28febd835c04',
                    quiz_instance: 'e35b9a36-40a6-4a6f-97e2-28febd835c04',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_gold',
                    badge_level: 1,
                    video_id: 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3',
                    datetime_awarded: '2019-05-30T18:19:39.367441',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_gold-e35b9a36-40a6-4a6f-97e2-28febd835c04',
                    quiz_instance: 'e35b9a36-40a6-4a6f-97e2-28febd835c04',
                    additional_data: null
                },
                {
                    user: 138316,
                    badge_type: 'single_video_growth_low_pretest_platinum',
                    badge_level: 1,
                    video_id: 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3',
                    datetime_awarded: '2019-10-16T16:42:34.727399',
                    description: 'Unable to generate description for Badge: single_video_growth_low_pretest_platinum-e78800e0-99b7-4cde-b164-1c4bf72de0a0',
                    quiz_instance: 'e78800e0-99b7-4cde-b164-1c4bf72de0a0',
                    additional_data: null
                }
            ]
        }
    }
};
