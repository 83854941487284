import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import LogoutRedirect from 'utils/LogoutRedirect';
import TanoshiRedirect from '../components/Tanoshi/TanoshiRedirect';
import Redirect from '../utils/Redirect';
import LoginContainer from './LoginContainerNew/LoginContainer';



const UnauthenticatedApp = ({ isLoggedIn }) => {
    console.debug('[UnauthenticatedApp]');
    return (
        <LoginContainer isLoggedIn={isLoggedIn} />
    );
    // return (
    //     <Routes>
    //         <Route path='/' element={<LoginContainer isLoggedIn={isLoggedIn} />} />
    //         {/*
    //         <Route path='/login' element={<LoginContainer />} />
    //         <Route path='/join' element={<LoginContainer />} />
    //         <Route path='/sign_up' element={<LoginContainer />} />
    //         <Route path='/new_password/:token' element={<LoginContainer />} />
    //         */}
    //       <Route path='/new_password/:token' element={<LoginContainer />} />
    //         <Route path='/tanoshi/:token' element={<TanoshiRedirect />} />
    //         <Route path='/logout' element={<LogoutRedirect />} />
    //         {(isLoggedIn === false) && <Route path='*' element={<Navigate to='/login' />} />}
    //     </Routes>
    // );
};

export default UnauthenticatedApp;
