import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';
import tw from 'twin.macro';
import CloseButton from '../AnswerKey/CloseButton';

const PlaylistPageActionsPopup = ({ showModal, setShowModal, header, boldCopy, copy1, copy2, copyButton1, copyButton2, handleConfirmAction, maxWidth }) => {
    maxWidth = maxWidth ? maxWidth : '584px';
    return (
        <Modal
            isOpen={showModal}
            setIsOpen={(value) => {
                setShowModal(value);
                handleConfirmAction(value);
            }}
            modalChildrenStyle={tw`maxWidth[929px] mx-auto`}
        >
            (
            <div tw='relative bg-white rounded flex-col justify-center font-sans' style={{ minWidth: '800px' }}>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-2xl'>{header}</div>
                <div tw='text-center text-lg text-DarkLiver pt-11 mx-auto' style={{ maxWidth: maxWidth }}>
                    <div tw='text-MediumPurple font-black text-2xl'>{boldCopy}</div>
                    <div>{copy1}</div>
                    <div tw='pt-5'>{copy2}</div>
                </div>
                <div tw='flex justify-center space-x-2 py-10'>
                    <Button variant='RosterTable' onClick={() => setShowModal(false)}>
                        {copyButton1}
                    </Button>
                    <Button onClick={() => handleConfirmAction()}>{copyButton2}</Button>
                </div>
                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={() => setShowModal(false)}
                />

            </div>
        </Modal>
    );
};

PlaylistPageActionsPopup.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    handleConfirmAction: PropTypes.func,
    header: PropTypes.string,
    boldCopy: PropTypes.string,
    copy1: PropTypes.string,
    copy2: PropTypes.string,
    copyButton1: PropTypes.string,
    copyButton2: PropTypes.string
};

export default PlaylistPageActionsPopup;
