import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import useOnClickOutside from '../../hooks/userOnClickOutside';

export const PopupMenu = ({ innerRef, actions, setShowPopup, rowIndex, right, top }) => {
    const ref = useRef();
    useOnClickOutside(ref, () => setShowPopup(false));
    return (
        <div
            tabIndex={0}
            ref={ref}
            // tw='origin-top-left absolute right-0 mt-3 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
            // tw='absolute right-0  pb-2.5 w-52 z-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none '
            tw='absolute right-0  pb-2.5 w-52 z-40 rounded-md shadow-lg bg-white focus:outline-none focus-visible:outline-none'
            style={{ right: right, top: top }}
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='user-menu'
        >
            <div tw='mx-4'>
                {actions &&
                    actions.map((action, key) => {
                        if (action.separator) {
                            return <div
                                key={key}
                                tw='bg-GainsboroGrey mt-4 h-0.5 block' />;
                        }
                        return (
                            <button
                                // autoFocus={key === 0}
                                tabIndex={0}
                                tw='block text-left mt-4 px-2 text-sm rounded-md text-DarkLiver hover:bg-LavenderWeb'
                                onClick={action.onAction}
                                style={{ color: action.color }}
                                key={key}
                            >
                                <div>{action.name}</div>
                                <div>{action.due && action.due}</div>
                            </button>
                        );
                    })}
            </div>
            <button
                tabIndex={0}
                tw='bg-white h-6 w-6 absolute -top-2 -right-2 rounded-full flex justify-center items-center shadow-md cursor-pointer z-50'
                onClick={() => setShowPopup(false)}
            >
                <svg
                    width='9'
                    height='9'
                    viewBox='0 0 9 9'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8.51286 0.46706C8.14351 0.0969177 7.5895 0.0969177 7.22015 0.46706L4.51163 3.18143L1.80311 0.46706C1.43377 0.0969177 0.87975 0.0969177 0.510406 0.46706C0.141062 0.837202 0.141062 1.39241 0.510406 1.76256L3.21893 4.47693L0.510406 7.1913C0.141062 7.56145 0.141062 8.11666 0.510406 8.4868C0.695078 8.67187 0.941307 8.73356 1.18754 8.73356C1.43377 8.73356 1.67999 8.67187 1.86467 8.4868L4.57319 5.77243L7.28171 8.4868C7.46638 8.67187 7.71261 8.73356 7.95884 8.73356C8.20507 8.73356 8.4513 8.67187 8.63597 8.4868C9.00532 8.11666 9.00532 7.56145 8.63597 7.1913L5.92745 4.47693L8.63597 1.76256C8.8822 1.39241 8.8822 0.837202 8.51286 0.46706Z'
                        fill='#6A7277'
                    />
                </svg>
            </button>
        </div>
    );
};

PopupMenu.propTypes = {
    actions: PropTypes.array
};

export default PopupMenu;
