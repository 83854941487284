/**
 * created by Jason Duncan on 3/1/18.
 */
import React, { Component } from 'react';
import tw from 'twin.macro';
// import { Col, Row } from 'react-bootstrap';


export const IncorrectMessage = ({ correct_choices, question_state }) => {
    console.log('correct', correct_choices);
    console.log('selected', question_state);
    if (!question_state || !correct_choices) return null;
    // export default class IncorrectMessage extends Component {
    //     static defaultProps = {
    //         correct_choices: [],
    //         question_state: []
    //     };
    //     constructor(props) {
    //         super(props);
    //         this.state = {};
    //     }

    // collect the correct choice bitmask from the question
    //finished = session.getIn(['quiz_state', 'quiz_finished'], false);
    //assert(question_state.length == correct.length);
    let missing_selected = false;
    let incorrect_selected = false;
    let correct_selected = false;

    for (let i = 0; i < correct_choices.length; i++) {
        let correct = correct_choices[i];
        let select = question_state[i];

        if (correct && !select) {
            missing_selected = true;
        }

        if (select && !correct) {
            incorrect_selected = true;
        }

        if (correct && select) {
            correct_selected = true;
        }
    }

    console.log('missing_selected', missing_selected);
    console.log('incorrect_selected', incorrect_selected);
    console.log('correct_selected', correct_selected);

    let text = '';
    // if (missing_selected && incorrect_selected)
    // {
    // 	text = <div>Not <u><i><strong>ALL</strong></i></u> correct answers were selected.<br/>At least one answer was incorrect.</div>;
    // }
    // else
    if (incorrect_selected && correct_selected) {
        // text = <div>Not <u>ALL</u> correct answers were selected.</div>;
        text = <div>At least one of your selections was incorrect.</div>;
    } else if (missing_selected && !incorrect_selected) {
        text = (
            <div>
                You did not select all the correct answers
            </div>
        );
    } else {
        return null;
    }
    return (
        <div tw='flex justify-center  mt-14 px-3 xsm:mx-auto w-full '>
            <div tw='flex justify-center items-center bg-[#5480F3] rounded-lg w-full p-2 max-w-[702px] h-14'>
                <div tw='font-medium text-base xsm:text-lg text-white ml-2 font-Poppins text-center'>
                    {text}
                </div>
            </div>
        </div>
    );

    // return (
    //     <div className='quiz-answer-row'>
    //         <div
    //             tw='w-full w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5'
    //             // xs={12}
    //             // xsOffset={0}
    //
    //             // sm={10}
    //             // smOffset={1}
    //
    //             // md={8}
    //             // mdOffset={2}
    //             style={{
    //                 // paddingRight: '76px',
    //                 borderRadius: '10px',
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 height: '60px',
    //                 lineHeight: '22px',
    //                 textAlign: 'center',
    //                 fontSize: '19px',
    //                 marginTop: '10px',
    //                 fontFamily: 'Avenir',
    //                 userSelect: 'none'
    //             }}
    //         >
    //             <div
    //                 style={{
    //                     background: 'red',
    //                     color: 'white',
    //                     width: '60px',
    //                     borderTopLeftRadius: '10px',
    //                     borderBottomLeftRadius: '10px',
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     display: 'flex'
    //                 }}
    //             >
    //                 <div
    //                     style={{
    //                         transform: 'rotate(45deg)',
    //                         fontSize: '40px'
    //                     }}
    //                 >
    //                     &#43;
    //                 </div>
    //             </div>
    //             <div
    //                 style={{
    //                     flex: 1,
    //                     background: 'black',
    //                     color: 'white',
    //                     borderTopRightRadius: '10px',
    //                     borderBottomRightRadius: '10px',
    //                     alignItems: 'center',
    //                     justifyContent: 'center',
    //                     display: 'flex'
    //                 }}
    //             >
    //                 {text}
    //             </div>
    //         </div>
    //     </div>
    // );
};
