import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import { handleNetworkError } from '../../lib/axios';
import { useVideoStatsUpdate } from '../../state/Student/VideoStats';
import VideoSurveyEndMobile from './VideoSurveyEndMobile';
import VideoSurveyEndDesktop from './VideoSurveyEndDesktop';
import DTCCardsDesktop from './DTCCardsDesktop';
import DTCCardsMobile from './DTCCardsMobile';

const StyledButton = styled(Button)`
  ${({ variant }) => variant === 'silver-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-4 rounded-lg hover:bg-[linear-gradient(180deg, #ECECEC 0%, #747474 100%)] `}
  ${({ variant }) => variant === 'gold-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-4 rounded-lg hover:bg-[linear-gradient(180deg, #FFF4DF 0%, #E39605 100%)] `}
  ${({ variant }) => variant === 'platinum-dtc' && tw`bg-white text-[#1C0057] font-Poppins p-4 rounded-lg hover:bg-[linear-gradient(180deg, #C4EBF9 0%, #24A6D3 100%)] `}
`;

const DTCVideoSessionSurveyEnd = ({ setEndCard, videoStats, video, onTakeChallenge, watchAgain, disableSurveyCard }) => {
    const updateVideoStats = useVideoStatsUpdate();
    const navigate = useNavigate();
    const stats = video?.stats ?? null;
    let level = stats.current_level;
    const [surveyAnswer, setSurveyAnswer] = useState((videoStats?.difficulty_rating !== 0) ? true : false);
    const [{ loading: isPosting }, postSurvey] = useAxios({ url: `/api/video_stats/${video.id}/difficulty_rating/`, method: 'PATCH' }, { manual: true });

    const onSubmit = (data) => {
        const payload = { difficulty_rating: data };
        postSurvey({ data: payload })
            .then((data) => {
                console.log(data);
                if (data.error) {
                    console.log(data.error);
                } else {
                    setSurveyAnswer(true);
                    if( videoStats ) {
                        videoStats.difficulty_rating = data;
                        updateVideoStats(videoStats);
                    }
                }
            })
            .catch((error) => {
                handleNetworkError(error);
            });
    };

    const takeChallenge = () => {
        if (onTakeChallenge) onTakeChallenge();
    };

    const buttonLevel = level === 1 ? 'silver-dtc' : level === 2 ? 'gold-dtc' : 'platinum-dtc';

    return (
        <>
            <div
                tw='absolute transform h-full w-full top-0 bg-gray-700 backdrop-blur-3xl rounded-t-lg'
                css={[
                    css`
                        background-image: linear-gradient(to top, #46217d, rgba(128,0,128,0) 90%), url(${video.url_thumbnail});
                        background-size: cover;
                    `
                ]}
            ></div>

            <div tw='hidden sm:block'>
                <DTCCardsDesktop
                    video={video}
                    level={level}
                    watchAgain={watchAgain}
                    takeChallenge={takeChallenge}
                    StyledButton={StyledButton}
                    surveyAnswer={surveyAnswer}
                />
            </div>

            <div tw='sm:hidden'>
                <DTCCardsMobile
                    video={video}
                    level={level}
                    watchAgain={watchAgain}
                    takeChallenge={takeChallenge}
                    StyledButton={StyledButton}
                    surveyAnswer={surveyAnswer}
                />
            </div>

            {(!disableSurveyCard && !surveyAnswer) ?
                <div tw='absolute top-2/4 sm:top-[80%] md:top-[82%] lg:top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.99)] sm:bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl w-[95%] sm:w-3/4 max-w-[608px] h-[95%] sm:h-[130px] md:h-[145px] lg:h-36 rounded-lg flex text-center'>
                    <div tw='absolute top-2 right-3 flex justify-end w-full cursor-pointer' onClick={() => setSurveyAnswer(true)}>
                        <svg
                            width='14' height='14' viewBox='0 0 14 14' fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path d='M13.3002 0.685509C13.2077 0.593198 13.0978 0.519961 12.9768 0.469992C12.8559 0.420022 12.7262 0.394301 12.5952 0.394301C12.4643 0.394301 12.3346 0.420022 12.2136 0.469992C12.0926 0.519961 11.9827 0.593198 11.8902 0.685509L7.00022 5.54487L2.11022 0.675551C2.01764 0.583361 1.90773 0.510231 1.78677 0.460338C1.6658 0.410445 1.53615 0.384766 1.40522 0.384766C1.27429 0.384766 1.14464 0.410445 1.02368 0.460338C0.902716 0.510231 0.792805 0.583361 0.700223 0.675551C0.607642 0.767742 0.534201 0.877188 0.484097 0.99764C0.433992 1.11809 0.408203 1.24719 0.408203 1.37757C0.408203 1.50795 0.433992 1.63705 0.484097 1.7575C0.534202 1.87795 0.607642 1.9874 0.700223 2.07959L5.59022 6.94891L0.700223 11.8182C0.607642 11.9104 0.534201 12.0199 0.484097 12.1403C0.433992 12.2608 0.408203 12.3899 0.408203 12.5203C0.408203 12.6506 0.433992 12.7797 0.484097 12.9002C0.534201 13.0206 0.607642 13.1301 0.700223 13.2223C0.792805 13.3145 0.902716 13.3876 1.02368 13.4375C1.14464 13.4874 1.27429 13.5131 1.40522 13.5131C1.53615 13.5131 1.6658 13.4874 1.78677 13.4375C1.90773 13.3876 2.01764 13.3145 2.11022 13.2223L7.00022 8.35295L11.8902 13.2223C11.9828 13.3145 12.0927 13.3876 12.2137 13.4375C12.3346 13.4874 12.4643 13.5131 12.5952 13.5131C12.7262 13.5131 12.8558 13.4874 12.9768 13.4375C13.0977 13.3876 13.2076 13.3145 13.3002 13.2223C13.3928 13.1301 13.4662 13.0206 13.5163 12.9002C13.5665 12.7797 13.5922 12.6506 13.5922 12.5203C13.5922 12.3899 13.5665 12.2608 13.5163 12.1403C13.4662 12.0199 13.3928 11.9104 13.3002 11.8182L8.41022 6.94891L13.3002 2.07959C13.6802 1.7012 13.6802 1.0639 13.3002 0.685509Z' fill='#6E8CA6' />
                        </svg>
                    </div>
                    <div tw='flex w-full pt-2 sm:hidden'>
                        <VideoSurveyEndMobile onSubmit={onSubmit} />
                    </div>
                    <div tw='hidden sm:flex w-full pt-7'>
                        <VideoSurveyEndDesktop onSubmit={onSubmit} />
                    </div>

                </div>
                : null}
        </>
    );
};

DTCVideoSessionSurveyEnd.propTypes = {
    anyProp: PropTypes.any
};

export default DTCVideoSessionSurveyEnd;