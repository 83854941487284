import * as React from 'react';

function SvgClassIcon01(props) {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <circle
                cx={20}
                cy={20}
                r={20}
                fill='#FFD233' />
            <path
                d='M20.2 15.716l-3.373 2.969-2.019-2.328 5.486-4.631h3.325v16.815H20.2V15.716z'
                fill='#12154C'
            />
        </svg>
    );
}

export default SvgClassIcon01;
