import React, { useMemo } from 'react';
import BalanceText from 'react-balance-text';
import tw, { css } from 'twin.macro';
import Records from 'components/videoCardProgress/Records';
import { useToggleFavoriteVideo } from 'state/Student/VideoStats';
import HeartFavoriteIconNew from 'images/icons/HeartFavoriteIconNew';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useClassStudents } from 'state/Teacher/ClassState';
import './DTCInfo.css';
import { wrapTitleParens } from '../../../../lib/string';

const DTCInfo = ({ view_count, video, children, showTitle, videoStats, props, isHovering, parent, onClick }) => {
    const toggleFavoriteVideo = useToggleFavoriteVideo(props.video?.id);
    const isFavorite = videoStats?.favorite ?? false;
    const { student_id } = useParams();
    const { students } = useClassStudents(null);
    const selectedStudentName = useMemo(() => students.find(s => s?.user.id.toString() === student_id)?.user.first_name, [students, student_id]);


    const handleToggleFavorite = (e) => {
        e.stopPropagation();
        toggleFavoriteVideo(e);
    };

    return (
        <>
            <div
                tw='flex flex-col justify-between p-2.5 rounded-b-md text-white min-h-[100px] sm:min-h-[136px] xl:min-h-32'
                css={[isHovering ? tw`bg-VideoHoverPurple` : tw`bg-VideoPurple`]}
                onClick={onClick}
            // id='no-hover'
            >
                {showTitle && <div tw='mb-3 text-white font-Poppins text-sm sm:text-base font-medium leading-snug'>
                    <BalanceText>{wrapTitleParens(video?.title)}</BalanceText>
                </div>}

                <div
                    tw='hidden -mt-2 flex-col flex-shrink-0 text-VideoDescription font-Poppins text-xs font-normal leading-snug mb-4'
                    css={[isHovering && tw`flex`]}
                >
                    {video?.description}
                </div>

                <div tw='flex justify-between'>
                    <Records className='record-list' stats={videoStats} dtc={true} isHovering={isHovering} />

                    <div tw='flex items-center'>
                        <div
                            tw='flex items-center uppercase' data-tooltip-id='my-tooltip'
                            data-tooltip-content={parent ? `${selectedStudentName}'s Views` : 'YOUR VIEWS'}>
                            <div tw='pr-1'>{view_count}</div>
                            <div tw='pr-3' css={[parent && tw`pr-0`]}>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20'
                                    fill='none'>
                                    <path
                                        d='M10.3535 14.8235C6.5589 14.8235 4.07565 12.4632 2.90371 11.0052C2.36893 10.3399 2.36893 9.4248 2.90371 8.75949C4.07565 7.30151 6.5589 4.94116 10.3535 4.94116C14.148 4.94116 16.6313 7.30151 17.8032 8.75949C18.338 9.4248 18.338 10.3399 17.8032 11.0052C16.6313 12.4632 14.148 14.8235 10.3535 14.8235Z'
                                        stroke='#9D91BB' strokeWidth='1.23529' strokeLinecap='round' />
                                    <circle
                                        cx='10.3529' cy='9.88233' r='2.47059' stroke='#9D91BB'
                                        strokeWidth='1.23529' />
                                </svg>
                            </div>
                        </div>
                        {!parent &&
                            <div
                                tw='flex items-center'
                                data-tooltip-id='my-tooltip'
                                data-tooltip-content={isFavorite ? 'REMOVE FROM FAVORITES PLAYLIST' : 'ADD TO FAVORITES PLAYLIST'}
                            >
                                <button
                                    aria-label='Toggle Favorite Video'
                                    data-testid='favorite-button'
                                    className='outline-offset-0 favorite-button-heart'
                                    onClick={(e) => handleToggleFavorite(e)}
                                >
                                    <HeartFavoriteIconNew className='fav' favorite={isFavorite} />
                                </button>
                            </div>
                        }

                    </div>
                </div>

                {children}
            </div>


            {isHovering && <Tooltip
                id='my-tooltip'
                style={{
                    backgroundColor: '#6441B9',
                    color: '#fff',
                    fontSize: '12px',
                    // center the text in the tooltip
                    textAlign: 'center'
                }}
                render={({ content, activeAnchor }) => (
                    <span>
                        {content}
                    </span>
                )}
            />}
        </>
    );
};


export default DTCInfo;
