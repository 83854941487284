import React from 'react';

const SvgIconRecentlyWatched = (props) => (
    <svg
        width='1em'
        height='1em'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
    >
        <path
            d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11z'
            fill='#fff'
        />
        <path
            d='M11 3.667c-2.18 0-4.133.957-5.474 2.47l-1.859-1.86v4.89h4.89L6.832 7.443A5.452 5.452 0 0111 5.5a5.5 5.5 0 11-3.89 9.39l-1.295 1.295A7.333 7.333 0 0018.333 11 7.333 7.333 0 0011 3.667zm-.61 3.056v4.89h3.667V10.39h-2.444V6.723H10.39z'
            fill='url(#prefix__paint0_linear)'
        />
        <defs>
            <linearGradient
                id='prefix__paint0_linear'
                x1={11}
                y1={3.667}
                x2={11}
                y2={18.334}
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1E215F' />
                <stop
                    offset={1}
                    stopColor='#070A3B' />
            </linearGradient>
        </defs>
    </svg>
);

export default SvgIconRecentlyWatched;
