import { List, fromJS } from 'immutable';
import { getCurrentTimestamp, toJS } from 'utils';

import { QUIZ_MODE_RESEARCH } from 'features';
import { getQuestion, getQuestionIndex, getQuizContext, getQuizInstance, getQuizInstanceQuestions } from './selectors';
import {
    ADD_QUIZ_RESPONSE,
    CHOICE_SELECTED,
    CHOICE_UNSELECTED,
    CONTEXT_STATE_WAITING_FOR_USER,
    INIT_QUIZ_CONTEXT,
    BEGIN_QUIZ_QUESTION,
    SELECT_QUESTION_CHOICE,
    FREE_RESPONSE_TEXT_CHANGED,
    SET_CONTEXT_STATE,
    SETUP_QUIZ_PROGRESS,
    PROGRESS_ITEM_NONE,
    PROGRESS_ITEM_CORRECT,
    PROGRESS_ITEM_INCORRECT,
    QUESTION_TYPE_MULTIPLE_CHOICE,
    QUESTION_TYPE_MULTIPLE_SELECT,
    QUESTION_TYPE_TRUE_FALSE,
    BUTTON_STATE_UNSELECTED, ENABLE_ANSWER_BUTTONS, ENABLE_SUBMIT_BUTTON, CONTEXT_STATE_INITIALIZING, CONTEXT_STATE_CREATED, CONTEXT_STATE_INITIALIZED
} from './constants';
import { enableAnswerButtons, enableSubmitButton } from './context';

/*
	The default quiz config
 */
const DEFAULT_QUIZ_CONFIG = {
    showFeedback: true, // show if a question is correct or incorrect
    autoAdvance: false // automatically go to the next question after it has been submitted
};

export default function quizReducer(state = [], action = {}) {
    switch (action.type) {
        case INIT_QUIZ_CONTEXT: {
            /*
                This is the first action called when a quiz is loaded.
                It sets up the quiz context and the first question.

                1. it gets the quiz instance
                2. it gets the quiz instance questions
                3. it sets the context state to waiting for user

             */
            // get the instance
            const instance = getQuizInstance(state);
            if (!instance) {
                // this is an error
                console.error('Error: INIT_QUIZ_CONTEXT called with no quiz instance');
                return state;
            }

            // get the instance questions
            const questions = getQuizInstanceQuestions(state);
            if (!questions) {
                // this is an error
                console.error('Error: INIT_QUIZ_CONTEXT called with no quiz questions');
                return state;
            }

            /*
                resume the current question
             */

            // question_index will be the first question not completed correctly in the previous responses
            // let question_index = instance.get('responses', List()).count();
            const responses = instance.get('responses').toJS();
            const firstIncorrectQuestionIndex = questions.findIndex((question, index) => {
                if (question.get('correct') !== true) {
                    // In research mode we run through each quiz from beginning to end
                    // regardless of previous instances.
                    if (!QUIZ_MODE_RESEARCH) {
                        // now skip over the questions submitted this session with this index
                        if (!responses.find(response => response.index === index))
                            return true;
                    }
                }
                return false;
            });
            const startingQuestionIndex = firstIncorrectQuestionIndex === -1 ? questions.count() : firstIncorrectQuestionIndex;

            // console.log('[quizReducer] INIT_QUIZ_CONTEXT firstIncorrectQuestionIndex', firstIncorrectQuestionIndex);
            // console.log('[quizReducer] INIT_QUIZ_CONTEXT question_index', startingQuestionIndex);
            // console.log('[quizReducer] INIT_QUIZ_CONTEXT responses', instance.get('responses').toJS());
            // console.log('[quizReducer] INIT_QUIZ_CONTEXT questions', questions.toJS());

            /*
            if (QUIZ_MODE_RESEARCH) {
                // In research mode we run through each quiz from beginning to end
                // regardless of previous instances.
                // So we resume at the first non submitted response.
            } else {
                // in normal mode we run through each instance
                // only answering questions not completed in previous instances.
                // So we resume at the first non submitted response.

                // find the first question not complete,
                // - but skip over already submitted responses.

                // if (questions) {
                //     const index = questions.findIndex((question, index) => index >= question_index && question.get('correct') === false);
                //     console.log('[quizReducer] INIT_QUIZ_CONTEXT findIndex', index);
                //     if (index !== -1) {
                //         question_index = index;
                //         console.log('[quizReducer] INIT_QUIZ_CONTEXT current question_index', question_index);
                //     }
                // }
            }
            */

            // get the question
            let question = null;
            if (startingQuestionIndex < questions.count())
                question = questions.get(startingQuestionIndex).toJS();

            // create the response
            const { quizConfig } = action.payload;

            // construct a new session context
            const context = {
                // set the quiz config from the action
                config: { ...DEFAULT_QUIZ_CONFIG, ...(quizConfig || {}) },

                // questions
                questions: questions,

                // progress
                progress: [],

                // current responses
                responses: [],

                // current response
                response: null,

                // start with the first question, waiting for the user to select a choice
                state: CONTEXT_STATE_WAITING_FOR_USER,
                // state: CONTEXT_STATE_INITIALIZED,
                question_index: startingQuestionIndex,
                question
            };

            // what still needs to be done:
            // 1. setup the progress
            // 2. setup the responses
            // 3. setup the current question & response

            // place the new context into the state
            return state.setIn(['quiz', 'session', 'context'], fromJS(context));
        }

        case SETUP_QUIZ_PROGRESS: {
            // console.log('SETUP_QUIZ_PROGRESS');
            // return state.
            // starts with session.instance.responses
            let progress = [];

            let questions = getQuizInstanceQuestions(state);
            let instance = toJS(getQuizInstance(state));

            let context = getQuizContext(state);
            let responses = null;

            if (context) {
                responses = toJS(context.responses);
            }

            if (questions && instance) {
                // console.log('PROGRESS QUESTIONS          :', questions.toJS());
                // console.log('PROGRESS CONTEXT RESPONSES  :', responses);
                // console.log('PROGRESS INSTANCE RESPONSES :', instance.responses);
                // console.log('PROGRESS INSTANCE           :', instance);
                progress = questions.map((question) => {
                    // console.log(question.toJS());
                    let question_id = question.get('id');
                    if (question.get('correct')) {
                        return PROGRESS_ITEM_CORRECT;
                    } else {
                        // check responses
                        if (responses) {
                            let response = responses.find((response) => response.question_id === question_id);
                            if (response) {
                                return response.correct ? PROGRESS_ITEM_CORRECT : PROGRESS_ITEM_INCORRECT;
                            }
                        }

                        // check previous responses
                        if (instance.responses) {
                            let response = instance.responses.find((response) => response.question_id === question.get('question_id'));
                            if (response) {
                                return response.correct ? PROGRESS_ITEM_CORRECT : PROGRESS_ITEM_INCORRECT;
                            }
                        }
                    }
                    return PROGRESS_ITEM_NONE;
                });

                // console.log('SETUP_QUIZ_PROGRESS 1', toJS(progress));
                return state.setIn(['quiz', 'session', 'context', 'progress'], fromJS(progress));
            }
            // console.log('SETUP_QUIZ_PROGRESS 2', toJS(progress));
            return state.setIn(['quiz', 'session', 'context', 'progress'], fromJS(progress));
        }

        // begin the current question
        case BEGIN_QUIZ_QUESTION: {
            // let context = toJS(getQuizContext(state));

            // get the current question
            // const question = toJS(state.getIn(['quiz', 'session', 'context', 'question']));
            // if (!question) {
            //     console.error('Error: BEGIN_QUIZ_QUESTION called with no question');
            // }
            // const index = context.question_index;


            // if (context) {
            // prepare the quiz question
            return state.updateIn(['quiz', 'session', 'context'], (contextValue) => {

                // get the question index and question
                const index = contextValue.get('question_index');
                const question = contextValue.get('question');

                // TODO: integrity check: verify the quiz question and index match
                // verify the question index and question match
                if (question && question.get('id') !== contextValue.getIn(['questions', index, 'id'])) {
                    console.error('Error: BEGIN_QUIZ_QUESTION called with invalid question index');
                    throw new Error('Error: BEGIN_QUIZ_QUESTION called with invalid question index');
                }

                console.log('BEGIN_QUIZ_QUESTION context:', contextValue.toJS());
                console.log('BEGIN_QUIZ_QUESTION question:', index, question.toJS());

                // const context = toJS(contextValue);
                // const index = context.question_index;

                // // get the current question
                // const question = context.question;
                // if (!question) {
                //     // ?
                //     console.error('Error: BEGIN_QUIZ_QUESTION called with no question');
                // }

                // setup the question response
                const response = {
                    question_index: index,
                    question_start: getCurrentTimestamp(),
                    question_stop: null,
                    free_entry: '',
                    all_selections: [],
                    audio_plays: [],
                    selected_choice: null,
                    correct: null,
                    attempts: 0
                };

                // reset the button state
                // console.log('new button state:', button_state);
                // const question = toJS(contextValue.getIn(['question'], {}));
                // if (!question) {
                //     console.error('Error: BEGIN_QUIZ_QUESTION called with no question');
                // }
                // const button_state = question?.choices?.map(() => BUTTON_STATE_UNSELECTED);

                // reset the button state from the current question's choices
                const choices = toJS(question.get('choices', []));
                const button_state = choices?.map(() => BUTTON_STATE_UNSELECTED);

                // update the context
                return contextValue
                    .set('response', fromJS(response)) // put the response in the context
                    .set('button_state', fromJS(button_state)) // reset the button state
                    .set('enable_answer_buttons', true)
                    .set('enable_submit_button', false)
                    .set('state', CONTEXT_STATE_WAITING_FOR_USER);
            });
        }

        // add the current quiz response to the responses list in preparations for submission
        case ADD_QUIZ_RESPONSE: {
            // set as the current response
            state = state.setIn(['quiz', 'session', 'context', 'response'], fromJS(action.response));

            // insert the quiz response into the list
            state = state.updateIn(['quiz', 'session', 'context', 'responses'], List(), (responses) => responses.push(fromJS(action.response)));

            // update the progress
            let current_question = getQuizContext(state).get('question_index');
            let progress_value = action.response.correct ? PROGRESS_ITEM_CORRECT : PROGRESS_ITEM_INCORRECT;

            // console.log('add question response', current_question, progress_value);
            return state.setIn(['quiz', 'session', 'context', 'progress', current_question], progress_value);
        }

        case SET_CONTEXT_STATE:
            return state.setIn(['quiz', 'session', 'context', 'state'], action.state);

        case SELECT_QUESTION_CHOICE: {
            // the user selected an answer -  toggle/set the answer

            // get the choice index
            const index = action.index;

            // get the current question
            const question = getQuestion(state).toJS();
            // if (!question) {
            //     // TODO: throw exception for rollbar and change the route
            //     const questionIndex = getQuestionIndex(state);
            //     console.error('Error: This question does not exist', questionIndex);
            //     alert(`INTERNAL ERROR: question does not exist: ${questionIndex}`);
            //     return state;
            // }
            // question = question.toJS();

            // determine what kind of question this is
            //console.log('question', question);
            if (question.type === QUESTION_TYPE_MULTIPLE_CHOICE || question.type === QUESTION_TYPE_TRUE_FALSE) {
                // multiple choice (select only one)
                const new_question_state = question.choices.map((i, j) => {
                    // console.log(i, j);
                    return j === index ? CHOICE_SELECTED : CHOICE_UNSELECTED;
                });
                // console.log("New Button State:", new_question_state);
                return state.setIn(['quiz', 'session', 'context', 'button_state'], fromJS(new_question_state));
            } else if (question.type === QUESTION_TYPE_MULTIPLE_SELECT) {
                // multiple select (select all that apply)
                // return state.setIn(['quiz', 'session', 'context', 'button_state'], fromJS(new_question_state));
                return state.updateIn(['quiz', 'session', 'context', 'button_state', index], (value) =>
                    value === CHOICE_SELECTED ? CHOICE_UNSELECTED : CHOICE_SELECTED
                );
                //
                // return state.updateIn(['quiz_session', 'quiz_state', 'question_state', index], (value) => !value);
            } else {
                // free answer
                // return state;
            }

            return state;
        }

        case FREE_RESPONSE_TEXT_CHANGED:
            return state.updateIn(['quiz', 'session', 'context', 'response'], (response) => (response ? response.set('free_entry', action.text) : null));

        default:
            return state;
    }
}
