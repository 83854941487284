import React from 'react';
import 'twin.macro';
const AllVideosActiveSVG = () => {
    return (
        <svg tw='w-[22px] h-[22px]' width='23' height='20' viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect
                x='9' y='15' width='13' height='3' rx='1.5' fill='#E775EF'
                stroke='#E775EF' strokeWidth='1.5' />
            <rect
                x='2' y='8' width='20' height='3' rx='1.5' fill='#E775EF'
                stroke='#E775EF' strokeWidth='1.5' />
            <rect
                x='2' y='1' width='20' height='3' rx='1.5' fill='#E775EF'
                stroke='#E775EF' strokeWidth='1.5' />
            <path d='M5.5 15.634C6.16667 16.0189 6.16667 16.9811 5.5 17.366L3.25 18.6651C2.58333 19.05 1.75 18.5688 1.75 17.799L1.75 15.201C1.75 14.4312 2.58333 13.95 3.25 14.3349L5.5 15.634Z' fill='#E775EF' stroke='#E775EF' strokeWidth='1.5' />
        </svg>
    );
};

export default AllVideosActiveSVG;