import React from 'react';
import tw, { css } from 'twin.macro';
import { Modal, VideoViewProgress } from 'components';
import { useLessons } from 'state/CatalogState';

const ScrollBarStyle = css`
    &::-webkit-scrollbar {
    width: 12px;
    background-color: #4D3095;
    border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
    border-radius: 6px;
    }
`;

const ProgressModal = ({ isOpen, setIsOpen, selectedGroupTitle, groups, student_id, student_name }) => {
    const findLesson = (lessons, lessonId) => Object.values(lessons).find(l => l.id === lessonId);
    const lessons = useLessons();
    const modalTitle = student_id ? `${selectedGroupTitle} Playlists - ${student_name}'s Progress` : `${selectedGroupTitle} Playlists - Progress`;

    const createProgressRows = (groups, findLesson, lessons) => {
        if (!groups) return null;
        return groups.map((group) => {
            const groupVideos = group.lessons.map(lessonId => findLesson(lessons, lessonId));
            return (
                <div tw='border-b-[1px] border-[#1C0057]  my-6 py-6 ' key={group.id}>
                    <div tw='flex items-center justify-between h-12'>
                        <div tw='text-white'>{group.title}</div>
                        <VideoViewProgress videos={groupVideos} student_id={student_id} />
                    </div>
                </div>
            );
        });
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full maxWidth[920px] mx-auto`}>
            <div tw='select-none mx-4 relative'>
                <div tw='bg-[#3E257A] p-6 rounded-xl'>
                    <div tw='text-white font-bold text-xl text-center pt-6 px-12 flex'>
                        <div tw='flex-1'>{modalTitle}</div>
                        <div tw='cursor-pointer' onClick={() => setIsOpen(false)}>
                            <svg
                                width='24' height='24' viewBox='0 0 24 24' fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path fillRule='evenodd' clipRule='evenodd' d='M4.4254 4.44406C4.69787 4.17137 5.06737 4.01818 5.45264 4.01818C5.83791 4.01818 6.20741 4.17137 6.47988 4.44406L11.9909 9.96115L17.502 4.44406C17.636 4.30513 17.7963 4.19432 17.9736 4.11809C18.1508 4.04186 18.3415 4.00173 18.5344 4.00005C18.7273 3.99838 18.9187 4.03518 19.0972 4.10831C19.2758 4.18145 19.438 4.28945 19.5744 4.42603C19.7109 4.5626 19.8187 4.725 19.8918 4.90376C19.9649 5.08252 20.0016 5.27405 19.9999 5.46718C19.9983 5.66032 19.9582 5.85118 19.882 6.02864C19.8059 6.2061 19.6952 6.36661 19.5564 6.50078L14.0454 12.0179L19.5564 17.535C19.8211 17.8093 19.9675 18.1767 19.9642 18.5581C19.9609 18.9395 19.8081 19.3043 19.5387 19.574C19.2693 19.8437 18.9049 19.9966 18.524 19.9999C18.143 20.0033 17.776 19.8566 17.502 19.5917L11.9909 14.0746L6.47988 19.5917C6.20585 19.8566 5.83883 20.0033 5.45787 19.9999C5.07691 19.9966 4.71249 19.8437 4.4431 19.574C4.17371 19.3043 4.02091 18.9395 4.0176 18.5581C4.01429 18.1767 4.16074 17.8093 4.4254 17.535L9.93645 12.0179L4.4254 6.50078C4.15302 6.22801 4 5.85811 4 5.47242C4 5.08673 4.15302 4.71682 4.4254 4.44406Z' fill='#9D91BB' />
                            </svg>
                        </div>
                    </div>
                    <div tw='h-full mt-10 px-12 max-h-[475px] overflow-y-auto' css={[ScrollBarStyle]}>{createProgressRows(groups, findLesson, lessons, student_id)}</div>
                </div>


            </div>
        </Modal>
    );
};

export default ProgressModal;