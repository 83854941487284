import React, { useEffect, useMemo, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Transition } from '@headlessui/react';
import { useEvents } from 'context/EventProvider.js';
import { useToggleFavoriteVideo, useVideoStats, useVideosWithStats } from 'state/Student/VideoStats';
import { VideoPlayerLyricsPopOver, VideoPlayerProgress } from 'components';
import VideoSlider from 'components/VideoSlider';
import { useVideoSession } from 'context/VideoSession/VideoSessionProvider';
import hearts_transparent_texture from 'static/images/hearts_transparent_texture.png';
import HeartFavoriteIcon from 'images/icons/HeartFavoriteIcon';
import { useClassStudents } from 'state/Teacher/ClassState';
import AssignmentProgressContainer from '../../shared/VideoSessionContainer/assignmentProgress/AssignmentProgressContainer';
import { useMainCourseAndVideos } from '../../../../state/CatalogState';
import { createVideoPoster } from '../../../util';
import VideoInfo from './VideoInfo';
import VideoLyrics from './videoContent/VideoLyrics';
import VideoInfoTeacher from './VideoInfoTeacher';
import VideoAchievementContent from './videoContent/VideoAchievementContent';

import { createVideoSources } from './VideoSources';
import './Session.css';
import { EndCard } from './EndCard';

export const ScreenRow = (props = { background: 'white', backgroundImage: 'white' }) => (
    <ScreenRowContainer
        className={`screen-row-container ${props.className || ''}`}
        background={props.background}
        backgroundImage={props.backgroundImage}
        $paddingTop={props.paddingTop}
        $paddingBottom={props.paddingBottom}
        $teacher={props.teacher}
    >
        <div className='screen-row-container--wrapper'>{props.children}</div>
    </ScreenRowContainer>
);


const VideoSession = (props) => {
    const { user, video, assignment, postVideoSessionRanges, postVideoSession } = props;
    const { is_parent_account } = user;
    // Handle changing of background on video session player
    const [blackBackground, setBlackBackground] = useState(null);
    const [endCard, setEndCard] = useState(false);

    const onVideoPlay = (args) => {
        console.log('[VideoSession] on video end (', args, ')');
        setBlackBackground(true);
        setEndCard(false);
    };

    const onVideoEnd = ({ seeking }) => {
        console.log('[VideoSession] on video end (seeking:', seeking, ')');
        if (!seeking) setBlackBackground(false);
        if (seeking) setEndCard(false);
        else setEndCard(true);
    };

    const onVideoPause = ({ seeking }) => {
        console.log('[VideoSession] on video pause (seeking:', seeking, ')');
        setEndCard(false);
        if (seeking) setEndCard(false);
    };

    // get the video session and player
    // console.log('[VideoSession] rendering video session', video);
    const [Player, sessionId, { play, pause }, createSession, video_id] = useVideoSession(video?.id, {
        fluid: true,
        playerType: 'bitmovin',
        events: {
            onPlay: onVideoPlay,
            onPause: onVideoPause,
            onEnd: onVideoEnd
        }
    });

    const allVideos = useVideosWithStats();
    const navigate = useNavigate();
    const postEvent = useEvents();
    const { students } = useClassStudents(null);
    const listBoxItems = students.map((student) => ({ name: `${student.user.first_name}'s Progress`, id: student.user.id, item: student }));
    const [selectedStudent, setSelectedStudent] = useState(listBoxItems[0]);
    const isPhone = useMedia('(min-width: 500px)');
    // const [pinned, togglePinned] = useToggle(false);
    const hideProgressBar = useMedia('(max-width: 1300px)');
    const instructor = user ? !!user.instructor : false;
    const parent = user ? !!user.is_parent : false;
    const course = useMainCourseAndVideos();
    // find courses that are related to the video
    // const relatedPlaylist = course
    //     ? course?.playlists?.find((playlist) => playlist?.videos && !!playlist.videos.find((v) => v && v.id === video.id))?.videos
    //     : [];

    const relatedPlaylist = course?.playlists?.find(playlist => playlist?.videos?.some(videoItem => videoItem?.id === video.id));
    if (relatedPlaylist) {
        console.log('related playlist', relatedPlaylist);
    }
    // filter out the video in the session from the playlist
    let relatedVideos = relatedPlaylist?.videos.filter(v => v).filter((v) => v?.id !== video?.id);
    console.log('related videos', relatedVideos);
    
    // for the two video that do not have other videos in a playlist manually added related videos
    if (video.id === 'd8f3000a-f506-4ca6-912b-196643b25cc6') {
        // for ratios and percents a related video would be operations of fractions
        relatedVideos = allVideos.filter((v) => v.id === '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08');
    }
    if (video.id === '092fbe10-302a-4d0f-a867-695e6028f2e0') {
        // for Pythagorean a related video would be definitions
        relatedVideos = allVideos.filter((v) => v.id === 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3');
    }

    // favorite video
    const toggleFavoriteVideo = useToggleFavoriteVideo(video?.id);
    const videoStats = useVideoStats(video?.id);

    // if the props or sessionid or video changes post the session
    useEffect(() => {
        if (video) {
            if (postVideoSession)
                postVideoSession(video.id, sessionId);
        }
    }, [postVideoSession, props, sessionId, video, video.id]);

    // when video begins
    const onVideoStart = () => {
        // create the session
        postEvent('video:start', { videoId: video.id });
    };

    // the user clicks "take challenge"
    const handleTakeChallenge = (level = null) => {
        if (video.quiz_id) {
            const path = `/quiz/${video.quiz_id ?? video.id}`;
            const query = { video_id: video.id };
            if (level) {
                query['level'] = level;
            }
            navigate(`${path}?${queryString.stringify(query)}`);
        }
    };

    // create the video player option
    const sources = useMemo(() => createVideoSources(video), [video]);
    if (!video) return null;

    const sliderResponsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 938,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];

    const metaData = {
        // viewerUserId: user.id,
        videoId: video.id,
        videoTitle: video.title,
        videoSeries: 'pre-algebra',
        videoStreamType: 'on-demand'
        // accountName: user.accountName
    };

    let videoPlayer = (
        <Player
            aria-label={`video player for ${video.title}`}
            user={user}
            video={video}
            assignment={assignment}
            key={sessionId}
            className='sticky-video'
            autoplay={false}
            controls={true}
            sources={sources}
            poster={createVideoPoster(video?.url_thumbnail ?? '')}
            metaData={metaData}
            onReady={onVideoStart}
            onPlay={onVideoPlay}
            onEnd={onVideoEnd}
            postVideoSessionRanges={postVideoSessionRanges}
        />
    );

    const watchAgain = () => {
        // create a new session
        console.log('[VideoSession] Clicked "Watch Again"');

        // create the new
        createSession(video_id);

        // hide the end card
        setEndCard(false);

        // play again
        play();
    };

    // take the current challenge
    const takeChallenge = () => {
        console.log('[VideoSession] Clicked "Take Challenge"');
        // TODO: unify this behavior with the end of quiz screen
        if (instructor) {
            handleTakeChallenge(1);
        } else {
            handleTakeChallenge(video?.stats?.current_level);
        }
    };

    // disable quiz button
    return (
        <VideoSessionContainer className='video-session-container' $teacher={instructor}>
            <ScreenRow
                className='info-section'
                background={blackBackground ? 'linear-gradient(180deg, #000000 93.34%, rgba(0, 0, 0, 0) 100%)' : 'none'}
                paddingBottom={blackBackground && '50px'}
                paddingTop='0'
                teacher={instructor}
            >
                <Transition
                    show={!blackBackground}
                    enter='transition ease-out duration-500'
                    enterFrom='transform opacity-0 scale-0'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-500'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-0'
                >
                    <div className='hearts-texture' />
                </Transition>
                <div
                    tw='relative mt-6'
                    css={[
                        css`
                            @media only screen and (max-height: 740px) {
                                display: flex;
                                justify-content: center;
                            }
                        `
                    ]}
                >
                    <button
                        tabIndex='0'
                        tw='absolute -left-3 '
                        style={{ top: '-3.375rem', cursor: 'pointer' }}
                        onClick={toggleFavoriteVideo}
                        css={[
                            css`
                                &:focus-visible {
                                    outline-offset: 0;
                                }

                                @media only screen and (min-width: 560px) and (max-height: 740px) {
                                    left: 7.25rem !important;
                                }
                            `
                        ]}
                    >
                        <HeartFavoriteIcon className='fav' favorite={videoStats?.favorite ?? false} />
                    </button>

                    <VideoPlayerLyricsPopOver
                        lyrics={video.lyrics}
                        lyricsFile={video.lyrics_file}
                        copyrightYear={video.copyright_year}
                    />

                    {videoPlayer}

                    {endCard && <EndCard onClickedWatchAgain={watchAgain} onClickedTakeChallenge={takeChallenge} />}

                    <div
                        tw='absolute top-0 -left-11'
                        css={[
                            css`
                                @media only screen and (max-height: 740px) {
                                    left: 5.25rem !important;
                                }
                            `
                        ]}
                    >
                        {instructor ? null : blackBackground ? null : hideProgressBar || !assignment ? null : (
                            <VideoPlayerProgress
                                quiz_level_goal={assignment.quiz_level_goal}
                                quiz_score_goal={assignment.quiz_score_goal}
                                stats={video.stats}
                            />
                        )}
                    </div>
                </div>

                {instructor && !parent ? (
                    <VideoInfoTeacher video={video} />
                ) : (
                    <VideoInfo
                        video={video}
                        onTakeChallenge={handleTakeChallenge}
                        lyrics={video.lyrics_file}
                        toggleFavorite={() => toggleFavoriteVideo()}
                        quiz_level_goal={assignment?.quiz_level_goal}
                        quiz_score_goal={assignment?.quiz_score_goal}
                        stats={video.stats}
                        hideProgressBar={hideProgressBar}
                        assignment={assignment}
                        parent={parent}
                    />
                )}
            </ScreenRow>

            <ScreenRowLyrics className='lyrics-section' background='none'>
                <div className='screen-row-container--wrapper'>
                    <VideoLyrics
                        lyricsFile={video.lyrics_file}
                        lyrics={video.lyrics}
                        copyrightYear={video.copyright_year}
                    />
                </div>
            </ScreenRowLyrics>

            {!video.quiz_id ? null : (
                <>
                    {instructor && !parent ? (
                        <ScreenRow className='assignment-section'>
                            <div className='assignment-section--title'>Records</div>
                            <div className='assignment-section--content'>
                                <AssignmentProgressContainer
                                    video={video}
                                    assignment={assignment}
                                    handleTakeChallenge={handleTakeChallenge}
                                    instructor={instructor}
                                />
                            </div>
                        </ScreenRow>
                    ) : (
                        <ScreenRow>
                            <AssignmentProgressContainer
                                video={video}
                                assignment={assignment}
                                handleTakeChallenge={handleTakeChallenge}
                                instructor={instructor}
                                parent={parent}
                                selectedStudent={selectedStudent}
                                setSelectedStudent={setSelectedStudent}
                                listBoxItems={listBoxItems}
                            />
                        </ScreenRow>
                    )}
                </>
            )}

            <ScreenRow className='achievement-section' background='none'>
                {instructor && !parent ? null : (
                    <VideoAchievementContent achievements={video.stats && video.stats.achievements ? video.stats.achievements : null} parent={parent} />
                )}
            </ScreenRow>

        </VideoSessionContainer>
    );
};

// video session style container
const VideoSessionContainer = styled.div`
    position: relative;
    flex-grow: 1;
    height: ${(props) => (props.$teacher === true ? 'none' : '100%')};
    overflow-y: scroll;
    background: ${(props) => props.theme.gradients.dark};

    .info-section {
        padding-top: 50px;

        .hearts-texture {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: url('${hearts_transparent_texture}');
            background-size: contain;
            background-color: transparent;
            background-repeat: no-repeat;

            @media screen and (max-width: 724px) {
                display: none;
            }
        }
    }

    .video-player {
        transition: width 0.2s ease-in-out, height 0.2s ease-in-out, transform 0.38s ease-in-out;

        .sticky-video {
            position: fixed;
            top: 15px;
            left: auto;
            max-width: 280px;
            max-height: 158px;
            width: 280px;
            height: 158px;
        }

        @media screen and (min-width: 1120px) {
            .sticky-video {
                transform: translateX(-80%);
            }
        }
        @media screen and (min-width: 1300px) {
            .sticky-video {
                transform: translateX(-115%);
            }
        }
    }

    .assignment-section {
        margin: 21px auto 42px;
        @media screen and (max-width: 500px) {
            margin: 0;
            padding-left: 0px;
            padding-right: 0px;
        }

        .screen-row-container--wrapper {
            max-width: 1250px;
        }

        .assignment-section--title {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
        }

        .assignment-section--content {
            margin: 32px 0 0;
            background: rgba(42, 38, 77, 0.5);
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 15px;
            @media screen and (min-width: 1024px) {
                padding: 45px 40px;
            }
            @media screen and (max-width: 500px) {
                border-radius: 0px;
            }
        }
    }

    .related-videos-section {
        margin: 21px auto 42px;

        .screen-row-container--wrapper {
            max-width: 1250px;
        }

        .related-videos-section--title {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
        }

        .related-videos-section--content {
            margin: 32px 0 0;
            background: #ffffff;
            border-radius: 10px;
            padding: 27px 15px;
        }
    }
`;

const ScreenRowContainer = styled.div`
    background: ${(props) => props.background};
    background-image: ${(props) => props.backgroundImage};
    background-size: cover;
    background-position-x: 50%;
    position: relative;
    padding-top: ${(props) => props.$paddingTop || 0};
    padding-bottom: ${(props) => props.$paddingBottom || 0};
    padding-left: 40px;
    padding-right: 40px;
    transition: all 1.5s ease;
    height: ${(props) => (props.$teacher ? '100%' : 'none')};

    @media screen and (max-width: 724px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media screen and (max-width: 640px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .screen-row-container--wrapper {
        max-width: var(--video-session-layout-width);
        margin: 0 auto;
    }

    .screen-row-container.lyrics-section {
        @media screen and (max-width: 500px) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .screen-row-container.assignment-section {
        @media screen and (max-width: 500px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
`;

const ScreenRowLyrics = styled.div`
    background: ${(props) => props.background};
    background-image: ${(props) => props.backgroundImage};
    background-size: cover;
    background-position-x: 50%;
    position: relative;
        // padding-top: ${(props) => props.$paddingTop || 0};
        // padding-bottom: ${(props) => props.$paddingBottom || 0};
    padding-left: 40px;
    padding-right: 40px;
    transition: all 1.5s ease;
        //height: ${(props) => (props.$teacher ? '100%' : 'none')};

    /* max-width: 1100px;
    margin: 0 auto; */

    @media screen and (max-width: 1100px) {
    }

    @media screen and (max-width: 724px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media screen and (max-width: 640px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (max-width: 500px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    .screen-row-container--wrapper {
        max-width: var(--video-session-layout-width);
        margin: 0 auto;
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
`;

export default VideoSession;
