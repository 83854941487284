import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import { Button, Modal } from 'components';

const QuitAssigningPlaylist = (props) => {
    const { isOpen, setIsOpen, onContinue, onCancel, onSaveDraft, parent, onQuitParent } = props;

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalChildrenStyle={tw`w-full  max-w-[380px] xsm:max-w-[806px] mx-auto z-50`}>
            <div tw='relative bg-white rounded-2xl flex-col justify-center font-sans xsm:min-w-[800px]'>
                <div tw='bg-gradient-to-r to-PholxPink from-SkyBlueCrayola py-2.5 text-center text-white text-lg font-extrabold rounded-t-2xl '>{`Quit ${parent ? 'Sharing' : 'Assigning'} Playlist`}</div>
                <div tw='bg-white rounded-bl-xl rounded-br-xl px-4 py-8 text-center'>
                    <div tw='space-y-6'>
                        <p tw='font-extrabold text-lg text-DarkLiver'>{`Are you sure you want to quit ${parent ? 'sharing' : 'creating'} this playlist?`}</p>
                    </div>
                    <div tw=' space-y-3 xsm:space-y-0 space-x-0 xsm:space-x-4 mt-7'>
                        {parent ? (
                            <>
                                <Button onClick={onQuitParent} variant='secondary'>
                                    QUIT
                                </Button>
                                <Button onClick={onContinue}>CONTINUE SHARING PLAYLIST</Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={onContinue}>CONTINUE ASSIGNING</Button>    
                                <Button onClick={onCancel} variant='secondary'>
                                    QUIT WITHOUT SAVING
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                {/* <div>
                    <div
                        tw='bg-white h-12 w-12 absolute -top-4 -right-4 rounded-full flex justify-center items-center shadow-md cursor-pointer'
                        onClick={() => setShowModal(false)}
                    >
                        <svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M16.705 1.257c-.71-.711-1.773-.711-2.482 0l-5.2 5.211-5.201-5.211c-.71-.711-1.773-.711-2.482 0-.71.71-.71 1.776 0 2.487l5.2 5.212-5.2 5.211c-.71.71-.71 1.777 0 2.487.355.356.827.474 1.3.474.473 0 .946-.118 1.3-.474l5.2-5.211 5.2 5.211c.355.356.828.474 1.301.474s.946-.118 1.3-.474c.71-.71.71-1.776 0-2.487l-5.2-5.211 5.2-5.212c.473-.71.473-1.777-.236-2.487z'
                                fill='#6A7277'
                            />
                        </svg>
                    </div>
                </div> */}
            </div>
        </Modal>
    );
};

QuitAssigningPlaylist.propTypes = {
    anyProp: PropTypes.any
};

export default QuitAssigningPlaylist;
