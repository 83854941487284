import React from 'react';
import tw, { css, styled } from 'twin.macro';
import SilverBackground from './backgroundImages/silver.png';
import GoldBackground from './backgroundImages/gold.png';
import PlatinumBackground from './backgroundImages/plat.png';
import { wrapTitleParens } from 'lib/string';


const Background = styled.div(({ level }) => [
    tw` bg-contain bg-no-repeat h-full `,
    level === 1 && css`background-image: url(${SilverBackground});
      background-size: 100% 100%; `,
    level === 2 && css`background-image: url(${GoldBackground});
      background-size: 100% 100%; `,
    level === 3 && css`background-image: url(${PlatinumBackground});
      background-size: 100% 100%;`
]);

const TitleBackground = styled.div(({ level }) => [
    tw`rounded-xl max-w-[103px] mx-auto `,
    level === 1 && css`background: linear-gradient(270deg, #B1B1B1 -1.03%, #898989 59.91%);`,
    level === 2 && css`background: linear-gradient(270deg, #F2B845 -0.61%, #A06F27 99.39%);`,
    level === 3 && css`background: linear-gradient(90deg, #46A6F8 0%, #B15AFE 100%); `

]);

const LineClampCss = css`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 640px) {
        -webkit-line-clamp: 3;
    }
    -webkit-line-clamp: 4;

`;

const DTCPlaque = ({
    recordImage,
    recordText,
    multiplier,
    copy,
    level,
}) => {
    return (
        <div tw='max-w-[250px] h-[300px] w-full'>
            <div tw='p-1 background[linear-gradient(91.31deg, #46A6F8 0.89%, #B15AFE 98.12%)] h-[300px]'>
                <Background level={level}>
                    <div tw='relative'>
                        <div tw='py-[1.6rem] px-2 relative'>
                            {multiplier && (
                                <span
                                    tw='h-8 w-8 text-lg font-extrabold rounded-full bg-[#02AC6F] text-white flex justify-center items-center absolute top-4 right-4'>
                                    {multiplier}
                                </span>
                            )}
                            <div tw='px-2 xsm:px-5 mb-3 relative flex items-center justify-center'>
                                <div tw='max-w-[113px]'>
                                    <img tw='w-full h-auto' src={recordImage} alt='record' />
                                </div>

                                {recordText &&
                                    <span tw='fontSize[32px] font-extrabold absolute text-white'>{recordText}</span>}
                            </div>
                            <div tw='px-2 text-center text-white pt-2.5'>
                                <TitleBackground level={level}>
                                    <h3 tw='text-sm font-medium '>
                                        {copy.title}
                                    </h3>
                                </TitleBackground>

                                <p tw='text-[0.7rem] sm:text-xs font-medium mt-2.5 min-h-[49px] text-center flex items-center justify-center px-0.5 uppercase' css={[LineClampCss]}>{wrapTitleParens(copy.videoTitle)}</p>
                            </div>
                        </div>
                    </div>
                </Background>
            </div>
        </div>
    );
};

export default DTCPlaque;