import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

/**
 *
 * @param items
 *      array of items with the following shape:
 *          id: the item id
 *          name: the item text
 * @param onChange (optional)
 *      a callback to receive the selected item ID
 * @param selected (optional)
 *      the id of the selected item
 * @param placeholder (optional)
 *      string to display if there is no selection
 * @param initialSelected
 * @param scroll
 * @param variant
 * @returns {JSX.Element}
 * @constructor
 */

const ListBox = ({ items, onChange, selected, placeholder, initialSelected, scroll, variant }) => {
    console.log("🚀 ~ ListBox ~ variant:", variant)
    const [selectedItem, setSelectedItem] = useState(initialSelected ?? selected ?? null);

    const handleChanged = (item) => {
        if (onChange)
            onChange(item);
        setSelectedItem(item);
    };

    const handleSVG = () => {
        if (variant === 'purple') {
            return (
                <span tw='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                    <svg
                        width='25' height='14' viewBox='0 0 25 14' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M2 2L12.5 12L23 2' stroke='white' strokeWidth='3.5' strokeLinecap='round'
                            strokeLinejoin='round' />
                    </svg>
                </span>
            );
        }
        if (variant === 'transparent') {
            return (
                <span tw='flex items-center pr-2 pointer-events-none minHeight[33px] ml-3.5'>
                    <svg
                        width='25' height='14' viewBox='0 0 25 14' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M2 2L12.5 12L23 2' stroke='white' strokeWidth='3.5' strokeLinecap='round'
                            strokeLinejoin='round' />
                    </svg>
                </span>
            );
        }
        if (variant === 'transparent-small') {
            return (
                <span tw='pr-2 pt-1.5  pointer-events-none minHeight[33px] ml-3.5'>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M3.33594 5.33398L7.62632 10.2373C7.82552 10.4649 8.17969 10.4649 8.37889 10.2373L12.6693 5.33398' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                    </svg>

                </span>
            );

        }
        if (variant === 'transparent-small-parent-home') {
            return (
                <span tw='pr-2 pt-1.5  pointer-events-none minHeight[33px] ml-3.5'>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M3.33594 5.33398L7.62632 10.2373C7.82552 10.4649 8.17969 10.4649 8.37889 10.2373L12.6693 5.33398' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                    </svg>

                </span>
            );

        } else
            return (
                <span tw='flex items-center pr-2 pointer-events-none'>
                    <svg
                        width='12' height='6' viewBox='0 0 12 6' fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M0.51593 0.415704C0.951931 0.0366037 1.55893 0.00685368 2.09193 0.415704L5.99993 3.60065L9.90793 0.415704C10.4409 0.00685368 11.0489 0.0366037 11.4819 0.415704C11.9179 0.793954 11.8899 1.43315 11.4819 1.78845C11.0759 2.14375 6.78693 5.61515 6.78693 5.61515C6.6848 5.70513 6.56238 5.77669 6.427 5.82557C6.29161 5.87444 6.14602 5.89963 5.99893 5.89963C5.85184 5.89963 5.70625 5.87444 5.57087 5.82557C5.43548 5.77669 5.31307 5.70513 5.21093 5.61515C5.21093 5.61515 0.923931 2.14375 0.51593 1.78845C0.10693 1.43315 0.0799304 0.793954 0.51593 0.415704Z'
                            fill='#5A5353'
                        />
                    </svg>
                </span>
            );


    };

    // css variant for Listbox.Button
    let ListBoxButtonCss = tw`bg-white`;
    if (variant === 'purple') ListBoxButtonCss = tw`bg-DarkIndigo border border-white`;
    if (variant === 'transparent') ListBoxButtonCss = tw`bg-none border-none shadow-none focus:blur-none items-start justify-start px-0 `;
    if (variant === 'transparent-small') ListBoxButtonCss = tw`text-lg font-semibold bg-none border-none shadow-none focus:blur-none items-center justify-end px-0 min-w-[200px] `;
    if (variant === 'transparent-small-parent-home') ListBoxButtonCss = tw`text-lg font-semibold bg-none border-none shadow-none focus:blur-none items-center justify-start md:justify-end px-0 min-w-[200px] `;


    // css variant for span
    let ListSpanCss = tw`text-DarkLiver`;
    if (variant === 'purple') ListSpanCss = tw`text-white ml-1.5 text-2xl`;
    if (variant === 'transparent') ListSpanCss = tw`text-white text-2xl`;
    if (variant === 'transparent-small') ListSpanCss = tw`text-white text-lg font-semibold`;
    if (variant === 'transparent-small-parent-home') ListSpanCss = tw`text-white text-lg font-semibold`;


    // css variant for Listbox.Option
    let ListBoxOptionCss = tw`text-base`;
    if (variant === 'purple' || variant === 'transparent') ListBoxOptionCss = tw`text-2xl`;
    if (variant === 'transparent-small') ListBoxOptionCss = tw`bg-[#4D3095] text-lg font-semibold text-white hover:text-white hover:bg-[#1C0057]`;
    if (variant === 'transparent-small-parent-home') ListBoxOptionCss = tw`bg-[#4D3095] text-lg font-semibold text-white hover:text-white hover:bg-[#1C0057]`;



    const selection = selected ?? selectedItem;

    return (
        <Listbox value={selection} onChange={handleChanged}>
            <div tw='relative mt-1 w-full' id='awards-dropdown'>
                <Listbox.Button
                    tw='relative flex items-center justify-between w-full py-2 px-2  text-left  rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm'
                    css={[ListBoxButtonCss]}
                >
                    <span tw='block truncate' css={[ListSpanCss]}>
                        {selection ? (selection.name ?? selection.title) : placeholder ?? items[0]?.title ?? <span>&nbsp;</span>}
                    </span>
                    {handleSVG()}
                </Listbox.Button>

                <Listbox.Options
                    tw='absolute z-20 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                    style={scroll && { height: '190px' }}
                    css={[ListBoxOptionCss]}

                >
                    {items.map((item) => (
                        <Listbox.Option
                            key={item.id ?? item.name ?? item.title}
                            value={item}
                            disabled={item.unavailable}
                            tw='text-DarkLiver select-none relative py-2 px-2 cursor-pointer hover:bg-GhostWhite focus:outline-none focus:ring-2 focus:ring-GhostWhite focus:ring-opacity-50'
                            css={[ListBoxOptionCss]}
                        >
                            {item.name ?? item.title}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

ListBox.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    selected: PropTypes.object,
    placeholder: PropTypes.string
};

export default ListBox;
