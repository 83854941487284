import React from 'react';
import tw, { css, styled } from 'twin.macro';
import { toMMSS } from 'utils/time';
import moment from 'moment';

moment.updateLocale('en', {
    relativeTime: {
        future: 'In %s',
        past: '%s Ago',
        s: 'a Few Seconds',
        ss: '%d Seconds',
        m: 'a Minute',
        mm: '%d Minutes',
        h: 'An Hour',
        hh: '%d Hours',
        d: 'a Day',
        dd: '%d Days',
        M: 'a Month',
        MM: '%d Months',
        y: 'A Year',
        yy: '%d Years'
    }
});

const NewVideoOverlay = styled.div(() => [
    tw`absolute left-2.5 top-2.5 flex justify-center items-center font-medium w-24 h-6 py-1 px-2 rounded-xl text-white text-sm uppercase font-Poppins`,
    css`border: 1px solid rgba(255, 255, 255, 0.60); background: linear-gradient(0deg, #6441B9 0%, #6441B9 100%), #FEFEFE;`
]);

const DurationOverlay = styled.div(() => [tw`absolute bottom-2.5 right-2.5 bg-[#4D3977] text-white rounded-lg h-6 w-11 text-sm font-Poppins flex justify-center items-center`]);

const AssignmentTag = styled.div(({ assignmentStatus, teacherHasAssignedVideo }) => [
    // The common button styles added with the tw import
    tw`absolute left-2.5 top-2.5 flex justify-center items-center min-w-[9rem] h-6 py-1 px-2 rounded-xl text-white text-sm font-medium uppercase font-Poppins`,

    // Use props  to conditionally style your components
    assignmentStatus === 'UPCOMING' && css`border: 1px solid rgba(255, 255, 255, 0.60); background: linear-gradient(0deg, #05146C 0%, #05146C 100%), #FEFEFE;`,
    assignmentStatus === 'OVERDUE' && css` border: 1px solid rgba(255, 255, 255, 0.60); background: linear-gradient(0deg, #FB0202 0%, #FB0202 100%), #FEFEFE;`,
    assignmentStatus === 'COMPLETED' && css` border: 1px solid rgba(255, 255, 255, 0.60); background: linear-gradient(0deg, #02AC6F 0%, #02AC6F 100%), #FEFEFE;`,
    teacherHasAssignedVideo && tw`absolute left-2.5 top-2.5 flex justify-center items-center w-24 h-6 py-1 px-2 rounded-xl text-white font-medium text-sm uppercase font-Poppins`,
    teacherHasAssignedVideo && css`border: 1px solid rgba(255, 255, 255, 0.60); background: linear-gradient(0deg, #4D058F 0%, #4D058F 100%), #FEFEFE;`

]);

const CreateAssignmentTag = ({ assignments, teacherHasAssignedVideo, teacher }) => {
    let assignmentStatus;
    let assignmentStatusCopy;
    let dueDate;
    // console.log('VideoProgressIndicator', assignments);

    assignments?.forEach((a) => {
        if (a.assignment) {
            dueDate = moment(a.assignment.due).fromNow('D');
            assignmentStatus = moment().isAfter(a.assignment.due) ? 'OVERDUE' : 'UPCOMING';
            if (assignmentStatus === 'OVERDUE') {
                assignmentStatusCopy = ` ${dueDate} Overdue`;
            }
            if (assignmentStatus === 'UPCOMING') {
                assignmentStatusCopy = ` Due In ${dueDate}`;
            }
            if (dueDate === 'a day') {
                if (assignmentStatus === 'OVERDUE') {
                    assignmentStatusCopy = ` ${dueDate} Day Overdue`;
                }
                if (assignmentStatus === 'UPCOMING') {
                    assignmentStatusCopy = ` Due In ${dueDate} Day`;
                }
            }

            // checking if assignment has been complete
            let assignmentProgress = a.assignment.progress?.completed ?? false;
            if (assignmentProgress === true) {
                assignmentStatusCopy = 'Completed';
                assignmentStatus = 'COMPLETED';
            }
        }
        if (dueDate === 'Invalid date') {
            return null;
        }
    });
    if (teacherHasAssignedVideo && teacher) {
        assignmentStatusCopy = 'Assigned';
    }
    if (dueDate === 'Invalid date') {
        return null;
    }
    return (
        <AssignmentTag assignmentStatus={assignmentStatus} teacherHasAssignedVideo={teacherHasAssignedVideo && teacher}>
            <div tw='pointer-events-none'>{assignmentStatusCopy}</div>
        </AssignmentTag>
    );
};

const VideoCardIndicator = ({ assignments, isNew, isMini, isAssigned, videoDuration, teacherHasAssignedVideo, teacher, hideDuration, assignmentPage }) => {
    if (assignmentPage) return null;
    const formattedDuration = toMMSS(videoDuration);
    const showIsNew = teacher ? !teacherHasAssignedVideo && isNew : !isAssigned && isNew;
    const showAssignmentTag = teacherHasAssignedVideo || isAssigned;
    return (
        <>
            {!hideDuration && <DurationOverlay>{formattedDuration}</DurationOverlay>}
            {showAssignmentTag && <CreateAssignmentTag assignments={assignments} teacherHasAssignedVideo={teacherHasAssignedVideo} teacher={teacher} />}
            {showIsNew && <NewVideoOverlay>New Video</NewVideoOverlay>}
            {isMini && <div tw='bg-white text-black text-center absolute bottom-0 left-0 font-Poppins font-medium text-xl py-1 px-2.5'>MINI</div>}
        </>
    );
};

export default VideoCardIndicator;