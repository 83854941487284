import React from 'react';

const SvgCategoryFunctions = (props) => (
    <svg
        viewBox='0 0 28 28'
        width='1em'
        height='1em'
        {...props}>
        <g transform='translate(-38 -730)'>
            <circle
                data-name='category_functions'
                cx={14}
                cy={14}
                r={14}
                transform='translate(38 730)'
                fill='white' />
            <text
                data-name='f(x)'
                transform='translate(41 736)'
                fill='#1E215'
                fontSize={13}
                fontFamily='Avenir-Black,Avenir'
                fontWeight={800}>
                <tspan
                    x={1.672}
                    y={13}
                    letterSpacing='.045em'>
                    f
                </tspan>
                <tspan
                    y={13}
                    fontFamily='Avenir-Heavy,Avenir'
                    letterSpacing='.045em'>
                    (
                </tspan>
                <tspan
                    y={13}
                    fontFamily='Avenir-Heavy,Avenir'>
                    x)
                </tspan>
            </text>
        </g>
    </svg>
);

export default SvgCategoryFunctions;
