import React from 'react';
import { styled } from 'twin.macro';
import { PROGRESS_ITEM_CORRECT, PROGRESS_ITEM_INCORRECT } from 'modules/quiz/constants';
import progress_check from 'static/images/white-check.png';
import progress_x from 'static/images/white-x.png';
import { css } from 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import { useMedia } from 'react-use';
import ProgressBackground from './backgrounds/quiz_progress_background.png';
import ProgressBackgroundSmall from './backgrounds/quiz_progress_small.png';

const Container = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    height: 76px;
    width: 100%;
    max-width: 320px;
    font-family: 'Lato', sans-serif;
    
    .indicator-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .connector-line {
            height: 5.5px;
            width: 48px;
            background: #6441B9;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            @media only screen and (max-width: 1280px) and (min-width: 1024px) {
                // 767 - 600
                width: 35px;
                // 2 + 10 + 2 = 14px
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                // 767 - 600
                width: 25px;
                // 2 + 10 + 2 = 14px
            }

            @media only screen and (max-width: 767px) and (min-width: 600px) {
                // 767 - 600
                width: 100%;

                // 2 + 10 + 2 = 14px
            }

            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (max-width: 766px) {
                width: 100%;
                height: 3px;
                // 2 + 8 + 2 = 12px
            }
        }

        @media only screen and (max-width: 767px) {
            display: grid;
            row-gap: 15px;
            grid-template-columns: ${(props) => props.$mini ? 'repeat(5, minmax(0, 1fr))' : 'repeat(10, minmax(0, 1fr))'} ;
            margin-left: ${(props) => props.$mini ? '10px' : '30px'};
        }
        @media only screen and (max-width: 640px) {
            row-gap: 8px;

        }
    }

    @media only screen and (min-width: 640px) {
        max-width: 525px;
        height: 124px;
    }


    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        max-width: 650px;
        height: 63px;
    }

    @media only screen and (max-width: 1280px) and (min-width: 1024px) {
        max-width: 780px;
        height: 76px;
    }

    @media only screen and (min-width: 1281px) {
        max-width: 950px;
        height: 92px;
    }




    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
    }
    @media only screen and (max-width: 767px) and (min-width: 500px) {
        // 767 and lower
    }
    @media only screen and (max-width: 640px) {
        /* margin-left: 30px; */
    }
`;

const Indicator = styled.div`
    z-index: 100;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.$color ?? '#5D686F'};
    border-radius: 50%;

    .indicator-center {
        // Style for "Ellipse 40"
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        background-color: #6441B9;
        font-weight: 600;
        font-size: 18px;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        
    }

    margin: 0;
    //border-image linear-gradient(184deg, #ffffff, #a8a8a8);
    transition: width 0.1s, height 0.1s, margin 0.1s, box-shadow 0.1s, text-shadow 0.1s;

    &.current {
        border-color: #ffffff;
        border: solid 2px white;
        height: 24px;
        width: 24px;
        .indicator-center {
            border-radius: 50%;
            background-color: #46A6F8;
            height: 18px;
            width: 18px;
        }

        @media only screen and (min-width: 640px) {
        height: 40px;
        width: 40px;
        margin-top: 0px;

        .indicator-center {
            font-size: 18px;
            color: #ffffff;
            height: 32px;
            width: 32px;
        }
    }

    @media only screen and (min-width: 768px) {
        height: 30px;
        width: 30px;
        margin-top: 0px;

        .indicator-center {
            font-size: 18px;
            color: #ffffff;
            height: 22px;
            width: 22px;
        }
    }

    @media only screen and (min-width: 1020px) {
        width: 35px;
        height: 35px;
        border-color: #ffffff;
        border: solid 2px white;
        .indicator-center {
            width: 25px;
            height: 25px;
        }
    }

    @media only screen and (min-width: 1280px) {
        width: 42px;
        height: 42px;
        border-color: #ffffff;
        border: solid 2px white;;
        .indicator-center {
            width: 30px;
            height: 30px;
        }
    }

    }

    &.enable-input {
        cursor: pointer;

        &.current,
        &:hover,
        &:active {
            transition: width 0.1s, height 0.1s, margin 0.1s, box-shadow 0.1s, text-shadow 0.1s;

            height: 56px;
            width: 56px;

            margin-left: 0;
            margin-right: 0;

            font-size: 32px;
            color: #ffffff;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

            &:active {
                text-shadow: 0 -1px -2px rgba(0, 0, 0, 0.5);
                box-shadow: 0 -2px -4px 0 rgba(0, 0, 0, 0.5);
            }
        }
    }

    // correct & incorrect overrides current
    &.correct {
        border-color: white;
        .indicator-center {
            background-color: #5fc17a;
        }
    }

    &.incorrect {
        border-color: white;
        .indicator-center {
            background-color: #ee7171;
        }
    }

    &.unanswered {
        border-color: white;
        .indicator-center {
            /* background-color: yellow; */
        }
    }


    // mobile first
    height: 22px;
    width: 22px;
    .indicator-center {
        font-size: 12px;
        color: white;
        height: 22px;
        width: 22px;
    }

    // small
    @media only screen and (min-width: 640px) {
        height: 40px;
        width: 40px;
        margin-top: 0px;

        .indicator-center {
            font-size: 18px;
            color: white;
            height: 40px;
            width: 40px;
        }

    }


    // med
    @media only screen and (min-width: 768px) {
        height: 28px;
        width: 28px;
        margin-top: 0px;

        .indicator-center {
            font-size: 18px;
            color: #ffffff;
            height: 28px;
            width: 28px;
        }
    }

    // large


    @media only screen and (min-width: 1020px) {
        height: 30px;
        width: 30px;
        margin-top: 0px;

        .indicator-center {
            font-size: 20px;
            color: #ffffff;
            height: 30px;
            width: 30px;
        }
    }

    @media only screen and (min-width: 1280px) {
        height: 36px;
        width: 36px;
        margin-top: 0px;
        .indicator-center {
            font-size: 18px;
            color: #ffffff;
            height: 36px;
            width: 36px;
        }
    }

    /*

    @media only screen and (max-width: 600px) {
        height: 30px;
        width: 30px;

        .indicator-center {
            font-size: 12px;
            height: 22px;
            width: 22px;
        }
    }
    @media only screen and (max-width: 425px) {
        height: 26px;
        width: 26px;

        .indicator-center {
            font-size: 10px;
            height: 18px;
            width: 18px;
        }
    }
    @media only screen and (max-width: 350px) {
        height: 22px;
        width: 22px;

        .indicator-center {
            font-size: 8px;
            height: 14px;
            width: 14px;
        }
    }

    @media only screen and (min-width: 1200px) {
        // 1200 and over
    }
    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        // 992 - 1199
    }
    @media only screen and (max-width: 991px) and (min-width: 768px) {
        // 768 - 992
    }
    @media only screen and (max-width: 767px) {
        // 767 and lower
    }
    */
`;

const QuizProgress = ({ className, backgroundColor, backgroundImage, color, current, enableUserInteraction, onSelect, progress }) => {
    const isSmall = useMedia('(max-width: 767px)');

    const handleClick = (index) => {
        if (enableUserInteraction) {
            if (onSelect) onSelect(index);
        }
    };

    const renderIndicators = (indicators) => {
        if (!indicators) return [];
        let count = typeof indicators !== 'undefined' ? indicators.length : indicators.count();

        let nodes = [];
        indicators.forEach((value, index) => {
            if (index >= count) return;
            let content = index + 1;

            let className = 'progress-indicator ';

            // get the class name
            if (index === current) {
                className += ' current ';
            } else {
                if (value === PROGRESS_ITEM_CORRECT) {
                    className += ' correct ';
                } else if (value === PROGRESS_ITEM_INCORRECT) {
                    className += ' incorrect ';
                } else {
                    if (index < current) {
                        // past
                        className += ' normal';
                    } else {
                        // future
                        className += ' normal unanswered';
                    }
                }
            }

            if (value === PROGRESS_ITEM_CORRECT) {
                className += ' correct ';

                // content = <span>&#10004;</span>;
            } else if (value === PROGRESS_ITEM_INCORRECT) {
                className += ' incorrect ';
            }

            if (enableUserInteraction) className = ' enable-input ';

            let indicator = (
                <Indicator
                    key={index}
                    className={className}
                    onClick={() => handleClick(index)}
                >
                    <div className='indicator-center'>{content}</div>
                </Indicator>
            );

            nodes.push(indicator);
            // console.log('current:', current);
            if (index !== count - 1) {
                // add a connector line
                nodes.push(
                    <div
                        key={`${index}-connector`}
                        className='connector-line'
                        css={[
                            index === 4 &&
                            css`
                                    @media only screen and (max-width: 767px) {
                                        background: transparent !important;
                                        box-shadow: none !important;
                                    }
                                `
                        ]}
                    />
                );
            }
        });

        return nodes;
    };
    console.log('QuizProgress', progress);
    return (
        <Container
            className={className}
            style={{
                backgroundImage: backgroundImage,
                backgroundColor: backgroundColor,
                color: color
            }}
            $mini={progress?.length === 3}
            $color={color}
        >
            <BackgroundImage image={isSmall ? ProgressBackgroundSmall : ProgressBackground} />

            <div className='indicator-container'>{renderIndicators(progress)}</div>
        </Container>
    );
};

export default QuizProgress;
