import { List, Map, fromJS } from 'immutable';
import { apiClient, getAuthToken } from 'utils/api-client';
import { v4 as uuidv4 } from 'uuid';
// import { quizEditorPath } from '../studio/quiz_editor';
import AchievementConfig from '../../app/constants/AchievementConstants';
import {
    QUIZ_STATE_LOADING,
    QUIZ_STATE_LOADED,
    QUIZ_STATE_LOAD_FAILED,
    QUIZ_STATE_INSTANCE_LOADING,
    QUIZ_STATE_INSTANCE_LOADED,
    QUIZ_STATE_INSTANCE_LOAD_FAILED
} from './constants';

// import moment from 'moment';

// Identifiers
const FETCH_QUIZ_INSTANCE = 'muzology/quiz/FETCH_QUIZ_INSTANCE';
const FETCH_QUIZ_INSTANCE_SUCCESS = 'muzology/quiz/FETCH_QUIZ_INSTANCE_SUCCESS';
const FETCH_QUIZ_INSTANCE_FAILURE = 'muzology/quiz/FETCH_QUIZ_INSTANCE_FAILURE';

const FETCH_QUIZ_DEFINITION = 'muzology/quiz/FETCH_QUIZ_DEFINITION';
const FETCH_QUIZ_DEFINITION_SUCCESS = 'muzology/quiz/FETCH_QUIZ_DEFINITION_SUCCESS';
const FETCH_QUIZ_DEFINITION_FAILURE = 'muzology/quiz/FETCH_QUIZ_DEFINITION_FAILURE';

const FETCH_ALL_QUIZ_DEFINITION = 'muzology/quiz/FETCH_ALL_QUIZ_DEFINITION';
const FETCH_ALL_QUIZ_DEFINITION_SUCCESS = 'muzology/quiz/FETCH_ALL_QUIZ_DEFINITION_SUCCESS';
const FETCH_ALL_QUIZ_DEFINITION_FAILURE = 'muzology/quiz/FETCH_ALL_QUIZ_DEFINITION_FAILURE';

const POST_QUIZ_RESULTS = 'muzology/quiz/POST_QUIZ_RESULTS';
const POST_QUIZ_RESULTS_SUCCESS = 'muzology/quiz/POST_QUIZ_RESULTS_SUCCESS';
const POST_QUIZ_RESULTS_FAILURE = 'muzology/quiz/POST_QUIZ_RESULTS_FAILURE';

const SAVE_QUIZ_RESPONSE = 'muzology/quiz/SAVE_QUIZ_RESPONSE';
const SAVE_QUIZ_RESPONSE_SUCCESS = 'muzology/quiz/SAVE_QUIZ_RESPONSE_SUCCESS';
const SAVE_QUIZ_RESPONSE_FAILURE = 'muzology/quiz/SAVE_QUIZ_RESPONSE_FAILURE';

const FETCH_QUIZ_CATALOG = 'muzology/quiz/FETCH_QUIZ_CATALOG';
const FETCH_QUIZ_CATALOG_SUCCESS = 'muzology/quiz/FETCH_QUIZ_CATALOG_SUCCESS';
const FETCH_QUIZ_CATALOG_FAILURE = 'muzology/quiz/FETCH_QUIZ_CATALOG_FAILURE';

const QUEUE_QUIZ_RESULTS = 'muzology/quiz/QUEUE_QUIZ_RESULTS';
const QUEUE_QUIZ_QUESTION = 'muzology/quiz/QUEUE_QUIZ_QUESTION';

const POST_COMPLETED = 'muzology/quiz_session/POST_COMPLETED';
const POST_COMPLETED_SUCCESS = 'muzology/quiz_session/POST_COMPLETED_SUCCESS';
const POST_COMPLETED_FAILURE = 'muzology/quiz_session/POST_COMPLETED_FAILURE';

// selectors
export const getSessionID = (state) => {
    return state.getIn(['quiz', 'session', 'instance', 'id'], null);
};

// Actions

// loads a quiz definition
export function fetchQuizDefinition(quiz_id) {
    console.assert(quiz_id);
    return async (dispatch) => {
        try {
            const url = `/api/quizzes/${quiz_id}/`;
            // console.log('Requesting quiz definition: ', url);
            await dispatch({ type: FETCH_QUIZ_DEFINITION });
            const { data } = await apiClient.get(url);
            // console.log(data);
            return await dispatch({ type: FETCH_QUIZ_DEFINITION_SUCCESS, payload: data });
        } catch (err) {
            console.error(err);
            return await dispatch({ type: FETCH_QUIZ_DEFINITION_FAILURE });
        }
    };
}

// export function fetchAllQuizDefinitions() {
//     return async (dispatch) => {
//         try {
//             const url = '/api/fullquizzes/';
//             console.log('Requesting quiz definition: ', url);
//             await dispatch({ type: FETCH_ALL_QUIZ_DEFINITION });
//             const { data } = await apiClient.get(url);
//             console.log(data);
//             await dispatch({ type: FETCH_ALL_QUIZ_DEFINITION_SUCCESS, payload: data });
//         } catch (err) {
//             console.error(err);
//             await dispatch({ type: FETCH_ALL_QUIZ_DEFINITION_FAILURE });
//         }
//     };
// }

// export function fetchQuizCatalog() {
//     return async (dispatch) => {
//         try {
//             const url = '/api/quizzes/catalog/';
//             console.log('Requesting quiz definition: ', url);
//             await dispatch({ type: FETCH_QUIZ_CATALOG });
//             const { data } = await apiClient.get(url);
//             console.log(data);
//             await dispatch({ type: FETCH_QUIZ_CATALOG_SUCCESS, payload: data });
//         } catch (err) {
//             console.error(err);
//             await dispatch({ type: FETCH_QUIZ_CATALOG_FAILURE });
//         }
//     };
// }

// fetch a session instance for a quiz
// export function fetchQuizInstance(quiz_id, dontCreate = false, level = null) {
//     console.log('fetching quiz instance for quiz_id', quiz_id, level);
//
//     return async (dispatch) => {
//         let url = '/api/quiz_sessions/';
//         if (dontCreate) {
//             // dont create the current quiz - ask for an exact quiz & level
//             url = `${url}find/${quiz_id}/`;
//             if (level)
//                 url = `${url}${level}/`;
//         }
//
//         try {
//             console.log('Requesting quiz session: ', url);
//             await dispatch({ type: FETCH_QUIZ_INSTANCE });
//             const ret = await apiClient.get(url);
//             console.log(ret);
//             await dispatch({ type: FETCH_QUIZ_INSTANCE_SUCCESS, payload: ret.data });
//         } catch (err) {
//             console.error(err);
//             await dispatch({ type: FETCH_QUIZ_INSTANCE_FAILURE });
//         }
//     };
// }

// post a single response to the quiz session api
export function postResponse(response) {

    return async (dispatch, getState) => {
        // console.log('saving response: ', response);

        let session_id = getState().getIn(['quiz', 'session', 'instance', 'id'], null);
        let responses = [response];
        const payload = JSON.stringify(responses);
        const meta = { question_index: response.question_index, response };

        try {
            const url = `/api/quiz_sessions/${session_id}/responses/`;
            // console.log('Posting quiz responses: ', url);
            await dispatch({ type: SAVE_QUIZ_RESPONSE, meta });
            const ret = await apiClient.post(url, payload,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
            // console.log(ret);
            await dispatch({ type: SAVE_QUIZ_RESPONSE_SUCCESS, payload: ret.data, meta });
        } catch (err) {
            console.error(err);
            await dispatch({ type: SAVE_QUIZ_RESPONSE_FAILURE, meta });
        }
    };
}

export function postCompleted() {
    return async (dispatch, getState) => {
        console.log('posting quiz complete');

        // get the session id
        let session_id = getSessionID(getState());
        console.assert(session_id);

        try {
            const url = `/api/quiz_sessions/${session_id}/complete/`;
            console.log('Requesting quiz definition: ', url);
            await dispatch({ type: POST_COMPLETED });
            const { data } = await apiClient.post(url, {});
            console.log(data);
            await dispatch({ type: POST_COMPLETED_SUCCESS, payload: data });
            return data;
        } catch (err) {
            console.error(err);
            await dispatch({ type: POST_COMPLETED_FAILURE });
            throw new Error(err);
        }
    };
}

// export function postQuizResults(results) {
//     return async (dispatch) => {
//         // place the results into a queue
//         if (results) dispatch({ type: QUEUE_QUIZ_RESULTS, payload: results });
//
//         // attempt to post it to the server
//         return dispatch(postNextQuizResults());
//     };
// }

// export function postNextQuizResults() {
//     return async (dispatch, getState) => {
//         // grab the next results
//         let next = getState().getIn(['quiz', 'session', 'saving', 'results'], List()).first();
//         if (!next)
//             return false;
//
//         // check if the results are posting
//         let is_posting = getState().getIn(['quiz', 'session', 'saving', 'posting'], false);
//         if (is_posting)
//             return false;
//
//         try {
//             const url = `/api/quiz/results/${next.id}`;
//             console.log('Requesting quiz definition: ', url);
//             await dispatch({ type: POST_QUIZ_RESULTS });
//             const { data } = await apiClient.post(url, next);
//             console.log(data);
//             await dispatch({ type: POST_QUIZ_RESULTS_SUCCESS, payload: data });
//         } catch (err) {
//             console.error(err);
//             await dispatch({ type: POST_QUIZ_RESULTS_FAILURE });
//         }
//     };
// }

// Reducer
export default function quizReducer(state = Map(), action = {}) {
    switch (action.type) {
        case FETCH_QUIZ_DEFINITION:
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADING }));

        case FETCH_QUIZ_DEFINITION_SUCCESS: {
            // merge into the quiz catalog
            // console.log('FETCH_QUIZ_DEFINITION_SUCCESS', action.payload);
            const quiz = action.payload;
            state = state.mergeDeepIn(['quiz', 'quizzes', quiz.id], quiz);

            // set the api state as loaded, and clear any errors
            state = state.mergeIn(['quiz', 'session', 'error'], null);
            state = state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADED }));

            // load the quiz editor if this is selected
            // let quiz = getQuiz(state, action.payload.quiz_id);
            // let selected_quiz_id = state.getIn(quizEditorPath.push('selected_quiz_id'), null);
            // if (selected_quiz_id && selected_quiz_id === quiz.id) {
            //     // console.log('select quiz', quiz, state.get('quiz', Map()).toJS());
            //     state = state.setIn(quizEditorPath.push('selected_quiz'), fromJS(quiz));
            // }
            return state;
        }

        case FETCH_QUIZ_DEFINITION_FAILURE: {
            state = state.mergeIn(['quiz', 'session', 'error'], fromJS(action.payload));
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOAD_FAILED }));
        }

        case FETCH_ALL_QUIZ_DEFINITION:
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADING }));

        case FETCH_ALL_QUIZ_DEFINITION_SUCCESS: {
            // const quizzes = action.payload;
            // state = state.setIn(['quiz', 'quizzes'], quizzes);

            // quiz map
            // let quiz_map = action.payload.reduce((item_map, item) => {
            //     return { ...item_map, [item.id]: item };
            // }, {});
            // item_map[item.id] = item;
            // return item_map;
            // }, {});

            // return videos.mergeDeep(fromJS({
            //     ...video_map,
            //     error: null,
            //     loading: false
            // }));
            let quiz_map = action.payload.reduce((item_map, item) => ({ ...item_map, [item.id]: item }), {});
            state = state.setIn(['quiz', 'quizzes'], fromJS(quiz_map));

            state = state.mergeIn(['quiz', 'session', 'error'], null);
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADED }));
        }

        case FETCH_ALL_QUIZ_DEFINITION_FAILURE: {
            state = state.mergeIn(['quiz', 'session', 'error'], fromJS(action.payload));
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOAD_FAILED }));
        }

        case FETCH_QUIZ_INSTANCE:
            state = state.mergeIn(['quiz', 'session', 'error'], null);
            return state.mergeIn(['quiz', 'session'], fromJS({ state: QUIZ_STATE_INSTANCE_LOADING }));

        case FETCH_QUIZ_INSTANCE_SUCCESS: {
            const session = {
                instance: action.payload,
                state: QUIZ_STATE_INSTANCE_LOADED
            };
            return state.mergeIn(['quiz', 'session'], fromJS(session));
        }

        case FETCH_QUIZ_INSTANCE_FAILURE: {
            state = state.mergeIn(['quiz', 'session', 'error'], fromJS(action.payload));
            return state.mergeIn(['quiz', 'session'], fromJS({ state: QUIZ_STATE_INSTANCE_LOAD_FAILED }));
        }

        case SAVE_QUIZ_RESPONSE:
            // console.log('SAVE_QUIZ_RESPONSE:', action);
            if (action.error) {
                // there was an error attempting the POST
                return state.setIn(['quiz', 'session', 'saving', 'failed', action.meta.question_index], {
                    response: action.meta.response,
                    error: action.payload
                });
            } else {
                // a question response is being POSTed
                return state.setIn(['quiz', 'session', 'saving', 'posting', action.meta.question_index], action.meta.response);
            }

        case SAVE_QUIZ_RESPONSE_SUCCESS:
            // a response was saved successfully, move it from 'posting' to 'success'
            state = state.deleteIn(['quiz', 'session', 'saving', 'posting', action.meta.question_index]);
            state = state.setIn(['quiz', 'session', 'saving', 'success', action.meta.question_index], action.meta.response);

            // also remove any potential failures
            state = state.deleteIn(['quiz', 'session', 'saving', 'failed', action.meta.question_index]);
            return state;

        case SAVE_QUIZ_RESPONSE_FAILURE:
            console.error('SAVE_QUIZ_RESPONSE_FAILURE', action);
            state = state.deleteIn(['quiz', 'session', 'saving', 'posting', action.meta.question_index]);
            return state.setIn(['quiz', 'session', 'saving', 'failed', action.meta.question_index], {
                response: action.meta.response,
                error: action.payload
            });

            /////////////////////////////////////
            // post results & queue
            /////////////////////////////////////

        case QUEUE_QUIZ_QUESTION:
            return state.updateIn(['quiz', 'session', 'saving', 'questions'], List(), (saving) => saving.push(fromJS(action.payload)));

        case QUEUE_QUIZ_RESULTS:
            return state.updateIn(['quiz', 'session', 'saving', 'results'], List(), (saving) => saving.push(fromJS(action.payload)));

        // begin to post results for a question
        case POST_QUIZ_RESULTS:
            // clear any error
            state = state.setIn(['quiz', 'session', 'saving', 'error'], null);
            state = state.setIn(['quiz', 'session', 'saving', 'posting'], true);
            return state;
        // return state.mergeIn(['quiz', 'session', 'context', 'state'], 'POST_QUIZ_RESULTS');
        case POST_COMPLETED_SUCCESS:
            if (action.payload) {
                const { assignments, badges, results, stats } = action.payload;
                //
                // console.log('POST_COMPLETED_SUCCESS', action.payload);
                // save updated results in the session instance
                if (results) {
                    state = state.setIn(['quiz', 'session', 'instance'], fromJS(results));
                }

                // save updated stats
                if (stats) {
                    state = state.setIn(['stats', 'video_stats', stats.video_id], fromJS(stats));
                }

                // save new badges
                if (badges) {
                    // put a copy of the badges in the quiz session
                    state = state.setIn(['quiz', 'session', 'badges'], fromJS(badges));

                    // add the badges to the user stats
                    // console.log('saving badges to toast', badges);
                    // badges.forEach((badge) => {
                    //     state = state.updateIn(['stats', 'badges'], List(), (items) => items.push(fromJS(badge)));
                    //
                    //     // create a toast notification
                    //     const details = AchievementConfig[badge.badge_type];
                    //     const toast = {
                    //         id: uuidv4(),
                    //         title: details.header,
                    //         message: badge.description,
                    //         type: 'default'
                    //     };
                    //     console.log(toast);
                    //     state = state.updateIn(['toast'], List(), (items) => items.push(fromJS(toast)));
                    //     console.log(state);
                    // });

                    // push the badges to a toast notification
                    // state = state.update('toast', List(), (items) => {
                    //     badges.forEach((badge) => {
                    //         // get the badge details
                    //         const details = AchievementConfig[badge.badge_type];
                    //         items.push(fromJS({
                    //             title: details.header,
                    //             message: badge.description,
                    //             type: 'default'
                    //         }));
                    //     });
                    // });
                }
            }
            return state;

        // question posted successfully
        case POST_QUIZ_RESULTS_SUCCESS: {
            // pop the results from the queue
            state = state.updateIn(['quiz', 'session', 'saving', 'results'], List(), (saving) => saving.shift());

            if (action.payload) {
                // save updated stats
                let stats = action.payload.stats;
                if (stats) {
                    // console.log("Saving stats", stats);
                    state = state.setIn(['stats', 'video_stats', stats.video_id], fromJS(stats));
                }

                // save updated results in the session instance
                let results = action.payload.results;
                if (results) {
                    state = state.setIn(['quiz', 'session', 'instance'], fromJS(results));
                }
            }

            // clear the flag
            state = state.setIn(['quiz', 'session', 'saving', 'posting'], false);

            return state;
            // set the state to
            // return state.mergeIn(['quiz', 'session', 'context', 'state'], 'POST_QUIZ_RESULTS_SUCCESS');
        }

        case POST_QUIZ_RESULTS_FAILURE: {
            // TODO: failure buffer & retry...
            console.error('POST_QUIZ_RESULTS_FAILURE', action);

            state = state.setIn(['quiz', 'session', 'error'], fromJS(action.payload));
            // clear the flag
            return state.setIn(['quiz', 'session', 'saving', 'posting'], false);
            // return state.mergeIn(['quiz', 'session', 'context', 'state'], 'POST_QUIZ_RESULTS_FAILURE');
        }

        case FETCH_QUIZ_CATALOG:
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADING }));

        case FETCH_QUIZ_CATALOG_SUCCESS: {
            // quiz map
            let quiz_map = action.payload.reduce((item_map, item) => {
                item_map[item.id] = item;
                return item_map;
            }, {});

            state = state.mergeDeepIn(['quiz', 'quizzes'], fromJS(quiz_map));

            // state = state.mergeIn(['quiz', 'session', 'error'], null);
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOADED }));
        }

        case FETCH_QUIZ_CATALOG_FAILURE: {
            // state = state.mergeIn(['quiz', 'session', 'error'], fromJS(action.payload));
            return state.mergeIn(['api', 'quizzes'], fromJS({ state: QUIZ_STATE_LOAD_FAILED }));
        }

        default:
            return state;
    }
}
