import React, { useState, useEffect, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { Helmet } from 'react-helmet';
import { StatsOverViewCard, StatsOverViewCardMobile, TabNavigator } from '../../../../components';
import { useVideoStats, useVideosWithStats } from '../../../../state/Student/VideoStats';
import { useActivity } from '../../../../state/User/ActivityState';
import { useUser } from '../../../../state/User/UserState';
import MXPProgress from './MXPProgress';
import RecentMXP from './RecentMXP';



const TitleGradientBlue = css`
    background: linear-gradient(227deg, #1576E7 0%, #6441B9 100%);
`;


const ProfileContainer = () => {
    const user = useUser();
    const videos = useVideosWithStats();
    const stats = useVideoStats();
    const { activity, downloadActivity } = useActivity();

    useEffect(() => {
        downloadActivity();
    }, []);

    // filter stats for only unlocked videos
    const unlockedStats = useMemo(() => Object.values(stats ?? {}).filter((stat) => stat.current_level > 0), [stats]);

    // filter videos that are unlocked
    const unlockedVideos = useMemo(() => {
        return videos.reduce((acc, video) => {
            if (unlockedStats.find((stat) => stat.video_id === video.id)) return [...acc, video];
            return acc;
        }, []);
    }, [videos, unlockedStats]);

    return (
        <div tw='h-full min-h-full overflow-hidden px-0 sm:px-6 py-3 sm:py-10 bg-[#1C0057] font-Poppins'>
            <Helmet>
                <title>My Profile</title>
            </Helmet>

            <div tw='2xl:max-w-[var(--layout-width)] mx-auto flex gap-4 max-h-full flex-col xl:flex-row'>
                <div tw='w-full xl:max-w-[300px] hidden xl:block'>
                    <StatsOverViewCard stats={unlockedStats} activity={activity} />
                </div>
                <div tw='w-full xl:max-w-[300px] block xl:hidden'>
                    <StatsOverViewCardMobile stats={unlockedStats} activity={activity} />
                </div>
                <div tw='w-full flex gap-4'>
                    <div tw='hidden xl:flex gap-4'>
                        <MXPProgress points={user?.points} />
                        <div tw='max-w-[350]'>
                            <RecentMXP />
                        </div>
                    </div>
                    <div tw='ml-2 w-full block xl:hidden'>
                        <div tw='mb-6 mr-3'>
                            <MXPProgress points={user?.points} />
                        </div>
                        <div tw='mr-3'>
                            <RecentMXP />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ProfileContainer;
