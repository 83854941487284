import React from 'react';
import { styled } from 'twin.macro';

const SVGContainer = styled.div`
    margin: ${(props) => props.m || '0'};
    padding: ${(props) => props.p || '0'};
    width: ${(props) => props.width || 'auto'};
    height: ${(props) => props.height || 'auto'};
	fill: ${(props) => (props.fill ? props.fill : '#ffffff')};
`;

export const NotificationIcon = (props) => (
    <SVGContainer
        className='notification-icon'
        {...props}>
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='auto'
            height='auto'
            viewBox='0 0 24 24'>
            <path
                // fill={props.color || '#ffffff'}
                id='a'
                d='M12 21.75c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.11 6 7.67 6 10.75v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z'
            />
        </svg>
    </SVGContainer>
);

export const PrintIcon = (props) => (
    <SVGContainer
        className='print-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 40 36'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <path
                id='path-1'
                d='M38,16 L10,16 C6.68,16 4,18.68 4,22 L4,34 L12,34 L12,42 L36,42 L36,34 L44,34 L44,22 C44,18.68 41.32,16 38,16 Z M32,38 L16,38 L16,28 L32,28 L32,38 Z M38,24 C36.9,24 36,23.1 36,22 C36,20.9 36.9,20 38,20 C39.1,20 40,20.9 40,22 C40,23.1 39.1,24 38,24 Z M36,6 L12,6 L12,14 L36,14 L36,6 Z'
            />
        </svg>
    </SVGContainer>
);

export const RecordCheckIcon = (props) => (
    <SVGContainer
        className='record-check-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 22 17'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <polygon
                id='path-record-check'
                points='6 11.2 1.8 7 0.4 8.4 6 14 18 2 16.6 0.6' />
            <filter
                x='-19.9%'
                y='-18.7%'
                width='139.8%'
                height='152.2%'
                filterUnits='objectBoundingBox'
                id='filter-3'>
                <feOffset
                    dx='0'
                    dy='1'
                    in='SourceAlpha'
                    result='shadowOffsetOuter1' />
                <feGaussianBlur
                    stdDeviation='1'
                    in='shadowOffsetOuter1'
                    result='shadowBlurOuter1' />
                <feColorMatrix
                    values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0'
                    type='matrix'
                    in='shadowBlurOuter1' />
            </filter>
        </svg>
    </SVGContainer>
);

export const RecordLockIcon = (props) => (
    <SVGContainer
        className='record-lock-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 16 21'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fillRule='evenodd'>
                <g
                    id='locked-gold-record'
                    transform='translate(-4.000000, -1.000000)'>
                    <path
                        id='path-record-lock'
                        d='M18,8 L17,8 L17,6 C17,3.24 14.76,1 12,1 C9.24,1 7,3.24 7,6 L7,8 L6,8 C4.9,8 4,8.9 4,10 L4,20 C4,21.1 4.9,22 6,22 L18,22 C19.1,22 20,21.1 20,20 L20,10 C20,8.9 19.1,8 18,8 Z M12,17 C10.9,17 10,16.1 10,15 C10,13.9 10.9,13 12,13 C13.1,13 14,13.9 14,15 C14,16.1 13.1,17 12,17 Z M9,8 L9,6 C9,4.34 10.34,3 12,3 C13.66,3 15,4.34 15,6 L15,8 L9,8 Z'
                    />
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const RecordNewIcon = (props) => (
    <SVGContainer
        className='record-new-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 20 16'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Artboard'
                    transform='translate(-43.000000, -12.000000)'>
                    <g
                        id='icon/av/fiber_new_24px'
                        transform='translate(41.000000, 8.000000)'>
                        <mask
                            id='mask-20'
                            fill='white'>
                            <path
                                d='M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M8.5,15 L7.3,15 L4.75,11.5 L4.75,15 L3.5,15 L3.5,9 L4.75,9 L7.25,12.5 L7.25,9 L8.5,9 L8.5,15 Z M13.5,10.26 L11,10.26 L11,11.38 L13.5,11.38 L13.5,12.64 L11,12.64 L11,13.75 L13.5,13.75 L13.5,15 L9.5,15 L9.5,9 L13.5,9 L13.5,10.26 Z M20.5,14 C20.5,14.55 20.05,15 19.5,15 L15.5,15 C14.95,15 14.5,14.55 14.5,14 L14.5,9 L15.75,9 L15.75,13.51 L16.88,13.51 L16.88,9.99 L18.13,9.99 L18.13,13.5 L19.25,13.5 L19.25,9 L20.5,9 L20.5,14 Z'
                                id='path-record-new'
                            />
                        </mask>
                        <g fillRule='nonzero' />
                        <g
                            id='✱-/-Color-/-Icons-/-Black-/-Active'
                            mask='url(#mask-20)'
                            fill={props.color || '#ffffff'}
                            fillOpacity={props.opacity || 1}
                            fillRule='evenodd'
                        >
                            <rect
                                id='Rectangle-13'
                                x='0'
                                y='0'
                                width='24'
                                height='24' />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const SortButtonIcon = (props) => (
    <SVGContainer
        className='sort-button-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 18 12'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Videos-Desktop'
                    transform='translate(-227.000000, -94.000000)'>
                    <g
                        id='Group-3'
                        transform='translate(0.000000, 72.000000)'>
                        <g
                            id='Group'
                            transform='translate(151.000000, 16.000000)'>
                            <g
                                id='icon/content/sort_24px'
                                transform='translate(73.000000, 0.000000)'>
                                <mask
                                    id='mask-sort-button'
                                    fill='#fff'>
                                    <path
                                        d='M3,18 L9,18 L9,16 L3,16 L3,18 Z M3,6 L3,8 L21,8 L21,6 L3,6 Z M3,13 L15,13 L15,11 L3,11 L3,13 Z'
                                        id='path-sort-button'
                                    />
                                </mask>
                                <g fillRule='nonzero' />
                                <g
                                    id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                    mask='url(#mask-sort-button)'
                                    fill={props.color}
                                    fillRule='evenodd'>
                                    <rect
                                        id='Rectangle-3'
                                        x='0'
                                        y='0'
                                        width='24'
                                        height='24' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const TrophyIcon = (props) => (
    <SVGContainer
        className='trophy-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 216 216'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Quiz-Summary---Desktop'
                    transform='translate(-290.000000, -317.000000)'>
                    <rect
                        fill='#E6E6ED'
                        x='0'
                        y='0'
                        width='1440'
                        height='1024' />
                    <g
                        id='Group-2'
                        transform='translate(-149.000000, 71.000000)'
                        fillRule='nonzero'>
                        <g
                            id='Group-12'
                            transform='translate(397.000000, 204.000000)'>
                            <g id='trophy_image'>
                                <g
                                    id='trophy_icon'
                                    transform='translate(42.000000, 42.000000)'>
                                    <polygon
                                        id='Shape'
                                        fill='#FF7058'
                                        points='128 178 88 178 94.826087 163.545595 100.73913 150 115.26087 150 121.173913 163.545595'
                                    />
                                    <path
                                        d='M153.401221,178 L63.0364747,178 C58.0642518,178 54,182.022523 54,186.943694 L54,216 L161.962092,216 L161.962092,186.943694 C162.437696,182.022523 158.373444,178 153.401221,178 Z'
                                        id='Shape'
                                        fill='#FFD15C'
                                    />
                                    <path
                                        d='M121,163.432071 C117.39604,165.302895 113.834983,167.173719 110.231023,168.554566 L108,169 L105.768977,168.064588 C102.165017,166.683742 98.6039604,164.812918 95,162.942094 L100.834983,149 L114.735974,149 L121,163.432071 Z'
                                        id='Shape'
                                        fill='#F1543F'
                                    />
                                    <g
                                        id='Group'
                                        fill='#F8B64C'>
                                        <path
                                            d='M0,24.8295455 L0,43.3113636 C0,88.4363636 27.8820717,127.256818 66.9944223,143.493182 C79.6015936,148.890909 93.4996016,151.611364 107.913944,151.611364 C122.328287,151.611364 136.226295,148.890909 148.833466,143.493182 C187.945817,127.256818 215.827888,88.4363636 215.827888,43.3113636 L215.827888,24.8295455 L0,24.8295455 Z M197.842231,43.3113636 C197.842231,64.9886364 190.183267,84.8090909 177.145817,100.613636 C160.494024,120.477273 135.796016,133.561364 107.913944,133.561364 C80.0318725,133.561364 55.2908367,120.909091 38.6820717,100.613636 C26.0749004,85.2840909 17.9856574,64.9886364 17.9856574,43.3113636 L17.9856574,42.8795455 L197.368924,42.8795455 L197.368924,43.3113636 L197.842231,43.3113636 Z'
                                            id='Shape'
                                        />
                                        <path
                                            d='M178.049402,0 L37.7784861,0 C34.6374502,0 32.4,2.72045455 32.4,5.39772727 C32.4,8.55 34.6374502,11.2704545 37.7784861,11.2704545 L178.049402,11.2704545 C181.190438,11.2704545 183.427888,8.55 183.427888,5.87272727 C183.427888,2.72045455 181.190438,0 178.049402,0 Z'
                                            id='Shape'
                                        />
                                    </g>
                                    <path
                                        d='M178,11 L178,59.694373 C178,104.765781 150.171779,145.782919 108,162 C65.8282209,145.308483 38,104.765781 38,59.2630677 L38,11 L178,11 Z'
                                        id='Shape'
                                        fill='#FFD15C'
                                    />
                                    <polygon
                                        id='Shape'
                                        fill='#FFFFFF'
                                        points='108.521479 31 117.929309 59.9544419 148 59.9544419 123.771615 78.0455581 132.749864 107 108.521479 88.9088838 84.2930941 107 93.228385 78.0455581 69 59.9544419 99.0706906 59.9544419'
                                    />
                                    <rect
                                        id='Rectangle-path'
                                        fill='#FFC952'
                                        x='38'
                                        y='11'
                                        width='140'
                                        height='7' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const AudioButtonIcon = (props) => (
    <SVGContainer
        className='audio-button-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 14 16'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <defs>
                <path
                    id='path-audio-button-icon'
                    d='M18.5,12 C18.5,10.23 17.48,8.71 16,7.97 L16,16.02 C17.48,15.29 18.5,13.77 18.5,12 Z M5,9 L5,15 L9,15 L14,20 L14,4 L9,9 L5,9 Z'
                />
            </defs>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Warmup-Question-Home---Desktop'
                    transform='translate(-673.000000, -652.000000)'>
                    <g
                        id='Group-20'
                        transform='translate(-123.000000, 186.000000)'>
                        <g
                            id='Group-10'
                            transform='translate(475.000000, 169.000000)'>
                            <g
                                id='Group-16'
                                transform='translate(0.000000, 169.000000)'>
                                <g
                                    id='Group-14-Copy'
                                    transform='translate(312.000000, 120.000000)'>
                                    <g
                                        id='icon/av/volume_down_24px'
                                        transform='translate(4.000000, 4.000000)'>
                                        <mask
                                            id='mask-2'
                                            fill='white'>
                                            <use xlinkHref='#path-audio-button-icon' />
                                        </mask>
                                        <g fillRule='nonzero' />
                                        <g
                                            id='✱-/-Color-/-Icons-/-Black-/-Active'
                                            mask='url(#mask-2)'
                                            fill='#000000'
                                            fillOpacity='1'
                                            fillRule='evenodd'>
                                            <rect
                                                id='Rectangle-13'
                                                x='0'
                                                y='0'
                                                width='24'
                                                height='24' />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const TriangleAlertIcon = (props) => (
    <SVGContainer
        className='triangle-alert-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 16 13'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <path
                    d='M0.666666667,14 L15.3333333,14 L8,1.33333333 L0.666666667,14 Z M8.66666667,12 L7.33333333,12 L7.33333333,10.6666667 L8.66666667,10.6666667 L8.66666667,12 Z M8.66666667,9.33333333 L7.33333333,9.33333333 L7.33333333,6.66666667 L8.66666667,6.66666667 L8.66666667,9.33333333 Z'
                    id='path-triangle-alert'
                />
            </defs>
            <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='assigned-overdue'
                    transform='translate(-68.000000, -5.000000)'>
                    <g id='Group'>
                        <g
                            id='icon/alert/warning_24px'
                            transform='translate(68.000000, 4.000000)'>
                            <mask
                                id='mask-alert'
                                fill='white'>
                                <path
                                    d='M0.666666667,14 L15.3333333,14 L8,1.33333333 L0.666666667,14 Z M8.66666667,12 L7.33333333,12 L7.33333333,10.6666667 L8.66666667,10.6666667 L8.66666667,12 Z M8.66666667,9.33333333 L7.33333333,9.33333333 L7.33333333,6.66666667 L8.66666667,6.66666667 L8.66666667,9.33333333 Z'
                                    id='path-triangle-alert'
                                />
                            </mask>
                            <g fillRule='nonzero' />
                            <g
                                id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                mask='url(#mask-alert)'
                                fill='#FFFFFF'
                                fillRule='evenodd'>
                                <rect
                                    id='Rectangle-3'
                                    x='0'
                                    y='0'
                                    width='16'
                                    height='16' />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const CircleInfoIcon = (props) => (
    <SVGContainer
        className='circle-info-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 14 14'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <path
                    d='M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M8.66666667,11.3333333 L7.33333333,11.3333333 L7.33333333,7.33333333 L8.66666667,7.33333333 L8.66666667,11.3333333 Z M8.66666667,6 L7.33333333,6 L7.33333333,4.66666667 L8.66666667,4.66666667 L8.66666667,6 Z'
                    id='path-circle-info'
                />
            </defs>
            <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='assigned-incomplete'
                    transform='translate(-69.000000, -5.000000)'>
                    <g id='Group'>
                        <g
                            id='icon/action/info_24px'
                            transform='translate(68.000000, 4.000000)'>
                            <mask
                                id='mask-circle-info'
                                fill='white'>
                                <path
                                    d='M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M8.66666667,11.3333333 L7.33333333,11.3333333 L7.33333333,7.33333333 L8.66666667,7.33333333 L8.66666667,11.3333333 Z M8.66666667,6 L7.33333333,6 L7.33333333,4.66666667 L8.66666667,4.66666667 L8.66666667,6 Z'
                                    id='path-circle-info'
                                />
                            </mask>
                            <g fillRule='nonzero' />
                            <g
                                id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                mask='url(#mask-circle-info)'
                                fill='#FFFFFF'
                                fillRule='evenodd'>
                                <rect
                                    id='Rectangle-3'
                                    x='0'
                                    y='0'
                                    width='16'
                                    height='16' />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const CircleCheckIcon = (props) => (
    <SVGContainer
        className='circle-check-icon'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 14 14'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <path
                    d='M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M6.19333333,10.86 L3.8,8.46666667 C3.67516488,8.34211232 3.6050096,8.17301176 3.6050096,7.99666667 C3.6050096,7.82032157 3.67516488,7.65122102 3.8,7.52666667 C4.06,7.26666667 4.48,7.26666667 4.74,7.52666667 L6.66666667,9.44666667 L11.2533333,4.86 C11.5133333,4.6 11.9333333,4.6 12.1933333,4.86 C12.4533333,5.12 12.4533333,5.54 12.1933333,5.8 L7.13333333,10.86 C6.88,11.12 6.45333333,11.12 6.19333333,10.86 Z'
                    id='path-circle-check'
                />
            </defs>
            <g
                id='Symbols'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='assigned-completed'
                    transform='translate(-69.000000, -5.000000)'>
                    <g id='Group'>
                        <g
                            id='Icon-/-Check-Circle-/-Rounded'
                            transform='translate(68.000000, 4.000000)'>
                            <mask
                                id='mask-circle-check'
                                fill='white'>
                                <path
                                    d='M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M6.19333333,10.86 L3.8,8.46666667 C3.67516488,8.34211232 3.6050096,8.17301176 3.6050096,7.99666667 C3.6050096,7.82032157 3.67516488,7.65122102 3.8,7.52666667 C4.06,7.26666667 4.48,7.26666667 4.74,7.52666667 L6.66666667,9.44666667 L11.2533333,4.86 C11.5133333,4.6 11.9333333,4.6 12.1933333,4.86 C12.4533333,5.12 12.4533333,5.54 12.1933333,5.8 L7.13333333,10.86 C6.88,11.12 6.45333333,11.12 6.19333333,10.86 Z'
                                    id='path-circle-check'
                                />
                            </mask>
                            <g
                                id='icon/action/check_circle_24px'
                                fillRule='nonzero' />
                            <g
                                id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                mask='url(#mask-circle-check)'
                                fill='#FFFFFF'
                                fillRule='evenodd'>
                                <rect
                                    id='Rectangle-3'
                                    x='0'
                                    y='0'
                                    width='16'
                                    height='16' />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const CirclePlayButton = (props) => (
    <SVGContainer
        className='circle-play-button'
        {...props}>
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='100%'
            height='100%'
            viewBox='0 0 24 24'>
            <defs>
                <path
                    id='circle-play-button'
                    d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-2-3.5l6-4.5-6-4.5v9z'
                />
            </defs>
            <g
                fill='none'
                fillRule='evenodd'>
                <mask
                    id='circle-play-button-mask'
                    fill='#fff'>
                    <use xlinkHref='#circle-play-button' />
                </mask>
                <g
                    fill='#fff'
                    fillOpacity='1.0'
                    mask='url(#circle-play-button-mask)'>
                    <path d='M0 0h24v24H0z' />
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const AssignmentInfo = (props) => (
    <SVGContainer
        className='circle-assignment-info'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 26 26'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <path
                    d='M8,1.33333333 C4.32,1.33333333 1.33333333,4.32 1.33333333,8 C1.33333333,11.68 4.32,14.6666667 8,14.6666667 C11.68,14.6666667 14.6666667,11.68 14.6666667,8 C14.6666667,4.32 11.68,1.33333333 8,1.33333333 Z M8.66666667,11.3333333 L7.33333333,11.3333333 L7.33333333,7.33333333 L8.66666667,7.33333333 L8.66666667,11.3333333 Z M8.66666667,6 L7.33333333,6 L7.33333333,4.66666667 L8.66666667,4.66666667 L8.66666667,6 Z'
                    id='path-assignment-info'
                />
            </defs>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Assignments---Desktop-Alt'
                    transform='translate(-320.000000, -234.000000)'>
                    <g
                        id='Group-16'
                        transform='translate(305.000000, 211.000000)'>
                        <g
                            id='assignment_icon_info'
                            transform='translate(16.000000, 24.000000)'>
                            <circle
                                id='Oval'
                                stroke='#1673B6'
                                fill='#1B98FF'
                                cx='12'
                                cy='12'
                                r='12' />
                            <g
                                id='icon/action/info_24px'
                                transform='translate(4.000000, 4.000000)'>
                                <mask
                                    id='mask-assignment-info'
                                    fill='white'>
                                    <use xlinkHref='#path-assignment-info' />
                                </mask>
                                <g fillRule='nonzero' />
                                <g
                                    id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                    mask='url(#mask-assignment-info)'
                                    fill='#FFFFFF'
                                    fillRule='evenodd'
                                >
                                    <rect
                                        id='Rectangle-3'
                                        x='0'
                                        y='0'
                                        width='16'
                                        height='16' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const AssignmentOverdue = (props) => (
    <SVGContainer
        className='circle-assignment-overdue'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 26 26'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <path
                    d='M8.33336667,5.39653333 L7.33336667,5.39653333 L7.33336667,9.39653333 L10.5000333,11.2965333 L11.0000333,10.4765333 L8.33336667,8.89653333 L8.33336667,5.39653333 Z M11.5577,1.27053333 L14.6293,3.83293333 L13.7751667,4.8568 L10.7035667,2.2944 L11.5577,1.27053333 Z M4.44223333,1.27013333 L5.29636667,2.294 L2.22476667,4.8564 L1.3707,3.83253333 L4.44223333,1.27013333 Z M8.00003333,2.72986667 C4.6867,2.72986667 2.00003333,5.41653333 2.00003333,8.72986667 C2.00003333,12.0432 4.6867,14.7298667 8.00003333,14.7298667 C11.3133667,14.7298667 14.0000333,12.0432 14.0000333,8.72986667 C14.0000333,5.41653333 11.3133667,2.72986667 8.00003333,2.72986667 Z M8.00003333,13.3965333 C5.4267,13.3965333 3.33336667,11.3032 3.33336667,8.72986667 C3.33336667,6.15653333 5.4267,4.0632 8.00003333,4.0632 C10.5733667,4.0632 12.6667,6.15653333 12.6667,8.72986667 C12.6667,11.3032 10.5733667,13.3965333 8.00003333,13.3965333 Z'
                    id='path-assignment-overdue'
                />
            </defs>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Assignments---Desktop-Alt'
                    transform='translate(-319.000000, -322.000000)'>
                    <g
                        id='Group-16-Copy'
                        transform='translate(304.000000, 299.000000)'>
                        <g
                            id='assignment_icon_overdue'
                            transform='translate(16.000000, 24.000000)'>
                            <circle
                                id='Oval'
                                stroke='#AA3434'
                                fill='#D84343'
                                cx='12'
                                cy='12'
                                r='12' />
                            <g
                                id='icon/action/alarm_24px'
                                transform='translate(4.000000, 4.000000)'>
                                <mask
                                    id='mask-assignment-overdue'
                                    fill='white'>
                                    <use xlinkHref='#path-assignment-overdue' />
                                </mask>
                                <g fillRule='nonzero' />
                                <g
                                    id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                    mask='url(#mask-assignment-overdue)'
                                    fill='#FFFFFF'
                                    fillRule='evenodd'
                                >
                                    <rect
                                        id='Rectangle-3'
                                        x='0'
                                        y='0'
                                        width='16'
                                        height='16' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);

export const AssignmentCompleted = (props) => (
    <SVGContainer
        className='circle-assignment-complete'
        {...props}>
        <svg
            width='100%'
            height='100%'
            viewBox='0 0 26 26'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'>
            <defs>
                <polygon
                    id='path-assignment-complete'
                    points='6.18875 12.93875 3.06125 9.81125 2 10.8725 6.18875 15.06125 15.18875 6.06125 14.1275 5' />
            </defs>
            <g
                id='Student-Hi-Fi-v2'
                stroke='none'
                strokeWidth='1'
                fill='none'
                fillRule='evenodd'>
                <g
                    id='Assignments---Desktop-Alt'
                    transform='translate(-319.000000, -410.000000)'>
                    <g
                        id='Group-16-Copy-2'
                        transform='translate(304.000000, 387.000000)'>
                        <g
                            id='assignment_icon_complete'
                            transform='translate(16.000000, 24.000000)'>
                            <circle
                                id='Oval'
                                stroke='#028900'
                                fill='#0BC806'
                                cx='12'
                                cy='12'
                                r='12' />
                            <g
                                id='Icon-/-Done-/-Filled'
                                transform='translate(4.000000, 2.000000)'>
                                <mask
                                    id='mask-assignment-complete'
                                    fill='white'>
                                    <use xlinkHref='#path-assignment-complete' />
                                </mask>
                                <g
                                    id='Mask'
                                    fillRule='nonzero' />
                                <g
                                    id='✱-/-Color-/-Icons-/-Selected-/-On-Primary-/-Active-'
                                    mask='url(#mask-assignment-complete)'
                                    fill='#FFFFFF'
                                    fillRule='evenodd'
                                >
                                    <rect
                                        id='Rectangle-3'
                                        x='0'
                                        y='0'
                                        width='18'
                                        height='18' />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </SVGContainer>
);
