import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import useAxios from 'axios-hooks';
import { useForm } from 'react-hook-form';

import { ErrorBoundary, withErrorBoundary } from 'react-error-boundary';
import { AssignPlaylistNowOrLaterModal, Button, Modal, QuitPlaylistCreationModal } from '../components';
import { handlePlaylistStepsModal, removeVideoFromPlaylist, updatePlaylistName, usePlaylistState } from '../state/PlaylistState';
import { useParent } from '../state/User/UserState.js';
import { GradientBorder } from '../app/styles/GradientBorder';
import SvgWhiteXButton from '../images/icons/WhiteXButton';
import { showError } from '../app/AccountOnboard/Components/Components';

import { usePlaylistAssignWizardState } from '../state/PlaylistAssignWizardState';
import SideNav from './SideNav';

function PlaylistIcon(props) {
    const parent = useParent();
    if (parent) {
        return (
            <svg
                width='25' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'
                {...props}>
                <g clipPath='url(#prefix__clip0)' fill='#5A5353'>
                    <path
                        d='M12.5 0C5.5971 0 0 5.5971 0 12.5C0 19.4029 5.5971 25 12.5 25C19.4029 25 25 19.4029 25 12.5C25 5.5971 19.4029 0 12.5 0ZM17.8571 13.1696C17.8571 13.2924 17.7567 13.3929 17.6339 13.3929H13.3929V17.6339C13.3929 17.7567 13.2924 17.8571 13.1696 17.8571H11.8304C11.7076 17.8571 11.6071 17.7567 11.6071 17.6339V13.3929H7.36607C7.2433 13.3929 7.14286 13.2924 7.14286 13.1696V11.8304C7.14286 11.7076 7.2433 11.6071 7.36607 11.6071H11.6071V7.36607C11.6071 7.2433 11.7076 7.14286 11.8304 7.14286H13.1696C13.2924 7.14286 13.3929 7.2433 13.3929 7.36607V11.6071H17.6339C17.7567 11.6071 17.8571 11.7076 17.8571 11.8304V13.1696Z'
                        fill='#1AC1DD'
                    />
                </g>
                <defs>
                    <clipPath id='prefix__clip0'>
                        <path fill='#fff' d='M0 0h25v25H0z' />
                    </clipPath>
                </defs>
            </svg>
        );
    } else
        return (
            <svg
                width={24} height={22} fill='none' xmlns='http://www.w3.org/2000/svg'
                {...props}>
                <g clipPath='url(#prefix__clip0)' fill='#5A5353'>
                    <path
                        d='M16.207 6.623h7.392c.146 0 .273-.131.273-.28V4.716c0-.15-.127-.28-.273-.28h-6.771l-3.085-3.158c-.073-.075-.146-.094-.237-.075a.252.252 0 00-.22-.13H11.7a.284.284 0 00-.274.28v13.938c-.858-.317-1.88-.41-2.938-.187-2.391.524-4.015 2.467-3.632 4.335.383 1.869 2.646 2.952 5.019 2.43 2.245-.486 3.796-2.224 3.668-3.962V4.175l2.19 2.242a.27.27 0 00.201.075h.037a.31.31 0 00.237.13z' />
                    <path
                        d='M5.067 1.073c.24 0 .47.096.64.267.17.171.266.404.266.645v2.737h2.72c.24 0 .471.096.641.267a.915.915 0 01-.64 1.557h-2.72v2.737a.915.915 0 01-.266.645.904.904 0 01-1.548-.645V6.546H1.44a.904.904 0 01-.641-.267.915.915 0 01.64-1.557h2.72V1.985c0-.241.096-.474.266-.645.17-.17.401-.267.642-.267zM23.726 9.182h-7.392a.284.284 0 00-.273.28v1.626c0 .15.127.28.273.28h7.392c.146 0 .274-.13.274-.28V9.463c0-.15-.128-.28-.274-.28zM23.726 13.966h-7.392a.284.284 0 00-.273.28v1.625c0 .15.127.28.273.28h7.392c.146 0 .274-.13.274-.28v-1.625c0-.15-.128-.28-.274-.28z' />
                </g>
                <defs>
                    <clipPath id='prefix__clip0'>
                        <path fill='#fff' d='M0 0h24v22H0z' />
                    </clipPath>
                </defs>
            </svg>
        );
}

function ParentPlaylistIcon(props) {
}

const Container = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;

    .category-list {
        background: #3b454d;
        //height: 100%;
        flex-grow: 0;
        max-height: 100%;
        min-height: 100%;

        .list-scroll {
            overflow-y: scroll;
            background: #4c4698;

            margin: 0;
            padding: 0;
        }
    }

    .contents {
        flex-grow: 1;
        overflow-y: scroll;
        overflow-x: hidden;

        ::-webkit-scrollbar {
        }
    }
`;

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
};

const EditPlaylistNameModal = ({ isOpen, setIsOpen, modalChildrenStyle, onEditPlaylistName, playlist }) => {
    /*
        This opens a dialog box asking for a playlist name
     */
    const { register, handleSubmit, errors: formErrors, reset: resetPlaylistName, setFocus, formState } = useForm();
    const ref = register({ required: true });
    // const nameRef = useRef(null);
    const [nameRef, setInputFocus] = useFocus();

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // when a user clicks outside of this modal it will fire this off
            modalChildrenStyle={modalChildrenStyle}
        >
            <div tw='mx-4'>
                <div tw='bg-white p-6 rounded-xl relative'>
                    <h3 tw='font-medium text-2xl mb-5'>Edit Playlist Name</h3>
                    <button
                        onClick={() => setIsOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-4 -top-6 w-12 h-12 flex justify-center items-center shadow-md '
                    >
                        <SvgWhiteXButton tw='w-4 h-4' />
                    </button>
                    <form onSubmit={handleSubmit(onEditPlaylistName)}>
                        <input
                            name='name'
                            ref={(e) => {
                                ref(e);
                                nameRef.current = e; // assign to ref
                            }}
                            css={[
                                tw`bg-GainsboroGrey px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full`,
                                formErrors.name && tw`border-red-400 border focus:outline-none focus:ring-2 focus:ring-red-400 focus:border-transparent`
                            ]}
                            type='text'
                            placeholder={playlist.name}
                            autoFocus
                            defaultValue={playlist.name}
                        />
                        {formErrors && showError(formErrors.name)}
                        <div tw='ml-auto w-max mt-6'>
                            <Button type='submit'>Update Name</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};

/*
    A CategoryListView is a view that contains a CategoryList on the left sidebar,
    with child components on the right side
 */
const CategoryListView = (
    {
        categories,
        categoriesHeader,
        categoriesSortable,
        categoryNames,
        children: content,
        className,
        contentsContainerStyle,
        index,
        match,
        onSelectCategory,
        pageProperties,
        pages,
        playlists,
        progressOverlay,
        routePrefix,
        sections,
        selectedCategory: current_category,
        setSearch,
        standardsOverlay,
        title
    }) => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const location = useLocation();
    const parent = useParent();
    const { ref, _, height } = useResizeObserver();
    const [playlistState, playlistDispatch, savePlaylist, , resetPlaylist] = usePlaylistState();
    const { playlist, isPlaylistDropdownOpen } = playlistState;
    const [isAssignPlaylistNowOrLaterModalOpen, setAssignPlaylistNowOrLaterModalOpen] = useState(false);
    const [isQuitPlaylistCreationModalOpen, setQuitPlaylistCreationModalOpen] = useState(false);
    const { action, playlist_id } = routeParams;
    const [{ loading: isUpdating }, updatePlaylist] = useAxios({ url: `/api/assignment_playlists/${playlist_id}/`, method: 'PATCH' }, { manual: true });

    useEffect(() => {
        document.documentElement.style.setProperty('--playlist-wizard-height', `${height}px`);
    }, [height]);

    if (!current_category && categories && categories.length) current_category = categories[0].id;

    const handleSelectCategory = (category_id) => {
        // find the item
        let item = null;
        if (sections) {
            sections.map((section) => {
                if (!item && section.items) {
                    item = section.items.find((item) => item.id === category_id);
                }
                setSearch(null);
                return item;
            });
        }

        // tell the parent
        if (onSelectCategory) onSelectCategory(category_id);

        // push a link
        if (navigate) {
            // if the item has a link, push it
            if (item && item.link) {
                // push the link
                navigate(`${item.link}`);
                return;
            }

            // route prefix
            if (routePrefix)
                navigate(`${routePrefix}${category_id}`);
        }
    };

    const renderCategoryPage = (category_id) => {
        if (category_id && pages) {
            const item = pages.find((item) => item.id === category_id);
            if (item && item.page) {
                const Component = withErrorBoundary(item.page);

                return (
                    <ErrorBoundary>
                        <Component {...(pageProperties)} />
                    </ErrorBoundary>
                );
            }
        }
    };

    if (pages) {
        let category_id = null;
        if (match && match.params && match.params.category_id) {
            category_id = match.params.category_id;
            content = renderCategoryPage(category_id);
        }
    }

    const [assignmentWizardState, playlistAssignWizardDispatch, loadPlaylistDetails, saveAssignment] = usePlaylistAssignWizardState();

    // begin assignment wizard - on clicking "assign now" after playlist wizard
    const beginAssignmentWizard = React.useCallback(
        (playlist) => {
            console.log('Assign Now: ', playlist, playlistState);

            // load the playlist id into the assignment wizard
            loadPlaylistDetails(playlist?.id, null, playlist);

            // start the assignment wizard
            handlePlaylistStepsModal(playlistDispatch, true);
        },
        [playlistState, loadPlaylistDetails, playlistDispatch]
    );

    // handle edting playlist and modal
    const [isEditPlaylistNameModalOpen, setIsEditPlaylistModalOpen] = useState(false);

    function onEditPlaylistName(data) {
        // update the state to have current playlist name
        updatePlaylistName(playlistDispatch, data);
        // close the "Create New Playlist" modal after name has been entered
        setIsEditPlaylistModalOpen(false);
    }

    const handleFinishedCreatingPlaylist = (saveAndQuit) => {
        // save the playlist
        return savePlaylist()
            .then((playlist) => {
                console.log('playlist:', playlist);

                // do not open the assign now dialog during an edit
                if (action === 'edit_videos') {
                    // reset the params
                    resetPlaylist();
                    navigate('/videos');
                } else {
                    // if save and quit is clicked do not open set assign playlist now or later
                    if (saveAndQuit !== true) setAssignPlaylistNowOrLaterModalOpen(true);
                }
                if (parent && action !== 'edit_videos') beginAssignmentWizard(playlist);
                // clear the playlist state
                // resetPlaylist();
            })
            .catch((err) => {
                console.log('Error creating playlist:');
                console.error(err);
                alert('There was an error creating the playlist, please try again or contact technical support');
            });
    };

    return (
        <Container className={`category-list-view-container ${className || ''}`}>
            <nav className='category-list' aria-label='Videos Navigation' tw='hidden xsm:block'>
                <SideNav
                    title={title}
                    sections={sections}
                    teacher={true}
                    categories={categories}
                    playlists={playlists}
                    sortable={categoriesSortable}
                    header={categoriesHeader}
                    index={index}
                    categoryNames={categoryNames}
                    selected={current_category}
                    onSelect={handleSelectCategory}
                />
            </nav>

            <div ref={ref} tw='fixed z-20 left-0 xsm:left[var(--video-side-bar-width)] right-0 w-full xsm:width[calc(100% - var(--video-side-bar-width))] '>
                {isPlaylistDropdownOpen && (
                    <div tw='p-2 xsm:p-0 bg-[#12154C]'>
                        <GradientBorder tw='bg-white p-4' borderWidth='3px'>
                            <div tw='flex flex-wrap xsm:flex-nowrap lg:justify-between items-start font-extrabold text-lg text-DarkLiver'>
                                <div tw='flex flex-wrap items-center -mt-2'>
                                    {playlist.name && (
                                        <h1
                                            tw='bg-InfoGray mb-4 lg:mb-0 mr-4 py-1 px-2 rounded border-LavenderBlue border cursor-pointer'
                                            onClick={() => setIsEditPlaylistModalOpen(true)}
                                        >
                                            {playlist.name}
                                        </h1>
                                    )}
                                    <p tw='mt-2'>
                                        Use <PlaylistIcon tw='inline-block ml-0 mr-0.5' /> to select videos for this playlist. (We recommend 1 to 3 videos.)
                                    </p>
                                </div>
                                <div tw='flex items-center ml-0 xl:ml-4 mt-6 xsm:-mt-1.5 xl:mb-0'>
                                    {action !== 'edit_videos' && (
                                        <Button variant='secondary' tw='mr-2' onClick={() => setQuitPlaylistCreationModalOpen(true)}>
                                            Quit
                                        </Button>
                                    )}
                                    <Button
                                        onClick={() => {
                                            if (parent) {
                                                handleFinishedCreatingPlaylist(true);
                                            } else handleFinishedCreatingPlaylist();
                                        }}
                                        // onClick={handleFinishedCreatingPlaylist}
                                        disabled={playlist.selectedVideos?.length < 1}
                                    >
                                        Done
                                    </Button>
                                </div>
                            </div>
                            <div tw='font-extrabold text-lg flex items-center flex-wrap'>
                                {playlist.selectedVideos.map((video) => {
                                    return (
                                        <div
                                            key={video.videoId}
                                            tw='bg-MuzologyLightBlue py-1.5 px-3 rounded mt-4 mr-4 flex-shrink-0 flex items-center text-white'
                                        >
                                            {video.videoName}
                                            <div tw='cursor-pointer' onClick={() => removeVideoFromPlaylist(playlistDispatch, video)}>
                                                <svg
                                                    tw='ml-4' width={13} height={13} fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'>
                                                    <path
                                                        fillRule='evenodd'
                                                        clipRule='evenodd'
                                                        d='M12.4.422c-.553-.563-1.382-.563-1.936 0l-4.056 4.13L2.35.422C1.798-.14.968-.14.415.422a1.378 1.378 0 000 1.972l4.057 4.13-4.057 4.13a1.378 1.378 0 000 1.97c.276.282.645.376 1.014.376s.738-.094 1.014-.376L6.5 8.494l4.057 4.13c.276.282.645.376 1.014.376s.738-.094 1.014-.376a1.378 1.378 0 000-1.97l-4.057-4.13 4.057-4.13c.369-.564.369-1.408-.184-1.972z'
                                                        fill='#EAEAFB'
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </GradientBorder>
                    </div>
                )}
            </div>
            <EditPlaylistNameModal
                isOpen={isEditPlaylistNameModalOpen}
                // when a user clicks outside of this modal it will fire this off
                setIsOpen={setIsEditPlaylistModalOpen}
                modalChildrenStyle={tw`w-full maxWidth[650px] mx-auto`}
                onEditPlaylistName={onEditPlaylistName}
                playlist={playlist}
            />

            <AssignPlaylistNowOrLaterModal
                playlistDispatch={playlistDispatch}
                playlist={playlist}
                isOpen={isAssignPlaylistNowOrLaterModalOpen}
                setIsOpen={setAssignPlaylistNowOrLaterModalOpen}
                onAssignLater={() => {
                    // reset the playlist state
                    resetPlaylist();

                    // close the dialog
                    setAssignPlaylistNowOrLaterModalOpen(false);
                }}
                onAssignNow={() => {
                    // begin the assignment wizard
                    beginAssignmentWizard(playlist);

                    // reset the playlist state
                    // resetPlaylist();

                    // close the dialog
                    setAssignPlaylistNowOrLaterModalOpen(false);
                }}
            />

            <QuitPlaylistCreationModal
                playlistDispatch={playlistDispatch}
                playlist={playlist}
                isOpen={isQuitPlaylistCreationModalOpen}
                setIsOpen={setQuitPlaylistCreationModalOpen}
                handleFinishedCreatingPlaylist={handleFinishedCreatingPlaylist}
                setAssignPlaylistNowOrLaterModalOpen={setAssignPlaylistNowOrLaterModalOpen}
                parent={parent}
            />

            <div tw='marginTop[var(--playlist-wizard-height)]' className='contents' style={contentsContainerStyle}>
                {content}
            </div>

            {standardsOverlay}
            {progressOverlay}
        </Container>
    );
};

export default CategoryListView;
