import React from 'react';
import { styled } from 'twin.macro';

export const PageStyle = styled.div`
    flex-grow: 1;

    flex-direction: column;

    .section {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &.container {
            //background: #0b9adb;
        }

        > .section_container {
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        justify-content: flex-start;
        align-items: stretch;
    }

    .col {
        display: flex;
        flex-direction: column;

        justify-content: flex-start;
        align-items: stretch;
    }

    .section-container {
        width: 900px;
        max-width: 100%;

        @media only screen and (max-width: 1024px) {
            width: 900px;
        }

        @media only screen and (max-width: 768px) {
            width: 500px;
        }
    }

    &.page-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;

        @media only screen and (min-width: 1300px) {
            width: 1250px;
        }

        @media only screen and (max-width: 1299px) and (min-width: 1200px) {
            width: 1127px;
        }

        @media only screen and (max-width: 1199px) and (min-width: 992px) {
            width: 933px;
        }

        @media only screen and (max-width: 991px) and (min-width: 768px) {
            width: 723px;
        }

        @media only screen and (max-width: 767px) {
            width: calc(100% - 2em);
        }
        @media only screen and (max-width: 414px) {
            width: 100%;
        }
    }
`;

export const Page = ({ children, className, container, style, ...props }) => {
    let className2 = `page ${className || ''}`;
    if (container) className2 += ' page-container';
    return (
        <PageStyle
            className={className2}
            style={style}
            {...props}
        >
            {children}
        </PageStyle>
    );
};

export const Section = (props) => {
    return (
        <div
            className={`section ${props.className || ''}`}
            style={props.style}>
            {props.children}
        </div>
    );
};

// a container is a shorter section centered in the middle of the page
export const Container = (props) => {
    return (
        <div
            className={`section-container ${props.className || ''} `}
            style={props.style}>
            {props.children}
        </div>
    );
};

const PageContainerStyle = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    @media only screen and (min-width: 1300px) {
        width: 1180px;
    }

    @media only screen and (max-width: 1299px) and (min-width: 1200px) {
        width: 1127px;
    }

    @media only screen and (max-width: 1199px) and (min-width: 992px) {
        width: 933px;
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
        width: 723px;
    }

    @media only screen and (max-width: 767px) {
        width: calc(100% - 2em);
    }
    @media only screen and (max-width: 414px) {
        width: 100%;
    }
`;

export const PageContainer = (props) => {
    return (
        <PageContainerStyle
            className={`page-container ${props.className || ''}`}
            style={props.style || null}>
            {props.children}
        </PageContainerStyle>
    );
};

export default Page;
