import React from 'react';
import 'twin.macro';
import { styled } from 'twin.macro';
import RichText from 'components/RichText';

const Choice = ({ choice }) => {
    if (!choice)
        return null;

    let questionNodes = [];
    let text = choice.choice_text;
    if (text) {
        if (choice.content_image) {
            questionNodes.push(
                <img
                    data-choice-image={text}
                    key={choice.id}
                    style={{ maxWidth: '100%', maxHeight: '200px', minHeight: '60px', margin: 'auto' }}
                    src={text}
                    alt='choice'
                />
            );
        } else {
            // check if this is centered, & grab the text from inside the center tag
            questionNodes.push(
                <RichText
                    key={choice.id}
                    // className='quiz-choice-content quiz-choice-text'
                    tw='text-center px-3'
                    text={choice.choice_text}
                />);
        }
    }
    return questionNodes;
};
export default Choice;
