/*
    create <video> sources from a video object
 */
export const createVideoSources = (video) => {
    // console.log('createVideoSources: video = ', video);
    if (!video)
        return [];

    let sources = [];

    if (video.audio) {
        sources.push({
            src: video.audio,
            type: 'audio/wav'
        });
    }

    if (video.url_webm) {
        sources.push({
            src: video.url_webm,
            type: 'video/webm'
        });
    }

    if (video.url_dash) {
        sources.push({
            src: video.url_dash,
            type: 'application/dash+xml'
        });
    }

    if (video.url_hls) {
        sources.push({
            src: video.url_hls,
            type: 'application/vnd.apple.mpegurl'
        });
    }

    if (video.url_mp4) {
        sources.push({
            src: video.url_mp4,
            type: 'video/mp4'
        });
    }
    return sources;
};

