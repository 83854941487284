import React, { useState } from 'react';
import { styled, css } from 'twin.macro';
import PropTypes from 'prop-types';
import { recordImageURL } from 'static/records/FilledRecordsAssets';
import ProgressBar from './ProgressBar';
import { QuizFinishedConditionalMessage } from '../QuizConstants';


const RecordProgressAnimationContainer = styled.div`
    width: 80%;
    margin-right: auto;
    margin-left: auto;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    .progress-bar {
        flex-grow: 1;
        flex-shrink: 1;
        z-index: 10;
    }
`;

const RecordProgress = ({ level, score, className }) => {
    const scoreConditionalText = QuizFinishedConditionalMessage[score];
    const randomNumber = Math.floor(Math.random() * 2);
    let percent = (score || 0) / 100.0;
    let scoreText = `SCORE: ${score}%`;

    return (
        <RecordProgressAnimationContainer className={className}>
            <div tw='font-extrabold text-2xl sm:text-3xl' css={css`text-shadow: 0px 0px 4.649400234222412px #A692D3;`}>{scoreText}</div>
            <ProgressBar percent={percent} level={level} />
            <div tw='text-lg font-normal mt-12 text-center'>{scoreConditionalText[randomNumber]}</div>
        </RecordProgressAnimationContainer>
    );
};

RecordProgress.propTypes = {
    level: PropTypes.number,
    score: PropTypes.number
};

export default RecordProgress;
