import React from 'react';
import tw, { css } from 'twin.macro';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import RecordIndicator from 'components/RecordIndicator';

const StyledButton = styled(Button)`
  ${({ variant }) => variant === 'silver-dtc' && tw`bg-white text-[#1C0057] font-Poppins font-medium px-6 lg:px-10 py-2.5 rounded-lg text-center hover:bg-[linear-gradient(180deg, #ECECEC 0%, #747474 100%)] `}
  ${({ variant }) => variant === 'gold-dtc' && tw`bg-white text-[#1C0057] font-Poppins font-medium  px-6 lg:px-10 py-2.5 rounded-lg hover:bg-[linear-gradient(180deg, #FFF4DF 0%, #E39605 100%)] `}
  ${({ variant }) => variant === 'platinum-dtc' && tw`bg-white text-[#1C0057] font-Poppins font-medium  px-6 lg:px-10 py-2.5 rounded-lg hover:bg-[linear-gradient(180deg, #C4EBF9 0%, #24A6D3 100%)] `}
`;

function DTCCardsDesktop({ surveyAnswer, watchAgain, level, video, takeChallenge }) {
    const buttonLevel = level === 1 ? 'silver-dtc' : level === 2 ? 'gold-dtc' : 'platinum-dtc';
    const buttonCopy = level === 1 ? 'Silver' : level === 2 ? 'Gold' : 'Platinum';
    return (
        <>
            {surveyAnswer ? (
                <>
                    <div tw='absolute top-40 md:top-48 lg:top-60 xl:top-72 left-1/4 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.99)] xl:bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg'>
                        <div
                            tw='cursor-pointer p-2 lg:p-5 bg-contain xl:bg-cover bg-no-repeat h-48 w-72 md:w-80 lg:h-60 lg:w-[380px] xl:w-[432px] flex rounded-lg'
                            css={[
                                css`
                            background-image: linear-gradient(to top, #46217d, rgba(128,0,128,0) 90%), url(${video.url_thumbnail});
                            background-size: 100% 100%;
                            padding-bottom: 40%;
                            /* background-size: cover; */
                            
                        `,
                                tw`hover:[box-shadow:0 0 20px 20px #ffffff22] active:[box-shadow:0 0 20px 20px #ffffff44]`
                            ]}
                            onClick={watchAgain}
                        >

                            <div tw='absolute bottom-2 flex items-end w-full lg:relative'>
                                <div tw='flex items-center gap-2 md:gap-4'>
                                    <div
                                        tw='cursor-pointer min-w-[46px] min-h-[46px] bg-white rounded-full flex items-center justify-center mr-1'
                                        onClick={watchAgain}
                                    >
                                        <div>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'>
                                                <path fill='#7959FA' d='M14.5 7.634a1 1 0 0 1 0 1.732L1.75 16.727a1 1 0 0 1-1.5-.866V1.14a1 1 0 0 1 1.5-.866L14.5 7.634Z' />
                                            </svg>
                                        </div>
                                    </div>
                                    <div tw='text-sm md:text-base lg:text-lg xl:text-xl text-white font-semibold max-w-[437px] ml-0'>Watch again!</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div tw='absolute top-40 md:top-48 lg:top-60 xl:top-72 left-3/4 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg'>
                        <div tw='p-6 flex flex-col justify-center lg:justify-end h-48 w-72 md:w-80 lg:h-60 lg:w-[380px] xl:w-[432px] rounded-lg relative' css={[css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`]}>
                            <div tw='absolute bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[54px] w-[54px] hidden lg:flex items-center justify-center top-[23px] left-[15px] z-20 rounded'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='34' height='40'
                                    fill='none'>
                                    <path fill='#fff' fillRule='evenodd' d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z' clipRule='evenodd' />
                                </svg>
                            </div>
                            <div tw='h-[53px] w-[53px] z-10 absolute top-[23px] left-[45px] hidden lg:block'>
                                <RecordIndicator level={level} dtc={true} />
                            </div>
                            <div tw='text-lg lg:text-xl text-white font-semibold max-w-[437px] mt-0 lg:mt-8'>Continue {buttonCopy} Challenge </div>
                            <div tw='flex justify-center items-center w-full mt-5'>
                                <div>
                                    <StyledButton variant={buttonLevel} onClick={takeChallenge}>
                                        <div tw='text-sm font-medium'>Continue</div>
                                    </StyledButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div tw='absolute top-28 md:top-[30%] xl:top-48 left-1/4 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg'>
                        <div
                            tw='cursor-pointer p-2 lg:p-5 bg-contain xl:bg-cover bg-no-repeat h-48 w-72 md:w-80 lg:h-60 lg:w-[380px] xl:w-[432px] flex rounded-lg'
                            css={[
                                css`
                            background-image: linear-gradient(to top, #46217d, rgba(128,0,128,0) 90%), url(${video.url_thumbnail});
                            background-size: 100% 100%;
                            padding-bottom: 40%;
                            /* background-size: cover; */
                            
                        `,
                                tw`hover:[box-shadow:0 0 20px 20px #ffffff22] active:[box-shadow:0 0 20px 20px #ffffff44]`
                            ]}
                            onClick={watchAgain}
                        >

                            <div tw='absolute bottom-2 flex items-end w-full lg:relative'>
                                <div tw='flex items-center gap-2 md:gap-4'>
                                    <div
                                        tw='cursor-pointer min-w-[46px] min-h-[46px] bg-white rounded-full flex items-center justify-center mr-1'
                                        onClick={watchAgain}
                                    >
                                        <div>
                                            <svg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'>
                                                <path fill='#7959FA' d='M14.5 7.634a1 1 0 0 1 0 1.732L1.75 16.727a1 1 0 0 1-1.5-.866V1.14a1 1 0 0 1 1.5-.866L14.5 7.634Z' />
                                            </svg>
                                        </div>
                                    </div>
                                    <div tw='text-sm md:text-base lg:text-lg xl:text-xl text-white font-semibold max-w-[437px] ml-0'>Watch again!</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div tw='absolute top-28 md:top-[30%] xl:top-48 left-3/4 transform -translate-x-1/2 -translate-y-1/2 bg-[rgba(28, 0, 87, 0.85)] backdrop-blur-3xl rounded-lg'>
                        <div tw='p-6 flex flex-col justify-center lg:justify-end h-48 w-72 md:w-80 lg:h-60 lg:w-[380px] xl:w-[432px] rounded-lg relative' css={[css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`]}>
                            <div tw='absolute bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[54px] w-[54px] hidden lg:flex items-center justify-center top-[23px] left-[15px] z-20 rounded'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='34' height='40'
                                    fill='none'>
                                    <path fill='#fff' fillRule='evenodd' d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z' clipRule='evenodd' />
                                </svg>
                            </div>
                            <div tw='h-[53px] w-[53px] z-10 absolute top-[23px] left-[45px] hidden lg:block'>
                                <RecordIndicator level={level} dtc={true} />
                            </div>
                            <div tw='text-lg lg:text-xl text-white font-semibold max-w-[437px] mt-0 lg:mt-8 text-center'>Continue {buttonCopy} Challenge </div>
                            <div tw='flex justify-center items-center w-full mt-5'>
                                <div>
                                    <StyledButton variant={buttonLevel} onClick={takeChallenge}>
                                        <div tw='text-sm font-medium'>Continue</div>
                                    </StyledButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};


export default DTCCardsDesktop;