import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';


const HorizontalScroll = (props) => {
    return (
        <div tw='w-full overflow-x-scroll sm:overflow-x-hidden background[yellow] sm:background[green]'>
            <div tw='width[1024px] sm:w-full'>
                {props.children}
            </div>
        </div>
    );
};

HorizontalScroll.propTypes = {
    anyProp: PropTypes.any
};

export default HorizontalScroll;

