import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'twin.macro';
import 'twin.macro';
import * as ClassIcons from 'assets/class_icons';
import { compareStrings } from '../../utils';
import ClassIcon from './ClassIcon';

const S = {

    Container: styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        padding: 11px 18px;

        background: linear-gradient(180deg, #1E215F 0%, #070A3B 100%);
        border-radius: 4px;

        width: 594px; // 9 * (40 + 11 + 11) + 18 + 18
    `,

    IconContainer: styled.div`
        width: 40px;
        height: 40px;
        margin: 13px 11px;

        cursor: pointer;

        &:hover {
            svg {
                opacity: 75%;
            }
        }

        &:active {
            svg {
                opacity: 50%;
            }

            :after {
                //z-index: 100;
                border-radius: 100%;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                //background: white;
                content: '';
                border: 2px solid white;
            }
        }

        position: relative;
        z-index: 1;

        //svg {
        //    position: relative;
        //    z-index: 10;            
        //}

        .class-icon {
            position: relative;
            
            &.selected {
                border: 3px solid white;
                border-radius: 100%;
                
                //&:after {
                //    //z-index: 100;
                //    border-radius: 100%;
                //    position: absolute;
                //    left: 0;
                //    top: 0;
                //    bottom: 0;
                //    right: 0;
                //    content: '';
                //    border: 3px solid white;
                //}
            }
        }
    `
};


const ClassIconPalette = (props) => {
    const [selected, setSelected] = useState(props.defaultSelected ?? null);
    const keys = Object.keys(ClassIcons).sort(compareStrings);

    const onClick = (key) => {
        if (props.onClicked)
            props.onClicked(key);
        if (props.setSelected)
            props.setSelected(key);
        setSelected(key);
    };

    const icons = keys.map(key => {
        // const Icon = ClassIcons[key];
        const isSelected = key === (props.selected ?? selected);
        return (
            <S.IconContainer
                key={key}
                className={`class-icon-container ${isSelected ? 'selected' : ''}`}
                selected={isSelected}
                onClick={() => onClick(key)}
            >
                <ClassIcon
                    className={`class-icon ${isSelected ? 'selected' : ''} ${key}`}
                    name={key}
                    selected={isSelected}
                />
            </S.IconContainer>
        );
    });

    return (
        <S.Container>
            {icons}
        </S.Container>
    );
};

ClassIconPalette.propTypes = {
    defaultSelected: PropTypes.string,
    selected: PropTypes.string,
    setSelected: PropTypes.func
};

export default ClassIconPalette;


