import React, { useMemo } from 'react';
import { useCatalogSetLibrary, useCatalogDispatch, useVideos } from '../CatalogState';
import { useAccount } from '../User/UserState';
import AssignmentsState from './AssignmentsState';
import VideoStatsProvider, { useVideoStats } from './VideoStats';

export const ACTION = 'ACTION';

const StoreContext = React.createContext();
const DispatchContext = React.createContext();

const initialState = {
    items: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION:
            return { ...state, items: action.payload };
        default:
            return state;
    }
};

const StudentProvider = (props) => {
    const account = useAccount();
    // const { addLibrary }  = useCatalog();
    const setLibrary = useCatalogSetLibrary();

    React.useEffect(() => {
        console.log('<StudentProvider> set Library', account?.course_library);
        if (account?.course_library) {
            // const { course_library } = account;
            setLibrary(account?.course_library);
            // dispatch({ type: ADD_LIBRARY, payload: course_library });
            console.log('StudentProvider: Installed Course Library:', account?.course_library);
            // addLibrary(account.get('library'));
        }
    }, [account?.course_library, setLibrary]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <AssignmentsState>
            <VideoStatsProvider>
                {props.children}
            </VideoStatsProvider>
        </AssignmentsState>
    );
    // const [store, dispatch] = React.useReducer(reducer, initialState, null);
    //
    // const performAction = (arg) => {
    //     dispatch({ type: ACTION, payload: arg });
    //     // .then(r => {
    //     //     console.log(r);
    //     // });
    // };
    //
    // return (
    //     <DispatchContext.Provider value={dispatch}>
    //         <StoreContext.Provider value={store}>
    //             {props.children}
    //         </StoreContext.Provider>
    //     </DispatchContext.Provider>
    // );
};

// const reduceVideoStats = (videos, stats) => {
//     return videos.reduce((all, video) => {
//         if (!video) return all;
//         const video_id = video.get('id');
//         return all.set(video_id, video.set('stats', stats.get(video_id)));
//     }, Map());
// };
//
// // get all video as a js object with stats attached
// export const getVideosAndStatsSelector = createSelector([getVideos, getAllVideoStats], (videos, stats) => {
//     return reduceVideoStats(videos, stats).toJS();
// });
//


export default StudentProvider;
