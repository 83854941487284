import React from 'react';
import { createUserDetails } from './User';

const Context = React.createContext();

const HeapProvider = (props) => {

    const value = {};

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    );
};

export function heapEvent(name, data) {
    // heap should be loaded by on the page
    if (window.heap) {
        console.log('[Heap Analytics] event', name, data);
        window.heap.track(name, data);
    }
}

export async function heapIdentifyUser(user) {
    // Heap should be loaded by the HTML page header
    const heap = window.heap;
    if (!heap || !user) {
        return false;
    }
    console.info('[Heap Analytics] identify user');
    try {
        heap.identify(user.id);
    } catch (error) {
        console.error('Error identifying user in Heap Analytics', error);
        if (window.Rollbar) {
            window.Rollbar.error('Error identifying user in Heap Analytics', error);
        }
    }

    let details = createUserDetails(user);
    if (window.LogRocket) {
        window.LogRocket.getSessionURL((url) => {
            details = { ...details, logRocketSessionUrl: url };
            heap.addUserProperties(details);
        });
    } else {
        heap.addUserProperties(details);
    }
}


export default HeapProvider;
