import React, { useCallback, useContext } from 'react';
import { apiClient } from '../utils/api-client';
// import { apiClient } from "utils/api-client";

export const EVENT_ROUTE_CHANGED = 'browser:route_changed';
export const EVENT_BROWSER_CLOSED = 'browser:closed';
export const EVENT_BROWSER_REFRESHED = 'browser:refreshed';
export const EVENT_BROWSER_LOADED = 'browser:loaded';
export const EVENT_BROWSER_PAGE_HIDDEN = 'browser:page_hidden';
export const EVENT_BROWSER_PAGE_VISIBLE = 'browser:page_visible';
export const EVENT_BROWSER_FOCUSED = 'browser:focused';

const EventContext = React.createContext();


const initialState = {
    next_event_id: 1,
    events: [],
    is_posting: false,
    posting: []
};

// construct an event with a timestamp
const constructEvent = (name, data = {}) => {
    return { name, data: { ...data, date: Date.now() / 1000.0 } };
};

// the event store reducer
const reducer = (state, action) => {
    // console.log('EVENT reducer', action);

    switch (action.type) {
        case 'POST_EVENT': {
            // post an event to the outgoing buffer
            return {
                ...state,
                events: [...state.events, { ...constructEvent(action.event.name, action.event.data), id: state.next_event_id }],
                next_event_id: state.next_event_id + 1
            };
        }

        case 'FLAG_EVENTS':
            // flag all the events posting
            return { ...state, events: state.events.map(event => event.flag = action.payload) };

        case 'FILTER_EVENTS':
            // filter the events
            return { ...state, events: state.events.filter(action.payload) };

        case 'BEGIN_POSTING':
            return { ...state, is_posting: true, posting: state.events, events: [] };
            // before sending events to the server

        case 'POSTING_COMPLETED':
            // after posting to the server
            return { ...state, is_posting: false, posting: [] };

        case 'POSTING_FAILED':
            // after posting to the server
            return { ...state, is_posting: false, events: [...state.posting, ...state.events], posting: [] };

            // case "POST_EVENTS":
            // 	// post an event to the outgoing buffer
            // 	return { ...state, outgoing: [...state.outgoing, ...action.events] };
            //
            // case "PREPARE_SENDING":
            // 	// post an event to the outgoing buffer
            // 	return { ...state, sending: [...state.sending, ...state.outgoing], outgoing: [] };

        default:
            return state;
    }
};

/*
	The Event Provider provides a context to buffer and send events to the server
 */

const EventProvider = (props) => {
    const [store, dispatch] = React.useReducer(reducer, initialState);

    // send events to the server
    // const postEventsToServer = () => {
    //
    // };

    // console.log("EVENT: provider store: ", store);
    // const [{ loading: isPosting }, postStage] = useAxios({ url: '/api/onboard_stage/', method: 'POST' }, { manual: true });

    // send events to the server
    const postEvents = useCallback((events) => {
        // case 'BEGIN_POSTING':
        // case 'POSTING_COMPLETED':
        // case 'POSTING_FAILED':
        if (store.is_posting) {
            console.log('Event posting, wait');
        }
        dispatch({ action: 'BEGIN_POSTING' });
        apiClient.post('/api/events/', events)
            .then(response => {
                // ok
                dispatch({ action: 'POSTING_COMPLETED' });
            })
            .catch(error => {
                // failed
                console.error(error);
                dispatch({ action: 'POSTING_FAILED' });
            });
        // try {
        //     // console.log('Posting all events', events);
        //     return events;
        // } catch (e) {
        //     console.log('Event post error', e);
        // }
    }, [store.is_posting]);

    // useEffect(() => {
    //     const events = store.events;
    //     postEvents(events).then((events) => {
    //         // console.log("All events posted", events);
    //         // const event_ids = events.map(event => event.id);
    //         // dispatch({ type: "FILTER_EVENTS", payload: (event) => !(event.id in event_ids) });
    //     });
    //
    //     // console.log("EVENT: event store changed", store);
    //     // return () => console.log("EVENT: event store changed done", store);
    // }, [store, dispatch, postEvents]);

    const dispatchEvent = (event) => {
        dispatch({ type: 'POST_EVENT', event });
    };

    // post an event
    // event = { name, data }
    const postEvent = async (event) => {
        // create the event
        // console.log('post event ', event.name, event.data);
        const eventObject = constructEvent(event.name, event.data ?? {});

        // spool events...
        // dispatchEvent(eventObject);

        // post it to the server
        return apiClient.post('/api/events/', [eventObject])
            .then(response => {
                // ok, do nothing...
                // dispatch({ action: 'POSTING_COMPLETED' });
                // console.log('event posted', event.name);
                return response;
            })
            .catch(error => {
                // failed, save the event and try again
                console.log('error posting event', error);
                console.error(error);
                dispatchEvent(eventObject);
                // dispatch({ action: 'POSTING_FAILED', payload: {error} });
            });
    };

    return (
        <EventContext.Provider value={postEvent}>
            {props.children}
        </EventContext.Provider>
    );
};

export const useEvents = (props) => {
    const postEvent = React.useContext(EventContext);
    return React.useCallback((name, data) => {
        // create an even object
        return postEvent({ name, data });
    }, [postEvent]);
};

export default EventProvider;
