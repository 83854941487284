import React, { useEffect, useMemo, useState } from 'react';
import tw, { css } from 'twin.macro';
import { Button, Modal } from 'components';
import { useClassActions } from 'state/Teacher/ClassState';
import { apiClient, handleAPIError } from 'utils/api-client';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from 'lib/components/loading';
import { useAssignmentActions } from '../../state/Student/AssignmentsState';
import { useUserAction } from '../../state/User/UserState';

const BorderGradient = css`
    color: #313149;
    display: inline-block;
    position: relative;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 3px;
        border-radius: 5px;
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
    &:hover {
        background: #e7e7ff;
    }
    &:active {
        background: #c7c7ff;
    }

    .selected {
        background: linear-gradient(93.49deg, #1cc8e1 3.49%, #887be3 100%);
        color: white;
    }
`;

const TextGradient = css`
    background: -webkit-linear-gradient(#e637a8, #e68037);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a5a5a5;
        border-radius: 20px;
        /* max-height: 60px; */
        border-right: 10px white solid;
        background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
        /* margin-top: 30px;
        margin-bottom: 30px; */
    }
`;

function useAssignmentTopics() {
    const [topics, setTopics] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        apiClient
            .get('/api/assignment_topics/')
            .then((response) => {
                const { data } = response;
                setTopics(data);
                setLoading(false);
            })
            .catch((error) => {
                handleAPIError(error);
                setLoading(false);
            });
    }, []);

    return [topics, loading];
}

function TopicListModal(props) {
    const { topics, parentTopicId, onSelectTopic, selected } = props;

    const createSubCards = () => {
        // find all children for this topic
        const children = topics?.filter((t) => t.parent === parentTopicId);
        return children?.map((item) => {
            return (
                <div
                    key={item.name}
                    tw=' py-5 px-3 cursor-pointer'
                    css={[
                        BorderGradient,
                        selected.includes(item.id) &&
                        tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] text-white rounded hover:bg-MediumPurple active:bg-SlateBlue`
                    ]}
                    onClick={() => onSelectTopic(item.id)}
                    data-testid={item.name}
                >
                    <div tw='text-base select-none'> {item.name}</div>
                </div>
            );
        });
    };
    return (
        <Modal isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
            <div tw='bg-white' data-testid='secondary-topics-modal-container'>
                <div tw='px-12 py-5'>
                    <div tw='text-white text-4xl font-extrabold pt-10 text-center' css={[TextGradient]}>
                        More specific topic
                    </div>
                    <div tw=' overflow-y-scroll grid grid-cols-1 gap-4 mt-6 maxHeight[494px]' >
                        {createSubCards()}
                    </div>
                </div>
                <div tw=' height[70px] boxShadow[0px -2px 10px rgba(0, 0, 0, 0.1)]'>
                    <div tw='flex justify-end items-center py-4'>
                        <div tw='flex'>
                            <div tw='ml-1.5 mr-9'>
                                <Button variant='hotPink' onClick={props.onClick} data-testid='secondary-topics-modal-button'>
                                    DONE
                                    {/*{studentIndex === studentsLength ? 'DONE' : 'NEXT CHILD'}*/}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const ChildLearningCategories = ({ onBoarding, handleModal, user }) => {
    const { updateStudent } = useClassActions();
    const { setStudent } = useUserAction();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [topics, loadingTopics] = useAssignmentTopics();
    const { fetchAssignments } = useAssignmentActions();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(user?.student?.assigned_topics ?? []);
    const [finishedStage, setFinished] = useState(false);
    const [secondaryStep, setSecondaryStep] = useState(false);
    const [showChildModal, setShowChildModal] = useState(false);

    // build the topic tree
    const rootTopics = useMemo(() => {
        const root = topics?.filter((t) => t.parent === null) ?? [];
        return root.map((t) => {
            return {
                ...t,
                children: topics?.filter((c) => c.parent === t.id) ?? []
            };
        });
    }, [topics]);

    useEffect(() => {
        apiClient.get('/api/topic_definitions/').then((response) => {
            const { data } = response;
            console.log('🚀 ~ file: ChildLearningCategories.js:112 ~ apiClient.get ~ data:', data);
            setCategories(data);
        });
    }, []);

    const handleAddingCategories = (topicId) => {
        // if this has children, we need to show the secondary step
        const children = topics.filter((c) => c.parent === topicId);
        if (children.length > 0) {
            // show the child popup
            setShowChildModal(topicId);
        } else {
            // toggle the selection state
            setSelected((values) => {
                if (values.includes(topicId)) return values.filter((s) => s !== topicId);
                else return [...values, topicId];
            });
        }
    };

    const handleCategorySave = () => {
        //  handle saving the learning categories for each child
        let data = { topics: selected };
        const payload = { user_id: user?.id, ...data };
        setLoading(true);
        return apiClient
            .post('/api/topics/', payload)
            .then((response) => {
                setLoading(false);
                const { data } = response;
                if (data?.user?.id) {
                    updateStudent(data);
                    setStudent(data);
                }
                fetchAssignments();
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
                handleAPIError(err);
            });
    };

    const createCards = () => {
        return rootTopics?.map((topic, index) => {
            const isSelected = selected?.find((s) => s === topic.id) === topic.id;
            return (
                <div
                    key={index}
                    data-testid={topic.name}
                    tw=' py-5 px-3 cursor-pointer'
                    css={[
                        BorderGradient,
                        isSelected &&
                        tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] text-white rounded hover:bg-MediumPurple active:bg-SlateBlue`
                    ]}
                    onClick={() => handleAddingCategories(topic.id)}
                >
                    <div tw='text-base'> {topic.name}</div>
                </div>
            );
        });
    };
    const createSubCards = () => {
        let items = categories.find((cat) => cat.name === 'Division');
        return items.subItems.map((name) => {
            return (
                <div
                    key={name}
                    tw=' py-5 px-3 cursor-pointer'
                    css={[
                        BorderGradient,
                        selected.find((s) => s === `Division: ${name}`) === `Division: ${name}` &&
                        tw`background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] text-white rounded hover:bg-MediumPurple active:bg-SlateBlue`
                    ]}
                    onClick={() => handleAddingCategories(`Division: ${name}`)}
                >
                    <div tw='text-base'> {name}</div>
                </div>
            );
        });
    };
    return (
        <div tw='relative rounded bg-white'>
            <LoadingOverlay show={loading || loadingTopics} fixed={true} />
            {finishedStage ? (
                <div tw='py-10'>
                    <p tw='pt-8 text-center px-12 text-4xl' css={[TextGradient]}>
                        We Created Playlists Just for You!
                    </p>
                    <div tw='flex justify-center mt-10 pb-16'>
                        <Button variant='hotPink' onClick={() => navigate('/playlists')}>
                            GET STARTED NOW
                        </Button>
                    </div>
                </div>
            ) : (
                <>
                    <div tw='text-white text-4xl font-extrabold pt-10 text-center' css={[TextGradient]}>
                        I Want to Learn About...
                    </div>
                    <p tw='text-base text-DavysGrey pt-2 text-center px-12'>We recommend choosing 1 or 2 topics at a time. </p>
                    <p tw='text-base text-DavysGrey pt-1 text-center px-12'>You can pick additional topics whenever you want! </p>
                    <p tw='text-base text-DavysGrey pt-1 text-center px-12'>We’ll make special math music video playlists, just for you.</p>
                    <div tw=' overflow-y-scroll grid grid-cols-2 gap-4 mt-6 maxHeight[494px]' css={[!onBoarding && tw`px-20`, ScrollBarStyle]}>
                        {createCards()}
                    </div>

                    <div tw=' height[110px] boxShadow[0px -2px 10px rgba(0, 0, 0, 0.1)]'>
                        {/* <p tw='text-base text-DavysGrey pt-4 text-center px-12 underline'>
                                    I’m not sure what topic to pick. Show me videos by grade level.
                                </p> */}
                        <div tw='flex justify-end items-center py-4'>
                            <div tw='flex'>
                                <div tw='mr-1.5'>
                                    <Button
                                        variant='RosterTable'
                                        onClick={() => {
                                            handleCategorySave();
                                            handleModal(false);
                                        }}
                                    >
                                        DO LATER
                                    </Button>
                                </div>

                                <div tw='ml-1.5 mr-9'>
                                    <Button variant='hotPink' onClick={handleCategorySave}>
                                        NEXT
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* Child topics modal dialog */}
            <TopicListModal
                isOpen={showChildModal !== false}
                setIsOpen={setShowChildModal}
                parentTopicId={showChildModal}
                topics={topics}
                selected={selected}
                onSelectTopic={handleAddingCategories}
                onClick={() => setShowChildModal(false)}
            />
        </div>
    );
};

export default ChildLearningCategories;
