const RelatedVideosConfig = {

    // Three Tricky Times Tables 	*
    '0372ebe7-ad45-48bc-b0ed-0ccf984014e1': ['fe60ba30-fc78-4292-a667-08a962109112', '9bbae7b0-238b-4520-be09-b5443ed24b96', '5775b3f2-72dc-42a3-b09e-d4f0d9fde7ae'],

    // Multiplying by Five through Nine	*
    'fe60ba30-fc78-4292-a667-08a962109112': ['0372ebe7-ad45-48bc-b0ed-0ccf984014e1', 'd347e45e-c7df-409a-b2e7-430477a04090', 'b56ac533-a9cf-41d6-a96a-0540323b0cba'],

    // Double up with Two (2 times tables)	*
    'd49b3f7b-a34d-4dc6-a36e-7c845b36cc6a': ['5d88d7ef-e61a-49a9-b2d7-be98aedbdbba', 'dc5cc441-9f32-4f0f-827a-4a4b8ca0bd7c', 'eb014af7-2c3f-4145-bd94-ab4d94748aee'],

    // Three Cheers for Three (3 times tables)*
    '5d88d7ef-e61a-49a9-b2d7-be98aedbdbba': ['d49b3f7b-a34d-4dc6-a36e-7c845b36cc6a', 'a33f0302-fab0-441d-be7b-5a13928c9648', 'dc5cc441-9f32-4f0f-827a-4a4b8ca0bd7c'],

    // One Two Three Four (4 times tables)	*
    'a33f0302-fab0-441d-be7b-5a13928c9648': ['dc5cc441-9f32-4f0f-827a-4a4b8ca0bd7c', 'd347e45e-c7df-409a-b2e7-430477a04090', 'b56ac533-a9cf-41d6-a96a-0540323b0cba'],

    // High Five For Five (5 times tables)*
    'dc5cc441-9f32-4f0f-827a-4a4b8ca0bd7c': ['d49b3f7b-a34d-4dc6-a36e-7c845b36cc6a', 'd347e45e-c7df-409a-b2e7-430477a04090', 'eb014af7-2c3f-4145-bd94-ab4d94748aee'],

    // The Sixth Sense (6 times tables)*
    'd347e45e-c7df-409a-b2e7-430477a04090': ['9bbae7b0-238b-4520-be09-b5443ed24b96', '5775b3f2-72dc-42a3-b09e-d4f0d9fde7ae', 'b56ac533-a9cf-41d6-a96a-0540323b0cba'],

    // Lucky Number Seven (7 times tables)*
    '9bbae7b0-238b-4520-be09-b5443ed24b96': ['d347e45e-c7df-409a-b2e7-430477a04090', '5775b3f2-72dc-42a3-b09e-d4f0d9fde7ae', 'b56ac533-a9cf-41d6-a96a-0540323b0cba'],

    // The Eight Wonders of Eight (8 times tables)*	
    '5775b3f2-72dc-42a3-b09e-d4f0d9fde7ae': ['9bbae7b0-238b-4520-be09-b5443ed24b96', 'b56ac533-a9cf-41d6-a96a-0540323b0cba', 'fe60ba30-fc78-4292-a667-08a962109112'],

    // One Cloud Nine (9 times tables)
    'b56ac533-a9cf-41d6-a96a-0540323b0cba': ['eb014af7-2c3f-4145-bd94-ab4d94748aee', '0372ebe7-ad45-48bc-b0ed-0ccf984014e1', 'fe60ba30-fc78-4292-a667-08a962109112'],

    // Ten’s a Perfect Ten (10 times tables)	
    'eb014af7-2c3f-4145-bd94-ab4d94748aee': ['dc5cc441-9f32-4f0f-827a-4a4b8ca0bd7c', 'fe60ba30-fc78-4292-a667-08a962109112', '0372ebe7-ad45-48bc-b0ed-0ccf984014e1'],

}

export default RelatedVideosConfig  