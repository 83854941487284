import React from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import VideoCard from 'components/VideoCard/VideoCard';

const MobileVideoCardRow = ({ videos, column }) => {
    if (!videos) return null;
    return videos?.map((video, index) => {
        let stats = video?.stats || {};
        return (
            <div key={video?.id ?? index} tw='min-w-[300px] max-w-[300px] mx-3' css={[column && tw`my-3` ]}>
                <VideoCard
                    routeToVideoSession
                    className='video-item-card'
                    index={index}
                    video={video}
                    stats={stats}
                    showTitle={true}
                    showViewCount={true}
                    showRecords={true}
                    disableHover={true}
                />
            </div>
        );
    });
};

MobileVideoCardRow.propTypes = {
    anyProp: PropTypes.any,
}

export default MobileVideoCardRow;

