import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import EmailValidator from 'email-validator';
import { LoginButton } from 'components';
import { apiLoginClient } from '../../../../context/Auth/AuthProvider';

const PasswordEmailSuccessScreen = ({ onClose }) => {
    return (
        <div tw='bg-white font-Inter rounded-tl-[20px] sm:rounded-tl-[0px] rounded-bl-[20px] sm:rounded-bl-[0px] rounded-tr-[20px] rounded-br-[20px]  shadow-2xl py-5 px-5 sm:px-10  w-full sm:w-[500px] md:w-[600px] lg:w-[750px] h-full lg:h-[520px] '>
            <div tw='bg-[#FBFAFA] relative h-full w-full rounded-[20px] flex flex-col justify-around'>
                <div tw='text-center text-[#494949] pt-10'>
                    <div tw='text-2xl font-bold'>Password Reset</div>
                    <div tw='text-base pt-5'>Your email is on its way.</div>
                </div>
                <div tw='text-base pt-3 sm:pt-10 mx-3 sm:mx-8 text-center'>If you don’t receive an email, please make sure you’ve entered the correct email address and check your spam folder.</div>
                <div tw='text-base font-bold pt-10 text-center pb-3 sm:pb-0'>
                    Need Help? <span tw='font-normal'></span>Contact us at -{' '}
                    <span tw='text-[#6C61DE] cursor-pointer'>
                        <a href='mailto:support@muzology.com' target='_blank' rel='noreferrer'>
                            “support@muzology.com”
                        </a>
                    </span>
                </div>
                <div tw='absolute -top-4 sm:top-0 right-4 cursor-pointer pt-5' onClick={() => onClose(false)}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'>
                        <path
                            fill='#131313'
                            fillRule='evenodd'
                            d='M27.566 17.52a1 1 0 0 0-1.475-1.346l-4.296 4.189-4.188-4.297-.087-.078a1 1 0 0 0-1.345 1.475l4.188 4.296-4.296 4.188-.08.086a1 1 0 0 0 1.476 1.346l4.296-4.188 4.188 4.296.087.079a1 1 0 0 0 1.346-1.475l-4.189-4.296 4.296-4.189.08-.086Z'
                            clipRule='evenodd'
                        />
                        <path
                            stroke='#000'
                            strokeWidth='1.5'
                            d='M37.25 22c0 8.422-6.828 15.25-15.25 15.25S6.75 30.422 6.75 22 13.578 6.75 22 6.75 37.25 13.578 37.25 22Z'
                        />
                    </svg>
                </div>
            </div>

        </div>
    );
};

const PasswordReset = ({ onClose, setIsLoading }) => {
    const [PasswordEmailSuccess, setPasswordEmailSuccess] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const [{ loading: isPosting }, postPasswordReset] = useAxios(
        {
            url: 'api/password_reset/',
            method: 'POST',
            options: { axios: apiLoginClient }
        },
        { manual: true }
    );

    const onSubmit = (data) => {
        setIsLoading(true);
        postPasswordReset({ data: data })
            .then((response) => {
                setIsLoading(false);
                console.log(response);
                if (response.status === 200) setPasswordEmailSuccess(true);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log('Password Reset error:', error);
                console.error(error);
            });
    };

    // once finished completing the first step in the password reset show this success screen telling them to check email
    if (PasswordEmailSuccess) {
        return <PasswordEmailSuccessScreen onClose={onClose} />;
    }


    return (
        <div
            tw='bg-white font-Inter rounded-tl-[20px] sm:rounded-tl-[0px] rounded-bl-[20px] sm:rounded-bl-[0px] rounded-tr-[20px] rounded-br-[20px]  shadow-2xl py-5 px-5 sm:px-10  w-full sm:w-[500px] md:w-[600px] lg:w-[750px] lg:h-[520px] '

        >
            <div tw='bg-[#FBFAFA] relative h-full w-full rounded-[20px]'>
                <div tw='text-center text-[#494949] pt-10' css={[errors.email && tw`pt-4`]}>
                    <div tw='text-2xl font-bold'>Parents / Educators </div>
                    <div tw='text-base pt-5'>Enter your email address to reset your password. </div>
                </div>
                <div tw='max-w-[220px] w-full mx-auto mt-8'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input
                            name='email'
                            ref={register({
                                required: true,
                                validate: (value) => EmailValidator.validate(value) || 'Enter a valid email'
                            })}
                            placeholder='Email'
                            tw='block w-full pl-2 pr-4 py-2.5 mt-2 font-normal text-xs text-[#6B7280] bg-white border-black border-[1.5px] border-solid rounded-[3.3px] focus:border-[#5EC5DF] focus:ring-[#5EC5DF] focus:outline-none focus:ring focus:ring-opacity-40'
                        />
                        {errors.email && <div tw='pt-2 text-red-500'>* This field is required.</div>}
                        <div tw='flex flex-col items-center justify-center mt-4'>
                            <LoginButton>Reset Password</LoginButton>
                        </div>
                    </form>
                </div>
                <div tw='text-center text-[#494949] max-w-[400px] pt-10 mx-auto'>
                    <div tw='text-2xl font-bold '>Students</div>
                    <div tw='text-base pt-5'>Ask your <strong tw='font-bold'>teacher</strong> or <strong tw='font-bold'>parent/guardian </strong> to reset your
                        <br tw='hidden sm:block'/>
                        password or retrieve your username.</div>
                </div>
                <div tw='text-base font-normal pt-10 text-center pb-3 sm:pb-0'>
                    Need Help? <span tw='font-normal'></span>Contact us at -{' '}
                    <span tw='text-[#6C61DE] cursor-pointer'>
                        <a href='mailto:support@muzology.com' target='_blank' rel='noreferrer'>
                            “support@muzology.com”
                        </a>
                    </span>
                </div>
                <div tw='absolute -top-4 sm:top-0 right-2 cursor-pointer pt-5' onClick={() => onClose(false)}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' fill='none'>
                        <path
                            fill='#131313'
                            fillRule='evenodd'
                            d='M27.566 17.52a1 1 0 0 0-1.475-1.346l-4.296 4.189-4.188-4.297-.087-.078a1 1 0 0 0-1.345 1.475l4.188 4.296-4.296 4.188-.08.086a1 1 0 0 0 1.476 1.346l4.296-4.188 4.188 4.296.087.079a1 1 0 0 0 1.346-1.475l-4.189-4.296 4.296-4.189.08-.086Z'
                            clipRule='evenodd'
                        />
                        <path
                            stroke='#000'
                            strokeWidth='1.5'
                            d='M37.25 22c0 8.422-6.828 15.25-15.25 15.25S6.75 30.422 6.75 22 13.578 6.75 22 6.75 37.25 13.578 37.25 22Z'
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
