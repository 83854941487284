import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import { useClassStudents } from 'state/Teacher/ClassState';
import { Button, Modal } from 'components';
import { useAccount } from 'state/User/UserState.js';
import CreatChildAccount from 'components/OnBoarding/Stages/CreatChildAccount';
import { CreateChildProvider } from '../../../../../components/OnBoarding/Stages/CreateChildProvider';
import ResetStudentPassword from './Modal/ResetStudentPassword';
import ChildSettingInfo, { studentGradeToString, valueToStudentGrade } from './ChildSettingInfo';

const ChildSettings = () => {
    const { students } = useClassStudents(null);
    const [selectedChild, setSelectedChild] = useState(null);
    const [isOpenAddChild, setIsOpenAddChild] = useState(false);
    const account = useAccount();

    const allowNewStudents = account.student_limit === 0 || account.student_limit > students.length;

    useEffect(() => {
        if (students && students.length > 0) {
            setSelectedChild(students[0]); // Select the first student by default
        }
    }, [students]);

    return (
        <div>
            {allowNewStudents && (
                <div tw='sm:ml-auto w-max mt-4 sm:marginTop[-2.25rem] mr-auto sm:mr-0'>
                    <Button variant='hotPink' onClick={() => setIsOpenAddChild(true)} id='add-child'>
                        Add Child
                    </Button>
                </div>
            )}
            <div tw='flex mt-5 mx-0 px-1 sm:px-4 pt-6'>
                {students.map((student) => (
                    <div
                        css={[
                            tw`w-2/5 sm:w-1/5 select-text text-[#B5AAD0] text-center mb-4 rounded-md py-2 hover:cursor-pointer`,
                            selectedChild === student ? tw`bg-[#6441B9] text-white` : null
                        ]}
                        onClick={() => setSelectedChild(student)}
                    >
                        {student.user.first_name}
                    </div>
                ))}
            </div>

            {selectedChild && (
                <div>
                    <ChildSettingInfo selectedChild={selectedChild} />
                </div>
            )}

        </div>
    );
};

export default ChildSettings;
