import React from 'react';
import TimesTableMathStarImg from 'static/images/guidance/times-table-math-star.png';
import tw, { styled, css } from 'twin.macro';

const ClipStyle = css`
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(4px 2px #B031FA);

`;

const TimesTableMathStar = ({ image }) => {
    return (
        <>
            <div tw='absolute text-white text-4xl sm:text-6xl lg:text-5xl xl:text-6xl bottom-[74px] sm:bottom-[105px] lg:bottom-[100px] xl:bottom-28 font-bold left-3 sm:left-5' css={[ClipStyle]}>YOU'RE A </div>
            <div tw='absolute text-white text-4xl sm:text-6xl lg:text-5xl xl:text-6xl bottom-10 sm:bottom-14 font-bold left-3 sm:left-5'css={[ClipStyle]}>MATH STAR! </div>
            <div tw='absolute text-white text-base tiny:text-lg sm:text-xl xl:text-2xl bottom-2 sm:bottom-4 font-medium left-3 sm:left-5' >Retake ALL the Platinum Challenges.</div>
            <img src={TimesTableMathStarImg} alt={image} tw='h-full w-full object-cover' />

        </>
    );
};

export default TimesTableMathStar;