import React from 'react';
import PropTypes from 'prop-types';
import VideoThumbnail from '../VideoThumbnail';
import 'twin.macro';

const PlaylistVideos = ({ videos }) => {
    // create the video card elements
    const makeVideosCards = () => {
        return videos.map((video, index) => {
            return (
                <div
                    key={video.id}
                    tw='max-h-56 relative'
                    style={{ maxWidth: '200px' }}>
                    <VideoThumbnail video={video} />
                    <div
                        tw='py-2 text-sm text-white font-medium font-sans pl-2'
                        style={{ backgroundImage: 'linear-gradient(96.81deg, #1cc8e1 0.89%, #d022ed 98.12%)' }}
                    >
                        <div tw='truncate w-full'>{video.title}</div>
                    </div>
                    <div tw='text-white text-base rounded-br-lg bg-PinkCrayola px-2 absolute top-0 left-0'>{index + 1}</div>
                </div>
            );
        });
    };
    return (
        <div tw='bg-white rounded-lg font-sans'>
            <div tw='text-base font-medium text-EarieBlack pl-4 pt-4'>Videos in this playlist</div>
            <div tw='grid grid-cols-2 sm:grid-cols-3 gap-4 px-6 pt-4 pb-6'>{makeVideosCards()}</div>
        </div>
    );
};

PlaylistVideos.propTypes = {
    videos: PropTypes.array
};

export default PlaylistVideos;
