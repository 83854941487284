import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw, { css } from 'twin.macro';
import moment from 'moment';
import { useMedia } from 'react-use';
import * as PropTypes from 'prop-types';
import { useUser } from 'state/User/UserState.js';

import {
    Button,
    ListBox,
    NullContainer,
    PlaylistAssignmentCard,
    PlaylistAssignWizard,
    PlaylistPageActionsPopup,
    PlaylistSelectionCard,
    VideoSlider,
    ParentPlaylistAssignmentCard,
    ParentLearningCategories,
    Modal
} from 'components';
import { SortArrowDown, SortArrows, SortArrowUp } from 'images/svg';
import { getClassesWithStudentsAndStateListSelector } from 'modules/selectors';
import { usePlaylistAssignWizardState } from 'state/PlaylistAssignWizardState';
import { usePlaylistState } from 'state/PlaylistState';
import { selectVideosWithIDs } from 'app/model/selectors';
import { usePlaylistActions, usePlaylists } from 'state/Teacher/TeacherProvider';
import { Helmet } from 'react-helmet';
import { useWebTourState } from 'state/WebTourState';
import { useVideoMap, useVideos } from '../../../../state/CatalogState';
import { useClasses } from '../../../../state/Teacher/ClassState';
import ParentTopicsModal from './ParentTopicsModal';

// sort types
const SORT_TYPE_ALPHA_ASC = 'alphabetical ascending';
const SORT_TYPE_ALPHA_DESC = 'alphabetical descending';
const SORT_TYPE_DATE_ASC = 'date ascending';
const SORT_TYPE_DATE_DESC = 'date descending';
const SORT_TYPE_ASSIGNED = 'assigned';
const SORT_TYPE_UNASSIGNED = 'unassigned';

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        display: none;
    }
`;

// Helper Function to reduce playlist to most recent date of assignment
let sortByDueDate = (playlists, asc, desc, unassigned) => {
    let onlyAssignedPLayLists = playlists.filter((playlist) => playlist.assignments.length > 0);
    let unAssignedPlayList = playlists.filter((assign) => assign.assignments.length === 0);

    let reducedList = onlyAssignedPLayLists.reduce((accumulator, playlist) => {
        let playlistSort = playlist.assignments.sort((a, b) => moment(a.due).diff(moment(b.due)));
        accumulator.push({
            date: playlistSort[0].due,
            playlist: playlist
        });
        return accumulator;
    }, []);

    // check if the list should be ascending or descending
    if (asc) {
        reducedList = reducedList.sort((a, b) => moment(a.date).diff(moment(b.date)));
    }

    if (desc) {
        reducedList = reducedList.sort((a, b) => moment(b.date).diff(moment(a.date)));
    }

    // if the assignment is unassigned re order the reduce list after the unassigned playlist
    if (unassigned) {
        let finalList = [];
        unAssignedPlayList.forEach((item) => {
            finalList.push(item);
        });
        reducedList.forEach((item) => {
            finalList.push(item.playlist);
        });
        return finalList;
    }

    let finalList = [];
    reducedList.forEach((item) => {
        finalList.push(item.playlist);
    });

    unAssignedPlayList.forEach((item) => {
        finalList.push(item);
    });

    return finalList;
};

const PlaylistSorting = ({ name, maxWidth, asc, desc, sortOrder, setSortOrder, style, parent }) => {
    const selected = sortOrder === asc || sortOrder === desc;

    const onClick = () => {
        if (selected) {
            setSortOrder(sortOrder === asc ? desc : asc);
        } else {
            setSortOrder(asc);
        }
    };
    return (
        <button
            tabIndex={0}
            tw='text-white text-sm font-black p-1.5 flex items-center rounded-md'
            style={{ maxWidth: maxWidth, ...style }}
            onClick={!parent && onClick}
            css={!parent && tw`cursor-pointer`}
        >
            <div tw='mr-1.5'>{name}</div>
            {!parent && <>{sortOrder === desc ? <SortArrowUp /> : sortOrder === asc ? <SortArrowDown /> : <SortArrows />}</>}
        </button>
    );
};

const SelectedPlaylist = ({
    activeId,
    activePlaylist,
    assignmentCards,
    onAssignPlaylist,
    onEditPlaylist,
    videos,
    onClickVideo,
    isParent,
    isPhone,
    isTablet,
    isSmallTablet
}) => {



    return (
        <div
            tw='flex-col w-full'
            css={[
                ScrollBarStyle,
                !activeId && tw`hidden`,
                css`
                    flex-grow: 1;
                `
            ]}
            id='playlist-info'
        // style={{ maxWidth: '780px', width: '100%', marginBottom: '50px' }}
        >
            <div tw='flex justify-between items-center bg-gradient-to-r to-PholxPink from-SkyBlueCrayola rounded-t-lg py-3 pl-3 pr-7 mt-4'>
                <div tw='text-lg sm:text-2xl text-white font-medium max-w-[200px] sm:max-w-[100%]'>{activePlaylist?.name}</div>
                {isParent ? (
                    // id check for created by muzology
                    <div tw='text-white text-lg'>Created by You</div>
                ) : (
                    <button tabIndex={0} tw='underline text-white text-lg cursor-pointer' onClick={onAssignPlaylist}>
                        {activePlaylist?.assignments?.length > 0 ? 'Assign Again' : 'Assign'}
                    </button>
                )}
            </div>
            <div tw='flex flex-col bg-white px-5 ' css={[!assignmentCards && tw`rounded-b-lg`]}>
                <div tw='flex mt-7'>
                    <div tw='font-bold text-lg text-SlateBlue'>Videos in this Playlist</div>
                    <button tabIndex={0} tw='text-lg text-PinkCrayola underline ml-3 cursor-pointer' onClick={onEditPlaylist}>
                        Edit
                    </button>
                </div>
                <div tw='pt-4 pb-10 mx-2 sm:mx-8'>
                    {activePlaylist?.videos && (
                        <VideoSlider
                            cols={isPhone ? 1 : isTablet ? 2 : 3}
                            videos={videos}
                            arrows={true}
                            leftAlignment={true}
                            teacher={true}
                            hideDuration={true}
                            onClickVideo={onClickVideo}
                            maxWidth={isPhone ? '300px' : isSmallTablet ? '450px' : isTablet ? '600px' : null}
                        />
                    )}
                </div>
                {assignmentCards && <div tw='h-0.5 bg-GainsboroGrey w-full' />}
            </div>
            {assignmentCards && <div tw='bg-white pt-5 pb-5 rounded-b-lg'>{assignmentCards}</div>}
        </div>
    );
};

SelectedPlaylist.propTypes = {
    activeId: PropTypes.any,
    activePlaylist: PropTypes.object,
    onEditPlaylist: PropTypes.func,
    onAssignPlaylist: PropTypes.func,
    videos: PropTypes.arrayOf(PropTypes.any),
    assignmentCards: PropTypes.any
};
const PlaylistsContainer = (props) => {
    const isTablet = useMedia('(max-width: 1023px)');
    const isSmallTablet = useMedia('(max-width: 780px)');
    const isPhone = useMedia('(max-width: 500px)');
    const navigate = useNavigate();
    const user = useUser();
    const isParent = user.is_parent;

    const videoMap = useVideoMap();
    const videos = useVideos();
    const classes = useClasses();

    // teacher state
    const assignedPlaylists = usePlaylists();
    const { addAssignment, deleteAssignment: deleteAssignmentAction, deletePlaylist: deletePlaylistAction } = usePlaylistActions();

    const [activeId, setActiveId] = useState(null);
    const [activePlaylist, setActivePlaylist] = useState({});
    const [sortedPlaylist, setPlaylist] = useState(null);

    const [sortOrder, setSortOrder] = useState(SORT_TYPE_DATE_DESC);

    const [selectedClass, setSelectedClass] = useState({ title: 'All Classes', id: 'all_classes' });
    const [playlistDetailsModal, setPlaylistDetailsModal] = useState(false);
    const [editPlaylistVideosModal, setEditPlaylistVideosModal] = useState(false);

    const [editAssignmentModal, setEditAssignmentModal] = useState(false);
    const [editDraftModal, setEditDraftModal] = useState(false);

    const [parentLearningModal, setParentLearningModal] = useState(false);

    const [assignmentId, setAssignmentId] = useState('');

    const [deletePlaylistModal, setDeletePlaylistModal] = useState(false);
    const [deletePlaylistId, setDeletePlaylistId] = useState('');
    const [deleteAssignmentModal, setDeleteAssignmentModal] = useState(false);
    const [deleteAssignmentId, setDeleteAssignmentId] = useState('');

    // for creating/editing playlists & assignments
    const [, , , editPlaylist] = usePlaylistState();
    const [, , loadAssignmentDetails, saveAssignment, beginAssignment] = usePlaylistAssignWizardState();
    const { tourSteps, stepIndex, setIndex } = useWebTourState();

    // handle state for the different modal actions
    // Editing Playlists Videos
    // handle editing videos

    // send them to the videos page with assignment parm
    //  index against and pre load the videos
    const handleEditVideos = useCallback(() => {
        navigate(`/videos/edit_videos/${activePlaylist.id}`, { action: 'edit playlist', playlist: activePlaylist });
    }, [activePlaylist, navigate]);

    // close the edit playlist warning modal
    const handleEditPlaylistModalConfirmed = () => {
        // close the modal
        setEditPlaylistVideosModal(false);

        // do whatever action after the have confirmed they want to edit playlist videos
        handleEditVideos();
    };

    // const [MessageDialog, showMessageDialog] = useMessageDialog();

    // edit the selected playlist
    const onEditPlaylist = useCallback(  () => {
        if (activePlaylist) {
            console.log('edit playlist', activePlaylist);
            if (activePlaylist.assignments?.length > 0 && !isParent) {
                // show the confirmation
                setEditPlaylistVideosModal(true);
                // showMessageDialog('Message')
                //     .addButton('OK', () => {
                //
                //     })
                //     .addButton('OK', () => {
                //
                //     });
            } else {
                // go to the editor
                handleEditVideos();
            }
        }
    }, [activePlaylist, handleEditVideos, isParent] );

    // Assign Again
    const HandleAssignAgain = useCallback(() => {
        if (activePlaylist) {
            editPlaylist(activePlaylist.id);
            setPlaylistDetailsModal(true);
        }
    }, [activePlaylist, editPlaylist]);

    // Editing Assignments - show a warning message
    const editAssignment = (assignmentId) => {
        // show the assignment edit warning message
        setAssignmentId(assignmentId);
        setEditAssignmentModal(true);
    };

    const editDraft = (assignmentId) => {
        // show the assignment edit warning message
        setAssignmentId(assignmentId);
        setEditDraftModal(true);
    };

    // edit the assignment
    const handleEditAssignmentModal = () => {
        // close the warning message
        setEditAssignmentModal(false);

        // load the playlist
        editPlaylist(activePlaylist.id);

        // load the assignment
        loadAssignmentDetails(activePlaylist.id, assignmentId);

        // show the assignment editor
        setPlaylistDetailsModal(true);
    };

    // Delete Playlist
    const handleDeletePlaylistModal = () => {
        // do whatever action after the have confirmed they want to delete playlist

        // find the index of the next playlist
        const index = assignedPlaylists.map((p) => p.id).indexOf(deletePlaylistId);
        console.log('Deleting playlist #', index, deletePlaylistId);

        // delete the playlist
        deletePlaylistAction(deletePlaylistId);

        // remove the playlist from redux
        // dispatch({ type: DELETE_PLAYLIST_SUCCESS, payload: deletePlaylistId });

        // close the modal
        setDeletePlaylistModal(false);

        // select a new playlist
        if (assignedPlaylists.length > 1) {
            let next;
            if (index === assignedPlaylists.length - 1) {
                // removing the last item - select the previous item
                next = Math.max(index - 1, 0);
            } else {
                next = Math.min(index + 1, assignedPlaylists.length - 1);
            }
            // if (index >= assignedPlaylists.length) {
            //     index = assignedPlaylists.length - 1;
            // }
            console.log('Selecting playlist #', next, assignedPlaylists[next].id);
            setActiveId(assignedPlaylists[next].id);
        }
    };

    // Delete Assignment
    const handleDeleteAssignmentModal = () => {
        // do whatever action after the have confirmed they want to delete assignment
        // dispatch(deleteAssignment(deleteAssignmentId));

        // update the teacher state
        deleteAssignmentAction(deleteAssignmentId);

        // close the modal
        setDeleteAssignmentModal(false);
    };

    // handle create new playlist
    // send them to the videos page with param
    // start creating playlist
    const handleCreateNewPlaylist = () => {
        navigate('/videos/create_playlist');
    };

    // if parent set no sort order
    useEffect(() => {
        if (isParent) {
            setSortOrder(null);
        }
    }, [isParent]);

    // when the playlist load and no playlist has been selected, select the first playlist
    useEffect(() => {
        if (activeId === null && assignedPlaylists?.length > 0) {
            setActiveId(assignedPlaylists[0].id);
        }
    }, [activeId, setActiveId, assignedPlaylists]);

    // any time a playlist has been selected - set the active playlist -
    useEffect(() => {
        if (activeId !== null) {
            setActivePlaylist(assignedPlaylists.find((playlist) => playlist.id === activeId));
        } else {
        }
    }, [activeId, assignedPlaylists]);

    // any time the selectClass changes run a reduce on playlists
    useEffect(() => {
        // default onload is all classes
        if (selectedClass.id === 'all_classes') {
            setPlaylist(assignedPlaylists);
        } else {
            if (selectedClass) {
                // reduce playlist to find if a specific class has been assigned a playlist
                // create new list of playlists
                let reducedPlaylists = assignedPlaylists.reduce(function (result, playlist) {
                    // check if it has been assigned
                    if (playlist.assignments.length > 0) {
                        // loop over assignments
                        playlist.assignments.forEach((assignments) => {
                            // check if it has been assigned to any classes
                            if (assignments.assigned_classes.length > 0) {
                                // loop over assigned classes
                                assignments.assigned_classes.forEach((assignedClass) => {
                                    if (assignedClass === selectedClass.id) {
                                        return result.push(playlist);
                                    }
                                });
                            }
                        });
                    }
                    return result;
                }, []);
                setPlaylist(reducedPlaylists);
            }
        }
    }, [selectedClass, assignedPlaylists]);

    // add a default all classes to classes list for sorting purposes
    const insert = (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
    ];

    const classesWithDefault = insert(classes, 0, { title: 'All Classes', id: 'all_classes' });

    // get the selected videos from the playlist
    const selectedVideosList = useMemo(() => {
        if (activePlaylist && activePlaylist.videos) {
            return selectVideosWithIDs(videos, activePlaylist.videos);
        } else {
            return [];
        }
    }, [activePlaylist, videos]);

    const selectedPlaylist = useMemo(() => {
        const createAssignmentCards = () => {
            if (isParent && activePlaylist?.assignments?.length > 0) return <ParentPlaylistAssignmentCard assignment={activePlaylist?.assignments[0]} />;
            if (activePlaylist?.assignments?.length > 0)
                return activePlaylist.assignments.map((assignment, index) => {
                    return (
                        <PlaylistAssignmentCard
                            key={assignment.id}
                            assignment={assignment}
                            index={index}
                            editAssignment={editAssignment}
                            editDraft={editDraft}
                            defaultOpen={index === activePlaylist.assignments.length - 1}
                            isParent={isParent}
                            onDeleteAssignment={() => {
                                setDeleteAssignmentId(assignment.id);
                                setDeleteAssignmentModal(true);
                            }}
                        />
                    );
                });
        };

        // begin a new assignment wizard
        const assignPlaylist = (playlistId) => {
            console.log('ASSIGN PLAYLIST');
            // reset the state & save the playlist id
            beginAssignment(playlistId);

            // open the wizard
            setPlaylistDetailsModal(true);
        };

        const onClickVideo = (video) => {
            // open the video
            navigate(`/video/${video.id}`);
        };

        return (
            <SelectedPlaylist
                activeId={activeId}
                activePlaylist={activePlaylist}
                onAssignPlaylist={() => assignPlaylist(activeId)}
                onEditPlaylist={onEditPlaylist}
                videos={selectedVideosList}
                assignmentCards={createAssignmentCards()}
                onClickVideo={onClickVideo}
                isParent={isParent}
                isPhone={isPhone}
                isTablet={isTablet}
                isSmallTablet={isSmallTablet}
            />
        );
    }, [activeId, activePlaylist, beginAssignment, isParent, isPhone, isSmallTablet, isTablet, navigate, onEditPlaylist, selectedVideosList]);

    const playlistSelectionCards = useMemo(() => {
        if (!sortedPlaylist) return null;
        let sortedAssignedPlaylist = assignedPlaylists.sort((a, b) => moment(b.created).diff(moment(a.created)));

        // filter the playlists
        let items = null;
        switch (sortOrder) {
            case SORT_TYPE_ALPHA_ASC:
                items = sortedPlaylist.sort((a, b) => a.name?.localeCompare(b.name));
                break;
            case SORT_TYPE_ALPHA_DESC:
                items = sortedPlaylist.sort((a, b) => b.name?.localeCompare(a.name));
                break;
            case SORT_TYPE_DATE_ASC:
                items = sortByDueDate(sortedPlaylist, true);
                break;
            case SORT_TYPE_DATE_DESC:
                items = sortByDueDate(sortedPlaylist, false, true);
                break;
            case SORT_TYPE_ASSIGNED:
                // if the assignment has been assigned use the same due date helper
                items = sortByDueDate(sortedPlaylist, true);
                break;
            case SORT_TYPE_UNASSIGNED:
                items = sortByDueDate(sortedPlaylist, false, false, true);
                break;
            default:
                items = sortedAssignedPlaylist;
        }
        return items?.map((playlist, index) => {
            const isSelected = playlist.id === activeId;
            return (
                <React.Fragment key={playlist.id}>
                    <PlaylistSelectionCard
                        playlist={playlist}
                        key={index}
                        active={activeId}
                        setActive={setActiveId}
                        onEditPlaylist={onEditPlaylist}
                        HandleAssignAgain={HandleAssignAgain}
                        setDeletePlaylistModal={setDeletePlaylistModal}
                        setDeleteAssignmentModal={setDeleteAssignmentModal}
                        setDeletePlaylistId={setDeletePlaylistId}
                        setDeleteAssignmentId={setDeleteAssignmentId}
                        isParent={isParent}
                    />
                    {isTablet && isSelected && selectedPlaylist}
                </React.Fragment>
            );
        });
    }, [HandleAssignAgain, activeId, assignedPlaylists, isParent, isTablet, onEditPlaylist, selectedPlaylist, sortOrder, sortedPlaylist]);

    // map selected assignments videos to the format need in the playlist wizard
    let selectedVideos = [];
    if (activePlaylist?.videos) {
        selectedVideos = activePlaylist.videos.map((videoId) => {
            const video = videoMap[videoId];
            return {
                videoId: videoId,
                videoName: video?.title,
                videoThumbnail: video?.url_thumbnail
            };
        });
    }
    const enable_topics = true;
    // const isTablet = true;
    return (
        <div tw='h-full pb-8' style={{ background: 'linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%)' }}>
            <Helmet>
                <title>Playlists</title>
            </Helmet>
            <div tw='h-full px-1 sm:px-6 font-sans overflow-y-scroll' css={[ScrollBarStyle]}>
                {/* Popups for all actions you can do on an assignment */}
                {/* Edit Playlist Videos */}
                <PlaylistPageActionsPopup
                    showModal={editPlaylistVideosModal}
                    setShowModal={setEditPlaylistVideosModal}
                    handleConfirmAction={handleEditPlaylistModalConfirmed}
                    header='Edit Playlist Videos'
                    copy1='This playlist has been assigned. Assignments will be updated to reflect any playlist edits.'
                    copy2='Are you sure you want to edit this playlist?'
                    copyButton1='NO THANKS'
                    copyButton2='EDIT PLAYLIST'
                />
                {/* Edit Assignment */}
                <PlaylistPageActionsPopup
                    showModal={editAssignmentModal}
                    setShowModal={setEditAssignmentModal}
                    handleConfirmAction={handleEditAssignmentModal}
                    header='Edit Assignment'
                    copy1='Your students have received this assignment. Any edits made will modify the existing assignment.'
                    copy2='Are you sure you want to edit this assignment?'
                    copyButton1='NO THANKS'
                    copyButton2='EDIT ASSIGNMENT'
                />
                {/* Edit Draft */}
                <PlaylistPageActionsPopup
                    showModal={editDraftModal}
                    setShowModal={setEditDraftModal}
                    handleConfirmAction={handleEditAssignmentModal}
                    header='Edit Draft'
                    // copy1='Your students have received this assignment. Any edits made will modify the existing assignment.'
                    copy2='Are you sure you want to edit this assignment?'
                    copyButton1='NO THANKS'
                    copyButton2='EDIT ASSIGNMENT'
                />
                {/* Delete Playlist */}
                <PlaylistPageActionsPopup
                    showModal={deletePlaylistModal}
                    setShowModal={setDeletePlaylistModal}
                    handleConfirmAction={handleDeletePlaylistModal}
                    boldCopy={activePlaylist?.name}
                    header='Delete Playlist'
                    maxWidth='675px'
                    copy2={
                        activePlaylist?.assignments?.length > 0
                            ? 'This playlist has been assigned. Deleting it will remove the playlist from appearing as an assignment for your students.  Are you sure you want to delete this playlist?'
                            : 'Are you sure you want to delete this playlist?'
                    }
                    copyButton1='KEEP'
                    copyButton2='DELETE PLAYLIST'
                />
                {/* Delete Assignment */}
                <PlaylistPageActionsPopup
                    showModal={deleteAssignmentModal}
                    setShowModal={setDeleteAssignmentModal}
                    handleConfirmAction={handleDeleteAssignmentModal}
                    header='Delete Assignment'
                    copy1='Your students have received this assignment. Deleting it will remove the assignment from your students. '
                    copy2='Are you sure you want to delete this assignment?'
                    copyButton1='KEEP'
                    copyButton2='DELETE ASSIGNMENT'
                />

                {/* Playlist wizard for assigning a playlist again  */}
                <PlaylistAssignWizard
                    isOpen={playlistDetailsModal}
                    setIsOpen={(open) => setPlaylistDetailsModal(open)}
                    playlistId={activeId}
                    // playlist={playlist}
                    playlist={{
                        name: activePlaylist?.name,
                        selectedVideos: selectedVideos
                    }}
                />
                <div tw='maxWidth[1360px] mx-auto w-full pb-10'>
                    <div
                        tw='flex justify-between items-start pt-7 pl-4'
                        css={[
                            isParent &&
                            css`
                            
                                    @media only screen and (max-width: 768px) {
                                        flex-direction: column;
                                    }
                                `
                        ]}
                    >
                        <div tw='flex items-center'>
                            {!isParent && (
                                <>
                                    <div tw='text-center text-white mr-2'>Filter by:</div>
                                    <div style={{ minWidth: '180px' }}>
                                        <ListBox items={classesWithDefault} onChange={(value) => setSelectedClass(value)} />
                                    </div>
                                </>
                            )}
                        </div>
                        <Button variant='teal-large' onClick={handleCreateNewPlaylist} css={isParent && tw`mt-4 md:mt-0`} id='playlist-create'>
                            <div tw='flex items-center justify-center'>
                                <div tw='mr-0.5'>
                                    <svg width='12' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M11.935 6.206a.914.914 0 0 0-.948-.948l-4.007.036.035-4.008A.914.914 0 0 0 6.068.34a.954.954 0 0 0-.965.965L5.067 5.31l-4.008.036a.954.954 0 0 0-.965.965c-.002.273.132.5.313.68.18.18.407.315.68.313l4.008-.036-.036 4.008c-.002.273.132.5.313.68.18.18.407.315.68.313a.954.954 0 0 0 .965-.965l.036-4.008 4.007-.036c.457-.095.87-.508.875-1.055Z'
                                            fill='#fff'
                                        />
                                    </svg>
                                </div>
                                <p>NEW PLAYLIST</p>
                            </div>
                        </Button>
                    </div>
                    {sortedPlaylist?.length ? (
                        <>
                            <div tw='flex items-center justify-between pt-5 pl-2' style={{ maxWidth: '400px' }}>
                                <PlaylistSorting
                                    name='Playlist'
                                    asc={SORT_TYPE_ALPHA_ASC}
                                    desc={SORT_TYPE_ALPHA_DESC}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    style={{ width: '190px' }}
                                    maxWidth='75px'
                                    parent={isParent}
                                />

                                <PlaylistSorting
                                    name={isParent ? 'Finish By' : 'Due'}
                                    asc={SORT_TYPE_DATE_ASC}
                                    desc={SORT_TYPE_DATE_DESC}
                                    sortOrder={sortOrder}
                                    setSortOrder={setSortOrder}
                                    parent={isParent}
                                />
                            </div>

                            <div tw='flex'>
                                <div
                                    tw='flex-col sticky overflow-visible px-2'
                                    css={[
                                        ScrollBarStyle,
                                        isTablet &&
                                        css`
                                                flex-grow: 1;
                                            `,
                                        !isTablet &&
                                        css`
                                                max-width: 590px;
                                                width: 100%;
                                            `
                                    ]}
                                    id='playlist-playlists'
                                // style={{ maxWidth: '590px', width: '42%' }}
                                >
                                    {playlistSelectionCards}
                                </div>

                                {!isTablet && (
                                    <div
                                        tw='ml-5'
                                        css={[
                                            css`
                                                margin-bottom: 50px;
                                                max-width: 780px;
                                                flex-grow: 1;
                                            `
                                        ]}
                                    >
                                        {selectedPlaylist}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        isParent ? <NullContainer tw='marginTop[30px]'>
                            <p>
                                Want a fun way to guide your child's learning? Create a Muzology playlist and share it with your child.{' '}
                                <span tw='underline cursor-pointer ' onClick={handleCreateNewPlaylist}>
                                    Click here
                                </span>{' '}
                                to create your first playlist, or click the "+ NEW PLAYLIST" button in the upper right.
                            </p>
                            <p tw='pt-8'>Going forward, you'll see your child's playlist progress on this page.</p>
                        </NullContainer> :
                            <NullContainer tw='marginTop[30px]'>
                                <p>
                                    Assigning Muzology playlists is a great way to keep your students focused and differentiate instruction. Teachers that assign
                                    playlists typically get the best Muzology results. Assigning playlists is fast and easy!{' '}
                                    <span tw='underline cursor-pointer ' onClick={handleCreateNewPlaylist}>
                                        Click here
                                    </span>{' '}
                                    to create your first playlist or click the “+ NEW PLAYLIST” button in the upper right.
                                </p>
                                <p tw='pt-8'>Going forward, you can see (and manage) your assigned playlists on this page.</p>
                            </NullContainer>
                    )}
                    <ParentTopicsModal
                        isOpen={parentLearningModal}
                        setIsOpen={(value) => {
                            setParentLearningModal(value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default PlaylistsContainer;
