import { styled } from 'twin.macro';
import Page from 'components/Page';

export const QuizSessionPage = styled(Page)`
    background-size: cover;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: stretch;

    position: relative;

    background-image: url(${(props) => props.$image});
    background-size: cover;
    background-position: center;
    height: 100%;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    /*
    
        //border-radius: 2px;
        color: black;
        background: transparent;
        min-height: 0;
        //min-height: 100%;
    
        
        flex-grow: 1;
    
        //height: 100vh;
        display: flex;
    
        //min-height: 100vh;
        //padding-top: 40px;
        //padding-bottom: 40px;
    
        flex-direction: column;
        justify-content: stretch;
    
        position: relative;
        */
    .section {
        flex-shrink: 0;
    }

    .quiz-title {
    }

    &.warmup {
        //background: #0000008f;
    }


    .background-image {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        //bottom: 0;

        width: 100%;
    }

    .grid {
    }

    .info-row {
        margin-top: 20px;
    }
`;
