import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AssignmentsContainer from 'app/containers/student/AssignmentsContainer/AssignmentsContainer';
import HomeContainer from 'app/containers/student/StudentHomeContainer/HomeContainer';
import VideoSessionContainer from 'app/containers/shared/VideoSessionContainer/VideoSessionContainer';
import { withScrollLayout } from 'app/layout/Layouts';
import Stats from '../containers/student/StatsContainer/Stats';
import { Error404Page } from '../../components';
import QuizSession from '../containers/child/QuizSessionContainer/QuizSession';


const StudentApp = () => {
    return (
        <Routes>
            <Route path='*' element={<Error404Page />} />
            <Route path='/quiz/:quiz_id' element={withScrollLayout(QuizSession)} />
            <Route path='/' exact={true} element={withScrollLayout(HomeContainer)} />
            <Route path='/video/:video_id' element={withScrollLayout(VideoSessionContainer)} />
            <Route path='/playlists/*' element={withScrollLayout(AssignmentsContainer)} />
            <Route path='/assignments/*' element={withScrollLayout(AssignmentsContainer)} />
            <Route path='/stats/*' element={withScrollLayout(Stats)} />
        </Routes>
    );
};
export default StudentApp;
